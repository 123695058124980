import React from 'react';
import { useTheme } from '../../context/ThemeContext';
import { usePostComments } from '../../context/PostCommentsContext';
import { useAuth } from '../../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import ChatInterfaceCommentHistorySkeleton from './ChatInterfaceCommentHistorySkeleton';
import { 
  ClockIcon, 
  TrashIcon, 
  ClipboardDocumentIcon,
  ArrowLeftIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  ChatBubbleLeftRightIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon as ClipboardDocumentIconSolid } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { createPortal } from 'react-dom';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center space-x-1">
    <div className="w-2 h-2 rounded-full bg-blue-500 animate-[bounce_0.9s_infinite]" />
    <div className="w-2 h-2 rounded-full bg-blue-500 animate-[bounce_0.9s_0.3s_infinite]" />
    <div className="w-2 h-2 rounded-full bg-blue-500 animate-[bounce_0.9s_0.6s_infinite]" />
  </div>
);

const EmptyState = ({ searchTerm, isDarkMode, error }) => {
  const isSearch = Boolean(searchTerm);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`text-center py-12 px-4 rounded-lg border ${
        isDarkMode 
          ? 'bg-[#1E1E1E] border-[#333333]' 
          : 'bg-white border-gray-100'
      }`}
    >
      {isSearch ? (
        <MagnifyingGlassIcon className={`h-12 w-12 mx-auto ${
          isDarkMode ? 'text-gray-600' : 'text-gray-400'
        } mb-4`} />
      ) : error ? (
        <ExclamationTriangleIcon className={`h-12 w-12 mx-auto ${
          isDarkMode ? 'text-amber-500' : 'text-amber-400'
        } mb-4`} />
      ) : (
        <ChatBubbleLeftRightIcon className={`h-12 w-12 mx-auto ${
          isDarkMode ? 'text-gray-600' : 'text-gray-400'
        } mb-4`} />
      )}
      
      <h3 className={`text-lg font-medium mb-2 ${
        isDarkMode ? 'text-gray-200' : 'text-gray-800'
      }`}>
        {isSearch 
          ? 'No matching results' 
          : error 
            ? 'Oops! Something went wrong' 
            : 'No comment history yet'}
      </h3>
      
      <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        {isSearch 
          ? 'Try adjusting your search terms or filters'
          : error
            ? error
            : 'Your generated comments will appear here'}
      </p>

      {!isSearch && !error && (
        <div className={`mt-6 inline-flex items-center gap-1.5 px-3 py-2 rounded-lg text-xs ${
          isDarkMode 
            ? 'bg-gray-800 text-gray-300' 
            : 'bg-gray-50 text-gray-600'
        }`}>
          <ClockIcon className="h-4 w-4" />
          Start generating comments to build your history
        </div>
      )}
    </motion.div>
  );
};

const TopBar = ({ searchTerm, setSearchTerm, isDarkMode, isSearchExpanded, setIsSearchExpanded, onBack }) => (
  <div className={`${isDarkMode ? 'bg-gray-800/90 border-gray-700' : 'bg-white/90 border-gray-200'} 
    backdrop-blur-xl h-10 flex-shrink-0 flex items-center justify-between px-3 w-full border-b sticky top-0 z-50`}>
    {/* Left section */}
    <div className="flex items-center gap-2">
      <button 
        onClick={onBack}
        className={`p-1.5 rounded-lg transition-colors duration-200 ${
          isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'
        }`}
      >
        <ArrowLeftIcon className={`h-3.5 w-3.5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
      </button>
      <div className="flex items-center gap-1.5">
        <ClockIcon className={`h-3.5 w-3.5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
        <h2 className={`text-xs font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Comment History</h2>
      </div>
    </div>

    {/* Right section - Search */}
    <div className="flex items-center">
      <ExpandableSearchBar
        isDarkMode={isDarkMode}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isExpanded={isSearchExpanded}
        setIsExpanded={setIsSearchExpanded}
      />
    </div>
  </div>
);

const ExpandableSearchBar = ({ isDarkMode, searchTerm, setSearchTerm, isExpanded, setIsExpanded }) => {
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  return (
    <div className="relative">
      {isExpanded ? (
        <div className="flex items-center">
          <input
            ref={inputRef}
            type="text"
            placeholder="Search history..."
            className={`w-40 pl-7 pr-7 py-1 text-xs border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500
                      ${isDarkMode 
                        ? 'bg-gray-700 text-gray-200 border-gray-600' 
                        : 'bg-white text-gray-900 border-gray-300'}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <MagnifyingGlassIcon className={`absolute left-1.5 top-1/2 transform -translate-y-1/2 w-3.5 h-3.5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          <button
            onClick={() => setIsExpanded(false)}
            className="absolute right-1.5 top-1/2 transform -translate-y-1/2"
          >
            <XMarkIcon className={`w-3.5 h-3.5 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsExpanded(true)}
          className={`p-1.5 rounded-lg transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
          }`}
        >
          <MagnifyingGlassIcon className={`h-3.5 w-3.5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
        </button>
      )}
    </div>
  );
};

const FullContentModal = ({ content, isOpen, onClose, isDarkMode }) => {
  // Handle Escape key - Move useEffect before the conditional return
  React.useEffect(() => {
    if (!isOpen) return;
    
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose, isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="fixed inset-0 z-[100]"
    >
      {/* Backdrop */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }}
        className="absolute inset-0 bg-black/20 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="relative h-full flex items-center justify-center p-4">
        <motion.div 
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={`
            relative w-full max-w-xl max-h-[85vh] overflow-hidden rounded-xl shadow-xl
            ${isDarkMode ? 'bg-[#1E1E1E] border-[#333333]' : 'bg-white border-gray-200'}
            border
          `}
        >
          {/* Header */}
          <div className={`
            px-4 py-3 flex items-center justify-between border-b
            ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
          `}>
            <h3 className={`text-xs font-medium ${
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            }`}>
              Original Post
            </h3>
            <button
              onClick={onClose}
              className={`
                p-1.5 rounded-md transition-all duration-200
                ${isDarkMode 
                  ? 'hover:bg-white/10 text-gray-400 hover:text-gray-300' 
                  : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'}
              `}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>

          {/* Content */}
          <div className={`
            p-4 overflow-y-auto
            ${isDarkMode ? 'custom-scrollbar-dark' : 'custom-scrollbar-light'}
          `}
            style={{ maxHeight: 'calc(85vh - 48px)' }} // 48px is header height
          >
            <div className={`
              text-sm whitespace-pre-wrap break-words leading-relaxed
              ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}
            `}>
              {content}
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>,
    document.body
  );
};

const ChatInterfaceCommentHistory = ({ onClose }) => {
  const { isDarkMode } = useTheme();
  const { user, isAuthenticated, loading: authLoading } = useAuth();
  const { 
    commentHistory, 
    deleteHistoryItem,
    copyToClipboard,
    fetchCommentHistory,
    error,
    setError,
    pagination,
    loadMoreComments,
    isLoadingMore,
    deletingComments
  } = usePostComments();
  const [isLoading, setIsLoading] = React.useState(false);
  const [copiedComments, setCopiedComments] = React.useState({});
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isSearchExpanded, setIsSearchExpanded] = React.useState(false);
  const [hoveredPost, setHoveredPost] = React.useState(null);

  // Debug logs for auth state
  React.useEffect(() => {
    console.log('Auth State:', {
      isAuthenticated,
      user,
      authLoading,
      accessToken: localStorage.getItem('accessToken')
    });
  }, [isAuthenticated, user, authLoading]);

  // Single source of truth for filtered history
  const filteredHistory = React.useMemo(() => {
    if (!commentHistory) return [];
    
    return searchTerm
      ? commentHistory.filter(item =>
          item.comment.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.postContent.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : commentHistory;
  }, [searchTerm, commentHistory]);

  // Initial data fetch
  React.useEffect(() => {
    let mounted = true;

    const initializeHistory = async () => {
      const token = localStorage.getItem('accessToken');
      
      if (authLoading || !mounted) return;
      if (!token) {
        setError('Please log in to view comment history');
        return;
      }

      // Only fetch if we don't have comments
      if (commentHistory?.length === 0) {
        try {
          setIsLoading(true);
          await fetchCommentHistory(1, 10);
        } catch (error) {
          if (mounted) {
            setError(error.response?.status === 401 
              ? 'Session expired. Please log in again.'
              : 'Failed to load comments');
          }
        } finally {
          if (mounted) {
            setIsLoading(false);
          }
        }
      }
    };

    initializeHistory();

    return () => {
      mounted = false;
    };
  }, [authLoading, user, fetchCommentHistory, setError, commentHistory?.length]);

  const handleCopy = async (text, id) => {
    await copyToClipboard(text);
    setCopiedComments(prev => ({ ...prev, [id]: true }));
    setTimeout(() => {
      setCopiedComments(prev => ({ ...prev, [id]: false }));
    }, 2000);
  };

  const handleDelete = async (id) => {
    try {
      const itemToDelete = filteredHistory.find(item => item._id === id);
      if (itemToDelete) {
        // Delete directly from the context
        await deleteHistoryItem(id);
      }
    } catch (error) {
      console.error('Error deleting comment:', error);
      setError('Failed to delete comment');
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !isLoadingMore && pagination.page < pagination.pages) {
      loadMoreComments();
    }
  };

  React.useEffect(() => {
    return () => {
      setError(null);
      setCopiedComments({});
      setSearchTerm('');
      setIsSearchExpanded(false);
    };
  }, [setError]);

  const CustomTooltip = ({ content, isVisible, children }) => {
    const [position, setPosition] = React.useState('top');
    const tooltipRef = React.useRef(null);
    const containerRef = React.useRef(null);

    React.useEffect(() => {
      if (isVisible && tooltipRef.current && containerRef.current) {
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        // Check if tooltip would overflow top
        const topOverflow = containerRect.top - tooltipRect.height - 8 < 0;
        // Check if tooltip would overflow bottom
        const bottomOverflow = containerRect.bottom + tooltipRect.height + 8 > windowHeight;
        // Check if tooltip would overflow right
        const rightOverflow = containerRect.right + tooltipRect.width > windowWidth;
        // Check if tooltip would overflow left
        const leftOverflow = containerRect.left - tooltipRect.width < 0;

        if (topOverflow && !bottomOverflow) {
          setPosition('bottom');
        } else if (!topOverflow) {
          setPosition('top');
        } else {
          setPosition('right');
        }
      }
    }, [isVisible]);

    return (
      <div className="relative" ref={containerRef}>
        {children}
        <div 
          ref={tooltipRef}
          className={`
            fixed z-[60] min-w-[200px] max-w-[min(90vw,400px)]
            transform transition-all duration-200
            ${position === 'top' ? 'bottom-full left-0 mb-2' : ''}
            ${position === 'bottom' ? 'top-full left-0 mt-2' : ''}
            ${position === 'right' ? 'left-full top-0 ml-2' : ''}
            ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-1 pointer-events-none'}
          `}
          style={{
            maxHeight: 'min(80vh, 400px)',
            overflowY: 'auto'
          }}
        >
          <div className={`
            p-3 rounded-lg shadow-lg border backdrop-blur-xl
            ${isDarkMode 
              ? 'bg-[#252525]/95 border-[#333333] text-slate-200' 
              : 'bg-white/95 border-gray-200 text-gray-700'}
          `}>
            <p className="text-xs whitespace-pre-wrap break-words leading-relaxed">
              {content}
            </p>
          </div>
          <div className={`
            absolute w-2 h-2 transform rotate-45
            ${isDarkMode 
              ? 'bg-[#252525] border-[#333333]' 
              : 'bg-white border-gray-200'}
            ${position === 'top' ? 'bottom-0 left-4 -mb-1 border-r border-b' : ''}
            ${position === 'bottom' ? 'top-0 left-4 -mt-1 border-l border-t' : ''}
            ${position === 'right' ? 'left-0 top-4 -ml-1 border-l border-t' : ''}
          `} />
        </div>
      </div>
    );
  };

  return (
    <div className={`h-full flex flex-col w-full ${isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-white text-gray-900'}`}>
      <TopBar 
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isDarkMode={isDarkMode}
        isSearchExpanded={isSearchExpanded}
        setIsSearchExpanded={setIsSearchExpanded}
        onBack={onClose}
      />
      
      {(authLoading || isLoading) ? (
        <ChatInterfaceCommentHistorySkeleton />
      ) : (
        <div className="flex-1 overflow-y-auto p-4 w-full max-w-full" onScroll={handleScroll}>
          <div className="max-w-5xl mx-auto w-full">
            {error && !authLoading ? (
              <div className={`mb-4 p-3 rounded-lg ${
                isDarkMode 
                  ? 'bg-red-900/20 border border-red-800/50 text-red-400' 
                  : 'bg-red-50 border border-red-200 text-red-600'
              }`}>
                <p className="text-sm">{error}</p>
              </div>
            ) : isLoading ? (
              <div className="flex justify-center py-12">
                <LoadingSpinner />
              </div>
            ) : filteredHistory.length === 0 ? (
              <EmptyState searchTerm={searchTerm} isDarkMode={isDarkMode} error={error} />
            ) : (
              <div className="space-y-6">
                <AnimatePresence mode="popLayout">
                  {filteredHistory.map((item) => (
                    <motion.div
                      key={item._id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ 
                        opacity: item.isDeleting ? 0 : 1,
                        y: item.isDeleting ? -20 : 0,
                        height: item.isDeleting ? 0 : 'auto',
                        marginBottom: item.isDeleting ? 0 : undefined
                      }}
                      exit={{ 
                        opacity: 0,
                        y: -20,
                        height: 0,
                        marginBottom: 0
                      }}
                      transition={{
                        duration: 0.3,
                        ease: "easeInOut"
                      }}
                      className={`relative ${
                        item.isDeleting ? 'scale-95 opacity-50' : ''
                      }`}
                    >
                      {/* Main Content Card */}
                      <div className={`p-4 rounded-lg border ${
                        isDarkMode 
                          ? 'bg-[#1E1E1E] border-[#333333]' 
                          : 'bg-white border-gray-200'
                      }`}>
                        {/* Top Row - Date and Actions */}
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <div className={`h-1.5 w-1.5 rounded-full ${
                              isDarkMode ? 'bg-blue-400' : 'bg-blue-500'
                            }`} />
                            <span className={`text-[10px] uppercase tracking-wider font-medium ${
                              isDarkMode ? 'text-slate-400' : 'text-gray-500'
                            }`}>
                              {format(new Date(item.createdAt), 'MMM d, yyyy')}
                            </span>
                          </div>
                          
                          {/* Action Buttons */}
                          <div className="flex items-center gap-1">
                            <button
                              onClick={() => handleCopy(item.comment, item._id)}
                              disabled={item.isDeleting || deletingComments.has(item._id)}
                              className={`p-1.5 rounded-md transition-colors ${
                                isDarkMode 
                                  ? copiedComments[item._id]
                                    ? 'bg-green-500/10 text-green-400'
                                    : 'text-slate-400' 
                                  : copiedComments[item._id]
                                    ? 'bg-green-50 text-green-600'
                                    : 'text-gray-500'
                              }`}
                              title={copiedComments[item._id] ? "Copied!" : "Copy comment"}
                            >
                              {copiedComments[item._id] ? (
                                <ClipboardDocumentIconSolid className="h-3.5 w-3.5" />
                              ) : (
                                <ClipboardDocumentIcon className="h-3.5 w-3.5" />
                              )}
                            </button>
                            <button
                              onClick={() => handleDelete(item._id)}
                              disabled={item.isDeleting || deletingComments.has(item._id)}
                              className={`p-1.5 rounded-md transition-colors
                                ${isDarkMode 
                                  ? 'text-slate-400' 
                                  : 'text-gray-500'
                                }`}
                              title={item.isDeleting || deletingComments.has(item._id) ? 'Deleting...' : 'Delete comment'}
                            >
                              <TrashIcon className={`h-3.5 w-3.5 ${
                                item.isDeleting || deletingComments.has(item._id) ? 'animate-spin' : ''
                              }`} />
                            </button>
                          </div>
                        </div>

                        {/* Tags Row */}
                        <div className="flex flex-wrap items-center gap-1.5 mt-3">
                          <span className={`px-1.5 py-0.5 rounded text-[10px] font-medium ${
                            isDarkMode ? 'bg-blue-500/5 text-blue-400' : 'bg-blue-50 text-blue-600'
                          }`}>
                            {item.tone}
                          </span>
                          {item.persona && (
                            <span className={`px-1.5 py-0.5 rounded text-[10px] font-medium ${
                              isDarkMode ? 'bg-purple-500/5 text-purple-400' : 'bg-purple-50 text-purple-600'
                            }`}>
                              {item.persona.name}
                            </span>
                          )}
                          {item.targetAudience && (
                            <span className={`px-1.5 py-0.5 rounded text-[10px] font-medium ${
                              isDarkMode ? 'bg-amber-500/5 text-amber-400' : 'bg-amber-50 text-amber-600'
                            }`}>
                              {item.targetAudience.name}
                            </span>
                          )}
                        </div>

                        {/* Content Section */}
                        <div className={`mt-3 space-y-3 ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`}>
                          {/* Original Post */}
                          <div className={`p-2 rounded ${
                            isDarkMode ? 'bg-[#252525]/50' : 'bg-gray-50'
                          }`}>
                            <p 
                              className="text-xs line-clamp-2 cursor-pointer"
                              onClick={() => setHoveredPost(item._id)}
                            >
                              {item.postContent}
                            </p>
                            <FullContentModal
                              content={item.postContent}
                              isOpen={hoveredPost === item._id}
                              onClose={() => setHoveredPost(null)}
                              isDarkMode={isDarkMode}
                            />
                          </div>

                          {/* Generated Comment */}
                          <div className="text-sm leading-relaxed">
                            {item.comment.split('\n').map((paragraph, i) => (
                              <p key={i} className={i !== 0 ? 'mt-2' : ''}>
                                {paragraph}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
                {isLoadingMore && (
                  <div className="py-4 flex justify-center">
                    <LoadingSpinner />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatInterfaceCommentHistory; 