import React from 'react';
import { useTheme } from '../../context/ThemeContext';

const getInitials = (name) => {
  if (!name) return '??';
  return name
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);
};

// Color pairs for light/dark mode [lightMode, darkMode]
const colorPairs = [
  ['bg-gray-100 text-gray-600', 'bg-gray-800 text-gray-200'],
  ['bg-blue-50 text-blue-600', 'bg-blue-900/30 text-blue-200'],
  ['bg-emerald-50 text-emerald-600', 'bg-emerald-900/30 text-emerald-200'],
  ['bg-violet-50 text-violet-600', 'bg-violet-900/30 text-violet-200'],
  ['bg-amber-50 text-amber-600', 'bg-amber-900/30 text-amber-200'],
  ['bg-rose-50 text-rose-600', 'bg-rose-900/30 text-rose-200']
];

const getColorFromName = (name, isDark) => {
  if (!name) return colorPairs[0][isDark ? 1 : 0];
  
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  return colorPairs[Math.abs(hash) % colorPairs.length][isDark ? 1 : 0];
};

const InitialsAvatar = ({ 
  name, 
  size = 'medium', 
  className = '',
  onClick 
}) => {
  const { isDarkMode } = useTheme();
  const initials = getInitials(name);
  const colorClass = getColorFromName(name, isDarkMode);
  
  const sizeClasses = {
    tiny: 'w-5 h-5 text-[10px]',
    small: 'w-8 h-8 text-xs',
    medium: 'w-10 h-10 text-sm',
    large: 'w-12 h-12 text-base'
  };

  return (
    <div
      className={`
        ${sizeClasses[size]}
        ${colorClass}
        rounded-full
        flex
        items-center
        justify-center
        font-medium
        select-none
        transition-colors
        duration-150
        ${onClick ? 'cursor-pointer hover:opacity-80 active:opacity-100' : ''}
        ${className}
      `}
      title={name}
      onClick={onClick}
    >
      {initials}
    </div>
  );
};

export default InitialsAvatar; 