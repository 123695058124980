import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { ChevronUpDownIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../../api';
import { useTheme } from '../../context/ThemeContext';
import CompactTargetAudienceCard from './CompactTargetAudienceCard';
import ResetIcon from '../personas/ResetIcon';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import InitialsAvatar from '../common/InitialsAvatar';

const DEFAULT_AUDIENCE = { _id: 'default', name: 'Default Audience' };

const truncateName = (name, maxLength = 8) => {
  if (!name) return '';
  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
};

const SkeletonLoader = () => (
  <div className="animate-pulse flex items-center p-2 rounded-md border mb-[5px]">
    <div className="relative mr-3 flex-shrink-0">
      <div className="w-8 h-8 rounded-full bg-gray-300"></div>
    </div>
    <div className="flex-1 min-w-0">
      <div className="h-2 bg-gray-300 rounded w-3/4 mb-1"></div>
      <div className="h-2 bg-gray-300 rounded w-1/2"></div>
    </div>
  </div>
);

const TargetAudienceDropdown = ({ onSelectAudience, selectedAudience: propSelectedAudience }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState(propSelectedAudience);
  const [searchTerm, setSearchTerm] = useState('');
  const [audiences, setAudiences] = useState([]);
  const [filteredAudiences, setFilteredAudiences] = useState([]);
  const dropdownRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const searchInputRef = useRef(null);
  const { isDarkMode } = useTheme();
  const initialFetchDone = useRef(false);
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const [horizontalPosition, setHorizontalPosition] = useState('left');
  const isMobile = useMediaQuery('(max-width: 640px)');

  const fetchAudiences = useMemo(() => async () => {
    try {
      const response = await api.get('/api/target-audiences');
      return response.data;
    } catch (error) {
      console.error('Error fetching target audiences:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    const initializeComponent = async () => {
      if (!initialFetchDone.current) {
        const fetchedAudiences = await fetchAudiences();
        setAudiences(fetchedAudiences);
        setFilteredAudiences(fetchedAudiences);

        const savedAudience = localStorage.getItem('selectedTargetAudience');
        if (savedAudience) {
          const parsedAudience = JSON.parse(savedAudience);
          setSelectedAudience(parsedAudience);
          onSelectAudience(parsedAudience);
        }
        initialFetchDone.current = true;
      }
    };

    initializeComponent();
  }, [fetchAudiences, onSelectAudience]);

  useEffect(() => {
    if (propSelectedAudience) {
      setSelectedAudience(propSelectedAudience);
    }
  }, [propSelectedAudience]);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredAudiences(audiences);
    } else {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = audiences.filter(audience =>
        audience && audience.targetAudienceName && audience.targetAudienceName.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredAudiences(filtered);
    }
    setFocusedIndex(-1);
  }, [searchTerm, audiences]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const dropdownWidth = 192; // w-48 = 12rem = 192px
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      
      // Vertical position check
      const spaceBelow = viewportHeight - dropdownRect.bottom;
      const spaceAbove = dropdownRect.top;
      
      // Horizontal position check
      const spaceRight = viewportWidth - dropdownRect.left;
      
      // Set vertical position
      if (spaceBelow < 300 && spaceAbove > 300) {
        setDropdownPosition('top');
      } else {
        setDropdownPosition('bottom');
      }
      
      // Set horizontal position
      if (spaceRight < dropdownWidth) {
        setHorizontalPosition('right');
      } else {
        setHorizontalPosition('left');
      }
    }
  }, [isOpen]);

  const handleAudienceSelect = useCallback(async (audience) => {
    if (audience._id === 'default') {
      setSelectedAudience(null);
      onSelectAudience(null);
      localStorage.removeItem('selectedTargetAudience');
    } else {
      setSelectedAudience(audience);
      onSelectAudience(audience);
      localStorage.setItem('selectedTargetAudience', JSON.stringify(audience));
    }
    setIsOpen(false);
    setSearchTerm('');
  }, [onSelectAudience]);

  const handleCreateNewAudience = () => {
    setIsOpen(false);
    navigate('/target-audiences/create');
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex + 1) % (filteredAudiences.length + 2));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex - 1 + filteredAudiences.length + 2) % (filteredAudiences.length + 2));
        break;
      case 'Enter':
        e.preventDefault();
        if (focusedIndex === -1) {
          handleAudienceSelect(DEFAULT_AUDIENCE);
        } else if (focusedIndex === filteredAudiences.length + 1) {
          handleCreateNewAudience();
        } else if (focusedIndex >= 0 && focusedIndex < filteredAudiences.length) {
          handleAudienceSelect(filteredAudiences[focusedIndex]);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  // Animation variants
  const dropdownVariants = {
    hidden: { 
      opacity: 0, 
      y: dropdownPosition === 'bottom' ? -5 : 5,
      x: horizontalPosition === 'right' ? 5 : -5,
      scale: 0.95 
    },
    visible: { 
      opacity: 1, 
      y: 0,
      x: 0, 
      scale: 1, 
      transition: { 
        type: "spring", 
        stiffness: 300, 
        damping: 30 
      } 
    },
    exit: { 
      opacity: 0, 
      y: dropdownPosition === 'bottom' ? -5 : 5,
      x: horizontalPosition === 'right' ? 5 : -5,
      scale: 0.95, 
      transition: { duration: 0.1 } 
    }
  };

  const listItemVariants = {
    hidden: { opacity: 0, x: -5 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.1 } }
  };

  return (
    <div className="relative" ref={dropdownRef} onKeyDown={handleKeyDown}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`group flex items-center justify-between px-2 py-1 text-xs font-medium rounded-md transition-all duration-200 whitespace-nowrap ${
          selectedAudience ? (isMobile ? 'w-[140px]' : 'min-w-fit') : 'w-fit'
        } ${
          isDarkMode
            ? 'text-gray-300 hover:bg-[#2A2A2A]/50'
            : 'text-slate-700 hover:bg-slate-100/50'
        } focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-slate-500`}
      >
        <div className="flex items-center">
          {selectedAudience ? (
            <>
              <div className="mr-1.5 flex-shrink-0">
                <InitialsAvatar 
                  name={selectedAudience.targetAudienceName}
                  size="tiny"
                />
              </div>
              <span className="truncate max-w-[90px]" title={selectedAudience.targetAudienceName}>
                {truncateName(selectedAudience.targetAudienceName, isMobile ? 12 : 12)}
              </span>
            </>
          ) : (
            <span>{isMobile ? 'Audience' : 'Audience Personas'}</span>
          )}
        </div>
        <ChevronUpDownIcon className="h-3 w-3 ml-1 flex-shrink-0" />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={dropdownVariants}
            className={`absolute ${
              dropdownPosition === 'bottom' ? 'top-full mt-1' : 'bottom-full mb-1'
            } ${
              horizontalPosition === 'right' ? 'right-0' : 'left-0'
            } w-64 rounded-md shadow-lg ${
              isDarkMode 
                ? 'bg-[#1E1E1E] ring-1 ring-[#333333]' 
                : 'bg-white ring-1 ring-slate-200'
            } z-[9999]`}
          >
            <div className={`p-2 ${isDarkMode ? 'border-slate-700' : 'border-gray-200'}`}>
              {audiences.length > 0 && (
                <div className={`mb-3 text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  <p className="leading-tight">
                    Choose a target audience to tailor your content. Each audience has specific characteristics and interests.
                  </p>
                </div>
              )}
              <div className="flex items-center space-x-2 mb-2">
                <div className="relative flex-grow">
                  <input
                    ref={searchInputRef}
                    type="text"
                    className={`w-full px-2 py-1 text-xs border rounded-md pl-2 pr-2 focus:outline-none focus:ring-1 focus:ring-slate-400 ${
                      isDarkMode
                        ? 'bg-[#121212] border-[#333333] text-gray-200 placeholder-gray-500'
                        : 'bg-slate-50 border-slate-200 text-slate-700 placeholder-slate-400'
                    }`}
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <button
                  onClick={() => handleAudienceSelect(DEFAULT_AUDIENCE)}
                  className={`p-1 rounded-full ${
                    isDarkMode 
                      ? 'hover:bg-[#2A2A2A]' 
                      : 'hover:bg-slate-100'
                  }`}
                  title="Reset to Default"
                >
                  <ResetIcon className={`h-4 w-4 ${
                    isDarkMode ? 'text-gray-400' : 'text-slate-500'
                  }`} />
                </button>
              </div>
              <div className={`${
                isDarkMode ? 'border-slate-700' : 'border-gray-200'
              }`}>
                {filteredAudiences.length === 0 && searchTerm ? (
                  <div className="p-3 text-left">
                    <p className="text-xs text-slate-500 dark:text-slate-400">
                      No results for "<span className="font-medium">{searchTerm}</span>"
                    </p>
                  </div>
                ) : filteredAudiences.length === 0 ? (
                  <div className="p-3">
                    <div className="text-left mb-2">
                      <p className={`text-xs font-medium ${
                        isDarkMode ? 'text-gray-200' : 'text-gray-900'
                      }`}>
                        Start with an Audience
                      </p>
                      <p className={`text-[11px] ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-600'
                      }`} style={{ lineHeight: '1.2', marginTop: '0.5rem' }}>
                        Create an audience to personalize your experience
                      </p>
                    </div>
                    <div className="space-y-1.5">
                      <SkeletonLoader />
                      <SkeletonLoader />
                    </div>
                  </div>
                ) : (
                  <div className="max-h-[120px] overflow-y-auto custom-scrollbar">
                    {filteredAudiences.map((audience, index) => (
                      <motion.div key={audience._id} variants={listItemVariants}>
                        <CompactTargetAudienceCard
                          audience={audience}
                          onAudienceClick={handleAudienceSelect}
                          isSelected={focusedIndex === index}
                        />
                      </motion.div>
                    ))}
                  </div>
                )}
              </div>
              <div className="pt-2">
                <motion.div variants={listItemVariants}>
                  <button
                    className={`flex items-center w-full px-2 py-1 text-xs rounded-md transition-colors duration-200 ${
                      isDarkMode
                        ? 'text-gray-300 hover:bg-[#2A2A2A]'
                        : 'text-slate-700 hover:bg-slate-100'
                    } ${
                      focusedIndex === filteredAudiences.length
                        ? isDarkMode ? 'bg-[#2A2A2A]' : 'bg-slate-100'
                        : ''
                    }`}
                    onClick={handleCreateNewAudience}
                  >
                    <PlusIcon className="h-3 w-3 mr-1" />
                    <span className="font-medium">Create Audience</span>
                  </button>
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TargetAudienceDropdown;