'use client'

import React, { useState, useEffect, useCallback } from 'react';
import { UserPlusIcon, PencilIcon, TrashIcon, CpuChipIcon, MagnifyingGlassIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import api from '../api';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '../lib/utils';
import SkeletonLoader from './SkeletonLoader';

const NoResultsMessage = ({ searchTerm, onCreateClick, isDarkMode }) => (
  <div className={`text-center py-10 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`}>
    <p className="text-base mb-4">No Brand Agents found for "{searchTerm}"</p>
    <button
      onClick={onCreateClick}
      className={`
        inline-flex items-center justify-center rounded-md text-sm font-medium
        h-9 px-4 py-2
        transition-colors
        focus-visible:outline-none focus-visible:ring-1
        disabled:pointer-events-none disabled:opacity-50
        ${isDarkMode 
          ? 'bg-slate-800 text-slate-50 hover:bg-slate-800/90 focus-visible:ring-slate-700' 
          : 'bg-slate-900 text-slate-50 hover:bg-slate-900/90 focus-visible:ring-slate-950'}
      `}
    >
      <UserPlusIcon className="w-4 h-4 mr-2" />
      Create New AI Agent
    </button>
  </div>
);

const EmptyState = ({ isDarkMode, onCreateClick }) => (
  <div className="h-full flex items-center justify-center">
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
      className={`
        flex flex-col items-center justify-center p-5 rounded-xl
        backdrop-blur-xl backdrop-saturate-150 w-[260px]
        ${isDarkMode 
          ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
          : 'bg-white/90 border border-black/5 shadow-black/10'}
      `}
    >
      <div className={`
        p-2.5 rounded-full mb-3
        ${isDarkMode 
          ? 'bg-[#252525] text-blue-400/80' 
          : 'bg-slate-100 text-blue-600/80'}
      `}>
        <CpuChipIcon className="w-5 h-5" />
      </div>
      
      <div className="text-center w-full">
        <motion.h3 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className={`text-sm font-medium mb-1 tracking-tight
            ${isDarkMode ? 'text-white' : 'text-slate-900'}
          `}
        >
          Create your first AI agent
        </motion.h3>
        
        <motion.p 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className={`text-xs mb-3
            ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
          `}
        >
          Get started by creating a new AI agent
        </motion.p>
        
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={onCreateClick}
          className={`
            relative group inline-flex items-center gap-1.5
            px-3 py-1.5 rounded-lg text-xs font-medium
            transition-all duration-200
            ${isDarkMode 
              ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
              : 'bg-slate-900 hover:bg-slate-800 text-white'}
          `}
        >
          <UserPlusIcon className="w-3 h-3" />
          <span>New AI Agent</span>
        </motion.button>
      </div>
    </motion.div>
  </div>
);

const AIAgentsListPopup = ({ isOpen, onClose, onSelectAgent }) => {
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const { isDarkMode } = useTheme();
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 639 });
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });

  const fetchAgents = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/ai-agents');
      setAgents(response.data);
    } catch (error) {
      console.error('Error fetching brand agents:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isInitialFetchDone) {
      fetchAgents().then(() => setIsInitialFetchDone(true));
    }
  }, [fetchAgents, isInitialFetchDone]);

  const handleEdit = (agentId) => {
    navigate(`/agents/edit/${agentId}`);
    onClose(); // Close the popup after navigating
  };

  const handleDeleteClick = (agent) => {
    setAgentToDelete(agent);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (!agentToDelete || !agentToDelete._id) {
      console.error('No agent to delete or invalid agent ID');
      return;
    }

    try {
      console.log('Deleting agent with ID:', agentToDelete._id);
      
      const response = await api.delete(`/api/ai-agents/${agentToDelete._id}`);
      console.log('Delete response:', response.data);

      // Update the local state by filtering out the deleted agent
      setAgents(prevAgents => prevAgents.filter(agent => agent._id !== agentToDelete._id));
      setFilteredAgents(prevFiltered => prevFiltered.filter(agent => agent._id !== agentToDelete._id));

      // Close the modal and reset the agentToDelete
      setShowDeleteModal(false);
      setAgentToDelete(null);

      // Show success notification
      if (window.addNotification) {
        window.addNotification('AI Agent deleted successfully', 'success');
      }
    } catch (error) {
      console.error('Error deleting AI agent:', error);
      console.error('Error details:', error.response?.data);
      
      // Show appropriate error message
      const errorMessage = error.response?.data?.error || 
        (error.response?.status === 404 
          ? 'AI Agent not found or already deleted' 
          : 'Failed to delete AI agent. Please try again.');
      
      if (window.addNotification) {
        window.addNotification(errorMessage, 'error');
      }

      // Close the modal if the agent doesn't exist anymore
      if (error.response?.status === 404) {
        setShowDeleteModal(false);
        setAgentToDelete(null);
        // Refresh the agents list to ensure UI is in sync
        fetchAgents();
      }
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setAgentToDelete(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (agents.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = agents.filter(agent =>
        agent.name.toLowerCase().includes(lowercasedFilter) ||
        agent.description.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredAgents(filtered);
    }
  }, [searchTerm, agents]);

  const handleCreateAgent = () => {
    navigate('/agents/create');
    onClose();
  };

  const handleAgentSelect = (agent) => {
    onSelectAgent(agent);
    onClose();
  };

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${isDarkMode ? 'bg-black/50' : 'bg-black/30'}`}>
      <div className={`
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white'} 
        w-full h-full 
        flex flex-col 
        overflow-hidden
        shadow-lg
        animate-in fade-in-0 zoom-in-95
      `}>
        {/* Header */}
        <div className={`
          ${isDarkMode ? 'bg-[#151515]/80 border-[#333333]' : 'bg-white/95 border-gray-200'} 
          h-12 min-h-12 flex-shrink-0 flex items-center justify-between px-4
          border-b backdrop-blur-xl
          sticky top-0 z-50
        `}>
          <div className="flex items-center space-x-2">
            <div className={cn(
              "w-8 h-8 rounded-lg flex items-center justify-center",
              isDarkMode ? "bg-[#252525]" : "bg-gray-100"
            )}>
              <CpuChipIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`} />
            </div>
            <h2 className={`text-sm font-medium tracking-tight ${isDarkMode ? 'text-slate-100' : 'text-gray-900'}`}>
              Brand Agents
            </h2>
          </div>
          <button 
            onClick={onClose}
            className={`
              p-2 rounded-lg transition-all duration-200
              ${isDarkMode 
                ? "text-slate-400 hover:text-slate-200 hover:bg-white/5 active:bg-white/10" 
                : "text-gray-500 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200"
              }
            `}
            title="Back to Chat"
          >
            <ArrowLeftIcon className="w-4 h-4" />
          </button>
        </div>

        {/* Content */}
        <div className={cn(
          "flex-grow overflow-y-auto",
          isDarkMode ? "bg-[#121212]" : "bg-gray-50"
        )}>
          {isLoading ? (
            <div className="px-4 py-3">
              <SkeletonLoader 
                isDarkMode={isDarkMode} 
                count={5} 
                variant="default"
                iconClassName="w-8 h-8"
              />
            </div>
          ) : filteredAgents.length > 0 ? (
            <div className="space-y-2 p-3">
              {filteredAgents.map((agent) => (
                <button
                  key={agent._id}
                  onClick={() => handleAgentSelect(agent)}
                  className={`w-full text-left ${
                    isDarkMode 
                      ? 'bg-gradient-to-r from-slate-800 to-slate-700 text-slate-200 hover:from-slate-700 hover:to-slate-600' 
                      : 'bg-gradient-to-r from-slate-100 to-white text-slate-700 hover:from-slate-50 hover:to-slate-100 border border-slate-200/60'
                  } transition-all duration-300 ease-in-out transform hover:scale-[1.01] shadow-sm rounded-md`}
                >
                  <div className="px-3 py-2">
                    {/* Header Section */}
                    <div className="flex justify-between items-center mb-1.5">
                      <div className="flex items-center flex-grow">
                        {agent.avatarUrl ? (
                          <img 
                            src={agent.avatarUrl} 
                            alt={`${agent.name} avatar`}
                            className={`h-5 w-5 rounded-full mr-2 flex-shrink-0 ${isDarkMode ? 'border border-slate-600' : 'border border-slate-200'}`}
                            onError={(e) => {
                              e.target.src = 'fallback-avatar.png';
                              e.target.onerror = null;
                            }}
                          />
                        ) : (
                          <CpuChipIcon className={`h-5 w-5 mr-2 flex-shrink-0 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} />
                        )}
                        <div className="flex flex-col">
                          <h3 className="text-xs font-medium truncate leading-tight">{agent.name}</h3>
                          {agent.categories && agent.categories.length > 0 && (
                            <span className={`text-[10px] truncate ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
                              {agent.categories[0]}{agent.categories.length > 1 ? ` +${agent.categories.length - 1}` : ''}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center space-x-1 ml-2">
                        <button
                          onClick={(e) => { e.stopPropagation(); handleEdit(agent._id); }}
                          className={`p-1 rounded-md transition-colors duration-200 
                            ${isDarkMode 
                              ? 'hover:bg-slate-600/50' 
                              : 'hover:bg-slate-200/50'}`}
                          title="Edit AI Agent"
                        >
                          <PencilIcon className="w-4 h-4" />
                        </button>
                        <button
                          onClick={(e) => { e.stopPropagation(); handleDeleteClick(agent); }}
                          className={`p-1 rounded-md transition-colors duration-200 
                            ${isDarkMode 
                              ? 'text-red-400 hover:text-red-300 hover:bg-red-500/10' 
                              : 'text-red-500 hover:text-red-600 hover:bg-red-50'}`}
                          title="Delete AI Agent"
                        >
                          <TrashIcon className="w-4 h-4" />
                        </button>
                      </div>
                    </div>

                    {/* Description Section */}
                    {agent.description && (
                      <p className={`text-[10px] line-clamp-2 leading-tight ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
                        {agent.description}
                      </p>
                    )}

                    {/* Stats Section */}
                    <div className="flex items-center justify-between mt-1.5 text-[10px]">
                      <div className="flex items-center space-x-2">
                        {agent.trainingExamples?.length > 0 && (
                          <span className={`${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} title="Training examples">
                            {agent.trainingExamples.length} examples
                          </span>
                        )}
                        {agent.systemInstructions && (
                          <span className={`flex items-center ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} title="Has system instructions">
                            <CpuChipIcon className="h-3 w-3 mr-0.5" />
                            Configured
                          </span>
                        )}
                      </div>
                      {agent.lastUsed && (
                        <span className={`truncate ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} title="Last used">
                          Last: {new Date(agent.lastUsed).toLocaleDateString()}
                        </span>
                      )}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          ) : searchTerm ? (
            <NoResultsMessage 
              searchTerm={searchTerm}
              onCreateClick={handleCreateAgent}
              isDarkMode={isDarkMode}
            />
          ) : (
            <EmptyState 
              isDarkMode={isDarkMode}
              onCreateClick={handleCreateAgent}
            />
          )}
        </div>

        {/* Footer */}
        <div className={cn(
          "px-4 py-3 border-t sticky bottom-0",
          isDarkMode 
            ? "bg-[#151515]/80 border-[#333333]" 
            : "bg-white/80 border-gray-200",
          "backdrop-blur-xl"
        )}>
          <div className="flex items-center justify-between">
            <span className={cn(
              "text-xs",
              isDarkMode ? "text-slate-400" : "text-gray-500"
            )}>
              {filteredAgents.length} agent{filteredAgents.length !== 1 ? 's' : ''}
            </span>
            <button
              onClick={handleCreateAgent}
              className={cn(
                "inline-flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm font-medium",
                "transition-all duration-200",
                isDarkMode 
                  ? "bg-[#1E1E1E] hover:bg-[#252525] text-slate-200 border border-[#333333]" 
                  : "bg-gray-900 hover:bg-gray-800 text-white"
              )}
            >
              <UserPlusIcon className="w-4 h-4" />
              New Agent
            </button>
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        {showDeleteModal && (
          <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
            onClick={cancelDelete}
          >
            <div
              className={`w-[400px] ${
                isDarkMode 
                  ? 'bg-gradient-to-b from-slate-800 to-slate-800/95 border border-slate-700' 
                  : 'bg-gradient-to-b from-white to-slate-50/95 border border-slate-200/60'
              } rounded-lg shadow-lg backdrop-blur-sm transform transition-all duration-200 ease-in-out animate-in fade-in-0 zoom-in-95`}
              onClick={e => e.stopPropagation()}
            >
              {/* Header */}
              <div className={`px-6 py-4 border-b ${isDarkMode ? 'border-slate-700' : 'border-slate-200'}`}>
                <div className="flex items-center space-x-3">
                  <div className={`p-2 rounded-full ${
                    isDarkMode ? 'bg-red-500/10' : 'bg-red-50'
                  }`}>
                    <TrashIcon className={`w-5 h-5 ${
                      isDarkMode ? 'text-red-400' : 'text-red-500'
                    }`} />
                  </div>
                  <div>
                    <h3 className={`text-base font-semibold ${
                      isDarkMode ? 'text-slate-200' : 'text-slate-900'
                    }`}>
                      Delete AI Agent
                    </h3>
                    <p className={`text-sm mt-0.5 ${
                      isDarkMode ? 'text-slate-400' : 'text-slate-600'
                    }`}>
                      Are you sure you want to delete "{agentToDelete?.name}"?
                    </p>
                  </div>
                </div>
              </div>

              {/* Content */}
              <div className="px-6 py-4">
                <p className={`text-sm ${
                  isDarkMode ? 'text-slate-300' : 'text-slate-600'
                }`}>
                  This action cannot be undone. This will permanently delete the AI agent
                  and remove all associated data.
                </p>
              </div>

              {/* Footer */}
              <div className={`px-6 py-4 border-t ${
                isDarkMode ? 'border-slate-700' : 'border-slate-200'
              } flex justify-end space-x-3`}>
                <button
                  onClick={cancelDelete}
                  className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200 
                    ${isDarkMode 
                      ? 'bg-slate-700 text-slate-200 hover:bg-slate-600' 
                      : 'bg-slate-100 text-slate-700 hover:bg-slate-200'}`}
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDelete}
                  className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors duration-200 
                    ${isDarkMode 
                      ? 'bg-red-500/10 text-red-400 hover:bg-red-500/20' 
                      : 'bg-red-500 text-white hover:bg-red-600'}`}
                >
                  Delete Agent
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AIAgentsListPopup;