import axios from 'axios';
import jwtDecode from 'jwt-decode';

const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://api.ammmplify.com/api'  // Production API URL
  : process.env.REACT_APP_API_URL || 'http://localhost:3001/api'; // Development fallback

class LinkedInScheduleService {
  constructor() {
    this.api = axios.create({
      baseURL: `${API_BASE_URL}/linkedin`,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    // Add request interceptor to include auth token
    this.api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          // Get user ID from token
          try {
            const decoded = jwtDecode(token);
            if (decoded?.userId) {
              // Add userId as query parameter for GET requests
              if (config.method === 'get') {
                config.params = { ...config.params, userId: decoded.userId };
              }
              // Add userId in body for other requests
              else if (['post', 'put', 'patch', 'delete'].includes(config.method)) {
                config.data = { ...config.data, userId: decoded.userId };
              }
            }
          } catch (error) {
            console.error('Error decoding token:', error);
          }
        }
        // Add error handling for the API URL
        if (!config.baseURL) {
          console.error('API URL not configured properly');
          throw new Error('API configuration error');
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Add response interceptor for better error handling
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.code === 'ERR_NETWORK') {
          console.error('Network error details:', {
            baseURL: this.api.defaults.baseURL,
            environment: process.env.NODE_ENV,
            error: error.message
          });
        }
        return Promise.reject(error);
      }
    );
  }

  async getScheduledPosts() {
    try {
      const response = await this.api.get('/scheduled-posts');
      return response.data;
    } catch (error) {
      console.error('Error fetching scheduled posts:', error);
      throw error;
    }
  }

  async getUnscheduledPosts() {
    try {
      const response = await this.api.get('/unscheduled-posts');
      return response.data;
    } catch (error) {
      console.error('Error fetching unscheduled posts:', error);
      throw error;
    }
  }

  async schedulePost(postData) {
    try {
      const response = await this.api.post('/schedule', postData);
      return response.data;
    } catch (error) {
      console.error('Error scheduling post:', error);
      throw error;
    }
  }

  async updatePostStatus(postId, status) {
    try {
      const response = await this.api.patch(`/status/${postId}`, { status });
      return response.data;
    } catch (error) {
      console.error('Error updating post status:', error);
      throw error;
    }
  }

  async deleteScheduledPost(postId) {
    try {
      await this.api.delete(`/${postId}`);
    } catch (error) {
      console.error('Error deleting scheduled post:', error);
      throw error;
    }
  }
}

export default new LinkedInScheduleService(); 