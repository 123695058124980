import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './animationsforcopyicon.css';
import App from './App';
import './styles/markdown.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';

// Set document title
document.title = 'Ammmplify: Hyper-personalized AI Writing Interface for LinkedIn Creators';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
