import React from 'react';
import { useTheme } from '../context/ThemeContext';
import { cn } from '../utils/cn';

const WelcomeScreenSkeleton = () => {
  const { isDarkMode } = useTheme();

  // Enhanced shimmer animation with better performance
  const shimmerClass = `
    relative overflow-hidden
    before:absolute before:inset-0
    before:-translate-x-full
    before:animate-[shimmer_2s_infinite]
    before:bg-gradient-to-r
    ${isDarkMode
      ? 'before:from-transparent before:via-[#3b82f615] before:to-transparent'
      : 'before:from-transparent before:via-[#60a5fa15] before:to-transparent'
    }
  `;

  const renderFeatureSkeleton = () => (
    <button className={cn(
      "p-3 rounded-lg w-full text-left transition-all cursor-default",
      isDarkMode 
        ? "bg-[#1e1e1e]/30 hover:bg-[#252525]/30 border-white/[0.02]" 
        : "bg-slate-50/50 hover:bg-slate-100/50 border-slate-200/20",
      "border",
      shimmerClass
    )}>
      <div className="flex items-start gap-2">
        {/* Icon placeholder with pulse animation */}
        <div className={cn(
          "flex-shrink-0 w-4 h-4 mt-0.5 rounded-md animate-pulse",
          isDarkMode ? "bg-[#3b82f610]" : "bg-blue-100/50"
        )} />
        
        <div>
          {/* Title placeholder */}
          <div className={cn(
            "h-3 rounded-md w-24",
            isDarkMode ? "bg-[#3b82f610]" : "bg-blue-100/50"
          )} />
          
          {/* Description placeholder */}
          <div className={cn(
            "h-2 w-36 rounded-md mt-0.5",
            isDarkMode ? "bg-[#3b82f608]" : "bg-blue-50/50"
          )} />
        </div>
      </div>
    </button>
  );

  return (
    <div className={cn(
      "flex flex-col h-full justify-center py-6",
      isDarkMode ? "text-gray-200" : "text-gray-600"
    )}>
      <div className="max-w-xl w-full mx-auto px-4">
        {/* Header Section */}
        <div className="text-left mb-6">
          {/* Welcome text placeholder */}
          <div className={cn(
            "h-6 w-48 rounded mb-1",
            isDarkMode ? "bg-[#3b82f615]" : "bg-blue-100/50",
            shimmerClass
          )} />
          
          {/* Subtitle placeholder */}
          <div className={cn(
            "h-8 w-full max-w-md rounded",
            isDarkMode ? "bg-[#3b82f610]" : "bg-blue-50/50",
            shimmerClass
          )} />
        </div>

        {/* Features Grid */}
        <div className={cn(
          "grid gap-3 w-full",
          window.innerWidth < 768 ? "grid-cols-1" : "grid-cols-2"
        )}>
          {[...Array(window.innerWidth < 768 ? 4 : 6)].map((_, i) => (
            <div 
              key={i} 
              className="w-full"
              style={{
                animation: `fadeSlideIn 0.6s ease-out ${i * 0.1}s both`
              }}
            >
              {renderFeatureSkeleton()}
            </div>
          ))}
        </div>
      </div>

      {/* Add keyframes for animations */}
      <style jsx="true">{`
        @keyframes fadeSlideIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes shimmer {
          0% {
            transform: translateX(-100%);
          }
          50%, 100% {
            transform: translateX(100%);
          }
        }
      `}</style>
    </div>
  );
};

export default WelcomeScreenSkeleton; 