import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MicrophoneIcon, StopIcon, PauseIcon, PlayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { motion, AnimatePresence } from 'framer-motion';
import Notification from './Notification';
import { useTheme } from '../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { cn } from '../lib/utils';
import { v4 as uuidv4 } from 'uuid';
import api from '../api';
import AnimatedLoader from './common/AnimatedLoader';

// Add animation variants
const containerVariants = {
  idle: { scale: 1 },
  recording: { scale: 1.02, transition: { duration: 0.2 } }
};

const microphoneVariants = {
  idle: { scale: 1 },
  recording: {
    scale: [1, 1.1, 1],
    transition: {
      repeat: Infinity,
      duration: 1.5,
      ease: "easeInOut"
    }
  }
};

const buttonVariants = {
  initial: { scale: 0.9, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.9, opacity: 0 },
  hover: { scale: 1.05 }
};

const timeVariants = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 }
};

// Constants for IndexedDB
const DB_NAME = 'voiceRecordings';
const DB_VERSION = 3;
const STORE_NAME = 'recordings';
const MAX_RECORDING_TIME = 20 * 60; // 20 minutes in seconds
const CHUNK_INTERVAL = 1000; // Get data every second
const TEMP_STORE_INTERVAL = 5000; // Store temp recording every 5 seconds
const MAX_STORAGE_PERCENTAGE = 90; // Maximum storage usage percentage
const MAX_RETRIES = 3; // Maximum number of retries for IndexedDB operations

const AudioRecorder = ({ 
  onRecordingComplete, 
  isLoading, 
  setProcessingStatus,
  startRecordingOnMount
}) => {
  const { isDarkMode } = useTheme();
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingStage, setProcessingStage] = useState(null);
  const [showTranscriptionBox, setShowTranscriptionBox] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const [availableWidth, setAvailableWidth] = useState(400);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const isRecordingRef = useRef(false);
  const [isDiscarded, setIsDiscarded] = useState(false);
  const isDiscardedRef = useRef(false);
  const [uploadError, setUploadError] = useState(null);
  const [storedRecordingId, setStoredRecordingId] = useState(null);
  const [isRetrying, setIsRetrying] = useState(false);
  const [isApproachingLimit, setIsApproachingLimit] = useState(false);
  const [isAutoSwitching, setIsAutoSwitching] = useState(false);
  const currentChunkRef = useRef(1);
  const lastChunkTimeRef = useRef(0);
  const [chunkNumber, setChunkNumber] = useState(0);
  const [isBackgroundRecording, setIsBackgroundRecording] = useState(false);
  const [isScreenLocked, setIsScreenLocked] = useState(false);
  const wakeLockRef = useRef(null);
  const audioContextRef = useRef(null);
  const streamRef = useRef(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const visibilityChangeTimeRef = useRef(null);
  const MAX_VISIBILITY_CHANGE_DURATION = 5 * 60 * 1000; // 5 minutes

  // Add mobile and iOS detection
  useEffect(() => {
    const checkDevice = () => {
      const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isMobileDevice = window.innerWidth < 768 || isIOSDevice;
      setIsIOS(isIOSDevice);
      setIsMobile(isMobileDevice);
    };
    
    checkDevice();
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  // Add wake lock request function
  const requestWakeLock = async () => {
    try {
      if ('wakeLock' in navigator) {
        wakeLockRef.current = await navigator.wakeLock.request('screen');
        console.log('Wake Lock is active');
      }
    } catch (err) {
      console.error('Wake Lock error:', err);
    }
  };

  // Add wake lock release function
  const releaseWakeLock = () => {
    if (wakeLockRef.current) {
      wakeLockRef.current.release()
        .then(() => {
          wakeLockRef.current = null;
          console.log('Wake Lock released');
        })
        .catch((err) => console.error('Wake Lock release error:', err));
    }
  };

  // Handle visibility change
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'hidden') {
        visibilityChangeTimeRef.current = Date.now();
        
        if (isRecording && !isPaused) {
          console.log('Screen locked/app hidden while recording, pausing...');
          setIsPaused(true);
          try {
            if (mediaRecorderRef.current?.state === 'recording') {
              mediaRecorderRef.current.pause();
            }
          } catch (error) {
            console.error('Error pausing recorder:', error);
          }
        }
      } else if (document.visibilityState === 'visible') {
        const hiddenDuration = Date.now() - (visibilityChangeTimeRef.current || 0);
        
        if (isRecording && isPaused) {
          if (hiddenDuration > MAX_VISIBILITY_CHANGE_DURATION) {
            console.log('App was hidden for too long, stopping recording...');
            stopRecording();
            addNotification('Recording stopped due to long inactivity', 'warning');
          } else {
            console.log('Screen unlocked/app visible, resuming...');
            try {
              if (audioContextRef.current?.state === 'suspended') {
                await audioContextRef.current.resume();
              }
              
              if (!streamRef.current || streamRef.current.getTracks()[0]?.readyState === 'ended') {
                console.log('Stream ended, restarting recording...');
                await restartRecording();
              } else if (mediaRecorderRef.current?.state === 'paused') {
                mediaRecorderRef.current.resume();
                setIsPaused(false);
              }
            } catch (error) {
              console.error('Error resuming recording:', error);
              stopRecording();
              addNotification('Could not resume recording. Please try again.', 'error');
            }
          }
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [isRecording, isPaused]);

  // Add audio interruption handlers
  useEffect(() => {
    const handleAudioInterruption = async () => {
      if (isRecording && !isPaused) {
        console.log('Audio interrupted, pausing recording...');
        togglePause();
      }
    };

    // iOS specific events
    document.addEventListener('pause', handleAudioInterruption);
    document.addEventListener('resign', handleAudioInterruption);
    
    // Android specific events
    if ('ondevicelight' in window) {
      window.addEventListener('devicelight', handleAudioInterruption);
    }

    return () => {
      document.removeEventListener('pause', handleAudioInterruption);
      document.removeEventListener('resign', handleAudioInterruption);
      if ('ondevicelight' in window) {
        window.removeEventListener('devicelight', handleAudioInterruption);
      }
    };
  }, [isRecording, isPaused]);

  // Add notification helper
  const addNotification = useCallback((message, type = 'error') => {
    setNotifications(prev => [...prev, { id: Date.now(), message, type }]);
  }, []);

  // Add timer functions
  const startTimer = useCallback(() => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    }
  }, []);

  const stopTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  // Add toggle pause function
  const togglePause = useCallback(() => {
    if (mediaRecorderRef.current) {
      if (isPaused) {
        mediaRecorderRef.current.resume();
        startTimer();
      } else {
        mediaRecorderRef.current.pause();
        stopTimer();
      }
      setIsPaused(!isPaused);
    }
  }, [isPaused]);

  // Add IndexedDB related functions
  const initDB = useCallback(async (retryCount = 0) => {
    const DB_NAME = 'voiceRecordings';
    const DB_VERSION = 3;
    const STORE_NAME = 'recordings';
    const MAX_RETRIES = 3;

    try {
      return await new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION);
        
        request.onerror = () => {
          const error = new Error("Error opening IndexedDB");
          error.originalError = request.error;
          reject(error);
        };
        
        request.onblocked = () => {
          console.warn('Database blocked. Please close other tabs with this site open');
          addNotification('Please close other tabs with this site open and try again.', 'warning');
        };
        
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          const transaction = event.target.transaction;
          
          if (db.objectStoreNames.contains(STORE_NAME)) {
            db.deleteObjectStore(STORE_NAME);
          }
          
          const store = db.createObjectStore(STORE_NAME, {
            keyPath: 'id',
            autoIncrement: false
          });
          store.createIndex('timestamp', 'timestamp', { unique: false });
          store.createIndex('type', 'type', { unique: false });
        };
        
        request.onsuccess = (event) => {
          const db = event.target.result;
          resolve(db);
        };
      });
    } catch (error) {
      if (retryCount < MAX_RETRIES) {
        await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
        return initDB(retryCount + 1);
      }
      throw error;
    }
  }, [addNotification]);

  // Add storage functions
  const storeRecording = useCallback(async (audioBlob, chunkId = 'final', retryCount = 0) => {
    const MAX_RETRIES = 3;
    try {
      const recordingId = chunkId === 'final' ? uuidv4() : chunkId;
      const db = await initDB();
      
      return await new Promise((resolve, reject) => {
        try {
          const transaction = db.transaction(['recordings'], 'readwrite');
          const store = transaction.objectStore('recordings');
          
          const recordingData = {
            id: recordingId,
            blob: audioBlob,
            timestamp: Date.now(),
            type: chunkId.startsWith('temp_') ? 'temp' : 
                  chunkId.startsWith('chunk_') ? 'chunk' : 'final',
            chunkNumber: chunkId.startsWith('chunk_') ? 
                        parseInt(chunkId.split('_')[1]) : null,
            size: audioBlob.size
          };
          
          const putRequest = store.put(recordingData);
          
          putRequest.onsuccess = () => resolve(recordingId);
          putRequest.onerror = (event) => reject(new Error(`Error storing recording: ${event.target.error}`));
          
          transaction.oncomplete = () => db.close();
        } catch (error) {
          reject(error);
        }
      });
    } catch (error) {
      if (retryCount < MAX_RETRIES) {
        await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
        return storeRecording(audioBlob, chunkId, retryCount + 1);
      }
      throw error;
    }
  }, [initDB]);

  const getStoredRecording = useCallback(async (recordingId) => {
    try {
      const db = await initDB();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(['recordings'], 'readonly');
        const store = transaction.objectStore('recordings');
        const getRequest = store.get(recordingId);
        
        getRequest.onsuccess = () => {
          const recordingData = getRequest.result;
          resolve(recordingData ? recordingData.blob : null);
        };
        
        getRequest.onerror = () => reject(new Error("Error retrieving recording"));
        transaction.oncomplete = () => db.close();
      });
    } catch (error) {
      console.error('Error getting stored recording:', error);
      throw error;
    }
  }, [initDB]);

  const getAllStoredChunks = useCallback(async () => {
    try {
      const db = await initDB();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(['recordings'], 'readonly');
        const store = transaction.objectStore('recordings');
        const index = store.index('type');
        const chunks = [];
        
        const cursorRequest = index.openCursor(IDBKeyRange.only('chunk'));
        
        cursorRequest.onsuccess = (e) => {
          const cursor = e.target.result;
          if (cursor) {
            chunks.push(cursor.value);
            cursor.continue();
          } else {
            chunks.sort((a, b) => a.chunkNumber - b.chunkNumber);
            resolve(chunks);
          }
        };
        
        cursorRequest.onerror = () => reject(new Error("Error retrieving chunks"));
        transaction.oncomplete = () => db.close();
      });
    } catch (error) {
      console.error('Error getting stored chunks:', error);
      throw error;
    }
  }, [initDB]);

  const removeStoredRecording = useCallback(async (recordingId) => {
    try {
      const db = await initDB();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(['recordings'], 'readwrite');
        const store = transaction.objectStore('recordings');
        const deleteRequest = store.delete(recordingId);
        
        deleteRequest.onsuccess = () => resolve();
        deleteRequest.onerror = () => reject(new Error("Error deleting recording"));
        transaction.oncomplete = () => db.close();
      });
    } catch (error) {
      console.error('Error removing stored recording:', error);
      throw error;
    }
  }, [initDB]);

  const cleanupOldRecordings = useCallback(async () => {
    const TWO_DAYS = 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds
    
    try {
      const db = await initDB();
      const transaction = db.transaction(['recordings'], 'readwrite');
      const store = transaction.objectStore('recordings');
      const index = store.index('timestamp');
      const oldTime = Date.now() - TWO_DAYS;
      
      const range = IDBKeyRange.upperBound(oldTime);
      const request = index.openCursor(range);
      
      request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          store.delete(cursor.primaryKey);
          cursor.continue();
        }
      };
    } catch (error) {
      console.error('Error cleaning up old recordings:', error);
    }
  }, [initDB]);

  const startRecording = async () => {
    try {
      isDiscardedRef.current = false;
      setIsDiscarded(false);
      setIsInitializing(true);
      
      if (error) {
        addNotification(error, 'error');
        return;
      }

      if (isRecordingRef.current) {
        console.log('Already recording');
        return;
      }

      // Initialize AudioContext with iOS-friendly settings
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 16000,
          latencyHint: 'interactive',
          iosLatencyHint: 0.01
        });
      }

      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }

      // Request wake lock for iOS
      await requestWakeLock();

      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          // iOS specific constraints
          googEchoCancellation: true,
          googAutoGainControl: true,
          googNoiseSuppression: true,
          googHighpassFilter: true
        }
      });

      streamRef.current = stream;

      const source = audioContextRef.current.createMediaStreamSource(stream);
      const destination = audioContextRef.current.createMediaStreamDestination();
      source.connect(destination);

      const mimeType = MediaRecorder.isTypeSupported('audio/webm;codecs=opus')
        ? 'audio/webm;codecs=opus'
        : 'audio/mp4';

      mediaRecorderRef.current = new MediaRecorder(destination.stream, {
        mimeType,
        audioBitsPerSecond: 24000
      });
      
      audioChunksRef.current = [];
      isRecordingRef.current = true;

      mediaRecorderRef.current.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        handleDiscard();
        setError('Recording error occurred. Please try again.');
      };

      mediaRecorderRef.current.ondataavailable = async (event) => {
        if (!isDiscardedRef.current && event.data.size > 0) {
          audioChunksRef.current.push(event.data);
          
          const currentTime = Date.now();
          if (currentTime - lastChunkTimeRef.current >= TEMP_STORE_INTERVAL) {
            const tempBlob = new Blob(audioChunksRef.current, { type: mimeType });
            try {
              await storeRecording(tempBlob, `temp_${currentChunkRef.current}`);
              lastChunkTimeRef.current = currentTime;
            } catch (error) {
              console.error('Error storing temp recording:', error);
            }
          }
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }
        
        if (!isDiscardedRef.current) {
          const hasAudioData = audioChunksRef.current.length > 0;
          if (hasAudioData) {
            const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
            if (isAutoSwitching) {
              await storeRecording(audioBlob, `chunk_${currentChunkRef.current}`);
              currentChunkRef.current += 1;
              setIsAutoSwitching(false);
              setTimeout(() => startRecording(), 100);
            } else {
              handleRecordingComplete(audioBlob);
            }
          }
        }
        
        audioChunksRef.current = [];
        if (!isAutoSwitching) {
          isRecordingRef.current = false;
          setIsRecording(false);
          setIsPaused(false);
        }
      };

      mediaRecorderRef.current.start(CHUNK_INTERVAL);
      setIsRecording(true);
      if (!isAutoSwitching) {
        setRecordingTime(0);
        currentChunkRef.current = 1;
      }
      
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
      
      setIsInitializing(false);
    } catch (error) {
      console.error('Error starting recording:', error);
      setError('Could not start recording. Please check microphone permissions.');
      addNotification('Error accessing microphone', 'error');
      isRecordingRef.current = false;
      releaseWakeLock();
      setIsInitializing(false);
      cleanup();
    }
  };

  const restartRecording = async () => {
    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }

      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          googEchoCancellation: true,
          googAutoGainControl: true,
          googNoiseSuppression: true,
          googHighpassFilter: true
        }
      });

      streamRef.current = stream;
      
      if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 16000,
          latencyHint: 'interactive',
          iosLatencyHint: 0.01
        });
      }

      const source = audioContextRef.current.createMediaStreamSource(stream);
      const destination = audioContextRef.current.createMediaStreamDestination();
      source.connect(destination);

      const mimeType = MediaRecorder.isTypeSupported('audio/webm;codecs=opus')
        ? 'audio/webm;codecs=opus'
        : 'audio/mp4';

      const mediaRecorder = new MediaRecorder(destination.stream, {
        mimeType,
        audioBitsPerSecond: 24000
      });

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }
        await handleRecordingComplete(audioBlob);
      };

      mediaRecorder.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        if (isRecording) {
          stopRecording();
        }
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsPaused(false);
      
      return true;
    } catch (error) {
      console.error('Error restarting recording:', error);
      return false;
    }
  };

  // Enhanced cleanup function
  const cleanup = () => {
    stopTimer();
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== 'inactive') {
        try {
          mediaRecorderRef.current.stop();
        } catch (error) {
          console.error('Error stopping recorder:', error);
        }
      }
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => {
        try {
          track.stop();
        } catch (error) {
          console.error('Error stopping track:', error);
        }
      });
      streamRef.current = null;
    }
    if (audioContextRef.current?.state !== 'closed') {
      audioContextRef.current?.close().catch(console.error);
      audioContextRef.current = null;
    }
    releaseWakeLock();
    audioChunksRef.current = [];
    setRecordingTime(0);
    setIsPaused(false);
    setIsRecording(false);
    isRecordingRef.current = false;
    setError(null);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      try {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
        setIsRecording(false);
        setIsPaused(false);
        isRecordingRef.current = false;
        releaseWakeLock();
      } catch (error) {
        console.error('Error stopping recording:', error);
        setError('Error stopping recording. Your recording may be incomplete.');
      }
    }
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
    }
  };

  const handleDiscard = () => {
    isDiscardedRef.current = true;
    setIsDiscarded(true);
    
    audioChunksRef.current = [];
    
    if (mediaRecorderRef.current) {
        if (mediaRecorderRef.current.state !== 'inactive') {
            try {
                mediaRecorderRef.current.stop();
            } catch (error) {
                console.error('Error stopping recorder:', error);
            }
        }
        
        if (mediaRecorderRef.current.stream) {
            mediaRecorderRef.current.stream.getTracks().forEach(track => {
                try {
                    track.stop();
                } catch (error) {
                    console.error('Error stopping track:', error);
                }
            });
        }
    }
    
    setIsRecording(false);
    setIsPaused(false);
    isRecordingRef.current = false;
    setRecordingTime(0);
    
    setError(null);
  };

  const handleRecordingComplete = async (audioBlob) => {
    setError(null);
    setUploadError(null);
    
    try {
      setIsProcessing(true);
      
      if (!(audioBlob instanceof Blob)) {
        console.error('Invalid audio data:', audioBlob);
        throw new Error('Invalid audio data');
      }

      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.webm');

      const response = await api.post('/api/voice-notes/transcribe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: [(data) => data],
        validateStatus: function (status) {
          return status >= 200 && status < 300;
        },
      });

      if (response.data && response.data._id) {
        await onRecordingComplete(response.data._id);
        
        if (!isMobile) {
          setShowTranscriptionBox(true);
        }
        
        setError(null);
        setUploadError(null);
      } else {
        console.error('Invalid response:', response);
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error handling recording:', error);
      setUploadError('Error processing recording. Please try again.');
      addNotification('Error processing recording. Please try again.', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRetryUpload = async () => {
    if (!storedRecordingId) return;
    
    setIsRetrying(true);
    setUploadError(null);
    
    try {
      // Get the final recording and any stored chunks
      const audioBlob = await getStoredRecording(storedRecordingId);
      const storedChunks = await getAllStoredChunks();
      
      if (!audioBlob && storedChunks.length === 0) {
        throw new Error('No stored recordings found');
      }
      
      let finalBlob = audioBlob;
      if (storedChunks.length > 0) {
        // If we have chunks, combine them
        const allBlobs = audioBlob 
          ? [...storedChunks.map(chunk => chunk.blob), audioBlob]
          : storedChunks.map(chunk => chunk.blob);
        finalBlob = new Blob(allBlobs, { type: 'audio/webm' });
      }
      
      await uploadRecording(finalBlob, storedRecordingId);
    } catch (error) {
      console.error('Error retrying upload:', error);
      setUploadError('Failed to upload recording. Click to retry.');
    } finally {
      setIsRetrying(false);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setAvailableWidth(entry.contentRect.width);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const isNarrow = availableWidth < 350; // Adjust this threshold as needed

  useEffect(() => {
    if (isRecording && !isPaused) {
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime + 1;
          
          // Show warning when approaching limit
          if (newTime >= MAX_RECORDING_TIME - 30 && newTime < MAX_RECORDING_TIME) {
            setIsApproachingLimit(true);
          }
          
          // Auto-switch to new recording at limit
          if (newTime >= MAX_RECORDING_TIME) {
            setIsAutoSwitching(true);
            setIsApproachingLimit(false);
            stopRecording();
            return 0; // Reset timer for next chunk
          }
          
          return newTime;
        });
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isRecording, isPaused]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current) {
        if (mediaRecorderRef.current.state !== 'inactive') {
          try {
            mediaRecorderRef.current.stop();
          } catch (error) {
            console.error('Error stopping recorder:', error);
          }
        }
        if (mediaRecorderRef.current.stream) {
          mediaRecorderRef.current.stream.getTracks().forEach(track => {
            try {
              track.stop();
            } catch (error) {
              console.error('Error stopping track:', error);
            }
          });
        }
      }
      releaseWakeLock();
      audioChunksRef.current = [];
      clearInterval(timerRef.current);
    };
  }, []);

  // Add warning message for approaching limit
  const renderTimeWarning = () => {
    if (!isApproachingLimit) return null;
    
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        className={cn(
          "rounded-lg px-4 py-3 text-sm mb-4",
          "flex items-center justify-between",
          isDarkMode 
            ? "bg-yellow-900/20 text-yellow-400 border border-yellow-800"
            : "bg-yellow-50 text-yellow-600 border border-yellow-200"
        )}
      >
        <span>Approaching 20-minute limit. Recording will automatically continue in a new chunk.</span>
      </motion.div>
    );
  };

  // Add visibility change handler
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && isRecording) {
        setIsBackgroundRecording(true);
      } else if (!document.hidden && isBackgroundRecording) {
        setIsBackgroundRecording(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isRecording, isBackgroundRecording]);

  // Add background recording indicator
  useEffect(() => {
    if (isBackgroundRecording) {
      // You might want to show a notification or update UI to indicate background recording
      console.log('Recording continuing in background...');
    }
  }, [isBackgroundRecording]);

  const uploadRecording = async (blob, recordingId) => {
    try {
      // Create a File object from the blob with a specific name
      const audioFile = new File([blob], 'audio.webm', { 
        type: blob.type || 'audio/webm',
        lastModified: Date.now()
      });

      const formData = new FormData();
      formData.append('audio', audioFile, 'audio.webm');
      if (recordingId) formData.append('recordingId', recordingId);

      console.log('Uploading audio file:', audioFile);
      
      const response = await api.post('/api/voice-notes/transcribe', formData, {
        headers: { 
          'Content-Type': 'multipart/form-data'
        },
        validateStatus: null,
        // Add timeout and size limits
        timeout: 30000,
        maxContentLength: 10 * 1024 * 1024 // 10MB limit
      });

      if (!response.data) {
        console.error('Empty response from server');
        throw new Error('No response from server');
      }

      if (response.status !== 200) {
        console.error('Server error:', response.data);
        throw new Error(response.data?.message || 'Server error');
      }

      if (!response.data._id) {
        console.error('Invalid response format:', response.data);
        throw new Error('Invalid server response');
      }

      await onRecordingComplete(response.data._id);
      if (!isMobile) setShowTranscriptionBox(true);
      return response.data;
    } catch (error) {
      console.error('Error uploading recording:', error);
      const message = error.response?.data?.message || error.message || 'Upload failed';
      throw new Error(message);
    }
  };

  return (
    <div className="w-full space-y-4 mb-6">
      {error && (
        <motion.div 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="rounded-lg border border-destructive/50 bg-destructive/10 px-4 py-3 text-sm text-destructive mb-4"
        >
          {error}
        </motion.div>
      )}
      
      {renderTimeWarning()}
      
      {notifications.map((notification, index) => (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          onClose={() => setNotifications(prev => prev.filter(n => n.id !== notification.id))}
          duration={3000}
          currentIndex={index + 1}
          totalCount={notifications.length}
        />
      ))}

      <div className="flex justify-center items-center mb-4">
        <motion.div 
          ref={containerRef}
          variants={containerVariants}
          initial="idle"
          animate={isRecording ? "recording" : "idle"}
          className={cn(
            "rounded-lg flex items-center gap-2 transition-all duration-200",
            "bg-white border shadow-sm",
            "hover:shadow-md hover:border-border/80",
            isRecording ? "px-3 py-2" : "p-1",
            "mb-2",
            "bg-white dark:bg-gray-950 text-gray-900 dark:text-gray-100"
          )}
        >
          <AnimatePresence mode="wait">
            {isProcessing ? (
              <motion.div
                key="processing"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex items-center justify-center w-full px-4 py-2"
              >
                <AnimatedLoader size={16} className="mr-2" />
                <span className="text-sm font-medium text-muted-foreground">
                  Processing...
                </span>
              </motion.div>
            ) : isRecording ? (
              <motion.div
                key="recording"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex items-center gap-2"
              >
                <motion.button
                  variants={buttonVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  whileHover="hover"
                  onClick={handleDiscard}
                  className={cn(
                    "inline-flex items-center justify-center",
                    "text-sm font-medium transition-colors",
                    "hover:bg-accent hover:text-accent-foreground",
                    "h-9 rounded-md px-3",
                    "text-destructive hover:bg-destructive/10"
                  )}
                >
                  Cancel
                </motion.button>

                <motion.span
                  variants={timeVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  className="font-medium text-sm tabular-nums text-foreground"
                >
                  {formatTime(recordingTime)}
                </motion.span>

                <motion.button
                  variants={buttonVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  whileHover="hover"
                  onClick={isPaused ? resumeRecording : pauseRecording}
                  className={cn(
                    "inline-flex items-center justify-center",
                    "rounded-md h-9 w-9",
                    "hover:bg-accent hover:text-accent-foreground",
                    "transition-colors"
                  )}
                >
                  {isPaused ? <PlayIcon className="w-4 h-4" /> : <PauseIcon className="w-4 h-4" />}
                </motion.button>

                <motion.button
                  variants={buttonVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  whileHover="hover"
                  onClick={stopRecording}
                  className={cn(
                    "inline-flex items-center justify-center",
                    "rounded-md px-3 h-9",
                    "bg-primary text-primary-foreground",
                    "hover:bg-primary/90",
                    "text-sm font-medium transition-colors"
                  )}
                >
                  Done
                </motion.button>
              </motion.div>
            ) : (
              <motion.button
                key="start"
                variants={buttonVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                whileHover="hover"
                onClick={startRecording}
                disabled={isLoading}
                className={cn(
                  "inline-flex items-center justify-center",
                  "rounded-md px-4 h-10 w-full",
                  "bg-primary text-primary-foreground",
                  "hover:bg-primary/90",
                  "transition-colors disabled:opacity-50",
                  "text-sm font-medium"
                )}
              >
                <motion.div
                  variants={microphoneVariants}
                  initial="idle"
                  animate={isRecording ? "recording" : "idle"}
                >
                  <MicrophoneIcon className="w-4 h-4 mr-2 text-red-400" />
                </motion.div>
                <span className="whitespace-nowrap">Record a voice note</span>
              </motion.button>
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      {uploadError && (
        <motion.div 
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={cn(
            "rounded-lg px-4 py-3 text-sm mb-4",
            "flex items-center justify-between",
            isDarkMode 
              ? "bg-red-900/20 text-red-400 border border-red-800"
              : "bg-red-50 text-red-600 border border-red-200"
          )}
        >
          <span>{uploadError}</span>
          <button
            onClick={handleRetryUpload}
            disabled={isRetrying}
            className={cn(
              "ml-4 px-3 py-1 rounded-md text-sm transition-colors",
              isDarkMode
                ? "bg-red-900/30 hover:bg-red-900/50 text-red-300"
                : "bg-red-100 hover:bg-red-200 text-red-700"
            )}
          >
            {isRetrying ? (
              <div className="flex items-center">
                <AnimatedLoader size={16} className="mr-2" />
                <span>Retrying...</span>
              </div>
            ) : (
              'Retry Upload'
            )}
          </button>
        </motion.div>
      )}
    </div>
  );
};

export default AudioRecorder;
