import React from 'react';
import { motion } from 'framer-motion';

const SkeletonCard = ({ isDarkMode, index }) => (
  <div className="animate-fade-scale-in aspect-square">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.3,
        delay: index * 0.1,
        ease: "easeOut"
      }}
      className={`h-full ${
        isDarkMode 
          ? 'bg-[#151515] border-[#333333]' 
          : 'bg-white border-gray-200'
      } rounded-lg p-4 border relative flex flex-col`}
    >
      {/* Header */}
      <div className="flex items-center justify-between mb-4">
        <div className={`h-2.5 w-20 rounded ${
          isDarkMode 
            ? 'bg-[#1a1a1a] animate-pulse' 
            : 'bg-gray-200 animate-pulse'
        }`} />
        <div className="flex items-center gap-1">
          {[1, 2].map((btn) => (
            <div
              key={btn}
              className={`h-5 w-5 rounded ${
                isDarkMode 
                  ? 'bg-[#1a1a1a]' 
                  : 'bg-gray-200'
              }`}
            />
          ))}
        </div>
      </div>

      {/* Content area */}
      <div className="flex-grow space-y-3">
        {/* Title */}
        <div className={`h-4 w-3/4 rounded ${
          isDarkMode 
            ? 'bg-[#1a1a1a] animate-pulse' 
            : 'bg-gray-200 animate-pulse'
        }`} />

        {/* Content lines */}
        {[1, 2, 3].map((line) => (
          <div
            key={line}
            className={`h-3 rounded ${
              isDarkMode 
                ? 'bg-[#1a1a1a] animate-pulse' 
                : 'bg-gray-200 animate-pulse'
            }`}
            style={{ width: `${Math.random() * 30 + 70}%` }}
          />
        ))}
      </div>

      {/* Footer with tags */}
      <div className="flex gap-2 mt-auto pt-4">
        {[1, 2].map((tag) => (
          <div
            key={tag}
            className={`h-5 rounded-full ${
              isDarkMode 
                ? 'bg-[#1a1a1a]' 
                : 'bg-gray-200'
            }`}
            style={{ width: `${Math.random() * 20 + 40}px` }}
          />
        ))}
      </div>
    </motion.div>
  </div>
);

const IdeasSkeleton = ({ isDarkMode }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {[...Array(6)].map((_, index) => (
        <SkeletonCard key={index} isDarkMode={isDarkMode} index={index} />
      ))}
    </div>
  );
};

export default IdeasSkeleton;