import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Route, Routes, useParams, useLocation } from 'react-router-dom';
import { UserGroupIcon, UserPlusIcon, MagnifyingGlassIcon, XMarkIcon, ArrowPathIcon, PlusCircleIcon, CpuChipIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import api from '../../api';
import TargetAudienceCreationWizard from './TargetAudienceCreationWizard';
import TargetAudienceOverviewPopup from './TargetAudienceOverviewPopup';
import Notification from '../Notification';
import TargetAudienceCard from './TargetAudienceCard';
import { motion, AnimatePresence } from 'framer-motion';
import { GridSkeleton } from '../common/CardSkeleton';
import { Helmet } from 'react-helmet-async';

const FALLBACK_AVATAR = '/default-avatar.png';
const REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes in milliseconds

const NoResultsMessage = ({ searchTerm, onCreateClick, onAddExamples, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <UserGroupIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center max-w-sm">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first audience'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new audience' 
          : 'Get started by creating a new target audience or add example audiences'}
      </motion.p>
      
      <div className="flex items-center gap-3 justify-center">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={onCreateClick}
          className={`
            relative group flex items-center gap-2 
            px-4 py-2 rounded-lg text-sm font-medium
            transition-all duration-200
            ${isDarkMode 
              ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
              : 'bg-slate-900 hover:bg-slate-800 text-white'}
          `}
        >
          <UserPlusIcon className="w-4 h-4" />
          <span>New Audience</span>
        </motion.button>
        
        {!searchTerm && (
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onAddExamples}
            className={`
              relative group flex items-center gap-2
              px-4 py-2 rounded-lg text-sm font-medium
              transition-all duration-200
              ${isDarkMode 
                ? 'bg-[#1e1e1e] hover:bg-[#252525] text-slate-300 border border-white/10' 
                : 'bg-slate-100 hover:bg-slate-200 text-slate-700'}
            `}
          >
            <PlusCircleIcon className="w-4 h-4" />
            <span>Add Examples</span>
          </motion.button>
        )}
      </div>
    </div>
  </motion.div>
);

const EmptyState = ({ isDarkMode, onCreateClick, searchTerm = '' }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <UserGroupIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center max-w-sm">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first audience'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new audience' 
          : 'Get started by creating a new target audience'}
      </motion.p>
      
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onCreateClick}
        className={`
          relative group flex items-center gap-2 
          px-4 py-2 rounded-lg text-sm font-medium
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
            : 'bg-slate-900 hover:bg-slate-800 text-white'}
        `}
      >
        <UserPlusIcon className="w-4 h-4" />
        <span>New Audience</span>
      </motion.button>
    </div>
  </motion.div>
);

const MobileSearchBar = ({ isDarkMode, searchTerm = '', onSearchChange, onClose }) => (
  <div className={`
    fixed inset-x-0 top-0 z-50 p-2
    ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
    border-b ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
  `}>
    <div className="relative flex items-center">
      <MagnifyingGlassIcon className={`absolute left-3 h-4 w-4 ${
        isDarkMode ? 'text-slate-400' : 'text-gray-400'
      }`} />
      <input
        type="text"
        className={`w-full pl-9 pr-8 py-2 text-sm rounded-lg
          focus:outline-none focus:ring-2
          ${isDarkMode
            ? 'bg-[#1a1a1a] text-slate-200 placeholder-slate-400 focus:ring-blue-500/30'
            : 'bg-gray-100 text-gray-900 placeholder-gray-500 focus:ring-blue-500/50'
          }
        `}
        placeholder="Search audiences..."
        value={searchTerm}
        onChange={onSearchChange}
        autoFocus
      />
      <button
        onClick={onClose}
        className={`absolute right-3 p-0.5 rounded-full ${
          isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-200'
        }`}
      >
        <XMarkIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
      </button>
    </div>
  </div>
);

const MobileMenu = ({ isDarkMode, onCreateNew }) => (
  <div className={`
    absolute right-2 top-12 z-50 mt-1 w-48 rounded-lg shadow-lg
    ${isDarkMode ? 'bg-[#1a1a1a] border border-[#333333]' : 'bg-white border border-gray-200'}
    py-1
  `}>
    <button
      onClick={onCreateNew}
      className={`w-full px-4 py-2 text-sm flex items-center gap-2
        ${isDarkMode 
          ? 'text-slate-200 hover:bg-[#252525]' 
          : 'text-gray-700 hover:bg-gray-100'}
      `}
    >
      <UserPlusIcon className="w-4 h-4" />
      Create New Audience
    </button>
  </div>
);

const AudienceList = ({ 
  audiences, 
  filteredAudiences, 
  isLoading, 
  searchTerm, 
  handleEdit, 
  handleDeleteClick, 
  handleDownload, 
  isDarkMode, 
  handleAudienceClick, 
  isPopup, 
  handleCreateClick,
  handleRestoreDefaults,
  progress,
  updateProgress
}) => {
  if (isLoading) {
    return <GridSkeleton count={6} />;
  }

  if (filteredAudiences.length === 0) {
    return (
      <NoResultsMessage 
        searchTerm={searchTerm}
        onCreateClick={handleCreateClick}
        onAddExamples={handleRestoreDefaults}
        isDarkMode={isDarkMode}
      />
    );
  }

  return (
    <motion.div 
      className={`grid grid-cols-1 sm:grid-cols-2 gap-4 ${isPopup ? '' : 'pt-4'}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <AnimatePresence>
        {filteredAudiences.map((audience) => (
          <motion.div
            key={audience._id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <TargetAudienceCard
              audience={audience}
              onEdit={handleEdit}
              onDelete={handleDeleteClick}
              onDownload={handleDownload}
              isDarkMode={isDarkMode}
              handleAudienceClick={handleAudienceClick}
              isPopup={isPopup}
              progress={progress[audience._id] || 0}
              updateProgress={(newProgress) => updateProgress(audience._id, newProgress)}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

const TargetAudiencesPage = ({ isPopup = false, onSelectAudience, showTopBar = true }) => {
  const [audiences, setAudiences] = useState([]);
  const [filteredAudiences, setFilteredAudiences] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [audienceToDelete, setAudienceToDelete] = useState(null);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [progress, setProgress] = useState({});
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDeleting, setIsDeleting] = useState(false);

  const addNotification = useCallback((title, message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, title, message, type }]);
    setTimeout(() => {
      setNotifications(prev => prev.filter(notification => notification.id !== id));
    }, 5000);
  }, []);

  const fetchAudiences = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/target-audiences');
      setAudiences(response.data);
    } catch (error) {
      console.error('Error fetching audiences:', error);
      addNotification(
        'Error Loading Audiences',
        'Failed to load audiences. Please try refreshing the page.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  }, [addNotification]);

  useEffect(() => {
    fetchAudiences();
  }, [fetchAudiences]);

  useEffect(() => {
    if (audiences.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = audiences.filter(audience => {
        const name = audience?.name || '';
        const description = audience?.description || '';
        return name.toLowerCase().includes(lowercasedFilter) ||
               description.toLowerCase().includes(lowercasedFilter);
      });
      setFilteredAudiences(filtered);
    } else {
      setFilteredAudiences([]);
    }
  }, [searchTerm, audiences]);

  const handleEdit = (audienceId) => {
    navigate(`/target-audiences/edit/${audienceId}`);
  };

  const handleDeleteClick = (audienceId) => {
    setAudienceToDelete(audienceId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (audienceToDelete) {
      setIsDeleting(true);
      try {
        await api.delete(`/api/target-audiences/${audienceToDelete}`);
        setAudiences(prev => prev.filter(audience => audience._id !== audienceToDelete));
        setShowDeleteModal(false);
        setAudienceToDelete(null);
        addNotification('Success', 'Target audience deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting audience:', error);
        addNotification('Delete Failed', 'Failed to delete target audience', 'error');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setAudienceToDelete(null);
  };

  const handleAudienceClick = (audience) => {
    if (isPopup && onSelectAudience) {
      onSelectAudience(audience);
    } else {
      setSelectedAudience(audience);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value || '';
    setSearchTerm(value);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setSearchTerm('');
    }
  };

  const handleCreateClick = () => {
    navigate('/target-audiences/create');
  };

  const closeOverviewPopup = () => {
    setSelectedAudience(null);
  };

  const handleDownload = async (audienceId) => {
    try {
      const response = await api.get(`/api/target-audiences/${audienceId}/download`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `target-audience-${audienceId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading audience:', error);
      addNotification('Download Failed', 'Failed to download audience data', 'error');
    }
  };

  const handleRestoreDefaults = async () => {
    try {
      setIsRestoring(true);
      const response = await api.post('/api/target-audiences/defaults/restore');
      if (response.data) {
        await fetchAudiences();
        addNotification(
          'Defaults Restored',
          'Default audiences have been restored successfully',
          'success'
        );
      }
    } catch (error) {
      console.error('Error restoring default audiences:', error);
      addNotification(
        'Restore Failed',
        'Failed to restore default audiences',
        'error'
      );
    } finally {
      setIsRestoring(false);
    }
  };

  const updateProgress = (audienceId, newProgress) => {
    setProgress(prev => ({
      ...prev,
      [audienceId]: newProgress
    }));
  };

  const MainContent = () => (
    <>
      {!isPopup && (
        <Helmet>
          <title>Target Audiences | Ammmplify</title>
          <meta name="description" content="Define and manage your target audiences to create highly relevant and engaging content. Understand demographics, interests, and pain points." />
          <meta name="keywords" content="target audience, audience segmentation, content strategy, audience analysis, customer personas" />
          <meta property="og:title" content="Target Audiences | Ammmplify" />
          <meta property="og:description" content="Define and manage your target audiences to create highly relevant and engaging content. Understand demographics, interests, and pain points." />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Target Audiences | Ammmplify" />
          <meta name="twitter:description" content="Define and manage your target audiences to create highly relevant and engaging content. Understand demographics, interests, and pain points." />
        </Helmet>
      )}
      <div className={`flex flex-col h-full relative overflow-hidden bg-transparent
                    ${isPopup ? 'max-h-96' : ''} 
                    min-w-[300px] w-full max-w-full
                    mx-auto transition-all duration-300 ease-in-out`}>
        {showTopBar && (
          <>
            {/* Mobile Search Bar */}
            {isSearchOpen && (
              <MobileSearchBar
                isDarkMode={isDarkMode}
                searchTerm={searchTerm}
                onSearchChange={handleSearchChange}
                onClose={toggleSearch}
              />
            )}

            {/* Top Bar - Desktop and Mobile */}
            <div className={`${isDarkMode ? 'bg-[#121212]/95 border-[#333333]' : 'bg-white bg-opacity-90 border-gray-200'} 
                            !h-10 min-h-10 flex-shrink-0 flex items-center justify-between 
                            px-3 sm:px-4 py-2 w-full border-b relative z-40`}>
              {/* Left section */}
              <div className="flex items-center">
                <UserGroupIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
                <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                  Target Audiences
                </h2>
              </div>

              {/* Right section - Desktop */}
              <div className="hidden sm:flex items-center space-x-2">
                {isSearchOpen ? (
                  <div className="relative">
                    <input
                      type="text"
                      className={`w-48 px-3 py-1 text-sm border rounded-md pl-8 
                        focus:outline-none focus:ring-2 focus:ring-blue-500
                        ${isDarkMode
                          ? 'bg-[#151515] border-[#333333] text-slate-200 placeholder-slate-400'
                          : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                        }`}
                      placeholder="Search audiences..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <MagnifyingGlassIcon className={`absolute left-2 top-1.5 h-4 w-4 ${
                      isDarkMode ? 'text-slate-400' : 'text-gray-400'
                    }`} />
                    <button
                      onClick={toggleSearch}
                      className={`absolute right-2 top-1.5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`}
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={toggleSearch}
                    className={`p-1 rounded-md ${
                      isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
                    }`}
                  >
                    <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                  </button>
                )}

                <button 
                  onClick={handleCreateClick}
                  className={`flex items-center px-3 py-2 rounded-full transition-colors duration-200 text-xs
                              ${isDarkMode 
                                ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200' 
                                : 'bg-black hover:bg-black/90 text-white'}`}
                  title="Create New Target Audience"
                >
                  <UserPlusIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-white'} mr-1`} />
                  Create New Audience
                </button>
              </div>

              {/* Right section - Mobile */}
              <div className="flex sm:hidden items-center space-x-2">
                <button
                  onClick={toggleSearch}
                  className={`p-1.5 rounded-md ${
                    isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-100'
                  }`}
                >
                  <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                </button>
                
                <button
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                  className={`p-1.5 rounded-md ${
                    isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-100'
                  }`}
                >
                  <EllipsisVerticalIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                </button>

                {showMobileMenu && (
                  <MobileMenu
                    isDarkMode={isDarkMode}
                    onCreateNew={() => {
                      setShowMobileMenu(false);
                      navigate('create');
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}

        {/* Content Area */}
        <div className={`
          flex-grow overflow-y-auto custom-scrollbar 
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white bg-opacity-80'}
          ${!isPopup && 'px-4 py-4'}
        `}>
          {isLoading ? (
            <GridSkeleton count={6} />
          ) : filteredAudiences.length > 0 ? (
            <div className={`
              grid grid-cols-1 md:grid-cols-2 gap-4
              ${isPopup ? 'px-2 pb-4' : 'max-w-3xl mx-auto pb-20'}
            `}>
              <AnimatePresence>
                {filteredAudiences.map((audience) => (
                  <motion.div
                    key={audience._id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <TargetAudienceCard
                      audience={audience}
                      onEdit={handleEdit}
                      onDelete={handleDeleteClick}
                      onDownload={handleDownload}
                      isDarkMode={isDarkMode}
                      handleAudienceClick={handleAudienceClick}
                      isPopup={isPopup}
                      progress={progress[audience._id] || 0}
                      updateProgress={(newProgress) => updateProgress(audience._id, newProgress)}
                    />
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          ) : (
            <div className="h-[calc(100vh-10rem)] flex items-center justify-center">
              <NoResultsMessage 
                searchTerm={searchTerm}
                onCreateClick={handleCreateClick}
                onAddExamples={handleRestoreDefaults}
                isDarkMode={isDarkMode}
              />
            </div>
          )}
        </div>

        {/* Delete Confirmation Modal */}
        {showDeleteModal && (
          <div className={`fixed inset-0 flex items-center justify-center p-4 z-50 ${
            isDarkMode ? 'bg-black/30 backdrop-blur-sm' : 'bg-white/30 backdrop-blur-sm'
          }`}>
            <div className={`relative w-full max-w-sm rounded-lg border transition-all
                            ${isDarkMode 
                              ? 'bg-[#121212] border-[#333333]' 
                              : 'bg-white border-zinc-200'}`}>
              <div className="p-6">
                <h3 className={`text-lg font-semibold leading-none tracking-tight mb-2
                              ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}`}>
                  Delete Target Audience
                </h3>
                <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
                  Are you sure? This action cannot be undone.
                </p>
                
                <div className="flex justify-end gap-3 mt-6">
                  <button
                    onClick={cancelDelete}
                    disabled={isDeleting}
                    className={`px-4 py-2 rounded-md text-sm font-medium
                              transition-colors
                              ${isDarkMode 
                                ? 'bg-[#1a1a1a] hover:bg-[#222] text-slate-300 border border-[#333333]' 
                                : 'bg-white hover:bg-zinc-50 text-slate-900 border border-zinc-200'}
                              ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={confirmDelete}
                    disabled={isDeleting}
                    className={`px-4 py-2 rounded-md text-sm font-medium text-white
                              transition-colors flex items-center gap-2
                              bg-red-600 hover:bg-red-700
                              ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {isDeleting ? (
                      <>
                        <ArrowPathIcon className="w-4 h-4 animate-spin" />
                        Deleting...
                      </>
                    ) : (
                      'Delete'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Add the TargetAudienceOverviewPopup */}
        {selectedAudience && (
          <TargetAudienceOverviewPopup
            audience={selectedAudience}
            onClose={closeOverviewPopup}
          />
        )}
      </div>
    </>
  );

  return (
    <Routes>
      <Route path="create" element={<TargetAudienceCreationWizard />} />
      <Route path="edit/:audienceId" element={<TargetAudienceCreationWizard />} />
      <Route path="/" element={<MainContent />} />
    </Routes>
  );
};

export default TargetAudiencesPage;
