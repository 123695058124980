import React from 'react';
import { motion } from 'framer-motion';
import PublicPageLayout from './layout/PublicPageLayout';
import PageSection from './shared/PageSection';
import { useTheme } from '../../context/ThemeContext';
import { 
  QuestionMarkCircleIcon,
  ChatBubbleLeftRightIcon,
  ArrowRightIcon,
  SparklesIcon
} from '@heroicons/react/24/outline';

const HelpCenterPage = () => {
  const { isDarkMode } = useTheme();

  const faqs = [
    {
      question: 'How does Ammmplify use AI to create LinkedIn content?',
      answer: 'Ammmplify uses advanced AI models (Claude 3.5, GPT-4o, Grok, and Gemini) to help you create authentic LinkedIn content while maintaining your unique voice. Our AI understands your brand persona, target audience, and content goals to generate engaging posts with captivating hooks, unique perspectives, and practical insights. You can use voice notes, text input, or our guided content strategy tools to brainstorm and create content.'
    },
    {
      question: 'What are Brand Personas and how do they work?',
      answer: 'Brand Personas are customizable profiles that help the AI understand your unique voice, expertise, and content style. You can create multiple personas for different content purposes, each with its own writing style, tone of voice, and training examples. The AI uses these personas to ensure all generated content consistently matches your brand voice and professional identity.'
    },
    {
      question: 'How can I brainstorm content ideas?',
      answer: 'Ammmplify offers multiple ways to generate content ideas: Use the Content Strategy Manager to create structured content plans, explore trending topics with YouIdea, or capture spontaneous thoughts with Voice Notes and Text Notes. Our AI helps refine these ideas into engaging LinkedIn posts while maintaining your authentic voice.'
    },
    {
      question: 'What\'s the difference between Content Studio and Content Strategy?',
      answer: 'Content Studio is our main creation space where you can instantly generate posts using AI. Content Strategy is a more structured approach where you can plan your content calendar, manage content ideas, and create comprehensive content strategies aligned with your brand goals and target audience.'
    },
    {
      question: 'How do Target Audiences help improve my content?',
      answer: 'Target Audiences help you define and understand your ideal readers. You can specify their career stage, industry, pain points, and content preferences. The AI uses this information to tailor your content\'s tone, examples, and insights to resonate specifically with your chosen audience.'
    },
    {
      question: 'Can I save and organize my content ideas?',
      answer: 'Yes! Ammmplify provides multiple organization tools: Save ideas to your Ideas Manager, create voice notes for quick captures, use the Media Library for files and images, and maintain a Knowledge Base of your expertise. All saved content can be easily accessed and converted into LinkedIn posts.'
    },
    {
      question: 'How does Focus Mode help with content creation?',
      answer: 'Focus Mode provides a distraction-free environment for content creation. When activated, it minimizes the sidebar and removes visual clutter, helping you concentrate on writing and ideation. You can quickly access the menu by moving your cursor to the left edge of the screen.'
    },
    {
      question: 'How secure is my content and personal data?',
      answer: 'We prioritize your data security with industry-standard encryption, secure authentication, and strict access controls. Your content, personas, and personal information are never shared without your explicit consent. We use secure AWS S3 storage for media files and implement comprehensive security measures across all features.'
    }
  ];

  return (
    <PublicPageLayout
      title="Help Center"
      description="Find quick answers to common questions"
    >
      {/* FAQs */}
      <PageSection className="py-16">
        <div className="max-w-3xl mx-auto">
          {/* FAQ Header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-12"
          >
            <div className={`
              mx-auto w-12 h-12 rounded-xl mb-6
              flex items-center justify-center
              ${isDarkMode 
                ? 'bg-blue-500/10 text-blue-400' 
                : 'bg-blue-50 text-blue-600'
              }
            `}>
              <SparklesIcon className="w-6 h-6" />
            </div>
            <h2 className={`
              text-2xl font-semibold mb-3
              bg-clip-text text-transparent bg-gradient-to-r
              ${isDarkMode 
                ? 'from-white via-white/90 to-slate-400/80' 
                : 'from-slate-900 via-slate-800 to-slate-700'
              }
            `}>
              Frequently Asked Questions
            </h2>
            <p className={`
              text-sm max-w-xl mx-auto
              ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
            `}>
              Get quick answers to common questions about Ammmplify. Can't find what you're looking for? 
              Our support team is here to help.
            </p>
          </motion.div>

          {/* FAQ List */}
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <motion.div
                key={faq.question}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className={`
                  group p-6 rounded-2xl transition-all duration-200
                  hover:scale-[1.02]
                  ${isDarkMode 
                    ? 'bg-[#151515] border border-white/10 hover:bg-[#1a1a1a]' 
                    : 'bg-white border border-black/5 hover:bg-slate-50'
                  }
                `}
              >
                <h4 className={`
                  text-lg font-semibold mb-3 flex items-start gap-3
                  ${isDarkMode ? 'text-white' : 'text-slate-900'}
                `}>
                  <QuestionMarkCircleIcon className={`
                    w-5 h-5 flex-shrink-0 mt-1 transition-colors duration-200
                    ${isDarkMode 
                      ? 'text-blue-400 group-hover:text-blue-300' 
                      : 'text-blue-500 group-hover:text-blue-600'
                    }
                  `} />
                  {faq.question}
                </h4>
                <p className={`
                  text-sm leading-relaxed pl-8
                  ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                `}>
                  {faq.answer}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </PageSection>

      {/* Still Need Help */}
      <PageSection className="py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-2xl mx-auto text-center"
        >
          <div className={`
            mx-auto w-12 h-12 rounded-xl mb-6
            flex items-center justify-center
            ${isDarkMode 
              ? 'bg-blue-500/10 text-blue-400' 
              : 'bg-blue-50 text-blue-600'
            }
          `}>
            <ChatBubbleLeftRightIcon className="w-6 h-6" />
          </div>
          <h3 className={`
            text-2xl font-semibold mb-3
            bg-clip-text text-transparent bg-gradient-to-r
            ${isDarkMode 
              ? 'from-white via-white/90 to-slate-400/80' 
              : 'from-slate-900 via-slate-800 to-slate-700'
            }
          `}>
            Still need help?
          </h3>
          <p className={`
            text-sm mb-8 max-w-xl mx-auto
            ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
          `}>
            Our support team is always here to help you with any questions or concerns. 
            We typically respond within 24 hours on business days.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a
              href="mailto:vinay@ammmplify.com"
              className={`
                inline-flex items-center justify-center gap-2
                px-6 py-3 rounded-xl font-medium text-sm
                transition-all duration-200
                ${isDarkMode 
                  ? 'bg-white/5 hover:bg-white/10 text-white' 
                  : 'bg-slate-100 hover:bg-slate-200 text-slate-900'
                }
              `}
            >
              Email Support
            </a>
            <a
              href="/contact"
              className={`
                inline-flex items-center justify-center gap-2
                px-6 py-3 rounded-xl font-medium text-sm
                text-white transition-all duration-200
                bg-blue-500 hover:bg-blue-600
              `}
            >
              Contact Us
              <ArrowRightIcon className="w-4 h-4" />
            </a>
          </div>
        </motion.div>
      </PageSection>
    </PublicPageLayout>
  );
};

export default HelpCenterPage; 