import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ChevronDownIcon, UserGroupIcon, ChatBubbleLeftRightIcon, FlagIcon, PencilIcon, RocketLaunchIcon, PlusIcon, MinusIcon, CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { cn } from '../utils/cn';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';

const toneOptions = [
  "Professional", "Informative", "Analytical", "Authoritative", "Collaborative",
  "Diplomatic", "Empowering", "Engaging", "Formal", "Innovative",
  "Inspirational", "Instructive", "Mentoring", "Motivational", "Objective",
  "Optimistic", "Pragmatic", "Strategic", "Thought-provoking", "Visionary"
];

const goalOptions = [
  "To educate or share knowledge",
  "To entertain or provide inspiration",
  "To challenge ideas or perspectives",
  "To share a personal experience or story",
  "To tell a compelling narrative",
  "To spark discussion or debate",
  "To provide insights or analysis",
  "To offer practical tips or advice",
  "To celebrate achievements or milestones",
  "To express gratitude or appreciation",
  "To reflect on lessons learned",
  "To share industry news or updates",
  "To promote an event or opportunity",
  "To seek input, feedback or collaboration",
  "To showcase creativity or innovative thinking",
  "To build brand awareness",
  "To drive engagement and interactions",
  "To establish thought leadership",
  "To attract potential clients or customers",
  "To connect with like-minded professionals",
  "To share company updates or announcements",
  "To promote products or services",
  "To share motivational or inspiring content",
  "To express personal or professional values",
  "To foster community and networking"
];

const IconWrapper = ({ icon: Icon, className }) => (
  <Icon className={cn(
    "h-4 w-4 absolute left-3 top-1/2 -translate-y-1/2 pointer-events-none",
    className
  )} />
);

const Input = ({ type, options, value, onChange, placeholder, icon: Icon, minHeight, fullWidth, required }) => {
  const { isDarkMode } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);
  const searchInputRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  useEffect(() => {
    if (type === 'textarea' && inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.max(inputRef.current.scrollHeight, minHeight || 0);
      inputRef.current.style.height = `${newHeight}px`;
    }
  }, [value, type, minHeight]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          triggerRef.current && !triggerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const updateDropdownPosition = () => {
      if (triggerRef.current && isOpen) {
        const rect = triggerRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceAbove = rect.top;
        const shouldShowAbove = spaceBelow < 200 && spaceAbove > spaceBelow;

        setDropdownPosition({
          top: shouldShowAbove ? rect.top - 220 : rect.bottom + 4,
          left: rect.left,
          width: rect.width,
          transformOrigin: shouldShowAbove ? 'bottom' : 'top'
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', updateDropdownPosition, true);
    window.addEventListener('resize', updateDropdownPosition);

    if (isOpen) {
      updateDropdownPosition();
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', updateDropdownPosition, true);
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, [isOpen]);

  const handleInputChange = (inputValue) => {
    if (type === 'dropdown') {
      setSearchTerm(inputValue);
    }
    onChange(inputValue);
  };

  const filteredOptions = useMemo(() => 
    options?.filter(option => 
      option.toLowerCase().includes((searchTerm || '').toLowerCase())
    ),
    [options, searchTerm]
  );

  const inputClasses = useMemo(() => 
    cn(
      "w-full px-3 py-2 rounded-md text-sm transition-all duration-200 outline-none border",
      isDarkMode
        ? "bg-slate-950 border-slate-800 text-slate-200 focus:border-slate-700 focus:ring-2 focus:ring-slate-700/50"
        : "bg-white border-gray-200 text-gray-900 focus:border-gray-300 focus:ring-2 focus:ring-gray-100",
      required && "required"
    ),
    [isDarkMode, required]
  );

  const [touched, setTouched] = useState(false);
  const hasError = required && touched && !value;

  const renderDropdown = () => {
    if (!isOpen) return null;

    return createPortal(
      <div
        ref={dropdownRef}
        style={{
          position: 'fixed',
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
          width: `${dropdownPosition.width}px`,
          maxHeight: '220px',
          transformOrigin: dropdownPosition.transformOrigin,
        }}
        className={cn(
          "z-[9999] rounded-md shadow-lg border",
          isDarkMode 
            ? "bg-slate-950 border-slate-800" 
            : "bg-white border-gray-200",
          "animate-in fade-in-0 zoom-in-95"
        )}
      >
        <div className="max-h-[220px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
          <div className="py-1">
            {filteredOptions.map((option) => (
              <div
                key={option}
                className={cn(
                  "px-3 py-2 text-sm cursor-pointer select-none",
                  "transition-colors duration-100",
                  isDarkMode
                    ? "hover:bg-slate-900 text-slate-200"
                    : "hover:bg-gray-50 text-gray-900",
                  value === option && (isDarkMode 
                    ? "bg-slate-900 text-slate-200" 
                    : "bg-gray-50 text-gray-900")
                )}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
              >
                <div className="flex items-center justify-between">
                  <span className={cn(
                    "block truncate",
                    value === option ? "font-medium" : "font-normal"
                  )}>
                    {option}
                  </span>
                  {value === option && (
                    <CheckIcon className="h-4 w-4 ml-2 flex-shrink-0" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>,
      document.body
    );
  };

  if (type === 'dropdown') {
    return (
      <div className={cn("relative", fullWidth && "w-full")} ref={triggerRef}>
        <button
          type="button"
          className={cn(
            "relative w-full text-left cursor-pointer rounded-md py-2 pl-9 pr-10 text-sm",
            "border transition-all duration-200 outline-none h-[38px] flex items-center",
            isDarkMode 
              ? "bg-slate-950 border-slate-800 text-slate-200 hover:bg-slate-900" 
              : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
            hasError && "border-red-500",
            "focus:outline-none focus:ring-2",
            isDarkMode ? "focus:ring-slate-700/50" : "focus:ring-gray-100"
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          {Icon && (
            <Icon className={cn(
              "h-4 w-4 absolute left-2.5 top-1/2 -translate-y-1/2",
              isDarkMode ? "text-slate-400" : "text-gray-500",
              hasError && "text-red-500"
            )} />
          )}
          <span className={cn(
            "block truncate flex-1",
            !value && "text-slate-400/50 dark:text-slate-500/50"
          )}>
            {value || placeholder}
          </span>
          <ChevronDownIcon className={cn(
            "h-4 w-4 flex-shrink-0 ml-2 transition-transform duration-200",
            isDarkMode ? "text-slate-400" : "text-gray-400",
            isOpen && "rotate-180"
          )} />
        </button>
        {renderDropdown()}
        {hasError && (
          <span className="absolute -bottom-5 left-0 text-xs text-red-500">
            This field is required
          </span>
        )}
      </div>
    );
  }

  return (
    <div className="relative w-full">
      {Icon && (
        <IconWrapper 
          icon={Icon}
          className={cn(
            type === 'textarea' ? "top-3 translate-y-0" : "top-1/2 -translate-y-1/2",
            isDarkMode ? "text-slate-400" : "text-gray-500",
            hasError && "text-red-500"
          )}
        />
      )}
      {type === 'textarea' ? (
        <textarea
          ref={inputRef}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => setTouched(true)}
          placeholder={placeholder}
          className={cn(
            inputClasses,
            "pl-9 resize-none overflow-hidden",
            hasError && "border-red-500",
            "focus:outline-none focus:ring-2",
            isDarkMode ? "focus:ring-slate-700/50" : "focus:ring-gray-100"
          )}
          rows={1}
          style={{ minHeight: minHeight ? `${minHeight}px` : 'auto' }}
          aria-required={required}
          aria-invalid={hasError}
        />
      ) : (
        <input
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          className={cn(
            inputClasses,
            "pl-9",
            "focus:outline-none focus:ring-2",
            isDarkMode ? "focus:ring-slate-700/50" : "focus:ring-gray-100"
          )}
        />
      )}
      {hasError && (
        <span className="absolute -bottom-5 left-0 text-xs text-red-500">
          This field is required
        </span>
      )}
    </div>
  );
};

const TemplateForm = ({ onSubmit, template, isMinimized }) => {
  const { isDarkMode } = useTheme();
  const [formData, setFormData] = useState({
    contentIdea: template?.contentIdea || '',
    toneOfVoice: template?.tone || template?.toneOfVoice || '',
    goalOfPost: template?.goalOfPost || '',
  });

  useEffect(() => {
    if (template) {
      setFormData(prev => ({
        ...prev,
        toneOfVoice: template.tone || template.toneOfVoice || prev.toneOfVoice,
        goalOfPost: template.goalOfPost || prev.goalOfPost,
      }));
    }
  }, [template]);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback((field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    if (errors[field]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!formData.contentIdea.trim()) {
      newErrors.contentIdea = 'Content idea is required';
    }
    return newErrors;
  }, [formData.contentIdea]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsSubmitting(false);
      return;
    }

    try {
      const filteredFormData = Object.entries(formData).reduce((acc, [key, value]) => {
        if (value.toString().trim() !== '') {
          acc[key] = value;
        }
        return acc;
      }, {});

      const message = formData.contentIdea || '';
      
      if (!message.trim()) {
        setErrors({ contentIdea: 'Content idea is required' });
        setIsSubmitting(false);
        return;
      }

      filteredFormData.message = message;
      if (template?.content) {
        filteredFormData.templateContent = template.content;
      }
      
      await onSubmit(filteredFormData, template);
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrors({ submit: 'Failed to submit form. Please try again.' });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return (
      <motion.div 
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="flex items-center justify-center p-4"
      >
        <div className="relative">
          <motion.div 
            className="h-8 w-8 rounded-full border-2 border-slate-200"
            style={{
              borderLeftColor: isDarkMode ? '#1e293b' : '#1e40af',
            }}
            animate={{ 
              rotate: 360,
            }}
            transition={{
              duration: 1,
              ease: "linear",
              repeat: Infinity
            }}
          />
          <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2 }}
            className="absolute inset-0 flex items-center justify-center"
          >
            <motion.div 
              className={cn(
                "h-3 w-3 rounded-full",
                isDarkMode ? "bg-slate-200" : "bg-slate-800"
              )}
              animate={{ 
                scale: [1, 0.8, 1],
              }}
              transition={{
                duration: 1.5,
                ease: "easeInOut",
                repeat: Infinity
              }}
            />
          </motion.div>
        </div>
      </motion.div>
    );
  }

  if (isMinimized) {
    return null;
  }

  return (
    <form 
      onSubmit={handleSubmit} 
      className={cn(
        "w-full mx-auto rounded-lg flex flex-col",
        isDarkMode ? "bg-slate-950" : "bg-white",
        "shadow-sm",
        isDarkMode ? "shadow-slate-900/10" : "shadow-gray-100"
      )}
      noValidate
    >
      <div className="flex-1 overflow-y-auto custom-scrollbar max-h-[calc(100vh-500px)] p-4">
        <Input
          type="textarea"
          value={formData.contentIdea}
          onChange={(value) => handleChange('contentIdea', value)}
          placeholder="Content Idea *"
          icon={PencilIcon}
          minHeight={100}
          required
        />
      </div>
      <div className={cn(
        "p-4 border-t",
        isDarkMode ? "border-slate-800" : "border-gray-100"
      )}>
        <div className="flex flex-col space-y-3 mb-4">
          <Input
            type="dropdown"
            options={toneOptions}
            value={formData.toneOfVoice}
            onChange={(value) => handleChange('toneOfVoice', value)}
            placeholder="Tone"
            icon={ChatBubbleLeftRightIcon}
            fullWidth
          />
          <Input
            type="dropdown"
            options={goalOptions}
            value={formData.goalOfPost}
            onChange={(value) => handleChange('goalOfPost', value)}
            placeholder="Goal of Post"
            icon={FlagIcon}
            fullWidth
          />
        </div>
        
        <div className="flex justify-end">
          <button 
            type="submit"
            className={cn(
              "flex items-center justify-center px-4 py-2 rounded-md text-xs font-medium",
              "transition-all duration-200 whitespace-nowrap",
              "focus:outline-none focus:ring-2 focus:ring-offset-2",
              isDarkMode ? "focus:ring-offset-slate-950" : "focus:ring-offset-white",
              !formData.contentIdea.trim() 
                ? cn(
                    isDarkMode 
                      ? "bg-slate-800 text-slate-400 cursor-not-allowed" 
                      : "bg-gray-100 text-gray-400 cursor-not-allowed",
                    "focus:ring-slate-500/50"
                  )
                : cn(
                    isDarkMode 
                      ? "bg-blue-600 hover:bg-blue-500 active:bg-blue-700 text-white" 
                      : "bg-blue-500 hover:bg-blue-400 active:bg-blue-600 text-white",
                    "hover:shadow-lg",
                    isDarkMode 
                      ? "hover:shadow-blue-500/10" 
                      : "hover:shadow-blue-500/20",
                    "focus:ring-blue-500/50"
                  )
            )}
            disabled={!formData.contentIdea.trim()}
          >
            <RocketLaunchIcon className="h-4 w-4 mr-2" />
            Generate Post
          </button>
        </div>
      </div>
      {errors.submit && (
        <div className={cn(
          "mt-2 text-xs text-red-500 px-4 pb-4",
          "animate-in fade-in-0 slide-in-from-top-1"
        )}>
          {errors.submit}
        </div>
      )}
    </form>
  );
};

export default TemplateForm;
