import React, { useState, useCallback, useRef } from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import AnimatedLoader from '../common/AnimatedLoader';

const MAX_TEXT_LENGTH = 500;
const MIN_TEXT_LENGTH = 1;

const TaskInput = ({ 
  onAdd, 
  placeholder = "Type a task and press Enter...",
  disabled = false 
}) => {
  const [text, setText] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const submitTimeoutRef = useRef(null);
  const lastSubmitTimeRef = useRef(0);
  const MIN_SUBMIT_INTERVAL = 1000; // 1 second

  const validateInput = useCallback((input) => {
    const trimmedInput = input.trim();
    
    if (trimmedInput.length < MIN_TEXT_LENGTH) {
      return false;
    }
    
    if (trimmedInput.length > MAX_TEXT_LENGTH) {
      return false;
    }
    
    return true;
  }, []);

  const handleTextChange = useCallback((e) => {
    const newText = e.target.value;
    if (newText.length <= MAX_TEXT_LENGTH) {
      setText(newText);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if disabled or already creating
    if (disabled || isCreating) return;
    
    // Prevent rapid submissions
    const now = Date.now();
    if (now - lastSubmitTimeRef.current < MIN_SUBMIT_INTERVAL) {
      return;
    }
    
    // Clear any existing timeout
    if (submitTimeoutRef.current) {
      clearTimeout(submitTimeoutRef.current);
    }
    
    if (!validateInput(text)) return;

    try {
      setIsCreating(true);
      lastSubmitTimeRef.current = now;
      
      await onAdd(text);
      setText('');
    } catch (error) {
      console.error('Error creating task:', error);
    } finally {
      // Delay resetting the creating state to prevent rapid submissions
      submitTimeoutRef.current = setTimeout(() => {
        setIsCreating(false);
      }, MIN_SUBMIT_INTERVAL);
    }
  };

  // Cleanup timeout on unmount
  React.useEffect(() => {
    return () => {
      if (submitTimeoutRef.current) {
        clearTimeout(submitTimeoutRef.current);
      }
    };
  }, []);

  const isDark = document.documentElement.classList.contains('dark');

  return (
    <form onSubmit={handleSubmit} className="relative">
      <input
        type="text"
        value={text}
        onChange={handleTextChange}
        placeholder={placeholder}
        disabled={disabled || isCreating}
        maxLength={MAX_TEXT_LENGTH}
        className={`
          w-full px-4 py-3 pr-12
          bg-transparent
          border-0 border-b border-gray-200 dark:border-zinc-800
          focus:border-b-2 focus:border-black-500 dark:focus:border-black-500
          outline-none
          text-gray-900 dark:text-gray-100
          placeholder:text-gray-400 dark:placeholder:text-zinc-600
          transition-all
          disabled:opacity-50 disabled:cursor-not-allowed
        `}
      />
      {text.length > 0 && (
        <span className={`
          absolute right-14 top-1/2 -translate-y-1/2
          text-xs
          ${text.length > MAX_TEXT_LENGTH * 0.8 
            ? 'text-red-500 dark:text-red-400' 
            : isDark ? 'text-zinc-600' : 'text-gray-400'
          }
        `}>
          {text.length}/{MAX_TEXT_LENGTH}
        </span>
      )}
      <button
        type="submit"
        disabled={!text.trim() || isCreating || disabled || text.length > MAX_TEXT_LENGTH}
        className={`
          absolute right-2 top-1/2 -translate-y-1/2
          p-1.5 rounded-md
          ${text.trim() && !disabled
            ? 'text-black hover:text-black-600 dark:text-black-400 dark:hover:text-black-300' 
            : 'text-gray-400/50 dark:text-zinc-600/50'
          }
          disabled:opacity-50 disabled:cursor-not-allowed
          transition-colors
        `}
        title={
          disabled ? 'Task creation is disabled' :
          isCreating ? 'Creating task...' :
          !text.trim() ? 'Please enter a task' :
          text.length > MAX_TEXT_LENGTH ? 'Text is too long' :
          'Add task'
        }
      >
        {isCreating ? (
          <AnimatedLoader size={20} />
        ) : (
          <ArrowRightIcon className="w-5 h-5" />
        )}
      </button>
    </form>
  );
};

TaskInput.propTypes = {
  onAdd: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default TaskInput; 