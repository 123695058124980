const isDevelopment = process.env.NODE_ENV === 'development';

const config = {
  baseURL: isDevelopment ? 'http://localhost:3001' : 'https://api.ammmplify.com',
  apiURL: isDevelopment ? 'http://localhost:3001/api' : 'https://api.ammmplify.com/api',
  googleAuthEndpoints: {
    getAuthUrl: '/api/auth/google/auth-url',
    callback: '/api/auth/google/callback',
    refreshToken: '/api/auth/google/refresh-token'
  }
};

export default config; 