import React, { useState } from 'react';
import { X, Upload, FileText, Loader, Plus } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import PropTypes from 'prop-types';
import { convertFileToText } from '../utils/fileConverter';

const FileUploadPopover = ({ onClose, onSubmit, containerWidth }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isDarkMode } = useTheme();

  // Calculate responsive width based on container width and screen size
  const isMobile = typeof window !== 'undefined' && window.innerWidth <= 768;
  const popoverWidth = isMobile 
    ? '95vw' 
    : Math.min(Math.max(containerWidth * 0.9, 280), 400);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const processedFiles = await Promise.all(files.map(async (file) => {
        try {
          const fileContent = await convertFileToText(file);
          const lines = fileContent.split('\n');
          return {
            id: `file-${Date.now()}-${Math.random()}`,
            name: file.name,
            type: file.type,
            size: file.size,
            content: fileContent,
            text: fileContent,
            lines: lines.length,
            createdAt: new Date(),
            preview: lines.slice(0, 3).join('\n')
          };
        } catch (error) {
          console.error(`Error processing file ${file.name}:`, error);
          throw error;
        }
      }));

      await onSubmit(processedFiles);
      onClose();
    } catch (error) {
      console.error('Error submitting files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled = files.length === 0;

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center bg-black/50">
      <div 
        className="absolute inset-0 bg-black/10 backdrop-blur-sm"
        onClick={onClose}
      />
      
      <div 
        className={`
          relative z-50 rounded-lg shadow-lg p-3 sm:p-4 md:p-6
          ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}
          transition-all duration-200 ease-in-out
          w-full mx-4
        `}
        style={{ 
          width: isMobile ? '95vw' : popoverWidth,
          maxWidth: isMobile ? '100%' : '480px',
          minWidth: isMobile ? 'auto' : '280px'
        }}
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-3 sm:mb-4">
          <h2 className={`text-base sm:text-lg font-bold ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
            Upload Files
          </h2>
          <button 
            onClick={onClose} 
            className={`p-1 rounded-full transition-colors duration-200
              ${isDarkMode 
                ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-300' 
                : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
              }
            `}
          >
            <X size={18} />
          </button>
        </div>

        {/* File Upload Section */}
        <div className="space-y-3">
          <div>
            <div className="flex items-center justify-center w-full">
              <label 
                htmlFor="file-upload" 
                className={`
                  flex flex-col items-center justify-center w-full
                  h-44 sm:h-52 border-2 border-dashed rounded-lg cursor-pointer
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'border-[#333333] bg-[#1e1e1e] hover:bg-[#2a2a2a]' 
                    : 'border-gray-300 bg-gray-50 hover:bg-gray-100'
                  }
                `}
              >
                <div className="flex flex-col items-center justify-center pt-4 pb-3 px-3 sm:p-5 text-center">
                  <Upload size={16} className={`${isDarkMode ? 'text-slate-400' : 'text-gray-400'} mb-2`} />
                  <p className={`text-xs ${isDarkMode ? 'text-slate-300' : 'text-gray-500'}`}>
                    <span className="font-semibold">Click to upload</span> or drag
                  </p>
                  <p className={`text-xs mt-1 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                    Text-based PDF, DOCX, TXT (max. 10MB)
                  </p>
                </div>

                {/* Separate Note Section */}
                <div className={`
                  mt-2 mx-4 p-2.5 rounded-md text-center mb-4 sm:mb-5
                  ${isDarkMode 
                    ? 'bg-[#252525] border border-[#333333]' 
                    : 'bg-gray-50 border border-gray-200'
                  }
                `}>
                  <p className={`text-[11px] font-medium ${isDarkMode ? 'text-amber-400/90' : 'text-amber-600'}`}>
                    Important Note
                  </p>
                  <p className={`text-[10px] mt-1 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                    Only text files are supported. Scanned PDFs cannot be processed.
                  </p>
                </div>
                <input id="file-upload" type="file" className="hidden" multiple onChange={handleFileChange} />
              </label>
            </div>
          </div>

          {/* Selected Files List */}
          {files.length > 0 && (
            <div className="mt-4 mb-3 sm:mt-5 sm:mb-4">
              <p className={`text-xs font-medium mb-2 ${
                isDarkMode ? 'text-slate-200' : 'text-gray-700'
              }`}>
                Selected Files:
              </p>
              <ul className={`
                text-xs max-h-16 sm:max-h-24 overflow-y-auto custom-scrollbar
                py-1 px-1
                ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
              `}>
                {files.map((file, index) => (
                  <li key={index} className="flex items-center py-1">
                    <FileText size={12} className="mr-1.5 flex-shrink-0" />
                    <span className="truncate">{file.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="mt-3 sm:mt-4 flex justify-end">
          <button
            className={`
              px-4 py-2 text-xs font-medium rounded-md
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
              transition-all duration-200 ease-in-out
              flex items-center justify-center min-w-[90px] sm:min-w-[100px]
              ${isSubmitDisabled
                ? isDarkMode
                  ? 'bg-[#1a1a1a] text-slate-400 cursor-not-allowed'
                  : 'bg-gray-100 text-gray-400 border border-gray-200 cursor-not-allowed'
                : isDarkMode
                  ? 'bg-[#1e1e1e] hover:bg-[#2a2a2a] text-blue-400 border border-[#333333]'
                  : 'bg-black hover:bg-gray-900 text-white border border-black'
              }
            `}
            onClick={handleSubmit}
            disabled={isSubmitDisabled || isLoading}
          >
            {isLoading ? (
              <>
                <Loader size={14} className="animate-spin mr-2" />
                <span>Uploading...</span>
              </>
            ) : (
              <>
                <Plus size={14} className="mr-2" />
                <span>Upload Files</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

FileUploadPopover.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  containerWidth: PropTypes.number
};

export default FileUploadPopover;