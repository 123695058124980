import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useChat } from '../context/ChatContext';
import LinkedInPostMockup, { SkeletonLinkedInPost } from './LinkedInPostMockup';
import CanvasFloatingBubbleMenu from './CanvasFloatingBubbleMenu';
import CanvasTopBar from './CanvasTopBar';
import CanvasWelcome from './CanvasWelcome';
import '../components/scrollbar.css';
import { AnimatePresence, motion } from 'framer-motion';
import { 
  addPostToCanvas, 
  updatePostInCanvas, 
  removePostFromCanvas,
  getCanvas,
  updatePostTitle,
  createPostWithTitle,
  createCanvas,
  updatePostPublishStatus,
  updateCanvasIcon
} from '../api';
import { useCanvasPostsPosition } from './CanvasPostsPosition';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import CanvasFocusPost from './CanvasFocusPost';
import CanvasMasonryView from './CanvasMasonryView';
import CanvasSkeleton from './CanvasSkeleton';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/solid';
import { useRecentActivities } from '../context/RecentActivitiesContext';
import FullScreenCanvasPostContentEditor from './FullScreenCanvasPostContentEditor';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { PlusIcon } from '@heroicons/react/24/outline';

// Empty state message component - simplify to always fit available space
const EmptyState = ({ isDarkMode }) => (
  <div className={`absolute inset-0 flex items-center justify-center ${
    isDarkMode ? 'text-slate-400' : 'text-gray-600'
  }`}>
    <div className="text-center">
      <p className={`text-lg font-medium ${
        isDarkMode ? 'text-slate-200' : 'text-gray-900'
      }`}>
        No posts yet
      </p>
      <p className={`text-sm mt-2 ${
        isDarkMode ? 'text-slate-400' : 'text-gray-600'
      }`}>
        Start a chat to create some posts!
      </p>
    </div>
  </div>
);

// Add MIN_POST_HEIGHT constant at the top with other constants
const MIN_POST_HEIGHT = 300;

// Add this new component at the top of the file
const ScrollAnchor = ({ id }) => (
  <div 
    id={`scroll-anchor-${id}`}
    style={{ 
      position: 'relative', 
      top: '-40px',  // Match the pt-10 (40px) padding
      visibility: 'hidden',
      height: 0
    }}
  />
);

// Add these animation variants at the top
const listViewAnimationVariants = {
  initial: {
    opacity: 0,
    y: 20,
    scale: 0.98
  },
  animate: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 25
    }
  },
  exit: {
    opacity: 0,
    scale: 0.96,
    transition: {
      duration: 0.2,
      ease: "easeOut"
    }
  }
};

const listContainerVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      when: "beforeChildren"
    }
  },
  exit: {
    opacity: 0,
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
      when: "afterChildren"
    }
  }
};

// Add this utility function at the top of the file
const generateUniqueId = () => {
  return `post-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
};

// Add this new component after the EmptyState component
const MobileBottomBar = ({ onClick, isDarkMode }) => (
  <div className={`
    fixed bottom-4 left-0 right-0
    flex items-center justify-center px-4
    pointer-events-none z-20
  `}>
    <button
      onClick={onClick}
      className={`
        flex items-center justify-center gap-2 px-4 py-2 rounded-full
        transition-all duration-300 ease-in-out transform
        pointer-events-auto shadow-md
        hover:shadow-lg hover:scale-[1.02]
        active:scale-[0.98]
        ${isDarkMode 
          ? 'bg-[#202020] text-white border border-[#333333] hover:bg-[#252525]' 
          : 'bg-white text-gray-800 border border-gray-200 hover:bg-gray-50'}
      `}
    >
      <PlusIcon className="w-4 h-4" />
      <span className="text-sm font-medium">Write Manually</span>
    </button>
  </div>
);

// Update the NewPostButton component to be desktop-only
const NewPostButton = ({ onClick, isDarkMode }) => (
  <button
    onClick={onClick}
    className={`
      fixed z-10 hidden md:flex items-center justify-center
      transition-all duration-300 ease-in-out transform
      ${isDarkMode 
        ? 'bg-[#202020] text-white border border-[#333333] hover:bg-[#252525]' 
        : 'bg-white text-gray-800 border border-gray-200 hover:bg-gray-50'}
      shadow-md hover:shadow-lg
      right-6 top-16
      group
      rounded-full
      w-10 h-10
      hover:w-[160px]
      px-3
      hover:px-4
      overflow-hidden
    `}
  >
    <div className="flex items-center justify-center min-w-[16px]">
      <PlusIcon className="w-4 h-4 flex-shrink-0 transition-transform duration-300 group-hover:scale-[1.02]" />
    </div>
    <div className="w-0 group-hover:w-auto transition-all duration-300 overflow-hidden">
      <span className="whitespace-nowrap text-sm font-medium ml-2">Write Manually</span>
    </div>
  </button>
);

const Canvas = ({ isChatCollapsed, onToggleChat, isMobile }) => {
  const { isDarkMode } = useTheme();
  const { 
    canvasContents, 
    newestPostId, 
    setNewestPostId, 
    removeFromCanvas,
    activeTabId,
    setActiveTabId 
  } = useChat();
  
  // Add refs
  const canvasRef = useRef(null);
  const canvasTopBarRef = useRef(null);
  const canvasContainerRef = useRef(null);
  const postsRef = useRef({});
  
  // Import isCanvasCached from api
  const { isCanvasCached } = require('../api');

  // Move isHorizontalLayout state to the top with other states
  const [activeDevice, setActiveDevice] = useState('mobile');
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [editingPostId, setEditingPostId] = useState(null);
  const [focusedPost, setFocusedPost] = useState(null);
  const [showWelcome, setShowWelcome] = useState(() => {
    const lastView = localStorage.getItem('canvasLastView');
    const lastActiveTabId = localStorage.getItem('lastActiveTabId');
    return !lastActiveTabId || lastView === 'welcome';
  });
  const [tabs, setTabs] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('canvasTabs') || '[]');
    } catch (error) {
      console.error('Error parsing saved tabs:', error);
      return [];
    }
  });
  const [tabsState, setTabsState] = useState({});
  const [canvasTitle, setCanvasTitle] = useState('Untitled Collection');
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [viewMode, setViewMode] = useState(() => {
    return localStorage.getItem('canvasViewMode') || 'list';
  });

  const {
    contentSize,
    calculateLayout,
    getPostWidth,
    updateContentSize,
    setCanvasViewportSize,
    resetLayout
  } = useCanvasPostsPosition(activeDevice, posts, setPosts, postsRef, canvasContainerRef);

  const { refreshActivities } = useRecentActivities();

  // Notification handler
  const addNotification = useCallback((message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
    setTimeout(() => {
      setNotifications(prev => prev.filter(n => n.id !== id));
    }, 3000);
  }, []);

  // Move this function before handleContentChange
  const handleMediaUrlUpdate = useCallback(async (postId, mediaFiles) => {
    try {
      // Ensure we have valid media files
      if (!mediaFiles?.length) {
        console.warn('No media files to update');
        return;
      }

      console.log('Updating media files:', mediaFiles);

      // Get the current post content
      const currentPost = posts.find(p => p.id === postId);
      const currentContent = currentPost?.content || 'Start writing your post here...';

      // Update the post in the database with new media files
      await updatePostInCanvas(activeTabId, postId, {
        mediaFiles: mediaFiles.map(file => ({
          originalName: file.originalName || file.name,
          cloudinaryUrl: file.secure_url || file.url,
          mimeType: file.mimeType || file.type,
          size: file.size,
          uploadedAt: new Date().toISOString(),
          metadata: {
            width: file.width || file.metadata?.width || null,
            height: file.height || file.metadata?.height || null,
            duration: file.duration || file.metadata?.duration || null,
            format: file.format || (file.mimeType || file.type || '').split('/')[1] || '',
            resource_type: file.resource_type || 'image',
            public_id: file.public_id || file.cloudinary_id
          }
        })),
        content: currentContent,
        lastEdited: new Date().toISOString()
      });

      console.log('Successfully updated media URLs in database');

    } catch (error) {
      console.error('Error updating media URL:', error);
      addNotification('Failed to update media URL', 'error');
      throw error;
    }
  }, [posts, activeTabId, updatePostInCanvas, addNotification]);

  const handleContentChange = useCallback(async (postId, newContent) => {
    if (!activeTabId) return;
    try {
      console.log('handleContentChange called with:', { postId, newContent });

      // Extract content and media information
      const contentToSave = typeof newContent === 'object' 
        ? String(newContent.content || '').trim()
        : String(newContent || '').trim();
      
      // Get the existing post
      const existingPost = posts.find(p => p.id === postId);
      console.log('Existing post:', existingPost);
      
      // Prepare update payload
      const updatePayload = {
        content: contentToSave,
        lastEdited: new Date()
      };

      // Handle media files
      if (typeof newContent === 'object') {
        console.log('Processing media files:', newContent.mediaFiles);
        
        // Preserve existing media files if no new ones are provided
        if (!newContent.mediaFiles || newContent.mediaFiles.length === 0) {
          updatePayload.mediaFiles = existingPost?.mediaFiles || [];
          console.log('Preserving existing media files:', updatePayload.mediaFiles);
        } else {
          // Process new media files and remove duplicates
          const processedFiles = newContent.mediaFiles.map(file => ({
            originalName: file.originalName || file.name,
            cloudinaryUrl: file.cloudinaryUrl || file.secure_url || file.url,
            url: file.cloudinaryUrl || file.secure_url || file.url,
            mimeType: file.mimeType || file.type,
            size: file.size,
            uploadedAt: file.uploadedAt || new Date(),
            metadata: {
              width: file.metadata?.width || file.width || null,
              height: file.metadata?.height || file.height || null,
              duration: file.metadata?.duration || file.duration || null,
              format: file.metadata?.format || (file.mimeType || file.type || '').split('/')[1],
              resource_type: file.metadata?.resource_type || file.resource_type || 'image',
              public_id: file.metadata?.public_id || file.public_id || null
            }
          }));

          // Remove duplicates based on cloudinaryUrl
          const uniqueFiles = [];
          const seenUrls = new Set();
          
          processedFiles.forEach(file => {
            if (file.cloudinaryUrl && !seenUrls.has(file.cloudinaryUrl)) {
              seenUrls.add(file.cloudinaryUrl);
              uniqueFiles.push(file);
            }
          });

          // Validate all files have required fields
          const validFiles = uniqueFiles.filter(file => {
            const isValid = file.cloudinaryUrl && file.originalName && file.mimeType;
            if (!isValid) {
              console.warn('Skipping invalid media file:', file);
            }
            return isValid;
          });

          console.log('Processed and validated media files:', validFiles);
          updatePayload.mediaFiles = validFiles;
        }
      } else {
        // If no media information provided, preserve existing media files
        updatePayload.mediaFiles = existingPost?.mediaFiles || [];
        console.log('Preserving existing media files (no media info provided):', updatePayload.mediaFiles);
      }

      console.log('Final update payload:', updatePayload);

      // Update local state first
      setPosts(prevPosts => 
        prevPosts.map(post => 
          post.id === postId 
            ? { 
                ...post, 
                content: contentToSave,
                lastEdited: new Date(),
                mediaFiles: updatePayload.mediaFiles
              }
            : post
        )
      );

      // Update tabsState to keep it in sync
      setTabsState(prev => ({
        ...prev,
        [activeTabId]: {
          ...prev[activeTabId],
          posts: prev[activeTabId].posts.map(post =>
            post.id === postId
              ? { 
                  ...post, 
                  content: contentToSave,
                  lastEdited: new Date(),
                  mediaFiles: updatePayload.mediaFiles
                }
              : post
          )
        }
      }));

      // Save to server
      const updatedPost = await updatePostInCanvas(activeTabId, postId, updatePayload);
      console.log('Server response:', updatedPost);

      // Verify the update was successful
      if (!updatedPost) {
        throw new Error('Failed to update post on server');
      }

    } catch (error) {
      console.error('Error updating post content:', error);
      toast.error('Failed to save post content');
    }
  }, [activeTabId, posts]);

  const handleTitleChange = useCallback(async (postId, newTitle) => {
    if (!activeTabId) return;
    try {
      // Update local state immediately
      setPosts(prevPosts => prevPosts.map(post => 
        post.id === postId ? { ...post, title: newTitle } : post
      ));

      // Update title in database
      await updatePostTitle(activeTabId, postId, newTitle);

      // Create activity for title update if changing from generating state
      const post = posts.find(p => p.id === postId);
      if (post?.title === 'Generating title...') {
        await refreshActivities();
      }
    } catch (error) {
      console.error('Error updating title:', error);
      addNotification('Failed to update title', 'error');
    }
  }, [activeTabId, addNotification, posts, refreshActivities]);

  const removePost = useCallback(async (id) => {
    if (!activeTabId) return;
    try {
      await removePostFromCanvas(activeTabId, id);
      removeFromCanvas(id);
      setPosts(prevPosts => {
        const newPosts = prevPosts.filter(post => post.id !== id);
        return calculateLayout(newPosts);
      });
    } catch (error) {
      console.error('Error removing post:', error);
      addNotification('Failed to remove post', 'error');
    }
  }, [activeTabId, removeFromCanvas, calculateLayout, addNotification]);

  // Editing handlers
  const handleStartEditing = useCallback((postId) => {
    setEditingPostId(postId);
  }, []);

  const handleStopEditing = useCallback(() => {
    setEditingPostId(null);
  }, []);

  // Focus mode handlers
  const handleFocusMode = useCallback((post) => {
    setFocusedPost(post);
  }, []);

  const handleCloseFocusMode = useCallback(() => {
    setFocusedPost(null);
  }, []);

  // Tab management handlers
  const handleTabChange = useCallback((tabId) => {
    if (tabId === activeTabId) return; // Prevent unnecessary changes

    setShowWelcome(false);
    setActiveTabId(tabId);
    
    // Update session storage
    sessionStorage.setItem('canvasSession', JSON.stringify({
      activeTabId: tabId,
      timestamp: Date.now()
    }));
    
    // Update localStorage
    localStorage.setItem('lastActiveTabId', tabId);
    localStorage.setItem('canvasLastView', 'canvas');
    
    // Check if we already have recent data in tabsState
    const existingTabData = tabsState[tabId];
    const isDataRecent = existingTabData?.lastFetched && 
      (Date.now() - existingTabData.lastFetched < 5000); // 5 seconds threshold

    if (isDataRecent) {
      // Use existing data if it's recent
      setPosts(calculateLayout(existingTabData.posts));
      return;
    }

    const loadCanvasData = async () => {
      try {
        const canvas = await getCanvas(tabId);
        if (canvas.posts?.length > 0) {
          const postsWithLayout = calculateLayout(canvas.posts);
          setPosts(postsWithLayout);
        } else {
          setPosts([]);
        }
        
        setTabsState(prev => ({
          ...prev,
          [tabId]: {
            posts: canvas.posts || [],
            title: canvas.title,
            lastFetched: Date.now()
          }
        }));
      } catch (error) {
        console.error('Error loading canvas data:', error);
        addNotification('Failed to load canvas data', 'error');
      }
    };

    loadCanvasData();
  }, [activeTabId, calculateLayout, addNotification, tabsState, setActiveTabId]);

  // Add this new ref for the scroll container
  const scrollContainerRef = useRef(null);

  // Add this ref at the top with other refs
  const isAddingRef = useRef(false);

  // Update the useEffect that handles new posts
  useEffect(() => {
    if (!activeTabId || !canvasContents.length) return;

    // Add detailed logging
    console.log('Canvas useEffect triggered with:', {
      activeTabId,
      canvasContentsLength: canvasContents.length,
      canvasContents: canvasContents,
      existingPosts: posts
    });

    // Create a more robust way to track existing posts
    const existingPostIds = new Set([
      ...posts.map(post => post.id),
      ...posts.map(post => post.originalId),
      ...posts.map(post => String(post.id)),
      ...posts.map(post => String(post.originalId))
    ].filter(Boolean));

    // More strict filtering of new posts
    const newPosts = canvasContents.filter(content => {
      const contentId = String(content.id);
      const isNew = !existingPostIds.has(contentId);
      console.log(`Checking content ${contentId}:`, { isNew, content });
      return isNew;
    });

    console.log('Filtered newPosts:', newPosts);

    // If no new posts, exit early
    if (newPosts.length === 0) {
      console.log('No new posts to add, returning early');
      return;
    }

    const addNewPosts = async () => {
      // Prevent concurrent additions
      if (isAddingRef.current) {
        console.log('Already adding posts, skipping');
        return;
      }

      isAddingRef.current = true;

      try {
        const formattedNewPosts = [];
        
        for (const content of newPosts) {
          // Generate unique ID once per content
          const uniqueId = generateUniqueId();
          console.log('Processing content for addition:', {
            contentId: content.id,
            uniqueId,
            content: content
          });

          if (content.immediate) {
            formattedNewPosts.push({
              id: uniqueId,
              originalId: content.id,
              content: content.content,
              title: 'Generating title...',
              titleColor: 'text-green-500',
              width: getPostWidth(activeDevice),
              height: MIN_POST_HEIGHT,
              order: -Date.now(),
              timestamp: Date.now(),
              published: content.published || false,
              editState: {
                isEditedLocally: content.editState?.isEditedLocally || false
              }
            });

            // Handle title generation
            createPostWithTitle(content.content)
              .then(post => {
                console.log('Title generated:', { uniqueId, title: post.title });
                handleTitleChange(uniqueId, post.title);
              })
              .catch(error => {
                console.error('Error generating title:', error);
              });
          } else {
            // Handle non-immediate posts...
          }
        }

        console.log('Formatted posts ready for addition:', formattedNewPosts);

        // Sort and update posts only if we have new ones
        if (formattedNewPosts.length > 0) {
          const sortedNewPosts = formattedNewPosts.sort((a, b) => b.timestamp - a.timestamp);
          const updatedPosts = calculateLayout([...sortedNewPosts, ...posts]);

          // Add posts to canvas one by one
          for (const postData of sortedNewPosts) {
            try {
              console.log('Adding post to canvas:', postData);
              const layoutPost = updatedPosts.find(p => p.id === postData.id);
              if (layoutPost) {
                await addPostToCanvas(activeTabId, {
                  ...postData,
                  position: layoutPost.position,
                  order: postData.order,
                  published: postData.published,
                  editState: postData.editState
                });
              }
            } catch (error) {
              console.error('Error adding post to canvas:', error);
              addNotification('Failed to add post to canvas', 'error');
            }
          }

          // Update state only once after all posts are added
          setPosts(updatedPosts);

          // Handle newest post and scrolling
          const newestPost = sortedNewPosts[0];
          if (newestPost) {
            console.log('Setting newest post:', newestPost.id);
            setNewestPostId(newestPost.id);
            
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }
          }

          setIsCanvasEmpty(false);
          addNotification('Post added successfully', 'success');
        }
      } catch (error) {
        console.error('Error in addNewPosts:', error);
        addNotification('Failed to add posts to canvas', 'error');
      } finally {
        isAddingRef.current = false;
      }
    };

    // Execute the addition
    addNewPosts();

    // Cleanup function
    return () => {
      isAddingRef.current = false;
    };
  }, [
    activeTabId,
    canvasContents,
    posts,
    calculateLayout,
    getPostWidth,
    activeDevice,
    addNotification,
    handleTitleChange
  ]);

  // Add handleSelectCanvas function
  const handleSelectCanvas = useCallback(async (canvasId) => {
    try {
      setShowWelcome(false);
      setActiveTabId(canvasId);
      
      localStorage.setItem('lastActiveTabId', canvasId);
      localStorage.setItem('canvasLastView', 'canvas');
      
      const canvas = await getCanvas(canvasId);
      if (canvas.posts?.length > 0) {
        const postsWithLayout = calculateLayout(canvas.posts);
        setPosts(postsWithLayout);
      } else {
        setPosts([]);
      }

      setTabs(prevTabs => {
        const existingTab = prevTabs.find(tab => tab.id === canvasId);
        if (!existingTab) {
          const newTab = {
            id: canvasId,
            title: canvas.title || 'Untitled Collection',
            icon: canvas.icon || 'default'  // Add icon to new tab
          };
          const updatedTabs = [...prevTabs, newTab];
          localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
          return updatedTabs;
        }
        // Update existing tab's icon if it exists
        const updatedTabs = prevTabs.map(tab => 
          tab.id === canvasId 
            ? { ...tab, icon: canvas.icon || tab.icon || 'default' }
            : tab
        );
        localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
        return updatedTabs;
      });

      // Update tabsState with the icon
      setTabsState(prev => ({
        ...prev,
        [canvasId]: {
          ...prev[canvasId],
          title: canvas.title,
          icon: canvas.icon || 'default',
          posts: canvas.posts || [],
          lastFetched: Date.now()
        }
      }));
    } catch (error) {
      console.error('Error selecting canvas:', error);
      addNotification('Failed to load canvas', 'error');
    }
  }, [calculateLayout, addNotification]);

  // Add handleHomeClick function
  const handleHomeClick = useCallback(() => {
    setActiveTabId(null);
    setPosts([]);
    setShowWelcome(true);
    
    // Clear session storage
    sessionStorage.removeItem('canvasSession');
    
    // Update localStorage to explicitly set welcome view
    localStorage.removeItem('lastActiveTabId');
    localStorage.setItem('canvasLastView', 'welcome');
  }, []);

  // Add handler for device changes
  const handleDeviceChange = useCallback((newDevice) => {
    setActiveDevice(newDevice);
    // Recalculate layout with new device width
    setPosts(prevPosts => {
      if (!prevPosts.length) return prevPosts;
      return calculateLayout(prevPosts);
    });
  }, [calculateLayout]);

  // Add this effect to handle initial state and page refresh
  useEffect(() => {
    let isSubscribed = true; // Add this flag for cleanup

    const loadInitialState = async () => {
      try {
        // First check if we should show welcome screen
        const lastView = localStorage.getItem('canvasLastView');
        
        // If lastView is explicitly set to 'welcome', show welcome screen
        if (lastView === 'welcome') {
          if (isSubscribed) {
            setShowWelcome(true);
            setActiveTabId(null);
            setPosts([]);
          }
          return; // Exit early
        }

        // Get the current session state
        const sessionState = JSON.parse(sessionStorage.getItem('canvasSession') || '{}');
        const lastActiveTabId = localStorage.getItem('lastActiveTabId');
        
        // If we have a current session state, prioritize that
        if (sessionState.activeTabId && isSubscribed) {
          try {
            const canvas = await getCanvas(sessionState.activeTabId);
            
            // Only update state if component is still mounted
            if (isSubscribed) {
              setActiveTabId(sessionState.activeTabId);
              setShowWelcome(false);
              
              if (canvas.posts?.length > 0) {
                const postsWithLayout = calculateLayout(canvas.posts);
                setPosts(postsWithLayout);
              }

              // Update tabsState
              setTabsState(prev => ({
                ...prev,
                [sessionState.activeTabId]: {
                  title: canvas.title,
                  posts: canvas.posts || [],
                  lastFetched: Date.now()
                }
              }));
            }
          } catch (error) {
            console.error('Error loading session canvas:', error);
            // If there's an error loading the session canvas, fall back to welcome screen
            if (isSubscribed) {
              setShowWelcome(true);
              setActiveTabId(null);
              setPosts([]);
            }
          }
        }
        // Otherwise, fall back to localStorage state
        else if (lastActiveTabId && lastView === 'canvas' && isSubscribed) {
          try {
            const canvas = await getCanvas(lastActiveTabId);
            
            if (isSubscribed) {
              setActiveTabId(lastActiveTabId);
              setShowWelcome(false);
              
              if (canvas.posts?.length > 0) {
                const postsWithLayout = calculateLayout(canvas.posts);
                setPosts(postsWithLayout);
              }

              // Save to session storage with timestamp
              sessionStorage.setItem('canvasSession', JSON.stringify({
                activeTabId: lastActiveTabId,
                timestamp: Date.now()
              }));
            }
          } catch (error) {
            console.error('Error loading stored canvas:', error);
            // If there's an error loading the stored canvas, show welcome screen
            if (isSubscribed) {
              setShowWelcome(true);
              setActiveTabId(null);
              setPosts([]);
            }
          }
        } else if (isSubscribed) {
          // If no valid state is found, show welcome screen
          setShowWelcome(true);
          setActiveTabId(null);
          setPosts([]);
        }
      } catch (error) {
        console.error('Error loading initial canvas state:', error);
        if (isSubscribed) {
          addNotification('Failed to load canvas', 'error');
          setShowWelcome(true);
          setActiveTabId(null);
          setPosts([]);
        }
      }
    };

    loadInitialState();

    // Cleanup function
    return () => {
      isSubscribed = false;
    };
  }, []); // Empty dependency array since this should only run once on mount

  // Replace the existing tabs metadata loading effect with this improved version
  useEffect(() => {
    if (tabs.length === 0) return;

    // Keep track of whether the component is mounted
    let isMounted = true;

    const loadTabsMetadata = async () => {
      try {
        // Create a map of existing tab data to avoid unnecessary updates
        const existingTabsMap = tabs.reduce((acc, tab) => {
          acc[tab.id] = tab;
          return acc;
        }, {});

        // Only load data for tabs that aren't cached
        const tabsToLoad = tabs.filter(tab => !isCanvasCached(tab.id));
        
        if (tabsToLoad.length === 0) {
          console.log('All tabs are cached, skipping metadata load');
          return;
        }

        console.log('Loading metadata for tabs:', tabsToLoad.map(t => t.id));
        const loadedTabs = await Promise.all(
          tabsToLoad.map(async (tab) => {
            try {
              const canvas = await getCanvas(tab.id);
              return {
                id: tab.id,
                title: canvas.title || existingTabsMap[tab.id].title
              };
            } catch (error) {
              console.error(`Error loading canvas ${tab.id}:`, error);
              return existingTabsMap[tab.id]; // Keep existing tab data on error
            }
          })
        );

        if (!isMounted) return;

        // Merge loaded tabs with existing tabs that were cached
        const updatedTabs = tabs.map(tab => {
          const loadedTab = loadedTabs.find(t => t.id === tab.id);
          return loadedTab || tab;
        });

        // Only update if there are actual changes
        const hasChanges = JSON.stringify(updatedTabs) !== JSON.stringify(tabs);
        if (hasChanges) {
          setTabs(updatedTabs);
          localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));

          // Update tabsState only for newly loaded tabs
          const newTabsState = {};
          loadedTabs.forEach(tab => {
            if (!tabsState[tab.id]) {
              newTabsState[tab.id] = {
                title: tab.title,
                posts: [] // Posts will be loaded when tab is selected
              };
            }
          });

          if (Object.keys(newTabsState).length > 0) {
            setTabsState(prev => ({...prev, ...newTabsState}));
          }
        }
      } catch (error) {
        console.error('Error loading tabs metadata:', error);
      }
    };

    // Use a debounced version of the load function
    const debouncedLoad = debounce(loadTabsMetadata, 1000);
    debouncedLoad();

    // Cleanup
    return () => {
      isMounted = false;
      debouncedLoad.cancel();
    };
  }, [tabs, tabsState]); // Only depend on tabs and tabsState

  // Add this new effect to handle canvas data loading
  useEffect(() => {
    if (!activeTabId || showWelcome) return;

    const loadCanvasData = async () => {
      try {
        // Check if we already have the data in tabsState
        if (tabsState[activeTabId]?.posts) {
          console.log('Using existing canvas data from tabsState');
          setPosts(calculateLayout(tabsState[activeTabId].posts));
          return;
        }

        console.log('Loading canvas data for:', activeTabId);
        const canvas = await getCanvas(activeTabId);
        
        if (canvas) {
          // Update tabsState with the new data
          setTabsState(prev => ({
            ...prev,
            [activeTabId]: {
              posts: canvas.posts || [],
              title: canvas.title
            }
          }));

          // Update posts with layout if there are any
          if (canvas.posts?.length > 0) {
            const postsWithLayout = calculateLayout(canvas.posts);
            setPosts(postsWithLayout);
          }
        }
      } catch (error) {
        console.error('Error loading canvas data:', error);
        addNotification('Failed to load canvas data', 'error');
      }
    };

    loadCanvasData();
  }, [activeTabId, showWelcome]); // Only depend on activeTabId and showWelcome

  const renderPost = useCallback((post, index) => {
    return (
      <motion.div
        key={post.id}
        className={`
          w-full flex justify-center
          ${isDarkMode ? 'bg-[#151515]' : 'bg-[#f9fafc]'}
          ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
          transition-colors duration-200
        `}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }}
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          pointerEvents: 'auto'
        }}
      >
        {post.id === newestPostId && <ScrollAnchor id={post.id} />}
        <LinkedInPostMockup 
          activeDevice={activeDevice}
          canvasContent={post}
          setCanvasContent={(updatedPost) => {
            setPosts(prevPosts => 
              prevPosts.map(p => 
                p.id === post.id ? { ...p, ...updatedPost } : p
              )
            );
          }}
          onRemove={() => removePost(post.id)}
          isNewest={post.id === newestPostId}
          isDarkMode={isDarkMode}
          onContentChange={handleContentChange}
          currentIndex={index}
          totalPosts={posts.length}
          onTitleChange={(newTitle) => handleTitleChange(post.id, newTitle)}
          isEditing={editingPostId === post.id}
          onStartEditing={() => handleStartEditing(post.id)}
          onStopEditing={handleStopEditing}
          onSave={(newContent) => handleContentChange(post.id, newContent)}
          onCancel={handleStopEditing}
          removeBorderAndPadding={true}
          isSimplifiedView={false}
          forceExpanded={true}
          preventCollapse={true}
          onFocusMode={handleFocusMode}
          className="overflow-x-hidden w-full pointer-events-auto"
          canvasId={activeTabId}
          onFullScreen={() => handleFullScreen(post)}
          isPublished={post.published || false}
          isEditedLocally={post.editState?.isEditedLocally}
        />
      </motion.div>
    );
  }, [
    activeDevice,
    newestPostId,
    isDarkMode,
    handleContentChange,
    handleTitleChange,
    editingPostId,
    handleStartEditing,
    handleStopEditing,
    handleFocusMode,
    removePost,
    activeTabId
  ]);

  // Add these new handlers near your other handlers
  const handleAddTab = useCallback(() => {
    const newId = `canvas-${Date.now()}`;
    const newTab = {
      id: newId,
      title: 'Untitled Collection'
    };
    setTabs(prevTabs => [...prevTabs, newTab]);
    setActiveTabId(newId);
  }, []);

  const handleRemoveTab = useCallback((tabId) => {
    setTabs(prevTabs => prevTabs.filter(tab => tab.id !== tabId));
    if (activeTabId === tabId) {
      const remainingTabs = tabs.filter(tab => tab.id !== tabId);
      if (remainingTabs.length > 0) {
        setActiveTabId(remainingTabs[0].id);
      } else {
        setActiveTabId(null);
        setShowWelcome(true);
      }
    }
  }, [activeTabId, tabs, setActiveTabId, setShowWelcome]);

  const handleTabTitleChange = useCallback((tabId, newTitle) => {
    setTabs(prevTabs => 
      prevTabs.map(tab => 
        tab.id === tabId ? { ...tab, title: newTitle } : tab
      )
    );
  }, []);

  const handleViewModeChange = useCallback((mode) => {
    setViewMode(mode);
    localStorage.setItem('canvasViewMode', mode);
  }, []);

  // Add floating action button for mobile
  const FloatingChatButton = () => (
    isMobile && isChatCollapsed && (
      <button
        onClick={onToggleChat}
        className={`
          fixed bottom-4 right-4 z-50 p-4 rounded-full shadow-lg
          ${isDarkMode 
            ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020]' 
            : 'bg-blue-500 text-white hover:bg-blue-600'
          }
          transition-all duration-200
        `}
      >
        <ChatBubbleLeftEllipsisIcon className="w-6 h-6" />
      </button>
    )
  );

  // Add this function back (place it with other handlers)
  const handleCreateCanvas = async () => {
    try {
      const newCanvas = await createCanvas('Untitled Collection');
      
      setTabs(prevTabs => {
        const newTab = {
          id: newCanvas._id,
          title: newCanvas.title
        };
        const updatedTabs = [...prevTabs, newTab];
        localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
        return updatedTabs;
      });

      setActiveTabId(newCanvas._id);
      setShowWelcome(false);
      setPosts([]);

      setTabsState(prev => ({
        ...prev,
        [newCanvas._id]: {
          posts: [],
          title: newCanvas.title,
          lastEdited: new Date().toISOString()
        }
      }));

      localStorage.setItem('lastActiveTabId', newCanvas._id);
      localStorage.setItem('canvasLastView', 'canvas');

      addNotification('Canvas created successfully', 'success');
      return newCanvas._id;
    } catch (error) {
      console.error('Error creating canvas:', error);
      addNotification('Failed to create canvas', 'error');
      return null;
    }
  };

  // Add state for existing canvases
  const [existingCanvases, setExistingCanvases] = useState([]);

  // Add state for full-screen editing
  const [fullScreenPost, setFullScreenPost] = useState(null);

  // Add handler for full-screen mode
  const handleFullScreen = (post) => {
    setFullScreenPost(post);
  };

  // Update the mobile full-screen container
  const [showFullScreenCanvas, setShowFullScreenCanvas] = useState(false);

  const navigate = useNavigate();

  // Add schedule handler
  const handleSchedulePost = useCallback((postData) => {
    console.log('handleSchedulePost called with:', postData);
    // Navigate to the scheduler with the post data
    navigate('/scheduler', { 
      state: { 
        postToSchedule: {
          canvasId: postData.canvasId,
          postId: postData.postId,
          content: postData.content,
          title: postData.title
        }
      }
    });
  }, [navigate]);

  // Add these function definitions before the return statement
  const handleRemovePost = useCallback(async (postId) => {
    try {
      await removePostFromCanvas(activeTabId, postId);
      setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
      addNotification('Post removed successfully', 'success');
    } catch (error) {
      console.error('Error removing post:', error);
      addNotification('Failed to remove post', 'error');
    }
  }, [activeTabId, setPosts, addNotification]);

  const handleExpandPost = useCallback((post) => {
    setFocusedPost(post);
  }, []);

  const handleNavigatePost = useCallback((index, direction) => {
    if (direction === 'next' && index < posts.length - 1) {
      setFocusedPost(posts[index + 1]);
    } else if (direction === 'previous' && index > 0) {
      setFocusedPost(posts[index - 1]);
    }
  }, [posts]);

  const handleSavePost = useCallback(async (postId) => {
    try {
      const post = posts.find(p => p.id === postId);
      if (!post) return;

      await updatePostInCanvas(activeTabId, postId, {
        content: post.content,
        title: post.title
      });
      setEditingPostId(null);
      addNotification('Post saved successfully', 'success');
    } catch (error) {
      console.error('Error saving post:', error);
      addNotification('Failed to save post', 'error');
    }
  }, [activeTabId, posts, addNotification]);

  const handleCancelEdit = useCallback(() => {
    setEditingPostId(null);
  }, []);

  const handleRequestDelete = useCallback((postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      handleRemovePost(postId);
    }
  }, [handleRemovePost]);

  const handlePublishStateChange = useCallback(async (postId, isPublished) => {
    try {
      const post = posts.find(p => p.id === postId);
      if (!post) return;

      // Update the post's publish status using the correct API function
      await updatePostPublishStatus(activeTabId, postId, isPublished);

      // Update local state
      setPosts(prevPosts => 
        prevPosts.map(p => 
          p.id === postId 
            ? { ...p, published: isPublished, publishedAt: isPublished ? new Date() : null }
            : p
        )
      );

      addNotification(
        isPublished ? 'Post published successfully' : 'Post unpublished successfully',
        'success'
      );
    } catch (error) {
      console.error('Error updating publish state:', error);
      addNotification('Failed to update publish state', 'error');
    }
  }, [activeTabId, posts, addNotification]);

  // Add these state variables
  const [focusedPostId, setFocusedPostId] = useState(null);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Add this new function before the renderPost function
  const handleCreateNewPost = useCallback(async () => {
    if (!activeTabId) return;

    try {
      const uniqueId = generateUniqueId();
      const newPost = {
        id: uniqueId,
        content: 'Start writing your post here...',
        title: 'Untitled Post',
        width: getPostWidth(activeDevice),
        height: MIN_POST_HEIGHT,
        order: -Date.now(),
        timestamp: Date.now(),
        published: false,
        editState: {
          isEditedLocally: false
        },
        mediaFiles: [] // Initialize empty media files array
      };

      // Add post to canvas
      const updatedCanvas = await addPostToCanvas(activeTabId, newPost);
      
      if (updatedCanvas) {
        // Update local state
        const postsWithLayout = calculateLayout([newPost, ...posts]);
        setPosts(postsWithLayout);
        setNewestPostId(uniqueId);
        setEditingPostId(uniqueId); // Start editing the new post immediately
        setIsCanvasEmpty(false);

        // Update tabsState to include the new post
        setTabsState(prev => ({
          ...prev,
          [activeTabId]: {
            ...prev[activeTabId],
            posts: [newPost, ...(prev[activeTabId]?.posts || [])]
          }
        }));

        // Scroll to top where the new post will be
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }

        addNotification('New post created', 'success');
      }
    } catch (error) {
      console.error('Error creating new post:', error);
      addNotification('Failed to create new post', 'error');
    }
  }, [activeTabId, activeDevice, posts, addNotification, calculateLayout, getPostWidth]);

  const handleIconChange = useCallback(async (canvasId, newIcon) => {
    try {
      await updateCanvasIcon(canvasId, newIcon);
      
      // Update local state
      setTabs(prevTabs => 
        prevTabs.map(tab => 
          tab.id === canvasId 
            ? { ...tab, icon: newIcon }
            : tab
        )
      );

      // Update tabsState
      setTabsState(prev => ({
        ...prev,
        [canvasId]: {
          ...prev[canvasId],
          icon: newIcon
        }
      }));

      addNotification('Icon updated successfully', 'success');
    } catch (error) {
      console.error('Error updating canvas icon:', error);
      addNotification('Failed to update icon', 'error');
    }
  }, [addNotification]);

  return (
    <div 
      className={`w-full h-full relative flex flex-col rounded-lg ${
        isDarkMode ? 'bg-[#151515]' : 'bg-[#f8fafc]'
      }`}
      ref={canvasRef}
      style={{
        overflow: 'hidden',
        minWidth: isMobile ? '100%' : (isChatCollapsed ? '100%' : '400px'),
        maxWidth: '100%',
        flex: 1,
        transition: 'min-width 300ms ease-in-out',
      }}
    >
      <div className="flex-shrink-0 w-full min-w-0">
        <CanvasTopBar
          ref={canvasTopBarRef}
          tabs={tabs}
          activeTabId={activeTabId}
          onTabChange={handleTabChange}
          setShowWelcome={setShowWelcome}
          setActiveTabId={setActiveTabId}
          setTabsState={setTabsState}
          addNotification={addNotification}
          isChatCollapsed={isChatCollapsed}
          onToggleChat={onToggleChat}
          setTabs={setTabs}
          setPosts={setPosts}
          setCanvasTitle={setCanvasTitle}
          onHomeClick={handleHomeClick}
          onAddTab={handleAddTab}
          onRemoveTab={handleRemoveTab}
          onTabTitleChange={handleTabTitleChange}
          isHomeView={showWelcome}
          tabsState={tabsState}
          calculateLayout={calculateLayout}
          isMobile={isMobile}
          className={`${isMobile ? 'py-2 px-3' : 'py-3 px-4'}`}
        />
      </div>

      {/* Show NewPostButton for desktop and MobileBottomBar for mobile */}
      {!showWelcome && (
        <>
          <NewPostButton onClick={handleCreateNewPost} isDarkMode={isDarkMode} />
          {isMobile && <MobileBottomBar onClick={handleCreateNewPost} isDarkMode={isDarkMode} />}
        </>
      )}

      <div 
        ref={scrollContainerRef}
        className={`
          flex-1 canvas-scrollbar relative
          ${isDarkMode ? 'bg-[#151515]' : 'bg-[#f8fafc]'}
        `}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          overflowX: 'hidden',
          pointerEvents: 'auto'
        }}
      >
        <AnimatePresence mode="wait">
          {showWelcome ? (
            <motion.div 
              className="w-full h-full"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{ minHeight: '100%' }}
            >
              <CanvasWelcome
                onCreateCanvas={handleCreateCanvas}
                existingCanvases={existingCanvases}
                setExistingCanvases={setExistingCanvases}
                onSelectCanvas={handleSelectCanvas}
                addNotification={addNotification}
                isMobile={isMobile}
              />
            </motion.div>
          ) : isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <CanvasSkeleton isDarkMode={isDarkMode} count={3} />
            </div>
          ) : (
            <motion.div
              key="canvas"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="w-full h-full"
            >
              {posts.length === 0 ? (
                <div className="w-full h-full flex items-center justify-center" style={{ minHeight: 'calc(100vh - 120px)' }}>
                  <CanvasSkeleton 
                    isDarkMode={isDarkMode} 
                    isEmpty={!isLoading && !tabsState[activeTabId]?.posts?.length} 
                    isLoading={isLoading || (activeTabId && !tabsState[activeTabId])}
                  />
                </div>
              ) : viewMode === 'list' ? (
                <motion.div 
                  className={`
                    flex flex-col items-center gap-4 w-full
                    ${posts.length > 0 ? 'pt-4 pb-20' : ''}
                    mx-auto width-changing
                    ${isDarkMode ? 'bg-[#151515]' : 'bg-[#f8fafc]'}
                  `}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  <AnimatePresence mode="popLayout">
                    {posts.map((post, index) => (
                      <LinkedInPostMockup
                        key={post.id}
                        canvasContent={post}
                        setCanvasContent={(updatedPost) => {
                          setPosts(prevPosts => 
                            prevPosts.map(p => 
                              p.id === post.id ? { ...p, ...updatedPost } : p
                            )
                          );
                        }}
                        onRemove={handleRemovePost}
                        title={post.title}
                        onTitleChange={(newTitle) => handleTitleChange(post.id, newTitle)}
                        onContentChange={handleContentChange}
                        isDarkMode={isDarkMode}
                        isNewest={post.id === newestPostId}
                        onStartEditing={handleStartEditing}
                        onStopEditing={handleStopEditing}
                        isFocused={focusedPost?.id === post.id}
                        onExpand={() => handleExpandPost(post)}
                        onNavigateNext={() => handleNavigatePost(index, 'next')}
                        onNavigatePrevious={() => handleNavigatePost(index, 'previous')}
                        currentIndex={index + 1}
                        totalPosts={posts.length}
                        isInCanvasExperience={true}
                        focusBorderColor={post.borderColor}
                        removeBorderAndPadding={viewMode === 'masonry'}
                        isEditing={editingPostId === post.id}
                        onSave={() => handleSavePost(post.id)}
                        onCancel={handleCancelEdit}
                        hideTitleBar={false}
                        isSimplifiedView={true}
                        forceExpanded={false}
                        preventCollapse={false}
                        onRequestDelete={() => handleRequestDelete(post.id)}
                        onFocusMode={() => handleFocusMode(post)}
                        activeDevice={activeDevice}
                        canvasId={activeTabId}
                        onFullScreen={() => handleFullScreen(post)}
                        isPublished={post.published || false}
                        isEditedLocally={post.editState?.isEditedLocally}
                        onPublish={(status) => handlePublishStateChange(post.id, status)}
                        onSchedule={handleSchedulePost}
                        onIconChange={handleIconChange}
                      />
                    ))}
                  </AnimatePresence>
                </motion.div>
              ) : (
                <div className={`
                  w-full h-full p-0 pb-20
                  ${isDarkMode ? 'bg-[#151515]' : 'bg-[#f8fafc]'}
                `}>
                  <CanvasMasonryView
                    posts={posts}
                    activeDevice={activeDevice}
                    isDarkMode={isDarkMode}
                    onRemove={removePost}
                    newestPostId={newestPostId}
                    onContentChange={handleContentChange}
                    onTitleChange={handleTitleChange}
                    editingPostId={editingPostId}
                    onStartEditing={handleStartEditing}
                    onStopEditing={handleStopEditing}
                    onFocusMode={handleFocusMode}
                    isChatCollapsed={isChatCollapsed}
                    onFullScreen={handleFullScreen}
                  />
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <FloatingChatButton />

      {/* Update the container for CanvasFloatingBubbleMenu */}
      <div className="absolute bottom-0 left-0 right-0 pointer-events-none" style={{ zIndex: 50 }}>
        <CanvasFloatingBubbleMenu
          isDarkMode={isDarkMode}
          activeDevice={activeDevice}
          setActiveDevice={setActiveDevice}
          onDeviceChange={handleDeviceChange}
          viewMode={viewMode}
          onViewModeChange={handleViewModeChange}
          isWelcomeVisible={showWelcome}
        />
      </div>

      <CanvasFocusPost
        isOpen={focusedPost !== null}
        onClose={handleCloseFocusMode}
        content={focusedPost?.content || ''}
        onSave={(newContent) => {
          if (focusedPost) {
            handleContentChange(focusedPost.id, newContent);
            handleCloseFocusMode();
          }
        }}
        onCancel={handleCloseFocusMode}
        title={focusedPost?.title || ''}
      />

      {fullScreenPost && (
        <FullScreenCanvasPostContentEditor
          content={fullScreenPost.content}
          onSave={(newContent) => handleContentChange(fullScreenPost.id, newContent)}
          onClose={() => setFullScreenPost(null)}
          isOpen={!!fullScreenPost}
          postId={fullScreenPost.id}
          canvasId={activeTabId}
          currentIndex={posts.findIndex(p => p.id === fullScreenPost.id)}
          totalPosts={posts.length}
          title={fullScreenPost.title || 'Untitled Post'}
          isPublished={fullScreenPost.published || false}
          mediaFiles={fullScreenPost.mediaFiles || []}
        />
      )}

      {showFullScreenCanvas && (
        <div className="fixed inset-0 z-[70] w-full h-full">
          <div className="w-full h-full">
            <Canvas 
              isChatCollapsed={false}
              onToggleChat={() => setShowFullScreenCanvas(false)}
              isMobile={isMobile}
              style={{ 
                width: '100%',
                minWidth: '100%',
                maxWidth: '100%'
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

Canvas.propTypes = {
  isChatCollapsed: PropTypes.bool.isRequired,
  onToggleChat: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default Canvas;
