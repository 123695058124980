import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ALLOWED_ORIGIN = process.env.NODE_ENV === 'production'
  ? 'https://www.ammmplify.com'
  : 'http://localhost:3000';

const GoogleAuthPopupCallback = () => {
  const location = useLocation();
  const codeProcessed = useRef(false);

  useEffect(() => {
    const handleCallback = () => {
      try {
        // Prevent processing the same code multiple times
        if (codeProcessed.current) {
          console.log('Code already processed, skipping...');
          return;
        }

        // Get the authorization code from the URL
        const urlParams = new URLSearchParams(location.search);
        const code = urlParams.get('code');
        const error = urlParams.get('error');
        
        if (error) {
          const errorMessage = error === 'access_denied' 
            ? 'Authentication was cancelled by user'
            : 'Google authentication failed';
          
          window.opener?.postMessage({ 
            type: 'GOOGLE_AUTH_ERROR', 
            error: errorMessage
          }, ALLOWED_ORIGIN);
        } else if (!code) {
          window.opener?.postMessage({ 
            type: 'GOOGLE_AUTH_ERROR', 
            error: 'No authorization code received from Google' 
          }, ALLOWED_ORIGIN);
        } else {
          // Send the code back to the opener window
          window.opener?.postMessage({ 
            type: 'GOOGLE_AUTH_SUCCESS', 
            code 
          }, ALLOWED_ORIGIN);
          codeProcessed.current = true;
        }
      } catch (error) {
        console.error('Error in popup callback:', error);
        // Send a more descriptive error message
        window.opener?.postMessage({ 
          type: 'GOOGLE_AUTH_ERROR', 
          error: 'Failed to process Google authentication response' 
        }, ALLOWED_ORIGIN);
      } finally {
        try {
          // Try to close the window after ensuring message is sent
          setTimeout(() => {
            try {
              if (window.opener) {
                // First try to send a message to the parent to close us
                window.opener.postMessage({ 
                  type: 'CLOSE_POPUP'
                }, ALLOWED_ORIGIN);
                
                // Fallback attempt to close directly with error handling
                try {
                  window.close();
                } catch (closeError) {
                  console.log('Could not close popup window directly:', closeError);
                  // Display a message to the user
                  document.getElementById('close-message').style.display = 'block';
                }
              }
            } catch (closeError) {
              console.warn('Could not close popup window:', closeError);
              // Display a message to the user
              document.getElementById('close-message').style.display = 'block';
            }
          }, 1000);
        } catch (error) {
          console.warn('Error in cleanup:', error);
        }
      }
    };

    handleCallback();
  }, [location]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="p-8 bg-white rounded-lg shadow-md">
        <div className="flex flex-col items-center space-y-4">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          <p className="text-gray-600">Processing Google authentication...</p>
          <p id="close-message" className="text-sm text-gray-500" style={{ display: 'none' }}>
            Authentication complete. You can close this window and return to the app.
          </p>
          <button 
            onClick={() => window.close()} 
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Close Window
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleAuthPopupCallback; 