import React, { useCallback } from 'react';
import { 
  DevicePhoneMobileIcon, 
  DeviceTabletIcon, 
  ComputerDesktopIcon,
  Squares2X2Icon,
  RectangleStackIcon
} from '@heroicons/react/24/outline';
import { DEVICE_CONFIGS } from '../constants/deviceConfig';
import PropTypes from 'prop-types';
import './CanvasFloatingBubbleMenu.css';

const CanvasFloatingBubbleMenu = ({
  isDarkMode,
  activeDevice,
  setActiveDevice,
  onDeviceChange,
  viewMode,
  onViewModeChange,
  isWelcomeVisible
}) => {
  const handleDeviceChange = useCallback((device) => {
    setActiveDevice(device);
    if (onDeviceChange) {
      onDeviceChange(device);
    }
  }, [setActiveDevice, onDeviceChange]);

  const handleViewModeToggle = useCallback(() => {
    const newMode = viewMode === 'list' ? 'masonry' : 'list';
    onViewModeChange(newMode);
  }, [viewMode, onViewModeChange]);

  const isMobileView = window.innerWidth <= 768;
  
  if (isWelcomeVisible || isMobileView) {
    return null;
  }

  const deviceInfo = {
    mobile: '409px width',
    tablet: '471px width',
    desktop: '555px width'
  };

  return (
    <div className={`canvas-floating-menu ${isDarkMode ? 'canvas-floating-menu-dark' : 'canvas-floating-menu-light'}`}>
      <div className="device-buttons">
        <button
          onClick={() => handleDeviceChange('mobile')}
          className={`menu-button ${isDarkMode ? 'menu-button-dark' : 'menu-button-light'} ${activeDevice === 'mobile' ? 'active' : ''}`}
          title={deviceInfo.mobile}
        >
          <DevicePhoneMobileIcon className="menu-icon" />
          <span className={`menu-tooltip ${isDarkMode ? 'menu-tooltip-dark' : 'menu-tooltip-light'}`}>
            {deviceInfo.mobile}
          </span>
        </button>
        <button
          onClick={() => handleDeviceChange('tablet')}
          className={`menu-button ${isDarkMode ? 'menu-button-dark' : 'menu-button-light'} ${activeDevice === 'tablet' ? 'active' : ''}`}
          title={deviceInfo.tablet}
        >
          <DeviceTabletIcon className="menu-icon" />
          <span className={`menu-tooltip ${isDarkMode ? 'menu-tooltip-dark' : 'menu-tooltip-light'}`}>
            {deviceInfo.tablet}
          </span>
        </button>
        <button
          onClick={() => handleDeviceChange('desktop')}
          className={`menu-button ${isDarkMode ? 'menu-button-dark' : 'menu-button-light'} ${activeDevice === 'desktop' ? 'active' : ''}`}
          title={deviceInfo.desktop}
        >
          <ComputerDesktopIcon className="menu-icon" />
          <span className={`menu-tooltip ${isDarkMode ? 'menu-tooltip-dark' : 'menu-tooltip-light'}`}>
            {deviceInfo.desktop}
          </span>
        </button>
      </div>

      <div className={`menu-divider ${isDarkMode ? 'menu-divider-dark' : 'menu-divider-light'}`} />

      <div className="view-mode-buttons">
        <button
          onClick={handleViewModeToggle}
          className={`menu-button ${isDarkMode ? 'menu-button-dark' : 'menu-button-light'} ${viewMode === 'list' ? 'active' : ''}`}
          title={viewMode === 'list' ? 'Switch to Masonry View' : 'Switch to List View'}
        >
          <RectangleStackIcon className="menu-icon" />
        </button>
        <button
          onClick={() => onViewModeChange('masonry')}
          className={`menu-button ${isDarkMode ? 'menu-button-dark' : 'menu-button-light'} ${viewMode === 'masonry' ? 'active' : ''}`}
          title="Masonry View"
        >
          <Squares2X2Icon className="menu-icon" />
        </button>
      </div>
    </div>
  );
};

CanvasFloatingBubbleMenu.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
  activeDevice: PropTypes.string.isRequired,
  setActiveDevice: PropTypes.func.isRequired,
  onDeviceChange: PropTypes.func,
  viewMode: PropTypes.string.isRequired,
  onViewModeChange: PropTypes.func.isRequired,
  isWelcomeVisible: PropTypes.bool.isRequired
};

export default CanvasFloatingBubbleMenu;
