import React from 'react';
import { motion } from 'framer-motion';
import { SwatchIcon, DocumentTextIcon as FontIcon, ArrowsPointingOutIcon, CubeIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import '../../styles/brand-guidelines.css';

const ColorSwatch = ({ color, name, hex }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="flex flex-col items-start">
      <div 
        className="w-20 h-20 rounded-md shadow-md mb-2 border transition-colors" 
        style={{ 
          backgroundColor: hex,
          borderColor: isDarkMode ? '#333333' : '#e5e7eb' 
        }}
      />
      <span className="font-medium text-sm">{name}</span>
      <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{hex}</span>
    </div>
  );
};

const BrandGuidelines = () => {
  const { isDarkMode } = useTheme();
  
  // Use CSS variables from brand-guidelines.css
  const colors = {
    primary: [
      { name: 'Primary', hex: 'var(--color-primary)' },
      { name: 'Secondary', hex: 'var(--color-secondary)' },
    ],
    lightMode: [
      { name: 'Text', hex: 'var(--color-text-light)' },
      { name: 'Background', hex: 'var(--color-bg-light)' },
      { name: 'Border', hex: 'var(--color-border-light)' },
    ],
    darkMode: [
      { name: 'Text', hex: 'var(--color-text-dark)' },
      { name: 'Background', hex: 'var(--color-bg-dark)' },
      { name: 'Border', hex: 'var(--color-border-dark)' },
    ],
    accent: [
      { name: 'Blue', hex: 'var(--accent-blue)' },
      { name: 'Green', hex: 'var(--accent-green)' },
      { name: 'Red', hex: 'var(--accent-red)' },
      { name: 'Yellow', hex: 'var(--accent-yellow)' },
      { name: 'Purple', hex: 'var(--accent-purple)' },
    ],
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="space-y-16"
      >
        {/* Header */}
        <div className="text-center">
          <h1 className="text-4xl font-bold mb-4">Brand Guidelines</h1>
          <p className={isDarkMode ? 'text-gray-400' : 'text-gray-600'}>
            A comprehensive guide to our visual identity and design system
          </p>
        </div>

        {/* Colors */}
        <section>
          <div className="flex items-center mb-8">
            <SwatchIcon className="w-6 h-6 mr-2" />
            <h2 className="text-2xl font-semibold">Colors</h2>
          </div>
          
          <div className="space-y-12">
            {Object.entries(colors).map(([category, swatches]) => (
              <div key={category}>
                <h3 className="text-lg font-medium mb-6 capitalize">
                  {category.replace(/([A-Z])/g, ' $1').trim()}
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
                  {swatches.map((swatch) => (
                    <ColorSwatch key={swatch.hex} {...swatch} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Typography */}
        <section>
          <div className="flex items-center mb-8">
            <FontIcon className="w-6 h-6 mr-2" />
            <h2 className="text-2xl font-semibold">Typography</h2>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-lg font-medium mb-4">Font Sizes</h3>
              <div className="space-y-4">
                {['xs', 'sm', 'base', 'lg', 'xl'].map((size) => (
                  <div key={size} className={`text-${size} ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Text {size}: The quick brown fox jumps over the lazy dog
                  </div>
                ))}
              </div>
            </div>
            
            <div>
              <h3 className="text-lg font-medium mb-4">Line Heights</h3>
              <div className="space-y-4">
                {['tight', 'normal', 'relaxed'].map((height) => (
                  <div 
                    key={height} 
                    className={`leading-${height} border-l-2 pl-4 ${
                      isDarkMode ? 'border-gray-700 text-gray-300' : 'border-gray-300 text-gray-700'
                    }`}
                  >
                    Line height {height}: The quick brown fox jumps over the lazy dog
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Spacing */}
        <section>
          <div className="flex items-center mb-8">
            <ArrowsPointingOutIcon className="w-6 h-6 mr-2" />
            <h2 className="text-2xl font-semibold">Spacing</h2>
          </div>
          
          <div className="space-y-4">
            {['xs', 'sm', 'md', 'lg', 'xl'].map((size) => (
              <div key={size} className="flex items-center">
                <span className="w-16">{size}:</span>
                <div 
                  className={`h-4 rounded transition-colors ${
                    isDarkMode ? 'bg-indigo-900' : 'bg-indigo-200'
                  }`}
                  style={{ width: `var(--spacing-${size})` }}
                />
                <span className={`ml-4 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {`var(--spacing-${size})`}
                </span>
              </div>
            ))}
          </div>
        </section>

        {/* Components */}
        <section>
          <div className="flex items-center mb-8">
            <CubeIcon className="w-6 h-6 mr-2" />
            <h2 className="text-2xl font-semibold">Components</h2>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Buttons */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium mb-4">Buttons</h3>
              <button className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition-colors">
                Primary Button
              </button>
              <button className={`px-4 py-2 rounded-md transition-colors border ${
                isDarkMode 
                  ? 'border-[#333333] hover:bg-[#333333]' 
                  : 'border-gray-300 hover:bg-gray-50'
              }`}>
                Secondary Button
              </button>
            </div>

            {/* Cards */}
            <div>
              <h3 className="text-lg font-medium mb-4">Cards</h3>
              <div className={`p-4 rounded-lg shadow-sm border ${
                isDarkMode 
                  ? 'border-[#333333] bg-[#1a1a1a]' 
                  : 'border-gray-200 bg-white'
              }`}>
                <h4 className="font-medium mb-2">Card Title</h4>
                <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                  Card content goes here
                </p>
              </div>
            </div>

            {/* Inputs */}
            <div>
              <h3 className="text-lg font-medium mb-4">Inputs</h3>
              <input 
                type="text" 
                placeholder="Input field"
                className={`w-full px-3 py-2 rounded-md transition-colors ${
                  isDarkMode 
                    ? 'bg-[#1a1a1a] border-[#333333] focus:border-indigo-500 text-gray-200' 
                    : 'bg-white border-gray-300 focus:border-indigo-500 text-gray-900'
                } border focus:ring-2 focus:ring-indigo-500`}
              />
            </div>
          </div>
        </section>
      </motion.div>
    </div>
  );
};

export default BrandGuidelines; 