import React, { useState } from 'react';
import { motion } from 'framer-motion';
import PublicPageLayout from './layout/PublicPageLayout';
import PageSection from './shared/PageSection';
import { useTheme } from '../../context/ThemeContext';
import { 
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  ExclamationTriangleIcon,
  SparklesIcon,
  LightBulbIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowRightIcon
} from '@heroicons/react/24/outline';

const ContactPage = () => {
  const { isDarkMode } = useTheme();
  const [formData, setFormData] = useState({
    name: '', email: '', category: '', message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const contactCategories = [
    {
      value: 'technical',
      label: 'Technical Support',
      icon: ExclamationTriangleIcon
    },
    {
      value: 'billing',
      label: 'Account & Billing',
      icon: QuestionMarkCircleIcon
    },
    {
      value: 'feature',
      label: 'Feature Request',
      icon: SparklesIcon
    },
    {
      value: 'content',
      label: 'Content Help',
      icon: LightBulbIcon
    },
    {
      value: 'other',
      label: 'Other',
      icon: ArrowRightIcon
    }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);
    
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setFormData({ name: '', email: '', category: '', message: '' });
      setSubmitStatus('success');
    } catch (error) {
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <PublicPageLayout
      title="Contact"
      description="Get help with your account or technical issues"
      className="-mt-8"
    >
      <PageSection className="py-4">
        <motion.form
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          onSubmit={handleSubmit}
          className={`
            max-w-xl mx-auto p-5 rounded-xl
            ${isDarkMode 
              ? 'bg-[#151515] border border-white/10' 
              : 'bg-white border border-slate-200/50'
            }
            shadow-sm
          `}
        >
          {/* Status Messages */}
          {submitStatus && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={`
                mb-4 p-3 rounded-lg text-sm flex items-center gap-2
                ${submitStatus === 'success' 
                  ? isDarkMode ? 'bg-green-500/10 text-green-400' : 'bg-green-50 text-green-600'
                  : isDarkMode ? 'bg-red-500/10 text-red-400' : 'bg-red-50 text-red-600'
                }
              `}
            >
              {submitStatus === 'success' 
                ? <><CheckCircleIcon className="w-4 h-4" /> Message sent successfully</>
                : <><XCircleIcon className="w-4 h-4" /> Failed to send message</>
              }
            </motion.div>
          )}

          {/* Category Selection */}
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mb-4">
            {contactCategories.map(category => (
              <label
                key={category.value}
                className={`
                  relative flex items-center gap-2 p-2 rounded-lg cursor-pointer
                  ${formData.category === category.value
                    ? isDarkMode
                      ? 'bg-blue-500/10 ring-1 ring-blue-500/30'
                      : 'bg-blue-50 ring-1 ring-blue-200'
                    : isDarkMode
                      ? 'bg-[#1a1a1a] hover:bg-[#202020]'
                      : 'bg-white hover:bg-slate-50'
                  }
                  border border-transparent transition-all duration-200
                `}
              >
                <input
                  type="radio"
                  name="category"
                  value={category.value}
                  checked={formData.category === category.value}
                  onChange={handleChange}
                  className="sr-only"
                />
                <category.icon className={`w-4 h-4 ${
                  formData.category === category.value ? 'text-blue-500' : ''
                }`} />
                <span className="text-xs">{category.label}</span>
              </label>
            ))}
          </div>

          {/* Contact Form */}
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
                className={`
                  w-full px-3 py-2 rounded-lg text-sm
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] border-white/10 text-white' 
                    : 'bg-white border-slate-200'
                  }
                  border focus:ring-1 focus:ring-blue-500/30
                `}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
                className={`
                  w-full px-3 py-2 rounded-lg text-sm
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] border-white/10 text-white' 
                    : 'bg-white border-slate-200'
                  }
                  border focus:ring-1 focus:ring-blue-500/30
                `}
              />
            </div>

            <textarea
              name="message"
              placeholder="How can we help?"
              value={formData.message}
              onChange={handleChange}
              required
              rows={4}
              className={`
                w-full px-3 py-2 rounded-lg text-sm
                ${isDarkMode 
                  ? 'bg-[#1a1a1a] border-white/10 text-white' 
                  : 'bg-white border-slate-200'
                }
                border focus:ring-1 focus:ring-blue-500/30
              `}
            />

            <button
              type="submit"
              disabled={isSubmitting}
              className={`
                w-full py-2 px-4 rounded-lg text-sm font-medium
                text-white transition-all duration-200
                ${isSubmitting 
                  ? 'bg-blue-400 cursor-not-allowed' 
                  : 'bg-blue-500 hover:bg-blue-600'
                }
                flex items-center justify-center gap-2
              `}
            >
              {isSubmitting ? (
                <>
                  <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  <span>Sending...</span>
                </>
              ) : 'Send Message'}
            </button>
          </div>
        </motion.form>

        <div className="mt-3 flex items-center justify-center gap-2 text-xs">
          <EnvelopeIcon className="w-4 h-4" />
          <span className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>
            support@ammmplify.com
          </span>
        </div>
      </PageSection>
    </PublicPageLayout>
  );
};

export default ContactPage; 