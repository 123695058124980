import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import '../styles/markdown.css';
import { ClipboardIcon, CheckIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { useChat } from '../context/ChatContext';
import LinkedInPreviewMockup from './LinkedInPreviewMockup';
import VideoScriptMacro from './VideoScriptMacro';
import AIAgentMacro from './AIAgentMacro';
import { useRecentActivities } from '../context/RecentActivitiesContext';

// Special identifier for video scripts (must match backend exactly)
const VIDEO_SCRIPT_IDENTIFIER = '🎬 VIDEO_SCRIPT_START 🎬';

// ------------------------------------------------------------------------
// ButtonGroup Component
// Renders copy and "Add to Collection" buttons with state-dependent styling
// ------------------------------------------------------------------------
const ButtonGroup = React.memo(({ isDarkMode, isCopied, onCopy, onAddToCanvas, addCount, activeTabId }) => {
  // Get appropriate button text based on state
  const getAddButtonText = () => {
    if (!activeTabId) return "Open a Collection first";
    if (addCount === 0) return "Add to Collection";
    if (addCount === 1) return "Added once";
    return `Added ${addCount}x`;
  };

  return (
    <div className="absolute top-2 right-2 flex space-x-1 p-1 z-10 opacity-0 group-hover:opacity-100 transition-opacity">
      <button
        onClick={onCopy}
        className={`
          p-1.5 rounded-md transition-all duration-200
          ${isCopied 
            ? isDarkMode 
              ? 'bg-green-500/20 text-green-400' 
              : 'bg-green-100 text-green-600'
            : isDarkMode 
              ? 'bg-[#252525] hover:bg-[#333333] text-gray-400 hover:text-gray-300' 
              : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700'
          }
        `}
        title="Copy code"
      >
        <motion.div
          initial={false}
          animate={{
            scale: isCopied ? [1, 1.2, 1] : 1,
          }}
          transition={{ duration: 0.3 }}
        >
          {isCopied ? (
            <CheckIcon className="w-3.5 h-3.5" />
          ) : (
            <ClipboardIcon className="w-3.5 h-3.5" />
          )}
        </motion.div>
      </button>

      <button
        onClick={activeTabId ? onAddToCanvas : undefined}
        disabled={!activeTabId}
        className={`
          rounded-md px-2 py-1.5 transition-all duration-200 
          flex items-center gap-1.5 text-xs font-medium
          ${isDarkMode 
            ? !activeTabId
              ? 'bg-slate-800/80 text-slate-600 cursor-not-allowed'
              : addCount > 0
                ? 'bg-purple-500/20 text-purple-400 hover:bg-purple-500/30' 
                : 'bg-[#252525] hover:bg-[#333333] text-gray-400 hover:text-gray-300'
            : !activeTabId
              ? 'bg-gray-100/80 text-gray-400 cursor-not-allowed'
              : addCount > 0
                ? 'bg-purple-100 text-purple-600 hover:bg-purple-200' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700'
          }
        `}
        title={getAddButtonText()}
        aria-label={getAddButtonText()}
      >
        <PlusCircleIcon className={`h-3.5 w-3.5 transition-transform duration-200 ${activeTabId ? 'group-hover:rotate-90' : ''}`} />
        <span className="whitespace-nowrap">{getAddButtonText()}</span>
      </button>
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.isDarkMode === nextProps.isDarkMode && 
         prevProps.isCopied === nextProps.isCopied &&
         prevProps.addCount === nextProps.addCount &&
         prevProps.activeTabId === nextProps.activeTabId;
});

ButtonGroup.displayName = 'ButtonGroup';

// ------------------------------------------------------------------------
// CodeBlockWithCopy Component
// Handles rendering of code blocks with copy functionality and LinkedIn
// post preview integration
// ------------------------------------------------------------------------
const CodeBlockWithCopy = React.memo(({ language, value, isDarkMode, index, copiedMessages, onCopy }) => {
  const { addToCanvas, selectedPersona, activeTabId } = useChat();
  const { refreshActivities } = useRecentActivities();
  const [isCopied, setIsCopied] = useState(false);
  const [addCount, setAddCount] = useState(0);

  // Simplified LinkedIn post detection
  const isLinkedInPost = 
    !language || 
    ['text', 'linkedin', 'linkedin-post', 'post'].includes(language.toLowerCase());

  // Copy Handler
  const handleCopy = useCallback(async (e) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(value);
      setIsCopied(true);
      
      toast.success('Code copied!', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });

      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy code', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });
    }
  }, [value, isDarkMode]);

  // Canvas Addition Handler
  const handleAddToCanvas = useCallback(async () => {
    try {
      await addToCanvas(value, addCount > 0, {
        immediate: true,
        tempTitle: 'Generating title...'
      });
      setAddCount(prevCount => prevCount + 1);
      
      toast.success(addCount === 0 ? 'Added to collection!' : 'Added to collection again!', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });
    } catch (error) {
      console.error('Error adding to canvas:', error);
      toast.error('Failed to add to collection', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });
    }
  }, [value, addToCanvas, addCount, isDarkMode]);

  const memoizedButtonGroup = useMemo(() => (
    <ButtonGroup
      isDarkMode={isDarkMode}
      isCopied={isCopied}
      onCopy={handleCopy}
      onAddToCanvas={handleAddToCanvas}
      addCount={addCount}
      activeTabId={activeTabId}
    />
  ), [isDarkMode, isCopied, handleCopy, handleAddToCanvas, addCount, activeTabId]);

  // If it's wrapped in ``` without a specific language or with linkedin/text/post identifier,
  // render as LinkedIn preview
  if (isLinkedInPost) {
    return (
      <div className="relative group mt-4 mb-4">
        {memoizedButtonGroup}
        <LinkedInPreviewMockup
          selectedPersona={selectedPersona}
          content={value}
          isDarkMode={isDarkMode}
        />
      </div>
    );
  }

  // Regular code block rendering
  return (
    <div className={`relative group rounded-md transition-colors duration-200 ${
      isDarkMode 
        ? 'hover:bg-slate-800/50' 
        : 'hover:bg-slate-50'
    }`}>
      {memoizedButtonGroup}
      <pre className={`rounded-md mb-4 overflow-x-auto text-xs markdown-pre ${
        isDarkMode ? 'bg-slate-800' : 'bg-gray-50'
      }`}>
        <code className={`language-${language} markdown-code block`}>
          {value}
        </code>
      </pre>
    </div>
  );
});

CodeBlockWithCopy.displayName = 'CodeBlockWithCopy';

CodeBlockWithCopy.propTypes = {
  language: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  copiedMessages: PropTypes.object.isRequired,
  onCopy: PropTypes.func.isRequired,
};

// ------------------------------------------------------------------------
// Main ChatMessageMarkdown Component
// Configures markdown rendering with custom components and styling
// ------------------------------------------------------------------------
const ChatMessageMarkdown = ({ content, isDarkMode, onSaveToTextNotes, index, copiedMessages, onCopy }) => {
  // Enhanced content extraction
  const extractContent = (input) => {
    if (typeof input === 'string') return input;
    if (typeof input?.content === 'string') return input.content;
    if (typeof input?.message?.content === 'string') return input.message.content;
    return '';
  };

  const messageContent = extractContent(content);

  // Enhanced content extraction for video scripts
  const extractVideoScriptContent = (content) => {
    if (!content) return null;

    // Split content by video script markers
    const parts = content.split(/(\*{6}[\s\S]*?\*{6})/);
    
    if (parts.length === 1) return null; // No video scripts found

    const result = {
      regularContent: [],
      videoScripts: []
    };

    parts.forEach((part, index) => {
      if (part.startsWith('******')) {
        // Extract video script content (remove the stars)
        const scriptContent = part.replace(/^\*{6}/, '').replace(/\*{6}$/, '').trim();
        
        // Check if this is a properly formatted video script
        const hasScriptSections = /\[(TITLE|OPENING|MAIN CONTENT|CLOSING)\]/.test(scriptContent);
        
        if (hasScriptSections) {
          result.videoScripts.push(scriptContent);
        } else {
          // If it's not a properly formatted script, treat it as regular content
          result.regularContent.push(part);
        }
      } else if (part.trim()) {
        // Add non-empty regular content
        result.regularContent.push(part.trim());
      }
    });

    return {
      regularContent: result.regularContent.join('\n\n'),
      videoScripts: result.videoScripts
    };
  };

  // Check for content wrapped in six hashes (for AI agent output)
  const extractAgentContent = (content) => {
    const agentPattern = /#{6}([\s\S]*?)#{6}/;
    const match = content.match(agentPattern);
    return match ? {
      agentContent: match[1].trim(),
      regularContent: content.replace(agentPattern, '').trim()
    } : null;
  };

  // Move all useMemo hooks to the top
  const agentData = useMemo(() => {
    return extractAgentContent(messageContent);
  }, [messageContent]);

  const videoScriptData = useMemo(() => {
    return extractVideoScriptContent(messageContent);
  }, [messageContent]);

  const MarkdownComponents = useMemo(() => ({
    // Add link handling
    a: ({ node, href, children, ...props }) => {
      return (
        <a 
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={`${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'}`}
          {...props}
        >
          {children}
        </a>
      );
    },
    
    // Paragraph handling
    p: ({ children, ...props }) => {
      const isInListItem = props.node?.parent?.type === 'listItem';
      
      return (
        <p 
          className={`markdown-paragraph ${isInListItem ? 'list-item-paragraph' : ''}`}
          style={isInListItem ? { 
            fontFamily: "'Inter', sans-serif",
            fontSize: '13px',
            color: 'inherit'
          } : undefined}
          {...props}
        >
          {children}
        </p>
      );
    },
    
    // Enhanced list handling
    ul: ({ children, ordered, ...props }) => (
      <ul className="markdown-list markdown-ul" {...props}>
        {children}
      </ul>
    ),
    ol: ({ children, ordered, ...props }) => (
      <ol className="markdown-list markdown-ol" {...props}>
        {children}
      </ol>
    ),
    li: ({ children, ...props }) => {
      return (
        <li className="markdown-list-item">
          {children}
        </li>
      );
    },
    
    // Keep existing code block handling
    code: ({ node, inline, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || '');
      const language = match ? match[1] : '';
      if (!inline) {
        return (
          <CodeBlockWithCopy
            language={language}
            value={String(children).replace(/\n$/, '')}
            isDarkMode={isDarkMode}
            index={index}
            copiedMessages={copiedMessages}
            onCopy={onCopy}
          />
        );
      }
      return (
        <code 
          className={`rounded px-1 py-0.5 text-sm markdown-code ${
            isDarkMode ? 'bg-slate-700' : 'bg-blue-200'
          }`} 
          {...props}
        >
          {children}
        </code>
      );
    },
  }), [isDarkMode, index, copiedMessages, onCopy]);

  // Check for agent content first (wrapped in six hashes)
  if (agentData) {
    return (
      <>
        {agentData.regularContent && (
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={MarkdownComponents}
            className={`markdown-content chat-message-content ${isDarkMode ? 'dark' : 'light'}`}
          >
            {agentData.regularContent}
          </ReactMarkdown>
        )}
        <AIAgentMacro
          content={agentData.agentContent}
          isDarkMode={isDarkMode}
        />
      </>
    );
  }

  // Video script rendering
  if (videoScriptData) {
    return (
      <>
        {videoScriptData.regularContent && (
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={MarkdownComponents}
            className={`markdown-content chat-message-content ${isDarkMode ? 'dark' : 'light'}`}
          >
            {videoScriptData.regularContent}
          </ReactMarkdown>
        )}
        {videoScriptData.videoScripts.map((script, index) => (
          <VideoScriptMacro 
            key={index}
            content={script} 
            isDarkMode={isDarkMode} 
          />
        ))}
      </>
    );
  }

  return (
    <ReactMarkdown 
      remarkPlugins={[remarkGfm]}
      components={MarkdownComponents}
      className={`markdown-content chat-message-content ${isDarkMode ? 'dark' : 'light'}`}
    >
      {messageContent}
    </ReactMarkdown>
  );
};

ChatMessageMarkdown.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      content: PropTypes.string,
      message: PropTypes.shape({
        content: PropTypes.string
      })
    })
  ]).isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  onSaveToTextNotes: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  copiedMessages: PropTypes.object.isRequired,
  onCopy: PropTypes.func.isRequired
};

export default React.memo(ChatMessageMarkdown);
