import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '../context/ThemeContext';
import { useAuth } from '../context/AuthContext';
import { 
  PencilSquareIcon, 
  GlobeAltIcon,
  Squares2X2Icon,
  ChatBubbleBottomCenterTextIcon,
  DocumentTextIcon,
  MicrophoneIcon,
  BookmarkIcon,
  CpuChipIcon,
  PlusIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import styles from './WelcomeScreen.module.css';
import WelcomeScreenSkeleton from './WelcomeScreenSkeleton';
import NewChatIcon from './icons/NewChatIcon';
import { CanvasGridIcon } from './icons/CanvasGridIcon';

// Function to get time-based greeting
const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour >= 5 && hour < 12) return 'Good morning';
  if (hour >= 12 && hour < 17) return 'Good afternoon';
  if (hour >= 17 && hour < 20) return 'Good evening';
  if (hour >= 20 && hour < 23) return 'Happy night';
  return 'Happy late night';
};

const getRandomFeatures = (features, count = 6) => {
  const isMobile = window.innerWidth < 768;
  return features.slice(0, isMobile ? 4 : count);
};

const FeatureButton = ({ feature, onClick, index }) => {
  const { isDarkMode } = useTheme();
  
  const getBackgroundColor = (color) => {
    const colorMap = {
      'text-blue-500': isDarkMode ? 'bg-blue-500/[0.03] border-blue-500/[0.07]' : 'bg-blue-50/50 border-blue-200/[0.2]',
      'text-purple-500': isDarkMode ? 'bg-purple-500/[0.03] border-purple-500/[0.07]' : 'bg-purple-50/50 border-purple-200/[0.2]',
      'text-green-500': isDarkMode ? 'bg-green-500/[0.03] border-green-500/[0.07]' : 'bg-green-50/50 border-green-200/[0.2]',
      'text-indigo-500': isDarkMode ? 'bg-indigo-500/[0.03] border-indigo-500/[0.07]' : 'bg-indigo-50/50 border-indigo-200/[0.2]',
      'text-orange-500': isDarkMode ? 'bg-orange-500/[0.03] border-orange-500/[0.07]' : 'bg-orange-50/50 border-orange-200/[0.2]',
      'text-teal-500': isDarkMode ? 'bg-teal-500/[0.03] border-teal-500/[0.07]' : 'bg-teal-50/50 border-teal-200/[0.2]',
    };
    return colorMap[color] || (isDarkMode ? 'bg-gray-500/[0.03] border-gray-500/[0.07]' : 'bg-gray-50/50 border-gray-200/[0.2]');
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClick(feature);
    }
  };
  
  return (
    <button
      onClick={() => onClick(feature)}
      onKeyDown={handleKeyDown}
      className={`
        ${styles.suggestion}
        ${isDarkMode ? styles.darkMode : ''}
        flex flex-col p-2 rounded-lg w-full
        ${getBackgroundColor(feature.iconColor)}
        cursor-default
        transition-all duration-200
        hover:translate-y-[-1px]
        border
      `}
      tabIndex={0}
      role="option"
      aria-selected="false"
      title={feature.description}
    >
      <div className="flex items-center gap-2">
        <feature.icon className={`w-4 h-4 ${feature.iconColor}`} />
        <span className={`text-xs font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          {feature.title}
        </span>
      </div>
      <p className={`text-[10px] mt-0.5 ml-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
        {feature.description}
      </p>
    </button>
  );
};

FeatureButton.propTypes = {
  feature: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    iconColor: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const WelcomeScreen = ({ onActionSelect }) => {
  const navigate = useNavigate();
  const [isNavigating, setIsNavigating] = useState(false);
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [greeting, setGreeting] = useState(getGreeting());

  // Update greeting every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setGreeting(getGreeting());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const allFeatures = [
    {
      title: 'Content Studio',
      description: 'One chat interface to create content for your LinkedIn',
      icon: NewChatIcon,
      iconColor: 'text-blue-500',
      type: 'post'
    },
    {
      title: 'Collections',
      description: 'Manage, edit & schedule your LinkedIn posts',
      icon: CanvasGridIcon,
      iconColor: 'text-rose-500',
      type: 'collections'
    },
    {
      title: 'Personas & Audiences',
      description: 'Hyper-personalize your brand',
      icon: UserGroupIcon,
      iconColor: 'text-purple-500',
      type: 'personas'
    },
    {
      title: 'Context Superpowers',
      description: 'Break "generic" AI barrier with context for every post',
      icon: PlusIcon,
      iconColor: 'text-orange-500',
      type: 'context'
    },

        {
      title: 'Custom Templates',
      description: 'Transform your training data into custom AI apps',
      icon: Squares2X2Icon,
      iconColor: 'text-green-500',
      type: 'templates'
    },
    {
      title: 'Ideation Tools',
      description: 'Generate ideas with AI, subreddits, and more',
      icon: ChatBubbleBottomCenterTextIcon,
      iconColor: 'text-teal-500',
      type: 'comments'
    }
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const features = useMemo(() => {
    const isMobile = windowWidth < 768;
    return getRandomFeatures(allFeatures, isMobile ? 4 : 6);
  }, [windowWidth]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000));
      } catch (error) {
        console.error('Error loading welcome screen:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const handleFeatureClick = async (feature) => {
    if (!isNavigating) {
      setIsNavigating(true);
      try {
        onActionSelect && onActionSelect(feature.type, feature.title);
        await navigate('/chat');
      } finally {
        setIsNavigating(false);
      }
    }
  };

  if (isLoading) {
    return <WelcomeScreenSkeleton />;
  }

  return (
    <div className={`flex flex-col h-full justify-center py-6 ${
      isDarkMode ? 'text-gray-200' : 'text-gray-600'
    } ${styles.container}`} role="main">
      <div className="max-w-xl w-full mx-auto px-4">
        <div className="text-left mb-6">
          <h1 className={`text-lg font-medium mb-1 ${styles.greeting} ${
            isDarkMode ? 'text-white' : 'text-gray-900'
          }`}>
            {greeting}, {user?.firstName ? `${user.firstName} ${user?.lastName || ''}` : 'there'}!
          </h1>
          <p className={`text-xs tracking-tight ${styles.subtitle} ${
            isDarkMode ? 'text-gray-400' : 'text-gray-500'
          }`}>
            I'm your <span className="font-medium bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent hover:animate-gradient-wave hover:bg-[length:200%_200%] transition-all duration-500 cursor-default">Personal AI Cowriter</span> to transform your ideas / thoughts / notes / transcripts into viral-worthy LinkedIn content
          </p>
        </div>

        <div className={`${isNavigating ? 'opacity-70 pointer-events-none' : ''}`}>
          <div className={`${styles.grid} ${
            windowWidth < 768 ? 'grid-cols-1' : 'grid-cols-2'
          }`}>
            {features.map((feature, index) => (
              <button
                key={index}
                onClick={() => handleFeatureClick(feature)}
                className={`p-3 rounded-lg text-left transition-all cursor-default ${
                  styles.card
                } ${
                  isDarkMode ? styles.cardDark : styles.cardLight
                }`}
              >
                <div className="flex items-start gap-2">
                  <feature.icon className={`w-4 h-4 mt-0.5 flex-shrink-0 ${feature.iconColor}`} />
                  <div>
                    <div className={`text-xs font-medium ${
                      isDarkMode ? 'text-white' : 'text-gray-900'
                    }`}>
                      {feature.title}
                    </div>
                    <div className={`text-[10px] mt-0.5 ${
                      isDarkMode ? 'text-gray-400' : 'text-gray-500'
                    }`}>
                      {feature.description}
                    </div>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;