import { API_BASE_URL } from '../config/constants';

/**
 * Get authentication token from storage
 */
export const getAuthToken = () => {
  // Try session storage first
  const possibleTokenKeys = ['token', 'auth_token', 'jwt_token', 'accessToken'];
  let token = null;
  
  // Check session storage
  for (const key of possibleTokenKeys) {
    const sessionToken = sessionStorage.getItem(key);
    if (sessionToken) {
      token = sessionToken;
      break;
    }
  }
  
  // If not in session storage, try local storage
  if (!token) {
    for (const key of possibleTokenKeys) {
      const localToken = localStorage.getItem(key);
      if (localToken) {
        token = localToken;
        break;
      }
    }
  }

  return token;
}; 