import React from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import InitialsAvatar from '../common/InitialsAvatar';

const CompactPersonaCard = ({ persona, onPersonaClick, isSelected, isInChatInterface, onEditInChat }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const handleEditClick = (e) => {
    e.stopPropagation();
    console.log('CompactPersonaCard: handleEditClick', { isInChatInterface, persona });
    if (isInChatInterface && onEditInChat) {
      onEditInChat(persona);
    } else {
      navigate(`/personas/edit/${persona._id}`);
    }
  };

  return (
    <div 
      className={`
        ${isDarkMode 
          ? 'bg-[#1E1E1E] hover:bg-[#2A2A2A] border-[#333333]' 
          : 'bg-white hover:bg-gray-50 border-gray-200'
        } 
        ${isSelected 
          ? isDarkMode 
            ? 'bg-[#2A2A2A]' 
            : 'bg-gray-100'
          : ''
        }
        rounded-md p-2 relative cursor-pointer transition-all duration-200 ease-in-out
        border mb-[5px] group
      `}
      onClick={() => onPersonaClick(persona)}
    >
      <div className="flex items-center">
        {/* Avatar */}
        <div className="relative mr-3 flex-shrink-0">
          <InitialsAvatar 
            name={persona.name}
            size="small"
          />
        </div>

        {/* Name and Occupation Column */}
        <div className="flex-1 min-w-0">
          <h2 className={`text-xs font-medium ${
            isDarkMode ? 'text-gray-200' : 'text-gray-800'
          } truncate`}>
            {persona.name}
          </h2>
          <p className={`text-xs ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          } truncate`}>
            {persona.occupation}
          </p>
        </div>

        {/* Edit Button */}
        <button
          onClick={handleEditClick}
          className={`ml-2 p-1.5 rounded-md opacity-0 group-hover:opacity-100
            transition-all duration-200 ease-in-out
            ${isDarkMode 
              ? 'hover:bg-[#2A2A2A] text-gray-400 hover:text-gray-200' 
              : 'hover:bg-gray-100 text-gray-400 hover:text-gray-600'
            }`}
          title="Edit profile"
        >
          <PencilSquareIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default CompactPersonaCard;
