import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PlusIcon, 
  CheckIcon,
  XMarkIcon,
  SparklesIcon,
  ExclamationTriangleIcon,
  ArrowPathIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
  XCircleIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import api, { generateContentStrategy, getYoutubeTranscript } from '../../api';
import toast from 'react-hot-toast';

const STORAGE_KEY = 'contentStrategy_suggestedTopics';
const STORAGE_KEY_CUSTOM = 'contentStrategy_customTopics';

const truncateWords = (text, limit = 15) => {
  const words = text.split(' ');
  if (words.length > limit) {
    return words.slice(0, limit).join(' ') + '...';
  }
  return text;
};

const TopicSuggestions = ({ 
  persona, 
  audience,
  onSubmit,
  loading,
  selectedTopics,
  setSelectedTopics
}) => {
  const { isDarkMode } = useTheme();
  const [suggestedTopics, setSuggestedTopics] = useState(() => {
    const stored = localStorage.getItem(STORAGE_KEY);
    return stored ? JSON.parse(stored) : [];
  });
  const [customTopic, setCustomTopic] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [customTopics, setCustomTopics] = useState(() => {
    const stored = localStorage.getItem(STORAGE_KEY_CUSTOM);
    return stored ? JSON.parse(stored) : [];
  });
  const [expandedCategories, setExpandedCategories] = useState({});
  const inputRef = useRef(null);
  const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
  const [showYoutubeInput, setShowYoutubeInput] = useState(false);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const youtubeInputRef = useRef(null);
  const [showInputArea, setShowInputArea] = useState(false);

  useEffect(() => {
    if (suggestedTopics.length > 0) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(suggestedTopics));
    }
  }, [suggestedTopics]);

  useEffect(() => {
    if (customTopics.length > 0) {
      localStorage.setItem(STORAGE_KEY_CUSTOM, JSON.stringify(customTopics));
    }
  }, [customTopics]);

  useEffect(() => {
    if (suggestedTopics.length === 0) {
      generateTopicSuggestions();
    }
  }, []);

  useEffect(() => {
    if (suggestedTopics.length > 0) {
      const categories = suggestedTopics.reduce((acc, topic) => {
        const category = topic.includes(':') ? topic.split(':')[0].trim() : 'General';
        acc[category] = true; // Set to true to expand all categories by default
        return acc;
      }, {});
      setExpandedCategories(categories);
    }
  }, [suggestedTopics]);

  // Focus input when showing input area
  useEffect(() => {
    if (showInputArea && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showInputArea]);

  // Handle escape key to close input area
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape' && showInputArea) {
        setShowInputArea(false);
        setCustomTopic('');
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [showInputArea]);

  const generateTopicSuggestions = async () => {
    setIsLoading(true);
    setError(null);
    
    if (!persona || !audience) {
      setError('Missing persona or audience information');
      setIsLoading(false);
      return;
    }

    const maxAttempts = 3;
    let attempt = 0;

    while (attempt < maxAttempts) {
      try {
        const response = await api.post('/api/content-strategy/suggest-topics', {
          persona: {
            name: persona?.name || '',
            occupation: persona?.occupation || '',
            expertiseLevel: persona?.expertiseLevel || '',
            contentGoals: persona?.contentGoals || '',
            writingStyle: persona?.writingStyle || 'Professional',
          },
          audience: {
            targetAudienceName: audience?.targetAudienceName || '',
            industry: audience?.industry || '',
            primaryGoal: audience?.primaryGoal || [],
            painPoints: audience?.painPoints || [],
          }
        });
        
        if (Array.isArray(response.data?.topics)) {
          setSuggestedTopics(response.data.topics);
          setExpandedCategories({});
          localStorage.removeItem(STORAGE_KEY);
          break;
        } else {
          throw new Error('Invalid topics data received');
        }
      } catch (error) {
        attempt++;
        const shouldRetry = error.response?.data?.shouldRetry || error.response?.status === 529;
        
        if (shouldRetry && attempt < maxAttempts) {
          const delay = Math.pow(2, attempt) * 1000;
          await new Promise(resolve => setTimeout(resolve, delay));
          continue;
        }

        console.error('Error generating topic suggestions:', error);
        setError(
          error.response?.data?.message || 
          error.message || 
          'Failed to generate topics. Please try again.'
        );
        setSuggestedTopics([]);
        break;
      }
    }
    
    setIsLoading(false);
  };

  const handleTopicSelect = (topic) => {
    setSelectedTopics(prev => {
      const isSelected = prev.includes(topic);
      if (isSelected) {
        return prev.filter(t => t !== topic);
      }
      return [...prev, topic];
    });
  };

  const handleAddCustomTopic = () => {
    if (customTopic.trim()) {
      const newTopic = customTopic.trim();
      const topicExists = [
        ...suggestedTopics,
        ...customTopics
      ].some(topic => topic.toLowerCase() === newTopic.toLowerCase());

      if (!topicExists) {
        const updatedCustomTopics = [...customTopics, newTopic];
        setCustomTopics(updatedCustomTopics);
        setSelectedTopics(prev => [...prev, newTopic]);
        setCustomTopic('');
        setShowInputArea(false);
        localStorage.setItem(STORAGE_KEY_CUSTOM, JSON.stringify(updatedCustomTopics));
      } else {
        toast('This topic already exists', {
          icon: '⚠️',
          duration: 2000,
          position: 'bottom-center',
          className: isDarkMode ? 'dark-toast' : '',
          style: {
            background: isDarkMode ? '#1e1e1e' : '#fff',
            color: isDarkMode ? '#fbbf24' : '#d97706',
            border: `1px solid ${isDarkMode ? '#2f2f2f' : '#f3f4f6'}`
          }
        });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (!Array.isArray(selectedTopics) || selectedTopics.length === 0) {
        throw new Error('Please select at least one topic');
      }

      if (!persona || !audience) {
        throw new Error('Missing persona or audience information');
      }

      // Call onSubmit with the selected topics
      onSubmit(selectedTopics);
    } catch (error) {
      console.error('Error submitting topics:', error);
      toast.error(error.message || 'Failed to submit topics');
    }
  };

  const handleCopyAll = async () => {
    try {
      const topicsText = suggestedTopics.join('\n');
      await navigator.clipboard.writeText(topicsText);
      setIsCopied(true);
      
      // Reset copy status after 2 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
      
      toast.success('Topics copied to clipboard!');
    } catch (error) {
      console.error('Failed to copy topics:', error);
      toast.error('Failed to copy topics');
    }
  };

  const handleSelectAll = () => {
    setSelectedTopics([...new Set([...suggestedTopics, ...customTopics])]);
  };

  const handleUnselectAll = () => {
    setSelectedTopics([]);
  };

  const isCustomTopic = (topic) => {
    return customTopics.includes(topic);
  };

  // Handle key press for textarea
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey && customTopic.trim()) {
        e.preventDefault();
        handleAddCustomTopic();
      }
    }
  };

  // Add function to auto-resize textarea
  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = (element.scrollHeight) + 'px';
  };

  // Handle textarea input
  const handleTextareaChange = (e) => {
    setCustomTopic(e.target.value);
    adjustTextareaHeight(e.target);
  };

  // Group topics by category
  const groupedTopics = suggestedTopics.reduce((acc, topic) => {
    const category = topic.includes(':') ? topic.split(':')[0].trim() : 'General';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(topic);
    return acc;
  }, {});

  // Function to extract YouTube video ID
  const extractVideoId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  };

  // Function to fetch transcript
  const fetchTranscript = async () => {
    try {
      const videoId = extractVideoId(youtubeUrl);
      
      if (!videoId) {
        toast.error('Invalid YouTube URL');
        return;
      }

      setIsLoadingTranscript(true);
      
      const response = await getYoutubeTranscript(videoId);

      if (response?.transcript) {
        setCustomTopic(response.transcript);
        setShowYoutubeInput(false);
        setYoutubeUrl('');
        adjustTextareaHeight(inputRef.current);
      }
    } catch (error) {
      toast.error(error.message || 'Failed to fetch transcript');
    } finally {
      setIsLoadingTranscript(false);
    }
  };

  // Handle YouTube input key press
  const handleYoutubeKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchTranscript();
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="w-full max-w-3xl mx-auto space-y-6 p-4 sm:p-6"
    >
      {/* Main Header with Buttons */}
      <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-0 sm:justify-between pb-4 border-b border-slate-200/5">
        <motion.h2 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`text-base sm:text-lg font-medium ${
            isDarkMode ? 'text-slate-200' : 'text-slate-700'
          }`}
        >
          Select topics to generate ideas
        </motion.h2>

        <div className="flex items-center gap-2 self-end">
          <button
            onClick={() => setShowInputArea(prev => !prev)}
            className={`flex items-center gap-1.5 px-2.5 sm:px-3 py-1.5 rounded-md transition-colors
              ${showInputArea
                ? isDarkMode
                  ? 'text-blue-400 bg-blue-500/5'
                  : 'text-blue-600 bg-blue-50/50'
                : isDarkMode 
                  ? 'text-slate-300 hover:text-slate-200 hover:bg-slate-800/20' 
                  : 'text-slate-600 hover:text-slate-700 hover:bg-slate-50/70'
              }
            `}
          >
            <PlusIcon className={`w-3.5 h-3.5 transition-transform ${showInputArea ? 'rotate-45' : ''}`} />
            <span className="text-xs">{showInputArea ? 'Cancel' : 'Add custom'}</span>
          </button>

          <button
            onClick={generateTopicSuggestions}
            disabled={isLoading}
            className={`flex items-center gap-1.5 px-2.5 sm:px-3 py-1.5 rounded-md transition-colors border
              ${isDarkMode 
                ? 'border-slate-800/60 text-blue-400 hover:bg-blue-500/5' 
                : 'border-slate-200/70 text-blue-500 hover:bg-blue-50/50'
              }
            `}
          >
            <SparklesIcon className={`w-3.5 h-3.5 ${isLoading ? 'animate-spin' : ''}`} />
            <span className="text-xs whitespace-nowrap">{isLoading ? 'Generating...' : 'New suggestions'}</span>
          </button>
        </div>
      </div>

      {/* Main content area */}
      <div className="space-y-6">
        {/* Add Topic Input Section - Shown conditionally */}
        <AnimatePresence>
          {showInputArea && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.15 }}
              className={`relative rounded-lg border ${
                isDarkMode ? 'border-slate-800/60' : 'border-slate-200/70'
              }`}
            >
              <div className="p-3 sm:p-4">
                <textarea
                  ref={inputRef}
                  value={customTopic}
                  onChange={handleTextareaChange}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your topic here..."
                  rows={1}
                  className={`w-full text-sm transition-colors
                    focus:outline-none resize-none bg-transparent
                    ${isDarkMode
                      ? 'text-slate-200 placeholder-slate-500'
                      : 'text-slate-800 placeholder-slate-400'
                    }
                  `}
                  style={{ 
                    height: '24px',
                    minHeight: '24px', 
                    maxHeight: '120px'
                  }}
                />
              </div>
              
              <div className={`flex items-center justify-between p-2 border-t ${
                isDarkMode ? 'border-slate-800/40' : 'border-slate-200/60'
              }`}>
                <div className="hidden sm:flex items-center gap-2 px-2">
                  <span className={`text-xs ${
                    isDarkMode ? 'text-slate-500' : 'text-slate-400'
                  }`}>
                    Press Enter to add
                  </span>
                </div>
                
                <motion.button
                  whileTap={{ scale: 0.98 }}
                  onClick={handleAddCustomTopic}
                  disabled={!customTopic.trim()}
                  className={`ml-auto px-3 py-1.5 rounded-md transition-colors flex items-center gap-1.5
                    ${customTopic.trim()
                      ? isDarkMode
                        ? 'text-blue-400 hover:bg-blue-500/5'
                        : 'text-blue-500 hover:bg-blue-50/50'
                      : isDarkMode
                        ? 'text-slate-600'
                        : 'text-slate-400'
                    }
                  `}
                >
                  <CheckIcon className="w-3.5 h-3.5" />
                  <span className="text-xs">Add topic</span>
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Error message */}
        {error && (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={`p-3 sm:p-4 rounded-lg text-xs sm:text-sm ${
              isDarkMode 
                ? 'bg-red-500/[0.02] text-red-400' 
                : 'bg-red-50 text-red-500'
            }`}
          >
            <div className="flex items-center justify-between gap-3">
              <div className="flex items-center gap-2 min-w-0">
                <ExclamationTriangleIcon className="w-4 h-4 flex-shrink-0" />
                <span className="truncate">{error}</span>
              </div>
              <button 
                onClick={generateTopicSuggestions}
                className="flex items-center gap-1.5 flex-shrink-0"
              >
                <ArrowPathIcon className="w-3.5 h-3.5" />
                <span className="hidden sm:inline">Retry</span>
              </button>
            </div>
          </motion.div>
        )}

        {/* Topics List */}
        <div className="space-y-5">
          {/* Custom Topics */}
          {customTopics.length > 0 && (
            <div className="space-y-1">
              <div className="flex flex-wrap items-center gap-x-2 gap-y-1 px-1 mb-3">
                <span className={`text-xs ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-500'
                }`}>
                  {customTopics.length} custom topic{customTopics.length !== 1 ? 's' : ''}
                </span>
                {selectedTopics.filter(t => customTopics.includes(t)).length > 0 && (
                  <>
                    <span className={`text-xs ${
                      isDarkMode ? 'text-slate-600' : 'text-slate-400'
                    }`}>•</span>
                    <span className={`text-xs ${
                      isDarkMode ? 'text-slate-400' : 'text-slate-500'
                    }`}>
                      {selectedTopics.filter(t => customTopics.includes(t)).length} selected
                    </span>
                  </>
                )}
              </div>
              
              <div className="space-y-px">
                {customTopics.map((topic, index) => (
                  <motion.div
                    key={`custom-${index}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="group flex items-start gap-3 px-3 sm:px-4 py-2.5 transition-all rounded-md
                      ${selectedTopics.includes(topic)
                        ? isDarkMode
                          ? 'bg-blue-500/[0.02] text-blue-400'
                          : 'bg-blue-50/40 text-blue-600'
                        : isDarkMode
                          ? 'text-slate-300 hover:bg-slate-800/20'
                          : 'text-slate-600 hover:bg-slate-50/70'
                      }"
                  >
                    <div 
                      onClick={() => handleTopicSelect(topic)}
                      className="flex-1 flex items-start gap-3 cursor-pointer"
                    >
                      <div className={`flex-shrink-0 w-[18px] h-[18px] mt-0.5 relative transition-colors border rounded-sm ${
                        selectedTopics.includes(topic)
                          ? isDarkMode
                            ? 'border-blue-400 bg-blue-400/10'
                            : 'border-blue-500 bg-blue-500/5'
                          : isDarkMode
                            ? 'border-slate-700 group-hover:border-slate-600'
                            : 'border-slate-300 group-hover:border-slate-400'
                      }`}>
                        <CheckIcon className={`absolute inset-0 p-0.5 transition-opacity ${
                          selectedTopics.includes(topic)
                            ? 'opacity-100'
                            : 'opacity-0 group-hover:opacity-30'
                        }`} />
                      </div>
                      
                      <div className="flex-1 min-w-0 text-left">
                        <span className="text-[13px] sm:text-sm break-words">
                          {topic}
                        </span>
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        setCustomTopics(prev => prev.filter(t => t !== topic));
                        setSelectedTopics(prev => prev.filter(t => t !== topic));
                      }}
                      className={`flex-shrink-0 p-1 -m-1 rounded-full opacity-0 group-hover:opacity-60 hover:!opacity-100 transition-all
                        ${isDarkMode
                          ? 'hover:bg-red-500/5 text-slate-500 hover:text-red-400'
                          : 'hover:bg-red-50/50 text-slate-400 hover:text-red-500'
                        }
                      `}
                    >
                      <XMarkIcon className="w-3.5 h-3.5" />
                    </button>
                  </motion.div>
                ))}
              </div>
            </div>
          )}

          {/* Suggested Topics */}
          {Object.entries(groupedTopics).map(([category, topics]) => (
            <div key={category} className={`rounded-lg border overflow-hidden ${
              isDarkMode ? 'border-slate-800/60' : 'border-slate-200/70'
            }`}>
              <div className={`px-3 sm:px-4 py-3 flex items-center justify-between ${
                isDarkMode ? 'bg-slate-900/20' : 'bg-slate-50/50'
              }`}>
                <span className={`text-xs font-medium ${
                  isDarkMode ? 'text-slate-200' : 'text-slate-700'
                }`}>
                  {category}
                </span>
                <div className="flex items-center gap-3">
                  <span className={`text-xs ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-500'
                  }`}>
                    {topics.length} topic{topics.length !== 1 ? 's' : ''}
                  </span>
                  <button
                    onClick={() => setExpandedCategories(prev => ({
                      ...prev,
                      [category]: !prev[category]
                    }))}
                    className={`p-1 -m-1 rounded transition-colors ${
                      isDarkMode ? 'hover:bg-slate-800/40' : 'hover:bg-slate-100/60'
                    }`}
                  >
                    <ChevronDownIcon 
                      className={`w-4 h-4 transition-transform duration-200 ${
                        expandedCategories[category] ? 'transform rotate-180' : ''
                      }`} 
                    />
                  </button>
                </div>
              </div>

              <AnimatePresence>
                {expandedCategories[category] && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className={`divide-y ${isDarkMode ? 'divide-slate-800/40' : 'divide-slate-200/60'}`}
                  >
                    {topics.map((topic, index) => {
                      const topicContent = topic.includes(':') ? topic.split(':')[1].trim() : topic;
                      
                      return (
                        <motion.button
                          key={`suggested-${index}`}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          onClick={() => handleTopicSelect(topic)}
                          className={`w-full flex items-start gap-3 px-3 sm:px-4 py-2.5 transition-colors group
                            ${selectedTopics.includes(topic)
                              ? isDarkMode
                                ? 'bg-blue-500/[0.02] text-blue-400'
                                : 'bg-blue-50/40 text-blue-600'
                              : isDarkMode
                                ? 'text-slate-300 hover:bg-slate-800/20'
                                : 'text-slate-600 hover:bg-slate-50/70'
                            }
                          `}
                        >
                          <div className={`flex-shrink-0 w-[18px] h-[18px] mt-0.5 relative transition-colors border rounded-sm ${
                            selectedTopics.includes(topic)
                              ? isDarkMode
                                ? 'border-blue-400 bg-blue-400/10'
                                : 'border-blue-500 bg-blue-500/5'
                              : isDarkMode
                                ? 'border-slate-700 group-hover:border-slate-600'
                                : 'border-slate-300 group-hover:border-slate-400'
                          }`}>
                            <CheckIcon className={`absolute inset-0 p-0.5 transition-opacity ${
                              selectedTopics.includes(topic)
                                ? 'opacity-100'
                                : 'opacity-0 group-hover:opacity-30'
                            }`} />
                          </div>
                          
                          <span className="flex-1 text-[13px] sm:text-sm text-left break-words">
                            {topicContent}
                          </span>
                        </motion.button>
                      );
                    })}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default TopicSuggestions; 