// ------------------------------------------------------------------------
// LinkedInPreviewMockup Component
// ------------------------------------------------------------------------
// This component creates a mockup of a LinkedIn post preview with responsive
// design and dark/light mode support. It handles avatar display, content
// expansion/collapse, and placeholder states.
// ------------------------------------------------------------------------

import React, { useState, useCallback, useEffect } from 'react';
import { EllipsisHorizontalIcon, UserIcon } from '@heroicons/react/24/outline';
import { isLinkedInConnected } from '../services/linkedinPublishService';
import '../styles/markdown.css';

// ------------------------------------------------------------------------
// Props:
// - selectedPersona: Object containing user profile data (name, occupation, avatarUrl)
// - content: String containing the post content
// - isDarkMode: Boolean to toggle dark/light mode styling
// ------------------------------------------------------------------------

const LinkedInPreviewMockup = ({ 
  selectedPersona, 
  content,
  isDarkMode,
}) => {
  // State for content expansion toggle and avatar URL management
  const [isExpanded, setIsExpanded] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [linkedInProfile, setLinkedInProfile] = useState(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);

  // ------------------------------------------------------------------------
  // LinkedIn Profile Fetching
  // Fetches the user's LinkedIn profile when component mounts
  // ------------------------------------------------------------------------
  useEffect(() => {
    const fetchLinkedInProfile = async () => {
      try {
        setIsLoadingProfile(true);
        const linkedInStatus = await isLinkedInConnected();
        
        if (linkedInStatus.isConnected && linkedInStatus.profile) {
          // Extract profile data from the response
          const profileData = linkedInStatus.profile.profile_data || linkedInStatus.profile;
          
          // Get name from the correct path in the data structure
          let fullName;
          if (profileData.name) {
            fullName = profileData.name;
          } else if (profileData.given_name && profileData.family_name) {
            fullName = `${profileData.given_name} ${profileData.family_name}`;
          } else if (linkedInStatus.profile.name) {
            fullName = linkedInStatus.profile.name;
          } else {
            fullName = 'LinkedIn User';
          }
          
          setLinkedInProfile({
            name: fullName,
            avatarUrl: profileData.picture || null
          });
        }
      } catch (error) {
        console.error('Error fetching LinkedIn profile:', error);
      } finally {
        setIsLoadingProfile(false);
      }
    };

    fetchLinkedInProfile();
  }, []);

  // ------------------------------------------------------------------------
  // Avatar URL Management
  // Updates avatar URL when selectedPersona or linkedInProfile changes
  // ------------------------------------------------------------------------
  useEffect(() => {
    if (linkedInProfile?.avatarUrl) {
      setAvatarUrl(linkedInProfile.avatarUrl);
    } else if (selectedPersona?.avatarUrl) {
      setAvatarUrl(selectedPersona.avatarUrl);
    } else {
      setAvatarUrl(null);
    }
  }, [selectedPersona, linkedInProfile]);

  // ------------------------------------------------------------------------
  // Content Toggle Handler
  // Manages expansion/collapse of post content with event propagation control
  // ------------------------------------------------------------------------
  const toggleView = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }
    setIsExpanded(prev => !prev);
  }, []);

  // ------------------------------------------------------------------------
  // Helper function to format the published date
  // ------------------------------------------------------------------------
  const formatPublishedDate = (date) => {
    if (!date) return 'Now';
    
    const publishedDate = new Date(date);
    const now = new Date();
    const diffInSeconds = Math.floor((now - publishedDate) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInSeconds < 60) return 'Just now';
    if (diffInMinutes < 60) return `${diffInMinutes}m`;
    if (diffInHours < 24) return `${diffInHours}h`;
    if (diffInDays < 7) return `${diffInDays}d`;
    
    return publishedDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  // ------------------------------------------------------------------------
  // Content Rendering Logic
  // Handles:
  // 1. Placeholder state when no content is provided
  // 2. Content truncation for non-expanded state
  // 3. Full content display for expanded state
  // 4. "...more" and "...show less" toggle buttons
  // ------------------------------------------------------------------------
  const renderContent = () => {
    if (!content || typeof content !== 'string') {
      return (
        <>
          <div className={`h-4 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-full mb-2`}></div>
          <div className={`h-4 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-5/6 mb-2`}></div>
          <div className={`h-4 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-4/6`}></div>
        </>
      );
    }

    const contentLines = content.split('\n');
    
    if (!isExpanded) {
      return (
        <>
          {contentLines.slice(0, 3).map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < 2 && <br />}
            </React.Fragment>
          ))}
          {contentLines.length > 3 && (
            <span 
              className={`hover:underline ml-1 cursor-pointer font-medium ${
                isDarkMode 
                  ? 'bg-gradient-to-r from-blue-400 to-blue-500 text-transparent bg-clip-text hover:from-blue-300 hover:to-blue-400' 
                  : 'bg-gradient-to-r from-blue-600 to-blue-500 text-transparent bg-clip-text hover:from-blue-700 hover:to-blue-600'
              }`}
              onClick={toggleView}
            >
              ...more
            </span>
          )}
        </>
      );
    } else {
      return (
        <>
          {content}
          <span 
            className={`hover:underline ml-1 cursor-pointer font-medium block mt-2 ${
              isDarkMode 
                ? 'bg-gradient-to-r from-blue-400 to-blue-500 text-transparent bg-clip-text hover:from-blue-300 hover:to-blue-400' 
                : 'bg-gradient-to-r from-blue-600 to-blue-500 text-transparent bg-clip-text hover:from-blue-700 hover:to-blue-600'
            }`}
            onClick={toggleView}
          >
            ...show less
          </span>
        </>
      );
    }
  };

  return (
    <div 
      className={`linkedin-post-mockup shadow-sm rounded-lg ${
        isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'
      } border relative ${(linkedInProfile || selectedPersona) ? 'p-4' : 'px-4 pt-0 pb-4'}`}
    >
      {/* Three-dot icon */}
      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
        <EllipsisHorizontalIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-300' : 'text-gray-500'} cursor-pointer`} />
      </div>

      {/* Profile Section - Only show if we have either LinkedIn profile or selected persona */}
      {(linkedInProfile || selectedPersona) && (
        <div className="flex items-center">
          {isLoadingProfile ? (
            <div className={`h-12 w-12 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} animate-pulse`} />
          ) : avatarUrl ? (
            <img 
              src={avatarUrl} 
              alt={(linkedInProfile?.name || selectedPersona?.name || 'User')} 
              className="h-12 w-12 rounded-full object-cover aspect-square"
              onError={() => setAvatarUrl(null)}
            />
          ) : (
            <div className={`h-12 w-12 rounded-full aspect-square ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} flex items-center justify-center`}>
              <UserIcon className={`h-8 w-8 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
            </div>
          )}
          <div className="ml-3 flex-1 min-w-0">
            {isLoadingProfile ? (
              <>
                <div className={`h-3 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-24 mb-2 animate-pulse`}></div>
                <div className={`h-3 ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-200'} rounded w-32 animate-pulse`}></div>
              </>
            ) : (
              <>
                <div className={`text-sm font-semibold truncate ${isDarkMode ? 'text-slate-200' : 'text-slate-800'}`}>
                  {linkedInProfile?.name || selectedPersona?.name || 'LinkedIn User'}
                </div>
                <div className="flex items-center gap-1 mt-0.5">
                  <span className={`text-xs truncate ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
                    {formatPublishedDate()}
                  </span>
                  <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>•</span>
                  <svg 
                    aria-hidden="true" 
                    className={`w-3 h-3 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 14 14"
                  >
                    <g>
                      <path 
                        id="Union" 
                        fill="currentColor" 
                        fillRule="evenodd" 
                        d="M1.50226 6.99993C1.50226 3.96361 3.96368 1.5022 6.99999 1.5022C7.78361 1.5022 8.52894 1.66614 9.20354 1.96161V2.23807C9.20354 2.68362 9.02655 3.11092 8.7115 3.42597C8.39645 3.74102 7.96915 3.91802 7.5236 3.91802C7.29823 3.91789 7.07515 3.96312 6.86762 4.05099C6.6601 4.13887 6.47237 4.2676 6.31563 4.42953C6.15888 4.59146 6.03632 4.78327 5.95523 4.99354C5.87415 5.20382 5.83621 5.42826 5.84366 5.6535V7.04188C5.84366 7.2625 5.8002 7.48095 5.71578 7.68477C5.63135 7.88859 5.50761 8.07378 5.35161 8.22978C5.19561 8.38578 5.01042 8.50952 4.8066 8.59395C4.60278 8.67838 4.38432 8.72183 4.16371 8.72183H2.46988H1.7773C1.59883 8.18019 1.50226 7.60133 1.50226 6.99993ZM9.71406 11.7821C10.8973 11.1092 11.8063 10.0098 12.2321 8.69305L12.0961 8.59334C11.6577 8.36677 11.1726 8.24548 10.6791 8.23911H8.80677C8.42317 8.27394 8.06645 8.45095 7.8067 8.73537C7.54694 9.01978 7.40292 9.39104 7.40292 9.77623C7.40292 10.1614 7.54694 10.5327 7.8067 10.8171C8.06645 11.1015 8.42317 11.2785 8.80677 11.3133C8.95071 11.3117 9.09355 11.3386 9.22702 11.3925C9.36049 11.4464 9.48192 11.5263 9.5843 11.6275C9.63237 11.675 9.67579 11.7268 9.71406 11.7821ZM6.99999 0.00219727C3.13525 0.00219727 0.0022583 3.13519 0.0022583 6.99993C0.0022583 10.8647 3.13525 13.9977 6.99999 13.9977C10.8647 13.9977 13.9977 10.8647 13.9977 6.99993C13.9977 3.13519 10.8647 0.00219727 6.99999 0.00219727Z" 
                        clipRule="evenodd"
                      />
                    </g>
                  </svg>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {/* Content section */}
      <div className={`mt-4 ${!(linkedInProfile || selectedPersona) ? 'mt-0' : ''}`}>
        <div
          className={`text-sm ${isDarkMode ? 'text-slate-200' : 'text-gray-700'} whitespace-pre-wrap break-words cursor-pointer`}
          onClick={toggleView}
        >
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default React.memo(LinkedInPreviewMockup);
