import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import useScreenSize from '../../../hooks/useScreenSize';
import ReactDOM from 'react-dom';
import { 
  PlayIcon,
  ArrowDownIcon,
  XMarkIcon,
  SparklesIcon,
  ArrowRightIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline';
import EarlyAccessButton from '../../EarlyAccessButton';
import heroVideoThumbnailLight from '../../assets/hero-video-thumbnail-light.webp';
import heroVideoThumbnailDark from '../../assets/hero-video-thumbnail-dark.webp';
import { fit } from '@cloudinary/url-gen/actions/resize';

// Add CSS for animated gradient and font
const gradientAnimation = `
  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes gradient-wave {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .hover\\:animate-gradient-wave:hover {
    animation: gradient-wave 3s ease infinite;
  }
`;

// Add style tag to head
if (!document.getElementById('gradient-animation')) {
  const style = document.createElement('style');
  style.id = 'gradient-animation';
  style.innerHTML = gradientAnimation;
  document.head.appendChild(style);
}

const VideoPlayer = ({ className = "", onVideoClick }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`relative w-full ${className}`}>
      <div 
        className="
          w-full
          cursor-pointer
          relative
          rounded-lg
          group
          inline-flex
          items-center
          justify-center
        "
        onClick={() => onVideoClick('I1gNZvsB87s')}
      >
        <img
          src={isDarkMode ? heroVideoThumbnailDark : heroVideoThumbnailLight}
          alt="Video Thumbnail"
          className="w-full -mt-[1px]"
        />
        <button
          className={`
            absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
            flex items-center justify-center
            w-16 h-16
            rounded-full
            ${isDarkMode 
              ? 'bg-white hover:bg-white/90'
              : 'bg-black hover:bg-black/90'
            }
            transition-all duration-300
            group-hover:scale-110
            shadow-lg group-hover:shadow-xl
            z-10
          `}
        >
          <PlayIcon className={`
            w-8 h-8 
            ${isDarkMode ? 'text-black' : 'text-white'} 
            stroke-[2.5]
          `} />
        </button>
      </div>
    </div>
  );
};

const HeroSection = ({ onVideoClick }) => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useScreenSize();
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 200], [1, 0]);
  const y = useTransform(scrollY, [0, 200], [0, 50]);
  const navigate = useNavigate();

  const MobileHero = () => (
    <div className={`
      relative min-h-[calc(100vh-4rem)] 
      flex flex-col items-center justify-center 
      pt-20 pb-8
      ${isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'}
    `}>
      {/* Mobile Content */}
      <div className="relative w-full flex flex-col items-start justify-center px-16 sm:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-left w-full mb-8"
        >
          <h1 className={`
            text-3xl
            font-bold 
            tracking-tighter 
            leading-tight 
            mb-6
          `}>
            <motion.span
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.8,
                ease: "easeOut"
              }}
              className="relative"
            >
              <span className={`
                font-bold block tracking-tight font-inter leading-tight
                ${isDarkMode ? 'text-white' : 'text-gray-900'}
              `}>
                <span className="font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent hover:animate-gradient-wave hover:bg-[length:200%_200%] transition-all duration-500 cursor-default">Personal AI Cowriter</span>
                <br /> For Creators
              </span>
            </motion.span>
          </h1>
          <p className={`
            text-sm
            max-w-[300px]
            ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
          `}>
            Write Viral-worthy content, consistently. <br /><br /> With "your persona". For "your audience". With "your notes, thoughts, and ideas". And a lot of help from AI.

          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="w-full flex flex-col sm:flex-row gap-3 justify-left mb-4"
        >
          <button
            onClick={() => navigate('/register')}
            className={`
              flex items-center justify-center gap-1.5 sm:gap-2 
              px-4 sm:px-6 py-2.5
              rounded-lg text-sm font-medium w-full sm:w-auto
              ${isDarkMode 
                ? 'bg-white text-black hover:bg-white/90' 
                : 'bg-black text-white hover:bg-black/90'}
              transition-all duration-200
              group
              shadow-lg hover:shadow-xl
              transform hover:scale-[1.02]
            `}
          >
            <SparklesIcon className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
            <span className="hidden sm:inline">Start Your 7-Day Free Trial</span>
            <span className="sm:hidden">Start Free Trial</span>
            <ArrowRightIcon className="w-3.5 h-3.5 sm:w-4 sm:h-4 transform transition-transform group-hover:translate-x-1" />
          </button>
          <div className={`
            text-xs flex items-center justify-center sm:justify-start gap-1.5 mt-2 sm:mt-0 sm:ml-4
            ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
          `}>
            <CheckCircleIcon className="w-3.5 h-3.5 sm:w-4 sm:h-4 text-green-500" />
            <span className="hidden sm:inline">No credit card required</span>
            <span className="sm:hidden">No card needed</span>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="relative w-full overflow-hidden mb-8"
        >
          <VideoPlayer className="w-full" onVideoClick={onVideoClick} />
        </motion.div>
      </div>

      {/* Mobile Scroll Indicator */}
      <motion.div 
        style={{ opacity }} 
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
      >
        <Link
          to="#context-bank-section"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById('context-bank-section')?.scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }}
          className={`
            flex flex-col items-center gap-2
            ${isDarkMode ? 'text-slate-500' : 'text-slate-400'}
          `}
        >
          <ArrowDownIcon className="h-4 w-4" />
          <span className="text-xs uppercase tracking-widest font-light">Scroll</span>
        </Link>
      </motion.div>
    </div>
  );

  const DesktopHero = () => (
    <div className={`
      relative min-h-screen 
      flex flex-col items-center justify-start 
      px-4 sm:px-6 lg:px-8 
      pt-24 sm:pt-32 pb-12 sm:pb-16 
      scroll-mt-24
      ${isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'}
    `}>
      <motion.div 
        style={{ y }}
        className="relative w-full max-w-6xl mx-auto flex flex-col items-center text-center"
      >
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-8 max-w-4xl"
        >
          <h1 className="font-bold tracking-tight leading-[1.15] mb-1">
            <motion.span
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.8,
                ease: [0.23, 1, 0.32, 1]
              }}
              className="relative block"
            >
              <span className={`
                font-bold block tracking-tight font-inter text-4xl leading-[1.15] mx-auto max-w-7xl
                ${isDarkMode ? 'text-white' : 'text-gray-900'}
              `}>
                <span className="font-bold bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent hover:animate-gradient-wave hover:bg-[length:200%_200%] transition-all duration-500 cursor-default">Personal AI Cowriter</span> For Creators
              </span>
            </motion.span>
          </h1>
          <p className={`
            text-xl mt-2
            max-w-4xl mx-auto
            leading-tighter
            tracking-tight
            ${isDarkMode ? 'text-slate-400/70' : 'text-[#1D1D1F]/50'}
          `}>
            Create Viral-worthy content, consistently. With "your persona". For "your audience". With "your notes, thoughts, and ideas". And a lot of help from AI.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          className="relative w-full max-w-5xl mx-auto mb-12"
        >
          <VideoPlayer className="w-full" onVideoClick={onVideoClick} />
        </motion.div>
      </motion.div>

      {/* Scroll indicator */}
      <motion.div 
        style={{ opacity }} 
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
      >
        <Link
          to="#personalization-section"
          onClick={(e) => {
            e.preventDefault();
            document.getElementById('personalization-section')?.scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }}
          className={`
            flex flex-col items-center gap-2
            transition-colors
            duration-200
            ${isDarkMode 
              ? 'text-slate-500 hover:text-slate-400' 
              : 'text-slate-400 hover:text-slate-600'
            }
          `}
        >
          <ArrowDownIcon className="h-4 w-4" />
          <span className="text-xs uppercase tracking-widest font-light">Scroll</span>
        </Link>
      </motion.div>
    </div>
  );

  return isMobile ? <MobileHero /> : <DesktopHero />;
};

export default HeroSection; 