import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGoogleAuthUrl } from '../../services/googleAuth';
import { toast } from 'react-hot-toast';
import AnimatedLoader from '../common/AnimatedLoader';

const GoogleAuthButton = ({ isSignUp = false, className = '', isDarkMode = false }) => {
  const navigate = useNavigate();
  const popupRef = useRef(null);
  const timeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleGoogleAuth = async () => {
    try {
      setIsLoading(true);
      const url = await getGoogleAuthUrl();
      
      if (!url) {
        toast.error('Failed to initialize Google authentication. Please try again later.', {
          style: {
            border: '1px solid #FCA5A5',
            borderRadius: '6px',
            background: isDarkMode ? '#1F1F1F' : '#FFFFFF',
            color: isDarkMode ? '#FDA4AF' : '#EF4444',
          },
        });
        setIsLoading(false);
        return;
      }
      
      // Store auth type in session storage
      sessionStorage.setItem('googleAuthType', isSignUp ? 'signup' : 'login');
      
      // Open Google auth in a popup
      const width = 500;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;

      popupRef.current = window.open(
        url,
        'Google Auth',
        `width=${width},height=${height},left=${left},top=${top},toolbar=0,location=0,menubar=0`
      );

      if (!popupRef.current) {
        setIsLoading(false);
        toast.error('Please allow popups for this site to use Google authentication.', {
          style: {
            border: '1px solid #FCA5A5',
            borderRadius: '6px',
            background: isDarkMode ? '#1F1F1F' : '#FFFFFF',
            color: isDarkMode ? '#FDA4AF' : '#EF4444',
          },
        });
        return;
      }

      // Listen for message from popup
      const messageHandler = (event) => {
        // Verify origin
        const allowedOrigins = [window.location.origin];
        if (!allowedOrigins.includes(event.origin)) {
          console.warn('Received message from unauthorized origin:', event.origin);
          return;
        }

        if (event.data.type === 'GOOGLE_AUTH_SUCCESS') {
          const { code } = event.data;
          cleanup();
          setIsLoading(false);
          // Redirect to callback handler with the code
          navigate('/auth/google/callback', { state: { code } });
        } else if (event.data.type === 'GOOGLE_AUTH_ERROR') {
          cleanup();
          setIsLoading(false);
          const errorMessage = event.data.error;
          console.error('Google auth error:', errorMessage);
          
          // Show specific error messages with modern styling
          const toastStyle = {
            border: '1px solid #FCA5A5',
            borderRadius: '6px',
            background: isDarkMode ? '#1F1F1F' : '#FFFFFF',
            color: isDarkMode ? '#FDA4AF' : '#EF4444',
          };

          if (errorMessage.includes('already registered')) {
            toast.error('This email is already registered with a different login method. Please use that method instead.', { style: toastStyle });
          } else if (errorMessage.includes('cancelled')) {
            toast((t) => (
              <div className="flex items-center gap-2">
                <span className={`text-${isDarkMode ? 'blue-400' : 'blue-600'}`}>
                  Google authentication was cancelled
                </span>
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className={`px-2 py-1 text-xs rounded-md border border-${isDarkMode ? 'gray-700' : 'gray-200'} hover:bg-${isDarkMode ? 'gray-800' : 'gray-50'} transition-colors`}
                >
                  Dismiss
                </button>
              </div>
            ), {
              style: {
                border: `1px solid ${isDarkMode ? '#374151' : '#E5E7EB'}`,
                borderRadius: '6px',
                background: isDarkMode ? '#1F1F1F' : '#FFFFFF',
              },
            });
          } else if (errorMessage.includes('No authorization code')) {
            toast.error('Failed to receive authorization from Google. Please try again.', { style: toastStyle });
          } else {
            toast.error(errorMessage || 'Authentication failed. Please try again.', { style: toastStyle });
          }
        }
      };

      const cleanup = () => {
        window.removeEventListener('message', messageHandler);
        
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }

        if (popupRef.current) {
          try {
            toast((t) => (
              <div className="flex flex-col items-center gap-3 py-1">
                <span className={`text-${isDarkMode ? 'gray-200' : 'gray-700'} text-sm`}>
                  Please close the Google sign-in window if it's still open
                </span>
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className={`px-3 py-1.5 text-xs rounded-md border border-${isDarkMode ? 'gray-700' : 'gray-200'} 
                    bg-${isDarkMode ? 'gray-800' : 'white'} 
                    text-${isDarkMode ? 'gray-200' : 'gray-700'}
                    hover:bg-${isDarkMode ? 'gray-700' : 'gray-50'} 
                    transition-colors duration-200`}
                >
                  Got it
                </button>
              </div>
            ), {
              duration: 5000,
              position: 'top-center',
              style: {
                border: `1px solid ${isDarkMode ? '#374151' : '#E5E7EB'}`,
                borderRadius: '6px',
                background: isDarkMode ? '#1F1F1F' : '#FFFFFF',
                padding: '12px',
              },
            });
          } catch (err) {
            console.log('Cleanup error:', err);
          }
          
          popupRef.current = null;
        }
        
        setIsLoading(false);
      };

      window.addEventListener('message', messageHandler);

      // Set a timeout to cleanup if no response is received
      timeoutRef.current = setTimeout(() => {
        cleanup();
        toast((t) => (
          <div className="flex items-center justify-between gap-3 w-full">
            <span className={`text-${isDarkMode ? 'red-400' : 'red-600'}`}>
              Google sign-in timed out
            </span>
            <button
              onClick={() => toast.dismiss(t.id)}
              className={`px-2 py-1 text-xs rounded-md border border-${isDarkMode ? 'gray-700' : 'gray-200'} 
                hover:bg-${isDarkMode ? 'gray-800' : 'gray-50'} 
                transition-colors`}
            >
              Try again
            </button>
          </div>
        ), {
          style: {
            border: `1px solid ${isDarkMode ? '#374151' : '#E5E7EB'}`,
            borderRadius: '6px',
            background: isDarkMode ? '#1F1F1F' : '#FFFFFF',
            padding: '12px',
          },
        });
      }, 300000); // 5 minutes timeout

    } catch (error) {
      console.error('Google auth error:', error);
      setIsLoading(false);
      
      const toastStyle = {
        border: '1px solid #FCA5A5',
        borderRadius: '6px',
        background: isDarkMode ? '#1F1F1F' : '#FFFFFF',
        color: isDarkMode ? '#FDA4AF' : '#EF4444',
        padding: '12px',
      };

      if (error.message.includes('Missing environment variables')) {
        toast.error('Google authentication is not configured. Please try again later or use another sign-in method.', { style: toastStyle });
      } else if (error.response?.status === 500) {
        toast.error('Server error. Please try again later.', { style: toastStyle });
      } else if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please check your connection and try again.', { style: toastStyle });
      } else {
        toast.error('Failed to initialize Google authentication. Please try again.', { style: toastStyle });
      }
    }
  };

  return (
    <button
      onClick={handleGoogleAuth}
      disabled={isLoading}
      className={`flex items-center justify-center gap-2 w-full px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-70 disabled:cursor-not-allowed ${className}`}
      type="button"
    >
      {isLoading ? (
        <>
          <AnimatedLoader size={20} color="#4285F4" />
          <span>Connecting to Google...</span>
        </>
      ) : (
        <>
          <svg className="w-5 h-5" viewBox="0 0 24 24">
            <path
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              fill="#4285F4"
            />
            <path
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              fill="#34A853"
            />
            <path
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              fill="#FBBC05"
            />
            <path
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              fill="#EA4335"
            />
          </svg>
          <span>{isSignUp ? 'Sign up with Google' : 'Sign in with Google'}</span>
        </>
      )}
    </button>
  );
};

export default GoogleAuthButton; 