import React from 'react';
import { motion } from 'framer-motion';

const spinTransition = {
  repeat: Infinity,
  duration: 1,
  ease: "linear"
};

const AnimatedLoader = ({ size = 16, color = "currentColor", className = "" }) => {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <motion.svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        animate={{ rotate: 360 }}
        transition={spinTransition}
      >
        <motion.circle
          cx="12"
          cy="12"
          r="10"
          stroke={color}
          strokeWidth="4"
          fill="none"
          className="opacity-25"
        />
        <motion.path
          fill={color}
          className="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </motion.svg>
    </div>
  );
};

export default AnimatedLoader; 