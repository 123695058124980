import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { PlusIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

const IdeasTagMenu = forwardRef(({ isDarkMode, tags, allTags, onAddTag, onRemoveTag, anchorEl, onClose }, ref) => {
  const [inputValue, setInputValue] = useState('');
  const menuRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const [menuDimensions, setMenuDimensions] = useState({ width: 320, maxHeight: '85vh' });

  useEffect(() => {
    const viewportWidth = window.innerWidth;
    const padding = 16;
    const width = isMobile ? Math.min(viewportWidth - (padding * 2), 320) : 320;
    setMenuDimensions({ width, maxHeight: '85vh' });
  }, [isMobile]);

  const filteredAllTags = allTags.filter(tag => 
    tag.toLowerCase().includes(inputValue.toLowerCase()) && !tags.includes(tag)
  );

  const handleAddTag = (tagToAdd) => {
    const trimmedTag = tagToAdd.trim();
    if (trimmedTag && !tags.includes(trimmedTag)) {
      onAddTag(trimmedTag);
      setInputValue('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTag(inputValue);
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  if (!anchorEl) return null;

  return createPortal(
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0 flex items-center justify-center">
        {/* Background Overlay */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`absolute inset-0 ${
            isDarkMode 
              ? 'bg-black/50' 
              : 'bg-slate-950/10'
          }`}
          onClick={onClose}
        />

        {/* Menu */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ 
            type: "spring",
            stiffness: 400,
            damping: 30
          }}
          ref={menuRef}
          style={{
            width: menuDimensions.width,
            maxHeight: menuDimensions.maxHeight,
          }}
          className={`
            relative mx-4 flex flex-col overflow-hidden rounded-lg border shadow-lg
            ${isDarkMode 
              ? 'bg-slate-950 border-slate-800/40' 
              : 'bg-white border-slate-200/60'
            }
          `}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className={`
            flex items-center justify-between border-b px-4 py-3
            ${isDarkMode ? 'border-slate-800/60' : 'border-slate-200/60'}
          `}>
            <h3 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}`}>
              Manage Tags
            </h3>
            <button
              onClick={onClose}
              className={`
                -mr-1 rounded-md p-1.5 transition-colors
                ${isDarkMode
                  ? 'text-slate-400 hover:bg-slate-800/50 hover:text-slate-200'
                  : 'text-slate-500 hover:bg-slate-100 hover:text-slate-900'}
              `}
              aria-label="Close menu"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>

          {/* Search Input */}
          <div className="px-4 py-3">
            <div className={`
              group relative flex items-center rounded-md transition-colors
              ${isDarkMode 
                ? 'bg-slate-900 ring-slate-800' 
                : 'bg-slate-100 ring-slate-200'
              }
              ring-1 ring-inset focus-within:ring-2
              ${isDarkMode
                ? 'focus-within:ring-slate-700'
                : 'focus-within:ring-slate-300'}
            `}>
              <MagnifyingGlassIcon 
                className={`
                  absolute left-3 h-4 w-4 transition-colors
                  ${isDarkMode ? 'text-slate-500 group-focus-within:text-slate-400' : 'text-slate-400 group-focus-within:text-slate-500'}
                `}
              />
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Search or add new tag"
                className={`
                  w-full rounded-md bg-transparent py-2 pl-9 pr-9 text-sm placeholder:text-sm
                  focus:outline-none
                  ${isDarkMode 
                    ? 'text-slate-200 placeholder:text-slate-500' 
                    : 'text-slate-900 placeholder:text-slate-500'
                  }
                `}
              />
              <button
                onClick={() => handleAddTag(inputValue)}
                disabled={!inputValue.trim()}
                className={`
                  absolute right-2 rounded-sm p-1.5 transition-all
                  ${!inputValue.trim()
                    ? 'opacity-30 cursor-not-allowed'
                    : isDarkMode 
                      ? 'text-slate-400 hover:text-slate-200 hover:bg-slate-800/50' 
                      : 'text-slate-500 hover:text-slate-900 hover:bg-slate-200/50'
                  }
                `}
              >
                <PlusIcon className="h-4 w-4" />
              </button>
            </div>
          </div>

          {/* Content Area */}
          <div className="flex-1 overflow-hidden">
            {/* Current Tags */}
            {tags.length > 0 && (
              <div className={`
                border-b px-4 py-3
                ${isDarkMode ? 'border-slate-800/60' : 'border-slate-200/60'}
              `}>
                <h4 className={`mb-2 text-xs font-medium ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
                  Current Tags
                </h4>
                <div className="flex flex-wrap gap-1.5 max-h-24 overflow-y-auto">
                  {tags.map((tag) => (
                    <span
                      key={tag}
                      className={`
                        group inline-flex items-center rounded-md px-2 py-1 text-xs
                        ${isDarkMode 
                          ? 'bg-slate-800/50 text-slate-300 ring-1 ring-inset ring-slate-800/60' 
                          : 'bg-slate-100 text-slate-700 ring-1 ring-inset ring-slate-200/60'}
                      `}
                    >
                      <span className="max-w-[150px] truncate">{tag}</span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemoveTag(tag);
                        }}
                        className={`
                          ml-1.5 rounded-sm p-0.5 opacity-60 transition-all
                          hover:opacity-100
                          ${isDarkMode
                            ? 'hover:bg-slate-700'
                            : 'hover:bg-slate-200'}
                        `}
                      >
                        <XMarkIcon className="h-3 w-3" />
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            )}

            {/* Available Tags */}
            {filteredAllTags.length > 0 && (
              <div className="px-4 py-3">
                <h4 className={`mb-2 text-xs font-medium ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
                  Available Tags
                </h4>
                <div className="overflow-y-auto" style={{ maxHeight: isMobile ? '35vh' : '25vh' }}>
                  <AnimatePresence>
                    {filteredAllTags.map((tag, index) => (
                      <motion.button
                        key={tag}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ 
                          duration: 0.2,
                          delay: index * 0.03,
                        }}
                        onClick={() => handleAddTag(tag)}
                        className={`
                          mb-1 w-full rounded-md px-3 py-2 text-left text-sm
                          transition-colors
                          ${isDarkMode
                            ? 'text-slate-300 hover:bg-slate-800/50'
                            : 'text-slate-700 hover:bg-slate-100'}
                        `}
                      >
                        {tag}
                      </motion.button>
                    ))}
                  </AnimatePresence>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>,
    document.body
  );
});

IdeasTagMenu.displayName = 'IdeasTagMenu';

IdeasTagMenu.isClickInsideMenu = (target, menuRef) => {
  return menuRef && menuRef.current && menuRef.current.contains(target);
};

export default IdeasTagMenu;
