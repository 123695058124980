import React, { useState, useEffect, useCallback, useRef } from 'react';
import { XMarkIcon, ArrowLeftIcon, MagnifyingGlassIcon, FolderIcon, DocumentIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { fetchKnowledgeBases } from '../services/mongoService';
import { motion, AnimatePresence } from 'framer-motion';
import { formatDistanceToNow } from 'date-fns';
import { formatFileSize } from '../utils/formatters';
import { uploadFile } from '../api';
import { toast } from 'react-hot-toast';
import AddToChat from './common/AddToChat';
import { cn } from '../utils/cn';
import SkeletonLoader from './common/SkeletonLoader';

const truncateFileName = (fileName, maxLength = 32) => {
  if (!fileName) return '';
  if (fileName.length <= maxLength) return fileName;

  const extension = fileName.includes('.') ? fileName.split('.').pop() : '';
  const nameWithoutExt = fileName.includes('.') ? fileName.substring(0, fileName.lastIndexOf('.')) : fileName;

  const truncatedLength = maxLength - (extension ? extension.length + 1 : 0);
  const truncatedName = nameWithoutExt.substring(0, truncatedLength - 3) + '...';

  return extension ? `${truncatedName}.${extension}` : truncatedName;
};

const ItemRow = ({ item, onSelect, isDarkMode, isSelected }) => {
  const [isHovered, setIsHovered] = useState(false);

  if (!item._id || !item.name) {
    console.error('Invalid item:', item);
    return null;
  }

  return (
    <motion.button
      onClick={() => onSelect(item)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.2 }}
      className={cn(
        "w-full text-left p-3 mb-2.5 flex items-center",
        "transition-all duration-200 rounded-xl border",
        isDarkMode 
          ? "hover:bg-[#1E1E1E] active:bg-[#252525]" 
          : "hover:bg-white active:bg-gray-50",
        isSelected
          ? isDarkMode
            ? "bg-[#1E1E1E] border-[#333333] shadow-lg shadow-black/10"
            : "bg-white border-blue-200 shadow-lg shadow-blue-500/5"
          : isDarkMode
            ? "border-transparent bg-transparent"
            : "border-transparent bg-transparent",
        "hover:shadow-md"
      )}
    >
      <div className={cn(
        "w-8 h-8 rounded-lg flex items-center justify-center flex-shrink-0 mr-3",
        isDarkMode ? "bg-[#252525]" : "bg-gray-100"
      )}>
        {item.type === 'folder' ? (
          <FolderIcon className={cn(
            "w-4 h-4",
            isDarkMode ? "text-yellow-400" : "text-yellow-500"
          )} />
        ) : (
          <DocumentIcon className={cn(
            "w-4 h-4",
            isDarkMode ? "text-blue-400" : "text-blue-500"
          )} />
        )}
      </div>
      <div className="flex-grow min-w-0 flex items-center justify-between">
        <div className="min-w-0 flex-shrink">
          <h3 className={cn(
            "font-medium text-sm mb-1 truncate max-w-full",
            isDarkMode ? "text-slate-100" : "text-gray-900"
          )}
          title={item.name}
          >
            {truncateFileName(item.name)}
          </h3>
          {item.updatedAt && (
            <p className={cn(
              "text-xs truncate",
              isDarkMode ? "text-slate-400" : "text-gray-500"
            )}>
              {formatDistanceToNow(new Date(item.updatedAt), { addSuffix: true })}
            </p>
          )}
        </div>
        {item.size && (
          <span className={cn(
            "text-xs ml-3 flex-shrink-0",
            isDarkMode ? "text-slate-400" : "text-gray-500"
          )}>
            {formatFileSize(item.size)}
          </span>
        )}
      </div>
    </motion.button>
  );
};

const KnowledgeBaseSelector = ({ 
  onSelect, 
  onClose, 
  initialSelectedFiles = [],
  containerWidth
}) => {
  const { isDarkMode } = useTheme();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(initialSelectedFiles);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);
  const fileInputRef = useRef(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const loadKnowledgeBases = useCallback(async (folderId = null) => {
    setLoading(true);
    try {
      const data = await fetchKnowledgeBases(folderId);
      setItems(data);
      setCurrentFolderId(folderId);
    } catch (error) {
      console.error('Error fetching knowledge bases:', error);
      setError('Failed to load files. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadKnowledgeBases();
  }, [loadKnowledgeBases]);

  const handleFileSelect = async (file) => {
    if (file.type === 'folder') {
      setCurrentFolderId(file._id);
      setCurrentPath(prev => [...prev, { _id: file._id, name: file.name }]);
      loadKnowledgeBases(file._id);
      return;
    }

    if (!file._id) {
      console.error('File missing ID:', file);
      toast.error('Invalid file selected');
      return;
    }

    setSelectedFiles(prev => {
      const isAlreadySelected = prev.some(selected => selected._id === file._id);
      if (isAlreadySelected) {
        return prev.filter(selected => selected._id !== file._id);
      } else {
        const validatedFile = {
          _id: file._id,
          name: file.name || 'Untitled',
          type: file.type || 'file',
          size: file.size || 0,
          updatedAt: file.updatedAt || new Date().toISOString()
        };
        return [...prev, validatedFile];
      }
    });
  };

  const handleConfirmSelection = () => {
    const validFiles = selectedFiles.filter(file => {
      if (!file._id) {
        console.error('Invalid file in selection:', file);
        return false;
      }
      return true;
    });

    if (validFiles.length !== selectedFiles.length) {
      toast.warning('Some files are invalid and will be skipped');
    }

    if (validFiles.length === 0) {
      toast.error('No valid files selected');
      return;
    }

    onSelect(validFiles);
    onClose();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const formData = new FormData();
      formData.append('file', file);
      if (currentFolderId) {
        formData.append('parentId', currentFolderId);
      }

      await uploadFile(formData, (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      });

      await loadKnowledgeBases(currentFolderId);
      toast.success('File uploaded successfully');
    } catch (error) {
      console.error('Failed to upload file:', error);
      toast.error('Failed to upload file');
    } finally {
      setIsUploading(false);
      setUploadProgress(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const navigateToFolder = (path) => {
    const folderId = path.length > 0 ? path[path.length - 1]._id : null;
    setCurrentPath(path);
    setCurrentFolderId(folderId);
    loadKnowledgeBases(folderId);
  };

  return (
    <div className="absolute inset-0 z-50 flex">
      <div className="flex-1 h-full">
        <motion.div 
          className={cn(
            "flex flex-col h-full relative overflow-hidden border-none rounded-none",
            isDarkMode ? "bg-[#121212]" : "bg-gray-50"
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {/* Header */}
          <div className={cn(
            "h-12 min-h-12 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b",
            "sticky top-0 z-50 backdrop-blur-xl",
            isDarkMode 
              ? "bg-[#151515]/80 border-[#333333]" 
              : "bg-white/80 border-gray-200"
          )}>
            <div className="flex items-center space-x-2">
              <div className={cn(
                "w-8 h-8 rounded-lg flex items-center justify-center",
                isDarkMode ? "bg-[#252525]" : "bg-gray-100"
              )}>
                <FolderIcon className={cn(
                  "w-4 h-4",
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                )} />
              </div>
              <h2 className={cn(
                "text-sm font-medium tracking-tight",
                isDarkMode ? "text-slate-100" : "text-gray-900"
              )}>
                Knowledge Base
              </h2>
            </div>
            <div className="flex items-center space-x-2">
              <label className={cn(
                "flex items-center px-3 py-1.5 rounded-lg text-xs cursor-pointer",
                "transition-all duration-200",
                isDarkMode 
                  ? "bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-[#333333]" 
                  : "bg-white hover:bg-gray-50 text-gray-700 border border-gray-200",
                "hover:shadow-md"
              )}>
                <ArrowUpTrayIcon className="w-3.5 h-3.5 mr-1.5" />
                <span>Upload</span>
                <input
                  ref={fileInputRef}
                  type="file"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </label>

              <button 
                onClick={onClose}
                className={cn(
                  "p-2 rounded-lg transition-all duration-200",
                  isDarkMode 
                    ? "text-slate-400 hover:text-slate-200 hover:bg-white/5 active:bg-white/10" 
                    : "text-gray-500 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200"
                )}
                title="Back to Chat"
              >
                <ArrowLeftIcon className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Search and Breadcrumbs */}
          <div className={cn(
            "px-4 py-2",
            isDarkMode ? "bg-[#151515]" : "bg-white",
            "border-b",
            isDarkMode ? "border-[#333333]" : "border-gray-200"
          )}>
            <div className="flex items-center justify-between">
              {/* Breadcrumbs */}
              <div className="flex items-center space-x-2 text-sm flex-1 min-w-0 mr-4 overflow-hidden">
                <div className="flex items-center space-x-2 overflow-x-auto no-scrollbar">
                  <button
                    onClick={() => navigateToFolder([])}
                    className={cn(
                      "hover:underline whitespace-nowrap flex-shrink-0",
                      isDarkMode ? "text-slate-200 hover:text-slate-100" : "text-gray-600 hover:text-gray-900"
                    )}
                  >
                    Home
                  </button>
                  {currentPath.map((folder, index) => (
                    <React.Fragment key={folder._id}>
                      <span className={cn(
                        "whitespace-nowrap flex-shrink-0",
                        isDarkMode ? "text-[#333333]" : "text-gray-400"
                      )}>/</span>
                      <button
                        onClick={() => navigateToFolder(currentPath.slice(0, index + 1))}
                        className={cn(
                          "hover:underline whitespace-nowrap truncate max-w-[150px]",
                          isDarkMode ? "text-slate-200 hover:text-slate-100" : "text-gray-600 hover:text-gray-900"
                        )}
                        title={folder.name}
                      >
                        {truncateFileName(folder.name, 20)}
                      </button>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              {/* Search */}
              <div className="relative flex items-center flex-shrink-0">
                <AnimatePresence mode="wait">
                  {isSearchOpen ? (
                    <motion.div
                      initial={{ opacity: 0, width: 0 }}
                      animate={{ opacity: 1, width: "auto" }}
                      exit={{ opacity: 0, width: 0 }}
                      transition={{ duration: 0.2 }}
                      className="flex items-center"
                    >
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Search files..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className={cn(
                            "pl-8 pr-8 py-1.5 text-sm rounded-lg w-[180px] sm:w-[200px] md:w-[250px]",
                            "transition-all duration-200",
                            isDarkMode 
                              ? "bg-[#252525] text-slate-200 placeholder-slate-400 border-[#333333]" 
                              : "bg-white text-gray-900 placeholder-gray-500 border-gray-200",
                            "border focus:outline-none focus:ring-2",
                            isDarkMode
                              ? "focus:ring-blue-500/20"
                              : "focus:ring-blue-500/20",
                            "focus:border-blue-500"
                          )}
                          autoFocus
                        />
                        <MagnifyingGlassIcon className={cn(
                          "absolute left-2.5 top-1/2 transform -translate-y-1/2 w-3.5 h-3.5",
                          isDarkMode ? "text-slate-400" : "text-gray-400"
                        )} />
                        <button
                          onClick={() => {
                            setIsSearchOpen(false);
                            setSearchTerm('');
                          }}
                          className={cn(
                            "absolute right-2 top-1/2 transform -translate-y-1/2 p-0.5 rounded-full",
                            isDarkMode 
                              ? "hover:bg-[#333333] text-slate-400 hover:text-slate-300" 
                              : "hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                          )}
                        >
                          <XMarkIcon className="w-3.5 h-3.5" />
                        </button>
                      </div>
                    </motion.div>
                  ) : (
                    <motion.button
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      onClick={() => setIsSearchOpen(true)}
                      className={cn(
                        "p-1.5 rounded-lg transition-all duration-200",
                        isDarkMode 
                          ? "hover:bg-[#252525] text-slate-400 hover:text-slate-300" 
                          : "hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                      )}
                      title="Search files"
                    >
                      <MagnifyingGlassIcon className="w-4 h-4" />
                    </motion.button>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>

          {/* Content Area */}
          <div className={cn(
            "flex-grow",
            isDarkMode ? "bg-[#121212]" : "bg-gray-50"
          )}>
            {loading ? (
              <div className="px-4 py-3">
                <SkeletonLoader 
                  isDarkMode={isDarkMode} 
                  count={5} 
                  variant="knowledge"
                  iconClassName="w-8 h-8"
                />
              </div>
            ) : items.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full p-4">
                <div className={cn(
                  "w-12 h-12 rounded-xl flex items-center justify-center mb-3",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}>
                  <FolderIcon className={cn(
                    "w-6 h-6",
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  )} />
                </div>
                <p className={cn(
                  "text-center text-sm",
                  isDarkMode ? "text-slate-400" : "text-gray-500"
                )}>
                  This folder is empty
                </p>
              </div>
            ) : (
              <div className="px-3 py-2 space-y-2">
                <AnimatePresence mode="popLayout">
                  {items
                    .filter(item => 
                      item.name.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                    .map(item => (
                      <ItemRow
                        key={item._id}
                        item={item}
                        onSelect={handleFileSelect}
                        isDarkMode={isDarkMode}
                        isSelected={selectedFiles.some(file => file._id === item._id)}
                      />
                    ))
                  }
                </AnimatePresence>
              </div>
            )}
          </div>

          {/* Footer */}
          <div className={cn(
            "px-4 py-3 sticky bottom-0 border-t",
            isDarkMode 
              ? "bg-[#151515]/80 border-[#333333]" 
              : "bg-white/80 border-gray-200",
            "backdrop-blur-xl"
          )}>
            <AddToChat
              onClick={handleConfirmSelection}
              disabled={selectedFiles.length === 0}
              selectedCount={selectedFiles.length}
            />
          </div>

          {/* Upload Progress */}
          {isUploading && (
            <div className={cn(
              "absolute bottom-4 right-4 p-4 rounded-xl shadow-lg",
              isDarkMode ? "bg-[#151515]" : "bg-white",
              "border",
              isDarkMode ? "border-[#333333]" : "border-gray-200"
            )}>
              <div className="flex items-center space-x-4">
                <div>
                  <div className={cn(
                    "text-sm font-medium mb-1",
                    isDarkMode ? "text-slate-200" : "text-gray-900"
                  )}>
                    Uploading...
                  </div>
                  <div className={cn(
                    "text-xs",
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  )}>
                    {uploadProgress}%
                  </div>
                </div>
                <div className="w-24 h-2 bg-gray-200 rounded-full overflow-hidden">
                  <motion.div 
                    className="h-full bg-blue-500"
                    initial={{ width: 0 }}
                    animate={{ width: `${uploadProgress}%` }}
                    transition={{ duration: 0.3 }}
                  />
                </div>
              </div>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default KnowledgeBaseSelector;