import React, { useRef } from 'react';
import { EllipsisHorizontalIcon, TrashIcon, TagIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';

const NoteMenu = ({ isOpen, onToggle, onDelete, isDarkMode, anchorEl, onTagClick }) => {
  const menuRef = useRef(null);

  const handleToggle = (e) => {
    e.stopPropagation();
    onToggle(e.currentTarget);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
    onToggle(null);
  };

  const handleTagClick = (e) => {
    e.stopPropagation();
    onTagClick(e);
    onToggle(null);
  };

  const getMenuPosition = () => {
    if (!anchorEl) return {};
    const rect = anchorEl.getBoundingClientRect();
    return {
      top: `${rect.bottom + window.scrollY + 5}px`,
      left: `${rect.left + window.scrollX - 120}px`,
    };
  };

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleToggle}
        className={`
          p-1.5 rounded-lg transition-colors duration-200
          ${isDarkMode 
            ? 'text-slate-400 hover:bg-slate-800/50 hover:text-slate-200' 
            : 'text-slate-500 hover:bg-slate-100 hover:text-slate-900'}
        `}
        title="More options"
      >
        <EllipsisHorizontalIcon className="w-3.5 h-3.5" />
      </motion.button>

      {createPortal(
        <AnimatePresence>
          {isOpen && anchorEl && (
            <div className="fixed inset-0 z-50" onClick={() => onToggle(null)}>
              <motion.div
                ref={menuRef}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ 
                  type: "spring",
                  stiffness: 400,
                  damping: 30
                }}
                className={`
                  fixed z-[100] w-36 overflow-hidden rounded-lg border shadow-lg
                  ${isDarkMode 
                    ? 'bg-slate-950 border-slate-800/40' 
                    : 'bg-white border-slate-200/60'}
                `}
                style={getMenuPosition()}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="py-1">
                  {/* Tags Button */}
                  <button
                    className={`
                      flex w-full items-center gap-2 px-3 py-2 text-xs font-medium
                      transition-colors
                      ${isDarkMode
                        ? 'text-slate-300 hover:bg-slate-800/50 hover:text-slate-200'
                        : 'text-slate-700 hover:bg-slate-100 hover:text-slate-900'}
                    `}
                    onClick={handleTagClick}
                  >
                    <TagIcon className="h-3.5 w-3.5" />
                    <span>Tags</span>
                  </button>

                  {/* Delete Button */}
                  <button
                    className={`
                      flex w-full items-center gap-2 px-3 py-2 text-xs font-medium
                      transition-colors
                      ${isDarkMode
                        ? 'text-red-400 hover:bg-slate-800/50 hover:text-red-300'
                        : 'text-red-600 hover:bg-slate-100 hover:text-red-700'}
                    `}
                    onClick={handleDelete}
                  >
                    <TrashIcon className="h-3.5 w-3.5" />
                    <span>Delete</span>
                  </button>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>,
        document.body
      )}
    </>
  );
};

NoteMenu.isClickInsideMenu = (element, menuRef) => {
  return menuRef?.current && menuRef.current.contains(element);
};

export default NoteMenu;
