import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { PencilIcon, TrashIcon, ArrowDownTrayIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

const PopupMenu = forwardRef(({ onClose, onEdit, onDelete, onGetInfo, onDownload, currentItemId, position, item, isDarkMode }, ref) => {
  const menuRef = useRef(null);
  const [menuWidth, setMenuWidth] = useState('auto');

  useEffect(() => {
    const adjustPosition = () => {
      if (menuRef.current) {
        const rect = menuRef.current.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let adjustedX = position.x;
        let adjustedY = position.y;

        // Adjust horizontal position
        if (adjustedX + rect.width > viewportWidth) {
          adjustedX = Math.max(0, viewportWidth - rect.width);
        }

        // Adjust vertical position
        if (adjustedY + rect.height > viewportHeight) {
          adjustedY = Math.max(0, viewportHeight - rect.height);
        }

        menuRef.current.style.left = `${adjustedX}px`;
        menuRef.current.style.top = `${adjustedY}px`;

        // Set the menu width to fit content
        setMenuWidth(`${rect.width}px`);
      }
    };

    adjustPosition();

    // Add event listener for window resize
    window.addEventListener('resize', adjustPosition);

    // Add event listener for clicks outside the menu
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup
    return () => {
      window.removeEventListener('resize', adjustPosition);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [position, onClose]);

  const handleAction = (action) => {
    action();
    onClose();
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      ref={menuRef}
      className={`fixed z-50 rounded-md shadow-lg py-1 ${
        isDarkMode ? 'bg-slate-800 text-slate-200 border border-slate-700' : 'bg-white text-gray-700'
      }`}
      style={{
        width: menuWidth,
        minWidth: '170px',
        maxWidth: '300px',
      }}
      onClick={stopPropagation}
    >
      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <button
          onClick={() => handleAction(() => onEdit(item))}
          className={`w-full text-left px-4 py-2 text-sm ${
            isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
          } flex items-center`}
          role="menuitem"
        >
          <PencilIcon className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
          Rename
        </button>
        <button
          onClick={() => handleAction(() => onDelete(item))}
          className={`w-full text-left px-4 py-2 text-sm ${
            isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
          } flex items-center`}
          role="menuitem"
        >
          <TrashIcon className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
          Delete
        </button>
        {item.type !== 'folder' && (
          <button
            onClick={() => handleAction(() => onDownload(item))}
            className={`w-full text-left px-4 py-2 text-sm ${
              isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
            } flex items-center`}
            role="menuitem"
          >
            <ArrowDownTrayIcon className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
            Download
          </button>
        )}
        <button
          onClick={() => handleAction(() => onGetInfo(item))}
          className={`w-full text-left px-4 py-2 text-sm ${
            isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
          } flex items-center`}
          role="menuitem"
        >
          <InformationCircleIcon className={`w-5 h-5 mr-2 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`} />
          Get Info
        </button>
      </div>
    </div>
  );
});

export default PopupMenu;
