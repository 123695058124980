import React, { useState, useEffect, useCallback, useRef } from 'react';
import { XMarkIcon, LightBulbIcon, CheckIcon, ArrowLeftIcon, DocumentTextIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '../../context/ThemeContext';
import { fetchIdeas } from '../../api';
import DOMPurify from 'dompurify';
import { motion, AnimatePresence } from 'framer-motion';
import AddToChat from '../common/AddToChat';
import { cn } from '../../utils/cn';
import SkeletonLoader from '../common/SkeletonLoader';
import { useNavigate } from 'react-router-dom';

const TextNoteSelector = ({ onSelect, onClose, initialSelectedNotes = [] }) => {
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 639 });
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState(initialSelectedNotes);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const observerTarget = useRef(null);
  const pageSize = 15;
  const navigate = useNavigate();

  const loadIdeas = useCallback(async (pageToLoad = 1) => {
    try {
      if (pageToLoad === 1) {
        setLoading(true);
        setNotes([]);
      } else {
        setIsLoadingMore(true);
      }

      const ideas = await fetchIdeas(pageToLoad, pageSize);
      setNotes(prev => pageToLoad === 1 ? ideas : [...prev, ...ideas]);
      setHasMore(ideas.length === pageSize);
      
    } catch (err) {
      console.error('Error loading ideas:', err);
      setError('Failed to load ideas. Please try again.');
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  }, []);

  useEffect(() => {
    loadIdeas(1);
  }, [loadIdeas]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !loading && !isLoadingMore) {
          setPage(prev => prev + 1);
          loadIdeas(page + 1);
        }
      },
      { threshold: 0.1 }
    );

    const currentTarget = observerTarget.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [hasMore, loading, isLoadingMore, page, loadIdeas]);

  const truncateContent = (content, maxLength = 100) => {
    if (!content) return 'No content available';
    
    try {
      const parsedContent = JSON.parse(content);
      if (parsedContent && parsedContent.blocks) {
        content = parsedContent.blocks
          .map(block => block.data && block.data.text ? block.data.text : '')
          .join('\n');
      }
    } catch (e) {
      // If parsing fails, use content as is
    }

    content = DOMPurify.sanitize(content, { ALLOWED_TAGS: [] });
    return content.length <= maxLength ? content : `${content.substr(0, maxLength)}...`;
  };

  const handleNoteSelection = (note) => {
    setSelectedNotes(prevSelected => {
      const isAlreadySelected = prevSelected.some(selected => selected._id === note._id);
      return isAlreadySelected
        ? prevSelected.filter(selected => selected._id !== note._id)
        : [...prevSelected, note];
    });
  };

  const handleConfirmSelection = () => {
    onSelect(selectedNotes);
    onClose();
  };

  const handleCreateNote = () => {
    navigate('/ideas');
    onClose();
  };

  return (
    <div className="absolute inset-0 z-50 flex">
      <div className="flex-1 h-full">
        <motion.div 
          className={cn(
            "flex flex-col h-full relative overflow-hidden border-none rounded-none",
            isDarkMode ? "bg-[#121212]" : "bg-gray-50"
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {/* Header */}
          <div className={cn(
            "h-12 min-h-12 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b",
            "sticky top-0 z-50 backdrop-blur-xl",
            isDarkMode 
              ? "bg-[#151515]/80 border-[#333333]" 
              : "bg-white/80 border-gray-200"
          )}>
            <div className="flex items-center space-x-2">
              <div className={cn(
                "w-8 h-8 rounded-lg flex items-center justify-center",
                isDarkMode ? "bg-[#252525]" : "bg-gray-100"
              )}>
                <DocumentTextIcon className={cn(
                  "w-4 h-4",
                  isDarkMode ? "text-slate-300" : "text-gray-700"
                )} />
              </div>
              <h2 className={cn(
                "text-sm font-medium tracking-tight",
                isDarkMode ? "text-slate-100" : "text-gray-900"
              )}>
                Text Notes
              </h2>
            </div>
            <button 
              onClick={onClose}
              className={cn(
                "p-2 rounded-lg transition-all duration-200",
                isDarkMode 
                  ? "text-slate-400 hover:text-slate-200 hover:bg-white/5 active:bg-white/10" 
                  : "text-gray-500 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200"
              )}
              title="Back to Chat"
            >
              <ArrowLeftIcon className="w-4 h-4" />
            </button>
          </div>

          {/* Content Area */}
          <div className={cn(
            "flex-grow",
            isDarkMode ? "bg-[#121212]" : "bg-gray-50"
          )}>
            {error ? (
              <div className="flex flex-col items-center justify-center h-full p-4">
                <p className={cn(
                  "text-sm mb-4",
                  isDarkMode ? "text-red-400" : "text-red-600"
                )}>
                  {error}
                </p>
                <button
                  onClick={() => loadIdeas(1)}
                  className={cn(
                    "px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200",
                    "bg-blue-600 text-white",
                    "hover:bg-blue-500 active:bg-blue-700",
                    "hover:shadow-lg hover:shadow-blue-500/20"
                  )}
                >
                  Retry
                </button>
              </div>
            ) : loading ? (
              <div className="px-4 py-3">
                <SkeletonLoader 
                  isDarkMode={isDarkMode} 
                  count={5} 
                  variant="default"
                  iconClassName="w-8 h-8"
                />
              </div>
            ) : notes.length === 0 && !loading ? (
              <div className="flex flex-col items-center justify-center h-full p-4">
                <div className={cn(
                  "w-12 h-12 rounded-xl flex items-center justify-center mb-3",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}>
                  <DocumentTextIcon className={cn(
                    "w-6 h-6",
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  )} />
                </div>
                <p className={cn(
                  "text-center text-sm mb-4",
                  isDarkMode ? "text-slate-400" : "text-gray-500"
                )}>
                  No notes available
                </p>
                <button
                  onClick={handleCreateNote}
                  className={cn(
                    "flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium",
                    "transition-all duration-200",
                    isDarkMode
                      ? "bg-blue-600/10 text-blue-400 hover:bg-blue-600/20"
                      : "bg-blue-50 text-blue-600 hover:bg-blue-100"
                  )}
                >
                  <PlusIcon className="w-4 h-4" />
                  Create New Note
                </button>
              </div>
            ) : (
              <div className="px-3 py-2 space-y-2">
                <AnimatePresence mode="popLayout">
                  {notes.map((note) => (
                    <motion.button
                      key={note._id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      transition={{ duration: 0.2 }}
                      onClick={() => handleNoteSelection(note)}
                      className={cn(
                        "w-full text-left p-3 flex items-center",
                        "transition-all duration-200 rounded-xl border",
                        isDarkMode 
                          ? "hover:bg-[#1E1E1E] active:bg-[#252525]" 
                          : "hover:bg-white active:bg-gray-50",
                        selectedNotes.some(selected => selected._id === note._id)
                          ? isDarkMode
                            ? "bg-[#1E1E1E] border-[#333333] shadow-lg shadow-black/10"
                            : "bg-white border-blue-200 shadow-lg shadow-blue-500/5"
                          : isDarkMode
                            ? "border-transparent bg-transparent"
                            : "border-transparent bg-transparent",
                        "hover:shadow-md"
                      )}
                    >
                      <div className={cn(
                        "w-8 h-8 rounded-lg flex items-center justify-center flex-shrink-0 mr-3",
                        isDarkMode 
                          ? "bg-[#252525]" 
                          : "bg-gray-100"
                      )}>
                        <DocumentTextIcon className={cn(
                          "w-4 h-4",
                          isDarkMode ? "text-slate-400" : "text-gray-500"
                        )} />
                      </div>
                      <div className="flex-grow min-w-0">
                        <h3 className={cn(
                          "font-medium text-sm mb-1 truncate",
                          isDarkMode ? "text-slate-100" : "text-gray-900"
                        )}>
                          {note.title || 'Untitled Note'}
                        </h3>
                        <p className={cn(
                          "text-xs line-clamp-2",
                          isDarkMode ? "text-slate-400" : "text-gray-500"
                        )}>
                          {truncateContent(note.content)}
                        </p>
                        {note.tags && note.tags.length > 0 && (
                          <div className="flex flex-wrap gap-1.5 mt-2">
                            {note.tags.slice(0, 3).map((tag) => (
                              <span
                                key={tag}
                                className={cn(
                                  "px-1.5 py-0.5 rounded-full text-[10px]",
                                  isDarkMode 
                                    ? "bg-[#252525] text-slate-300" 
                                    : "bg-gray-100 text-gray-600"
                                )}
                              >
                                {tag}
                              </span>
                            ))}
                            {note.tags.length > 3 && (
                              <span className={cn(
                                "text-[10px]",
                                isDarkMode ? "text-slate-400" : "text-gray-500"
                              )}>
                                +{note.tags.length - 3}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      {selectedNotes.some(selected => selected._id === note._id) && (
                        <div className={cn(
                          "w-8 h-8 rounded-lg flex items-center justify-center flex-shrink-0 ml-3",
                          isDarkMode 
                            ? "bg-blue-500/10" 
                            : "bg-blue-50"
                        )}>
                          <CheckIcon className={cn(
                            "h-4 w-4",
                            isDarkMode ? "text-blue-400" : "text-blue-600"
                          )} />
                        </div>
                      )}
                    </motion.button>
                  ))}
                </AnimatePresence>
                {loading && (
                  <div className="py-3 text-center">
                    <div className="inline-block animate-spin rounded-full h-8 w-8 border-2 border-blue-500/20 border-t-blue-500"></div>
                  </div>
                )}
                {/* Observer target for infinite scroll */}
                <div ref={observerTarget} className="h-4 w-full" />
              </div>
            )}
          </div>

          {/* Footer */}
          <div className={cn(
            "px-4 py-3 sticky bottom-0 border-t",
            isDarkMode 
              ? "bg-[#151515]/80 border-[#333333]" 
              : "bg-white/80 border-gray-200",
            "backdrop-blur-xl"
          )}>
            <AddToChat
              onClick={handleConfirmSelection}
              disabled={selectedNotes.length === 0}
              selectedCount={selectedNotes.length}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default TextNoteSelector;
