import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Eye, EyeOff, AlertCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import { requestPasswordReset } from '../../services/passwordService';
import { toast } from 'react-hot-toast';
import logo from '../../logo.svg';
import AnimatedLoader from '../common/AnimatedLoader';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      // Basic validation
      if (!email.trim()) {
        setError('Please enter your email address');
        return;
      }

      // Email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setError('Please enter a valid email address');
        return;
      }

      const result = await requestPasswordReset(email);
      
      if (result.success) {
        setSuccess(true);
        toast.success(result.message || 'Password reset instructions sent to your email');
      }
    } catch (error) {
      console.error('Password reset request error:', error);
      setError(error.message || 'Failed to send reset instructions');
      toast.error('Failed to send reset instructions. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`min-h-screen bg-fixed ${
      isDarkMode 
        ? 'bg-gradient-to-br from-[#121212] via-[#1a1a1a] to-[#121212]' 
        : 'bg-gradient-to-br from-slate-50 via-white to-slate-50'
    }`}>
      <div className="max-w-md mx-auto px-4 sm:px-6 min-h-screen flex flex-col items-center justify-center py-8">
        <Link
          to="/"
          className={`inline-flex items-center gap-2 text-sm mb-4 ${
            isDarkMode ? 'text-slate-400 hover:text-slate-300' : 'text-slate-600 hover:text-slate-800'
          } transition-colors`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
          </svg>
          Back to Login
        </Link>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className={`w-full p-6 md:p-8 rounded-2xl ${
            isDarkMode 
              ? 'bg-[#1E1E1E] border border-gray-800/40' 
              : 'bg-white border border-slate-200/60'
          }`}
        >
          <div className="text-center mb-6">
            <Link to="/" className="inline-block mb-6">
              <img src={logo} alt="Ammmplify Logo" className="h-8 mx-auto" />
            </Link>
            <h2 className={`text-2xl font-bold mb-2 ${
              isDarkMode ? 'text-white' : 'text-slate-900'
            }`}>
              Reset Password
            </h2>
            <p className={`text-sm ${
              isDarkMode ? 'text-slate-400' : 'text-slate-600'
            }`}>
              Enter your email to receive password reset instructions
            </p>
          </div>

          {error && (
            <motion.div 
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-4 p-3 rounded-lg bg-red-100 border border-red-200 text-red-600 text-sm flex items-center gap-2"
            >
              <AlertCircle className="h-4 w-4 flex-shrink-0" />
              {error}
            </motion.div>
          )}

          {success ? (
            <div className={`text-center p-4 rounded-lg ${
              isDarkMode 
                ? 'bg-green-500/10 text-green-400' 
                : 'bg-green-50 text-green-600'
            }`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 className="text-lg font-semibold mb-2">Check Your Email</h3>
              <p className="text-sm mb-4">
                We've sent password reset instructions to {email}
              </p>
              <Link
                to="/login"
                className="text-blue-500 hover:text-blue-600 font-medium text-sm"
              >
                Return to Login
              </Link>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-5">
              <div>
                <label className="sr-only" htmlFor="email">
                  Email address
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Work email"
                  required
                  className={`w-full px-3 py-3 rounded-lg border focus:ring-2 focus:ring-offset-2 transition-colors ${
                    isDarkMode 
                      ? 'bg-[#2A2A2A] border-gray-700 text-white placeholder-gray-500 focus:ring-blue-500/40 focus:border-blue-500' 
                      : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:ring-blue-500/40 focus:border-blue-500'
                  }`}
                />
              </div>

              <button
                type="submit"
                disabled={isLoading}
                className={`w-full py-3 px-4 rounded-lg font-semibold text-white text-sm
                  ${isLoading 
                    ? 'bg-blue-400 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700'
                  } transition-all duration-200 transform hover:scale-[1.02] active:scale-[0.98] relative overflow-hidden`}
              >
                {isLoading ? (
                  <span className="flex items-center justify-center gap-2">
                    <AnimatedLoader size={16} color="white" />
                    Sending Instructions...
                  </span>
                ) : (
                  'Send Reset Instructions'
                )}
              </button>

              <p className={`mt-6 text-sm text-center ${
                isDarkMode ? 'text-slate-400' : 'text-slate-600'
              }`}>
                Remember your password?{' '}
                <Link to="/login" className="text-blue-500 hover:text-blue-600 font-medium">
                  Sign in
                </Link>
              </p>
            </form>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default ResetPassword; 