import React, { useState, useRef, useEffect, memo } from 'react';
import { XMarkIcon, EyeIcon, CpuChipIcon, BookOpenIcon, DocumentTextIcon, ClockIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import PropTypes from 'prop-types';

// Memoized Tag Content component
const TagContent = memo(({ agent, isDarkMode, onButtonClick, buttonRef, onRemove }) => (
  <div 
    className={`px-3 py-2 rounded-md flex flex-col text-xs 
      ${isDarkMode 
        ? 'bg-gradient-to-r from-slate-800 to-slate-700 text-slate-200 hover:from-slate-700 hover:to-slate-600' 
        : 'bg-gradient-to-r from-slate-100 to-white text-slate-700 hover:from-slate-50 hover:to-slate-100 border border-slate-200/60'}
      transition-all duration-300 ease-in-out transform hover:scale-[1.01] shadow-sm`}
  >
    {/* Header Section */}
    <div className="flex justify-between items-center mb-1.5">
      <div className="flex items-center flex-grow">
        {agent.avatarUrl ? (
          <img 
            src={agent.avatarUrl} 
            alt={`${agent.name} avatar`}
            className={`h-5 w-5 rounded-full mr-2 flex-shrink-0 ${isDarkMode ? 'border border-slate-600' : 'border border-slate-200'}`}
            onError={(e) => {
              e.target.src = 'fallback-avatar.png';
              e.target.onerror = null;
            }}
          />
        ) : (
          <CpuChipIcon className={`h-5 w-5 mr-2 flex-shrink-0 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} />
        )}
        <div className="flex flex-col">
          <h3 className="font-medium truncate leading-tight">{agent.name}</h3>
          {agent.categories && agent.categories.length > 0 && (
            <span className={`text-[10px] truncate ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
              {agent.categories[0]}{agent.categories.length > 1 ? ` +${agent.categories.length - 1}` : ''}
            </span>
          )}
        </div>
      </div>
      <div className="flex items-center space-x-1 ml-2">
        <button
          ref={buttonRef}
          onClick={onButtonClick}
          className={`p-1 rounded-md transition-colors duration-200 
            ${isDarkMode 
              ? 'hover:bg-slate-600/50' 
              : 'hover:bg-slate-200/50'}`}
          aria-label="View AI agent details"
          title="View AI agent details"
        >
          <EyeIcon className="h-4 w-4" />
        </button>
        <button
          onClick={onRemove}
          className={`p-1 rounded-md transition-colors duration-200 
            ${isDarkMode 
              ? 'hover:bg-slate-600/50' 
              : 'hover:bg-slate-200/50'}`}
          aria-label="Remove AI agent"
          title="Remove AI agent"
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>
    </div>

    {/* Description Section - Only show if exists */}
    {agent.description && (
      <p className={`text-[10px] line-clamp-2 leading-tight ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
        {agent.description}
      </p>
    )}

    {/* Stats Section */}
    <div className="flex items-center justify-between mt-1.5 text-[10px]">
      <div className="flex items-center space-x-2">
        {agent.trainingExamples?.length > 0 && (
          <span className={`${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} title="Training examples">
            {agent.trainingExamples.length} examples
          </span>
        )}
        {agent.systemInstructions && (
          <span className={`flex items-center ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} title="Has system instructions">
            <CpuChipIcon className="h-3 w-3 mr-0.5" />
            Configured
          </span>
        )}
      </div>
      {agent.lastUsed && (
        <span className={`truncate ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} title="Last used">
          Last: {new Date(agent.lastUsed).toLocaleDateString()}
        </span>
      )}
    </div>
  </div>
));

// Memoized Popup Content component
const PopupContent = memo(({ agent, isDarkMode, contentRef }) => (
  <div 
    ref={contentRef}
    className={`absolute z-10 right-0 bottom-full mb-1 p-4 rounded-lg shadow-lg w-96 max-w-[90vw]
      ${isDarkMode 
        ? 'bg-gradient-to-b from-slate-800 to-slate-800/95 text-slate-200 border border-slate-700' 
        : 'bg-gradient-to-b from-white to-slate-50/95 text-slate-700 border border-slate-200/60'}
      backdrop-blur-sm transform transition-all duration-200 ease-in-out`}
    role="dialog"
    aria-label={`Details for ${agent.name}`}
  >
    {/* Header with Avatar and Basic Info */}
    <div className="mb-6">
      <div className="flex items-start space-x-3">
        {agent.avatarUrl ? (
          <img 
            src={agent.avatarUrl} 
            alt={`${agent.name} full avatar`}
            className={`h-12 w-12 rounded-full ${isDarkMode ? 'border-2 border-slate-600' : 'border-2 border-slate-200'}`}
            onError={(e) => {
              e.target.src = 'fallback-avatar.png';
              e.target.onerror = null;
            }}
          />
        ) : (
          <div className={`h-12 w-12 rounded-full flex items-center justify-center ${
            isDarkMode ? 'bg-slate-700' : 'bg-slate-100'
          }`}>
            <CpuChipIcon className={`h-6 w-6 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} />
          </div>
        )}
        <div className="flex-1 min-w-0">
          <h3 className="font-semibold text-base mb-0.5 truncate">{agent.name}</h3>
          <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
            {agent.description || 'No description available'}
          </p>
          {agent.categories?.length > 0 && (
            <div className="flex flex-wrap gap-1.5 mt-2">
              {agent.categories.map((category, index) => (
                <span 
                  key={index}
                  className={`text-xs px-2 py-0.5 rounded-full inline-flex items-center
                    ${isDarkMode 
                      ? 'bg-slate-700/50 text-slate-300 border border-slate-600/50' 
                      : 'bg-slate-100/50 text-slate-600 border border-slate-200/50'}`}
                >
                  {category}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>

    {/* System Instructions Section */}
    <div className="mb-6">
      <h4 className={`text-sm font-medium mb-2 flex items-center ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
        <CpuChipIcon className="h-4 w-4 mr-1.5" />
        System Instructions
      </h4>
      <div 
        className={`text-xs p-3 rounded-md ${
          isDarkMode 
            ? 'bg-slate-700/50 border border-slate-600/50' 
            : 'bg-slate-100/50 border border-slate-200/50'
        }`}
      >
        <pre className="whitespace-pre-wrap font-mono max-h-32 overflow-y-auto custom-scrollbar">
          {agent.systemInstructions || 'No system instructions available'}
        </pre>
      </div>
    </div>

    {/* Training Examples Section */}
    {agent.trainingExamples?.length > 0 && (
      <div>
        <h4 className={`text-sm font-medium mb-2 flex items-center ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
          <BookOpenIcon className="h-4 w-4 mr-1.5" />
          Training Examples
        </h4>
        <div className="space-y-2 max-h-48 overflow-y-auto custom-scrollbar pr-1">
          {agent.trainingExamples.map((example, index) => (
            <div 
              key={index}
              className={`p-3 rounded-md text-xs ${
                isDarkMode 
                  ? 'bg-slate-700/50 border border-slate-600/50' 
                  : 'bg-slate-100/50 border border-slate-200/50'
              }`}
            >
              <div className="mb-2">
                <span className={`font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>Input: </span>
                <span className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>{example.input}</span>
              </div>
              <div>
                <span className={`font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>Output: </span>
                <span className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>{example.output}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    {/* Footer Stats */}
    <div className={`mt-4 pt-3 border-t ${isDarkMode ? 'border-slate-700' : 'border-slate-200'} 
      flex items-center justify-between text-xs ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}
    >
      <div className="flex items-center space-x-3">
        {agent.trainingExamples?.length > 0 && (
          <span className="flex items-center">
            <DocumentTextIcon className="h-3.5 w-3.5 mr-1" />
            {agent.trainingExamples.length} examples
          </span>
        )}
        {agent.lastUsed && (
          <span className="flex items-center">
            <ClockIcon className="h-3.5 w-3.5 mr-1" />
            Last used: {new Date(agent.lastUsed).toLocaleDateString()}
          </span>
        )}
      </div>
    </div>
  </div>
));

const AIAgentTag = ({ agent, onRemove, width = 'w-full' }) => {
  const [showContent, setShowContent] = useState(false);
  const { isDarkMode } = useTheme();
  const contentRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contentRef.current && !contentRef.current.contains(event.target) && 
          !buttonRef.current.contains(event.target)) {
        setShowContent(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setShowContent(false);
      }
    };

    if (showContent) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [showContent]);

  const toggleContent = (e) => {
    e.stopPropagation();
    setShowContent(!showContent);
  };

  const handleRemove = (e) => {
    e.stopPropagation();
    onRemove();
  };

  if (!agent) {
    return null;
  }

  return (
    <div className={`${width} relative`}>
      <TagContent 
        agent={agent}
        isDarkMode={isDarkMode}
        onButtonClick={toggleContent}
        buttonRef={buttonRef}
        onRemove={handleRemove}
      />
      
      {showContent && (
        <PopupContent 
          agent={agent}
          isDarkMode={isDarkMode}
          contentRef={contentRef}
        />
      )}
    </div>
  );
};

AIAgentTag.propTypes = {
  agent: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string,
    description: PropTypes.string,
    systemInstructions: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
    trainingExamples: PropTypes.arrayOf(PropTypes.shape({
      input: PropTypes.string,
      output: PropTypes.string
    }))
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  width: PropTypes.string
};

TagContent.displayName = 'TagContent';
PopupContent.displayName = 'PopupContent';

export default memo(AIAgentTag);