// Create this new utility file for text conversion functions

const normalChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

const fontMappings = {
  // Serif styles
  serifBold: '𝐀𝐁𝐂𝐃𝐄𝐅𝐆𝐇𝐈𝐉𝐊𝐋𝐌𝐍𝐎𝐏𝐐𝐑𝐒𝐓𝐔𝐕𝐖𝐗𝐘𝐙𝐚𝐛𝐜𝐝𝐞𝐟𝐠𝐡𝐢𝐣𝐤𝐥𝐦𝐧𝐨𝐩𝐪𝐫𝐬𝐭𝐮𝐯𝐰𝐱𝐲𝐳𝟎𝟏𝟐𝟑𝟒𝟓𝟔𝟕𝟖𝟗',
  serifItalic: '𝑨𝑩𝑪𝑫𝑬𝑭𝑮𝑯𝑰𝑱𝑲𝑳𝑴𝑵𝑶𝑷𝑸𝑹𝑺𝑻𝑼𝑽𝑾𝑿𝒀𝒁𝒂𝒃𝒄𝒅𝒆𝒇𝒈𝒉𝒊𝒋𝒌𝒍𝒎𝒏𝒐𝒑𝒒𝒓𝒔𝒕𝒖𝒗𝒘𝒙𝒚𝒛0123456789',
  
  // Sans serif styles
  sansBold: '𝗔𝗕𝗖𝗗𝗘𝗙𝗚𝗛𝗜𝗝𝗞𝗟𝗠𝗡𝗢𝗣𝗤𝗥𝗦𝗧𝗨𝗩𝗪𝗫𝗬𝗭𝗮𝗯𝗰𝗱𝗲𝗳𝗴𝗵𝗶𝗷𝗸𝗹𝗺𝗻𝗼𝗽𝗾𝗿𝘀𝘁𝘂𝘃𝘄𝘅𝘆𝘇𝟬𝟭𝟮𝟯𝟰𝟱𝟲𝟳𝟴𝟵',
  sansItalic: '𝘈𝘉𝘊𝘋𝘌𝘍𝘎𝘏𝘐𝘑𝘒𝘓𝘔𝘕𝘖𝘗𝘘𝘙𝘚𝘛𝘜𝘝𝘞𝘟𝘠𝘡𝘢𝘣𝘤𝘥𝘦𝘧𝘨𝘩𝘪𝘫𝘬𝘭𝘮𝘯𝘰𝘱𝘲𝘳𝘴𝘵𝘶𝘷𝘸𝘹𝘺𝘻0123456789',
  sansBoldItalic: '𝘼𝘽𝘾𝘿𝙀𝙁𝙂𝙃𝙄𝙅𝙆𝙇𝙈𝙉𝙊𝙋𝙌𝙍𝙎𝙏𝙐𝙑𝙒𝙓𝙔𝙕𝙖𝙗𝙘𝙙𝙚𝙛𝙜𝙝𝙞𝙟𝙠𝙡𝙢𝙣𝙤𝙥𝙦𝙧𝙨𝙩𝙪𝙫𝙬𝙭𝙮𝙯𝟬𝟭𝟮𝟯𝟰𝟱𝟲𝟳𝟴𝟵',
  
  // Monospace
  monospace: '𝙰𝙱𝙲𝙳𝙴𝙵𝙶𝙷𝙸𝙹𝙺𝙻𝙼𝙽𝙾𝙿𝚀𝚁𝚂𝚃𝚄𝚅𝚆𝚇𝚈𝚉𝚊𝚋𝚌𝚍𝚎𝚏𝚐𝚑𝚒𝚓𝚔𝚕𝚖𝚗𝚘𝚙𝚚𝚛𝚜𝚝𝚞𝚟𝚠𝚡𝚢𝚣𝟶𝟷𝟸𝟹𝟺𝟻𝟼𝟽𝟾𝟿',
};

// Font style descriptions for the UI
export const fontStyles = [
  // Sans Serif Group (Primary Styles)
  { id: 'sansBold', name: '𝗦𝗮𝗻𝘀 𝗕𝗼𝗹𝗱', sample: '𝗔𝗮' },
  { id: 'sansBoldItalic', name: '𝙎𝙖𝙣𝙨 𝘽𝙤𝙡𝙙 𝙄𝙩𝙖𝙡𝙞𝙘', sample: '𝙄𝙖' },
  { id: 'sansItalic', name: '𝘚𝘢𝘯𝘴 𝘐𝘵𝘢𝘭𝘪𝘤', sample: '𝘈𝘢' },
  
  // Serif Group (Secondary Styles)
  { id: 'serifBold', name: '𝐒𝐞𝐫𝐢𝐟 𝐁𝐨𝐥𝐝', sample: '𝐀𝐚' },
  { id: 'serifItalic', name: '𝑺𝒆𝒓𝒊𝒇 𝑰𝒕𝒂𝒍𝒊𝒄', sample: '𝑨𝒂' },
  
  // Special Styles
  { id: 'monospace', name: '𝙼𝚘𝚗𝚘𝚜𝚙𝚊𝚌𝚎', sample: '𝙰𝚊' },
];

export const convertToUnicodeFont = (text, fontStyle) => {
  if (!fontMappings[fontStyle]) return text;

  // Create a mapping of normal characters to their Unicode equivalents
  const mapping = {};
  const normalArray = [...normalChars];
  const unicodeArray = [...fontMappings[fontStyle]];
  
  normalArray.forEach((char, i) => {
    if (unicodeArray[i]) {
      // Map each character to its corresponding unicode character
      // preserving case - first 26 chars are uppercase, next 26 are lowercase
      mapping[char] = unicodeArray[i];
    }
  });

  // Convert the text character by character
  return [...text].map(char => {
    // If we have a mapping for this character, use it
    if (mapping[char]) {
      return mapping[char];
    }
    // For characters we don't have mappings for (spaces, punctuation, etc.)
    // return them as-is
    return char;
  }).join('');
};

// Helper function to check if a character has a Unicode equivalent
export const hasUnicodeEquivalent = (char, fontStyle) => {
  return normalChars.includes(char.toLowerCase()) && fontMappings[fontStyle];
};

export const applyUnicodeFontToSelection = (text, selectionStart, selectionEnd, fontStyle) => {
  const selectedText = text.slice(selectionStart, selectionEnd);
  const convertedText = convertToUnicodeFont(selectedText, fontStyle);
  
  return {
    newText: text.slice(0, selectionStart) + convertedText + text.slice(selectionEnd),
    newCursorPosition: selectionStart + convertedText.length
  };
};

// Add helper functions to detect unicode styles
export const isUnicodeStyle = (text, styleId) => {
  if (!text || !fontMappings[styleId]) return false;
  const unicodeChars = [...fontMappings[styleId]];
  return [...text].some(char => unicodeChars.includes(char));
};

export const revertToNormalText = (text) => {
  if (!text) return text;
  
  // Create reverse mappings for all styles
  const reverseMapping = {};
  Object.values(fontMappings).forEach(styleChars => {
    [...styleChars].forEach((unicodeChar, index) => {
      reverseMapping[unicodeChar] = normalChars[index];
    });
  });

  // Convert text back to normal characters
  return [...text].map(char => reverseMapping[char] || char).join('');
};
