import React, { useState, useEffect } from 'react';
import { XMarkIcon, ArrowUpTrayIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';

const UploadProgress = ({ progress, isUploading, onCancel, fileName, fileSize, onClose, uploadComplete }) => {
  const { isDarkMode } = useTheme();
  const [showComponent, setShowComponent] = useState(false);
  const [showCompleteState, setShowCompleteState] = useState(false);

  useEffect(() => {
    if (isUploading || progress > 0 || uploadComplete) {
      setShowComponent(true);
    }
  }, [isUploading, progress, uploadComplete]);

  useEffect(() => {
    let timeoutId;
    if (uploadComplete) {
      setShowComponent(true);
      timeoutId = setTimeout(() => {
        setShowCompleteState(true);
      }, 500);

      timeoutId = setTimeout(() => {
        handleClose();
      }, 3000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [uploadComplete]);

  const handleClose = () => {
    setShowComponent(false);
    setShowCompleteState(false);
    if (onClose) {
      onClose();
    }
  };

  if (!showComponent) return null;

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 20, scale: 0.95 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 20, scale: 0.95 }}
        transition={{ duration: 0.2 }}
        className={`
          fixed bottom-4 right-4 p-4 rounded-lg shadow-lg
          w-[320px] max-w-[calc(100vw-2rem)]
          ${isDarkMode 
            ? 'bg-[#1a1a1a] border border-[#333333] text-slate-200' 
            : 'bg-white border border-slate-200 text-slate-900'
          }
          backdrop-blur-lg backdrop-saturate-150
        `}
        role="alert"
        aria-live="polite"
      >
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <motion.div 
              className="flex items-center space-x-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              {showCompleteState ? (
                <CheckCircleIcon className={`w-4 h-4 ${isDarkMode ? 'text-green-400' : 'text-green-500'}`} />
              ) : (
                <ArrowUpTrayIcon className="w-4 h-4" />
              )}
              <span className="text-sm font-medium">
                {showCompleteState ? 'Upload Complete' : 'Uploading...'}
              </span>
            </motion.div>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleClose}
              className={`
                rounded-full p-1 transition-colors
                ${isDarkMode 
                  ? 'hover:bg-[#333333]' 
                  : 'hover:bg-slate-100'
                }
              `}
              aria-label="Close notification"
            >
              <XMarkIcon className="w-4 h-4" />
            </motion.button>
          </div>

          <div className="space-y-1.5">
            <div className="flex items-center justify-between text-xs">
              <span className="truncate font-medium">{fileName}</span>
              <span className={`
                ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
              `}>
                {formatFileSize(fileSize)}
              </span>
            </div>

            <div className={`
              relative h-1 w-full overflow-hidden rounded-full
              ${isDarkMode ? 'bg-[#333333]' : 'bg-slate-100'}
            `}>
              <motion.div
                className={`
                  absolute left-0 top-0 h-full rounded-full
                  ${showCompleteState
                    ? isDarkMode ? 'bg-green-400' : 'bg-green-500'
                    : isDarkMode ? 'bg-blue-400' : 'bg-blue-500'
                  }
                `}
                initial={{ width: 0 }}
                animate={{ width: `${uploadComplete ? 100 : progress}%` }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              />
            </div>

            <div className="flex justify-end">
              <span className={`
                text-xs
                ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
              `}>
                {showCompleteState ? 'Completed' : `${Math.round(progress)}%`}
              </span>
            </div>
          </div>

          {showCompleteState && (
            <motion.div
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              className={`
                flex items-center justify-center text-xs
                ${isDarkMode ? 'text-green-400' : 'text-green-500'}
              `}
            >
              <CheckCircleIcon className="w-3 h-3 mr-1.5" />
              <span>File uploaded successfully</span>
            </motion.div>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default UploadProgress;
