import React, { useState, useEffect, useCallback } from 'react';
import { XMarkIcon, SparklesIcon, CheckIcon, ArrowLeftIcon, ChevronDownIcon, UserGroupIcon, CalendarIcon, DocumentTextIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '../../context/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '../../utils/cn';
import SkeletonLoader from '../common/SkeletonLoader';
import { fetchContentStrategies } from '../../api';

const ContentIdeasSelector = ({ onSelect, onClose }) => {
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 639 });
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });
  const [strategies, setStrategies] = useState([]);
  const [expandedStrategy, setExpandedStrategy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadStrategies = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchContentStrategies();
      setStrategies(response.data || []);
    } catch (error) {
      console.error('Error fetching content strategies:', error);
      setError('Failed to load content strategies. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadStrategies();
  }, [loadStrategies]);

  const truncateText = (text, maxLength = 95) => {
    if (!text) return 'No content available';
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const handleStrategyClick = (strategyId) => {
    setExpandedStrategy(expandedStrategy === strategyId ? null : strategyId);
  };

  const handleIdeaSelection = (idea) => {
    // Format the idea content for the chat input
    const formattedContent = `${idea.hook}\n\n${idea.content}\n\nAngle: ${idea.angle}\nValue: ${idea.value}\nAlignment: ${idea.expertiseAlignment}`;
    onSelect(formattedContent);
    onClose();
  };

  if (error) {
    return (
      <div className="absolute inset-0 z-50 flex">
        <div className="flex-1 h-full">
          <motion.div 
            className={cn(
              "flex flex-col h-full relative overflow-hidden border-none rounded-none",
              isDarkMode ? "bg-[#121212]" : "bg-gray-50"
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {/* Error state content */}
          </motion.div>
        </div>
      </div>
    );
  }

  return (
    <div className="absolute inset-0 z-50 flex">
      <div className="flex-1 h-full">
        <motion.div 
          className={cn(
            "flex flex-col h-full relative overflow-hidden border-none rounded-none",
            isDarkMode ? "bg-[#121212]" : "bg-gray-50"
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {/* Header */}
          <div className={cn(
            "h-12 min-h-12 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b",
            "sticky top-0 z-50 backdrop-blur-xl",
            isDarkMode 
              ? "bg-[#151515]/80 border-[#333333]" 
              : "bg-white/80 border-gray-200"
          )}>
            <div className="flex items-center space-x-2">
              <SparklesIcon className={cn(
                "h-4 w-4",
                isDarkMode ? "text-slate-300" : "text-gray-700"
              )} />
              <h2 className={cn(
                "text-sm font-medium tracking-tight",
                isDarkMode ? "text-slate-100" : "text-gray-900"
              )}>
                Content Ideas
              </h2>
            </div>
            <button 
              onClick={onClose}
              className={cn(
                "p-2 rounded-lg transition-all duration-200",
                isDarkMode 
                  ? "text-slate-400 hover:text-slate-200 hover:bg-white/5 active:bg-white/10" 
                  : "text-gray-500 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200"
              )}
              title="Back to Chat"
            >
              <ArrowLeftIcon className="w-4 h-4" />
            </button>
          </div>

          {/* Content Area */}
          <div className={cn(
            "flex-grow overflow-y-auto",
            isDarkMode ? "bg-[#121212]" : "bg-gray-50"
          )}>
            {loading ? (
              <div className="px-4 py-3">
                <SkeletonLoader 
                  isDarkMode={isDarkMode} 
                  count={5} 
                  variant="default"
                  iconClassName="w-8 h-8"
                />
              </div>
            ) : strategies.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full p-4">
                <div className={cn(
                  "w-12 h-12 rounded-xl flex items-center justify-center mb-3",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}>
                  <SparklesIcon className={cn(
                    "w-6 h-6",
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  )} />
                </div>
                <p className={cn(
                  "text-center text-sm mb-4",
                  isDarkMode ? "text-slate-400" : "text-gray-500"
                )}>
                  No content strategies available
                </p>
              </div>
            ) : (
              <div className="px-2 py-2 space-y-2">
                {strategies.map((strategy) => (
                  <div key={strategy._id} className="space-y-1">
                    <button
                      onClick={() => handleStrategyClick(strategy._id)}
                      className={cn(
                        "w-full text-left p-4 rounded-xl border transition-all duration-200",
                        "hover:-translate-y-1 hover:shadow-lg",
                        isDarkMode 
                          ? "bg-[#121212] border-[#333333] hover:border-[#1e1e1e]" 
                          : "bg-white border-slate-200 hover:border-slate-300",
                        expandedStrategy === strategy._id && "mb-2"
                      )}
                    >
                      <div className="space-y-3">
                        {/* Header Section */}
                        <div className="space-y-1.5">
                          <h3 className={cn(
                            "font-medium line-clamp-1 pr-8",
                            isDarkMode ? "text-slate-200" : "text-slate-900"
                          )}>
                            {strategy.persona?.name || 'Unnamed Creator'}
                          </h3>
                          <div className="flex items-center gap-2 text-xs">
                            <span className={cn(
                              "flex items-center gap-1",
                              isDarkMode ? "text-slate-400" : "text-slate-500"
                            )}>
                              <UserGroupIcon className="w-3.5 h-3.5" />
                              <span className="line-clamp-1">
                                {strategy.audience?.targetAudienceName || 'General Audience'}
                              </span>
                            </span>
                            <span className={cn(
                              "flex items-center gap-1",
                              isDarkMode ? "text-slate-400" : "text-slate-400"
                            )}>
                              <CalendarIcon className="w-3.5 h-3.5" />
                              {new Date(strategy.createdAt).toLocaleDateString(undefined, { 
                                month: 'short', 
                                day: 'numeric'
                              })}
                            </span>
                          </div>
                        </div>

                        {/* Stats Section */}
                        <div className="flex items-center gap-4">
                          <div className={cn(
                            "flex items-center gap-1.5",
                            isDarkMode ? "text-slate-400" : "text-slate-500"
                          )}>
                            <DocumentTextIcon className="w-3.5 h-3.5" />
                            <span className="text-xs">
                              {strategy.topics?.length || 0} topics
                            </span>
                          </div>
                          <div className={cn(
                            "flex items-center gap-1.5",
                            isDarkMode ? "text-slate-400" : "text-slate-500"
                          )}>
                            <DocumentDuplicateIcon className="w-3.5 h-3.5" />
                            <span className="text-xs">
                              {strategy.generatedIdeas?.reduce((total, topicIdeas) => {
                                return total + (topicIdeas.ideas?.length || 0);
                              }, 0) || 0} ideas
                            </span>
                          </div>
                        </div>

                        {/* Expand/Collapse Indicator */}
                        <div className={cn(
                          "absolute right-4 top-1/2 -translate-y-1/2",
                          isDarkMode ? "text-[#333333]" : "text-slate-300"
                        )}>
                          <ChevronDownIcon className={cn(
                            "w-5 h-5 transition-transform",
                            expandedStrategy === strategy._id ? "transform rotate-180" : ""
                          )} />
                        </div>
                      </div>
                    </button>

                    <AnimatePresence>
                      {expandedStrategy === strategy._id && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          className="overflow-hidden pl-2"
                        >
                          {strategy.generatedIdeas?.map((topicIdeas) => (
                            <div key={topicIdeas.topic} className="mb-3">
                              <h4 className={cn(
                                "text-xs font-medium mb-2 px-2",
                                isDarkMode ? "text-slate-400" : "text-slate-500"
                              )}>
                                {topicIdeas.topic}
                              </h4>
                              <div className="space-y-1">
                                {topicIdeas.ideas?.map((idea) => (
                                  <button
                                    key={idea._id}
                                    onClick={() => handleIdeaSelection(idea)}
                                    className={cn(
                                      "w-full text-left p-3 rounded-lg border transition-all duration-200",
                                      isDarkMode 
                                        ? "hover:bg-[#1E1E1E] border-[#262626]" 
                                        : "hover:bg-slate-50 border-slate-100",
                                      "hover:shadow-md"
                                    )}
                                  >
                                    <h3 className={cn(
                                      "text-sm font-medium mb-1",
                                      isDarkMode ? "text-slate-200" : "text-slate-700"
                                    )}>
                                      {idea.hook?.replace(/['"]/g, '')}
                                    </h3>
                                    <p className={cn(
                                      "text-xs line-clamp-2 mb-2",
                                      isDarkMode ? "text-slate-400" : "text-slate-600"
                                    )}>
                                      {truncateText(idea.content)}
                                    </p>
                                    <div className="flex flex-wrap gap-2">
                                      <span className={cn(
                                        "inline-flex items-center px-2 py-0.5 text-[10px] rounded-full",
                                        isDarkMode 
                                          ? "bg-slate-800/50 text-slate-400" 
                                          : "bg-slate-100/50 text-slate-500"
                                      )}>
                                        <span className="font-medium mr-1">Angle:</span> {idea.angle}
                                      </span>
                                    </div>
                                  </button>
                                ))}
                              </div>
                            </div>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ContentIdeasSelector; 