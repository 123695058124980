import axios from 'axios';
import OpenAI from 'openai';
import { refreshAccessToken } from './context/AuthContext';
import { fetchKnowledgeBases, createFolder, createKnowledgeBase, deleteItem, updateItem, fetchFolderById } from './services/mongoService';
import { TokenStorage } from './utils/tokenStorage';
import { isLinkedInConnected } from './services/linkedinPublishService';
import { refreshSession } from './services/supabaseAuth';

// Add these at the top of the file, after the imports
const canvasCache = new Map();
const CACHE_DURATION = 30000; // 30 seconds cache duration

// Update API URLs to use HTTP only
const API_URLS = process.env.REACT_APP_API_URLS ? 
  process.env.REACT_APP_API_URLS.split(',').map(url => url.trim()) : 
  [
    'http://localhost:3001',  // Local development
    'https://api.ammmplify.com',  // Primary API domain
    'https://ghost-app-backend.vercel.app'  // Fallback domain
  ];

// Update getBestApiUrl function to use development URL when on localhost
const getBestApiUrl = () => {
  const isLocalhost = window.location.hostname === 'localhost';
  const isDevelopment = process.env.NODE_ENV === 'development';
  
  // For local development, always use localhost:3001
  if (isLocalhost || isDevelopment) {
    return 'http://localhost:3001';
  }
  
  // For production, use the first available URL
  return API_URLS[0];
};

// Track the current API URL index for fallback logic
let currentApiUrlIndex = 0;
export const API_URL = getBestApiUrl();

// Log the configuration
console.log('API Configuration:', {
  hostname: window.location.hostname,
  environment: process.env.NODE_ENV,
  selectedUrl: API_URL,
  currentIndex: currentApiUrlIndex
});

console.log('Current hostname:', window.location.hostname);
console.log('Available API URLs:', API_URLS);
console.log('Selected API_URL:', API_URL);

// Add this function to determine the best API URL
const getAuthHeaders = () => {
  const token = localStorage.getItem('accessToken');
  return {
    'Authorization': token ? `Bearer ${token}` : '',
    'Content-Type': 'application/json'
  };
};

// Create axios instance with retry logic
const api = axios.create({
  baseURL: getBestApiUrl(),
  timeout: 120000, // Increased to 120 seconds
  withCredentials: false, // Disable by default, enable only for specific routes
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add request interceptor with fallback logic
api.interceptors.request.use(
  (config) => {
    console.log('🔵 Request Interceptor:', {
      url: config.url,
      method: config.method,
      baseURL: config.baseURL,
      hasToken: !!localStorage.getItem('accessToken'),
      hasSupabaseSession: !!localStorage.getItem('supabase_session'),
      headers: config.headers
    });

    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      console.log('🔑 Added auth token to request:', token.substring(0, 10) + '...');
    } else {
      console.log('⚠️ No auth token found in localStorage');
      // Check if we have a Supabase session
      const supabaseSession = localStorage.getItem('supabase_session');
      if (supabaseSession) {
        try {
          const session = JSON.parse(supabaseSession);
          if (session?.access_token) {
            config.headers['Authorization'] = `Bearer ${session.access_token}`;
            console.log('🔑 Using Supabase token as fallback');
          }
        } catch (e) {
          console.error('Failed to parse Supabase session:', e);
        }
      }
    }
    return config;
  },
  (error) => {
    console.error('❌ Request Interceptor Error:', {
      message: error.message,
      code: error.code,
      config: error.config
    });
    return Promise.reject(error);
  }
);

// Add response interceptor with fallback logic
api.interceptors.response.use(
  (response) => {
    console.log('✅ Response Success:', {
      url: response.config.url,
      status: response.status,
      statusText: response.statusText
    });
    return response;
  },
  async (error) => {
    console.log('🔴 Response Error Initial:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      statusText: error.response?.statusText,
      message: error.message,
      hasSupabaseSession: !!localStorage.getItem('supabase_session'),
      hasAccessToken: !!localStorage.getItem('accessToken'),
      hasRefreshToken: !!localStorage.getItem('refreshToken')
    });

    const originalRequest = error.config;
    
    // If error is 401 and we haven't tried to refresh token yet
    if (error.response?.status === 401 && !originalRequest._retry401) {
      console.log('🔄 Starting token refresh flow:', {
        isFirstRetry: !originalRequest._retry401,
        originalUrl: originalRequest.url
      });

      originalRequest._retry401 = true;

      try {
        // First try to refresh Supabase session
        console.log('🔄 Attempting Supabase session refresh...');
        const supabaseSession = await refreshSession();
        
        if (supabaseSession) {
          console.log('✅ Supabase session refresh successful:', {
            hasAccessToken: !!supabaseSession.access_token,
            expiresAt: supabaseSession.expires_at
          });
          return api(originalRequest);
        } else {
          console.log('⚠️ Supabase session refresh failed, trying JWT refresh...');
        }

        // If no Supabase session or refresh failed, try JWT refresh
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          try {
            console.log('🔄 Attempting JWT token refresh...');
            const { accessToken, refreshToken: newRefreshToken } = await refreshAccessToken(refreshToken);
            
            if (accessToken) {
              console.log('✅ JWT refresh successful:', {
                hasNewAccessToken: !!accessToken,
                hasNewRefreshToken: !!newRefreshToken
              });

              localStorage.setItem('accessToken', accessToken);
              if (newRefreshToken) {
                localStorage.setItem('refreshToken', newRefreshToken);
              }
              api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
              originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
              return api(originalRequest);
            } else {
              console.error('❌ JWT refresh failed: No access token returned');
            }
          } catch (refreshError) {
            console.error('❌ JWT refresh error:', {
              message: refreshError.message,
              response: refreshError.response?.data,
              status: refreshError.response?.status
            });
            
            // Clear tokens and redirect to login
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('supabase_session');
            window.location.href = '/login';
            return Promise.reject(refreshError);
          }
        } else {
          console.error('❌ No refresh token available for JWT refresh');
        }
      } catch (error) {
        console.error('❌ Token refresh flow failed:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
          type: error.type || 'unknown'
        });
        
        // Clear all tokens on refresh failure
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('supabase_session');
        window.location.href = '/login';
        return Promise.reject(error);
      }
    }
    
    // If error is a network error or timeout, try the next URL
    if ((error.code === 'ECONNABORTED' || !error.response || error.message === 'Network Error') 
        && !originalRequest._retry 
        && currentApiUrlIndex < API_URLS.length - 1) {
      
      console.log('🔄 Network error, trying fallback URL:', {
        currentUrl: API_URLS[currentApiUrlIndex],
        nextUrl: API_URLS[currentApiUrlIndex + 1],
        error: error.message,
        code: error.code
      });
      
      originalRequest._retry = true;
      currentApiUrlIndex++;
      console.log(`🔄 Switching to next API URL: ${API_URLS[currentApiUrlIndex]}`);
      
      // Update the baseURL for the current request and axios instance
      originalRequest.baseURL = API_URLS[currentApiUrlIndex];
      api.defaults.baseURL = API_URLS[currentApiUrlIndex];
      
      // Create a new request with the updated configuration
      return api(originalRequest);
    }
    
    console.error('❌ Final error response:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      statusText: error.response?.statusText,
      message: error.message,
      response: error.response?.data
    });
    
    return Promise.reject(error);
  }
);

// Add this function at the beginning of the file
const getAccessToken = () => localStorage.getItem('accessToken');

console.log('Axios instance created with baseURL:', API_URL);

// Replace the existing generateContent function with this updated version
export const generateContent = async (id, prompt, topic, numOutputs, toneOfVoice, additionalContext) => {
  try {
    if (id && prompt) {
      // This is for generating content based on a voice note
      console.log('Generating content for voice note:', id);
      const response = await api.post(`/api/voice-notes/${id}/generate`, { prompt }, {
        headers: { 'Authorization': `Bearer ${getAccessToken()}` }
      });
      return response.data.generatedContent;
    } else if (topic) {
      // This is for generating content based on a topic
      console.log('Generating content with:', { topic, numOutputs, toneOfVoice, additionalContext });
      const response = await api.post('/generate-post', {
        topic,
        numOutputs,
        toneOfVoice,
        additionalContext
      });
      console.log('Content generation response:', response.data);
      if (!response.data.posts || response.data.posts.length === 0) {
        throw new Error('No posts were generated');
      }
      return response.data.posts;
    } else {
      throw new Error('Invalid parameters for content generation');
    }
  } catch (error) {
    console.error('Error generating content:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      const errorMessage = error.response.data.message || error.response.data.error || 'An error occurred while generating content';
      throw new Error(`Failed to generate content: ${errorMessage}`);
    } else if (error.request) {
      console.error('Error request:', error.request);
      throw new Error('No response received from server');
    } else {
      console.error('Error message:', error.message);
      throw error;
    }
  }
};

export const generateTitle = async (transcription) => {
  try {
    const response = await fetch('/api/generate-title', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ transcription }),
    });

    if (!response.ok) {
      throw new Error('Failed to generate title');
    }

    const data = await response.json();
    return data.title;
  } catch (error) {
    console.error('Error generating title:', error);
    return 'Untitled Transcription';
  }
};

export const regenerateTitle = async (postId) => {
  try {
    const response = await api.put(`/api/posts/${postId}/regenerate-title`);
    console.log('Title regeneration response:', response.data);
    
    if (!response.data.title) {
      throw new Error('Server did not return an updated title');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error regenerating title:', error);
    throw error;
  }
};

// Update the sendChatMessage function to include contentStyle while preserving existing functionality
export const sendChatMessage = async (
  model,
  message,
  chatId,
  persona,
  template,
  agent,
  targetAudience,
  contentStyle
) => {
  try {
    const response = await api.post('/api/chat', {
      model,
      message,
      chatId,
      persona,
      template,
      agent,
      targetAudience,
      contentStyle
    }, {
      timeout: 120000 // Preserve the 120-second timeout
    });

    console.log('Raw API response:', response.data);
    if (response.data && response.data.message) {
      return {
        content: typeof response.data.message === 'string' ? response.data.message : response.data.message.content,
        role: 'assistant',
        timestamp: new Date().toISOString(),
        saved: false,
        id: response.data.messageId,
        chatId: response.data.chatId,
        chatTitle: response.data.chatTitle || 'Untitled Chat',
        templateTag: template ? template.title : null,
        agent: agent ? { id: agent.id, name: agent.name } : null
      };
    } else {
      console.error('Unexpected response structure:', response.data);
      throw new Error('Invalid response format from server');
    }
  } catch (error) {
    handleApiError(error);
  }
};

export const createNewChat = async (title, model = 'Claude 3.5 Sonnet') => {
  try {
    console.log('Attempting to create new chat:', { title, model });
    const response = await api.post('/api/chat/new', { title, model });
    console.log('New chat created:', response.data);
    return {
      chatId: response.data.chatId,
      title: response.data.title
    };
  } catch (error) {
    console.error('Error creating new chat:', error);
    throw error;
  }
};

export const updateChatTitle = async (chatId, newTitle) => {
  try {
    console.log(`Sending request to update chat title. ChatId: ${chatId}, New Title: ${newTitle}`);
    const response = await api.put(`/api/chats/${chatId}/title`, { title: newTitle });
    console.log('Update chat title response:', response.data);
    if (!response.data || !response.data.title) {
      throw new Error('Invalid response from server');
    }
    return response.data;
  } catch (error) {
    console.error('Error updating chat title:', error);
    throw error;
  }
};

export const fetchUserStats = async (userId) => {
  try {
    const response = await api.get(`/api/users/${userId}/stats`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user stats:', error);
    throw error;
  }
};

export const transcribeAudio = async (audioData) => {
  let audioBlob;
  
  if (audioData instanceof Blob) {
    audioBlob = audioData;
  } else if (audioData instanceof ArrayBuffer) {
    audioBlob = new Blob([audioData], { type: 'audio/webm' });
  } else if (typeof audioData === 'string' && audioData.startsWith('data:')) {
    const response = await fetch(audioData);
    audioBlob = await response.blob();
  } else {
    throw new Error('Invalid audio data format');
  }

  const formData = new FormData();
  formData.append('audio', audioBlob, 'audio.webm');

  try {
    const response = await fetch('/api/transcribe', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error in transcribeAudio:', error);
    throw error;
  }
};

export const loadChat = async (chatId) => {
  try {
    console.log('Sending request to load chat:', chatId);
    const response = await api.get(`/api/chats/${chatId}`);
    console.log('Received response for loadChat:', response.data);
    if (!response.data || !response.data.title) {
      console.error('Invalid chat data received:', response.data);
      throw new Error('Invalid chat data received from server');
    }
    const normalizedMessages = response.data.messages.map(message => ({
      content: message.content,
      role: message.role,
      timestamp: message.timestamp || new Date().toISOString(),
      saved: message.saved || false,
      id: message._id,
      templateTag: message.templateTag || null,
      templateContent: message.templateContent || null,
      contextInputTags: message.contextInputTags || []
    }));
    return { ...response.data, messages: normalizedMessages };
  } catch (error) {
    console.error('Error in loadChat:', error);
    
    // Enhanced error handling
    let errorMessage = 'An error occurred while loading the chat';
    
    if (error.response) {
      const { status, data } = error.response;
      console.error('Server error response:', { status, data });
      
      if (status === 404) {
        errorMessage = 'Chat not found';
      } else if (data?.error) {
        errorMessage = data.error;
        
        // Handle specific DeepSeek errors
        if (data.type === 'authentication_error') {
          errorMessage = 'DeepSeek API authentication failed. Please check your API key.';
        } else if (data.type === 'rate_limit_error') {
          errorMessage = 'DeepSeek API rate limit exceeded. Please try again later.';
        } else if (data.type === 'timeout_error') {
          errorMessage = 'Request to DeepSeek API timed out. Please try again.';
        } else if (data.details) {
          errorMessage = data.details;
        }
      }
    } else if (error.request) {
      console.error('No response received from server');
      errorMessage = 'No response received from server. Please check your internet connection.';
    } else {
      console.error('Error message:', error.message);
      errorMessage = error.message;
    }
    
    throw new Error(errorMessage);
  }
};

export const saveMessage = async (messageData) => {
  try {
    console.log('Attempting to save message:', messageData);
    const response = await api.post('/api/saved-items', {
      content: messageData.content,
      originalMessageId: messageData.id,
      chatId: messageData.chatId,
      role: messageData.role,
      messageIndex: messageData.messageIndex,
      templateTag: messageData.templateTag || null,
      templateContent: messageData.templateContent || null
    });
    console.log('Save message response:', response.data);
    if (!response.data || !response.data._id) {
      throw new Error('Saved message does not have an id');
    }
    if (messageData.id) {
      await api.put(`/api/chat/${messageData.chatId}/messages`, { 
        messageId: messageData.id,
        saved: true,
        templateTag: messageData.templateTag || null,
        templateContent: messageData.templateContent || null
      });
    } else if (messageData.messageIndex !== undefined) {
      await api.put(`/api/chat/${messageData.chatId}/messages`, { 
        messageIndex: messageData.messageIndex,
        saved: true,
        templateTag: messageData.templateTag || null,
        templateContent: messageData.templateContent || null
      });
    } else {
      throw new Error('Cannot update message: no id or messageIndex provided');
    }
    return response.data;
  } catch (error) {
    console.error('Error saving message:', error);
    throw new Error(error.response?.data?.message || 'Failed to save message');
  }
};

export const unsaveMessage = async (messageId) => {
  try {
    const response = await api.delete(`/api/saved-items/${messageId}`);
    return response.data;
  } catch (error) {
    console.error('Error unsaving message:', error);
    throw error;
  }
};

export const saveChatTitle = async (chatId, newTitle) => {
  try {
    const response = await api.put(`/api/chats/${chatId}/title`, { title: newTitle });
    return response.data;
  } catch (error) {
    console.error('Error saving chat title:', error);
    throw error;
  }
};

export const updateChatMessage = async (chatId, messageId, content) => {
  try {
    const response = await api.put(`/api/chat/${chatId}/messages/${messageId}`, { content });
    return response.data;
  } catch (error) {
    console.error('Error updating chat message:', error);
    throw error;
  }
};

export const getSignedUrl = async (id) => {
  if (!id) {
    console.error('Attempted to get signed URL with undefined id');
    return null;
  }
  try {
    console.log(`Fetching signed URL for voice note ID: ${id}`);
    const response = await api.get(`/api/voice-notes/${id}/signed-url`);
    console.log(`Received signed URL response:`, response.data);
    return response.data.signedUrl;
  } catch (error) {
    console.error(`Error getting signed URL for voice note ID ${id}:`, error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const getSavedMessages = async () => {
  try {
    const response = await api.get('/api/saved-items');
    return response.data;
  } catch (error) {
    console.error('Error fetching saved messages:', error);
    throw error;
  }
};

export const fetchTemplates = async () => {
  try {
    console.log('Fetching all templates...');
    const response = await api.get('/api/templates');
    console.log('Templates response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching templates:', error);
    throw error;
  }
};

export const fetchUserTemplates = async () => {
  try {
    console.log('Fetching user templates...');
    const response = await api.get('/api/templates/user');
    console.log('User templates response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user templates:', error);
    throw error;
  }
};

export const createUserTemplate = async (templateData) => {
  try {
    console.log('Creating template:', templateData);
    const response = await api.post('/api/templates', templateData);
    console.log('Create template response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating template:', error);
    throw error;
  }
};

export const updateUserTemplate = async (templateId, templateData) => {
  try {
    console.log('Updating template:', templateId, templateData);
    const response = await api.put(`/api/templates/${templateId}`, templateData);
    console.log('Update template response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating template:', error);
    throw error;
  }
};

export const deleteUserTemplate = async (templateId) => {
  try {
    console.log('Deleting template:', templateId);
    const response = await api.delete(`/api/templates/${templateId}`);
    console.log('Delete template response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting template:', error);
    throw error;
  }
};

export const searchTemplates = async (query) => {
  try {
    console.log('Searching templates:', query);
    const response = await api.get(`/api/templates/search?q=${encodeURIComponent(query)}`);
    console.log('Search templates response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error searching templates:', error);
    throw error;
  }
};

export const filterTemplates = async (filters) => {
  try {
    console.log('Filtering templates:', filters);
    const queryParams = new URLSearchParams(filters).toString();
    const response = await api.get(`/api/templates/filter?${queryParams}`);
    console.log('Filter templates response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error filtering templates:', error);
    throw error;
  }
};

export const getTemplateById = async (templateId) => {
  try {
    console.log('Fetching template:', templateId);
    const response = await api.get(`/api/templates/${templateId}`);
    console.log('Get template response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching template:', error);
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    console.log('Attempting login with email:', email);
    console.log('Login URL:', `${API_URL}/api/auth/login`);
    const response = await api.post('/api/auth/login', { email, password });
    console.log('Login response:', response.data);
    
    // Store the token in localStorage
    if (response.data.token) {
      localStorage.setItem('accessToken', response.data.token);
      api.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;
    }
    
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};
export const fetchChatHistory = async (page = 1, limit = 10) => {
  try {
    console.log('Fetching chat history:', { page, limit });
    const response = await api.get(`/api/chats?page=${page}&limit=${limit}`);
    console.log('Received chat history raw response:', response);
    console.log('Received chat history data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching chat history:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

export const deleteChat = async (chatId) => {
  try {
    const response = await api.delete(`/api/chats/${chatId}`);
      if (!response.data.success) {
      throw new Error(response.data.error || 'Failed to delete chat');
    }
    return response.data;
  } catch (error) {
    console.error('Error deleting chat:', error);
    throw error;
  }
};

export const uploadFolder = async (formData, onUploadProgress) => {
  try {
    const response = await api.post('/api/upload/folder', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (onUploadProgress) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onUploadProgress(percentCompleted);
        }
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading folder:', error);
    throw error;
  }
};

export const uploadFile = async (formData, onUploadProgress) => {
  try {
    const file = formData.get('file');
    console.log('Uploading file:', file.name, 'Size:', file.size, 'bytes');
    console.log('FormData contents:', [...formData.entries()]);
    const response = await api.post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    });
    console.log('Upload response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const downloadFile = async (fileId) => {
  try {
    console.log('Attempting to download file with ID:', fileId);
    const response = await api.get(`/api/download/${fileId}`, {
      responseType: 'blob',
    });
    console.log('Download response:', response);
    return response.data;
  } catch (error) {
    console.error('Error downloading file:', error);
    if (error.response) {
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
      if (error.response.data instanceof Blob) {
        const text = await error.response.data.text();
        console.error('Error response:', text);
      } else {
        console.error('Error response:', error.response.data);
      }
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw new Error(`Failed to download file: ${error.message}`);
  }
};

export const createAIAgent = async (agentData) => {
  try {
    const response = await api.post('/api/ai-agents', agentData);
    return response.data;
  } catch (error) {
    console.error('Error creating AI Agent:', error);
    throw error;
  }
};

export const fetchAIAgents = async () => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('No access token available');
    }

    const response = await api.get('/api/ai-agents', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.message === 'No access token available') {
      throw error;
    }
    if (error.response) {
      if (error.response.status === 401) {
        throw new Error('Session expired. Please log in again.');
      }
      if (error.response.status === 403) {
        throw new Error('You do not have permission to access AI agents.');
      }
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      throw new Error(error.response.data.error || 'Failed to fetch AI agents');
    }
    throw new Error('Network error while fetching AI agents');
  }
};

export const updateAIAgent = async (agentId, agentData) => {
  try {
    const response = await api.put(`/api/ai-agents/${agentId}`, agentData);
    return response.data;
  } catch (error) {
    console.error('Error updating AI Agent:', error);
    throw error;
  }
};

export const uploadAIAgentProfilePicture = async (agentId, formData) => {
  try {
    const response = await api.post(`/api/ai-agents/${agentId}/upload-profile-picture`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading AI Agent profile picture:', error);
    throw error;
  }
};

export const deleteAIAgent = async (agentId) => {
  try {
    const response = await api.delete(`/api/ai-agents/${agentId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting AI Agent:', error);
    throw error;
  }
};

export const fetchClients = async () => {
  try {
    const response = await api.get('/api/clients');
    return response.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};

export const fetchProjects = async () => {
  try {
    const response = await api.get('/api/projects');
    return response.data;
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const fetchCampaigns = async () => {
  try {
    const response = await api.get('/api/campaigns');
    return response.data;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    throw error;
  }
};

export const updateTranscriptionTitle = async (transcriptionId, newTitle) => {
  try {
    console.log('Updating transcription title. ID:', transcriptionId, 'New Title:', newTitle);
    const response = await api.put(`/api/voice-notes/${transcriptionId}`, { title: newTitle });
    console.log('Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating transcription title:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

// Find the existing fetchVoiceNotes function and replace it with this updated version
export const fetchVoiceNotes = async (tags = [], page = 1, limit = 15) => {
  try {
    console.log('Fetching voice notes with tags:', tags, 'page:', page, 'limit:', limit);
    let endpoint = '/api/voice-notes';
    const params = new URLSearchParams();
    
    if (tags.length > 0) {
      params.append('tags', tags.join(','));
    }
    params.append('page', page);
    params.append('limit', limit);

    endpoint += `?${params.toString()}`;

    const token = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    console.log('Current access token:', token);
    console.log('Current refresh token:', refreshToken ? 'Present' : 'Not present');
    
    const response = await api.get(endpoint);
    console.log('Fetched voice notes response:', response.data);
    
    return {
      voiceNotes: response.data.voiceNotes,
      totalPages: response.data.totalPages,
      currentPage: response.data.currentPage
    };
  } catch (error) {
    console.error('Error fetching voice notes:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    }
    throw error;
  }
};

export const regenerateTranscriptionTitle = async (transcriptionId) => {
  try {
    console.log('Regenerating title for transcription ID:', transcriptionId);
    const response = await api.post(`/api/voice-notes/${transcriptionId}/regenerate-title`);
    console.log('Regenerate title response:', response.data);
    return response.data.title;
  } catch (error) {
    console.error('Error regenerating transcription title:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

export const deleteVoiceNote = async (id) => {
  try {
    console.log(`Attempting to delete voice note with ID: ${id}`);
    const response = await api.delete(`/api/voice-notes/${id}`);
    console.log('Delete response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting voice note:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      if (error.response.status === 404) {
        console.log('Voice note not found, considering it deleted');
        return { message: 'Voice note not found or already deleted' };
      }
    }
    throw error;
  }
};

export const updateVoiceNote = async (id, updates) => {
  try {
    if (!id) {
      throw new Error('Voice note ID is required');
    }
    console.log('Updating voice note:', { id, updates });
    const response = await api.put(`/api/voice-notes/${id}`, updates);
    if (!response.data) {
      throw new Error('No data received from server');
    }
    return response.data;
  } catch (error) {
    console.error('Error updating voice note:', error);
    throw error;
  }
};

export const fetchTags = async () => {
  try {
    const response = await api.get('/api/voice-notes/tags', {
      headers: { 'Authorization': `Bearer ${getAccessToken()}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching tags:', error);
    throw error;
  }
};

export const addTag = async (voiceNoteId, tag) => {
  try {
    console.log('API call: Adding tag', tag, 'to voice note', voiceNoteId);
    if (!voiceNoteId) {
      throw new Error('Voice note ID is required');
    }
    const response = await api.post(`/api/voice-notes/${voiceNoteId}/tags`, { tag }, {
      headers: { 'Authorization': `Bearer ${getAccessToken()}` }
    });
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('API error adding tag:', error);
    console.error('Error details:', error.response ? error.response.data : 'No response data');
    throw error;
  }
};

export const removeTag = async (voiceNoteId, tag) => {
  try {
    console.log('API call: Removing tag', tag, 'from voice note', voiceNoteId);
    const response = await api.delete(`/api/voice-notes/${voiceNoteId}/tags/${encodeURIComponent(tag)}`);
    console.log('API response:', response.data);
    return response.data; // This should be the updated voice note
  } catch (error) {
    console.error('API error removing tag:', error);
    console.error('Error details:', error.response ? error.response.data : 'No response data');
    throw error;
  }
};

export const editTag = async (oldTag, newTag) => {
  try {
    console.log('API call: Editing tag', oldTag, 'to', newTag);
    if (!oldTag || !newTag) {
      console.error('Invalid oldTag or newTag:', { oldTag, newTag });
      throw new Error('Both oldTag and newTag are required');
    }
    console.log('Sending request with body:', { oldTag, newTag });
    const response = await api.put('/api/voice-notes/tags', { oldTag, newTag });
    console.log('Full API request:', response.config);
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('API error editing tag:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    }
    throw error;
  }
};

export const deleteTag = async (tag) => {
  try {
    console.log('API call: Deleting tag', tag);
    const response = await api.delete(`/api/voice-notes/tags/${encodeURIComponent(tag)}`);
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('API error deleting tag:', error);
    throw error;
  }
};

export const getTagCounts = async () => {
  try {
    console.log('Fetching tag counts...');
    const response = await api.get('/api/voice-notes/tag-counts');
    console.log('Tag counts response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching tag counts:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

// Add this new function
export const saveGeneratedContent = async (voiceNoteId, prompt, content) => {
  try {
    const response = await api.post(`/api/voice-notes/${voiceNoteId}/generatedContent`, { prompt, content });
    return response.data;
  } catch (error) {
    console.error('Error saving generated content:', error);
    throw error;
  }
};

export const addNote = async (noteData) => {
  try {
    console.log('Sending note data:', noteData); // Add this line for debugging
    const response = await api.post('/api/notes', noteData);
    console.log('Server response:', response.data); // Add this line for debugging
    return response.data;
  } catch (error) {
    console.error('Error adding note:', error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error data:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    }
    throw error;
  }
};

export const fetchNotes = async () => {
  try {
    console.log('Fetching notes from API');
    const token = localStorage.getItem('accessToken');
    console.log('Access token:', token);
    const response = await api.get('/api/notes', {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    console.log('API response:', response);
    console.log('Fetched notes data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching notes from API:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

export const updateNote = async (id, updatedNote) => {
  try {
    console.log(`Sending PUT request to: /api/notes/${id}`);
    const response = await api.put(`/api/notes/${id}`, updatedNote);
    console.log('Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating note:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteNote = async (ids) => {
  try {
    const idsArray = Array.isArray(ids) ? ids : [ids];
    const response = await api.delete('/api/notes', { data: { ids: idsArray } });
    console.log('Delete response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error deleting note(s):', error);
    if (error.response && (error.response.status === 404 || error.response.status === 400)) {
      console.log('Note(s) not found or invalid, considering them deleted');
      return { message: error.response.data.message, deletedIds: ids };
    }
    throw error;
  }
};

// Update the fetchTextNotes function
export const fetchTextNotes = async () => {
  try {
    console.log('Fetching text notes...');
    const response = await api.get('/api/notes');
    console.log('Fetched text notes:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching text notes:', error);
    throw error;
  }
};

// Replace the individual count functions with this:
export const fetchAllCounts = async () => {
  try {
    console.log('Sending request to fetch counts...');
    const response = await api.get('/api/counts');
    console.log('Raw API response:', response);
    console.log('Fetched counts:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching counts:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

export const downloadAllChats = async (format) => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('No access token found');
    }
    const response = await api.get(`/api/chat/download-all/${format}`, {
      responseType: format === 'json' ? 'json' : 'blob',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error downloading chats:', error);
    throw error;
  }
};

export const updateChatTemplate = async (chatId, template) => {
  try {
    const response = await api.put(`/api/chat/${chatId}/template`, { template });
    return response.data;
  } catch (error) {
    console.error('Error updating chat template:', error);
    throw error;
  }
};

export const saveCodeBlock = async (codeBlockData) => {
  try {
    const response = await api.post('/api/save-code-block', codeBlockData);
    return response.data;
  } catch (error) {
    console.error('Error saving code block:', error);
    throw error;
  }
};

// Function to fetch all ideas
export const fetchIdeas = async (tags = []) => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('No access token found');
    }

    let url = '/api/ideas';
    if (tags.length > 0 && !tags.includes('All')) {
      const tagParams = tags.map(tag => `tags=${encodeURIComponent(tag)}`).join('&');
      url += `?${tagParams}`;
    }

    console.log('Fetching ideas with URL:', url);
    console.log('Authorization token present:', !!token);

    // Add retry logic
    let retries = 3;
    while (retries > 0) {
      try {
        const response = await api.get(url);
        if (!Array.isArray(response.data)) {
          console.error('Invalid response format:', response.data);
          throw new Error('Invalid response format from server');
        }
        console.log('Ideas response:', response.data);
        return response.data;
      } catch (error) {
        retries--;
        if (retries === 0) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 1 second before retry
      }
    }
  } catch (error) {
    console.error('Error fetching ideas:', error);
    if (error.response) {
      console.error('Server error response:', error.response.data);
      if (error.response.status === 401) {
        // Clear invalid token
        localStorage.removeItem('accessToken');
      }
    }
    throw error;
  }
};

// Function to create a new idea
export const createIdea = async (ideaData) => {
  try {
    console.log('Creating new idea with data:', ideaData);
    const response = await api.post('/api/ideas', ideaData);
    console.log('Created idea:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating idea:', error);
    throw error;
  }
};

// Function to update an existing idea
export const updateIdea = async (id, ideaData) => {
  try {
    console.log('Updating idea:', id, 'with data:', ideaData);
    const response = await api.put(`/api/ideas/${id}`, ideaData);
    console.log('Updated idea:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating idea:', error);
    throw error;
  }
};

// Function to delete an idea
export const deleteIdea = async (id) => {
  try {
    const response = await api.delete(`/api/ideas/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting idea:', error);
    throw error;
  }
};

// Function to fetch all idea tags
export const fetchIdeaTags = async () => {
  try {
    const response = await api.get('/api/ideas/tags');
    return response.data;
  } catch (error) {
    console.error('Error fetching idea tags:', error);
    if (error.response && error.response.status === 404) {
      console.warn('Idea tags endpoint not found. Returning empty array.');
      return [];
    }
    throw error;
  }
};

// Function to add a tag to an idea
export const addIdeaTag = async (ideaId, tag) => {
  try {
    const response = await api.post(`/api/ideas/${ideaId}/tags`, { tag });
    return response.data;
  } catch (error) {
    console.error('Error adding tag to idea:', error);
    throw error;
  }
};

// Function to remove a tag from an idea
export const removeIdeaTag = async (ideaId, tag) => {
  try {
    const response = await api.delete(`/api/ideas/${ideaId}/tags/${tag}`);
    return response.data;
  } catch (error) {
    console.error('Error removing tag from idea:', error);
    throw error;
  }
};

// Function to edit an idea tag
export const editIdeaTag = async (oldTag, newTag) => {
  try {
    console.log('API call: Editing idea tag', oldTag, 'to', newTag);
    if (!oldTag || !newTag) {
      console.error('Invalid oldTag or newTag:', { oldTag, newTag });
      throw new Error('Both oldTag and newTag are required');
    }
    console.log('Sending request with body:', { oldTag, newTag });
    const response = await api.put('/api/ideas/tags', { oldTag, newTag });
    console.log('Full API request:', response.config);
    console.log('API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('API error editing idea tag:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    }
    throw error;
  }
};

// Function to delete an idea tag
export const deleteIdeaTag = async (tag) => {
  try {
    const response = await api.delete(`/api/ideas/tags/${tag}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting idea tag:', error);
    throw error;
  }
};

// Function to get idea tag counts
export const getIdeaTagCounts = async () => {
  try {
    const response = await api.get('/api/ideas/tag-counts');
    return response.data;
  } catch (error) {
    console.error('Error fetching idea tag counts:', error);
    throw error;
  }
};

export const fetchTargetAudienceAvatarUrl = async (audienceId) => {
  try {
    const response = await api.get(`/api/target-audiences/${audienceId}/avatar`);
    return response.data.signedUrl;
  } catch (error) {
    console.error('Error fetching target audience avatar URL:', error);
    throw error;
  }
};

// Add these new functions at the end of the file

export const generateIdeasStrategist = async (clientId, audienceId, contentGoals, personalizationData) => {
  try {
    const response = await api.post('/api/idea-strategist/generate', {
      clientId,
      audienceId,
      contentGoals,
      personalizationData
    });
    return response.data.ideas;
  } catch (error) {
    console.error('Error generating ideas:', error);
    throw error;
  }
};

export const fetchGeneratedIdeas = async () => {
  try {
    const response = await api.get('/api/idea-strategist/ideastrategy');
    return response.data;
  } catch (error) {
    console.error('Error fetching generated ideas:', error);
    throw error;
  }
};

// Update or add this function
export const getTargetAudiences = async () => {
  try {
    console.log('Fetching target audiences...');
    const response = await api.get('/api/target-audiences');
    console.log('Target audiences response:', response.data);
    if (!Array.isArray(response.data)) {
      console.error('Unexpected response format for target audiences:', response.data);
      return [];
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching target audiences:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    return []; // Return an empty array instead of throwing an error
  }
};

// Add these new functions at the end of the file, just before the last line (export default api;)

// Function to save the Idea Strategist progress
export const saveIdeaStrategistProgress = async (progressData) => {
  try {
    console.log('Saving Idea Strategist progress:', progressData);
    const response = await api.post('/api/idea-strategist/save-progress', progressData);
    console.log('Progress saved:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error saving Idea Strategist progress:', error);
    throw error;
  }
};

// Function to fetch the saved Idea Strategist progress
export const fetchIdeaStrategistProgress = async () => {
  try {
    const response = await api.get('/api/idea-strategist/progress');
    return response.data;
  } catch (error) {
    console.error('Error fetching Idea Strategist progress:', error);
    throw error;
  }
};

// Function to finalize an Idea Strategist idea
export const finalizeIdeaStrategist = async (ideaId) => {
  try {
    const response = await api.put(`/api/idea-strategist/finalize/${ideaId}`);
    return response.data;
  } catch (error) {
    console.error('Error finalizing Idea Strategist idea:', error);
    throw error;
  }
};

// Add these new functions to api.js

export const createCanvas = async (title, parentId = null) => {
  try {
    console.log('Making API request to create canvas:', { title, parentId });
    const response = await api.post('/api/canvases', { title, parentId });
    console.log('Canvas creation response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in createCanvas API call:', error);
    console.error('Error details:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserCanvases = async () => {
  try {
    console.log('Making API request to fetch user canvases');
    const response = await api.get('/api/canvases');
    console.log('Fetched canvases:', {
      count: response.data.length,
      canvases: response.data.map(c => ({ id: c._id, title: c.title }))
    });
    return response.data;
  } catch (error) {
    console.error('Error in getUserCanvases API call:', error);
    console.error('Error details:', error.response?.data || error.message);
    throw error;
  }
};

// Add a timestamp to track when the cache was last cleaned
let lastCacheCleanup = Date.now();
const CACHE_CLEANUP_INTERVAL = 60000; // Clean up every minute

// Add this function to clean up expired cache entries
const cleanupCache = () => {
  const now = Date.now();
  for (const [key, value] of canvasCache.entries()) {
    if (now - value.timestamp > CACHE_DURATION) {
      canvasCache.delete(key);
    }
  }
  lastCacheCleanup = now;
};

export const getCanvas = async (canvasId) => {
  try {
    // Clean up expired cache entries if needed
    if (Date.now() - lastCacheCleanup > CACHE_CLEANUP_INTERVAL) {
      cleanupCache();
    }

    // Check cache first
    const cached = canvasCache.get(canvasId);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      console.log('Using cached canvas data:', canvasId);
      return cached.data;
    }

    // Prevent multiple simultaneous requests for the same canvas
    const pendingRequest = canvasCache.get(`pending_${canvasId}`);
    if (pendingRequest) {
      console.log('Request already pending for canvas:', canvasId);
      return pendingRequest;
    }

    console.log('Cache miss or expired, fetching canvas:', canvasId);
    
    // Create a promise for the request and store it in cache
    const requestPromise = api.get(`/api/canvases/${canvasId}`)
      .then(response => {
        // Cache the response
        canvasCache.set(canvasId, {
          data: response.data,
          timestamp: Date.now()
        });
        // Remove the pending request
        canvasCache.delete(`pending_${canvasId}`);
        return response.data;
      })
      .catch(error => {
        // Remove the pending request on error
        canvasCache.delete(`pending_${canvasId}`);
        throw error;
      });

    // Store the pending request
    canvasCache.set(`pending_${canvasId}`, requestPromise);
    
    return requestPromise;
  } catch (error) {
    console.error('Error in getCanvas API call:', error);
    // Remove failed requests from cache
    canvasCache.delete(canvasId);
    canvasCache.delete(`pending_${canvasId}`);
    throw error;
  }
};

// Add a new function to check if canvas is cached
export const isCanvasCached = (canvasId) => {
  const cached = canvasCache.get(canvasId);
  const isPending = canvasCache.has(`pending_${canvasId}`);
  return (cached && Date.now() - cached.timestamp < CACHE_DURATION) || isPending;
};

// Add this new function
export const deleteCanvas = async (canvasId) => {
  try {
    console.log('Making API request to delete canvas:', canvasId);
    const response = await api.delete(`/api/canvases/${canvasId}`);
    console.log('Canvas deletion response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in deleteCanvas API call:', error);
    console.error('Error details:', error.response?.data || error.message);
    throw error;
  }
};

// Add this new function
export const updateCanvasTitle = async (canvasId, newTitle) => {
  try {
    console.log('Making API request to update canvas title:', { canvasId, newTitle });
    const response = await api.put(`/api/canvases/${canvasId}/title`, { title: newTitle });
    console.log('Canvas title update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in updateCanvasTitle API call:', error);
    console.error('Error details:', error.response?.data || error.message);
    throw error;
  }
};

// Update these methods to use the correct endpoint
export const addPostToCanvas = async (canvasId, postData) => {
  try {
    console.log('Adding post to canvas:', { canvasId, postData });
    
    // Ensure we have a valid canvasId
    if (!canvasId) {
      throw new Error('Canvas ID is required');
    }

    const response = await api.post(`/api/canvases/${canvasId}/posts`, {
      ...postData,
      canvasId // Include canvasId in the post data
    });
    
    console.log('Post added successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error adding post to canvas:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

// Update the updatePostInCanvas function
export const updatePostInCanvas = async (canvasId, postId, updates) => {
  try {
    if (!canvasId || !postId) {
      throw new Error('Canvas ID and Post ID are required');
    }
    
    // Ensure content is a string if it exists in updates
    if ('content' in updates) {
      updates.content = String(updates.content || '').trim();
      if (!updates.content) {
        throw new Error('Post content cannot be empty');
      }
    }
    
    console.log('Updating post:', { canvasId, postId, updates });
    const response = await api.put(`/api/canvases/${canvasId}/posts/${postId}`, updates);
    
    if (!response.data) {
      throw new Error('No data received from server');
    }

    // Invalidate the canvas cache after successful update
    invalidateCanvasCache(canvasId);

    console.log('Update response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating post:', error);
    throw new Error(error.response?.data?.error || error.message);
  }
};

export const removePostFromCanvas = async (canvasId, postId) => {
  try {
    console.log('Removing post from canvas:', { canvasId, postId });
    const response = await api.delete(`/api/canvases/${canvasId}/posts/${postId}`);
    console.log('Post removed successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error removing post from canvas:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

// Add this new function for updating post titles specifically
export const updatePostTitle = async (canvasId, postId, newTitle) => {
  try {
    console.log('Updating post title:', { canvasId, postId, newTitle });
    const response = await api.put(`/api/canvases/${canvasId}/posts/${postId}/title`, {
      title: newTitle
    });

    if (!response.data) {
      throw new Error('No data received from server');
    }

    console.log('Update post title response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating post title:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw new Error('Failed to update post title');
  }
};

// Add these new functions for YouIdea feature

// Function to generate ideas
api.generateYouIdeas = async ({ topic, persona, targetAudience }) => {
  try {
    const response = await api.post('/api/youidea/generate', { 
      topic: topic, // Send just the topic string, not the whole object
      personaId: persona?._id, // Send just the ID
      targetAudienceId: targetAudience?._id // Send just the ID
    });
    return response.data;
  } catch (error) {
    console.error('Error generating ideas:', error);
    throw error;
  }
};

// Function to fetch user's ideas with pagination
api.fetchYouIdeas = async (page = 1, limit = 10) => {
  try {
    const response = await api.get(`/api/youidea?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching ideas:', error);
    throw error;
  }
};

// Function to toggle favorite status of an idea
api.toggleYouIdeaFavorite = async (ideaId) => {
  try {
    const response = await api.patch(`/api/youidea/${ideaId}/favorite`);
    return response.data;
  } catch (error) {
    console.error('Error toggling favorite status:', error);
    throw error;
  }
};

// Function to delete an idea
api.deleteYouIdea = async (ideaId) => {
  try {
    const response = await api.delete(`/api/youidea/${ideaId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting idea:', error);
    throw error;
  }
};

// Update the generateYouIdeas function
const generateYouIdeas = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/youidea/generate`, data, {
      headers: getAuthHeaders()
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add this method to your api object
api.fetchRedditData = async (subreddit, listing = 'hot', limit = 100, timeframe = 'month') => {
    try {
        const response = await api.get(
            `/api/youidea/reddit-data/${subreddit}?listing=${listing}&limit=${limit}&timeframe=${timeframe}`
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching Reddit data:', error);
        throw error;
    }
};

// Add this export near the other canvas-related functions
export const invalidateCanvasCache = (canvasId) => {
  if (canvasId) {
    console.log('Invalidating cache for canvas:', canvasId);
    canvasCache.delete(canvasId);
    canvasCache.delete(`pending_${canvasId}`);
  } else {
    console.log('Clearing entire canvas cache');
    canvasCache.clear();
  }
};

// Add this to your existing api.js file
api.getUserAnalytics = async () => {
  try {
    console.log('Fetching user analytics...');
    const response = await api.get('/api/analytics');
    console.log('Analytics response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user analytics:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

// Add this function near other voice note related functions
export const deleteGeneratedContent = async (voiceNoteId, contentId) => {
  try {
    const response = await api.delete(`/api/voice-notes/${voiceNoteId}/generatedContent/${contentId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting generated content:', error);
    throw error;
  }
};

// Add this new method to your api object
api.getUserActivity = async () => {
  try {
    console.log('Fetching user activity...');
    const response = await api.get('/api/activity');
    console.log('Activity response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user activity:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

// Add this new function to fetch a single voice note
export const fetchVoiceNote = async (id) => {
  try {
    console.log('Fetching voice note with ID:', id);
    const response = await api.get(`/api/voice-notes/${id}`);
    
    if (!response.data) {
      throw new Error('No data received from server');
    }

    console.log('Fetched voice note:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching voice note:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

// Update the fetchIdea function
export const fetchIdea = async (id) => {
  try {
    console.log('Fetching idea with ID:', id);
    const response = await api.get(`/api/ideas/${id}`);
    console.log('Fetched idea:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching idea:', error);
    throw error;
  }
};

// Add this near the top of the file, after the imports
const handleApiError = (error) => {
  console.error('API Error:', error);
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error('Error data:', error.response.data);
    console.error('Error status:', error.response.status);
    console.error('Error headers:', error.response.headers);
    throw new Error(error.response.data.message || 'Server error occurred');
  } else if (error.request) {
    // The request was made but no response was received
    console.error('Error request:', error.request);
    throw new Error('No response received from server');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error message:', error.message);
    throw error;
  }
};

// Add these new methods for post comments
api.generatePostComments = async (postContent, tone, numComments) => {
  try {
    const response = await api.post('/api/post-comments/generate', {
      postContent,
      tone,
      numComments
    });
    return response.data;
  } catch (error) {
    console.error('Error generating post comments:', error);
    throw error;
  }
};

api.getSavedComments = async () => {
  try {
    const response = await api.get('/api/post-comments/saved');
    return response.data;
  } catch (error) {
    console.error('Error fetching saved comments:', error);
    throw error;
  }
};

// Update the saveComment method to use the correct endpoint
api.saveComment = async (commentData) => {
  try {
    const response = await api.post('/api/post-comments/saved', {
      postContent: commentData.postContent,
      commentToReply: commentData.commentToReply,
      comment: commentData.comment,
      tone: commentData.tone,
      persona: commentData.persona?._id,
      targetAudience: commentData.targetAudience?._id
    });
    return response.data;
  } catch (error) {
    console.error('Error saving comment:', error);
    throw error;
  }
};

export const generatePostTitle = async (content) => {
  try {
    const response = await api.post('/api/post-titles/generate-title', { content });
    return response.data.title;
  } catch (error) {
    console.error('Error generating post title:', error);
    throw error;
  }
};

export const createPostWithTitle = async (content) => {
  try {
    const response = await api.post('/api/post-titles/create', { content });
    return response.data;
  } catch (error) {
    console.error('Error creating post with title:', error);
    // Return a basic post structure on error
    return {
      _id: Date.now().toString(),
      content: content,
      title: 'Untitled Post',
      updatedTitle: 'Untitled Post',
      generatedTitle: false
    };
  }
};

export const regeneratePostTitle = async (postId) => {
  try {
    const response = await api.put(`/api/post-titles/${postId}/regenerate-title`);
    return response.data;
  } catch (error) {
    console.error('Error regenerating post title:', error);
    throw error;
  }
};

// Add these new task-related functions to your existing api.js file

// Function to fetch tasks with advanced filtering
export const fetchTasks = async (filters = {}) => {
  try {
    const queryParams = new URLSearchParams();
    if (filters.startDate) queryParams.append('startDate', filters.startDate);
    if (filters.endDate) queryParams.append('endDate', filters.endDate);
    if (filters.completed !== undefined) queryParams.append('completed', filters.completed);

    const response = await api.get(`/api/tasks?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    throw error;
  }
};

// Function to update task status with history tracking
export const updateTaskStatus = async (taskId, status) => {
  try {
    const response = await api.put(`/api/tasks/${taskId}`, { status });
    return response.data;
  } catch (error) {
    console.error('Error updating task status:', error);
    throw error;
  }
};

// Function to get task status history
export const getTaskStatusHistory = async (taskId) => {
  try {
    const response = await api.get(`/api/tasks/${taskId}/history`);
    return response.data;
  } catch (error) {
    console.error('Error fetching task history:', error);
    throw error;
  }
};

// Add these to your existing exports
export const taskApi = {
  fetchTasks: async (filters = {}) => {
    try {
      const queryParams = new URLSearchParams();
      if (filters.startDate) queryParams.append('startDate', filters.startDate);
      if (filters.endDate) queryParams.append('endDate', filters.endDate);
      if (filters.completed !== undefined) queryParams.append('completed', filters.completed);

      const response = await api.get(`/api/tasks?${queryParams.toString()}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching tasks:', error);
      throw error;
    }
  },

  createTask: async (taskData) => {
    try {
      // Validate dates if both are provided
      if (taskData.startDate && taskData.endDate) {
        const startDate = new Date(taskData.startDate);
        const endDate = new Date(taskData.endDate);
        if (startDate > endDate) {
          throw new Error('Start date must be before end date');
        }
      }

      const response = await api.post('/api/tasks', taskData);
      return response.data;
    } catch (error) {
      console.error('Error creating task:', error);
      throw error;
    }
  },

  updateTask: async (taskId, taskData) => {
    try {
      // Validate dates if both are provided
      if (taskData.startDate && taskData.endDate) {
        const startDate = new Date(taskData.startDate);
        const endDate = new Date(taskData.endDate);
        if (startDate > endDate) {
          throw new Error('Start date must be before end date');
        }
      }

      const response = await api.put(`/api/tasks/${taskId}`, taskData);
      return response.data;
    } catch (error) {
      console.error('Error updating task:', error);
      throw error;
    }
  },

  deleteTask: async (taskId) => {
    try {
      const response = await api.delete(`/api/tasks/${taskId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting task:', error);
      throw error;
    }
  },

  reorderTasks: async (tasks) => {
    try {
      const response = await api.put('/api/tasks/reorder', { tasks });
      return response.data;
    } catch (error) {
      console.error('Error reordering tasks:', error);
      throw error;
    }
  }
};

export const fetchRecentActivities = async () => {
  try {
    const response = await api.get('/api/recent-activities');
    return response.data;
  } catch (error) {
    console.error('Error fetching recent activities:', error);
    throw error;
  }
};

// Add this new function
export const sendChatMessageOpenAI = async (model, message, chatId, persona, template, agent, targetAudience, contentStyle, attachments) => {
  try {
    const response = await api.post('/api/chat/openai', {
      model,
      message: {
        content: message,
        ...attachments
      },
      chatId,
      persona,
      template,
      agent,
      targetAudience,
      contentStyle
    });
    return response.data;
  } catch (error) {
    console.error('Error sending chat message to OpenAI:', error);
    throw error;
  }
};

// Add these new functions for content strategy
export const generateContentStrategy = async (persona, audience, preferences) => {
  try {
    console.log('🔄 Generating content strategy:', {
      hasPersona: !!persona,
      personaId: persona?._id,
      hasAudience: !!audience,
      audienceId: audience?._id,
      selectedTopics: preferences?.selectedTopics
    });

    const response = await api.post('/api/content-strategy/generate', {
      persona,
      audience,
      preferences
    });

    console.log('📥 Generation response:', {
      status: response.status,
      success: response.data?.success,
      hasData: !!response.data?.data,
      topicsCount: response.data?.data?.topics?.length || 0,
      ideasCount: response.data?.data?.generatedIdeas?.length || 0
    });

    if (!response.data?.success) {
      throw new Error(response.data?.message || 'Failed to generate content strategy');
    }

    return response.data;
  } catch (error) {
    console.error('❌ Error generating content strategy:', {
      error,
      message: error.message,
      response: error.response?.data
    });
    throw new Error(error.response?.data?.message || error.message || 'Failed to generate content strategy');
  }
};

// Add this function to save generated ideas
export const saveContentStrategyIdeas = async (data) => {
  try {
    console.log(' Saving content strategy:', {
      hasRawContent: !!data.rawContent,
      hasFrameworks: !!data.frameworks,
      frameworksCount: data.frameworks?.length,
      hasPersona: !!data.persona,
      personaId: data.persona?._id,
      hasAudience: !!data.audience,
      audienceId: data.audience?._id
    });

    const response = await api.post('/api/content-strategy/save', data);
    
    console.log('📥 Save response:', {
      status: response.status,
      success: response.data?.success,
      data: response.data
    });

    if (!response.data?.success) {
      throw new Error(response.data?.error || 'Failed to save content strategy');
    }

    return response.data.data;
  } catch (error) {
    console.error('❌ Error saving content strategy:', {
      error,
      message: error.message,
      response: error.response?.data
    });
    throw error;
  }
};

// Add this function to fetch saved content strategies
export const fetchContentStrategies = async () => {
  try {
    console.log('📡 Fetching content strategies from API...');
    const response = await api.get('/api/content-strategy');
    console.log('📥 API Response:', {
      status: response.status,
      headers: response.headers,
      data: response.data,
      count: Array.isArray(response.data) ? response.data.length : 0
    });
    
    // Validate response format
    if (!Array.isArray(response.data)) {
      throw new Error('Invalid response format: Expected an array of strategies');
    }

    // Transform response to match the GeneratedIdeas.js format
    const transformedData = response.data.map(strategy => ({
      ...strategy,
      data: {
        topics: strategy.topics || [],
        generatedIdeas: strategy.generatedIdeas?.map(topicIdea => ({
          topic: topicIdea.topic,
          ideas: topicIdea.ideas?.map(idea => ({
            angle: idea.angle || '',
            hook: idea.hook || '',
            content: idea.content || '',
            value: idea.value || '',
            expertiseAlignment: idea.expertiseAlignment || ''
          })) || []
        })) || []
      }
    }));
    
    return {
      success: true,
      data: transformedData
    };
  } catch (error) {
    console.error('❌ Error fetching content strategies:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    return {
      success: false,
      error: error.message || 'Failed to load strategies',
      data: []
    };
  }
};

// Add this function to fetch a single content strategy
export const fetchContentStrategy = async (id) => {
  try {
    console.log('🔍 Fetching content strategy:', id);
    const response = await api.get(`/api/content-strategy/${id}`);
    console.log('📥 Received strategy data:', response.data);
    return response.data;
  } catch (error) {
    console.error('❌ Error fetching content strategy:', error);
    throw error;
  }
};

// Add these new functions for content idea management
export const contentIdeaApi = {
  // Favorite handling
  toggleFavorite: async (ideaId) => {
    try {
      const response = await api.post(`/api/content-strategy/ideas/${ideaId}/favorite`);
      return response.data;
    } catch (error) {
      console.error('Error toggling favorite:', error);
      throw error;
    }
  },

  // Notes handling
  saveNote: async (ideaId, note) => {
    try {
      const response = await api.post(`/api/content-strategy/ideas/${ideaId}/notes`, { note });
      return response.data;
    } catch (error) {
      console.error('Error saving note:', error);
      throw error;
    }
  },

  // Tags handling
  addTag: async (ideaId, tag) => {
    try {
      const response = await api.post(`/api/content-strategy/ideas/${ideaId}/tags`, { tag });
      return response.data;
    } catch (error) {
      console.error('Error adding tag:', error);
      throw error;
    }
  },

  removeTag: async (ideaId, tag) => {
    try {
      const response = await api.delete(`/api/content-strategy/ideas/${ideaId}/tags/${tag}`);
      return response.data;
    } catch (error) {
      console.error('Error removing tag:', error);
      throw error;
    }
  },

  // Export functionality
  exportIdeas: async (format = 'pdf') => {
    try {
      const response = await api.get(`/api/content-strategy/export?format=${format}`, {
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      console.error('Error exporting ideas:', error);
      throw error;
    }
  },

  // Share functionality
  shareIdeas: async (ideaIds, shareConfig) => {
    try {
      const response = await api.post('/api/content-strategy/share', {
        ideaIds,
        ...shareConfig
      });
      return response.data;
    } catch (error) {
      console.error('Error sharing ideas:', error);
      throw error;
    }
  },

  // Fetch idea metadata
  fetchIdeaMetadata: async (ideaId) => {
    try {
      const response = await api.get(`/api/content-strategy/ideas/${ideaId}/metadata`);
      return response.data;
    } catch (error) {
      console.error('Error fetching idea metadata:', error);
      throw error;
    }
  },

  // Strategy management
  getStrategy: async (id) => {
    try {
      console.log('📡 Fetching strategy:', id);
      const response = await api.get(`/api/content-strategy/${id}`);
      console.log('📥 Received strategy data:', response.data);
      return response.data;
    } catch (error) {
      console.error('❌ Error fetching strategy:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        throw new Error(error.response.data.error || 'Failed to fetch strategy');
      }
      throw error;
    }
  },

  deleteStrategy: async (id) => {
    try {
      console.log('Deleting strategy with ID:', id);
      const response = await api.delete(`/api/content-strategy/${id}`);
      
      if (!response.data) {
        throw new Error('No response data received');
      }
      
      console.log('Delete response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error deleting strategy:', error);
      if (error.response?.status === 404) {
        throw new Error('Strategy not found or you are not authorized to delete it');
      }
      throw new Error(error.response?.data?.error || 'Failed to delete strategy');
    }
  },

  cloneStrategy: async (id) => {
    try {
      const response = await api.post(`/api/content-strategy/${id}/clone`);
      return response.data;
    } catch (error) {
      console.error('Error cloning strategy:', error);
      throw error;
    }
  }
};

// Add a new function to suggest topics
export const suggestTopics = async (persona, audience, preferences) => {
  try {
    const response = await api.post('/api/content-strategy/suggest-topics', {
      persona,
      audience,
      preferences
    });

    if (!Array.isArray(response.data?.topics)) {
      console.error('Invalid topics response:', response.data);
      throw new Error('Invalid topics response format');
    }

    return response.data.topics;
  } catch (error) {
    console.error('Error suggesting topics:', error);
    throw error;
  }
};

// Add the searchSubreddits method to the api object
api.searchSubreddits = async (query) => {
  try {
    console.log('Searching subreddits with query:', query);
    const response = await api.get(`/api/youidea/search-subreddits?query=${encodeURIComponent(query)}`);
    
    if (!response.data || !Array.isArray(response.data.subreddits)) {
      console.error('Invalid response format:', response.data);
      throw new Error('Invalid response format from server');
    }
    
    console.log('Search results:', response.data.subreddits);
    return response.data.subreddits;
  } catch (error) {
    console.error('Error in searchSubreddits:', error);
    if (error.response?.status === 404) {
      return []; // Return empty array for no results
    }
    throw new Error('Failed to search subreddits');
  }
};

// Add these functions after the existing API functions

// Function to fetch saved ideas
export const fetchSavedIdeas = async () => {
  try {
    const response = await api.get('/api/saved-ideas', {
      headers: {
        'Content-Type': 'application/json',
        ...(localStorage.getItem('accessToken') ? {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        } : {})
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching saved ideas:', error);
    throw error;
  }
};

// Function to save an idea
export const saveIdea = async (ideaData) => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.error('No authentication token found in localStorage');
      throw new Error('No authentication token found');
    }

    // Ensure required fields are present
    const payload = {
      ...ideaData,
      source: ideaData.source || 'content-strategy',
      status: ideaData.status || 'pending'
    };

    console.log('Token being used:', token);
    console.log('Saving idea with payload:', payload);

    const response = await api.post('/api/saved-ideas', payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    });

    console.log('Save idea response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error saving idea:', error);
    console.error('Error response:', error.response?.data);
    console.error('Error status:', error.response?.status);
    
    if (error.response?.status === 401) {
      console.error('Authentication error - token might be invalid or expired');
      // You might want to trigger a token refresh or redirect to login here
    }
    
    if (error.response?.data?.details) {
      console.error('Validation errors:', error.response.data.details);
    }
    throw error;
  }
};

// Function to update a saved idea
export const updateSavedIdea = async (id, ideaData) => {
  try {
    const response = await api.patch(`/api/saved-ideas/${id}`, ideaData, {
      headers: {
        'Content-Type': 'application/json',
        ...(localStorage.getItem('accessToken') ? {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        } : {})
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Error updating saved idea:', error);
    throw error;
  }
};

// Function to delete a saved idea
export const deleteSavedIdea = async (id) => {
  try {
    const response = await api.delete(`/api/saved-ideas/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        ...(localStorage.getItem('accessToken') ? {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        } : {})
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting saved idea:', error);
    throw error;
  }
};

// Add these new functions for folder management
export const createClientFolder = async (folderData) => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await api.post('/api/saved-ideas/folders', folderData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    });

    if (!response.data) {
      throw new Error('No data received from server');
    }

    return response.data;
  } catch (error) {
    console.error('Error creating client folder:', error);
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

export const getClientFolders = async () => {
  try {
    const response = await api.get('/api/saved-ideas/folders', {
      headers: {
        'Content-Type': 'application/json',
        ...(localStorage.getItem('accessToken') ? {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        } : {})
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching client folders:', error);
    throw error;
  }
};

export const moveIdeaToFolder = async (ideaId, folderId) => {
  try {
    console.log('Moving idea:', ideaId, 'to folder:', folderId);
    const response = await api.patch(`/api/saved-ideas/${ideaId}/move`, { 
      folderId 
    }, {
      headers: {
        'Content-Type': 'application/json',
        // Add any auth headers if needed
        ...(localStorage.getItem('accessToken') ? {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        } : {})
      },
      // Add withCredentials for CORS
      withCredentials: true
    });
    
    if (!response.data) {
      throw new Error('No data received from server');
    }
    
    console.log('Move response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error moving idea to folder:', error);
    console.error('Error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    throw error;
  }
};

export default api;

// Add this new function for updating folder names
export const updateClientFolder = async (folderId, updates) => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await api.put(`/api/saved-ideas/folders/${folderId}`, updates, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    });

    if (!response.data) {
      throw new Error('No data received from server');
    }

    return response.data;
  } catch (error) {
    console.error('Error updating client folder:', error);
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

// Add this new function with the other folder-related functions
export const deleteClientFolder = async (folderId) => {
  try {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await api.delete(`/api/saved-ideas/folders/${folderId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting client folder:', error);
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

// Add this function to handle cancellation
export const cancelAnalysis = async () => {
  try {
    const response = await api.post('/api/cancel-analysis');
    return response.data;
  } catch (error) {
    console.error('Failed to cancel analysis:', error);
    throw error;
  }
};

// Add this with other API functions
export const getYoutubeTranscript = async (videoId) => {
  try {
    const response = await api.post('/api/youtube/transcript', { videoId });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

// Add these functions to the exports
export const processPersonaText = async (text) => {
  try {
    const response = await api.post('/api/personas/ai/process-text', { text });
    return response.data;
  } catch (error) {
    console.error('Error processing text:', error);
    throw error;
  }
};

export const processPersonaPDF = async (file) => {
  try {
    const formData = new FormData();
    formData.append('pdf', file);

    const response = await api.post('/api/personas/ai/process-pdf', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 30000, // 30 second timeout for file uploads
    });
    return response.data;
  } catch (error) {
    console.error('Error processing PDF:', error);
    throw error;
  }
};

export const processPersonaURL = async (url) => {
  try {
    const response = await api.post('/api/personas/ai/process-url', { url });
    return response.data;
  } catch (error) {
    console.error('Error processing URL:', error);
    throw error;
  }
};

export const sendChatMessageForDecks = async (model, message, chatId, persona, template, agent, targetAudience, contentStyle, attachments) => {
  try {
    const response = await api.post('/api/chat/decks', {
      model,
      message: {
        content: message,
        ...attachments
      },
      chatId,
      persona,
      template,
      agent,
      targetAudience,
      contentStyle
    });
    return response.data;
  } catch (error) {
    console.error('Error sending deck chat message:', error);
    throw error;
  }
};

// Add this function to handle LinkedIn token refresh
const refreshLinkedInTokens = async () => {
  try {
    const storedTokens = TokenStorage.getTokens();
    if (!storedTokens?.refresh_token) {
      throw new Error('No refresh token available');
    }

    const response = await api.post('/api/linkedin/refresh', {
      refresh_token: storedTokens.refresh_token
    });

    if (response.data.success) {
      // Update stored tokens
      TokenStorage.saveTokens({
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        expires_at: response.data.expires_at
      });
      return response.data;
    }
    throw new Error('Token refresh failed');
  } catch (error) {
    console.error('Error refreshing LinkedIn tokens:', error);
    TokenStorage.clearTokens(); // Clear invalid tokens
    throw error;
  }
};

// Update the LinkedIn publish function to handle token refresh
export const publishToLinkedIn = async (content, title = '') => {
  try {
    // First check if user is connected to LinkedIn
    const statusResponse = await isLinkedInConnected();
    console.log('LinkedIn status before publishing:', statusResponse);
    
    if (!statusResponse.isConnected || statusResponse.needsReconnect) {
      // Try to refresh tokens first
      try {
        await refreshLinkedInTokens();
        // Retry status check after refresh
        const newStatus = await isLinkedInConnected();
        if (!newStatus.isConnected) {
          throw new Error('LinkedIn authentication required after token refresh');
        }
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        throw new Error(
          statusResponse.needsReconnect 
            ? 'LinkedIn authentication has expired. Please reconnect your account.'
            : 'Not connected to LinkedIn. Please connect your LinkedIn account first.'
        );
      }
    }

    const response = await api.post('/api/linkedin/publish', {
      content,
      title
    });
    
    console.log('LinkedIn publish response:', response.data);
    return response.data;
  } catch (error) {
    console.error('LinkedIn publish error:', error.response?.data || error);
    
    if (error.response?.status === 401) {
      // Clear tokens on authentication error
      TokenStorage.clearTokens();
    }
    
    throw new Error(error.response?.data?.error || error.message);
  }
};

// Add this function to handle LinkedIn authentication
export const handleLinkedInAuth = async (code, state) => {
  try {
    const response = await api.post('/api/linkedin/callback', { code, state });
    
    // Save tokens securely
    if (response.data.linkedinTokens) {
      TokenStorage.saveTokens(response.data.linkedinTokens);
    }
    
    return response.data;
  } catch (error) {
    console.error('LinkedIn auth error:', error);
    throw error;
  }
};

// Add this new function
export const updatePostPublishStatus = async (canvasId, postId, isPublished) => {
  try {
    const response = await api.put(
      `/api/canvases/${canvasId}/posts/${postId}/publish`,
      { isPublished }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating post publish status:', error);
    throw error;
  }
};

// Add specific function for DeepSeek chat
export const sendDeepSeekChat = async (model, message, chatId, persona, template, agent, targetAudience, contentStyle) => {
  try {
    console.log('Sending DeepSeek chat request:', {
      model,
      message: typeof message === 'object' ? { ...message, content: '...' } : '...',
      chatId,
      persona: persona?.name,
      template: template?.title,
      agent: agent?.name,
      targetAudience: targetAudience?.name,
      contentStyle
    });

    const response = await api.post('/api/chat/deepseek', {
      model,
      message,
      chatId,
      persona,
      template,
      agent,
      targetAudience,
      contentStyle
    });

    console.log('Received DeepSeek response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in sendDeepSeekChat:', error);
    
    let errorMessage = 'An error occurred while processing your request';
    
    if (error.response?.data) {
      const { error: apiError, details, type } = error.response.data;
      
      // Use the most specific error message available
      errorMessage = details || apiError || errorMessage;
      
      // Add context for specific error types
      if (type === 'authentication_error') {
        errorMessage = 'Failed to authenticate with DeepSeek API. Please check your API key.';
      } else if (type === 'rate_limit_error') {
        errorMessage = 'DeepSeek API rate limit exceeded. Please try again in a few minutes.';
      } else if (type === 'timeout_error') {
        errorMessage = 'Request to DeepSeek API timed out. Please try again.';
      } else if (type === 'invalid_request_error') {
        errorMessage = 'Invalid request to DeepSeek API. Please check your input.';
      }
    }
    
    throw new Error(errorMessage);
  }
};

export const updateCanvasIcon = async (canvasId, icon) => {
  try {
    console.log('Updating canvas icon:', { canvasId, icon });
    
    // Fixed URL: changed from /canvas/ to /canvases/
    const response = await api.patch(`/api/canvases/${canvasId}/icon`, { icon });
    
    if (!response.data) {
      throw new Error('No data received from server');
    }

    console.log('Icon updated successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error updating canvas icon:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      throw new Error(error.response.data.error || 'Failed to update canvas icon');
    }
    throw error;
  }
};

// Add this new function for customer portal session
export const createCustomerPortalSession = async () => {
  try {
    console.log('Creating customer portal session...');
    const response = await api.post('/api/subscription/create-portal-session');
    console.log('Portal session response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating customer portal session:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
    }
    throw error;
  }
};

export const sendVideoScript = async (model, message, chatId, persona, template, agent, targetAudience, videoStyle) => {
  try {
    console.log('Sending video script request:', {
      model,
      message: typeof message === 'object' ? { ...message, content: '...' } : '...',
      chatId,
      persona: persona?.name,
      template: template?.title,
      agent: agent?.name,
      targetAudience: targetAudience?.name,
      videoStyle
    });

    const response = await api.post('/api/chat/video-script', {
      model,
      message,
      chatId,
      persona,
      template,
      agent,
      targetAudience,
      videoStyle
    });

    console.log('Received video script response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error in sendVideoScript:', error);
    
    let errorMessage = 'An error occurred while processing your request';
    
    if (error.response?.data) {
      const { error: apiError, details, type } = error.response.data;
      
      // Use the most specific error message available
      errorMessage = details || apiError || errorMessage;
    }
    
    throw new Error(errorMessage);
  }
};

export const searchWithPerplexity = async (query) => {
  try {
    const response = await axios.post('/api/perplexity/search', { query });
    return response.data;
  } catch (error) {
    console.error('Perplexity search error:', error);
    throw error;
  }
};

// Add Perplexity specific API configuration
export const perplexityApi = axios.create({
  baseURL: `${getBestApiUrl()}/api/perplexity`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add request interceptor for Perplexity API
perplexityApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for Perplexity API
perplexityApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401 && !error.config._retry) {
      error.config._retry = true;
      try {
        await refreshAccessToken();
        const token = localStorage.getItem('accessToken');
        error.config.headers['Authorization'] = `Bearer ${token}`;
        return perplexityApi(error.config);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

