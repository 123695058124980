import React from 'react';
import { useTheme } from '../context/ThemeContext';
import AnimatedLoader from './common/AnimatedLoader';

const CanvasSkeleton = ({ isEmpty, isLoading }) => {
  const { isDarkMode } = useTheme();

  // CSS Variables matching index.html with enhanced text colors
  const cssVariables = isDarkMode ? {
    loaderBg: '#121212',
    loaderColor: '#3B82F6',
    chatBg: 'rgba(17, 24, 39, 0.4)',
    chatBorder: 'rgba(59, 130, 246, 0.15)',
    chatItem: 'rgba(59, 130, 246, 0.2)',
    headerItem: 'rgba(59, 130, 246, 0.25)',
    shimmer: 'rgba(59, 130, 246, 0.3)',
    postBg: 'rgba(17, 24, 39, 0.8)',
    // Enhanced text colors for dark mode
    textPrimary: 'rgba(255, 255, 255, 0.95)',
    textSecondary: 'rgba(148, 163, 184, 0.95)', // Slate-300
    textTertiary: 'rgba(148, 163, 184, 0.8)', // Slate-400
  } : {
    loaderBg: '#ffffff',
    loaderColor: 'rgba(96, 165, 250, 1)',
    chatBg: 'rgba(96, 165, 250, 0.05)',
    chatBorder: 'rgba(96, 165, 250, 0.1)',
    chatItem: 'rgba(96, 165, 250, 0.1)',
    headerItem: 'rgba(96, 165, 250, 0.15)',
    shimmer: 'rgba(96, 165, 250, 0.2)',
    postBg: 'rgba(255, 255, 255, 0.8)',
    // Enhanced text colors for light mode
    textPrimary: 'rgba(15, 23, 42, 0.95)', // Slate-900
    textSecondary: 'rgba(51, 65, 85, 0.95)', // Slate-700
    textTertiary: 'rgba(71, 85, 105, 0.8)', // Slate-600
  };

  const renderOverlay = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center space-y-3">
          <AnimatedLoader 
            size={24}
            color={cssVariables.loaderColor}
          />
          <div 
            className="px-4 py-2 text-center"
            style={{ color: cssVariables.textSecondary }}
          >
            <div className="text-sm font-medium mb-1">Loading posts...</div>
            <div className="text-xs" style={{ color: cssVariables.textTertiary }}>
              This might take a moment
            </div>
          </div>
        </div>
      );
    }

    if (isEmpty) {
      return (
        <div 
          className="px-6 py-4 rounded-lg border text-center"
          style={{
            background: cssVariables.postBg,
            borderColor: cssVariables.chatBorder,
          }}
        >
          <div 
            className="text-sm font-medium mb-1"
            style={{ color: cssVariables.textSecondary }}
          >
            No posts yet
          </div>
          <div 
            className="text-xs"
            style={{ color: cssVariables.textTertiary }}
          >
            Add posts to manage here
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="relative w-[70%] mx-auto max-w-[90%] sm:max-w-[350px]">
      {/* Skeleton */}
      <div 
        className="backdrop-blur-sm rounded-xl transition-colors duration-300 p-3 sm:p-4 relative border"
        style={{
          background: cssVariables.postBg,
          borderColor: cssVariables.chatBorder,
          boxShadow: isDarkMode ? '0 0 40px rgba(17, 24, 39, 0.4)' : 'none'
        }}
      >
        {/* Message Box - Static Overlay */}
        <div className="absolute inset-0 flex items-center justify-center z-10">
          {renderOverlay()}
        </div>

        {/* Enhanced Skeleton Content with Animation */}
        <div 
          className="space-y-3 sm:space-y-4"
          style={{ opacity: isLoading || isEmpty ? 0.5 : 1 }}
        >
          {/* Header with avatar and text */}
          <div className="flex items-center space-x-2 sm:space-x-3">
            <div 
              className="rounded-full h-10 w-10 sm:h-12 sm:w-12 relative overflow-hidden"
              style={{
                background: `linear-gradient(135deg, ${cssVariables.headerItem}, ${cssVariables.chatItem})`,
                boxShadow: isDarkMode ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
              }}
            >
              <div 
                className="absolute inset-0"
                style={{
                  background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                  animation: 'typing 2s ease-in-out infinite'
                }}
              />
            </div>
            <div className="flex-1 space-y-1.5 sm:space-y-2 py-1">
              {[...Array(2)].map((_, index) => (
                <div 
                  key={index}
                  className="relative overflow-hidden rounded-full"
                  style={{
                    height: '0.75rem',
                    width: index === 0 ? '75%' : '50%',
                    background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
                    boxShadow: isDarkMode ? '0 1px 2px rgba(0, 0, 0, 0.1)' : 'none'
                  }}
                >
                  <div 
                    className="absolute inset-0"
                    style={{
                      background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                      animation: 'typing 2s ease-in-out infinite'
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Content area with typing effect */}
          <div className="space-y-1.5 sm:space-y-2">
            {[...Array(3)].map((_, index) => (
              <div 
                key={index}
                className="relative overflow-hidden rounded-full"
                style={{
                  height: '0.75rem',
                  width: index === 0 ? '100%' : index === 1 ? '85%' : '70%',
                  background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
                  boxShadow: isDarkMode ? '0 1px 2px rgba(0, 0, 0, 0.1)' : 'none',
                  animation: 'lineAppear 0.5s ease-out forwards',
                  animationDelay: `${index * 1.5}s`
                }}
              >
                <div 
                  className="absolute inset-0"
                  style={{
                    background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                    animation: 'typing 2s ease-in-out infinite',
                    animationDelay: `${index * 0.5}s`
                  }}
                />
              </div>
            ))}
          </div>

          {/* Image placeholder with enhanced gradient */}
          <div className="mt-2 sm:mt-3">
            <div 
              className="rounded-lg sm:rounded-xl h-32 sm:h-40 w-full relative overflow-hidden"
              style={{
                background: `linear-gradient(135deg, ${cssVariables.headerItem}, ${cssVariables.chatItem})`,
                boxShadow: isDarkMode ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none'
              }}
            >
              <div 
                className="absolute inset-0"
                style={{
                  background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                  animation: 'shimmer 3s infinite'
                }}
              />
            </div>
          </div>

          {/* Engagement metrics skeleton */}
          <div className="flex items-center justify-between pt-2">
            {[...Array(3)].map((_, index) => (
              <div 
                key={index}
                className="relative overflow-hidden rounded-full"
                style={{
                  height: '0.5rem',
                  width: '3rem',
                  background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
                  boxShadow: isDarkMode ? '0 1px 2px rgba(0, 0, 0, 0.1)' : 'none'
                }}
              >
                <div 
                  className="absolute inset-0"
                  style={{
                    background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                    animation: 'shimmer 2s infinite'
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Glow effect for dark mode */}
        {isDarkMode && (
          <div className="absolute inset-0 pointer-events-none rounded-xl">
            <div 
              className="absolute inset-0"
              style={{
                background: `linear-gradient(135deg, ${cssVariables.loaderColor}0A, transparent 50%, ${cssVariables.loaderColor}0A)`
              }}
            />
          </div>
        )}
      </div>

      {/* Replace the style jsx tag with proper keyframes */}
      <style>{`
        @keyframes typing {
          0% { left: -100%; }
          100% { left: 200%; }
        }

        @keyframes shimmer {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }

        @keyframes lineAppear {
          0% {
            opacity: 0;
            transform: translateY(5px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default CanvasSkeleton; 
