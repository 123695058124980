import React, { useState, useEffect, useCallback, useRef } from 'react';
import { XMarkIcon, MicrophoneIcon, CheckIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '../context/ThemeContext';
import { fetchVoiceNotes } from '../api';
import { motion, AnimatePresence } from 'framer-motion';
import AddToChat from './common/AddToChat';
import { cn } from '../utils/cn';
import SkeletonLoader from './common/SkeletonLoader';
import { useNavigate } from 'react-router-dom';

const VoiceNoteSelector = ({ onSelect, onClose, initialSelectedNotes = [] }) => {
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 639 });
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });
  const [voiceNotes, setVoiceNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState(initialSelectedNotes);
  const navigate = useNavigate();

  const loadVoiceNotes = useCallback(async () => {
    try {
      setLoading(true);
      // Set limit to a very high number to get all notes at once
      const { voiceNotes: notes } = await fetchVoiceNotes([], 1, 1000);
      setVoiceNotes(notes);
    } catch (error) {
      console.error('Error fetching voice notes:', error);
      setError('Failed to load voice notes. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Initial load
  useEffect(() => {
    loadVoiceNotes();
  }, [loadVoiceNotes]);

  const truncateTranscription = (text, maxLength = 95) => {
    if (!text) return 'No transcription available';
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const truncateTitle = (title, maxLength = 35) => {
    if (!title) return 'Untitled';
    if (title.length <= maxLength) return title;
    return title.substr(0, maxLength) + '...';
  };

  const handleNoteSelection = (note) => {
    setSelectedNotes(prevSelected => {
      const isAlreadySelected = prevSelected.some(selected => selected._id === note._id);
      if (isAlreadySelected) {
        return prevSelected.filter(selected => selected._id !== note._id);
      } else {
        const completeNote = {
          _id: note._id,
          title: note.title,
          transcription: note.transcription,
          audioUrl: note.audioUrl,
          createdAt: note.createdAt
        };
        return [...prevSelected, completeNote];
      }
    });
  };

  const handleConfirmSelection = () => {
    const completeNotes = selectedNotes.map(note => ({
      _id: note._id,
      title: note.title,
      transcription: note.transcription,
      audioUrl: note.audioUrl,
      createdAt: note.createdAt
    }));
    onSelect(completeNotes);
    onClose();
  };

  const handleCreateVoiceNote = () => {
    navigate('/voice-notes', { state: { startRecording: true } });
    onClose();
  };

  if (error) {
    return (
      <div className="absolute inset-0 z-50 flex">
        <div className="flex-1 h-full">
          <motion.div 
            className={cn(
              "flex flex-col h-full relative overflow-hidden border-none rounded-none",
              isDarkMode ? "bg-[#121212]" : "bg-gray-50"
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {/* Error state content */}
          </motion.div>
        </div>
      </div>
    );
  }

  return (
    <div className="absolute inset-0 z-50 flex">
      <div className="flex-1 h-full">
        <motion.div 
          className={cn(
            "flex flex-col h-full relative overflow-hidden border-none rounded-none",
            isDarkMode ? "bg-[#121212]" : "bg-gray-50"
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {/* Header */}
          <div className={cn(
            "h-12 min-h-12 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b",
            "sticky top-0 z-50 backdrop-blur-xl",
            isDarkMode 
              ? "bg-[#151515]/80 border-[#333333]" 
              : "bg-white/80 border-gray-200"
          )}>
            <div className="flex items-center space-x-2">
              <MicrophoneIcon className={cn(
                "h-4 w-4",
                isDarkMode ? "text-slate-300" : "text-gray-700"
              )} />
              <h2 className={cn(
                "text-sm font-medium tracking-tight",
                isDarkMode ? "text-slate-100" : "text-gray-900"
              )}>
                Voice Notes
              </h2>
            </div>
            <button 
              onClick={onClose}
              className={cn(
                "p-2 rounded-lg transition-all duration-200",
                isDarkMode 
                  ? "text-slate-400 hover:text-slate-200 hover:bg-white/5 active:bg-white/10" 
                  : "text-gray-500 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200"
              )}
              title="Back to Chat"
            >
              <ArrowLeftIcon className="w-4 h-4" />
            </button>
          </div>

          {/* Content Area */}
          <div className={cn(
            "flex-grow overflow-y-auto",
            isDarkMode ? "bg-[#121212]" : "bg-gray-50"
          )}>
            {loading ? (
              <div className="px-4 py-3">
                <SkeletonLoader 
                  isDarkMode={isDarkMode} 
                  count={5} 
                  variant="default"
                  iconClassName="w-8 h-8"
                />
              </div>
            ) : voiceNotes.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full p-4">
                <div className={cn(
                  "w-12 h-12 rounded-xl flex items-center justify-center mb-3",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}>
                  <MicrophoneIcon className={cn(
                    "w-6 h-6",
                    isDarkMode ? "text-slate-400" : "text-gray-500"
                  )} />
                </div>
                <p className={cn(
                  "text-center text-sm mb-4",
                  isDarkMode ? "text-slate-400" : "text-gray-500"
                )}>
                  No voice notes available
                </p>
                <button
                  onClick={handleCreateVoiceNote}
                  className={cn(
                    "flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium",
                    "transition-all duration-200",
                    isDarkMode
                      ? "bg-blue-600/10 text-blue-400 hover:bg-blue-600/20"
                      : "bg-blue-50 text-blue-600 hover:bg-blue-100"
                  )}
                >
                  <MicrophoneIcon className="w-4 h-4" />
                  Record Voice Note
                </button>
              </div>
            ) : (
              <div className="px-2 py-2 space-y-1.5">
                <AnimatePresence mode="popLayout">
                  {voiceNotes.map((note) => (
                    <motion.button
                      key={note._id}
                      onClick={() => handleNoteSelection(note)}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      transition={{ duration: 0.2 }}
                      className={cn(
                        "w-full text-left p-2.5 flex items-center relative",
                        "transition-all duration-200 rounded-xl border",
                        isDarkMode 
                          ? "hover:bg-[#1E1E1E] active:bg-[#252525]" 
                          : "hover:bg-white active:bg-gray-50",
                        selectedNotes.some(selected => selected._id === note._id)
                          ? isDarkMode
                            ? "bg-[#1E1E1E] border-[#333333] shadow-lg shadow-black/10"
                            : "bg-white border-blue-200 shadow-lg shadow-blue-500/5"
                          : isDarkMode
                            ? "border-transparent bg-transparent"
                            : "border-transparent bg-transparent",
                        "hover:shadow-md"
                      )}
                    >
                      <div className={cn(
                        "w-7 h-7 rounded-lg flex items-center justify-center flex-shrink-0 mr-2.5",
                        isDarkMode 
                          ? "bg-[#252525]" 
                          : "bg-gray-100",
                      )}>
                        <MicrophoneIcon className={cn(
                          "w-3.5 h-3.5",
                          isDarkMode ? "text-slate-400" : "text-gray-500"
                        )} />
                      </div>
                      <div className="flex-grow min-w-0 pr-7">
                        <h3 className={cn(
                          "font-medium text-sm mb-0.5 truncate",
                          isDarkMode ? "text-slate-100" : "text-gray-900"
                        )}>
                          {truncateTitle(note.title)}
                        </h3>
                        <p className={cn(
                          "text-xs line-clamp-2",
                          isDarkMode ? "text-slate-400" : "text-gray-500"
                        )}>
                          {truncateTranscription(note.transcription, 115)}
                        </p>
                      </div>
                      {selectedNotes.some(selected => selected._id === note._id) && (
                        <div className={cn(
                          "w-7 h-7 rounded-lg flex items-center justify-center flex-shrink-0",
                          "absolute right-2.5",
                          isDarkMode 
                            ? "bg-blue-500/10" 
                            : "bg-blue-50"
                        )}>
                          <CheckIcon className={cn(
                            "h-3.5 w-3.5",
                            isDarkMode ? "text-blue-400" : "text-blue-600"
                          )} />
                        </div>
                      )}
                    </motion.button>
                  ))}
                </AnimatePresence>
              </div>
            )}
          </div>

          {/* Footer */}
          <div className={cn(
            "px-3 py-3 sticky bottom-0 border-t",
            isDarkMode 
              ? "bg-[#151515]/80 border-[#333333]" 
              : "bg-white/80 border-gray-200",
            "backdrop-blur-xl"
          )}>
            <AddToChat
              onClick={handleConfirmSelection}
              disabled={selectedNotes.length === 0}
              selectedCount={selectedNotes.length}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default VoiceNoteSelector;
