import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGoogleCallback } from '../../services/googleAuth';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import AnimatedLoader from '../common/AnimatedLoader';
import SubscriptionService from '../../services/subscriptionService';
import GeoService from '../../services/geoService';

const GoogleAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const codeProcessed = useRef(false);
  const { isDarkMode } = useTheme();
  const [status, setStatus] = useState('initializing');
  const [error, setError] = useState(null);

  const statusMessages = {
    initializing: 'Initializing Google sign-in...',
    processing: 'Processing your Google account...',
    authenticating: 'Authenticating your account...',
    finalizing: 'Almost there...',
    error: 'Authentication failed'
  };

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const code = location.state?.code;
        if (!code) {
          setStatus('error');
          setError('Authentication failed: No authorization code received');
          toast.error('Authentication failed: No authorization code received');
          setTimeout(() => navigate('/login'), 2000);
          return;
        }

        // Prevent processing the same code multiple times
        if (codeProcessed.current) {
          console.log('Code already processed, skipping...');
          return;
        }
        codeProcessed.current = true;

        setStatus('processing');
        console.log('🔵 Processing Google callback with code...');
        
        setStatus('authenticating');
        const authData = await handleGoogleCallback(code);
        console.log('🔵 Received auth data:', { 
          hasAccessToken: !!authData.accessToken,
          hasRefreshToken: !!authData.refreshToken,
          hasUser: !!authData.user,
          isNewUser: authData.isNewUser
        });
        
        if (authData.accessToken && authData.refreshToken && authData.user) {
          setStatus('finalizing');
          console.log('🔵 Storing tokens and updating auth state...');
          
          // Store tokens
          localStorage.setItem('accessToken', authData.accessToken);
          localStorage.setItem('refreshToken', authData.refreshToken);
          
          // Update auth context with user data
          setUser({
            ...authData.user,
            token: authData.accessToken
          });
          
          // Show success message
          toast.success('Successfully signed in with Google!');
          
          // Only check for trial intent if this is a new user
          if (authData.isNewUser) {
            console.log('🔵 New user detected, checking for trial intent...');
            // Check for stored trial intent
            const trialIntent = localStorage.getItem('trial_intent');
            console.log('🔵 Trial intent from storage:', trialIntent);

            // Default trial data for new users
            let planId = 'standard';
            let isYearly = false;

            if (trialIntent) {
              try {
                const trialData = JSON.parse(trialIntent);
                console.log('🔵 Parsed trial data:', trialData);
                planId = trialData.planId || planId;
                isYearly = trialData.isYearly || false;
                localStorage.removeItem('trial_intent');
              } catch (parseError) {
                console.error('❌ Error parsing trial intent, using defaults:', parseError);
              }
            } else {
              console.log('🔵 No trial intent found, using default trial settings');
            }

            // Always start trial for new users
            try {
              console.log('🔵 Starting trial with:', { planId, isYearly });
              const trialResult = await SubscriptionService.startTrial(planId, isYearly ? 'year' : 'month');
              console.log('🔵 Trial start result:', trialResult);
              
              if (trialResult.success) {
                toast.success('Your free trial has started! Enjoy full access for 7 days.');
                navigate('/chat');
              } else {
                console.error('❌ Trial start failed:', trialResult);
                // Even if trial fails, send to chat
                navigate('/chat');
              }
            } catch (trialError) {
              console.error('❌ Error starting trial:', trialError);
              // If trial start fails, still send to chat
              navigate('/chat');
            }
          } else {
            console.log('🔵 Existing user, skipping trial start');
            // If existing user, redirect to chat
            navigate('/chat');
          }
        } else {
          console.error('❌ Invalid auth data:', authData);
          throw new Error('Invalid authentication response');
        }
      } catch (error) {
        console.error('❌ Google callback error:', error);
        setStatus('error');
        setError(error.response?.data?.error || 'Authentication failed');
        toast.error(error.response?.data?.error || 'Authentication failed');
        setTimeout(() => navigate('/login'), 2000);
      }
    };

    handleCallback();
  }, [location, navigate, setUser]);

  return (
    <div className={`min-h-screen flex items-center justify-center ${
      isDarkMode 
        ? 'bg-gradient-to-br from-[#121212] via-[#1a1a1a] to-[#121212]' 
        : 'bg-gradient-to-br from-slate-50 via-white to-slate-50'
    }`}>
      <div className={`p-8 rounded-2xl ${
        isDarkMode 
          ? 'bg-[#1E1E1E]/90 border border-gray-800/40' 
          : 'bg-white/90 border border-slate-200/60'
      } backdrop-blur-sm shadow-xl max-w-md w-full mx-4`}>
        <AnimatePresence mode="wait">
          <motion.div
            key={status}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="flex flex-col items-center space-y-6"
          >
            {status === 'error' ? (
              <>
                <div className={`p-3 rounded-full ${
                  isDarkMode ? 'bg-red-500/10' : 'bg-red-50'
                }`}>
                  <svg 
                    className={`w-8 h-8 ${isDarkMode ? 'text-red-400' : 'text-red-500'}`}
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M6 18L18 6M6 6l12 12" 
                    />
                  </svg>
                </div>
                <div className="text-center">
                  <h2 className={`text-xl font-semibold mb-2 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {statusMessages[status]}
                  </h2>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>
                    {error}
                  </p>
                  <p className={`text-sm mt-2 ${
                    isDarkMode ? 'text-gray-500' : 'text-gray-500'
                  }`}>
                    Redirecting you back...
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className={`p-3 rounded-full ${
                  isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50'
                }`}>
                  <AnimatedLoader 
                    size={32} 
                    color={isDarkMode ? '#60A5FA' : '#2563EB'} 
                  />
                </div>
                <div className="text-center">
                  <h2 className={`text-xl font-semibold mb-2 ${
                    isDarkMode ? 'text-white' : 'text-gray-900'
                  }`}>
                    {statusMessages[status]}
                  </h2>
                  <p className={`text-sm ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>
                    Please wait while we complete the authentication process
                  </p>
                </div>
                <div className="flex gap-1">
                  {[...Array(3)].map((_, i) => (
                    <motion.div
                      key={i}
                      className={`w-2 h-2 rounded-full ${
                        isDarkMode ? 'bg-blue-500/50' : 'bg-blue-200'
                      }`}
                      animate={{
                        scale: [1, 1.2, 1],
                        opacity: [0.5, 1, 0.5]
                      }}
                      transition={{
                        duration: 1,
                        repeat: Infinity,
                        delay: i * 0.2
                      }}
                    />
                  ))}
                </div>
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default GoogleAuthCallback; 