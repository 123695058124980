import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const DropdownPortal = ({ children, targetRef, isOpen, onClose }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) &&
        targetRef.current &&
        !targetRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
      positionDropdown();
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  const positionDropdown = () => {
    if (!targetRef.current || !dropdownRef.current) return;

    const targetRect = targetRef.current.getBoundingClientRect();
    const dropdownRect = dropdownRef.current.getBoundingClientRect();
    
    // Calculate position
    let top = targetRect.bottom + window.scrollY + 4; // 4px gap
    let left = targetRect.right - dropdownRect.width + window.scrollX;

    // Check if dropdown would go off screen to the left
    if (left < 0) {
      left = targetRect.left + window.scrollX;
    }

    // Check if dropdown would go off screen to the bottom
    const viewportHeight = window.innerHeight;
    if (top + dropdownRect.height > viewportHeight) {
      top = targetRect.top - dropdownRect.height - 4 + window.scrollY;
    }

    dropdownRef.current.style.top = `${top}px`;
    dropdownRef.current.style.left = `${left}px`;
  };

  // Update position on scroll or resize
  useEffect(() => {
    if (isOpen) {
      const handleUpdate = () => {
        requestAnimationFrame(positionDropdown);
      };

      window.addEventListener('scroll', handleUpdate, true);
      window.addEventListener('resize', handleUpdate);

      return () => {
        window.removeEventListener('scroll', handleUpdate, true);
        window.removeEventListener('resize', handleUpdate);
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <div 
      ref={dropdownRef}
      className="fixed z-50"
      style={{ minWidth: targetRef.current?.offsetWidth }}
    >
      {children}
    </div>,
    document.body
  );
};

export default DropdownPortal; 