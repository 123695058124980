import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import LPHeader from '../../LPHeader';
import Footer from '../../Footer';

const PublicPageLayout = ({ children, title, description }) => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`
      min-h-screen flex flex-col overflow-x-hidden w-full
      ${isDarkMode 
        ? 'bg-[#0A0A0B] text-slate-200' 
        : 'bg-white text-slate-800'
      }
      relative
    `}>
      <LPHeader />
      
      <main className={`
        min-h-screen w-full overflow-y-auto overflow-x-hidden 
        chat-scrollbar-overlay
        ${isDarkMode ? 'bg-[#0A0A0B]' : 'bg-white'}
      `}>
        {/* Enhanced Page Header */}
        {(title || description) && (
          <div className={`
            relative w-full pt-24 mb-16
            ${isDarkMode 
              ? 'bg-gradient-to-b from-[#0A0A0B] to-[#0A0A0B]' 
              : 'bg-gradient-to-b from-white to-white'
            }
          `}>
            {/* Decorative Background Elements */}
            <div className="absolute inset-0 -z-10 overflow-hidden">
              {/* Top gradient line */}
              <div className={`
                absolute inset-x-0 top-0 h-px
                bg-gradient-to-r from-transparent via-slate-500/10 to-transparent
              `} />
              
              {/* Glowing orb */}
              <div className={`
                absolute left-1/2 -top-8 -translate-x-1/2
                w-[600px] h-[200px] 
                bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-pink-500/20
                blur-3xl opacity-50
                animate-pulse
              `} />
            </div>

            <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="relative text-center py-16"
              >
                {title && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="relative mb-4"
                  >
                    <h1 className={`
                      relative
                      text-5xl font-bold tracking-tight
                      bg-clip-text text-transparent
                      bg-gradient-to-b
                      ${isDarkMode 
                        ? 'from-white via-white/90 to-slate-400/80' 
                        : 'from-slate-900 via-slate-800 to-slate-700'
                      }
                    `}>
                      {title}
                    </h1>
                  </motion.div>
                )}
                
                {description && (
                  <motion.p
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                    className={`
                      relative
                      text-lg max-w-2xl mx-auto
                      ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                    `}
                  >
                    {description}
                  </motion.p>
                )}

                {/* Decorative line */}
                <div className={`
                  absolute bottom-0 left-1/2 -translate-x-1/2
                  w-48 h-px
                  bg-gradient-to-r from-transparent via-slate-500/10 to-transparent
                `} />
              </motion.div>
            </div>
          </div>
        )}

        {/* Page Content */}
        <div className={`
          relative
          ${isDarkMode ? 'bg-[#0A0A0B]' : 'bg-white'}
        `}>
          {children}
        </div>

        <Footer />
      </main>
    </div>
  );
};

export default PublicPageLayout; 