import React from 'react';
import { motion } from 'framer-motion';

const CreateButton = ({ 
  isDarkMode, 
  isSidebarCollapsed, 
  onClick, 
  icon: Icon, 
  text = "Content Studio", 
  className = ""
}) => {
  return (
    <motion.button
      onClick={onClick}
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.99 }}
      className={`
        inline-flex items-center justify-center
        ${isSidebarCollapsed ? 'w-8 h-8 p-1.5' : 'w-full h-9 px-3'} 
        rounded-md
        ${isDarkMode 
          ? 'bg-zinc-800/80 text-zinc-200 hover:bg-zinc-700/90 ring-1 ring-zinc-700/60' 
          : 'bg-white text-zinc-700 hover:bg-zinc-50 ring-1 ring-zinc-200'
        }
        transition-colors duration-300
        focus-visible:outline-none focus-visible:ring-2
        focus-visible:ring-zinc-500/50
        ${className}
      `}
      aria-label={text}
      title={isSidebarCollapsed ? text : undefined}
    >
      <div className="flex items-center justify-center gap-2">
        <Icon className={`
          ${isSidebarCollapsed ? 'w-4 h-4' : 'w-[18px] h-[18px]'}
          flex-shrink-0
          ${isDarkMode ? 'text-zinc-200' : 'text-zinc-700'}
        `} />
        
        {!isSidebarCollapsed && (
          <span className="text-[13px] font-medium tracking-tight">
            {text}
          </span>
        )}
      </div>
    </motion.button>
  );
};

export default CreateButton;
