import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../utils/cn';

const SkeletonLoader = ({ 
  className, 
  isDarkMode, 
  variant = 'default',
  count = 1,
  showIcon = true,
  iconClassName = 'w-8 h-8'
}) => {
  const renderSkeleton = (index) => {
    switch (variant) {
      case 'persona':
        return (
          <div 
            key={index}
            className={cn(
              "flex items-center gap-4 p-3 rounded-xl",
              isDarkMode ? "bg-[#1E1E1E]" : "bg-white",
              className
            )}
          >
            <div className={cn(
              "rounded-lg flex-shrink-0",
              isDarkMode ? "bg-[#252525]" : "bg-gray-100",
              iconClassName
            )} />
            <div className="flex-1 space-y-2">
              <motion.div 
                className={cn(
                  "h-4 rounded-md w-24",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}
                animate={{ opacity: [0.5, 0.7, 0.5] }}
                transition={{ duration: 1.5, repeat: Infinity }}
              />
              <motion.div 
                className={cn(
                  "h-3 rounded-md w-32",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}
                animate={{ opacity: [0.5, 0.7, 0.5] }}
                transition={{ duration: 1.5, repeat: Infinity, delay: 0.2 }}
              />
            </div>
          </div>
        );

      case 'knowledge':
        return (
          <div 
            key={index}
            className={cn(
              "flex items-start gap-4 p-3 rounded-xl",
              isDarkMode ? "bg-[#1E1E1E]" : "bg-white",
              className
            )}
          >
            {showIcon && (
              <div className={cn(
                "rounded-lg flex-shrink-0",
                isDarkMode ? "bg-[#252525]" : "bg-gray-100",
                iconClassName
              )} />
            )}
            <div className="flex-1 space-y-3">
              <div className="flex items-center gap-2">
                <motion.div 
                  className={cn(
                    "h-4 rounded-md w-32",
                    isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                  )}
                  animate={{ opacity: [0.5, 0.7, 0.5] }}
                  transition={{ duration: 1.5, repeat: Infinity }}
                />
                <motion.div 
                  className={cn(
                    "h-4 rounded-md w-16",
                    isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                  )}
                  animate={{ opacity: [0.5, 0.7, 0.5] }}
                  transition={{ duration: 1.5, repeat: Infinity, delay: 0.2 }}
                />
              </div>
              <motion.div 
                className={cn(
                  "h-3 rounded-md w-full",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}
                animate={{ opacity: [0.5, 0.7, 0.5] }}
                transition={{ duration: 1.5, repeat: Infinity, delay: 0.4 }}
              />
              <motion.div 
                className={cn(
                  "h-3 rounded-md w-3/4",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}
                animate={{ opacity: [0.5, 0.7, 0.5] }}
                transition={{ duration: 1.5, repeat: Infinity, delay: 0.6 }}
              />
            </div>
          </div>
        );

      case 'idea':
        return (
          <div 
            key={index}
            className={cn(
              "p-3 rounded-xl space-y-3",
              isDarkMode ? "bg-[#1E1E1E]" : "bg-white",
              className
            )}
          >
            <div className="flex items-center gap-2">
              <motion.div 
                className={cn(
                  "h-3 rounded-full w-16",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}
                animate={{ opacity: [0.5, 0.7, 0.5] }}
                transition={{ duration: 1.5, repeat: Infinity }}
              />
              <motion.div 
                className={cn(
                  "h-3 rounded-full w-12",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}
                animate={{ opacity: [0.5, 0.7, 0.5] }}
                transition={{ duration: 1.5, repeat: Infinity, delay: 0.2 }}
              />
            </div>
            <motion.div 
              className={cn(
                "h-4 rounded-md w-full",
                isDarkMode ? "bg-[#252525]" : "bg-gray-100"
              )}
              animate={{ opacity: [0.5, 0.7, 0.5] }}
              transition={{ duration: 1.5, repeat: Infinity, delay: 0.4 }}
            />
            <motion.div 
              className={cn(
                "h-4 rounded-md w-5/6",
                isDarkMode ? "bg-[#252525]" : "bg-gray-100"
              )}
              animate={{ opacity: [0.5, 0.7, 0.5] }}
              transition={{ duration: 1.5, repeat: Infinity, delay: 0.6 }}
            />
          </div>
        );

      default:
        return (
          <div 
            key={index}
            className={cn(
              "flex items-center gap-4 p-3 rounded-xl",
              isDarkMode ? "bg-[#1E1E1E]" : "bg-white",
              className
            )}
          >
            {showIcon && (
              <div className={cn(
                "rounded-lg flex-shrink-0",
                isDarkMode ? "bg-[#252525]" : "bg-gray-100",
                iconClassName
              )} />
            )}
            <div className="flex-1 space-y-2">
              <motion.div 
                className={cn(
                  "h-4 rounded-md w-3/4",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}
                animate={{ opacity: [0.5, 0.7, 0.5] }}
                transition={{ duration: 1.5, repeat: Infinity }}
              />
              <motion.div 
                className={cn(
                  "h-3 rounded-md w-full",
                  isDarkMode ? "bg-[#252525]" : "bg-gray-100"
                )}
                animate={{ opacity: [0.5, 0.7, 0.5] }}
                transition={{ duration: 1.5, repeat: Infinity, delay: 0.2 }}
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div className="space-y-2">
      {Array.from({ length: count }).map((_, index) => renderSkeleton(index))}
    </div>
  );
};

export default SkeletonLoader; 