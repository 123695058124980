import React, { useState, useEffect, useRef, useCallback } from 'react';
import { format, addMinutes, parse } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { XMarkIcon, CalendarIcon, ClockIcon, UserIcon, ArrowPathIcon, ChevronLeftIcon, ChevronRightIcon, TrashIcon, PhotoIcon, DocumentIcon, BugAntIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import Portal from '../ui/Portal';
import linkedinScheduleService from '../../services/linkedinScheduleService';
import { isLinkedInConnected } from '../../services/linkedinPublishService';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../context/AuthContext';
import { fetchMediaSignedUrl } from '../../utils/mediaUtils';
import 'react-day-picker/dist/style.css';
import ScheduleCalendarDesign from './ScheduleCalendarDesign';
import ScheduleTimePickerDesign from './ScheduleTimePickerDesign';
import LinkedInPostPreview from './LinkedInPostPreview';

// Custom components for the date picker navigation
const DatePickerNav = ({ 
  onPreviousClick, 
  onNextClick, 
  goToMonth,
  month,
  isDarkMode,
  disabled
}) => {
  return (
    <div className="flex items-center justify-between px-1">
      <button
        onClick={() => onPreviousClick()}
        disabled={disabled}
        className={`
          p-2 rounded-md transition-colors
          ${isDarkMode 
            ? 'hover:bg-zinc-800 disabled:text-zinc-700' 
            : 'hover:bg-zinc-100 disabled:text-zinc-300'
          }
          disabled:cursor-not-allowed
        `}
        aria-label="Previous month"
      >
        <ChevronLeftIcon className="w-4 h-4" />
      </button>
      <div className={`text-sm font-medium ${isDarkMode ? 'text-zinc-200' : 'text-zinc-900'}`}>
        {format(month, 'MMMM yyyy')}
      </div>
      <button
        onClick={() => onNextClick()}
        disabled={disabled}
        className={`
          p-2 rounded-md transition-colors
          ${isDarkMode 
            ? 'hover:bg-zinc-800 disabled:text-zinc-700' 
            : 'hover:bg-zinc-100 disabled:text-zinc-300'
          }
          disabled:cursor-not-allowed
        `}
        aria-label="Next month"
      >
        <ChevronRightIcon className="w-4 h-4" />
      </button>
    </div>
  );
};

const SchedulePostModal = ({ 
  selectedDate = new Date(), 
  onClose, 
  onSchedule,
  onDelete,
  isDarkMode, 
  postData,
  isOpen = false,
  isMobile = false
}) => {
  const { user } = useAuth();
  console.log('🔍 SchedulePostModal Props:', { postData, selectedDate, isOpen });

  // Update handleReconnectLinkedIn function
  const handleReconnectLinkedIn = async () => {
    try {
      // Redirect to the LinkedIn auth page
      window.location.href = '/api/auth/linkedin';
    } catch (error) {
      console.error('Error initiating LinkedIn reconnection:', error);
      toast.error('Failed to initiate LinkedIn reconnection');
    }
  };

  // Determine the modal mode based on postData
  const modalMode = postData 
    ? postData.status === 'published'
      ? 'reschedule'
      : postData.status === 'scheduled'
        ? 'edit'
        : 'schedule'
    : 'schedule';

  // Get initial time 5 minutes from now
  const getInitialTime = () => {
    const futureDate = addMinutes(new Date(), 5);
    return format(futureDate, 'HH:mm');
  };

  const [selectedTime, setSelectedTime] = useState(getInitialTime());
  const [selectedPost, setSelectedPost] = useState(() => {
    if (postData) {
      console.log('🔍 Initializing selectedPost with postData:', postData);
      const processedMediaFiles = (postData.mediaFiles || []).map(file => ({
        ...file,
        cloudinaryUrl: file.cloudinaryUrl || file.url,
        url: file.cloudinaryUrl || file.url,
        _key: `${file.cloudinaryUrl || file.url}-${Date.now()}`
      }));
      
      console.log('🔍 Processed media files during initialization:', processedMediaFiles);
      
      return {
        ...postData,
        content: postData.content || '',
        title: postData.title || 'Untitled Post',
        mediaFiles: processedMediaFiles
      };
    }
    return null;
  });
  const [availablePosts, setAvailablePosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pickedDate, setPickedDate] = useState(format(selectedDate, 'yyyy-MM-dd'));
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkedInProfile, setLinkedInProfile] = useState(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [timeDropdownPosition, setTimeDropdownPosition] = useState({ top: 0, left: 0 });
  const [selectedMedia, setSelectedMedia] = useState(null);

  const dateButtonRef = useRef(null);
  const timeButtonRef = useRef(null);
  const signedUrlsRef = useRef({});

  // Calculate dropdown position for date picker
  const calculateDropdownPosition = useCallback(() => {
    if (!dateButtonRef.current) return { top: 0, left: 0 };

    const buttonRect = dateButtonRef.current.getBoundingClientRect();
    const dropdownWidth = 320;
    const dropdownHeight = 360;
    const padding = 8;
    
    let top = buttonRect.bottom + padding;
    let left = buttonRect.left;

    if (left + dropdownWidth > window.innerWidth) {
      left = window.innerWidth - dropdownWidth - padding;
    }

    if (left < padding) {
      left = padding;
    }

    if (top + dropdownHeight > window.innerHeight) {
      top = buttonRect.top - dropdownHeight - padding;
    }

    return { top, left };
  }, []);

  // Effect to update selectedPost when postData changes
  useEffect(() => {
    if (postData) {
      console.log('🔍 Processing postData in useEffect:', postData);
      const processedMediaFiles = (postData.mediaFiles || []).map(file => {
        console.log('🔍 Processing media file in useEffect:', file);
        return {
          ...file,
          cloudinaryUrl: file.cloudinaryUrl || file.url,
          url: file.cloudinaryUrl || file.url,
          _key: `${file.cloudinaryUrl || file.url}-${Date.now()}`
        };
      });
      
      console.log('🔍 Setting selectedPost with:', {
        ...postData,
        mediaFiles: processedMediaFiles
      });
      
      setSelectedPost({
        ...postData,
        content: postData.content || '',
        title: postData.title || 'Untitled Post',
        mediaFiles: processedMediaFiles
      });
    }
  }, [postData]);

  // Effect to set initial date/time for editing scheduled posts
  useEffect(() => {
    if (modalMode === 'edit' && postData?.scheduledFor) {
      const postDate = new Date(postData.scheduledFor);
      setSelectedTime(format(postDate, 'HH:mm'));
      setPickedDate(format(postDate, 'yyyy-MM-dd'));
    }
  }, [modalMode, postData]);

  // Effect to update date picker position
  useEffect(() => {
    if (showDatePicker) {
      const updatePosition = () => {
        const position = calculateDropdownPosition();
        setDropdownPosition(position);
      };

      updatePosition();
      window.addEventListener('scroll', updatePosition, true);
      window.addEventListener('resize', updatePosition);

      return () => {
        window.removeEventListener('scroll', updatePosition, true);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [showDatePicker, calculateDropdownPosition]);

  // Effect to fetch LinkedIn profile
  useEffect(() => {
    const fetchLinkedInProfile = async () => {
      try {
        setIsLoadingProfile(true);
        const linkedInStatus = await isLinkedInConnected();
        
        if (linkedInStatus.isConnected && linkedInStatus.profile) {
          const profileData = linkedInStatus.profile.profile_data || linkedInStatus.profile;
          
          let fullName;
          if (profileData.name) {
            fullName = profileData.name;
          } else if (profileData.given_name && profileData.family_name) {
            fullName = `${profileData.given_name} ${profileData.family_name}`;
          } else if (linkedInStatus.profile.name) {
            fullName = linkedInStatus.profile.name;
          } else {
            fullName = 'LinkedIn User';
          }
          
          setLinkedInProfile({
            name: fullName,
            avatarUrl: profileData.picture || null
          });
        }
      } catch (error) {
        console.error('Error fetching LinkedIn profile:', error);
      } finally {
        setIsLoadingProfile(false);
      }
    };

    if (isOpen) {
      fetchLinkedInProfile();
    }
  }, [isOpen]);

  // Effect to fetch available posts
  useEffect(() => {
    if (isOpen && !postData) {
      fetchAvailablePosts();
    }
  }, [postData, isOpen]);

  // Effect for mobile body scroll lock
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Debug effect for post data
  useEffect(() => {
    if (postData) {
      console.log('SchedulePostModal received postData:', {
        hasMediaFiles: !!postData.mediaFiles?.length,
        mediaFiles: postData.mediaFiles,
        imageUrl: postData.imageUrl
      });
    }
  }, [postData]);

  const fetchAvailablePosts = async () => {
    try {
      setIsLoading(true);
      setError(null);
      if (!postData) {
        const data = await linkedinScheduleService.getUnscheduledPosts();
        setAvailablePosts(data);
      }
    } catch (error) {
      setError('Failed to load posts. Please try again.');
      console.error('Error fetching available posts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const LoadingSkeleton = () => (
    <div className="space-y-4 animate-pulse">
      <div className={`h-24 rounded-lg ${isDarkMode ? 'bg-[#252525]' : 'bg-zinc-100'}`} />
      <div className={`h-24 rounded-lg ${isDarkMode ? 'bg-[#252525]' : 'bg-zinc-100'}`} />
    </div>
  );

  const handleSchedule = async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      // Debug log the current state
      console.log('Current state before scheduling:', {
        postData,
        selectedPost,
        pickedDate,
        selectedTime
      });

      // 1. Validate LinkedIn connection with more detailed check
      const linkedInStatus = await isLinkedInConnected();
      console.log('🔍 LinkedIn connection status:', linkedInStatus);
      
      if (!linkedInStatus.isConnected || linkedInStatus.needsReconnect) {
        const message = linkedInStatus.error || 
          (linkedInStatus.needsReconnect 
            ? 'Your LinkedIn authentication has expired. Please reconnect your account.' 
            : 'Please connect your LinkedIn account to schedule posts');
        
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>{message}</p>
            <button
              onClick={handleReconnectLinkedIn}
              className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {linkedInStatus.needsReconnect ? 'Reconnect LinkedIn' : 'Connect LinkedIn'}
            </button>
          </div>,
          { duration: 8000 }
        );
        throw new Error(message);
      }

      // 2. Validate scheduling time
      const scheduledDateTime = new Date(pickedDate);
      const [hours, minutes] = selectedTime.split(':');
      scheduledDateTime.setHours(parseInt(hours), parseInt(minutes));

      if (scheduledDateTime <= new Date()) {
        throw new Error('Please select a future date and time');
      }

      // 3. Validate post content
      const post = selectedPost || postData;
      if (!post) {
        throw new Error('No post data available');
      }

      // Validate user context
      if (!user?._id) {
        console.error('User context missing');
        throw new Error('User authentication required. Please try logging in again.');
      }

      // Log the post object for debugging
      console.log('Post object for scheduling:', post);

      // Check if content exists and is not just whitespace
      if (!post.content) {
        console.error('Content is missing from post:', post);
        throw new Error('Post content is missing. Please try again.');
      }

      const postContent = post.content.toString().trim();
      if (!postContent) {
        throw new Error('Post content cannot be empty. Please add some content to your post.');
      }

      if (postContent.length > 3000) {
        throw new Error('Post content exceeds LinkedIn\'s 3000 character limit');
      }

      // Check if this post has been scheduled before
      if (post.status === 'published') {
        toast.success('Creating a new schedule for a previously published post', {
          duration: 4000,
          icon: '📅'
        });
      } else if (post.status === 'scheduled') {
        toast.success('Creating another schedule for this post', {
          duration: 4000,
          icon: '📅'
        });
      }

      // Process media files using Cloudinary URLs
      const mediaFiles = post.mediaFiles?.map(file => {
        console.log('🔍 Processing media file for preview:', {
          original: file,
          cloudinaryUrl: file.cloudinaryUrl,
          url: file.url,
          mimeType: file.mimeType || file.type,
          originalName: file.originalName || file.name
        });

        // Prioritize cloudinaryUrl, then fall back to url
        const mediaUrl = file.cloudinaryUrl || file.url;
        if (!mediaUrl) {
          console.warn('⚠️ No URL found for media file:', file);
        }

        const mimeType = file.mimeType || file.type;
        const isDocument = mimeType === 'application/pdf';

        const processedFile = {
          ...file,
          cloudinaryUrl: mediaUrl,
          url: mediaUrl,
          originalName: file.originalName || file.name,
          mimeType: mimeType,
          size: file.size || 0,
          metadata: {
            ...file.metadata,
            resource_type: isDocument ? 'document' : (mimeType?.startsWith('video/') ? 'video' : 'image'),
            format: file.metadata?.format || (mimeType || '').split('/')[1] || '',
          },
          _key: `${mediaUrl}-${Date.now()}`
        };

        console.log('🔍 Processed media file:', processedFile);
        return processedFile;
      }) || [];

      console.log('🔍 Final processed mediaFiles:', mediaFiles);

      // Schedule the post as a new entry
      const postPayload = {
        userId: user._id,
        canvasId: post.canvasId || post._id,
        content: postContent,
        title: post.title?.trim() || 'Untitled Post',
        scheduledFor: scheduledDateTime.toISOString(),
        status: 'scheduled',
        mediaFiles,
        imageUrl: mediaFiles[0]?.cloudinaryUrl || mediaFiles[0]?.url,
        publishHistory: post.publishHistory || {
          originalContent: null,
          publishedContent: null,
          lastPublishedAt: null
        },
        editState: post.editState || {
          isEditedLocally: false,
          lastEditedAt: null,
          originalPublishedContent: null
        }
      };

      console.log('Sending schedule payload:', postPayload);
      
      try {
        const scheduledPost = await linkedinScheduleService.schedulePost(postPayload);
        console.log('Received scheduled post:', scheduledPost);

        // Show success toast
        toast.success(`Post scheduled successfully for ${format(scheduledDateTime, 'MMM d, yyyy h:mm a')}`);
        
        // Close the modal
        onClose();
        
        // Call onSchedule with the scheduled post
        if (onSchedule) {
          onSchedule(scheduledPost);
        }
      } catch (scheduleError) {
        console.error('Error from schedule service:', scheduleError);
        
        // Check if it's a server error
        if (scheduleError.response?.status === 500) {
          // Check if it's a LinkedIn token error
          if (scheduleError.response?.data?.error?.includes('token') || 
              scheduleError.response?.data?.error?.includes('auth') ||
              scheduleError.response?.data?.error?.includes('linkedin')) {
            toast.error(
              <div className="flex flex-col space-y-2">
                <p>LinkedIn authentication has expired</p>
                <button
                  onClick={handleReconnectLinkedIn}
                  className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Reconnect LinkedIn
                </button>
              </div>,
              { duration: 8000 }
            );
          } else {
            // Generic server error
            toast.error('Server error. Please try again later.');
          }
        } else {
          // Other errors
          toast.error(scheduleError.message || 'Failed to schedule post');
        }
        throw scheduleError;
      }
      
    } catch (error) {
      console.error('Error scheduling post:', error);
      
      // Handle specific error cases
      if (error.response?.status === 500) {
        // Server error already handled above
        setError('Failed to schedule post. Please try again.');
      } else if (error.message?.includes('401') || 
                 error.message?.includes('authentication') || 
                 error.message?.includes('token')) {
        // Authentication error - show reconnect button
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>Your LinkedIn connection needs to be refreshed</p>
            <button
              onClick={handleReconnectLinkedIn}
              className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              Reconnect LinkedIn
            </button>
          </div>,
          { duration: 8000 }
        );
        setError('LinkedIn authentication required');
      } else {
        // Generic error handling
        const errorMessage = error.message || 'Failed to schedule post';
        setError(errorMessage);
        toast.error(errorMessage);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    if (modalMode !== 'edit') return;
    
    try {
      setIsSubmitting(true);
      await linkedinScheduleService.deleteScheduledPost(postData._id);
      if (onDelete) {
        onDelete(postData._id);
      }
      onClose();
    } catch (error) {
      console.error('Error deleting post:', error);
      toast.error('Failed to delete post');
    } finally {
      setIsSubmitting(false);
    }
  };

  const today = format(new Date(), 'yyyy-MM-dd');

  // Custom styles for the date picker
  const datePickerClassNames = {
    months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
    month: 'space-y-4',
    caption: 'flex justify-center relative items-center',
    caption_label: 'text-sm font-medium',
    nav: 'space-x-1 flex items-center',
    nav_button: `
      h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 transition-opacity
      ${isDarkMode ? 'text-zinc-100' : 'text-zinc-900'}
    `,
    nav_button_previous: 'absolute left-1',
    nav_button_next: 'absolute right-1',
    table: 'w-full border-collapse space-y-1',
    head_row: 'flex',
    head_cell: `
      text-zinc-500 rounded-md w-9 font-normal text-[0.8rem]
      ${isDarkMode ? 'text-zinc-400' : 'text-zinc-600'}
    `,
    row: 'flex w-full mt-2',
    cell: `
      text-center text-sm relative p-0 
      ${isDarkMode ? 'text-zinc-100' : 'text-zinc-900'}
      [&:has([aria-selected].day-range-end)]:rounded-r-md
      [&:has([aria-selected].day-outside)]:bg-transparent
      [&:has([aria-selected])]:bg-transparent
      first:[&:has([aria-selected])]:rounded-l-md 
      last:[&:has([aria-selected])]:rounded-r-md
      focus-within:relative
    `,
    day: `
      h-9 w-9 p-0 font-normal
      hover:bg-zinc-100 dark:hover:bg-zinc-800
      aria-selected:opacity-100
      rounded-md
      transition-colors
      ${isDarkMode ? 'text-zinc-100' : 'text-zinc-900'}
    `,
    day_range_end: 'day-range-end',
    day_selected: `
      bg-blue-500 text-white hover:bg-blue-600
      dark:bg-blue-600 dark:text-white dark:hover:bg-blue-700
      focus:bg-blue-500 focus:text-white
      dark:focus:bg-blue-600 dark:focus:text-white
    `,
    day_today: `
      bg-zinc-100 text-zinc-900
      dark:bg-zinc-800 dark:text-zinc-100
    `,
    day_outside: `
      day-outside
      opacity-50
      aria-selected:bg-zinc-100/50
      aria-selected:text-zinc-500
      aria-selected:opacity-30
      dark:aria-selected:bg-zinc-800/50
      dark:aria-selected:text-zinc-400
    `,
    day_disabled: 'opacity-50 cursor-not-allowed',
    day_hidden: 'invisible',
  };

  // Add function to calculate time dropdown position
  const calculateTimeDropdownPosition = useCallback(() => {
    if (!timeButtonRef.current) return { top: 0, left: 0 };

    const buttonRect = timeButtonRef.current.getBoundingClientRect();
    const dropdownWidth = 200; // Width of our time picker
    const dropdownHeight = 280; // Approximate height of time picker
    const padding = 8;
    
    let top = buttonRect.bottom + padding;
    let left = buttonRect.left;

    if (left + dropdownWidth > window.innerWidth) {
      left = window.innerWidth - dropdownWidth - padding;
    }

    if (left < padding) {
      left = padding;
    }

    if (top + dropdownHeight > window.innerHeight) {
      top = buttonRect.top - dropdownHeight - padding;
    }

    return { top, left };
  }, []);

  // Update time picker position on scroll/resize
  useEffect(() => {
    if (showTimePicker) {
      const updatePosition = () => {
        const position = calculateTimeDropdownPosition();
        setTimeDropdownPosition(position);
      };

      updatePosition();
      window.addEventListener('scroll', updatePosition, true);
      window.addEventListener('resize', updatePosition);

      return () => {
        window.removeEventListener('scroll', updatePosition, true);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [showTimePicker, calculateTimeDropdownPosition]);

  // Update date input section
  const renderDateInput = () => (
    <div className="space-y-1">
      <label className={`
        text-sm font-normal tracking-tight
        ${isDarkMode ? 'text-zinc-400' : 'text-zinc-500'}
      `}>
        Schedule Date
      </label>
      <div
        ref={dateButtonRef}
        onClick={() => {
          setShowDatePicker(true);
          setTimeout(() => {
            const position = calculateDropdownPosition();
            setDropdownPosition(position);
          }, 0);
        }}
        className={`
          group
          relative
          flex
          h-9
          w-full
          items-center
          rounded-md
          border
          bg-transparent
          px-3
          text-sm
          shadow-sm
          ring-offset-white
          transition-colors
          cursor-pointer
          ${isDarkMode 
            ? 'hover:bg-white/[0.08] border-[#333333] text-zinc-100' 
            : 'hover:bg-black/[0.04] border-zinc-200 text-zinc-900'
          }
          dark:ring-offset-[#1a1a1a]
          ${showDatePicker && (isDarkMode 
            ? 'border-blue-600 ring-1 ring-blue-600' 
            : 'border-blue-600 ring-1 ring-blue-600'
          )}
        `}
      >
        <CalendarIcon 
          className={`
            mr-2 h-4 w-4 shrink-0
            ${isDarkMode 
              ? 'text-zinc-400 group-hover:text-zinc-100' 
              : 'text-zinc-500 group-hover:text-zinc-900'
            }
            ${showDatePicker && 'text-blue-600'}
          `}
        />
        <span className={`
          flex-1
          ${isDarkMode 
            ? 'text-zinc-100' 
            : 'text-zinc-900'
          }
        `}>
          {format(parse(pickedDate, 'yyyy-MM-dd', new Date()), 'MMMM d, yyyy')}
        </span>
        <div className={`
          ml-auto pl-3
          ${isDarkMode 
            ? 'text-zinc-400 group-hover:text-zinc-100' 
            : 'text-zinc-500 group-hover:text-zinc-900'
          }
          ${showDatePicker && 'text-blue-600'}
        `}>
          <ChevronRightIcon className={`
            h-4 w-4 rotate-90 transition-transform
            ${showDatePicker && '-rotate-90'}
          `} />
        </div>
      </div>

      {/* Date Picker Popover */}
      {showDatePicker && (
        <Portal>
          <div 
            className={`
              fixed inset-0 z-[1000]
              ${isDarkMode 
                ? 'bg-[#000000]/10' 
                : 'bg-[#ffffff]/30'
              }
            `}
            onClick={() => setShowDatePicker(false)}
          >
            <div
              className={`
                fixed
                shadow-lg
                border
                rounded-lg
                ${isDarkMode 
                  ? 'bg-[#1a1a1a] border-[#333333]' 
                  : 'bg-white border-zinc-200'
                }
              `}
              style={{
                top: `${dropdownPosition.top}px`,
                left: `${dropdownPosition.left}px`,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <ScheduleCalendarDesign
                month={calendarDate.getMonth()}
                year={calendarDate.getFullYear()}
                selectedDate={parse(pickedDate, 'yyyy-MM-dd', new Date())}
                onDateSelect={(date) => {
                  setPickedDate(format(date, 'yyyy-MM-dd'));
                  setShowDatePicker(false);
                }}
                onPrevMonth={() => {
                  setCalendarDate(new Date(calendarDate.getFullYear(), calendarDate.getMonth() - 1));
                }}
                onNextMonth={() => {
                  setCalendarDate(new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1));
                }}
                isDarkMode={isDarkMode}
                disablePastDates={true}
              />
            </div>
          </div>
        </Portal>
      )}
    </div>
  );

  if (!isOpen) return null;

  const renderPostPreview = (post) => {
    console.log('🔍 renderPostPreview called with:', post);
    
    if (!post) {
      console.log('❌ No post data available for preview');
      return null;
    }

    // Log the media files
    console.log('🔍 Media files in post:', post.mediaFiles);
    
    // Get the image URL with detailed logging
    const imageUrl = post.mediaFiles?.[0]?.cloudinaryUrl || post.mediaFiles?.[0]?.url || post.imageUrl;
    console.log('🔍 Determined imageUrl:', imageUrl);

    // Process media files using Cloudinary URLs
    const mediaFiles = post.mediaFiles?.map(file => {
      console.log('🔍 Processing media file for preview:', {
        original: file,
        cloudinaryUrl: file.cloudinaryUrl,
        url: file.url,
        mimeType: file.mimeType || file.type,
        originalName: file.originalName || file.name
      });

      // Prioritize cloudinaryUrl, then fall back to url
      const mediaUrl = file.cloudinaryUrl || file.url;
      if (!mediaUrl) {
        console.warn('⚠️ No URL found for media file:', file);
      }

      const mimeType = file.mimeType || file.type;
      const isDocument = mimeType === 'application/pdf';

      const processedFile = {
        ...file,
        cloudinaryUrl: mediaUrl,
        url: mediaUrl,
        originalName: file.originalName || file.name,
        mimeType: mimeType,
        size: file.size || 0,
        metadata: {
          ...file.metadata,
          resource_type: isDocument ? 'document' : (mimeType?.startsWith('video/') ? 'video' : 'image'),
          format: file.metadata?.format || (mimeType || '').split('/')[1] || '',
        },
        _key: `${mediaUrl}-${Date.now()}`
      };

      console.log('🔍 Processed media file:', processedFile);
      return processedFile;
    }) || [];

    console.log('🔍 Final processed mediaFiles:', mediaFiles);

    // Create preview post object with the required fields
    const previewPost = {
      id: post.id || post._id,
      content: post.content || '',
      scheduledFor: new Date().toISOString(),
      status: modalMode === 'reschedule' ? 'published' : (modalMode === 'edit' ? 'scheduled' : 'scheduled'),
      mediaFiles: mediaFiles,
      canvasId: {
        title: post.title || post.canvasId?.title || 'Untitled Post'
      }
    };

    console.log('🔍 Created previewPost:', previewPost);
    
    return (
      <div className="w-full">
        <LinkedInPostPreview
          post={previewPost}
          isDarkMode={isDarkMode}
          viewType="day"
          isCompact={false}
          className="h-auto"
          onEdit={() => {}} // Empty function to prevent errors
          onDelete={() => {}} // Empty function to prevent errors
        />
      </div>
    );
  };

  // Update the base button styles to be more compact
  const buttonBaseStyles = `
    inline-flex items-center justify-center gap-1.5
    rounded-md text-xs font-medium
    ring-offset-white transition-colors
    focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-zinc-950 focus-visible:ring-offset-2
    disabled:pointer-events-none disabled:opacity-50
    h-7 px-2.5
    dark:ring-offset-zinc-950 dark:focus-visible:ring-zinc-300
  `;

  const buttonVariants = {
    primary: (isDarkMode) => `
      ${buttonBaseStyles}
      ${isDarkMode
        ? 'bg-zinc-50 text-zinc-900 hover:bg-zinc-50/90 dark:bg-zinc-50 dark:text-zinc-900 dark:hover:bg-zinc-50/90'
        : 'bg-zinc-900 text-zinc-50 hover:bg-zinc-900/90'
      }
    `,
    danger: (isDarkMode) => `
      ${buttonBaseStyles}
      ${isDarkMode
        ? 'bg-red-500 text-zinc-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-red-50 dark:hover:bg-red-900/90'
        : 'bg-red-500 text-zinc-50 hover:bg-red-500/90'
      }
    `,
    outline: (isDarkMode) => `
      ${buttonBaseStyles}
      border border-zinc-200
      ${isDarkMode
        ? 'border-zinc-800 bg-transparent hover:bg-zinc-800 hover:text-zinc-50 dark:border-zinc-800 dark:hover:bg-zinc-800 dark:hover:text-zinc-50'
        : 'bg-transparent hover:bg-zinc-100 hover:text-zinc-900'
      }
    `,
    ghost: (isDarkMode) => `
      ${buttonBaseStyles}
      ${isDarkMode
        ? 'hover:bg-zinc-800 hover:text-zinc-50 dark:hover:bg-zinc-800 dark:hover:text-zinc-50'
        : 'hover:bg-zinc-100 hover:text-zinc-900'
      }
    `
  };

  // Add these header style utilities after the buttonVariants
  const headerVariants = {
    default: (isDarkMode) => `
      border-b
      ${isDarkMode 
        ? 'border-zinc-800 bg-zinc-900/90' 
        : 'border-zinc-100 bg-white/90'
      }
    `,
    edit: (isDarkMode) => `
      border-b
      ${isDarkMode 
        ? 'border-blue-900/20 bg-blue-900/10' 
        : 'border-blue-100 bg-blue-50/80'
      }
    `,
    reschedule: (isDarkMode) => `
      border-b
      ${isDarkMode 
        ? 'border-emerald-900/20 bg-emerald-900/10' 
        : 'border-emerald-100 bg-emerald-50/80'
      }
    `
  };

  const badgeVariants = {
    success: (isDarkMode) => `
      inline-flex items-center rounded-full px-1.5 py-0.5 text-[10px] font-medium transition-colors
      ${isDarkMode
        ? 'bg-emerald-500/10 text-emerald-400'
        : 'bg-emerald-50 text-emerald-700'
      }
    `,
    edit: (isDarkMode) => `
      inline-flex items-center rounded-full px-1.5 py-0.5 text-[10px] font-medium transition-colors
      ${isDarkMode
        ? 'bg-blue-500/10 text-blue-400'
        : 'bg-blue-50 text-blue-700'
      }
    `
  };

  return (
    <Portal>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`
            fixed inset-0 z-[100] 
            flex items-end sm:items-center justify-center 
            ${isDarkMode 
              ? 'bg-[#000000]/80'
              : 'bg-[#ffffff]/80'
            } 
            p-0 sm:p-4 
            cursor-pointer
            backdrop-blur-sm
          `}
          onClick={(e) => {
            if (e.target === e.currentTarget) onClose();
          }}
        >
          <motion.div
            initial={{ 
              opacity: 0,
              y: 100,
              scale: 1
            }}
            animate={{ 
              opacity: 1,
              y: 0,
              scale: 1
            }}
            exit={{ 
              opacity: 0,
              y: 100,
              scale: 1
            }}
            transition={{ 
              type: "spring",
              damping: 25,
              stiffness: 350
            }}
            className={`
              w-full
              sm:w-full
              sm:max-w-xl
              rounded-t-2xl
              sm:rounded-lg
              max-h-[90vh]
              sm:max-h-[85vh]
              flex
              flex-col
              cursor-default
              ${isDarkMode 
                ? 'bg-[#1a1a1a] border-t border-[#333333] sm:border' 
                : 'bg-white border-t border-zinc-200 sm:border'
              }
              ${isMobile ? 'pb-[calc(76px+env(safe-area-inset-bottom,0px))]' : ''}
            `}
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
          >
            {/* Header with mode-specific styling */}
            <div className={`
              flex items-center justify-between 
              ${isMobile ? 'px-3 py-2.5 rounded-t-2xl' : 'p-2.5 sm:p-3 sm:rounded-t-lg'}
              ${modalMode === 'reschedule'
                ? headerVariants.reschedule(isDarkMode)
                : modalMode === 'edit'
                  ? headerVariants.edit(isDarkMode)
                  : headerVariants.default(isDarkMode)
              }
            `}>
              <div className="flex items-center gap-2">
                <h2 className={`
                  text-[13px] font-semibold tracking-tight leading-none
                  ${isDarkMode ? 'text-zinc-100' : 'text-zinc-900'}
                `}>
                  {modalMode === 'reschedule' 
                    ? 'Reschedule Post'
                    : modalMode === 'edit'
                      ? 'Edit Schedule'
                      : 'Schedule Post'
                  }
                </h2>
                {modalMode === 'reschedule' && (
                  <span className={`
                    ${badgeVariants.success(isDarkMode)}
                    translate-y-[0.5px]
                  `}>
                    Published
                  </span>
                )}
                {modalMode === 'edit' && (
                  <span className={`
                    ${badgeVariants.edit(isDarkMode)}
                    translate-y-[0.5px]
                  `}>
                    Scheduled
                  </span>
                )}
              </div>

              <button
                onClick={onClose}
                className={`
                  -mr-0.5 p-1 opacity-70 ring-offset-white
                  transition-opacity hover:opacity-100
                  rounded-md
                  focus:outline-none focus:ring-2 focus:ring-zinc-950 focus:ring-offset-2
                  disabled:pointer-events-none
                  ${isDarkMode 
                    ? 'text-zinc-300 hover:text-zinc-50 focus:ring-zinc-300'
                    : 'text-zinc-950 hover:text-zinc-900'
                  }
                `}
                title="Close"
              >
                <XMarkIcon className="h-3.5 w-3.5" />
              </button>
            </div>

            {/* Content */}
            <div className={`
              flex-1 overflow-y-auto overscroll-contain 
              ${isMobile ? 'px-3 py-3' : 'p-3 sm:p-4'}
              space-y-3
            `}>
              {error && (
                <div className={`
                  p-3 rounded-md text-sm
                  ${isDarkMode 
                    ? 'bg-red-900/20 text-red-200 border border-red-800' 
                    : 'bg-red-50 text-red-600 border border-red-200'
                  }
                `}>
                  {error}
                </div>
              )}

              {/* Combined Info Banner */}
              {(modalMode === 'edit' || modalMode === 'reschedule') && (
                <div className={`
                  flex items-center gap-2 px-2 py-1.5 rounded-md text-xs
                  ${isDarkMode 
                    ? 'bg-blue-900/10 text-blue-200' 
                    : 'bg-blue-50 text-blue-600'
                  }
                `}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3.5 h-3.5 shrink-0">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clipRule="evenodd" />
                  </svg>
                  <div className="flex-1">
                    {modalMode === 'reschedule' && (
                      <span>Published on {format(new Date(postData.publishedAt), 'MMM d')} at {format(new Date(postData.publishedAt), 'h:mm a')}. </span>
                    )}
                    <span>Changes will create a new scheduled post</span>
                  </div>
                </div>
              )}

              {/* Existing date & time selection */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {renderDateInput()}
                <div className="space-y-1">
                  <label className={`
                    text-sm font-normal tracking-tight
                    ${isDarkMode ? 'text-zinc-400' : 'text-zinc-500'}
                  `}>
                    Schedule Time
                  </label>
                  <div
                    ref={timeButtonRef}
                    onClick={() => {
                      setShowTimePicker(true);
                      setTimeout(() => {
                        const position = calculateTimeDropdownPosition();
                        setTimeDropdownPosition(position);
                      }, 0);
                    }}
                    className={`
                      group
                      relative
                      flex
                      h-9
                      w-full
                      items-center
                      rounded-md
                      border
                      bg-transparent
                      px-3
                      text-sm
                      shadow-sm
                      ring-offset-white
                      transition-colors
                      cursor-pointer
                      ${isDarkMode 
                        ? 'hover:bg-white/[0.08] border-[#333333] text-zinc-100' 
                        : 'hover:bg-black/[0.04] border-zinc-200 text-zinc-900'
                      }
                      dark:ring-offset-[#1a1a1a]
                      ${showTimePicker && (isDarkMode 
                        ? 'border-blue-600 ring-1 ring-blue-600' 
                        : 'border-blue-600 ring-1 ring-blue-600'
                      )}
                    `}
                  >
                    <ClockIcon 
                      className={`
                        mr-2 h-4 w-4 shrink-0
                        ${isDarkMode 
                          ? 'text-zinc-400 group-hover:text-zinc-100' 
                          : 'text-zinc-500 group-hover:text-zinc-900'
                        }
                        ${showTimePicker && 'text-blue-600'}
                      `}
                    />
                    <span className={`
                      flex-1
                      ${isDarkMode 
                        ? 'text-zinc-100' 
                        : 'text-zinc-900'
                      }
                    `}>
                      {format(parse(selectedTime, 'HH:mm', new Date()), 'h:mm a')}
                    </span>
                    <div className={`
                      ml-auto pl-3
                      ${isDarkMode 
                        ? 'text-zinc-400 group-hover:text-zinc-100' 
                        : 'text-zinc-500 group-hover:text-zinc-900'
                      }
                      ${showTimePicker && 'text-blue-600'}
                    `}>
                      <ChevronRightIcon className={`
                        h-4 w-4 rotate-90 transition-transform
                        ${showTimePicker && '-rotate-90'}
                      `} />
                    </div>
                  </div>

                  {/* Time Picker Popover */}
                  {showTimePicker && (
                    <Portal>
                      <div 
                        className={`
                          fixed inset-0 z-[1000]
                          ${isDarkMode 
                            ? 'bg-[#000000]/10' 
                            : 'bg-[#ffffff]/30'
                          }
                        `}
                        onClick={(e) => {
                          if (e.target === e.currentTarget) {
                            setShowTimePicker(false);
                          }
                        }}
                        onMouseDown={(e) => {
                          if (e.target !== e.currentTarget) {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        }}
                      >
                        <div
                          className={`
                            fixed
                            shadow-lg
                            border
                            rounded-lg
                            ${isDarkMode 
                              ? 'bg-[#1a1a1a] border-[#333333]' 
                              : 'bg-white border-zinc-200'
                            }
                          `}
                          style={{
                            top: `${timeDropdownPosition.top}px`,
                            left: `${timeDropdownPosition.left}px`,
                          }}
                          onClick={(e) => e.stopPropagation()}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          <ScheduleTimePickerDesign
                            selectedTime={selectedTime}
                            onTimeSelect={(time) => {
                              setSelectedTime(time);
                            }}
                            isDarkMode={isDarkMode}
                            step={15}
                            onSetTime={(time) => {
                              setSelectedTime(time);
                              setShowTimePicker(false);
                            }}
                            onClose={() => setShowTimePicker(false)}
                          />
                        </div>
                      </div>
                    </Portal>
                  )}
                </div>
              </div>

              {/* Post Preview */}
              <div className="space-y-1">
                <label className={`
                  text-sm font-normal tracking-tight
                  ${isDarkMode ? 'text-zinc-400' : 'text-zinc-500'}
                `}>
                  Post Preview
                </label>
                <div className={`
                  -mx-3 sm:-mx-4
                  ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'}
                `}>
                  {isLoading ? (
                    <LoadingSkeleton />
                  ) : error ? (
                    <div className="h-full flex items-center justify-center p-4">
                      <button 
                        onClick={fetchAvailablePosts}
                        className="text-blue-500 hover:text-blue-600 cursor-pointer hover:underline"
                      >
                        Retry Loading Posts
                      </button>
                    </div>
                  ) : (
                    <div className="px-3 py-0 sm:px-4 sm:py-1">
                      {postData && renderPostPreview(postData)}
                      {!postData && selectedPost && renderPostPreview(selectedPost)}
                      {!postData && !selectedPost && (
                        <div className={`
                          h-32 flex items-center justify-center
                          ${isDarkMode ? 'text-zinc-400' : 'text-zinc-500'}
                        `}>
                          No post selected
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Footer with mode-specific actions */}
            <div className={`
              flex items-center justify-end gap-1.5 
              ${isMobile 
                ? 'px-3 py-2.5 fixed bottom-0 left-0 right-0 bg-inherit' 
                : 'p-2.5 sm:p-3 sm:rounded-b-lg'
              }
              border-t
              ${isDarkMode ? 'border-zinc-800 bg-zinc-900' : 'border-zinc-200 bg-white'}
            `}>
              {/* Show Delete button in edit mode */}
              {modalMode === 'edit' && (
                <button
                  onClick={handleDelete}
                  disabled={isSubmitting}
                  className={`
                    ${buttonVariants.ghost(isDarkMode)}
                    ${isDarkMode ? 'text-red-400' : 'text-red-500'}
                  `}
                >
                  <TrashIcon className="w-3.5 h-3.5" />
                  {!isMobile && <span>Delete</span>}
                </button>
              )}
              
              {/* Primary Action Button */}
              <button
                onClick={handleSchedule}
                disabled={isSubmitting}
                className={`
                  ${modalMode === 'reschedule' 
                    ? buttonVariants.outline(isDarkMode)
                    : buttonVariants.primary(isDarkMode)
                  }
                `}
              >
                {isSubmitting ? (
                  <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    className={`
                      w-3 h-3 border-1.5 rounded-full
                      ${isDarkMode 
                        ? 'border-zinc-800 border-t-zinc-400'
                        : 'border-zinc-300 border-t-zinc-800'
                      }
                    `}
                  />
                ) : modalMode === 'reschedule' ? (
                  <>
                    <ArrowPathIcon className="w-3.5 h-3.5" />
                    <span>{isMobile ? 'Reschedule' : 'Reschedule Post'}</span>
                  </>
                ) : modalMode === 'edit' ? (
                  'Save Changes'
                ) : (
                  'Schedule Post'
                )}
              </button>
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </Portal>
  );
};

export default SchedulePostModal; 