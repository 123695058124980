import React, { useState } from 'react';
import { XMarkIcon, PencilIcon, TrashIcon, CheckIcon } from '@heroicons/react/24/outline';

const IdeasTagManager = ({ tags, onClose, onEditTag, onDeleteTag, isDarkMode }) => {
  const [editingTag, setEditingTag] = useState(null);
  const [newTagName, setNewTagName] = useState('');

  const handleEditClick = (tag) => {
    setEditingTag(tag);
    setNewTagName(tag);
  };

  const handleSaveEdit = () => {
    if (newTagName && newTagName !== editingTag) {
      onEditTag(editingTag, newTagName);
    }
    setEditingTag(null);
    setNewTagName('');
  };

  const handleDeleteClick = (tag) => {
    if (window.confirm(`Are you sure you want to delete the tag "${tag}"?`)) {
      onDeleteTag(tag);
    }
  };

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${isDarkMode ? 'bg-black bg-opacity-50' : 'bg-gray-600 bg-opacity-50'}`}>
      <div className={`relative w-full max-w-md p-6 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-xl`}>
        <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Manage Tags</h2>
        <button
          onClick={onClose}
          className={`absolute top-2 right-2 p-1 rounded-full ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
        <div className="space-y-2">
          {tags.map((tag) => (
            <div key={tag} className="flex items-center justify-between">
              {editingTag === tag ? (
                <input
                  type="text"
                  value={newTagName}
                  onChange={(e) => setNewTagName(e.target.value)}
                  className={`flex-grow px-2 py-1 mr-2 rounded ${
                    isDarkMode ? 'bg-gray-700 text-gray-200' : 'bg-gray-100 text-gray-800'
                  }`}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handleSaveEdit();
                  }}
                />
              ) : (
                <span className={`flex-grow ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{tag}</span>
              )}
              <div className="flex space-x-2">
                {editingTag === tag ? (
                  <button
                    onClick={handleSaveEdit}
                    className={`p-1 rounded ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
                  >
                    <CheckIcon className="w-4 h-4" />
                  </button>
                ) : (
                  <button
                    onClick={() => handleEditClick(tag)}
                    className={`p-1 rounded ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
                  >
                    <PencilIcon className="w-4 h-4" />
                  </button>
                )}
                <button
                  onClick={() => handleDeleteClick(tag)}
                  className={`p-1 rounded ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
                >
                  <TrashIcon className="w-4 h-4" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IdeasTagManager;
