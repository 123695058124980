import api from '../api';

const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
const LINKEDIN_REDIRECT_URI = process.env.NODE_ENV === 'production'
  ? 'https://ammmplify.com/auth/linkedin/callback'
  : 'http://localhost:3000/auth/linkedin/callback';
const LINKEDIN_SCOPE = 'openid profile w_member_social email';
const LINKEDIN_STATE_KEY = 'linkedin_auth_state';
const LINKEDIN_SOURCE_KEY = 'linkedin_auth_source';

// Export helper functions
export const getState = () => {
  try {
    console.log('🔵 Getting state from storage');
    const data = localStorage.getItem(LINKEDIN_STATE_KEY);
    console.log('🔵 Raw state data:', data);
    
    if (!data) {
      console.log('🔵 No state data found in storage');
      return null;
    }
    
    const parsed = JSON.parse(data);
    
    // Check if state has expired (30 minutes)
    if (parsed.timestamp && Date.now() - parsed.timestamp > 30 * 60 * 1000) {
      console.log('🔵 State data has expired');
      localStorage.removeItem(LINKEDIN_STATE_KEY);
      return null;
    }
    
    console.log('🔵 Parsed state data:', {
      ...parsed,
      timestamp: new Date(parsed.timestamp).toISOString(),
      codeVerifier: parsed.codeVerifier ? '[PRESENT]' : '[MISSING]'
    });
    
    return parsed;
  } catch (error) {
    console.error('❌ Error reading state:', error);
    return null;
  }
};

export const saveState = (stateData) => {
  try {
    console.log('🔵 Saving state data:', {
      ...stateData,
      timestamp: new Date(stateData.timestamp).toISOString()
    });
    
    // Clear any existing state first
    localStorage.removeItem(LINKEDIN_STATE_KEY);
    
    // Save new state
    localStorage.setItem(LINKEDIN_STATE_KEY, JSON.stringify(stateData));
    
    // Verify the save was successful
    const saved = localStorage.getItem(LINKEDIN_STATE_KEY);
    const parsed = saved ? JSON.parse(saved) : null;
    
    console.log('🔵 Verifying saved state:', {
      saveSuccess: !!saved,
      stateMatch: parsed?.state === stateData.state
    });
    
    return parsed && parsed.state === stateData.state;
  } catch (error) {
    console.error('❌ Error saving state:', error);
    return false;
  }
};

// Generate code verifier and challenge
export const generateCodeVerifier = () => {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return btoa(String.fromCharCode.apply(null, array))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
    .substring(0, 43);  // Ensure proper length for PKCE
};

export const generateCodeChallenge = async (verifier) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const hash = await crypto.subtle.digest('SHA-256', data);
  return btoa(String.fromCharCode(...new Uint8Array(hash)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

export const getLinkedInAuthUrl = async (state) => {
  try {
    console.log('🔵 Generating LinkedIn auth URL with state:', state);
    
    if (!state) {
      console.error('❌ No state provided to getLinkedInAuthUrl');
      throw new Error('State parameter is required');
    }
    
    const codeVerifier = generateCodeVerifier();
    console.log('🔵 Generated code verifier:', codeVerifier.substring(0, 10) + '...');
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    console.log('🔵 Generated code challenge:', codeChallenge.substring(0, 10) + '...');
    
    // Get the auth source
    const authSource = localStorage.getItem(LINKEDIN_SOURCE_KEY) || 'login';
    console.log('🔵 Auth source:', authSource);
    
    // Store state data with additional metadata
    const stateData = {
      state,
      codeVerifier,
      timestamp: Date.now(),
      origin: window.location.origin,
      redirectUri: LINKEDIN_REDIRECT_URI,
      source: authSource // Store the source in state data
    };
    
    // Save and verify state
    const saved = saveState(stateData);
    if (!saved) {
      throw new Error('Failed to save authentication state');
    }
    
    // Double check state was saved correctly
    const verifiedData = getState();
    if (!verifiedData || verifiedData.state !== state) {
      throw new Error('Authentication state verification failed');
    }
    
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: LINKEDIN_CLIENT_ID,
      redirect_uri: LINKEDIN_REDIRECT_URI,
      scope: LINKEDIN_SCOPE,
      state,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256'
    });

    const url = `https://www.linkedin.com/oauth/v2/authorization?${params.toString()}`;
    console.log('🔵 Generated LinkedIn auth URL:', url);
    return url;
  } catch (error) {
    console.error('❌ Error generating LinkedIn auth URL:', error);
    throw new Error('Failed to initialize LinkedIn authentication: ' + error.message);
  }
};

export const handleLinkedInCallback = async (code, state) => {
  try {
    console.log('🔵 Handling LinkedIn callback:', {
      codePreview: code.substring(0, 10) + '...',
      state,
      hasState: !!state,
      url: window.location.href
    });
    
    const stateData = getState();
    if (!stateData) {
      console.error('❌ No state data found in storage');
      throw new Error('Authentication session not found or expired. Please try again.');
    }
    
    console.log('🔵 State data from storage:', {
      hasStoredState: !!stateData.state,
      storedState: stateData.state,
      hasCodeVerifier: !!stateData.codeVerifier,
      timestamp: new Date(stateData.timestamp).toISOString(),
      source: stateData.source || 'login'
    });
    
    if (!stateData.codeVerifier) {
      throw new Error('Authentication data is incomplete. Please try again.');
    }
    
    if (stateData.state !== state) {
      console.error('❌ State mismatch:', {
        expected: stateData.state,
        received: state
      });
      throw new Error('Invalid authentication state. Please try again.');
    }
    
    const stateAge = Date.now() - (stateData.timestamp || 0);
    if (stateAge > 30 * 60 * 1000) {
      throw new Error('Authentication session has expired. Please try again.');
    }
    
    // Verify origin matches
    if (stateData.origin !== window.location.origin) {
      throw new Error('Invalid authentication origin. Please try again.');
    }
    
    // Handle different flows based on state prefix
    if (state.startsWith('connect-')) {
      console.log('🔵 Handling LinkedIn connection callback');
      const response = await api.post('/api/linkedin/connect', { 
        code,
        state,
        codeVerifier: stateData.codeVerifier
      });
      
      if (response.data.success) {
        localStorage.removeItem(LINKEDIN_STATE_KEY);
        localStorage.removeItem(LINKEDIN_SOURCE_KEY);
        return response.data;
      }
    } else if (state.startsWith('reconnect-')) {
      console.log('🔵 Handling LinkedIn reconnection callback');
      const response = await api.post('/api/linkedin/reconnect', { 
        code,
        state,
        codeVerifier: stateData.codeVerifier
      });
      
      if (response.data.success) {
        localStorage.removeItem(LINKEDIN_STATE_KEY);
        localStorage.removeItem(LINKEDIN_SOURCE_KEY);
        return response.data;
      }
    }
    
    // Default auth flow (login/signup)
    const response = await api.post('/api/auth/linkedin/callback', { 
      code,
      codeVerifier: stateData.codeVerifier,
      isSignup: stateData.source === 'register'
    });
    
    console.log('🔍 LinkedIn Callback Response:', {
      responseData: response.data,
      linkedinTokens: response.data.linkedinTokens,
      isSignup: response.data.linkedinTokens?.isSignup,
      source: stateData.source
    });
    
    localStorage.removeItem(LINKEDIN_STATE_KEY);
    localStorage.removeItem(LINKEDIN_SOURCE_KEY);
    return response.data;
  } catch (error) {
    console.error('❌ LinkedIn authentication error:', error);
    console.error('❌ Error details:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    
    // Clean up state on error
    localStorage.removeItem(LINKEDIN_STATE_KEY);
    localStorage.removeItem(LINKEDIN_SOURCE_KEY);
    
    if (error.response?.status === 400) {
      throw new Error(error.response.data?.error || 'Invalid authentication request');
    }
    if (error.response?.status === 401) {
      throw new Error('Authentication failed. Please try again.');
    }
    throw error;
  }
};

// Helper function to generate random state
const generateRandomState = () => {
  const array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  return Array.from(array, x => x.toString(16)).join('');
};

const getRedirectUri = () => {
  return process.env.REACT_APP_LINKEDIN_REDIRECT_URI || 
    `${window.location.origin}/auth/linkedin/callback`;
};

export const initiateLinkedInAuth = () => {
  const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirectUri = getRedirectUri();
  const state = generateRandomState();
  const codeVerifier = generateCodeVerifier();
  
  // Store auth state
  sessionStorage.setItem('linkedin_auth_state', JSON.stringify({
    state,
    codeVerifier,
    timestamp: Date.now()
  }));

  const codeChallenge = generateCodeChallenge(codeVerifier);
  
  const authUrl = `https://www.linkedin.com/oauth/v2/authorization?` +
    `response_type=code&` +
    `client_id=${clientId}&` +
    `redirect_uri=${encodeURIComponent(redirectUri)}&` +
    `state=${state}&` +
    `scope=${encodeURIComponent('r_liteprofile r_emailaddress')}&` +
    `code_challenge=${codeChallenge}&` +
    `code_challenge_method=S256`;

  return authUrl;
}; 