import React, { useState } from 'react';
import { XMarkIcon, PencilIcon, TrashIcon, CheckCircleIcon, ExclamationCircleIcon, TagIcon } from '@heroicons/react/24/outline';

const NotificationMessage = ({ message, type, isDarkMode }) => (
  <div className={`
    fixed sm:top-4 sm:right-4 top-0 right-0 left-0 flex items-center gap-x-2 px-4 py-3 text-sm shadow-lg
    sm:rounded-lg sm:w-auto w-full sm:border-l sm:border-r sm:border-b border
    ${type === 'success' 
      ? isDarkMode 
        ? 'bg-green-950/50 text-green-50 border-green-800'
        : 'bg-white text-green-900 border-green-200'
    : isDarkMode
        ? 'bg-red-950/50 text-red-50 border-red-800'
        : 'bg-white text-red-900 border-red-200'
    }
  `}>
    {type === 'success' 
      ? <CheckCircleIcon className="h-4 w-4 flex-shrink-0" />
      : <ExclamationCircleIcon className="h-4 w-4 flex-shrink-0" />
    }
    <span className="font-medium">{message}</span>
  </div>
);

const ConfirmDialog = ({ message, onConfirm, onCancel, isDarkMode }) => (
  <div className={`
    fixed inset-0 z-50 flex items-center justify-center backdrop-blur-[4px] p-4
    ${isDarkMode ? 'bg-black/80' : 'bg-white/80'}
  `}>
    <div className={`
      relative w-full max-w-sm rounded-lg border p-4 sm:p-6 shadow-lg
      ${isDarkMode 
        ? 'bg-slate-950 text-slate-50 border-slate-800' 
        : 'bg-white text-slate-950 border-slate-200'
      }
    `}>
      <p className="text-sm text-muted-foreground leading-6">{message}</p>
      <div className="mt-4 flex flex-col sm:flex-row justify-end gap-2 sm:gap-x-2">
        <button
          onClick={onCancel}
          className={`
            inline-flex h-10 sm:h-9 items-center justify-center rounded-md px-4 sm:px-3 text-sm font-medium ring-offset-white transition-colors
            ${isDarkMode
              ? 'bg-slate-900 text-slate-50 hover:bg-slate-900/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-700 focus-visible:ring-offset-2'
              : 'bg-slate-100 text-slate-900 hover:bg-slate-100/80 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2'
            }
          `}
        >
          Cancel
        </button>
        <button
          onClick={onConfirm}
          className={`
            inline-flex h-10 sm:h-9 items-center justify-center rounded-md px-4 sm:px-3 text-sm font-medium ring-offset-white transition-colors
            ${isDarkMode
              ? 'bg-red-900 text-red-50 hover:bg-red-900/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-700 focus-visible:ring-offset-2'
              : 'bg-red-600 text-white hover:bg-red-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-400 focus-visible:ring-offset-2'
            }
          `}
        >
          Delete
        </button>
      </div>
    </div>
  </div>
);

const EmptyState = ({ isDarkMode }) => (
  <div className="flex flex-col items-center justify-center py-12 text-center">
    <div className={`
      p-3 rounded-full mb-4
      ${isDarkMode ? 'bg-slate-800' : 'bg-slate-100'}
    `}>
      <TagIcon className={`
        w-8 h-8
        ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
      `} />
    </div>
    <h3 className={`
      text-base font-semibold mb-2
      ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
    `}>
      No tags yet
    </h3>
    <p className={`
      text-sm max-w-[240px] mx-auto
      ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
    `}>
      Start organizing your content by adding tags from the tag menu in your notes.
    </p>
  </div>
);

const TagManager = ({ tags, onClose, onEditTag, onDeleteTag, isDarkMode }) => {
  const [editingTag, setEditingTag] = useState(null);
  const [editedTagName, setEditedTagName] = useState('');
  const [notification, setNotification] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);

  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleEditClick = (tag) => {
    if (editingTag) {
      showNotification('Please finish editing the current tag first.', 'error');
      return;
    }
    setEditingTag(tag);
    setEditedTagName(tag);
  };

  const handleSaveEdit = async () => {
    if (!editedTagName.trim()) {
      showNotification('Tag name cannot be empty.', 'error');
      return;
    }

    if (editedTagName.trim() === editingTag) {
      setEditingTag(null);
      setEditedTagName('');
      return;
    }

    try {
      await onEditTag(editingTag, editedTagName.trim());
      setEditingTag(null);
      setEditedTagName('');
      showNotification(`Tag "${editingTag}" successfully renamed to "${editedTagName.trim()}"`);
    } catch (error) {
      console.error('Error saving tag edit:', error);
      showNotification(error.response?.data?.error || 'Failed to edit tag. Please try again.', 'error');
    }
  };

  const handleCancelEdit = () => {
    setEditingTag(null);
    setEditedTagName('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSaveEdit();
    } else if (e.key === 'Escape') {
      handleCancelEdit();
    }
  };

  const handleDeleteClick = async (tag) => {
    setConfirmDialog({
      message: `Are you sure you want to delete the tag "${tag}"? This action cannot be undone.`,
      onConfirm: async () => {
        try {
          await onDeleteTag(tag);
          showNotification(`Tag "${tag}" successfully deleted`);
        } catch (error) {
          console.error('Error deleting tag:', error);
          showNotification('Failed to delete tag. Please try again.', 'error');
        }
        setConfirmDialog(null);
      },
      onCancel: () => setConfirmDialog(null)
    });
  };

  return (
    <div className={`
      fixed inset-0 z-50 flex items-center justify-center backdrop-blur-[4px] p-4
      ${isDarkMode ? 'bg-black/80' : 'bg-white/80'}
    `}>
      {notification && (
        <NotificationMessage
          message={notification.message}
          type={notification.type}
          isDarkMode={isDarkMode}
        />
      )}
      
      {confirmDialog && (
        <ConfirmDialog
          message={confirmDialog.message}
          onConfirm={confirmDialog.onConfirm}
          onCancel={confirmDialog.onCancel}
          isDarkMode={isDarkMode}
        />
      )}

      <div className={`
        relative w-full max-w-md rounded-lg border shadow-lg
        ${isDarkMode 
          ? 'bg-slate-950 text-slate-50 border-slate-800' 
          : 'bg-white text-slate-950 border-slate-200'
        }
      `}>
        <button
          onClick={onClose}
          className={`
            absolute right-3 top-3 sm:right-4 sm:top-4 rounded-sm opacity-70 ring-offset-white transition-opacity hover:opacity-100 z-10
            ${isDarkMode 
              ? 'text-slate-400 hover:text-slate-100' 
              : 'text-slate-900 hover:text-slate-900'
            }
            focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2
          `}
          aria-label="Close"
        >
          <XMarkIcon className="h-5 w-5 sm:h-4 sm:w-4" />
        </button>
        <div className="flex flex-col gap-y-4 p-4 sm:p-6 relative">
          <h2 className="text-lg font-semibold leading-none tracking-tight pl-1 pr-8">Manage Tags</h2>
          {tags.length === 0 ? (
            <EmptyState isDarkMode={isDarkMode} />
          ) : (
            <div className="flex flex-col gap-y-2 max-h-[60vh] sm:max-h-[400px] overflow-y-auto pr-2">
              {tags.map((tag) => (
                <div key={tag} className={`
                  flex items-center justify-between rounded-md p-3 transition-colors
                  ${isDarkMode 
                    ? 'hover:bg-slate-800/50' 
                    : 'hover:bg-slate-100'
                  }
                `}>
                  {editingTag === tag ? (
                    <div className="flex flex-col sm:flex-row w-full gap-3">
                      <div className="w-full sm:flex-1 sm:mr-4">
                        <input
                          type="text"
                          value={editedTagName}
                          onChange={(e) => setEditedTagName(e.target.value)}
                          onKeyDown={handleKeyPress}
                          className={`
                            flex h-10 sm:h-9 w-full rounded-md px-3 text-sm ring-offset-white transition-colors
                            ${isDarkMode 
                              ? 'bg-slate-900 border-0 text-slate-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-700 focus-visible:ring-offset-2' 
                              : 'bg-white border border-slate-200 text-slate-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2'
                            }
                          `}
                          autoFocus
                        />
                      </div>
                      <div className="flex items-center gap-x-2 sm:flex-shrink-0">
                        <button
                          onClick={handleSaveEdit}
                          className={`
                            flex-1 sm:flex-initial inline-flex h-10 sm:h-8 items-center justify-center rounded-md px-4 sm:px-3 text-sm font-medium ring-offset-white transition-colors
                            ${isDarkMode
                              ? 'bg-slate-900 text-slate-50 hover:bg-slate-900/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-700 focus-visible:ring-offset-2'
                              : 'bg-slate-100 text-slate-900 hover:bg-slate-100/80 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2'
                            }
                          `}
                        >
                          Save
                        </button>
                        <button
                          onClick={handleCancelEdit}
                          className={`
                            flex-1 sm:flex-initial inline-flex h-10 sm:h-8 items-center justify-center rounded-md px-4 sm:px-3 text-sm font-medium ring-offset-white transition-colors
                            ${isDarkMode
                              ? 'hover:bg-slate-800 text-slate-400 hover:text-slate-100'
                              : 'hover:bg-slate-100 text-slate-500 hover:text-slate-900'
                            }
                          `}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-between w-full">
                      <span className="text-sm font-medium pl-1">{tag}</span>
                      <div className="flex items-center gap-x-2">
                        <button
                          onClick={() => handleEditClick(tag)}
                          className={`
                            inline-flex h-10 w-10 sm:h-8 sm:w-8 items-center justify-center rounded-md transition-colors
                            ${isDarkMode
                              ? 'hover:bg-slate-800 text-slate-400 hover:text-slate-100'
                              : 'hover:bg-slate-100 text-slate-500 hover:text-slate-900'
                            }
                            focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2
                          `}
                        >
                          <PencilIcon className="h-5 w-5 sm:h-4 sm:w-4" />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(tag)}
                          className={`
                            inline-flex h-10 w-10 sm:h-8 sm:w-8 items-center justify-center rounded-md transition-colors
                            ${isDarkMode
                              ? 'hover:bg-red-900/50 text-red-400 hover:text-red-100'
                              : 'hover:bg-red-100 text-red-500 hover:text-red-600'
                            }
                            focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-400 focus-visible:ring-offset-2
                          `}
                        >
                          <TrashIcon className="h-5 w-5 sm:h-4 sm:w-4" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TagManager;