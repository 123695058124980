import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { motion } from 'framer-motion';

const LinkedInLogo = ({ className = "w-5 h-5" }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
  </svg>
);

const XLogo = ({ className = "w-5 h-5" }) => (
  <svg 
    className={className} 
    viewBox="0 0 251 256" 
    fill="currentColor"
  >
    <path d="M149.079 108.399L242.33 0h-22.098l-80.97 94.12L74.59 0H0l97.796 142.328L0 256h22.1l85.507-99.395L175.905 256h74.59L149.073 108.399zM118.81 143.58l-9.909-14.172l-78.84-112.773h33.943l63.625 91.011l9.909 14.173l82.705 118.3H186.3l-67.49-96.533z"/>
  </svg>
);

const Footer = () => {
  const { isDarkMode } = useTheme();
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  // Simplified footer sections with proper navigation links
  const footerSections = [
    {
      title: 'Resources',
      links: [
        { label: 'Our Story', href: '/about' },
        { label: 'Thought Leadership', href: '/blog' },
        { label: 'Support', href: '/contact' },
        { label: 'Pricing', href: '/pricing', isExternal: true },
      ],
    },
    {
      title: 'Legal',
      links: [
        { label: 'Privacy Policy', href: '/privacy' },
        { label: 'Terms of Use', href: '/terms' },
      ],
    },
  ];

  const socialLinks = [
    { 
      name: 'X', 
      href: 'https://x.com/ammmplifyhq',
      icon: XLogo
    },
    { 
      name: 'LinkedIn', 
      href: 'https://www.linkedin.com/company/ammmplify/',
      icon: LinkedInLogo
    }
  ];

  // Handle navigation for section links
  const handleNavClick = (e, href) => {
    e.preventDefault();
    
    if (href.startsWith('#')) {
      const currentPath = window.location.pathname;
      
      if (currentPath !== '/home' && currentPath !== '/') {
        // If we're not on the home page, navigate first
        navigate('/home', { 
          state: { scrollToSection: href.replace('#', '') } 
        });
      } else {
        // If we're already on the home page, just scroll
        const elementId = href.replace('#', '');
        const element = document.getElementById(elementId);
        const mainContainer = document.querySelector('main');
        
        if (element && mainContainer) {
          const currentScrollTop = mainContainer.scrollTop;
          const containerRect = mainContainer.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const headerOffset = 100;
          const absoluteElementTop = elementRect.top - containerRect.top + currentScrollTop;
          
          mainContainer.scrollTo({
            top: absoluteElementTop - headerOffset,
            behavior: 'smooth'
          });
        }
      }
    } else {
      // For non-anchor links, just navigate
      navigate(href);
    }
  };

  // Glass effect styles matching header
  const glassEffectStyles = `
    ${isDarkMode
      ? 'bg-gradient-to-b from-[#151515]/60 to-[#121212]/40'
      : 'bg-gradient-to-b from-white/60 to-white/40'
    }
  `;

  const containerStyles = `
    ${isDarkMode 
      ? 'bg-[#151515]/30 shadow-lg shadow-[#121212]/20 border border-white/[0.05]' 
      : 'bg-slate-50/30 shadow-lg shadow-slate-200/20 border border-black/[0.03]'
    }
  `;

  const borderStyles = `
    ring-1 
    ${isDarkMode
      ? 'ring-[#333333]'
      : 'ring-black/5'
    }
  `;

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8 py-4">
      <div className="max-w-7xl mx-auto">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className={`
            relative rounded-2xl overflow-hidden
            ${containerStyles}
          `}
        >
          {/* Glass effect overlay */}
          <div className={`
            absolute inset-0
            backdrop-blur-xl backdrop-saturate-150
            ${glassEffectStyles}
          `} />

          {/* Border overlay */}
          <div className={`
            absolute inset-0
            ${borderStyles}
            rounded-2xl
          `} />

          {/* Main content */}
          <div className="relative p-6 lg:p-12">
            {/* Mobile-optimized grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-12 gap-8 md:gap-x-8 md:gap-y-12">
              {/* Brand section - optimized for mobile */}
              <div className="col-span-1 sm:col-span-2 md:col-span-8 space-y-6">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="flex items-start"
                >
                  <Link to="/" className="inline-flex items-center">
                    <img 
                      src="/amplify-logo-full.svg" 
                      alt="Amplify Logo" 
                      className={`h-6 w-auto ${isDarkMode ? 'invert' : ''}`}
                    />
                    <div className="flex items-center py-0.5 ml-1.5">
                      <span className={`
                        text-[8px] px-1.5 py-[1px] rounded-full font-semibold tracking-wide
                        transition-all duration-300 ease-in-out
                        ${isDarkMode 
                          ? 'bg-gradient-to-r from-indigo-500/10 to-purple-500/10 text-indigo-400 ring-1 ring-indigo-500/20' 
                          : 'bg-gradient-to-r from-indigo-50 to-purple-50 text-indigo-600 ring-1 ring-indigo-200/50'
                        }
                        hover:scale-105 transform
                        relative
                        translate-y-[1px]
                      `}>
                        BETA
                      </span>
                    </div>
                  </Link>
                </motion.div>
                <p className="mt-6 text-sm text-gray-500 sm:mt-0">
                Your AI Cowriter To Become A Top 1% LinkedIn Creator <br />
                With "your persona". For "your audience". With "your notes, thoughts, and ideas".
                </p>
              </div>

              {/* Navigation sections - optimized for mobile */}
              <div className="col-span-1 sm:col-span-2 md:col-span-4">
                <div className="grid grid-cols-2 gap-8">
                  {footerSections.map((section) => (
                    <div key={section.title}>
                      <h3 className={`text-sm font-semibold mb-4 ${
                        isDarkMode ? 'text-white' : 'text-slate-900'
                      }`}>
                        {section.title}
                      </h3>
                      <ul className="space-y-3">
                        {section.links.map((link) => (
                          <motion.li 
                            key={link.label}
                            whileHover={{ x: 4 }}
                            transition={{ type: "tween" }}
                          >
                            <a
                              href={link.href}
                              onClick={(e) => {
                                e.preventDefault();
                                if (link.isExternal) {
                                  window.location.href = link.href;
                                } else {
                                  handleNavClick(e, link.href);
                                }
                              }}
                              className={`
                                text-sm transition-colors duration-200
                                ${isDarkMode
                                  ? 'text-slate-400 hover:text-white'
                                  : 'text-slate-600 hover:text-slate-900'
                                }
                              `}
                            >
                              {link.label}
                            </a>
                          </motion.li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Bottom bar - optimized for mobile */}
          <div className={`
            relative
            border-t px-6 lg:px-12 py-6
            ${isDarkMode ? 'border-white/10' : 'border-black/5'}
          `}>
            <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
              <div className={`text-sm text-center sm:text-left ${
                isDarkMode ? 'text-slate-400' : 'text-slate-600'
              }`}>
                © {currentYear} Ammmplify.
              </div>
              <div className="flex items-center gap-6">
                {socialLinks.map((link) => (
                  <motion.a
                    key={link.name}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`
                      transition-colors duration-200
                      ${isDarkMode 
                        ? 'text-slate-400 hover:text-white' 
                        : 'text-slate-600 hover:text-slate-900'
                      }
                    `}
                    aria-label={link.name}
                  >
                    <link.icon className="w-5 h-5" />
                  </motion.a>
                ))}
              </div>
            </div>
          </div>

          {/* Floating effect shadows */}
          <div className={`
            absolute inset-0
            rounded-2xl 
            -z-10
            blur-2xl
            opacity-50
            ${isDarkMode
              ? 'bg-gradient-to-r from-blue-500/5 via-purple-500/5 to-pink-500/5'
              : 'bg-gradient-to-r from-blue-500/10 via-purple-500/10 to-pink-500/10'
            }
          `} />
        </motion.div>
      </div>
    </div>
  );
};

export default Footer;
