import React from 'react';
import { useTheme } from '../context/ThemeContext';

const SkeletonLoader = ({ rowCount = 5 }) => {
  const { isDarkMode } = useTheme();

  const styles = {
    row: `group ${isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-50'} transition-colors duration-200`,
    cell: 'py-2 px-3 text-sm',
    skeleton: `h-4 rounded ${isDarkMode ? 'bg-slate-600' : 'bg-gray-300'}`,
  };

  return (
    <>
      {Array.from({ length: rowCount }).map((_, idx) => (
        <tr key={idx} className={styles.row}>
          <td className={`${styles.cell} w-full`}>
            <div className="flex items-center">
              <div className={`${styles.skeleton} w-4 mr-2`}></div>
              <div className={`${styles.skeleton} w-32`}></div>
            </div>
          </td>
          <td className={`${styles.cell} w-40 hidden sm:table-cell`}>
            <div className={`${styles.skeleton} w-24`}></div>
          </td>
          <td className={`${styles.cell} w-32 hidden md:table-cell`}>
            <div className={`${styles.skeleton} w-16`}></div>
          </td>
          <td className={`${styles.cell} w-12`}>
            <div className={`${styles.skeleton} w-4 ml-auto`}></div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default SkeletonLoader;
