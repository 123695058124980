import React from 'react';

export const CanvasGridIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 64 64" 
    className={className}
    fill="none"
    stroke="currentColor"
    strokeWidth="4"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="4" y="12" width="24" height="16" rx="4" />
    <rect x="36" y="12" width="24" height="32" rx="4" />
    <rect x="4" y="36" width="24" height="16" rx="4" />
  </svg>
); 