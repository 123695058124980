import api from '../api';
import { TokenStorage } from '../utils/tokenStorage';
import { updatePostPublishStatus as apiUpdatePostPublishStatus } from '../api';

// Add these constants at the top
const LINKEDIN_PROFILE_CACHE_KEY = 'linkedin_profile_cache';
const LINKEDIN_PROFILE_CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

// Add these helper functions
const getCachedProfile = () => {
  try {
    const cached = localStorage.getItem(LINKEDIN_PROFILE_CACHE_KEY);
    if (!cached) return null;
    
    const { profile, timestamp } = JSON.parse(cached);
    if (Date.now() - timestamp > LINKEDIN_PROFILE_CACHE_DURATION) {
      localStorage.removeItem(LINKEDIN_PROFILE_CACHE_KEY);
      return null;
    }
    
    return profile;
  } catch (error) {
    console.error('Error reading cached profile:', error);
    return null;
  }
};

const cacheProfile = (profile) => {
  try {
    localStorage.setItem(LINKEDIN_PROFILE_CACHE_KEY, JSON.stringify({
      profile,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.error('Error caching profile:', error);
  }
};

export const clearLinkedInCache = () => {
  localStorage.removeItem(LINKEDIN_PROFILE_CACHE_KEY);
};

/**
 * Publishes a post to LinkedIn
 * @param {string} content - The content of the post
 * @param {string} title - The title of the post (optional)
 * @param {Array} mediaFiles - Array of media files to attach (optional)
 * @param {boolean} isDryRun - Whether to run in test mode without publishing
 * @returns {Promise} - A promise that resolves when the post is published
 */
export const publishToLinkedIn = async (content, title = '', mediaFiles = [], isDryRun = false, retryCount = 0) => {
  try {
    // First check if user is connected to LinkedIn
    const statusResponse = await isLinkedInConnected();
    console.log('LinkedIn status check:', statusResponse);
    
    if (!statusResponse.isConnected || statusResponse.needsReconnect) {
      // Add more detailed error information
      const errorDetails = {
        isConnected: statusResponse.isConnected,
        needsReconnect: statusResponse.needsReconnect,
        linkedinId: statusResponse.linkedinId,
        originalError: statusResponse.error
      };
      console.error('LinkedIn connection issue:', errorDetails);

      // Prevent infinite recursion
      if (retryCount >= 1) {
        throw new Error('Failed to establish LinkedIn connection after retry');
      }

      // Try to refresh the connection
      try {
        const refreshResponse = await api.post('/api/linkedin/refresh');
        if (refreshResponse.data.success) {
          console.log('Successfully refreshed LinkedIn connection');
          return publishToLinkedIn(content, title, mediaFiles, isDryRun, retryCount + 1);
        }
      } catch (refreshError) {
        console.error('LinkedIn refresh failed:', refreshError);
      }

      throw new Error(statusResponse.error || 'LinkedIn connection required. Please reconnect your account.');
    }

    // Ensure mediaFiles is properly formatted
    const processedMediaFiles = Array.isArray(mediaFiles) ? mediaFiles.map(file => ({
      cloudinaryUrl: file.cloudinaryUrl || file.url,
      url: file.cloudinaryUrl || file.url,
      originalName: file.originalName || file.name,
      mimeType: file.mimeType || file.type
    })) : [];

    // Add request logging
    console.log('Publishing to LinkedIn - Request body:', {
      content,
      title,
      mediaFiles: processedMediaFiles,
      isDryRun,
      contentLength: content?.length,
      hasTitle: !!title,
      mediaFilesCount: processedMediaFiles?.length,
      timestamp: new Date().toISOString()
    });

    const response = await api.post('/api/auth/linkedin/publish', {
      content,
      title,
      mediaFiles: processedMediaFiles,
      isDryRun
    });

    if (isDryRun) {
      // Ensure we have a consistent response structure for test mode
      return {
        success: true,
        message: response.data.message || 'Test completed successfully',
        steps: response.data.steps || {
          mediaDownloaded: false,
          mediaUploaded: false,
          assetRegistered: false
        }
      };
    }

    console.log('LinkedIn publish success:', response.data);
    return response.data;
  } catch (error) {
    console.error('LinkedIn publish error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status
    });
    
    // Enhanced error handling
    if (error.response?.status === 401) {
      throw new Error('LinkedIn authentication expired. Please reconnect your account.');
    }
    
    throw new Error(error.response?.data?.error || error.message || 'Failed to publish to LinkedIn');
  }
};

/**
 * Checks if the user is connected to LinkedIn
 * @returns {Promise<{isConnected: boolean, needsReconnect: boolean}>} - A promise that resolves to connection status
 */
export const isLinkedInConnected = async () => {
  try {
    // First check cache
    const cachedProfile = getCachedProfile();
    if (cachedProfile) {
      return {
        isConnected: true,
        needsReconnect: false,
        profile: cachedProfile
      };
    }

    console.log('Checking LinkedIn connection status...');
    const response = await api.get('/api/auth/linkedin/status');
    
    // Add more detailed logging
    console.log('LinkedIn status response:', {
      isConnected: response.data.isConnected,
      needsReconnect: response.data.needsReconnect,
      hasLinkedinId: !!response.data.linkedinId,
      profile: response.data.profile,
      tokenStatus: response.data.tokenStatus,
      action: response.data.action,
      error: response.data.error
    });

    // Handle specific reconnection cases
    if (response.data.action === 'RECONNECT') {
      return {
        isConnected: false,
        needsReconnect: true,
        linkedinId: response.data.linkedinId,
        profile: response.data.profile,
        error: response.data.error || 'LinkedIn reconnection required',
        message: 'Please reconnect your LinkedIn account'
      };
    }

    // Cache the profile only if connected and doesn't need reconnect
    if (response.data.isConnected && !response.data.needsReconnect) {
      cacheProfile(response.data.profile);
    }

    return {
      isConnected: response.data.isConnected,
      needsReconnect: response.data.needsReconnect,
      linkedinId: response.data.linkedinId,
      profile: response.data.profile,
      error: response.data.error,
      message: response.data.message
    };
  } catch (error) {
    console.error('LinkedIn status check error:', error);
    return {
      isConnected: false,
      needsReconnect: true,
      linkedinId: null,
      profile: null,
      error: error.message || 'Failed to check LinkedIn connection status'
    };
  }
};

/**
 * Disconnects the user from LinkedIn
 * @returns {Promise} - A promise that resolves when the disconnect is successful
 */
export const disconnectLinkedIn = async () => {
  try {
    const response = await api.post('/api/auth/linkedin/disconnect');
    return response.data;
  } catch (error) {
    console.error('LinkedIn disconnect error:', error);
    throw new Error(error.response?.data?.error || 'Failed to disconnect LinkedIn account');
  }
};

export const updatePostPublishStatus = async (canvasId, postId, isPublished) => {
  try {
    console.log('Updating post publish status:', { canvasId, postId, isPublished });
    const result = await apiUpdatePostPublishStatus(canvasId, postId, isPublished);
    return result;
  } catch (error) {
    console.error('Error updating post publish status:', error);
    throw error;
  }
}; 