import React from 'react';
import { useTheme } from '../../context/ThemeContext';

const SkeletonPulse = () => {
  const { isDarkMode } = useTheme();
  return (
    <div className={`animate-pulse ${isDarkMode ? 'bg-gray-700/50' : 'bg-gray-200'} rounded`} />
  );
};

const ChatInterfaceCommentHistorySkeleton = () => {
  const { isDarkMode } = useTheme();

  return (
    <div className={`h-full flex flex-col w-full ${isDarkMode ? 'bg-gray-900' : 'bg-white'}`}>
      {/* Content Skeleton */}
      <div className="flex-1 overflow-y-auto p-4">
        <div className="max-w-5xl mx-auto space-y-6">
          {[1, 2, 3].map((item) => (
            <div
              key={item}
              className={`p-4 rounded-lg border ${
                isDarkMode ? 'bg-[#1E1E1E] border-[#333333]' : 'bg-white border-gray-200'
              }`}
            >
              {/* Top Row */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className={`h-1.5 w-1.5 rounded-full ${isDarkMode ? 'bg-gray-700/50' : 'bg-gray-200'} animate-pulse`} />
                  <div className="w-24 h-3 animate-pulse rounded">
                    <SkeletonPulse />
                  </div>
                </div>
                <div className="flex items-center gap-1">
                  <div className="w-7 h-7 animate-pulse rounded-md">
                    <SkeletonPulse />
                  </div>
                  <div className="w-7 h-7 animate-pulse rounded-md">
                    <SkeletonPulse />
                  </div>
                </div>
              </div>

              {/* Tags Row */}
              <div className="flex flex-wrap items-center gap-1.5 mt-3">
                {[1, 2, 3].map((tag) => (
                  <div key={tag} className="w-16 h-4 animate-pulse rounded">
                    <SkeletonPulse />
                  </div>
                ))}
              </div>

              {/* Content Section */}
              <div className="mt-3 space-y-3">
                {/* Original Post */}
                <div className={`p-2 rounded ${isDarkMode ? 'bg-[#252525]/50' : 'bg-gray-50'}`}>
                  <div className="space-y-1">
                    <div className="w-full h-3 animate-pulse rounded">
                      <SkeletonPulse />
                    </div>
                    <div className="w-3/4 h-3 animate-pulse rounded">
                      <SkeletonPulse />
                    </div>
                  </div>
                </div>

                {/* Generated Comment */}
                <div className="space-y-2">
                  {[1, 2, 3].map((line) => (
                    <div key={line} className={`w-${line === 2 ? '3/4' : 'full'} h-4 animate-pulse rounded`}>
                      <SkeletonPulse />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChatInterfaceCommentHistorySkeleton; 