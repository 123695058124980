/**
 * Get authentication token from storage
 * Checks both session storage and local storage for possible token keys
 * @returns {string|null} The authentication token if found, null otherwise
 */
export const getAuthToken = () => {
  const possibleTokenKeys = ['token', 'auth_token', 'jwt_token', 'accessToken'];
  let token = null;
  
  // Check session storage first
  for (const key of possibleTokenKeys) {
    const sessionToken = sessionStorage.getItem(key);
    if (sessionToken) {
      console.log(`Found token in sessionStorage with key: ${key}`);
      token = sessionToken;
      break;
    }
  }
  
  // If not in session storage, try local storage
  if (!token) {
    for (const key of possibleTokenKeys) {
      const localToken = localStorage.getItem(key);
      if (localToken) {
        console.log(`Found token in localStorage with key: ${key}`);
        token = localToken;
        break;
      }
    }
  }

  return token;
}; 