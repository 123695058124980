import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { 
  PaperAirplaneIcon, 
  ClipboardDocumentIcon,
  BriefcaseIcon, 
  ChatBubbleBottomCenterTextIcon, 
  SparklesIcon,
  HeartIcon,
  FaceSmileIcon,
  PencilSquareIcon,
  HashtagIcon,
  AdjustmentsHorizontalIcon,
  XMarkIcon,
  TrashIcon,
  AcademicCapIcon,
  LightBulbIcon,
  FireIcon,
  HandRaisedIcon,
  ShieldExclamationIcon,
  UserGroupIcon,
  MegaphoneIcon
} from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon as ClipboardDocumentIconSolid } from '@heroicons/react/24/solid';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../../api';
import './PostComments.css';
import PersonaDropdown from '../personas/PersonaDropdown';
import TargetAudienceDropdown from '../targetAudiences/TargetAudienceDropdown';
import { createPortal } from 'react-dom';
import { ClockIcon } from '@heroicons/react/24/outline';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import StyledActionButton from '../StyledActionButton';
import { usePostComments } from '../../context/PostCommentsContext';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const TONE_OPTIONS = [
  { 
    id: 'conversational',
    label: 'Conversational',
    icon: 'ChatBubbleBottomCenterTextIcon',
    description: 'Natural and engaging dialogue'
  },
  { 
    id: 'professional',
    label: 'Professional',
    icon: 'BriefcaseIcon',
    description: 'Formal and business-like'
  },
  { 
    id: 'casual',
    label: 'Casual',
    icon: 'ChatBubbleBottomCenterTextIcon',
    description: 'Relaxed and conversational'
  },
  { 
    id: 'enthusiastic',
    label: 'Enthusiastic',
    icon: 'SparklesIcon',
    description: 'Energetic and positive'
  },
  { 
    id: 'empathetic',
    label: 'Empathetic',
    icon: 'HeartIcon',
    description: 'Understanding and supportive'
  },
  { 
    id: 'humorous',
    label: 'Humorous',
    icon: 'FaceSmileIcon',
    description: 'Light-hearted and fun'
  },
  {
    id: 'educational',
    label: 'Educational',
    icon: 'AcademicCapIcon',
    description: 'Informative and instructive'
  },
  {
    id: 'insightful',
    label: 'Insightful',
    icon: 'LightBulbIcon',
    description: 'Thoughtful and analytical'
  },
  {
    id: 'passionate',
    label: 'Passionate',
    icon: 'FireIcon',
    description: 'Strong and emotionally engaged'
  },
  {
    id: 'respectful',
    label: 'Respectful',
    icon: 'HandRaisedIcon',
    description: 'Polite and considerate'
  },
  {
    id: 'authoritative',
    label: 'Authoritative',
    icon: 'ShieldExclamationIcon',
    description: 'Expert and commanding'
  },
  {
    id: 'collaborative',
    label: 'Collaborative',
    icon: 'UserGroupIcon',
    description: 'Team-oriented and inclusive'
  },
  {
    id: 'persuasive',
    label: 'Persuasive',
    icon: 'MegaphoneIcon',
    description: 'Convincing and influential'
  }
];

// Export ToneDropdown component
export const ToneDropdown = ({ selectedTone, onToneSelect, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && 
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    const updatePosition = () => {
      if (buttonRef.current && isOpen) {
        const rect = buttonRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom + window.scrollY + 4,
          left: rect.left + window.scrollX,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);

    updatePosition();

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isOpen]);

  const getIcon = (iconName) => {
    const icons = {
      BriefcaseIcon,
      ChatBubbleBottomCenterTextIcon,
      SparklesIcon,
      HeartIcon,
      FaceSmileIcon,
      AcademicCapIcon,
      LightBulbIcon,
      FireIcon,
      HandRaisedIcon,
      ShieldExclamationIcon,
      UserGroupIcon,
      MegaphoneIcon
    };
    const Icon = icons[iconName];
    return Icon ? <Icon className="h-4 w-4" /> : null;
  };

  const selectedOption = TONE_OPTIONS.find(option => option.id === selectedTone) || TONE_OPTIONS[0];

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className={`
          group flex items-center justify-between w-full px-3 py-1.5 text-xs font-medium 
          rounded-md transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#1e1e1e] hover:bg-[#2a2a2a] text-slate-200 border border-[#333333]' 
            : 'bg-gray-50 hover:bg-gray-100 text-gray-900 border border-gray-200'
          }
        `}
      >
        <div className="flex items-center gap-2">
          {getIcon(selectedOption.icon)}
          <span>{selectedOption.label}</span>
        </div>
        <ChevronDownIcon className={`
          w-4 h-4 transition-transform duration-200
          ${isOpen ? 'rotate-180' : ''}
          ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}
        `} />
      </button>

      {isOpen && createPortal(
        <div
          ref={dropdownRef}
          style={{
            position: 'absolute',
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
            width: '16rem',
            zIndex: 100,
          }}
          className={`
            rounded-lg shadow-lg border
            ${isDarkMode 
              ? 'bg-[#151515] border-[#333333]' 
              : 'bg-white border-gray-200'
            }
          `}
        >
          <div className="py-1 max-h-[280px] overflow-y-auto custom-scrollbar">
            {TONE_OPTIONS.map(option => (
              <button
                key={option.id}
                onClick={() => {
                  onToneSelect(option.id);
                  setIsOpen(false);
                }}
                className={`
                  flex items-center w-full px-3 py-2 text-xs
                  transition-colors duration-200
                  ${isDarkMode
                    ? 'hover:bg-[#2a2a2a] text-slate-200' 
                    : 'hover:bg-gray-50 text-gray-700'
                  }
                  ${selectedTone === option.id 
                    ? isDarkMode 
                      ? 'bg-[#2a2a2a]' 
                      : 'bg-gray-50' 
                    : ''
                  }
                `}
              >
                <div className="flex items-center gap-2">
                  {getIcon(option.icon)}
                  <div className="flex flex-col items-start">
                    <span className="font-medium">{option.label}</span>
                    <span className={`text-[10px] ${
                      isDarkMode ? 'text-slate-400' : 'text-gray-500'
                    }`}>
                      {option.description}
                    </span>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

const TopBar = ({ isDarkMode, onClose, isInChatInterface }) => (
  <div className={`${isDarkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} 
    bg-opacity-90 h-10 flex-shrink-0 flex items-center justify-between px-3 w-full border-b border-opacity-50`}>
    <div className="flex items-center">
      <ChatBubbleLeftRightIcon className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mr-2`} />
      <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Generate Human-like Comments</h2>
    </div>
    <div className="flex items-center space-x-2">
      {isInChatInterface && (
        <button
          onClick={onClose}
          className={`p-1 rounded-full transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
          }`}
        >
          <XMarkIcon className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
        </button>
      )}
    </div>
  </div>
);

const PostComments = ({ onClose }) => {
  const { isDarkMode } = useTheme();
  const [postContent, setPostContent] = useState('');
  const [selectedTone, setSelectedTone] = useState('conversational');
  const [generatedComments, setGeneratedComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copiedComments, setCopiedComments] = useState({});
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [selectedTargetAudience, setSelectedTargetAudience] = useState(null);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 700);

  const { fetchCommentHistory } = usePostComments();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [generatedComments]);

  const handleGenerate = async () => {
    if (!postContent.trim()) {
      setError('Please enter post content');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await api.post('/api/post-comments/generate', {
        postContent,
        tone: selectedTone,
        persona: selectedPersona,
        targetAudience: selectedTargetAudience
      });

      setGeneratedComments(response.data.comments);
      scrollToBottom();
    } catch (err) {
      console.error('Error generating comments:', err);
      setError(err.response?.data?.error || 'Failed to generate comments');
    } finally {
      setIsLoading(false);
    }
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedComments(prev => ({ ...prev, [index]: true }));
      setTimeout(() => {
        setCopiedComments(prev => ({ ...prev, [index]: false }));
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      setError('Failed to copy to clipboard');
    });
  };

  const messageVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 }
  };

  // Add keyboard shortcut for generate
  useEffect(() => {
    const handleKeyPress = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'Enter') {
        handleGenerate();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [postContent]);

  const navigate = useNavigate();

  const location = useLocation();
  const isInChatInterface = location.pathname.includes('/chat/');

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 700);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`flex flex-col h-full ${isDarkMode ? 'bg-gray-900 text-gray-100' : 'bg-white text-gray-900'}`}>
      <TopBar 
        isDarkMode={isDarkMode}
        onClose={onClose}
        isInChatInterface={isInChatInterface}
      />
      
      <div className="flex-1 overflow-y-auto">
        <div className={`p-3 ${!isInChatInterface && isWideScreen ? 'grid grid-cols-2 gap-4' : 'space-y-3'}`}>
          {/* Input Section */}
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'} rounded-lg p-3`}>
            <div className="mb-3">
              <h2 className="text-sm font-medium">Create Comment</h2>
              <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Add your post content below
              </p>
            </div>

            {/* Input Area */}
            <div className="relative mb-3">
              <AnimatePresence>
                {error && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className={`absolute -top-2 left-0 right-0 z-10 flex items-center justify-between px-2 py-1 rounded text-xs
                      ${isDarkMode 
                        ? 'bg-red-500/10 text-red-400 border border-red-900/30' 
                        : 'bg-red-50 text-red-600 border border-red-100'
                      }`}
                  >
                    <span>{error}</span>
                    <button onClick={() => setError(null)}>
                      <XMarkIcon className="h-3 w-3" />
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>

              <textarea
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                className={`w-full rounded-lg border p-2 text-sm h-32 transition-colors ultra-minimal-scrollbar
                  ${error ? 'mt-6' : 'mt-0'} 
                  ${error 
                    ? isDarkMode
                      ? 'border-red-500/50 focus:border-red-500'
                      : 'border-red-300 focus:border-red-500'
                    : isDarkMode 
                      ? 'border-gray-700 focus:border-blue-500 bg-gray-900' 
                      : 'border-gray-200 focus:border-blue-500 bg-white'
                  }`}
                placeholder="Add your post content here... (Cmd/Ctrl + Enter)"
              />
            </div>

            {/* Controls */}
            <div className="space-y-2">
              <div className="flex flex-wrap items-center justify-between gap-2">
                <div className="flex flex-wrap items-center gap-2">
                  <PersonaDropdown
                    onSelectPersona={setSelectedPersona}
                    selectedPersona={selectedPersona}
                    isLoadingAvatar={isLoadingAvatar}
                    compact={true}
                  />
                  <TargetAudienceDropdown
                    onSelectAudience={setSelectedTargetAudience}
                    selectedAudience={selectedTargetAudience}
                    compact={true}
                  />
                  <ToneDropdown
                    selectedTone={selectedTone}
                    onToneSelect={setSelectedTone}
                    isDarkMode={isDarkMode}
                  />
                </div>
                <StyledActionButton
                  onClick={handleGenerate}
                  icon={PaperAirplaneIcon}
                  text="Generate"
                  isDarkMode={isDarkMode}
                  disabled={isLoading || !postContent.trim()}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>

          {/* Generated Comments Section */}
          <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'} rounded-lg p-3`}>
            <div className="mb-3">
              <h2 className="text-sm font-medium">Generated Comments</h2>
              <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                Your generated comments will appear here
              </p>
            </div>

            <AnimatePresence>
              {generatedComments.length > 0 ? (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  className="space-y-3"
                >
                  {generatedComments.map((comment, index) => (
                    <div
                      key={index}
                      className={`p-3 rounded-lg border ${
                        isDarkMode 
                          ? 'bg-gray-900 border-gray-700' 
                          : 'bg-white border-gray-200'
                      } relative group`}
                    >
                      <div className={`text-sm whitespace-pre-line ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        {comment.split('\n').map((paragraph, i) => (
                          <p key={i} className={i !== 0 ? 'mt-2' : ''}>
                            {paragraph}
                          </p>
                        ))}
                      </div>
                      <div className="absolute left-0 bottom-[-24px] right-0 flex justify-start">
                        <div className="mt-2 ml-3 max-w-full overflow-x-auto">
                          <div className={`inline-flex rounded-lg space-x-1 p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 ${
                            isDarkMode 
                              ? 'bg-slate-800 bg-opacity-80' 
                              : 'bg-white border border-gray-200 shadow-sm'
                          }`}>
                            <button
                              onClick={() => copyToClipboard(comment, index)}
                              className={`p-1 transition-colors duration-200 flex items-center group ${
                                isDarkMode ? 'text-slate-300 hover:text-slate-100' : 'text-gray-500 hover:text-gray-700'
                              }`}
                              title={copiedComments[index] ? "Copied!" : "Copy to clipboard"}
                            >
                              {copiedComments[index] ? (
                                <ClipboardDocumentIconSolid className="h-4 w-4 mr-1" />
                              ) : (
                                <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                              )}
                              <span className="text-xs hidden group-hover:inline icon-text">
                                {copiedComments[index] ? "Copied!" : "Copy"}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={`flex flex-col items-center justify-center h-32 rounded-lg border-2 border-dashed ${
                    isDarkMode ? 'border-gray-700' : 'border-gray-200'
                  }`}
                >
                  <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                    Add content and press Generate
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostComments; 