import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables. Please check your .env file');
}

const supabase = createClient(supabaseUrl, supabaseAnonKey);

const REFRESH_THRESHOLD = 30 * 60; // 30 minutes before expiry
const PROACTIVE_REFRESH_INTERVAL = 5 * 60; // Check every 5 minutes
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second
const MAX_AUTH_ATTEMPTS = 5;
const RATE_LIMIT_WINDOW = 15 * 60 * 1000; // 15 minutes
const MAX_REFRESH_RETRIES = 3;
const REFRESH_TIMEOUT = 10000; // 10 seconds timeout for refresh operations

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const shouldRefreshToken = (session) => {
  if (!session?.expires_at) {
    console.log('⚠️ No expiry time found in session');
    return true;
  }
  
  const expiresAt = session.expires_at;
  const now = Math.floor(Date.now() / 1000);
  const timeUntilExpiry = expiresAt - now;
  const shouldRefresh = timeUntilExpiry < REFRESH_THRESHOLD;
  
  console.log('🔍 Token Refresh Check:', {
    currentTime: new Date(now * 1000).toLocaleString(),
    expiryTime: new Date(expiresAt * 1000).toLocaleString(),
    timeUntilExpiry: `${Math.floor(timeUntilExpiry / 60)}m ${timeUntilExpiry % 60}s`,
    refreshThreshold: `${REFRESH_THRESHOLD / 60} minutes`,
    shouldRefresh,
    nextRefreshIn: shouldRefresh ? 'Refreshing now' : `${Math.floor((timeUntilExpiry - REFRESH_THRESHOLD) / 60)}m ${(timeUntilExpiry - REFRESH_THRESHOLD) % 60}s`
  });

  return shouldRefresh;
};

const retryWithBackoff = async (fn, retries = MAX_RETRIES, delay = RETRY_DELAY) => {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) throw error;
    console.log(`🔄 Retrying... ${MAX_RETRIES - retries + 1}/${MAX_RETRIES}`);
    await sleep(delay);
    return retryWithBackoff(fn, retries - 1, delay * 2);
  }
};

const authAttempts = new Map();

const checkRateLimit = (email) => {
  const now = Date.now();
  const attempts = authAttempts.get(email) || [];
  
  // Remove attempts outside the window
  const recentAttempts = attempts.filter(timestamp => now - timestamp < RATE_LIMIT_WINDOW);
  
  if (recentAttempts.length >= MAX_AUTH_ATTEMPTS) {
    const oldestAttempt = recentAttempts[0];
    const timeToWait = RATE_LIMIT_WINDOW - (now - oldestAttempt);
    throw new Error(`Too many login attempts. Please try again in ${Math.ceil(timeToWait / 60000)} minutes.`);
  }
  
  // Add new attempt
  recentAttempts.push(now);
  authAttempts.set(email, recentAttempts);
};

export const signUpWithEmail = async (email, password, firstName, lastName) => {
  try {
    console.log('Starting Supabase signup process...');
    
    // Sign up with Supabase
    const { data: authData, error: authError } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          firstName,
          lastName,
        },
        emailRedirectTo: process.env.NODE_ENV === 'production'
          ? 'https://ammmplify.com/auth/callback'
          : `${window.location.origin}/auth/callback`,
      },
    });

    console.log('Supabase signup response:', authData);

    if (authError) {
      console.error('Supabase signup error:', authError);
      throw authError;
    }

    // Check if the user was created
    if (!authData?.user) {
      throw new Error('No user data returned from signup');
    }

    // Return the response
    return {
      supabaseUser: authData.user,
      session: authData.session,
      message: 'Please check your email for the verification link'
    };
  } catch (error) {
    console.error('Supabase signup error:', error);
    throw error;
  }
};

const formatAuthError = (error) => {
  const errorMap = {
    'Invalid login credentials': 'Incorrect email or password. Please try again.',
    'Email not confirmed': 'Please verify your email before signing in.',
    'Rate limit exceeded': 'Too many attempts. Please try again later.',
    'Invalid email': 'Please enter a valid email address.',
    'Weak password': 'Password must be at least 8 characters long and contain a mix of letters, numbers, and symbols.',
    'Email already registered': 'An account with this email already exists.',
    'Network request failed': 'Connection error. Please check your internet connection and try again.'
  };

  return errorMap[error.message] || error.message;
};

// State management with persistence
const STORAGE_KEYS = {
  REFRESH_STATE: 'supabase_refresh_state',
  REFRESH_HISTORY: 'supabase_refresh_history'
};

const loadPersistedState = () => {
  try {
    const persistedState = localStorage.getItem(STORAGE_KEYS.REFRESH_STATE);
    const persistedHistory = localStorage.getItem(STORAGE_KEYS.REFRESH_HISTORY);
    
    return {
      refreshState: persistedState ? JSON.parse(persistedState) : {
        isRefreshing: false,
        lastRefreshTime: null,
        nextScheduledRefresh: null
      },
      refreshHistory: persistedHistory ? JSON.parse(persistedHistory) : []
    };
  } catch (error) {
    console.error('Error loading persisted state:', error);
    return {
      refreshState: {
        isRefreshing: false,
        lastRefreshTime: null,
        nextScheduledRefresh: null
      },
      refreshHistory: []
    };
  }
};

const state = {
  refreshInterval: null,
  ...loadPersistedState(),
  MAX_REFRESH_HISTORY: 10,
  lastCleanupTime: null,
  activeAuthProviders: new Set(),
  lastSessionTermination: null
};

const persistState = () => {
  try {
    localStorage.setItem(STORAGE_KEYS.REFRESH_STATE, JSON.stringify(state.refreshState));
    localStorage.setItem(STORAGE_KEYS.REFRESH_HISTORY, JSON.stringify(state.refreshHistory));
  } catch (error) {
    console.error('Error persisting state:', error);
  }
};

const addToRefreshHistory = (refreshData) => {
  state.refreshHistory.unshift({
    timestamp: new Date().toISOString(),
    ...refreshData
  });
  
  if (state.refreshHistory.length > state.MAX_REFRESH_HISTORY) {
    state.refreshHistory.pop();
  }
  
  // Persist the updated history
  persistState();
  
  // Make refresh history available in console
  window.supabaseRefreshHistory = state.refreshHistory;
  
  console.log('📊 Refresh History:', state.refreshHistory);
};

const checkAndRefresh = async () => {
  try {
    // Get current session from localStorage first
    const storedSession = localStorage.getItem('supabase_session');
    if (!storedSession) {
      console.log('❌ No stored session found');
      return;
    }

    const parsedStoredSession = JSON.parse(storedSession);
    const now = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = parsedStoredSession.expires_at - now;

    console.log('🔍 Checking session expiry:', {
      currentTime: new Date(now * 1000).toLocaleString(),
      expiryTime: new Date(parsedStoredSession.expires_at * 1000).toLocaleString(),
      timeUntilExpiry: `${Math.floor(timeUntilExpiry / 60)}m ${timeUntilExpiry % 60}s`,
      shouldRefresh: timeUntilExpiry < REFRESH_THRESHOLD
    });

    // Check if we need to refresh
    if (timeUntilExpiry < REFRESH_THRESHOLD) {
      console.log('🔄 Session needs refresh, initiating...');
      
      // Get fresh session from Supabase
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        console.log('❌ No active session found');
        return;
      }

      // Double check with fresh session
      const freshTimeUntilExpiry = session.expires_at - now;
      console.log('🔍 Fresh session check:', {
        timeUntilExpiry: `${Math.floor(freshTimeUntilExpiry / 60)}m ${freshTimeUntilExpiry % 60}s`,
        shouldRefresh: freshTimeUntilExpiry < REFRESH_THRESHOLD
      });

      if (freshTimeUntilExpiry < REFRESH_THRESHOLD) {
        console.log('🔄 Initiating proactive refresh...');
        const refreshedSession = await refreshSession();
        
        if (refreshedSession) {
          console.log('✅ Proactive refresh successful');
          // Reset interval after successful refresh
          if (state.refreshInterval) {
            clearInterval(state.refreshInterval);
          }
          // Check more frequently when close to expiry
          const checkInterval = freshTimeUntilExpiry < 300 ? 60 : PROACTIVE_REFRESH_INTERVAL;
          state.refreshInterval = setInterval(checkAndRefresh, checkInterval * 1000);
          
          // Force immediate state update
          syncRefreshState(refreshedSession);
        } else {
          console.log('⚠️ Refresh failed, retrying in 30 seconds...');
          setTimeout(checkAndRefresh, 30000);
        }
      }
    }
  } catch (error) {
    console.error('❌ Error in proactive refresh:', error);
    setTimeout(checkAndRefresh, 30000);
  }
};

// Add a periodic state health check
const checkStateHealth = () => {
  const now = Date.now();
  
  // Only run cleanup once every minute at most
  if (state.lastCleanupTime && (now - state.lastCleanupTime) < 60000) {
    return;
  }
  
  if (state.refreshState.isRefreshing) {
    const refreshStartTime = state.refreshState.lastRefreshTime ? new Date(state.refreshState.lastRefreshTime).getTime() : null;
    if (refreshStartTime && (now - refreshStartTime) > 30000) {
      console.log('🔄 Found stuck refresh state, resetting...');
      state.refreshState.isRefreshing = false;
      persistState();
    }
  }

  // Reset past scheduled refreshes
  if (state.refreshState.nextScheduledRefresh) {
    const nextRefresh = new Date(state.refreshState.nextScheduledRefresh).getTime();
    if (nextRefresh < now) {
      console.log('🔄 Updating past scheduled refresh...');
      const session = JSON.parse(localStorage.getItem('supabase_session') || '{}');
      syncRefreshState(session);
    }
  }

  state.lastCleanupTime = now;
};

// Update syncRefreshState to be more robust
const syncRefreshState = (session) => {
  if (!session) return;
  
  const now = Math.floor(Date.now() / 1000);
  const timeUntilExpiry = session.expires_at - now;
  const timeUntilRefresh = timeUntilExpiry - REFRESH_THRESHOLD;
  
  // Always schedule next refresh in the future
  const nextRefreshTime = new Date(Date.now() + Math.max(timeUntilRefresh, 60) * 1000);
  
  state.refreshState = {
    isRefreshing: false,
    lastRefreshTime: new Date(),
    nextScheduledRefresh: nextRefreshTime
  };
  
  persistState();
  
  console.log('🔄 Refresh state synced:', {
    lastRefresh: state.refreshState.lastRefreshTime.toLocaleString(),
    nextRefresh: nextRefreshTime.toLocaleString(),
    timeUntilNextRefresh: `${Math.floor(timeUntilRefresh / 60)}m ${timeUntilRefresh % 60}s`
  });
};

// Add network state monitoring
const monitorNetworkState = () => {
  window.addEventListener('online', () => {
    console.log('🌐 Network is online, checking session...');
    checkAndRefresh();
  });

  window.addEventListener('offline', () => {
    console.log('🔌 Network is offline');
    logSessionTermination('network_offline');
  });
};

// Enhance session monitoring
const monitorSession = () => {
  let lastCheck = Date.now();
  
  const checkSession = async () => {
    try {
      const now = Date.now();
      // Only check if more than 5 seconds have passed
      if (now - lastCheck < 5000) return;
      lastCheck = now;

      const storedSession = localStorage.getItem('supabase_session');
      if (!storedSession) return;

      const { data: { session }, error } = await supabase.auth.getSession();
      
      if (error || !session) {
        console.log('❌ Session validation failed:', error || 'No session');
        logSessionTermination('session_validation_failed', { error: error?.message });
        await signOut('session_invalid');
        return;
      }

      // Check if stored session matches current session
      const stored = JSON.parse(storedSession);
      if (stored.access_token !== session.access_token) {
        console.log('⚠️ Session mismatch detected');
        logSessionTermination('session_mismatch', {
          stored: stored.access_token?.substring(0, 10),
          current: session.access_token?.substring(0, 10)
        });
        syncRefreshState(session); // Update to match current session
      }
    } catch (error) {
      console.error('❌ Error in session monitor:', error);
    }
  };

  // Check session every 30 seconds
  const intervalId = setInterval(checkSession, 30000);
  
  // Initial check
  checkSession();

  return () => clearInterval(intervalId);
};

// Update setupProactiveRefresh to include new monitoring
export const setupProactiveRefresh = () => {
  console.log('🔄 Setting up proactive refresh...');
  
  // Clear any existing intervals
  if (state.refreshInterval) {
    clearInterval(state.refreshInterval);
    state.refreshInterval = null;
  }

  // Run initial health check
  checkStateHealth();
  
  // Force immediate check
  checkAndRefresh();
  
  // Set up monitoring
  monitorNetworkState();
  const cleanupSessionMonitor = monitorSession();
  
  // Set up regular interval checks
  state.refreshInterval = setInterval(() => {
    checkStateHealth();
    checkAndRefresh();
  }, 60 * 1000); // Check every minute

  return () => {
    if (state.refreshInterval) {
      clearInterval(state.refreshInterval);
      state.refreshInterval = null;
    }
    cleanupSessionMonitor();
  };
};

// Ensure setupProactiveRefresh is called when the module loads
(() => {
  console.log('🚀 Initializing proactive refresh on module load');
  const session = localStorage.getItem('supabase_session');
  if (session) {
    setupProactiveRefresh();
  }
})();

export const signInWithEmail = async (email, password) => {
  try {
    checkRateLimit(email);
    
    console.log('🔄 Starting sign in process:', { email });
    const { data: { user, session }, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      console.error('❌ Sign in error:', error);
      throw new Error(formatAuthError(error));
    }

    if (!user.email_confirmed_at) {
      console.warn('⚠️ Unverified email attempt:', { email });
      throw new Error('Please verify your email before signing in.');
    }

    // Analyze token expiration
    const tokenAnalysis = analyzeTokenExpiration(session);
    console.log('Token Analysis:', tokenAnalysis);

    // Store initial session and sync state
    const sessionToStore = {
      ...session,
      expires_at: session.expires_at || Math.floor(Date.now() / 1000) + (session.expires_in || 3600),
      expires_in: session.expires_in || 3600
    };
    localStorage.setItem('supabase_session', JSON.stringify(sessionToStore));
    syncRefreshState(sessionToStore);

    // Initialize refresh mechanism
    setupProactiveRefresh();

    // Run an immediate check
    await checkAndRefresh();

    console.log('✅ Sign in successful:', {
      userId: user.id,
      email: user.email,
      sessionExpiry: new Date(sessionToStore.expires_at * 1000).toISOString()
    });

    return { user, session: sessionToStore };
  } catch (error) {
    console.error('Supabase signin error:', error);
    throw error;
  }
};

export const resendVerificationEmail = async (email) => {
  try {
    const { error } = await supabase.auth.resend({
      type: 'signup',
      email,
      options: {
        emailRedirectTo: `${window.location.origin}/auth/callback`
      }
    });

    if (error) throw error;
    return { message: 'Verification email resent successfully' };
  } catch (error) {
    console.error('Error resending verification email:', error);
    throw error;
  }
};

export const verifyEmail = async (token) => {
  try {
    const { error } = await supabase.auth.verifyOtp({
      token_hash: token,
      type: 'email'
    });

    if (error) throw error;
    return { message: 'Email verified successfully' };
  } catch (error) {
    console.error('Error verifying email:', error);
    throw error;
  }
};

export const signOut = async (reason = 'user_initiated') => {
  try {
    logSessionTermination(reason);
    
    if (state.refreshInterval) {
      clearInterval(state.refreshInterval);
      state.refreshInterval = null;
    }

    // Clear persisted state
    localStorage.removeItem(STORAGE_KEYS.REFRESH_STATE);
    localStorage.removeItem(STORAGE_KEYS.REFRESH_HISTORY);
    
    // Reset state
    state.refreshState = {
      isRefreshing: false,
      lastRefreshTime: null,
      nextScheduledRefresh: null
    };
    state.refreshHistory = [];
    state.activeAuthProviders.clear();

    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  } catch (error) {
    console.error('❌ Supabase signout error:', error);
    throw error;
  }
};

export const getCurrentSession = async () => {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) throw error;
    return session;
  } catch (error) {
    console.error('Error getting session:', error);
    return null;
  }
};

export const onAuthStateChange = (callback) => {
  const wrappedCallback = (event, session) => {
    console.log('🔄 Auth State Change:', { event, sessionPresent: !!session });
    
    if (event === 'SIGNED_IN') {
      const provider = session?.user?.app_metadata?.provider;
      if (provider) {
        state.activeAuthProviders.add(provider);
        console.log('👤 Auth Provider Added:', provider);
      }
    } else if (event === 'SIGNED_OUT') {
      logSessionTermination('auth_state_change', { event });
      state.activeAuthProviders.clear();
    }
    
    callback(event, session);
  };

  return supabase.auth.onAuthStateChange(wrappedCallback);
};

export const handleEmailConfirmation = async (token) => {
  try {
    console.log('Handling email confirmation with token:', token);
    const { data, error } = await supabase.auth.verifyOtp({
      token_hash: token,
      type: 'email'
    });

    if (error) throw error;

    return {
      success: true,
      message: 'Email verified successfully'
    };
  } catch (error) {
    console.error('Email confirmation error:', error);
    throw error;
  }
};

const resetRefreshState = () => {
  state.refreshState.isRefreshing = false;
  persistState();
};

export const refreshSession = async () => {
  const cleanup = () => {
    state.refreshState = {
      ...state.refreshState,
      isRefreshing: false,
      lastRefreshTime: state.refreshState.lastRefreshTime,
      nextScheduledRefresh: state.refreshState.nextScheduledRefresh
    };
    persistState();
    console.log('🧹 Cleanup: Reset refresh state:', state.refreshState);
  };

  try {
    // Check if already refreshing
    if (state.refreshState.isRefreshing) {
      const refreshStartTime = state.refreshState.lastRefreshTime ? new Date(state.refreshState.lastRefreshTime) : null;
      const stuckDuration = refreshStartTime ? (Date.now() - refreshStartTime.getTime()) : 0;
      
      if (stuckDuration > 30000) {
        console.log('🔄 Found stale refresh state, cleaning up...');
        cleanup();
      } else {
        console.log('🔄 Refresh already in progress, waiting...');
        return null;
      }
    }

    state.refreshState.isRefreshing = true;
    persistState();

    const refreshStartTime = new Date();
    console.log('🔄 Starting token refresh flow at:', refreshStartTime.toLocaleString());

    // Get current session
    const { data: { session: currentSession }, error: sessionError } = await supabase.auth.getSession();
    
    if (sessionError) {
      console.error('❌ Error getting current session:', sessionError);
      cleanup();
      return null;
    }

    if (!currentSession) {
      console.log('❌ No current session found');
      cleanup();
      return null;
    }

    // Check if refresh token is expired
    const refreshToken = currentSession.refresh_token;
    if (refreshToken) {
      try {
        const decoded = decodeJWT(refreshToken);
        if (decoded && decoded.exp) {
          const now = Math.floor(Date.now() / 1000);
          if (decoded.exp <= now) {
            console.log('❌ Refresh token has expired, user needs to re-authenticate');
            cleanup();
            await signOut();
            return null;
          }
        } else {
          console.log('⚠️ Could not decode refresh token expiry, proceeding with refresh');
        }
      } catch (e) {
        console.warn('⚠️ Could not decode refresh token:', e);
      }
    }

    // Attempt refresh with retry logic
    let retryCount = 0;
    const maxRetries = 2;
    let lastError = null;

    while (retryCount <= maxRetries) {
      try {
        const { data, error } = await supabase.auth.refreshSession();
        
        if (error) {
          lastError = error;
          if (error.message.includes('network')) {
            retryCount++;
            if (retryCount <= maxRetries) {
              console.log(`🔄 Retry ${retryCount}/${maxRetries} after network error...`);
              await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
              continue;
            }
          }
          throw error;
        }

        if (!data?.session) {
          throw new Error('No session returned from refresh');
        }

        const newSession = data.session;
        const refreshEndTime = new Date();
        const refreshDuration = refreshEndTime - refreshStartTime;

        // Store the session
        const sessionToStore = {
          ...newSession,
          expires_at: newSession.expires_at,
          expires_in: newSession.expires_in || 3600
        };

        localStorage.setItem('supabase_session', JSON.stringify(sessionToStore));
        await supabase.auth.setSession(sessionToStore);

        // Update state and history
        syncRefreshState(sessionToStore);
        addToRefreshHistory({
          status: 'success',
          duration: `${refreshDuration}ms`,
          oldExpiryTime: currentSession.expires_at ? new Date(currentSession.expires_at * 1000).toLocaleString() : 'unknown',
          newExpiryTime: new Date(sessionToStore.expires_at * 1000).toLocaleString(),
          nextRefreshIn: Math.floor((sessionToStore.expires_at - Date.now() / 1000) - REFRESH_THRESHOLD) + ' seconds',
          nextScheduledRefresh: state.refreshState.nextScheduledRefresh.toLocaleString()
        });

        cleanup();
        return sessionToStore;
      } catch (error) {
        lastError = error;
        retryCount++;
      }
    }

    // If we get here, all retries failed
    console.error('❌ Session refresh failed after retries:', lastError);
    addToRefreshHistory({
      status: 'failed',
      error: lastError?.message,
      oldExpiryTime: currentSession.expires_at ? new Date(currentSession.expires_at * 1000).toLocaleString() : 'unknown'
    });

    // Only sign out for specific error conditions
    if (lastError && (
      lastError.message.includes('invalid refresh token') ||
      lastError.message.includes('token expired') ||
      lastError.message.includes('invalid token')
    )) {
      console.log('🔑 Invalid or expired token, signing out...');
      await signOut();
    }

    return null;
  } catch (error) {
    console.error('❌ Unexpected error in refresh flow:', error);
    addToRefreshHistory({
      status: 'error',
      error: error.message
    });
    return null;
  } finally {
    cleanup();
  }
};

// Add a helper function to inspect session structure
export const debugSessionStructure = () => {
  try {
    const currentSession = localStorage.getItem('supabase_session');
    if (!currentSession) {
      console.log('No session found in localStorage');
      return;
    }

    const session = JSON.parse(currentSession);
    console.log('Current Session Structure:', {
      hasAccessToken: !!session.access_token,
      hasRefreshToken: !!session.refresh_token,
      expiresAt: session.expires_at ? new Date(session.expires_at * 1000).toLocaleString() : 'not set',
      expiresIn: session.expires_in || 'not set',
      tokenType: session.token_type || 'not set',
      user: session.user ? {
        id: session.user.id,
        email: session.user.email,
        role: session.user.role
      } : 'not set'
    });

    // Make it available in console
    window.currentSession = session;
    console.log('Full session object available at window.currentSession');
  } catch (error) {
    console.error('Error parsing session:', error);
  }
};

// Make debug function available globally
window.debugSessionStructure = debugSessionStructure;

export const testTokenRefresh = async () => {
  try {
    console.log('🧪 Starting token refresh test...');
    
    // Get current session
    const currentSession = localStorage.getItem('supabase_session');
    if (!currentSession) {
      console.error('❌ No current session found. Please login first.');
      return;
    }

    // Log initial state
    const parsedSession = JSON.parse(currentSession);
    console.log('📊 Initial session state:', {
      accessToken: parsedSession.access_token?.substring(0, 10) + '...',
      expiresAt: new Date(parsedSession.expires_at * 1000).toISOString(),
      timeLeft: Math.floor(parsedSession.expires_at - Date.now() / 1000) + ' seconds'
    });

    // Force token to appear expired by directly calling refresh
    console.log('🔄 Forcing token refresh...');
    const { data: { session: refreshedSession }, error: refreshError } = 
      await supabase.auth.refreshSession();

    if (refreshError) {
      console.error('❌ Refresh failed:', refreshError);
      return null;
    }

    if (refreshedSession) {
      console.log('✅ Token refresh successful! Comparing tokens:', {
        oldToken: parsedSession.access_token?.substring(0, 10) + '...',
        newToken: refreshedSession.access_token?.substring(0, 10) + '...',
        tokensAreDifferent: parsedSession.access_token !== refreshedSession.access_token,
        oldExpiresAt: new Date(parsedSession.expires_at * 1000).toISOString(),
        newExpiresAt: new Date(refreshedSession.expires_at * 1000).toISOString(),
        oldRefreshToken: parsedSession.refresh_token?.substring(0, 10) + '...',
        newRefreshToken: refreshedSession.refresh_token?.substring(0, 10) + '...'
      });

      // Store the new session
      localStorage.setItem('supabase_session', JSON.stringify(refreshedSession));
      return refreshedSession;
    } else {
      console.error('❌ Token refresh failed - no session returned');
      return null;
    }
  } catch (error) {
    console.error('❌ Test failed:', error);
    throw error;
  }
};

export const checkNextRefresh = () => {
  try {
    const currentSession = localStorage.getItem('supabase_session');
    
    if (!currentSession) {
      console.log('❌ No session found in localStorage');
      return {
        status: 'no_session',
        message: 'No active session found'
      };
    }

    const session = JSON.parse(currentSession);
    
    if (!session?.expires_at) {
      console.log('❌ No expiry time found in session:', session);
      return {
        status: 'no_expiry',
        message: 'No expiry time in session'
      };
    }

    const now = Math.floor(Date.now() / 1000);
    const expiresAt = session.expires_at;
    const timeUntilExpiry = expiresAt - now;
    const timeUntilRefresh = timeUntilExpiry - REFRESH_THRESHOLD;

    const status = {
      currentTime: new Date(now * 1000).toLocaleString(),
      expiryTime: new Date(expiresAt * 1000).toLocaleString(),
      timeUntilExpiry: `${Math.floor(timeUntilExpiry / 60)}m ${timeUntilExpiry % 60}s`,
      timeUntilRefresh: `${Math.floor(timeUntilRefresh / 60)}m ${timeUntilRefresh % 60}s`,
      refreshThreshold: `${REFRESH_THRESHOLD / 60} minutes`,
      proactiveRefreshInterval: `${PROACTIVE_REFRESH_INTERVAL / 60} minutes`,
      accessToken: session.access_token?.substring(0, 10) + '...',
      refreshToken: session.refresh_token?.substring(0, 10) + '...',
      isRefreshNeeded: timeUntilExpiry < REFRESH_THRESHOLD ? 'Yes' : 'No',
      status: timeUntilExpiry < 0 ? 'expired' : timeUntilExpiry < REFRESH_THRESHOLD ? 'needs_refresh' : 'active'
    };

    console.log('📊 Session Status:', status);
    return status;
  } catch (error) {
    console.error('❌ Error checking refresh time:', error);
    console.log('Current localStorage state:', localStorage.getItem('supabase_session'));
    return {
      status: 'error',
      error: error.message
    };
  }
};

// Add this debug helper function
const debugSession = () => {
  try {
    const session = localStorage.getItem('supabase_session');
    if (!session) {
      console.log('❌ No session found in localStorage');
      return null;
    }

    const parsed = JSON.parse(session);
    const sessionDetails = {
      accessToken: parsed.access_token?.substring(0, 15) + '...',
      refreshToken: parsed.refresh_token?.substring(0, 10) + '...',
      expiresAt: new Date(parsed.expires_at * 1000).toLocaleString(),
      timeUntilExpiry: Math.floor(parsed.expires_at - Date.now() / 1000) + ' seconds',
      user: parsed.user ? {
        email: parsed.user.email,
        id: parsed.user.id,
        lastSignIn: parsed.user.last_sign_in_at
      } : 'not set',
      refreshState: {
        isRefreshing: state.refreshState.isRefreshing,
        lastRefresh: state.refreshState.lastRefreshTime ? new Date(state.refreshState.lastRefreshTime).toLocaleString() : 'never',
        nextRefresh: state.refreshState.nextScheduledRefresh ? new Date(state.refreshState.nextScheduledRefresh).toLocaleString() : 'not scheduled'
      }
    };

    console.log('📊 Current Session Details:', sessionDetails);
    return sessionDetails;
  } catch (error) {
    console.error('❌ Error parsing session:', error);
    return null;
  }
};

const decodeJWT = (token) => {
  if (!token) {
    console.warn('⚠️ No token provided to decode');
    return null;
  }

  try {
    const parts = token.split('.');
    if (parts.length !== 3) {
      console.warn('⚠️ Invalid JWT format');
      return null;
    }

    const base64Url = parts[1];
    if (!base64Url) {
      console.warn('⚠️ No payload found in JWT');
      return null;
    }

    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => 
      '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    ).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('❌ Error decoding JWT:', error);
    return null;
  }
};

const analyzeSession = () => {
  try {
    const session = localStorage.getItem('supabase_session');
    if (!session) {
      console.log('❌ No session found');
      return;
    }

    const parsed = JSON.parse(session);
    const decodedToken = decodeJWT(parsed.access_token);
    
    const now = Math.floor(Date.now() / 1000);
    const tokenIat = decodedToken.iat;
    const tokenExp = decodedToken.exp;
    
    console.log('🔍 Token Analysis:', {
      issued: new Date(tokenIat * 1000).toLocaleString(),
      expires: new Date(tokenExp * 1000).toLocaleString(),
      timeLeft: Math.floor(tokenExp - now) + ' seconds',
      willRefreshIn: Math.floor(tokenExp - REFRESH_THRESHOLD - now) + ' seconds',
      claims: {
        sub: decodedToken.sub,
        email: decodedToken.email,
        role: decodedToken.role,
        session_id: decodedToken.session_id
      }
    });

    return {
      decodedToken,
      session: parsed
    };
  } catch (error) {
    console.error('❌ Error analyzing session:', error);
  }
};

export const analyzeTokenExpiration = (session) => {
  if (!session) {
    console.log('❌ No session provided');
    return null;
  }

  const now = Math.floor(Date.now() / 1000);
  let expiresAt;

  // Check different possible locations of expiration information
  if (session.expires_at) {
    expiresAt = session.expires_at;
  } else if (session.expires_in) {
    expiresAt = now + session.expires_in;
  } else if (session.session?.expires_at) {
    expiresAt = session.session.expires_at;
  } else {
    console.log('❌ No expiration time found in session:', session);
    return null;
  }

  const timeUntilExpiry = expiresAt - now;
  const expiryDate = new Date(expiresAt * 1000);

  console.log('🔍 Token Expiration Analysis:', {
    currentTime: new Date(now * 1000).toLocaleString(),
    expiryTime: expiryDate.toLocaleString(),
    timeUntilExpiry: `${Math.floor(timeUntilExpiry / 60)} minutes and ${timeUntilExpiry % 60} seconds`,
    totalExpiryTime: session.created_at 
      ? `${Math.floor((expiresAt - session.created_at) / 60)} minutes`
      : 'Unknown',
    isExpired: timeUntilExpiry <= 0,
    needsRefresh: timeUntilExpiry < REFRESH_THRESHOLD,
    sessionData: {
      hasExpiresAt: !!session.expires_at,
      hasExpiresIn: !!session.expires_in,
      hasNestedExpiresAt: !!session.session?.expires_at
    }
  });

  return {
    expiresAt: expiryDate,
    timeUntilExpiry,
    isExpired: timeUntilExpiry <= 0,
    needsRefresh: timeUntilExpiry < REFRESH_THRESHOLD
  };
};

// Simple session check function
export const checkSession = () => {
  try {
    const session = localStorage.getItem('supabase_session');
    if (!session) {
      console.log('No session found');
      return null;
    }

    const parsed = JSON.parse(session);
    const now = Math.floor(Date.now() / 1000);
    const expiresAt = parsed.expires_at || (parsed.session?.expires_at);
    
    if (!expiresAt) {
      console.log('No expiration time found');
      return null;
    }

    const timeLeft = expiresAt - now;
    const expiryDate = new Date(expiresAt * 1000);

    return {
      expiresAt: expiryDate.toLocaleString(),
      timeLeft: `${Math.floor(timeLeft / 60)} minutes and ${timeLeft % 60} seconds`,
      isExpired: timeLeft <= 0
    };
  } catch (error) {
    console.log('Error checking session:', error);
    return null;
  }
};

// Expose the function globally for easy console access
window.checkSession = checkSession;

// Add session termination logging
const logSessionTermination = (reason, details = {}) => {
  const terminationLog = {
    timestamp: new Date().toISOString(),
    reason,
    details,
    activeProviders: Array.from(state.activeAuthProviders),
    lastRefreshState: { ...state.refreshState },
    sessionState: localStorage.getItem('supabase_session') ? 'present' : 'absent',
    lastKnownExpiry: (() => {
      try {
        const session = JSON.parse(localStorage.getItem('supabase_session') || '{}');
        return session.expires_at ? new Date(session.expires_at * 1000).toLocaleString() : 'unknown';
      } catch (e) {
        return 'error parsing';
      }
    })()
  };
  
  state.lastSessionTermination = terminationLog;
  console.log('🔐 Session Termination:', terminationLog);
};

// Add auth state debugging
const debugAuthState = () => {
  const currentState = {
    activeProviders: Array.from(state.activeAuthProviders),
    lastTermination: state.lastSessionTermination,
    currentSession: (() => {
      try {
        return localStorage.getItem('supabase_session') 
          ? JSON.parse(localStorage.getItem('supabase_session')) 
          : null;
      } catch (e) {
        return 'error parsing session';
      }
    })(),
    refreshState: state.refreshState,
    lastCleanupTime: state.lastCleanupTime ? new Date(state.lastCleanupTime).toLocaleString() : null,
    refreshHistory: state.refreshHistory.slice(0, 5) // Last 5 refresh attempts
  };

  console.log('🔍 Auth State Debug:', currentState);
  return currentState;
};

// Setup global debug tools
const setupDebugTools = () => {
  window.supabaseTools = {
    testRefresh: testTokenRefresh,
    checkNextRefresh: checkNextRefresh,
    debugSession,
    forceRefresh: refreshSession,
    getRefreshHistory: () => state.refreshHistory,
    analyze: analyzeSession,
    getRefreshState: () => {
      const refreshState = state.refreshState;
      return {
        isRefreshing: refreshState.isRefreshing,
        lastRefresh: refreshState.lastRefreshTime ? new Date(refreshState.lastRefreshTime).toLocaleString() : 'never',
        nextRefresh: refreshState.nextScheduledRefresh ? new Date(refreshState.nextScheduledRefresh).toLocaleString() : 'not scheduled',
        lastRefreshTime: refreshState.lastRefreshTime ? new Date(refreshState.lastRefreshTime) : null,
        nextScheduledRefresh: refreshState.nextScheduledRefresh ? new Date(refreshState.nextScheduledRefresh) : null
      };
    },
    debugAuthState,
    getLastTermination: () => state.lastSessionTermination
  };

  // Re-run initial setup
  const session = localStorage.getItem('supabase_session');
  if (session) {
    console.log('🔄 Re-initializing refresh state with current session');
    syncRefreshState(JSON.parse(session));
  }

  console.log('🛠️ Supabase debug tools initialized');
};

// Ensure debug tools are initialized
setupDebugTools();

// Export supabase instance
export default supabase;
