import React, { useState, useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';
import api from '../../api';
import {
  ChartBarIcon,
  UsersIcon,
  DocumentTextIcon,
  MicrophoneIcon,
  PencilSquareIcon,
  ArrowTrendingUpIcon,
  CalendarIcon,
  MagnifyingGlassIcon,
  FunnelIcon,
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/24/outline';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Tab, Disclosure, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const AdminAnalyticsCard = ({ title, total, lastWeek, lastMonth, icon: Icon, color }) => {
  const { isDarkMode } = useTheme();
  
  const calculateTrend = () => {
    if (lastWeek === 0 && lastMonth === 0) return { value: 0, label: "No activity" };
    if (lastMonth === 0) return { value: 100, label: "New activity" };

    const weeklyAvg = lastWeek / 7;
    const monthlyAvg = lastMonth / 30;
    if (monthlyAvg === 0) return { value: 100, label: "New activity" };

    const percentChange = ((weeklyAvg - monthlyAvg) / monthlyAvg * 100);
    return {
      value: percentChange,
      label: `${Math.abs(percentChange).toFixed(0)}% ${percentChange > 0 ? 'increase' : 'decrease'}`
    };
  };

  const trend = calculateTrend();
  const isPositiveTrend = trend.value > 0;
  
  return (
    <div className={`
      relative rounded-lg p-3 sm:p-6 transition-all duration-200
      ${isDarkMode 
        ? 'bg-[#141414]/50 ring-1 ring-[#232323] hover:ring-[#333333]' 
        : 'bg-white/30 border border-slate-200/40 hover:border-slate-300/60'}
      hover:shadow-sm
    `}>
      <div className="flex items-center justify-between space-x-2">
        <h3 className={`text-xs sm:text-sm font-medium leading-none tracking-tight ${
          isDarkMode ? 'text-slate-200' : 'text-slate-900'
        }`}>
          {title}
        </h3>
        <Icon className={`h-3.5 w-3.5 sm:h-4 sm:w-4 ${color} opacity-70`} />
      </div>
      <p className={`text-lg sm:text-2xl font-bold mt-2 sm:mt-4 ${
        isDarkMode ? 'text-slate-200' : 'text-slate-900'
      }`}>
        {total.toLocaleString()}
      </p>
      
      <div className="mt-2 sm:mt-4 space-y-1 sm:space-y-2">
        <div className="flex items-center justify-between text-xs">
          <span className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>Last 7 days</span>
          <span className={`font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>
            {lastWeek.toLocaleString()}
          </span>
        </div>
        <div className="flex items-center justify-between text-xs">
          <span className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>Last 30 days</span>
          <span className={`font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>
            {lastMonth.toLocaleString()}
          </span>
        </div>
        
        <div className={`
          inline-flex items-center rounded-full px-1.5 sm:px-2 py-0.5 sm:py-1 text-[10px] sm:text-xs font-medium ring-1 ring-inset mt-1 sm:mt-2
          ${trend.value === 0 
            ? isDarkMode 
              ? 'bg-[#181818] text-slate-400 ring-[#232323]' 
              : 'bg-slate-100/40 text-slate-600 ring-slate-200'
            : isPositiveTrend
              ? 'bg-emerald-500/10 text-emerald-400 ring-emerald-500/20'
              : 'bg-rose-500/10 text-rose-400 ring-rose-500/20'
          }
        `}>
          {trend.value !== 0 && (
            <ArrowTrendingUpIcon 
              className={`w-2.5 h-2.5 sm:w-3 sm:h-3 mr-0.5 sm:mr-1 ${!isPositiveTrend && 'transform rotate-180'}`}
            />
          )}
          {trend.label}
        </div>
      </div>
    </div>
  );
};

const AnalyticsChart = ({ title, type, data, options, isDarkMode }) => {
  const ChartComponent = type === 'line' ? Line : type === 'bar' ? Bar : Pie;
  
  return (
    <div className={`
      p-3 sm:p-4 rounded-lg
      ${isDarkMode 
        ? 'bg-[#141414]/50 ring-1 ring-[#232323] hover:ring-[#333333]' 
        : 'bg-white/30 border border-slate-200/40 hover:border-slate-300/60'
      }
      transition-all duration-200 hover:shadow-sm
    `}>
      <h3 className={`
        text-[10px] sm:text-xs font-medium mb-2
        ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
      `}>
        {title}
      </h3>
      <div className="h-[140px] sm:h-[180px] relative">
        <ChartComponent 
          data={data} 
          options={{
            ...options,
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              ...options.plugins,
              legend: {
                ...options.plugins?.legend,
                labels: {
                  ...options.plugins?.legend?.labels,
                  boxWidth: 6,
                  padding: 8,
                  font: {
                    size: 8,
                    lineHeight: 1
                  }
                }
              }
            },
            scales: type !== 'pie' ? {
              ...options.scales,
              x: {
                ...options.scales?.x,
                ticks: {
                  ...options.scales?.x?.ticks,
                  font: {
                    size: 8
                  }
                }
              },
              y: {
                ...options.scales?.y,
                ticks: {
                  ...options.scales?.y?.ticks,
                  font: {
                    size: 8
                  }
                }
              }
            } : undefined
          }} 
        />
      </div>
    </div>
  );
};

const FilterControls = ({ filters, setFilters, isDarkMode }) => {
  const inputClass = `
    w-full px-3 py-2 text-sm rounded-lg
    ${isDarkMode 
      ? 'bg-[#1a1a1a] text-slate-200 ring-1 ring-[#333333] focus:ring-indigo-500' 
      : 'bg-slate-50 text-slate-900 border border-slate-200 focus:border-indigo-500'
    }
    focus:outline-none focus:ring-2 transition-colors duration-200
  `;

  const selectClass = `
    w-full px-3 py-2 text-sm rounded-lg appearance-none
    ${isDarkMode 
      ? 'bg-[#1a1a1a] text-slate-200 ring-1 ring-[#333333] focus:ring-indigo-500' 
      : 'bg-slate-50 text-slate-900 border border-slate-200 focus:border-indigo-500'
    }
    focus:outline-none focus:ring-2 transition-colors duration-200
  `;

  const labelClass = `block text-sm font-medium mb-1 ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`;

  const quickFilters = [
    { label: 'All Users', filters: { activityStatus: '', subscriptionStatus: '', minActivity: 0 } },
    { label: 'Active Subscribers', filters: { activityStatus: 'active', subscriptionStatus: 'subscribed', minActivity: 1 } },
    { label: 'Trial Users', filters: { subscriptionStatus: 'trial' } },
    { label: 'Inactive Users', filters: { activityStatus: 'inactive', minActivity: 0 } }
  ];

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className={`
            flex items-center justify-between w-full px-4 py-3 text-sm font-medium
            ${isDarkMode 
              ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#232323]' 
              : 'bg-slate-50 text-slate-700 hover:bg-slate-100'
            }
            rounded-lg transition-colors duration-200
          `}>
            <div className="flex items-center space-x-2">
              <AdjustmentsHorizontalIcon className="w-5 h-5" />
              <span>Filters & Controls</span>
              {(filters.search || filters.activityStatus || filters.subscriptionStatus || filters.minActivity > 0) && (
                <span className={`
                  inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium
                  ${isDarkMode 
                    ? 'bg-indigo-500/20 text-indigo-400' 
                    : 'bg-indigo-100 text-indigo-800'
                  }
                `}>
                  Active Filters
                </span>
              )}
            </div>
            {open ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </Disclosure.Button>

          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className={`
              p-4 mt-2 rounded-lg
              ${isDarkMode 
                ? 'bg-[#141414] ring-1 ring-[#232323]' 
                : 'bg-white border border-slate-200'
              }
            `}>
              {/* Quick Filters */}
              <div className="mb-4 flex flex-wrap gap-2">
                {quickFilters.map((quickFilter, index) => (
                  <button
                    key={index}
                    onClick={() => setFilters(prev => ({ ...prev, ...quickFilter.filters }))}
                    className={`
                      px-3 py-1 text-sm rounded-full transition-colors duration-200
                      ${isDarkMode
                        ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#232323] ring-1 ring-[#333333]'
                        : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
                      }
                    `}
                  >
                    {quickFilter.label}
                  </button>
                ))}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {/* Search Group */}
                <div className="lg:col-span-3">
                  <label className={labelClass}>
                    Search Users
                  </label>
                  <div className="relative">
                    <MagnifyingGlassIcon className={`
                      absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4
                      ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
                    `} />
                    <input
                      type="text"
                      placeholder="Search by name or email..."
                      value={filters.search}
                      onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
                      className={`${inputClass} pl-10`}
                    />
                  </div>
                </div>

                {/* Date Range Group */}
                <div className="lg:col-span-3 grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <label className={labelClass}>Date Field</label>
                    <select
                      value={filters.dateField}
                      onChange={(e) => setFilters(prev => ({ ...prev, dateField: e.target.value }))}
                      className={selectClass}
                    >
                      <option value="createdAt">Created Date</option>
                      <option value="lastUpdated">Last Updated</option>
                    </select>
                  </div>
                  <div>
                    <label className={labelClass}>Start Date</label>
                    <input
                      type="date"
                      value={filters.startDate}
                      onChange={(e) => setFilters(prev => ({ ...prev, startDate: e.target.value }))}
                      className={inputClass}
                    />
                  </div>
                  <div>
                    <label className={labelClass}>End Date</label>
                    <input
                      type="date"
                      value={filters.endDate}
                      onChange={(e) => setFilters(prev => ({ ...prev, endDate: e.target.value }))}
                      className={inputClass}
                    />
                  </div>
                </div>

                {/* Status Group */}
                <div>
                  <label className={labelClass}>Activity Status</label>
                  <select
                    value={filters.activityStatus}
                    onChange={(e) => setFilters(prev => ({ ...prev, activityStatus: e.target.value }))}
                    className={selectClass}
                  >
                    <option value="">All Users</option>
                    <option value="active">Active Users</option>
                    <option value="inactive">Inactive Users</option>
                  </select>
                </div>

                <div>
                  <label className={labelClass}>Subscription Status</label>
                  <select
                    value={filters.subscriptionStatus}
                    onChange={(e) => setFilters(prev => ({ ...prev, subscriptionStatus: e.target.value }))}
                    className={selectClass}
                  >
                    <option value="">All Subscriptions</option>
                    <option value="subscribed">Subscribed</option>
                    <option value="trial">Trial</option>
                    <option value="expired">Expired</option>
                  </select>
                </div>

                <div>
                  <label className={labelClass}>Minimum Activity</label>
                  <input
                    type="number"
                    min="0"
                    value={filters.minActivity}
                    onChange={(e) => setFilters(prev => ({ ...prev, minActivity: e.target.value }))}
                    className={inputClass}
                    placeholder="0"
                  />
                </div>

                {/* Sort Group */}
                <div>
                  <label className={labelClass}>Sort By</label>
                  <select
                    value={filters.sortBy}
                    onChange={(e) => setFilters(prev => ({ ...prev, sortBy: e.target.value }))}
                    className={selectClass}
                  >
                    <option value="lastUpdated">Last Updated</option>
                    <option value="name">Name</option>
                    <option value="activity">Activity</option>
                    <option value="createdAt">Created Date</option>
                  </select>
                </div>

                <div>
                  <label className={labelClass}>Sort Order</label>
                  <select
                    value={filters.sortOrder}
                    onChange={(e) => setFilters(prev => ({ ...prev, sortOrder: e.target.value }))}
                    className={selectClass}
                  >
                    <option value="desc">Descending</option>
                    <option value="asc">Ascending</option>
                  </select>
                </div>

                <div>
                  <label className={labelClass}>Items Per Page</label>
                  <select
                    value={filters.limit}
                    onChange={(e) => setFilters(prev => ({ ...prev, limit: e.target.value }))}
                    className={selectClass}
                  >
                    <option value="10">10 per page</option>
                    <option value="25">25 per page</option>
                    <option value="50">50 per page</option>
                    <option value="100">100 per page</option>
                  </select>
                </div>
              </div>

              {/* Reset Filters */}
              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => setFilters({
                    search: '',
                    startDate: '',
                    endDate: '',
                    dateField: 'createdAt',
                    sortBy: 'lastUpdated',
                    sortOrder: 'desc',
                    activityStatus: '',
                    subscriptionStatus: '',
                    minActivity: 0,
                    limit: 25,
                    page: 1
                  })}
                  className={`
                    px-4 py-2 text-sm rounded-lg transition-colors duration-200
                    ${isDarkMode
                      ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#232323] ring-1 ring-[#333333]'
                      : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
                    }
                  `}
                >
                  Reset Filters
                </button>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

const UserActivityTable = ({ users, isDarkMode, pagination, onPageChange, filters, setFilters }) => {
  const { page, totalPages, total } = pagination;
  const [localUsers, setLocalUsers] = useState(users);
  const [localSort, setLocalSort] = useState({ field: filters.sortBy, order: filters.sortOrder });

  useEffect(() => {
    setLocalUsers(users);
    setLocalSort({ field: filters.sortBy, order: filters.sortOrder });
  }, [users, filters.sortBy, filters.sortOrder]);

  const handleSort = (field) => {
    const newOrder = localSort.field === field && localSort.order === 'asc' ? 'desc' : 'asc';
    setLocalSort({ field, order: newOrder });
    
    const sortedUsers = [...localUsers].sort((a, b) => {
      let aValue, bValue;
      
      switch (field) {
        case 'name':
          aValue = a.user.name.toLowerCase();
          bValue = b.user.name.toLowerCase();
          break;
        case 'status':
          aValue = a.user.isSubscribed ? 'subscribed' : (a.user.isInTrial ? 'trial' : 'expired');
          bValue = b.user.isSubscribed ? 'subscribed' : (b.user.isInTrial ? 'trial' : 'expired');
          break;
        case 'words':
          aValue = a.wordCounts.total;
          bValue = b.wordCounts.total;
          break;
        case 'voiceNotes':
          aValue = a.voiceNotes.total;
          bValue = b.voiceNotes.total;
          break;
        case 'textNotes':
          aValue = a.textNotes.total;
          bValue = b.textNotes.total;
          break;
        case 'posts':
          aValue = a.canvasPosts.total;
          bValue = b.canvasPosts.total;
          break;
        case 'lastActive':
          aValue = new Date(a.lastUpdated).getTime();
          bValue = new Date(b.lastUpdated).getTime();
          break;
        default:
          return 0;
      }

      if (typeof aValue === 'string') {
        return newOrder === 'asc' 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      return newOrder === 'asc' ? aValue - bValue : bValue - aValue;
    });

    setLocalUsers(sortedUsers);
  };

  const getSortIcon = (field) => {
    if (localSort.field !== field) {
      return null;
    }
    return localSort.order === 'asc' ? (
      <ChevronUpIcon className="w-4 h-4 ml-1" />
    ) : (
      <ChevronDownIcon className="w-4 h-4 ml-1" />
    );
  };

  const headerClass = `
    px-6 py-3 text-left text-xs font-medium uppercase tracking-wider cursor-pointer
    ${isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-slate-500 hover:text-slate-700'}
    transition-colors duration-150
  `;

  return (
    <div className={`
      rounded-lg overflow-hidden
      ${isDarkMode 
        ? 'bg-[#141414]/50 ring-1 ring-[#232323]' 
        : 'bg-white border border-slate-200'
      }
    `}>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className={isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-50'}>
            <tr>
              <th 
                className={headerClass}
                onClick={() => handleSort('name')}
              >
                <div className="flex items-center">
                  User
                  {getSortIcon('name')}
                </div>
              </th>
              <th 
                className={headerClass}
                onClick={() => handleSort('status')}
              >
                <div className="flex items-center">
                  Status
                  {getSortIcon('status')}
                </div>
              </th>
              <th 
                className={headerClass}
                onClick={() => handleSort('words')}
              >
                <div className="flex items-center">
                  Words
                  {getSortIcon('words')}
                </div>
              </th>
              <th 
                className={headerClass}
                onClick={() => handleSort('voiceNotes')}
              >
                <div className="flex items-center">
                  Voice Notes
                  {getSortIcon('voiceNotes')}
                </div>
              </th>
              <th 
                className={headerClass}
                onClick={() => handleSort('textNotes')}
              >
                <div className="flex items-center">
                  Text Notes
                  {getSortIcon('textNotes')}
                </div>
              </th>
              <th 
                className={headerClass}
                onClick={() => handleSort('posts')}
              >
                <div className="flex items-center">
                  Posts
                  {getSortIcon('posts')}
                </div>
              </th>
              <th 
                className={headerClass}
                onClick={() => handleSort('lastActive')}
              >
                <div className="flex items-center">
                  Last Active
                  {getSortIcon('lastActive')}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className={`
            divide-y
            ${isDarkMode 
              ? 'divide-[#232323] text-slate-300' 
              : 'divide-slate-200 text-slate-600'
            }
          `}>
            {localUsers.map((user) => (
              <tr 
                key={user.user.id}
                className={`
                  hover:bg-opacity-50 transition-colors duration-150
                  ${isDarkMode ? 'hover:bg-[#1a1a1a]' : 'hover:bg-slate-50'}
                `}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div>
                      <div className={`
                        text-sm font-medium
                        ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
                      `}>
                        {user.user.name}
                      </div>
                      <div className="text-sm text-slate-500">{user.user.email}</div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex flex-col space-y-1">
                    {user.user.isSubscribed && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Subscribed
                      </span>
                    )}
                    {user.user.isInTrial && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        Trial
                      </span>
                    )}
                    {user.user.hasTrialExpired && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        Expired
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {user.wordCounts.total.toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {user.voiceNotes.total.toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {user.textNotes.total.toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {user.canvasPosts.total.toLocaleString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {format(new Date(user.lastUpdated), 'MMM d, yyyy HH:mm')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Enhanced Pagination */}
      <div className={`
        px-6 py-4 flex items-center justify-between border-t
        ${isDarkMode ? 'border-[#232323]' : 'border-slate-200'}
      `}>
        <div className="flex items-center gap-4">
          <div className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
            <span className="font-medium">{((page - 1) * filters.limit) + 1}</span>
            -
            <span className="font-medium">
              {Math.min(page * filters.limit, total)}
            </span> of{' '}
            <span className="font-medium">{total}</span> results
          </div>
          
          <select
            value={filters.limit}
            onChange={(e) => {
              setFilters(prev => ({ 
                ...prev, 
                limit: Number(e.target.value),
                page: 1 
              }))
            }}
            className={`
              text-sm rounded-md border-0 py-1.5 pl-3 pr-8
              ${isDarkMode 
                ? 'bg-[#1a1a1a] text-slate-300 ring-1 ring-[#333333] focus:ring-indigo-500' 
                : 'bg-slate-50 text-slate-900 ring-1 ring-slate-200 focus:ring-indigo-500'
              }
              focus:outline-none focus:ring-2 transition-colors duration-200
            `}
          >
            <option value={10}>10 / page</option>
            <option value={25}>25 / page</option>
            <option value={50}>50 / page</option>
            <option value={100}>100 / page</option>
          </select>
        </div>

        <div className="flex items-center gap-2">
          <button
            onClick={() => onPageChange(1)}
            disabled={page === 1}
            className={`
              p-1.5 rounded-md
              ${isDarkMode 
                ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#232323] disabled:bg-[#1a1a1a]' 
                : 'bg-slate-100 text-slate-700 hover:bg-slate-200 disabled:bg-slate-50'
              }
              disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150
            `}
            title="First Page"
          >
            <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
            </svg>
          </button>

          <button
            onClick={() => onPageChange(page - 1)}
            disabled={page === 1}
            className={`
              p-1.5 rounded-md
              ${isDarkMode 
                ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#232323] disabled:bg-[#1a1a1a]' 
                : 'bg-slate-100 text-slate-700 hover:bg-slate-200 disabled:bg-slate-50'
              }
              disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150
            `}
            title="Previous Page"
          >
            <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          <span className={`
            text-sm font-medium px-3 py-1.5 rounded-md
            ${isDarkMode 
              ? 'bg-[#1a1a1a] text-slate-300' 
              : 'bg-slate-100 text-slate-700'
            }
          `}>
            {page} / {totalPages}
          </span>

          <button
            onClick={() => onPageChange(page + 1)}
            disabled={page === totalPages}
            className={`
              p-1.5 rounded-md
              ${isDarkMode 
                ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#232323] disabled:bg-[#1a1a1a]' 
                : 'bg-slate-100 text-slate-700 hover:bg-slate-200 disabled:bg-slate-50'
              }
              disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150
            `}
            title="Next Page"
          >
            <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>

          <button
            onClick={() => onPageChange(totalPages)}
            disabled={page === totalPages}
            className={`
              p-1.5 rounded-md
              ${isDarkMode 
                ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#232323] disabled:bg-[#1a1a1a]' 
                : 'bg-slate-100 text-slate-700 hover:bg-slate-200 disabled:bg-slate-50'
              }
              disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150
            `}
            title="Last Page"
          >
            <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

const AdminAnalytics = () => {
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isDarkMode } = useTheme();
  const [filters, setFilters] = useState({
    search: '',
    startDate: '',
    endDate: '',
    dateField: 'createdAt',
    sortBy: 'lastUpdated',
    sortOrder: 'desc',
    activityStatus: '',
    subscriptionStatus: '',
    minActivity: 0,
    limit: 10,
    page: 1
  });

  // Add default chart data
  const defaultChartData = {
    userGrowth: {
      labels: [],
      data: []
    },
    dailyActivity: {
      labels: [],
      words: [],
      voiceNotes: [],
      textNotes: [],
      posts: []
    }
  };

  // Add loading skeleton for charts
  const ChartSkeleton = () => (
    <div className={`
      animate-pulse p-4 rounded-lg h-[220px]
      ${isDarkMode 
        ? 'bg-[#141414]/50 ring-1 ring-[#232323]' 
        : 'bg-white/30 border border-slate-200/40'
      }
    `}>
      <div className={`h-3 w-1/4 rounded ${isDarkMode ? 'bg-[#232323]' : 'bg-slate-200'}`} />
      <div className="mt-2 space-y-3">
        <div className={`h-[180px] rounded ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-100'}`} />
      </div>
    </div>
  );

  const fetchAnalytics = async () => {
      try {
        setLoading(true);
      const queryParams = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (value) queryParams.append(key, value);
      });
      
      const response = await api.get(`/api/admin/analytics/overview?${queryParams.toString()}`);
        setAnalytics(response.data);
      setError(null);
      } catch (err) {
        console.error('Error fetching admin analytics:', err);
        setError(err.response?.data?.message || 'Failed to load analytics');
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => {
    fetchAnalytics();
  }, [filters]);

  const handlePageChange = (newPage) => {
    setFilters(prev => ({ ...prev, page: newPage }));
  };

  if (loading) {
    return (
      <div className={`
        flex flex-col h-full relative overflow-hidden p-6 space-y-6
        ${isDarkMode 
          ? 'bg-gradient-to-b from-[#121212] to-[#141414]' 
          : 'bg-gradient-to-b from-white to-gray-50'
        }
      `}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <ChartSkeleton />
          <ChartSkeleton />
          <ChartSkeleton />
          <ChartSkeleton />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`
        flex items-center justify-center h-screen
        ${isDarkMode ? 'text-slate-300' : 'text-slate-800'}
      `}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className={`
      flex flex-col h-full relative overflow-hidden
      ${isDarkMode 
        ? 'bg-gradient-to-b from-[#121212] to-[#141414]' 
        : 'bg-gradient-to-b from-white to-gray-50'
      }
    `}>
      {/* Top Bar - Made more mobile friendly */}
      <div className={`
        ${isDarkMode 
          ? 'bg-[#141414]/80 border-[#232323] backdrop-blur supports-[backdrop-filter]:bg-opacity-80' 
          : 'bg-white/95 border-slate-200/40'
        } 
        sticky top-0 z-40 flex-shrink-0 
        px-4 py-3 w-full border-b transition-colors duration-200
      `}>
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
        <div className="flex items-center gap-2">
          <ChartBarIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`} />
            <h2 className={`text-base sm:text-lg font-medium ${isDarkMode ? 'text-slate-200' : 'text-slate-800'}`}>
            Admin Analytics
          </h2>
        </div>

          <div className="flex items-center gap-2 text-xs sm:text-sm">
            <CalendarIcon className={`h-3.5 w-3.5 sm:h-4 sm:w-4 ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`} />
            <span className={`${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
              Updated: {format(new Date(analytics.lastUpdated), 'MMM d, HH:mm')}
          </span>
          </div>
        </div>
      </div>

      {/* Content - Improved mobile layout */}
      <div className="flex-grow overflow-y-auto p-3 sm:p-6 space-y-4 sm:space-y-6">
        {/* Filters */}
        <FilterControls 
          filters={filters}
          setFilters={setFilters}
          isDarkMode={isDarkMode}
        />

        {/* Analytics Charts - Better mobile grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4">
          {/* User Growth Chart */}
          <AnalyticsChart
            title="User Distribution"
            type="pie"
            isDarkMode={isDarkMode}
            data={{
              labels: ['Active', 'Trial', 'Inactive'],
              datasets: [{
                data: [
                  analytics.totalStats.activeUsers,
                  analytics.totalStats.trialUsers,
                  analytics.totalStats.users - analytics.totalStats.activeUsers
                ],
                backgroundColor: isDarkMode 
                  ? ['#34d399', '#818cf8', '#f87171']
                  : ['#059669', '#4f46e5', '#dc2626']
              }]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    boxWidth: 8,
                    padding: 10,
                    font: { size: 10 }
                  }
                }
              }
            }}
          />

          {/* Activity Distribution Chart */}
          <AnalyticsChart
            title="Activity Distribution"
            type="bar"
            isDarkMode={isDarkMode}
            data={{
              labels: ['Words', 'Voice', 'Text', 'Posts'],
              datasets: [{
                data: [
                  analytics.totalStats.wordCounts.total,
                  analytics.totalStats.voiceNotes.total,
                  analytics.totalStats.textNotes.total,
                  analytics.totalStats.canvasPosts.total
                ],
                backgroundColor: isDarkMode 
                  ? ['#818cf8', '#34d399', '#fbbf24', '#f87171']
                  : ['#4f46e5', '#059669', '#d97706', '#dc2626']
              }]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    font: { size: 10 }
                  }
                },
                x: {
                  ticks: {
                    font: { size: 10 }
                  }
                }
              }
            }}
          />

          {/* Weekly vs Monthly Activity */}
          <AnalyticsChart
            title="Weekly vs Monthly Activity"
            type="bar"
            isDarkMode={isDarkMode}
            data={{
              labels: ['Words', 'Voice', 'Text', 'Posts'],
              datasets: [
                {
                  label: 'Last Week',
                  data: [
                    analytics.totalStats.wordCounts.lastWeek,
                    analytics.totalStats.voiceNotes.lastWeek,
                    analytics.totalStats.textNotes.lastWeek,
                    analytics.totalStats.canvasPosts.lastWeek
                  ],
                  backgroundColor: isDarkMode ? '#34d399' : '#059669'
                },
                {
                  label: 'Last Month',
                  data: [
                    analytics.totalStats.wordCounts.lastMonth,
                    analytics.totalStats.voiceNotes.lastMonth,
                    analytics.totalStats.textNotes.lastMonth,
                    analytics.totalStats.canvasPosts.lastMonth
                  ],
                  backgroundColor: isDarkMode ? '#818cf8' : '#4f46e5'
                }
              ]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    boxWidth: 8,
                    padding: 10,
                    font: { size: 10 }
                  }
                }
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    font: { size: 10 }
                  }
                },
                x: {
                  ticks: {
                    font: { size: 10 }
                  }
                }
              }
            }}
          />

          {/* Subscription Status */}
          <AnalyticsChart
            title="Subscription Status"
            type="pie"
            isDarkMode={isDarkMode}
            data={{
              labels: ['Subscribed', 'Trial', 'Expired'],
              datasets: [{
                data: [
                  analytics.totalStats.subscribedUsers,
                  analytics.totalStats.trialUsers,
                  analytics.totalStats.users - (analytics.totalStats.subscribedUsers + analytics.totalStats.trialUsers)
                ],
                backgroundColor: isDarkMode 
                  ? ['#34d399', '#818cf8', '#f87171']
                  : ['#059669', '#4f46e5', '#dc2626']
              }]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    boxWidth: 8,
                    padding: 10,
                    font: { size: 10 }
                  }
                }
              }
            }}
          />
        </div>

        {/* Overview Cards - Improved mobile grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-3 sm:gap-6">
          <AdminAnalyticsCard
            title="Total Users"
            total={analytics.totalStats.users}
            lastWeek={analytics.totalStats.activeUsers}
            lastMonth={analytics.totalStats.subscribedUsers}
            icon={UsersIcon}
            color={isDarkMode ? 'text-purple-400' : 'text-purple-500'}
          />
          
          <AdminAnalyticsCard
            title="Words Generated"
            total={analytics.totalStats.wordCounts.total}
            lastWeek={analytics.totalStats.wordCounts.lastWeek}
            lastMonth={analytics.totalStats.wordCounts.lastMonth}
            icon={DocumentTextIcon}
            color={isDarkMode ? 'text-amber-400' : 'text-amber-500'}
          />
          
          <AdminAnalyticsCard
            title="Voice Notes"
            total={analytics.totalStats.voiceNotes.total}
            lastWeek={analytics.totalStats.voiceNotes.lastWeek}
            lastMonth={analytics.totalStats.voiceNotes.lastMonth}
            icon={MicrophoneIcon}
            color={isDarkMode ? 'text-blue-400' : 'text-blue-500'}
          />
          
          <AdminAnalyticsCard
            title="Text Notes"
            total={analytics.totalStats.textNotes.total}
            lastWeek={analytics.totalStats.textNotes.lastWeek}
            lastMonth={analytics.totalStats.textNotes.lastMonth}
            icon={DocumentTextIcon}
            color={isDarkMode ? 'text-green-400' : 'text-green-500'}
          />

          <AdminAnalyticsCard
            title="Posts Created"
            total={analytics.totalStats.canvasPosts.total}
            lastWeek={analytics.totalStats.canvasPosts.lastWeek}
            lastMonth={analytics.totalStats.canvasPosts.lastMonth}
            icon={PencilSquareIcon}
            color={isDarkMode ? 'text-amber-400' : 'text-amber-500'}
          />
        </div>

        {/* User Activity Table - Mobile optimized */}
        <div className="overflow-hidden rounded-lg">
          <div className="overflow-x-auto -mx-3 sm:mx-0">
            <div className="min-w-[800px] sm:min-w-full">
        <UserActivityTable 
          users={analytics.userActivityTrends} 
          isDarkMode={isDarkMode} 
                pagination={analytics.pagination}
                onPageChange={handlePageChange}
                filters={filters}
                setFilters={setFilters}
        />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAnalytics; 