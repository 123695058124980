import React, { useState, useEffect, useRef, useMemo } from 'react';
import { XMarkIcon, MagnifyingGlassIcon, ChevronDownIcon, Squares2X2Icon, PlusIcon, ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import { 
  fetchTemplates, 
  fetchUserTemplates, 
  createUserTemplate, 
  updateUserTemplate 
} from '../api';
import Masonry from 'react-masonry-css';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import DropdownPortal from './common/DropdownPortal';
import toast from 'react-hot-toast';

const templateNameOptions = [
  'Industry Term Redefined', 'The Myth Buster', 'The Wake-Up Call',
  'The No-BS Guide', 'The Reality vs. Expectation', 'The Uncomfortable Truth',
  'The Brutal Honesty', 'The Sacred Cow Slaughter', 'The Tough Love',
  'The Contrarian View', 'The Simplification', 'The Reality Check'
];

const categoryOptions = [
  'LinkedIn Post', 'Twitter Thread', 'Blog Post', 'Newsletter', 'Sales Copy'
];

const toneOptions = [
  'Professional', 'Casual', 'Inspirational', 'Educational', 'Persuasive',
  'Storytelling', 'Humorous', 'Technical', 'Conversational', 'Formal'
];

const industryOptions = [
  'Technology', 'Marketing', 'Sales', 'Healthcare', 'Finance',
  'Education', 'E-commerce', 'Real Estate', 'Consulting', 'General'
];

const sourceOptions = [
  'All Templates', 'Default Templates', 'My Templates'
];

// Update the getBreakpointColumns function for better responsiveness
const getBreakpointColumns = (containerWidth) => {
  // More granular breakpoints for better responsiveness
  if (containerWidth < 640) return 1; // Mobile
  if (containerWidth < 768) return 2; // Small tablets
  if (containerWidth < 1024) return 3; // Large tablets
  if (containerWidth < 1280) return 4; // Small desktop
  return 5; // Large desktop
};

const highlightSearchTerm = (content, searchTerm) => {
  if (!searchTerm) return content;
  try {
    const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
    return content.replace(regex, '<mark class="bg-yellow-200 dark:bg-yellow-800 rounded px-0.5">$1</mark>');
  } catch (error) {
    console.error('Error highlighting search term:', error);
    return content;
  }
};

// Change the closeAllPopups event name to be more specific
const closeAllDropdowns = new CustomEvent('closeAllDropdowns');

const dropdownVariants = {
  hidden: { 
    opacity: 0, 
    scale: 0.95,
    y: -10,
    transition: { duration: 0.15, ease: [0.4, 0, 0.2, 1] }
  },
  visible: { 
    opacity: 1, 
    scale: 1,
    y: 0,
    transition: { duration: 0.2, ease: [0.4, 0, 0.2, 1] }
  },
  exit: { 
    opacity: 0, 
    scale: 0.95,
    y: -10,
    transition: { duration: 0.15, ease: [0.4, 0, 0.2, 1] }
  }
};

const FilterDropdown = ({ options, selectedOptions, onChange, label, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleCloseDropdowns = () => setIsOpen(false);
    document.addEventListener('closeAllDropdowns', handleCloseDropdowns);
    return () => document.removeEventListener('closeAllDropdowns', handleCloseDropdowns);
  }, []);

  const handleOpen = (e) => {
    e.stopPropagation();
    document.dispatchEvent(closeAllDropdowns);
    setIsOpen(true);
  };

  return (
    <div className="relative">
      <motion.button
        ref={buttonRef}
        whileTap={{ scale: 0.97 }}
        onClick={handleOpen}
        className={`
          inline-flex items-center justify-center
          text-[11px] font-medium tracking-wide
          h-[30px] px-2.5 rounded-md
          border transition-all duration-200
          ${isDarkMode 
            ? `${selectedOptions.length > 0 
                ? 'bg-[#1e1e1e] border-[#333333] text-blue-400' 
                : 'bg-[#1e1e1e] border-[#333333] text-slate-400 hover:bg-[#2a2a2a]'}`
            : `${selectedOptions.length > 0 
                ? 'bg-black border-black text-white' 
                : 'bg-white border-black/10 text-black/70 hover:border-black/20'}`
          }
          touch-manipulation
        `}
      >
        <span className="mr-1">{label}</span>
        {selectedOptions.length > 0 && (
          <span className={`
            px-1 rounded text-[10px] font-semibold min-w-[16px] text-center
            ${isDarkMode 
              ? 'bg-indigo-500/30 text-indigo-200' 
              : 'bg-indigo-100 text-indigo-700'}
          `}>
            {selectedOptions.length}
          </span>
        )}
        <ChevronDownIcon className="ml-1 h-3 w-3" />
      </motion.button>
      
      <DropdownPortal
        targetRef={buttonRef}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={`
            rounded-lg shadow-lg max-h-[60vh] overflow-y-auto
            ${isDarkMode 
              ? 'bg-[#151515] border border-[#333333]' 
              : 'bg-white border border-black/5'}
          `}
        >
          <div className="p-1.5">
            {options.map((option) => (
              <motion.label 
                key={typeof option === 'string' ? option : option.name}
                whileHover={{ x: 2 }}
                whileTap={{ scale: 0.98 }}
                className={`
                  relative flex cursor-pointer select-none items-center
                  rounded-lg px-3 py-2 text-[13px]
                  transition-colors duration-200 touch-manipulation
                  ${isDarkMode 
                    ? 'hover:bg-[#1e1e1e]' 
                    : 'hover:bg-black/5'}
                `}
              >
                {option.image && (
                  <img 
                    src={option.image} 
                    alt={option.name}
                    className="w-5 h-5 rounded-full object-cover mr-2"
                  />
                )}
                <motion.div
                  initial={false}
                  animate={{
                    backgroundColor: selectedOptions.includes(option.name || option) 
                      ? isDarkMode ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'
                      : 'transparent'
                  }}
                  className={`
                    flex h-3.5 w-3.5 items-center justify-center rounded-full border
                    ${isDarkMode 
                      ? 'border-white/30' 
                      : 'border-black/30'}
                  `}
                >
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ 
                      scale: selectedOptions.includes(option.name || option) ? 1 : 0 
                    }}
                    className={`
                      h-2 w-2 rounded-full
                      ${isDarkMode ? 'bg-black' : 'bg-white'}
                    `}
                  />
                </motion.div>
                <span className={`
                  ml-2.5 transition-all duration-200
                  ${isDarkMode ? 'text-slate-200' : 'text-black/90'}
                  ${selectedOptions.includes(option.name || option) 
                    ? 'font-medium translate-x-0.5' 
                    : 'font-normal'}
                `}>
                  {option.name || option}
                </span>
                <input
                  type="checkbox"
                  className="hidden"
                  checked={selectedOptions.includes(option.name || option)}
                  onChange={() => onChange(option.name || option)}
                />
              </motion.label>
            ))}
          </div>
        </motion.div>
      </DropdownPortal>
    </div>
  );
};

const TopBar = ({ 
  selectedCategories, 
  selectedIndustries,
  selectedTones,
  selectedSource,
  onSelectCategory, 
  onSelectIndustry,
  onSelectTone,
  onSelectSource,
  isDarkMode,
  searchTerm,
  setSearchTerm,
  totalTemplates,
  filteredCount,
  onClose,
  isSearchOpen,
  setIsSearchOpen,
  isMobile,
  onCreateTemplate
}) => {
  return (
    <div className={`
      w-full border-b sticky top-0 z-50
      ${isDarkMode ? 'bg-[#121212] border-[#333333]' : 'bg-white border-black/5'}
    `}>
      {/* Title Bar with count and search */}
      <div className={`
        flex items-center justify-between px-2.5 h-[46px] border-b
        ${isDarkMode ? 'border-[#333333]' : 'border-black/5'}
      `}>
        {/* Hide title and count when search is open on mobile */}
        {(!isSearchOpen || !isMobile) && (
          <div className="flex items-center space-x-2">
            <Squares2X2Icon className={`h-3.5 w-3.5 ${isDarkMode ? 'text-slate-400' : 'text-black/70'}`} />
            <h2 className={`text-xs font-medium ${isDarkMode ? 'text-slate-200' : 'text-black/90'}`}>
              {isMobile ? 'Templates' : 'Choose a Template'}
            </h2>
            <span className={`
              text-[10px] font-medium px-1.5 py-0.5 rounded-full
              ${isDarkMode 
                ? 'bg-[#1e1e1e] text-slate-400 border border-[#333333]' 
                : 'bg-gray-100 text-black/40 border border-gray-200'
              }
            `}>
              {filteredCount} / {totalTemplates}
            </span>
          </div>
        )}

        {/* Search and close buttons */}
        <div className={`flex items-center gap-1.5 ${isMobile && isSearchOpen ? 'w-full' : ''}`}>
          {isSearchOpen ? (
            <div className={`
              flex items-center w-full relative
              animate-in slide-in-from-right-5 duration-200
            `}>
              <MagnifyingGlassIcon className={`
                absolute left-2.5 w-3.5 h-3.5 pointer-events-none
                ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}
              `} />
              
              <input
                type="text"
                placeholder="Search templates..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`
                  w-full pl-7 pr-7 py-1.5 text-xs rounded-md
                  transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-[#1e1e1e] border-[#333333] text-slate-200 placeholder-slate-400' 
                    : 'bg-gray-100 border-gray-200 text-black/90 placeholder-black/30'
                  } border focus:outline-none focus:ring-1 focus:ring-blue-500
                `}
                autoFocus
              />

              <div className="flex items-center absolute right-1">
                <button
                  onClick={() => {
                    setIsSearchOpen(false);
                    setSearchTerm('');
                  }}
                  className={`
                    p-1.5 rounded-full
                    ${isDarkMode 
                      ? 'hover:bg-[#2a2a2a] text-slate-400 hover:text-slate-300' 
                      : 'hover:bg-gray-200 text-gray-500 hover:text-gray-700'
                    }
                  `}
                >
                  <XMarkIcon className="w-3.5 h-3.5" />
                </button>
              </div>
            </div>
          ) : (
            <>
              <button
                onClick={onCreateTemplate}
                className={`
                  flex items-center gap-1.5 px-2.5 h-7 rounded-md text-xs font-medium
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] hover:bg-[#252525] text-slate-200 border border-[#333333]' 
                    : 'bg-black hover:bg-black/90 text-white'}
                `}
              >
                <PlusIcon className="w-3.5 h-3.5" />
                <span>{isMobile ? 'New' : 'New Template'}</span>
              </button>
              <button
                onClick={() => setIsSearchOpen(true)}
                className={`
                  p-1.5 rounded-md transition-colors duration-200
                  ${isDarkMode 
                    ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-300' 
                    : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
                  }
                `}
                title="Search templates"
              >
                <MagnifyingGlassIcon className="w-4 h-4" />
              </button>
              <button 
                onClick={onClose}
                className={`
                  p-1.5 rounded-full transition-colors
                  ${isDarkMode ? 'hover:bg-[#1e1e1e]' : 'hover:bg-black/5'}
                `}
              >
                <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-black/70'}`} />
              </button>
            </>
          )}
        </div>
      </div>

      {/* Hide filters when search is open on mobile */}
      {(!isSearchOpen || !isMobile) && (
        <div className={`
          px-2.5 py-2 
          ${isMobile ? 'overflow-x-auto scrollbar-hide' : ''}
        `}>
          <div className={`
            flex items-center gap-1.5 
            ${isMobile ? 'min-w-max pb-1' : 'justify-center'}
          `}>
            <FilterDropdown
              options={sourceOptions}
              selectedOptions={selectedSource ? [selectedSource] : []}
              onChange={(source) => onSelectSource(source === selectedSource ? null : source)}
              label="Source"
              isDarkMode={isDarkMode}
            />
            <FilterDropdown
              options={categoryOptions}
              selectedOptions={selectedCategories}
              onChange={onSelectCategory}
              label="Type"
              isDarkMode={isDarkMode}
            />
            <FilterDropdown
              options={industryOptions}
              selectedOptions={selectedIndustries}
              onChange={onSelectIndustry}
              label="Industry"
              isDarkMode={isDarkMode}
            />
            <FilterDropdown
              options={toneOptions}
              selectedOptions={selectedTones}
              onChange={onSelectTone}
              label="Tone"
              isDarkMode={isDarkMode}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const NoResultsState = ({ searchTerm, isDarkMode }) => (
  <div className="flex flex-col items-center justify-center h-full p-8 text-center">
    <MagnifyingGlassIcon className={`h-16 w-16 ${isDarkMode ? 'text-[#333333]' : 'text-gray-300'} mb-4`} />
    <h3 className={`text-lg font-medium mb-2 ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
      No templates found
    </h3>
    <p className={`text-sm max-w-md ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
      {searchTerm 
        ? `We couldn't find any templates matching "${searchTerm}"`
        : "No templates match the selected filters"
      }
    </p>
  </div>
);

const TemplateForm = ({ template, onSave, onClose, isDarkMode }) => {
  const [formData, setFormData] = useState({
    title: template?.title || '',
    type: template?.type || categoryOptions[0],
    industry: template?.industry || industryOptions[0],
    tone: template?.tone || toneOptions[0],
    content: template?.content || ''
  });

  const isMobile = useMediaQuery({ maxWidth: 639 });

  return (
    <div className="fixed inset-0 z-50">
      {/* Overlay */}
      <div 
        className={`
          absolute inset-0 transition-opacity duration-300
          ${isDarkMode 
            ? 'bg-black/70 backdrop-blur-sm' 
            : 'bg-white/80 backdrop-blur-sm'
          }
        `}
        onClick={onClose}
      />

      {/* Modal */}
      <div className="fixed inset-0 overflow-y-auto">
        <div className="min-h-full flex items-end sm:items-center justify-center p-0 sm:p-4">
          <div 
            className={`
              relative w-full sm:max-w-2xl transform transition-all
              ${isMobile ? 'min-h-[85vh] rounded-t-2xl' : 'rounded-xl'}
              ${isDarkMode 
                ? 'bg-[#1a1a1a] border-t border-[#333333] sm:border' 
                : 'bg-white border-t border-gray-200 sm:border'
              }
            `}
          >
            {/* Header */}
            <div className={`
              flex items-center justify-between 
              ${isMobile ? 'px-4 py-3' : 'px-6 py-4'} 
              border-b relative
              ${isDarkMode ? 'border-[#333333]' : 'border-gray-100'}
            `}>
              {/* Drag Handle for Mobile */}
              {isMobile && (
                <div className="absolute top-1 left-1/2 -translate-x-1/2 w-8 h-1 rounded-full bg-gray-300 dark:bg-gray-600" />
              )}
              
              <h3 className={`
                ${isMobile ? 'text-sm' : 'text-base'}
                font-semibold mt-2
                ${isDarkMode ? 'text-white' : 'text-gray-900'}
              `}>
                {template ? 'Edit Template' : 'Create New Template'}
              </h3>
              <button
                onClick={onClose}
                className={`
                  rounded-full p-1.5 -mr-1 transition-colors duration-200
                  ${isDarkMode 
                    ? 'hover:bg-[#333333] text-gray-400 hover:text-gray-300' 
                    : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
                  }
                `}
              >
                <XMarkIcon className={`${isMobile ? 'w-4 h-4' : 'w-5 h-5'}`} />
              </button>
            </div>

            {/* Form */}
            <div className={`${isMobile ? 'p-4 space-y-4' : 'p-6 space-y-5'}`}>
              {/* Title Input */}
              <div className="space-y-1.5">
                <label className={`
                  block ${isMobile ? 'text-xs' : 'text-sm'} font-medium
                  ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}
                `}>
                  Title
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className={`
                    w-full px-3 py-2 rounded-md
                    ${isMobile ? 'text-xs' : 'text-sm'}
                    transition-shadow duration-200
                    ${isDarkMode 
                      ? 'bg-[#222222] border-[#333333] text-white focus:border-[#444444]' 
                      : 'bg-white border-gray-300 text-gray-900 focus:border-gray-400'
                    }
                    border focus:outline-none focus:ring-2 focus:ring-blue-500/20
                  `}
                  placeholder="Enter template title..."
                />
              </div>

              {/* Dropdowns Grid */}
              <div className={`grid ${isMobile ? 'grid-cols-2 gap-3' : 'grid-cols-3 gap-4'}`}>
                {/* Type Select */}
                <div className="space-y-1.5">
                  <label className={`
                    block ${isMobile ? 'text-xs' : 'text-sm'} font-medium
                    ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}
                  `}>
                    Type
                  </label>
                  <select
                    value={formData.type}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    className={`
                      w-full px-3 py-2 rounded-md
                      ${isMobile ? 'text-xs' : 'text-sm'}
                      transition-shadow duration-200 appearance-none
                      ${isDarkMode 
                        ? 'bg-[#222222] border-[#333333] text-white focus:border-[#444444]' 
                        : 'bg-white border-gray-300 text-gray-900 focus:border-gray-400'
                      }
                      border focus:outline-none focus:ring-2 focus:ring-blue-500/20
                    `}
                  >
                    {categoryOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>

                {/* Industry Select */}
                <div className="space-y-1.5">
                  <label className={`
                    block ${isMobile ? 'text-xs' : 'text-sm'} font-medium
                    ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}
                  `}>
                    Industry
                  </label>
                  <select
                    value={formData.industry}
                    onChange={(e) => setFormData({ ...formData, industry: e.target.value })}
                    className={`
                      w-full px-3 py-2 rounded-md
                      ${isMobile ? 'text-xs' : 'text-sm'}
                      transition-shadow duration-200 appearance-none
                      ${isDarkMode 
                        ? 'bg-[#222222] border-[#333333] text-white focus:border-[#444444]' 
                        : 'bg-white border-gray-300 text-gray-900 focus:border-gray-400'
                      }
                      border focus:outline-none focus:ring-2 focus:ring-blue-500/20
                    `}
                  >
                    {industryOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>

                {/* Tone Select */}
                <div className={`space-y-1.5 ${isMobile ? 'col-span-2' : ''}`}>
                  <label className={`
                    block ${isMobile ? 'text-xs' : 'text-sm'} font-medium
                    ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}
                  `}>
                    Tone
                  </label>
                  <select
                    value={formData.tone}
                    onChange={(e) => setFormData({ ...formData, tone: e.target.value })}
                    className={`
                      w-full px-3 py-2 rounded-md
                      ${isMobile ? 'text-xs' : 'text-sm'}
                      transition-shadow duration-200 appearance-none
                      ${isDarkMode 
                        ? 'bg-[#222222] border-[#333333] text-white focus:border-[#444444]' 
                        : 'bg-white border-gray-300 text-gray-900 focus:border-gray-400'
                      }
                      border focus:outline-none focus:ring-2 focus:ring-blue-500/20
                    `}
                  >
                    {toneOptions.map(option => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Content Textarea */}
              <div className="space-y-1.5">
                <label className={`
                  block ${isMobile ? 'text-xs' : 'text-sm'} font-medium
                  ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}
                `}>
                  Content
                </label>
                <textarea
                  value={formData.content}
                  onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                  rows={isMobile ? 6 : 8}
                  className={`
                    w-full px-3 py-2 rounded-md
                    ${isMobile ? 'text-xs' : 'text-sm'}
                    transition-shadow duration-200
                    ${isDarkMode 
                      ? 'bg-[#222222] border-[#333333] text-white focus:border-[#444444]' 
                      : 'bg-white border-gray-300 text-gray-900 focus:border-gray-400'
                    }
                    border focus:outline-none focus:ring-2 focus:ring-blue-500/20
                    resize-none
                  `}
                  placeholder="Write your template content..."
                />
              </div>
            </div>

            {/* Footer */}
            <div className={`
              flex justify-end gap-3 
              ${isMobile ? 'px-4 py-3 border-t' : 'px-6 py-4 border-t'}
              ${isDarkMode ? 'border-[#333333]' : 'border-gray-100'}
            `}>
              <button
                onClick={onClose}
                className={`
                  px-4 py-2 ${isMobile ? 'text-xs' : 'text-sm'} font-medium rounded-md
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'text-gray-300 hover:bg-[#333333]' 
                    : 'text-gray-700 hover:bg-gray-100'
                  }
                `}
              >
                Cancel
              </button>
              <button
                onClick={() => onSave(formData)}
                className={`
                  px-4 py-2 ${isMobile ? 'text-xs' : 'text-sm'} font-medium rounded-md
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'bg-blue-600 text-white hover:bg-blue-700' 
                    : 'bg-blue-600 text-white hover:bg-blue-700'
                  }
                `}
              >
                {template ? 'Save Changes' : 'Create Template'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TemplatePopup = ({ onClose, onSelectTemplate, isMobileView }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 639 }) || isMobileView;
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTones, setSelectedTones] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [selectedSource, setSelectedSource] = useState(null);
  const [copyStates, setCopyStates] = useState({});

  // Add ref for container width measurement
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  // Add ResizeObserver to measure container width
  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  // Update the columns calculation for mobile
  const columns = useMemo(() => {
    if (isMobile) return 1; // Force single column on mobile
    return getBreakpointColumns(containerWidth);
  }, [containerWidth, isMobile]);

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        // Fetch both default templates and user templates in parallel
        const [defaultTemplates, userTemplates] = await Promise.all([
          fetchTemplates(),
          fetchUserTemplates()
        ]);

        // Create a Set to track template IDs we've already processed
        const processedIds = new Set();

        // Mark user templates to distinguish them
        const markedUserTemplates = userTemplates
          .filter(template => {
            // Skip if we've already processed this template
            if (!template._id || processedIds.has(template._id)) return false;
            processedIds.add(template._id);
            return true;
          })
          .map(template => ({
            ...template,
            userId: true, // This will be used to show edit/delete buttons
            content: template.content || template.templateContent, // Handle content field
            type: template.type || template.category, // Handle type/category field
            title: template.title || template.templateName // Handle title field
          }));

        // Mark default templates and ensure content field is mapped correctly
        const markedDefaultTemplates = defaultTemplates
          .filter(template => {
            // Skip if we've already processed this template
            if (!template._id || processedIds.has(template._id)) return false;
            processedIds.add(template._id);
            return true;
          })
          .map(template => ({
            ...template,
            isDefault: true, // This will be used to prevent editing/deleting
            content: template.templateContent || template.content, // Handle content field
            title: template.templateName || template.title, // Handle title field
            type: template.category || template.type, // Handle type/category field
            industry: template.industry || 'General', // Default industry if not present
            tone: template.tone || 'Professional' // Default tone if not present
          }));

        // Combine both types of templates
        const allTemplates = [...markedUserTemplates, ...markedDefaultTemplates];
        console.log('Loaded templates:', allTemplates); // Debug log
        setTemplates(allTemplates);
        setFilteredTemplates(allTemplates);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching templates:', err);
        setError('Failed to load templates. Please try again.');
        setLoading(false);
      }
    };

    loadTemplates();
  }, []);

  useEffect(() => {
    const filtered = templates.filter(template => {
      if (!template) return false;
      
      const searchLower = (searchTerm || '').toLowerCase();
      const templateTitle = ((template.templateName || template.title) ?? '').toLowerCase();
      const templateContent = ((template.templateContent || template.content) ?? '').toLowerCase();
      const templateType = ((template.type || template.category) ?? '').toLowerCase();
      const templateIndustry = (template.industry ?? '').toLowerCase();
      const templateTone = (template.tone ?? '').toLowerCase();
      
      const searchMatch = 
        templateTitle.includes(searchLower) ||
        templateContent.includes(searchLower);
        
      const categoryMatch = !selectedCategories.length || 
        selectedCategories.some(cat => (cat || '').toLowerCase() === templateType);
        
      const industryMatch = !selectedIndustries.length || 
        selectedIndustries.some(ind => (ind || '').toLowerCase() === templateIndustry);
        
      const toneMatch = !selectedTones.length || 
        selectedTones.some(tone => (tone || '').toLowerCase() === templateTone);

      const sourceMatch = !selectedSource || 
        (selectedSource === 'Default Templates' && template.isDefault) ||
        (selectedSource === 'My Templates' && template.userId && !template.isDefault) ||
        selectedSource === 'All Templates';
      
      return searchMatch && categoryMatch && industryMatch && toneMatch && sourceMatch;
    });

    setFilteredTemplates(filtered);
  }, [searchTerm, selectedCategories, selectedIndustries, selectedTones, selectedSource, templates]);

  const handleCategorySelect = (category) => {
    setSelectedCategories(prev => 
      prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]
    );
  };

  const handleToneSelect = (tone) => {
    setSelectedTones(prev => 
      prev.includes(tone) ? prev.filter(t => t !== tone) : [...prev, tone]
    );
  };

  const handleIndustrySelect = (industry) => {
    setSelectedIndustries(prev => 
      prev.includes(industry) ? prev.filter(i => i !== industry) : [...prev, industry]
    );
  };

  const handleSourceSelect = (source) => {
    setSelectedSource(source);
  };

  useEffect(() => {
    // Remove this effect that was closing the popup
    // const handleClosePopups = () => onClose();
    // document.addEventListener('closeAllPopups', handleClosePopups);
    // return () => document.removeEventListener('closeAllPopups', handleClosePopups);
  }, [onClose]);

  const handleCreateTemplate = () => {
    setEditingTemplate(null);
    setShowTemplateForm(true);
  };

  const handleSaveTemplate = async (formData) => {
    try {
      let savedTemplate;
      if (editingTemplate) {
        savedTemplate = await updateUserTemplate(editingTemplate._id, formData);
        // Update the existing template in the state
        setTemplates(prev => prev.map(t => 
          t._id === editingTemplate._id ? { ...savedTemplate, userId: true } : t
        ));
      } else {
        savedTemplate = await createUserTemplate(formData);
        // Add the new template at the beginning of the array
        setTemplates(prev => {
          // Check if template already exists
          const exists = prev.some(t => t._id === savedTemplate._id);
          if (exists) return prev;
          return [{ ...savedTemplate, userId: true }, ...prev];
        });
      }
      setShowTemplateForm(false);
      setEditingTemplate(null);
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleCopy = async (templateId, content) => {
    try {
      await navigator.clipboard.writeText(content);
      
      // Set copy success state for this template
      setCopyStates(prev => ({ ...prev, [templateId]: true }));
      
      // Show success toast
      toast.success('Template copied!', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });

      // Reset copy state after animation
      setTimeout(() => {
        setCopyStates(prev => ({ ...prev, [templateId]: false }));
      }, 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy template', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.2 }}
      className={`
        flex flex-col h-full overflow-hidden rounded-lg
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
        ${isMobile ? 'max-h-[80vh]' : ''}
      `}
    >
      <TopBar
        selectedCategories={selectedCategories}
        selectedIndustries={selectedIndustries}
        selectedTones={selectedTones}
        selectedSource={selectedSource}
        onSelectCategory={handleCategorySelect}
        onSelectIndustry={handleIndustrySelect}
        onSelectTone={handleToneSelect}
        onSelectSource={handleSourceSelect}
        isDarkMode={isDarkMode}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        totalTemplates={templates.length}
        filteredCount={filteredTemplates.length}
        onClose={onClose}
        isSearchOpen={isSearchOpen}
        setIsSearchOpen={setIsSearchOpen}
        isMobile={isMobile}
        onCreateTemplate={handleCreateTemplate}
      />

      <div 
        ref={containerRef}
        className={`
          flex-grow overflow-y-auto
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
          ${isMobile ? 'pb-4' : ''}
        `}
      >
        {loading ? (
          <div className="px-4 pt-4">
            <Masonry
              breakpointCols={columns}
              className="flex -ml-2 w-auto"
              columnClassName="pl-2 bg-clip-padding"
            >
              {[...Array(isMobile ? 3 : 6)].map((_, index) => (
                <div 
                  key={index} 
                  className={`
                    mb-3 animate-pulse rounded-lg overflow-hidden
                    ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-100'}
                    ${isMobile ? 'p-3' : 'p-4'}
                    border
                    ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
                    ${isMobile ? 'w-full' : 'max-w-[250px]'}
                  `}
                >
                  <div className={`
                    h-16 rounded-md
                    ${isDarkMode ? 'bg-[#252525]' : 'bg-gray-200'}
                  `} />
                  <div className="mt-3 pt-3 flex items-center justify-between border-t border-gray-200">
                    <div className={`
                      w-16 h-4 rounded
                      ${isDarkMode ? 'bg-[#252525]' : 'bg-gray-200'}
                    `} />
                    <div className={`
                      w-20 h-4 rounded
                      ${isDarkMode ? 'bg-[#252525]' : 'bg-gray-200'}
                    `} />
                  </div>
                </div>
              ))}
            </Masonry>
          </div>
        ) : error ? (
          <div className="flex items-center justify-center h-full p-4">
            <button
              onClick={() => window.location.reload()}
              className={`
                px-4 py-2 rounded-lg text-sm font-medium
                transition-colors duration-200
                ${isDarkMode 
                  ? 'bg-[#1e1e1e] text-slate-200 hover:bg-[#2a2a2a] border border-[#333333]' 
                  : 'bg-black text-white hover:bg-gray-900 border border-black'}
              `}
            >
              Retry
            </button>
          </div>
        ) : filteredTemplates.length === 0 ? (
          <NoResultsState searchTerm={searchTerm} isDarkMode={isDarkMode} />
        ) : (
          <div className="px-4 pt-4">
            <Masonry
              breakpointCols={columns}
              className="flex -ml-2 w-auto"
              columnClassName="pl-2 bg-clip-padding"
            >
              {filteredTemplates.map((template) => (
                <div 
                  key={template._id} 
                  className={`
                    mb-3 
                    ${isMobile ? 'w-full' : 'w-full max-w-[350px]'}
                    transition-all duration-200
                  `}
                >
                  <div 
                    onClick={() => onSelectTemplate({
                      ...template,
                      content: template.content || template.templateContent,
                      title: template.title || template.templateName,
                      type: template.type || template.category,
                      industry: template.industry || 'General',
                      tone: template.tone || 'Professional',
                      isDefault: template.isDefault || false,
                      userId: template.userId,
                      _id: template._id,
                      message: template.content || template.templateContent
                    })}
                    className={`
                      relative group cursor-pointer rounded-lg
                      ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-white'}
                      ${isMobile ? 'p-3' : 'sm:p-3 md:p-4'}
                      border transition-all duration-200
                      hover:shadow-lg transform hover:-translate-y-0.5
                      h-full flex flex-col
                      ${isDarkMode 
                        ? 'border-[#333333] hover:border-[#444444]' 
                        : 'border-gray-200 hover:border-gray-300'}
                    `}
                  >
                    <div className={`
                      whitespace-pre-wrap break-words flex-grow
                      ${isMobile 
                        ? 'text-xs leading-relaxed' 
                        : 'sm:text-xs md:text-sm leading-relaxed'}
                      ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}
                    `}
                      dangerouslySetInnerHTML={{
                        __html: highlightSearchTerm(template.content || template.templateContent, searchTerm)
                      }}
                    />
                    <div className={`
                      mt-3 pt-2
                      border-t flex items-center justify-between gap-2 flex-shrink-0
                      ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
                    `}>
                      <div className="flex items-center gap-2">
                        <span className={`
                          ${isMobile ? 'text-[10px]' : 'sm:text-[10px] md:text-xs'} 
                          font-medium truncate
                          ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
                        `}>
                          {template.type || template.category || 'Template'}
                        </span>
                        {!template.userId && template.isDefault && (
                          <span className={`
                            px-1.5 py-0.5 text-[10px] rounded-md
                            ${isDarkMode 
                              ? 'bg-[#252525] text-slate-400' 
                              : 'bg-gray-100 text-gray-500'}
                          `}>
                            Default
                          </span>
                        )}
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopy(template._id, template.content || template.templateContent);
                        }}
                        className={`
                          p-1.5 rounded-md
                          transition-all duration-200
                          ${copyStates[template._id] 
                            ? isDarkMode 
                              ? 'bg-green-500/20 text-green-400' 
                              : 'bg-green-100 text-green-600'
                            : isDarkMode 
                              ? 'hover:bg-[#252525] text-gray-400 hover:text-gray-300' 
                              : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'}
                        `}
                        title="Copy template"
                      >
                        <motion.div
                          initial={false}
                          animate={{
                            scale: copyStates[template._id] ? [1, 1.2, 1] : 1,
                          }}
                          transition={{ duration: 0.3 }}
                        >
                          {copyStates[template._id] ? (
                            <CheckIcon className="w-3.5 h-3.5" />
                          ) : (
                            <ClipboardIcon className="w-3.5 h-3.5" />
                          )}
                        </motion.div>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </Masonry>
          </div>
        )}
      </div>

      {showTemplateForm && (
        <TemplateForm
          template={editingTemplate}
          onSave={handleSaveTemplate}
          onClose={() => {
            setShowTemplateForm(false);
            setEditingTemplate(null);
          }}
          isDarkMode={isDarkMode}
        />
      )}
    </motion.div>
  );
};

TemplatePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSelectTemplate: PropTypes.func.isRequired,
  isMobileView: PropTypes.bool
};

export default TemplatePopup;