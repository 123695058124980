import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../context/ThemeContext';
import { FolderIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

const NewFolderModal = ({ onClose, onCreate }) => {
  const { isDarkMode } = useTheme();
  const [folderName, setFolderName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (folderName.trim()) {
      onCreate(folderName.trim());
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${
          isDarkMode ? 'bg-black/60' : 'bg-white/80'
        } backdrop-blur-sm`}
        onClick={(e) => {
          if (e.target === e.currentTarget) onClose();
        }}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={`relative w-full max-w-md overflow-hidden rounded-lg shadow-lg ${
            isDarkMode 
              ? 'bg-[#1e1e1e] border border-[#333333]' 
              : 'bg-white border border-gray-200'
          }`}
          onClick={e => e.stopPropagation()}
        >
          {/* Header */}
          <div className={`px-6 py-4 border-b ${
            isDarkMode ? 'border-[#333333]' : 'border-gray-100'
          }`}>
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className={`p-2 rounded-lg ${
                  isDarkMode ? 'bg-[#252525]' : 'bg-gray-100'
                }`}>
                  <FolderIcon className={`w-5 h-5 ${
                    isDarkMode ? 'text-blue-400' : 'text-blue-500'
                  }`} />
                </div>
                <h3 className={`text-lg font-medium leading-6 ${
                  isDarkMode ? 'text-slate-200' : 'text-gray-900'
                }`}>
                  New Folder
                </h3>
              </div>
              <button
                onClick={onClose}
                className={`rounded-full p-1 transition-colors duration-200 ${
                  isDarkMode 
                    ? 'hover:bg-[#333333] text-slate-400 hover:text-slate-300' 
                    : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
                }`}
              >
                <XMarkIcon className="w-5 h-5" />
              </button>
            </div>
          </div>

          {/* Content */}
          <form onSubmit={handleSubmit} className="px-6 py-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <label 
                  htmlFor="folderName" 
                  className={`text-sm font-medium ${
                    isDarkMode ? 'text-slate-400' : 'text-gray-700'
                  }`}
                >
                  Folder Name
                </label>
                <input
                  ref={inputRef}
                  id="folderName"
                  type="text"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Untitled Folder"
                  className={`w-full px-3 py-2 text-sm rounded-md border ${
                    isDarkMode 
                      ? 'bg-[#252525] border-[#333333] text-slate-200 placeholder-slate-500' 
                      : 'bg-white border-gray-200 text-gray-900 placeholder-gray-400'
                  } focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                    isDarkMode
                      ? 'focus:ring-blue-500/20 focus:ring-offset-[#1e1e1e]'
                      : 'focus:ring-blue-500/20 focus:ring-offset-white'
                  } transition-colors duration-200`}
                />
              </div>

              <div className="flex justify-end space-x-2 pt-2">
                <button
                  type="button"
                  onClick={onClose}
                  className={`px-3 py-1.5 rounded-md text-sm font-medium transition-all duration-200 ${
                    isDarkMode
                      ? 'bg-[#252525] text-slate-200 hover:bg-[#333333] border border-[#333333]'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200 border border-gray-200'
                  }`}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!folderName.trim()}
                  className={`px-3 py-1.5 rounded-md text-sm font-medium transition-all duration-200 ${
                    isDarkMode
                      ? 'bg-blue-500 hover:bg-blue-600 text-white'
                      : 'bg-blue-500 hover:bg-blue-600 text-white'
                  } disabled:opacity-50 disabled:cursor-not-allowed`}
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default NewFolderModal;