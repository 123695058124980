import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Eye, EyeOff, AlertCircle, Info, CheckCircle, XCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import logo from '../amplify-logo-full.svg';
import { getLinkedInAuthUrl, getState, saveState } from '../services/linkedinAuthService';
import { toast } from 'react-hot-toast';
import { SparklesIcon, BoltIcon, MicrophoneIcon, ChartBarIcon } from '@heroicons/react/24/outline';
import LinkedInLoadingOverlay from './LinkedInLoadingOverlay';
import GoogleAuthButton from './auth/GoogleAuthButton';
import AnimatedLoader from './common/AnimatedLoader';
import subscriptionService from '../services/subscriptionService';
import geoService from '../services/geoService';

const VisuallyHidden = ({ children }) => (
  <span className="sr-only">{children}</span>
);

const PasswordStrengthIndicator = ({ password }) => {
  const { isDarkMode } = useTheme();
  const requirements = [
    { regex: /.{8,}/, text: "At least 8 characters" },
    { regex: /[A-Z]/, text: "Uppercase letter" },
    { regex: /[a-z]/, text: "Lowercase letter" },
    { regex: /[0-9]/, text: "Number" },
    { regex: /[!@#$%^&*]/, text: "Special character" },
  ];

  if (!password) return null;

  return (
    <div className="mt-2 space-y-2">
      <div className={`text-xs font-medium ${isDarkMode ? 'text-slate-400' : 'text-slate-700'}`}>
        Password strength:
      </div>
      {requirements.map((req, index) => (
        <div key={index} className="flex items-center text-xs">
          {req.regex.test(password) ? (
            <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
          ) : (
            <XCircle className="h-4 w-4 text-red-500 mr-2" />
          )}
          <span className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>
            {req.text}
          </span>
        </div>
      ))}
    </div>
  );
};

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkedInLoading, setIsLinkedInLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const { register, loginWithLinkedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const fromPricing = location.state?.fromPricing;
  const selectedPlan = location.state?.selectedPlan;
  const selectedPrice = location.state?.price;
  const pricingToastShown = useRef(false);

  useEffect(() => {
    // Check if we're on the callback URL
    const isCallback = window.location.pathname === '/auth/linkedin/callback';
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const error = params.get('error');
    const errorDescription = params.get('error_description');
    
    if (isCallback && code) {
      setIsLinkedInLoading(true);
      handleLinkedInCallback(code);
    }
    
    if (error) {
      console.error('❌ LinkedIn error:', error, errorDescription);
      setError(errorDescription || 'LinkedIn authentication failed');
      setIsLinkedInLoading(false);
      navigate('/register', { replace: true });
    }
  }, [location]);

  useEffect(() => {
    if (fromPricing && selectedPlan && !pricingToastShown.current) {
      const message = selectedPlan === 'pro' 
        ? `Complete your registration to get started with the Pro plan at $${selectedPrice}/month`
        : 'Complete your registration to get started with the Free plan';
      
      toast.success(message, {
        duration: 5000,
        position: 'top-center',
        style: {
          background: isDarkMode ? '#1E1E1E' : '#FFFFFF',
          color: isDarkMode ? '#FFFFFF' : '#1E1E1E',
          border: `1px solid ${isDarkMode ? '#333333' : '#E5E7EB'}`,
        },
      });
      
      pricingToastShown.current = true;
    }
  }, [fromPricing, selectedPlan, selectedPrice, isDarkMode]);

  const handleLinkedInCallback = async (code) => {
    console.log('🔷 Processing LinkedIn callback');
    const returnedState = new URLSearchParams(location.search).get('state');
    const savedStateData = getState();
    
    if (!savedStateData) {
      console.error('❌ No saved state data found');
      setError('LinkedIn authentication session expired. Please try again.');
      setIsLinkedInLoading(false);
      navigate('/register', { replace: true });
      return;
    }
    
    console.log('🔷 State validation:', {
      returnedState,
      savedState: savedStateData.state,
      hasCodeVerifier: !!savedStateData.codeVerifier,
      stateAge: Date.now() - (savedStateData.timestamp || 0)
    });

    if (!savedStateData.state || savedStateData.state !== returnedState) {
      console.error('❌ State mismatch:', {
        savedState: savedStateData.state,
        returnedState
      });
      setError('LinkedIn authentication failed. Please try again.');
      setIsLinkedInLoading(false);
      navigate('/register', { replace: true });
      return;
    }

    try {
      const success = await loginWithLinkedIn(code, returnedState);
      if (success) {
        // Clear state data
        localStorage.removeItem('linkedin_auth_state');
        
        // Check for stored trial intent
        const trialIntent = localStorage.getItem('trial_intent');
        if (trialIntent) {
          try {
            const trialData = JSON.parse(trialIntent);
            const { planId, isYearly } = trialData;
            localStorage.removeItem('trial_intent');
            
            // Start trial directly with proper interval
            const trialResult = await subscriptionService.startTrial(planId, isYearly ? 'year' : 'month');
            if (trialResult.success) {
              toast.success('Your free trial has started! Enjoy full access for 7 days.');
              navigate('/dashboard', { replace: true });
              return;
            }
          } catch (trialError) {
            console.error('Error starting trial:', trialError);
            // If trial start fails, redirect to dashboard
            navigate('/dashboard', { replace: true });
            return;
          }
        }
        
        // If no trial intent, navigate to success page
        console.log('🎉 Register - Navigating to success page with state:', {
          isSignup: true,
          linkedinTokens: success.linkedinTokens
        });
        
        navigate('/auth/linkedin/success', { 
          replace: true,
          state: { 
            isSignup: true,
            linkedinTokens: success.linkedinTokens
          }
        });
        return;
      } else {
        throw new Error('Authentication failed');
      }
    } catch (error) {
      console.error('❌ LinkedIn auth error:', error);
      
      if (error.response?.status === 400) {
        if (error.response.data?.error === 'User already exists') {
          setError('You already have an account. Please use the login page instead.');
          setIsLinkedInLoading(false);
          navigate('/login', { replace: true });
          return;
        } else {
          setError(error.response.data?.error || 'Authentication failed. Please try again.');
        }
      } else {
        setError(error.message || 'Authentication failed. Please try again.');
      }
      setIsLinkedInLoading(false);
      navigate('/register', { replace: true });
    }
  };

  const handleLinkedInSignup = async () => {
    try {
      console.log('🔷 Initiating LinkedIn signup flow');
      
      // Clear any existing state data first
      localStorage.removeItem('linkedin_auth_state');
      
      // Generate state with more entropy
      const state = Array.from(crypto.getRandomValues(new Uint8Array(16)))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
      
      console.log('🔷 Generated state:', state);
      
      // Get LinkedIn auth URL
      const linkedInUrl = await getLinkedInAuthUrl(state);
      console.log('🔷 Redirecting to LinkedIn:', linkedInUrl);
      
      // Final state verification before redirect
      const finalCheck = getState();
      if (!finalCheck || finalCheck.state !== state) {
        console.error('❌ Final state verification failed');
        setError('Failed to initialize LinkedIn authentication. Please try again.');
        setIsLinkedInLoading(false);
        return;
      }
      
      // Store the source of LinkedIn auth
      localStorage.setItem('linkedin_auth_source', 'register');
      
      // Redirect to LinkedIn
      window.location.href = linkedInUrl;
    } catch (error) {
      console.error('❌ Error initiating LinkedIn signup:', error);
      setError('Failed to connect to LinkedIn: ' + error.message);
      setIsLinkedInLoading(false);
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8) return "Password must be at least 8 characters long";
    if (!/[A-Z]/.test(password)) return "Password must contain at least one uppercase letter";
    if (!/[a-z]/.test(password)) return "Password must contain at least one lowercase letter";
    if (!/[0-9]/.test(password)) return "Password must contain at least one number";
    if (!/[!@#$%^&*]/.test(password)) return "Password must contain at least one special character";
    return null;
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    setError('');

    if (!email.trim()) {
      setError('Please enter your email address');
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setCurrentStep(2);
    
    // Only show the success toast if not coming from pricing
    if (!fromPricing) {
      toast.success('Email looks good! Please complete your registration.', {
        duration: 3000,
        position: 'top-center',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    // Basic validation
    if (!firstName.trim() || !lastName.trim()) {
      setError('Please enter your full name');
      setIsLoading(false);
      return;
    }

    if (!email.trim()) {
      setError('Please enter your email address');
      setIsLoading(false);
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      setIsLoading(false);
      return;
    }

    // Name format validation
    const nameRegex = /^[a-zA-Z\s-']+$/;
    if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
      setError('Names can only contain letters, spaces, hyphens, and apostrophes');
      setIsLoading(false);
      return;
    }

    // Password validation
    const passwordError = validatePassword(password);
    if (passwordError) {
      setError(passwordError);
      toast.error(passwordError, {
        duration: 5000,
        position: 'top-center',
      });
      setIsLoading(false);
      return;
    }

    try {
      const result = await register(firstName, lastName, email, password);
      if (result.success) {
        // Check for stored trial intent
        const trialIntent = localStorage.getItem('trial_intent');
        if (trialIntent) {
          let planId, isYearly;  // Declare variables at the top of the block
          try {
            const trialData = JSON.parse(trialIntent);
            planId = trialData.planId;
            isYearly = trialData.isYearly;
            localStorage.removeItem('trial_intent');

            // Start trial directly
            const trialResult = await subscriptionService.startTrial(planId, isYearly ? 'year' : 'month');
            
            if (trialResult.success) {
              toast.success('Your free trial has started! Enjoy full access for 7 days.');
              // Redirect to verify email after starting trial
              navigate('/auth/verify-email', { 
                state: { 
                  email,
                  message: result.message || 'Please check your email for the verification link.',
                  trialStarted: true
                }
              });
              return;
            }
          } catch (trialError) {
            console.error('Error starting trial:', trialError);
            // If trial start fails, continue with email verification
            navigate('/auth/verify-email', { 
              state: { 
                email,
                message: result.message || 'Please check your email for the verification link.',
                trialPending: true
              }
            });
            return;
          }
        } else {
          toast.success(result.message || 'Account created successfully! Please verify your email.', {
            duration: 5000,
            position: 'top-center',
          });
          
          navigate('/auth/verify-email', { 
            state: { 
              email,
              message: result.message || 'Please check your email for the verification link.'
            }
          });
        }

        // Clear any stored subscription intent
        localStorage.removeItem('subscription_intent');
      } else {
        console.error('Registration failed without error:', result);
        setError('Registration failed. Please try again.');
        toast.error('Unable to create account. Please try again.', {
          duration: 5000,
          position: 'top-center',
        });
      }
    } catch (err) {
      console.error('Registration error:', err);
      
      if (err.response) {
        switch (err.response.status) {
          case 400:
            if (err.response.data?.error === 'User already exists') {
              setError('An account with this email already exists.');
              toast.error('Account already exists. Please sign in instead.', {
                duration: 5000,
                position: 'top-center',
              });
              // Optional: Redirect to login page
              setTimeout(() => {
                navigate('/login', { 
                  state: { email }
                });
              }, 2000);
            } else if (err.response.data?.error === 'Invalid email domain') {
              setError('Please use a valid work email address.');
              toast.error('Please use your work email address.', {
                duration: 5000,
                position: 'top-center',
              });
            } else {
              setError(err.response.data?.error || 'Invalid registration details.');
              toast.error('Please check your registration details.', {
                duration: 5000,
                position: 'top-center',
              });
            }
            break;
          case 429:
            setError('Too many registration attempts. Please try again later.');
            toast.error('Too many attempts. Please wait a few minutes.', {
              duration: 5000,
              position: 'top-center',
            });
            break;
          case 500:
            setError('Server error. Please try again later.');
            toast.error('Server error. Please try again later.', {
              duration: 5000,
              position: 'top-center',
            });
            break;
          default:
            setError('Registration failed. Please try again.');
            toast.error('Unable to create account. Please try again.', {
              duration: 5000,
              position: 'top-center',
            });
        }
      } else if (err.request) {
        // Network error
        setError('Unable to connect to the server. Please check your internet connection.');
        toast.error('Network error. Please check your connection.', {
          duration: 5000,
          position: 'top-center',
        });
      } else {
        setError(err.message || 'An unexpected error occurred. Please try again.');
        toast.error('Registration failed. Please try again.', {
          duration: 5000,
          position: 'top-center',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AnimatePresence>
        {isLinkedInLoading && <LinkedInLoadingOverlay />}
      </AnimatePresence>

      {!isLinkedInLoading && (
        <div className={`min-h-screen bg-fixed overflow-x-hidden flex flex-col ${
          isDarkMode 
            ? 'bg-gradient-to-br from-[#09090b] via-[#18181b] to-[#09090b]' 
            : 'bg-gradient-to-br from-slate-50 via-white to-slate-50'
        }`}>
          <div className="flex-grow flex items-center justify-center px-4 sm:px-6 py-12">
            <div className="max-w-md w-full">
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center mb-8"
              >
                <Link to="/" className="inline-block mb-6 transform hover:scale-105 transition-transform">
                  <img src={logo} alt="Ammmplify" className="h-8 w-auto mx-auto dark:invert" />
                </Link>
                <h2 className={`text-3xl font-bold mb-3 ${
                  isDarkMode ? 'text-white' : 'text-slate-900'
                }`}>
                  {fromPricing && selectedPlan === 'pro' 
                    ? 'Complete Pro Registration' 
                    : 'Create your account'}
                </h2>
                <div className="flex flex-col items-center gap-2">
                  <div className="flex items-center gap-2">
                    <span className={`text-xs px-2 py-0.5 rounded-full border ${
                      isDarkMode 
                        ? 'bg-blue-500/10 text-blue-400 border-blue-500/20' 
                        : 'bg-blue-50 text-blue-600 border-blue-100'
                    }`}>
                      7-day free trial
                    </span>
                    <span className={`text-xs px-2 py-0.5 rounded-full border ${
                      isDarkMode 
                        ? 'bg-emerald-500/10 text-emerald-400 border-emerald-500/20' 
                        : 'bg-emerald-50 text-emerald-600 border-emerald-100'
                    }`}>
                      No credit card required
                    </span>
                  </div>
                </div>
              </motion.div>

              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className={`w-full p-8 rounded-xl backdrop-blur-sm ${
                  isDarkMode 
                    ? 'bg-[#18181b]/90 border border-[#27272a]' 
                    : 'bg-white/90 border border-slate-200/60'
                }`}
              >
                {error && (
                  <motion.div 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className={`mb-6 p-4 rounded-lg text-sm flex items-center gap-3 ${
                      isDarkMode 
                        ? 'bg-red-900/20 text-red-400 border border-red-900/50' 
                        : 'bg-red-50 text-red-600 border border-red-100'
                    }`}
                  >
                    <AlertCircle className="h-5 w-5 flex-shrink-0" />
                    <p className="flex-grow">{error}</p>
                  </motion.div>
                )}

                {currentStep === 1 ? (
                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="space-y-4"
                  >
                    <button
                      type="button"
                      onClick={handleLinkedInSignup}
                      className={`w-full flex items-center justify-center gap-3 py-3.5 px-4 rounded-lg font-medium text-base
                        bg-[#2d64bc] text-white hover:bg-[#2d64bc]/90
                        transform transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]`}
                    >
                      <img
                        className="h-5 w-5 sm:h-6 sm:w-6"
                        src="/linkedin-icon.svg"
                        alt="LinkedIn logo"
                      />
                      <span>Sign up with LinkedIn</span>
                      <span className={`hidden sm:inline-flex text-xs px-1.5 py-0.5 rounded-full bg-white/20 text-white/90`}>
                        Recommended
                      </span>
                    </button>

                    <div className="mt-4">
                      <GoogleAuthButton 
                        isSignUp={true} 
                        className={`w-full py-3.5 px-4 rounded-lg text-base ${
                          isDarkMode 
                            ? 'bg-[#27272a] text-white hover:bg-[#3f3f46] border-[#3f3f46]' 
                            : 'bg-white text-slate-700 hover:bg-slate-50 border-slate-200'
                        }`} 
                      />
                    </div>

                    <div className="mt-4">
                      <button
                        type="button"
                        onClick={() => setCurrentStep(2)}
                        className={`w-full flex items-center justify-center gap-3 py-3.5 px-4 rounded-lg font-medium text-base
                          ${isDarkMode 
                            ? 'bg-[#27272a] text-white hover:bg-[#3f3f46] border border-[#3f3f46]' 
                            : 'bg-white text-slate-700 hover:bg-slate-50 border border-slate-200'
                          } transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>
                        Sign up with email
                      </button>
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    className="space-y-6"
                  >
                    <button
                      type="button"
                      onClick={() => setCurrentStep(1)}
                      className={`-mt-2 -mx-2 mb-4 flex items-center gap-2 py-2 px-3 rounded-lg text-sm font-medium
                        ${isDarkMode 
                          ? 'text-slate-400 hover:text-white hover:bg-[#27272a]' 
                          : 'text-slate-500 hover:text-slate-900 hover:bg-slate-50'
                        } transition-all duration-200`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                      </svg>
                      Back
                    </button>

                    <form onSubmit={handleSubmit} className="space-y-6">
                      <div>
                        <label className="sr-only" htmlFor="email">
                          Email address
                        </label>
                        <input
                          id="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Your email address"
                          required
                          autoFocus
                          autoComplete="email"
                          aria-label="Email address"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          className={`w-full px-4 py-3.5 rounded-lg border text-base transition-all duration-200 ${
                            isDarkMode 
                              ? 'bg-[#27272a] border-[#3f3f46] text-white placeholder-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500' 
                              : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                          }`}
                        />
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="sr-only" htmlFor="firstName">
                            First name
                          </label>
                          <input
                            id="firstName"
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First name"
                            required
                            autoComplete="given-name"
                            aria-label="First name"
                            pattern="[A-Za-z\s-']+"
                            className={`w-full px-4 py-3.5 rounded-lg border text-base transition-all duration-200 ${
                              isDarkMode 
                                ? 'bg-[#27272a] border-[#3f3f46] text-white placeholder-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500' 
                                : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                            }`}
                          />
                        </div>
                        <div>
                          <label className="sr-only" htmlFor="lastName">
                            Last name
                          </label>
                          <input
                            id="lastName"
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last name"
                            required
                            autoComplete="family-name"
                            aria-label="Last name"
                            pattern="[A-Za-z\s-']+"
                            className={`w-full px-4 py-3.5 rounded-lg border text-base transition-all duration-200 ${
                              isDarkMode 
                                ? 'bg-[#27272a] border-[#3f3f46] text-white placeholder-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500' 
                                : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                            }`}
                          />
                        </div>
                      </div>

                      <div>
                        <label className="sr-only" htmlFor="password">
                          Password
                        </label>
                        <div className="relative">
                          <input
                            id="password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                            autoComplete="new-password"
                            aria-label="Password"
                            className={`w-full px-4 py-3.5 rounded-lg border text-base transition-all duration-200 ${
                              isDarkMode 
                                ? 'bg-[#27272a] border-[#3f3f46] text-white placeholder-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500' 
                                : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                            }`}
                          />
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className={`absolute right-4 top-1/2 -translate-y-1/2 p-1 rounded-lg ${
                              isDarkMode 
                                ? 'text-slate-400 hover:text-white hover:bg-[#3f3f46]' 
                                : 'text-slate-500 hover:text-slate-700 hover:bg-slate-100'
                            } transition-colors`}
                          >
                            {showPassword ? (
                              <EyeOff className="h-5 w-5" />
                            ) : (
                              <Eye className="h-5 w-5" />
                            )}
                          </button>
                        </div>
                        <PasswordStrengthIndicator password={password} />
                      </div>

                      <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full py-3.5 px-4 rounded-lg font-semibold text-base
                          ${isLoading 
                            ? 'opacity-70 cursor-not-allowed' 
                            : 'hover:opacity-90'
                          } ${
                            isDarkMode
                              ? 'bg-white text-[#18181b] hover:bg-gray-100'
                              : 'bg-[#18181b] text-white hover:bg-black'
                          } transition-all duration-200 transform hover:scale-[1.02] active:scale-[0.98] relative overflow-hidden`}
                      >
                        <AnimatePresence mode="wait">
                          {isLoading ? (
                            <motion.span
                              key="loading"
                              initial={{ opacity: 0, y: 10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                              className="flex items-center justify-center gap-3"
                            >
                              <AnimatedLoader size={20} color={isDarkMode ? 'black' : 'white'} />
                              Creating account...
                            </motion.span>
                          ) : (
                            <motion.span
                              key="create"
                              initial={{ opacity: 0, y: 10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                            >
                              Create account
                            </motion.span>
                          )}
                        </AnimatePresence>
                      </button>
                    </form>
                  </motion.div>
                )}

                <p className={`mt-8 text-sm text-center ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-600'
                }`}>
                  Already have an account?{' '}
                  <Link to="/login" className="text-blue-500 hover:text-blue-600 font-medium transition-colors">
                    Sign in
                  </Link>
                </p>
              </motion.div>
            </div>
          </div>
          
          <footer className={`py-6 text-center ${
            isDarkMode ? 'border-t border-[#27272a]' : 'border-t border-slate-200/60'
          }`}>
            <p className={`text-sm ${
              isDarkMode ? 'text-slate-500' : 'text-slate-400'
            }`}>
              <Link to="/terms" className="hover:text-blue-500 transition-colors">Terms</Link>
              {' · '}
              <Link to="/privacy" className="hover:text-blue-500 transition-colors">Privacy</Link>
            </p>
          </footer>
        </div>
      )}
    </>
  );
};

const FeatureCard = ({ icon, title, description, isDarkMode }) => (
  <div className={`p-6 rounded-xl ${
    isDarkMode ? 'bg-[#1E1E1E]' : 'bg-white'
  } border ${
    isDarkMode ? 'border-gray-800' : 'border-slate-200'
  }`}>
    <div className={`w-10 h-10 rounded-lg flex items-center justify-center mb-4 ${
      isDarkMode ? 'bg-blue-500/20 text-blue-400' : 'bg-blue-100 text-blue-600'
    }`}>
      {icon}
    </div>
    <h3 className={`text-lg font-semibold mb-2 ${
      isDarkMode ? 'text-white' : 'text-slate-900'
    }`}>{title}</h3>
    <p className={`text-sm ${
      isDarkMode ? 'text-slate-400' : 'text-slate-600'
    }`}>{description}</p>
  </div>
);

export default Register;
