import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { 
  EllipsisHorizontalIcon,
  PencilSquareIcon,
  TrashIcon,
  UserGroupIcon,
  MapPinIcon,
  BuildingOfficeIcon,
  EyeIcon,
  AcademicCapIcon,
  DocumentArrowDownIcon
} from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import InitialsAvatar from '../common/InitialsAvatar';
import { motion, AnimatePresence } from 'framer-motion';

const DropdownMenu = ({ isDarkMode, onEdit, onDelete, onDownload, position, menuRef }) => {
  if (!position) return null;

  return ReactDOM.createPortal(
    <motion.div 
      ref={menuRef}
      initial={{ opacity: 0, y: -4 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -4 }}
      transition={{ duration: 0.15 }}
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 9999,
      }}
      className={`w-[160px] overflow-hidden rounded-lg border shadow-lg
                 ${isDarkMode 
                   ? 'bg-[#151515] border-[#333333]' 
                   : 'bg-white border-slate-200'}`}
    >
      <div className="px-1 py-1">
        <button
          onClick={onEdit}
          className={`flex w-full items-center gap-2 px-2 py-1.5 text-sm rounded-md
                   ${isDarkMode 
                     ? 'hover:bg-[#252525] text-slate-300' 
                     : 'hover:bg-slate-50 text-slate-700'}`}
        >
          <PencilSquareIcon className="h-4 w-4" />
          Edit
        </button>
        <button
          onClick={onDownload}
          className={`flex w-full items-center gap-2 px-2 py-1.5 text-sm rounded-md
                   ${isDarkMode 
                     ? 'hover:bg-[#252525] text-slate-300' 
                     : 'hover:bg-slate-50 text-slate-700'}`}
        >
          <DocumentArrowDownIcon className="h-4 w-4" />
          Export PDF
        </button>
        <button
          onClick={onDelete}
          className={`flex w-full items-center gap-2 px-2 py-1.5 text-sm rounded-md
                   text-red-500
                   ${isDarkMode ? 'hover:bg-red-500/10' : 'hover:bg-red-50'}`}
        >
          <TrashIcon className="h-4 w-4" />
          Delete
        </button>
      </div>
    </motion.div>,
    document.body
  );
};

const InfoRow = ({ icon: Icon, label, text, isDarkMode }) => (
  <div className="space-y-1">
    <div className={`flex items-center gap-1.5 text-xs font-medium
                  ${isDarkMode ? 'text-slate-500' : 'text-slate-500'}`}>
      <Icon className="h-3.5 w-3.5 flex-shrink-0" />
      <span>{label}</span>
    </div>
    <p className={`text-xs line-clamp-2 pl-5
                ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>
      {text}
    </p>
  </div>
);

const TargetAudienceCard = ({ audience, onEdit, onDelete, onDownload, isDarkMode, handleAudienceClick, isPopup }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && 
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMoreOptions = (e) => {
    e.stopPropagation();
    const buttonRect = buttonRef.current.getBoundingClientRect();
    setMenuPosition({
      top: buttonRect.bottom + window.scrollY + 4,
      left: buttonRect.right - 160,
    });
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(audience._id);
    setIsMenuOpen(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(audience._id);
    setIsMenuOpen(false);
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    onDownload(audience._id);
    setIsMenuOpen(false);
  };

  const handleCardClick = () => {
    handleAudienceClick(audience);
  };

  return (
    <div 
      className={`group relative overflow-hidden rounded-lg border transition-all duration-200
                ${isDarkMode 
                  ? 'bg-[#151515] hover:bg-[#1a1a1a] border-[#333333]' 
                  : 'bg-white hover:bg-slate-50 border-slate-200'}`}
    >
      <div className="p-4">
        {/* Header with Avatar and Actions */}
        <div className="flex items-start justify-between mb-4">
          <div className="flex items-center gap-3">
            <InitialsAvatar 
              name={audience.targetAudienceName}
              size="medium"
            />
            <div>
              <h3 className={`text-sm font-medium
                          ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}`}>
                {audience.targetAudienceName}
              </h3>
              <p className={`text-xs mt-0.5 truncate max-w-[200px]
                          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
                {[audience.occupation, audience.primaryLocation, audience.industry]
                  .filter(Boolean)
                  .join(' • ')}
              </p>
            </div>
          </div>

          {!isPopup && (
            <div className="relative">
              <button
                ref={buttonRef}
                onClick={handleMoreOptions}
                className={`rounded-md p-1.5 transition-colors
                         ${isDarkMode 
                           ? 'hover:bg-[#252525] text-slate-400' 
                           : 'hover:bg-slate-100 text-slate-500'}`}
              >
                <EllipsisHorizontalIcon className="h-4 w-4" />
              </button>

              <AnimatePresence>
                {isMenuOpen && (
                  <DropdownMenu
                    isDarkMode={isDarkMode}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onDownload={handleDownload}
                    position={menuPosition}
                    menuRef={menuRef}
                  />
                )}
              </AnimatePresence>
            </div>
          )}
        </div>

        {/* Content Section */}
        <div className={`pt-3 mt-3 space-y-3 border-t ${isDarkMode ? 'border-[#252525]' : 'border-slate-100'}`}>
          <InfoRow 
            icon={UserGroupIcon}
            label="Demographics"
            text={`${audience.ageRange || 'Any age'} • ${audience.primaryLocation || 'Any location'}`}
            isDarkMode={isDarkMode}
          />
          <InfoRow 
            icon={BuildingOfficeIcon}
            label="Professional"
            text={`${audience.careerStage || 'Any level'} • ${audience.industry || 'Any industry'}`}
            isDarkMode={isDarkMode}
          />
          <InfoRow 
            icon={AcademicCapIcon}
            label="Learning Style"
            text={audience.preferredLearningStyle || 'Not specified'}
            isDarkMode={isDarkMode}
          />
        </div>
      </div>

      {/* View Button - Fixed at Bottom Right */}
      <div className="absolute bottom-0 right-0 opacity-0 translate-y-1 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-200">
        <button
          onClick={handleCardClick}
          className={`m-4 flex items-center gap-1.5 px-3 py-1.5 text-xs font-medium rounded-md
                   transition-colors duration-200
                   ${isDarkMode 
                     ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-300' 
                     : 'bg-slate-100 hover:bg-slate-200 text-slate-700'}`}
        >
          <EyeIcon className="h-3.5 w-3.5" />
          View Details
        </button>
      </div>
    </div>
  );
};

export default TargetAudienceCard;
