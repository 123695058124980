import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ClipboardIcon, CheckIcon, CpuChipIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import toast from 'react-hot-toast';

const AIAgentMacro = ({ content, agent, isDarkMode }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Handle copy functionality
  const handleCopy = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      toast.success('Content copied to clipboard!', {
        position: 'top-center',
        style: isDarkMode 
          ? { background: '#1e1e1e', color: '#fff', border: '1px solid #333333' }
          : { background: '#fff', color: '#000', border: '1px solid #e5e7eb' }
      });
    } catch (error) {
      console.error('Copy failed:', error);
      toast.error('Failed to copy content', {
        position: 'top-center',
        style: isDarkMode 
          ? { background: '#1e1e1e', color: '#fff', border: '1px solid #333333' }
          : { background: '#fff', color: '#000', border: '1px solid #e5e7eb' }
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  // Keyboard handler for accessibility
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleCopy(e);
    }
  };

  return (
    <div 
      className={`
        relative group rounded-lg border shadow-sm mt-4 overflow-hidden
        ${isDarkMode 
          ? 'bg-[#151515] border-[#333333]' 
          : 'bg-white border-gray-200'
        }
      `}
      role="article"
      aria-label={`${agent?.name || 'AI Agent'} Output`}
    >
      {/* Macro Window Header */}
      <div 
        className={`
          flex items-center justify-between px-4 py-2 border-b
          ${isDarkMode ? 'bg-[#1a1a1a] border-[#333333]' : 'bg-gray-50 border-gray-200'}
        `}
      >
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1.5">
            <div className="w-3 h-3 rounded-full bg-[#FF605C] hover:brightness-90 transition-all"></div>
            <div className="w-3 h-3 rounded-full bg-[#FFBD44] hover:brightness-90 transition-all"></div>
            <div className="w-3 h-3 rounded-full bg-[#00CA4E] hover:brightness-90 transition-all"></div>
          </div>
          <div className="flex items-center ml-2">
            {agent?.avatarUrl ? (
              <img 
                src={agent.avatarUrl} 
                alt={`${agent.name} avatar`}
                className={`h-5 w-5 rounded-full mr-2 flex-shrink-0 ${
                  isDarkMode ? 'border border-slate-600' : 'border border-slate-200'
                }`}
                onError={(e) => {
                  e.target.src = 'fallback-avatar.png';
                  e.target.onerror = null;
                }}
              />
            ) : (
              <CpuChipIcon className={`h-5 w-5 mr-2 flex-shrink-0 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`} />
            )}
            <span 
              className={`
                text-xs font-medium font-inter tracking-tight
                ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
              `}
            >
              {agent?.name || 'AI Agent'}
            </span>
            {agent?.categories && agent.categories.length > 0 && (
              <span className={`
                text-[10px] ml-2 px-2 py-0.5 rounded-full
                ${isDarkMode 
                  ? 'bg-slate-800 text-slate-400' 
                  : 'bg-slate-100 text-slate-600'}
              `}>
                {agent.categories[0]}
              </span>
            )}
          </div>
        </div>
        <button
          onClick={handleCopy}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          className={`
            p-1.5 rounded-md transition-all duration-200
            ${isCopied 
              ? isDarkMode 
                ? 'bg-green-500/20 text-green-400' 
                : 'bg-green-100 text-green-600'
              : isDarkMode 
                ? 'bg-[#252525] hover:bg-[#333333] text-gray-400 hover:text-gray-300' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700'
            }
            ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
          `}
          title="Copy content"
          aria-label="Copy content to clipboard"
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={isCopied ? 'check' : 'copy'}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {isCopied ? (
                <CheckIcon className="w-3.5 h-3.5" />
              ) : (
                <ClipboardIcon className="w-3.5 h-3.5" />
              )}
            </motion.div>
          </AnimatePresence>
        </button>
      </div>

      {/* Content Area */}
      <div 
        className={`
          p-4 space-y-4 font-jetbrains text-sm whitespace-pre-wrap leading-relaxed tracking-tight
          ${isDarkMode ? 'text-slate-300' : 'text-slate-800'}
        `}
      >
        {content}
      </div>
    </div>
  );
};

AIAgentMacro.propTypes = {
  content: PropTypes.string.isRequired,
  agent: PropTypes.shape({
    name: PropTypes.string,
    avatarUrl: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string)
  }),
  isDarkMode: PropTypes.bool.isRequired
};

export default React.memo(AIAgentMacro); 