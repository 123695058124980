import io from 'socket.io-client';

// Use the same URL pattern as API configuration
const SOCKET_URLS = process.env.REACT_APP_API_URLS ? 
  process.env.REACT_APP_API_URLS.split(',').map(url => url.trim()) : 
  [
    'https://api.ammmplify.com',  // Primary API domain
    'https://ghost-app-backend.vercel.app',  // Fallback domain
    'http://localhost:3001'  // Local development
  ];

// Get the best socket URL based on environment
const getBestSocketUrl = () => {
  const isLocalhost = window.location.hostname === 'localhost';
  const isDevelopment = process.env.NODE_ENV === 'development';
  return (isLocalhost || isDevelopment) ? SOCKET_URLS[2] : SOCKET_URLS[0];
};

// Add retry configuration
const RETRY_CONFIG = {
  maxRetries: 3,
  initialDelay: 1000,
  maxDelay: 5000,
  factor: 2
};

let currentUrlIndex = 0;
let retryCount = 0;

export const socket = io(getBestSocketUrl(), {
  autoConnect: false,
  withCredentials: true,
  transports: ['polling', 'websocket'],
  reconnectionAttempts: 5,
  reconnectionDelay: 2000,
  reconnectionDelayMax: 10000,
  timeout: 60000,
  forceNew: true,
  path: '/socket.io/',
  rejectUnauthorized: false,
  pingTimeout: 60000,
  pingInterval: 25000,
  upgrade: true,
  rememberUpgrade: true,
  secure: true,
  rejectUnauthorized: false
});

const getNextDelay = () => {
  const delay = Math.min(
    RETRY_CONFIG.initialDelay * Math.pow(RETRY_CONFIG.factor, retryCount),
    RETRY_CONFIG.maxDelay
  );
  return delay;
};

const tryNextUrl = () => {
  currentUrlIndex = (currentUrlIndex + 1) % SOCKET_URLS.length;
  const nextUrl = SOCKET_URLS[currentUrlIndex];
  console.log(`Attempting connection to next URL: ${nextUrl}`);
  
  if (socket.connected) {
    socket.disconnect();
  }
  
  socket.io.uri = nextUrl;
  socket.io.opts.transports = nextUrl.includes('localhost') ? 
    ['polling', 'websocket'] : 
    ['websocket', 'polling'];
    
  return nextUrl;
};

// Connect when token is available
export const connectSocket = (token) => {
  if (!token) {
    console.error('No token provided for socket connection');
    return;
  }

  socket.removeAllListeners();
  socket.auth = { token };
  
  socket.on('connect_error', (error) => {
    console.error('Socket connection error:', {
      message: error.message,
      type: error.type,
      context: error.context,
      currentUrl: socket.io.uri,
      transport: socket.io.engine?.transport?.name
    });

    if (retryCount < RETRY_CONFIG.maxRetries) {
      const delay = getNextDelay();
      retryCount++;
      
      setTimeout(() => {
        console.log(`Retrying connection (attempt ${retryCount}/${RETRY_CONFIG.maxRetries})`);
        const nextUrl = tryNextUrl();
        
        console.log(`Connecting to ${nextUrl} with transport:`, socket.io.opts.transports);
        socket.connect();
      }, delay);
    } else {
      console.error('Max retry attempts reached');
      retryCount = 0;
      socket.disconnect();
      socket.emit('connection_failed');
    }
  });

  socket.on('connect', () => {
    console.log('Socket connected successfully to:', socket.io.uri);
    retryCount = 0; // Reset retry count on successful connection
    
    // Add health check
    const healthCheck = setInterval(() => {
      if (!socket.connected) {
        console.log('Health check failed, attempting reconnection...');
        socket.connect();
      }
    }, 30000);

    socket.on('disconnect', () => {
      clearInterval(healthCheck);
    });
  });

  socket.on('disconnect', (reason) => {
    console.log('Socket disconnected:', reason);
    if (reason === 'io server disconnect') {
      // Server initiated disconnect, attempt reconnection
      setTimeout(() => socket.connect(), 1000);
    }
  });

  try {
    socket.connect();
  } catch (error) {
    console.error('Socket connection attempt failed:', error);
  }
};

// Disconnect and clear auth
export const disconnectSocket = () => {
  retryCount = 0;
  currentUrlIndex = 0;
  socket.auth = null;
  socket.disconnect();
}; 