import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useLocation, Link } from 'react-router-dom';
import { verifyEmail, resendVerificationEmail } from '../services/supabaseAuth';
import { CheckCircleIcon, XCircleIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';
import logo from '../logo.svg';

const VerificationPage = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [status, setStatus] = useState('pending'); // pending, verifying, success, error
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Get email from location state (from registration)
    const emailFromState = location.state?.email;
    if (emailFromState) {
      setEmail(emailFromState);
      setMessage(location.state.message || 'Please check your email for verification link.');
    }

    // Check if we have a token in the URL (from email link)
    const token = searchParams.get('token');
    if (token) {
      handleVerification(token);
    }
  }, [location, searchParams]);

  const handleVerification = async (token) => {
    try {
      setStatus('verifying');
      const result = await verifyEmail(token);
      setStatus('success');
      setMessage(result.message);
      
      // Shows success toast and redirects to login after 3 seconds with state
      toast.success('Email verified successfully! Redirecting to login...');
      setTimeout(() => {
        navigate('/login', {
          state: {
            verificationSuccess: true,
            message: 'Email verified successfully! Please log in.'
          }
        });
      }, 3000);
    } catch (error) {
      setStatus('error');
      setMessage(error.message || 'Verification failed. Please try again.');
      toast.error(error.message || 'Verification failed. Please try again.');
      
      // In case of error, navigate with error state
      setTimeout(() => {
        navigate('/login', {
          state: {
            verificationError: true,
            message: error.message || 'Verification failed. Please try again.'
          }
        });
      }, 3000);
    }
  };

  const handleResendEmail = async () => {
    if (!email) {
      toast.error('No email address available');
      return;
    }
    try {
      const result = await resendVerificationEmail(email);
      toast.success(result.message);
      setMessage(result.message);
    } catch (error) {
      toast.error(error.message || 'Failed to resend verification email.');
      setMessage(error.message || 'Failed to resend verification email.');
    }
  };

  return (
    <div className={`min-h-screen flex items-center justify-center ${
      isDarkMode ? 'bg-[#121212]' : 'bg-white'
    }`}>
      <div className="max-w-md w-full space-y-8 p-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <Link to="/" className="inline-block group mb-8">
            <motion.img
              className="mx-auto h-8 w-auto transition-transform duration-300 group-hover:scale-110"
              src={logo}
              alt="Ammmplify"
            />
            <span className={`text-xl font-bold mt-2 inline-block ${
              isDarkMode ? 'text-white' : 'text-slate-900'
            }`}>
              Ammmplify
            </span>
          </Link>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className={`rounded-2xl shadow-xl p-8 ${
            isDarkMode ? 'bg-[#1E1E1E] text-white' : 'bg-white text-slate-900'
          }`}
        >
          {status === 'pending' && (
            <div className="space-y-6">
              <div className="text-center">
                <EnvelopeIcon className={`mx-auto h-12 w-12 ${isDarkMode ? 'text-slate-400' : 'text-slate-600'} mb-4`} />
                <h2 className={`text-2xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-slate-900'}`}>Check your email</h2>
                <p className={`${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
                  {message}
                </p>
                <div className={`mt-4 text-sm font-medium ${
                  isDarkMode ? 'text-slate-300' : 'text-slate-700'
                }`}>
                  Verification email sent to:
                  <span className={`block mt-1 font-bold ${
                    isDarkMode ? 'text-slate-200' : 'text-slate-900'
                  }`}>
                    {email}
                  </span>
                </div>
              </div>
            </div>
          )}

          {status === 'verifying' && (
            <div className="text-center space-y-4">
              <div className={`animate-spin rounded-full h-12 w-12 border-b-2 mx-auto ${
                isDarkMode ? 'border-slate-400' : 'border-slate-600'
              }`}></div>
              <p className={`text-lg ${isDarkMode ? 'text-white' : 'text-slate-900'}`}>
                Verifying your email...
              </p>
            </div>
          )}

          {status === 'success' && (
            <div className="text-center space-y-4">
              <CheckCircleIcon className="mx-auto h-12 w-12 text-green-500" />
              <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-slate-900'}`}>Email Verified!</h2>
              <p className={`${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
                {message}
              </p>
              <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
                Redirecting to login...
              </p>
            </div>
          )}

          {status === 'error' && (
            <div className="text-center space-y-4">
              <XCircleIcon className="mx-auto h-12 w-12 text-red-500" />
              <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>Verification Failed</h2>
              <p className={`${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
                {message}
              </p>
              <button
                onClick={() => navigate('/login')}
                className={`w-full flex justify-center py-2.5 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium transition-all duration-200 ${
                  isDarkMode
                    ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border-[#333333] focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#121212] focus:ring-blue-500/30'
                    : 'bg-slate-900 text-white hover:bg-slate-800 focus:ring-2 focus:ring-offset-2 focus:ring-slate-900'
                }`}
              >
                Return to Login
              </button>
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default VerificationPage; 