import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useClickAway } from 'react-use';
import { 
  MicrophoneIcon, 
  DocumentTextIcon, 
  PaperClipIcon,
  ChatBubbleBottomCenterTextIcon,
  BookmarkIcon,
  XMarkIcon,
  Squares2X2Icon,
  CpuChipIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '../lib/utils';
import RedditIcon from './icons/RedditIcon';

// Memoized MenuItem Component with enhanced accessibility
const MenuItem = memo(({ icon: Icon, label, onClick, description, isDarkMode, variant = 'default' }) => {
  // Define color variants with more subtle colors
  const getVariantStyles = (variant) => {
    const variants = {
      default: isDarkMode 
        ? "hover:bg-[#1a1a1a] hover:bg-opacity-40"
        : "hover:bg-slate-100 hover:bg-opacity-40",
      files: isDarkMode
        ? "hover:bg-blue-900/10"
        : "hover:bg-blue-50/40",
      templates: isDarkMode
        ? "hover:bg-purple-900/10"
        : "hover:bg-purple-50/40",
      notes: isDarkMode
        ? "hover:bg-green-900/10"
        : "hover:bg-green-50/40",
      ideas: isDarkMode
        ? "hover:bg-amber-900/10"
        : "hover:bg-amber-50/40"
    };
    return variants[variant] || variants.default;
  };

  const getIconStyles = (variant) => {
    const variants = {
      default: isDarkMode 
        ? "bg-[#252525] group-hover:bg-[#2a2a2a]"
        : "bg-slate-100 group-hover:bg-slate-200",
      files: isDarkMode
        ? "bg-blue-900/20 group-hover:bg-blue-800/25"
        : "bg-blue-100/60 group-hover:bg-blue-200/60",
      templates: isDarkMode
        ? "bg-purple-900/20 group-hover:bg-purple-800/25"
        : "bg-purple-100/60 group-hover:bg-purple-200/60",
      notes: isDarkMode
        ? "bg-green-900/20 group-hover:bg-green-800/25"
        : "bg-green-100/60 group-hover:bg-green-200/60",
      ideas: isDarkMode
        ? "bg-amber-900/20 group-hover:bg-amber-800/25"
        : "bg-amber-100/60 group-hover:bg-amber-200/60"
    };
    return variants[variant] || variants.default;
  };

  return (
    <motion.button
      onClick={onClick}
      whileHover={{ scale: 1.0025 }}
      whileTap={{ scale: 0.9975 }}
      className={cn(
        "relative flex w-full items-center gap-3 rounded-lg px-3 py-2",
        "transition-all duration-75 group focus:outline-none",
        "focus:ring-1 focus:ring-offset-1 overflow-hidden",
        getVariantStyles(variant),
        isDarkMode 
          ? [
            "text-slate-200 focus:ring-slate-700",
            "focus:ring-offset-[#151515]"
          ]
          : [
            "text-slate-700 focus:ring-slate-200",
            "focus:ring-offset-white"
          ]
      )}
      role="menuitem"
      tabIndex={0}
      aria-label={`${label} - ${description}`}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onClick();
        }
      }}
    >
      <div className={cn(
        "flex items-center justify-center w-[22px] h-[22px] rounded-md flex-shrink-0",
        "transition-all duration-75",
        getIconStyles(variant)
      )}>
        {Icon === RedditIcon ? (
          <RedditIcon 
            className="h-3.5 w-3.5"
            showBackground={false}
            isDarkMode={isDarkMode}
          />
        ) : (
          <Icon className={cn(
            "h-3.5 w-3.5 transition-all duration-75",
            isDarkMode 
              ? "text-slate-400 group-hover:text-slate-300" 
              : "text-slate-500 group-hover:text-slate-600"
          )} />
        )}
      </div>
      
      <div className="flex flex-col items-start min-w-0 flex-1">
        <span className={cn(
          "text-[11px] font-medium truncate leading-none mb-0.5 transition-colors duration-75",
          isDarkMode 
            ? "text-slate-300 group-hover:text-slate-200" 
            : "text-slate-700 group-hover:text-slate-800"
        )}>
          {label}
        </span>
        <span className={cn(
          "text-[10px] leading-none truncate transition-colors duration-75",
          isDarkMode 
            ? "text-slate-500 group-hover:text-slate-400" 
            : "text-slate-500 group-hover:text-slate-600"
        )}>
          {description}
        </span>
      </div>
    </motion.button>
  );
});

MenuItem.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['default', 'files', 'templates', 'notes', 'ideas'])
};

MenuItem.displayName = 'MenuItem';

// Memoized MenuSection Component with enhanced accessibility
const MenuSection = memo(({ title, isDarkMode, children }) => (
  <div 
    className="px-2.5" 
    role="group" 
    aria-labelledby={`section-${title}`}
  >
    {title && (
      <div className="flex flex-col px-1 mb-1">
        <span 
          id={`section-${title}`}
          className={cn(
            "text-[9px] font-medium uppercase tracking-wider",
            isDarkMode ? "text-slate-600" : "text-slate-400"
          )}
        >
          {title}
        </span>
      </div>
    )}
    <div className="space-y-0.5">
      {children}
    </div>
  </div>
));

MenuSection.propTypes = {
  title: PropTypes.string,
  isDarkMode: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

MenuSection.displayName = 'MenuSection';

// Main AddContextMenu Component
const AddContextMenu = ({ 
  onSelectVoiceNote, 
  onSelectTextNote, 
  onSelectRedditIdeas,
  onSelectFileUpload,
  onSelectContentIdeas,
  onClose,
  isDarkMode,
  isOpen
}) => {
  const menuRef = React.useRef(null);
  
  useClickAway(menuRef, (event) => {
    if (!event.target.closest('[data-menu-trigger]')) {
      onClose();
    }
  });

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        ref={menuRef}
        initial={{ opacity: 0, scale: 0.95, y: 10 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        exit={{ opacity: 0, scale: 0.95, y: 10 }}
        transition={{ duration: 0.1, ease: "easeOut" }}
        className={cn(
          "absolute bottom-full mb-2 w-[270px] rounded-lg border shadow-lg md:right-0 right-[-80px]",
          "backdrop-blur-xl backdrop-saturate-150",
          "transform origin-bottom-right overflow-hidden",
          isDarkMode 
            ? [
              "bg-[#151515] bg-opacity-90",
              "border-[#262626]",
              "shadow-black/30"
            ]
            : [
              "bg-white bg-opacity-90",
              "border-slate-200/60",
              "shadow-slate-200/30"
            ]
        )}
        role="menu"
        aria-label="Add Context Menu"
      >
        {/* Header */}
        <div className={cn(
          "flex items-center justify-between px-3 py-1.5 border-b",
          isDarkMode 
            ? "border-[#262626] bg-[#151515]" 
            : "border-slate-200/60 bg-white"
        )}>
          <span className={cn(
            "text-[11px] font-medium",
            isDarkMode ? "text-slate-300" : "text-slate-600"
          )} role="heading" aria-level="2">
            Add Context
          </span>
          <button
            onClick={onClose}
            className={cn(
              "p-0.5 rounded-md transition-colors duration-75",
              isDarkMode 
                ? [
                  "hover:bg-[#252525] text-slate-500 hover:text-slate-400"
                ]
                : [
                  "hover:bg-slate-100 text-slate-400 hover:text-slate-500"
                ]
            )}
            aria-label="Close menu"
          >
            <XMarkIcon className="w-3.5 h-3.5" />
          </button>
        </div>

        <div className="py-2 space-y-2">
          {/* Files */}
          <MenuSection title="Files as context" isDarkMode={isDarkMode}>
            <MenuItem
              icon={PaperClipIcon}
              label="Upload Files"
              description="Attach files as context"
              onClick={onSelectFileUpload}
              isDarkMode={isDarkMode}
              variant="files"
            />
          </MenuSection>

          {/* Notes */}
          <MenuSection title="Notes as context" isDarkMode={isDarkMode}>
            <MenuItem
              icon={MicrophoneIcon}
              label="Voice Notes"
              description="Attach your voice notes as context"
              onClick={onSelectVoiceNote}
              isDarkMode={isDarkMode}
              variant="notes"
            />
            <MenuItem
              icon={DocumentTextIcon}
              label="Text Notes"
              description="Attach your text notes as context"
              onClick={onSelectTextNote}
              isDarkMode={isDarkMode}
              variant="notes"
            />
          </MenuSection>

          {/* Ideas */}
          <MenuSection title="Ideas as context" isDarkMode={isDarkMode}>
            <MenuItem
              icon={SparklesIcon}
              label="Content Ideas"
              description="Add your generated content ideas"
              onClick={onSelectContentIdeas}
              isDarkMode={isDarkMode}
              variant="ideas"
            />
            <MenuItem
              icon={RedditIcon}
              label="Reddit Insights"
              description="Turn Reddit gold into viral content"
              onClick={onSelectRedditIdeas}
              isDarkMode={isDarkMode}
              variant="ideas"
            />
          </MenuSection>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

AddContextMenu.propTypes = {
  onSelectVoiceNote: PropTypes.func.isRequired,
  onSelectTextNote: PropTypes.func.isRequired,
  onSelectRedditIdeas: PropTypes.func.isRequired,
  onSelectFileUpload: PropTypes.func.isRequired,
  onSelectContentIdeas: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default memo(AddContextMenu);