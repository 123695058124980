import React, { createContext, useContext, useState, useEffect } from 'react';
import SubscriptionService from '../services/subscriptionService';
import { useAuth } from './AuthContext';

const SubscriptionContext = createContext();

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
};

export const SubscriptionProvider = ({ children }) => {
  const { user, refreshSubscriptionStatus } = useAuth();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSubscriptionStatus = async () => {
    try {
      if (user) {
        const [subscriptionStatus, trialStatus] = await Promise.all([
          refreshSubscriptionStatus(),
          SubscriptionService.checkTrialStatus()
        ]);

        // Update user's trial status
        if (trialStatus) {
          user.isInTrial = trialStatus.isInTrial;
          user.hasTrialExpired = trialStatus.hasExpired;
          user.trialEndDate = trialStatus.trialEnd;
          user.trialPlanId = trialStatus.planId;
        }

        // Update subscription status if changed
        setSubscriptionStatus(subscriptionStatus);
      } else {
        setSubscriptionStatus(null);
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching subscription status:', err);
      setError('Failed to fetch subscription status');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchSubscriptionStatus();
    } else {
      setSubscriptionStatus(null);
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;

    let isSubscribed = true;
    const interval = setInterval(async () => {
      if (isSubscribed) {
        await fetchSubscriptionStatus();
      }
    }, 5 * 60 * 1000);

    return () => {
      isSubscribed = false;
      clearInterval(interval);
    };
  }, [user]);

  const subscribe = async (planId) => {
    try {
      await SubscriptionService.createCheckoutSession(planId);
    } catch (err) {
      console.error('Error creating subscription:', err);
      throw err;
    }
  };

  const cancelSubscription = async () => {
    try {
      await SubscriptionService.cancelSubscription();
      await fetchSubscriptionStatus();
    } catch (err) {
      console.error('Error canceling subscription:', err);
      throw err;
    }
  };

  const reactivateSubscription = async () => {
    try {
      await SubscriptionService.reactivateSubscription();
      await fetchSubscriptionStatus();
    } catch (err) {
      console.error('Error reactivating subscription:', err);
      throw err;
    }
  };

  const updateWordUsage = async (wordCount) => {
    try {
      const result = await SubscriptionService.updateWordUsage(wordCount);
      await fetchSubscriptionStatus();
      return result;
    } catch (err) {
      console.error('Error updating word usage:', err);
      throw err;
    }
  };

  const checkWordLimit = (wordCount) => {
    if (!subscriptionStatus) return false;
    return subscriptionStatus.wordsUsed + wordCount <= subscriptionStatus.wordLimit;
  };

  const getRemainingWords = () => {
    if (!subscriptionStatus) return 0;
    return Math.max(0, subscriptionStatus.wordLimit - subscriptionStatus.wordsUsed);
  };

  const getSubscriptionPlans = () => {
    return SubscriptionService.getSubscriptionPlans();
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionStatus,
        loading,
        error,
        subscribe,
        cancelSubscription,
        reactivateSubscription,
        updateWordUsage,
        checkWordLimit,
        getRemainingWords,
        getSubscriptionPlans,
        refreshStatus: fetchSubscriptionStatus
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}; 