import React, { useState } from 'react';
import { motion } from 'framer-motion';
import PublicPageLayout from './layout/PublicPageLayout';
import PageSection from './shared/PageSection';
import { useTheme } from '../../context/ThemeContext';
import { 
  MagnifyingGlassIcon,
  NewspaperIcon,
  ExclamationCircleIcon,
  FunnelIcon,
  ArrowPathIcon
} from '@heroicons/react/24/outline';

const BlogPage = () => {
  const { isDarkMode } = useTheme();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [subscribeStatus, setSubscribeStatus] = useState(null); // 'success' | 'error' | null

  const categories = [
    { id: 'all', name: 'All Posts' },
    { id: 'tips', name: 'Tips & Tricks' },
    { id: 'guides', name: 'Guides' },
    { id: 'updates', name: 'Product Updates' },
    { id: 'case-studies', name: 'Case Studies' }
  ];

  // State Components
  const StateWrapper = ({ icon: Icon, title, description, action }) => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className={`
        p-8 rounded-xl border text-center
        ${isDarkMode 
          ? 'bg-[#151515] border-white/10' 
          : 'bg-white border-slate-200/50'
        }
      `}
    >
      <div className={`
        mx-auto w-12 h-12 rounded-xl mb-4
        flex items-center justify-center
        ${isDarkMode 
          ? 'bg-blue-500/10 text-blue-400' 
          : 'bg-blue-50 text-blue-600'
        }
      `}>
        <Icon className="w-6 h-6" />
      </div>
      <h3 className={`
        text-lg font-medium mb-2
        ${isDarkMode ? 'text-white' : 'text-slate-900'}
      `}>
        {title}
      </h3>
      <p className={`
        text-sm mb-4
        ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
      `}>
        {description}
      </p>
      {action && (
        <button
          onClick={action.onClick}
          className={`
            text-sm font-medium
            ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'}
          `}
        >
          {action.label}
        </button>
      )}
    </motion.div>
  );

  const LoadingState = () => (
    <StateWrapper
      icon={() => (
        <ArrowPathIcon className="w-6 h-6 animate-spin" />
      )}
      title="Loading articles"
      description="Please wait while we fetch the latest content..."
    />
  );

  const EmptyState = () => (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className={`
        p-8 rounded-xl border
        ${isDarkMode 
          ? 'bg-[#151515] border-white/10' 
          : 'bg-white border-slate-200/50'
        }
      `}
    >
      <div className="text-center">
        <div className={`
          mx-auto w-12 h-12 rounded-xl mb-4
          flex items-center justify-center
          ${isDarkMode 
            ? 'bg-blue-500/10 text-blue-400' 
            : 'bg-blue-50 text-blue-600'
          }
        `}>
          <NewspaperIcon className="w-6 h-6" />
        </div>
        <h3 className={`
          text-lg font-medium mb-2
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}>
          Blog Coming Soon
        </h3>
        <p className={`
          text-sm mb-6 max-w-md mx-auto
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}>
          We're crafting insightful articles about AI-powered content creation, 
          personal branding, and LinkedIn growth strategies. Be the first to know 
          when we publish.
        </p>

        <form onSubmit={handleSubscribe} className="max-w-sm mx-auto">
          <div className="flex gap-2">
            <div className="relative flex-grow">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className={`
                  w-full px-4 py-2 rounded-lg text-sm
                  focus:outline-none focus:ring-2
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] border-white/10 text-white focus:ring-blue-500/50' 
                    : 'bg-white border-slate-200 text-slate-900 focus:ring-blue-500/20'
                  }
                  border
                `}
              />
            </div>
            <button
              type="submit"
              disabled={isSubscribing}
              className={`
                px-4 py-2 rounded-lg text-sm font-medium whitespace-nowrap
                transition-all duration-200
                ${isSubscribing
                  ? 'bg-blue-400 cursor-not-allowed'
                  : 'bg-blue-500 hover:bg-blue-600 hover:shadow-lg hover:shadow-blue-500/20'
                }
                text-white flex-shrink-0
              `}
            >
              {isSubscribing ? (
                <span className="flex items-center gap-2">
                  <ArrowPathIcon className="w-3 h-3 animate-spin" />
                  Subscribing...
                </span>
              ) : (
                'Notify me'
              )}
            </button>
          </div>

          {/* Subscription Status */}
          {subscribeStatus && (
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className={`
                mt-2 text-xs
                ${subscribeStatus === 'success'
                  ? isDarkMode ? 'text-green-400' : 'text-green-600'
                  : isDarkMode ? 'text-red-400' : 'text-red-600'
                }
              `}
            >
              {subscribeStatus === 'success'
                ? "Thanks! We'll notify you when we launch."
                : "Couldn't subscribe. Please try again."
              }
            </motion.p>
          )}
        </form>

        {/* Preview Topics */}
        <div className="mt-8 flex flex-wrap justify-center gap-2">
          {['AI Writing', 'Personal Branding', 'LinkedIn Growth', 'Content Strategy'].map((topic) => (
            <span
              key={topic}
              className={`
                px-2 py-1 rounded-md text-xs
                ${isDarkMode
                  ? 'bg-[#1a1a1a] text-slate-400 border border-white/10'
                  : 'bg-slate-50 text-slate-600 border border-slate-200/50'
                }
              `}
            >
              {topic}
            </span>
          ))}
        </div>
      </div>
    </motion.div>
  );

  const ErrorState = () => (
    <StateWrapper
      icon={ExclamationCircleIcon}
      title="Couldn't load articles"
      description="There was a problem loading the blog posts. Please try again."
      action={{
        label: "Try again",
        onClick: () => {
          setError(null);
          // Add your retry logic here
        }
      }}
    />
  );

  const NoResultsState = () => (
    <StateWrapper
      icon={FunnelIcon}
      title="No matching articles"
      description={`No articles found for "${searchQuery}" in ${
        selectedCategory === 'all' ? 'any category' : categories.find(c => c.id === selectedCategory)?.name
      }`}
      action={{
        label: "Clear filters",
        onClick: () => {
          setSearchQuery('');
          setSelectedCategory('all');
        }
      }}
    />
  );

  const renderContent = () => {
    if (isLoading) return <LoadingState />;
    if (error) return <ErrorState />;
    if (searchQuery || selectedCategory !== 'all') return <NoResultsState />;
    return <EmptyState />;
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setIsSubscribing(true);
    setSubscribeStatus(null);
    
    try {
      // Add your newsletter signup logic here
      await new Promise(resolve => setTimeout(resolve, 1000));
      setSubscribeStatus('success');
      setEmail('');
    } catch (error) {
      setSubscribeStatus('error');
    } finally {
      setIsSubscribing(false);
    }
  };

  return (
    <PublicPageLayout
      title="Blog"
      description="Insights, updates, and resources"
      className="-mt-8"
    >
      <PageSection className="py-4">
        <div className="max-w-2xl mx-auto space-y-6">
          {/* Search */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className={`
              p-4 rounded-xl border
              ${isDarkMode 
                ? 'bg-[#151515] border-white/10' 
                : 'bg-white border-slate-200/50'
              }
            `}
          >
            <div className="relative">
              <MagnifyingGlassIcon className={`
                absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4
                ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
              `} />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search articles..."
                className={`
                  w-full pl-9 pr-4 py-2 rounded-lg text-sm
                  focus:outline-none focus:ring-2
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] border-white/10 text-white focus:ring-blue-500/50' 
                    : 'bg-white border-slate-200 text-slate-900 focus:ring-blue-500/20'
                  }
                  border
                `}
              />
            </div>
          </motion.div>

          {/* Categories */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="flex flex-wrap gap-2"
          >
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => setSelectedCategory(category.id)}
                className={`
                  px-3 py-1.5 rounded-lg text-xs font-medium
                  transition-all duration-200
                  ${selectedCategory === category.id
                    ? isDarkMode
                      ? 'bg-blue-500/10 text-blue-400 ring-1 ring-blue-500/30'
                      : 'bg-blue-50 text-blue-600 ring-1 ring-blue-200'
                    : isDarkMode
                      ? 'bg-[#151515] text-slate-400 hover:bg-[#1a1a1a] border border-white/10'
                      : 'bg-white text-slate-600 hover:bg-slate-50 border border-slate-200/50'
                  }
                `}
              >
                {category.name}
              </button>
            ))}
          </motion.div>

          {/* Dynamic State Content */}
          {renderContent()}
        </div>
      </PageSection>
    </PublicPageLayout>
  );
};

export default BlogPage; 