import api from '../api';
import config from '../config';

const getGoogleAuthUrl = async () => {
  try {
    console.log('🔵 Getting Google auth URL from:', `${config.apiURL}/auth/google/auth-url`);
    const response = await api.get(config.googleAuthEndpoints.getAuthUrl);
    console.log('✅ Got Google auth URL:', response.data);
    return response.data.url;
  } catch (error) {
    console.error('❌ Error getting Google auth URL:', {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
      config: {
        baseURL: error.config?.baseURL,
        url: error.config?.url,
        method: error.config?.method
      }
    });
    throw error;
  }
};

const handleGoogleCallback = async (code) => {
  try {
    console.log('🔵 Handling Google callback with code:', code?.substring(0, 10) + '...');
    const response = await api.post(config.googleAuthEndpoints.callback, { code });
    console.log('✅ Google callback successful');
    return response.data;
  } catch (error) {
    console.error('❌ Error handling Google callback:', {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data
    });
    throw error;
  }
};

const refreshGoogleTokens = async (refreshToken) => {
  try {
    console.log('🔵 Refreshing Google tokens');
    const response = await api.post(config.googleAuthEndpoints.refreshToken, { refreshToken });
    console.log('✅ Google tokens refreshed successfully');
    return response.data;
  } catch (error) {
    console.error('❌ Error refreshing Google tokens:', {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data
    });
    throw error;
  }
};

export { getGoogleAuthUrl, handleGoogleCallback, refreshGoogleTokens }; 