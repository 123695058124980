import React, { useEffect, useState, memo, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { contentStrategyProgressService } from '../../services/contentStrategyProgressService';
import { 
  SparklesIcon,
  DocumentTextIcon,
  ChevronDownIcon,
  StarIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  PlusSmallIcon,
  MinusSmallIcon,
  EllipsisHorizontalIcon,
  ArrowDownTrayIcon,
  XMarkIcon,
  FolderIcon,
  BookmarkIcon,
  LightBulbIcon,
  AcademicCapIcon
} from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import {
  WrenchScrewdriverIcon,
  BookOpenIcon,
  ExclamationTriangleIcon,
  ChatBubbleBottomCenterTextIcon,
  RocketLaunchIcon,
  HeartIcon,
  PuzzlePieceIcon,
  QuestionMarkCircleIcon,
  ArrowTrendingUpIcon,
  CalculatorIcon,
  BeakerIcon,
} from '@heroicons/react/24/outline';
import ContentStrategyPDFDownloader from './ContentStrategyPDFDownloader';
import { saveIdea, getClientFolders, moveIdeaToFolder, saveContentStrategyIdeas } from '../../api';

// Animation variants
const pageVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.4, ease: "easeOut" }
  },
  exit: { 
    opacity: 0,
    y: 20,
    transition: { duration: 0.3, ease: "easeIn" }
  }
};

const expandAnimation = {
  initial: { height: 0, opacity: 0 },
  animate: { 
    height: "auto", 
    opacity: 1,
    transition: { 
      height: { duration: 0.3, ease: "easeOut" },
      opacity: { duration: 0.2, delay: 0.1 }
    }
  },
  exit: { 
    height: 0,
    opacity: 0,
    transition: { 
      height: { duration: 0.3, ease: "easeIn" },
      opacity: { duration: 0.2 }
    }
  }
};

const ideaGroupVariants = {
  initial: { opacity: 0, x: -10 },
  animate: { 
    opacity: 1, 
    x: 0,
    transition: { duration: 0.3 }
  }
};

const ideaCardVariants = {
  initial: { opacity: 0, y: 10 },
  animate: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.2 }
  }
};

// Loading Components
const LoadingSkeleton = memo(({ isDarkMode, selectedPersona }) => (
  <motion.div
    variants={pageVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    className="w-full max-w-4xl mx-auto space-y-4 p-4"
  >
    {/* Loading Action Bar */}
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`sticky top-0 z-10 mb-4 rounded-lg ring-1 backdrop-blur-lg ${
        isDarkMode 
          ? 'bg-[#151515]/90 ring-[#333333]' 
          : 'bg-blue-50/90 ring-blue-100'
      }`}
    >
      <div className="px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className={`h-4 w-32 rounded animate-pulse ${
              isDarkMode ? 'bg-blue-800/20' : 'bg-blue-100'
            }`} />
            {selectedPersona?.name && (
              <>
                <div className={`h-4 w-4 rounded animate-pulse ${
                  isDarkMode ? 'bg-blue-800/20' : 'bg-blue-100'
                }`} />
                <div className={`h-4 w-32 rounded animate-pulse ${
                  isDarkMode ? 'bg-blue-800/20' : 'bg-blue-100'
                }`} />
              </>
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className={`h-7 w-20 rounded-md animate-pulse ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-blue-100'
            }`} />
            <div className={`h-7 w-16 rounded-md animate-pulse ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-blue-100'
            }`} />
          </div>
        </div>
      </div>
    </motion.div>

    {/* Loading Frameworks */}
    {[1, 2, 3].map(index => (
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: index * 0.1 }}
        className={`rounded-xl ring-1 ${
          isDarkMode 
            ? 'bg-[#151515] ring-[#333333]' 
            : 'bg-white/50 ring-slate-200/50'
        }`}
      >
        {/* Framework Header */}
        <div className="px-5 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className={`flex items-center justify-center w-8 h-8 rounded-lg ${
                isDarkMode 
                  ? 'bg-slate-800/50' 
                  : 'bg-slate-100/80'
              }`}>
                <div className={`h-4 w-4 rounded animate-pulse ${
                  isDarkMode ? 'bg-slate-700' : 'bg-slate-200'
                }`} />
              </div>
              <div className={`h-4 w-48 rounded animate-pulse ${
                isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
              }`} />
            </div>

            <div className="flex items-center gap-6">
              <div className="flex items-center gap-4">
                <div className={`h-3 w-20 rounded animate-pulse ${
                  isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                }`} />
                <div className={`h-3 w-20 rounded animate-pulse ${
                  isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                }`} />
              </div>
              <div className={`h-4 w-px ${
                isDarkMode ? 'bg-[#333333]' : 'bg-slate-200'
              }`} />
              <div className={`h-5 w-5 rounded animate-pulse ${
                isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
              }`} />
            </div>
          </div>
        </div>

        {/* Framework Content */}
        <div className="p-4 space-y-4">
          {[1, 2].map(groupIndex => (
            <div key={groupIndex} className="space-y-2">
              {/* Group Header */}
              <div className={`flex items-center justify-between py-2 px-1`}>
                <div className="flex items-center gap-2">
                  <div className={`h-4 w-4 rounded animate-pulse ${
                    isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                  }`} />
                  <div className={`h-3 w-24 rounded animate-pulse ${
                    isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                  }`} />
                  <div className={`h-3 w-16 rounded animate-pulse ${
                    isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                  }`} />
                </div>
                <div className={`h-6 w-6 rounded animate-pulse ${
                  isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                }`} />
              </div>

              {/* Group Content */}
              <div className={`rounded-lg overflow-hidden ring-1 ${
                isDarkMode 
                  ? 'bg-[#1a1a1a] ring-[#333333]' 
                  : 'bg-slate-50/50 ring-slate-200/50'
              }`}>
                <div className={`divide-y ${
                  isDarkMode ? 'divide-[#333333]' : 'divide-slate-200/50'
                }`}>
                  {[1, 2, 3].map(ideaIndex => (
                    <div
                      key={ideaIndex}
                      className="p-4"
                    >
                      <div className="space-y-3">
                        <div className={`h-4 rounded animate-pulse ${
                          isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                        }`} style={{ width: '85%' }} />
                        <div className={`h-4 rounded animate-pulse ${
                          isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                        }`} style={{ width: '65%' }} />
                        <div className={`h-6 w-32 rounded-full animate-pulse ${
                          isDarkMode ? 'bg-slate-800/50' : 'bg-slate-200'
                        }`} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    ))}
  </motion.div>
));

// Utility Components
const CopyButton = memo(({ content, isDarkMode }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(content);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <button
      onClick={handleCopy}
      className={`p-2 rounded-lg transition-colors ${
        isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-slate-100'
      }`}
    >
      {copied ? (
        <CheckIcon className="w-4 h-4 text-green-500" />
      ) : (
        <DocumentDuplicateIcon className="w-4 h-4 text-slate-400" />
      )}
    </button>
  );
});

// Content Components
const IdeaCard = memo(({ idea, isDarkMode, index, topic }) => {
  const [copied, setCopied] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const handleCopy = async (e) => {
    e.stopPropagation();
    const textToCopy = [
      `Topic: ${topic}`,
      `Angle: ${idea.angle}`,
      `Hook: ${idea.hook}`,
      `Content: ${idea.content}`,
      `Value: ${idea.value}`,
      `Expertise: ${idea.expertiseAlignment}`
    ].filter(Boolean).join('\n\n');
      
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      toast.success('Copied to clipboard!');
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      toast.error('Failed to copy to clipboard');
    }
  };

  const handleSave = async (e) => {
    e.stopPropagation();
    try {
      await saveContentStrategyIdeas({
        topic,
        idea: {
          angle: idea.angle,
          hook: idea.hook,
          content: idea.content,
          value: idea.value,
          expertiseAlignment: idea.expertiseAlignment
        }
      });
      setIsSaved(true);
      toast.success('Idea saved successfully!');
      setTimeout(() => setIsSaved(false), 2000);
    } catch (error) {
      toast.error('Failed to save idea');
    }
  };

  return (
    <motion.div
      variants={ideaCardVariants}
      initial="initial"
      animate="animate"
      custom={index}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      className={`w-full px-4 py-5 transition-colors ring-1 ${
        isDarkMode 
          ? 'bg-[#151515] ring-[#333333] hover:bg-[#1a1a1a]' 
          : 'bg-white/50 ring-slate-200/50 hover:bg-slate-50'
      }`}
    >
      <div className="flex items-start justify-between gap-4">
        <div className="flex-1 space-y-3">
          {/* Hook */}
          <h3 className={`text-[15px] font-medium leading-relaxed tracking-tight ${
            isDarkMode ? 'text-slate-200' : 'text-slate-700'
          }`}>
            {idea.hook.replace(/['"]/g, '')}
          </h3>

          {/* Content */}
          <p className={`text-[13px] leading-relaxed ${
            isDarkMode ? 'text-slate-300' : 'text-slate-600'
          }`}>
            {idea.content}
          </p>

          {/* Tags Row */}
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2 pt-1">
            <div className={`inline-flex items-center px-2 py-0.5 text-[12px] rounded-full ${
              isDarkMode 
                ? 'bg-slate-800/50 text-slate-400' 
                : 'bg-slate-100/50 text-slate-500'
            }`}>
              <span className="font-medium mr-1">Angle:</span> {idea.angle}
            </div>

            <div className={`inline-flex items-center px-2 py-0.5 text-[12px] rounded-full ${
              isDarkMode 
                ? 'bg-slate-800/50 text-slate-400' 
                : 'bg-slate-100/50 text-slate-500'
            }`}>
              <span className="font-medium mr-1">Value:</span> {idea.value}
            </div>

            <div className={`inline-flex items-center px-2 py-0.5 text-[12px] rounded-full ${
              isDarkMode 
                ? 'bg-slate-800/50 text-slate-400' 
                : 'bg-slate-100/50 text-slate-500'
            }`}>
              <span className="font-medium mr-1">Alignment:</span> {idea.expertiseAlignment}
            </div>
          </div>
        </div>

        {/* Actions */}
        <div className={`flex flex-col gap-1.5 transition-opacity duration-200 ${
          showActions || copied ? 'opacity-100' : 'opacity-0'
        }`}>
          <button
            onClick={handleCopy}
            className={`p-1.5 rounded-sm transition-colors ${
              isDarkMode ? 'hover:bg-slate-800' : 'hover:bg-slate-100'
            }`}
          >
            {copied ? (
              <CheckIcon className="w-3.5 h-3.5 text-green-500" />
            ) : (
              <DocumentDuplicateIcon className="w-3.5 h-3.5 text-slate-400" />
            )}
          </button>
        </div>
      </div>
    </motion.div>
  );
});

// Only keep the SERIES_ICONS mapping
const SERIES_ICONS = {
  expertTips: LightBulbIcon,
  practicalHacks: WrenchScrewdriverIcon,
  resources: DocumentTextIcon,
  comprehensiveGuides: BookOpenIcon,
  keyLessons: AcademicCapIcon,
  commonMistakes: ExclamationTriangleIcon,
  strategicInsights: ChatBubbleBottomCenterTextIcon,
  quickStart: RocketLaunchIcon,
  emotionalChallenges: HeartIcon,
  implementationChallenges: PuzzlePieceIcon,
  commonMisconceptions: QuestionMarkCircleIcon,
  industryTrends: ArrowTrendingUpIcon,
  impactAnalysis: CalculatorIcon,
  methodologyBreakdown: BeakerIcon,
};

// Update the IdeaGroup component
const IdeaGroup = memo(({ group, framework, isDarkMode }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const groupKey = group.groupName
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  const GroupIcon = SERIES_ICONS[groupKey] || DocumentTextIcon;

  return (
    <motion.div 
      variants={ideaGroupVariants}
      initial="initial"
      animate="animate"
      className={`rounded-xl ring-1 overflow-hidden ${
        isDarkMode 
          ? 'bg-[#1a1a1a] ring-[#333333]' 
          : 'bg-slate-50/50 ring-slate-200/70'
      }`}
    >
      {/* Group Header */}
      <div className={`px-3 sm:px-4 py-2.5 sm:py-3 border-b ${
        isDarkMode ? 'border-slate-800/60' : 'border-slate-200/70'
      }`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1.5 sm:gap-2">
            <div className={`flex items-center gap-2 ${
              isDarkMode ? 'text-slate-400' : 'text-slate-500'
            }`}>
              <GroupIcon className="w-3.5 sm:w-4 h-3.5 sm:h-4" />
              <span className={`text-xs sm:text-sm font-medium ${
                isDarkMode ? 'text-slate-300' : 'text-slate-700'
              }`}>
                {group.groupName}
              </span>
            </div>
            <div className={`flex items-center gap-2 ${
              isDarkMode ? 'text-slate-500' : 'text-slate-400'
            }`}>
              <span className="text-[10px] sm:text-xs">
                {group.ideas?.length || 0} ideas
              </span>
            </div>
          </div>

          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`p-1.5 rounded-md transition-colors ${
              isDarkMode 
                ? 'hover:bg-slate-800/40 text-slate-400' 
                : 'hover:bg-slate-200/80 text-slate-500'
            }`}
          >
            <ChevronDownIcon 
              className={`w-3.5 sm:w-4 h-3.5 sm:h-4 transition-transform duration-200 ${
                isExpanded ? 'transform rotate-180' : ''
              }`} 
            />
          </button>
        </div>
      </div>

      {/* Group Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className={`divide-y ${
              isDarkMode ? 'divide-slate-800/40' : 'divide-slate-200/60'
            }`}
          >
            {group.ideas.map((idea, index) => (
              <IdeaCard
                key={index}
                idea={idea}
                topic={group.groupName}
                isDarkMode={isDarkMode}
                index={index}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});

// Update the Framework component
const Framework = memo(({ framework, isDarkMode, index }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  
  const stats = {
    totalIdeas: framework.ideaGroups.reduce((acc, group) => acc + group.ideas.length, 0),
    seriesCount: framework.ideaGroups.length
  };

  // Format the framework name to be more descriptive
  const getFormattedFrameworkName = (name) => {
    const cleanName = name.replace('FRAMEWORK', '').trim();
    switch (cleanName.toLowerCase()) {
      case 'actionable':
        return 'Action oriented ideas';
      case 'aspirational':
        return 'Strategic perspective ideas';
      case 'anthropological':
        return 'Human emotion-driven ideas';
      case 'analytical':
        return 'Data-driven ideas';
      default:
        return cleanName;
    }
  };

  // Format the series name to be more descriptive
  const getFormattedSeriesName = (name) => {
    const seriesMap = {
      'tips': 'Quick implementation ideas',
      'hacks': 'Smart solutions & strategies',
      'ultimate guides': 'Comprehensive guides',
      'lessons': 'Key lessons',
      'mistakes': 'Common mistakes',
      'reflections': 'Strategic insights',
      'getting started': 'Quick start guide',
      'fears': 'Emotional challenges',
      'challenges': 'Implementation challenges',
      'misconceptions': 'Common misconceptions',
      'trends': 'Industry trends',
      'impact': 'Impact analysis',
      'methodology': 'Methodology breakdown'
    };
    
    const cleanName = name.toLowerCase().replace(' series', '').trim();
    return seriesMap[cleanName] || name;
  };

  const frameworkName = getFormattedFrameworkName(framework.frameworkName);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: index * 0.1 }}
      className={`rounded-xl ring-1 ${
        isDarkMode 
          ? 'bg-[#151515] ring-[#333333]' 
          : 'bg-white/50 ring-slate-200/50'
      }`}
    >
      {/* Framework Header */}
      <div className={`px-3 sm:px-6 py-3 sm:py-4 border-b ${
        isDarkMode ? 'border-slate-800/60' : 'border-slate-200/70'
      }`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 sm:gap-4">
            <div className={`flex items-center justify-center w-8 sm:w-10 h-8 sm:h-10 rounded-lg font-medium ${
              isDarkMode 
                ? 'bg-slate-800/50 text-slate-300' 
                : 'bg-slate-100/80 text-slate-600'
            }`}>
              {index + 1}
            </div>
            <div>
              <h3 className={`text-sm sm:text-base font-medium ${
                isDarkMode ? 'text-slate-200' : 'text-slate-700'
              }`}>
                {frameworkName}
              </h3>
              <p className={`text-xs sm:text-sm mt-0.5 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`}>
                <span className="hidden sm:inline">{stats.seriesCount} series · {stats.totalIdeas} ideas</span>
                <span className="sm:hidden">{stats.totalIdeas} ideas</span>
              </p>
            </div>
          </div>
          
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`p-1.5 sm:p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'hover:bg-slate-800/40 text-slate-400' 
                : 'hover:bg-slate-100/60 text-slate-500'
            }`}
          >
            {isExpanded ? (
              <MinusSmallIcon className="w-4 sm:w-5 h-4 sm:h-5" />
            ) : (
              <PlusSmallIcon className="w-4 sm:w-5 h-4 sm:h-5" />
            )}
          </button>
        </div>
      </div>

      {/* Framework Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div 
            {...expandAnimation} 
            className="p-3 sm:p-6 grid grid-cols-1 gap-3 sm:gap-6"
          >
            {framework.ideaGroups.map((group, groupIndex) => (
              <IdeaGroup
                key={groupIndex}
                group={{
                  ...group,
                  groupName: getFormattedSeriesName(group.groupName)
                }}
                framework={framework}
                isDarkMode={isDarkMode}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});

// Update the ActionBar component
const ActionBar = memo(({ ideas, isDarkMode, selectedPersona }) => {
  const [copyState, setCopyState] = useState('idle');
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  // Calculate total ideas based on the new structure
  const totalIdeas = useMemo(() => {
    if (!ideas?.data?.generatedIdeas) return 0;
    return ideas.data.generatedIdeas.reduce((total, topicIdea) => {
      return total + (Array.isArray(topicIdea.ideas) ? topicIdea.ideas.length : 0);
    }, 0);
  }, [ideas]);

  const copyAllIdeas = async () => {
    if (copyState !== 'idle' || !ideas?.data?.generatedIdeas) return;
    
    setCopyState('copying');
    try {
      const formattedIdeas = ideas.data.generatedIdeas
        .map(topicIdea => {
          return `${topicIdea.topic}\n\n${topicIdea.ideas
            .map(idea => {
              return [
                `Angle: ${idea.angle}`,
                `Hook: ${idea.hook}`,
                `Content: ${idea.content}`,
                `Value: ${idea.value}`,
                `Expertise: ${idea.expertiseAlignment}`
              ].join('\n')
            })
            .join('\n\n')}`
        })
        .join('\n\n---\n\n');

      await navigator.clipboard.writeText(formattedIdeas);
      setCopyState('copied');
      toast.success('All ideas copied to clipboard');
      
      setTimeout(() => {
        setCopyState('idle');
      }, 3500);
    } catch (error) {
      console.error('Copy error:', error);
      toast.error('Failed to copy ideas');
      setCopyState('idle');
    }
  };

  const downloadPDF = () => {
    setDownloadingPDF(true);
    try {
      const pdfDownloader = new ContentStrategyPDFDownloader(ideas, selectedPersona);
      pdfDownloader.generatePDF();
      toast.success('PDF downloaded successfully');
    } catch (error) {
      console.error('PDF generation error:', error);
      toast.error('Failed to generate PDF');
    } finally {
      setDownloadingPDF(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`sticky top-0 z-10 mb-4 rounded-lg ring-1 backdrop-blur-lg ${
        isDarkMode 
          ? 'bg-[#151515]/90 ring-[#333333]' 
          : 'bg-blue-50/90 ring-blue-100'
      }`}
    >
      {/* Mobile View */}
      <div className="sm:hidden">
        <div className="px-3 py-2.5">
          {/* Stats Row */}
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center gap-1">
              <span className={`text-[11px] font-medium ${
                isDarkMode ? 'text-blue-100' : 'text-blue-900'
              }`}>
                {totalIdeas} ideas
              </span>
              {selectedPersona?.name && (
                <span className={`text-[11px] ${
                  isDarkMode ? 'text-blue-400' : 'text-blue-500'
                }`}>
                  for {selectedPersona.name}
                </span>
              )}
            </div>
          </div>

          {/* Action Buttons Row */}
          <div className="flex items-center justify-between gap-2">
            <button
              onClick={copyAllIdeas}
              disabled={copyState !== 'idle'}
              className={`flex-1 flex items-center justify-center gap-1.5 px-3 py-1.5 rounded-md text-[11px] transition-all duration-200 ${
                copyState === 'copied'
                  ? isDarkMode
                    ? 'bg-green-500/20 text-green-400'
                    : 'bg-green-50 text-green-600'
                  : isDarkMode
                    ? 'bg-[#1a1a1a] text-slate-200 border border-[#333333]'
                    : 'bg-white text-blue-700 border border-blue-100'
              }`}
            >
              {copyState === 'copied' ? (
                <>
                  <CheckIcon className="w-3.5 h-3.5 animate-scale-check" />
                  <span>Copied!</span>
                </>
              ) : copyState === 'copying' ? (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5 animate-pulse" />
                  <span>Copying...</span>
                </>
              ) : (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5" />
                  <span>Copy All Ideas</span>
                </>
              )}
            </button>
            
            <button
              onClick={downloadPDF}
              disabled={downloadingPDF}
              className={`flex-1 flex items-center justify-center gap-1.5 px-3 py-1.5 rounded-md text-[11px] transition-colors ${
                downloadingPDF
                  ? 'opacity-50 cursor-not-allowed'
                  : isDarkMode
                    ? 'bg-[#1a1a1a] text-slate-200 border border-[#333333]'
                    : 'bg-white text-blue-700 border border-blue-100'
              }`}
            >
              <ArrowDownTrayIcon className={`w-3.5 h-3.5 ${downloadingPDF ? 'animate-pulse' : ''}`} />
              <span>{downloadingPDF ? 'Downloading...' : 'Download PDF'}</span>
            </button>
          </div>
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden sm:block px-4 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1.5">
            <span className={`text-sm ${
              isDarkMode ? 'text-blue-400' : 'text-blue-500'
            }`}>
              <span className={`font-medium ${
                isDarkMode ? 'text-blue-100' : 'text-blue-900'
              }`}>{totalIdeas} ideas</span>
              {selectedPersona?.name && (
                <>
                  <span> generated for </span>
                  <span className={`font-medium ${
                    isDarkMode ? 'text-blue-100' : 'text-blue-900'
                  }`}>{selectedPersona.name}</span>
                </>
              )}
            </span>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={copyAllIdeas}
              disabled={copyState !== 'idle'}
              className={`flex items-center gap-1.5 px-2.5 py-1 rounded-md text-xs transition-all duration-200 ${
                copyState === 'copied'
                  ? isDarkMode
                    ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30'
                    : 'bg-green-50 text-green-600 hover:bg-green-100'
                  : isDarkMode
                    ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]'
                    : 'hover:bg-blue-100/80 text-blue-700'
              }`}
            >
              {copyState === 'copied' ? (
                <>
                  <CheckIcon className="w-3.5 h-3.5 animate-scale-check" />
                  <span>Copied!</span>
                </>
              ) : copyState === 'copying' ? (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5 animate-pulse" />
                  <span>Copying...</span>
                </>
              ) : (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5" />
                  <span>Copy All</span>
                </>
              )}
            </button>
            
            <button
              onClick={downloadPDF}
              disabled={downloadingPDF}
              className={`flex items-center gap-1.5 px-2.5 py-1 rounded-md text-xs transition-colors ${
                downloadingPDF
                  ? 'opacity-50 cursor-not-allowed'
                  : isDarkMode
                    ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]'
                    : 'hover:bg-blue-100/80 text-blue-700'
              }`}
            >
              <ArrowDownTrayIcon className={`w-3.5 h-3.5 ${downloadingPDF ? 'animate-pulse' : ''}`} />
              <span>{downloadingPDF ? 'Downloading...' : 'PDF'}</span>
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
});

// Add this CSS at the top of your file or in your global styles
const styles = `
  @keyframes scale-check {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }

  .animate-scale-check {
    animation: scale-check 0.3s ease-in-out;
  }
`;

// Add the styles to the document
if (!document.getElementById('generated-ideas-styles')) {
  const styleSheet = document.createElement('style');
  styleSheet.id = 'generated-ideas-styles';
  styleSheet.textContent = styles;
  document.head.appendChild(styleSheet);
}

const TopicSection = memo(({ topic, ideas, isDarkMode }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  
  return (
    <motion.div
      variants={ideaGroupVariants}
      initial="initial"
      animate="animate"
      className={`rounded-xl ring-1 ${
        isDarkMode 
          ? 'bg-[#151515] ring-[#333333]' 
          : 'bg-white/50 ring-slate-200/50'
      }`}
    >
      {/* Topic Header */}
      <div className={`px-4 py-3 sm:px-6 sm:py-4 border-b ${
        isDarkMode ? 'border-slate-800/60' : 'border-slate-200/70'
      }`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className={`flex items-center justify-center w-8 sm:w-10 h-8 sm:h-10 rounded-lg ${
              isDarkMode 
                ? 'bg-slate-800/50 text-slate-300' 
                : 'bg-slate-100/80 text-slate-600'
            }`}>
              <SparklesIcon className="w-4 sm:w-5 h-4 sm:h-5" />
            </div>
            <div>
              <h3 className={`text-sm sm:text-base font-medium ${
                isDarkMode ? 'text-slate-200' : 'text-slate-700'
              }`}>
                {topic}
              </h3>
              <p className={`text-xs mt-0.5 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`}>
                {Array.isArray(ideas) ? ideas.length : 0} unique angles
              </p>
            </div>
          </div>
          
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`p-1.5 sm:p-2 rounded-lg transition-colors ${
              isDarkMode 
                ? 'hover:bg-slate-800/40 text-slate-400' 
                : 'hover:bg-slate-100/60 text-slate-500'
            }`}
          >
            {isExpanded ? (
              <MinusSmallIcon className="w-4 sm:w-5 h-4 sm:h-5" />
            ) : (
              <PlusSmallIcon className="w-4 sm:w-5 h-4 sm:h-5" />
            )}
          </button>
        </div>
      </div>

      {/* Ideas Content */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div 
            {...expandAnimation} 
            className={`divide-y ${
              isDarkMode ? 'divide-slate-800/40' : 'divide-slate-200/60'
            }`}
          >
            {Array.isArray(ideas) && ideas.map((idea, index) => (
              <IdeaCard
                key={index}
                idea={idea}
                topic={topic}
                isDarkMode={isDarkMode}
                index={index}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});

// Main Component
const GeneratedIdeas = memo(({ 
  ideas, 
  loading, 
  selectedPersona,
  selectedAudience
}) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [hasSaved, setHasSaved] = useState(false);
  const saveInProgress = useRef(false);

  useEffect(() => {
    if (!loading && ideas) {
      if (!ideas.data?.topics || !ideas.data?.generatedIdeas) {
        console.error('Invalid ideas data structure:', ideas);
        toast.error('Failed to load content ideas');
        return;
      }

      contentStrategyProgressService.updateProgress('generatedIdeas', { data: ideas });
      toast.success('Content strategy generated successfully!');
    }
  }, [loading, ideas, navigate]);

  if (loading) {
    return <LoadingSkeleton isDarkMode={isDarkMode} selectedPersona={selectedPersona} />;
  }

  if (!ideas?.data?.topics?.length || !ideas?.data?.generatedIdeas?.length) {
    return (
      <div className="text-center py-12">
        <p className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>
          No content generated. Please try again.
        </p>
      </div>
    );
  }

  return (
    <div className="relative min-h-screen pb-24">
      <motion.div
        variants={pageVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className="w-full max-w-4xl mx-auto space-y-6 p-4"
      >
        <ActionBar 
          ideas={ideas}
          isDarkMode={isDarkMode}
          selectedPersona={selectedPersona}
        />
        
        {ideas.data.generatedIdeas.map((topicIdeas, index) => (
          <TopicSection
            key={index}
            topic={topicIdeas.topic}
            ideas={topicIdeas.ideas}
            isDarkMode={isDarkMode}
          />
        ))}
      </motion.div>
    </div>
  );
});

GeneratedIdeas.propTypes = {
  ideas: PropTypes.object,
  loading: PropTypes.bool,
  selectedPersona: PropTypes.object,
  selectedAudience: PropTypes.object
};

export default GeneratedIdeas; 