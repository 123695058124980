import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Route, Routes, Outlet } from 'react-router-dom'; // Add Outlet
import { UserPlusIcon, PencilIcon, TrashIcon, CpuChipIcon, MagnifyingGlassIcon, XMarkIcon, EllipsisHorizontalIcon, EllipsisVerticalIcon, ArrowPathIcon, DocumentTextIcon, SparklesIcon, ChatBubbleLeftIcon, EyeIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import api from '../../api';
import AIAgentCreationWizard from './AIAgentCreationWizard';
import ReactDOM from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

// Add this new component at the top of the file, outside of the main AIAgentsPage component
const NoResultsMessage = ({ searchTerm, onCreateClick, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150 w-full max-w-md mx-auto
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <CpuChipIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center w-full">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first AI Agent'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new Agent' 
          : 'Get started by creating a new AI agent'}
      </motion.p>
      
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onCreateClick}
        className={`
          relative group inline-flex items-center gap-2 
          px-4 py-2 rounded-lg text-sm font-medium
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
            : 'bg-slate-900 hover:bg-slate-800 text-white'}
        `}
      >
        <UserPlusIcon className="w-4 h-4" />
        <span>New AI Agent</span>
      </motion.button>
    </div>
  </motion.div>
);

// Update the EmptyState component
const EmptyState = ({ isDarkMode, onCreateClick, searchTerm = '' }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150 w-full max-w-md mx-auto
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <CpuChipIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center w-full">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first AI agent'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new agent' 
          : 'Get started by creating a new AI agent'}
      </motion.p>
      
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onCreateClick}
        className={`
          relative group inline-flex items-center gap-2 
          px-4 py-2 rounded-lg text-sm font-medium
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
            : 'bg-slate-900 hover:bg-slate-800 text-white'}
        `}
      >
        <CpuChipIcon className="w-4 h-4" />
        <span>New AI Agent</span>
      </motion.button>
    </div>
  </motion.div>
);

// Add this new component at the top, after NoResultsMessage
const DropdownMenu = ({ isDarkMode, onEdit, onDelete, position, menuRef }) => {
  if (!position) return null;

  return ReactDOM.createPortal(
    <div 
      ref={menuRef}
      style={{
        position: 'fixed',
        top: `${position.top}px`,
        left: `${position.left}px`,
        zIndex: 9999,
      }}
      className={`min-w-[8rem] overflow-hidden rounded-md border p-1 shadow-md
                 ${isDarkMode 
                   ? 'bg-[#121212] border-[#333333]' 
                   : 'bg-white border-slate-200'}`}
    >
      <button
        onClick={onEdit}
        className={`flex w-full items-center gap-2 rounded-sm px-3 py-2 text-sm transition-colors
                   ${isDarkMode 
                     ? 'hover:bg-[#1a1a1a] text-slate-400' 
                     : 'hover:bg-slate-100 text-slate-600'}`}
      >
        <PencilIcon className="h-4 w-4" />
        Edit
      </button>
      <button
        onClick={onDelete}
        className={`flex w-full items-center gap-2 rounded-sm px-3 py-2 text-sm transition-colors
                   text-red-500 hover:text-red-600
                   ${isDarkMode ? 'hover:bg-[#1a1a1a]' : 'hover:bg-slate-100'}`}
      >
        <TrashIcon className="h-4 w-4" />
        Delete
      </button>
    </div>,
    document.body
  );
};

// Update the AIAgentCard component
const AIAgentCard = ({ agent, isPopup, onAgentClick, onEdit, onDelete, isDarkMode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState(null);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && 
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMoreOptions = (e) => {
    e.stopPropagation();
    const buttonRect = buttonRef.current.getBoundingClientRect();
    setMenuPosition({
      top: buttonRect.bottom + window.scrollY + 4,
      left: buttonRect.right - 160,
    });
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(agent._id);
    setIsMenuOpen(false);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(agent._id);
    setIsMenuOpen(false);
  };

  const handleCardClick = () => {
    onAgentClick(agent);
  };

  // Helper function to truncate text
  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? `${text.substring(0, length)}...` : text;
  };

  // Helper function to get the first training example
  const getFirstExample = () => {
    if (agent.trainingExamples && agent.trainingExamples.length > 0) {
      return {
        input: truncateText(agent.trainingExamples[0].input, 40),
        output: truncateText(agent.trainingExamples[0].output, 40)
      };
    }
    return null;
  };

  const firstExample = getFirstExample();

  return (
    <div 
      className={`group relative overflow-hidden rounded-lg border transition-all duration-200
                h-[280px] flex flex-col
                ${isDarkMode 
                  ? 'bg-[#151515] hover:bg-[#1a1a1a] border-[#333333]' 
                  : 'bg-white hover:bg-slate-50 border-slate-200'}`}
    >
      {/* Header Section - Fixed Height */}
      <div className="p-4 flex-shrink-0">
        <div className="flex items-start justify-between">
          <div className="flex items-center gap-3">
            <div className={`w-10 h-10 rounded-lg overflow-hidden flex items-center justify-center flex-shrink-0
                          ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-100'}`}>
              <CpuChipIcon className={`w-6 h-6 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} />
            </div>
            <div className="min-w-0">
              <h3 className={`text-sm font-medium truncate
                          ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}`}>
                {agent.name}
              </h3>
              <div className="flex items-center gap-1.5 mt-0.5 flex-wrap">
                {agent.categories && agent.categories.slice(0, 2).map((category, index) => (
                  <span 
                    key={index}
                    className={`text-[10px] px-1.5 py-0.5 rounded-full truncate max-w-[100px]
                            ${isDarkMode 
                              ? 'bg-[#252525] text-slate-400' 
                              : 'bg-slate-100 text-slate-600'}`}
                  >
                    {category}
                  </span>
                ))}
                {agent.categories?.length > 2 && (
                  <span className={`text-[10px] ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
                    +{agent.categories.length - 2}
                  </span>
                )}
              </div>
            </div>
          </div>

          {!isPopup && (
            <div className="relative flex-shrink-0">
              <button
                ref={buttonRef}
                onClick={handleMoreOptions}
                className={`rounded-md p-1.5 transition-colors
                         ${isDarkMode 
                           ? 'hover:bg-[#252525] text-slate-400' 
                           : 'hover:bg-slate-100 text-slate-500'}`}
              >
                <EllipsisHorizontalIcon className="h-4 w-4" />
              </button>

              <AnimatePresence>
                {isMenuOpen && (
                  <DropdownMenu
                    isDarkMode={isDarkMode}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    position={menuPosition}
                    menuRef={menuRef}
                  />
                )}
              </AnimatePresence>
            </div>
          )}
        </div>
      </div>

      {/* Content Section - Scrollable */}
      <div className="flex-1 min-h-0 overflow-hidden">
        <div className={`h-full p-4 pt-3 pb-14 space-y-4 border-t ${isDarkMode ? 'border-[#252525]' : 'border-slate-100'}`}>
          {/* Description */}
          <div className="space-y-1">
            <div className={`flex items-center gap-1.5 text-xs font-medium
                          ${isDarkMode ? 'text-slate-500' : 'text-slate-500'}`}>
              <DocumentTextIcon className="h-3.5 w-3.5 flex-shrink-0" />
              <span>Description</span>
            </div>
            <p className={`text-xs line-clamp-2 pl-5
                        ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>
              {agent.description || 'No description provided'}
            </p>
          </div>

          {/* System Instructions */}
          <div className="space-y-1">
            <div className={`flex items-center gap-1.5 text-xs font-medium
                          ${isDarkMode ? 'text-slate-500' : 'text-slate-500'}`}>
              <SparklesIcon className="h-3.5 w-3.5 flex-shrink-0" />
              <span>System Instructions</span>
            </div>
            <p className={`text-xs line-clamp-2 pl-5
                        ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>
              {truncateText(agent.systemInstructions, 100)}
            </p>
          </div>

          {/* Training Example */}
          {firstExample && (
            <div className="space-y-1">
              <div className={`flex items-center gap-1.5 text-xs font-medium
                            ${isDarkMode ? 'text-slate-500' : 'text-slate-500'}`}>
                <ChatBubbleLeftIcon className="h-3.5 w-3.5 flex-shrink-0" />
                <span>Example Interaction</span>
              </div>
              <div className={`pl-5 space-y-1.5 text-xs
                            ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>
                <p className="line-clamp-1">
                  <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>User: </span>
                  {firstExample.input}
                </p>
                <p className="line-clamp-1">
                  <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>AI: </span>
                  {firstExample.output}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Edit Button - Fixed at Bottom */}
      <div className={`absolute bottom-0 left-0 right-0 px-4 py-3 border-t 
                    ${isDarkMode 
                      ? 'bg-[#151515] border-[#252525]' 
                      : 'bg-white border-slate-100'}`}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onEdit(agent._id);
          }}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-md text-xs
                   transition-colors duration-200 w-[fit-content]
                   ${isDarkMode 
                     ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-300' 
                     : 'bg-slate-100 hover:bg-slate-200 text-slate-700'}`}
        >
          <PencilIcon className="h-3.5 w-3.5" />
          Edit Agent
        </button>
      </div>
    </div>
  );
};

// Add these new components after NoResultsMessage

const MobileSearchBar = ({ isDarkMode, searchTerm, onSearchChange, onClose }) => (
  <div className={`
    fixed inset-x-0 top-0 z-50 p-2
    ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
    border-b ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
  `}>
    <div className="relative flex items-center">
      <MagnifyingGlassIcon className={`absolute left-3 h-4 w-4 ${
        isDarkMode ? 'text-slate-400' : 'text-gray-400'
      }`} />
      <input
        type="text"
        className={`w-full pl-9 pr-8 py-2 text-sm rounded-lg
          focus:outline-none focus:ring-2
          ${isDarkMode
            ? 'bg-[#1a1a1a] text-slate-200 placeholder-slate-400 focus:ring-blue-500/30'
            : 'bg-gray-100 text-gray-900 placeholder-gray-500 focus:ring-blue-500/50'
          }
        `}
        placeholder="Search AI agents..."
        value={searchTerm}
        onChange={onSearchChange}
        autoFocus
      />
      <button
        onClick={onClose}
        className={`absolute right-3 p-0.5 rounded-full ${
          isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-200'
        }`}
      >
        <XMarkIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
      </button>
    </div>
  </div>
);

const MobileMenu = ({ isDarkMode, onCreateNew }) => (
  <div className={`
    absolute right-2 top-12 z-50 mt-1 w-48 rounded-lg shadow-lg
    ${isDarkMode ? 'bg-[#1a1a1a] border border-[#333333]' : 'bg-white border border-gray-200'}
    py-1
  `}>
    <button
      onClick={onCreateNew}
      className={`w-full px-4 py-2 text-sm flex items-center gap-2
        ${isDarkMode 
          ? 'text-slate-200 hover:bg-[#252525]' 
          : 'text-gray-700 hover:bg-gray-100'}
      `}
    >
      <UserPlusIcon className="w-4 h-4" />
      Create AI Agent
    </button>
  </div>
);

// Update the AIAgentsPage component to add mobile states and handlers
const AIAgentsPage = ({ isPopup = false, onSelectAgent }) => {
  const [agents, setAgents] = useState([]);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [showCreationWizard, setShowCreationWizard] = useState(false); // Add this state
  const [avatars, setAvatars] = useState({});
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isDeletingAgent, setIsDeletingAgent] = useState(false);

  // Add resize handler
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchAgents = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/ai-agents');
      const agentsWithAvatars = await Promise.all(response.data.map(async (agent) => {
        if (agent.avatar) {
          try {
            const avatarResponse = await api.get(`/api/ai-agents/${agent._id}/avatar`);
            return { ...agent, avatarUrl: avatarResponse.data.avatarUrl };
          } catch (error) {
            console.error('Error fetching avatar URL:', error);
            return agent;
          }
        }
        return agent;
      }));
      setAgents(agentsWithAvatars);
    } catch (error) {
      console.error('Error fetching AI agents:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isInitialFetchDone) {
      fetchAgents().then(() => setIsInitialFetchDone(true));
    }
  }, [fetchAgents, isInitialFetchDone]);

  const handleEdit = (agentId) => {
    navigate(`edit/${agentId}`);
  };

  const handleDeleteClick = (agentId) => {
    setAgentToDelete(agentId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (agentToDelete) {
      setIsDeletingAgent(true);
      try {
        await api.delete(`/api/ai-agents/${agentToDelete}`);
        // First close the modal
        setShowDeleteModal(false);
        setAgentToDelete(null);
        // Then update the agents list
        setAgents(prevAgents => prevAgents.filter(agent => agent._id !== agentToDelete));
        setFilteredAgents(prevFiltered => prevFiltered.filter(agent => agent._id !== agentToDelete));
      } catch (error) {
        console.error('Error deleting AI agent:', error);
        alert('Failed to delete AI agent. Please try again.');
      } finally {
        setIsDeletingAgent(false);
      }
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setAgentToDelete(null);
  };

  const handleAgentClick = (agent) => {
    if (isPopup && onSelectAgent) {
      onSelectAgent(agent);
    }
  };

  // Add these new state variables at the top of your component
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // Add these new functions
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen) {
      setSearchTerm('');
    }
  };

  // Add this useEffect to filter agents based on search term
  useEffect(() => {
    if (agents.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = agents.filter(agent =>
        agent.name.toLowerCase().includes(lowercasedFilter) ||
        agent.description.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredAgents(filtered);
    }
  }, [searchTerm, agents]);

  // Update the handleCreateAgent function to work with the new component
  const handleCreateAgent = () => {
    if (isPopup) {
      // Handle creation in popup mode (if needed)
    } else {
      navigate('create');
    }
  };

  const handleSaveAgent = async (agentData) => {
    try {
      await fetchAgents(); // Refresh the list
    } catch (error) {
      console.error('Error refreshing agents list:', error);
    }
  };

  return (
    <Routes>
      <Route path="/" element={
        <>
          <Helmet>
            <title>AI Agents | Ammmplify</title>
            <meta name="description" content="Create and manage your AI agents for automated content generation. Build custom AI agents to streamline your content creation workflow." />
            <meta name="keywords" content="AI agents, content automation, AI agents, content generation, workflow automation" />
            <meta property="og:title" content="AI Agents | Ammmplify" />
            <meta property="og:description" content="Create and manage your AI agents for automated content generation. Build custom AI agents to streamline your content creation workflow." />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="AI Agents | Ammmplify" />
            <meta name="twitter:description" content="Create and manage your AI agents for automated content generation. Build custom AI agents to streamline your content creation workflow." />
          </Helmet>

          <div className={`flex flex-col h-full relative overflow-hidden bg-transparent
                        min-w-[300px] w-full max-w-full
                        mx-auto transition-all duration-300 ease-in-out`}>
            {/* Mobile Search Bar */}
            {isSearchOpen && isMobile && (
              <MobileSearchBar
                isDarkMode={isDarkMode}
                searchTerm={searchTerm}
                onSearchChange={handleSearchChange}
                onClose={toggleSearch}
              />
            )}

            {/* Top Bar - Desktop and Mobile */}
            <div className={`${isDarkMode ? 'bg-[#121212]/95 border-[#333333]' : 'bg-white bg-opacity-90 border-gray-200'} 
                            !h-10 min-h-10 flex-shrink-0 flex items-center justify-between 
                            px-3 sm:px-4 py-2 w-full border-b relative z-40`}>
              {/* Left section */}
              <div className="flex items-center">
                <CpuChipIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
                <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                  Brand Agents
                </h2>
              </div>

              {/* Right section - Desktop */}
              <div className="hidden sm:flex items-center space-x-2">
                {isSearchOpen ? (
                  <div className="relative">
                    <input
                      type="text"
                      className={`w-48 px-3 py-1 text-sm border rounded-md pl-8 
                        focus:outline-none focus:ring-2 focus:ring-blue-500
                        ${isDarkMode
                          ? 'bg-[#151515] border-[#333333] text-slate-200 placeholder-slate-400'
                          : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                        }`}
                      placeholder="Search AI agents..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <MagnifyingGlassIcon className={`absolute left-2 top-1.5 h-4 w-4 ${
                      isDarkMode ? 'text-slate-400' : 'text-gray-400'
                    }`} />
                    <button
                      onClick={toggleSearch}
                      className={`absolute right-2 top-1.5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`}
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={toggleSearch}
                    className={`p-1 rounded-md ${
                      isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
                    }`}
                  >
                    <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                  </button>
                )}

                <button 
                  onClick={handleCreateAgent}
                  className={`flex items-center px-3 py-2 rounded-full transition-colors duration-200 text-xs
                              ${isDarkMode 
                                ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200' 
                                : 'bg-black hover:bg-black/90 text-white'}`}
                  title="Create Brand Agent"
                >
                  <UserPlusIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-white'} mr-1`} />
                  New Agent
                </button>
              </div>

              {/* Right section - Mobile */}
              <div className="flex sm:hidden items-center space-x-2">
                <button
                  onClick={toggleSearch}
                  className={`p-1.5 rounded-md ${
                    isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-100'
                  }`}
                >
                  <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                </button>
                
                <button
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                  className={`p-1.5 rounded-md ${
                    isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-100'
                  }`}
                >
                  <EllipsisVerticalIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                </button>

                {showMobileMenu && (
                  <MobileMenu
                    isDarkMode={isDarkMode}
                    onCreateNew={() => {
                      setShowMobileMenu(false);
                      navigate('create');
                    }}
                  />
                )}
              </div>
            </div>

            {/* Content Area */}
            <div className={`flex-grow overflow-y-auto custom-scrollbar ${isDarkMode ? 'bg-[#121212] bg-opacity-80' : 'bg-white bg-opacity-80'}`}>
              <div className="max-w-3xl mx-auto px-4 pb-20 h-full">
                {isLoading || (isDeletingAgent && filteredAgents.length === 1) ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
                    {[...Array(4)].map((_, index) => (
                      <div 
                        key={index}
                        className={`h-[72px] rounded-lg animate-pulse
                                  ${isDarkMode ? 'bg-[#151515]' : 'bg-gray-100'}`}
                      />
                    ))}
                  </div>
                ) : filteredAgents.length > 0 ? (
                  <div className={`grid grid-cols-1 md:grid-cols-${filteredAgents.length === 1 ? '1' : '2'} gap-4 pt-4 ${
                    filteredAgents.length === 1 ? 'max-w-[400px]' : ''
                  } mx-auto`}>
                    <AnimatePresence mode="wait">
                      {filteredAgents.map((agent) => (
                        <motion.div
                          key={agent._id}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                        >
                          <AIAgentCard
                            agent={agent}
                            isPopup={isPopup}
                            onAgentClick={handleAgentClick}
                            onEdit={handleEdit}
                            onDelete={handleDeleteClick}
                            isDarkMode={isDarkMode}
                          />
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </div>
                ) : (
                  <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    className="h-[calc(100vh-10rem)] flex items-center justify-center px-4"
                  >
                    {searchTerm ? (
                      <NoResultsMessage 
                        searchTerm={searchTerm}
                        onCreateClick={handleCreateAgent}
                        isDarkMode={isDarkMode}
                      />
                    ) : (
                      <EmptyState 
                        isDarkMode={isDarkMode}
                        onCreateClick={handleCreateAgent}
                        searchTerm={searchTerm}
                      />
                    )}
                  </motion.div>
                )}
              </div>
            </div>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
              <div className={`fixed inset-0 flex items-center justify-center p-4 z-50 ${
                isDarkMode ? 'bg-black/30 backdrop-blur-sm' : 'bg-white/30 backdrop-blur-sm'
              }`}>
                <div className={`relative w-full max-w-sm rounded-lg border transition-all
                                ${isDarkMode 
                                  ? 'bg-[#121212] border-[#333333]' 
                                  : 'bg-white border-zinc-200'}`}>
                  <div className="p-6">
                    <h3 className={`text-lg font-semibold leading-none tracking-tight mb-2
                                  ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}`}>
                      Delete AI Agent
                    </h3>
                    <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
                      Are you sure? This action cannot be undone.
                    </p>
                    
                    <div className="flex justify-end gap-3 mt-6">
                      <button
                        onClick={cancelDelete}
                        disabled={isDeletingAgent}
                        className={`px-4 py-2 rounded-md text-sm font-medium
                                  transition-colors
                                  ${isDarkMode 
                                    ? 'bg-[#1a1a1a] hover:bg-[#222] text-slate-300 border border-[#333333]' 
                                    : 'bg-white hover:bg-zinc-50 text-slate-900 border border-zinc-200'}
                                  ${isDeletingAgent ? 'opacity-50 cursor-not-allowed' : ''}`}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={confirmDelete}
                        disabled={isDeletingAgent}
                        className={`px-4 py-2 rounded-md text-sm font-medium text-white
                                  transition-colors
                                  bg-red-600 hover:bg-red-700
                                  ${isDeletingAgent ? 'opacity-50 cursor-not-allowed' : ''}`}
                      >
                        {isDeletingAgent ? (
                          <div className="flex items-center gap-2">
                            <ArrowPathIcon className="w-4 h-4 animate-spin" />
                            Deleting...
                          </div>
                        ) : (
                          'Delete'
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      } />
      <Route path="create" element={<AIAgentCreationWizard onSave={handleSaveAgent} />} />
      <Route path="edit/:agentId" element={<AIAgentCreationWizard onSave={handleSaveAgent} />} />
    </Routes>
  );
};

export default AIAgentsPage;