import React, { useState, useRef, useCallback } from 'react';
import { useTheme } from '../context/ThemeContext';

/**
 * Custom SVG icon component for the resize handle
 * Renders a vertical dots pattern to indicate draggable area
 * @param {Object} props - Component props
 * @param {string} props.className - CSS classes for styling
 */
const ResizeIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24" 
    className={className}
  >
    <path 
      fill="currentColor" 
      d="M9 3h2v2H9zm4 0h2v2h-2zM9 7h2v2H9zm4 0h2v2h-2zm-4 4h2v2H9zm4 0h2v2h-2zm-4 4h2v2H9zm4 0h2v2h-2zm-4 4h2v2H9zm4 0h2v2h-2z"    />
  </svg>
);

/**
 * ResizableHandle Component
 * Provides a draggable handle for resizing the chat interface width
 * Supports both mouse and touch input
 * 
 * @param {Object} props - Component props
 * @param {Function} props.onResize - Callback function that receives the new width value
 */
export const ResizableHandle = ({ onResize, onDoubleClick }) => {
  const { isDarkMode } = useTheme();
  const [isResizing, setIsResizing] = useState(false);
  
  const frameRef = useRef();
  const initialWidthRef = useRef();
  const startXRef = useRef();
  const touchIdRef = useRef();

  /**
   * Common resize logic used by both mouse and touch handlers
   * @param {number} clientX - Current X position
   */
  const handleResize = useCallback((clientX) => {
    if (frameRef.current) {
      cancelAnimationFrame(frameRef.current);
    }

    frameRef.current = requestAnimationFrame(() => {
      const deltaX = clientX - startXRef.current;
      const canvasMinWidth = 300; // Match Canvas.js minimum width
      const chatMinWidth = 450;
      const maxChatWidth = Math.min(
        window.innerWidth - canvasMinWidth, // Don't let chat squeeze Canvas below its min width
        window.innerWidth * 0.4 // Or exceed 80% of window width
      );
      
      const newWidth = Math.min(
        Math.max(chatMinWidth, initialWidthRef.current + deltaX),
        maxChatWidth
      );
      onResize(newWidth);
    });
  }, [onResize]);

  /**
   * Cleanup function used by both mouse and touch handlers
   */
  const cleanup = useCallback(() => {
    setIsResizing(false);
    document.body.style.userSelect = '';
    document.body.style.cursor = '';
    touchIdRef.current = null;

    if (frameRef.current) {
      cancelAnimationFrame(frameRef.current);
    }
  }, []);

  /**
   * Mouse event handlers
   */
  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsResizing(true);
    
    startXRef.current = e.clientX;
    initialWidthRef.current = document.querySelector('.chat-interface')?.offsetWidth || 450;
    
    document.body.style.userSelect = 'none';
    document.body.style.cursor = 'ew-resize';
    
    const handleMouseMove = (moveEvent) => {
      handleResize(moveEvent.clientX);
    };

    const handleMouseUp = () => {
      cleanup();
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [handleResize, cleanup]);

  /**
   * Touch event handlers
   */
  const handleTouchStart = useCallback((e) => {
    e.preventDefault();
    
    // Store the touch identifier to track the correct touch point
    const touch = e.touches[0];
    touchIdRef.current = touch.identifier;
    
    setIsResizing(true);
    startXRef.current = touch.clientX;
    initialWidthRef.current = document.querySelector('.chat-interface')?.offsetWidth || 450;
    
    document.body.style.userSelect = 'none';
  }, []);

  const handleTouchMove = useCallback((e) => {
    e.preventDefault();
    
    // Find the touch point we're tracking
    const touch = Array.from(e.touches).find(
      t => t.identifier === touchIdRef.current
    );
    
    if (touch) {
      handleResize(touch.clientX);
    }
  }, [handleResize]);

  const handleTouchEnd = useCallback((e) => {
    e.preventDefault();
    cleanup();
  }, [cleanup]);

  return (
    <div 
      className={`
        flex items-center justify-center w-1 h-full cursor-ew-resize
        touch-none relative hover:w-2 transition-all duration-200
        ${isDarkMode ? 'text-slate-400 hover:text-slate-300' : 'text-slate-600 hover:text-slate-800'}
        ${isResizing ? 'text-blue-500 w-2' : ''}
      `}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onDoubleClick={onDoubleClick}
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <ResizeIcon className={`
          w-3 h-3 opacity-50 hover:opacity-100 transition-opacity duration-200
          ${isResizing ? 'opacity-100' : ''}
        `} />
      </div>
    </div>
  );
};
