import React from 'react';
import { useTheme } from '../../context/ThemeContext';
import InitialsAvatar from '../common/InitialsAvatar';

const CompactTargetAudienceCard = ({ audience, onAudienceClick, isSelected }) => {
  const { isDarkMode } = useTheme();

  return (
    <div 
      className={`
        ${isDarkMode 
          ? 'bg-[#1E1E1E] hover:bg-[#2A2A2A] border-[#333333]' 
          : 'bg-white hover:bg-gray-50 border-gray-200'
        } 
        ${isSelected 
          ? isDarkMode 
            ? 'bg-[#2A2A2A]' 
            : 'bg-gray-100'
          : ''
        }
        rounded-md p-2 relative cursor-pointer transition-all duration-200 ease-in-out
        border mb-[5px]
      `}
      onClick={() => onAudienceClick(audience)}
    >
      <div className="flex items-center">
        {/* Avatar */}
        <div className="relative mr-3 flex-shrink-0">
          <InitialsAvatar 
            name={audience.targetAudienceName}
            size="small"
          />
        </div>

        {/* Name Column */}
        <div className="flex-1 min-w-0">
          <h2 className={`text-xs font-medium ${
            isDarkMode ? 'text-gray-200' : 'text-gray-800'
          } truncate`}>
            {audience.targetAudienceName}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default CompactTargetAudienceCard;
