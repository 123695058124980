import React, { useState, useEffect, useCallback, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useTheme } from '../context/ThemeContext'
import {
  MagnifyingGlassIcon,
  UserCircleIcon,
  UsersIcon,
  DocumentIcon,
  MicrophoneIcon,
  DocumentTextIcon,
  SparklesIcon,
  CommandLineIcon
} from '@heroicons/react/24/outline'

const TypingAnimation = ({ 
  selectedPersona, 
  selectedTargetAudience,
  selectedTemplate,
  selectedVoiceNotes,
  selectedTextNotes,
  selectedAgent,
  onComplete
}) => {
  const { isDarkMode } = useTheme();
  const [messageIndex, setMessageIndex] = useState(0);
  const [isCompleting, setIsCompleting] = useState(false);
  const [progress, setProgress] = useState(0);
  const startTimeRef = useRef(Date.now());
  const animationFrameRef = useRef(null);

  // Different durations for different message types
  const PROCESSING_DURATION = 1500;
  const ANALYSIS_DURATION = 1200;
  const GENERATION_DURATION = 600;
  const MINIMUM_TOTAL_DURATION = 2000;
  const PROGRESS_SPEED = 8;

  const loadingMessages = [
    { 
      text: "Analysing your message...", 
      duration: ANALYSIS_DURATION,
      icon: MagnifyingGlassIcon,
      iconAnimation: {
        scale: [1, 1.2, 1],
        rotate: [0, 15, -15, 0]
      }
    },
    selectedPersona && { 
      text: "Considering profile info...", 
      duration: PROCESSING_DURATION,
      icon: UserCircleIcon,
      iconAnimation: {
        scale: [1, 1.1, 1],
        y: [0, -2, 0]
      }
    },
    selectedTargetAudience && { 
      text: "Adapting for target audience...", 
      duration: PROCESSING_DURATION,
      icon: UsersIcon,
      iconAnimation: {
        scale: [1, 1.1, 1],
        x: [0, 2, 0]
      }
    },
    selectedTemplate && { 
      text: "Applying template structure...", 
      duration: PROCESSING_DURATION,
      icon: DocumentIcon,
      iconAnimation: {
        scale: [1, 1.1, 1],
        rotate: [0, 5, -5, 0]
      }
    },
    selectedVoiceNotes?.length > 0 && { 
      text: "Processing voice notes...", 
      duration: PROCESSING_DURATION,
      icon: MicrophoneIcon,
      iconAnimation: {
        scale: [1, 1.2, 1],
        opacity: [1, 0.6, 1]
      }
    },
    selectedTextNotes?.length > 0 && { 
      text: "Incorporating text notes...", 
      duration: PROCESSING_DURATION,
      icon: DocumentTextIcon,
      iconAnimation: {
        scale: [1, 1.1, 1],
        y: [0, -2, 0]
      }
    },
    selectedAgent && { 
      text: `Engaging ${selectedAgent.name}...`, 
      duration: PROCESSING_DURATION,
      icon: SparklesIcon,
      iconAnimation: {
        scale: [1, 1.2, 1],
        rotate: [0, 180, 360]
      }
    },
    { 
      text: "Generating response...", 
      duration: GENERATION_DURATION,
      icon: CommandLineIcon,
      iconAnimation: {
        scale: [1, 1.1, 1],
        opacity: [1, 0.7, 1]
      }
    }
  ].filter(Boolean); // Filter out falsy values

  // Ensure there's always at least one message
  if (loadingMessages.length === 0) {
    loadingMessages.push({
      text: "Processing...",
      duration: PROCESSING_DURATION,
      icon: CommandLineIcon,
      iconAnimation: {
        scale: [1, 1.1, 1],
        opacity: [1, 0.7, 1]
      }
    });
  }

  const totalDuration = loadingMessages.reduce((acc, msg) => acc + msg.duration, 0);

  const updateProgress = useCallback(() => {
    const now = Date.now();
    const elapsedTime = now - startTimeRef.current;
    let currentProgress;

    if (messageIndex === loadingMessages.length - 1) {
      // For the final "Generating response" step
      const progressUpToGeneration = loadingMessages
        .slice(0, -1)
        .reduce((acc, msg) => acc + msg.duration, 0);
      
      const generationProgress = Math.min(
        (elapsedTime - progressUpToGeneration) * PROGRESS_SPEED / 1000,
        loadingMessages[messageIndex].duration
      );

      currentProgress = ((progressUpToGeneration + generationProgress) / totalDuration) * 100;
    } else {
      currentProgress = (elapsedTime / totalDuration) * 100;
    }

    // Ensure progress doesn't exceed the current step
    const maxProgressForCurrentStep = loadingMessages
      .slice(0, messageIndex + 1)
      .reduce((acc, msg) => acc + msg.duration, 0);
    
    currentProgress = Math.min(
      currentProgress,
      (maxProgressForCurrentStep / totalDuration) * 100
    );

    setProgress(Math.min(currentProgress, 99));

    if (!isCompleting) {
      animationFrameRef.current = requestAnimationFrame(updateProgress);
    }
  }, [messageIndex, totalDuration, isCompleting, loadingMessages]);

  useEffect(() => {
    animationFrameRef.current = requestAnimationFrame(updateProgress);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [updateProgress]);

  useEffect(() => {
    let timeoutId;

    const showNextMessage = () => {
      if (messageIndex < loadingMessages.length - 1) {
        setMessageIndex(prev => prev + 1);
        timeoutId = setTimeout(showNextMessage, loadingMessages[messageIndex].duration);
      } else {
        const remainingTime = Math.max(
          MINIMUM_TOTAL_DURATION - (Date.now() - startTimeRef.current),
          0
        );
        timeoutId = setTimeout(completeAnimation, Math.max(remainingTime, GENERATION_DURATION));
      }
    };

    timeoutId = setTimeout(showNextMessage, loadingMessages[messageIndex].duration);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [messageIndex, loadingMessages.length]);

  const completeAnimation = useCallback(() => {
    if (!isCompleting) {
      setIsCompleting(true);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      setProgress(100);
      setTimeout(() => {
        if (onComplete) {
          onComplete();
        }
      }, 300);
    }
  }, [onComplete, isCompleting]);

  // Dots animation variants
  const dotsContainerVariants = {
    initial: {},
    animate: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const dotVariants = {
    initial: { y: 0 },
    animate: {
      y: [-1.5, 1.5, -1.5],
      transition: {
        repeat: Infinity,
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  // Particle component
  const Particle = ({ index }) => (
    <motion.div
      className="absolute w-0.5 h-0.5 rounded-full"
      style={{
        background: isDarkMode ? 'rgba(96, 165, 250, 0.3)' : 'rgba(37, 99, 235, 0.2)',
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
      }}
      animate={{
        scale: [0, 1, 0],
        opacity: [0, 0.8, 0],
        x: [0, Math.random() * 30 - 15],
        y: [0, Math.random() * 30 - 15],
      }}
      transition={{
        duration: 2,
        delay: index * 0.2,
        repeat: Infinity,
        ease: "easeInOut",
      }}
    />
  );

  // Add null check for icon access
  const currentMessage = loadingMessages[messageIndex] || loadingMessages[0];
  const Icon = currentMessage?.icon;
  const animation = currentMessage?.iconAnimation;

  // Add smooth transition between states
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.3,
        ease: "easeOut"
      }
    },
    exit: { 
      opacity: 0, 
      y: -20,
      margin: 0,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    }
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit" 
      className={`relative w-full max-w-2xl mx-auto p-4 mt-4 mb-[50vh] rounded-lg border backdrop-blur-sm ${
        isDarkMode 
          ? 'bg-[#121212]/80 border-[#333333]/50 text-slate-200 shadow-lg shadow-blue-500/10' 
          : 'bg-white/90 border-gray-200/50 shadow-lg shadow-blue-500/5'
      } transition-all duration-300 overflow-hidden`}
    >
      {/* Progress bar with smoother animation */}
      <motion.div 
        className={`absolute top-0 left-0 h-0.5 ${
          isDarkMode ? 'bg-blue-400/50' : 'bg-blue-600/50'
        }`}
        style={{ 
          width: `${progress}%`,
          transition: 'width 150ms cubic-bezier(0.4, 0, 0.2, 1)'
        }}
      />

      {/* Step indicator */}
      <div className="absolute top-2 right-2 text-xs font-medium opacity-50">
        {messageIndex + 1}/{loadingMessages.length}
      </div>

      {/* Particle effects */}
      {[...Array(4)].map((_, i) => (
        <Particle key={i} index={i} />
      ))}
      
      {/* Gradient background */}
      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-500/5 to-transparent animate-gradient-x" />
      
      <div className="relative flex items-center space-x-3">
        {/* Animated dots */}
        <motion.div
          variants={dotsContainerVariants}
          initial="initial"
          animate="animate"
          className="flex space-x-0.5"
        >
          {[...Array(3)].map((_, i) => (
            <motion.div
              key={i}
              variants={dotVariants}
              className={`h-1.5 w-1.5 rounded-full ${
                isDarkMode 
                  ? 'bg-blue-400/90' 
                  : 'bg-blue-600/90'
              }`}
            />
          ))}
        </motion.div>

        {/* Message animation with animated icon */}
        <AnimatePresence mode="wait">
          <motion.div
            key={messageIndex}
            initial={{ opacity: 0, y: 8 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -8 }}
            transition={{ duration: 0.2 }}
            className="flex items-center space-x-2"
          >
            {Icon && (
              <motion.div
                animate={animation}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <Icon
                  className={`h-4 w-4 ${
                    isDarkMode 
                      ? 'text-blue-400/90' 
                      : 'text-blue-600/90'
                  }`}
                />
              </motion.div>
            )}
            <span className={`text-xs font-medium ${
              isDarkMode 
                ? 'text-slate-300' 
                : 'text-gray-700'
            }`}>
              {currentMessage.text}
            </span>
          </motion.div>
        </AnimatePresence>

        {/* Pulse effect */}
        <motion.div
          className={`absolute right-0 h-4 w-4 rounded-full ${
            isDarkMode 
              ? 'bg-blue-400/20' 
              : 'bg-blue-600/20'
          }`}
          animate={{
            scale: [1, 1.3, 1],
            opacity: [0.2, 0.1, 0.2],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
      </div>
    </motion.div>
  );
};

export default TypingAnimation;