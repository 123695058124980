import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';

const PageSection = ({ 
  children, 
  title, 
  description,
  className = '',
  containerClassName = '',
  titleClassName = '',
  descriptionClassName = ''
}) => {
  const { isDarkMode } = useTheme();

  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className={`relative ${className}`}
    >
      {(title || description) && (
        <div className="relative space-y-1 text-center mb-12">
          {title && (
            <motion.h2 
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className={`
                text-3xl font-bold tracking-tight
                bg-clip-text text-transparent
                bg-gradient-to-r
                ${isDarkMode 
                  ? 'from-white to-slate-400/80' 
                  : 'from-slate-900 to-slate-700'
                }
                ${titleClassName}
              `}
            >
              {title}
            </motion.h2>
          )}
          {description && (
            <motion.p 
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className={`
                text-lg max-w-3xl mx-auto
                ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                ${descriptionClassName}
              `}
            >
              {description}
            </motion.p>
          )}
        </div>
      )}
      <div className={`
        relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8
        ${containerClassName}
      `}>
        {children}
      </div>
    </motion.section>
  );
};

export default PageSection; 