import React from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { createPortal } from 'react-dom';
import AnimatedLoader from './common/AnimatedLoader';

const DeleteConfirmationModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  isDarkMode,
  title = 'Delete Item',
  message = 'Are you sure you want to delete this item? This action cannot be undone.',
  confirmButtonText = 'Delete',
  cancelButtonText = 'Cancel',
  isDeleting = false
}) => {
  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Backdrop with improved wash effect */}
      <div 
        className={`absolute inset-0 backdrop-blur-[2px] ${
          isDarkMode 
            ? 'bg-black/80' 
            : 'bg-white/80'
        }`}
        onClick={isDeleting ? undefined : onClose}
      />
      
      {/* Modal with improved contrast */}
      <div className={`
        relative w-full max-w-sm rounded-lg shadow-lg p-4
        ${isDarkMode 
          ? 'bg-[#151515] border border-[#333333]' 
          : 'bg-white border border-slate-200'
        }
      `}>
        <div className="flex flex-col gap-4">
          <div className="flex items-start gap-3">
            <div className={`p-2 rounded-full ${
              isDarkMode ? 'bg-red-500/10' : 'bg-red-50'
            }`}>
              <TrashIcon className="w-5 h-5 text-red-500" />
            </div>
            <div>
              <h3 className={`text-sm font-medium ${
                isDarkMode ? 'text-slate-200' : 'text-slate-900'
              }`}>
                {title}
              </h3>
              <p className={`mt-1 text-xs ${
                isDarkMode ? 'text-slate-400' : 'text-slate-600'
              }`}>
                {message}
              </p>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <button
              onClick={onClose}
              disabled={isDeleting}
              className={`px-3 py-1.5 text-xs rounded-md transition-colors duration-200 ${
                isDarkMode 
                  ? 'bg-slate-800 text-slate-300 hover:bg-slate-700 disabled:opacity-50 disabled:cursor-not-allowed' 
                  : 'bg-slate-100 text-slate-700 hover:bg-slate-200 disabled:opacity-50 disabled:cursor-not-allowed'
              }`}
            >
              {cancelButtonText}
            </button>
            <button
              onClick={onConfirm}
              disabled={isDeleting}
              className={`px-3 py-1.5 text-xs rounded-md bg-red-500 text-white hover:bg-red-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center min-w-[64px]`}
            >
              {isDeleting ? (
                <AnimatedLoader size={16} color="white" />
              ) : (
                confirmButtonText
              )}
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DeleteConfirmationModal; 