import React from 'react';
import { XMarkIcon, UserIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const FALLBACK_AVATAR = '/default-avatar.png';

const PersonaDetailsPopup = ({ persona, onClose }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/personas/edit/${persona._id}`);
    onClose();
  };

  const fieldGroups = [
    {
      title: 'Basic Identity',
      description: 'Core characteristics of the writing profile.',
      icon: (className) => <UserIcon className={className} />,
      fields: [
        { label: 'Name', value: persona.name },
        { label: 'Professional Role', value: persona.occupation },
        { label: 'Content Goals', value: persona.contentGoals }
      ]
    },
    {
      title: 'Background & Expertise',
      description: 'Knowledge base and perspective.',
      icon: (className) => <UserIcon className={className} />,
      fields: [
        { label: 'Professional Background', value: persona.personalHistory },
        { label: 'Cultural Perspective', value: persona.culturalBackground },
        { label: 'Areas of Expertise', value: persona.interests },
        { label: 'Topics to Avoid', value: persona.dislikes }
      ]
    },
    {
      title: 'Writing Voice',
      description: 'Expression and communication style.',
      icon: (className) => <UserIcon className={className} />,
      fields: [
        { 
          label: 'Writing Style', 
          value: persona.writingStyle === 'custom' 
            ? persona.writingStyleCustom 
            : persona.writingStyle 
        },
        { 
          label: 'Tone of Voice', 
          value: persona.toneOfVoice === 'custom' 
            ? persona.toneOfVoiceCustom 
            : persona.toneOfVoice 
        }
      ]
    }
  ];

  // Add training examples section if they exist
  if (persona.trainingExamples && persona.trainingExamples.length > 0) {
    fieldGroups.push({
      title: 'Training Examples',
      description: 'Example conversations for the profile.',
      icon: (className) => <UserIcon className={className} />,
      fields: persona.trainingExamples.map((example, index) => ({
        label: `Example ${index + 1}`,
        value: example,
        isExample: true
      }))
    });
  }

  const renderValue = (value) => {
    if (!value) return null;
    
    // Handle training examples differently
    if (typeof value === 'object' && 'input' in value && 'output' in value) {
      return (
        <div className="space-y-3">
          <div className={`
            relative p-3 rounded-lg border group transition-all duration-200
            ${isDarkMode 
              ? 'bg-[#1a1a1a]/50 hover:bg-[#1a1a1a] border-[#333333]' 
              : 'bg-slate-50 hover:bg-white border-slate-200'
            }
          `}>
            <div className="space-y-2">
              <p className={`
                text-xs font-medium
                ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
              `}>
                User Input
              </p>
              <p className={`
                text-sm whitespace-pre-wrap break-words leading-relaxed
                ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
              `}>
                {value.input || ''}
              </p>
            </div>
          </div>
          <div className={`
            relative p-3 rounded-lg border group transition-all duration-200
            ${isDarkMode 
              ? 'bg-[#1a1a1a]/50 hover:bg-[#1a1a1a] border-[#333333]' 
              : 'bg-slate-50 hover:bg-white border-slate-200'
            }
          `}>
            <div className="space-y-2">
              <p className={`
                text-xs font-medium
                ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
              `}>
                Profile Response
              </p>
              <p className={`
                text-sm whitespace-pre-wrap break-words leading-relaxed
                ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
              `}>
                {value.output || ''}
              </p>
            </div>
          </div>
        </div>
      );
    }

    // Handle regular text values
    if (Array.isArray(value)) {
      return (
        <div className="flex flex-wrap gap-2">
          {value.map((item, i) => (
            <span 
              key={i} 
              className={`
                px-2.5 py-1 rounded-md text-xs font-medium transition-colors duration-200
                ${isDarkMode 
                  ? 'bg-[#1a1a1a]/50 text-slate-300 hover:bg-[#1a1a1a] border border-[#333333]' 
                  : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
                }
              `}
            >
              {item}
            </span>
          ))}
        </div>
      );
    }
    return (
      <p className={`
        text-sm whitespace-pre-wrap break-words leading-relaxed
        ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
      `}>
        {String(value)}
      </p>
    );
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`fixed inset-0 backdrop-blur-sm flex items-center justify-center z-50 p-4
          ${isDarkMode ? 'bg-black/60' : 'bg-white/60'}`}
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ type: "spring", duration: 0.3 }}
          className={`
            relative w-full max-w-3xl max-h-[85vh] rounded-xl shadow-2xl
            border popup-scrollbar overflow-y-auto
            ${isDarkMode 
              ? 'bg-[#121212] border-[#333333] shadow-black/20' 
              : 'bg-white border-slate-200 shadow-slate-200/20'
            }
          `}
          onClick={e => e.stopPropagation()}
        >
          {/* Close Button - Absolute Position */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClose}
            className={`
              absolute top-3 right-3 z-20
              rounded-lg p-1.5 sm:p-2 flex-shrink-0
              transition-colors duration-200
              ${isDarkMode 
                ? 'text-slate-400 hover:bg-[#1a1a1a]' 
                : 'text-slate-500 hover:bg-slate-100'
              }
            `}
          >
            <XMarkIcon className="w-4 h-4 sm:w-5 sm:h-5" />
          </motion.button>

          {/* Header */}
          <div className={`
            sticky top-0 z-10 flex flex-col sm:flex-row sm:items-center
            p-4 sm:p-6 pr-12 border-b gap-3 sm:gap-4
            backdrop-blur-xl
            ${isDarkMode 
              ? 'bg-[#121212]/95 border-[#333333]' 
              : 'bg-white/95 border-slate-200'
            }
          `}>
            <div className="flex items-center gap-3 min-w-0">
              {/* Icon */}
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className={`
                  relative h-12 w-12 sm:h-14 sm:w-14 flex-shrink-0 rounded-full overflow-hidden 
                  flex items-center justify-center border
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] border-[#333333]' 
                    : 'bg-slate-100 border-slate-200'
                  }
                `}
              >
                <UserIcon className={`h-7 w-7 sm:h-8 sm:w-8 ${isDarkMode ? 'text-slate-400' : 'text-slate-400'}`} />
              </motion.div>
              <div className="min-w-0 flex-1">
                <h2 className={`
                  text-lg sm:text-xl font-semibold tracking-tight truncate
                  ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
                `}>
                  {persona.name}
                </h2>
                <p className={`
                  text-sm font-medium truncate
                  ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
                `}>
                  {persona.occupation}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleEdit}
                className={`
                  px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg text-sm font-medium
                  transition-colors duration-200 flex items-center gap-1.5 sm:gap-2 flex-shrink-0
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#252525] border border-[#333333]' 
                    : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
                  }
                `}
              >
                <PencilSquareIcon className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                <span className="hidden sm:inline">Edit Profile</span>
                <span className="sm:hidden">Edit</span>
              </motion.button>
            </div>
          </div>

          {/* Content */}
          <div className="p-6 space-y-8">
            {fieldGroups.map((group, index) => (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                key={index}
                className="space-y-4"
              >
                <div className="flex items-center gap-3">
                  <div className={`
                    p-2 rounded-lg
                    ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-100'}
                  `}>
                    {group.icon(`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`)}
                  </div>
                  <div>
                    <h3 className={`
                      text-sm font-semibold tracking-tight
                      ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
                    `}>
                      {group.title}
                    </h3>
                    <p className={`
                      text-xs
                      ${isDarkMode ? 'text-slate-500' : 'text-slate-500'}
                    `}>
                      {group.description}
                    </p>
                  </div>
                </div>
                <div className={`
                  grid grid-cols-1 
                  ${group.title !== 'Training Examples' ? 'md:grid-cols-2' : ''} 
                  gap-6 p-4 rounded-lg border
                  ${isDarkMode 
                    ? 'bg-[#151515]/50 border-[#333333]' 
                    : 'bg-slate-50 border-slate-200'
                  }
                `}>
                  {group.fields.map((field, fieldIndex) => (
                    field.value && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: fieldIndex * 0.05 }}
                        key={fieldIndex}
                        className={`
                          space-y-2 
                          ${field.isExample ? 'col-span-full' : ''}
                        `}
                      >
                        <label className={`
                          text-xs font-medium
                          ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
                        `}>
                          {field.label}
                        </label>
                        <div className="min-h-[1.5rem]">
                          {renderValue(field.value)}
                        </div>
                      </motion.div>
                    )
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PersonaDetailsPopup;