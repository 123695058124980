import React, { useState } from 'react';
import { XMarkIcon, DocumentTextIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';

const PersonaAIFillModal = ({ isOpen, onClose, onSubmit }) => {
  const { isDarkMode } = useTheme();
  const [inputType, setInputType] = useState('text');
  const [input, setInput] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    setError('');
    setIsLoading(true);

    try {
      let processedInput = '';

      if (inputType === 'text') {
        processedInput = input;
      } else if (inputType === 'pdf' && file) {
        processedInput = file;
      }

      await onSubmit(inputType, processedInput);
      onClose();
    } catch (err) {
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setFile(selectedFile);
      setError('');
    } else {
      setError('Please select a PDF file');
      setFile(null);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 flex items-center justify-center p-4 z-50
                    ${isDarkMode 
                      ? 'bg-black/80 backdrop-blur-sm' 
                      : 'bg-white/80 backdrop-blur-sm'}`}>
      <div className={`relative w-full max-w-md transform overflow-hidden rounded-lg shadow-lg
                    ${isDarkMode ? 'bg-[#151515]' : 'bg-white'}`}>
        {/* Compact Header */}
        <div className="flex items-center justify-between px-4 py-3">
          <h3 className={`text-base font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
            Fill with AI
          </h3>
          <button
            onClick={onClose}
            className={`rounded-full p-1 hover:bg-opacity-80 transition-colors
                    ${isDarkMode ? 'hover:bg-slate-800' : 'hover:bg-gray-100'}`}
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>

        {/* Compact Content */}
        <div className="p-4 space-y-3">
          {/* Segmented Control for Input Type */}
          <div className={`inline-flex w-full rounded-md p-0.5
                        ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'}`}>
            <button
              onClick={() => setInputType('text')}
              className={`flex-1 flex items-center justify-center px-3 py-1.5 text-xs font-medium rounded-md
                       transition-all duration-200 ${inputType === 'text'
                         ? isDarkMode
                           ? 'bg-[#252525] text-blue-400 shadow-sm'
                           : 'bg-white text-blue-600 shadow-sm'
                         : isDarkMode
                           ? 'text-slate-400 hover:text-slate-300'
                           : 'text-gray-600 hover:text-gray-800'
                       }`}
            >
              <DocumentTextIcon className="h-3.5 w-3.5 mr-1.5" />
              Text
            </button>
            <button
              onClick={() => setInputType('pdf')}
              className={`flex-1 flex items-center justify-center px-3 py-1.5 text-xs font-medium rounded-md
                       transition-all duration-200 ${inputType === 'pdf'
                         ? isDarkMode
                           ? 'bg-[#252525] text-blue-400 shadow-sm'
                           : 'bg-white text-blue-600 shadow-sm'
                         : isDarkMode
                           ? 'text-slate-400 hover:text-slate-300'
                           : 'text-gray-600 hover:text-gray-800'
                       }`}
            >
              <DocumentIcon className="h-3.5 w-3.5 mr-1.5" />
              PDF
            </button>
          </div>

          {/* Input Fields */}
          {inputType === 'text' ? (
            <textarea
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Enter text to generate profile..."
              rows={4}
              className={`w-full px-3 py-2 text-sm rounded-md border transition-all
                       resize-none focus:ring-1 focus:ring-blue-500
                       ${isDarkMode 
                         ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 placeholder-slate-500' 
                         : 'bg-white border-gray-200 text-gray-900 placeholder-gray-400'}`}
            />
          ) : (
            <div className={`border border-dashed rounded-md p-4 text-center
                         ${isDarkMode 
                           ? 'border-[#333333] bg-[#1a1a1a]' 
                           : 'border-gray-200 bg-gray-50'}`}>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                className="hidden"
                id="pdf-upload"
              />
              <label
                htmlFor="pdf-upload"
                className="cursor-pointer flex flex-col items-center"
              >
                <DocumentIcon className={`h-8 w-8 mb-2
                                     ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
                <span className={`text-xs ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                  {file ? file.name : 'Click to upload PDF'}
                </span>
              </label>
            </div>
          )}

          {error && (
            <p className="text-red-500 text-xs">{error}</p>
          )}
        </div>

        {/* Compact Footer */}
        <div className={`flex justify-end gap-2 px-4 py-3 border-t
                      ${isDarkMode ? 'border-[#333333]' : 'border-gray-100'}`}>
          <button
            onClick={onClose}
            className={`px-3 py-1.5 text-xs font-medium rounded-md transition-colors
                     ${isDarkMode
                       ? 'text-slate-300 hover:bg-slate-800'
                       : 'text-gray-600 hover:bg-gray-100'}`}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={isLoading || (!input && !file)}
            className={`px-3 py-1.5 text-xs font-medium rounded-md transition-colors
                     ${isLoading || (!input && !file)
                       ? 'bg-blue-400/50 text-white/50 cursor-not-allowed'
                       : 'bg-blue-500 text-white hover:bg-blue-600'}`}
          >
            {isLoading ? 'Processing...' : 'Generate'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonaAIFillModal; 