import React from 'react';
import { useTheme } from '../context/ThemeContext';
import { 
  PlusIcon, 
  MagnifyingGlassIcon,
  ArrowsUpDownIcon
} from '@heroicons/react/24/outline';

const CanvasWelcomeSkeleton = () => {
  const { isDarkMode } = useTheme();

  // CSS Variables matching index.html with enhanced text colors
  const cssVariables = isDarkMode ? {
    loaderBg: '#121212',
    loaderColor: '#3B82F6',
    chatBg: 'rgba(17, 24, 39, 0.4)',
    chatBorder: 'rgba(59, 130, 246, 0.15)',
    chatItem: 'rgba(59, 130, 246, 0.2)',
    headerItem: 'rgba(59, 130, 246, 0.25)',
    shimmer: 'rgba(59, 130, 246, 0.3)',
    postBg: 'rgba(17, 24, 39, 0.8)',
    // Enhanced text colors for dark mode
    textPrimary: 'rgba(255, 255, 255, 0.95)',
    textSecondary: 'rgba(148, 163, 184, 0.95)', // Slate-300
    textTertiary: 'rgba(148, 163, 184, 0.8)', // Slate-400
    // Background colors
    bgPrimary: 'rgba(17, 24, 39, 0.95)',
    bgSecondary: 'rgba(30, 41, 59, 0.95)',
    bgTertiary: 'rgba(51, 65, 85, 0.95)',
  } : {
    loaderBg: '#ffffff',
    loaderColor: 'rgba(96, 165, 250, 1)',
    chatBg: 'rgba(96, 165, 250, 0.05)',
    chatBorder: 'rgba(96, 165, 250, 0.1)',
    chatItem: 'rgba(96, 165, 250, 0.1)',
    headerItem: 'rgba(96, 165, 250, 0.15)',
    shimmer: 'rgba(96, 165, 250, 0.2)',
    postBg: 'rgba(255, 255, 255, 0.8)',
    // Enhanced text colors for light mode
    textPrimary: 'rgba(15, 23, 42, 0.95)', // Slate-900
    textSecondary: 'rgba(51, 65, 85, 0.95)', // Slate-700
    textTertiary: 'rgba(71, 85, 105, 0.8)', // Slate-600
    // Background colors
    bgPrimary: 'rgba(255, 255, 255, 0.95)',
    bgSecondary: 'rgba(241, 245, 249, 0.95)',
    bgTertiary: 'rgba(226, 232, 240, 0.95)',
  };

  const renderSkeletonItems = (count) => (
    <div className={`
      grid gap-2
      grid-cols-[repeat(auto-fill,minmax(200px,1fr))]
      auto-rows-min
    `}>
      {[...Array(count)].map((_, i) => (
        <div
          key={i}
          className="group relative border rounded-lg w-full h-[60px] p-3 flex flex-col justify-between transform hover:-translate-y-0.5 transition-all duration-200 ease-out"
          style={{
            background: cssVariables.bgPrimary,
            borderColor: cssVariables.chatBorder,
            boxShadow: isDarkMode ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
          }}
        >
          {/* Title Area */}
          <div className="flex items-center gap-2">
            <div 
              className="h-5 w-5 rounded-md flex-shrink-0 relative overflow-hidden"
              style={{
                background: `linear-gradient(135deg, ${cssVariables.headerItem}, ${cssVariables.chatItem})`,
              }}
            >
              <div 
                className="absolute inset-0"
                style={{
                  background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                  animation: 'shimmer 2s infinite'
                }}
              />
            </div>
            <div 
              className="h-4 w-32 rounded relative overflow-hidden"
              style={{
                background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
              }}
            >
              <div 
                className="absolute inset-0"
                style={{
                  background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                  animation: 'shimmer 2s infinite'
                }}
              />
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-between">
            <div 
              className="h-3 w-16 rounded relative overflow-hidden"
              style={{
                background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
              }}
            >
              <div 
                className="absolute inset-0"
                style={{
                  background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                  animation: 'shimmer 2s infinite'
                }}
              />
            </div>
            <div 
              className="h-5 w-5 rounded relative overflow-hidden"
              style={{
                background: `linear-gradient(135deg, ${cssVariables.headerItem}, ${cssVariables.chatItem})`,
              }}
            >
              <div 
                className="absolute inset-0"
                style={{
                  background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                  animation: 'shimmer 2s infinite'
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div 
      className="w-full h-full flex flex-col overflow-hidden cursor-default relative"
      style={{ background: cssVariables.bgPrimary }}
    >
      {/* Compact Top Bar */}
      <div 
        className="sticky top-0 z-[99] px-3 sm:px-4 border-b sm:border-none h-9 flex-shrink-0"
        style={{
          background: `${cssVariables.bgPrimary}95`,
          borderColor: cssVariables.chatBorder
        }}
      >
        <div className="flex items-center justify-between max-w-screen-2xl mx-auto h-full">
          {/* Desktop Pill Container */}
          <div 
            className="hidden sm:flex items-center justify-between w-full px-2.5 h-8 rounded-lg mt-4"
            style={{
              background: cssVariables.bgSecondary,
              boxShadow: `0 0 0 1px ${cssVariables.chatBorder}`
            }}
          >
            {/* Left: Title + Count */}
            <div className="flex items-center gap-2">
              <div 
                className="h-4 w-24 rounded relative overflow-hidden"
                style={{
                  background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
                }}
              >
                <div 
                  className="absolute inset-0"
                  style={{
                    background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                    animation: 'shimmer 2s infinite'
                  }}
                />
              </div>
              <div 
                className="h-4 w-6 rounded-full relative overflow-hidden"
                style={{
                  background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
                }}
              >
                <div 
                  className="absolute inset-0"
                  style={{
                    background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                    animation: 'shimmer 2s infinite'
                  }}
                />
              </div>
            </div>

            {/* Right Controls in Pill */}
            <div className="flex items-center gap-1.5 h-full pr-1.5">
              {/* Sort Button */}
              <div 
                className="h-5 w-6 rounded-md flex items-center justify-center"
                style={{ color: cssVariables.textSecondary }}
              >
                <ArrowsUpDownIcon className="w-3 h-3" />
              </div>

              {/* Search */}
              <div 
                className="h-5 w-6 rounded-md flex items-center justify-center"
                style={{ color: cssVariables.textSecondary }}
              >
                <MagnifyingGlassIcon className="w-3 h-3" />
              </div>

              {/* New Button */}
              <div 
                className="flex items-center gap-1.5 px-2 h-5 rounded-md text-[10px] font-medium"
                style={{
                  background: cssVariables.bgTertiary,
                  color: cssVariables.textSecondary,
                  boxShadow: `0 0 0 1px ${cssVariables.chatBorder}`
                }}
              >
                <PlusIcon className="w-3 h-3" />
                <div 
                  className="h-3 w-20 rounded relative overflow-hidden"
                  style={{
                    background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
                  }}
                >
                  <div 
                    className="absolute inset-0"
                    style={{
                      background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                      animation: 'shimmer 2s infinite'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Layout */}
          <div className="flex sm:hidden items-center justify-between w-full">
            {/* Left: Title + Count */}
            <div className="flex items-center gap-2">
              <div 
                className="h-4 w-24 rounded relative overflow-hidden"
                style={{
                  background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
                }}
              >
                <div 
                  className="absolute inset-0"
                  style={{
                    background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                    animation: 'shimmer 2s infinite'
                  }}
                />
              </div>
              <div 
                className="h-4 w-6 rounded-full relative overflow-hidden"
                style={{
                  background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
                }}
              >
                <div 
                  className="absolute inset-0"
                  style={{
                    background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                    animation: 'shimmer 2s infinite'
                  }}
                />
              </div>
            </div>

            {/* Right Controls */}
            <div className="flex items-center gap-2">
              <div 
                className="h-6 w-6 rounded-md flex items-center justify-center"
                style={{ color: cssVariables.textSecondary }}
              >
                <MagnifyingGlassIcon className="w-3.5 h-3.5" />
              </div>
              <div 
                className="flex items-center gap-1.5 px-2 h-6 rounded-md text-xs font-medium"
                style={{
                  background: cssVariables.bgTertiary,
                  color: cssVariables.textSecondary,
                  boxShadow: `0 0 0 1px ${cssVariables.chatBorder}`
                }}
              >
                <PlusIcon className="w-3 h-3" />
                <div 
                  className="h-3 w-16 rounded relative overflow-hidden"
                  style={{
                    background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
                  }}
                >
                  <div 
                    className="absolute inset-0"
                    style={{
                      background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                      animation: 'shimmer 2s infinite'
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div className="flex-1 relative min-h-0">
        <div 
          className="absolute inset-0 custom-scrollbar pt-3 sm:pt-4 px-3 sm:px-4"
          style={{ background: cssVariables.bgPrimary }}
        >
          <div className="space-y-6">
            {renderSkeletonItems(8)}
          </div>
        </div>
      </div>

      {/* Bottom Stats Bar */}
      <div 
        className="h-[46px] px-4 border-t flex items-center justify-between"
        style={{
          background: cssVariables.bgPrimary,
          borderColor: cssVariables.chatBorder
        }}
      >
        {/* Left Stats */}
        <div className="flex items-center gap-6">
          <div 
            className="h-4 w-32 rounded relative overflow-hidden"
            style={{
              background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
            }}
          >
            <div 
              className="absolute inset-0"
              style={{
                background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                animation: 'shimmer 2s infinite'
              }}
            />
          </div>
          <div 
            className="h-4 w-24 rounded relative overflow-hidden"
            style={{
              background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
            }}
          >
            <div 
              className="absolute inset-0"
              style={{
                background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
                animation: 'shimmer 2s infinite'
              }}
            />
          </div>
        </div>

        {/* Right Stats */}
        <div 
          className="h-4 w-32 rounded relative overflow-hidden"
          style={{
            background: `linear-gradient(90deg, ${cssVariables.headerItem}, ${cssVariables.chatItem}, ${cssVariables.headerItem})`,
          }}
        >
          <div 
            className="absolute inset-0"
            style={{
              background: `linear-gradient(90deg, transparent, ${cssVariables.shimmer}, transparent)`,
              animation: 'shimmer 2s infinite'
            }}
          />
        </div>
      </div>

      <style>{`
        @keyframes shimmer {
          0% { transform: translateX(-100%); }
          100% { transform: translateX(100%); }
        }
      `}</style>
    </div>
  );
};

export default CanvasWelcomeSkeleton; 