import React, { useState, useEffect } from 'react';
import { ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon, DocumentIcon, MicrophoneIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import ChatMessageMarkdown from './ChatMessageMarkdown';
import TemplateTag from './TemplateTag';
import TextFilePreview from './TextFilePreview';

const styles = `
  @media (min-width: 640px) {
    .icon-text {
      display: inline;
    }
  }
  @media (max-width: 639px) {
    .icon-text {
      display: none;
    }
  }
  .action-buttons {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  .action-buttons.hidden {
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none;
  }
`;

const UserMessageChat = ({ 
  message, 
  index, 
  isDarkMode, 
  copiedMessages, 
  onCopy, 
  onSaveToTextNotes, 
  onPreviewText, 
  onRetry, 
  isAutoScrolling
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [copyState, setCopyState] = useState(false);
  
  const messageContent = message?.content || message?.message?.content;
  const messageId = message?.id || message?.message?.id || message?._id;

  const handleCopy = async (content, idx) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopyState(true);
      onCopy(content, idx);
      
      toast.success('Message copied!', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });

      setTimeout(() => {
        setCopyState(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy message', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });
    }
  };

  const renderMessageContent = () => {
    const hasPreviewedContent = message.previewedContent && (
      message.previewedContent.voiceNotes ||
      message.previewedContent.textNotes ||
      message.previewedContent.attachedFiles ||
      message.previewedContent.pastedText
    );

    // Enhanced regex to capture content type and title
    const parts = message.content ? message.content.split(/(\*\*\*\*\*(?:Voice Note|Text Note|File|Pasted Content)[^*]+\*\*\*\*\*)/s) : [];

    return (
      <div className="message-content overflow-hidden">
        {/* Regular text content */}
        {parts.map((part, idx) => {
          if (part.startsWith('*****') && part.endsWith('*****')) {
            // Extract content between stars
            const content = part.slice(5, -5);
            
            // Parse the content type and title
            const typeMatch = content.match(/^(Voice Note|Text Note|File|Pasted Content)(?:\s*\(([^)]+)\))?:/);
            if (typeMatch) {
              const [, type, title] = typeMatch;
              const actualContent = content.substring(content.indexOf('\n') + 1);
              
              // Determine icon and styling based on type
              const getPreviewProps = () => {
                switch(type) {
                  case 'Voice Note':
                    return {
                      name: title || 'Voice Note',
                      icon: 'microphone',
                      type: 'voice-note'
                    };
                  case 'Text Note':
                    return {
                      name: title || 'Text Note',
                      icon: 'document-text',
                      type: 'text-note'
                    };
                  case 'File':
                    return {
                      name: title || 'File',
                      icon: 'document',
                      type: 'file'
                    };
                  case 'Pasted Content':
                    return {
                      name: 'Pasted Text',
                      icon: 'clipboard',
                      type: 'pasted'
                    };
                  default:
                    return {
                      name: 'Attached Content',
                      icon: 'document',
                      type: 'unknown'
                    };
                }
              };

              const previewProps = getPreviewProps();

              return (
                <TextFilePreview
                  key={`preview-${idx}`}
                  content={actualContent}
                  name={previewProps.name}
                  size={new Blob([actualContent]).size}
                  lines={actualContent.split('\n').length}
                  isDarkMode={isDarkMode}
                  icon={previewProps.icon}
                  onPreview={() => onPreviewText({
                    content: actualContent,
                    name: previewProps.name,
                    size: new Blob([actualContent]).size,
                    lines: actualContent.split('\n').length,
                    type: previewProps.type
                  })}
                />
              );
            }
          }
          
          return part && (
            <div key={`text-${idx}`} className="last:mb-0 mb-4">
              <ChatMessageMarkdown
                content={part}
                isDarkMode={isDarkMode}
                onSaveToTextNotes={onSaveToTextNotes}
                index={index}
                copiedMessages={copiedMessages}
                onCopy={onCopy}
              />
            </div>
          );
        })}

        {/* Previewed content */}
        {hasPreviewedContent && (
          <div className="space-y-2">
            {message.previewedContent.voiceNotes?.map((note, idx) => (
              <TextFilePreview
                key={`voice-${idx}`}
                content={note.transcription}
                name={note.title}
                size={note.size}
                lines={note.lines}
                isDarkMode={isDarkMode}
                onPreview={() => onPreviewText({
                  ...note,
                  type: 'voice-note'
                })}
              />
            ))}

            {message.previewedContent.textNotes?.map((note, idx) => (
              <TextFilePreview
                key={`text-${idx}`}
                {...note}
                isDarkMode={isDarkMode}
                onPreview={() => onPreviewText({
                  ...note,
                  type: 'text-note'
                })}
              />
            ))}

            {message.previewedContent.attachedFiles?.map((file, idx) => (
              <TextFilePreview
                key={`file-${idx}`}
                {...file}
                isDarkMode={isDarkMode}
                onPreview={() => onPreviewText({
                  ...file,
                  type: 'file'
                })}
              />
            ))}

            {message.previewedContent.pastedText && (
              <TextFilePreview
                {...message.previewedContent.pastedText}
                name="Pasted Text"
                isDarkMode={isDarkMode}
                onPreview={() => onPreviewText({
                  ...message.previewedContent.pastedText,
                  type: 'pasted'
                })}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <style>{styles}</style>
      <div 
        id={`user-message-${index}`}
        className="mb-2 relative flex justify-end scroll-mt-8"
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className={`inline-block rounded-lg break-words max-w-[85%] max-w-[280px] ${
          isDarkMode
            ? 'bg-[#151515] text-slate-200 border border-white/[0.05]'
            : 'bg-[#fafafa] text-[#1E293B] border border-[#f4f4f4]'
        }`}>
          <div className="flex items-start px-3 py-0">
            <div className="flex-grow text-left overflow-hidden">
              {message.isError ? (
                <div className="text-red-500 flex items-center">
                  Error: {message.content}
                  <button
                    className="ml-1.5 text-blue-500 hover:text-blue-700"
                    onClick={() => onRetry(message.content)}
                    title="Retry"
                  >
                    <ArrowPathIcon className="h-3.5 w-3.5" />
                  </button>
                </div>
              ) : (
                <>
                  {/* Template Tag */}
                  {message.templateTag && (
                    <div className="mb-1.5">
                      <TemplateTag title={message.templateTag} />
                    </div>
                  )}

                  {/* Message Content */}
                  {renderMessageContent()}
                </>
              )}
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="absolute right-0 bottom-[-16px] w-full flex justify-end">
          <div className="mt-0.5">
            <div className={`action-buttons ${isHovering ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200`}>
              <button
                className={`
                  p-1 rounded-md transition-all duration-200
                  ${copyState 
                    ? isDarkMode 
                      ? 'bg-green-500/20 text-green-400' 
                      : 'bg-green-100 text-green-600'
                    : isDarkMode 
                      ? 'bg-[#252525] hover:bg-[#333333] text-gray-400 hover:text-gray-300' 
                      : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700'
                  }
                `}
                onClick={() => handleCopy(message.content, index)}
                title="Copy message"
              >
                <motion.div
                  initial={false}
                  animate={{
                    scale: copyState ? [1, 1.2, 1] : 1,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {copyState ? (
                    <CheckIcon className="w-3 h-3" />
                  ) : (
                    <ClipboardIcon className="w-3 h-3" />
                  )}
                </motion.div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserMessageChat;