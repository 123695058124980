import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  UserCircleIcon,
  QuestionMarkCircleIcon,
  SunIcon,
  MoonIcon,
  ArrowRightOnRectangleIcon,
  LinkIcon,
  CreditCardIcon,
  ChartBarIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-hot-toast';
import SubscriptionService from '../services/subscriptionService';

const MenuItem = ({ children, onClick, className, isFirst, isLast, isDarkMode }) => {
  return (
    <motion.div
      whileHover={{ 
        x: 2,
      }}
      whileTap={{ scale: 0.98 }}
      transition={{ duration: 0.1 }}
      className="relative"
    >
      {onClick ? (
        <button
          onClick={onClick}
          className={`block w-full text-left px-4 py-2 text-xs ${className} flex items-center group transition-all duration-200 relative z-10`}
          role="menuitem"
        >
          {children}
        </button>
      ) : (
        <div className={`block px-4 py-2 text-xs ${className} flex items-center group transition-all duration-200 relative z-10`}>
          {children}
        </div>
      )}
      <motion.div
        className={`absolute inset-0 opacity-0 ${
          isDarkMode 
            ? 'bg-gradient-to-r from-[#2A2A2A] to-[#1E1E1E] ring-1 ring-[#333333]' 
            : 'bg-slate-200'
        }`}
        initial={false}
        whileHover={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
      />
    </motion.div>
  );
};

const SideBarFooterMenuPopup = ({ 
  dropdownRef,
  isDarkMode,
  isSidebarCollapsed,
  toggleDarkMode,
  logout,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const isPro = user?.subscriptionDetails?.planId === 'pro';

  const handleManageSubscription = () => {
    navigate('/subscription');
  };

  const menuItems = [
    {
      label: 'LinkedIn Connection',
      icon: LinkIcon,
      onClick: () => navigate('/linkedin-connection'),
    },
    // ... existing menu items ...
  ];

  const menuVariants = {
    hidden: { 
      opacity: 0, 
      scale: 0.95,
      y: 10,
    },
    visible: { 
      opacity: 1, 
      scale: 1,
      y: 0,
      transition: {
        duration: 0.15,
        ease: 'easeOut',
      }
    },
    exit: { 
      opacity: 0, 
      scale: 0.95,
      y: 10,
      transition: {
        duration: 0.1,
        ease: 'easeIn',
      }
    }
  };

  const getItemStyle = (isDarkMode) => ({
    normal: isDarkMode 
      ? 'text-gray-300' 
      : 'text-slate-700',
    danger: isDarkMode
      ? 'text-red-400'
      : 'text-red-600',
    icon: isDarkMode 
      ? 'text-slate-400 group-hover:text-white' 
      : 'text-slate-600 group-hover:text-slate-900',
    dangerIcon: isDarkMode
      ? 'text-red-400 group-hover:text-red-300'
      : 'text-red-600 group-hover:text-red-700',
  });

  const styles = getItemStyle(isDarkMode);

  return (
    <AnimatePresence>
      <motion.div 
        ref={dropdownRef}
        className={`py-2 rounded-md shadow-lg absolute bottom-2 w-48 z-10 overflow-hidden ${
          isDarkMode 
            ? 'bg-[#121212] border border-[#333333] shadow-lg shadow-black/20' 
            : 'bg-white border border-slate-200 shadow-lg shadow-slate-200/20'
        }`}
        style={{
          left: isSidebarCollapsed ? 'calc(3.5rem + 0.5rem)' : 'calc(12rem + 0.5rem)',
        }}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={menuVariants}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu-button"
      >
        {/* Profile & Activity Section */}
        <Link to="/my-profile">
          <MenuItem className={styles.normal} isFirst isDarkMode={isDarkMode}>
            <UserCircleIcon className={`w-4 h-4 mr-3 transition-all duration-200 ${styles.icon}`} />
            My Profile
          </MenuItem>
        </Link>

        <Link to="/analytics">
          <MenuItem className={styles.normal} isDarkMode={isDarkMode}>
            <RocketLaunchIcon className={`w-4 h-4 mr-3 transition-all duration-200 ${styles.icon}`} />
            My Activity
          </MenuItem>
        </Link>

        <Link to="/linkedin-connection">
          <MenuItem className={styles.normal} isDarkMode={isDarkMode}>
            <LinkIcon className={`w-4 h-4 mr-3 transition-all duration-200 ${styles.icon}`} />
            LinkedIn Connection
          </MenuItem>
        </Link>

        <div className={`mx-4 my-1 ${isDarkMode ? 'border-t border-[#333333]/50' : 'border-t border-slate-200/50'}`} />

        {/* Admin & Plan Section */}
        {(user?.isAdmin || isPro) && (
          <>
            {user?.isAdmin && (
              <Link to="/admin/analytics">
                <MenuItem className={styles.normal} isDarkMode={isDarkMode}>
                  <ChartBarIcon className={`w-4 h-4 mr-3 transition-all duration-200 ${styles.icon}`} />
                  Admin Overview
                </MenuItem>
              </Link>
            )}

            {isPro && (
              <MenuItem onClick={handleManageSubscription} className={styles.normal} isDarkMode={isDarkMode}>
                <CreditCardIcon className={`w-4 h-4 mr-3 transition-all duration-200 ${styles.icon}`} />
                Manage Plan
              </MenuItem>
            )}

            <div className={`mx-4 my-1 ${isDarkMode ? 'border-t border-[#333333]/50' : 'border-t border-slate-200/50'}`} />
          </>
        )}

        {/* Help & Theme Section */}
        <Link to="/help">
          <MenuItem className={styles.normal} isDarkMode={isDarkMode}>
            <QuestionMarkCircleIcon className={`w-4 h-4 mr-3 transition-all duration-200 ${styles.icon}`} />
            Help
          </MenuItem>
        </Link>

        <MenuItem onClick={toggleDarkMode} className={styles.normal} isDarkMode={isDarkMode}>
          {isDarkMode ? (
            <SunIcon className={`w-4 h-4 mr-3 transition-all duration-200 ${styles.icon}`} />
          ) : (
            <MoonIcon className={`w-4 h-4 mr-3 transition-all duration-200 ${styles.icon}`} />
          )}
          {isDarkMode ? "Light Mode" : "Dark Mode"}
        </MenuItem>

        <div className={`mx-4 my-1 ${isDarkMode ? 'border-t border-[#333333]/50' : 'border-t border-slate-200/50'}`} />

        {/* Logout Section */}
        <MenuItem onClick={logout} className={styles.danger} isLast isDarkMode={isDarkMode}>
          <ArrowRightOnRectangleIcon className={`w-4 h-4 mr-3 transition-all duration-200 ${styles.dangerIcon}`} />
          Logout
        </MenuItem>
      </motion.div>
    </AnimatePresence>
  );
};

export default SideBarFooterMenuPopup; 