import React, { useEffect, useState } from 'react';
import { DocumentTextIcon, XMarkIcon, ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import toast from 'react-hot-toast';
import { cn } from '../utils/cn';

const PreviewPastedText = ({ content, name, size, lines, isDarkMode, onRemove }) => {
  const [copyState, setCopyState] = useState(false);

  const formatSize = (bytes) => {
    const kb = bytes / 1024;
    return `${kb.toFixed(1)} KB`;
  };

  const handleClose = (e) => {
    e.stopPropagation();
    onRemove();
  };

  const handleCopy = async (e) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content || '');
      setCopyState(true);
      
      toast.success('Message copied!', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });

      setTimeout(() => {
        setCopyState(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
      toast.error('Failed to copy message', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.2 }}
      className={cn(
        "w-[calc(100vw-32px)] md:w-full rounded-xl overflow-hidden font-sans",
        "border shadow-lg",
        "touch-manipulation",
        isDarkMode 
          ? "bg-[#151515] border-[#333333] shadow-black/20" 
          : "bg-white border-gray-200 shadow-gray-100/50"
      )}
    >
      <div className={cn(
        "min-h-[44px] md:h-11 flex items-center justify-between px-3 md:px-4",
        "border-b",
        isDarkMode 
          ? "bg-[#1A1A1A] border-[#333333]" 
          : "bg-gray-50/80 border-gray-200",
        "backdrop-blur-sm"
      )}>
        <div className="flex items-center space-x-2 md:space-x-3 flex-1 min-w-0">
          <div className={cn(
            "w-8 h-8 md:w-7 md:h-7 rounded-lg flex items-center justify-center",
            isDarkMode ? "bg-[#252525]" : "bg-white"
          )}>
            <DocumentTextIcon className={cn(
              "h-4 w-4",
              isDarkMode ? "text-slate-300" : "text-gray-600"
            )} />
          </div>
          <div className="flex items-center space-x-2 min-w-0">
            <span className={cn(
              "text-sm font-medium tracking-tight truncate",
              isDarkMode ? "text-slate-200" : "text-gray-700"
            )}>
              {name || 'Pasted text'}
            </span>
            <div className={cn(
              "hidden md:flex items-center space-x-1.5 text-xs font-medium",
              isDarkMode ? "text-slate-400" : "text-gray-500"
            )}>
              <span className="text-[4px] leading-none relative top-[0.5px]">●</span>
              <span>{formatSize(size)}</span>
              <span className="text-[4px] leading-none relative top-[0.5px]">●</span>
              <span>{lines} {lines === 1 ? 'line' : 'lines'}</span>
            </div>
          </div>
        </div>
        
        <div className="flex items-center gap-1">
          <button
            onClick={handleCopy}
            className={cn(
              "p-1.5 rounded-md transition-all duration-200",
              copyState 
                ? isDarkMode 
                  ? 'bg-green-500/20 text-green-400' 
                  : 'bg-green-100 text-green-600'
                : isDarkMode 
                  ? 'bg-[#252525] hover:bg-[#333333] text-gray-400 hover:text-gray-300' 
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700'
            )}
            title="Copy message"
          >
            <motion.div
              initial={false}
              animate={{
                scale: copyState ? [1, 1.2, 1] : 1,
              }}
              transition={{ duration: 0.3 }}
            >
              {copyState ? (
                <CheckIcon className="w-3.5 h-3.5" />
              ) : (
                <ClipboardIcon className="w-3.5 h-3.5" />
              )}
            </motion.div>
          </button>
          <button
            onClick={handleClose}
            className={cn(
              "p-1.5 rounded-md transition-all duration-200 -mr-1",
              isDarkMode 
                ? "hover:bg-white/5 active:bg-white/10 text-slate-400 hover:text-slate-300" 
                : "hover:bg-gray-100 active:bg-gray-200 text-gray-500 hover:text-gray-700"
            )}
          >
            <XMarkIcon className="h-4 w-4" />
          </button>
        </div>
      </div>

      <motion.div 
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        transition={{ duration: 0.2 }}
        className={cn(
          "overflow-y-auto overscroll-contain custom-scrollbar",
          isDarkMode ? "bg-[#151515]" : "bg-white"
        )}
        style={{ 
          maxHeight: 'calc(100vh - 180px)',
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <div className={cn(
          "relative",
          "before:absolute before:inset-x-0 before:top-0 before:h-4 before:z-10",
          "after:absolute after:inset-x-0 after:bottom-0 after:h-4 after:z-10",
          isDarkMode 
            ? "before:bg-gradient-to-b before:from-[#151515] before:to-transparent after:bg-gradient-to-t after:from-[#151515] after:to-transparent" 
            : "before:bg-gradient-to-b before:from-white before:to-transparent after:bg-gradient-to-t after:from-white after:to-transparent"
        )}>
          <div className={cn(
            "text-[15px] md:text-sm leading-relaxed p-3 md:p-4 font-normal",
            "whitespace-pre-wrap break-words tracking-tight",
            isDarkMode ? "text-slate-200" : "text-gray-900"
          )}>
            {content?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < content?.split('\n').length - 1 && <br />}
              </React.Fragment>
            )) || ''}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default PreviewPastedText;