import React, { useState, useEffect, useMemo, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import { Helmet } from 'react-helmet-async';
import { 
  MagnifyingGlassIcon,
  FunnelIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  PencilIcon,
  TrashIcon,
  ArrowPathIcon,
  DocumentArrowDownIcon,
  ShareIcon,
  UserIcon,
  UserGroupIcon,
  CalendarIcon,
  TagIcon,
  RocketLaunchIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  CheckCircleIcon,
  EllipsisVerticalIcon,
  XMarkIcon,
  DocumentPlusIcon,
  LightBulbIcon,
  SparklesIcon,
  PlusIcon
} from '@heroicons/react/24/outline';
import { fetchContentStrategies, contentIdeaApi } from '../../api';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import toast from 'react-hot-toast';
import ResetIcon from '../personas/ResetIcon';
import ContentStrategyCard from './ContentStrategyCard';
import AnimatedLoader from '../common/AnimatedLoader';

const TopBar = ({ 
  isDarkMode, 
  onNewStrategy, 
  search, 
  setSearch, 
  sortBy, 
  setSortBy,
  onDeleteMultiple,
  selectionMode,
  onReset
}) => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileSearch, setShowMobileSearch] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const searchInputRef = useRef(null);
  const moreActionsRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moreActionsRef.current && !moreActionsRef.current.contains(event.target)) {
        setShowMobileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const sortOptions = [
    { value: 'date', label: 'Latest First', icon: CalendarIcon },
    { value: 'name', label: 'By Creator', icon: UserIcon },
    { value: 'ideas', label: 'Most Ideas', icon: DocumentTextIcon }
  ];

  return (
    <>
      {/* Mobile Search Bar */}
      {showMobileSearch && (
        <div className={`
          fixed inset-x-0 top-0 z-50 p-2
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
          border-b ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
        `}>
          <div className="relative flex items-center">
            <MagnifyingGlassIcon className={`absolute left-3 h-4 w-4 ${
              isDarkMode ? 'text-slate-400' : 'text-gray-400'
            }`} />
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className={`w-full pl-9 pr-8 py-2 text-sm rounded-lg
                focus:outline-none focus:ring-2
                ${isDarkMode
                  ? 'bg-[#1a1a1a] text-slate-200 placeholder-slate-400 focus:ring-blue-500/30'
                  : 'bg-gray-100 text-gray-900 placeholder-gray-500 focus:ring-blue-500/50'
                }
              `}
              placeholder="Search strategies..."
              autoFocus
            />
            <button
              onClick={() => {
                setShowMobileSearch(false);
                setSearch('');
              }}
              className={`absolute right-3 p-0.5 rounded-full ${
                isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-200'
              }`}
            >
              <XMarkIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
            </button>
          </div>
        </div>
      )}

      {/* Main TopBar */}
      <div className={`${isDarkMode ? 'bg-[#121212]/95' : 'bg-white/50'} 
        backdrop-blur-sm sticky top-0 z-10 !h-10 min-h-10 flex items-center justify-between 
        px-3 sm:px-4 w-full border-b ${isDarkMode ? 'border-[#333333]' : 'border-gray-200/60'}`}>
        {/* Left side - Title */}
        <div className="flex items-center gap-2">
          <LightBulbIcon className={`h-5 w-5 ${
            isDarkMode ? 'text-gray-400' : 'text-gray-600'
          }`} />
          <h2 className={`text-sm font-medium ${
            isDarkMode ? 'text-gray-200' : 'text-gray-800'
          }`}>
            Topic Generator
          </h2>
        </div>

        {/* Right side - Actions */}
        <div className="flex items-center gap-2">
          {/* Desktop Search */}
          <div className="relative hidden sm:flex items-center">
            {isSearchExpanded ? (
              <div className="relative w-60">
                <input
                  ref={searchInputRef}
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search strategies..."
                  className={`w-full pl-8 pr-8 py-1.5 text-sm border rounded-md
                    ${isDarkMode 
                      ? 'bg-[#151515] border-[#333333] text-slate-200 placeholder-slate-400' 
                      : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                    } focus:outline-none focus:ring-2 focus:ring-blue-500`}
                />
                <MagnifyingGlassIcon className={`absolute left-2.5 top-1.5 h-4 w-4 ${
                  isDarkMode ? 'text-slate-400' : 'text-gray-400'
                }`} />
                {search && (
                  <button
                    onClick={() => {
                      setSearch('');
                      searchInputRef.current?.focus();
                    }}
                    className={`absolute right-2 top-1.5 p-0.5 rounded-full ${
                      isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-100'
                    }`}
                  >
                    <XMarkIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
                  </button>
                )}
              </div>
            ) : (
              <button
                onClick={() => setIsSearchExpanded(true)}
                className={`p-1.5 rounded-md transition-colors ${
                  isDarkMode
                    ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-300'
                    : 'hover:bg-slate-100 text-slate-500 hover:text-slate-600'
                }`}
              >
                <MagnifyingGlassIcon className="w-4 h-4" />
              </button>
            )}
          </div>

          {/* Mobile Search Button */}
          <button
            onClick={() => setShowMobileSearch(true)}
            className={`sm:hidden p-1.5 rounded-md transition-colors ${
              isDarkMode
                ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-300'
                : 'hover:bg-slate-100 text-slate-500 hover:text-slate-600'
            }`}
          >
            <MagnifyingGlassIcon className="w-4 h-4" />
          </button>

          {/* Sort & Actions Menu */}
          <div className="relative" ref={moreActionsRef}>
            <button
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              className={`p-1.5 rounded-md transition-colors ${
                isDarkMode
                  ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-300'
                  : 'hover:bg-slate-100 text-slate-500 hover:text-slate-600'
              }`}
            >
              <EllipsisVerticalIcon className="w-4 h-4" />
            </button>

            {/* Dropdown Menu */}
            <AnimatePresence>
              {showMobileMenu && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={`absolute right-0 mt-1 w-48 rounded-lg ${
                    isDarkMode ? 'bg-[#121212] border border-[#333333]' : 'bg-white border border-slate-200'
                  }`}
                >
                  {/* Sort Options */}
                  <div className="py-1">
                    {sortOptions.map(option => (
                      <button
                        key={option.value}
                        onClick={() => {
                          setSortBy(option.value);
                          setShowMobileMenu(false);
                        }}
                        className={`flex items-center w-full px-3 py-1.5 text-xs ${
                          sortBy === option.value
                            ? isDarkMode
                              ? 'bg-slate-700 text-slate-200'
                              : 'bg-slate-100 text-slate-900'
                            : isDarkMode
                              ? 'text-slate-300 hover:bg-slate-700'
                              : 'text-slate-700 hover:bg-slate-50'
                        }`}
                      >
                        <option.icon className="w-3.5 h-3.5 mr-2" />
                        {option.label}
                      </button>
                    ))}
                  </div>

                  {/* Divider */}
                  <div className={`h-px mx-3 ${isDarkMode ? 'bg-slate-700' : 'bg-slate-200'}`} />

                  {/* Delete Multiple */}
                  <div className="py-1">
                    <button
                      onClick={() => {
                        onDeleteMultiple();
                        setShowMobileMenu(false);
                      }}
                      className={`flex items-center w-full px-3 py-1.5 text-xs ${
                        isDarkMode
                          ? 'text-red-400 hover:bg-slate-700'
                          : 'text-red-600 hover:bg-slate-50'
                      }`}
                    >
                      <TrashIcon className="w-3.5 h-3.5 mr-2" />
                      Delete Multiple
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Generate Topics Button */}
          <motion.button
            whileTap={{ scale: 0.98 }}
            onClick={onNewStrategy}
            className={`
              flex items-center px-3 py-2 rounded-full transition-colors duration-200 text-xs
              ${isDarkMode 
                ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200' 
                : 'bg-black hover:bg-black/90 text-white'}
            `}
          >
            <PlusIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-white'} mr-1`} />
            <span className="hidden sm:inline">Generate Topics</span>
            <span className="sm:hidden">Generate</span>
          </motion.button>
        </div>
      </div>
    </>
  );
};

const StrategyDetailsPopup = ({ strategy, isDarkMode, onClose }) => {
  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Popup Content */}
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className={`relative w-full max-w-md p-6 rounded-lg ${
          isDarkMode 
            ? 'bg-[#121212] border border-[#333333]' 
            : 'bg-white border border-slate-200'
        }`}
      >
        {/* Header */}
        <div className="mb-4">
          <h3 className={`text-lg font-medium mb-2 ${
            isDarkMode ? 'text-slate-200' : 'text-slate-900'
          }`}>
            Strategy Details
          </h3>
          <div className={`text-sm ${
            isDarkMode ? 'text-slate-400' : 'text-slate-600'
          }`}>
            Created for {strategy.persona?.name || 'Unnamed Creator'}
          </div>
        </div>

        {/* Content */}
        <div className="space-y-4">
          {/* Audience Section */}
          <div>
            <h4 className={`text-xs font-medium mb-2 ${
              isDarkMode ? 'text-slate-400' : 'text-slate-500'
            }`}>
              Target Audience
            </h4>
            <div className={`text-sm ${
              isDarkMode ? 'text-slate-300' : 'text-slate-700'
            }`}>
              {strategy.audience?.targetAudienceName || 'General Audience'}
            </div>
          </div>

          {/* Frameworks Section */}
          <div>
            <h4 className={`text-xs font-medium mb-2 ${
              isDarkMode ? 'text-slate-400' : 'text-slate-500'
            }`}>
              Content Frameworks
            </h4>
            <div className="flex flex-wrap gap-2">
              {strategy.frameworks?.map((framework, index) => (
                <div
                  key={index}
                  className={`text-xs px-2.5 py-1 rounded-full ${
                    isDarkMode 
                      ? 'bg-slate-700 text-slate-300 border border-slate-600' 
                      : 'bg-slate-100 text-slate-700 border border-slate-200'
                  }`}
                >
                  {framework.frameworkName}
                </div>
              ))}
            </div>
          </div>

          {/* Statistics Section */}
          <div className="grid grid-cols-2 gap-4">
            <div className={`p-3 rounded-lg ${
              isDarkMode ? 'bg-slate-700/50' : 'bg-slate-50'
            }`}>
              <div className={`text-xs mb-1 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`}>
                Content Types
              </div>
              <div className={`text-lg font-semibold ${
                isDarkMode ? 'text-slate-200' : 'text-slate-900'
              }`}>
                {strategy.frameworks?.reduce((total, framework) => 
                  total + framework.ideaGroups.length, 0) || 0}
              </div>
            </div>
            <div className={`p-3 rounded-lg ${
              isDarkMode ? 'bg-slate-700/50' : 'bg-slate-50'
            }`}>
              <div className={`text-xs mb-1 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`}>
                Total Ideas
              </div>
              <div className={`text-lg font-semibold ${
                isDarkMode ? 'text-slate-200' : 'text-slate-900'
              }`}>
                {strategy.frameworks?.reduce((total, framework) => 
                  total + framework.ideaGroups.reduce((groupTotal, group) => 
                    groupTotal + (group.ideas?.length || 0), 0), 0) || 0}
              </div>
            </div>
          </div>
        </div>

        {/* Close Button */}
        <button
          onClick={onClose}
          className={`absolute top-4 right-4 p-1 rounded-full ${
            isDarkMode 
              ? 'hover:bg-slate-700 text-slate-400' 
              : 'hover:bg-slate-100 text-slate-500'
          }`}
        >
          <XMarkIcon className="w-5 h-5" />
        </button>
      </motion.div>
    </div>,
    document.body
  );
};

const NoSearchResults = ({ isDarkMode, search, onClearSearch }) => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    className={`
      flex flex-col items-center justify-center 
      w-full max-w-md mx-auto my-12
      ${isDarkMode 
        ? 'bg-[#151515] border-[#333333]' 
        : 'bg-slate-50/50 border-slate-200/50'
      }
      rounded-xl backdrop-blur-sm
      border py-8 px-6
    `}
  >
    <div className="flex flex-col items-center max-w-sm text-center">
      {/* Animated Icon Container */}
      <motion.div 
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ 
          delay: 0.1,
          duration: 0.4,
          ease: [0.23, 1, 0.32, 1]
        }}
        className={`
          mb-4 p-3.5 rounded-full
          ${isDarkMode 
            ? 'bg-[#1e1e1e]' 
            : 'bg-slate-100/80'
          }
        `}
      >
        <MagnifyingGlassIcon className={`
          w-7 h-7
          transition-colors duration-200
          ${isDarkMode 
            ? 'text-slate-400 md:text-slate-500 md:group-hover:text-slate-400' 
            : 'text-slate-500 md:text-slate-400 md:group-hover:text-slate-500'
          }
        `} />
      </motion.div>

      {/* Text Content with Animation */}
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        <h3 className={`
          text-lg font-medium mb-2
          ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
        `}>
          No matches found
        </h3>
        
        <p className={`
          text-sm mb-4
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}>
          No strategies found matching "<span className="font-medium">{search}</span>"
        </p>

        {/* Enhanced Button */}
        <motion.button
          onClick={onClearSearch}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className={`
            inline-flex items-center gap-1.5
            px-4 py-2 rounded-lg 
            text-sm font-medium
            ${isDarkMode 
              ? 'bg-white/10 hover:bg-white/15 text-white border border-white/10' 
              : 'bg-slate-900 hover:bg-slate-800 text-white'
            }
            transition-colors duration-200
          `}
        >
          <XMarkIcon className="w-4 h-4" />
          <span>Clear Search</span>
        </motion.button>
      </motion.div>
    </div>
  </motion.div>
);

const EmptyState = ({ isDarkMode, onNewStrategy }) => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    className={`
      flex flex-col items-center justify-center text-center
      w-full max-w-md mx-auto p-8
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10' 
        : 'bg-white/90 border border-black/5'}
      rounded-2xl backdrop-blur-xl backdrop-saturate-150
    `}
  >
    {/* Icon Container */}
    <motion.div 
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ 
        delay: 0.1,
        duration: 0.4,
        ease: [0.23, 1, 0.32, 1]
      }}
      className={`
        p-4 rounded-full mb-6
        ${isDarkMode 
          ? 'bg-[#252525] text-blue-400/80' 
          : 'bg-slate-100 text-blue-600/80'}
      `}
    >
      <PlusIcon className="w-8 h-8" />
    </motion.div>
    
    {/* Text Content */}
    <div className="space-y-2 mb-8">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-lg font-semibold tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        Create Your First Topic
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className={`text-sm max-w-sm mx-auto
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        Get started by generating topics tailored to your audience and brand voice
      </motion.p>
    </div>

    {/* Action Button */}
    <motion.button
      onClick={onNewStrategy}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className={`
        flex items-center justify-center
        px-4 py-2.5 rounded-full
        text-sm font-medium
        transition-all duration-200
        ${isDarkMode 
          ? 'bg-white/10 hover:bg-white/15 text-white' 
          : 'bg-black hover:bg-black/90 text-white'}
      `}
    >
      <PlusIcon className="w-4 h-4 mr-2" />
      Generate Topics
    </motion.button>
  </motion.div>
);

// Add this new component for the bulk actions bar
const BulkActionsBar = ({ 
  selectedCount, 
  onCancel, 
  onDelete,
  isDarkMode 
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 20 }}
    className={`fixed bottom-4 left-1/2 -translate-x-1/2 px-4 py-3 rounded-lg z-50 flex items-center gap-4 ${
      isDarkMode
        ? 'bg-[#121212] border border-[#333333]'
        : 'bg-white border border-slate-200'
    }`}
  >
    <span className={`text-sm ${
      isDarkMode ? 'text-slate-300' : 'text-slate-600'
    }`}>
      {selectedCount} strategies selected
    </span>
    <div className="flex items-center gap-2">
      <button
        onClick={onCancel}
        className={`px-3 py-1.5 rounded-md text-sm transition-colors ${
          isDarkMode
            ? 'bg-slate-700 text-slate-300 hover:bg-slate-600'
            : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
        }`}
      >
        Cancel
      </button>
      <button
        onClick={onDelete}
        className="px-3 py-1.5 rounded-md text-sm bg-red-500 text-white hover:bg-red-600 transition-colors"
      >
        Delete Selected
      </button>
    </div>
  </motion.div>
);

// Add DeleteConfirmationModal before ContentStrategyManager
const DeleteConfirmationModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  isDarkMode,
  strategyName,
  isMultiple,
  count 
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleConfirm = async () => {
    setIsDeleting(true);
    try {
      await onConfirm();
    } finally {
      setIsDeleting(false);
    }
  };

  if (!isOpen) return null;

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/50 backdrop-blur-sm"
        onClick={!isDeleting ? onClose : undefined}
      />
      
      {/* Modal */}
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className={`relative w-full max-w-md p-6 rounded-lg ${
          isDarkMode 
            ? 'bg-[#121212] border border-[#333333]' 
            : 'bg-white border border-slate-200'
        }`}
      >
        <div className="flex flex-col gap-4">
          <div className="flex items-start gap-3">
            <div className={`p-2 rounded-full ${
              isDarkMode ? 'bg-red-500/10' : 'bg-red-50'
            }`}>
              <TrashIcon className="w-5 h-5 text-red-500" />
            </div>
            <div>
              <h3 className={`text-sm font-medium ${
                isDarkMode ? 'text-slate-200' : 'text-slate-900'
              }`}>
                Delete {isMultiple ? 'strategies' : 'strategy'}
              </h3>
              <p className={`mt-1 text-xs ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`}>
                {isMultiple 
                  ? `Are you sure you want to delete ${count} selected strategies?`
                  : `Are you sure you want to delete "${strategyName}"?`
                }
                <br />
                <span className="text-red-500 mt-1">
                  This action cannot be undone.
                </span>
              </p>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <button
              onClick={onClose}
              disabled={isDeleting}
              className={`px-3 py-1.5 text-xs rounded-md transition-colors ${
                isDarkMode 
                  ? `bg-slate-700 text-slate-300 ${!isDeleting && 'hover:bg-slate-600'} ${isDeleting && 'opacity-50 cursor-not-allowed'}` 
                  : `bg-slate-100 text-slate-700 ${!isDeleting && 'hover:bg-slate-200'} ${isDeleting && 'opacity-50 cursor-not-allowed'}`
              }`}
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              disabled={isDeleting}
              className={`
                relative px-3 py-1.5 text-xs rounded-md text-white
                transition-colors duration-200
                ${isDeleting 
                  ? 'bg-red-500 cursor-not-allowed'
                  : 'bg-red-500 hover:bg-red-600'
                }
                min-w-[60px]
              `}
            >
              <span className={isDeleting ? 'opacity-0' : 'opacity-100'}>
                Delete
              </span>
              {isDeleting && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <AnimatedLoader size={16} color="white" />
                </div>
              )}
            </button>
          </div>
        </div>
      </motion.div>
    </div>,
    document.body
  );
};

const ContentStrategyManager = () => {
  const { isDarkMode } = useTheme();
  const [strategies, setStrategies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('all');
  const [sortBy, setSortBy] = useState('date');
  const navigate = useNavigate();
  const [selectedStrategies, setSelectedStrategies] = useState(new Set());
  const [selectionMode, setSelectionMode] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    strategy: null,
    isMultiple: false
  });

  useEffect(() => {
    console.log('🔄 ContentStrategyManager mounted');
    loadStrategies();
  }, []);

  useEffect(() => {
    const debugStrategies = async () => {
      try {
        console.log('🔍 Current strategies:', {
          count: strategies.length,
          strategies: strategies
        });
        const freshStrategies = await fetchContentStrategies();
        console.log('🔄 Fresh strategies fetch:', {
          count: freshStrategies.length,
          strategies: freshStrategies
        });
      } catch (error) {
        console.error('Debug fetch error:', error);
      }
    };

    debugStrategies();
  }, [strategies]);

  const loadStrategies = async () => {
    try {
      console.log('🔄 Loading strategies - Start');
      setLoading(true);
      
      const response = await fetchContentStrategies();
      console.log('📥 Received strategies data:', {
        success: response.success,
        count: response.data?.length || 0,
        hasData: !!response.data,
        firstItem: response.data?.[0],
        ids: response.data?.map(s => s._id)
      });

      if (!response.success || !Array.isArray(response.data)) {
        console.error('❌ Invalid data format received:', response);
        throw new Error('Invalid data format received from API');
      }

      setStrategies(response.data);
      console.log('✅ Strategies set in state:', {
        count: response.data.length,
        strategyIds: response.data.map(s => s._id)
      });
    } catch (error) {
      console.error('❌ Error loading strategies:', {
        error,
        message: error.message,
        response: error.response?.data
      });
      toast.error('Failed to load strategies');
    } finally {
      setLoading(false);
      console.log('🏁 Loading strategies - Complete');
    }
  };

  const handleView = async (id) => {
    try {
      navigate(`/content-strategy/view/${id}`);
    } catch (error) {
      console.error('Error viewing strategy:', error);
    }
  };

  const handleDelete = async (strategy) => {
    try {
      setDeleteConfirmation({
        isOpen: true,
        strategy,
        isMultiple: false
      });
    } catch (error) {
      console.error('Error initiating delete:', error);
      toast.error('Failed to initiate delete process');
    }
  };

  // Add confirmation handlers
  const handleDeleteConfirm = async () => {
    try {
      if (deleteConfirmation.isMultiple) {
        // Handle bulk delete
        let successCount = 0;
        let errorCount = 0;

        for (const id of selectedStrategies) {
          try {
            await contentIdeaApi.deleteStrategy(id);
            successCount++;
          } catch (error) {
            console.error(`Error deleting strategy ${id}:`, error);
            errorCount++;
          }
        }

        if (successCount > 0) {
          toast.success(`Successfully deleted ${successCount} strategies`);
        }
        if (errorCount > 0) {
          toast.error(`Failed to delete ${errorCount} strategies`);
        }

        setSelectedStrategies(new Set());
        setSelectionMode(false);
      } else {
        // Handle single delete
        await contentIdeaApi.deleteStrategy(deleteConfirmation.strategy._id);
        toast.success('Strategy deleted successfully');
      }
      
      await loadStrategies(); // Refresh the list
    } catch (error) {
      console.error('Error deleting strategy:', error);
      toast.error('Failed to delete strategy');
    } finally {
      setDeleteConfirmation({ isOpen: false, strategy: null, isMultiple: false });
    }
  };

  // Memoized filtered and sorted strategies
  const processedStrategies = useMemo(() => {
    console.log('🔍 Filtering strategies:', {
      totalStrategies: strategies.length,
      searchTerm: search,
      sortBy
    });

    let result = strategies.filter(strategy => {
      const searchLower = search.toLowerCase();
      const matches = (
        (strategy.persona?.name || '').toLowerCase().includes(searchLower) ||
        (strategy.audience?.targetAudienceName || '').toLowerCase().includes(searchLower)
      );

      return matches;
    });

    // Then sort
    result = [...result].sort((a, b) => {
      switch (sortBy) {
        case 'date':
          return new Date(b.createdAt) - new Date(a.createdAt);
        case 'name':
          return (a.persona?.name || '').localeCompare(b.persona?.name || '');
        case 'ideas':
          const aCount = a.frameworks?.reduce((total, framework) => 
            total + (framework.ideaGroups.reduce((groupTotal, group) => 
              groupTotal + (group.ideas?.length || 0), 0)), 0
          );
          
          const bCount = b.frameworks?.reduce((total, framework) => 
            total + (framework.ideaGroups.reduce((groupTotal, group) => 
              groupTotal + (group.ideas?.length || 0), 0)), 0
          );
          
          return bCount - aCount;
        default:
          return 0;
      }
    });

    console.log('✨ Filtered results:', {
      filteredCount: result.length,
      firstResult: result[0]
    });

    return result;
  }, [strategies, search, sortBy]);

  const sortOptions = [
    { value: 'date', label: 'Latest First', icon: CalendarIcon },
    { value: 'name', label: 'By Creator', icon: UserIcon },
    { value: 'ideas', label: 'Most Ideas', icon: DocumentTextIcon }
  ];

  // Add these new handlers
  const toggleStrategySelection = (id) => {
    setSelectedStrategies(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(id)) {
        newSelection.delete(id);
      } else {
        newSelection.add(id);
      }
      return newSelection;
    });
  };

  const handleBulkDelete = () => {
    setDeleteConfirmation({
      isOpen: true,
      strategy: null,
      isMultiple: true
    });
  };

  const cancelSelection = () => {
    setSelectedStrategies(new Set());
    setSelectionMode(false);
  };

  // Add a select all function
  const handleSelectAll = () => {
    if (selectedStrategies.size === processedStrategies.length) {
      setSelectedStrategies(new Set());
    } else {
      setSelectedStrategies(new Set(processedStrategies.map(s => s._id)));
    }
  };

  return (
    <div className={`fixed inset-0 flex flex-col ${
      isDarkMode ? 'bg-[#121212]' : 'bg-white'
    }`}>
      <Helmet>
        <title>Topic Generator | Ammmplify</title>
        <meta name="description" content="Generate and manage your content topics with AI-powered insights. Create personalized content plans tailored to your audience and business goals." />
        <meta name="keywords" content="topic generator, content planning, AI topic generator, content management, content creation" />
        <meta property="og:title" content="Topic Generator | Ammmplify" />
        <meta property="og:description" content="Generate and manage your content topics with AI-powered insights. Create personalized content plans tailored to your audience and business goals." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Topic Generator | Ammmplify" />
        <meta name="twitter:description" content="Generate and manage your content topics with AI-powered insights. Create personalized content plans tailored to your audience and business goals." />
      </Helmet>

      <TopBar 
        isDarkMode={isDarkMode}
        onNewStrategy={() => navigate('/content-strategy')}
        search={search}
        setSearch={setSearch}
        sortBy={sortBy}
        setSortBy={setSortBy}
        onDeleteMultiple={() => setSelectionMode(true)}
        selectionMode={selectionMode}
        onReset={cancelSelection}
      />
      
      <main className="flex-1 min-h-0">
        <div className="h-full overflow-auto">
          <div className="w-full max-w-7xl mx-auto px-6 py-6 pb-24">
            {loading ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {[1, 2, 3].map((i) => (
                  <div 
                    key={i}
                    className={`animate-pulse rounded-lg border p-4 ${
                      isDarkMode 
                        ? 'bg-[#151515] border-[#333333]' 
                        : 'bg-slate-50/50 border-slate-200/30'
                    }`}
                  >
                    <div className="h-6 bg-slate-700/20 rounded w-3/4 mb-4" />
                    <div className="h-4 bg-slate-700/20 rounded w-1/2 mb-6" />
                    <div className="grid grid-cols-2 gap-4 mb-6">
                      <div className="h-4 bg-slate-700/20 rounded" />
                      <div className="h-4 bg-slate-700/20 rounded" />
                    </div>
                    <div className="h-8 bg-slate-700/20 rounded" />
                  </div>
                ))}
              </div>
            ) : processedStrategies.length === 0 ? (
              <div className="h-[calc(100vh-12rem)] flex items-center justify-center">
                {search ? (
                  <NoSearchResults 
                    isDarkMode={isDarkMode} 
                    search={search}
                    onClearSearch={() => setSearch('')}
                  />
                ) : (
                  <EmptyState 
                    isDarkMode={isDarkMode} 
                    onNewStrategy={() => navigate('/content-strategy')}
                  />
                )}
              </div>
            ) : (
              <motion.div 
                layout
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              >
                <AnimatePresence>
                  {processedStrategies.map(strategy => (
                    <ContentStrategyCard
                      key={strategy._id}
                      strategy={strategy}
                      isDarkMode={isDarkMode}
                      onView={handleView}
                      onDelete={handleDelete}
                      isSelected={selectedStrategies.has(strategy._id)}
                      onSelect={toggleStrategySelection}
                      selectionMode={selectionMode}
                    />
                  ))}
                </AnimatePresence>
              </motion.div>
            )}
          </div>
        </div>
      </main>

      {/* BulkActionsBar */}
      {selectionMode && selectedStrategies.size > 0 && (
        <BulkActionsBar
          selectedCount={selectedStrategies.size}
          onCancel={cancelSelection}
          onDelete={handleBulkDelete}
          isDarkMode={isDarkMode}
        />
      )}

      {/* Delete confirmation modal */}
      <DeleteConfirmationModal
        isOpen={deleteConfirmation.isOpen}
        onClose={() => setDeleteConfirmation({ isOpen: false, strategy: null, isMultiple: false })}
        onConfirm={handleDeleteConfirm}
        isDarkMode={isDarkMode}
        strategyName={deleteConfirmation.strategy?.persona?.name || 'Unnamed Strategy'}
        isMultiple={deleteConfirmation.isMultiple}
        count={selectedStrategies.size}
      />
    </div>
  );
};

export default ContentStrategyManager; 