import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format, isToday, isTomorrow, isThisWeek, isThisMonth, isPast } from 'date-fns';
import { toast } from 'react-hot-toast';
import TaskInput from './TaskInput';
import { taskApi } from '../../api';
import TaskItem from './TaskItem';
import AnimatedLoader from '../common/AnimatedLoader';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

const TaskManager = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [draggedTask, setDraggedTask] = useState(null);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);
  
  // Constants
  const MAX_RETRIES = 3;
  
  // Operation locks
  const operations = useRef(new Map()).current;
  const operationInProgress = useRef(false);
  
  // Clear operation lock
  const clearOperation = useCallback((operationId) => {
    operations.delete(operationId);
    operationInProgress.current = operations.size > 0;
  }, [operations]);

  // Set operation lock
  const setOperation = useCallback((operationId) => {
    if (operations.has(operationId)) {
      return false;
    }
    operations.set(operationId, {
      timestamp: Date.now(),
      retryCount: 0
    });
    operationInProgress.current = true;
    return true;
  }, [operations]);

  const handleError = useCallback((error, operation, operationId) => {
    console.error(`Error during ${operation}:`, error);
    
    if (error.message === 'Operation failed after multiple attempts') {
      setError('The operation failed. Please try again later.');
      toast.error('Unable to complete the operation. Please try again.');
      return;
    }
    
    if (error.response?.status === 429) {
      setError('Too many requests. Please wait a moment.');
      toast.error('Too many requests. Please try again later.');
    } else if (error.response?.status === 401) {
      setError('Session expired. Please refresh the page.');
      toast.error('Session expired. Please refresh the page.');
    } else if (!navigator.onLine) {
      setError('No internet connection.');
      toast.error('No internet connection. Changes will be saved when you\'re back online.');
    } else {
      setError(`Failed to ${operation}. Please try again.`);
      toast.error(`Failed to ${operation}. Please try again.`);
    }
  }, []);

  const retryOperation = useCallback(async (operation, operationId, ...args) => {
    const operationState = operations.get(operationId);
    if (!operationState) return null;

    if (operationState.retryCount >= MAX_RETRIES) {
      clearOperation(operationId);
      throw new Error('Operation failed after multiple attempts');
    }

    try {
      operationState.retryCount++;
      const result = await operation(...args);
      clearOperation(operationId);
      return result;
    } catch (error) {
      if (error.response?.status === 429 || !navigator.onLine) {
        await new Promise(resolve => setTimeout(resolve, 1000 * operationState.retryCount));
        return retryOperation(operation, operationId, ...args);
      }
      
      if (operationState.retryCount < MAX_RETRIES) {
        await new Promise(resolve => setTimeout(resolve, 1000 * operationState.retryCount));
        return retryOperation(operation, operationId, ...args);
      }
      
      clearOperation(operationId);
      throw error;
    }
  }, [clearOperation, operations]);

  useEffect(() => {
    loadTasks();
    
    // Add online/offline handlers
    const handleOnline = () => {
      toast.success('Back online! Syncing changes...');
      loadTasks();
    };
    
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', () => toast.error('You are offline. Changes will be saved when you\'re back online.'));
    
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', () => {});
    };
  }, []);

  const loadTasks = async () => {
    const operationId = `load_tasks_${Date.now()}`;
    if (!setOperation(operationId)) return;

    try {
      setLoading(true);
      const fetchedTasks = await retryOperation(taskApi.fetchTasks, operationId);
      if (fetchedTasks) {
        setTasks(fetchedTasks);
        setError(null);
      }
    } catch (error) {
      handleError(error, 'load tasks', operationId);
    } finally {
      setLoading(false);
      clearOperation(operationId);
    }
  };

  const addTask = async (text) => {
    const operationId = `add_task_${Date.now()}`;
    if (!setOperation(operationId)) return;

    // Optimistic update
    const tempId = Date.now().toString();
    const optimisticTask = {
      _id: tempId,
      text: text.trim(),
      completed: false,
      order: tasks.length
    };

    setTasks(prev => [...prev, optimisticTask]);
    
    try {
      const newTask = await retryOperation(taskApi.createTask, operationId, {
        text: text.trim(),
        completed: false,
        order: tasks.length
      });
      
      if (newTask) {
        setTasks(prev => prev.map(t => t._id === tempId ? newTask : t));
        toast.success('Task added successfully');
        setError(null);
      }
    } catch (error) {
      // Rollback optimistic update
      setTasks(prev => prev.filter(t => t._id !== tempId));
      handleError(error, 'add task', operationId);
    } finally {
      clearOperation(operationId);
    }
  };

  const toggleTask = async (id) => {
    const operationId = `toggle_task_${id}_${Date.now()}`;
    if (!setOperation(operationId)) return;

    const task = tasks.find(t => t._id === id);
    const originalState = task.completed;

    // Optimistic update
    setTasks(prev => prev.map(t => t._id === id ? { ...t, completed: !t.completed } : t));

    try {
      const updatedTask = await retryOperation(taskApi.updateTask, operationId, id, {
        ...task,
        completed: !task.completed,
        lastEdited: new Date().toISOString()
      });
      
      if (updatedTask) {
        setTasks(prev => prev.map(t => t._id === id ? updatedTask : t));
        toast.success(`Task marked as ${updatedTask.completed ? 'completed' : 'incomplete'}`);
        setError(null);
      }
    } catch (error) {
      // Rollback optimistic update
      setTasks(prev => prev.map(t => t._id === id ? { ...t, completed: originalState } : t));
      handleError(error, 'update task', operationId);
    } finally {
      clearOperation(operationId);
    }
  };

  const deleteTask = async (id) => {
    const operationId = `delete_${id}_${Date.now()}`;
    if (!setOperation(operationId)) return;

    const taskToRemove = tasks.find(t => t._id === id);
    setIsDeleting(true);

    // Store task for potential rollback
    const taskBackup = [...tasks];

    // Optimistic update
    setTasks(prev => prev.filter(t => t._id !== id));

    try {
      await retryOperation(taskApi.deleteTask, operationId, id);
      toast.success('Task deleted successfully');
      setTaskToDelete(null);
      setError(null);
    } catch (error) {
      // Rollback optimistic update
      setTasks(taskBackup);
      handleError(error, 'delete task', operationId);
      setTaskToDelete(null);
    } finally {
      setIsDeleting(false);
      clearOperation(operationId);
    }
  };

  const updateTaskDate = async (id, dates) => {
    const operationId = `update_date_${id}_${Date.now()}`;
    if (!setOperation(operationId)) return;

    const task = tasks.find(t => t._id === id);
    const originalDates = { startDate: task.startDate, endDate: task.endDate };

    // Optimistic update with version tracking
    setTasks(prev => prev.map(t => t._id === id ? { ...t, ...dates, version: (t.version || 0) + 1 } : t));

    try {
      const updatedTask = await retryOperation(taskApi.updateTask, operationId, id, {
        ...task,
        ...dates
      });
      if (updatedTask) {
        setTasks(prev => prev.map(t => 
          t._id === id && t.version === (task.version || 0) + 1 
            ? { ...updatedTask, version: t.version } 
            : t
        ));
        toast.success('Due date updated successfully');
        setError(null);
      }
    } catch (error) {
      // Rollback optimistic update
      setTasks(prev => prev.map(t => t._id === id ? { ...originalDates, version: task.version } : t));
      handleError(error, 'update due date', operationId);
    } finally {
      clearOperation(operationId);
    }
  };

  const updateTask = async (id, updates) => {
    const operationId = `update_task_${id}_${Date.now()}`;
    if (!setOperation(operationId)) return;

    const task = tasks.find(t => t._id === id);
    const originalTask = { ...task };

    // Optimistic update with version tracking
    setTasks(prev => prev.map(t => t._id === id ? { ...t, ...updates, version: (t.version || 0) + 1 } : t));

    try {
      const updatedTask = await retryOperation(taskApi.updateTask, operationId, id, {
        ...task,
        ...updates,
        lastEdited: new Date().toISOString()
      });
      if (updatedTask) {
        setTasks(prev => prev.map(t => 
          t._id === id && t.version === (task.version || 0) + 1 
            ? { ...updatedTask, version: t.version } 
            : t
        ));
        toast.success('Task updated successfully');
        setError(null);
      }
    } catch (error) {
      // Rollback optimistic update
      setTasks(prev => prev.map(t => t._id === id ? { ...originalTask, version: task.version } : t));
      handleError(error, 'update task', operationId);
    } finally {
      clearOperation(operationId);
    }
  };

  const handleDragStart = (task) => {
    setDraggedTask(task);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (targetTask) => {
    if (!draggedTask || draggedTask._id === targetTask._id) return;

    const oldIndex = tasks.findIndex(t => t._id === draggedTask._id);
    const newIndex = tasks.findIndex(t => t._id === targetTask._id);
    
    const newTasks = Array.from(tasks);
    const [removed] = newTasks.splice(oldIndex, 1);
    newTasks.splice(newIndex, 0, removed);

    setTasks(newTasks);
    setDraggedTask(null);

    // Update backend
    try {
      await taskApi.reorderTasks(
        newTasks.map((task, index) => ({
          id: task._id,
          order: index
        }))
      );
    } catch (error) {
      console.error('Error reordering tasks:', error);
      loadTasks(); // Reload tasks if reordering fails
    }
  };

  const handleDeleteClick = (task) => {
    setTaskToDelete(task);
  };

  const groupTasksByDate = (tasks) => {
    const groups = {
      overdue: [],
      today: [],
      tomorrow: [],
      thisWeek: [],
      thisMonth: [],
      future: [],
      noDate: []
    };

    tasks.forEach(task => {
      const date = task.endDate ? new Date(task.endDate) : null;
      
      if (!date) {
        groups.noDate.push(task);
      } else if (isPast(date) && !isToday(date)) {
        groups.overdue.push(task);
      } else if (isToday(date)) {
        groups.today.push(task);
      } else if (isTomorrow(date)) {
        groups.tomorrow.push(task);
      } else if (isThisWeek(date)) {
        groups.thisWeek.push(task);
      } else if (isThisMonth(date)) {
        groups.thisMonth.push(task);
      } else {
        groups.future.push(task);
      }
    });

    return groups;
  };

  const renderDateGroup = (tasks, title) => {
    if (!tasks || tasks.length === 0) return null;

    const isDark = document.documentElement.classList.contains('dark');

    return (
      <div key={title} className="mb-6 last:mb-0">
        <h3 className={`
          text-[11px] uppercase tracking-wider font-medium mb-2 px-1
          ${isDark ? 'text-zinc-600' : 'text-gray-400'}
          transition-colors
        `}>
          {title}
        </h3>
        <div className="space-y-1">
          {tasks.map((task) => (
            <div
              key={task._id}
              draggable
              onDragStart={() => handleDragStart(task)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(task)}
            >
              <TaskItem
                task={task}
                onToggle={toggleTask}
                onDelete={deleteTask}
                onDateChange={updateTaskDate}
                onUpdate={updateTask}
                isDragging={draggedTask?._id === task._id}
                onDeleteClick={handleDeleteClick}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderEmptyState = () => {
    const isDark = document.documentElement.classList.contains('dark');
    
    return (
      <div className="flex flex-col items-center justify-center py-12 text-center">
        <ClipboardDocumentListIcon className={`
          w-12 h-12 mb-4
          ${isDark ? 'text-zinc-700' : 'text-gray-200'}
        `} />
        <h3 className={`
          text-sm font-medium mb-1
          ${isDark ? 'text-zinc-400' : 'text-gray-600'}
        `}>
          No tasks yet
        </h3>
        <p className={`
          text-xs
          ${isDark ? 'text-zinc-600' : 'text-gray-400'}
        `}>
          Add your first task using the input above
        </p>
      </div>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center py-12">
          <AnimatedLoader 
            size={24}
            className={`text-gray-300 dark:text-zinc-700`}
          />
        </div>
      );
    }

    if (!tasks.length) {
      return renderEmptyState();
    }

    const groupedTasks = groupTasksByDate(tasks);

    return (
      <div className="space-y-6">
        {renderDateGroup(groupedTasks.overdue, 'Overdue')}
        {renderDateGroup(groupedTasks.today, 'Today')}
        {renderDateGroup(groupedTasks.tomorrow, 'Tomorrow')}
        {renderDateGroup(groupedTasks.thisWeek, 'This Week')}
        {renderDateGroup(groupedTasks.thisMonth, 'This Month')}
        {renderDateGroup(groupedTasks.future, 'Future')}
        {renderDateGroup(groupedTasks.noDate, 'No Due Date')}
      </div>
    );
  };

  return (
    <div className="max-w-[600px] mx-auto p-4">
      <div className="relative min-h-[200px]">
        {error && (
          <div className="absolute top-0 left-0 right-0 bg-red-50 dark:bg-red-900/10 p-3 rounded-lg mb-4">
            <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
          </div>
        )}
        
        <TaskInput onAdd={addTask} disabled={operationInProgress.current} />
        
        <div className="mt-6">
          {renderContent()}
        </div>

        {/* Delete Confirmation Modal */}
        {taskToDelete && (
          <div className="fixed inset-0 z-[9999] flex items-center justify-center">
            <div 
              className="absolute inset-0 bg-white/70 dark:bg-zinc-900/70 backdrop-blur-sm transition-colors"
              onClick={() => !isDeleting && setTaskToDelete(null)}
            />
            <div className="relative bg-white dark:bg-zinc-900 rounded-lg shadow-xl max-w-sm w-full mx-4 overflow-hidden border border-gray-200/50 dark:border-zinc-800/50">
              <div className="p-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 mb-2">
                  Delete Task
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                  Are you sure you want to delete this task? This action cannot be undone.
                </p>
                <div className="flex justify-end gap-3">
                  <button
                    onClick={() => setTaskToDelete(null)}
                    disabled={isDeleting}
                    className={`
                      px-4 py-2 text-sm font-medium rounded-md
                      border border-gray-200 dark:border-zinc-800
                      hover:bg-gray-50 dark:hover:bg-zinc-800
                      text-gray-700 dark:text-gray-300
                      transition-colors
                      disabled:opacity-50 disabled:cursor-not-allowed
                    `}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteTask(taskToDelete._id)}
                    disabled={isDeleting}
                    className={`
                      px-4 py-2 text-sm font-medium rounded-md
                      bg-red-500 hover:bg-red-600
                      text-white
                      transition-colors
                      disabled:opacity-50 disabled:cursor-not-allowed
                      flex items-center gap-2
                    `}
                  >
                    {isDeleting ? (
                      <>
                        <AnimatedLoader size={16} color="white" />
                        <span>Deleting...</span>
                      </>
                    ) : (
                      'Delete'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskManager; 