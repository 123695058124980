import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../api';
import { useAuth, refreshAccessToken } from './AuthContext';

const PostCommentsContext = createContext();

const INITIAL_PAGINATION = { page: 1, total: 0, pages: 1 };

export const PostCommentsProvider = ({ children }) => {
  const { user, isAuthenticated, loading: authLoading } = useAuth();

  const [commentHistory, setCommentHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isInChatInterface, setIsInChatInterface] = useState(false);
  const [postContent, setPostContent] = useState('');
  const [selectedTone, setSelectedTone] = useState('conversational');
  const [generatedComments, setGeneratedComments] = useState([]);
  const [copiedComments, setCopiedComments] = useState({});
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [selectedTargetAudience, setSelectedTargetAudience] = useState(null);
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, total: 0, pages: 1 });
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [deletingComments, setDeletingComments] = useState(new Set());
  const [commentToReply, setCommentToReply] = useState('');

  useEffect(() => {
    let mounted = true;

    const initializeContext = async () => {
      if (authLoading || !mounted) return;

      setError(null);

      if (!isAuthenticated || !user) {
        setCommentHistory([]);
        setPagination(INITIAL_PAGINATION);
        return;
      }

      try {
        setIsLoading(true);
        const data = await fetchCommentHistory();
        if (mounted && data?.comments) {
          setCommentHistory(data.comments);
          setPagination(data.pagination || INITIAL_PAGINATION);
        }
      } catch (error) {
        console.error('Error initializing context:', error);
        handleError(error);
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    initializeContext();

    return () => {
      mounted = false;
    };
  }, [user, isAuthenticated, authLoading]);

  const getAuthToken = () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('No authentication token found');
    }
    return token;
  };

  const debugAuthState = () => {
    console.log('Auth State Debug:', {
      isAuthenticated,
      userId: user?.id,
      hasToken: !!localStorage.getItem('accessToken'),
      authLoading
    });
  };

  const fetchCommentHistory = async (page = 1, limit = 10) => {
    debugAuthState();

    const token = localStorage.getItem('accessToken');
    
    if (!token) {
      console.log('No token found in localStorage');
      setError('Please log in to view comment history');
      return { comments: [], pagination: INITIAL_PAGINATION };
    }

    // If we have a token but no user object, try to continue anyway
    if (!user) {
      console.log('Warning: User object missing but token exists');
    }

    try {
      setError(null); // Clear any existing errors
      console.log('Fetching comments with token:', token.substring(0, 10) + '...');
      
      const response = await api.get('/api/post-comments/saved', {
        params: { 
          page, 
          limit
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: false
      });
      
      if (!response.data) {
        console.error('No data received from API');
        setError('Failed to load comments: No data received');
        return { comments: [], pagination: INITIAL_PAGINATION };
      }

      console.log('API Response:', {
        status: response.status,
        dataLength: response.data?.comments?.length || 0,
        pagination: response.data?.pagination,
        firstComment: response.data?.comments?.[0]
      });

      // Transform the comments to match the expected format
      const transformedComments = response.data?.comments?.map(comment => ({
        ...comment,
        _id: comment._id || comment.id,
        postContent: comment.postContent,
        comment: comment.comment,
        tone: comment.tone,
        persona: comment.persona,
        targetAudience: comment.targetAudience,
        createdAt: comment.createdAt
      })) || [];

      if (page === 1) {
        console.log('Setting initial comments:', transformedComments.length);
        setCommentHistory(transformedComments);
      } else {
        console.log('Appending comments:', transformedComments.length);
        setCommentHistory(prev => [...prev, ...transformedComments]);
      }
      
      setPagination(response.data.pagination || INITIAL_PAGINATION);
      return { 
        comments: transformedComments, 
        pagination: response.data.pagination || INITIAL_PAGINATION 
      };
    } catch (error) {
      console.error('Error in fetchCommentHistory:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data
      });

      if (error.response?.status === 401) {
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          if (refreshToken) {
            console.log('Attempting token refresh after 401...');
            const { accessToken: newToken } = await refreshAccessToken(refreshToken);
            if (newToken) {
              console.log('Token refresh successful, retrying fetch...');
              localStorage.setItem('accessToken', newToken);
              return fetchCommentHistory(page, limit);
            }
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          setError('Session expired. Please log in again.');
          return { comments: [], pagination: INITIAL_PAGINATION };
        }
      }

      const errorMessage = error.response?.data?.message || 'Failed to load comments';
      setError(errorMessage);
      return { comments: [], pagination: INITIAL_PAGINATION };
    }
  };

  const loadInitialHistory = async () => {
    try {
      const data = await fetchCommentHistory(1, 10);
      return data;
    } catch (error) {
      console.error('Error loading initial history:', error);
      setError('Failed to load comment history');
      return null;
    }
  };

  const loadMoreComments = async () => {
    if (isLoadingMore || pagination.page >= pagination.pages) return;
    
    setIsLoadingMore(true);
    setError(null);

    try {
      await fetchCommentHistory(pagination.page + 1);
    } catch (err) {
      console.error('Error loading more comments:', err);
      // Error is already handled in fetchCommentHistory
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleGenerate = async () => {
    if (!postContent.trim()) {
      setError('Please enter post content');
      return;
    }

    let abortController = new AbortController();
    setIsLoading(true);
    setError(null);

    try {
      const token = getAuthToken();
      const response = await api.post('/api/post-comments/generate', 
        {
          postContent,
          tone: selectedTone,
          persona: selectedPersona,
          targetAudience: selectedTargetAudience,
          commentToReply: commentToReply
        },
        {
          signal: abortController.signal,
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      setGeneratedComments(response.data.comments);
      return response.data.comments;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request cancelled');
        return;
      }
      handleError(error);
    } finally {
      setIsLoading(false);
    }

    return () => {
      abortController.abort();
    };
  };

  const deleteHistoryItem = async (id) => {
    const previousComments = [...commentHistory];
    
    try {
      setDeletingComments(prev => new Set([...prev, id]));
      
      // Optimistic update
      setCommentHistory(prev => prev.filter(item => item._id !== id));
      
      const token = getAuthToken();
      await api.delete(`/api/post-comments/${id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      return true;
    } catch (error) {
      // Revert on error
      setCommentHistory(previousComments);
      handleError(error);
      throw error;
    } finally {
      setDeletingComments(prev => {
        const next = new Set(prev);
        next.delete(id);
        return next;
      });
    }
  };

  const copyToClipboard = async (text, index) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedComments(prev => ({ ...prev, [index]: true }));
      setTimeout(() => {
        setCopiedComments(prev => ({ ...prev, [index]: false }));
      }, 2000);
    } catch (err) {
      console.error('Error copying to clipboard:', err);
      setError('Failed to copy to clipboard');
    }
  };

  const setContext = (inChat) => {
    setIsInChatInterface(inChat);
  };

  const checkServerConnectivity = async () => {
    try {
      await api.get('/api/health-check');
      return true;
    } catch (error) {
      console.error('Server connectivity check failed:', error);
      return false;
    }
  };

  const handleError = (error) => {
    if (!navigator.onLine) {
      setError('No internet connection. Please check your network.');
    } else if (error.response?.status === 401) {
      setError('Session expired. Please log in again.');
      // Consider adding a callback to AuthContext to handle session expiry
    } else {
      setError(error.message || 'An unexpected error occurred');
    }
  };

  const validateComment = (comment) => {
    if (!comment || typeof comment !== 'object') return false;
    if (!comment._id || typeof comment._id !== 'string') return false;
    // Add more validation as needed
    return true;
  };

  const setCommentHistoryWithValidation = (comments) => {
    if (!Array.isArray(comments)) {
      console.error('Invalid comments format');
      return;
    }

    const validComments = comments.filter(validateComment);
    setCommentHistory(validComments);
  };

  const saveToHistory = async (commentData) => {
    try {
      const response = await api.post('/api/post-comments/history', commentData);
      console.log('Saved comment response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error saving comment to history:', error);
      throw error;
    }
  };

  const value = {
    commentHistory,
    setCommentHistory,
    deleteHistoryItem,
    copyToClipboard,
    refreshHistory: fetchCommentHistory,
    fetchCommentHistory,
    isLoading,
    error,
    setError,
    isInChatInterface,
    setContext,
    postContent,
    setPostContent,
    selectedTone,
    setSelectedTone,
    generatedComments,
    setGeneratedComments,
    copiedComments,
    selectedPersona,
    setSelectedPersona,
    selectedTargetAudience,
    setSelectedTargetAudience,
    isLoadingAvatar,
    handleGenerate,
    pagination,
    loadMoreComments,
    isLoadingMore,
    deletingComments,
    isAuthenticated,
    authLoading,
    user,
    saveToHistory,
    commentToReply,
    setCommentToReply,
  };

  return (
    <PostCommentsContext.Provider value={value}>
      {children}
    </PostCommentsContext.Provider>
  );
};

export const usePostComments = () => {
  const context = useContext(PostCommentsContext);
  if (!context) {
    throw new Error('usePostComments must be used within a PostCommentsProvider');
  }
  return context;
};

export default PostCommentsContext; 