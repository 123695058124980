import React, { useState, useEffect } from 'react';
import PersonasForContentStrategy from './PersonasForContentStrategy';
import AudienceForContentStrategy from './AudienceForContentStrategy';
import { useTheme } from '../../context/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { 
  RocketLaunchIcon, 
  DocumentMagnifyingGlassIcon, 
  AdjustmentsHorizontalIcon, 
  ListBulletIcon,
  UserIcon,
  UserGroupIcon,
  SparklesIcon,
  LightBulbIcon,
  ArrowPathIcon,
  XCircleIcon,
  ArrowLeftIcon
} from '@heroicons/react/24/outline';
import '../customScrollbar.css';
import CreateButton from '../CreateButton';
import { ContentStrategyProvider } from '../../context/ContentStrategyContext';
import GeneratedIdeas from './GeneratedIdeas';
import { generateContentStrategy, saveContentStrategyIdeas } from '../../api';
import { useNavigate } from 'react-router-dom';
import { contentStrategyProgressService } from '../../services/contentStrategyProgressService';
import TopicSuggestions from './TopicSuggestions';
import toast from 'react-hot-toast';

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
│ TOPIC GENERATOR PROGRESS SERVICE
│ Handles saving, loading, and managing progress in the topic generation process
╰───────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
const topicGeneratorProgressService = {
  saveProgress: (progress) => {
    try {
      localStorage.setItem('topicGeneratorProgress', JSON.stringify(progress));
    } catch (error) {
      console.error('Error saving topic generator progress:', error);
    }
  },

  loadProgress: () => {
    try {
      const savedProgress = localStorage.getItem('topicGeneratorProgress');
      return savedProgress ? JSON.parse(savedProgress) : {};
    } catch (error) {
      console.error('Error loading topic generator progress:', error);
      return {};
    }
  },

  clearProgress: () => {
    try {
      localStorage.removeItem('topicGeneratorProgress');
    } catch (error) {
      console.error('Error clearing topic generator progress:', error);
    }
  },

  hasProgress: () => {
    return !!localStorage.getItem('topicGeneratorProgress');
  }
};

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
│ TOP BAR COMPONENT
│ Displays the header with title and icon. Stays fixed at the top of the content area.
╰───────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
const TopBar = ({ isDarkMode, handleClearProgress, onClose, isInChatInterface }) => {
  const navigate = useNavigate();
  
  return (
    <div className={`${isDarkMode ? 'bg-[#121212]/95' : 'bg-white/50'} 
      backdrop-blur-sm sticky top-0 z-10 h-12 sm:h-10 flex items-center justify-between px-2 sm:px-3 w-full border-b ${
      isDarkMode ? 'border-[#333333]' : 'border-gray-200/60'}`}>
      <div className="flex items-center flex-1 gap-2">
        <DocumentMagnifyingGlassIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
        <h2 className={`text-xs font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-800'} hidden sm:block`}>
          Topic Generator
        </h2>
        <h2 className={`text-xs font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-800'} sm:hidden`}>
          Topics
        </h2>
      </div>
      <div className="flex items-center gap-2 sm:gap-3">
        {contentStrategyProgressService.hasProgress() && (
          <button
            onClick={handleClearProgress}
            className={`px-2 py-1 text-[11px] sm:text-xs rounded-md transition-colors whitespace-nowrap ${
              isDarkMode 
                ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#202020] border border-[#333333]' 
                : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
            }`}
          >
            <span className="hidden sm:inline">Clear Progress</span>
            <span className="sm:hidden">Clear</span>
          </button>
        )}
        <button
          onClick={() => navigate('/content-strategy/manager')}
          className={`px-2 py-1 text-[11px] sm:text-xs rounded-md transition-colors whitespace-nowrap ${
            isDarkMode 
              ? 'bg-[#1a1a1a] text-slate-300 hover:bg-[#202020] border border-[#333333]' 
              : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
          }`}
        >
          <span className="hidden sm:inline">Manage Topics</span>
          <span className="sm:hidden">Manage</span>
        </button>
        {isInChatInterface && onClose && (
          <button
            onClick={onClose}
            className={`
              p-1.5 rounded-md
              transition-all duration-200
              ml-2 sm:ml-3
              ${isDarkMode
                ? 'bg-white hover:bg-gray-100 text-black'
                : 'bg-black hover:bg-gray-800 text-white'
              }
            `}
            title="Close"
          >
            <ArrowLeftIcon className="w-4 h-4" />
          </button>
        )}
      </div>
    </div>
  );
};

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
│ STEP TRACKER COMPONENT
│ Shows progress through the content strategy creation process with icons and connecting lines
╰───────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
const StepTracker = ({ currentStep, isDarkMode, onStepClick, canAccessStep }) => {
  const steps = [
    { number: 1, title: 'Profile & Audience', icon: UserGroupIcon },
    { number: 2, title: 'Select Topics', icon: AdjustmentsHorizontalIcon },
    { number: 3, title: 'Generated Ideas', icon: ListBulletIcon }
  ];

  return (
    <div className="w-full overflow-x-auto scrollbar-hide flex justify-center">
      <div className="flex items-center space-x-4 sm:space-x-8 min-w-max px-4">
        {steps.map((step, index) => {
          const StepIcon = step.icon;
          const isActive = index + 1 === currentStep;
          const isCompleted = index + 1 < currentStep;
          const isClickable = canAccessStep(index + 1);

          return (
            <button
              key={step.number}
              onClick={() => isClickable && onStepClick(index + 1)}
              disabled={!isClickable}
              className={`flex items-center ${
                isClickable ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
              }`}
            >
              <div className={`flex items-center ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
                <div className={`
                  flex items-center justify-center
                  transition-all duration-200
                  ${isActive
                    ? isDarkMode ? 'text-blue-400' : 'text-blue-500'
                    : isCompleted
                      ? isDarkMode ? 'text-green-400' : 'text-green-500'
                      : isDarkMode ? 'text-slate-600' : 'opacity-40'
                  }
                `}>
                  <StepIcon className="w-4 h-4" />
                  <span className={`ml-2 text-xs ${isActive ? 'font-medium' : 'font-normal'} hidden sm:inline`}>
                    {step.title}
                  </span>
                  <span className={`ml-2 text-xs ${isActive ? 'font-medium' : 'font-normal'} sm:hidden`}>
                    Step {step.number}
                  </span>
                </div>

                {index < steps.length - 1 && (
                  <div className={`mx-2 sm:mx-4 h-px w-4 sm:w-8 ${
                    isDarkMode 
                      ? isCompleted ? 'bg-green-400/30' : 'bg-[#333333]' 
                      : isCompleted ? 'bg-green-500/30' : 'bg-slate-200'
                  }`} />
                )}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
│ ANIMATED BACKGROUND COMPONENT
│ Provides subtle animated gradient background with dark/light mode support
╰───────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
const AnimatedBackground = ({ isDarkMode }) => {
  const orbVariants = {
    animate: {
      scale: [1, 1.05, 1],
      opacity: [0.3, 0.4, 0.3],
      transition: {
        duration: 12,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  return (
    <div className="absolute inset-0 pointer-events-none overflow-hidden">
      {/* Animated gradient orb */}
      <motion.div
        variants={orbVariants}
        animate="animate"
        className={`absolute top-0 left-1/3 w-[800px] h-[800px] rounded-full blur-3xl
          ${isDarkMode 
            ? 'bg-gradient-to-br from-[#151515]/30 to-[#121212]/30' 
            : 'bg-gradient-to-br from-slate-100/50 to-slate-200/50'
          }`}
      />
      
      {/* Base gradient overlay */}
      <div className={`absolute inset-0 ${
        isDarkMode 
          ? 'bg-gradient-to-b from-[#121212] via-[#121212]/95 to-[#121212]'
          : 'bg-gradient-to-b from-white via-white/95 to-white'
      }`} />
    </div>
  );
};

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
│ MAIN TOPIC GENERATOR COMPONENT
│ Orchestrates the entire topic generation interface
╰───────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
const TopicGenerator = ({ onClose, isInChatInterface }) => {
  // State management
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const { isDarkMode } = useTheme();
  const [currentStep, setCurrentStep] = useState(1);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedIdeas, setGeneratedIdeas] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);

  /*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
  │ Animation Variants
  │ Defines all animation configurations for various elements
  ╰───────────────────────────────────────────────────────────────────────────────────────────────────────────────────*/
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };

  const sparkleVariants = {
    animate: {
      scale: [1, 1.2, 1],
      rotate: [0, 5, -5, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        repeatType: "reverse"
      }
    }
  };

  const buttonVariants = {
    tap: { 
      scale: 0.98,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 10
      }
    }
  };

  const iconVariants = {
    initial: { y: 0, rotate: 0 },
    hover: {
      y: [-2, 2, -2],
      rotate: [-5, 5, -5],
      transition: {
        y: { repeat: Infinity, duration: 2.5, ease: "easeInOut" },
        rotate: { repeat: Infinity, duration: 3, ease: "easeInOut" }
      }
    }
  };

  const shimmerVariants = {
    initial: { x: "-100%" },
    hover: {
      x: "200%",
      transition: { repeat: Infinity, duration: 2, ease: "linear" }
    }
  };

  // Generation state
  const canGenerate = selectedPersona && selectedAudience;

  // Function to handle moving to preferences step
  const handleStartGenerating = () => {
    if (canGenerate) {
      setCurrentStep(2);
    }
  };

  // Function to handle preferences submission
  const handlePreferencesSubmit = async (topics) => {
    try {
      setIsGenerating(true);
      setCurrentStep(3);
      setSelectedTopics(topics); // Save selected topics

      console.log('Generating content for topics:', topics);

      if (!selectedPersona || !selectedAudience) {
        throw new Error('Missing persona or audience information');
      }

      const response = await generateContentStrategy(
        selectedPersona,
        selectedAudience,
        {
          selectedTopics: topics
        }
      );

      console.log('Generated content response:', response);

      // Check for success and data structure
      if (!response?.success) {
        throw new Error('Content generation failed');
      }

      if (!response?.data?.topics || !response?.data?.generatedIdeas) {
        throw new Error('Invalid response format from content generation');
      }

      // Transform the response to match the expected format
      const newGeneratedIdeas = {
        data: {
          topics: response.data.topics,
          generatedIdeas: response.data.generatedIdeas.map(topicIdeas => ({
            topic: topicIdeas.topic,
            ideas: topicIdeas.ideas.map(idea => ({
              angle: idea.angle || '',
              hook: idea.hook || '',
              content: idea.content || '',
              value: idea.value || '',
              expertiseAlignment: idea.expertiseAlignment || ''
            }))
          }))
        }
      };

      // Save the generated content strategy
      await saveContentStrategyIdeas({
        topics: response.data.topics,
        generatedIdeas: response.data.generatedIdeas,
        selectedTopics: topics,
        persona: selectedPersona,
        audience: selectedAudience
      });

      setGeneratedIdeas(newGeneratedIdeas);
      
      // Immediately save to progress service
      contentStrategyProgressService.saveProgress({
        selectedPersona,
        selectedAudience,
        generatedIdeas: newGeneratedIdeas,
        currentStep: 3,
        selectedTopics: topics
      });

    } catch (error) {
      console.error('Error generating content:', error);
      toast.error(error.message || 'Failed to generate content');
      setCurrentStep(2);
    } finally {
      setIsGenerating(false);
    }
  };

  const canAccessStep = (stepNumber) => {
    if (stepNumber === 1) return true;
    if (stepNumber === 2) return selectedPersona && selectedAudience;
    if (stepNumber === 3) return generatedIdeas !== null;
    return false;
  };

  const handleStepClick = (stepNumber) => {
    if (canAccessStep(stepNumber)) {
      setCurrentStep(stepNumber);
    }
  };

  // Load saved progress on mount
  useEffect(() => {
    const loadSavedProgress = () => {
      const progress = contentStrategyProgressService.loadProgress();
      
      if (progress.selectedPersona) {
        setSelectedPersona(progress.selectedPersona);
      }
      if (progress.selectedAudience) {
        setSelectedAudience(progress.selectedAudience);
      }
      if (progress.generatedIdeas?.data?.topics) {
        setGeneratedIdeas(progress.generatedIdeas);
        // If we have generated ideas, make sure we're on step 3
        setCurrentStep(3);
      }
      if (progress.selectedTopics) {
        setSelectedTopics(progress.selectedTopics);
      }
      // Only set the current step if we don't have generated ideas
      // This ensures we stay on step 3 if we have ideas
      if (progress.currentStep && !progress.generatedIdeas?.data?.topics) {
        setCurrentStep(progress.currentStep);
      }
    };

    loadSavedProgress();
  }, []);

  // Save progress when important state changes
  useEffect(() => {
    contentStrategyProgressService.saveProgress({
      selectedPersona,
      selectedAudience,
      generatedIdeas,
      currentStep,
      selectedTopics
    });
  }, [selectedPersona, selectedAudience, generatedIdeas, currentStep, selectedTopics]);

  // Add clear progress function with proper cleanup
  const handleClearProgress = () => {
    contentStrategyProgressService.clearProgress();
    setSelectedPersona(null);
    setSelectedAudience(null);
    setGeneratedIdeas(null);
    setSelectedTopics([]);
    setCurrentStep(1);
  };

  // Render appropriate content based on current step
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            {/* Main Content Card - Mobile optimized */}
            <div className={`
              relative 
              px-3 sm:px-10 py-8 sm:py-10
              rounded-lg sm:rounded-xl
              mx-auto 
              ${isDarkMode 
                ? 'bg-[#151515]/50 border border-[#252525]'
                : 'bg-white/50 border border-slate-100'
              }
              backdrop-blur-sm
              w-[calc(100%-16px)] sm:w-full sm:max-w-2xl
            `}>
              {/* Enhanced gradient background - more subtle */}
              <div className="absolute inset-0 rounded-xl overflow-hidden">
                <div className={`
                  absolute inset-0 
                  ${isDarkMode
                    ? 'bg-gradient-to-br from-blue-500/[0.02] via-purple-500/[0.02] to-pink-500/[0.02]'
                    : 'bg-gradient-to-br from-blue-50/20 via-slate-50/20 to-white/20'
                  }
                `} />
              </div>

              {/* Content Container with refined text styling */}
              <div className="relative">
                <div className={`
                  flex flex-wrap items-center justify-center
                  text-[13px] sm:text-base
                  leading-relaxed
                  gap-x-1 sm:gap-x-1.5 gap-y-2 sm:gap-y-2.5
                  ${isDarkMode 
                    ? 'text-slate-400'
                    : 'text-slate-600'
                  }
                `}>
                  {/* First segment with framework context */}
                  <motion.div 
                    className="flex items-center gap-0.5 sm:gap-1"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    <span>Generate</span>
                    <span>content</span>
                    <span>topics</span>
                  </motion.div>

                  <motion.div
                    className="flex items-center gap-0.5 sm:gap-1"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                  >
                    <span className={`
                      font-medium
                      ${isDarkMode 
                        ? 'bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400'
                        : 'bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500'
                      } 
                      text-transparent bg-clip-text
                      animate-gradient
                    `}>
                      hyper-personalized
                    </span>
                    <span>for</span>
                  </motion.div>

                  {/* Persona Selection */}
                  <motion.div 
                    className="mx-1 sm:mx-1.5 inline-flex items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.6 }}
                  >
                    <PersonasForContentStrategy
                      onSelectPersona={setSelectedPersona}
                      selectedPersona={selectedPersona}
                    />
                  </motion.div>

                  <motion.div
                    className="flex items-center gap-0.5 sm:gap-1"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.8 }}
                  >
                    <span>to</span>
                    <span>engage</span>
                  </motion.div>

                  {/* Audience Selection */}
                  <motion.div 
                    className="mx-1 sm:mx-1.5 inline-flex items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                  >
                    <AudienceForContentStrategy
                      onSelectAudience={setSelectedAudience}
                      selectedAudience={selectedAudience}
                    />
                  </motion.div>

                  {/* Framework context */}
                  <motion.div
                    className="flex items-center gap-0.5 sm:gap-1"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1.2 }}
                  >
                    <span>with</span>
                    <span>AI</span>
                    <span>magic</span>
                  </motion.div>
                </div>
              </div>
            </div>

            {/* Minimalistic Centered Tip - Mobile optimized */}
            <motion.div
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ 
                delay: 1.4,
                duration: 0.6,
                ease: "easeOut"
              }}
              className="mt-3 sm:mt-4 flex justify-center max-w-xl mx-auto w-full px-1"
            >
              <div className="flex flex-col items-center gap-2 group text-center">
                <span className={`
                  text-[10px] sm:text-[11px]
                  transition-colors
                  duration-200
                  ${isDarkMode 
                    ? 'text-slate-500 group-hover:text-slate-400' 
                    : 'text-slate-400 group-hover:text-slate-500'
                  }
                `}>
                  You'll be able to customize content types and select specific topics in the next steps
                </span>
              </div>
            </motion.div>
          </>
        );

      case 2:
        return (
          <div className="overflow-y-auto custom-scrollbar">
            <TopicSuggestions 
              onSubmit={handlePreferencesSubmit}
              loading={isGenerating}
              persona={selectedPersona}
              audience={selectedAudience}
              selectedTopics={selectedTopics}
              setSelectedTopics={setSelectedTopics}
            />
          </div>
        );

      case 3:
        console.log('Rendering GeneratedIdeas with:', {
          hasIdeas: !!generatedIdeas,
          selectedPersona,
          selectedAudience,
          personaId: selectedPersona?._id,
          audienceId: selectedAudience?._id
        });
        
        return (
          <GeneratedIdeas 
            ideas={generatedIdeas}
            loading={isGenerating}
            selectedPersona={selectedPersona}
            selectedAudience={selectedAudience}
          />
        );

      default:
        return null;
    }
  };

  // Add a more engaging progress indicator
  const ProgressIndicator = ({ currentStep, totalSteps }) => (
    <div className="absolute -top-3 left-0 right-0 flex justify-center">
      <div className={`
        px-3 py-1.5 rounded-full 
        flex items-center gap-2
        ${isDarkMode 
          ? 'bg-[#1a1a1a] border border-[#333333]' 
          : 'bg-white border border-slate-200 shadow-sm'
        }
      `}>
        <motion.div
          className="flex items-center gap-1"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <SparklesIcon className="w-4 h-4 text-blue-500" />
          <span className={`text-xs font-medium ${
            isDarkMode ? 'text-slate-300' : 'text-slate-700'
          }`}>
            Step {currentStep} of {totalSteps}
          </span>
        </motion.div>
        <div className="flex items-center gap-1">
          {[...Array(totalSteps)].map((_, i) => (
            <motion.div
              key={i}
              className={`w-1.5 h-1.5 rounded-full ${
                i + 1 <= currentStep
                  ? 'bg-blue-500'
                  : isDarkMode 
                    ? 'bg-[#333333]' 
                    : 'bg-slate-200'
              }`}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: i * 0.1 }}
            />
          ))}
        </div>
      </div>
    </div>
  );

  // Add contextual help component
  const ContextualTip = ({ step }) => {
    const tips = {
      1: {
        icon: LightBulbIcon,
        text: "Start by selecting your content creator persona and target audience"
      },
      2: {
        icon: SparklesIcon,
        text: "Choose topics that align with your content goals"
      },
      3: {
        icon: RocketLaunchIcon,
        text: "Review and customize your generated topics"
      }
    };

    const { icon: Icon, text } = tips[step];

    return (
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className={`
          mt-4 p-3 rounded-lg
          flex items-center gap-2
          ${isDarkMode 
            ? 'bg-[#1a1a1a] border border-[#333333]' 
            : 'bg-blue-50 border border-blue-100'
          }
        `}
      >
        <Icon className={`w-4 h-4 ${
          isDarkMode ? 'text-blue-400' : 'text-blue-500'
        }`} />
        <span className={`text-xs ${
          isDarkMode ? 'text-slate-300' : 'text-slate-600'
        }`}>
          {text}
        </span>
      </motion.div>
    );
  };

  // Update BottomBar to include back buttons
  const BottomBar = () => {
    const getActionButtons = () => {
      const buttons = [];

      // Add back button for steps 2 and 3
      if (currentStep > 1) {
        buttons.push(
          <motion.button
            key="back"
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setCurrentStep(currentStep - 1)}
            className={`
              px-2 sm:px-3 py-1.5 sm:py-2 rounded-lg
              font-medium inline-flex items-center justify-center
              transition-all duration-300 border mr-2
              text-[11px] sm:text-xs
              ${isDarkMode
                ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border-[#333333]'
                : 'bg-white text-slate-700 hover:bg-slate-50 border-slate-200'
              }
            `}
          >
            <ArrowLeftIcon className="w-3.5 h-3.5" />
            <span className="ml-1">Back</span>
          </motion.button>
        );
      }

      // Add main action button based on current step
      switch (currentStep) {
        case 1:
          buttons.push(
            <motion.button
              key="next"
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setCurrentStep(2)}
              disabled={!selectedPersona || !selectedAudience}
              className={`
                group relative overflow-hidden
                px-2 sm:px-3 py-1.5 sm:py-2 rounded-lg
                font-medium inline-flex items-center justify-center
                transition-all duration-300 border
                text-[11px] sm:text-xs
                ${selectedPersona && selectedAudience
                  ? isDarkMode
                    ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border-[#333333]'
                    : 'bg-gradient-to-r from-slate-800 to-slate-900 text-white hover:from-slate-700 hover:to-slate-800'
                  : isDarkMode
                    ? 'bg-[#151515] text-slate-600 cursor-not-allowed border-[#333333]'
                    : 'bg-slate-100/50 text-slate-400 cursor-not-allowed'
                }
              `}
            >
              <div className="flex items-center justify-center relative z-10 space-x-1.5">
                <RocketLaunchIcon className="w-3.5 h-3.5" />
                <span className="font-medium hidden sm:inline">Next: Topics</span>
                <span className="font-medium sm:hidden">Next</span>
              </div>
              <motion.div
                className="absolute inset-0 overflow-hidden"
                initial={{ x: '-100%' }}
                whileHover={{ x: '200%' }}
                transition={{ duration: 1.5, repeat: Infinity, ease: 'linear' }}
              >
                <div className={`w-full h-full absolute inset-0 bg-gradient-to-r 
                  ${isDarkMode 
                    ? 'from-transparent via-white/5 to-transparent' 
                    : 'from-transparent via-white/10 to-transparent'
                  } -skew-x-45`}
                />
              </motion.div>
            </motion.button>
          );
          break;

        case 2:
          buttons.push(
            <motion.button
              key="generate"
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => handlePreferencesSubmit(selectedTopics)}
              disabled={selectedTopics.length === 0 || isGenerating}
              className={`
                group relative overflow-hidden
                px-2 sm:px-3 py-1.5 sm:py-2 rounded-lg
                font-medium inline-flex items-center justify-center
                transition-all duration-300 border
                text-[11px] sm:text-xs
                ${selectedTopics.length > 0 && !isGenerating
                  ? isDarkMode
                    ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border-[#333333]'
                    : 'bg-gradient-to-r from-slate-800 to-slate-900 text-white hover:from-slate-700 hover:to-slate-800'
                  : isDarkMode
                    ? 'bg-[#151515] text-slate-600 cursor-not-allowed border-[#333333]'
                    : 'bg-slate-100/50 text-slate-400 cursor-not-allowed'
                }
              `}
            >
              <div className="flex items-center justify-center relative z-10 space-x-1.5">
                {isGenerating ? (
                  <>
                    <ArrowPathIcon className="w-3.5 h-3.5 animate-spin" />
                    <span className="hidden sm:inline">Generating...</span>
                    <span className="sm:hidden">...</span>
                  </>
                ) : (
                  <>
                    <RocketLaunchIcon className="w-3.5 h-3.5" />
                    <span className="font-medium hidden sm:inline">Generate ({selectedTopics.length})</span>
                    <span className="font-medium sm:hidden">Gen ({selectedTopics.length})</span>
                  </>
                )}
              </div>
              <motion.div
                className="absolute inset-0 overflow-hidden"
                initial={{ x: '-100%' }}
                animate={{ x: '200%' }}
                transition={{ duration: 2, repeat: Infinity, ease: 'linear' }}
              >
                <div className={`w-full h-full absolute inset-0 bg-gradient-to-r 
                  ${isDarkMode 
                    ? 'from-transparent via-white/5 to-transparent' 
                    : 'from-transparent via-white/10 to-transparent'
                  } -skew-x-45`}
                />
              </motion.div>
            </motion.button>
          );
          break;

        default:
          break;
      }

      return buttons;
    };

    return (
      <div className={`fixed bottom-0 left-0 right-0 z-50 
        ${isDarkMode ? 'bg-[#121212]/90' : 'bg-white/90'} 
        backdrop-blur-sm border-t 
        ${isDarkMode ? 'border-[#333333]' : 'border-slate-200'}`}
      >
        <div className="max-w-4xl mx-auto px-2 py-2 flex items-center">
          {/* Left Side: Selected Topics Pill */}
          <div className="flex-1 min-w-0">
            {currentStep === 2 && (
              <div 
                className={`
                  inline-flex px-2 py-1 rounded-lg
                  items-center gap-1.5
                  ${selectedTopics.length > 0 
                    ? isDarkMode 
                      ? 'bg-white text-slate-900' 
                      : 'bg-slate-900 text-white'
                    : isDarkMode
                      ? 'bg-[#1a1a1a] text-slate-400'
                      : 'bg-slate-100 text-slate-500'
                  }
                  transition-colors duration-200
                  text-[10px] sm:text-xs
                `}
              >
                <div className="flex items-center gap-1.5 truncate">
                  <span className="font-medium truncate hidden sm:inline">Topics</span>
                  <AnimatePresence mode="wait">
                    <motion.div
                      key={selectedTopics.length}
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.5, opacity: 0 }}
                      className={`px-1.5 py-0.5 rounded text-[10px] font-medium
                        ${selectedTopics.length > 0
                          ? isDarkMode 
                            ? 'bg-slate-900 text-white' 
                            : 'bg-white text-slate-900'
                          : isDarkMode
                            ? 'bg-[#252525] text-slate-400'
                            : 'bg-slate-200 text-slate-500'
                        }
                        transition-colors duration-200
                      `}
                    >
                      {selectedTopics.length}
                    </motion.div>
                  </AnimatePresence>
                </div>
                {selectedTopics.length > 0 && (
                  <button
                    onClick={() => setSelectedTopics([])}
                    className={`p-0.5 rounded transition-colors
                      ${isDarkMode 
                        ? 'hover:bg-slate-100' 
                        : 'hover:bg-slate-800'
                      }
                    `}
                  >
                    <XCircleIcon className="w-3.5 h-3.5" />
                  </button>
                )}
              </div>
            )}
          </div>

          {/* Right Side: Action Buttons */}
          <div className="flex justify-end">
            {getActionButtons()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ContentStrategyProvider>
      <div className="h-full w-full flex flex-col">
        <TopBar 
          isDarkMode={isDarkMode} 
          handleClearProgress={handleClearProgress}
          onClose={onClose}
          isInChatInterface={isInChatInterface}
        />
        
        {/* Steps tracker - Make it fixed */}
        <div className={`w-full sticky top-0 z-10 
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white'} 
          pt-4 sm:pt-6 pb-3 sm:pb-3
          flex items-center justify-center`}
        >
          <div className="w-full max-w-4xl">
            <StepTracker 
              currentStep={currentStep} 
              isDarkMode={isDarkMode}
              onStepClick={handleStepClick}
              canAccessStep={canAccessStep}
            />
          </div>
        </div>

        {/* Main scrollable content area */}
        <div className={`flex-1 min-h-0 ${isDarkMode ? 'bg-[#121212]' : 'bg-white'} relative`}>
          <AnimatedBackground isDarkMode={isDarkMode} />
          
          <div className="h-full overflow-y-auto">
            {/* Content wrapper */}
            <div className="w-full max-w-4xl mx-auto px-2 sm:px-6 lg:px-8 pb-20">
              {currentStep === 3 ? (
                // For step 3 (Generated Ideas), don't add extra wrappers
                <motion.div 
                  initial="hidden"
                  animate="visible"
                  variants={containerVariants}
                  className="w-full"
                >
                  {renderStepContent()}
                </motion.div>
              ) : (
                // For other steps, keep the centered layout
                <div className="min-h-[calc(100vh-200px)] flex items-center">
                  <motion.div 
                    initial="hidden"
                    animate="visible"
                    variants={containerVariants}
                    className="w-full py-4 sm:py-8"
                  >
                    {renderStepContent()}
                  </motion.div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <BottomBar />
      </div>
      <Helmet>
        <title>Topic Generator | Ammmplify</title>
        <meta name="description" content="Generate and manage your content topics with AI-powered insights. Create personalized topic plans tailored to your audience and business goals." />
        <meta name="keywords" content="topic generator, content planning, AI topic generator, content management, content creation" />
        <meta property="og:title" content="Topic Generator | Ammmplify" />
        <meta property="og:description" content="Generate and manage your content topics with AI-powered insights. Create personalized topic plans tailored to your audience and business goals." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Topic Generator | Ammmplify" />
        <meta name="twitter:description" content="Generate and manage your content topics with AI-powered insights. Create personalized topic plans tailored to your audience and business goals." />
      </Helmet>
    </ContentStrategyProvider>
  );
};

export default TopicGenerator; 