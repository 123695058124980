// API Configuration
export const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'https://api.ammmplify.com'
  : (process.env.REACT_APP_API_URL || 'http://localhost:3001');

// File Upload Configuration
export const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
export const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'video/mp4',
  'application/pdf'
]; 