import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  XMarkIcon, 
  SparklesIcon, 
  CheckIcon, 
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  GlobeAltIcon,
  ChartBarIcon,
  ClipboardIcon,
  ClipboardCheckIcon
} from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from '../../context/ThemeContext';
import api from '../../api';
import { motion } from 'framer-motion';
import SkeletonLoader from '../common/SkeletonLoader';

// Add RedditIcon component
const RedditIcon = ({ isDarkMode, className = "w-4 h-4", showBackground = true }) => (
  <svg 
    className={`${className} ${!showBackground && isDarkMode ? 'text-orange-300' : ''}`}
    viewBox="0 0 256 256"
  >
    {showBackground && (
      <circle 
        cx="128" 
        cy="128" 
        r="128" 
        className={isDarkMode ? 'fill-orange-500/20' : 'fill-orange-500'} 
      />
    )}
    <path 
      className={showBackground 
        ? isDarkMode 
          ? 'fill-orange-300' // Lighter color for dark mode with background
          : 'fill-white'      // White for light mode with background
        : 'fill-current'      // Use current color when no background
      }
      d="M213.15 129.22c0-10.376-8.391-18.617-18.617-18.617a18.74 18.74 0 0 0-12.97 5.189c-12.818-9.157-30.368-15.107-49.9-15.87l8.544-39.981l27.773 5.95c.307 7.02 6.104 12.667 13.278 12.667c7.324 0 13.275-5.95 13.275-13.278c0-7.324-5.95-13.275-13.275-13.275c-5.188 0-9.768 3.052-11.904 7.478l-30.976-6.562c-.916-.154-1.832 0-2.443.458c-.763.458-1.22 1.22-1.371 2.136l-9.464 44.558c-19.837.612-37.692 6.562-50.662 15.872a18.74 18.74 0 0 0-12.971-5.188c-10.377 0-18.617 8.391-18.617 18.617c0 7.629 4.577 14.037 10.988 16.939a33.6 33.6 0 0 0-.458 5.646c0 28.686 33.42 52.036 74.621 52.036c41.202 0 74.622-23.196 74.622-52.036a35 35 0 0 0-.458-5.646c6.408-2.902 10.985-9.464 10.985-17.093M85.272 142.495c0-7.324 5.95-13.275 13.278-13.275c7.324 0 13.275 5.95 13.275 13.275s-5.95 13.278-13.275 13.278c-7.327.15-13.278-5.953-13.278-13.278m74.317 35.251c-9.156 9.157-26.553 9.768-31.588 9.768c-5.188 0-22.584-.765-31.59-9.768c-1.371-1.373-1.371-3.51 0-4.883c1.374-1.371 3.51-1.371 4.884 0c5.8 5.8 18.008 7.782 26.706 7.782s21.058-1.983 26.704-7.782c1.374-1.371 3.51-1.371 4.884 0c1.22 1.373 1.22 3.51 0 4.883m-2.443-21.822c-7.325 0-13.275-5.95-13.275-13.275s5.95-13.275 13.275-13.275c7.327 0 13.277 5.95 13.277 13.275c0 7.17-5.95 13.275-13.277 13.275"
    />
  </svg>
);

const processHighPotentialThemes = (content) => {
  const themes = [];
  let currentTheme = null;
  let currentPoints = [];

  content.forEach(line => {
    const trimmedLine = line.trim();
    
    // Theme title line (starts with number)
    if (/^\d+\./.test(trimmedLine)) {
      // Save previous theme's points
      if (currentTheme && currentPoints.length > 0) {
        themes.push(...currentPoints);
      }
      currentTheme = trimmedLine;
      currentPoints = [];
    } 
    // Content Angles line
    else if (trimmedLine.startsWith('Content Angles')) {
      // Skip this line as it's just a header
      return;
    }
    // Quick Stats line
    else if (trimmedLine.startsWith('Quick Stats:')) {
      // Skip this line as it's just a header
      return;
    }
    // Actual content points (start with dash or bullet)
    else if (trimmedLine.startsWith('-') || trimmedLine.startsWith('•')) {
      const point = trimmedLine
        .replace(/^[-•]\s*/, '')  // Remove bullet
        .split(/\.\s+/);  // Split at first period to separate angle from explanation
      
      // If there's an explanation, format it nicely
      const formattedPoint = point.length > 1
        ? `${point[0]}. ${point.slice(1).join('. ')}`
        : point[0];
      
      currentPoints.push(formattedPoint);
    }
  });

  // Add the last theme's points
  if (currentPoints.length > 0) {
    themes.push(...currentPoints);
  }

  return themes;
};

const processQuickWinIdeas = (content) => {
  const ideas = [];
  let currentIdea = null;
  let currentPoints = [];

  content.forEach(line => {
    const trimmedLine = line.trim();
    
    // Idea title line (starts with number)
    if (/^\d+\./.test(trimmedLine)) {
      // Save previous idea's points
      if (currentIdea && currentPoints.length > 0) {
        // Combine the points into a single formatted string
        ideas.push(`${currentIdea}: ${currentPoints.join(' | ')}`);
      }
      currentIdea = trimmedLine.replace(/^\d+\.\s*/, '');
      currentPoints = [];
    }
    // Hook or Source lines
    else if (trimmedLine.startsWith('-')) {
      const [label, value] = trimmedLine.replace(/^-\s*/, '').split(':').map(s => s.trim());
      if (value) {
        currentPoints.push(`${label}: ${value}`);
      }
    }
  });

  // Add the last idea
  if (currentIdea && currentPoints.length > 0) {
    ideas.push(`${currentIdea}: ${currentPoints.join(' | ')}`);
  }

  return ideas;
};

const processViralOpportunities = (content) => {
  const opportunities = [];
  let currentOpportunity = null;
  let currentPoints = {
    title: '',
    hook: '',
    keyPoints: [],
    supportingData: ''
  };

  content.forEach(line => {
    const trimmedLine = line.trim();
    
    // Opportunity title line (starts with number)
    if (/^\d+\./.test(trimmedLine)) {
      // Save previous opportunity if it exists
      if (currentOpportunity && currentPoints.title) {
        const formattedContent = [
          `${currentPoints.title}`,
          '',  // Add empty line after title
          currentPoints.hook ? `🎯 ${currentPoints.hook}` : '',
          currentPoints.hook ? '' : null,  // Add empty line after hook if it exists
          currentPoints.keyPoints.length > 0 ? `📌 Key Points:` : '',
          currentPoints.keyPoints.length > 0 ? currentPoints.keyPoints.map(point => `   • ${point}`).join('\n') : '',
          currentPoints.keyPoints.length > 0 ? '' : null,  // Add empty line after key points if they exist
          currentPoints.supportingData ? `📊 ${currentPoints.supportingData}` : ''
        ].filter(Boolean).join('\n');
        
        opportunities.push(formattedContent);
      }
      
      // Reset for new opportunity
      currentOpportunity = trimmedLine;
      currentPoints = {
        title: trimmedLine.replace(/^\d+\.\s*/, ''),
        hook: '',
        keyPoints: [],
        supportingData: ''
      };
    }
    // Handle points that start with a dash
    else if (trimmedLine.startsWith('-')) {
      const [label, value] = trimmedLine.replace(/^-\s*/, '').split(':').map(s => s.trim());
      if (!value) return;

      switch (label.toLowerCase()) {
        case 'hook':
          currentPoints.hook = `Hook: ${value}`;
          break;
        case 'supporting data':
          currentPoints.supportingData = `Supporting Data: ${value}`;
          break;
        case 'key points':
          // Skip the "Key Points:" header as we'll add it later
          break;
        default:
          // Handle any other labeled points
          currentPoints.keyPoints.push(`${label}: ${value}`);
      }
    }
    // Handle bullet points under Key Points
    else if (trimmedLine.startsWith('•')) {
      const point = trimmedLine.replace(/^•\s*/, '').trim();
      if (point) {
        currentPoints.keyPoints.push(point);
      }
    }
  });

  // Add the last opportunity if it exists
  if (currentOpportunity && currentPoints.title) {
    const formattedContent = [
      `${currentPoints.title}`,
      '',  // Add empty line after title
      currentPoints.hook ? `🎯 ${currentPoints.hook}` : '',
      currentPoints.hook ? '' : null,  // Add empty line after hook if it exists
      currentPoints.keyPoints.length > 0 ? `📌 Key Points:` : '',
      currentPoints.keyPoints.length > 0 ? currentPoints.keyPoints.map(point => `   • ${point}`).join('\n') : '',
      currentPoints.keyPoints.length > 0 ? '' : null,  // Add empty line after key points if they exist
      currentPoints.supportingData ? `📊 ${currentPoints.supportingData}` : ''
    ].filter(Boolean).join('\n');
    
    opportunities.push(formattedContent);
  }

  return opportunities;
};

const processTrendingDiscussions = (content) => {
  const discussions = [];
  let currentDiscussion = null;
  let currentPoints = {
    title: '',
    engagement: '',
    angles: []
  };

  content.forEach(line => {
    const trimmedLine = line.trim();
    
    // Discussion title line (starts with number)
    if (/^\d+\./.test(trimmedLine)) {
      // Save previous discussion if it exists
      if (currentDiscussion && currentPoints.title) {
        const formattedContent = [
          `${currentPoints.title}${currentPoints.engagement ? ` (${currentPoints.engagement})` : ''}`,
          '',  // Add empty line after title
          `📊 Content Angles:`,
          currentPoints.angles.map(angle => `   • ${angle}`).join('\n')
        ].filter(Boolean).join('\n');
        
        discussions.push(formattedContent);
      }
      
      // Reset for new discussion
      currentDiscussion = trimmedLine;
      // Extract title and engagement if present
      const match = trimmedLine.match(/^(\d+\.\s*)(.+?)(?:\s*\(Engagement:\s*([^)]+)\))?$/);
      currentPoints = {
        title: match ? match[2].trim() : trimmedLine.replace(/^\d+\.\s*/, ''),
        engagement: match ? match[3] : '',
        angles: []
      };
    }
    // Handle points that start with a dash
    else if (trimmedLine.startsWith('-')) {
      const point = trimmedLine.replace(/^-\s*/, '').trim();
      if (point) {
        currentPoints.angles.push(point);
      }
    }
  });

  // Add the last discussion if it exists
  if (currentDiscussion && currentPoints.title) {
    const formattedContent = [
      `${currentPoints.title}${currentPoints.engagement ? ` (${currentPoints.engagement})` : ''}`,
      '',  // Add empty line after title
      `📊 Content Angles:`,
      currentPoints.angles.map(angle => `   • ${angle}`).join('\n')
    ].filter(Boolean).join('\n');
    
    discussions.push(formattedContent);
  }

  return discussions;
};

const processEngagementInsights = (content) => {
  const insights = [];
  let currentTitle = '';
  let currentPoints = [];

  content.forEach(line => {
    const trimmedLine = line.trim();
    
    // Title line (starts with number)
    if (/^\d+\./.test(trimmedLine)) {
      // Save previous insight if it exists
      if (currentTitle && currentPoints.length > 0) {
        insights.push({
          type: 'header',
          content: currentTitle
        });
        currentPoints.forEach(point => {
          insights.push({
            type: 'point',
            content: point
          });
        });
        // Add empty line after each section
        insights.push({
          type: 'spacer',
          content: ''
        });
      }
      
      // Start new insight
      currentTitle = trimmedLine.replace(/^\d+\.\s*/, '');
      currentPoints = [];
    }
    // Handle points that start with a dash or bullet
    else if (trimmedLine.startsWith('-') || trimmedLine.startsWith('•')) {
      const point = trimmedLine.replace(/^[-•]\s*/, '').trim();
      if (point) {
        currentPoints.push(point);
      }
    }
  });

  // Add the last insight if it exists
  if (currentTitle && currentPoints.length > 0) {
    insights.push({
      type: 'header',
      content: currentTitle
    });
    currentPoints.forEach(point => {
      insights.push({
        type: 'point',
        content: point
      });
    });
  }

  return insights;
};

const YouIdeaSelector = ({ onSelect, onClose }) => {
  const { isDarkMode } = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 639 });
  const isTablet = useMediaQuery({ minWidth: 640, maxWidth: 1023 });
  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [expandedIdeas, setExpandedIdeas] = useState({});
  const [expandedSections, setExpandedSections] = useState({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const loadIdeas = useCallback(async (pageToLoad = 1) => {
    try {
      setLoading(true);
      const response = await api.fetchYouIdeas(pageToLoad, 15);
      
      if (pageToLoad === 1) {
        setIdeas(response.ideas);
      } else {
        setIdeas(prev => [...prev, ...response.ideas]);
      }
      
      setHasMore(pageToLoad < response.totalPages);
    } catch (error) {
      console.error('Error fetching ideas:', error);
      setError('Failed to load insights. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const lastIdeaElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => {
          const nextPage = prevPage + 1;
          loadIdeas(nextPage);
          return nextPage;
        });
      }
    });

    if (node) observer.current.observe(node);
  }, [loading, hasMore, loadIdeas]);

  const toggleIdeaExpansion = (ideaId) => {
    setExpandedIdeas(prev => ({
      ...prev,
      [ideaId]: !prev[ideaId]
    }));
  };

  const toggleSection = (ideaId, section) => {
    setExpandedSections(prev => ({
      ...prev,
      [`${ideaId}-${section}`]: !prev[`${ideaId}-${section}`]
    }));
  };

  const isRedditContent = (idea) => {
    if (idea.source === 'reddit') return true;
    const content = idea.generatedIdeas || '';
    const hasRedditKeywords = /\b(reddit|subreddit|r\/)\b/i.test(content);
    const topic = idea.topic || '';
    const hasRedditTopic = /\b(reddit|subreddit|r\/)\b/i.test(topic);
    return hasRedditKeywords || hasRedditTopic;
  };

  const handlePointSelection = (ideaId, section, point) => {
    const pointKey = `${ideaId}-${section}-${point}`;
    setSelectedPoints(prev => {
      const isSelected = prev.includes(pointKey);
      if (isSelected) {
        return prev.filter(p => p !== pointKey);
      } else {
        return [...prev, pointKey];
      }
    });
  };

  const handleConfirmSelection = () => {
    if (selectedPoints.length === 0) return;

    const selectedContent = selectedPoints.map(pointKey => {
      const [ideaId, ...sectionParts] = pointKey.split('-');
      // The last part is always the pointIndex
      const pointIndex = sectionParts.pop();
      // Rejoin the middle parts in case the section title had hyphens
      const sectionTitle = sectionParts.join('-');
      
      const idea = ideas.find(i => i._id === ideaId);
      if (!idea?.generatedIdeas) return '';

      // Extract the section content
      const sections = idea.generatedIdeas.split(/(?=# |## )/).filter(Boolean);
      const section = sections.find(s => s.includes(sectionTitle));
      if (!section) return '';

      // Process the section based on its type
      if (sectionTitle === 'HIGH-POTENTIAL CONTENT THEMES') {
        const themes = processHighPotentialThemes(section.split('\n').filter(Boolean).slice(1));
        const selectedTheme = themes[parseInt(pointIndex)];
        return selectedTheme ? selectedTheme.trim() : '';
      }

      if (sectionTitle === 'VIRAL CONTENT OPPORTUNITIES') {
        const opportunities = processViralOpportunities(section.split('\n').filter(Boolean).slice(1));
        const selectedOpportunity = opportunities[parseInt(pointIndex)];
        return selectedOpportunity ? selectedOpportunity.trim() : '';
      }

      if (sectionTitle === 'QUICK-WIN IDEAS') {
        const quickWins = processQuickWinIdeas(section.split('\n').filter(Boolean).slice(1));
        const selectedIdea = quickWins[parseInt(pointIndex)];
        return selectedIdea ? selectedIdea.trim() : '';
      }

      if (sectionTitle === 'TRENDING DISCUSSIONS') {
        const discussions = processTrendingDiscussions(section.split('\n').filter(Boolean).slice(1));
        const selectedDiscussion = discussions[parseInt(pointIndex)];
        return selectedDiscussion ? selectedDiscussion.trim() : '';
      }

      if (sectionTitle === 'ENGAGEMENT INSIGHTS') {
        const insights = processEngagementInsights(section.split('\n').filter(Boolean).slice(1));
        // Find the actual point (skip headers and spacers)
        let actualPointIndex = 0;
        let currentPointIndex = 0;
        
        for (const item of insights) {
          if (item.type === 'point') {
            if (currentPointIndex === parseInt(pointIndex)) {
              return item.content.trim();
            }
            currentPointIndex++;
          }
        }
        return '';
      }

      // For other sections, use the standard processing
      const lines = section.split('\n').filter(line => {
        const trimmedLine = line.trim();
        return trimmedLine.startsWith('-') || 
               trimmedLine.startsWith('•') || 
               trimmedLine.startsWith('*') ||
               /^\d+\./.test(trimmedLine);
      });

      const selectedLine = lines[parseInt(pointIndex)];
      if (!selectedLine) return '';

      // Clean up the point text
      return selectedLine
        .replace(/^[-•*\d\.\s]+/, '')  // Remove bullet points and numbers
        .replace(/^💡\s*Content Angles:?/, '')  // Remove "Content Angles" label
        .replace(/^Why:\s*/, '')  // Remove "Why:" prefix
        .trim();
    }).filter(Boolean);

    // Format the selected content
    const formattedText = selectedContent.map(point => `• ${point}`).join('\n');
    
    // Return the formatted text
    onSelect(formattedText);
    onClose();
  };

  const renderSection = (idea, sectionTitle, content, renderContent) => {
    const sectionKey = `${idea._id}-${sectionTitle}`;
    const isExpanded = expandedSections[sectionKey];

    return (
      <div key={sectionKey} className="space-y-2">
        <div 
          onClick={() => toggleSection(idea._id, sectionTitle)}
          className={`flex items-center justify-between py-2 cursor-pointer group border-b ${
            isDarkMode 
              ? 'border-[#333333] hover:border-[#444444]' 
              : 'border-gray-200/60 hover:border-gray-300/60'
          } transition-colors`}
        >
          <div className="flex items-center gap-2">
            {isExpanded ? (
              <ChevronUpIcon className={`w-4 h-4 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`} />
            ) : (
              <ChevronDownIcon className={`w-4 h-4 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`} />
            )}
            <h3 className={`text-sm font-medium ${
              isDarkMode ? 'text-slate-200' : 'text-slate-700'
            }`}>
              {sectionTitle}
            </h3>
          </div>
        </div>

        {isExpanded && (
          <div className="pl-4 space-y-2">
            {renderContent(content)}
          </div>
        )}
      </div>
    );
  };

  const renderIdea = (idea) => {
    if (!idea.generatedIdeas) return null;

    // Split content into sections
    const sections = idea.generatedIdeas.split(/(?=# |## )/).filter(Boolean);
    
    const processedSections = sections
      .map(section => {
        const [title, ...content] = section.split('\n').filter(Boolean);
        const sectionTitle = title.replace(/^[#\s]+/, '');
        
        // Special handling for ENGAGEMENT INSIGHTS
        if (sectionTitle === 'ENGAGEMENT INSIGHTS') {
          const insights = processEngagementInsights(content);
          return {
            title: sectionTitle,
            content: insights,
            hasContent: insights.length > 0
          };
        }
        
        // Special handling for HIGH-POTENTIAL CONTENT THEMES
        if (sectionTitle === 'HIGH-POTENTIAL CONTENT THEMES') {
          const themes = processHighPotentialThemes(content);
          return {
            title: sectionTitle,
            content: themes,
            hasContent: themes.length > 0
          };
        }
        
        // Special handling for VIRAL CONTENT OPPORTUNITIES
        if (sectionTitle === 'VIRAL CONTENT OPPORTUNITIES') {
          const opportunities = processViralOpportunities(content);
          return {
            title: sectionTitle,
            content: opportunities,
            hasContent: opportunities.length > 0
          };
        }

        // Special handling for TRENDING DISCUSSIONS
        if (sectionTitle === 'TRENDING DISCUSSIONS') {
          const discussions = processTrendingDiscussions(content);
          return {
            title: sectionTitle,
            content: discussions,
            hasContent: discussions.length > 0
          };
        }
        
        // Special handling for QUICK-WIN IDEAS
        if (sectionTitle === 'QUICK-WIN IDEAS') {
          const ideas = processQuickWinIdeas(content);
          return {
            title: sectionTitle,
            content: ideas,
            hasContent: ideas.length > 0
          };
        }
        
        // Regular content processing for other sections
        const contentPoints = content.filter(line => {
          const trimmedLine = line.trim();
          return trimmedLine.startsWith('-') || 
                 trimmedLine.startsWith('•') || 
                 trimmedLine.startsWith('*') ||
                 /^\d+\./.test(trimmedLine);
        });

        return {
          title: sectionTitle,
          content: contentPoints,
          hasContent: contentPoints.length > 0
        };
      })
      .filter(section => section.hasContent);
    
    return (
      <div className="space-y-4">
        {/* Quick Stats Section */}
        {idea.quickStats && Object.keys(idea.quickStats).length > 0 && (
          <div className={`p-3 rounded-md ${
            isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-50'
          }`}>
            <h3 className={`text-sm font-medium mb-2 ${
              isDarkMode ? 'text-slate-200' : 'text-slate-700'
            }`}>
              Quick Stats
            </h3>
            <div className="grid grid-cols-2 gap-3">
              {Object.entries(idea.quickStats).map(([key, value]) => (
                <div key={key} className="flex items-center gap-2">
                  <ChartBarIcon className={`w-4 h-4 ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-500'
                  }`} />
                  <span className={`text-sm ${
                    isDarkMode ? 'text-slate-300' : 'text-slate-600'
                  }`}>
                    {key}: {value}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Sections */}
        {processedSections.map((section, index) => (
          renderSection(idea, section.title, section.content, (sectionContent) => (
            <div key={`${idea._id}-${section.title}`} className="space-y-2">
              {sectionContent.map((point, pointIndex) => {
                // Special handling for Engagement Insights section
                if (section.title === 'ENGAGEMENT INSIGHTS') {
                  if (point.type === 'header') {
                    return (
                      <div key={`header-${pointIndex}`} className={`
                        font-medium mb-2 mt-4 first:mt-0
                        ${isDarkMode ? 'text-slate-200' : 'text-slate-700'}
                      `}>
                        {point.content}
                      </div>
                    );
                  }
                  if (point.type === 'spacer') {
                    return <div key={`spacer-${pointIndex}`} className="h-4" />;
                  }
                  
                  // Calculate the actual point index for selectable points
                  const actualPointIndex = sectionContent
                    .slice(0, pointIndex)
                    .filter(p => p.type === 'point')
                    .length;

                  const pointKey = `${idea._id}-${section.title}-${actualPointIndex}`;
                  const isSelected = selectedPoints.includes(pointKey);

                  return (
                    <div
                      key={pointKey}
                      onClick={() => handlePointSelection(idea._id, section.title, actualPointIndex)}
                      className={`flex items-start gap-2 p-2 rounded-md cursor-pointer ${
                        isSelected
                          ? isDarkMode
                            ? 'bg-blue-500/10 border border-blue-500/30'
                            : 'bg-blue-50 border border-blue-200'
                          : isDarkMode
                            ? 'hover:bg-[#1e1e1e]'
                            : 'hover:bg-gray-50'
                      }`}
                    >
                      <div className={`mt-1 flex-shrink-0 w-4 h-4 rounded-sm border ${
                        isSelected
                          ? isDarkMode
                            ? 'border-blue-500 bg-blue-500'
                            : 'border-blue-500 bg-blue-500'
                          : isDarkMode
                            ? 'border-[#333333]'
                            : 'border-gray-300'
                      }`}>
                        {isSelected && (
                          <CheckIcon className="w-3 h-3 text-white" />
                        )}
                      </div>
                      <span className={`text-sm whitespace-pre-wrap ${
                        isDarkMode ? 'text-slate-300' : 'text-slate-700'
                      }`}>
                        {point.content}
                      </span>
                    </div>
                  );
                }

                const trimmedPoint = typeof point === 'string' ? point.trim() : point;
                
                // Skip validation for special sections since they're already processed
                const isSpecialSection = section.title === 'HIGH-POTENTIAL CONTENT THEMES' || 
                                       section.title === 'QUICK-WIN IDEAS' ||
                                       section.title === 'VIRAL CONTENT OPPORTUNITIES' ||
                                       section.title === 'TRENDING DISCUSSIONS' ||
                                       section.title === 'ENGAGEMENT INSIGHTS';
                
                // Only validate points for regular sections
                if (!isSpecialSection && 
                    !trimmedPoint.startsWith('-') && 
                    !trimmedPoint.startsWith('•') && 
                    !trimmedPoint.startsWith('*') && 
                    !/^\d+\.\s/.test(trimmedPoint)) return null;

                // Skip rendering for non-point items in Engagement Insights
                if (section.title === 'ENGAGEMENT INSIGHTS' && typeof point !== 'string') {
                  return null;
                }

                // Clean up the point text based on section type
                const pointText = isSpecialSection
                  ? typeof point === 'string' ? point : point.content // Handle both string and object points
                  : trimmedPoint
                      .replace(/^[-•*]\s+/, '')  // Remove only bullet points
                      .replace(/^(\d+\.\s*)/, '$1') // Preserve numbers with dots
                      .replace(/^💡\s*Content Angles:?/, '')  // Remove "Content Angles" label
                      .replace(/^Why:\s*/, '')  // Remove "Why:" prefix
                      .trim();

                const pointKey = `${idea._id}-${section.title}-${pointIndex}`;
                const isSelected = selectedPoints.includes(pointKey);

                return (
                  <div
                    key={pointKey}
                    onClick={() => handlePointSelection(idea._id, section.title, pointIndex)}
                    className={`flex items-start gap-2 p-2 rounded-md cursor-pointer ${
                      isSelected
                        ? isDarkMode
                          ? 'bg-blue-500/10 border border-blue-500/30'
                          : 'bg-blue-50 border border-blue-200'
                        : isDarkMode
                          ? 'hover:bg-[#1e1e1e]'
                          : 'hover:bg-gray-50'
                    }`}
                  >
                    <div className={`mt-1 flex-shrink-0 w-4 h-4 rounded-sm border ${
                      isSelected
                        ? isDarkMode
                          ? 'border-blue-500 bg-blue-500'
                          : 'border-blue-500 bg-blue-500'
                        : isDarkMode
                          ? 'border-[#333333]'
                          : 'border-gray-300'
                    }`}>
                      {isSelected && (
                        <CheckIcon className="w-3 h-3 text-white" />
                      )}
                    </div>
                    <span className={`text-sm whitespace-pre-wrap ${
                      isDarkMode ? 'text-slate-300' : 'text-slate-700'
                    }`}>
                      {pointText}
                    </span>
                  </div>
                );
              })}
            </div>
          ))
        ))}
      </div>
    );
  };

  useEffect(() => {
    loadIdeas(1);
  }, [loadIdeas]);

  if (error) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className={`
          ${isDarkMode ? 'bg-slate-800 text-slate-200' : 'bg-white text-slate-800'}
          w-full h-full md:w-11/12 md:h-[90vh] md:max-w-2xl md:rounded-lg
          flex flex-col overflow-hidden shadow-lg
        `}>
          <div className="flex-1 flex items-center justify-center p-4">
            <div className="text-center">
              <p className={isDarkMode ? 'text-slate-300' : 'text-gray-600'}>{error}</p>
              <button
                onClick={() => loadIdeas(1)}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Retry
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <motion.div 
        className={`flex flex-col h-full w-full overflow-hidden
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
          border-none rounded-none`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {/* Header */}
        <div className={`
          ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'} 
          !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 w-full 
          border-b z-50 relative
        `}>
          {/* Left side: Icon + Title */}
          <div className="flex items-center gap-1.5">
            <div className={`flex items-center justify-center w-6 h-6 rounded-md ${isDarkMode ? 'bg-[#1e1e1e]' : 'bg-gray-100/80'}`}>
              <SparklesIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
            </div>
            <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
              Insights
            </h2>
          </div>

          {/* Right side: Back button */}
          <button 
            onClick={onClose}
            className={`
              p-1.5 rounded-md transition-colors duration-200
              ${isDarkMode 
                ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-200' 
                : 'hover:bg-gray-100 text-gray-600 hover:text-gray-800'}
            `}
            title="Back to Chat"
          >
            <ArrowLeftIcon className="w-4 h-4" />
          </button>
        </div>

        {/* Content Area */}
        <div className={`
          flex-grow overflow-y-auto custom-scrollbar
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
        `}>
          {loading ? (
            <div className="px-4 py-3">
              <SkeletonLoader 
                isDarkMode={isDarkMode} 
                count={5} 
                variant="idea"
              />
            </div>
          ) : ideas.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full p-4">
              <SparklesIcon className={`h-10 w-10 mb-3 ${isDarkMode ? 'text-[#333333]' : 'text-gray-400'}`} />
              <p className={`text-center text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                No insights available
              </p>
            </div>
          ) : (
            <div className="px-4 py-3 space-y-6">
              {ideas.map((idea, index) => (
                <div
                  key={idea._id}
                  ref={index === ideas.length - 1 ? lastIdeaElementRef : null}
                  className={`rounded-lg overflow-hidden border ${
                    isDarkMode 
                      ? 'border-[#333333] bg-[#151515]' 
                      : 'border-gray-200 bg-white'
                  }`}
                >
                  {/* Idea Header */}
                  <div 
                    onClick={() => toggleIdeaExpansion(idea._id)}
                    className={`
                      flex items-center justify-between p-4 cursor-pointer
                      ${isDarkMode 
                        ? 'hover:bg-[#1a1a1a]' 
                        : 'hover:bg-gray-50'
                      }
                    `}
                  >
                    <div className="flex items-center gap-3">
                      {isRedditContent(idea) ? (
                        <RedditIcon 
                          isDarkMode={isDarkMode} 
                          className="w-5 h-5 flex-shrink-0" 
                        />
                      ) : (
                        <GlobeAltIcon 
                          className={`w-5 h-5 flex-shrink-0 ${
                            isDarkMode ? 'text-slate-400' : 'text-gray-500'
                          }`} 
                        />
                      )}
                      <div>
                        <h3 className={`text-sm font-medium ${
                          isDarkMode ? 'text-slate-200' : 'text-gray-900'
                        }`}>
                          {idea.topic || 'Untitled Idea'}
                        </h3>
                      </div>
                    </div>
                    <ChevronDownIcon 
                      className={`w-5 h-5 transition-transform ${
                        expandedIdeas[idea._id] ? 'rotate-180' : ''
                      } ${
                        isDarkMode ? 'text-slate-400' : 'text-gray-500'
                      }`}
                    />
                  </div>

                  {/* Expanded Content */}
                  {expandedIdeas[idea._id] && (
                    <div className={`p-4 border-t ${
                      isDarkMode ? 'border-[#333333]' : 'border-gray-200'
                    }`}>
                      {renderIdea(idea)}
                    </div>
                  )}
                </div>
              ))}
              {loading && (
                <div className="py-4 text-center">
                  <div className="inline-block animate-spin rounded-full h-5 w-5 border-2 border-t-transparent border-blue-500"></div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className={`
          px-3 py-2 
          ${isDarkMode ? 'bg-[#151515] border-t border-[#333333]' : 'bg-white border-t border-gray-200'}
          sticky bottom-0
        `}>
          <div className="flex items-center justify-between">
            <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              {selectedPoints.length} points selected
            </span>
            <button
              onClick={handleConfirmSelection}
              className={`
                px-3 py-1.5 rounded-md text-xs font-medium
                transition-colors duration-200 flex items-center gap-1.5
                ${selectedPoints.length > 0
                  ? isDarkMode
                    ? 'bg-[#1e1e1e] hover:bg-[#2a2a2a] text-blue-400 border border-[#333333]'
                    : 'bg-blue-600 hover:bg-blue-700 text-white'
                  : isDarkMode
                    ? 'bg-[#1e1e1e] text-slate-400'
                    : 'bg-gray-200 text-gray-400'}
              `}
              disabled={selectedPoints.length === 0}
            >
              <CheckIcon className="h-3.5 w-3.5" />
              Add to Chat
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default YouIdeaSelector; 