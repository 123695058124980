import React, { useCallback, useState, useRef, useEffect, memo } from 'react';
import { format, parse, setHours, setMinutes, isValid } from 'date-fns';
import { ChevronUpIcon, ChevronDownIcon, ClockIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

// Memoized TimeColumn component for better performance
const TimeColumn = memo(({ 
  value, 
  onChange, 
  onDirectInput, 
  label, 
  isDarkMode, 
  maxValue = 59,
  onKeyDown 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);

  // Reset input value when prop value changes
  useEffect(() => {
    if (!isEditing) {
      setInputValue(value);
    }
  }, [value, isEditing]);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    if (!isEditing) {
      setIsEditing(true);
      setInputValue(value);
      requestAnimationFrame(() => {
        inputRef.current?.select();
      });
    }
  }, [isEditing, value]);

  const validateAndFormatValue = useCallback((numValue) => {
    if (isNaN(numValue)) return value;

    if (label === 'hours') {
      if (numValue === 0) return '12';
      if (numValue > 12) return (numValue % 12 || 12).toString().padStart(2, '0');
      return numValue.toString().padStart(2, '0');
    }
    
    return Math.min(Math.max(0, numValue), maxValue).toString().padStart(2, '0');
  }, [label, maxValue, value]);

  const handleBlur = useCallback(() => {
    setIsEditing(false);
    const numValue = parseInt(inputValue, 10);
    const formattedValue = validateAndFormatValue(numValue);
    setInputValue(formattedValue);
    onDirectInput(parseInt(formattedValue, 10));
  }, [inputValue, onDirectInput, validateAndFormatValue]);

  const handleKeyDown = useCallback((e) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        e.target.blur();
        break;
      case 'Escape':
        e.preventDefault();
        setIsEditing(false);
        setInputValue(value);
        e.target.blur();
        break;
      case 'ArrowUp':
        e.preventDefault();
        onChange(1)(e);
        break;
      case 'ArrowDown':
        e.preventDefault();
        onChange(-1)(e);
        break;
      case 'Tab':
        handleBlur();
        break;
      default:
        onKeyDown?.(e);
    }
  }, [onChange, onKeyDown, value, handleBlur]);

  const handleChange = useCallback((e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');
    if (newValue.length <= 2) {
      setInputValue(newValue);
      const numValue = parseInt(newValue, 10);
      if (!isNaN(numValue)) {
        if ((label === 'hours' && numValue >= 1 && numValue <= 12) ||
            (label === 'minutes' && numValue >= 0 && numValue <= maxValue)) {
          onDirectInput(numValue);
        }
      }
    }
  }, [label, maxValue, onDirectInput]);

  const handleWheel = useCallback((e) => {
    e.preventDefault();
    onChange(e.deltaY < 0 ? 1 : -1)(e);
  }, [onChange]);

  return (
    <div 
      className="relative flex flex-col items-center" 
      role="spinbutton" 
      aria-label={label}
      aria-valuemin={label === 'hours' ? 1 : 0}
      aria-valuemax={label === 'hours' ? 12 : 59}
      aria-valuenow={parseInt(value, 10)}
      aria-valuetext={`${parseInt(value, 10)} ${label}`}
    >
      <motion.button
        whileTap={{ scale: 0.95 }}
        onClick={onChange(1)}
        className={`
          p-0.5 rounded
          transition-colors
          focus:outline-none focus:ring-2 focus:ring-blue-500
          ${isDarkMode 
            ? 'hover:bg-zinc-800 text-zinc-400 hover:text-zinc-100' 
            : 'hover:bg-zinc-100 text-zinc-600 hover:text-zinc-900'
          }
        `}
        aria-label={`Increase ${label}`}
      >
        <ChevronUpIcon className="w-3 h-3" />
      </motion.button>
      
      <AnimatePresence mode="wait">
        {isEditing ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`
              relative h-6
              w-[32px]
              ${isDarkMode ? 'bg-zinc-800' : 'bg-white'}
              rounded
              ring-1 ring-blue-500/50
            `}
          >
            <input
              ref={inputRef}
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              value={inputValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              aria-label={`Enter ${label}`}
              className={`
                w-full h-full
                text-center text-sm font-medium tabular-nums
                bg-transparent
                focus:outline-none
                selection:bg-blue-500/20
                ${isDarkMode ? 'text-zinc-100' : 'text-zinc-900'}
              `}
            />
          </motion.div>
        ) : (
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleClick(e);
              }
            }}
            role="button"
            tabIndex={0}
            className={`
              group
              relative h-6 flex items-center justify-center
              w-[32px]
              text-sm font-medium tabular-nums select-none
              rounded
              transition-colors duration-100
              cursor-text
              focus:outline-none focus:ring-2 focus:ring-blue-500
              ${isDarkMode 
                ? 'hover:bg-zinc-800/50 active:bg-zinc-800 text-zinc-100' 
                : 'hover:bg-zinc-100 active:bg-zinc-200 text-zinc-900'
              }
            `}
            onWheel={handleWheel}
          >
            {value}
            <div className={`
              absolute -bottom-0.5 left-1/2 -translate-x-1/2
              w-3 h-0.5 rounded-full
              opacity-0 group-hover:opacity-100 group-focus:opacity-100
              transition-opacity duration-150
              ${isDarkMode ? 'bg-zinc-600' : 'bg-zinc-300'}
            `} />
          </motion.div>
        )}
      </AnimatePresence>
      
      <motion.button
        whileTap={{ scale: 0.95 }}
        onClick={onChange(-1)}
        className={`
          p-0.5 rounded
          transition-colors
          focus:outline-none focus:ring-2 focus:ring-blue-500
          ${isDarkMode 
            ? 'hover:bg-zinc-800 text-zinc-400 hover:text-zinc-100' 
            : 'hover:bg-zinc-100 text-zinc-600 hover:text-zinc-900'
          }
        `}
        aria-label={`Decrease ${label}`}
      >
        <ChevronDownIcon className="w-3 h-3" />
      </motion.button>
    </div>
  );
});

TimeColumn.displayName = 'TimeColumn';

TimeColumn.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDirectInput: PropTypes.func.isRequired,
  label: PropTypes.oneOf(['hours', 'minutes']).isRequired,
  isDarkMode: PropTypes.bool,
  maxValue: PropTypes.number,
  onKeyDown: PropTypes.func
};

const ScheduleTimePickerDesign = ({
  selectedTime,
  onTimeSelect,
  isDarkMode = false,
  step = 1,
  className = '',
  onSetTime,
  onClose,
}) => {
  const [error, setError] = useState(null);
  const containerRef = useRef(null);

  // Validate input time
  useEffect(() => {
    const timeDate = parse(selectedTime, 'HH:mm', new Date());
    if (!isValid(timeDate)) {
      setError('Invalid time format');
      return;
    }
    setError(null);
  }, [selectedTime]);

  const timeDate = parse(selectedTime, 'HH:mm', new Date());
  const hours = timeDate.getHours();
  const minutes = timeDate.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!containerRef.current) return;
      
      if (e.key === 'Escape') {
        onClose?.();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  const handleHourChange = useCallback((increment) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newHours = (hours + increment + 24) % 24;
    const newTime = setHours(timeDate, newHours);
    onTimeSelect(format(newTime, 'HH:mm'));
  }, [hours, timeDate, onTimeSelect]);

  const handleMinuteChange = useCallback((increment) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const stepSize = step || 1;
    let newMinutes = Math.round((minutes + increment * stepSize) / stepSize) * stepSize;
    let newHours = hours;
    
    if (newMinutes >= 60) {
      newMinutes = 0;
      newHours = (hours + 1) % 24;
    } else if (newMinutes < 0) {
      newMinutes = 59;
      newHours = (hours - 1 + 24) % 24;
    }
    
    const newTime = setMinutes(setHours(timeDate, newHours), newMinutes);
    onTimeSelect(format(newTime, 'HH:mm'));
  }, [minutes, hours, timeDate, onTimeSelect, step]);

  const handleDirectHourInput = useCallback((value) => {
    let newHours = value;
    if (value === 12) {
      newHours = period === 'AM' ? 0 : 12;
    } else if (period === 'PM') {
      newHours = value < 12 ? value + 12 : value;
    }

    const newTime = setHours(timeDate, newHours);
    onTimeSelect(format(newTime, 'HH:mm'));
  }, [period, timeDate, onTimeSelect]);

  const handleDirectMinuteInput = useCallback((value) => {
    const newMinutes = Math.min(Math.max(0, value), 59);
    const newTime = setMinutes(timeDate, newMinutes);
    onTimeSelect(format(newTime, 'HH:mm'));
  }, [timeDate, onTimeSelect]);

  const handlePeriodToggle = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const newHours = (hours + 12) % 24;
    const newTime = setHours(timeDate, newHours);
    onTimeSelect(format(newTime, 'HH:mm'));
  }, [hours, timeDate, onTimeSelect]);

  const preventClose = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleSetTime = useCallback(() => {
    if (error) return;
    onSetTime?.(format(timeDate, 'HH:mm'));
    onClose?.();
  }, [error, timeDate, onSetTime, onClose]);

  return (
    <motion.div 
      ref={containerRef}
      initial={{ opacity: 0, y: 2 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 2 }}
      transition={{ duration: 0.15 }}
      className={`
        w-[240px]
        rounded-lg
        p-2
        ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'}
        ${className}
      `}
      onClick={preventClose}
      onMouseDown={preventClose}
      role="dialog"
      aria-label="Time picker"
      aria-modal="true"
    >
      {/* Minimal Header */}
      {error ? (
        <div className={`
          text-xs text-red-500 mb-2 px-1.5 py-1 rounded flex items-center gap-1
          ${isDarkMode ? 'bg-red-900/20' : 'bg-red-50'}
        `}>
          <ClockIcon className="w-3 h-3" />
          {error}
        </div>
      ) : (
        <div className={`
          text-[10px] font-medium tracking-tight flex items-center gap-1 px-1 mb-1.5
          ${isDarkMode ? 'text-zinc-400' : 'text-zinc-500'}
        `}>
          <ClockIcon className="w-2.5 h-2.5" />
          <span>Click to edit</span>
        </div>
      )}

      {/* Time Selection */}
      <div className="flex items-stretch gap-1.5 mb-1.5">
        {/* Time Input Container */}
        <div className={`
          flex-1 rounded-md p-1.5
          ${isDarkMode ? 'bg-zinc-800/50' : 'bg-zinc-50'}
        `}>
          <div className="flex items-center justify-center gap-2">
            <TimeColumn
              value={format(timeDate, 'hh')}
              onChange={handleHourChange}
              onDirectInput={handleDirectHourInput}
              label="hours"
              isDarkMode={isDarkMode}
              maxValue={12}
            />
            <div className={`
              h-6 flex items-center
              text-sm font-medium select-none
              ${isDarkMode ? 'text-zinc-400' : 'text-zinc-500'}
            `} 
              aria-hidden="true"
            >:</div>
            <TimeColumn
              value={format(timeDate, 'mm')}
              onChange={handleMinuteChange}
              onDirectInput={handleDirectMinuteInput}
              label="minutes"
              isDarkMode={isDarkMode}
              maxValue={59}
            />
          </div>
        </div>

        {/* AM/PM Container */}
        <div className={`
          w-12 rounded-md p-1
          ${isDarkMode ? 'bg-zinc-800/50' : 'bg-zinc-50'}
        `}>
          <div className="relative h-full flex flex-col gap-0.5">
            <button
              onClick={handlePeriodToggle}
              className={`
                flex-1 flex items-center justify-center
                text-[10px] font-medium rounded
                transition-colors
                focus:outline-none focus:ring-2 focus:ring-blue-500
                ${period === 'AM' 
                  ? isDarkMode 
                    ? 'bg-blue-500 text-white hover:bg-blue-600' 
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                  : isDarkMode
                    ? 'text-zinc-400 hover:text-zinc-100 hover:bg-zinc-800' 
                    : 'text-zinc-600 hover:text-zinc-900 hover:bg-zinc-100'
                }
              `}
              aria-pressed={period === 'AM'}
              aria-label="Toggle AM"
            >
              AM
            </button>
            <button
              onClick={handlePeriodToggle}
              className={`
                flex-1 flex items-center justify-center
                text-[10px] font-medium rounded
                transition-colors
                focus:outline-none focus:ring-2 focus:ring-blue-500
                ${period === 'PM' 
                  ? isDarkMode 
                    ? 'bg-blue-500 text-white hover:bg-blue-600' 
                    : 'bg-blue-500 text-white hover:bg-blue-600'
                  : isDarkMode
                    ? 'text-zinc-400 hover:text-zinc-100 hover:bg-zinc-800' 
                    : 'text-zinc-600 hover:text-zinc-900 hover:bg-zinc-100'
                }
              `}
              aria-pressed={period === 'PM'}
              aria-label="Toggle PM"
            >
              PM
            </button>
          </div>
        </div>
      </div>

      {/* Minimal Set Button */}
      <button
        onClick={handleSetTime}
        disabled={!!error}
        className={`
          w-full h-6
          flex items-center justify-center
          rounded-md
          text-[10px] font-medium
          transition-colors
          focus:outline-none focus:ring-2 focus:ring-blue-500
          disabled:opacity-50 disabled:cursor-not-allowed
          ${isDarkMode
            ? 'bg-zinc-800 hover:bg-zinc-700 text-zinc-300'
            : 'bg-zinc-100 hover:bg-zinc-200 text-zinc-600'
          }
        `}
      >
        Set Time
      </button>
    </motion.div>
  );
};

ScheduleTimePickerDesign.propTypes = {
  selectedTime: PropTypes.string.isRequired,
  onTimeSelect: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool,
  step: PropTypes.number,
  className: PropTypes.string,
  onSetTime: PropTypes.func,
  onClose: PropTypes.func
};

export default memo(ScheduleTimePickerDesign); 