import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import LinkedInPostMockup from './LinkedInPostMockup';
import debounce from 'lodash/debounce';
import { DEVICE_CONFIGS } from '../constants/deviceConfig';

const postAnimationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.15 } },
  exit: { opacity: 0, transition: { duration: 0.1 } }
};

const CanvasMasonryView = ({
  posts,
  selectedPersona,
  activeDevice,
  isDarkMode,
  onRemove,
  newestPostId,
  onContentChange,
  onTitleChange,
  editingPostId,
  onStartEditing,
  onStopEditing,
  onFocusMode,
  isChatCollapsed,
  onFullScreen,
}) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [columns, setColumns] = useState(1);
  
  // Get current post width based on device
  const getCurrentPostWidth = useCallback(() => {
    return {
      mobile: 380,
      tablet: 440,
      desktop: 520
    }[activeDevice] || 380;
  }, [activeDevice]);

  // Calculate optimal number of columns based on container width and post width
  const calculateOptimalColumns = useCallback((width, postWidth) => {
    const minGap = 16; // Minimum gap between posts
    const containerPadding = 32; // Total container padding (16px on each side)
    
    // Calculate usable width
    const usableWidth = width - containerPadding;
    
    // Calculate how many columns can fit with minimum gap
    const possibleColumns = Math.floor((usableWidth + minGap) / (postWidth + minGap));
    
    // Ensure at least 1 column, but no more than 3
    return Math.max(1, Math.min(possibleColumns, 3));
  }, []);

  // Update layout when container size changes or device changes
  useEffect(() => {
    const updateLayout = () => {
      if (!containerRef.current) return;
      
      const rect = containerRef.current.getBoundingClientRect();
      const actualWidth = rect.width;
      
      // Calculate available width based on chat state
      const availableWidth = isChatCollapsed 
        ? actualWidth * 0.98  // Use 98% when chat is collapsed
        : actualWidth * 0.92; // Use 92% when chat is visible
      
      setContainerWidth(availableWidth);
      
      // Get current post width and calculate optimal columns
      const postWidth = getCurrentPostWidth();
      const optimalColumns = calculateOptimalColumns(availableWidth, postWidth);
      
      setColumns(optimalColumns);
    };

    // Create debounced version of updateLayout
    const debouncedUpdateLayout = debounce(updateLayout, 250);

    // Set up resize observer
    const resizeObserver = new ResizeObserver(debouncedUpdateLayout);
    
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      updateLayout(); // Initial layout calculation
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
      debouncedUpdateLayout.cancel();
    };
  }, [isChatCollapsed, activeDevice, calculateOptimalColumns, getCurrentPostWidth]);

  // Calculate layout dimensions based on current state
  const layoutDimensions = useMemo(() => {
    const postWidth = getCurrentPostWidth();
    const minGap = 16;
    
    // Calculate actual gap and column width to fill space evenly
    const totalGapsWidth = (columns - 1) * minGap;
    const availableWidthForPosts = containerWidth - totalGapsWidth;
    const columnWidth = Math.min(postWidth, Math.floor(availableWidthForPosts / columns));
    
    return {
      columns,
      columnWidth,
      gap: minGap,
      totalWidth: (columnWidth * columns) + totalGapsWidth
    };
  }, [columns, containerWidth, getCurrentPostWidth]);

  // Distribute posts across columns
  const columnedPosts = useMemo(() => {
    const columnArrays = Array.from({ length: layoutDimensions.columns }, () => []);
    let currentIndex = 0;
    
    posts.forEach((post) => {
      // Distribute posts evenly across columns in order
      const columnIndex = currentIndex % layoutDimensions.columns;
      columnArrays[columnIndex].push({
        ...post,
        overallIndex: currentIndex
      });
      currentIndex++;
    });

    return columnArrays;
  }, [posts, layoutDimensions.columns]);

  return (
    <motion.div 
      ref={containerRef}
      className="masonry-container w-full"
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '16px',
        boxSizing: 'border-box',
        minHeight: '100%',
        transition: 'all 0.3s ease-in-out'
      }}
    >
      <motion.div 
        className="grid"
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${layoutDimensions.columns}, ${layoutDimensions.columnWidth}px)`,
          gap: `${layoutDimensions.gap}px`,
          width: '100%',
          maxWidth: `${layoutDimensions.totalWidth}px`,
          margin: '0 auto',
          transition: 'all 0.3s ease-in-out'
        }}
      >
        {columnedPosts.map((column, columnIndex) => (
          <motion.div
            key={columnIndex}
            className="flex flex-col"
            style={{ gap: `${layoutDimensions.gap}px` }}
          >
            <AnimatePresence mode="popLayout">
              {column.map((post) => (
                <motion.div
                  key={post.id}
                  variants={postAnimationVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  style={{ width: '100%' }}
                >
                  <LinkedInPostMockup
                    selectedPersona={selectedPersona}
                    activeDevice={activeDevice}
                    canvasContent={post}
                    onRemove={() => onRemove(post.id)}
                    isNewest={post.id === newestPostId}
                    isDarkMode={isDarkMode}
                    onContentChange={onContentChange}
                    onTitleChange={(newTitle) => onTitleChange(post.id, newTitle)}
                    isEditing={editingPostId === post.id}
                    onStartEditing={() => onStartEditing(post.id)}
                    onStopEditing={onStopEditing}
                    onSave={(newContent) => onContentChange(post.id, newContent)}
                    onCancel={onStopEditing}
                    removeBorderAndPadding={true}
                    isSimplifiedView={false}
                    forceExpanded={true}
                    preventCollapse={true}
                    onFocusMode={onFocusMode}
                    currentIndex={post.overallIndex}
                    totalPosts={posts.length}
                    onFullScreen={() => onFullScreen(post)}
                    isPublished={post.published || false}
                    isEditedLocally={post.editState?.isEditedLocally || false}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default CanvasMasonryView;