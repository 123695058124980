import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../context/ThemeContext';
import { motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import {
  SparklesIcon,
  PencilIcon,
  ArrowPathIcon,
  BriefcaseIcon,
  ClipboardDocumentListIcon,
  FlagIcon,
  QuestionMarkCircleIcon,
  ChartBarIcon,
  ArrowTrendingUpIcon,
  LightBulbIcon,
  ChatBubbleLeftRightIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline';

// Add loader animation component
const LoaderAnimation = () => (
  <div className="flex items-center gap-2">
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ 
        scale: [0.8, 1.2, 0.8],
        opacity: [0.3, 1, 0.3]
      }}
      transition={{
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      className={`w-1.5 h-1.5 rounded-full bg-current`}
    />
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ 
        scale: [0.8, 1.2, 0.8],
        opacity: [0.3, 1, 0.3]
      }}
      transition={{
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut",
        delay: 0.2
      }}
      className={`w-1.5 h-1.5 rounded-full bg-current`}
    />
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ 
        scale: [0.8, 1.2, 0.8],
        opacity: [0.3, 1, 0.3]
      }}
      transition={{
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut",
        delay: 0.4
      }}
      className={`w-1.5 h-1.5 rounded-full bg-current`}
    />
  </div>
);

const SUGGESTED_PROMPTS = [
  {
    label: "Summarize",
    prompt: "Create a concise summary of the main points from this transcription",
    icon: SparklesIcon
  },
  {
    label: "Proofread & Edit",
    prompt: "Proofread, edit, and organize these notes into a clear and structured format",
    icon: PencilIcon
  },
  {
    label: "Write Tweet",
    prompt: "Create an engaging tweet from the key insights in this transcription",
    icon: ArrowPathIcon
  },
  {
    label: "LinkedIn Post",
    prompt: "Transform this transcription into an insightful LinkedIn post",
    icon: BriefcaseIcon
  },
  {
    label: "Extract Action Items",
    prompt: "Extract and list all action items and tasks mentioned in this transcription",
    icon: ClipboardDocumentListIcon
  },
  {
    label: "Key Points",
    prompt: "Extract the key points and main takeaways from this transcription",
    icon: FlagIcon
  },
  {
    label: "Questions",
    prompt: "Generate follow-up questions based on this transcription",
    icon: QuestionMarkCircleIcon
  },
  {
    label: "Analysis",
    prompt: "Provide a detailed analysis of the main themes and insights",
    icon: ChartBarIcon
  },
  {
    label: "Next Steps",
    prompt: "Suggest actionable next steps based on this transcription",
    icon: ArrowTrendingUpIcon
  },
  {
    label: "Ideas",
    prompt: "Generate creative ideas and suggestions based on this content",
    icon: LightBulbIcon
  },
  {
    label: "Social Post",
    prompt: "Create a compelling social media post highlighting the key insights",
    icon: ChatBubbleLeftRightIcon
  },
  {
    label: "Email",
    prompt: "Draft a professional email summarizing the main points",
    icon: EnvelopeIcon
  }
];

const GenerateContentPopup = ({ 
  buttonRect, 
  onClose, 
  onGenerate, 
  isGenerating 
}) => {
  const { isDarkMode } = useTheme();
  const [prompt, setPrompt] = useState('');
  const popupRef = useRef(null);
  const maxPromptLength = 280;
  
  const getPopupStyles = () => {
    if (!buttonRect) return {};

    const popupHeight = 320;
    const spacing = 4;
    const windowHeight = window.innerHeight;
    const spaceBelow = windowHeight - buttonRect.bottom;
    const showAbove = spaceBelow < popupHeight && buttonRect.top > spaceBelow;

    const horizontalPosition = Math.max(8, Math.min(
      buttonRect.left, 
      window.innerWidth - (window.innerWidth < 640 ? 320 : 428)
    ));

    return {
      position: 'absolute',
      left: `${horizontalPosition}px`,
      ...(showAbove 
        ? { bottom: `${window.innerHeight - buttonRect.top + spacing}px` }
        : { top: `${buttonRect.bottom + spacing}px` }
      ),
      width: 'min(calc(100vw - 16px), 320px)',
      maxWidth: window.innerWidth < 640 ? '320px' : '420px',
    };
  };

  const handleGenerate = () => {
    if (!prompt.trim()) {
      toast.error('Please enter a prompt');
      return;
    }
    if (prompt.length > maxPromptLength) {
      toast.error(`Prompt is too long. Maximum ${maxPromptLength} characters allowed.`);
      return;
    }
    onGenerate(prompt);
  };

  const handlePromptSelect = (selectedPrompt) => {
    if (isGenerating) {
      toast.error('Please wait for current generation to complete');
      return;
    }
    if (prompt === selectedPrompt) {
      toast.info('This prompt is already selected');
      return;
    }
    setPrompt(selectedPrompt);
  };

  return (
    <div 
      ref={popupRef}
      className="prompt-popup fixed z-[9999]"
      style={getPopupStyles()}
    >
      <div 
        className={`
          ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'} 
          border rounded-lg shadow-lg overflow-hidden
          ${getPopupStyles().bottom ? 'animate-slide-up' : 'animate-slide-down'}
        `}
      >
        {/* Input Area */}
        <div className="p-3 space-y-2">
          <div className="relative">
            <textarea
              value={prompt}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length > maxPromptLength) {
                  toast.error(`Maximum ${maxPromptLength} characters allowed`);
                  return;
                }
                setPrompt(newValue);
              }}
              placeholder="What would you like me to do with this transcription?"
              className={`
                w-full px-3 py-2 text-xs
                rounded-md border
                resize-none overflow-hidden
                placeholder:text-muted-foreground
                focus-visible:outline-none
                transition-all duration-200
                ${isDarkMode 
                  ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 placeholder-slate-500 focus-visible:border-[#333333]' 
                  : 'bg-gray-50/50 border-gray-200 text-gray-700 placeholder-gray-400 focus-visible:border-gray-300'
                }
                focus-visible:ring-[0.5px]
                focus-visible:ring-opacity-40
                ${isDarkMode
                  ? 'focus-visible:ring-blue-500/30'
                  : 'focus-visible:ring-gray-300'
                }
              `}
              rows="2"
              style={{
                minHeight: '60px',
                maxHeight: '120px'
              }}
            />
            
            {/* Character count */}
            <div 
              className={`
                absolute right-2 bottom-2
                text-[10px] tabular-nums
                ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}
                ${prompt.length > maxPromptLength ? 'text-red-500' : ''}
              `}
            >
              {prompt.length}/{maxPromptLength}
            </div>
          </div>

          {/* Suggested Prompts */}
          <div className="flex flex-wrap gap-1.5">
            {SUGGESTED_PROMPTS.map((item, index) => {
              const Icon = item.icon;
              return (
                <button
                  key={index}
                  onClick={() => handlePromptSelect(item.prompt)}
                  disabled={isGenerating}
                  className={`
                    inline-flex items-center gap-1.5
                    text-[11px] px-2.5 py-1.5
                    rounded-md border
                    transition-all duration-200
                    hover:scale-[1.02]
                    active:scale-[0.98]
                    ${isGenerating ? 'opacity-50 cursor-not-allowed' : ''}
                    ${isDarkMode
                      ? prompt === item.prompt
                        ? 'bg-[#1a1a1a] border-purple-500/30 text-purple-400 shadow-sm shadow-purple-500/10'
                        : 'bg-[#151515] border-[#333333] text-slate-400 hover:bg-[#1e1e1e] hover:text-slate-300 hover:border-[#444444]'
                        : prompt === item.prompt
                          ? 'bg-purple-50 border-purple-200 text-purple-600 shadow-sm'
                          : 'bg-gray-50/50 border-gray-200/50 text-gray-500 hover:bg-gray-100/80 hover:text-gray-600 hover:border-gray-300'
                    }
                  `}
                >
                  <Icon className={`w-3.5 h-3.5 ${prompt === item.prompt ? 'text-current' : 'opacity-70'}`} />
                  <span>{item.label}</span>
                </button>
              );
            })}
          </div>
        </div>

        {/* Action Buttons */}
        <div className={`
          p-3 border-t
          ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
        `}>
          <div className="flex justify-end gap-2.5">
            <button
              onClick={() => {
                if (prompt.trim() && !isGenerating) {
                  toast.info('Changes discarded');
                }
                onClose();
              }}
              className={`
                px-4 py-1.5 text-[12px] font-medium
                rounded-md border
                transition-all duration-200
                hover:scale-[1.02]
                active:scale-[0.98]
                ${isDarkMode
                  ? 'border-[#333333] text-slate-400 hover:bg-[#1e1e1e] hover:text-slate-300 hover:border-[#444444]'
                  : 'border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-600'
                }
              `}
            >
              Cancel
            </button>
            <motion.button
              onClick={handleGenerate}
              disabled={isGenerating || !prompt.trim() || prompt.length > maxPromptLength}
              whileTap={{ scale: 0.98 }}
              whileHover={{ scale: 1.02 }}
              className={`
                px-4 py-1.5 text-[12px] font-medium
                rounded-md
                transition-all duration-200
                min-w-[90px]
                flex items-center justify-center
                shadow-sm
                ${isDarkMode
                  ? isGenerating || !prompt.trim() || prompt.length > maxPromptLength
                    ? 'bg-[#1a1a1a] text-slate-500 cursor-not-allowed border border-[#333333]'
                    : 'bg-purple-500/10 text-purple-400 hover:bg-purple-500/20 border border-purple-500/30'
                  : isGenerating || !prompt.trim() || prompt.length > maxPromptLength
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed border border-gray-200'
                    : 'bg-purple-500 text-white hover:bg-purple-600'
                }
              `}
            >
              {isGenerating ? <LoaderAnimation /> : 'Generate'}
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateContentPopup;