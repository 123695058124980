import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  TrashIcon, 
  CheckIcon,
  ArrowRightIcon,
  UserIcon,
  UserGroupIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';

const ContentStrategyCard = ({ 
  strategy, 
  isDarkMode, 
  onView, 
  onDelete,
  isSelected,
  onSelect,
  selectionMode,
  selectedCount = 0,
  onCancelSelection
}) => {
  // Calculate statistics from the new data structure
  const totalIdeas = strategy.generatedIdeas?.reduce((total, topicIdeas) => {
    return total + (topicIdeas.ideas?.length || 0);
  }, 0) || 0;

  const handleClick = (e) => {
    if (selectionMode) {
      e.stopPropagation();
      onSelect(strategy._id);
    } else {
      onView(strategy._id);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        onClick={handleClick}
        className={`
          group relative
          px-8 py-6
          rounded-xl border
          transition-all duration-300
          cursor-pointer
          ${isDarkMode 
            ? 'bg-black/20 border-white/[0.08] hover:bg-black/30' 
            : 'bg-white border-black/[0.03] hover:bg-zinc-50/50'
          }
          ${isSelected 
            ? isDarkMode
              ? 'ring-2 ring-blue-500/30 border-blue-500/30 bg-blue-500/[0.02]'
              : 'ring-2 ring-blue-500/20 border-blue-500/20 bg-blue-50/50'
            : ''
          }
          ${selectionMode && !isSelected
            ? isDarkMode
              ? 'hover:ring-1 hover:ring-white/10'
              : 'hover:ring-1 hover:ring-black/5'
            : ''
          }
        `}
      >
        {/* Selection Indicator */}
        {selectionMode && (
          <div className="absolute top-1/2 left-3 -translate-y-1/2">
            <div className={`
              w-4 h-4 rounded-full 
              transition-all duration-300 ease-out
              flex items-center justify-center
              ${isSelected
                ? isDarkMode
                  ? 'bg-blue-500 border-blue-400'
                  : 'bg-blue-500 border-blue-400'
                : isDarkMode
                  ? 'border-2 border-white/20'
                  : 'border-2 border-black/20'
              }
            `}>
              {isSelected && (
                <CheckIcon className="w-2.5 h-2.5 text-white" />
              )}
            </div>
          </div>
        )}

        <div className="space-y-4">
          {/* Title & Ideas Count */}
          <div className="flex items-start justify-between gap-4">
            <h3 className={`
              font-medium text-sm leading-tight
              ${isDarkMode ? 'text-white/80' : 'text-black/80'}
            `}>
              {strategy.mainTopic || 
               strategy.topics?.[0]?.name || 
               strategy.generatedIdeas?.[0]?.topic || 
               strategy.contentFocus || 
               'Untitled Strategy'}
            </h3>
            <span className={`
              text-sm tabular-nums font-medium
              ${isDarkMode ? 'text-white/40' : 'text-black/40'}
            `}>
              {totalIdeas} ideas
            </span>
          </div>

          {/* Tags Row */}
          <div className="flex items-center gap-3 text-xs">
            <span className={`
              inline-flex items-center gap-1.5
              ${isDarkMode ? 'text-white/40' : 'text-black/40'}
            `}>
              <UserIcon className="w-3.5 h-3.5" />
              {strategy.persona?.name || 'Unnamed Creator'}
            </span>
            <span className={`
              inline-flex items-center gap-1.5
              ${isDarkMode ? 'text-white/40' : 'text-black/40'}
            `}>
              <UserGroupIcon className="w-3.5 h-3.5" />
              {strategy.audience?.targetAudienceName || 'General Audience'}
            </span>
          </div>

          {/* Action Row */}
          <div className="flex items-center justify-between pt-1">
            {/* View Button */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                onView(strategy._id);
              }}
              className={`
                inline-flex items-center gap-2
                transition-colors duration-300
                ${isDarkMode 
                  ? 'text-white/40 hover:text-white/60' 
                  : 'text-black/40 hover:text-black/60'
                }
              `}
            >
              <span className="text-xs font-medium">View ideas</span>
              <ArrowRightIcon className="w-3.5 h-3.5" />
            </button>

            {/* Delete Button */}
            {!selectionMode && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(strategy);
                }}
                className={`
                  p-1.5 rounded-md
                  transition-colors duration-300
                  ${isDarkMode 
                    ? 'text-white/30 hover:text-red-400 hover:bg-red-500/10' 
                    : 'text-black/30 hover:text-red-500 hover:bg-red-50'
                  }
                `}
              >
                <TrashIcon className="w-3.5 h-3.5" />
              </button>
            )}
          </div>
        </div>
      </motion.div>

      {/* Multi-Selection Action Bar */}
      <AnimatePresence>
        {selectionMode && selectedCount > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed bottom-6 left-1/2 -translate-x-1/2 z-50"
          >
            <div className={`
              flex items-center gap-4 px-6 py-4 rounded-xl shadow-lg
              ${isDarkMode
                ? 'bg-zinc-900/90 border border-white/[0.08] backdrop-blur-xl'
                : 'bg-white/90 border border-black/[0.03] shadow-black/5 backdrop-blur-xl'
              }
            `}>
              <span className={`
                text-sm font-medium
                ${isDarkMode ? 'text-white/60' : 'text-black/60'}
              `}>
                {selectedCount} selected
              </span>

              <div className="h-4 w-px bg-zinc-200/10" />

              <div className="flex items-center gap-3">
                <button
                  onClick={onCancelSelection}
                  className={`
                    inline-flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm
                    transition-colors duration-300
                    ${isDarkMode
                      ? 'text-white/60 hover:bg-white/[0.06]'
                      : 'text-black/60 hover:bg-black/[0.03]'
                    }
                  `}
                >
                  <XMarkIcon className="w-4 h-4" />
                  Cancel
                </button>

                <button
                  onClick={() => {/* Handle delete selected */}}
                  className={`
                    inline-flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm
                    transition-colors duration-300
                    ${isDarkMode
                      ? 'text-red-400 hover:bg-red-500/10'
                      : 'text-red-500 hover:bg-red-50'
                    }
                  `}
                >
                  <TrashIcon className="w-4 h-4" />
                  Delete Selected
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ContentStrategyCard; 