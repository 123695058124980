import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import logo from '../logo.svg';

const LinkedInLoadingOverlay = () => {
  const { isDarkMode } = useTheme();
  const source = localStorage.getItem('linkedin_auth_source');
  const isSignup = source === 'register';

  console.log('🔍 LinkedInLoadingOverlay - Source:', {
    source,
    isSignup
  });

  const messages = {
    signup: {
      title: 'Creating Your Account',
      subtitle: 'Connecting with LinkedIn...',
      description: 'Setting up your professional profile'
    },
    login: {
      title: 'Welcome Back',
      subtitle: 'Authenticating with LinkedIn...',
      description: 'Securely signing you in'
    }
  };

  const currentMessages = isSignup ? messages.signup : messages.login;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`fixed inset-0 z-50 ${
        isDarkMode ? 'bg-[#121212]' : 'bg-white'
      }`}
    >
      <div className="h-full flex items-center justify-center">
        <div className="flex flex-col items-center space-y-8">
          {/* Logo */}
          <motion.img
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="h-12 w-auto"
            src={logo}
            alt="Ammmplify"
          />
          
          {/* LinkedIn Logo Pulse Animation */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ 
              scale: [0.8, 1, 0.8],
              opacity: [0.5, 1, 0.5]
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className="relative"
          >
            <div className={`w-16 h-16 rounded-lg flex items-center justify-center
              ${isDarkMode ? 'bg-[#0A66C2]/20' : 'bg-[#0A66C2]/10'}`}
            >
              <img
                src="/linkedin-icon.svg"
                alt="LinkedIn"
                className="w-8 h-8"
              />
            </div>
          </motion.div>

          {/* Loading Message */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="text-center space-y-2"
          >
            <h2 className={`text-xl font-medium ${
              isDarkMode ? 'text-white' : 'text-slate-900'
            }`}>
              {currentMessages.title}
            </h2>
            <p className={`text-base ${
              isDarkMode ? 'text-slate-300' : 'text-slate-700'
            }`}>
              {currentMessages.subtitle}
            </p>
            <p className={`text-sm ${
              isDarkMode ? 'text-slate-400' : 'text-slate-600'
            }`}>
              {currentMessages.description}
            </p>
          </motion.div>

          {/* Loading Spinner */}
          <div className="relative">
            <motion.div
              animate={{ rotate: 360 }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "linear"
              }}
              className={`w-8 h-8 border-2 rounded-full
                ${isDarkMode 
                  ? 'border-white/10 border-t-white/80' 
                  : 'border-black/10 border-t-black/80'
                }`}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default LinkedInLoadingOverlay; 