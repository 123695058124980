import React, { useState, useEffect, useRef } from 'react';
import { getSavedMessages, unsaveMessage } from '../api';
import toast from 'react-hot-toast';
import MessageCard from './MessageCard';
import MessageModal from './MessageModal';
import { BookmarkIcon, MagnifyingGlassIcon, FunnelIcon, ArrowsUpDownIcon, ChevronDownIcon, ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FolderOpenIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { useNavigate } from 'react-router-dom';

const CustomDropdown = ({ options, value, onChange, icon, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className={`flex items-center justify-between space-x-1 border rounded-md px-2 py-1 text-xs font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 whitespace-nowrap
                    ${isDarkMode 
                      ? 'bg-slate-700 border-slate-600 text-slate-200 hover:bg-slate-600' 
                      : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50'}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          {icon}
          <span className="ml-1">{options.find(opt => opt.value === value)?.label}</span>
        </div>
        <ChevronDownIcon className={`h-3 w-3 flex-shrink-0 ml-1 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
      </button>
      {isOpen && (
        <div className={`absolute z-10 mt-1 w-auto min-w-full shadow-lg max-h-60 rounded-md py-1 text-xs ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none
                         ${isDarkMode ? 'bg-slate-800 border border-slate-700' : 'bg-white border border-gray-200'}`}>
          {options.map((option) => (
            <div
              key={option.value}
              className={`${
                option.value === value 
                  ? isDarkMode ? 'bg-slate-700 text-slate-200' : 'bg-gray-100 text-gray-900'
                  : isDarkMode ? 'text-slate-300 hover:bg-slate-700' : 'text-gray-700 hover:bg-gray-50'
              } cursor-pointer select-none relative py-2 pl-3 pr-9 whitespace-nowrap`}
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const SkeletonLoader = ({ isDarkMode }) => {
  return (
    <div className="flex flex-col h-full w-full">
      {/* Skeleton for Top Bar */}
      <div className={`${isDarkMode ? 'bg-slate-800' : 'bg-white'} bg-opacity-90 !h-10 min-h-10 flex-shrink-0 flex items-center px-4 py-2 w-full border-b ${isDarkMode ? 'border-slate-700' : 'border-gray-200 border-opacity-50'}`}>
        <div className="flex items-center flex-1">
          <div className={`w-4 h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded-full mr-2`}></div>
          <div className={`w-32 h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded`}></div>
        </div>
        <div className="flex items-center space-x-4">
          <div className={`w-48 h-6 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded`}></div>
          <div className={`w-24 h-6 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded`}></div>
          <div className={`w-24 h-6 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded`}></div>
        </div>
      </div>

      {/* Skeleton for Content Area */}
      <div className={`flex-grow overflow-y-auto custom-scrollbar pt-4 ${isDarkMode ? 'bg-slate-900' : 'bg-white'} bg-opacity-80 w-full`}>
        <div className="pl-4 pr-4 pb-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {[...Array(9)].map((_, index) => (
              <div key={index} className={`${isDarkMode ? 'bg-slate-800' : 'bg-white'} rounded-lg shadow-md p-4`}>
                <div className={`h-6 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-3/5 mb-2`}></div>
                <div className={`h-24 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded mb-4`}></div>
                <div className="flex justify-end items-center">
                  <div className="flex space-x-2">
                    <div className={`w-6 h-6 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded-full`}></div>
                    <div className={`w-6 h-6 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded-full`}></div>
                    <div className={`w-6 h-6 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded-full`}></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const EmptyState = ({ isDarkMode }) => (
  <div className="flex flex-col items-center justify-center h-full text-center p-4">
    <FolderOpenIcon className={`h-16 w-16 ${isDarkMode ? 'text-slate-600' : 'text-gray-400'} mb-4`} />
    <h3 className={`text-lg font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'} mb-2`}>No saved messages</h3>
    <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-gray-500'} max-w-sm`}>
      You haven't saved any messages yet. When you do, they'll appear here.
    </p>
  </div>
);

// Add this new component for the expandable search bar
const ExpandableSearchBar = ({ isDarkMode, searchTerm, setSearchTerm, isExpanded, setIsExpanded }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  return (
    <div className="relative">
      {isExpanded ? (
        <div className="flex items-center">
          <input
            ref={inputRef}
            type="text"
            placeholder="Search messages..."
            className={`w-40 pl-8 pr-8 py-1 text-xs border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500
                        ${isDarkMode 
                          ? 'bg-slate-700 text-slate-200 border-slate-600' 
                          : 'bg-white text-gray-900 border-gray-300'}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <MagnifyingGlassIcon className={`absolute left-2 top-1/2 transform -translate-y-1/2 w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
          <button
            onClick={() => setIsExpanded(false)}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsExpanded(true)}
          className={`p-1 rounded-full transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-200'
          }`}
        >
          <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`} />
        </button>
      )}
    </div>
  );
};

const SavedItemsScreen = () => {
  const { isDarkMode } = useTheme();
  const [savedMessages, setSavedMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [sortBy, setSortBy] = useState('date');
  const [lengthFilter, setLengthFilter] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSavedMessages();
  }, []);

  const fetchSavedMessages = async () => {
    setIsLoading(true);
    try {
      const messages = await getSavedMessages();
      setSavedMessages(messages);
    } catch (error) {
      console.error('Error fetching saved messages:', error);
      toast.error('Failed to fetch saved messages. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsaveMessage = async (messageId) => {
    try {
      await unsaveMessage(messageId);
      setSavedMessages(prevMessages => prevMessages.filter(msg => msg._id !== messageId));
      toast.success('Message unsaved successfully');
    } catch (error) {
      console.error('Error unsaving message:', error);
      toast.error(error.response?.data?.message || 'Failed to unsave message. Please try again.');
    }
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      toast.success('Message copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy message');
    });
  };

  const filteredAndSortedMessages = savedMessages
    .filter(message => message.content.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter(message => {
      if (lengthFilter === 'all') return true;
      const length = message.content.length;
      if (lengthFilter === 'short') return length < 50;
      if (lengthFilter === 'medium') return length >= 50 && length <= 200;
      if (lengthFilter === 'long') return length > 200;
      return true;
    })
    .sort((a, b) => {
      if (sortBy === 'date') {
        return new Date(b.timestamp) - new Date(a.timestamp);
      } else if (sortBy === 'name') {
        return a.content.localeCompare(b.content);
      }
      return 0;
    });

  const hasMessages = filteredAndSortedMessages.length > 0;

  const sortOptions = [
    { value: 'date', label: 'Date' },
    { value: 'name', label: 'Name (A-Z)' },
  ];

  const lengthOptions = [
    { value: 'all', label: 'All Lengths' },
    { value: 'short', label: 'Short (<50 chars)' },
    { value: 'medium', label: 'Medium (50-200 chars)' },
    { value: 'long', label: 'Long (>200 chars)' },
  ];

  const handleBackClick = () => {
    navigate('/chat');
  };

  return (
    <div className={`flex flex-col h-full relative overflow-hidden rounded-lg bg-transparent
                    min-w-[300px] w-full max-w-full
                    mx-auto transition-all duration-300 ease-in-out
                    ${isDarkMode ? 'bg-slate-900' : 'bg-white'}`}>
      {isLoading ? (
        <SkeletonLoader isDarkMode={isDarkMode} />
      ) : (
        <>
          {/* Top Bar */}
          <div className={`${isDarkMode ? 'bg-slate-800' : 'bg-white'} 
                          !h-10 min-h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full`}>
            {/* Left section */}
            <div className="flex items-center">
              <BookmarkIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-slate-600'} mr-2`} />
              <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-slate-800'}`}>Saved Messages</h2>
            </div>

            {/* Right section */}
            <div className="flex items-center space-x-2">
              <ExpandableSearchBar
                isDarkMode={isDarkMode}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                isExpanded={isSearchExpanded}
                setIsExpanded={setIsSearchExpanded}
              />
              <CustomDropdown
                options={sortOptions}
                value={sortBy}
                onChange={setSortBy}
                icon={<ArrowsUpDownIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />}
                isDarkMode={isDarkMode}
              />
              <CustomDropdown
                options={lengthOptions}
                value={lengthFilter}
                onChange={setLengthFilter}
                icon={<FunnelIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />}
                isDarkMode={isDarkMode}
              />
              <button
                onClick={handleBackClick}
                className={`p-1 rounded-full transition-colors duration-200 ${
                  isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-200'
                }`}
                aria-label="Back to Chat"
              >
                <ArrowLeftIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`} />
              </button>
            </div>
          </div>

          {/* Content Area */}
          <div className={`flex-grow overflow-y-auto custom-scrollbar pt-4 ${isDarkMode ? 'bg-slate-900' : 'bg-white'} bg-opacity-80`}>
            {hasMessages ? (
              <div className="pl-4 pr-4 pb-20">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 justify-start">
                  {filteredAndSortedMessages.map((message) => (
                    <MessageCard
                      key={message._id}
                      message={message}
                      onView={() => setSelectedMessage(message)}
                      onCopy={() => copyToClipboard(message.content)}
                      onUnsave={() => handleUnsaveMessage(message._id)}
                      isDarkMode={isDarkMode}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <EmptyState isDarkMode={isDarkMode} />
            )}
          </div>
        </>
      )}

      {selectedMessage && (
        <MessageModal
          message={selectedMessage}
          onClose={() => setSelectedMessage(null)}
          onCopy={() => copyToClipboard(selectedMessage.content)}
          onUnsave={() => handleUnsaveMessage(selectedMessage._id)}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
};

export default SavedItemsScreen;