import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import toast from 'react-hot-toast';

// Constants for styling
const STYLE_CONSTANTS = {
  baseStyle: 'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2',
  darkModeStyles: {
    TITLE: 'bg-blue-500/20 text-blue-400',
    OPENING: 'bg-green-500/20 text-green-400',
    'MAIN CONTENT': 'bg-purple-500/20 text-purple-400',
    CLOSING: 'bg-orange-500/20 text-orange-400'
  },
  lightModeStyles: {
    TITLE: 'bg-blue-100 text-blue-800',
    OPENING: 'bg-green-100 text-green-800',
    'MAIN CONTENT': 'bg-purple-100 text-purple-800',
    CLOSING: 'bg-orange-100 text-orange-800'
  }
};

// Section validation
const REQUIRED_SECTIONS = ['TITLE', 'OPENING', 'MAIN CONTENT', 'CLOSING'];

const VideoScriptMacro = ({ content, isDarkMode }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Memoized section parsing
  const parseScriptSections = useCallback((content) => {
    const sections = {};
    const sectionRegex = /\[(TITLE|OPENING|MAIN CONTENT|CLOSING)\]([\s\S]*?)(?=\[(?:TITLE|OPENING|MAIN CONTENT|CLOSING)\]|$)/g;
    let match;

    while ((match = sectionRegex.exec(content)) !== null) {
      sections[match[1]] = match[2].trim();
    }

    // Validate required sections
    const missingSections = REQUIRED_SECTIONS.filter(section => !sections[section]);
    if (missingSections.length > 0) {
      console.warn(`Missing required sections: ${missingSections.join(', ')}`);
    }

    return sections;
  }, []);

  const sections = useMemo(() => parseScriptSections(content), [content, parseScriptSections]);

  // Memoized style getter
  const getSectionStyle = useCallback((type) => {
    const styles = isDarkMode ? STYLE_CONSTANTS.darkModeStyles : STYLE_CONSTANTS.lightModeStyles;
    return `${STYLE_CONSTANTS.baseStyle} ${styles[type] || ''}`;
  }, [isDarkMode]);

  // Enhanced copy handler with error handling and feedback
  const handleCopy = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      toast.success('Script copied to clipboard!', {
        position: 'top-center',
        style: isDarkMode 
          ? { background: '#1e1e1e', color: '#fff', border: '1px solid #333333' }
          : { background: '#fff', color: '#000', border: '1px solid #e5e7eb' }
      });
    } catch (error) {
      console.error('Copy failed:', error);
      toast.error('Failed to copy script', {
        position: 'top-center',
        style: isDarkMode 
          ? { background: '#1e1e1e', color: '#fff', border: '1px solid #333333' }
          : { background: '#fff', color: '#000', border: '1px solid #e5e7eb' }
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => setIsCopied(false), 2000);
    }
  };

  // Keyboard handler for accessibility
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleCopy(e);
    }
  };

  return (
    <div 
      className={`
        relative group rounded-lg border shadow-sm mt-4 overflow-hidden
        ${isDarkMode 
          ? 'bg-[#151515] border-[#333333]' 
          : 'bg-white border-gray-200'
        }
      `}
      role="article"
      aria-label="Video Script"
    >
      {/* Macro Window Header */}
      <div 
        className={`
          flex items-center justify-between px-4 py-2 border-b
          ${isDarkMode ? 'bg-[#1a1a1a] border-[#333333]' : 'bg-gray-50 border-gray-200'}
        `}
      >
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1.5">
            <div className="w-3 h-3 rounded-full bg-red-500"></div>
            <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
          </div>
          <span 
            className={`
              text-xs font-medium ml-2 font-inter tracking-tight
              ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
            `}
          >
            Video Script
          </span>
        </div>
        <button
          onClick={handleCopy}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          className={`
            p-1.5 rounded-md transition-all duration-200
            ${isCopied 
              ? isDarkMode 
                ? 'bg-green-500/20 text-green-400' 
                : 'bg-green-100 text-green-600'
              : isDarkMode 
                ? 'bg-[#252525] hover:bg-[#333333] text-gray-400 hover:text-gray-300' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700'
            }
            ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
          `}
          title="Copy script"
          aria-label="Copy script to clipboard"
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={isCopied ? 'check' : 'copy'}
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {isCopied ? (
                <CheckIcon className="w-3.5 h-3.5" />
              ) : (
                <ClipboardIcon className="w-3.5 h-3.5" />
              )}
            </motion.div>
          </AnimatePresence>
        </button>
      </div>

      {/* Script Content */}
      <div 
        className={`
          p-4 space-y-4 font-jetbrains text-sm whitespace-pre-wrap leading-relaxed tracking-tight
          ${isDarkMode ? 'text-slate-300' : 'text-slate-800'}
        `}
      >
        {Object.entries(sections).map(([type, content]) => (
          <motion.div 
            key={type}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="space-y-2"
          >
            <div className={getSectionStyle(type)}>
              {type}
            </div>
            <div 
              className={`
                pl-4 border-l-2 
                ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}
              `}
            >
              {content}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

VideoScriptMacro.propTypes = {
  content: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default React.memo(VideoScriptMacro); 