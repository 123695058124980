import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import SubscriptionCard from '../components/Subscription/SubscriptionCard';
import PublicPageLayout from '../components/public/layout/PublicPageLayout';
import GeoService from '../services/geoService';

const PublicSubscriptionPage = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [userCountry, setUserCountry] = useState(null);

  // Detect user's country on component mount
  useEffect(() => {
    const detectCountry = async () => {
      try {
        await GeoService.detectUserCountry();
        const country = GeoService.getUserCountry();
        setUserCountry(country);
      } catch (error) {
        console.error('Error detecting country:', error);
        setUserCountry('US'); // Default to US if detection fails
      }
    };
    
    detectCountry();
  }, []);

  // Handler for subscription attempts
  const handleSubscriptionAttempt = () => {
    // Store subscription intent
    localStorage.setItem('subscription_intent', JSON.stringify({
      type: 'direct',
      timestamp: new Date().toISOString()
    }));
    navigate('/register');
  };

  // Handler for trial attempts
  const handleTrialAttempt = (planId) => {
    // Store trial intent with plan details and country
    localStorage.setItem('trial_intent', JSON.stringify({
      planId,
      country: userCountry,
      timestamp: new Date().toISOString(),
      isYearly: searchParams.get('yearly') === 'true'
    }));
    
    // Redirect to registration with trial parameters
    navigate(`/register?trial=true&plan=${planId}${searchParams.get('yearly') ? '&yearly=true' : ''}`);
  };

  return (
    <PublicPageLayout
      title="Pricing"
      description="Choose the perfect plan for your content creation journey"
    >
      <div className="flex-1 flex items-center justify-center py-8">
        <div className="w-full max-w-5xl px-4 relative">
          {/* Background gradient effect */}
          <div className="absolute inset-0 flex justify-center pointer-events-none">
            <div className={`w-full h-full max-w-lg blur-[120px] opacity-20 ${
              isDarkMode 
                ? 'bg-gradient-to-r from-indigo-500/30 via-purple-500/30 to-pink-500/30' 
                : 'bg-[#ffffff]'
            }`}></div>
          </div>
          
          {/* Content */}
          <div className="relative w-full">
            {userCountry && (
              <SubscriptionCard 
                subscription={null} 
                isPublic={true}
                onPublicSubscribe={handleSubscriptionAttempt}
                onStartTrial={handleTrialAttempt}
                showTrialInPublic={true}
                userCountry={userCountry}
              />
            )}
          </div>
        </div>
      </div>
    </PublicPageLayout>
  );
};

export default PublicSubscriptionPage; 