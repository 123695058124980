import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import { CanvasGridIcon } from './icons/CanvasGridIcon';
import { 
  BookOpenIcon,
  ChartBarIcon,
  FolderIcon,
  StarIcon,
  DocumentTextIcon,
  BeakerIcon,
  BriefcaseIcon,
  CalendarIcon,
  ChatBubbleLeftIcon,
  CodeBracketIcon,
  CogIcon,
  CommandLineIcon,
  CpuChipIcon,
  GlobeAltIcon,
  HeartIcon,
  LightBulbIcon,
  LinkIcon,
  MusicalNoteIcon,
  PhotoIcon,
  PuzzlePieceIcon,
  RocketLaunchIcon,
  ShoppingBagIcon,
  VideoCameraIcon,
  WrenchIcon
} from '@heroicons/react/24/outline';

const icons = {
  default: CanvasGridIcon,
  books: BookOpenIcon,
  chart: ChartBarIcon,
  folder: FolderIcon,
  star: StarIcon,
  note: DocumentTextIcon,
  beaker: BeakerIcon,
  briefcase: BriefcaseIcon,
  calendar: CalendarIcon,
  chat: ChatBubbleLeftIcon,
  code: CodeBracketIcon,
  settings: CogIcon,
  terminal: CommandLineIcon,
  chip: CpuChipIcon,
  globe: GlobeAltIcon,
  heart: HeartIcon,
  bulb: LightBulbIcon,
  link: LinkIcon,
  music: MusicalNoteIcon,
  photo: PhotoIcon,
  puzzle: PuzzlePieceIcon,
  rocket: RocketLaunchIcon,
  shopping: ShoppingBagIcon,
  video: VideoCameraIcon,
  tools: WrenchIcon
};

const CanvasIconPicker = ({ isOpen, onClose, onSelect, currentIcon, isDarkMode, anchorEl }) => {
  if (!isOpen || !anchorEl) return null;

  const rect = anchorEl.getBoundingClientRect();

  const handleOverlayClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return createPortal(
    <AnimatePresence>
      <div className="fixed inset-0 z-[9999]" onClick={handleOverlayClick}>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.1 }}
          className={`
            fixed p-3 rounded-lg shadow-lg
            grid grid-cols-5 gap-2
            ${isDarkMode 
              ? 'bg-[#1a1a1a] border border-[#333333]' 
              : 'bg-white border border-slate-200'
            }
          `}
          style={{
            top: rect.bottom + 5,
            left: rect.left,
            minWidth: '280px',
            transform: 'translateZ(0)'
          }}
          onClick={e => e.stopPropagation()}
        >
          {Object.entries(icons).map(([name, Icon]) => (
            <motion.button
              key={name}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(name);
              }}
              className={`
                p-2 rounded-lg
                transition-colors duration-200
                ${currentIcon === name
                  ? isDarkMode
                    ? 'bg-blue-500/20 text-blue-400'
                    : 'bg-blue-50 text-blue-600'
                  : isDarkMode
                    ? 'hover:bg-[#333333] text-slate-400'
                    : 'hover:bg-slate-100 text-slate-600'
                }
              `}
            >
              <Icon className="w-5 h-5" />
            </motion.button>
          ))}
        </motion.div>
      </div>
    </AnimatePresence>,
    document.body
  );
};

CanvasIconPicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  currentIcon: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any
};

export default CanvasIconPicker; 