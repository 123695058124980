import React, { createContext, useContext, useState, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Set up PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const MediaPreviewContext = createContext();

export const MediaPreviewProvider = ({ children, isDarkMode }) => {
  const [previewState, setPreviewState] = useState({
    isOpen: false,
    mediaUrl: null,
    mediaType: null,
    mediaName: null
  });

  const openPreview = ({ url, type, name }) => {
    setPreviewState({
      isOpen: true,
      mediaUrl: url,
      mediaType: type,
      mediaName: name
    });
  };

  const closePreview = () => {
    setPreviewState({
      isOpen: false,
      mediaUrl: null,
      mediaType: null,
      mediaName: null
    });
  };

  // Add PDFPreview component
  const PDFPreview = ({ url }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.0);
    const containerRef = useRef(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };

    const handlePrevPage = () => {
      setPageNumber(prev => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
      setPageNumber(prev => Math.min(prev + 1, numPages));
    };

    const handleZoomIn = () => {
      setScale(prev => Math.min(prev + 0.2, 2.0));
    };

    const handleZoomOut = () => {
      setScale(prev => Math.max(prev - 0.2, 0.5));
    };

    return (
      <div className="flex flex-col items-center max-h-full overflow-hidden">
        {/* Controls */}
        <div className={`
          w-full 
          flex 
          items-center 
          justify-between 
          p-2 
          mb-4
          rounded-lg
          ${isDarkMode ? 'bg-gray-800' : 'bg-gray-100'}
        `}>
          <div className="flex items-center gap-2">
            <button
              onClick={handlePrevPage}
              disabled={pageNumber <= 1}
              className={`
                p-1.5 
                rounded-md 
                ${isDarkMode 
                  ? 'hover:bg-gray-700 disabled:bg-gray-900' 
                  : 'hover:bg-gray-200 disabled:bg-gray-50'
                }
                disabled:opacity-50
                transition-colors
              `}
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              Page {pageNumber} of {numPages || '--'}
            </span>
            <button
              onClick={handleNextPage}
              disabled={pageNumber >= numPages}
              className={`
                p-1.5 
                rounded-md 
                ${isDarkMode 
                  ? 'hover:bg-gray-700 disabled:bg-gray-900' 
                  : 'hover:bg-gray-200 disabled:bg-gray-50'
                }
                disabled:opacity-50
                transition-colors
              `}
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={handleZoomOut}
              disabled={scale <= 0.5}
              className={`
                p-1.5 
                rounded-md 
                ${isDarkMode 
                  ? 'hover:bg-gray-700 disabled:bg-gray-900' 
                  : 'hover:bg-gray-200 disabled:bg-gray-50'
                }
                disabled:opacity-50
                transition-colors
              `}
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
              </svg>
            </button>
            <span className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              {Math.round(scale * 100)}%
            </span>
            <button
              onClick={handleZoomIn}
              disabled={scale >= 2.0}
              className={`
                p-1.5 
                rounded-md 
                ${isDarkMode 
                  ? 'hover:bg-gray-700 disabled:bg-gray-900' 
                  : 'hover:bg-gray-200 disabled:bg-gray-50'
                }
                disabled:opacity-50
                transition-colors
              `}
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
            </button>
          </div>
        </div>

        {/* PDF Viewer */}
        <div 
          ref={containerRef}
          className="flex-1 overflow-auto w-full"
          style={{ maxHeight: 'calc(90vh - 100px)' }}
        >
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            className="flex justify-center"
          >
            <Page
              pageNumber={pageNumber}
              scale={scale}
              className={`
                shadow-lg 
                rounded-lg 
                ${isDarkMode ? 'bg-gray-800' : 'bg-white'}
              `}
            />
          </Document>
        </div>
      </div>
    );
  };

  const MediaPreviewModal = () => {
    if (!previewState.isOpen) return null;

    return (
      <div 
        className="fixed inset-0 z-[99999] flex items-center justify-center bg-black/90"
        onClick={closePreview}
      >
        <div className="relative w-full h-full flex items-center justify-center p-4">
          {/* Close button */}
          <button
            onClick={closePreview}
            className="absolute top-4 right-4 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>

          {/* Media content */}
          <div 
            className="max-w-[90%] max-h-[90vh] relative"
            onClick={(e) => e.stopPropagation()}
          >
            {previewState.mediaType?.startsWith('image/') ? (
              <img
                src={previewState.mediaUrl}
                alt="Media preview"
                className="max-w-full max-h-[90vh] object-contain rounded-lg"
              />
            ) : previewState.mediaType === 'video/mp4' ? (
              <video
                src={previewState.mediaUrl}
                controls
                className="max-w-full max-h-[90vh] rounded-lg"
                autoPlay
              />
            ) : previewState.mediaType === 'application/pdf' ? (
              <PDFPreview url={previewState.mediaUrl} />
            ) : (
              <div className={`
                p-8 rounded-lg
                ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'}
              `}>
                <div className="flex items-center gap-3">
                  <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
                  <span className="text-lg">{previewState.mediaName || 'File'}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <MediaPreviewContext.Provider value={{ openPreview, closePreview }}>
      {children}
      <MediaPreviewModal />
    </MediaPreviewContext.Provider>
  );
};

export const useMediaPreview = () => {
  const context = useContext(MediaPreviewContext);
  if (!context) {
    throw new Error('useMediaPreview must be used within a MediaPreviewProvider');
  }
  return context;
}; 