import React, { useRef, forwardRef, useEffect, useState } from 'react';
import { CheckIcon, XMarkIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { uploadLinkedInPostMedia, getSignedUrl } from '../services/linkedinPostMediaService';
import AnimatedLoader from './common/AnimatedLoader';
import { toast } from 'react-hot-toast';
import PDFPreview from './PDFPreview';
import FontStylePillBar from './FontStylePillBar';
import ListStylePillBar from './ListStylePillBar';
import OrderStylePillBar from './OrderStylePillBar';
import { convertToUnicodeFont, isUnicodeStyle, revertToNormalText } from '../utils/unicodeFonts';

/**
 * A simple contentEditable component that leverages browser's native capabilities
 */
const CanvasPostContentEditor = forwardRef(({ 
  content, 
  onSave, 
  onCancel, 
  isDarkMode,
  isEditing,
  removeBorderAndPadding = false,
  hideSaveCancelButtons = false,
  hideMediaButton = false,
  onChange,
  mediaFile,
  mediaPreview,
  onMediaSelect,
  onMediaRemove,
  isUploading,
  uploadProgress,
}, ref) => {
  const contentEditableRef = useRef(null);
  const fileInputRef = useRef(null);
  const [localMediaFile, setLocalMediaFile] = useState(null);
  const [localMediaPreview, setLocalMediaPreview] = useState(null);
  const [isLocalUploading, setIsLocalUploading] = useState(false);
  const [localUploadProgress, setLocalUploadProgress] = useState(0);
  const [selectedFontStyle, setSelectedFontStyle] = useState(null);
  const [selectedListStyle, setSelectedListStyle] = useState(null);
  const [selectedOrderStyle, setSelectedOrderStyle] = useState(null);

  // Add effect to focus when editing starts
  useEffect(() => {
    if (isEditing && contentEditableRef.current) {
      contentEditableRef.current.focus();
      // Place cursor at the end of the content
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [isEditing]);

  // Set initial content only once when the component mounts or when content prop changes
  useEffect(() => {
    if (contentEditableRef.current && contentEditableRef.current.innerText !== content) {
      contentEditableRef.current.innerText = content || '';
    }
  }, [content]);

  // Expose the contentEditable ref through forwardRef
  React.useImperativeHandle(ref, () => ({
    focus: () => contentEditableRef.current?.focus(),
    getContent: () => contentEditableRef.current?.innerText || '',
    setContent: (text) => {
      if (contentEditableRef.current) {
        contentEditableRef.current.innerText = text;
      }
    }
  }));

  // Handle font style selection with proper Unicode support
  const handleFontStyleSelect = (styleId) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) {
      toast.error('Please select some text first');
      return;
    }

    const range = selection.getRangeAt(0);
    if (range.collapsed) {
      toast.error('Please select some text first');
      return;
    }

    try {
      // Get the selected text and its start/end positions
      const selectedText = range.toString();
      const preSelectionRange = range.cloneRange();
      preSelectionRange.selectNodeContents(contentEditableRef.current);
      preSelectionRange.setEnd(range.startContainer, range.startOffset);
      const start = preSelectionRange.toString().length;

      // Convert the text
      const convertedText = convertToUnicodeFont(selectedText, styleId);
      
      // Create a new span element to preserve Unicode characters
      const span = document.createElement('span');
      span.style.fontFamily = 'inherit'; // Inherit font family
      span.textContent = convertedText;
      
      // Store the selection range boundaries
      const startContainer = range.startContainer;
      const startOffset = range.startOffset;
      const endContainer = range.endContainer;
      const endOffset = range.endOffset;
      
      // Replace the selection with the new span
      range.deleteContents();
      range.insertNode(span);
      
      // Create a new range for the inserted content
      const newRange = document.createRange();
      newRange.setStart(span, 0);
      newRange.setEnd(span, 1);
      
      // Update selection to maintain the original selection
      selection.removeAllRanges();
      selection.addRange(newRange);

      // Normalize the content to clean up text nodes
      contentEditableRef.current.normalize();

      // Trigger onChange with the new content
      if (onChange) {
        onChange(contentEditableRef.current.innerText);
      }

      setSelectedFontStyle(styleId);
    } catch (error) {
      console.error('Error applying Unicode font:', error);
      toast.error('Failed to apply font style. Please try again.');
    }
  };

  // Handle only special key combinations
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      onSave?.();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      onCancel?.();
    } else if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'b') {
      e.preventDefault();
      const selection = window.getSelection();
      if (selection.rangeCount && !selection.getRangeAt(0).collapsed) {
        const selectedText = selection.getRangeAt(0).toString();
        const range = selection.getRangeAt(0);
        
        // Store the selection range boundaries
        const startContainer = range.startContainer;
        const startOffset = range.startOffset;
        const endContainer = range.endContainer;
        const endOffset = range.endOffset;

        // Check if text is already bold italic
        if (isUnicodeStyle(selectedText, 'sansBoldItalic')) {
          // If CMD/CTRL + B is pressed on bold italic, make it just italic
          handleFontStyleSelect('sansItalic');
        } else if (isUnicodeStyle(selectedText, 'sansBold')) {
          // If already bold, revert to normal
          const normalText = revertToNormalText(selectedText);
          const span = document.createElement('span');
          span.textContent = normalText;
          range.deleteContents();
          range.insertNode(span);
          
          // Create a new range for the inserted content
          const newRange = document.createRange();
          newRange.setStart(span, 0);
          newRange.setEnd(span, 1);
          
          // Update selection
          selection.removeAllRanges();
          selection.addRange(newRange);
          
          setSelectedFontStyle(null);
        } else if (isUnicodeStyle(selectedText, 'sansItalic')) {
          // If italic and pressing bold, make it bold italic
          handleFontStyleSelect('sansBoldItalic');
        } else {
          // If normal text, make it bold
          handleFontStyleSelect('sansBold');
        }
      }
    } else if ((e.metaKey || e.ctrlKey) && e.key.toLowerCase() === 'i') {
      e.preventDefault();
      const selection = window.getSelection();
      if (selection.rangeCount && !selection.getRangeAt(0).collapsed) {
        const selectedText = selection.getRangeAt(0).toString();
        const range = selection.getRangeAt(0);
        
        // Store the selection range boundaries
        const startContainer = range.startContainer;
        const startOffset = range.startOffset;
        const endContainer = range.endContainer;
        const endOffset = range.endOffset;

        // Check if text is already bold italic
        if (isUnicodeStyle(selectedText, 'sansBoldItalic')) {
          // If CMD/CTRL + I is pressed on bold italic, make it just bold
          handleFontStyleSelect('sansBold');
        } else if (isUnicodeStyle(selectedText, 'sansItalic')) {
          // If already italic, revert to normal
          const normalText = revertToNormalText(selectedText);
          const span = document.createElement('span');
          span.textContent = normalText;
          range.deleteContents();
          range.insertNode(span);
          
          // Create a new range for the inserted content
          const newRange = document.createRange();
          newRange.setStart(span, 0);
          newRange.setEnd(span, 1);
          
          // Update selection
          selection.removeAllRanges();
          selection.addRange(newRange);
          
          setSelectedFontStyle(null);
        } else if (isUnicodeStyle(selectedText, 'sansBold')) {
          // If bold and pressing italic, make it bold italic
          handleFontStyleSelect('sansBoldItalic');
        } else {
          // If normal text, make it italic
          handleFontStyleSelect('sansItalic');
        }
      }
    }
  };

  const handleChange = (newContent) => {
    if (onChange) {
      onChange(newContent);
    }
  };

  // Update useEffect for media initialization
  useEffect(() => {
    if (mediaFile && mediaPreview) {
      setLocalMediaFile(mediaFile);
      setLocalMediaPreview(mediaPreview);
    }
  }, [mediaFile, mediaPreview]);

  // Update handleMediaSelect to sync with parent immediately
  const handleMediaSelect = async (e) => {
    try {
      // Add defensive checks
      if (!e) {
        console.warn('Event object is undefined');
        return;
      }

      // Handle both direct File objects and event.target.files
      const files = e.target?.files || (e instanceof File ? [e] : null);
      
      if (!files || files.length === 0) {
        console.warn('No files selected');
        return;
      }

      const file = files[0];
      console.log('Selected file:', file);

      // Validate file type and size
      const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf', 'video/mp4'];
      const maxSize = 100 * 1024 * 1024; // 100MB

      if (!validTypes.includes(file.type)) {
        toast.error('Please select a valid file (JPEG, PNG, GIF, PDF, or MP4)');
        return;
      }

      if (file.size > maxSize) {
        toast.error('File size should be less than 100MB');
        return;
      }

      // Get token from multiple possible locations
      const accessToken = localStorage.getItem('accessToken');
      const supabaseSession = localStorage.getItem('supabase_session');
      const linkedinToken = localStorage.getItem('linkedin_access_token');
      
      const authToken = accessToken || (supabaseSession && JSON.parse(supabaseSession).access_token) || linkedinToken;

      if (!authToken) {
        toast.error('Please log in to upload media');
        return;
      }

      // Set temporary preview
      const tempPreviewUrl = URL.createObjectURL(file);
      setLocalMediaFile(file);
      setLocalMediaPreview(tempPreviewUrl);
      setIsLocalUploading(true);
      setLocalUploadProgress(0);

      console.log('Starting upload to Cloudinary...');

      // Upload to Cloudinary
      const uploadedMedia = await uploadLinkedInPostMedia(file, authToken, (progress) => {
        setLocalUploadProgress(progress);
      });

      // Detailed debug logging
      console.log('Raw Cloudinary response:', uploadedMedia);
      console.log('Secure URL:', uploadedMedia?.secure_url);
      console.log('Public ID:', uploadedMedia?.public_id);

      // Create media info object with proper URL handling
      const mediaInfo = {
        originalName: file.name,
        cloudinaryUrl: uploadedMedia?.secure_url || uploadedMedia?.url,
        url: uploadedMedia?.secure_url || uploadedMedia?.url,
        mimeType: file.type,
        size: file.size,
        uploadedAt: new Date().toISOString(),
        metadata: {
          width: uploadedMedia?.width || null,
          height: uploadedMedia?.height || null,
          duration: uploadedMedia?.duration || null,
          format: uploadedMedia?.format || file.type.split('/')[1],
          resource_type: uploadedMedia?.resource_type || 'image',
          public_id: uploadedMedia?.public_id || uploadedMedia?.asset_id || uploadedMedia?.public_id || null
        }
      };

      // More detailed validation
      if (!mediaInfo.cloudinaryUrl) {
        console.error('Missing Cloudinary URL:', uploadedMedia);
        throw new Error('Missing Cloudinary URL in response');
      }

      if (!mediaInfo.metadata.public_id) {
        console.error('Missing public_id:', uploadedMedia);
        // Don't throw here, just log warning
        console.warn('Missing public_id in Cloudinary response');
      }

      // Cleanup temporary preview
      URL.revokeObjectURL(tempPreviewUrl);

      // Update local state
      setLocalMediaFile(mediaInfo);
      setLocalMediaPreview(mediaInfo.cloudinaryUrl || mediaInfo.url);
      setIsLocalUploading(false);
      setLocalUploadProgress(0);

      // Notify parent component immediately
      if (onMediaSelect) {
        onMediaSelect(mediaInfo);
      }

      toast.success('Media uploaded successfully');
    } catch (error) {
      console.error('Error uploading media:', error);
      toast.error(error.message || 'Failed to upload media');
      setLocalMediaFile(null);
      setLocalMediaPreview(null);
      setIsLocalUploading(false);
      setLocalUploadProgress(0);
    }
  };

  // Update handleMediaRemove to sync with parent immediately
  const handleMediaRemove = () => {
    if (localMediaPreview?.startsWith('blob:')) {
      URL.revokeObjectURL(localMediaPreview);
    }
    setLocalMediaFile(null);
    setLocalMediaPreview(null);
    setIsLocalUploading(false);
    setLocalUploadProgress(0);

    if (onMediaRemove) {
      onMediaRemove();
    }
  };

  // Add list formatting function
  const handleListStyleSelect = (styleId) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) {
      toast.error('Please select some text first');
      return;
    }

    const range = selection.getRangeAt(0);
    const selectedText = range.toString();
    
    if (!selectedText.trim()) {
      toast.error('Please select some text first');
      return;
    }

    try {
      // Split text into sentences first, then process each sentence
      const sentences = selectedText
        .split(/[.!?]+\s*/g)  // Split on sentence endings (., !, ?) followed by whitespace
        .filter(sentence => sentence.trim().length > 0)  // Remove empty sentences
        .map(sentence => sentence.trim());  // Clean up each sentence

      if (sentences.length === 0) {
        toast.error('Please select some text to format');
        return;
      }

      // Format each sentence based on the selected style
      const formattedLines = sentences.map((sentence, index) => {
        // Remove any existing list markers
        const cleanSentence = sentence
          .replace(/^[•\-→★✓]\s+/, '') // Remove bullet, dash, arrow, star, check
          .replace(/^\d+\.\s+/, '') // Remove numbered list
          .replace(/^[-–—]\s+/, '') // Remove different types of dashes
          .trim();

        switch (styleId) {
          case 'bullet':
            return `• ${cleanSentence}`;
          case 'numbered':
            return `${index + 1}. ${cleanSentence}`;
          case 'dash':
            return `— ${cleanSentence}`;
          case 'check':
            return `✓ ${cleanSentence}`;
          case 'star':
            return `★ ${cleanSentence}`;
          case 'arrow':
            return `→ ${cleanSentence}`;
          default:
            return cleanSentence;
        }
      });

      // Join the sentences back together with proper line breaks
      const formattedText = formattedLines.join('\n');
      
      // Replace the selection with the formatted text
      range.deleteContents();
      const textNode = document.createTextNode(formattedText);
      range.insertNode(textNode);
      
      // Update selection to be after the inserted text
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);

      // Normalize the content to clean up text nodes
      contentEditableRef.current.normalize();

      // Trigger onChange with the new content
      if (onChange) {
        onChange(contentEditableRef.current.innerText);
      }

      setSelectedListStyle(styleId);
    } catch (error) {
      console.error('Error applying list style:', error);
      toast.error('Failed to apply list style. Please try again.');
    }
  };

  // Add handleOrderStyleSelect function
  const handleOrderStyleSelect = (styleId) => {
    const selection = window.getSelection();
    if (!selection.rangeCount) {
      toast.error('Please select some text first');
      return;
    }

    const range = selection.getRangeAt(0);
    const selectedText = range.toString();
    
    if (!selectedText.trim()) {
      toast.error('Please select some text first');
      return;
    }

    try {
      // Split text into sentences while preserving their original endings
      const sentencesWithEndings = selectedText.match(/[^.!?]+[.!?]+/g) || [];
      
      if (sentencesWithEndings.length === 0) {
        toast.error('Please select complete sentences to order');
        return;
      }

      // Create array of sentence objects to preserve original endings
      const sentences = sentencesWithEndings.map(sentence => {
        const match = sentence.match(/([^.!?]+)([.!?]+\s*)/);
        return {
          text: match[1].trim(),
          ending: match[2],
          length: match[1].trim().length
        };
      });

      // Sort sentences based on length while preserving their endings
      const sortedSentences = [...sentences].sort((a, b) => {
        return styleId === 'ascending' ? a.length - b.length : b.length - a.length;
      });

      // Join the sentences back together with their original endings and line breaks
      const orderedText = sortedSentences
        .map(sentence => `${sentence.text}${sentence.ending}`)
        .join('\n');

      // Replace the selection with the ordered text
      range.deleteContents();
      const textNode = document.createTextNode(orderedText);
      range.insertNode(textNode);

      // Update selection to be after the inserted text
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);

      // Normalize the content to clean up text nodes
      contentEditableRef.current.normalize();

      // Trigger onChange with the new content
      if (onChange) {
        onChange(contentEditableRef.current.innerText);
      }

      setSelectedOrderStyle(styleId);
    } catch (error) {
      console.error('Error ordering sentences:', error);
      toast.error('Failed to order sentences. Please try again.');
    }
  };

  return (
    <div className="relative canvas-post-editor">
      {!hideSaveCancelButtons && (
        <div className="absolute -top-10 right-0 flex space-x-2">
          <button
            type="button"
            onClick={onCancel}
            disabled={isLocalUploading}
            className={`
              px-2.5 py-1.5 rounded-md transition-all duration-200
              ${isDarkMode 
                ? 'bg-[#151515] hover:bg-[#1a1a1a] text-slate-400 active:bg-[#121212]' 
                : 'bg-gray-200 hover:bg-gray-300 text-gray-600 active:bg-gray-400'}
              text-xs flex items-center gap-1.5
              ${isLocalUploading ? 'opacity-50 cursor-not-allowed' : ''}
            `}
          >
            <XMarkIcon className="h-3.5 w-3.5" />
            <span className="font-medium">Cancel</span>
          </button>
          <button
            type="button"
            onClick={onSave}
            disabled={isLocalUploading}
            className={`
              px-2.5 py-1.5 rounded-md transition-all duration-200
              ${isDarkMode 
                ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 active:bg-[#151515]' 
                : 'bg-blue-500 hover:bg-blue-600 text-white active:bg-blue-700'}
              text-xs flex items-center gap-1.5
              ${isLocalUploading ? 'opacity-50 cursor-not-allowed' : ''}
            `}
          >
            <CheckIcon className="h-3.5 w-3.5" />
            <span className="font-medium">{isLocalUploading ? 'Uploading...' : 'Save'}</span>
          </button>
        </div>
      )}

      {isEditing && (
        <div className="mb-3">
          <div className="flex items-center gap-1.5">
            <FontStylePillBar
              onSelectStyle={handleFontStyleSelect}
              selectedStyle={selectedFontStyle}
              isDarkMode={isDarkMode}
            />
            <ListStylePillBar
              onSelectStyle={handleListStyleSelect}
              selectedStyle={selectedListStyle}
              isDarkMode={isDarkMode}
            />
            <OrderStylePillBar
              onSelectStyle={handleOrderStyleSelect}
              selectedStyle={selectedOrderStyle}
              isDarkMode={isDarkMode}
            />
          </div>
        </div>
      )}

      <div
        ref={contentEditableRef}
        contentEditable={isEditing}
        suppressContentEditableWarning
        onKeyDown={handleKeyDown}
        onInput={(e) => handleChange(e.currentTarget.innerText)}
        className={`
          outline-none transition-colors duration-200
          font-sans
          ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}
          ${isEditing ? 'cursor-text caret-current' : 'cursor-default'}
          ${isEditing && isDarkMode ? 'bg-[#151515]' : ''}
        `}
        style={{
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          WebkitUserModify: 'read-write-plaintext-only',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          fontFeatureSettings: '"liga" 0',
          lineHeight: '1.5',
          letterSpacing: '0.01em'
        }}
        spellCheck="false"
        translate="no"
        data-gramm="false"
        dir="auto"
      />

      {(mediaPreview || localMediaPreview) && (
        <div className="mt-4 relative group">
          {((mediaFile?.type || localMediaFile?.type)?.startsWith('image/')) ? (
            <div className="relative w-full">
              <img 
                src={localMediaPreview || mediaPreview} 
                alt={localMediaFile?.originalName || mediaFile?.originalName || "Post media"} 
                className="rounded-lg max-h-[400px] w-auto object-contain mx-auto"
              />
              {(isUploading || isLocalUploading) && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-lg">
                  <div className="text-center text-white">
                    <AnimatedLoader size={24} color="white" className="mb-2" />
                    <div className="text-sm font-medium">
                      Uploading...
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : ((mediaFile?.type || localMediaFile?.type) === 'video/mp4') ? (
            <div className="relative">
              <video 
                src={localMediaPreview || mediaPreview} 
                controls 
                className="rounded-lg max-h-[400px] w-auto mx-auto"
              />
              {(isUploading || isLocalUploading) && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-lg">
                  <div className="text-center text-white">
                    <AnimatedLoader size={24} color="white" className="mb-2" />
                    <div className="text-sm font-medium">
                      Uploading...
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : ((mediaFile?.type || localMediaFile?.type) === 'application/pdf') ? (
            <div 
              className={`
                rounded-lg overflow-hidden
                transition-all duration-200
                ${isDarkMode 
                  ? 'bg-[#1a1a1a] border border-[#333333]' 
                  : 'bg-gray-50 border border-gray-200'
                }
              `}
            >
              <div className="relative">
                <PDFPreview 
                  url={localMediaPreview || mediaPreview}
                  isDarkMode={isDarkMode}
                  containerStyle="max-h-[600px]"
                  showFullScreenButton={true}
                />
                {(isUploading || isLocalUploading) && (
                  <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 rounded-lg">
                    <AnimatedLoader size={24} color="white" className="mb-2" />
                    <div className="text-sm font-medium text-white">
                      Uploading...
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div 
              className={`
                rounded-lg p-4 flex items-center gap-2 relative
                ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'}
              `}
            >
              <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
              </svg>
              <span className="text-sm">
                {localMediaFile?.originalName || mediaFile?.originalName || 'File'}
              </span>
              {(isUploading || isLocalUploading) && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-lg">
                  <div className="text-center text-white">
                    <AnimatedLoader size={24} color="white" className="mb-2" />
                    <div className="text-sm font-medium">
                      Uploading...
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {isEditing && !(isUploading || isLocalUploading) && (
            <button
              onClick={handleMediaRemove}
              className={`
                absolute top-2 right-2 p-1 rounded-full
                opacity-0 group-hover:opacity-100
                transition-opacity duration-200
                ${isDarkMode 
                  ? 'bg-black/50 hover:bg-black/70 text-white' 
                  : 'bg-white/50 hover:bg-white/70 text-black'
                }
              `}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          )}
        </div>
      )}

      {isEditing && !hideMediaButton && (
        <div className={`relative ${(mediaPreview || localMediaPreview) ? 'mt-4' : 'mt-4'}`}>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*,application/pdf"
            onChange={handleMediaSelect}
            onClick={(e) => {
              // Reset the input value to allow selecting the same file again
              e.target.value = '';
            }}
          />
          <div className="group relative inline-block">
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                fileInputRef.current?.click();
              }}
              disabled={isLocalUploading}
              className={`
                inline-flex items-center justify-between px-2.5 py-1.5 rounded-md
                text-xs font-medium transition-all duration-200 min-w-[120px]
                border backdrop-blur-sm shadow-sm
                ${isDarkMode 
                  ? 'bg-white/[0.06] hover:bg-white/[0.08] border-white/[0.08] text-slate-200 hover:border-white/[0.12]' 
                  : 'bg-white hover:bg-gray-50 border-gray-200/70 text-gray-700 hover:border-gray-300'}
                ${isLocalUploading ? 'opacity-50 cursor-not-allowed' : ''}
              `}
            >
              <span className="mr-2">
                {isLocalUploading ? 'Uploading...' : (mediaPreview || localMediaPreview) ? 'Replace Media' : 'Add Media'}
              </span>
              <div className="flex items-center gap-1.5">
                <div className={`
                  flex items-center justify-center w-4 h-4 rounded-full
                  transition-colors duration-200
                  ${isDarkMode ? 'bg-white/[0.06]' : 'bg-gray-100'}
                `}>
                  <PhotoIcon className="w-2.5 h-2.5 text-blue-400" />
                </div>
                <div className={`
                  flex items-center justify-center w-4 h-4 rounded-full
                  transition-colors duration-200
                  ${isDarkMode ? 'bg-white/[0.06]' : 'bg-gray-100'}
                `}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" 
                    className="w-2.5 h-2.5 text-red-400" strokeWidth={2} stroke="currentColor">
                    <path strokeLinecap="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                </div>
              </div>
            </button>
            <div className={`
              absolute bottom-full left-0 mb-2 w-48 p-2 rounded-md text-xs
              transform scale-0 group-hover:scale-100
              transition-transform duration-200 origin-bottom-left
              ${isDarkMode 
                ? 'bg-[#1a1a1a] text-slate-300 border border-[#333]' 
                : 'bg-white text-gray-600 shadow-lg border border-gray-100'}
            `}>
              <div className="flex flex-col gap-1">
                <span>• Images: Up to 5MB</span>
                <span>• PDF files supported</span>
              </div>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          @font-face {
            font-family: 'UnicodeFont';
            src: local('Arial');
            unicode-range: U+0000-FFFF;
          }

          .canvas-post-editor [contenteditable] {
            -webkit-font-feature-settings: "liga" 0;
            font-feature-settings: "liga" 0;
            text-rendering: optimizeLegibility;
            -webkit-text-size-adjust: 100%;
            font-variant-ligatures: none;
          }
          
          .canvas-post-editor [contenteditable] span {
            display: inline;
            white-space: pre-wrap;
            unicode-bidi: isolate;
            font-variant-ligatures: none;
          }
        `}
      </style>
    </div>
  );
});

// Add display name for better debugging
CanvasPostContentEditor.displayName = 'CanvasPostContentEditor';

export default CanvasPostContentEditor;
