import React, { useState, useEffect } from 'react';
import { useTheme } from '../context/ThemeContext';
import { isLinkedInConnected, disconnectLinkedIn, clearLinkedInCache } from '../services/linkedinPublishService';
import { getLinkedInAuthUrl, saveState, generateCodeVerifier } from '../services/linkedinAuthService';
import { toast } from 'react-hot-toast';
import {
  ArrowPathIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowTopRightOnSquareIcon,
  LinkIcon,
  UserIcon,
  BuildingOfficeIcon,
  ChevronRightIcon,
  BoltIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import Notification from './Notification';
import { useAuth } from '../context/AuthContext';
import AnimatedLoader from './common/AnimatedLoader';

// Add the listItemVariants
const listItemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 }
};

// Add the LinkedInLogo component
const LinkedInLogo = ({ className = "w-5 h-5" }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
  </svg>
);

// Update the EmptyConnectionState to handle reconnect scenario
const EmptyConnectionState = ({ isDarkMode, onConnect, needsReconnect = false }) => (
  <div className={`
    p-4 rounded-2xl border overflow-hidden w-full
    ${isDarkMode ? 'border-white/10' : 'border-black/5'}
  `}>
    {/* Header with LinkedIn logo and status */}
    <div className="flex justify-between items-center">
      <div className="flex items-center space-x-2">
        <LinkedInLogo className={`w-4 h-4 transition-colors duration-200
          ${isDarkMode 
            ? 'text-white/60 group-hover:text-white/80' 
            : 'text-black/60 group-hover:text-black/80'}`} 
        />
        <span className={`text-xs font-medium tracking-wide uppercase
          ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
          LinkedIn Integration
        </span>
      </div>
      <div className="flex items-center space-x-2">
        <span className={`text-xs ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
          Not connected
        </span>
        <div className={`w-2 h-2 rounded-full ${
          isDarkMode ? 'bg-white/20' : 'bg-black/20'
        }`} />
      </div>
    </div>

    {/* Content */}
    <div className="mt-4">
      <p className={`text-sm ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
        Connect your LinkedIn account to publish posts directly from the app.
      </p>
      <button
        onClick={onConnect}
        className={`w-full flex items-center justify-center space-x-2 
          px-3 py-2 text-sm font-medium rounded-lg mt-3
          bg-[#0A66C2] text-white hover:bg-[#0A66C2]/90
          transition-all duration-200`}
      >
        <LinkedInLogo className="w-4 h-4" />
        <span>Connect with LinkedIn</span>
      </button>
    </div>
  </div>
);

// Add this component near the top with other component definitions
const ProfileSkeletonLoader = ({ isDarkMode }) => {
  return (
    <div className="space-y-4 animate-pulse">
      {/* Profile Info Skeleton */}
      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-white/5' : 'bg-black/5'}`}>
        <div className="flex items-center space-x-3">
          {/* Avatar Skeleton */}
          <div className={`w-12 h-12 rounded-full ${
            isDarkMode ? 'bg-white/10' : 'bg-black/10'
          }`} />
          
          {/* Profile Text Skeleton */}
          <div className="space-y-2 flex-1">
            <div className={`h-4 rounded ${
              isDarkMode ? 'bg-white/10' : 'bg-black/10'
            } w-1/3`} />
            <div className={`h-3 rounded ${
              isDarkMode ? 'bg-white/10' : 'bg-black/10'
            } w-1/2`} />
            <div className={`h-3 rounded ${
              isDarkMode ? 'bg-white/10' : 'bg-black/10'
            } w-1/4`} />
          </div>
        </div>
      </div>

      {/* Features List Skeleton */}
      <div className="space-y-3">
        {/* Title Skeleton */}
        <div className={`h-4 rounded ${
          isDarkMode ? 'bg-white/10' : 'bg-black/10'
        } w-2/3`} />
        
        {/* Feature Items Skeleton */}
        <div className="space-y-2">
          {[1, 2].map((item) => (
            <div key={item} className="flex items-center space-x-2">
              <div className={`w-4 h-4 rounded ${
                isDarkMode ? 'bg-white/10' : 'bg-black/10'
              }`} />
              <div className={`h-3 rounded ${
                isDarkMode ? 'bg-white/10' : 'bg-black/10'
              } w-3/4`} />
            </div>
          ))}
        </div>
      </div>

      {/* Button Skeleton */}
      <div className="pt-2">
        <div className={`h-9 rounded-lg ${
          isDarkMode ? 'bg-white/10' : 'bg-black/10'
        } w-full`} />
      </div>
    </div>
  );
};

// Update the DisconnectConfirmationModal with borders
const DisconnectConfirmationModal = ({ isOpen, onClose, onConfirm, isDarkMode, isLoading }) => {
  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 backdrop-blur-sm z-50
      ${isDarkMode 
        ? 'bg-black/70'
        : 'bg-white/70'
      }`}>
      <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[320px] 
        ${isDarkMode 
          ? 'bg-black/90 border border-white/[0.08]' 
          : 'bg-white/90 border border-black/[0.08]'} 
        backdrop-blur-xl rounded-2xl p-6 transform transition-all duration-300
        shadow-xl`}>
        <div className="space-y-4">
          {/* Icon */}
          <div className="flex justify-center">
            <div className={`p-3 rounded-full 
              ${isDarkMode ? 'bg-white/10' : 'bg-black/5'}`}>
              <LinkedInLogo className={`w-6 h-6 
                ${isDarkMode ? 'text-white/60' : 'text-black/60'}`} />
            </div>
          </div>

          {/* Title and Description */}
          <div className="text-center space-y-2">
            <h3 className={`text-base font-medium 
              ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Disconnect LinkedIn Account?
            </h3>
            <p className={`text-sm 
              ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
              You won't be able to publish posts directly to LinkedIn after disconnecting.
            </p>
          </div>

          {/* Buttons */}
          <div className="space-y-2">
            <button
              onClick={onConfirm}
              disabled={isLoading}
              className={`relative w-full py-2 text-sm font-medium 
                ${isDarkMode 
                  ? 'bg-red-500/20 text-red-400 hover:bg-red-500/30' 
                  : 'bg-red-50 text-red-600 hover:bg-red-100'}
                rounded-full transition-all duration-200`}
            >
              <span className={isLoading ? 'opacity-0' : 'opacity-100'}>
                Disconnect Account
              </span>
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <AnimatedLoader size={16} color={isDarkMode ? "white" : "black"} />
                </div>
              )}
            </button>
            <button
              onClick={onClose}
              disabled={isLoading}
              className={`w-full py-2 text-sm font-medium rounded-full transition-all duration-200
                ${isDarkMode 
                  ? 'bg-white/10 text-white hover:bg-white/20' 
                  : 'bg-black/5 text-black hover:bg-black/10'}`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LinkedInConnectionManager = () => {
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [connectionStatus, setConnectionStatus] = useState({
    isConnected: false,
    needsReconnect: false,
    profile: null,
    error: null
  });
  const [notifications, setNotifications] = useState([]);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const addNotification = (message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
    setTimeout(() => {
      setNotifications(prev => prev.filter(n => n.id !== id));
    }, 5000);
  };

  useEffect(() => {
    checkConnectionStatus();
  }, []);

  const checkConnectionStatus = async () => {
    try {
      setIsLoading(true);
      const status = await isLinkedInConnected();
      setConnectionStatus(status);
    } catch (error) {
      console.error('Error checking LinkedIn status:', error);
      setConnectionStatus(prev => ({
        ...prev,
        error: error.message || 'Failed to check LinkedIn connection status'
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const validateDomain = () => {
    const currentDomain = window.location.hostname;
    const allowedDomains = ['ammmplify.com', 'www.ammmplify.com', 'localhost'];
    return allowedDomains.includes(currentDomain);
  };

  const handleConnect = async () => {
    try {
      if (!validateDomain()) {
        toast.error('LinkedIn connection is only available on ammmplify.com');
        return;
      }

      // Generate state with connect- prefix to identify connection flow
      const state = `connect-${Date.now()}-${Math.random().toString(36).substring(7)}`;
      const stateData = {
        state,
        timestamp: Date.now(),
        origin: window.location.origin,
        codeVerifier: generateCodeVerifier() // Add code verifier for PKCE
      };
      
      // Save state data
      const saved = saveState(stateData);
      if (!saved) {
        throw new Error('Failed to save authentication state');
      }
      
      // Get auth URL with state
      const authUrl = await getLinkedInAuthUrl(state);
      
      // Redirect to LinkedIn
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error initiating LinkedIn connection:', error);
      addNotification('Failed to initiate LinkedIn connection', 'error');
    }
  };

  const handleDisconnect = async () => {
    try {
      setIsDisconnecting(true);
      await disconnectLinkedIn();
      clearLinkedInCache();
      addNotification('LinkedIn account disconnected successfully', 'success');
      setConnectionStatus({
        isConnected: false,
        needsReconnect: false,
        profile: null,
        error: null
      });
      setShowDisconnectModal(false);
    } catch (error) {
      console.error('Error disconnecting LinkedIn:', error);
      addNotification('Failed to disconnect LinkedIn account', 'error');
    } finally {
      setIsDisconnecting(false);
    }
  };

  const handleReconnect = async () => {
    try {
      setIsLoading(true);
      
      // First, clear existing LinkedIn tokens and state
      await disconnectLinkedIn();
      
      // Generate new state with reconnect prefix for identification
      const state = `reconnect-${Date.now()}-${Math.random().toString(36).substring(7)}`;
      const stateData = {
        state,
        timestamp: Date.now(),
        origin: window.location.origin,
        codeVerifier: generateCodeVerifier() // Add code verifier for PKCE
      };
      
      // Save state data
      const saved = saveState(stateData);
      if (!saved) {
        throw new Error('Failed to save authentication state');
      }
      
      // Get auth URL with state
      const authUrl = await getLinkedInAuthUrl(state);
      
      // Redirect to LinkedIn auth
      window.location.href = authUrl;

    } catch (error) {
      console.error('Error initiating LinkedIn reconnection:', error);
      addNotification('Failed to initiate LinkedIn reconnection. Please try again.', 'error');
      toast.error('LinkedIn reconnection failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatConnectionDate = (date) => {
    if (!date) return '';
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const ConnectedProfileCard = ({ 
    profile, 
    onDisconnect, 
    onReconnect, 
    needsReconnect,
    isDisconnectingLinkedIn 
  }) => {
    const { user } = useAuth();
    const linkedinData = profile || {};

    return (
      <div className="space-y-4">
        {/* Add Status Header */}
        <div className={`
          p-4 rounded-2xl border overflow-hidden w-full
          ${isDarkMode ? 'border-white/10' : 'border-black/5'}
        `}>
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <LinkedInLogo className={`w-4 h-4 transition-colors duration-200
                ${isDarkMode 
                  ? 'text-white/60 group-hover:text-white/80' 
                  : 'text-black/60 group-hover:text-black/80'}`} 
              />
              <span className={`text-xs font-medium tracking-wide uppercase
                ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                LinkedIn Integration
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span className={`text-xs ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
                Connected
              </span>
              <div className="w-2 h-2 rounded-full bg-green-500" />
            </div>
          </div>
        </div>

        {/* Profile Info */}
        <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-white/5' : 'bg-black/5'}`}>
          <div className="flex items-center space-x-3">
            {linkedinData.picture && (
              <img 
                src={linkedinData.picture} 
                alt="LinkedIn Profile" 
                className="w-12 h-12 rounded-full"
              />
            )}
            <div>
              <h3 className={`font-medium ${isDarkMode ? 'text-white' : 'text-black'}`}>
                {linkedinData.name || `${linkedinData.given_name} ${linkedinData.family_name}`}
              </h3>
              <p className={`text-sm ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                {linkedinData.email}
              </p>
              {linkedinData.locale && (
                <p className={`text-xs ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
                  Region: {linkedinData.locale}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Features List */}
        <div className="space-y-3">
          <p className={`text-sm ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
            Your LinkedIn account is connected. You can now:
          </p>
          <ul className={`text-sm space-y-2 ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
            {[
              'Schedule & Publish posts directly to LinkedIn',
              'Sync your professional network'
            ].map((feature, index) => (
              <motion.li 
                key={feature}
                variants={listItemVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: index * 0.1 }}
                className="flex items-center space-x-2"
              >
                <CheckIcon className="w-4 h-4 text-green-500" />
                <span>{feature}</span>
              </motion.li>
            ))}
          </ul>
        </div>
        
        {/* Disconnect Button */}
        <div className="pt-2">
          {/* Add warning message */}
          <div className={`mb-3 p-3 rounded-lg text-xs
            ${isDarkMode 
              ? 'bg-yellow-500/10 text-yellow-400/90' 
              : 'bg-yellow-50 text-yellow-600'}`}
          >
            <p>Note: If you disconnect your LinkedIn account, you'll need to:</p>
            <ul className="mt-1 ml-4 list-disc space-y-0.5">
              <li>Wait for 30 minutes before reconnecting</li>
              <li>Log out and clear all browser cookies</li>
              <li>Log back in before attempting to reconnect</li>
            </ul>
          </div>

          <button
            onClick={() => setShowDisconnectModal(true)}
            disabled={isDisconnectingLinkedIn}
            className={`group relative w-full px-3 py-2 text-sm font-medium rounded-lg 
              transition-all duration-200 
              ${isDarkMode 
                ? 'bg-red-500/20 text-red-400 hover:bg-red-500/30' 
                : 'bg-red-50 text-red-600 hover:bg-red-100'
              }`}
          >
            <span className="flex items-center justify-center space-x-2">
              <ArrowRightOnRectangleIcon className="w-4 h-4" />
              <span>Disconnect Account</span>
            </span>
          </button>
        </div>
      </div>
    );
  };

  // Update the renderContent function to use the skeleton loader
  const renderContent = () => {
    if (isLoading) {
      return <ProfileSkeletonLoader isDarkMode={isDarkMode} />;
    }

    // Show reconnect state if there's an error or needs reconnect
    if (connectionStatus.error || connectionStatus.needsReconnect) {
      return (
        <EmptyConnectionState 
          isDarkMode={isDarkMode} 
          onConnect={handleReconnect}
          needsReconnect={true}
        />
      );
    }

    // Show initial connect state
    if (!connectionStatus.isConnected) {
      return (
        <EmptyConnectionState 
          isDarkMode={isDarkMode} 
          onConnect={handleConnect} 
        />
      );
    }

    // Show connected state
    return (
      <div className="space-y-4 max-w-2xl mx-auto">
        <ConnectedProfileCard 
          profile={connectionStatus.profile}
          onDisconnect={handleDisconnect}
          onReconnect={handleReconnect}
          needsReconnect={connectionStatus.needsReconnect}
          isDisconnectingLinkedIn={isDisconnecting}
        />
      </div>
    );
  };

  return (
    <div className="h-full flex flex-col overflow-hidden">
      {/* Top Bar */}
      <div className={`
        h-10 min-h-[40px] flex-shrink-0 
        flex items-center justify-between 
        px-4 py-2 w-full border-b
        ${isDarkMode 
          ? 'bg-[#121212]/95 border-[#333333]' 
          : 'bg-white bg-opacity-90 border-gray-200'
        }
      `}>
        <div className="flex items-center">
          <LinkIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
          <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
            LinkedIn Connection
          </h2>
        </div>
      </div>

      {/* Content Area */}
      <div className={`
        flex-grow overflow-y-auto custom-scrollbar
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white bg-opacity-80'}
        p-4 flex justify-center
      `}>
        <div className={`
          w-full max-w-md
          sm:max-w-md 
          md:max-w-lg
          lg:max-w-xl
          px-4 sm:px-0
        `}>
          {renderContent()}
        </div>
      </div>

      {/* Notifications */}
      <div className="fixed top-4 right-4 z-50">
        {notifications.map(notification => (
          <Notification
            key={notification.id}
            message={notification.message}
            type={notification.type}
            onClose={() => setNotifications(prev => 
              prev.filter(n => n.id !== notification.id)
            )}
          />
        ))}
      </div>

      {/* Disconnect Modal - Now at root level */}
      <DisconnectConfirmationModal
        isOpen={showDisconnectModal}
        onClose={() => setShowDisconnectModal(false)}
        onConfirm={handleDisconnect}
        isDarkMode={isDarkMode}
        isLoading={isDisconnecting}
      />
    </div>
  );
};

export default LinkedInConnectionManager; 