import React, { useMemo } from 'react';
import { format, isSameHour, isToday, isBefore } from 'date-fns';
import CompactLinkedInPostPreview from './CompactLinkedInPostPreview';
import { PlusIcon, ClockIcon, ChevronDownIcon, TrashIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '../../utils/cn';

const TimeSlot = ({ hour, isDarkMode, isCurrentHour }) => {
  const formattedTime = useMemo(() => {
    const date = new Date().setHours(hour);
    return {
      hours: format(date, 'h'),
      period: format(date, 'a')
    };
  }, [hour]);

  return (
    <div 
      className={cn(
        "py-4 px-2 sm:px-4 text-sm font-medium h-[5rem] flex items-center justify-end",
        "border-b select-none",
        isDarkMode ? 'text-gray-400 border-[#333]' : 'text-gray-600 border-gray-200',
        isCurrentHour && (isDarkMode 
          ? "text-blue-400 bg-blue-500/10" 
          : "text-blue-600 bg-blue-50/50"
        )
      )}
    >
      <div className="flex items-center tabular-nums">
        <span className="w-[1.5ch] text-right">{formattedTime.hours}</span>
        <span className="mx-0.5">:</span>
        <span>00</span>
        <span className="ml-1 text-xs uppercase opacity-60">{formattedTime.period}</span>
      </div>
    </div>
  );
};

const AddButton = ({ isDarkMode, onClick }) => (
  <motion.button
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    whileHover={{ scale: 1.005 }}
    whileTap={{ scale: 0.995 }}
    onClick={onClick}
    className={cn(
      "flex items-center gap-2",
      "px-3 py-2 rounded-md",
      "transition-all duration-150",
      "group",
      isDarkMode 
        ? [
            "text-gray-500",
            "hover:bg-gray-800/10",
            "hover:text-gray-300"
          ]
        : [
            "text-gray-400",
            "hover:bg-gray-100/80",
            "hover:text-gray-600"
          ]
    )}
  >
    <PlusIcon className={cn(
      "w-4 h-4",
      "transition-transform duration-150",
      "group-hover:scale-105"
    )} />
    <span className={cn(
      "text-sm font-medium",
      "opacity-0 translate-x-1",
      "transition-all duration-150",
      "group-hover:opacity-100 group-hover:translate-x-0"
    )}>
      Add
    </span>
  </motion.button>
);

const PostSlot = ({ 
  posts, 
  isDarkMode, 
  onEdit, 
  onDelete, 
  isPast,
  onAddClick,
  hour,
  isCurrentHour
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const showExpandButton = posts.length > 1;
  const displayedPosts = isExpanded ? posts : posts.slice(0, 1);
  const now = new Date();
  const currentMinutes = now.getMinutes();
  const isCurrentDayHour = isCurrentHour && hour === now.getHours();

  const handleAddClick = (e) => {
    if (isCurrentDayHour) {
      // For current hour, calculate click position to determine minutes
      const rect = e.currentTarget.getBoundingClientRect();
      const clickY = e.clientY - rect.top;
      const minuteInHour = Math.floor((clickY / rect.height) * 60);
      
      // Only allow scheduling if clicked after current time
      if (minuteInHour <= currentMinutes) return;

      // Set the exact time for scheduling
      const scheduleTime = new Date();
      scheduleTime.setHours(hour, minuteInHour);
      onAddClick(scheduleTime);
    } else {
      // For future hours, use the start of the hour
      onAddClick(new Date().setHours(hour, 0));
    }
  };

  return (
    <div className={cn(
      "px-4 sm:px-6 py-2 h-full",
      "relative",
      showExpandButton ? "overflow-visible" : "overflow-y-auto",
      isCurrentHour && (isDarkMode 
        ? "bg-blue-500/10" 
        : "bg-blue-50/50"
      )
    )}>
      {isCurrentDayHour && (
        <div 
          className={cn(
            "absolute inset-x-0 top-0 pointer-events-none",
            "bg-gradient-to-b",
            isDarkMode 
              ? "from-red-500/10 to-transparent" 
              : "from-red-100/20 to-transparent",
            "opacity-50"
          )}
          style={{
            height: `${(currentMinutes / 60) * 100}%`
          }}
        />
      )}

      <div className="flex gap-3 items-start">
        {/* Posts Column */}
        <div className={cn(
          "space-y-2",
          "max-w-[300px]",
          isPast && !isCurrentDayHour && "opacity-60"
        )}>
          <AnimatePresence initial={false}>
            {displayedPosts.map((post, index) => (
              <motion.div
                key={post._id}
                layout
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.2, delay: index * 0.05 }}
                className="group relative"
              >
                <CompactLinkedInPostPreview
                  post={post}
                  isDarkMode={isDarkMode}
                  colorIndex={index}
                  className="transform transition-all duration-200 hover:-translate-y-0.5"
                />
                
                {/* Delete Button */}
                {(post.status === 'scheduled' || post.status === 'published' || post.status === 'failed') && (
                  <div className={cn(
                    "absolute right-0 top-0 -mt-1 -mr-1",
                    "opacity-0 group-hover:opacity-100",
                    "transition-opacity duration-200"
                  )}>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(post._id);
                      }}
                      className={cn(
                        "p-1 rounded-full",
                        "shadow-sm",
                        isDarkMode
                          ? [
                              "bg-red-500/90 hover:bg-red-500",
                              "text-white/90 hover:text-white",
                              "border border-red-400/20"
                            ]
                          : [
                              "bg-red-500/90 hover:bg-red-500",
                              "text-white",
                              "border border-red-400/20"
                            ],
                        "transition-colors duration-200",
                        "focus:outline-none focus:ring-2",
                        isDarkMode
                          ? "focus:ring-red-500/30"
                          : "focus:ring-red-500/30"
                      )}
                      title="Delete post"
                    >
                      <TrashIcon className="w-3 h-3" />
                    </button>
                  </div>
                )}
              </motion.div>
            ))}
          </AnimatePresence>

          {showExpandButton && (
            <motion.button
              layout
              onClick={() => setIsExpanded(!isExpanded)}
              className={cn(
                "w-full flex items-center justify-center py-1 mt-1",
                "text-xs font-medium rounded-md",
                isDarkMode 
                  ? 'hover:bg-white/10 text-gray-400' 
                  : 'hover:bg-gray-100 text-gray-600'
              )}
            >
              <span>{isExpanded ? 'Show less' : `${posts.length - 1} more posts`}</span>
              <ChevronDownIcon 
                className={cn(
                  "w-3 h-3 ml-1 transition-transform",
                  isExpanded && "transform rotate-180"
                )} 
              />
            </motion.button>
          )}
        </div>

        {/* Add Button - Show for future times and current hour after current minute */}
        {(!isPast || isCurrentDayHour) && (
          <div className="h-full flex-1" onClick={handleAddClick}>
            <AddButton isDarkMode={isDarkMode} onClick={handleAddClick} />
          </div>
        )}
      </div>
    </div>
  );
};

const EmptySlot = ({ isDarkMode, isPast, onDateClick, hour, isCurrentHour }) => {
  const now = new Date();
  const currentMinutes = now.getMinutes();
  const isCurrentDayHour = isCurrentHour && hour === now.getHours();
  
  // Only fully disable if it's a past hour
  if (isPast && !isCurrentDayHour) return null;

  const handleSlotClick = (e) => {
    if (isCurrentDayHour) {
      // For current hour, calculate click position to determine minutes
      const rect = e.currentTarget.getBoundingClientRect();
      const clickY = e.clientY - rect.top;
      const minuteInHour = Math.floor((clickY / rect.height) * 60);
      
      // Only allow scheduling if clicked after current time
      if (minuteInHour <= currentMinutes) return;

      // Set the exact time for scheduling
      const scheduleTime = new Date();
      scheduleTime.setHours(hour, minuteInHour);
      onDateClick(scheduleTime);
    } else {
      // For future hours, use the start of the hour
      onDateClick(new Date().setHours(hour, 0));
    }
  };
  
  return (
    <div 
      onClick={handleSlotClick}
      className={cn(
        "h-full w-full relative",
        "group cursor-pointer",
        "transition-all duration-150",
        isDarkMode 
          ? "hover:bg-gray-800/5" 
          : "hover:bg-gray-50",
        isCurrentHour && (isDarkMode 
          ? "bg-blue-500/10" 
          : "bg-blue-50/50"
        )
      )}
    >
      {isCurrentDayHour && (
        <div 
          className={cn(
            "absolute inset-x-0 top-0 pointer-events-none",
            "bg-gradient-to-b",
            isDarkMode 
              ? "from-red-500/10 to-transparent" 
              : "from-red-100/20 to-transparent",
            "opacity-50"
          )}
          style={{
            height: `${(currentMinutes / 60) * 100}%`
          }}
        />
      )}

      {/* Centered content that appears on hover */}
      <div className={cn(
        "absolute inset-0 -mt-[1px]",
        "flex items-center justify-center gap-2",
        "opacity-0 group-hover:opacity-100",
        "transition-all duration-150",
        isDarkMode ? "text-gray-400" : "text-gray-500"
      )}>
        <div className="flex items-center gap-2 -translate-y-[1px]">
          <PlusIcon className="w-4 h-4" />
          <span className="text-sm font-medium">
            Schedule a post
          </span>
        </div>
      </div>
    </div>
  );
};

const CurrentTimeIndicator = ({ isDarkMode }) => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  
  // Each hour slot is 5rem (80px)
  // Calculate position based on hours and percentage of hour passed
  const hourPosition = hours * 80; // 5rem = 80px
  const minutePosition = (minutes / 60) * 80;
  const position = hourPosition + minutePosition;

  return (
    <div 
      className="absolute left-0 right-0 z-30 pointer-events-none"
      style={{ 
        top: `${position}px`,
      }}
    >
      <div className="relative flex items-center">
        {/* Clock bubble */}
        <div className={cn(
          "flex items-center justify-center ml-4",
          "w-5 h-5 rounded-full",
          "bg-blue-500",
          "shadow-sm",
          isDarkMode ? "shadow-black/20" : "shadow-blue-500/20"
        )}>
          <ClockIcon className="w-3 h-3 text-white" />
        </div>

        {/* Line */}
        <div className="flex-1 border-t-2 border-blue-500" />

        {/* Time */}
        <div className={cn(
          "px-2 py-0.5 rounded-full mr-4",
          "text-xs font-medium",
          "bg-blue-500 text-white",
          "shadow-sm",
          isDarkMode ? "shadow-black/20" : "shadow-blue-500/20"
        )}>
          {format(now, 'h:mm a')}
        </div>
      </div>
    </div>
  );
};

const DailyView = ({ 
  currentDate, 
  scheduledPosts, 
  isDarkMode,
  onDateClick,
  onEditPost,
  onDeletePost 
}) => {
  const scrollContainerRef = React.useRef(null);
  const timeSlots = useMemo(() => Array.from({ length: 24 }, (_, i) => i), []);
  const currentHour = new Date().getHours();
  const isCurrentDay = isToday(new Date(currentDate));

  // Effect to scroll to current time on mount and when currentDate changes
  React.useEffect(() => {
    if (scrollContainerRef.current) {
      const now = new Date();
      const currentHour = now.getHours();
      
      // Each time slot is 5rem (80px) high
      const scrollPosition = currentHour * 80 - (window.innerHeight / 2) + 40;
      
      scrollContainerRef.current.scrollTo({
        top: Math.max(0, scrollPosition),
        behavior: 'smooth'
      });
    }
  }, [currentDate]);

  // Group posts by hour for better performance
  const postsByHour = useMemo(() => {
    const grouped = new Map();
    timeSlots.forEach(hour => {
      const postsAtHour = scheduledPosts.filter(post => {
        const postDate = new Date(post.scheduledFor);
        return isSameHour(postDate, new Date(currentDate).setHours(hour));
      });
      grouped.set(hour, postsAtHour);
    });
    return grouped;
  }, [scheduledPosts, currentDate, timeSlots]);

  return (
    <div className="flex-1 w-full h-full flex flex-col">
      <div 
        ref={scrollContainerRef}
        className="flex-1 overflow-auto relative"
      >
        <div className="grid grid-cols-[auto_1fr] w-full h-full">
          {/* Time slots column */}
          <div className={cn(
            "w-[5.5rem] sm:w-24 border-r sticky left-0 top-0 z-10 shadow-md",
            isDarkMode ? 'border-[#333] bg-[#121212]' : 'border-gray-200 bg-white'
          )}>
            {timeSlots.map(hour => (
              <TimeSlot 
                key={hour} 
                hour={hour} 
                isDarkMode={isDarkMode}
                isCurrentHour={isCurrentDay && hour === currentHour}
              />
            ))}
          </div>

          {/* Content column */}
          <div className="flex-1 min-w-0 relative">
            {isCurrentDay && <CurrentTimeIndicator isDarkMode={isDarkMode} />}
            
            {timeSlots.map(hour => {
              const postsAtHour = postsByHour.get(hour) || [];
              const isPast = isBefore(
                new Date(currentDate).setHours(hour),
                new Date()
              );
              const isCurrentHour = isCurrentDay && hour === currentHour;

              return (
                <motion.div 
                  key={hour}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  className={cn(
                    "border-b min-h-[5rem]",
                    "relative",
                    isDarkMode ? 'border-[#333]' : 'border-gray-200',
                    isCurrentHour && (isDarkMode 
                      ? "bg-blue-500/10 border-blue-500/20" 
                      : "bg-blue-50/50 border-blue-200/20"
                    )
                  )}
                >
                  <div className="absolute inset-0">
                    {postsAtHour.length === 0 ? (
                      <EmptySlot
                        isDarkMode={isDarkMode}
                        isPast={isPast}
                        onDateClick={onDateClick}
                        hour={hour}
                        isCurrentHour={isCurrentHour}
                      />
                    ) : (
                      <PostSlot
                        posts={postsAtHour}
                        isDarkMode={isDarkMode}
                        onEdit={onEditPost}
                        onDelete={onDeletePost}
                        isPast={isPast}
                        onAddClick={() => onDateClick(new Date(currentDate).setHours(hour))}
                        hour={hour}
                        isCurrentHour={isCurrentHour}
                      />
                    )}
                  </div>
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyView; 