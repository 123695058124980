import React from 'react';
import { VideoCameraIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { cn } from '../lib/utils';

const VideoScriptButton = ({ isActive, onClick, isDarkMode }) => (
  <motion.button
    onClick={onClick}
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    className={cn(
      "flex items-center gap-1.5 h-7 px-2",
      "rounded-lg transition-all duration-200 group relative",
      isDarkMode 
        ? isActive 
          ? "bg-purple-500/20 text-purple-400" 
          : "bg-[#1a1a1a] hover:bg-[#222222] text-slate-400"
        : isActive
          ? "bg-purple-50 text-purple-600"
          : "bg-slate-50 hover:bg-slate-100 text-slate-600",
      "border",
      isDarkMode
        ? isActive
          ? "border-purple-500/20"
          : "border-[#333333]"
        : isActive
          ? "border-purple-100"
          : "border-slate-200"
    )}
  >
    <div className={cn(
      "flex items-center justify-center w-4 h-4 rounded-md",
      "transition-all duration-200",
      isDarkMode
        ? isActive
          ? "bg-purple-500/20"
          : "bg-[#252525] group-hover:bg-[#2a2a2a]"
        : isActive
          ? "bg-purple-100"
          : "bg-white group-hover:bg-slate-50"
    )}>
      <VideoCameraIcon className={cn(
        "h-3 w-3",
        "transition-colors duration-200",
        isDarkMode
          ? isActive
            ? "text-purple-400"
            : "text-slate-400 group-hover:text-slate-300"
          : isActive
            ? "text-purple-600"
            : "text-slate-500 group-hover:text-slate-600"
      )} />
    </div>
    
    <span className="text-[10px] font-medium">
      Script
    </span>
  </motion.button>
);

export default VideoScriptButton; 