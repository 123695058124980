import React, { useState, useEffect, useRef } from 'react';
import { format, addHours, startOfDay, isSameDay, parseISO } from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

const TimeSlot = ({ 
  hour, 
  isDarkMode, 
  posts = [], 
  onAddClick, 
  onPostClick,
  selectedDate, 
  isFirstSlot 
}) => {
  // More precise past time check
  const now = new Date();
  const slotStart = new Date(hour);
  const slotEnd = new Date(hour);
  slotEnd.setHours(slotEnd.getHours() + 1);
  
  // A time slot is considered past if it's completely in the past
  const isPast = slotEnd <= now;
  
  // For the current hour, we need to check if there's still time to schedule
  const isCurrentHour = slotStart.getHours() === now.getHours() && 
                       slotStart.getDate() === now.getDate() && 
                       slotStart.getMonth() === now.getMonth() &&
                       slotStart.getFullYear() === now.getFullYear();

  // Add a buffer time (e.g., 5 minutes) for scheduling
  const BUFFER_MINUTES = 5;
  const hasTimeToSchedule = isCurrentHour ? 
    (now.getMinutes() + BUFFER_MINUTES) < 60 : 
    !isPast;

  return (
    <div 
      className={`
        flex h-20 relative border-b group
        ${isDarkMode ? 'border-[#333333]' : 'border-gray-100'}
        ${isFirstSlot ? 'border-t' : ''}
        ${isPast ? 'opacity-75' : ''}
        ${isCurrentHour ? 'bg-blue-50/5' : ''}
      `}
    >
      {/* Time Label */}
      <div className="w-24 flex-shrink-0 px-4 pt-2">
        <span className={`
          text-xs font-medium whitespace-nowrap
          ${isDarkMode 
            ? isPast 
              ? 'text-gray-600' 
              : isCurrentHour
                ? 'text-blue-400'
                : 'text-gray-500'
            : isPast 
              ? 'text-gray-300' 
              : isCurrentHour
                ? 'text-blue-600'
                : 'text-gray-400'
          }
        `}>
          {format(hour, 'h:mm a')}
          {isCurrentHour && (
            <span className={`
              ml-1 text-[10px]
              ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}
            `}>
              • Now
            </span>
          )}
        </span>
      </div>

      {/* Content Area */}
      <div 
        className={`
          flex-1 relative px-4
          ${hasTimeToSchedule && (isDarkMode 
            ? 'hover:bg-white/[0.02] active:bg-white/[0.05]' 
            : 'hover:bg-black/[0.02] active:bg-black/[0.05]'
          )}
          transition-colors duration-200
          ${hasTimeToSchedule ? 'cursor-pointer' : 'cursor-default'}
          ${isCurrentHour ? 'bg-blue-500/[0.02]' : ''}
        `}
        onClick={(e) => {
          if (hasTimeToSchedule && e.target === e.currentTarget) {
            onAddClick(hour);
          }
        }}
      >
        {/* Empty State - More minimal design (only show for future times and current hour if schedulable) */}
        {hasTimeToSchedule && posts.length === 0 && (
          <div 
            className={`
              h-full flex items-center
              group/slot cursor-pointer
            `}
            onClick={(e) => {
              e.stopPropagation();
              onAddClick(hour);
            }}
          >
            <div className={`
              flex items-center gap-1.5
              ${isDarkMode 
                ? isCurrentHour
                  ? 'text-blue-500 group-hover/slot:text-blue-400'
                  : 'text-gray-700 group-hover/slot:text-gray-600' 
                : isCurrentHour
                  ? 'text-blue-400 group-hover/slot:text-blue-500'
                  : 'text-gray-200 group-hover/slot:text-gray-300'
              }
              transition-colors duration-200
            `}>
              <PlusIcon className="w-3.5 h-3.5" />
              <span className="text-[11px] font-medium">
                {isCurrentHour ? 'Schedule for this hour' : 'Add'}
              </span>
            </div>
          </div>
        )}

        {/* Posts List */}
        {posts.map(post => (
          <motion.div
            key={post._id}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              onPostClick(post);
            }}
            className={`
              p-3 rounded-lg my-1
              ${isDarkMode 
                ? `bg-blue-900/20 border border-blue-800/30 
                   ${hasTimeToSchedule && 'active:bg-blue-900/30'}`
                : `bg-blue-50 border border-blue-100 
                   ${hasTimeToSchedule && 'active:bg-blue-100'}`
              }
              ${hasTimeToSchedule ? 'cursor-pointer' : 'cursor-default'}
              transition-colors duration-200
              ${isCurrentHour ? 'ring-1 ring-blue-500/20' : ''}
            `}
          >
            <div className="flex items-start justify-between gap-3">
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2">
                  <span className={`text-xs font-medium ${
                    isDarkMode 
                      ? isPast ? 'text-blue-300' : 'text-blue-200'
                      : isPast ? 'text-blue-400' : 'text-blue-700'
                  }`}>
                    {format(parseISO(post.scheduledFor), 'h:mm a')}
                  </span>
                  <span className={`
                    text-[10px] px-1.5 py-0.5 rounded-full
                    ${isDarkMode 
                      ? isPast 
                        ? 'bg-blue-900/20 text-blue-300'
                        : isCurrentHour
                          ? 'bg-blue-900/40 text-blue-200'
                          : 'bg-blue-900/30 text-blue-200'
                      : isPast
                        ? 'bg-blue-50 text-blue-400'
                        : isCurrentHour
                          ? 'bg-blue-100 text-blue-600'
                          : 'bg-blue-100 text-blue-700'
                    }
                  `}>
                    {post.status}
                  </span>
                </div>
                <p className={`
                  text-xs mt-1 line-clamp-2
                  ${isDarkMode 
                    ? isPast ? 'text-blue-200' : 'text-blue-100'
                    : isPast ? 'text-blue-400' : 'text-blue-800'
                  }
                `}>
                  {post.content}
                </p>
              </div>
              {post.imageUrl && (
                <div className="w-12 h-12 rounded overflow-hidden flex-shrink-0">
                  <img 
                    src={post.imageUrl} 
                    alt="" 
                    className="w-full h-full object-cover"
                  />
                </div>
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

const MobileCalendarView = ({ 
  selectedDate,
  scheduledPosts,
  isDarkMode,
  onDateChange,
  onAddPost,
  onEditPost
}) => {
  const timeGridRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  // Scroll to current time when viewing today
  useEffect(() => {
    if (timeGridRef.current && isSameDay(selectedDate, new Date())) {
      const scrollPosition = getTimeIndicatorPosition() - (window.innerHeight / 2);
      timeGridRef.current.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  }, [selectedDate]);

  // Update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  // Calculate time indicator position
  const getTimeIndicatorPosition = () => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    // Each hour slot is 80px high
    return (hours * 80) + ((minutes / 60) * 80);
  };

  // Generate time slots for the day
  const timeSlots = Array.from({ length: 24 }, (_, i) => 
    addHours(startOfDay(selectedDate), i)
  );

  // Group posts by hour
  const postsByHour = timeSlots.map(hour => ({
    hour,
    posts: scheduledPosts.filter(post => 
      isSameDay(parseISO(post.scheduledFor), selectedDate) &&
      format(parseISO(post.scheduledFor), 'H') === format(hour, 'H')
    )
  }));

  return (
    <div className="h-full flex flex-col">
      {/* Date Header */}
      <div className={`
        sticky top-0 z-20 px-4 py-3
        border-b backdrop-blur-lg
        ${isDarkMode 
          ? 'bg-[#121212]/90 border-[#333333]' 
          : 'bg-white/90 border-gray-200'
        }
      `}>
        <div className="flex items-center justify-between">
          <button
            onClick={() => onDateChange(-1)}
            className={`
              p-2 rounded-full
              ${isDarkMode 
                ? 'hover:bg-white/10 active:bg-white/20' 
                : 'hover:bg-gray-100 active:bg-gray-200'
              }
            `}
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </button>
          <div className="flex flex-col items-center">
            <h2 className={`text-base font-semibold ${
              isDarkMode ? 'text-white' : 'text-gray-900'
            }`}>
              {format(selectedDate, 'EEEE')}
            </h2>
            <p className={`text-sm ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              {format(selectedDate, 'MMMM d')}
            </p>
          </div>
          <button
            onClick={() => onDateChange(1)}
            className={`
              p-2 rounded-full
              ${isDarkMode 
                ? 'hover:bg-white/10 active:bg-white/20' 
                : 'hover:bg-gray-100 active:bg-gray-200'
              }
            `}
          >
            <ChevronRightIcon className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Time Slots */}
      <div 
        ref={timeGridRef}
        className="flex-1 overflow-y-auto overscroll-contain scroll-smooth"
      >
        <div className={`
          min-h-full relative
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
        `}>
          {postsByHour.map(({ hour, posts }, index) => (
            <TimeSlot
              key={format(hour, 'HH')}
              hour={hour}
              posts={posts}
              isDarkMode={isDarkMode}
              onAddClick={(time) => onAddPost(time)}
              onPostClick={(post) => onEditPost(post)}
              selectedDate={selectedDate}
              isFirstSlot={index === 0}
            />
          ))}

          {/* Current Time Indicator - Updated width to match new time column */}
          {isSameDay(selectedDate, new Date()) && (
            <div 
              className="absolute left-0 right-0 flex items-center pointer-events-none z-10"
              style={{
                top: `${getTimeIndicatorPosition()}px`
              }}
            >
              <div className="w-24 flex items-center justify-end pr-4">
                <div className="w-2 h-2 rounded-full bg-red-500" />
              </div>
              <div className="flex-1 h-0.5 bg-red-500 mr-4" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileCalendarView; 