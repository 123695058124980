import React from 'react';
import { motion } from 'framer-motion';
import PublicPageLayout from './layout/PublicPageLayout';
import PageSection from './shared/PageSection';
import { useTheme } from '../../context/ThemeContext';
import { LockClosedIcon } from '@heroicons/react/24/outline';

const PrivacyPage = () => {
  const { isDarkMode } = useTheme();

  const sections = [
    {
      title: '1. Information We Collect',
      content: `We collect several types of information for various purposes:
      
      Personal Information:
      • Name, email address, and profile information
      • LinkedIn account data (with your explicit permission)
      • Payment information (for paid subscriptions)
      • Profile picture and professional details
      • Communication preferences
      • Authentication credentials
      
      Professional Profiles:
      • Brand personas and writing styles
      • Target audience profiles
      • Content preferences and strategies
      • Training examples and custom content
      • Professional background and expertise
      
      Usage Data:
      • Log data and analytics
      • Device and browser information
      • IP address and location data
      • Content creation patterns
      • Feature usage statistics
      • Session duration and interactions
      • Performance metrics
      
      Content Data:
      • Posts and drafts created using our platform
      • Engagement metrics and analytics
      • Scheduled content information
      • Content preferences and settings
      • AI-generated content and prompts
      • Custom templates and examples`
    },
    {
      title: '2. How We Use Your Information',
      content: `Your information helps us:
      • Provide and improve our services
      • Personalize your experience
      • Process your transactions
      • Send you important updates
      • Analyze and optimize platform performance
      • Protect against fraudulent activity
      • Comply with legal obligations
      • Debug and fix platform issues
      • Train and improve AI models
      • Generate content suggestions
      • Enhance persona profiles
      • Optimize target audience insights
      • Improve content strategies
      • Provide personalized analytics
      • Enhance LinkedIn integration
      • Develop new features
      
      Legal Bases for Processing (GDPR):
      • Contract fulfillment
      • Legal obligations
      • Legitimate interests
      • User consent
      • Public interest
      • Vital interests protection`
    },
    {
      title: '3. Data Storage and Security',
      content: `We implement robust security measures to protect your data:
      
      Infrastructure Security:
      • End-to-end encryption for sensitive data
      • Regular security audits and monitoring
      • Secure data centers and backup systems
      • Access controls and authentication
      • Employee security training
      • Incident response procedures
      • Regular penetration testing
      • DDoS protection
      • Firewall configurations
      
      Data Protection:
      • Encryption at rest and in transit
      • Regular backup procedures
      • Disaster recovery plans
      • Data retention policies
      • Access logging and monitoring
      • Security incident response plan
      • Persona data protection
      • Audience insights security
      • Content encryption
      
      Compliance:
      • GDPR compliance measures
      • CCPA compliance procedures
      • Industry standard certifications
      • Regular compliance audits
      • Data protection impact assessments`
    },
    {
      title: '4. Data Sharing and Third Parties',
      content: `We may share your information with:
      • Service providers (hosting, analytics, payment processing)
      • LinkedIn (for content publishing, with your explicit consent)
      • Legal authorities (when required by law)
      • Business partners (with your consent)
      
      Third-Party Integrations:
      • LinkedIn API integration
      • Analytics providers
      • Payment processors
      • Cloud storage providers
      
      We do not sell your personal information to third parties.
      
      Data Sharing Controls:
      • Granular permission settings
      • Integration management
      • Data export options
      • Sharing preferences`
    },
    {
      title: '5. Your Privacy Rights',
      content: `You have the right to:
      • Access your personal data
      • Request data correction or deletion
      • Export your data
      • Opt out of marketing communications
      • Withdraw consent for data processing
      • Control LinkedIn integration
      • Manage persona profiles
      • Delete audience insights
      • Control content sharing
      • File a complaint with supervisory authorities
      
      Data Control Features:
      • Privacy dashboard
      • Export tools
      • Deletion requests
      • Permission management
      • Integration controls`
    },
    {
      title: '6. Cookies and Tracking',
      content: `We use cookies and similar technologies to:
      • Maintain your session
      • Remember your preferences
      • Analyze platform usage
      • Improve user experience
      • Provide targeted features
      • Track content performance
      • Analyze audience engagement
      • Optimize AI suggestions
      
      Cookie Categories:
      • Essential cookies
      • Functional cookies
      • Analytics cookies
      • Performance cookies
      • AI preference cookies
      
      You can control cookie settings through your browser.`
    },
    {
      title: '7. Children\'s Privacy',
      content: `Our services are not intended for users under 16 years of age. We do not knowingly collect personal information from children.
      
      Protection Measures:
      • Age verification procedures
      • Immediate data deletion upon discovery
      • Parental consent requirements
      • Account termination for underage users
      
      If you become aware that a child has provided us with personal information:
      • Contact us immediately
      • We will delete the information
      • We will terminate the account
      • We will maintain records of such incidents`
    },
    {
      title: '8. International Data Transfers',
      content: `We may transfer your data to servers located outside your country. We ensure appropriate safeguards are in place through:
      
      Legal Framework:
      • Standard contractual clauses
      • Data processing agreements
      • Compliance with local data protection laws
      • Privacy Shield certification (where applicable)
      • Binding corporate rules
      
      Security Measures:
      • Encryption during transfer
      • Access controls
      • Data minimization
      • Regular audits
      • Vendor assessments
      
      User Rights:
      • Right to know where data is stored
      • Right to object to transfers
      • Right to data portability
      • Right to withdraw consent`
    },
    {
      title: '9. Data Retention',
      content: `We retain your information for as long as necessary to:
      • Provide our services
      • Comply with legal obligations
      • Resolve disputes
      • Enforce agreements
      
      Retention Periods:
      • Account data: Duration of account plus 30 days
      • Payment data: As required by tax laws
      • Usage logs: 12 months
      • Analytics data: 24 months in anonymous form
      • Marketing preferences: Until consent withdrawal
      • Persona profiles: Until deletion request
      • Audience insights: Until account termination
      • Content data: As specified by user
      
      After retention period expiration:
      • Secure deletion procedures
      • Anonymization where applicable
      • Archival of required legal records`
    },
    {
      title: '10. Your California Privacy Rights (CCPA)',
      content: `California residents have the right to:
      • Know what personal information is collected
      • Know whether personal information is sold or disclosed
      • Say no to the sale of personal information
      • Access their personal information
      • Request deletion of their information
      • Equal service and price (non-discrimination)
      
      Additional Rights:
      • Persona data access
      • Audience insights control
      • Content ownership
      • Integration management
      
      To exercise these rights:
      • Use our privacy dashboard
      • Contact our privacy team
      • Verify your identity
      • Receive timely responses
      
      We will respond to all requests within 45 days.`
    }
  ];

  return (
    <PublicPageLayout
      title="Privacy"
      description="How we protect your data"
      className="-mt-8"
    >
      <PageSection className="py-4">
        <div className="max-w-2xl mx-auto space-y-6">
          {/* Header */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className={`
              p-4 rounded-xl border flex items-center justify-between
              ${isDarkMode 
                ? 'bg-[#151515] border-white/10' 
                : 'bg-white border-slate-200/50'
              }
            `}
          >
            <div className="flex items-center gap-3">
              <LockClosedIcon className="w-5 h-5 text-blue-500" />
              <p className="text-sm font-medium">
                Last updated: January 19, 2025
              </p>
            </div>
            <a
              href="mailto:vinay@ammmplify.com"
              className={`
                text-xs
                ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'}
              `}
            >
              vinay@ammmplify.com
            </a>
          </motion.div>

          {/* Quick Links */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="flex flex-wrap gap-2"
          >
            {sections.map((section, index) => (
              <button
                key={index}
                onClick={() => document.getElementById(section.title).scrollIntoView({ behavior: 'smooth' })}
                className={`
                  text-xs px-3 py-1.5 rounded-lg transition-colors
                  ${isDarkMode 
                    ? 'bg-[#151515] border border-white/10 hover:bg-[#1a1a1a]' 
                    : 'bg-white border border-slate-200/50 hover:bg-slate-50'
                  }
                `}
              >
                {section.title.replace(/^\d+\.\s/, '')}
              </button>
            ))}
          </motion.div>

          {/* Sections */}
          <div className="space-y-4">
            {sections.map((section, index) => (
              <motion.div
                id={section.title}
                key={section.title}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05 }}
                className={`
                  p-4 rounded-xl border scroll-mt-4
                  ${isDarkMode 
                    ? 'bg-[#151515] border-white/10' 
                    : 'bg-white border-slate-200/50'
                  }
                `}
              >
                <h3 className={`
                  text-sm font-medium mb-3
                  ${isDarkMode ? 'text-white' : 'text-slate-900'}
                `}>
                  {section.title}
                </h3>
                <div className={`
                  text-xs space-y-2
                  ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
                `}>
                  <p className="whitespace-pre-line">
                    {section.content}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </PageSection>
    </PublicPageLayout>
  );
};

export default PrivacyPage; 