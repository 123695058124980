import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import TitleForLinkedInPostMockup from './titleForLinkedInPostMockup';
import CanvasPostContentEditor from './CanvasPostContentEditor';
import PropTypes from 'prop-types';
import { DEVICE_CONFIGS } from '../constants/deviceConfig';
import { API_BASE_URL } from '../config/constants';
import { ExclamationTriangleIcon, PencilIcon, UserIcon, CheckIcon, ArrowPathIcon, CheckCircleIcon, PaperAirplaneIcon, XMarkIcon } from '@heroicons/react/24/outline';
import '../styles/markdown.css';
import Portal from './Portal';
import { isLinkedInConnected } from '../services/linkedinPublishService';
import { motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { publishToLinkedIn } from '../services/linkedinPublishService';
import { updatePostPublishStatus } from '../services/linkedinPublishService';
import { uploadLinkedInPostMedia } from '../services/linkedinPostMediaService';
import { fetchMediaSignedUrl } from '../utils/mediaUtils';
import { getAuthToken } from '../utils/authUtils';
import { useMediaPreview } from '../context/MediaPreviewContext';
import { debounce } from 'lodash';
import { getSignedUrl } from '../services/linkedinPostMediaService';
import PDFPreview from './PDFPreview';
import AnimatedLoader from './common/AnimatedLoader';

/**
 * Utility function for random number generation
 * @param {number} min - Minimum value
 * @param {number} max - Maximum value
 * @returns {number} Random number between min and max
 */
const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

/**
 * Helper functions for generating random engagement numbers
 */
const getRandomReactions = () => getRandomNumber(0, 9999);
const getRandomComments = () => getRandomNumber(0, 999);
const getRandomShares = () => getRandomNumber(0, 99);

/**
 * LinkedIn post mockup component that simulates a LinkedIn post interface
 * @component
 * @param {Object} props
 * @param {Object} props.canvasContent - Content data for the post
 * @param {Function} props.onRemove - Callback when post is removed
 * @param {string} props.title - Post title
 * @param {Function} props.onTitleChange - Callback for title changes
 * @param {Function} props.onContentChange - Callback for content changes
 * @param {boolean} props.isDarkMode - Whether dark mode is enabled
 * @param {boolean} props.isNewest - Whether this is the newest post
 * @param {Function} props.onStartEditing - Callback when editing starts
 * @param {Function} props.onStopEditing - Callback when editing stops
 * @param {boolean} props.isFocused - Whether the post is focused
 * @param {Function} props.onExpand - Callback when post is expanded
 * @param {Function} props.onNavigateNext - Callback to navigate to next post
 * @param {Function} props.onNavigatePrevious - Callback to navigate to previous post
 * @param {number} props.currentIndex - Current index in posts array
 * @param {number} props.totalPosts - Total number of posts
 * @param {boolean} props.isInCanvasExperience - Whether in canvas experience mode
 * @param {string} props.focusBorderColor - Color for focus border
 * @param {boolean} props.removeBorderAndPadding - Whether to remove border and padding
 * @param {boolean} props.isEditing - Whether post is being edited
 * @param {Function} props.onSave - Callback when content is saved
 * @param {Function} props.onCancel - Callback when editing is cancelled
 * @param {boolean} props.hideTitleBar - Whether to hide the title bar
 * @param {boolean} props.isSimplifiedView - Whether to show simplified view
 * @param {boolean} props.forceExpanded - Whether to force expanded view
 * @param {boolean} props.preventCollapse - Whether to prevent collapsing
 * @param {Function} props.onRequestDelete - Callback when delete is requested
 * @param {Function} props.onFocusMode - Callback when focus mode is toggled
 * @param {string} props.activeDevice - Active device type
 * @param {string} props.canvasId - Canvas ID
 * @param {Function} props.onFullScreen - Callback when full screen mode is toggled
 * @param {boolean} props.isPublished - Whether the post is published
 * @param {boolean} props.isEditedLocally - Whether the post is edited locally
 * @param {Function} props.onPublish - Callback when post is published
 * @param {Function} props.onSchedule - Callback when post is scheduled
 */
const LinkedInPostMockup = React.memo(React.forwardRef(function LinkedInPostMockup({ 
  canvasContent, 
  setCanvasContent,
  onRemove,
  title,
  onTitleChange,
  onContentChange,
  isDarkMode,
  isNewest,
  onStartEditing,
  onStopEditing,
  isFocused = false,
  onExpand,
  onNavigateNext,
  onNavigatePrevious,
  currentIndex,
  totalPosts,
  isInCanvasExperience = false,
  focusBorderColor = '',
  removeBorderAndPadding,
  isEditing,
  onSave,
  onCancel,
  hideTitleBar = false,
  isSimplifiedView: initialSimplifiedView = true,
  forceExpanded = false,
  preventCollapse = false,
  onRequestDelete,
  onFocusMode,
  activeDevice,
  canvasId,
  onFullScreen,
  isPublished: initialIsPublished = false,
  isEditedLocally = false,
  onPublish,
  onSchedule,
}, ref) {
  // Move all state declarations to the top
  // eslint-disable-next-line no-unused-vars
  const [isExpanded, setIsExpanded] = useState(forceExpanded || !initialSimplifiedView);
  const [isInteracting, setIsInteracting] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isEditingContent, setIsEditingContent] = useState(isEditing);
  const [editedContent, setEditedContent] = useState(canvasContent?.content || '');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [prevWidth, setPrevWidth] = useState(null);
  const [prevPosition, setPrevPosition] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isContentHovered, setIsContentHovered] = useState(false);
  const [linkedInProfile, setLinkedInProfile] = useState(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [publishState, setPublishState] = useState(initialIsPublished ? 'published' : 'idle');
  const [showRepublishConfirm, setShowRepublishConfirm] = useState(false);
  const [isPublished, setIsPublished] = useState(initialIsPublished);
  const [isPublishing, setIsPublishing] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);
  const fileInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [hasContentChanges, setHasContentChanges] = useState(false);
  const { openPreview } = useMediaPreview();
  const [isMediaInitialized, setIsMediaInitialized] = useState(false);

  // Update isPublished when the prop changes
  useEffect(() => {
    setIsPublished(initialIsPublished);
  }, [initialIsPublished]);

  // Add these constants for the engagement numbers
  const reactions = useMemo(() => getRandomReactions(), []);
  const comments = useMemo(() => getRandomComments(), []);
  const shares = useMemo(() => getRandomShares(), []);

  // Add delete handlers inside the component
  const handleDeleteClick = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }
    setShowDeleteConfirm(true);
  }, []);

  const handleCancelDelete = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }
    setShowDeleteConfirm(false);
  }, []);

  const handleConfirmDelete = useCallback(async (e) => {
    if (e) {
      e.stopPropagation();
    }
    
    setIsDeleting(true);
    
    try {
      if (onRemove) {
        await onRemove(canvasContent.id);
      }
    } catch (error) {
      console.error('Error deleting post:', error);
      toast.error('Failed to delete post');
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirm(false);
    }
  }, [onRemove, canvasContent?.id]);

  // Refs
  const postRef = useRef(null);
  const contentEditableRef = useRef(null);
  const contentRef = useRef(null);

  // Add this new state for avatar URL
  useEffect(() => {
    if (linkedInProfile && linkedInProfile.avatarUrl) {
      setAvatarUrl(linkedInProfile.avatarUrl);
    } else {
      setAvatarUrl(null);
    }
  }, [linkedInProfile]);

  // Title-specific handler
  const handleTitleChange = useCallback((newTitle) => {
    if (onTitleChange && canvasContent?.id) {
      onTitleChange(newTitle);
    }
  }, [onTitleChange, canvasContent?.id]);

  /**
   * Handles content editing request
   * @param {Event} e - The event object
   */
  const handleContentEdit = useCallback((e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsEditingContent(true);
    setEditedContent(canvasContent.content);
    setHasContentChanges(false); // Reset changes flag when starting to edit
    
    // Initialize media state with existing media from the post
    if (canvasContent.mediaFiles && canvasContent.mediaFiles.length > 0) {
      const existingMedia = canvasContent.mediaFiles[0]; // Get the first media file
      setMediaFile({
        name: existingMedia.originalName,
        type: existingMedia.mimeType,
        size: existingMedia.size,
        mimeType: existingMedia.mimeType // Add mimeType for consistency
      });
      
      // Use direct URL for media preview
      const mediaUrl = existingMedia.cloudinaryUrl || existingMedia.url;
      setMediaPreview(mediaUrl);
    }

    if (onStartEditing) {
      onStartEditing();
    }
  }, [canvasContent, onStartEditing]);

  /**
   * Handles saving edited content
   * @param {string} newContent - The new content to save
   */
  const handleContentSave = useCallback(() => {
    const contentToSave = String(editedContent || '').trim();
    
    console.log('Saving content:', {
      contentToSave,
      postId: canvasContent?.id,
      mediaFile,
      editedContent,
      existingMediaFiles: canvasContent.mediaFiles
    });
    
    if (!contentToSave) {
      console.warn('Attempted to save empty content');
      toast('Already saved. Update content to save.', {
        style: {
          background: '#EFF6FF',
          color: '#3B82F6',
          border: '1px solid #93C5FD'
        },
        duration: 3000
      });
      return;
    }

    if (onContentChange) {
      // Preserve existing media files
      const existingMediaFiles = canvasContent.mediaFiles || [];
      
      // Create content update object
      const contentUpdate = {
        content: contentToSave,
        mediaFiles: existingMediaFiles
      };

      // If we have a new media file, add it to the array
      if (mediaFile && !existingMediaFiles.some(m => m.cloudinaryUrl === mediaFile.cloudinaryUrl)) {
        contentUpdate.mediaFiles = [...existingMediaFiles, mediaFile];
      }

      console.log('Saving content with media:', contentUpdate);
      
      // For new manual posts, generate a temporary ID if none exists
      const postId = canvasContent?.id || `temp_${Date.now()}`;
      onContentChange(postId, contentUpdate);
      
      setIsEditingContent(false);
      setEditedContent('');
      setHasContentChanges(false);
      
      if (onStopEditing) {
        onStopEditing();
      }
    }
  }, [canvasContent?.id, canvasContent?.mediaFiles, editedContent, onContentChange, onStopEditing, mediaFile]);

  const handleContentCancel = useCallback(() => {
    setIsEditingContent(false);
    setEditedContent('');
    setHasContentChanges(false);
    
    if (onStopEditing) {
      onStopEditing();
    }
  }, [onStopEditing]);

  // Add this utility function at the top
  const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  };

  // Helper function to cleanup media state
  const cleanupMediaState = (previewUrl) => {
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setMediaFile(null);
    setMediaPreview(null);
    setIsUploading(false);
    setUploadProgress(0);
  };

  // Add media file handling functions
  const handleMediaSelect = async (mediaInput) => {
    try {
      // If mediaInput is already a media info object (from CanvasPostContentEditor)
      if (mediaInput && typeof mediaInput === 'object' && !mediaInput.target && mediaInput.url) {
        console.log('Received media info object:', mediaInput);
        
        // Create updated content object with media
        const updatedContent = {
          ...canvasContent,
          content: editedContent,
          mediaFile: mediaInput,
          mediaFiles: [mediaInput]
        };

        // Update local state
        setEditedContent(updatedContent.content);
        
        // Update canvas content
        if (setCanvasContent) {
          setCanvasContent(updatedContent);
        }
        
        // Notify parent of content change
        if (onContentChange && canvasContent?.id) {
          onContentChange(canvasContent.id, updatedContent);
        }
        
        setHasContentChanges(true);
        return;
      }

      // Add null checks for e.target and e.target.files
      if (!mediaInput?.target?.files?.length) {
        console.warn('No files selected or files property is undefined');
        return;
      }

      const file = mediaInput.target.files[0];
      if (!file) return;

      // Validate file type and size
      const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf', 'video/mp4'];
      const maxSize = 100 * 1024 * 1024; // 100MB

      if (!validTypes.includes(file.type)) {
        toast.error('Please select a valid file (JPEG, PNG, GIF, PDF, or MP4)');
        return;
      }

      if (file.size > maxSize) {
        toast.error('File size should be less than 100MB');
        return;
      }

      // Get token
      const token = getAuthToken();
      if (!token) {
        toast.error('Please log in to upload media');
        return;
      }

      // Set temporary preview and upload states
      const tempPreviewUrl = URL.createObjectURL(file);
      setMediaFile(file);
      setMediaPreview(tempPreviewUrl);
      setHasContentChanges(true);
      setIsUploading(true);
      setUploadProgress(0);

      // Implement realistic progress animation
      let lastProgress = 0;
      let activeIntervals = [];
      
      const clearAllIntervals = () => {
        activeIntervals.forEach(interval => clearInterval(interval));
        activeIntervals = [];
      };

      const smoothProgress = (actualProgress) => {
        // Clear any existing intervals
        clearAllIntervals();

        // For small files (< 1MB), add artificial delay
        const isSmallFile = file.size < 1024 * 1024;
        const minSteps = isSmallFile ? 20 : 10; // More steps for small files
        
        // Calculate steps based on file size and progress difference
        const progressDiff = actualProgress - lastProgress;
        const baseSteps = Math.max(minSteps, Math.floor(progressDiff));
        
        // Adjust increment based on file size
        // Smaller files should progress slower to match network latency
        const baseIncrement = progressDiff / baseSteps;
        const increment = isSmallFile ? baseIncrement * 0.5 : baseIncrement;

        // Calculate interval based on file size
        // Larger files should update more frequently
        const baseInterval = isSmallFile ? 100 : 50;
        const interval = Math.max(50, Math.min(baseInterval, file.size / 1024));

        let currentProgress = lastProgress;

        // Use non-linear progress curve
        // Progress starts faster and slows down as it approaches the target
        const intervalId = setInterval(() => {
          const remaining = actualProgress - currentProgress;
          const speed = Math.max(0.1, remaining * 0.1); // Slow down as we approach target
          currentProgress += Math.min(speed, increment);

          if (currentProgress >= actualProgress) {
            currentProgress = actualProgress;
            clearInterval(intervalId);
            const index = activeIntervals.indexOf(intervalId);
            if (index > -1) activeIntervals.splice(index, 1);
          }

          // Only show up to 90% during actual upload
          // Reserve last 10% for backend processing
          const displayProgress = Math.min(90, Math.round(currentProgress));
          setUploadProgress(displayProgress);
        }, interval);

        activeIntervals.push(intervalId);
        lastProgress = actualProgress;
      };

      // Start upload immediately
      const uploadedMedia = await uploadLinkedInPostMedia(file, token, (progress) => {
        // Scale the upload progress to 90% of the total
        // This reserves 10% for backend processing
        smoothProgress(Math.min(90, progress * 0.9));
      });

      // Create new media file object
      const newMediaFile = {
        originalName: file.name,
        cloudinaryUrl: uploadedMedia?.secure_url || uploadedMedia?.url,
        url: uploadedMedia?.secure_url || uploadedMedia?.url,
        mimeType: file.type,
        size: file.size,
        uploadedAt: new Date(),
        metadata: {
          format: file.type ? file.type.split('/')[1] : '',
          width: null,
          height: null,
          duration: null,
          resource_type: uploadedMedia?.resource_type || 'image',
          public_id: uploadedMedia?.public_id || uploadedMedia?.asset_id || uploadedMedia?.public_id || null
        }
      };

      // Detailed debug logging
      console.log('Raw Cloudinary response:', uploadedMedia);
      console.log('Secure URL:', uploadedMedia?.secure_url);
      console.log('Public ID:', uploadedMedia?.public_id);

      // More detailed validation
      if (!newMediaFile.cloudinaryUrl) {
        console.error('Missing Cloudinary URL:', uploadedMedia);
        throw new Error('Missing Cloudinary URL in response');
      }

      if (!newMediaFile.metadata.public_id) {
        console.error('Missing public_id:', uploadedMedia);
        // Don't throw here, just log warning
        console.warn('Missing public_id in Cloudinary response');
      }

      // Debug logging
      console.log('Cloudinary upload response:', uploadedMedia);
      console.log('Created media file:', newMediaFile);

      // If it's an image, get dimensions
      if (file.type.startsWith('image/')) {
        const img = new Image();
        img.src = tempPreviewUrl;
        await new Promise((resolve) => {
          img.onload = () => {
            newMediaFile.metadata.width = img.width;
            newMediaFile.metadata.height = img.height;
            resolve();
          };
          img.onerror = () => {
            console.warn('Failed to load image dimensions');
            resolve();
          };
        });
      }

      // If it's a video, get duration
      if (file.type === 'video/mp4') {
        const video = document.createElement('video');
        video.src = tempPreviewUrl;
        await new Promise((resolve) => {
          video.onloadedmetadata = () => {
            newMediaFile.metadata.width = video.videoWidth;
            newMediaFile.metadata.height = video.videoHeight;
            newMediaFile.metadata.duration = video.duration;
            resolve();
          };
          video.onerror = () => {
            console.warn('Failed to load video metadata');
            resolve();
          };
        });
      }

      // Show processing phase (90% to 95%)
      smoothProgress(95);
      
      // Get existing media files from canvasContent
      const existingMediaFiles = canvasContent?.mediaFiles || [];
      
      // Update media files in the backend
      if (canvasId && canvasContent?.id) {
        await fetch(`${API_BASE_URL}/api/linkedin-post-media/canvas/${canvasId}/posts/${canvasContent.id}/media`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            mediaFiles: [...existingMediaFiles, newMediaFile]
          })
        });
      }

      // Show finalizing phase (95% to 98%)
      smoothProgress(98);

      // Use direct cloudinary URL
      const mediaUrl = uploadedMedia.secure_url || uploadedMedia.url;
      
      // Revoke the temporary blob URL
      URL.revokeObjectURL(tempPreviewUrl);
      
      // Final completion (98% to 100%)
      smoothProgress(100);
      
      // Clean up all intervals
      clearAllIntervals();
      
      // Update state with the signed URL and media file info
      setMediaFile(newMediaFile);
      setMediaPreview(mediaUrl);
      setIsUploading(false);
      toast.success('Media uploaded successfully');

      // Update canvasContent with new media files
      if (typeof setCanvasContent === 'function') {
        setCanvasContent({
          ...canvasContent,
          mediaFiles: [...existingMediaFiles, newMediaFile]
        });
      } else {
        console.warn('setCanvasContent is not available, skipping canvas content update');
      }

      // After successful upload
      setIsMediaInitialized(true);
    } catch (error) {
      console.error('Error uploading media:', error);
      toast.error(error.details || 'Failed to upload media');
      cleanupMediaState(mediaPreview);
      setIsUploading(false);
      setUploadProgress(0);
      setIsMediaInitialized(false);
    }
  };

  const handleRemoveMedia = async () => {
    try {
      // Clean up local state
      if (mediaPreview?.startsWith('blob:')) {
        URL.revokeObjectURL(mediaPreview);
      }
      setMediaFile(null);
      setMediaPreview(null);
      setHasContentChanges(true);
      setUploadProgress(0);
      setIsUploading(false);
      setIsMediaInitialized(false); // Reset initialization state

      // Update parent state by removing media files
      if (typeof setCanvasContent === 'function' && canvasContent) {
        const updatedContent = {
          ...canvasContent,
          mediaFiles: [] // Clear media files array
        };
        setCanvasContent(updatedContent);

        // Update in backend if we have canvasId and postId
        if (canvasId && canvasContent.id) {
          const token = getAuthToken();
          if (token) {
            await fetch(`${API_BASE_URL}/api/linkedin-post-media/canvas/${canvasId}/posts/${canvasContent.id}/media`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({
                mediaFiles: []
              })
            });
          }
        }
      }
    } catch (error) {
      console.error('Error removing media:', error);
      toast.error('Failed to remove media');
    }
  };

  // Clean up preview URL on unmount
  useEffect(() => {
    return () => {
      if (mediaPreview && mediaPreview.startsWith('blob:')) {
        URL.revokeObjectURL(mediaPreview);
      }
    };
  }, [mediaPreview]);

  // Update renderContent to show upload progress
  const renderContent = () => {
    if (isEditingContent) {
      return (
        <>
          <CanvasPostContentEditor
            ref={contentEditableRef}
            initialContent={canvasContent.content}
            content={editedContent || canvasContent.content}
            onSave={handleContentSave}
            onCancel={handleContentCancel}
            isDarkMode={isDarkMode}
            isEditing={isEditingContent}
            removeBorderAndPadding={isInCanvasExperience && removeBorderAndPadding}
            hideSaveCancelButtons={true}
            onChange={(newContent) => {
              console.log('Content changed:', newContent);
              setEditedContent(newContent);
              setHasContentChanges(newContent.trim() !== canvasContent.content.trim());
            }}
            mediaFile={mediaFile}
            mediaPreview={mediaPreview}
            onMediaSelect={handleMediaSelect}
            onMediaRemove={handleRemoveMedia}
            isUploading={isUploading}
            uploadProgress={uploadProgress}
          />
        </>
      );
    }

    return (
      <>
        <div 
          ref={contentRef}
          className={`
            relative
            whitespace-pre-wrap
            ${isEditingContent ? 'cursor-text' : 'cursor-pointer'}
            transition-colors
            duration-200
            ${isContentHovered ? `
              ${isDarkMode 
                ? 'text-slate-100' 
                : 'text-slate-900'}
            ` : ''}
          `}
          onClick={(e) => {
            e.stopPropagation();
            handleContentEdit(e);
          }}
        >
          {canvasContent.content}
        </div>
        {mediaPreview && (
          <div className="mt-4 relative group">
            {(mediaFile?.mimeType?.startsWith('image/') || mediaFile?.type?.startsWith('image/')) ? (
              <div className="relative">
                <img 
                  src={mediaPreview} 
                  alt={mediaFile.originalName || "Post media"} 
                  className="rounded-lg max-h-[400px] w-auto object-contain cursor-pointer"
                  onClick={handleMediaClick}
                />
                {isUploading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-lg">
                    <div className="text-center text-white">
                      <div className="mb-2">Uploading... {uploadProgress}%</div>
                      <div className="w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-blue-500 transition-all duration-300"
                          style={{ width: `${uploadProgress}%` }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (mediaFile?.mimeType === 'video/mp4' || mediaFile?.type === 'video/mp4') ? (
              <div className="relative">
                <video 
                  src={mediaPreview} 
                  controls 
                  className="rounded-lg max-h-[400px] w-auto cursor-pointer"
                  onClick={handleMediaClick}
                />
                {isUploading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-lg">
                    <div className="text-center text-white">
                      <div className="mb-2">Uploading... {uploadProgress}%</div>
                      <div className="w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-blue-500 transition-all duration-300"
                          style={{ width: `${uploadProgress}%` }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (mediaFile?.mimeType === 'application/pdf' || mediaFile?.type === 'application/pdf') ? (
              <div 
                className={`
                  rounded-lg overflow-hidden
                  transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] border border-[#333333]' 
                    : 'bg-gray-50 border border-gray-200'
                  }
                `}
              >
                {/* PDF Viewer */}
                <div className="relative">
                  <PDFPreview 
                    url={mediaPreview} 
                    isDarkMode={isDarkMode}
                    containerStyle="max-h-[600px]"
                    showFullScreenButton={true}
                    onFullScreen={handleMediaClick}
                  />
                  {isUploading && (
                    <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 rounded-lg">
                      <AnimatedLoader size={24} color="white" className="mb-2" />
                      <div className="text-sm font-medium text-white">
                        Uploading...
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div 
                className={`
                  rounded-lg p-4 flex items-center gap-2 relative cursor-pointer
                  ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'}
                `}
                onClick={handleMediaClick}
              >
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                </svg>
                <span className="text-sm">{mediaFile?.originalName || mediaFile?.name || 'File'}</span>
                {isUploading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-lg">
                    <div className="text-center text-white">
                      <div className="mb-2">Uploading... {uploadProgress}%</div>
                      <div className="w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-blue-500 transition-all duration-300"
                          style={{ width: `${uploadProgress}%` }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {isEditingContent && !isUploading && (
              <button
                onClick={handleRemoveMedia}
                className={`
                  absolute top-2 right-2 p-1 rounded-full
                  opacity-0 group-hover:opacity-100
                  transition-opacity duration-200
                  ${isDarkMode 
                    ? 'bg-black/50 hover:bg-black/70 text-white' 
                    : 'bg-white/50 hover:bg-white/70 text-black'
                  }
                `}
              >
                <XMarkIcon className="w-4 h-4" />
              </button>
            )}
          </div>
        )}
      </>
    );
  };

  // Add console log to verify prop
  useEffect(() => {
    if (isNewest) {
      console.log('LinkedInPostMockup received isNewest prop:', { id: canvasContent.id });
    }
  }, [isNewest, canvasContent.id]);

  // Add this effect to handle smooth transitions when post position changes
  useEffect(() => {
    if (isNewest) {
      const element = postRef.current;
      if (element) {
        element.style.transition = 'all 0.5s ease-out';
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [isNewest]);

  // Update isEditingContent when isEditing prop changes
  useEffect(() => {
    setIsEditingContent(isEditing);
  }, [isEditing]);

  // Inside the LinkedInPostMockup component
  const customTitle = canvasContent.title.split(' - ')[1] || canvasContent.title;

  // Add mouse event handlers
  const handleMouseEnter = useCallback(() => {
    setIsInteracting(true);
    setIsHovered(true);
  }, []);

  // Keep the second handleMouseLeave as is (for interaction state)
  const handleMouseLeave = useCallback(() => {
    if (!isEditingContent) {
      setIsInteracting(false);
      setIsHovered(false);
    }
  }, [isEditingContent]);

  // Add the copy handler function near other handlers
  const handleCopy = useCallback(async () => {
    try {
      // Only copy the content, not the title
      await navigator.clipboard.writeText(canvasContent.content);
      toast.success('Post copied to clipboard!');
    } catch (error) {
      console.error('Failed to copy post:', error);
      toast.error('Failed to copy post');
    }
  }, [canvasContent.content]);

  // Add an effect to handle forceExpanded changes
  useEffect(() => {
    if (forceExpanded) {
      setIsExpanded(true);
    }
  }, [forceExpanded]);

  // Update the handleFocusMode function to use the prop
  const handleFocusMode = useCallback((e) => {
    e.stopPropagation();
    onFocusMode(canvasContent); // Pass the post content to the parent
  }, [canvasContent, onFocusMode]);

  // Update the save handler in LinkedInPostMockup
  const handleSaveClick = useCallback((e) => {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
      e.stopPropagation();
    }
    
    handleContentSave();
    setIsEditingContent(false); // Ensure we exit edit mode
  }, [handleContentSave]);

  // Add this new function to calculate responsive width
  const calculateResponsiveWidth = useCallback(() => {
    const isMobileView = window.innerWidth <= 768;
    
    if (isMobileView) {
      // On mobile, use available viewport width minus padding
      return `${Math.min(window.innerWidth - 32, DEVICE_CONFIGS[activeDevice].width)}px`;
    }
    
    // For other devices, use the device config width
    return `${DEVICE_CONFIGS[activeDevice].width}px`;
  }, [activeDevice]);

  // Update the getDeviceWidth function
  const getDeviceWidth = useCallback(() => {
    return calculateResponsiveWidth();
  }, [calculateResponsiveWidth]);

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      // Force re-render when window is resized
      setPrevWidth(calculateResponsiveWidth());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calculateResponsiveWidth]);

  // Fix position effect dependencies
  useEffect(() => {
    const currentPosition = canvasContent?.position;
    if (prevPosition !== null && 
        JSON.stringify(prevPosition) !== JSON.stringify(currentPosition)) {
      setIsMoving(true);
      const timer = setTimeout(() => {
        setIsMoving(false);
      }, 200);
      return () => clearTimeout(timer);
    }
    setPrevPosition(currentPosition);
  }, [canvasContent?.position, prevPosition]);

  // First, add this new CSS class near the top of the file, after the imports
  const publishedStyles = {
    light: `
      border border-green-200 
      ring-1 ring-green-100 
      shadow-[0_0_15px_-3px_rgba(22,163,74,0.15)]
      bg-white/95
    `,
    dark: `
      border border-green-800/30 
      ring-1 ring-green-800/20 
      shadow-[0_0_15px_-3px_rgba(22,163,74,0.2)]
      bg-[#1e1e1e]
    `
  };

  // Add effect to fetch LinkedIn profile data
  useEffect(() => {
    const fetchLinkedInProfile = async () => {
      try {
        setIsLoadingProfile(true);
        const linkedInStatus = await isLinkedInConnected();
        
        if (linkedInStatus.isConnected && linkedInStatus.profile) {
          // Extract profile data from the response
          const profileData = linkedInStatus.profile.profile_data || linkedInStatus.profile;
          
          // Debug log to see the exact structure
          console.log('Raw LinkedIn Status:', linkedInStatus);
          
          // Get name from the correct path in the data structure
          let fullName;
          if (profileData.name) {
            fullName = profileData.name;
          } else if (profileData.given_name && profileData.family_name) {
            fullName = `${profileData.given_name} ${profileData.family_name}`;
          } else if (linkedInStatus.profile.name) {
            fullName = linkedInStatus.profile.name;
          } else {
            fullName = 'LinkedIn User';
          }
          
          setLinkedInProfile({
            name: fullName,
            avatarUrl: profileData.picture || null
          });
          
          // Debug log to see what data we're getting
          console.log('LinkedIn Profile Data:', {
            profileData,
            linkedInProfile: {
              name: fullName,
              avatarUrl: profileData.picture || null
            }
          });
        }
      } catch (error) {
        console.error('Error fetching LinkedIn profile:', error);
      } finally {
        setIsLoadingProfile(false);
      }
    };

    fetchLinkedInProfile();
  }, []);

  // Add this helper function at the top with other utility functions
  const formatPublishedDate = (date) => {
    if (!date) return 'Now';
    
    const publishedDate = new Date(date);
    const now = new Date();
    const diffInSeconds = Math.floor((now - publishedDate) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInSeconds < 60) return 'Just now';
    if (diffInMinutes < 60) return `${diffInMinutes}m`;
    if (diffInHours < 24) return `${diffInHours}h`;
    if (diffInDays < 7) return `${diffInDays}d`;
    
    return publishedDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  // Update the renderAvatar function to handle loading and error states better
  const renderAvatar = () => {
    if (isLoadingProfile) {
      return (
        <div className={`h-12 w-12 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} animate-pulse`} />
      );
    }

    if (!linkedInProfile) {
      return null;
    }

    // Get the published date from canvasContent
    const publishedDate = canvasContent?.publishedAt || canvasContent?.publishHistory?.lastPublishedAt;
    const displayDate = formatPublishedDate(publishedDate);

    return (
      <div className="flex items-center">
        {linkedInProfile.avatarUrl ? (
          <img 
            src={linkedInProfile.avatarUrl}
            alt={linkedInProfile.name}
            className="h-12 w-12 rounded-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = ''; // Default to empty to show fallback
            }}
          />
        ) : (
          <div className={`h-12 w-12 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} flex items-center justify-center`}>
            <UserIcon className={`h-8 w-8 ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`} />
          </div>
        )}
        
        <div className="ml-3 flex-1 min-w-0">
          <div className={`text-sm font-semibold truncate ${isDarkMode ? 'text-slate-200' : 'text-slate-800'}`}>
            {linkedInProfile.name}
          </div>
          <div className="flex items-center gap-1 mt-0.5">
            <span className={`text-xs truncate ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
              {displayDate}
            </span>
            <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>•</span>
            <svg 
              aria-hidden="true" 
              className={`w-3 h-3 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} 
              xmlns="http://www.w3.org/2000/svg" 
              fill="none" 
              viewBox="0 0 14 14"
            >
              <g>
                <path 
                  id="Union" 
                  fill="currentColor" 
                  fillRule="evenodd" 
                  d="M1.50226 6.99993C1.50226 3.96361 3.96368 1.5022 6.99999 1.5022C7.78361 1.5022 8.52894 1.66614 9.20354 1.96161V2.23807C9.20354 2.68362 9.02655 3.11092 8.7115 3.42597C8.39645 3.74102 7.96915 3.91802 7.5236 3.91802C7.29823 3.91789 7.07515 3.96312 6.86762 4.05099C6.6601 4.13887 6.47237 4.2676 6.31563 4.42953C6.15888 4.59146 6.03632 4.78327 5.95523 4.99354C5.87415 5.20382 5.83621 5.42826 5.84366 5.6535V7.04188C5.84366 7.2625 5.8002 7.48095 5.71578 7.68477C5.63135 7.88859 5.50761 8.07378 5.35161 8.22978C5.19561 8.38578 5.01042 8.50952 4.8066 8.59395C4.60278 8.67838 4.38432 8.72183 4.16371 8.72183H2.46988H1.7773C1.59883 8.18019 1.50226 7.60133 1.50226 6.99993ZM9.71406 11.7821C10.8973 11.1092 11.8063 10.0098 12.2321 8.69305L12.0961 8.59334C11.6577 8.36677 11.1726 8.24548 10.6791 8.23911H8.80677C8.42317 8.27394 8.06645 8.45095 7.8067 8.73537C7.54694 9.01978 7.40292 9.39104 7.40292 9.77623C7.40292 10.1614 7.54694 10.5327 7.8067 10.8171C8.06645 11.1015 8.42317 11.2785 8.80677 11.3133C8.95071 11.3117 9.09355 11.3386 9.22702 11.3925C9.36049 11.4464 9.48192 11.5263 9.5843 11.6275C9.63237 11.675 9.67579 11.7268 9.71406 11.7821ZM6.99999 0.00219727C3.13525 0.00219727 0.0022583 3.13519 0.0022583 6.99993C0.0022583 10.8647 3.13525 13.9977 6.99999 13.9977C10.8647 13.9977 13.9977 10.8647 13.9977 6.99993C13.9977 3.13519 10.8647 0.00219727 6.99999 0.00219727Z" 
                  clipRule="evenodd"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  };

  // Add this component near the top with other component definitions
  const PublishingIndicator = ({ isDarkMode }) => (
    <div className={`
      absolute 
      inset-0 
      flex 
      items-center 
      justify-center
      backdrop-blur-[1px]
      ${isDarkMode ? 'bg-black/20' : 'bg-white/20'}
      z-50
      rounded-lg
    `}>
      <div className={`
        flex 
        items-center 
        gap-2 
        px-3 
        py-2 
        rounded-full
        ${isDarkMode 
          ? 'bg-slate-800/90 text-slate-200' 
          : 'bg-white/90 text-slate-800'}
        shadow-lg
        border
        ${isDarkMode 
          ? 'border-white/10' 
          : 'border-black/10'}
      `}>
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className={`w-4 h-4 border-2 border-t-transparent rounded-full
            ${isDarkMode ? 'border-white/60' : 'border-black/60'}`}
        />
        <span className="text-sm font-medium">Publishing to LinkedIn...</span>
      </div>
    </div>
  );

  // Add this component for the republish confirmation dialog
  const RepublishConfirmDialog = ({ isOpen, onConfirm, onCancel, isDarkMode }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center">
        {/* Backdrop with subtle blur effect */}
        <div className="absolute inset-0 bg-black/20 backdrop-blur-[1px]" onClick={onCancel} />
        
        {/* Dialog content - Inspired by shadcn's compact design */}
        <div className={`
          relative
          w-full
          max-w-[320px]
          mx-4
          p-4
          rounded-lg
          shadow-lg
          ${isDarkMode 
            ? 'bg-slate-900 border border-slate-800' 
            : 'bg-white border border-slate-200'
          }
        `}>
          <div className="flex flex-col gap-3">
            <div className="flex items-start gap-3">
              <div className={`
                p-2 
                rounded-full 
                ${isDarkMode ? 'bg-slate-800' : 'bg-slate-100'}
              `}>
                <ExclamationTriangleIcon className="w-5 h-5 text-yellow-500" />
              </div>
              <div>
                <h3 className={`
                  text-sm 
                  font-semibold 
                  ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
                `}>
                  Confirm Republish
                </h3>
                <p className={`
                  text-xs 
                  mt-1 
                  ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                `}>
                  This post has already been published. Are you sure you want to publish it again?
                </p>
              </div>
            </div>

            <div className="flex items-center justify-end gap-2">
              <button
                onClick={onCancel}
                className={`
                  px-3 
                  py-1.5 
                  text-xs 
                  font-medium 
                  rounded-md
                  transition-colors
                  ${isDarkMode 
                    ? 'text-slate-300 hover:bg-slate-800' 
                    : 'text-slate-700 hover:bg-slate-100'
                  }
                `}
              >
                Cancel
              </button>
              <button
                onClick={onConfirm}
                className={`
                  px-3 
                  py-1.5 
                  text-xs 
                  font-medium 
                  rounded-md
                  bg-blue-500 
                  text-white
                  hover:bg-blue-600
                  transition-colors
                `}
              >
                Republish
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Add PropTypes for the new component
  RepublishConfirmDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isDarkMode: PropTypes.bool.isRequired,
  };

  // Add this before handlePublishClick
  const handlePublish = useCallback(async () => {
    try {
      setPublishState('publishing');
      
      // First check LinkedIn connection status
      const linkedInStatus = await isLinkedInConnected();
      console.log('LinkedIn connection status:', linkedInStatus);
      
      if (!linkedInStatus.isConnected || linkedInStatus.needsReconnect) {
        const message = linkedInStatus.error || 
          (linkedInStatus.needsReconnect 
            ? 'LinkedIn authentication has expired' 
            : 'Please connect your LinkedIn account first');
        
        toast.error(message);
        setPublishState('idle');
        return;
      }
      
      const result = await publishToLinkedIn(canvasContent.content, title, canvasContent.mediaFiles);
      console.log('Publish result:', result);

      // Update publish status in database
      await updatePostPublishStatus(canvasId, canvasContent.id, true);
      
      setPublishState('published');
      setIsPublished(true);
      toast.success('Successfully published to LinkedIn!');
      
      // Only reset the publishing indicator state, not the actual published state
      setTimeout(() => {
        setPublishState('idle');
      }, 2000);
    } catch (error) {
      console.error('Error publishing to LinkedIn:', error);
      setPublishState('idle');
      
      const errorMessage = error.message || 'Failed to publish to LinkedIn';
      toast.error(errorMessage);

      // If it's a duplicate content error, still mark as published since it exists on LinkedIn
      if (errorMessage.includes('duplicate') || errorMessage.includes('already been published')) {
        try {
          await updatePostPublishStatus(canvasId, canvasContent.id, true);
          setIsPublished(true);
        } catch (dbError) {
          console.error('Error updating publish status:', dbError);
        }
      }
    }
  }, [canvasContent.content, canvasContent.id, canvasId, title, canvasContent.mediaFiles]);

  // Add this effect to sync isPublished with initialIsPublished prop
  useEffect(() => {
    // This ensures the published state stays in sync with the prop
    setIsPublished(initialIsPublished);
  }, [initialIsPublished]);

  // Update the handlePublishStateChange to maintain published state
  const handlePublishStateChange = useCallback((newPublishState) => {
    setPublishState(newPublishState);
    // Only set isPublished to true when published, never back to false
    if (newPublishState === 'published') {
      setIsPublished(true);
    }
  }, []);

  // Handler for publish button click
  const handlePublishClick = useCallback(() => {
    if (isPublished) {
      setShowRepublishConfirm(true);
    } else {
      // Handle normal publish flow
      handlePublish();
    }
  }, [isPublished, handlePublish]);

  // Handler for republish confirmation
  const handleRepublishConfirm = useCallback(() => {
    setShowRepublishConfirm(false);
    handlePublish();
  }, [handlePublish]);

  // Add border styles based on publish state
  const getBorderStyles = () => {
    if (publishState === 'published') {
      return isDarkMode 
        ? 'border-green-500/20 shadow-[0_0_0_1px_rgba(34,197,94,0.2)]' 
        : 'border-green-500/20 shadow-[0_0_0_1px_rgba(34,197,94,0.2)]';
    }
    return isDarkMode ? 'border-[#333333]' : 'border-gray-200';
  };

  // Add schedule handler
  const handleSchedule = useCallback((postData) => {
    if (!canvasContent?.content) {
      toast.error('Cannot schedule empty content');
      return;
    }
    
    if (onSchedule) {
      onSchedule({
        canvasId,
        postId: canvasContent.id,
        content: canvasContent.content,
        title: title
      });
    }
  }, [canvasId, canvasContent, title, onSchedule]);

  // Add useEffect to handle media files initialization
  useEffect(() => {
    // Skip if no media files or if in editing mode
    if (!canvasContent?.mediaFiles?.length) {
      setMediaFile(null);
      setMediaPreview(null);
      return;
    }

    const latestMedia = canvasContent.mediaFiles[canvasContent.mediaFiles.length - 1];
    
    // First try to use cloudinaryUrl, then fall back to url
    if (latestMedia?.cloudinaryUrl || latestMedia?.url) {
      const mediaUrl = latestMedia.cloudinaryUrl || latestMedia.url;
      setMediaFile({
        ...latestMedia,
        mimeType: latestMedia.mimeType,
        type: latestMedia.mimeType, // For compatibility
        url: mediaUrl, // Add url for compatibility
        cloudinaryUrl: mediaUrl
      });
      setMediaPreview(mediaUrl);
      setIsMediaInitialized(true);
    }
  }, [canvasContent?.mediaFiles]);

  // Add this new component before MediaPreviewModal
  const MediaPreviewModal = ({ isOpen, onClose, mediaUrl, mediaType, isDarkMode }) => {
    if (!isOpen) return null;

    return (
      <div 
        className="fixed inset-0 z-[9999] flex items-center justify-center bg-black/80"
        onClick={onClose}
      >
        <div className="relative w-full h-full flex items-center justify-center p-4">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>

          {/* Media content */}
          <div 
            className="max-w-[90%] max-h-[90vh] relative"
            onClick={(e) => e.stopPropagation()}
          >
            {mediaType?.startsWith('image/') ? (
              <img
                src={mediaUrl}
                alt="Media preview"
                className="max-w-full max-h-[90vh] object-contain rounded-lg"
              />
            ) : mediaType === 'video/mp4' ? (
              <video
                src={mediaUrl}
                controls
                className="max-w-full max-h-[90vh] rounded-lg"
                autoPlay
              />
            ) : mediaType === 'application/pdf' ? (
              <PDFPreview url={mediaUrl} isDarkMode={isDarkMode} />
            ) : (
              <div className={`
                p-8 rounded-lg
                ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'}
              `}>
                <div className="flex items-center gap-3">
                  <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
                  <span className="text-lg">{mediaFile?.originalName || 'File'}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Add this new click handler function
  const handleMediaClick = (e) => {
    e.stopPropagation(); // Prevent post expansion
    if (!isEditingContent) {
      openPreview({
        url: mediaPreview,
        type: mediaFile?.mimeType,
        name: mediaFile?.originalName
      });
    }
  };

  // Update the main container's className to include green border when published
  return (
    <div 
      className={`
        flex flex-col
        ${isInCanvasExperience ? 'mb-4' : 'mb-4'}
        mx-auto
        w-full
        px-4
        transition-all
        duration-200
      `}
      style={{
        maxWidth: getDeviceWidth(),
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Title bar container */}
      {!hideTitleBar && (
        <div className="w-full" style={{ 
          width: '100%',
          maxWidth: calculateResponsiveWidth()
        }}>
          <TitleForLinkedInPostMockup
            initialTitle={title}
            onTitleChange={onTitleChange}
            onEdit={handleContentEdit}
            onCopy={handleCopy}
            onRemove={onRemove}
            currentIndex={currentIndex}
            totalPosts={totalPosts}
            onNavigateNext={onNavigateNext}
            onNavigatePrevious={onNavigatePrevious}
            isEditing={isEditingContent}
            onSave={handleContentSave}
            onCancel={handleContentCancel}
            isInCanvasExperience={isInCanvasExperience}
            isInteracting={isInteracting}
            onDelete={handleDeleteClick}
            isHovered={isHovered}
            canvasId={canvasId}
            postId={canvasContent.id}
            onFullScreen={onFullScreen}
            content={canvasContent.content}
            isPublished={canvasContent.published}
            onPublish={handlePublishStateChange}
            onSchedule={handleSchedule}
            hasContentChanges={hasContentChanges}
            isUploading={isUploading}
            mediaFiles={canvasContent.mediaFiles}
          />
        </div>
      )}

      {/* Main post content container */}
      <div 
        ref={postRef}
        className={`
          linkedin-post-mockup rounded-lg
          relative group/post
          overflow-visible
          ${
            isEditingContent
              ? `border-2 border-dashed p-6 ${
                  isDarkMode 
                    ? 'bg-[#151515] border-[#333333]' 
                    : 'bg-white border-blue-300'
                }`
              : isPublished
                ? `p-6 ${isDarkMode ? publishedStyles.dark : publishedStyles.light}`
                : `p-6 ${
                    isDarkMode 
                      ? 'bg-[#1e1e1e] border border-[#333333]' 
                      : 'bg-white border border-gray-200'
                  }`
          }
          ${isFocused && isInCanvasExperience ? `ring-1.5 ring-[${focusBorderColor}]` : ''}
          ${showDeleteConfirm ? 'blur-[1px] pointer-events-none' : ''}
          ${isResizing ? 'post-resizing' : ''}
          ${isMoving ? 'post-moving' : ''}
          transition-all
          duration-200
          ease-in-out
          ${!isEditingContent ? `
            ${isPublished 
              ? `hover:shadow-[0_4px_16px_rgb(34,197,94,0.08)]
                 dark:hover:shadow-[0_4px_16px_rgb(34,197,94,0.12)]
                 hover:border-opacity-60`
              : `hover:shadow-[0_4px_12px_rgb(0,0,0,0.02)]
                 dark:hover:shadow-[0_4px_12px_rgb(0,0,0,0.08)]
                 hover:border-opacity-60`
            }
            ${isDarkMode 
              ? 'hover:bg-[#1a1a1a] hover:border-slate-700/50' 
              : 'hover:bg-white hover:border-slate-200'
            }
            hover:translate-y-[-1px]
            hover:scale-[1.0008]
            active:scale-[1.0004]
            active:translate-y-[-0.5px]
            ${isPublished 
              ? `active:shadow-[0_2px_8px_rgb(34,197,94,0.06)]
                 dark:active:shadow-[0_2px_8px_rgb(34,197,94,0.1)]`
              : `active:shadow-[0_2px_8px_rgb(0,0,0,0.01)]
                 dark:active:shadow-[0_2px_8px_rgb(0,0,0,0.06)]`
            }
          ` : ''}
          w-full
        `}
        onMouseEnter={() => setIsContentHovered(true)}
        onMouseLeave={() => setIsContentHovered(false)}
        style={{
          cursor: isEditingContent ? 'default' : 'pointer',
          zIndex: isFocused ? 1000 : isEditingContent ? 999 : 1,
          userSelect: 'none',
          touchAction: 'pan-y',
          pointerEvents: showDeleteConfirm ? 'none' : 'auto',
          isolation: isEditingContent ? 'isolate' : 'auto',
          width: '100%',
          maxWidth: getDeviceWidth(),
          height: 'auto',
          minHeight: 'min-content',
          margin: '1px auto',
          position: 'relative',
          overflow: 'visible',
          willChange: isResizing || isMoving ? 'transform, width' : 'auto',
          transform: 'translateZ(0)', // Force GPU acceleration for smoother animations
        }}
      >
        {/* Update the pencil icon to be clickable */}
        {!isEditingContent && (
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent post expansion when clicking edit
              handleContentEdit(e);
            }}
            className={`
              absolute 
              right-4 
              top-4
              p-1.5
              rounded-full
              transform
              opacity-0
              group-hover/post:opacity-100
              transition-all
              duration-200
              ${isDarkMode 
                ? 'bg-slate-800/90 text-slate-300 hover:bg-slate-700/90 ring-1 ring-slate-700/50' 
                : 'bg-slate-50/90 text-slate-600 hover:bg-slate-100/90 ring-1 ring-slate-200/50'
              }
              cursor-pointer
              hover:scale-105
              active:scale-95
              z-10
            `}
            title="Edit post"
          >
            <div className="flex items-center gap-2 text-xs font-medium">
              <PencilIcon className="w-3.5 h-3.5" />
            </div>
          </button>
        )}

        {/* Add published and edited locally indicator badges */}
        <div className="absolute left-4 top-4 flex gap-2">
          {isPublished && (
            <div className={`
              px-2 
              py-1
              rounded-full
              flex items-center gap-1.5
              ${isDarkMode 
                ? 'bg-slate-800/90 text-slate-300 ring-1 ring-slate-700/50' 
                : 'bg-slate-50/90 text-slate-600 ring-1 ring-slate-200/50'}
              z-10
            `}>
              <CheckIcon className="w-3.5 h-3.5" />
              <span className="text-xs font-medium">Published</span>
            </div>
          )}
          {isEditedLocally && (
            <div className={`
              px-2 
              py-1
              rounded-full
              flex items-center gap-1.5
              ${isDarkMode 
                ? 'bg-slate-800/90 text-slate-300 ring-1 ring-slate-700/50' 
                : 'bg-slate-50/90 text-slate-600 ring-1 ring-slate-200/50'}
              z-10
            `}>
              <PencilIcon className="w-3.5 h-3.5" />
              <span className="text-xs font-medium">Edited locally</span>
            </div>
          )}
        </div>

        {/* Post header with avatar and persona info */}
        <div className={`
          flex items-center
          ${isPublished ? 'opacity-95' : 'opacity-100'}
          ${isPublished ? 'mt-8' : ''}
        `}>
          {renderAvatar()}
        </div>

        {/* Post content */}
        <div className={`
          mt-6
          ${isPublished ? 'opacity-95' : 'opacity-100'}
        `}>
          <div
            className={`text-sm ${
              isDarkMode 
                ? isEditingContent 
                  ? 'text-slate-200 bg-[#151515]' 
                  : 'text-slate-300' 
                : 'text-gray-700'
            } whitespace-pre-wrap break-words relative`}
            style={{
              position: 'relative',
              zIndex: 0
            }}
          >
            {renderContent()}
          </div>
        </div>
      </div>
      {/* Show delete confirmation modal */}
      {showDeleteConfirm && (
        <div 
          className="fixed inset-0 z-[9999] flex items-center justify-center p-4"
          onClick={(e) => {
            if (e.target === e.currentTarget && !isDeleting) handleCancelDelete();
          }}
        >
          {/* Improved backdrop */}
          <div 
            className={`absolute inset-0 ${
              isDarkMode 
                ? 'bg-slate-900/70 backdrop-blur-sm' 
                : 'bg-slate-50/80 backdrop-blur-sm'
            } transition-opacity duration-300`}
            aria-hidden="true"
          />
          
          {/* Modal content - Vertical layout with compact sizes */}
          <div 
            className={`
              ${isDarkMode 
                ? 'bg-slate-800 border-slate-700' 
                : 'bg-white border-gray-200'
              } 
              border rounded-lg p-4 relative z-[10000] w-[300px] 
              transform transition-all duration-200 ease-out
              ${isDarkMode 
                ? 'ring-1 ring-slate-600/50 shadow-xl shadow-black/20' 
                : 'ring-1 ring-gray-200/50 shadow-xl shadow-black/10'
              }
            `}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
          >
            {/* Warning icon - Centered */}
            <div className="flex h-8 w-8 mx-auto items-center justify-center rounded-full bg-red-100 mb-3">
              {isDeleting ? (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                  className="w-4 h-4 border-2 border-red-500 border-t-transparent rounded-full"
                />
              ) : (
                <ExclamationTriangleIcon 
                  className="h-4 w-4 text-red-500" 
                  aria-hidden="true" 
                />
              )}
            </div>

            <div 
              id="modal-title"
              className={`text-sm font-semibold text-center mb-1 ${
                isDarkMode ? 'text-slate-200' : 'text-gray-900'
              }`}
            >
              {isDeleting ? 'Deleting Post...' : 'Delete This Post?'}
            </div>

            <div className={`text-xs text-center mb-3 ${
              isDarkMode 
                ? 'text-slate-400'
                : 'text-gray-500'
            }`}>
              {isDeleting 
                ? 'Please wait while we delete your post...'
                : 'Sure you want to delete this post? This action cannot be undone.'}
            </div>

            <div className="flex space-x-2">
              <button
                onClick={handleCancelDelete}
                disabled={isDeleting}
                className={`
                  flex-1 px-3 py-1.5 rounded text-xs font-medium 
                  transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-slate-700 hover:bg-slate-600 text-slate-200 focus:ring-slate-500' 
                    : 'bg-gray-100 hover:bg-gray-200 text-gray-700 focus:ring-gray-400'
                  } 
                  focus:outline-none focus:ring-2 focus:ring-offset-2
                  ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}
                `}
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmDelete}
                disabled={isDeleting}
                className={`
                  flex-1 px-3 py-1.5 rounded text-xs font-medium
                  bg-red-500 hover:bg-red-600 text-white
                  transition-all duration-200
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                  ${isDarkMode 
                    ? 'shadow-sm shadow-red-900/30' 
                    : 'shadow-sm shadow-red-200/50'
                  }
                  ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}
                `}
              >
                {isDeleting ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add publishing indicator */}
      {publishState === 'publishing' && (
        <PublishingIndicator isDarkMode={isDarkMode} />
      )}

      {/* Add republish confirmation dialog */}
      <RepublishConfirmDialog
        isOpen={showRepublishConfirm}
        onConfirm={handleRepublishConfirm}
        onCancel={() => setShowRepublishConfirm(false)}
        isDarkMode={isDarkMode}
      />
    </div>
  );
}));

// Update PropTypes
LinkedInPostMockup.propTypes = {
  canvasContent: PropTypes.object,
  setCanvasContent: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  title: PropTypes.string,
  onTitleChange: PropTypes.func,
  onContentChange: PropTypes.func,
  isDarkMode: PropTypes.bool,
  isNewest: PropTypes.bool,
  onStartEditing: PropTypes.func,
  onStopEditing: PropTypes.func,
  isFocused: PropTypes.bool,
  onExpand: PropTypes.func,
  onNavigateNext: PropTypes.func,
  onNavigatePrevious: PropTypes.func,
  currentIndex: PropTypes.number,
  totalPosts: PropTypes.number,
  isInCanvasExperience: PropTypes.bool,
  focusBorderColor: PropTypes.string,
  removeBorderAndPadding: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  hideTitleBar: PropTypes.bool,
  isSimplifiedView: PropTypes.bool,
  forceExpanded: PropTypes.bool,
  preventCollapse: PropTypes.bool,
  onRequestDelete: PropTypes.func,
  onFocusMode: PropTypes.func,
  activeDevice: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  canvasId: PropTypes.string,
  onFullScreen: PropTypes.func,
  isPublished: PropTypes.bool,
  isEditedLocally: PropTypes.bool,
  onPublish: PropTypes.func,
  onSchedule: PropTypes.func,
};

/**
 * Skeleton component for LinkedIn post mockup
 * @component
 * @param {Object} props
 * @param {boolean} props.isDarkMode - Whether dark mode is enabled
 */
const SkeletonLinkedInPost = ({ isDarkMode }) => {
  return (
    <div className={`linkedin-post-mockup shadow-sm rounded-lg ${
      isDarkMode ? 'bg-slate-800 border-slate-700' : 'bg-white border-gray-200'
    } border relative p-6 animate-pulse mb-6`}  // Changed p-4 to p-6
    style={{ 
      width: '400px',
      marginBottom: '24px',
    }}
    >
      <div className="flex items-center mb-6"> {/* Changed from mb-4 */}
        <div className={`h-12 w-12 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'}`}></div>
        <div className="ml-3 flex-1 min-w-0">
          <div className={`h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-3/4 mb-2`}></div>
          <div className={`h-3 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-1/2`}></div>
          <div className={`h-2 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-1/4 mt-2`}></div>
        </div>
      </div>
      <div className="mb-6"> {/* Changed from mb-4 */}
        <div className={`h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-full mb-2`}></div>
        <div className={`h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-5/6 mb-2`}></div>
        <div className={`h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-4/6`}></div>
      </div>
      <div className="flex justify-between items-center">
        <div className={`h-3 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-1/4`}></div>
        <div className={`h-3 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} rounded w-1/3`}></div>
      </div>
    </div>
  );
};

// Export both components
export { SkeletonLinkedInPost };
export default LinkedInPostMockup;