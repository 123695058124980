import React, { useState, useRef, useEffect } from 'react';
import { UserIcon, ChevronDownIcon, PlusIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import api from '../../api';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import InitialsAvatar from '../common/InitialsAvatar';

const PersonasForContentStrategy = ({ onSelectPersona, selectedPersona }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [personas, setPersonas] = useState([]);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const loadPersonas = async () => {
      try {
        const response = await api.get('/api/personas');
        setPersonas(response.data);
      } catch (error) {
        console.error('Error fetching personas:', error);
      }
    };

    loadPersonas();
  }, []);

  useEffect(() => {
    const updatePosition = () => {
      if (buttonRef.current && isOpen) {
        const rect = buttonRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom + window.scrollY + 5,
          left: rect.left + window.scrollX,
          width: rect.width,
        });
      }
    };

    updatePosition();
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isOpen]);

  const handlePersonaSelect = (persona) => {
    onSelectPersona(persona);
    setIsOpen(false);
  };

  const handleAddNew = () => {
    setIsOpen(false);
    navigate('/personas/create');
  };

  const renderPersonaItem = (persona) => (
    <button
      key={persona._id}
      onClick={() => handlePersonaSelect(persona)}
      className={`block w-full text-left px-3 py-2 text-sm rounded-md
        transition-all duration-200
        ${isDarkMode
          ? 'bg-indigo-500/10 hover:bg-indigo-500/15 text-indigo-300'
          : 'bg-indigo-50 hover:bg-indigo-100 text-indigo-600'
        }
      `}
    >
      <div className="flex items-center">
        <InitialsAvatar name={persona.name} size="tiny" className="mr-2" />
        <span className="truncate">{persona.name}</span>
      </div>
    </button>
  );

  const renderDropdown = () => (
    <div 
      className="fixed inset-0 z-[1000]" 
      onClick={() => setIsOpen(false)}
    >
      <div 
        className="fixed"
        style={{
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          width: '300px',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={`rounded-lg shadow-lg backdrop-blur-sm ${
            isDarkMode 
              ? 'bg-[#151515]/95 border border-[#333333]' 
              : 'bg-white/95 border border-indigo-200/50'
          }`}
          onClick={e => e.stopPropagation()}
        >
          <div className="py-3">
            <div className="px-4 mb-2 flex items-center justify-between">
              <div className={`text-xs font-medium ${
                isDarkMode ? 'text-indigo-300/70' : 'text-indigo-500/70'
              }`}>
                Your personas
              </div>
              <div className="flex items-center gap-2">
                <div className={`text-[10px] px-1.5 rounded-full ${
                  isDarkMode 
                    ? 'bg-indigo-500/10 text-indigo-300' 
                    : 'bg-indigo-50 text-indigo-500'
                }`}>
                  {personas.length}
                </div>
                {selectedPersona && (
                  <button
                    onClick={() => {
                      onSelectPersona(null);
                      setIsOpen(false);
                    }}
                    className={`text-[10px] px-1.5 rounded-full transition-colors duration-200 ${
                      isDarkMode
                        ? 'text-red-400/70 hover:text-red-300'
                        : 'text-red-500/70 hover:text-red-500'
                    }`}
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>
            <div className="space-y-1 px-2">
              {personas.map(renderPersonaItem)}
            </div>
          </div>

          <div className={`px-4 py-3 border-t ${
            isDarkMode ? 'border-indigo-500/20' : 'border-indigo-200/20'
          }`}>
            <button
              onClick={handleAddNew}
              className={`
                w-full flex items-center justify-center 
                px-3 py-2 rounded-full text-xs font-medium
                transition-all duration-200
                ${isDarkMode
                  ? 'bg-indigo-500/10 text-indigo-300 hover:bg-indigo-500/20 active:bg-indigo-500/30'
                  : 'bg-indigo-500 text-white hover:bg-indigo-600 active:bg-indigo-700'
                }
                transform hover:scale-[1.02] active:scale-[0.98]
              `}
            >
              <PlusIcon className="w-3.5 h-3.5 mr-1.5" />
              Create New Persona
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );

  return (
    <>
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className={`inline-flex items-center px-3 py-1.5 text-sm font-medium rounded-full min-w-[140px] 
          transition-all duration-200
          ${isDarkMode
            ? 'bg-indigo-500/10 text-indigo-300 hover:bg-indigo-500/15'
            : 'bg-indigo-50 text-indigo-600 hover:bg-indigo-100'
          }
        `}
      >
        {selectedPersona ? (
          <>
            <InitialsAvatar name={selectedPersona.name} size="tiny" className="mr-2" />
            {selectedPersona.name}
          </>
        ) : (
          <span className="flex-1 text-center">Select Persona</span>
        )}
        <ChevronDownIcon className="w-3.5 h-3.5 ml-2 opacity-50" />
      </button>

      {isOpen && createPortal(renderDropdown(), document.body)}
    </>
  );
};

export default PersonasForContentStrategy; 