import axios from 'axios';
import { API_BASE_URL } from '../config/constants';
import { toast } from 'react-hot-toast';
import { getAuthToken } from '../utils/mediaUtils';

const MAX_RETRIES = 3;
const TIMEOUT = 300000; // 5 minutes timeout

export const uploadLinkedInPostMedia = async (file, token, onProgress) => {
  const formData = new FormData();
  formData.append('file', file);
  
  if (!token) {
    token = getAuthToken();
    if (!token) {
      toast.error('Please log in to upload media');
      throw { details: 'Authentication token not found. Please log in again.' };
    }
  }

  let retries = 0;
  const axiosInstance = axios.create({
    timeout: TIMEOUT,
    maxContentLength: Infinity,
    maxBodyLength: Infinity
  });

  const upload = async () => {
    try {
      const response = await axiosInstance.post(`${API_BASE_URL}/api/linkedin-post-media/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
        withCredentials: true,
        onUploadProgress: (progressEvent) => {
          if (onProgress) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress(percentCompleted);
          }
        }
      });

      // Return Cloudinary response
      const { file: cloudinaryFile } = response.data;
      return {
        originalName: cloudinaryFile.name,
        public_id: cloudinaryFile.public_id,
        url: cloudinaryFile.url,
        type: cloudinaryFile.type,
        size: cloudinaryFile.size,
        width: cloudinaryFile.width,
        height: cloudinaryFile.height,
        duration: cloudinaryFile.duration,
        resource_type: cloudinaryFile.resource_type
      };
    } catch (error) {
      console.error('Error uploading LinkedIn post media:', error);

      // Check if we should retry
      if (retries < MAX_RETRIES && (
        error.code === 'ECONNABORTED' || 
        error.message === 'Network Error' ||
        (error.response && error.response.status >= 500)
      )) {
        retries++;
        const delay = Math.pow(2, retries) * 1000; // Exponential backoff
        console.log(`Retrying upload (${retries}/${MAX_RETRIES}) after ${delay}ms`);
        await new Promise(resolve => setTimeout(resolve, delay));
        return upload(); // Retry the upload
      }

      // Handle specific errors
      if (error.response) {
        if (error.response.status === 401) {
          toast.error('Your session has expired. Please log in again.');
          throw { details: 'Your session has expired. Please log in again.' };
        } else if (error.response.status === 413) {
          toast.error('File is too large. Maximum size is 100MB.');
          throw { details: 'File size exceeds the maximum limit of 100MB.' };
        }
        throw error.response.data;
      } else if (error.code === 'ECONNABORTED') {
        toast.error('Upload timed out. Please try again with a smaller file or check your connection.');
        throw { details: 'Upload timed out. Please try with a smaller file or check your connection.' };
      } else if (error.message === 'Network Error') {
        toast.error('Connection error. Please check your internet connection.');
        throw { details: 'No response received from server. Please check your connection.' };
      } else {
        const errorMsg = error.message || 'An error occurred while uploading media.';
        toast.error(errorMsg);
        throw { details: errorMsg };
      }
    }
  };

  return upload();
};

export const deleteLinkedInPostMedia = async (public_id, resource_type = 'image') => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error('Authentication token not found');
    }

    await axios.delete(`${API_BASE_URL}/api/linkedin-post-media/media/${public_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      params: { resource_type }
    });

    return true;
  } catch (error) {
    console.error('Error deleting media:', error);
    throw error;
  }
};

export const getSignedUrl = async (public_id) => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error('Authentication token not found');
    }

    // Remove the folder prefix if it exists
    const cleanPublicId = public_id.includes('/') ? public_id.split('/').pop() : public_id;
    
    const response = await axios.get(`${API_BASE_URL}/api/linkedin-post-media/signed-url/${encodeURIComponent(cleanPublicId)}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    return response.data.signedUrl;
  } catch (error) {
    console.error('Error getting signed URL:', error);
    throw error;
  }
}; 