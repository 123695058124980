import React from 'react';
import { motion } from 'framer-motion';
import PublicPageLayout from './layout/PublicPageLayout';
import PageSection from './shared/PageSection';
import { useTheme } from '../../context/ThemeContext';
import { 
  SparklesIcon, 
  UserGroupIcon, 
  RocketLaunchIcon,
  HeartIcon,
  ArrowRightIcon
} from '@heroicons/react/24/outline';

const AboutPage = () => {
  const { isDarkMode } = useTheme();

  const values = [
    {
      icon: SparklesIcon,
      title: 'AI-Powered Innovation',
      description: 'Leveraging Claude, GPT-4o, Grok, and Gemini to enhance your content creation'
    },
    {
      icon: UserGroupIcon,
      title: 'Built for Professionals',
      description: 'Designed specifically for LinkedIn content creators and thought leaders'
    },
    {
      icon: RocketLaunchIcon,
      title: 'Focus on Growth',
      description: 'Tools and features that help you build a strong professional presence'
    },
    {
      icon: HeartIcon,
      title: 'Human-Centered',
      description: 'AI assists but never replaces your authentic voice and expertise'
    }
  ];

  const fadeInUpVariant = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <PublicPageLayout
      title="About"
      description="AI-powered content creation for LinkedIn professionals"
      className="-mt-8"
    >
      <PageSection className="py-4">
        <div className="max-w-2xl mx-auto space-y-8">
          {/* Mission Statement */}
          <motion.div
            variants={fadeInUpVariant}
            initial="hidden"
            animate="visible"
            className={`
              p-6 rounded-xl border
              ${isDarkMode 
                ? 'bg-[#151515] border-white/10' 
                : 'bg-white border-slate-200/50'
              }
            `}
          >
            <h2 className={`
              text-lg font-medium mb-3 flex items-center gap-2
              ${isDarkMode ? 'text-white' : 'text-slate-900'}
            `}>
              <SparklesIcon className="w-5 h-5 text-blue-500" />
              Our Mission
            </h2>
            <p className={`
              text-sm leading-relaxed
              ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
            `}>
              Ammmplify helps professionals create authentic LinkedIn content that resonates. 
              We combine AI technology with your expertise to amplify your professional voice 
              and build meaningful connections.
            </p>
          </motion.div>

          {/* Values Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {values.map((value, index) => (
              <motion.div
                key={value.title}
                variants={fadeInUpVariant}
                initial="hidden"
                animate="visible"
                transition={{ delay: index * 0.1 }}
                className={`
                  group p-4 rounded-xl border
                  transition-all duration-200 hover:scale-[1.02]
                  ${isDarkMode 
                    ? 'bg-[#151515] border-white/10 hover:bg-[#1a1a1a]' 
                    : 'bg-white border-slate-200/50 hover:bg-slate-50'
                  }
                `}
              >
                <div className="flex items-start gap-3">
                  <div className={`
                    p-2 rounded-lg
                    ${isDarkMode 
                      ? 'bg-blue-500/10 text-blue-400' 
                      : 'bg-blue-50 text-blue-600'
                    }
                  `}>
                    <value.icon className="w-4 h-4" />
                  </div>
                  <div>
                    <h3 className={`
                      text-sm font-medium mb-1
                      ${isDarkMode ? 'text-white' : 'text-slate-900'}
                    `}>
                      {value.title}
                    </h3>
                    <p className={`
                      text-xs leading-relaxed
                      ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                    `}>
                      {value.description}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Story Section */}
          <motion.div
            variants={fadeInUpVariant}
            initial="hidden"
            animate="visible"
            className={`
              p-6 rounded-xl border space-y-4
              ${isDarkMode 
                ? 'bg-[#151515] border-white/10' 
                : 'bg-white border-slate-200/50'
              }
            `}
          >
            <h2 className={`
              text-lg font-medium mb-3 flex items-center gap-2
              ${isDarkMode ? 'text-white' : 'text-slate-900'}
            `}>
              <RocketLaunchIcon className="w-5 h-5 text-blue-500" />
              Our Story
            </h2>
            <div className={`
              text-sm space-y-3
              ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
            `}>
              <p>
                Founded in 2024, Ammmplify emerged from a simple observation: while LinkedIn 
                offers immense professional growth potential, many struggle to consistently 
                create engaging content that reflects their expertise.
              </p>
              <p>
                Today, we're proud to help thousands of professionals amplify their voice 
                and make a lasting impact in their industries.
              </p>
            </div>

            {/* CTA Button */}
            <a
              href="/chat"
              className={`
                inline-flex items-center gap-2 mt-2
                text-sm font-medium
                ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'}
                transition-colors duration-200
              `}
            >
              Try Ammmplify
              <ArrowRightIcon className="w-4 h-4" />
            </a>
          </motion.div>
        </div>
      </PageSection>
    </PublicPageLayout>
  );
};

export default AboutPage; 