import React, { useState, useCallback, useEffect } from 'react';
import { ClipboardIcon, CheckIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';
import ChatMessageMarkdown from './ChatMessageMarkdown';
import TemplateTag from './TemplateTag';
import AIAgentMacro from './AIAgentMacro';
import '../styles/markdown.css';

// ------------------------------------------------------------------------
// AIResponseChat Component
// ------------------------------------------------------------------------
// This component renders AI responses in a chat interface with support for
// markdown content, error states, copying, and retry functionality
// ------------------------------------------------------------------------

const AIResponseChat = ({ message, index, isDarkMode, copiedMessages, onCopy, onSave, onSaveToTextNotes, personaAvatarUrl, logo, onRetry = () => {}, isAutoScrolling, agent }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [copyState, setCopyState] = useState(false);
  
  useEffect(() => {
    console.log('AIResponseChat received message:', {
      fullMessage: message,
      content: message?.content,
      messageContent: message?.message?.content,
      structure: {
        hasDirectContent: Boolean(message?.content),
        hasNestedContent: Boolean(message?.message?.content),
        role: message?.role,
        id: message?.id || message?.message?.id || message?._id
      }
    });
  }, [message]);

  const handleCopy = async (content, idx) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopyState(true);
      onCopy(content, idx);
      
      toast.success('Message copied!', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });

      setTimeout(() => {
        setCopyState(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
      toast.error('Failed to copy message');
    }
  };

  const handleRetry = useCallback((content) => {
    if (typeof onRetry === 'function') {
      onRetry(content, index);
    }
  }, [onRetry, index]);

  // These lines already handle both formats correctly
  const messageContent = message?.content || message?.message?.content;
  const messageId = message?.id || message?.message?.id || message?._id;
  
  // Add null check for message
  if (!message || !messageContent) {
    console.warn('No valid message content found:', {
      message,
      contentPath1: message?.content,
      contentPath2: message?.message?.content
    });
    return null;
  }

  // If we have an agent, use AIAgentMacro
  if (agent) {
    return (
      <AIAgentMacro
        content={messageContent}
        agent={agent}
        isDarkMode={isDarkMode}
      />
    );
  }

  return (
    <div 
      id={`ai-message-${messageId || index}`}
      className="mb-12 relative w-full scroll-mt-16"
      data-message-id={messageId}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <motion.div 
        className="flex flex-col items-start"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ 
          type: "spring",
          stiffness: 500,
          damping: 40,
          mass: 1
        }}
      >
        <div className="mb-0 z-10">
          <motion.div 
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.2 }}
            className={`w-6 h-6 flex items-center justify-center overflow-hidden rounded-full ${
              isDarkMode ? 'bg-[#151515]' : 'bg-white'
            }`}
          >
            {personaAvatarUrl ? (
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.1 }}
                src={personaAvatarUrl}
                alt="Persona Avatar"
                className="w-full h-full object-cover"
              />
            ) : (
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.1 }}
                src={logo}
                alt="AI Avatar"
                className="w-full h-full object-contain"
              />
            )}
          </motion.div>
        </div>
        <motion.div 
          className={`chat-bubble ${isDarkMode ? 'dark' : 'light'} max-w-500px]`}
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.1, duration: 0.2 }}
        >
          <div className="flex items-start px-4 pt-0 pb-0">
            <div className="flex-grow text-left overflow-hidden">
              {message.isError ? (
                <motion.div 
                  className="text-red-500 flex items-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  Error: {message.content}
                  {typeof onRetry === 'function' && (
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      className="ml-2 text-blue-500 hover:text-blue-700"
                      onClick={() => handleRetry(message.content)}
                      title="Retry"
                    >
                      <ArrowPathIcon className="h-4 w-4" />
                    </motion.button>
                  )}
                </motion.div>
              ) : (
                <>
                  {message.templateTag && (
                    <motion.div 
                      className="mb-3"
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <TemplateTag title={message.templateTag} />
                    </motion.div>
                  )}
                  <motion.div 
                    className="message-content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                  >
                    <div className="markdown-content">
                      <ChatMessageMarkdown
                        content={message.content}
                        isDarkMode={isDarkMode}
                        onSaveToTextNotes={onSaveToTextNotes}
                        index={index}
                        copiedMessages={copiedMessages}
                        onCopy={onCopy}
                      />
                    </div>
                  </motion.div>
                </>
              )}
            </div>
          </div>
        </motion.div>
        <div className="absolute left-0 bottom-[-28px] right-0 flex justify-start">
          <div className="mt-1 pl-4">
            <div className="action-buttons">
              <button
                className={`
                  py-1 px-2 rounded-md flex items-center space-x-1.5 text-[11px] border
                  transform transition-all duration-200 ease-out
                  ${copyState 
                    ? isDarkMode 
                      ? 'bg-green-500/5 text-green-400 border-green-500/20' 
                      : 'bg-green-50 text-green-600 border-green-200/50'
                    : isDarkMode 
                      ? 'bg-[#151515] hover:bg-[#202020] text-gray-500 hover:text-gray-400 border-[#252525]' 
                      : 'bg-white hover:bg-gray-50 text-gray-400 hover:text-gray-500 border-gray-100'
                  }
                `}
                onClick={() => handleCopy(message.content, index)}
                title="Copy message"
              >
                <motion.div
                  initial={false}
                  animate={{
                    scale: copyState ? [1, 1.05, 1] : 1,
                  }}
                  transition={{ duration: 0.2, ease: "easeOut" }}
                  className="flex items-center space-x-1.5"
                >
                  {copyState ? (
                    <>
                      <CheckIcon className="w-[10px] h-[10px]" />
                      <span className="font-normal tracking-wide">Copied</span>
                    </>
                  ) : (
                    <>
                      <ClipboardIcon className="w-[10px] h-[10px]" />
                      <span className="font-normal tracking-wide">Copy</span>
                    </>
                  )}
                </motion.div>
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AIResponseChat;
