import React, { useEffect, useState } from 'react';
import { CheckIcon, SparklesIcon, ClockIcon, CheckCircleIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import SubscriptionService from '../../services/subscriptionService';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import { toast } from 'react-hot-toast';
import GeoService from '../../services/geoService';
import { useNavigate } from 'react-router-dom';

const getColorClasses = (color, isDarkMode) => {
  const colors = {
    indigo: {
      badge: isDarkMode 
        ? 'bg-gradient-to-r from-indigo-500 to-purple-500 text-white ring-1 ring-indigo-500'
        : 'bg-gradient-to-r from-indigo-100 to-purple-100 text-indigo-600',
      button: 'bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600 transform transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]',
      ring: isDarkMode ? 'ring-indigo-500' : 'ring-indigo-200/50',
      shadow: 'shadow-[0_8px_30px_rgba(99,102,241,0.2)]',
      check: isDarkMode ? 'text-indigo-400' : 'text-indigo-500',
      manage: isDarkMode 
        ? 'bg-indigo-500/10 text-indigo-400 hover:bg-indigo-500/20' 
        : 'bg-indigo-50 text-indigo-600 hover:bg-indigo-100'
    },
    blue: {
      badge: isDarkMode 
        ? 'bg-gradient-to-r from-blue-500 to-sky-500 text-white ring-1 ring-blue-500'
        : 'bg-gradient-to-r from-blue-100 to-sky-100 text-blue-600',
      button: 'bg-gradient-to-r from-blue-500 to-sky-500 hover:from-blue-600 hover:to-sky-600 transform transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]',
      ring: isDarkMode ? 'ring-blue-500' : 'ring-blue-200/50',
      shadow: 'shadow-[0_8px_30px_rgba(59,130,246,0.2)]',
      check: isDarkMode ? 'text-blue-400' : 'text-blue-500',
      manage: isDarkMode 
        ? 'bg-blue-500/10 text-blue-400 hover:bg-blue-500/20' 
        : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
    },
    emerald: {
      badge: isDarkMode 
        ? 'bg-gradient-to-r from-emerald-500/10 to-teal-500/10 text-emerald-400 ring-1 ring-emerald-500/20'
        : 'bg-gradient-to-r from-emerald-100 to-teal-100 text-emerald-600',
      button: 'bg-gradient-to-r from-emerald-500 to-teal-500 hover:from-emerald-600 hover:to-teal-600 transform transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]',
      ring: isDarkMode ? 'ring-emerald-400/10' : 'ring-emerald-200/50',
      shadow: 'shadow-[0_8px_30px_rgba(16,185,129,0.2)]',
      check: isDarkMode ? 'text-emerald-400' : 'text-emerald-500',
      manage: isDarkMode 
        ? 'bg-emerald-500/10 text-emerald-400 hover:bg-emerald-500/20' 
        : 'bg-emerald-50 text-emerald-600 hover:bg-emerald-100'
    }
  };
  return colors[color] || colors.blue;
};

const PlanCard = ({ 
  plan, 
  isActive, 
  onSubscribe, 
  onCancel, 
  onReactivate, 
  subscription, 
  isDarkMode,
  isPublic,
  onPublicSubscribe,
  onStartTrial,
  showTrialInPublic
}) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const subscriptionService = new SubscriptionService();
  const colorClasses = getColorClasses(plan.color, isDarkMode);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleManageSubscription = async () => {
    try {
      if (!user?.isSubscribed) {
        toast.error('Please subscribe to a plan to access subscription management.');
        return;
      }

      setIsLoading(true);
      const { url } = await subscriptionService.createCustomerPortalSession();
      
      if (!url) {
        toast.error('Unable to access subscription management. Please try again later.');
        return;
      }

      await new Promise(resolve => setTimeout(resolve, 100));
      window.location.href = url;
    } catch (error) {
      console.error('Error redirecting to customer portal:', error);
      toast.error(error.message || 'Unable to access subscription management. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const isProPlan = plan.id === 'pro';
  const isSubscribed = user?.isSubscribed;
  const isCurrentPlan = isSubscribed && 
    user?.subscriptionDetails?.planId === plan.id && 
    user?.subscriptionDetails?.interval === plan.interval;
  const isCancelled = subscription?.cancelAtPeriodEnd;

  console.log('Plan status:', {
    planId: plan.id,
    planInterval: plan.interval,
    isSubscribed,
    userPlan: user?.subscriptionDetails?.planId,
    userInterval: user?.subscriptionDetails?.interval,
    isCurrentPlan,
    isCancelled
  });

  const getButtonConfig = () => {
    // For public pages (not logged in)
    if (isPublic) {
      return {
        text: 'Start Free Trial',
        onClick: () => onPublicSubscribe(plan.id),
        variant: 'primary',
        note: 'No credit card required'
      };
    }

    // For logged in users with subscription
    if (user?.isSubscribed) {
      // If this is current plan
      if (isCurrentPlan) {
        if (subscription?.cancelAtPeriodEnd) {
          return {
            text: 'Reactivate Subscription',
            onClick: () => onReactivate(plan.id),
            variant: 'secondary'
          };
        }
        return {
          text: 'Manage Plan',
          onClick: handleManageSubscription,
          variant: 'manage'
        };
      }
      // If user is subscribed but this is not their current plan
      return {
        text: 'Change Plan',
        onClick: () => onSubscribe(plan.id),
        variant: 'primary'
      };
    }

    // For users in trial
    if (user?.isInTrial) {
      const trialEnd = new Date(user.trialEndDate);
      const now = new Date();
      const daysLeft = Math.ceil((trialEnd - now) / (1000 * 60 * 60 * 24));
      return {
        text: `${daysLeft} days left in trial`,
        onClick: () => onSubscribe(plan.id),
        variant: 'secondary',
        subText: 'Subscribe to continue access'
      };
    }

    // For users with expired trial
    if (user?.hasTrialExpired) {
      return {
        text: 'Subscribe Now',
        onClick: () => onSubscribe(plan.id),
        variant: 'primary',
        subText: 'Continue with full access'
      };
    }

    // Default case (logged in, no trial, no subscription)
    return {
      text: 'Subscribe Now',
      onClick: () => onSubscribe(plan.id),
      variant: 'primary'
    };
  };

  const buttonConfig = getButtonConfig();

  return (
    <div className={`relative rounded-xl transition-all duration-300 transform hover:translate-y-[-4px] ${
      isCurrentPlan 
        ? `ring-1 ${
            isDarkMode 
              ? 'ring-amber-500/20 bg-gradient-to-r from-amber-500/10 to-yellow-500/10' 
              : 'ring-amber-200/50 bg-gradient-to-r from-amber-50 to-yellow-50'
          }` 
        : `border ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}`
    }`}>
      <div className={`h-full rounded-xl backdrop-blur-sm ${isDarkMode ? 'bg-gray-900/90' : 'bg-white/90'}`}>
        {(plan.badge || isCurrentPlan) && (
          <div className="absolute -top-3 left-1/2 -translate-x-1/2 transform transition-all duration-200 hover:scale-105">
            <div className={`px-4 py-1.5 text-xs font-medium rounded-full inline-flex items-center gap-2 shadow-lg ${
              isCurrentPlan 
                ? isDarkMode
                  ? 'bg-gradient-to-r from-amber-500/10 to-yellow-500/10 text-amber-400 ring-1 ring-amber-500/20'
                  : 'bg-gradient-to-r from-amber-50 to-yellow-50 text-amber-600 ring-1 ring-amber-200/50'
                : isDarkMode
                  ? 'bg-gradient-to-r from-amber-500/20 to-orange-500/20 text-amber-400 ring-1 ring-amber-500/30'
                  : 'bg-gradient-to-r from-amber-100 to-orange-100 text-amber-600'
            }`}>
              {isCurrentPlan ? (
                <>
                  <CheckCircleIcon className="w-4 h-4" />
                  Current Plan
                </>
              ) : (
                <>
                  <SparklesIcon className="w-4 h-4" />
                  {plan.badge}
                </>
              )}
            </div>
          </div>
        )}

        {subscription?.cancelAtPeriodEnd && isCurrentPlan && (
          <div className="absolute -top-2.5 right-4">
            <div className={`px-3 py-1 text-xs font-medium rounded-full inline-flex items-center gap-1.5 ${
              isDarkMode 
                ? 'bg-red-500/20 text-red-400' 
                : 'bg-red-50 text-red-600'
            }`}>
              <ClockIcon className="w-3.5 h-3.5" />
              Expires {formatDate(subscription.currentPeriodEnd)}
            </div>
          </div>
        )}

        <div className="p-6">
          <div className="text-center space-y-5">
            <div>
              <h2 className={`text-xl font-bold ${isDarkMode ? 'text-gray-100' : 'text-gray-900'}`}>
                {plan.name}
              </h2>
              <p className={`text-sm mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                {plan.description}
              </p>
            </div>

            <div className="flex items-baseline justify-center">
              <span className={`text-4xl font-extrabold bg-clip-text text-transparent ${
                isDarkMode 
                  ? 'bg-gradient-to-r from-gray-100 to-gray-300' 
                  : 'bg-gradient-to-r from-gray-700 to-gray-900'
              }`}>
                {plan.currency}{plan.price}
              </span>
              <span className={`ml-2 text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                /mo
              </span>
              {plan.originalPrice && (
                <span className={`ml-3 text-sm line-through ${isDarkMode ? 'text-gray-600' : 'text-gray-400'}`}>
                  {plan.currency}{plan.originalPrice}
                </span>
              )}
            </div>

            {plan.interval === 'yearly' && (
              <div className="text-center transform transition-all duration-200 hover:scale-105">
                <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  Billed {plan.currency}{plan.totalPrice} yearly
                </p>
                <p className={`text-xs mt-1 font-medium ${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'}`}>
                  Save {plan.currency}{Math.round((plan.originalPrice * 12) - plan.totalPrice)} yearly
                </p>
              </div>
            )}

            <div className="space-y-3">
              <button
                onClick={buttonConfig.onClick}
                disabled={!buttonConfig.onClick || isLoading}
                className={`w-full px-4 py-2.5 rounded-md text-sm font-medium transition-colors ${
                  buttonConfig.variant === 'primary'
                    ? `${isDarkMode 
                        ? 'bg-white text-gray-900 hover:bg-gray-200' 
                        : 'bg-gray-900 text-white hover:bg-gray-800'}`
                    : buttonConfig.variant === 'manage'
                      ? `${isDarkMode 
                          ? 'bg-gray-800 text-gray-200 hover:bg-gray-700 border border-gray-700' 
                          : 'bg-gray-100 text-gray-900 hover:bg-gray-200 border border-gray-200'}`
                      : `${isDarkMode
                          ? 'bg-gray-800 text-gray-200 hover:bg-gray-700 border border-gray-700'
                          : 'bg-gray-100 text-gray-900 hover:bg-gray-200 border border-gray-200'}`
                }`}
              >
                {isLoading ? (
                  <span className="flex items-center justify-center gap-2">
                    <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    <span>Loading...</span>
                  </span>
                ) : (
                  <span className="flex items-center justify-center">
                    {buttonConfig.text}
                  </span>
                )}
              </button>
              
              {buttonConfig.variant === 'primary' && buttonConfig.note && isPublic && (
                <p className={`text-xs text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {buttonConfig.note}
                </p>
              )}
              
              {buttonConfig.variant === 'secondary' && buttonConfig.subText && (
                <p className={`text-xs text-center ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {buttonConfig.subText}
                </p>
              )}
            </div>

            {isCurrentPlan && subscription?.currentPeriodEnd && (
              <div className={`mt-4 text-center text-sm ${
                subscription?.cancelAtPeriodEnd
                  ? isDarkMode ? 'text-red-400' : 'text-red-600'
                  : isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                {subscription?.cancelAtPeriodEnd
                  ? `Plan will be canceled on ${formatDate(subscription.currentPeriodEnd)}`
                  : `Plan will renew on ${formatDate(subscription.currentPeriodEnd)}`}
              </div>
            )}
          </div>
        </div>

        <div className={`p-6 border-t ${isDarkMode ? 'border-gray-800/50' : 'border-gray-100'}`}>
          <p className={`text-xs font-medium mb-4 tracking-wider ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
            FEATURES
          </p>
          <ul className="space-y-3.5">
            {plan.features.map((feature, index) => (
              <li key={index} className="flex items-start text-sm group">
                <CheckIcon className={`h-4 w-4 mt-0.5 mr-3 flex-shrink-0 transition-all duration-200 group-hover:scale-110 ${colorClasses.check}`} />
                <span className={`${isDarkMode ? 'text-gray-300' : 'text-gray-600'} transition-colors duration-200 group-hover:${isDarkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                  {feature}
                </span>
              </li>
            ))}
          </ul>

          {isActive && isSubscribed && subscription?.currentPeriodEnd && (
            <div className={`mt-6 pt-6 border-t ${isDarkMode ? 'border-gray-800/50' : 'border-gray-100'}`}>
              <div className="flex justify-between items-center">
                <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {subscription?.cancelAtPeriodEnd ? 'Cancels' : 'Renews'} {formatDate(subscription.currentPeriodEnd)}
                </p>
                <button
                  onClick={handleManageSubscription}
                  disabled={isLoading}
                  className={`text-xs font-medium px-3 py-1.5 rounded-lg transition-colors ${
                    isDarkMode
                      ? 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {isLoading ? 'Loading...' : 'Manage'}
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Trial Features Section - Only show in public pages */}
        {isPublic && plan.trialEnabled && (
          <div className={`p-6 border-t ${isDarkMode ? 'border-gray-800/50' : 'border-gray-100'}`}>
            <div className="flex items-center gap-2 mb-4">
              <SparklesIcon className={`h-4 w-4 ${
                isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
              }`} />
              <p className={`text-xs font-medium tracking-wider ${
                isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
              }`}>
                INCLUDES 7-DAY FREE TRIAL
              </p>
            </div>
            <ul className="space-y-3.5">
              {plan.trialFeatures.map((feature, index) => (
                <li key={index} className="flex items-start text-sm group">
                  <CheckIcon className={`h-4 w-4 mt-0.5 mr-3 flex-shrink-0 transition-all duration-200 group-hover:scale-110 ${
                    isDarkMode ? 'text-emerald-400' : 'text-emerald-500'
                  }`} />
                  <span className={`${
                    isDarkMode ? 'text-gray-300' : 'text-gray-600'
                  } transition-colors duration-200 group-hover:${
                    isDarkMode ? 'text-gray-200' : 'text-gray-900'
                  }`}>
                    {feature}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const SubscriptionCard = ({ subscription, isPublic = false, onPublicSubscribe }) => {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const [isYearly, setIsYearly] = useState(false);
  const yearlyDiscount = 0.25;
  const navigate = useNavigate();

  // Set initial billing cycle based on current subscription
  useEffect(() => {
    if (subscription?.interval === 'year') {
      setIsYearly(true);
    }
  }, [subscription]);

  // Current plan display component
  const CurrentPlanDisplay = () => {
    if (!subscription) return null;

    const getPlanName = (planId) => {
      const planKey = Object.keys(plans).find(key => plans[key].id === planId);
      if (planKey) {
        return plans[planKey].name;
      }

      switch(planId) {
        case 'pro':
          return 'Pro';
        case 'business':
          return 'Business';
        case 'standard':
          return 'Standard';
        default:
          return planId;
      }
    };

    const planName = getPlanName(subscription.plan);
    const isYearlyPlan = subscription.interval === 'year';
    const expiryDate = new Date(subscription.currentPeriodEnd);
    const formattedDate = expiryDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    return (
      <div className={`inline-flex items-center gap-3 px-5 py-3 rounded-2xl transition-all duration-300 transform hover:scale-[1.02] ${
        isDarkMode 
          ? 'bg-gray-800/80 backdrop-blur-sm border border-gray-700/50' 
          : 'bg-white/80 backdrop-blur-sm border border-gray-200/50 shadow-lg shadow-gray-100/20'
      }`}>
        <div className={`p-2 rounded-xl ${
          isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50'
        }`}>
          <svg xmlns="http://www.w3.org/2000/svg" className={`w-5 h-5 ${
            isDarkMode ? 'text-blue-400' : 'text-blue-600'
          }`} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
          </svg>
        </div>
        <div className="flex flex-col">
          <span className={`text-sm font-semibold ${
            isDarkMode ? 'text-gray-200' : 'text-gray-900'
          }`}>
            {planName} • {isYearlyPlan ? 'Yearly' : 'Monthly'}
          </span>
          <span className={`text-xs ${
            subscription.cancelAtPeriodEnd
              ? isDarkMode ? 'text-red-400' : 'text-red-600'
              : isDarkMode ? 'text-gray-400' : 'text-gray-500'
          }`}>
            {subscription.cancelAtPeriodEnd
              ? `Expires ${formattedDate}`
              : `Renews ${formattedDate}`}
          </span>
        </div>
      </div>
    );
  };

  const [plans, setPlans] = useState({
    STANDARD: {
      id: 'standard',
      name: 'Standard',
      currency: '$',
      monthlyPrice: 11.99,
      yearlyPrice: Math.round(11.99 * 12 * 0.75),
      description: 'Perfect for individual content creators',
      features: [
        '500 AI Credits*',
        '3 Brand Personas',
        '3 Target Audience Personas',
        '3 Custom AI Templates',
        'Unlimited Text Notes',
        '5 Voice Note Transcriptions',
        '10 AI Content Strategies',
        '10 Reddit Subreddit Analysis',
        'Unlimited Custom Post Templates',
        '7 Monthly LinkedIn Schedules',
        'Basic User Analytics',
        'LinkedIn Analytics (Coming Soon)'
      ],
      color: 'indigo',
      trialEnabled: true,
      trialDays: 7,
      trialFeatures: [
        'Full access to all Standard features',
        '500 AI Credits',
        'Create Brand & Audience Personas',
        'Generate AI Content Strategies',
        'Analyze Reddit Subreddits'
      ]
    },
    PRO: {
      id: 'pro',
      name: 'Pro',
      currency: '$',
      monthlyPrice: 29,
      yearlyPrice: Math.round(29 * 12 * 0.75),
      description: 'Advanced features for power users',
      features: [
        '1000 AI Credits*',
        '30 Brand Personas',
        '30 Target Audience Personas',
        '30 Custom AI Templates',
        'Unlimited Text Notes',
        '20 Voice Note Transcriptions',
        '50 AI Content Strategies',
        '50 Reddit Subreddit Analysis',
        'Unlimited Custom Post Templates',
        '30 Monthly LinkedIn Schedules',
        'Bulk Scheduling (Coming Soon)',
        'Basic User Analytics',
        'LinkedIn Analytics (Coming Soon)',
        'Priority Support'
      ],
      isPopular: true,
      color: 'blue',
      badge: 'Power User',
      trialEnabled: true,
      trialDays: 7,
      trialFeatures: [
        'Full access to all Pro features',
        '1000 AI Credits',
        'Create 30 Brand & Audience Personas',
        'Generate 50 AI Content Strategies',
        'Priority Support Access'
      ]
    }
  });

  useEffect(() => {
    const detectCountry = async () => {
      try {
        await GeoService.detectUserCountry();
        const country = GeoService.getUserCountry();
        
        if (country === 'IN') {
          setPlans(prevPlans => ({
            STANDARD: {
              ...prevPlans.STANDARD,
              currency: '₹',
              monthlyPrice: 999,
              yearlyPrice: Math.round(999 * 12 * 0.75)
            },
            PRO: {
              ...prevPlans.PRO,
              currency: '₹',
              monthlyPrice: 2499,
              yearlyPrice: Math.round(2499 * 12 * 0.75)
            }
          }));
        }
      } catch (error) {
        console.error('Error detecting country:', error);
      }
    };
    
    detectCountry();
  }, []);

  // Add billing cycle toggle component
  const BillingToggle = () => (
    <div className="flex flex-col items-center justify-center mb-8">
      <div className={`inline-flex rounded-xl p-1 ${
        isDarkMode ? 'bg-gray-800/80 backdrop-blur-sm' : 'bg-gray-100'
      }`}>
        <button
          onClick={() => setIsYearly(false)}
          className={`px-6 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${
            !isYearly
              ? isDarkMode
                ? 'bg-gray-700 text-white shadow-lg shadow-gray-900/20'
                : 'bg-white text-gray-900 shadow-md'
              : isDarkMode
                ? 'text-gray-400 hover:text-gray-300'
                : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          Monthly
        </button>
        <button
          onClick={() => setIsYearly(true)}
          className={`px-6 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${
            isYearly
              ? isDarkMode
                ? 'bg-gray-700 text-white shadow-lg shadow-gray-900/20'
                : 'bg-white text-gray-900 shadow-md'
              : isDarkMode
                ? 'text-gray-400 hover:text-gray-300'
                : 'text-gray-500 hover:text-gray-700'
          }`}
        >
          Yearly
        </button>
      </div>
      {isYearly && (
        <div className={`mt-3 text-sm font-medium ${
          isDarkMode ? 'text-emerald-400' : 'text-emerald-600'
        }`}>
          Save 25% with yearly billing 🎉
        </div>
      )}
    </div>
  );

  const handleSubscribe = async (planId) => {
    if (isPublic) {
      onPublicSubscribe?.();
      return;
    }

    if (planId === 'free') return;
    
    let loadingToast;
    try {
      console.log('Starting subscription process for plan:', planId);
      
      loadingToast = toast.loading('Setting up your subscription...', {
        style: {
          background: isDarkMode ? '#1E1E1E' : '#FFFFFF',
          color: isDarkMode ? '#FFFFFF' : '#1E1E1E',
          border: `1px solid ${isDarkMode ? '#333333' : '#E5E7EB'}`,
        },
      });
      
      await GeoService.detectUserCountry();
      const userCountry = GeoService.getUserCountry();
      console.log('User country detected:', userCountry);
      
      const result = await SubscriptionService.createCheckoutSession(
        planId,
        userCountry,
        isYearly ? 'year' : 'month'
      );
      
      toast.dismiss(loadingToast);
      
      setTimeout(() => {
        if (result && !window.location.href.includes('checkout.stripe.com')) {
          console.error('No redirection occurred after successful checkout session creation');
          toast.error('Unable to redirect to payment page. Please try again.', {
            duration: 5000,
            position: 'top-center',
            style: {
              background: isDarkMode ? '#1E1E1E' : '#FFFFFF',
              color: isDarkMode ? '#FFFFFF' : '#1E1E1E',
              border: `1px solid ${isDarkMode ? '#333333' : '#E5E7EB'}`,
              padding: '16px',
              borderRadius: '8px',
            },
          });
        }
      }, 5000);
    } catch (error) {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
      
      console.error('Subscription error:', error);
      
      let errorMessage = 'Failed to start subscription process';
      if (error instanceof Error) {
        console.error('Error details:', {
          message: error.message,
          stack: error.stack,
          name: error.name,
          response: error.response?.data
        });
        
        if (error.message.includes('checkout session')) {
          errorMessage = 'Unable to create checkout session. Please try again later.';
        } else if (error.message.includes('Invalid response')) {
          errorMessage = 'Received invalid response from server. Please try again.';
        } else if (error.message.includes('No response')) {
          errorMessage = 'No response from server. Please check your connection and try again.';
        } else {
          errorMessage = error.message;
        }
      }
      
      toast.error(errorMessage, {
        duration: 5000,
        position: 'top-center',
        style: {
          background: isDarkMode ? '#1E1E1E' : '#FFFFFF',
          color: isDarkMode ? '#FFFFFF' : '#1E1E1E',
          border: `1px solid ${isDarkMode ? '#333333' : '#E5E7EB'}`,
          padding: '16px',
          borderRadius: '8px',
        },
      });
    }
  };

  const handleCancel = async () => {
    try {
      const response = await SubscriptionService.cancelSubscription();
      toast.success(response.message);
    } catch (error) {
      console.error('Error canceling subscription:', error);
      toast.error('Failed to cancel subscription. Please try again.');
    }
  };

  const handleReactivate = async () => {
    try {
      const response = await SubscriptionService.reactivateSubscription();
      toast.success(response.message);
    } catch (error) {
      console.error('Error reactivating subscription:', error);
      toast.error('Failed to reactivate subscription. Please try again.');
    }
  };

  const handleStartTrial = async (planId) => {
    if (isPublic) {
      // Store trial intent and redirect to registration
      localStorage.setItem('trial_intent', JSON.stringify({
        planId,
        timestamp: new Date().toISOString(),
        isYearly: isYearly
      }));
      navigate('/register?trial=true');
      return;
    }

    // For logged-in users in subscription page
    let loadingToast;
    try {
      loadingToast = toast.loading('Starting your free trial...', {
        style: {
          background: isDarkMode ? '#1E1E1E' : '#FFFFFF',
          color: isDarkMode ? '#FFFFFF' : '#1E1E1E',
          border: `1px solid ${isDarkMode ? '#333333' : '#E5E7EB'}`,
        },
      });

      const result = await SubscriptionService.startTrial(planId, isYearly ? 'year' : 'month');
      
      if (result.success) {
        toast.dismiss(loadingToast);
        toast.success('Your free trial has started! Enjoy full access for 7 days.', {
          duration: 5000,
          position: 'top-center',
          style: {
            background: isDarkMode ? '#1E1E1E' : '#FFFFFF',
            color: isDarkMode ? '#FFFFFF' : '#1E1E1E',
            border: `1px solid ${isDarkMode ? '#333333' : '#E5E7EB'}`,
            padding: '16px',
            borderRadius: '8px',
          },
        });
        
        // Refresh the page to show updated trial status
        window.location.reload();
      }
    } catch (error) {
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
      
      toast.error(error.message || 'Failed to start trial. Please try again.', {
        duration: 5000,
        position: 'top-center',
        style: {
          background: isDarkMode ? '#1E1E1E' : '#FFFFFF',
          color: isDarkMode ? '#FFFFFF' : '#1E1E1E',
          border: `1px solid ${isDarkMode ? '#333333' : '#E5E7EB'}`,
          padding: '16px',
          borderRadius: '8px',
        },
      });
    }
  };

  // Add trial status display
  const getTrialStatus = () => {
    if (!user) return null;
    
    if (user.isInTrial) {
      const trialEnd = new Date(user.trialEndDate);
      const now = new Date();
      const daysLeft = Math.ceil((trialEnd - now) / (1000 * 60 * 60 * 24));
      
      return {
        isActive: true,
        daysLeft,
        endDate: trialEnd
      };
    }
    
    return {
      isActive: false,
      hasExpired: user.hasTrialExpired
    };
  };

  return (
    <div className="space-y-8">
      <BillingToggle />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-[800px] mx-auto">
        {Object.entries(plans).map(([key, planData]) => {
          const planWithInterval = {
            ...planData,
            price: isYearly ? Math.round(planData.yearlyPrice / 12) : planData.monthlyPrice,
            interval: isYearly ? 'year' : 'month',
            totalPrice: planData.yearlyPrice,
            yearlyDiscount: isYearly ? '25% off' : null,
            originalPrice: isYearly ? Math.round(planData.monthlyPrice * 1.33) : Math.round(planData.monthlyPrice * 1.33)
          };

          const isCurrentPlan = !isPublic && subscription?.plan === planData.id && 
            subscription?.interval === planWithInterval.interval;

          return (
            <div key={key} className="w-full max-w-sm">
              <PlanCard
                plan={planWithInterval}
                isActive={isCurrentPlan}
                onSubscribe={handleSubscribe}
                onCancel={handleCancel}
                onReactivate={handleReactivate}
                subscription={isCurrentPlan ? subscription : null}
                isDarkMode={isDarkMode}
                isPublic={isPublic}
                onPublicSubscribe={onPublicSubscribe}
                onStartTrial={handleStartTrial}
                showTrialInPublic={true}
              />
            </div>
          );
        })}
      </div>

      {/* Information Cards */}
      <div className="max-w-[800px] mx-auto space-y-4">
        {/* Credits Usage Card */}
        <div className={`p-6 rounded-2xl border backdrop-blur-sm transition-all duration-300 transform hover:translate-y-[-2px] ${
          isDarkMode 
            ? 'bg-gray-800/30 border-gray-700/50 shadow-xl shadow-gray-900/20' 
            : 'bg-white/50 border-gray-200/50 shadow-lg shadow-gray-100/50'
        }`}>
          <div className="flex items-start gap-4">
            <div className={`p-3 rounded-xl ${
              isDarkMode ? 'bg-indigo-500/10' : 'bg-indigo-50'
            }`}>
              <svg xmlns="http://www.w3.org/2000/svg" className={`w-6 h-6 ${
                isDarkMode ? 'text-indigo-400' : 'text-indigo-600'
              }`} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
              </svg>
            </div>
            <div className="flex-1">
              <h3 className={`text-base font-semibold mb-2 ${
                isDarkMode ? 'text-gray-200' : 'text-gray-900'
              }`}>AI Credits Usage</h3>
              <p className={`text-sm leading-relaxed ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                Premium AI models (Claude 3.5 Sonnet, GPT-4o, Gemini 2.0 Flash) consume 2 credits per use, while standard AI models (Claude 3.5 Haiku, DeepSeek Chat, Grok) consume 1 credit per use. Credits reset monthly. Unused credits don't roll over to the next month.
              </p>
            </div>
          </div>
        </div>

        {/* Money-Back Guarantee Card */}
        <div className={`p-6 rounded-2xl border backdrop-blur-sm transition-all duration-300 transform hover:translate-y-[-2px] ${
          isDarkMode 
            ? 'bg-blue-950/30 border-blue-800/30 shadow-xl shadow-blue-900/10' 
            : 'bg-blue-50/50 border-blue-100/50 shadow-lg shadow-blue-100/50'
        }`}>
          <div className="flex items-start gap-4">
            <div className={`p-3 rounded-xl ${
              isDarkMode ? 'bg-blue-500/10' : 'bg-blue-100'
            }`}>
              <SparklesIcon className={`w-6 h-6 ${
                isDarkMode ? 'text-blue-400' : 'text-blue-600'
              }`} />
            </div>
            <div className="flex-1">
              <h3 className={`text-base font-semibold mb-2 ${
                isDarkMode ? 'text-blue-200' : 'text-blue-900'
              }`}>7-Day Money-Back Guarantee</h3>
              <p className={`text-sm leading-relaxed ${
                isDarkMode ? 'text-blue-300' : 'text-blue-700'
              }`}>
                Try risk-free and get a full refund if you're not completely satisfied with your subscription. No questions asked! 🎉
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard; 