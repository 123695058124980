import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { API_URL, default as api } from '../api';
import { clearAppStorage } from '../utils/storageUtils';
import { connectSocket, disconnectSocket } from '../socket';
import { getState } from '../services/linkedinAuthService';
import supabaseAuth, { signUpWithEmail, refreshSession } from '../services/supabaseAuth';

/**
 * Constants and Configuration
 * --------------------------
 * LINKEDIN_STATE_KEY: Used for LinkedIn OAuth state verification
 * API_URLS: Fallback API endpoints for high availability
 */
const LINKEDIN_STATE_KEY = 'linkedin_auth_state';

const API_URLS = [
  'http://localhost:3001',     // Local development
  'https://api.ammmplify.com',  // Primary API
  'https://ghost-app-backend.vercel.app'  // Fallback API
];

// Log configuration for debugging purposes
console.log('Auth Context Configuration:', {
  hostname: window.location.hostname,
  apiUrl: API_URL
});

/**
 * Context Creation
 * ---------------
 * AuthContext: Main context for authentication state
 * useAuth: Custom hook for easy context consumption
 */
export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

/**
 * Token Refresh Function
 * ---------------------
 * Handles access token refresh using refresh token
 * Implements retry logic and error handling
 * Updates localStorage and axios headers on success
 */
export const refreshAccessToken = async (refreshToken) => {
  if (!refreshToken) {
    console.error('No refresh token provided');
    return null;
  }

  console.log('Starting token refresh:', {
    hasRefreshToken: !!refreshToken,
    tokenLength: refreshToken?.length,
    apiUrl: API_URL
  });

  try {
    const response = await axios.post(`${API_URL}/api/auth/refresh-token`, 
      { refreshToken },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    console.log('Refresh token response:', {
      status: response.status,
      hasAccessToken: !!response.data?.accessToken,
      hasRefreshToken: !!response.data?.refreshToken,
      hasUser: !!response.data?.user
    });

    if (!response.data || !response.data.accessToken) {
      throw new Error('Invalid response format');
    }

    const { accessToken, refreshToken: newRefreshToken } = response.data;

    // Update tokens in localStorage
    localStorage.setItem('accessToken', accessToken);
    if (newRefreshToken) {
      localStorage.setItem('refreshToken', newRefreshToken);
    }

    // Update axios headers
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    console.log('Token refresh successful:', {
      hasNewAccessToken: !!accessToken,
      hasNewRefreshToken: !!newRefreshToken,
      authHeader: !!axios.defaults.headers.common['Authorization']
    });

    return accessToken;
  } catch (error) {
    console.error('Token refresh failed:', {
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      message: error.message
    });
    
    // Clear tokens on authentication errors
    if (error.response?.status === 401) {
      console.log('Clearing tokens due to authentication error');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      delete axios.defaults.headers.common['Authorization'];
    }
    
    return null;
  }
};

/**
 * AuthProvider Component
 * ---------------------
 * Main authentication context provider
 * Manages user state, loading state, and authentication operations
 */
export const AuthProvider = ({ children }) => {
  // State management
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  /**
   * Initial User Load Effect
   * -----------------------
   * Runs on component mount
   * Checks for existing auth tokens and loads user data
   * Handles token refresh if needed
   */
  useEffect(() => {
    const loadUser = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const supabaseSession = localStorage.getItem('supabase_session');
        const linkedinExpiresAt = localStorage.getItem('linkedin_expires_at');
        
        console.log('Loading user with tokens:', {
          hasAccessToken: !!accessToken,
          hasRefreshToken: !!refreshToken,
          hasSupabaseSession: !!supabaseSession,
          linkedinExpiresAt: linkedinExpiresAt ? new Date(linkedinExpiresAt).toISOString() : null
        });

        if (accessToken && refreshToken) {
          try {
            // First try to restore/refresh Supabase session
            if (supabaseSession) {
              try {
                console.log('Attempting to restore Supabase session...');
                // Try to restore the existing session
                const { data: { session }, error: setSessionError } = 
                  await supabaseAuth.auth.setSession(JSON.parse(supabaseSession));
                
                if (setSessionError || !session) {
                  console.log('Session restoration failed, attempting refresh...');
                  // Try to refresh the session
                  const refreshedSession = await refreshSession();
                  if (!refreshedSession) {
                    console.log('Session refresh failed, redirecting to login...');
                    await logout();
                    window.location.href = '/login';
                    return;
                  }
                  console.log('Session refreshed successfully:', {
                    hasAccessToken: !!refreshedSession.access_token,
                    expiresAt: refreshedSession.expires_at
                  });
                } else {
                  console.log('Session restored successfully:', {
                    hasAccessToken: !!session.access_token,
                    expiresAt: session.expires_at
                  });
                }
              } catch (sessionError) {
                console.error('Session error:', sessionError);
                // Try one last refresh before giving up
                try {
                  const refreshedSession = await refreshSession();
                  if (!refreshedSession) {
                    throw new Error('Final refresh attempt failed');
                  }
                } catch (finalError) {
                  console.error('Final refresh attempt failed:', finalError);
                  await logout();
                  window.location.href = '/login';
                  return;
                }
              }
            }

            // Check if LinkedIn token needs refresh (5 minutes before expiry)
            const shouldRefreshLinkedIn = linkedinExpiresAt && 
              new Date(linkedinExpiresAt).getTime() - Date.now() < 300000;

            if (shouldRefreshLinkedIn) {
              console.log('LinkedIn token needs refresh, refreshing tokens...');
              const newAccessToken = await refreshTokenAndUpdateUser();
              if (!newAccessToken) {
                throw new Error('Token refresh failed');
              }
            }

            // Set authorization header
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            const res = await axios.get(`${API_URL}/api/auth/user`);
            
            console.log('User loaded successfully:', {
              userId: res.data.id,
              email: res.data.email,
              hasLinkedIn: !!res.data.linkedinId
            });

            // Fetch subscription status
            try {
              const subscriptionResponse = await axios.get(`${API_URL}/api/subscription/status`, {
                headers: {
                  'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
              });
              const userData = {
                ...res.data,
                isSubscribed: subscriptionResponse.data.isSubscribed,
                subscriptionDetails: subscriptionResponse.data.subscription
              };
              
              console.log('Subscription status loaded:', {
                isSubscribed: userData.isSubscribed,
                subscriptionPlan: userData.subscriptionDetails?.plan
              });
              
              setUser(userData);
            } catch (subError) {
              console.error('Error fetching subscription status:', subError);
              // Still set the user even if subscription fetch fails
              setUser(res.data);
            }
          } catch (err) {
            console.error('Error in loadUser:', err);
            
            // Try to refresh tokens
            try {
              console.log('Attempting to refresh tokens...');
              const newAccessToken = await refreshTokenAndUpdateUser();
              if (!newAccessToken) {
                throw new Error('Token refresh failed');
              }
            } catch (refreshError) {
              console.error('Token refresh failed:', refreshError);
              await logout();
              window.location.href = '/login';
            }
          }
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error in loadUser:', error);
        await logout();
        window.location.href = '/login';
      } finally {
        setLoading(false);
      }
    };

    loadUser();

    // Set up Supabase auth state change listener with better event handling
    const { data: { subscription } } = supabaseAuth.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state changed:', event, session);
      
      switch (event) {
        case 'SIGNED_OUT':
          await logout();
          window.location.href = '/login';
          break;
        
        case 'TOKEN_REFRESHED':
          if (session) {
            console.log('Token refreshed successfully:', {
              hasAccessToken: !!session.access_token,
              expiresAt: session.expires_at
            });
            localStorage.setItem('supabase_session', JSON.stringify(session));
          } else {
            console.error('Token refresh event without session');
            await logout();
            window.location.href = '/login';
          }
          break;
        
        case 'USER_DELETED':
          await logout();
          window.location.href = '/login';
          break;
      }
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  /**
   * Authentication Methods
   * ---------------------
   * login: Handles user login with email/password
   * logout: Handles user logout and cleanup
   * register: Handles new user registration
   * refreshUser: Updates user data from server
   * refreshTokenAndUpdateUser: Refreshes auth tokens and user data
   * loginWithLinkedIn: Handles LinkedIn OAuth login
   */

  const login = async (email, password) => {
    try {
      if (!API_URL) {
        throw new Error('API URL is not configured');
      }

      console.log('Login attempt:', {
        email,
        apiUrl: API_URL,
        endpoint: `${API_URL}/api/auth/login`
      });

      const res = await axios.post(
        `${API_URL}/api/auth/login`, 
        { email, password },
        { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Login response:', res.data);

      if (res.data.accessToken && res.data.refreshToken && res.data.user) {
        localStorage.setItem('accessToken', res.data.accessToken);
        localStorage.setItem('refreshToken', res.data.refreshToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.accessToken}`;
        
        // Fetch full user profile
        const userProfileRes = await axios.get(`${API_URL}/api/auth/user`, {
          headers: { Authorization: `Bearer ${res.data.accessToken}` }
        });
        
        setUser(userProfileRes.data);
        connectSocket(res.data.accessToken);
        return true;
      }

      console.log('Login failed: Invalid response format');
      return false;
    } catch (err) {
      console.error('Login error:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        apiUrl: API_URL
      });
      throw err;
    }
  };

  const logout = async () => {
    try {
      console.log('Starting logout process...');
      
      // First, clear all local storage to prevent any pending requests
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('supabase_session');
      
      // Clear auth headers immediately
      delete axios.defaults.headers.common['Authorization'];
      
      // Clear user state before Supabase signout to prevent additional requests
      setUser(null);
      
      // Clear all app data
      clearAppStorage();
      
      // Disconnect socket
      disconnectSocket();
      
      // Finally, sign out from Supabase
      // Wrap in try-catch to proceed with redirect even if this fails
      try {
        await supabaseAuth.auth.signOut();
      } catch (supabaseError) {
        console.error('Supabase signout error:', supabaseError);
        // Continue with logout process even if Supabase signout fails
      }

      // Redirect to login page
      window.location.href = '/login';
      
    } catch (error) {
      console.error('Error during logout:', error);
      // Force redirect to login even if there's an error
      window.location.href = '/login';
    }
  };

  // Update the register function
  const register = async (firstName, lastName, email, password) => {
    try {
      console.log('Starting registration process for:', email);
      
      // First, register with Supabase
      const supabaseResponse = await signUpWithEmail(email, password, firstName, lastName);
      console.log('Supabase registration response:', supabaseResponse);
      
      if (!supabaseResponse || !supabaseResponse.supabaseUser) {
        throw new Error('Failed to create Supabase user');
      }

      // Then create user in MongoDB
      const mongoResponse = await axios.post(`${API_URL}/api/auth/register`, { 
        firstName, 
        lastName, 
        email, 
        password,
        supabaseUid: supabaseResponse.supabaseUser.id
      });
      
      console.log('MongoDB registration response:', mongoResponse.data);

      // Return success regardless of MongoDB response structure
      // since we know the user was created in both systems
      return {
        success: true,
        message: supabaseResponse.message || 'Registration successful! Please check your email to verify your account.'
      };
      
    } catch (err) {
      console.error('Registration error details:', {
        error: err,
        response: err.response?.data,
        message: err.message
      });
      
      // Handle specific error cases
      if (err.response?.data?.errors?.[0]?.msg === 'User already exists') {
        throw new Error('An account with this email already exists.');
      }
      
      // If we got a Supabase success but MongoDB error, still proceed
      if (err.message === 'Failed to create user record in database' && 
          err.supabaseSuccess) {
        return {
          success: true,
          message: 'Registration successful! Please check your email to verify your account.'
        };
      }
      
      throw new Error(err.response?.data?.message || err.message || 'Registration failed. Please try again.');
    }
  };

  const refreshUser = async () => {
    try {
      console.log('Refreshing user information...');
      const response = await api.get('/api/user/profile');
      console.log('Refreshed user data:', response.data);
      setUser(response.data);
      console.log('User state updated after refresh');
    } catch (error) {
      console.error('Error refreshing user information:', error);
      if (error.response) {
        console.error('Server responded with:', error.response.data);
      }
    }
  };

  const refreshTokenAndUpdateUser = async () => {
    try {
      console.log('Starting token refresh process...');
      const refreshToken = localStorage.getItem('refreshToken');
      const linkedinRefreshToken = localStorage.getItem('linkedin_refresh_token');
      const linkedinExpiresAt = localStorage.getItem('linkedin_expires_at');

      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      // Check if LinkedIn token needs refresh (5 minutes before expiry)
      const shouldRefreshLinkedIn = linkedinExpiresAt && 
        new Date(linkedinExpiresAt).getTime() - Date.now() < 300000;

      console.log('Token refresh status:', {
        hasRefreshToken: !!refreshToken,
        hasLinkedInRefresh: !!linkedinRefreshToken,
        linkedinExpiresAt: linkedinExpiresAt ? new Date(linkedinExpiresAt).toISOString() : null,
        shouldRefreshLinkedIn
      });

      // Refresh both JWT and LinkedIn tokens
      const response = await api.post('/api/auth/refresh-token', { 
        refreshToken,
        linkedinRefreshToken: shouldRefreshLinkedIn ? linkedinRefreshToken : undefined
      });

      const { 
        accessToken, 
        user: updatedUser,
        linkedinTokens 
      } = response.data;

      // Update JWT tokens
      localStorage.setItem('accessToken', accessToken);
      if (response.data.refreshToken) {
        localStorage.setItem('refreshToken', response.data.refreshToken);
      }

      // Update LinkedIn tokens if present
      if (linkedinTokens) {
        localStorage.setItem('linkedin_access_token', linkedinTokens.access_token);
        localStorage.setItem('linkedin_refresh_token', linkedinTokens.refresh_token);
        localStorage.setItem('linkedin_expires_at', linkedinTokens.expires_at);
      }

      // Update axios headers
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      setUser(updatedUser);
      return accessToken;

    } catch (error) {
      console.error('Error refreshing tokens:', error);
      if (error.response?.status === 401) {
        // Clear all tokens and redirect to login
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('linkedin_access_token');
        localStorage.removeItem('linkedin_refresh_token');
        localStorage.removeItem('linkedin_expires_at');
        setUser(null);
        window.location.href = '/login';
      }
      return null;
    }
  };

  const loginWithLinkedIn = async (code) => {
    let refreshInterval;
    try {
      console.log('🔵 Starting LinkedIn auth process with code:', code.substring(0, 10) + '...');
      const stateData = getState();
      
      if (!stateData) {
        console.error('❌ No state data found');
        throw new Error('Authentication state not found. Please try again.');
      }
      
      const response = await axios.post(`${API_URL}/api/auth/linkedin/callback`, { 
        code,
        codeVerifier: stateData.codeVerifier,
        isSignup: stateData.source === 'register'
      });
      
      if (!response.data.accessToken || !response.data.refreshToken || !response.data.user) {
        throw new Error('Invalid response from server');
      }

      // Store both JWT and LinkedIn tokens
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      
      // Store LinkedIn specific tokens
      if (response.data.linkedinTokens) {
        localStorage.setItem('linkedin_access_token', response.data.linkedinTokens.access_token);
        localStorage.setItem('linkedin_refresh_token', response.data.linkedinTokens.refresh_token);
        localStorage.setItem('linkedin_expires_at', response.data.linkedinTokens.expires_at);
      }

      // Set up axios headers
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.accessToken}`;

      setUser(response.data.user);
      
      // Set up token refresh interval
      refreshInterval = setInterval(async () => {
        const expiresAt = localStorage.getItem('linkedin_expires_at');
        if (expiresAt && new Date(expiresAt).getTime() - Date.now() < 300000) { // 5 minutes before expiry
          await refreshTokenAndUpdateUser();
        }
      }, 60000); // Check every minute

      // Return the response data for the success page
      return response.data;

    } catch (error) {
      console.error('❌ LinkedIn auth error:', error);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('linkedin_access_token');
      localStorage.removeItem('linkedin_refresh_token');
      localStorage.removeItem('linkedin_expires_at');
      setUser(null);
      
      // Clean up interval if it was set
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
      
      throw error;
    }
  };

  // Add new method to refresh subscription status
  const refreshSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/subscription/status`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      // Only update subscription-specific fields
      if (user) {
        const subscriptionData = {
          isSubscribed: response.data.isSubscribed,
          subscriptionDetails: response.data.subscription
        };

        // Update only if values have changed
        if (user.isSubscribed !== subscriptionData.isSubscribed || 
            JSON.stringify(user.subscriptionDetails) !== JSON.stringify(subscriptionData.subscriptionDetails)) {
          setUser(prev => ({
            ...prev,
            ...subscriptionData
          }));
        }
      }
      return response.data;
    } catch (error) {
      console.error('Error refreshing subscription status:', error);
      if (error.response?.status === 401) {
        try {
          const newAccessToken = await refreshTokenAndUpdateUser();
          if (newAccessToken) {
            const retryResponse = await axios.get(`${API_URL}/api/subscription/status`, {
              headers: {
                'Authorization': `Bearer ${newAccessToken}`
              }
            });
            
            // Only update subscription-specific fields on retry
            if (user) {
              const subscriptionData = {
                isSubscribed: retryResponse.data.isSubscribed,
                subscriptionDetails: retryResponse.data.subscription
              };

              // Update only if values have changed
              if (user.isSubscribed !== subscriptionData.isSubscribed || 
                  JSON.stringify(user.subscriptionDetails) !== JSON.stringify(subscriptionData.subscriptionDetails)) {
                setUser(prev => ({
                  ...prev,
                  ...subscriptionData
                }));
              }
            }
            return retryResponse.data;
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
        }
      }
      return null;
    }
  };

  // Show nothing while initial auth check is in progress
  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      setUser, 
      login, 
      logout, 
      register, 
      loading, 
      refreshUser, 
      initialLoadComplete, 
      refreshTokenAndUpdateUser,
      loginWithLinkedIn,
      refreshSubscriptionStatus
    }}>
      {children}
    </AuthContext.Provider>
  );
};
