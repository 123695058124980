import React, { useState } from 'react';
import { format, isAfter, isBefore, startOfDay, isSameDay } from 'date-fns';
import { 
  ChevronLeftIcon, 
  ChevronRightIcon,
  XMarkIcon,
  CheckIcon
} from '@heroicons/react/24/outline';

const TimeInput = ({ value, onChange, isDark }) => (
  <div className="flex items-center space-x-1.5">
    <input
      type="text"
      value={format(value, 'hh')}
      onChange={(e) => {
        const val = e.target.value.replace(/\D/g, '');
        if (val === '' || (parseInt(val) >= 1 && parseInt(val) <= 12)) {
          onChange('hours', val);
        }
      }}
      className={`w-6 text-center bg-transparent focus:outline-none text-xs font-medium ${
        isDark ? 'text-gray-300' : 'text-gray-600'
      }`}
    />
    <span className={isDark ? 'text-gray-500' : 'text-gray-400'}>:</span>
    <input
      type="text"
      value={format(value, 'mm')}
      onChange={(e) => {
        const val = e.target.value.replace(/\D/g, '');
        if (val === '' || (parseInt(val) >= 0 && parseInt(val) <= 59)) {
          onChange('minutes', val);
        }
      }}
      className={`w-6 text-center bg-transparent focus:outline-none text-xs font-medium ${
        isDark ? 'text-gray-300' : 'text-gray-600'
      }`}
    />
    <button
      onClick={() => onChange('period')}
      className={`
        px-2 py-0.5 text-[11px] font-medium rounded
        ${value.getHours() >= 12 
          ? isDark ? 'bg-zinc-800/80 text-gray-300' : 'bg-gray-100 text-gray-700'
          : isDark ? 'bg-zinc-800/50 text-gray-400' : 'bg-gray-50 text-gray-600'
        }
      `}
    >
      {value.getHours() >= 12 ? 'PM' : 'AM'}
    </button>
  </div>
);

const DateTimePickerDesign = ({
  selectedStartDateTime,
  selectedEndDateTime,
  onDateTimeSelect,
  onClose,
}) => {
  const isDark = document.documentElement.classList.contains('dark');
  const [activeDate, setActiveDate] = useState('start');
  const [currentStartDate, setCurrentStartDate] = useState(() => 
    selectedStartDateTime ? new Date(selectedStartDateTime) : new Date()
  );
  const [currentEndDate, setCurrentEndDate] = useState(() => 
    selectedEndDateTime ? new Date(selectedEndDateTime) : new Date()
  );
  const [currentMonth, setCurrentMonth] = useState(() => currentStartDate.getMonth());
  const [currentYear, setCurrentYear] = useState(() => currentStartDate.getFullYear());

  const firstDay = new Date(currentYear, currentMonth, 1).getDay();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const today = startOfDay(new Date());

  const weekDays = [
    { key: 'sun', label: 'S' },
    { key: 'mon', label: 'M' },
    { key: 'tue', label: 'T' },
    { key: 'wed', label: 'W' },
    { key: 'thu', label: 'T' },
    { key: 'fri', label: 'F' },
    { key: 'sat', label: 'S' }
  ];

  const generateDays = () => {
    const days = [];
    for (let i = 0; i < firstDay; i++) days.push(null);
    for (let day = 1; day <= daysInMonth; day++) days.push(day);
    return days;
  };

  const handleDateSelect = (day) => {
    if (!day) return;
    const newDate = new Date(currentYear, currentMonth, day);
    const currentDate = activeDate === 'start' ? currentStartDate : currentEndDate;
    
    // Preserve the current time
    newDate.setHours(currentDate.getHours());
    newDate.setMinutes(currentDate.getMinutes());

    if (activeDate === 'start') {
      // If new start date is after current end date, set end date to same day but later time
      if (isAfter(newDate, currentEndDate)) {
        const newEndDate = new Date(newDate);
        newEndDate.setHours(newDate.getHours() + 1); // Set end time 1 hour later
        setCurrentEndDate(newEndDate);
        onDateTimeSelect('end', newEndDate);
      }
      setCurrentStartDate(newDate);
      onDateTimeSelect('start', newDate);
      setActiveDate('end'); // Switch to end date selection
    } else {
      // If new end date is before current start date, don't allow the change
      if (isBefore(newDate, currentStartDate)) {
        return; // Prevent invalid selection
      }
      setCurrentEndDate(newDate);
      onDateTimeSelect('end', newDate);
    }
  };

  const handleTimeChange = (type, value, dateType) => {
    const currentDate = dateType === 'start' ? currentStartDate : currentEndDate;
    const otherDate = dateType === 'start' ? currentEndDate : currentStartDate;
    const setCurrentDate = dateType === 'start' ? setCurrentStartDate : setCurrentEndDate;
    const newDate = new Date(currentDate);
    
    if (type === 'hours') {
      const isPM = currentDate.getHours() >= 12;
      const hours = parseInt(value) || 0;
      newDate.setHours(isPM ? (hours % 12) + 12 : hours % 12);
    } else if (type === 'minutes') {
      newDate.setMinutes(parseInt(value) || 0);
    } else if (type === 'period') {
      const newHours = (currentDate.getHours() + 12) % 24;
      newDate.setHours(newHours);
    }

    // Validate the new time
    if (dateType === 'start' && isAfter(newDate, otherDate)) {
      // If setting start time after end time, adjust end time
      const newEndDate = new Date(newDate);
      newEndDate.setHours(newDate.getHours() + 1);
      setCurrentEndDate(newEndDate);
      onDateTimeSelect('end', newEndDate);
    } else if (dateType === 'end' && isBefore(newDate, otherDate)) {
      // If setting end time before start time, don't allow the change
      return;
    }
    
    setCurrentDate(newDate);
    onDateTimeSelect(dateType, newDate);
  };

  const isInRange = (day) => {
    if (!day) return false;
    const date = new Date(currentYear, currentMonth, day);
    return (
      isAfter(date, startOfDay(currentStartDate)) && 
      isBefore(date, startOfDay(currentEndDate))
    );
  };

  return (
    <div className={`w-[280px] ${isDark ? 'bg-zinc-900 border-zinc-800/80' : 'bg-white border-gray-200/80'} rounded-lg border shadow-sm overflow-hidden`}>
      {/* Time Selection */}
      <div className={`flex items-center justify-between p-2.5 ${isDark ? 'border-zinc-800/80' : 'border-gray-100'} border-b`}>
        <div className="space-y-2 w-full">
          <div className="flex items-center justify-between">
            <button
              onClick={() => setActiveDate('start')}
              className={`text-[11px] px-2 py-1 rounded font-medium ${
                activeDate === 'start'
                  ? isDark ? 'bg-zinc-800/80 text-blue-300' : 'bg-blue-50 text-blue-500'
                  : isDark ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-600'
              }`}
            >
              Start
            </button>
            <TimeInput
              value={currentStartDate}
              onChange={(type, value) => handleTimeChange(type, value, 'start')}
              isDark={isDark}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              onClick={() => setActiveDate('end')}
              className={`text-[11px] px-2 py-1 rounded font-medium ${
                activeDate === 'end'
                  ? isDark ? 'bg-zinc-800/80 text-blue-300' : 'bg-blue-50 text-blue-500'
                  : isDark ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-600'
              }`}
            >
              End
            </button>
            <TimeInput
              value={currentEndDate}
              onChange={(type, value) => handleTimeChange(type, value, 'end')}
              isDark={isDark}
            />
          </div>
        </div>
      </div>

      {/* Calendar Header */}
      <div className={`flex items-center justify-between p-2.5 ${isDark ? 'border-zinc-800/80' : 'border-gray-100'} border-b`}>
        <button
          onClick={() => setCurrentMonth(m => m === 0 ? 11 : m - 1)}
          className={`p-0.5 rounded ${
            isDark ? 'hover:bg-zinc-800/50' : 'hover:bg-gray-50'
          }`}
        >
          <ChevronLeftIcon className={`w-4 h-4 ${isDark ? 'text-gray-400' : 'text-gray-500'}`} />
        </button>
        <span className={`text-xs font-medium ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
          {format(new Date(currentYear, currentMonth), 'MMMM yyyy')}
        </span>
        <button
          onClick={() => setCurrentMonth(m => m === 11 ? 0 : m + 1)}
          className={`p-0.5 rounded ${
            isDark ? 'hover:bg-zinc-800/50' : 'hover:bg-gray-50'
          }`}
        >
          <ChevronRightIcon className={`w-4 h-4 ${isDark ? 'text-gray-400' : 'text-gray-500'}`} />
        </button>
      </div>

      {/* Calendar Grid */}
      <div className="p-2.5">
        <div className="grid grid-cols-7 gap-0.5">
          {weekDays.map(day => (
            <div key={day.key} className={`text-center text-[11px] ${isDark ? 'text-gray-500' : 'text-gray-400'} py-1`}>
              {day.label}
            </div>
          ))}
          {generateDays().map((day, i) => {
            const date = day ? new Date(currentYear, currentMonth, day) : null;
            const isStartSelected = day && isSameDay(date, currentStartDate);
            const isEndSelected = day && isSameDay(date, currentEndDate);
            const isRangeDay = isInRange(day);
            const isDisabled = date ? isBefore(date, today) : true;
            const isToday = date && isSameDay(date, today);

            return (
              <button
                key={i}
                disabled={isDisabled}
                onClick={() => handleDateSelect(day)}
                className={`
                  text-[11px] py-1.5 text-center font-medium
                  ${!day ? 'invisible' : ''}
                  ${isDisabled ? isDark ? 'text-zinc-800' : 'text-gray-200' : ''}
                  ${isStartSelected || isEndSelected
                    ? isDark 
                        ? 'bg-blue-500/20 text-blue-200 ring-2 ring-blue-500/40' 
                        : 'bg-blue-50 text-blue-700 ring-2 ring-blue-500/30'
                    : isRangeDay
                    ? isDark ? 'bg-blue-500/10 text-gray-300' : 'bg-blue-50/50 text-gray-600'
                    : isToday
                    ? isDark ? 'text-blue-300' : 'text-blue-500'
                    : isDark ? 'text-gray-400 hover:bg-zinc-800/30' : 'text-gray-600 hover:bg-gray-50'
                  } rounded transition-colors
                `}
              >
                {day}
              </button>
            );
          })}
        </div>
      </div>

      {/* Footer */}
      <div className={`flex items-center justify-end gap-2 p-2.5 ${isDark ? 'border-zinc-800/80' : 'border-gray-100'} border-t`}>
        <button
          onClick={onClose}
          className={`
            inline-flex items-center justify-center gap-1 px-3 py-1.5 text-xs font-medium rounded-md
            ${isDark 
              ? 'text-gray-300 hover:text-gray-200 hover:bg-zinc-800/80 active:bg-zinc-800' 
              : 'text-gray-700 hover:text-gray-900 hover:bg-gray-100/80 active:bg-gray-200'
            }
            transition-colors
          `}
        >
          <XMarkIcon className="w-3.5 h-3.5" />
          Cancel
        </button>
        <button
          onClick={onClose}
          className={`
            inline-flex items-center justify-center gap-1 px-3 py-1.5 text-xs font-medium rounded-md
            ${isDark 
              ? 'bg-blue-600 text-white hover:bg-blue-500 active:bg-blue-700' 
              : 'bg-blue-600 text-white hover:bg-blue-500 active:bg-blue-700'
            }
            transition-colors
          `}
        >
          <CheckIcon className="w-3.5 h-3.5" />
          Apply
        </button>
      </div>
    </div>
  );
};

export default DateTimePickerDesign; 