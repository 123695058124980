import api from '../api';

export const cancelAnalysis = async () => {
  try {
    const response = await api.post('/api/youidea/cancel-analysis');
    return response.data;
  } catch (error) {
    console.error('Failed to cancel analysis:', error);
    throw error;
  }
}; 