import React from 'react';
import { ViewColumnsIcon, CalendarIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';

const ViewToggle = ({ currentView, onViewChange, isDarkMode }) => {
  const views = [
    { 
      id: 'month', 
      icon: CalendarIcon, 
      label: 'Month'
    },
    { 
      id: 'week', 
      icon: CalendarDaysIcon, 
      label: 'Week'
    },
    { 
      id: 'day', 
      icon: ViewColumnsIcon, 
      label: 'Day'
    }
  ];

  return (
    <div className={`hidden sm:inline-flex rounded-md h-7 ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-50'}`}>
      {views.map(({ id, icon: Icon, label }) => (
        <button
          key={id}
          onClick={() => onViewChange(id)}
          className={`
            px-2.5 flex items-center justify-center text-xs font-medium relative gap-1
            ${currentView === id 
              ? isDarkMode 
                ? 'bg-[#333] text-white' 
                : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300'
              : isDarkMode
                ? 'text-gray-400 hover:text-white hover:bg-[#333]'
                : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
            }
            ${id === 'month' ? 'rounded-l-md' : ''}
            ${id === 'day' ? 'rounded-r-md' : ''}
            transition-colors duration-200
          `}
        >
          <Icon className="w-3.5 h-3.5" />
          <span className="font-medium">{label}</span>
        </button>
      ))}
    </div>
  );
};

export default ViewToggle; 