import api from '../api';

class GeoService {
  static async detectUserCountry() {
    try {
      // First try to get from localStorage
      const cachedCountry = localStorage.getItem('userCountry');
      if (cachedCountry) {
        console.log('[GeoService] Using cached country:', cachedCountry);
        return cachedCountry;
      }

      console.log('[GeoService] No cached country found, detecting...');

      // Try multiple geolocation services in sequence
      const services = [
        // Try ipapi.co first (most reliable)
        async () => {
          console.log('[GeoService] Trying ipapi.co...');
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          console.log('[GeoService] ipapi.co response:', data);
          if (!data.country_code) throw new Error('No country from ipapi.co');
          return data.country_code;
        },
        // Then try ipinfo.io as backup
        async () => {
          console.log('[GeoService] Trying ipinfo.io...');
          const response = await fetch('https://ipinfo.io/json');
          const data = await response.json();
          console.log('[GeoService] ipinfo.io response:', data);
          if (!data.country) throw new Error('No country from ipinfo.io');
          return data.country;
        },
        // Browser language as last resort
        async () => {
          const browserLanguages = navigator.languages || [navigator.language];
          console.log('[GeoService] Browser languages:', browserLanguages);
          const countryFromBrowser = browserLanguages
            .map(lang => lang.split('-')[1])
            .find(country => country && country.length === 2);
          if (countryFromBrowser) {
            console.log('[GeoService] Detected country from browser:', countryFromBrowser);
            return countryFromBrowser;
          }
          throw new Error('No country from browser language');
        }
      ];

      // Try each service in sequence until one works
      for (const service of services) {
        try {
          const countryCode = await service();
          if (countryCode) {
            console.log('[GeoService] Successfully detected country:', countryCode);
            localStorage.setItem('userCountry', countryCode.toUpperCase());
            return countryCode.toUpperCase();
          }
        } catch (error) {
          console.warn('[GeoService] Service failed:', error.message);
          continue;
        }
      }

      throw new Error('All geolocation services failed');
    } catch (error) {
      console.error('[GeoService] Error detecting country:', error);
      // In production, default to USD pricing
      return 'US';
    }
  }

  static setUserCountry(countryCode) {
    if (countryCode && countryCode.length === 2) {
      console.log('[GeoService] Setting user country:', countryCode.toUpperCase());
      localStorage.setItem('userCountry', countryCode.toUpperCase());
    }
  }

  static getUserCountry() {
    try {
      const country = localStorage.getItem('userCountry');
      console.log('[GeoService] Getting user country from storage:', country);
      return (country || 'US').toUpperCase();
    } catch (error) {
      console.error('[GeoService] Error getting country from storage:', error);
      return 'US';
    }
  }

  // Helper method to clear country cache (useful for testing)
  static clearCountryCache() {
    console.log('[GeoService] Clearing country cache');
    localStorage.removeItem('userCountry');
  }
}

export default GeoService; 