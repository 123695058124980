// Login.js
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Eye, EyeOff, AlertCircle, Info } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../context/ThemeContext';
import logo from '../amplify-logo-full.svg';
import { getLinkedInAuthUrl, getState, saveState } from '../services/linkedinAuthService';
import { toast } from 'react-hot-toast';
import LinkedInLoadingOverlay from './LinkedInLoadingOverlay';
import GoogleAuthButton from './auth/GoogleAuthButton';
import AnimatedLoader from './common/AnimatedLoader';

const VisuallyHidden = ({ children }) => (
  <span className="sr-only">{children}</span>
);

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkedInLoading, setIsLinkedInLoading] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { login, loginWithLinkedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const location = useLocation();

  useEffect(() => {
    // Handle URL parameters
    const params = new URLSearchParams(location.search);
    const verified = params.get('verified');
    const error = params.get('error');
    const message = params.get('message');
    const emailParam = params.get('email');

    if (verified === 'true' && emailParam) {
      setEmail(emailParam);
      toast.success(message || 'Email verified successfully! Please log in.');
      // Clean up URL
      window.history.replaceState({}, '', '/login');
    } else if (error === 'true') {
      toast.error(message || 'Verification failed. Please try again.');
      // Clean up URL
      window.history.replaceState({}, '', '/login');
    }

    // Handle LinkedIn callback
    const code = params.get('code');
    
    if (code) {
      setIsLinkedInLoading(true);
      handleLinkedInCallback(code);
    }

    // Handle verification success/error messages
    if (location.state?.verificationSuccess) {
      toast.success(location.state.message || 'Email verified successfully! Please log in.');
      // Clear the state after showing the message
      navigate(location.pathname, { replace: true });
    } else if (location.state?.verificationError) {
      toast.error(location.state.message || 'Verification failed. Please try again.');
      // Clear the state after showing the message
      navigate(location.pathname, { replace: true });
    }

    // Load remembered credentials on component mount
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    const shouldRemember = localStorage.getItem('rememberMe') === 'true';
    
    if (shouldRemember && rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true);
    }
  }, [location, navigate]);

  const handleLinkedInCallback = async (code) => {
    try {
      console.log('🔷 Processing LinkedIn callback in Login component');
      const returnedState = new URLSearchParams(location.search).get('state');
      const savedStateData = getState();
      
      if (!savedStateData) {
        console.error('❌ No saved state data found');
        setError('LinkedIn authentication session expired. Please try again.');
        setIsLinkedInLoading(false);
        navigate('/login', { replace: true });
        return;
      }
      
      console.log('🔷 State validation:', {
        returnedState,
        savedState: savedStateData.state,
        hasCodeVerifier: !!savedStateData.codeVerifier,
        stateAge: Date.now() - (savedStateData.timestamp || 0)
      });

      if (!savedStateData.state || savedStateData.state !== returnedState) {
        console.error('❌ State mismatch:', {
          savedState: savedStateData.state,
          returnedState
        });
        setError('LinkedIn authentication failed. Please try again.');
        setIsLinkedInLoading(false);
        navigate('/login', { replace: true });
        return;
      }

      try {
        const success = await loginWithLinkedIn(code, returnedState);
        if (success) {
          // Clear state data
          localStorage.removeItem('linkedin_auth_state');
          
          // Navigate directly to success page without showing login page
          console.log('🔍 Login - Navigating to success page with state:', {
            isSignup: false,
            linkedinTokens: success.linkedinTokens
          });
          
          navigate('/auth/linkedin/success', { 
            replace: true,
            state: { 
              isSignup: false,
              linkedinTokens: success.linkedinTokens
            }
          });
          return; // Add return to prevent further execution
        } else {
          throw new Error('Authentication failed');
        }
      } catch (error) {
        console.error('❌ LinkedIn login error:', error);
        setError(error.response?.data?.message || 'Authentication failed. Please try again.');
        setIsLinkedInLoading(false);
        navigate('/login', { replace: true });
        return; // Add return to prevent further execution
      }
    } catch (error) {
      console.error('❌ LinkedIn callback error:', error);
      setError('Authentication failed. Please try again.');
      setIsLinkedInLoading(false);
      navigate('/login', { replace: true });
    }
  };

  const handleLinkedInLogin = async () => {
    try {
      console.log('🔷 Initiating LinkedIn login flow');
      
      // Clear any existing state data
      localStorage.removeItem('linkedin_auth_state');
      
      // Generate state with more entropy
      const state = Array.from(crypto.getRandomValues(new Uint8Array(16)))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
      
      console.log('🔷 Generated state:', state);
      
      // Get LinkedIn auth URL
      const linkedInUrl = await getLinkedInAuthUrl(state);
      console.log('🔷 Redirecting to LinkedIn:', linkedInUrl);
      
      // Final state verification before redirect
      const finalCheck = getState();
      if (!finalCheck || finalCheck.state !== state) {
        console.error('❌ Final state verification failed');
        setError('Failed to initialize LinkedIn authentication. Please try again.');
        return;
      }
      
      // Store the source of LinkedIn auth
      localStorage.setItem('linkedin_auth_source', 'login');
      
      // Redirect to LinkedIn
      window.location.href = linkedInUrl;
    } catch (error) {
      console.error('❌ Error initiating LinkedIn login:', error);
      setError('Failed to connect to LinkedIn: ' + error.message);
      setIsLinkedInLoading(false);
    }
  };

  const handleContinue = (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!email.trim()) {
      setError('Please enter your email address');
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setError('');
    setShowPasswordField(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!password.trim()) {
      setError('Please enter your password');
      setIsLoading(false);
      return;
    }

    try {
      const success = await login(email, password);
      if (success) {
        // Handle Remember Me
        if (rememberMe) {
          localStorage.setItem('rememberedEmail', email);
          localStorage.setItem('rememberMe', 'true');
        } else {
          localStorage.removeItem('rememberedEmail');
          localStorage.removeItem('rememberMe');
        }

        toast.success('Welcome back! Successfully signed in.', {
          duration: 3000,
          position: 'top-center',
        });
        navigate('/');
      } else {
        setError('Login failed. Please check your credentials and try again.');
        toast.error('Invalid email or password. Please try again.', {
          duration: 5000,
          position: 'top-center',
        });
      }
    } catch (error) {
      console.error('Login error:', error);
      
      // Handle specific error cases
      if (error.response) {
        switch (error.response.status) {
          case 400:
            if (error.response.data?.error === 'Email not verified') {
              setError('Please verify your email before logging in.');
              toast.error('Email not verified. Check your inbox for verification link.', {
                duration: 5000,
                position: 'top-center',
              });
              navigate('/auth/verify-email', { 
                state: { 
                  email,
                  message: 'Please verify your email to continue.' 
                }
              });
            } else {
              setError('Invalid email or password.');
              toast.error('Invalid credentials. Please try again.', {
                duration: 5000,
                position: 'top-center',
              });
            }
            break;
          case 401:
            setError('Invalid credentials. Please check your email and password.');
            toast.error('Invalid credentials. Please try again.', {
              duration: 5000,
              position: 'top-center',
            });
            break;
          case 403:
            setError('Your account has been locked. Please contact support.');
            toast.error('Account locked. Contact support for assistance.', {
              duration: 7000,
              position: 'top-center',
            });
            break;
          case 429:
            setError('Too many login attempts. Please try again later.');
            toast.error('Too many attempts. Please wait a few minutes.', {
              duration: 5000,
              position: 'top-center',
            });
            break;
          case 500:
            setError('Server error. Please try again later.');
            toast.error('Server error. Please try again later.', {
              duration: 5000,
              position: 'top-center',
            });
            break;
          default:
            setError('An unexpected error occurred. Please try again.');
            toast.error('Login failed. Please try again.', {
              duration: 5000,
              position: 'top-center',
            });
        }
      } else if (error.request) {
        // Network error
        setError('Unable to connect to the server. Please check your internet connection.');
        toast.error('Network error. Please check your connection.', {
          duration: 5000,
          position: 'top-center',
        });
      } else {
        setError('An unexpected error occurred. Please try again.');
        toast.error('Login failed. Please try again.', {
          duration: 5000,
          position: 'top-center',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AnimatePresence>
        {isLinkedInLoading && <LinkedInLoadingOverlay />}
      </AnimatePresence>

      {!isLinkedInLoading && (
        <div className={`min-h-screen bg-fixed overflow-x-hidden flex flex-col ${
          isDarkMode 
            ? 'bg-gradient-to-br from-[#09090b] via-[#18181b] to-[#09090b]' 
            : 'bg-gradient-to-br from-slate-50 via-white to-slate-50'
        }`}>
          <div className="flex-grow flex items-center justify-center px-4 sm:px-6 py-12">
            <div className="max-w-md w-full">
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-center mb-8"
              >
                <Link to="/" className="inline-block mb-6 transform hover:scale-105 transition-transform">
                  <img src={logo} alt="Ammmplify" className="h-8 w-auto mx-auto dark:invert" />
                </Link>
                <h2 className={`text-3xl font-bold mb-2 ${
                  isDarkMode ? 'text-white' : 'text-slate-900'
                }`}>
                  Welcome back
                </h2>
              </motion.div>

              <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className={`w-full p-8 rounded-xl backdrop-blur-sm ${
                  isDarkMode 
                    ? 'bg-[#18181b]/90 border border-[#27272a]' 
                    : 'bg-white/90 border border-slate-200/60'
                }`}
              >
                {error && (
                  <motion.div 
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    className={`mb-6 p-4 rounded-lg text-sm flex items-center gap-3 ${
                      isDarkMode 
                        ? 'bg-red-900/20 text-red-400 border border-red-900/50' 
                        : 'bg-red-50 text-red-600 border border-red-100'
                    }`}
                  >
                    <AlertCircle className="h-5 w-5 flex-shrink-0" />
                    <p className="flex-grow">{error}</p>
                  </motion.div>
                )}

                {!showPasswordField ? (
                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="space-y-4"
                  >
                    <button
                      type="button"
                      onClick={handleLinkedInLogin}
                      className={`w-full flex items-center justify-center gap-3 py-3.5 px-4 rounded-lg font-medium text-base
                        bg-[#2d64bc] text-white hover:bg-[#2d64bc]/90
                        transform transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]`}
                    >
                      <img
                        className="h-5 w-5 sm:h-6 sm:w-6"
                        src="/linkedin-icon.svg"
                        alt="LinkedIn logo"
                      />
                      Sign in with LinkedIn
                    </button>

                    <div className="mt-4">
                      <GoogleAuthButton 
                        isSignUp={false} 
                        className={`w-full py-3.5 px-4 rounded-lg text-base ${
                          isDarkMode 
                            ? 'bg-[#27272a] text-white hover:bg-[#3f3f46] border-[#3f3f46]' 
                            : 'bg-white text-slate-700 hover:bg-slate-50 border-slate-200'
                        }`} 
                      />
                    </div>

                    <div className="mt-4">
                      <button
                        type="button"
                        onClick={() => setShowPasswordField(true)}
                        className={`w-full flex items-center justify-center gap-3 py-3.5 px-4 rounded-lg font-medium text-base
                          ${isDarkMode 
                            ? 'bg-[#27272a] text-white hover:bg-[#3f3f46] border border-[#3f3f46]' 
                            : 'bg-white text-slate-700 hover:bg-slate-50 border border-slate-200'
                          } transition-all duration-200 hover:scale-[1.02] active:scale-[0.98]`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                        </svg>
                        Sign in with email
                      </button>
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    className="space-y-6"
                  >
                    <button
                      type="button"
                      onClick={() => setShowPasswordField(false)}
                      className={`-mt-2 -mx-2 mb-4 flex items-center gap-2 py-2 px-3 rounded-lg text-sm font-medium
                        ${isDarkMode 
                          ? 'text-slate-400 hover:text-white hover:bg-[#27272a]' 
                          : 'text-slate-500 hover:text-slate-900 hover:bg-slate-50'
                        } transition-all duration-200`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                      </svg>
                      Back
                    </button>

                    <form onSubmit={handleSubmit} className="space-y-6">
                      <div>
                        <label className="sr-only" htmlFor="email">
                          Email address
                        </label>
                        <input
                          id="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Your email address"
                          required
                          autoFocus
                          autoComplete="email"
                          aria-label="Email address"
                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                          className={`w-full px-4 py-3.5 rounded-lg border text-base transition-all duration-200 ${
                            isDarkMode 
                              ? 'bg-[#27272a] border-[#3f3f46] text-white placeholder-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500' 
                              : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                          }`}
                        />
                      </div>

                      <div>
                        <label className="sr-only" htmlFor="password">
                          Password
                        </label>
                        <div className="relative">
                          <input
                            id="password"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            required
                            className={`w-full px-4 py-3.5 rounded-lg border text-base transition-all duration-200 ${
                              isDarkMode 
                                ? 'bg-[#27272a] border-[#3f3f46] text-white placeholder-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500' 
                                : 'bg-white border-slate-200 text-slate-900 placeholder-slate-400 focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                            }`}
                          />
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className={`absolute right-4 top-1/2 -translate-y-1/2 p-1 rounded-lg ${
                              isDarkMode 
                                ? 'text-slate-400 hover:text-white hover:bg-[#3f3f46]' 
                                : 'text-slate-500 hover:text-slate-700 hover:bg-slate-100'
                            } transition-colors`}
                          >
                            {showPassword ? (
                              <EyeOff className="h-5 w-5" />
                            ) : (
                              <Eye className="h-5 w-5" />
                            )}
                          </button>
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="remember"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                            className={`h-4 w-4 rounded border ${
                              isDarkMode 
                                ? 'bg-[#27272a] border-[#3f3f46] text-blue-500' 
                                : 'bg-white border-slate-300 text-blue-600'
                            }`}
                          />
                          <label 
                            htmlFor="remember" 
                            className={`ml-2 text-sm ${
                              isDarkMode ? 'text-slate-400' : 'text-slate-600'
                            } cursor-pointer select-none`}
                          >
                            Remember me
                          </label>
                        </div>
                        <div className="flex items-center gap-4 text-sm">
                          <Link 
                            to="/reset-password" 
                            className={`${
                              isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-500 hover:text-blue-600'
                            } transition-colors`}
                          >
                            Forgot password?
                          </Link>
                          <button 
                            type="button"
                            onClick={() => {
                              navigate('/magic-link', { 
                                state: { email },
                                replace: true 
                              });
                            }}
                            className={`${
                              isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-500 hover:text-blue-600'
                            } transition-colors`}
                          >
                            Use magic link
                          </button>
                        </div>
                      </div>

                      <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full py-3.5 px-4 rounded-lg font-semibold text-base
                          ${isLoading 
                            ? 'opacity-70 cursor-not-allowed' 
                            : 'hover:opacity-90'
                          } ${
                            isDarkMode
                              ? 'bg-white text-[#18181b] hover:bg-gray-100'
                              : 'bg-[#18181b] text-white hover:bg-black'
                          } transition-all duration-200 transform hover:scale-[1.02] active:scale-[0.98] relative overflow-hidden`}
                      >
                        <AnimatePresence mode="wait">
                          {isLoading ? (
                            <motion.span
                              key="loading"
                              initial={{ opacity: 0, y: 10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                              className="flex items-center justify-center gap-3"
                            >
                              <AnimatedLoader size={20} color={isDarkMode ? 'black' : 'white'} />
                              Signing in...
                            </motion.span>
                          ) : (
                            <motion.span
                              key="sign-in"
                              initial={{ opacity: 0, y: 10 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -10 }}
                            >
                              Sign in
                            </motion.span>
                          )}
                        </AnimatePresence>
                      </button>
                    </form>
                  </motion.div>
                )}

                <p className={`mt-8 text-sm text-center ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-600'
                }`}>
                  Don't have an account?{' '}
                  <Link to="/register" className="text-blue-500 hover:text-blue-600 font-medium transition-colors">
                    Sign up
                  </Link>
                </p>
              </motion.div>
            </div>
          </div>
          
          <footer className={`py-6 text-center ${
            isDarkMode ? 'border-t border-[#27272a]' : 'border-t border-slate-200/60'
          }`}>
            <p className={`text-sm ${
              isDarkMode ? 'text-slate-500' : 'text-slate-400'
            }`}>
              <Link to="/terms" className="hover:text-blue-500 transition-colors">Terms</Link>
              {' · '}
              <Link to="/privacy" className="hover:text-blue-500 transition-colors">Privacy</Link>
            </p>
          </footer>
        </div>
      )}
    </>
  );
};

export default Login;
