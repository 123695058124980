import React from 'react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { 
  UserIcon,
  ClockIcon,
  GlobeAltIcon,
  ExclamationCircleIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import { cn } from '../../utils/cn';
import { isLinkedInConnected } from '../../services/linkedinPublishService';

const colorRotations = {
  light: [
    'bg-sky-50 hover:bg-sky-100 border-sky-200',
    'bg-rose-50 hover:bg-rose-100 border-rose-200',
    'bg-teal-50 hover:bg-teal-100 border-teal-200',
    'bg-amber-50 hover:bg-amber-100 border-amber-200',
    'bg-purple-50 hover:bg-purple-100 border-purple-200',
  ],
  dark: [
    'bg-blue-900/30 hover:bg-blue-900/40 border-blue-700/20',
    'bg-rose-900/30 hover:bg-rose-900/40 border-rose-700/20',
    'bg-emerald-900/30 hover:bg-emerald-900/40 border-emerald-700/20',
    'bg-amber-900/30 hover:bg-amber-900/40 border-amber-700/20',
    'bg-violet-900/30 hover:bg-violet-900/40 border-violet-700/20',
  ]
};

const getPostBackgroundColor = (post, colorIndex, isDarkMode) => {
  const colors = isDarkMode ? colorRotations.dark : colorRotations.light;
  const baseColor = colors[colorIndex % colors.length];
  
  if (post.status && post.status !== 'scheduled') {
    if (isDarkMode) {
      switch (post.status) {
        case 'published':
          return baseColor.replace('/30', '/40').replace('/40', '/50');
        case 'failed':
          return 'bg-red-900/30 hover:bg-red-900/40 border-red-700/30';
        case 'processing':
          return baseColor.replace('/30', '/20').replace('/40', '/30');
        default:
          return baseColor;
      }
    } else {
      switch (post.status) {
        case 'published':
          return baseColor.replace('50', '100').replace('100', '200');
        case 'failed':
          return 'bg-red-50 hover:bg-red-100 border-red-200';
        case 'processing':
          return baseColor.replace('100', '50').replace('200', '100');
        default:
          return baseColor;
      }
    }
  }

  return baseColor;
};

const StatusBadge = ({ status, className, isDarkMode }) => {
  const statusConfig = {
    scheduled: {
      icon: ClockIcon,
      styles: cn(
        isDarkMode
          ? 'bg-blue-950/50 text-blue-300 dark:ring-blue-500/20'
          : 'bg-blue-50 text-blue-600',
        'ring-1 ring-black/[0.08] dark:ring-white/[0.09]'
      ),
      label: 'Scheduled'
    },
    published: {
      icon: GlobeAltIcon,
      styles: cn(
        isDarkMode
          ? 'bg-emerald-950/50 text-emerald-300 dark:ring-emerald-500/20'
          : 'bg-emerald-50 text-emerald-600',
        'ring-1 ring-black/[0.08] dark:ring-white/[0.09]'
      ),
      label: 'Live'
    },
    failed: {
      icon: ExclamationCircleIcon,
      styles: cn(
        isDarkMode
          ? 'bg-rose-950/50 text-rose-300 dark:ring-rose-500/20'
          : 'bg-rose-50 text-rose-600',
        'ring-1 ring-black/[0.08] dark:ring-white/[0.09]'
      ),
      label: 'Failed'
    },
    processing: {
      icon: ArrowPathIcon,
      styles: cn(
        isDarkMode
          ? 'bg-amber-950/50 text-amber-300 dark:ring-amber-500/20'
          : 'bg-amber-50 text-amber-600',
        'ring-1 ring-black/[0.08] dark:ring-white/[0.09]'
      ),
      label: 'Processing'
    }
  };

  const { icon: Icon, styles, label } = statusConfig[status];

  return (
    <span className={cn(
      "inline-flex items-center gap-0.5 px-1.5 py-px",
      "text-[10px] font-medium rounded-full",
      styles,
      className
    )}>
      <Icon className="w-2.5 h-2.5" />
      <span className="tracking-tight">{label}</span>
    </span>
  );
};

const CompactLinkedInPostPreview = ({ 
  post, 
  isDarkMode, 
  isSelected = false,
  colorIndex = 0,
  className,
  onClick
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  // Get the first media file's Cloudinary URL if available
  const mediaUrl = post.mediaFiles?.[0]?.cloudinaryUrl || post.mediaFiles?.[0]?.url;
  const mediaFile = post.mediaFiles?.[0];

  return (
    <motion.div
      initial={{ opacity: 0, y: 2 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -2 }}
      whileHover={{ scale: 1.002 }}
      whileTap={{ scale: 0.998 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      onClick={() => onClick?.(post)}
      className={cn(
        "group relative rounded-lg border h-12 px-2",
        "transition-all duration-150",
        "cursor-pointer",
        isDarkMode 
          ? [
              'focus:ring-1 focus:ring-blue-500/50 focus:ring-offset-1 focus:ring-offset-[#121212]',
              isSelected && 'ring-1 ring-blue-500/50 ring-offset-1 ring-offset-[#121212]',
              'shadow-sm shadow-black/10',
              getPostBackgroundColor(post, colorIndex, isDarkMode)
            ]
          : [
              'focus:ring-1 focus:ring-blue-500/30 focus:ring-offset-1',
              isSelected && 'ring-1 ring-blue-500/30 ring-offset-1',
              'shadow-sm',
              getPostBackgroundColor(post, colorIndex, isDarkMode)
            ],
        className
      )}
      role="button"
      tabIndex={0}
    >
      <div className="flex items-center h-full gap-2">
        {/* Media Preview - Only shown when media exists and is an image */}
        {mediaUrl && mediaFile && (mediaFile.mimeType?.startsWith('image/') || mediaFile.type?.startsWith('image/')) && (
          <div className={cn(
            "w-8 h-8 rounded-md overflow-hidden shrink-0",
            isDarkMode ? 'bg-black/20' : 'bg-zinc-100/50'
          )}>
            <img
              src={mediaUrl}
              alt=""
              className="h-full w-full object-cover"
              onError={(e) => {
                console.error('Failed to load image:', mediaUrl);
                e.target.onerror = null;
                e.target.src = '';
              }}
            />
          </div>
        )}
          
        {/* Content Preview */}
        <div className="min-w-0 flex-1">
          <p className={cn(
            "text-xs line-clamp-1",
            isDarkMode ? "text-zinc-300" : "text-zinc-600"
          )}>
            {post.content}
          </p>
          <div className="flex items-center gap-1.5 mt-0.5">
            <time className={cn(
              "text-[9px] font-medium tabular-nums",
              isDarkMode ? "text-gray-500" : "text-muted-foreground/70"
            )}>
              {format(new Date(post.scheduledFor), 'h:mm a')}
            </time>
            {post.status && (
              <StatusBadge 
                status={post.status} 
                isDarkMode={isDarkMode}
              />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CompactLinkedInPostPreview; 