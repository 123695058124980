import React, { useState, useRef, useEffect } from 'react';
import { fontStyles } from '../utils/unicodeFonts';
import { cn } from '../utils/cn';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import FontStylePortal from './FontStylePortal';

const FontStylePillBar = ({ onSelectStyle, selectedStyle, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredStyle, setHoveredStyle] = useState(null);
  const buttonRef = useRef(null);
  const [dropdownStyle, setDropdownStyle] = useState({});

  const selectedFont = fontStyles.find(style => style.id === selectedStyle) || fontStyles[0];

  // Update dropdown position when button position changes
  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownStyle({
        position: 'fixed',
        top: `${rect.bottom + 4}px`,
        left: `${rect.left}px`,
        minWidth: `${Math.max(rect.width, 200)}px`
      });
    }
  }, [isOpen]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isOpen) return;

      switch (e.key) {
        case 'Escape':
          setIsOpen(false);
          break;
        case 'ArrowDown':
          e.preventDefault();
          const currentIndex = fontStyles.findIndex(style => style.id === hoveredStyle);
          const nextIndex = currentIndex === fontStyles.length - 1 ? 0 : currentIndex + 1;
          setHoveredStyle(fontStyles[nextIndex].id);
          break;
        case 'ArrowUp':
          e.preventDefault();
          const currIndex = fontStyles.findIndex(style => style.id === hoveredStyle);
          const prevIndex = currIndex <= 0 ? fontStyles.length - 1 : currIndex - 1;
          setHoveredStyle(fontStyles[prevIndex].id);
          break;
        case 'Enter':
          if (hoveredStyle) {
            onSelectStyle(hoveredStyle);
            setIsOpen(false);
          }
          break;
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [isOpen, hoveredStyle, onSelectStyle]);

  return (
    <div className="relative inline-block">
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setIsOpen(!isOpen);
          }
        }}
        aria-label="Select font style"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        className={cn(
          "flex items-center gap-2 px-3 py-1.5 rounded-md text-xs font-medium h-[30px]",
          "transition-all duration-200 select-none group",
          isDarkMode
            ? [
                "bg-[#151515] border border-[#333] text-slate-200 hover:bg-[#1a1a1a]",
              ]
            : [
                "bg-gray-50 border border-gray-200 text-gray-700 hover:bg-white",
              ],
          isOpen && (isDarkMode 
            ? "bg-[#1a1a1a] border-[#444]" 
            : "bg-white border-gray-300")
        )}
      >
        <div className="flex items-center h-full">
          <span className="opacity-90 transition-opacity duration-200 scale-100 group-hover:scale-90 group-hover:opacity-100 leading-none">{selectedFont.sample}</span>
        </div>
        <ChevronDownIcon 
          className={cn(
            "w-3 h-3 transition-all duration-300 ease-out",
            isDarkMode 
              ? "text-slate-400 group-hover:text-blue-400" 
              : "text-gray-400 group-hover:text-blue-600",
            isOpen ? "transform rotate-180" : ""
          )}
        />
      </button>

      {isOpen && (
        <FontStylePortal>
          {/* Backdrop */}
          <div 
            className="fixed inset-0 z-[9999] bg-transparent" 
            onClick={() => setIsOpen(false)}
          />

          {/* Dropdown */}
          <div
            style={dropdownStyle}
            className={cn(
              "z-[9999] rounded-lg shadow-lg p-1.5",
              "transform transition-all duration-200 ease-out",
              "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95",
              isDarkMode
                ? "bg-[#1a1a1a] border border-[#333] shadow-2xl shadow-black/20"
                : "bg-white border border-gray-200 shadow-xl shadow-gray-100/70"
            )}
            role="listbox"
            aria-label="Font styles"
          >
            <div className="py-1">
              {fontStyles.map((style) => (
                <button
                  key={style.id}
                  role="option"
                  aria-selected={selectedStyle === style.id}
                  onClick={() => {
                    onSelectStyle(style.id);
                    setIsOpen(false);
                  }}
                  onMouseEnter={() => setHoveredStyle(style.id)}
                  onMouseLeave={() => setHoveredStyle(null)}
                  className={cn(
                    "w-full text-left px-3 py-2 text-xs rounded-md",
                    "flex items-center justify-between",
                    "transition-all duration-200",
                    "relative group/item",
                    selectedStyle === style.id
                      ? isDarkMode
                        ? [
                            "bg-[#252525] text-white",
                          ]
                        : [
                            "bg-gray-100 text-gray-900",
                          ]
                      : isDarkMode
                        ? [
                            "text-slate-200 hover:bg-[#252525] hover:text-white",
                          ]
                        : [
                            "text-gray-800 hover:bg-gray-50 hover:text-gray-900",
                          ],
                    hoveredStyle === style.id && "bg-opacity-50"
                  )}
                >
                  <div className="flex flex-col gap-1">
                    <span className={cn(
                      "font-medium transition-colors duration-200",
                      hoveredStyle === style.id && (isDarkMode ? "text-blue-400" : "text-blue-600")
                    )}>{style.name}</span>
                  </div>
                  
                  {/* Preview */}
                  <div className={cn(
                    "flex items-center gap-2",
                    "transition-all duration-200 transform",
                    "group-hover/item:scale-110",
                    selectedStyle === style.id
                      ? isDarkMode ? "text-blue-400" : "text-blue-600"
                      : hoveredStyle === style.id
                        ? isDarkMode ? "text-blue-400" : "text-blue-600"
                        : "opacity-70 group-hover/item:opacity-100"
                  )}>
                    <span className="text-sm">{style.sample}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </FontStylePortal>
      )}
    </div>
  );
};

export default FontStylePillBar; 