import React, { useState, useEffect, useRef } from 'react';
import { XMarkIcon, MagnifyingGlassIcon, ChevronUpDownIcon, CheckIcon, PhotoIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import Portal from '../ui/Portal';
import { getUserCanvases, getCanvas } from '../../api';

const CustomDropdown = ({ value, onChange, options, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const buttonRef = useRef(null);
  const [buttonRect, setButtonRect] = useState(null);
  const dropdownRef = useRef(null);

  const selectedOption = options.find(option => option._id === value);

  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setButtonRect(rect);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleKeyDown = (e) => {
    if (!isOpen) {
      if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowDown') {
        e.preventDefault();
        setIsOpen(true);
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev < options.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev > 0 ? prev - 1 : prev
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0) {
          onChange(options[highlightedIndex]);
          setIsOpen(false);
        }
        break;
      case 'Escape':
        e.preventDefault();
        setIsOpen(false);
        break;
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        ref={buttonRef}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        className={`
          relative w-full rounded-md px-3 py-2 text-left text-sm
          border focus:outline-none focus:ring-1 focus:ring-blue-500
          transition-colors duration-200
          ${isDarkMode 
            ? 'bg-[#141414] text-zinc-100 border-[#333333] hover:bg-[#1a1a1a]' 
            : 'bg-white text-zinc-900 border-zinc-200 hover:bg-zinc-50'
          }
          ${isOpen ? 'ring-1 ring-blue-500' : ''}
        `}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span className="flex items-center justify-between">
          <span className={`block truncate ${selectedOption ? '' : isDarkMode ? 'text-zinc-500' : 'text-zinc-400'}`}>
            {selectedOption ? (
              <span className="flex items-center gap-2">
                <span>{selectedOption.title}</span>
                <span className={`text-xs px-2 py-0.5 rounded-full ${
                  isDarkMode 
                    ? 'bg-[#252525] text-zinc-400' 
                    : 'bg-zinc-100 text-zinc-600'
                }`}>
                  {selectedOption.posts?.length || 0} posts
                </span>
              </span>
            ) : (
              'Select a campaign'
            )}
          </span>
          <ChevronUpDownIcon
            className={`h-4 w-4 flex-shrink-0 transition-transform duration-200
              ${isDarkMode ? 'text-zinc-500' : 'text-zinc-400'}
              ${isOpen ? 'transform rotate-180' : ''}
            `}
            aria-hidden="true"
          />
        </span>
      </button>

      <AnimatePresence>
        {isOpen && buttonRect && (
          <Portal>
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.15 }}
              style={{
                position: 'absolute',
                top: `${buttonRect.bottom + 4}px`,
                left: `${buttonRect.left}px`,
                width: `${buttonRect.width}px`,
                zIndex: 100
              }}
              className={`
                max-h-60 overflow-auto rounded-md py-1
                shadow-lg ring-1 ring-opacity-5 focus:outline-none
                ${isDarkMode 
                  ? 'bg-[#1a1a1a] ring-[#333333] custom-scrollbar-dark' 
                  : 'bg-white ring-black custom-scrollbar-light'
                }
              `}
              role="listbox"
            >
              {options.map((option, index) => (
                <button
                  key={option._id}
                  onClick={() => {
                    onChange(option);
                    setIsOpen(false);
                  }}
                  onMouseEnter={() => setHighlightedIndex(index)}
                  className={`
                    relative w-full cursor-pointer select-none py-2 pl-3 pr-3
                    text-sm transition-colors duration-100
                    ${isDarkMode 
                      ? 'hover:bg-[#252525] text-zinc-100' 
                      : 'hover:bg-zinc-50 text-zinc-900'
                    }
                    ${highlightedIndex === index ? (isDarkMode ? 'bg-[#202020]' : 'bg-zinc-50') : ''}
                    ${value === option._id ? 'font-medium' : 'font-normal'}
                  `}
                  role="option"
                  aria-selected={value === option._id}
                >
                  <span className="flex items-center justify-between">
                    <span className="block truncate">
                      {option.title}
                    </span>
                    <span className="flex items-center gap-2">
                      <span className={`text-xs px-2 py-0.5 rounded-full ${
                        isDarkMode 
                          ? 'bg-[#252525] text-zinc-400' 
                          : 'bg-zinc-100 text-zinc-600'
                      }`}>
                        {option.posts?.length || 0} posts
                      </span>
                      {value === option._id && (
                        <CheckIcon className={`h-4 w-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
                      )}
                    </span>
                  </span>
                </button>
              ))}
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>
    </div>
  );
};

const modalVariants = {
  overlay: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.15 }
  },
  content: {
    initial: { scale: 0.98, opacity: 0, y: 8 },
    animate: { scale: 1, opacity: 1, y: 0 },
    exit: { scale: 0.98, opacity: 0, y: 8 },
    transition: { type: "spring", duration: 0.25, bounce: 0.2 }
  }
};

const CampaignPostsModal = ({ isOpen, onClose, onSelectPost, isDarkMode }) => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [posts, setPosts] = useState([]);
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);
  const [isFetchingCampaigns, setIsFetchingCampaigns] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchCampaigns();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCampaign) {
      fetchCampaignPosts(selectedCampaign._id);
    }
  }, [selectedCampaign]);

  const fetchCampaigns = async () => {
    try {
      setIsFetchingCampaigns(true);
      setError(null);
      
      const canvases = await getUserCanvases();
      console.log('Fetched campaigns:', canvases);
      
      if (canvases && canvases.length > 0) {
        const formattedCanvases = canvases.map(canvas => ({
          ...canvas,
          title: canvas.title || 'Untitled Campaign'
        }));
        setCampaigns(formattedCanvases);
      } else {
        setError('No campaigns found');
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      if (error.response?.status === 401) {
        setError('Please log in to view campaigns');
      } else if (error.response?.status === 404) {
        setError('No campaigns found');
      } else {
        setError('Failed to load campaigns. Please try again.');
      }
    } finally {
      setIsFetchingCampaigns(false);
    }
  };

  const fetchCampaignPosts = async (campaignId) => {
    try {
      setIsLoadingPosts(true);
      setError(null);
      
      const canvas = await getCanvas(campaignId);
      console.log('Fetched canvas:', canvas);
      
      if (canvas && canvas.posts) {
        const validPosts = canvas.posts
          .filter(post => post.content)
          .map(post => {
            // Process media files
            let mediaFiles = post.mediaFiles || [];
            if (mediaFiles.length > 0) {
              mediaFiles = mediaFiles.map(file => ({
                ...file,
                url: file.cloudinaryUrl || file.url
              }));
            }

            return {
              ...post,
              title: post.title || 'Untitled Post',
              content: post.content.trim(),
              mediaFiles
            };
          });
        
        setPosts(validPosts);
      } else {
        setPosts([]);
      }
    } catch (error) {
      console.error('Error fetching campaign posts:', error);
      if (error.response?.status === 401) {
        setError('Please log in to view posts');
      } else if (error.response?.status === 404) {
        setError('Campaign not found');
      } else {
        setError('Failed to load campaign posts. Please try again.');
      }
    } finally {
      setIsLoadingPosts(false);
    }
  };

  const filteredPosts = posts.filter(post => 
    post.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.title?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const LoadingSkeleton = () => (
    <div className="space-y-3">
      {[1, 2, 3].map((i) => (
        <div 
          key={i}
          className="animate-pulse flex gap-3"
        >
          {/* Media placeholder */}
          <div className={`
            w-20 h-20 rounded-md flex-shrink-0
            ${isDarkMode ? 'bg-[#252525]' : 'bg-zinc-100'}
          `} />
          
          {/* Content placeholder */}
          <div className="flex-1 space-y-2">
            {/* Title placeholder */}
            <div className={`
              h-4 rounded-md w-1/3
              ${isDarkMode ? 'bg-[#252525]' : 'bg-zinc-100'}
            `} />
            
            {/* Content lines */}
            <div className={`
              h-3 rounded-md w-full
              ${isDarkMode ? 'bg-[#252525]' : 'bg-zinc-100'}
            `} />
            <div className={`
              h-3 rounded-md w-2/3
              ${isDarkMode ? 'bg-[#252525]' : 'bg-zinc-100'}
            `} />
          </div>
        </div>
      ))}
    </div>
  );

  const handlePostSelect = (post) => {
    // Ensure we have all necessary media information
    const mediaFiles = post.mediaFiles?.map(file => ({
      originalName: file.originalName || file.name,
      s3Key: file.s3Key,
      bucket: file.bucket || process.env.REACT_APP_AWS_S3_BUCKET,
      mimeType: file.mimeType || file.type,
      size: file.size,
      metadata: file.metadata || {},
      url: file.cloudinaryUrl || file.url // Use direct Cloudinary URL
    })) || [];

    onSelectPost({
      ...post,
      mediaFiles,
      title: post.title || 'Untitled Post'
    });
  };

  if (!isOpen) return null;

  return (
    <Portal>
      <AnimatePresence>
        <motion.div
          {...modalVariants.overlay}
          className={`
            fixed inset-0 z-50 
            flex items-center justify-center 
            ${isDarkMode ? 'bg-black/60' : 'bg-zinc-900/20'} 
            backdrop-blur-[2px]
            p-4 sm:p-6
            cursor-pointer
            overflow-y-auto
          `}
          onClick={(e) => {
            if (e.target === e.currentTarget) onClose();
          }}
        >
          <motion.div
            {...modalVariants.content}
            className={`
              w-full max-w-xl rounded-lg shadow-lg relative z-[60]
              ${isDarkMode 
                ? 'bg-[#1a1a1a] border border-[#333333]' 
                : 'bg-white border border-zinc-100'
              }
            `}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Header - Simplified */}
            <div className={`
              flex items-center justify-between p-4 border-b
              ${isDarkMode ? 'border-[#333333]' : 'border-zinc-100'}
            `}>
              <h3 className={`text-base font-medium ${
                isDarkMode ? 'text-zinc-100' : 'text-zinc-900'
              }`}>
                Select Post
              </h3>
              <button
                onClick={onClose}
                className={`
                  rounded-full p-1.5 transition-colors
                  ${isDarkMode 
                    ? 'text-zinc-400 hover:bg-zinc-800 hover:text-zinc-100' 
                    : 'text-zinc-500 hover:bg-zinc-100 hover:text-zinc-700'
                  }
                `}
              >
                <XMarkIcon className="w-4 h-4" />
              </button>
            </div>

            {/* Content */}
            <div className="p-4 space-y-4">
              {/* Error Display - Simplified */}
              {error && error !== 'No posts found in this campaign' && (
                <div className={`
                  p-2.5 rounded-md text-sm flex items-center gap-2
                  ${isDarkMode 
                    ? 'bg-red-500/10 text-red-200' 
                    : 'bg-red-50 text-red-600'
                  }
                `}>
                  {error}
                </div>
              )}

              {/* Campaign Selection - Simplified */}
              <CustomDropdown
                value={selectedCampaign?._id || ''}
                onChange={(campaign) => setSelectedCampaign(campaign)}
                options={campaigns}
                isDarkMode={isDarkMode}
              />

              {/* Search - Simplified */}
              {selectedCampaign && (
                <div className="relative">
                  <MagnifyingGlassIcon className={`
                    absolute left-2.5 top-2.5 w-4 h-4
                    ${isDarkMode ? 'text-zinc-500' : 'text-zinc-400'}
                  `} />
                  <input
                    type="text"
                    placeholder="Search posts..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={`
                      w-full pl-9 pr-3 py-2 text-sm rounded-md
                      ${isDarkMode 
                        ? 'bg-[#141414] text-zinc-100 border-[#333333] placeholder-zinc-600' 
                        : 'bg-zinc-50 text-zinc-900 border-zinc-200 placeholder-zinc-400'
                      }
                      border focus:outline-none focus:ring-1 focus:ring-blue-500
                      transition-colors duration-200
                    `}
                  />
                </div>
              )}

              {/* Posts List - Simplified */}
              <div className={`
                space-y-2 max-h-[400px] overflow-y-auto
                ${isDarkMode ? 'custom-scrollbar-dark' : 'custom-scrollbar-light'}
              `}>
                {selectedCampaign ? (
                  isLoadingPosts ? (
                    <LoadingSkeleton />
                  ) : filteredPosts.length > 0 ? (
                    filteredPosts.map(post => (
                      <button
                        key={post.id}
                        onClick={() => handlePostSelect(post)}
                        className={`
                          w-full text-left p-3 rounded-md border transition-all
                          ${isDarkMode 
                            ? 'bg-[#1a1a1a] border-[#333333] hover:bg-[#252525]' 
                            : 'bg-white border-zinc-100 hover:bg-zinc-50'
                          }
                          group
                        `}
                      >
                        <div className="flex gap-3">
                          {/* Media Preview */}
                          {(post.mediaFiles?.length > 0 || post.imageUrl) && (
                            <div className="w-20 h-20 flex-shrink-0 rounded-md overflow-hidden">
                              {post.mediaFiles?.[0]?.mimeType?.startsWith('image/') || post.imageUrl ? (
                                <img 
                                  src={post.mediaFiles?.[0]?.url || post.mediaFiles?.[0]?.cloudinaryUrl || post.imageUrl} 
                                  alt="Preview"
                                  className="w-full h-full object-cover"
                                />
                              ) : (
                                <div className={`
                                  w-full h-full flex items-center justify-center
                                  ${isDarkMode ? 'bg-[#252525]' : 'bg-zinc-100'}
                                `}>
                                  <DocumentIcon className="w-6 h-6 text-zinc-400" />
                                </div>
                              )}
                            </div>
                          )}

                          {/* Content */}
                          <div className="flex-1 min-w-0">
                            <h4 className={`text-sm font-medium truncate ${
                              isDarkMode ? 'text-zinc-200' : 'text-zinc-900'
                            }`}>
                              {post.title || 'Untitled Post'}
                            </h4>
                            <p className={`text-sm mt-1 line-clamp-2 ${
                              isDarkMode ? 'text-zinc-400' : 'text-zinc-600'
                            }`}>
                              {post.content}
                            </p>
                            {post.mediaFiles?.length > 1 && (
                              <div className={`
                                mt-2 inline-flex items-center gap-1 text-xs
                                ${isDarkMode ? 'text-zinc-500' : 'text-zinc-400'}
                              `}>
                                <PhotoIcon className="w-3.5 h-3.5" />
                                <span>+{post.mediaFiles.length - 1} more</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </button>
                    ))
                  ) : (
                    <div className={`
                      text-center py-8 rounded-md
                      ${isDarkMode 
                        ? 'bg-[#141414] text-zinc-400' 
                        : 'bg-zinc-50 text-zinc-500'
                      }
                    `}>
                      No posts found
                    </div>
                  )
                ) : (
                  <div className={`
                    text-center py-8 rounded-md
                    ${isDarkMode 
                      ? 'bg-[#141414] text-zinc-400' 
                      : 'bg-zinc-50 text-zinc-500'
                    }
                  `}>
                    {isFetchingCampaigns ? (
                      'Loading campaigns...'
                    ) : (
                      'Select a campaign to view posts'
                    )}
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </Portal>
  );
};

export default CampaignPostsModal; 