import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserIcon, UserPlusIcon, MagnifyingGlassIcon, XMarkIcon, ArrowPathIcon, PlusCircleIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import api, { defaultDataApi } from '../../api';
import PersonaCard from './PersonaCard';
import CompactPersonaCard from './CompactPersonaCard';
import PersonaDetailsPopup from './PersonaDetailsPopup';
import { GridSkeleton } from '../common/CardSkeleton';
import { motion } from 'framer-motion';
import Notification from '../Notification';
import UpgradePopup from '../UpgradePopup/UpgradePopup';
import { useAuth } from '../../context/AuthContext';
import SubscriptionService from '../../services/subscriptionService';
import { Helmet } from 'react-helmet-async';

// Add this new component at the top of the file, outside of the main PersonasPage component
const NoResultsMessage = ({ searchTerm, onCreateClick, onAddExamples, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-8 rounded-2xl
      backdrop-blur-xl backdrop-saturate-150
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-4
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <UserIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center max-w-sm">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-2 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'Create your first persona'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm mb-6
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or create a new persona' 
          : 'Get started by creating a new persona or add example personas'}
      </motion.p>
      
      <div className="flex items-center gap-3 justify-center">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={onCreateClick}
          className={`
            relative group flex items-center gap-2 
            px-4 py-2 rounded-lg text-sm font-medium
            transition-all duration-200
            ${isDarkMode 
              ? 'bg-[#252525] hover:bg-[#2a2a2a] text-slate-200 border border-white/10' 
              : 'bg-slate-900 hover:bg-slate-800 text-white'}
          `}
        >
          <UserPlusIcon className="w-4 h-4" />
          <span>New Persona</span>
        </motion.button>
        
        {!searchTerm && (
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={onAddExamples}
            className={`
              relative group flex items-center gap-2
              px-4 py-2 rounded-lg text-sm font-medium
              transition-all duration-200
              ${isDarkMode 
                ? 'bg-[#1e1e1e] hover:bg-[#252525] text-slate-300 border border-white/10' 
                : 'bg-slate-100 hover:bg-slate-200 text-slate-700'}
            `}
          >
            <PlusCircleIcon className="w-4 h-4" />
            <span>Add Example Personas</span>
          </motion.button>
        )}
      </div>
    </div>
  </motion.div>
);

// Add this new component for the mobile search bar
const MobileSearchBar = ({ isDarkMode, searchTerm, onSearchChange, onClose }) => (
  <div className={`
    fixed inset-x-0 top-0 z-50 p-2
    ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
    border-b ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
  `}>
    <div className="relative flex items-center">
      <MagnifyingGlassIcon className={`absolute left-3 h-4 w-4 ${
        isDarkMode ? 'text-slate-400' : 'text-gray-400'
      }`} />
      <input
        type="text"
        className={`w-full pl-9 pr-8 py-2 text-sm rounded-lg
          focus:outline-none focus:ring-2
          ${isDarkMode
            ? 'bg-[#1a1a1a] text-slate-200 placeholder-slate-400 focus:ring-blue-500/30'
            : 'bg-gray-100 text-gray-900 placeholder-gray-500 focus:ring-blue-500/50'
          }
        `}
        placeholder="Search personas..."
        value={searchTerm}
        onChange={onSearchChange}
        autoFocus
      />
      <button
        onClick={onClose}
        className={`absolute right-3 p-0.5 rounded-full ${
          isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-200'
        }`}
      >
        <XMarkIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} />
      </button>
    </div>
  </div>
);

// Add this new component for the mobile menu
const MobileMenu = ({ isDarkMode, onCreateNew }) => (
  <div className={`
    absolute right-2 top-12 z-50 mt-1 w-48 rounded-lg shadow-lg
    ${isDarkMode ? 'bg-[#1a1a1a] border border-[#333333]' : 'bg-white border border-gray-200'}
    py-1
  `}>
    <button
      onClick={onCreateNew}
      className={`w-full px-4 py-2 text-sm flex items-center gap-2
        ${isDarkMode 
          ? 'text-slate-200 hover:bg-[#252525]' 
          : 'text-gray-700 hover:bg-gray-100'}
      `}
    >
      <UserPlusIcon className="w-4 h-4" />
      New Persona
    </button>
  </div>
);

const PersonasPage = ({ 
  isPopup = false, 
  onSelectPersona, 
  onEditPersona, 
  externalSearchTerm = '',
  isInChatInterface = false
}) => {
  const [personas, setPersonas] = useState([]);
  const [filteredPersonas, setFilteredPersonas] = useState([]);
  const [searchTerm, setSearchTerm] = useState(externalSearchTerm);
  const [isInitialFetchDone, setIsInitialFetchDone] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [personaToDelete, setPersonaToDelete] = useState(null);
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [isRestoring, setIsRestoring] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const { user } = useAuth();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const addNotification = useCallback((title, message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, title, message, type }]);
    
    // Automatically remove the notification after 5 seconds
    setTimeout(() => {
      setNotifications(prev => prev.filter(notification => notification.id !== id));
    }, 5000);
  }, []);

  const fetchPersonas = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/api/personas');
      setPersonas(response.data);
    } catch (error) {
      console.error('Error fetching personas:', error);
      addNotification(
        'Error Loading Personas',
        'Failed to load personas. Please try refreshing the page.',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  }, [addNotification]);

  // Add subscription status check
  const fetchSubscriptionStatus = useCallback(async () => {
    try {
      const status = await SubscriptionService.getSubscriptionStatus();
      setSubscriptionStatus(status);
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      addNotification(
        'Error',
        'Failed to verify subscription status',
        'error'
      );
    }
  }, [addNotification]);

  // Add subscription check to initial useEffect
  useEffect(() => {
    if (!isInitialFetchDone) {
      Promise.all([
        fetchPersonas(),
        fetchSubscriptionStatus()
      ]).then(() => setIsInitialFetchDone(true));
    }
  }, [fetchPersonas, fetchSubscriptionStatus, isInitialFetchDone]);

  // Update filteredPersonas when searchTerm or personas change
  useEffect(() => {
    if (personas.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = personas.filter(persona =>
        persona.name.toLowerCase().includes(lowercasedFilter) ||
        persona.occupation.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredPersonas(filtered);
    }
  }, [searchTerm, personas]);

  // Update internal searchTerm when externalSearchTerm changes (for popup mode)
  useEffect(() => {
    if (isPopup) {
      setSearchTerm(externalSearchTerm);
    }
  }, [externalSearchTerm, isPopup]);

  const handleEdit = (persona) => {
    console.log('PersonasPage: handleEdit', { isInChatInterface, persona });
    if (isInChatInterface && onEditPersona) {
      onEditPersona(persona);
    } else {
      navigate(`/personas/edit/${persona._id}`);
    }
  };

  const handleDeleteClick = (personaId) => {
    setPersonaToDelete(personaId);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (personaToDelete) {
      setIsDeleting(true);
      try {
        await api.delete(`/api/personas/${personaToDelete}`);
        const updatedPersonas = personas.filter(persona => persona._id !== personaToDelete);
        setPersonas(updatedPersonas);
        // Update filtered personas immediately
        const lowercasedFilter = searchTerm.toLowerCase();
        const updatedFiltered = updatedPersonas.filter(persona =>
          persona.name.toLowerCase().includes(lowercasedFilter) ||
          persona.occupation.toLowerCase().includes(lowercasedFilter)
        );
        setFilteredPersonas(updatedFiltered);
        setShowDeleteModal(false);
        setPersonaToDelete(null);
        addNotification('Success', 'Persona deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting persona:', error);
        addNotification('Delete Failed', 'Failed to delete persona. Please try again.', 'error');
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const cancelDelete = () => {
    setShowDeleteModal(false);
    setPersonaToDelete(null);
  };

  const handlePersonaClick = (persona) => {
    if (isPopup && onSelectPersona) {
      onSelectPersona(persona);
    } else {
      setSelectedPersona(persona); // Open details popup for non-popup mode
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen) {
      setSearchTerm('');
    }
  };

  // Add this new function to handle the create button click
  const handleCreateClick = () => {
    if (!checkPersonaLimit()) {
      return;
    }
    if (isPopup) {
      // Handle creation in popup mode (if needed)
    } else {
      navigate('/personas/create');
    }
  };

  const handleRestoreDefaults = async () => {
    try {
      setIsRestoring(true);
      const response = await api.post('/api/personas/defaults/restore');
      if (response.data) {
        await fetchPersonas(); // Refresh the list after restore
        addNotification(
          'Defaults Restored',
          'Default personas have been restored successfully',
          'success'
        );
      }
    } catch (error) {
      console.error('Error restoring default personas:', error);
      addNotification(
        'Restore Failed',
        'Failed to restore default personas',
        'error'
      );
    } finally {
      setIsRestoring(false);
    }
  };

  // Modify checkPersonaLimit to use subscription status
  const checkPersonaLimit = () => {
    const standardPlanLimit = 3;
    const isPro = subscriptionStatus?.subscription?.plan === 'pro';
    
    // If they're on pro, no limit
    if (isPro) {
      return true;
    }

    // If they're on standard or no subscription, check limit
    if (personas.length >= standardPlanLimit) {
      setShowUpgradePopup(true);
      return false;
    }

    return true;
  };

  // Get upgrade features with pricing from subscription service
  const getUpgradeFeatures = () => {
    const plans = SubscriptionService.getSubscriptionPlans();
    const proPlan = plans.PRO;

    return [
      `${proPlan.features[0]} (Currently limited to 3)`, // AI Credits feature
      proPlan.features[1], // Brand Personas
      proPlan.features[2], // Target Audience Personas
      proPlan.features[3], // Custom AI Templates
      proPlan.features[13], // Priority Support
    ];
  };

  return (
    <>
      {!isPopup && (
        <Helmet>
          <title>Brand Personas | Ammmplify</title>
          <meta name="description" content="Create and manage AI-powered brand personas to enhance your content creation. Customize personalities, communication styles, and expertise levels." />
          <meta name="keywords" content="brand personas, AI personas, content creation, brand voice, communication style" />
          <meta property="og:title" content="Brand Personas | Ammmplify" />
          <meta property="og:description" content="Create and manage AI-powered brand personas to enhance your content creation. Customize personalities, communication styles, and expertise levels." />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Brand Personas | Ammmplify" />
          <meta name="twitter:description" content="Create and manage AI-powered brand personas to enhance your content creation. Customize personalities, communication styles, and expertise levels." />
        </Helmet>
      )}
      <div className={`flex flex-col h-full relative overflow-hidden bg-transparent
                    ${isPopup ? 'max-h-96' : ''} 
                    min-w-[300px] w-full max-w-full
                    mx-auto transition-all duration-300 ease-in-out`}>
        {/* Notifications */}
        <div className="fixed top-4 right-4 z-50">
          {notifications.map(notification => (
            <Notification
              key={notification.id}
              title={notification.title}
              message={notification.message}
              type={notification.type}
              onClose={() => setNotifications(prev => prev.filter(n => n.id !== notification.id))}
            />
          ))}
        </div>

        {!isPopup && (
          <>
            {/* Mobile Search Bar */}
            {isSearchOpen && (
              <MobileSearchBar
                isDarkMode={isDarkMode}
                searchTerm={searchTerm}
                onSearchChange={handleSearchChange}
                onClose={toggleSearch}
              />
            )}

            {/* Top Bar - Desktop and Mobile */}
            <div className={`
              ${isDarkMode ? 'bg-[#121212]/95 border-[#333333]' : 'bg-white bg-opacity-90 border-gray-200'} 
              !h-10 min-h-10 flex-shrink-0 flex items-center justify-between 
              px-3 sm:px-4 py-2 w-full border-b relative z-40
            `}>
              {/* Left section */}
              <div className="flex items-center">
                <UserIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
                <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
                  Brand Personas
                </h2>
              </div>

              {/* Right section - Desktop */}
              <div className="hidden sm:flex items-center space-x-2">
                {isSearchOpen ? (
                  <div className="relative">
                    <input
                      type="text"
                      className={`w-48 px-3 py-1 text-sm border rounded-md pl-8 
                        focus:outline-none focus:ring-2 focus:ring-blue-500
                        ${isDarkMode
                          ? 'bg-[#151515] border-[#333333] text-slate-200 placeholder-slate-400'
                          : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                        }`}
                      placeholder="Search personas..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <MagnifyingGlassIcon className={`absolute left-2 top-1.5 h-4 w-4 ${
                      isDarkMode ? 'text-slate-400' : 'text-gray-400'
                    }`} />
                    <button
                      onClick={toggleSearch}
                      className={`absolute right-2 top-1.5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`}
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={toggleSearch}
                    className={`p-1 rounded-md ${
                      isDarkMode ? 'hover:bg-slate-700' : 'hover:bg-gray-100'
                    }`}
                  >
                    <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                  </button>
                )}

                <button 
                  onClick={() => navigate('/personas/create')}
                  className={`flex items-center px-3 py-2 rounded-full transition-colors duration-200 text-xs
                              ${isDarkMode 
                                ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200' 
                                : 'bg-black hover:bg-black/90 text-white'}`}
                  title="Create New Persona"
                >
                  <UserPlusIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-400' : 'text-white'} mr-1`} />
                  Create New Persona
                </button>
              </div>

              {/* Right section - Mobile */}
              <div className="flex sm:hidden items-center space-x-2">
                <button
                  onClick={toggleSearch}
                  className={`p-1.5 rounded-md ${
                    isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-100'
                  }`}
                >
                  <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                </button>
              
                <button
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                  className={`p-1.5 rounded-md ${
                    isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-100'
                  }`}
                >
                  <EllipsisVerticalIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`} />
                </button>

                {showMobileMenu && (
                  <MobileMenu
                    isDarkMode={isDarkMode}
                    onCreateNew={() => {
                      setShowMobileMenu(false);
                      navigate('/personas/create');
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}

        {/* Content Area */}
        <div className={`
          flex-grow overflow-y-auto custom-scrollbar 
          ${isDarkMode ? 'bg-[#121212]' : 'bg-white bg-opacity-80'}
          ${!isPopup && 'px-4 py-4'} // Add padding when not in popup mode
        `}>
          {isLoading ? (
            <GridSkeleton count={6} />
          ) : filteredPersonas.length > 0 ? (
            <div className={`
              grid grid-cols-1 md:grid-cols-2 gap-4
              ${isPopup ? 'px-2 pb-4' : 'max-w-3xl mx-auto pb-20'}
            `}>
              {filteredPersonas.map((persona) => (
                isInChatInterface ? (
                  <CompactPersonaCard
                    key={persona._id}
                    persona={persona}
                    onPersonaClick={handlePersonaClick}
                    isSelected={selectedPersona?._id === persona._id}
                    isInChatInterface={isInChatInterface}
                    onEditInChat={() => handleEdit(persona)}
                  />
                ) : (
                  <PersonaCard
                    key={persona._id}
                    persona={persona}
                    isPopup={isPopup}
                    onPersonaClick={handlePersonaClick}
                    onEdit={() => handleEdit(persona)}
                    onDelete={handleDeleteClick}
                  />
                )
              ))}
            </div>
          ) : (
            <div className="h-full flex items-center justify-center py-4">
              <NoResultsMessage 
                searchTerm={searchTerm}
                onCreateClick={handleCreateClick}
                onAddExamples={handleRestoreDefaults}
                isDarkMode={isDarkMode}
              />
            </div>
          )}
        </div>

        {/* Delete Confirmation Modal */}
        {showDeleteModal && (
          <div className={`fixed inset-0 flex items-center justify-center p-4 z-50 ${
            isDarkMode ? 'bg-black/30 backdrop-blur-sm' : 'bg-white/30 backdrop-blur-sm'
          }`}>
            <div className={`relative w-full max-w-sm rounded-lg border transition-all
                            ${isDarkMode 
                              ? 'bg-[#121212] border-[#333333]' 
                              : 'bg-white border-zinc-200'}`}>
              <div className="p-6">
                <h3 className={`text-lg font-semibold leading-none tracking-tight mb-2
                              ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}`}>
                  Delete Persona
                </h3>
                <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
                  Are you sure? This action cannot be undone.
                </p>
                
                <div className="flex justify-end gap-3 mt-6">
                  <button
                    onClick={cancelDelete}
                    disabled={isDeleting}
                    className={`px-4 py-2 rounded-md text-sm font-medium
                              transition-colors
                              ${isDarkMode 
                                ? 'bg-[#1a1a1a] hover:bg-[#222] text-slate-300 border border-[#333333]' 
                                : 'bg-white hover:bg-zinc-50 text-slate-900 border border-zinc-200'}
                              ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={confirmDelete}
                    disabled={isDeleting}
                    className={`px-4 py-2 rounded-md text-sm font-medium text-white
                              transition-colors flex items-center gap-2
                              bg-red-600 hover:bg-red-700
                              ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {isDeleting ? (
                      <>
                        <ArrowPathIcon className="w-4 h-4 animate-spin" />
                        Deleting...
                      </>
                    ) : (
                      'Delete'
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Move PersonaDetailsPopup here */}
        {selectedPersona && (
          <PersonaDetailsPopup
            persona={selectedPersona}
            onClose={() => setSelectedPersona(null)}
          />
        )}

        {/* Add Upgrade Popup */}
        {showUpgradePopup && (
          <UpgradePopup
            onClose={() => setShowUpgradePopup(false)}
            title="Upgrade to Create More Personas"
            description="You've reached the limit of 3 personas on the Standard plan. Upgrade to Pro to create unlimited personas and unlock more features!"
            features={getUpgradeFeatures()}
            currentUsage={personas.length}
            limit={3}
          />
        )}
      </div>
    </>
  );
};

export default PersonasPage;
