import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../context/ThemeContext';
import api from '../api';
import {
  ChartBarIcon,
  MicrophoneIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  ArrowTrendingUpIcon,
  CalendarIcon,
  ChatBubbleBottomCenterTextIcon,
  GlobeAltIcon
} from '@heroicons/react/24/outline';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  Filler,
  TimeScale
} from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { Tab } from '@headlessui/react';
import { BeakerIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet-async';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  Filler,
  TimeScale
);

const CHART_COLORS = {
  voiceNotes: {
    base: '#3B82F6CC',          // Blue-500 with 80% opacity
    light: '#3B82F61A',         // Blue-500 with 10% opacity
    border: '#3B82F633',        // Blue-500 with 20% opacity
    gradient: ['#3B82F633', '#3B82F60D']  // Blue-500 with 20% and 5% opacity
  },
  textNotes: {
    base: '#10B981CC',          // Emerald-500 with 80% opacity
    light: '#10B9811A',         // Emerald-500 with 10% opacity
    border: '#10B98133',        // Emerald-500 with 20% opacity
    gradient: ['#10B98133', '#10B9810D']  // Emerald-500 with 20% and 5% opacity
  },
  postsCreated: {
    base: '#F472B6CC',          // Pink-400 with 80% opacity
    light: '#F472B61A',         // Pink-400 with 10% opacity
    border: '#F472B633',        // Pink-400 with 20% opacity
    gradient: ['#F472B633', '#F472B60D']  // Pink-400 with 20% and 5% opacity
  },
  background: {
    grid: {
      dark: '#FFFFFF08',        // White with 3% opacity
      light: '#00000008'        // Black with 3% opacity
    }
  }
};

const AnalyticsCard = ({ title, total, lastWeek, lastMonth, icon: Icon, color }) => {
  const { isDarkMode } = useTheme();
  
  const calculateTrend = () => {
    if (lastWeek === 0 && lastMonth === 0) {
      return { value: 0, label: "No activity yet" };
    }

    if (lastMonth === 0 && lastWeek > 0) {
      return { value: 100, label: "New activity" };
    }

    const weeklyAvg = lastWeek / 7;
    const monthlyAvg = lastMonth / 30;

    if (monthlyAvg === 0) {
      return { value: 100, label: "New activity" };
    }

    const percentChange = ((weeklyAvg - monthlyAvg) / monthlyAvg * 100);

    if (percentChange === 0) {
      return { value: 0, label: "No change" };
    }

    return {
      value: percentChange,
      label: `${Math.abs(percentChange).toFixed(0)}% ${percentChange > 0 ? 'increase' : 'decrease'}`
    };
  };

  const trend = calculateTrend();
  const isPositiveTrend = trend.value > 0;
  
  return (
    <div className={`
      group relative rounded-lg p-6 transition-all duration-200
      ${isDarkMode 
        ? 'bg-[#141414]/50 ring-1 ring-[#232323] hover:ring-[#333333]' 
        : 'bg-white/30 border border-slate-200/40 hover:border-slate-300/60'}
      hover:shadow-sm
    `}>
      <div className="flex items-center justify-between space-x-2">
        <h3 className={`text-sm font-medium leading-none tracking-tight ${
          isDarkMode ? 'text-slate-200' : 'text-slate-900'
        }`}>
          {title}
        </h3>
        <Icon className={`h-4 w-4 ${color} opacity-70`} />
      </div>
      <p className={`text-2xl font-bold mt-4 ${
        isDarkMode ? 'text-slate-200' : 'text-slate-900'
      }`}>
        {total}
      </p>
      
      <div className="mt-4 space-y-2">
        <div className="flex items-center justify-between text-xs">
          <span className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>Last 7 days</span>
          <span className={`font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>
            {lastWeek}
          </span>
        </div>
        <div className="flex items-center justify-between text-xs">
          <span className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>Last 30 days</span>
          <span className={`font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>
            {lastMonth}
          </span>
        </div>
        
        {/* Trend indicator */}
        <div className={`
          inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset mt-2
          ${trend.value === 0 
            ? isDarkMode 
              ? 'bg-[#181818] text-slate-400 ring-[#232323]' 
              : 'bg-slate-100/40 text-slate-600 ring-slate-200'
            : isPositiveTrend
              ? 'bg-emerald-500/10 text-emerald-400 ring-emerald-500/20'
              : 'bg-rose-500/10 text-rose-400 ring-rose-500/20'
          }
        `}>
          {trend.value !== 0 && (
            <ArrowTrendingUpIcon 
              className={`w-3 h-3 mr-1 ${!isPositiveTrend && 'transform rotate-180'}`}
            />
          )}
          {trend.label}
        </div>
      </div>
    </div>
  );
};

// Add this wrapper component
const ChartWrapper = ({ children }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  if (!isMounted) {
    return null;
  }

  return children;
};

// Update the ActivityChart component
const ActivityChart = ({ data, isDarkMode }) => {
  const chartData = {
    labels: ['Voice Notes', 'Text Notes', 'Posts Created'],
    datasets: [
      {
        label: 'Last Week',
        data: [
          data.voiceNotes.lastWeek,
          data.textNotes.lastWeek,
          data.canvasPosts.lastWeek
        ],
        backgroundColor: [
          CHART_COLORS.voiceNotes.light,
          CHART_COLORS.textNotes.light,
          CHART_COLORS.postsCreated.light
        ],
        borderColor: [
          CHART_COLORS.voiceNotes.border,
          CHART_COLORS.textNotes.border,
          CHART_COLORS.postsCreated.border
        ],
        borderWidth: 1,
        borderRadius: 4
      },
      {
        label: 'Last Month',
        data: [
          data.voiceNotes.lastMonth,
          data.textNotes.lastMonth,
          data.canvasPosts.lastMonth
        ],
        backgroundColor: [
          CHART_COLORS.voiceNotes.base,
          CHART_COLORS.textNotes.base,
          CHART_COLORS.postsCreated.base
        ],
        borderColor: [
          CHART_COLORS.voiceNotes.border,
          CHART_COLORS.textNotes.border,
          CHART_COLORS.postsCreated.border
        ],
        borderWidth: 1,
        borderRadius: 4
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          color: isDarkMode ? '#e2e8f0' : '#1e293b',
          font: {
            family: 'Inter var, system-ui, sans-serif'
          }
        }
      },
      tooltip: {
        backgroundColor: isDarkMode ? '#141414' : 'rgba(255, 255, 255, 0.9)',
        titleColor: isDarkMode ? '#e2e8f0' : '#000',
        bodyColor: isDarkMode ? '#94a3b8' : 'rgba(0, 0, 0, 0.8)',
        padding: 12,
        cornerRadius: 8,
        displayColors: true,
        borderColor: isDarkMode ? '#232323' : 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.parsed.y.toLocaleString()}`
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: isDarkMode ? '#232323' : 'rgba(0, 0, 0, 0.1)',
          drawBorder: false
        },
        ticks: {
          color: isDarkMode ? '#94a3b8' : '#475569',
          font: {
            family: 'Inter var, system-ui, sans-serif'
          }
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: isDarkMode ? '#94a3b8' : '#475569',
          font: {
            family: 'Inter var, system-ui, sans-serif'
          }
        }
      }
    }
  };

  return (
    <div className={`p-6 rounded-lg ${
      isDarkMode 
        ? 'bg-[#141414]/50 ring-1 ring-[#232323]' 
        : 'bg-white border border-slate-200'
    }`}>
      <h3 className={`text-lg font-semibold mb-4 ${
        isDarkMode ? 'text-slate-200' : 'text-slate-800'
      }`}>
        Activity Comparison
      </h3>
      <ChartWrapper>
        <Bar data={chartData} options={options} />
      </ChartWrapper>
    </div>
  );
};

// Update the DistributionChart component
const DistributionChart = ({ data, isDarkMode }) => {
  const total = data.voiceNotes.total + data.textNotes.total + data.canvasPosts.total;
  
  const chartData = {
    labels: ['Voice Notes', 'Text Notes', 'Posts Created'],
    datasets: [{
      data: [
        data.voiceNotes.total,
        data.textNotes.total,
        data.canvasPosts.total
      ],
      backgroundColor: [
        CHART_COLORS.voiceNotes.base,
        CHART_COLORS.textNotes.base,
        CHART_COLORS.postsCreated.base
      ],
      hoverOffset: 8
    }]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          color: isDarkMode ? '#e2e8f0' : '#1e293b',
          font: {
            family: 'Inter var, system-ui, sans-serif',
            size: 11
          }
        }
      },
      tooltip: {
        backgroundColor: isDarkMode ? '#141414' : 'rgba(255, 255, 255, 0.9)',
        titleColor: isDarkMode ? '#e2e8f0' : '#000',
        bodyColor: isDarkMode ? '#94a3b8' : 'rgba(0, 0, 0, 0.8)',
        padding: 12,
        cornerRadius: 8,
        borderColor: isDarkMode ? '#232323' : 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${value.toLocaleString()} (${percentage}%)`;
          }
        }
      }
    }
  };

  return (
    <div className={`p-6 rounded-lg ${
      isDarkMode 
        ? 'bg-[#141414]/50 ring-1 ring-[#232323]' 
        : 'bg-white border border-slate-200'
    }`}>
      <h3 className={`text-lg font-semibold mb-4 ${
        isDarkMode ? 'text-slate-200' : 'text-slate-800'
      }`}>
        Content Distribution
      </h3>
      <div className="h-[300px] flex items-center justify-center">
        <ChartWrapper>
          <Doughnut data={chartData} options={options} />
        </ChartWrapper>
      </div>
    </div>
  );
};

// Update the TrendChart component
const TrendChart = ({ data, isDarkMode }) => {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chart]);

  // Generate daily data points based on actual user activity
  const generateDailyData = (total, lastWeek, lastMonth) => {
    const dates = Array.from({ length: 30 }, (_, i) => {
      const date = new Date();
      date.setDate(date.getDate() - (29 - i));
      return date;
    });

    // Calculate average daily values
    const recentDailyAvg = lastWeek / 7;
    const olderDailyAvg = (lastMonth - lastWeek) / 23; // (30 - 7) days

    return dates.map((date, index) => ({
      date,
      // For the last 7 days, use the weekly average
      // For earlier days, use the monthly average
      value: index >= 23 
        ? Math.round(recentDailyAvg * (index - 22)) // Cumulative for last 7 days
        : Math.round(olderDailyAvg * (index + 1))   // Cumulative for earlier days
    }));
  };

  const chartData = {
    labels: generateDailyData(0, 0, 0).map(item => item.date),
    datasets: [
      {
        label: 'Voice Notes',
        data: generateDailyData(
          data.voiceNotes.total,
          data.voiceNotes.lastWeek,
          data.voiceNotes.lastMonth
        ).map(item => ({
          x: item.date,
          y: item.value
        })),
        borderColor: CHART_COLORS.voiceNotes.base,
        backgroundColor: CHART_COLORS.voiceNotes.gradient[0],
        fill: {
          target: 'origin',
          above: CHART_COLORS.voiceNotes.gradient[0]
        },
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: CHART_COLORS.voiceNotes.base,
        pointHoverBorderColor: isDarkMode ? '#fff' : '#000',
        pointHoverBorderWidth: 2
      },
      {
        label: 'Text Notes',
        data: generateDailyData(
          data.textNotes.total,
          data.textNotes.lastWeek,
          data.textNotes.lastMonth
        ).map(item => ({
          x: item.date,
          y: item.value
        })),
        borderColor: CHART_COLORS.textNotes.base,
        backgroundColor: CHART_COLORS.textNotes.gradient[0],
        fill: {
          target: 'origin',
          above: CHART_COLORS.textNotes.gradient[0]
        },
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: CHART_COLORS.textNotes.base,
        pointHoverBorderColor: isDarkMode ? '#fff' : '#000',
        pointHoverBorderWidth: 2
      },
      {
        label: 'Posts Created',
        data: generateDailyData(
          data.canvasPosts.total,
          data.canvasPosts.lastWeek,
          data.canvasPosts.lastMonth
        ).map(item => ({
          x: item.date,
          y: item.value
        })),
        borderColor: CHART_COLORS.postsCreated.base,
        backgroundColor: CHART_COLORS.postsCreated.gradient[0],
        fill: {
          target: 'origin',
          above: CHART_COLORS.postsCreated.gradient[0]
        },
        tension: 0.4,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: CHART_COLORS.postsCreated.base,
        pointHoverBorderColor: isDarkMode ? '#fff' : '#000',
        pointHoverBorderWidth: 2
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            family: 'Inter var, system-ui, sans-serif',
            size: 11,
            weight: '500'
          },
          color: isDarkMode ? '#e2e8f0' : '#1e293b'
        }
      },
      tooltip: {
        backgroundColor: isDarkMode ? 'rgba(15, 23, 42, 0.9)' : 'rgba(255, 255, 255, 0.9)',
        titleColor: isDarkMode ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)',
        bodyColor: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
        padding: 12,
        boxPadding: 6,
        cornerRadius: 8,
        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1,
        displayColors: true,
        callbacks: {
          title: (tooltipItems) => {
            return new Date(tooltipItems[0].parsed.x).toLocaleDateString('en-US', {
              weekday: 'short',
              month: 'short',
              day: 'numeric'
            });
          },
          label: (context) => {
            const value = context.parsed.y;
            return `${context.dataset.label}: ${value.toLocaleString()}`;
          }
        }
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM d'
          },
          tooltipFormat: 'PPP' // More detailed date in tooltip
        },
        grid: {
          display: false
        },
        ticks: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
          padding: 8,
          font: {
            size: 11
          },
          maxRotation: 0
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          color: isDarkMode 
            ? CHART_COLORS.background.grid.dark
            : CHART_COLORS.background.grid.light,
          drawBorder: false
        },
        ticks: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
          padding: 8,
          font: {
            size: 11
          },
          callback: (value) => Math.round(value)
        }
      }
    }
  };

  // Calculate growth metrics for current user
  const calculateGrowth = (current, previous) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  };

  const growthMetrics = {
    voiceNotes: calculateGrowth(data.voiceNotes.lastWeek, data.voiceNotes.lastMonth / 4),
    textNotes: calculateGrowth(data.textNotes.lastWeek, data.textNotes.lastMonth / 4),
    posts: calculateGrowth(data.canvasPosts.lastWeek, data.canvasPosts.lastMonth / 4)
  };

  const formatGrowthLabel = (key) => {
    const labels = {
      voiceNotes: 'Voice Notes',
      textNotes: 'Text Notes',
      posts: 'Posts'
    };
    return labels[key];
  };

  return (
    <div className={`p-6 rounded-lg ${
      isDarkMode 
        ? 'bg-[#141414]/50 ring-1 ring-[#232323]' 
        : 'bg-white border border-slate-200'
    }`}>
      <div className="flex items-center justify-between mb-6">
        <h3 className={`text-lg font-semibold ${
          isDarkMode ? 'text-slate-200' : 'text-slate-800'
        }`}>
          Your Content Growth
        </h3>
        <div className="flex gap-4 text-xs">
          {Object.entries(growthMetrics).map(([key, value]) => (
            <div key={key} className="flex flex-col items-end">
              <span className={`text-xs ${
                isDarkMode ? 'text-slate-400' : 'text-slate-600'
              }`}>
                {formatGrowthLabel(key)}
              </span>
              <div className={`flex items-center ${
                value > 0 
                  ? isDarkMode ? 'text-emerald-400' : 'text-emerald-500'
                  : value < 0 
                    ? isDarkMode ? 'text-rose-400' : 'text-rose-500'
                    : isDarkMode ? 'text-slate-400' : 'text-slate-500'
              }`}>
                <ArrowTrendingUpIcon 
                  className={`w-3 h-3 mr-1 ${value < 0 && 'transform rotate-180'}`}
                />
                <span>{value === 0 ? '-' : `${Math.abs(value).toFixed(1)}%`}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[400px]">
        <ChartWrapper>
          <Line data={chartData} options={options} />
        </ChartWrapper>
      </div>
    </div>
  );
};

// Update the TopBar component to be more mobile-friendly
const TopBar = ({ lastUpdated, isDarkMode }) => (
  <div className={`
    ${isDarkMode 
      ? 'bg-[#141414]/80 border-[#232323] backdrop-blur supports-[backdrop-filter]:bg-opacity-80' 
      : 'bg-white/95 border-slate-200/40'
    } 
    sticky top-0 z-40 flex-shrink-0 flex items-center justify-between 
    px-3 sm:px-4 py-3 sm:py-2 w-full border-b transition-colors duration-200
  `}>
    <div className="flex items-center gap-2">
      <ChartBarIcon className={`h-4 w-4 ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`} />
      <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-slate-800'}`}>
        Analytics
      </h2>
    </div>

    {/* Hide last updated text on mobile, show only icon and date */}
    <div className="flex items-center gap-1.5 sm:gap-2">
      <CalendarIcon className={`h-3.5 w-3.5 ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`} />
      <span className={`text-xs truncate ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
        <span className="hidden sm:inline">Last updated: </span>
        {new Date(lastUpdated).toLocaleString(undefined, {
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })}
      </span>
    </div>
  </div>
);

// Update the AnalyticsTabs component for better mobile display
const AnalyticsTabs = ({ isDarkMode, children }) => (
  <Tab.Group>
    <div className="px-3 sm:px-6 pt-4 sm:pt-6">
      <div className={`
        inline-flex rounded-full p-1 w-full sm:w-auto
        ${isDarkMode 
          ? 'bg-[#151515] border border-[#333333]' 
          : 'bg-gray-50/60 border border-gray-200/40'
        }
      `}>
        {['User Activity', 'Social Analytics'].map((category) => (
          <Tab
            key={category}
            className={({ selected }) => `
              flex-1 sm:flex-none px-3 sm:px-4 py-2 rounded-full text-xs font-medium
              transition-all duration-300 ease-out
              flex items-center justify-center sm:justify-start gap-2
              ${selected
                ? isDarkMode
                  ? 'bg-indigo-600/20 text-indigo-300 border border-indigo-500/30' 
                  : 'bg-white text-indigo-600 shadow-md'
                : isDarkMode
                  ? 'text-slate-400 hover:text-slate-300 hover:bg-[#1e1e1e]/50' 
                  : 'text-gray-600 hover:text-gray-800'
              }
            `}
          >
            {category === 'User Activity' ? (
              <>
                <ChartBarIcon className="w-4 h-4" />
                <span className="hidden sm:inline">{category}</span>
                <span className="sm:hidden">Activity</span>
              </>
            ) : (
              <>
                <GlobeAltIcon className="w-4 h-4" />
                <span className="hidden sm:inline">{category}</span>
                <span className="sm:hidden">Social</span>
              </>
            )}
          </Tab>
        ))}
      </div>
    </div>
    <Tab.Panels className="mt-2">
      <Tab.Panel>{children}</Tab.Panel>
      <Tab.Panel>
        <ComingSoonState isDarkMode={isDarkMode} />
      </Tab.Panel>
    </Tab.Panels>
  </Tab.Group>
);

// Update the ComingSoonState to match the new style
const ComingSoonState = ({ isDarkMode }) => (
  <div className={`
    flex flex-col items-center justify-center h-[calc(100vh-12rem)] text-center
    mx-auto max-w-md p-8 rounded-xl
    ${isDarkMode 
      ? 'bg-[#151515]/40 border border-[#333333] shadow-[0_2px_8px_0_rgba(0,0,0,0.08)]' 
      : 'bg-white/90 border border-gray-200/40 shadow-[0_2px_8px_0_rgba(0,0,0,0.04)]'
    }
  `}>
    <div className={`
      p-4 rounded-full mb-6
      ${isDarkMode 
        ? 'bg-indigo-500/10 ring-1 ring-indigo-500/30' 
        : 'bg-indigo-50 ring-1 ring-indigo-500/20'
      }
    `}>
      <BeakerIcon className={`w-8 h-8 ${
        isDarkMode ? 'text-indigo-400' : 'text-indigo-600'
      }`} />
    </div>
    <h3 className={`text-xl font-semibold mb-3 ${
      isDarkMode ? 'text-slate-200' : 'text-slate-900'
    }`}>
      Social Analytics Coming Soon
    </h3>
    <p className={`text-sm leading-relaxed ${
      isDarkMode ? 'text-slate-400' : 'text-slate-600'
    }`}>
      We're brewing up something special! Soon you'll be able to track your social media performance 
      and engagement metrics right here.
    </p>
  </div>
);

// Update AnalyticsLoading component
const AnalyticsLoading = ({ isDarkMode }) => {
  const shimmerClass = isDarkMode 
    ? 'animate-pulse bg-[#181818]'
    : 'animate-pulse bg-slate-200';

  return (
    <div className="p-6 space-y-6">
      {/* Analytics Cards Loading */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {[...Array(4)].map((_, i) => (
          <div 
            key={i} 
            className={`rounded-lg p-6 ${
              isDarkMode 
                ? 'bg-[#141414]/50 ring-1 ring-[#232323]' 
                : 'bg-white border border-slate-200'
            }`}
          >
            <div className="flex items-center justify-between">
              <div className={`h-4 w-24 rounded ${shimmerClass}`} />
              <div className={`h-4 w-4 rounded ${shimmerClass}`} />
            </div>
            <div className={`h-8 w-16 rounded mt-4 ${shimmerClass}`} />
            <div className="space-y-2 mt-4">
              <div className={`h-3 w-full rounded ${shimmerClass}`} />
              <div className={`h-3 w-full rounded ${shimmerClass}`} />
            </div>
          </div>
        ))}
      </div>

      {/* Charts Loading */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {[...Array(2)].map((_, i) => (
          <div 
            key={i}
            className={`rounded-lg p-6 ${
              isDarkMode 
                ? 'bg-[#141414]/50 ring-1 ring-[#232323]' 
                : 'bg-white border border-slate-200'
            }`}
          >
            <div className={`h-5 w-40 mb-8 rounded ${shimmerClass}`} />
            <div className={`h-[300px] rounded ${shimmerClass} opacity-30`} />
          </div>
        ))}
      </div>

      {/* Trend Chart Loading */}
      <div 
        className={`rounded-lg p-6 ${
          isDarkMode 
            ? 'bg-[#141414]/50 ring-1 ring-[#232323]' 
            : 'bg-white border border-slate-200'
        }`}
      >
        <div className="flex items-center justify-between mb-8">
          <div className={`h-5 w-48 rounded ${shimmerClass}`} />
          <div className="flex gap-4">
            {[...Array(3)].map((_, i) => (
              <div key={i} className={`h-4 w-20 rounded ${shimmerClass}`} />
            ))}
          </div>
        </div>
        <div className={`h-[400px] rounded ${shimmerClass} opacity-30`} />
      </div>
    </div>
  );
};

const UserAnalytics = () => {
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const data = await api.getUserAnalytics();
        setAnalytics(data);
      } catch (err) {
        setError('Failed to load analytics');
        console.error('Error fetching analytics:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, []);

  return (
    <>
      <Helmet>
        <title>Analytics Dashboard | Ammmplify</title>
        <meta name="description" content="Track your content creation metrics, analyze performance trends, and gain insights into your voice notes, text notes, and social media posts." />
        <meta name="keywords" content="analytics dashboard, content metrics, performance tracking, social media analytics, content insights" />
        <meta property="og:title" content="Analytics Dashboard | Ammmplify" />
        <meta property="og:description" content="Track your content creation metrics, analyze performance trends, and gain insights into your voice notes, text notes, and social media posts." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Analytics Dashboard | Ammmplify" />
        <meta name="twitter:description" content="Track your content creation metrics, analyze performance trends, and gain insights into your voice notes, text notes, and social media posts." />
      </Helmet>

      <div className={`
        flex flex-col h-full relative overflow-hidden
        ${isDarkMode 
          ? 'bg-gradient-to-b from-[#121212] to-[#141414]' 
          : 'bg-gradient-to-b from-white to-gray-50'
        }
      `}>
        {analytics && <TopBar lastUpdated={analytics.lastUpdated} isDarkMode={isDarkMode} />}

        <div className="flex-grow overflow-y-auto custom-scrollbar">
          {loading ? (
            <AnalyticsTabs isDarkMode={isDarkMode}>
              <AnalyticsLoading isDarkMode={isDarkMode} />
            </AnalyticsTabs>
          ) : error ? (
            <div className={`p-4 text-center ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}`}>
              <p>{error}</p>
            </div>
          ) : (
            <AnalyticsTabs isDarkMode={isDarkMode}>
              <div className="p-3 sm:p-6 space-y-4 sm:space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-6">
                  <AnalyticsCard
                    title="Words Generated"
                    total={analytics.wordCounts?.total || 0}
                    lastWeek={analytics.wordCounts?.lastWeek || 0}
                    lastMonth={analytics.wordCounts?.lastMonth || 0}
                    icon={ChatBubbleBottomCenterTextIcon}
                    color={isDarkMode ? 'text-amber-400' : 'text-amber-500'}
                  />
                  
                  <AnalyticsCard
                    title="Voice Notes"
                    total={analytics.voiceNotes.total}
                    lastWeek={analytics.voiceNotes.lastWeek}
                    lastMonth={analytics.voiceNotes.lastMonth}
                    icon={MicrophoneIcon}
                    color={isDarkMode ? 'text-blue-400' : 'text-blue-500'}
                  />
                  
                  <AnalyticsCard
                    title="Text Notes"
                    total={analytics.textNotes.total}
                    lastWeek={analytics.textNotes.lastWeek}
                    lastMonth={analytics.textNotes.lastMonth}
                    icon={DocumentTextIcon}
                    color={isDarkMode ? 'text-green-400' : 'text-green-500'}
                  />

                  <AnalyticsCard
                    title="Posts Created"
                    total={analytics.canvasPosts.total}
                    lastWeek={analytics.canvasPosts.lastWeek}
                    lastMonth={analytics.canvasPosts.lastMonth}
                    icon={PencilSquareIcon}
                    color={isDarkMode ? 'text-purple-400' : 'text-purple-500'}
                  />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 sm:gap-6">
                  <ActivityChart data={analytics} isDarkMode={isDarkMode} />
                  <DistributionChart data={analytics} isDarkMode={isDarkMode} />
                </div>

                <div className="w-full">
                  <TrendChart data={analytics} isDarkMode={isDarkMode} />
                </div>
              </div>
            </AnalyticsTabs>
          )}
        </div>
      </div>
    </>
  );
};

export default UserAnalytics; 