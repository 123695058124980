import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { 
  XMarkIcon, 
  FolderIcon,
  MagnifyingGlassIcon,
  CheckIcon,
  ChevronDownIcon,
  SparklesIcon,
  PlusIcon
} from '@heroicons/react/24/outline';
import { fetchSavedIdeas, getClientFolders } from '../../api';
import toast from 'react-hot-toast';
import ReactMarkdown from 'react-markdown';
import { createPortal } from 'react-dom';
import SkeletonLoader from '../common/SkeletonLoader';

const SavedIdeaSelector = ({ onSelect, onClose }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [ideas, setIdeas] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Load ideas and folders
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [ideasData, foldersData] = await Promise.all([
          fetchSavedIdeas(),
          getClientFolders()
        ]);

        // Add folder details to ideas
        const ideasWithFolderDetails = ideasData.map(idea => ({
          ...idea,
          clientFolderDetails: idea.clientFolder 
            ? foldersData.find(f => f._id === idea.clientFolder)
            : null
        }));

        setIdeas(ideasWithFolderDetails);
        setFolders(foldersData);
      } catch (error) {
        console.error('Error loading data:', error);
        toast.error('Failed to load saved ideas');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  // Filter ideas based on search and selected folder
  const filteredIdeas = ideas.filter(idea => {
    const matchesSearch = idea.content.toLowerCase().includes(search.toLowerCase()) ||
      idea.details?.toLowerCase().includes(search.toLowerCase()) ||
      idea.category?.toLowerCase().includes(search.toLowerCase()) ||
      idea.tags?.some(tag => tag.toLowerCase().includes(search.toLowerCase()));

    const matchesFolder = selectedFolder === null || idea.clientFolder === selectedFolder;

    return matchesSearch && matchesFolder;
  });

  const toggleIdeaSelection = (ideaId) => {
    setSelectedIdeas(prev => {
      const isSelected = prev.includes(ideaId);
      if (isSelected) {
        return prev.filter(id => id !== ideaId);
      } else {
        return [...prev, ideaId];
      }
    });
  };

  const handleConfirmSelection = () => {
    const selectedContent = selectedIdeas
      .map(id => {
        const idea = ideas.find(i => i._id === id);
        return idea ? idea.content : '';
      })
      .filter(Boolean)
      .join('\n\n');

    if (selectedContent) {
      onSelect(selectedContent);
    }
  };

  const selectedFolderName = selectedFolder 
    ? folders.find(f => f._id === selectedFolder)?.name 
    : 'All Folders';

  // Update dropdown position when it opens
  useEffect(() => {
    if (isDropdownOpen && triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY + 4,
        left: rect.left + window.scrollX,
        width: rect.width
      });
    }
  }, [isDropdownOpen]);

  // Render dropdown in portal
  const renderDropdown = () => {
    if (!isDropdownOpen) return null;

    return createPortal(
      <div 
        style={{
          position: 'absolute',
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          width: Math.max(dropdownPosition.width, 160),
          zIndex: 50
        }}
        className="animate-in fade-in slide-in-from-top-1 duration-200"
      >
        <div className={`py-1 rounded-md border shadow-lg
          ${isDarkMode 
            ? 'bg-[#1a1a1a] border-[#2d2d2d]' 
            : 'bg-white border-gray-200'
          }`}
        >
          <button
            onClick={() => {
              setSelectedFolder(null);
              setIsDropdownOpen(false);
            }}
            className={`w-full text-left px-3 py-1.5 text-xs flex items-center gap-2
              ${isDarkMode 
                ? 'text-slate-200 hover:bg-[#202020]' 
                : 'text-gray-900 hover:bg-gray-50'
              }
              ${!selectedFolder ? (isDarkMode ? 'bg-[#202020]' : 'bg-gray-50') : ''}`}
          >
            <FolderIcon className={`w-3.5 h-3.5 
              ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`} 
            />
            <span>All Folders</span>
            {!selectedFolder && (
              <CheckIcon className="w-3.5 h-3.5 ml-auto text-blue-500" />
            )}
          </button>

          {folders.map(folder => (
            <button
              key={folder._id}
              onClick={() => {
                setSelectedFolder(folder._id);
                setIsDropdownOpen(false);
              }}
              className={`w-full text-left px-3 py-1.5 text-xs flex items-center gap-2
                ${isDarkMode 
                  ? 'text-slate-200 hover:bg-[#202020]' 
                  : 'text-gray-900 hover:bg-gray-50'
                }
                ${selectedFolder === folder._id ? (isDarkMode ? 'bg-[#202020]' : 'bg-gray-50') : ''}`}
            >
              <FolderIcon className={`w-3.5 h-3.5 
                ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`} 
              />
              <span className="truncate">{folder.name}</span>
              {selectedFolder === folder._id && (
                <CheckIcon className="w-3.5 h-3.5 ml-auto text-blue-500" />
              )}
            </button>
          ))}
        </div>
      </div>,
      document.body
    );
  };

  const handleCreateIdea = () => {
    navigate('/saved-ideas');
    onClose();
  };

  return (
    <div className={`flex flex-col h-full ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}`}>
      {/* Header */}
      <div className={`px-4 py-3 border-b ${
        isDarkMode ? 'border-[#1d1d1d]' : 'border-gray-100'
      }`}>
        <div className="flex items-center justify-between mb-3">
          <h2 className={`text-sm font-medium ${
            isDarkMode ? 'text-slate-200' : 'text-gray-700'
          }`}>
            Saved Ideas
          </h2>
          <button
            onClick={onClose}
            className={`rounded-md p-1 transition-colors hover:bg-opacity-80 ${
              isDarkMode 
                ? 'text-slate-400 hover:bg-[#1d1d1d]' 
                : 'text-gray-500 hover:bg-gray-100'
            }`}
          >
            <XMarkIcon className="w-4 h-4" />
          </button>
        </div>

        <div className="flex gap-2">
          {/* Search input */}
          <div className="relative flex-1">
            <div className={`absolute inset-y-0 left-0 flex items-center pl-2.5 pointer-events-none ${
              isDarkMode ? 'text-slate-500' : 'text-gray-400'
            }`}>
              <MagnifyingGlassIcon className="w-3.5 h-3.5" />
            </div>
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search ideas..."
              className={`w-full pl-8 pr-3 py-1 text-xs rounded-md border shadow-sm
                transition-colors duration-200
                ${isDarkMode 
                  ? 'bg-[#1a1a1a] border-[#2d2d2d] text-slate-200 placeholder-slate-500 focus:border-[#3d3d3d]' 
                  : 'bg-white border-gray-200 text-gray-900 placeholder-gray-400 focus:border-gray-300'
                }
                focus:outline-none`}
            />
          </div>

          {/* Custom Folder Dropdown */}
          <div className="relative" ref={dropdownRef}>
            <button
              ref={triggerRef}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className={`flex items-center gap-2 pl-2.5 pr-2 py-1 text-xs rounded-md border shadow-sm
                min-w-[120px] transition-colors duration-200
                ${isDarkMode 
                  ? 'bg-[#1a1a1a] border-[#2d2d2d] text-slate-200 hover:border-[#3d3d3d]' 
                  : 'bg-white border-gray-200 text-gray-900 hover:border-gray-300'
                }
                focus:outline-none`}
            >
              <div className="flex items-center gap-1.5 flex-1 min-w-0">
                <FolderIcon className={`w-3.5 h-3.5 flex-shrink-0 
                  ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`} 
                />
                <span className="truncate">{selectedFolderName}</span>
              </div>
              <ChevronDownIcon className={`w-3.5 h-3.5 flex-shrink-0 transition-transform duration-200
                ${isDropdownOpen ? 'transform rotate-180' : ''}
                ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`} 
              />
            </button>

            {renderDropdown()}
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div className={`
        flex-grow overflow-y-auto custom-scrollbar
        ${isDarkMode ? 'bg-[#121212]' : 'bg-white'}
      `}>
        {loading ? (
          <div className="px-4 py-3">
            <SkeletonLoader 
              isDarkMode={isDarkMode} 
              count={5} 
              variant="idea"
            />
          </div>
        ) : filteredIdeas.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full p-4">
            <SparklesIcon className={`h-8 w-8 mb-2 ${isDarkMode ? 'text-[#333333]' : 'text-gray-400'}`} />
            <p className={`text-center text-sm mb-4 ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              {search ? 'No matching ideas found' : 'No saved ideas available'}
            </p>
            {!search && (
              <button
                onClick={handleCreateIdea}
                className={`
                  flex items-center gap-2 px-4 py-2 rounded-lg text-sm font-medium
                  transition-all duration-200
                  ${isDarkMode
                    ? 'bg-blue-500/10 text-blue-400 hover:bg-blue-500/20'
                    : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                  }
                `}
              >
                <PlusIcon className="w-4 h-4" />
                Save Ideas to Use Them
              </button>
            )}
          </div>
        ) : (
          <div className="p-3 space-y-2">
            {filteredIdeas.map(idea => (
              <div
                key={idea._id}
                onClick={() => toggleIdeaSelection(idea._id)}
                className={`rounded-lg border transition-all duration-200 cursor-pointer hover:bg-opacity-50 ${
                  isDarkMode 
                    ? 'border-[#333333] bg-[#151515] hover:bg-[#202020]' 
                    : 'border-gray-200 bg-white hover:bg-gray-50'
                } ${
                  selectedIdeas.includes(idea._id)
                    ? isDarkMode
                      ? 'ring-1 ring-blue-500/50 bg-blue-500/10'
                      : 'ring-1 ring-blue-500/30 bg-blue-50'
                    : ''
                }`}
              >
                <div className="flex items-start p-2.5">
                  <div className="flex-1 min-w-0">
                    {/* Metadata line */}
                    {(idea.clientFolderDetails || idea.tags?.length > 0) && (
                      <div className="flex flex-wrap items-center gap-1 mb-1.5">
                        {idea.clientFolderDetails && (
                          <span className={`inline-flex items-center px-1.5 text-[10px] leading-[16px] rounded-sm
                            ${isDarkMode 
                              ? 'bg-[#202020] text-slate-400' 
                              : 'bg-gray-100 text-gray-600'
                            }`}
                          >
                            <FolderIcon className="w-2.5 h-2.5 mr-0.5" />
                            {idea.clientFolderDetails.name}
                          </span>
                        )}
                        {idea.tags?.map((tag, i) => (
                          <span
                            key={i}
                            className={`inline-flex items-center px-1.5 text-[10px] leading-[16px] rounded-sm
                              ${isDarkMode 
                                ? 'bg-[#202020] text-slate-400' 
                                : 'bg-gray-100 text-gray-600'
                              }`}
                          >
                            #{tag}
                          </span>
                        ))}
                      </div>
                    )}
                    <div className={`text-sm ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`}>
                      <ReactMarkdown
                        components={{
                          p: ({ children }) => (
                            <p className="!m-0 !leading-relaxed">
                              {children}
                            </p>
                          )
                        }}
                      >
                        {idea.content}
                      </ReactMarkdown>
                    </div>
                  </div>
                  {selectedIdeas.includes(idea._id) && (
                    <CheckIcon className="w-4 h-4 flex-shrink-0 mt-0.5 text-blue-500" />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Footer */}
      <div className={`
        px-4 py-2.5 border-t
        ${isDarkMode ? 'bg-[#151515] border-[#333333]' : 'bg-white border-gray-200'}
      `}>
        <div className="flex items-center justify-between">
          <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
            {selectedIdeas.length} selected
          </span>
          <button
            onClick={handleConfirmSelection}
            disabled={selectedIdeas.length === 0}
            className={`
              px-3 py-1 rounded-lg text-sm font-medium
              transition-all duration-200
              ${selectedIdeas.length > 0
                ? isDarkMode
                  ? 'bg-blue-500/10 text-blue-400 hover:bg-blue-500/20'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
                : isDarkMode
                  ? 'bg-[#202020] text-slate-500 cursor-not-allowed'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              }
            `}
          >
            Add to Chat
          </button>
        </div>
      </div>
    </div>
  );
};

export default SavedIdeaSelector; 