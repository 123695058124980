import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import AudioRecorder from './AudioRecorder';
import TranscriptionGridItem from './TranscriptionGridItem';
import { MicrophoneIcon, MagnifyingGlassIcon, XMarkIcon, ArrowLeftIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { AuthContext } from '../context/AuthContext';
import api, { fetchVoiceNotes, deleteVoiceNote, updateVoiceNote, fetchTags, addTag, removeTag, editTag, deleteTag, getTagCounts, getSignedUrl } from '../api';
import './scrollbar.css';
import VoiceNotesSkeleton from './VoiceNotesSkeleton';
import TagList from './TagList';
import TagManager from './TagManager';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecentActivities } from '../context/RecentActivitiesContext';
import VoiceNotePage from './VoiceNotePage';
import { motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';

const TopBar = ({ searchTerm, setSearchTerm, isDarkMode, isSearchExpanded, setIsSearchExpanded, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isVoiceNotePage = location.pathname.includes('/voice-notes/') && location.pathname !== '/voice-notes';
  return (
    <div className={`${
      isDarkMode ? 'bg-[#121212]/95 border-[#333333]' : 'bg-white border-gray-200'
    } bg-opacity-90 h-10 flex-shrink-0 flex items-center justify-between px-4 py-2 w-full border-b border-opacity-50`}>
      {/* Left section */}
      <div className="flex items-center">
        {isVoiceNotePage ? (
          <button
            onClick={() => navigate('/voice-notes')}
            className={`
              flex items-center text-xs gap-1.5 px-2 py-1.5 rounded-md
              transition-colors duration-200
              ${isDarkMode 
                ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1a1a1a]' 
                : 'text-gray-600 hover:text-gray-800 hover:bg-gray-100'
              }
            `}
          >
            <ArrowLeftIcon className="h-4 w-4" />
            <span>Back to Voice Notes</span>
          </button>
        ) : (
          <>
            <MicrophoneIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'} mr-2`} />
            <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>Voice Notes</h2>
          </>
        )}
      </div>

      {/* Right section - Search */}
      {!isVoiceNotePage && (
        <div className="flex items-center">
          <ExpandableSearchBar
            isDarkMode={isDarkMode}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isExpanded={isSearchExpanded}
            setIsExpanded={setIsSearchExpanded}
          />
        </div>
      )}
    </div>
  );
};

// Add this new component for the expandable search bar
const ExpandableSearchBar = ({ isDarkMode, searchTerm, setSearchTerm, isExpanded, setIsExpanded }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  const handleClose = () => {
    setIsExpanded(false);
    setSearchTerm('');
  };

  return (
    <div className="relative">
      {isExpanded ? (
        <div className="flex items-center">
          <input
            ref={inputRef}
            type="text"
            placeholder="Search transcriptions..."
            className={`w-40 pl-8 pr-8 py-1 text-xs border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500
                        ${isDarkMode 
                          ? 'bg-[#151515] text-slate-200 border-[#333333]' 
                          : 'bg-white text-gray-900 border-gray-300'}`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <MagnifyingGlassIcon className={`absolute left-2 top-1/2 transform -translate-y-1/2 w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          <button
            onClick={handleClose}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-400'}`} />
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsExpanded(true)}
          className={`p-1 rounded-full transition-colors duration-200 ${
            isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
          }`}
        >
          <MagnifyingGlassIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
        </button>
      )}
    </div>
  );
};

const NoResultsMessage = ({ searchTerm, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-12
      backdrop-blur-xl backdrop-saturate-150 mx-auto rounded-2xl
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-6 flex items-center justify-center
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      {searchTerm ? <DocumentTextIcon className="w-8 h-8" /> : <MicrophoneIcon className="w-8 h-8" />}
    </div>
    
    <div className="text-center max-w-sm flex flex-col items-center">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-3 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'No voice notes yet'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm max-w-[15rem] mx-auto
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or start recording a new voice note' 
          : 'Start by recording your first voice note'}
      </motion.p>
    </div>
  </motion.div>
);

const TranscriptionScreen = ({ setRecordingState, children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [startRecordingOnMount, setStartRecordingOnMount] = useState(false);
  const { isDarkMode } = useTheme();
  const { refreshTokenAndUpdateUser } = useContext(AuthContext);
  const [voiceNotes, setVoiceNotes] = useState(() => {
    // Initialize from session storage if available
    const cached = sessionStorage.getItem('voiceNotes');
    return cached ? JSON.parse(cached) : [];
  });
  const [searchTerm, setSearchTerm] = useState('');
  const transcriptionsRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState(() => {
    // Initialize tags from session storage if available
    const cached = sessionStorage.getItem('tags');
    return cached ? JSON.parse(cached) : [];
  });
  const [selectedTags, setSelectedTags] = useState(['All']);
  const [tagCounts, setTagCounts] = useState(() => {
    // Initialize tag counts from session storage if available
    const cached = sessionStorage.getItem('tagCounts');
    return cached ? JSON.parse(cached) : {};
  });
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [lastFetchTime, setLastFetchTime] = useState(() => {
    // Initialize last fetch time from session storage if available
    const cached = sessionStorage.getItem('lastFetchTime');
    return cached ? parseInt(cached) : 0;
  });

  // Add back the missing state variables
  const [isTagManagerOpen, setIsTagManagerOpen] = useState(false);
  const [isAudioRecorderLoading, setIsAudioRecorderLoading] = useState(false);
  const [processingStage, setProcessingStage] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  // Cache timeout in milliseconds (e.g., 5 minutes)
  const CACHE_TIMEOUT = 5 * 60 * 1000;

  // Effect to save to session storage whenever data changes
  useEffect(() => {
    sessionStorage.setItem('voiceNotes', JSON.stringify(voiceNotes));
    sessionStorage.setItem('tags', JSON.stringify(tags));
    sessionStorage.setItem('tagCounts', JSON.stringify(tagCounts));
    sessionStorage.setItem('lastFetchTime', lastFetchTime.toString());
  }, [voiceNotes, tags, tagCounts, lastFetchTime]);

  // Modified fetchVoiceNotesData to use cache
  const fetchVoiceNotesData = useCallback(async (pageToFetch = 1, retryCount = 0, forceRefresh = false) => {
    // Check if we can use cached data
    const now = Date.now();
    if (!forceRefresh && pageToFetch === 1 && lastFetchTime && (now - lastFetchTime < CACHE_TIMEOUT)) {
      console.log('Using cached voice notes');
      return;
    }

    try {
      setIsLoading(true);
      let response;
      if (selectedTags.includes('All')) {
        response = await fetchVoiceNotes([], pageToFetch);
      } else {
        response = await fetchVoiceNotes(selectedTags, pageToFetch);
      }

      const { voiceNotes: newVoiceNotes, totalPages, currentPage } = response;

      setVoiceNotes(prevNotes => {
        const updatedNotes = pageToFetch === 1 ? newVoiceNotes : [...prevNotes, ...newVoiceNotes];
        sessionStorage.setItem('voiceNotes', JSON.stringify(updatedNotes));
        return updatedNotes;
      });

      setHasMore(currentPage < totalPages);
      setLastFetchTime(now);
      setIsLoading(false);
      setIsLoadingMore(false);
      setError(null);
    } catch (error) {
      console.error('Error fetching voice notes:', error);
      if (error.response && error.response.status === 401 && retryCount < 1) {
        console.log('Attempting to refresh token...');
        try {
          const newToken = await refreshTokenAndUpdateUser();
          if (newToken) {
            console.log('Token refreshed successfully. Retrying fetch...');
            return fetchVoiceNotesData(pageToFetch, retryCount + 1);
          } else {
            console.log('Failed to refresh token.');
            setError('Authentication failed. Please log in again.');
          }
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          setError('Authentication failed. Please log in again.');
        }
      } else {
        setError(`Error fetching voice notes: ${error.message}`);
      }
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  }, [selectedTags, refreshTokenAndUpdateUser, lastFetchTime]);

  // Modified fetchTagsData to use cache
  const fetchTagsData = async (forceRefresh = false) => {
    // Check if we can use cached data
    const now = Date.now();
    if (!forceRefresh && lastFetchTime && (now - lastFetchTime < CACHE_TIMEOUT)) {
      console.log('Using cached tags');
      return;
    }

    try {
      const fetchedTags = await fetchTags();
      setTags(fetchedTags);
      sessionStorage.setItem('tags', JSON.stringify(fetchedTags));
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  // Modified fetchTagCounts to use cache
  const fetchTagCounts = async (forceRefresh = false) => {
    // Check if we can use cached data
    const now = Date.now();
    if (!forceRefresh && lastFetchTime && (now - lastFetchTime < CACHE_TIMEOUT)) {
      console.log('Using cached tag counts');
      return;
    }

    try {
      const counts = await getTagCounts();
      setTagCounts(counts);
      sessionStorage.setItem('tagCounts', JSON.stringify(counts));
    } catch (error) {
      console.error('Error fetching tag counts:', error);
      setError(`Error fetching tag counts: ${error.message}`);
    }
  };

  // Force refresh function
  const forceRefreshData = useCallback(() => {
    fetchVoiceNotesData(1, 0, true);
    fetchTagsData(true);
    fetchTagCounts(true);
  }, [fetchVoiceNotesData]);

  // Add this effect to handle data staleness
  useEffect(() => {
    const now = Date.now();
    if (!lastFetchTime || (now - lastFetchTime >= CACHE_TIMEOUT)) {
      forceRefreshData();
    }
  }, [lastFetchTime, forceRefreshData]);

  const { refreshActivities } = useRecentActivities();

  useEffect(() => {
    fetchVoiceNotesData();
    fetchTagsData();
    fetchTagCounts();
  }, [selectedTags]);

  useEffect(() => {
    if (voiceNotes.length > 0) {
      const lowercasedFilter = searchTerm.toLowerCase();
      const filtered = voiceNotes.filter(note =>
        note.title.toLowerCase().includes(lowercasedFilter) ||
        note.transcription.toLowerCase().includes(lowercasedFilter) ||
        (note.tags && note.tags.some(tag => tag.toLowerCase().includes(lowercasedFilter)))
      );
      setFilteredNotes(filtered);
    } else {
      setFilteredNotes([]);
    }
  }, [searchTerm, voiceNotes]);

  const loadMore = useCallback(() => {
    if (!isLoadingMore && hasMore) {
      setIsLoadingMore(true);
      setPage(prevPage => prevPage + 1);
      fetchVoiceNotesData(page + 1);
    }
  }, [isLoadingMore, hasMore, page, fetchVoiceNotesData]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);

  const handleTagSelect = (newSelectedTags) => {
    setSelectedTags(newSelectedTags);
  };

  const handleAddTag = async (voiceNoteId, newTag) => {
    try {
      const updatedNote = await addTag(voiceNoteId, newTag);
      setVoiceNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === voiceNoteId ? { ...note, tags: updatedNote.tags } : note
        )
      );
      fetchTagsData();
      fetchTagCounts();
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };

  const handleRemoveTag = async (noteId, tagToRemove) => {
    try {
      await removeTag(noteId, tagToRemove);
      setVoiceNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === noteId
            ? { ...note, tags: note.tags.filter(tag => tag !== tagToRemove) }
            : note
        )
      );
    } catch (error) {
      console.error('Error removing tag:', error);
    }
  };

  const handleRecordingComplete = async (voiceNoteId) => {
    try {
      // Clear the recording state
      setRecordingState({
        isRecording: false,
        isPaused: false,
        recordingTime: 0,
        onStop: null,
        onPause: null,
        onResume: null,
        onDiscard: null
      });

      // Show initial processing status
      setProcessingStage('Processing your voice...');
      
      // Fetch the newly created voice note
      const response = await api.get(`/api/voice-notes/${voiceNoteId}`);
      const newVoiceNote = response.data;

      // Update state with new voice note
      setVoiceNotes(prevNotes => [newVoiceNote, ...prevNotes]);
      
      // Clear processing status
      setProcessingStage(null);

      // Refresh activities and data
      await refreshActivities();
      forceRefreshData();

      // Scroll to top if ref exists
      if (transcriptionsRef.current) {
        transcriptionsRef.current.scrollTo({ 
          top: 0, 
          behavior: 'smooth' 
        });
      }

      toast.success('Voice note recorded and transcribed successfully');
    } catch (error) {
      console.error('Error processing voice note:', error);
      if (error.response?.status === 401) {
        try {
          await refreshTokenAndUpdateUser();
          return handleRecordingComplete(voiceNoteId);
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          toast.error('Authentication failed. Please log in again.');
        }
      } else {
        toast.error(`Error processing voice note: ${error.message}`);
      }
      setProcessingStage(null);
    }
  };

  const handleTitleUpdate = async (id, newTitle) => {
    try {
      const updatedNote = await updateVoiceNote(id, { title: newTitle });
      setVoiceNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === id ? { ...note, title: newTitle } : note
        )
      );
      await refreshActivities();
      toast.success('Title updated successfully');
    } catch (error) {
      console.error('Error updating title:', error);
      toast.error('Failed to update title');
    }
  };

  const handleDelete = async (id) => {
    try {
      // First update the UI optimistically
      setVoiceNotes(prevNotes => {
        const updatedNotes = prevNotes.filter(note => note._id !== id);
        sessionStorage.setItem('voiceNotes', JSON.stringify(updatedNotes));
        return updatedNotes;
      });

      // Then perform the actual deletion
      await deleteVoiceNote(id);
      
      // Only refresh activities after successful deletion
      await refreshActivities();
      
      // Show success message
      toast.success('Voice note deleted successfully');
    } catch (error) {
      console.error('Error deleting voice note:', error);
      
      // If there's a server error, revert the optimistic update
      if (error.response?.status === 500) {
        toast.error(`Error deleting voice note: ${error.message}`);
        // Refresh to get the correct state
        forceRefreshData();
      } else {
        // For other errors, keep the optimistic update
        toast.error('Voice note removed from view');
      }
    }
  };

  const handleTranscriptionUpdate = async (id, newTranscription) => {
    try {
      if (!id) {
        throw new Error('Voice note ID is missing');
      }
      const updatedNote = await updateVoiceNote(id, { transcription: newTranscription });
      setVoiceNotes(prevNotes =>
        prevNotes.map(note =>
          note._id === id ? { ...note, transcription: updatedNote.transcription } : note
        )
      );
      await refreshActivities();
      toast.success('Transcription updated successfully');
      return updatedNote;
    } catch (error) {
      console.error('Error updating transcription:', error);
      toast.error('Failed to update transcription');
      throw error;
    }
  };

  const handleEditTag = async (oldTag, newTag) => {
    try {
      if (!oldTag || !newTag || oldTag === newTag) {
        toast.error('Invalid tag edit. Please ensure both old and new tags are provided and different.');
        return;
      }
      const result = await editTag(oldTag, newTag);

      setTags(prevTags => prevTags.map(tag => tag === oldTag ? newTag : tag));

      setVoiceNotes(prevNotes => prevNotes.map(note => ({
        ...note,
        tags: note.tags.map(tag => tag === oldTag ? newTag : tag)
      })));

      fetchTagsData();
      toast.success('Tag edited successfully');
    } catch (error) {
      console.error('Error editing tag:', error);
      let errorMessage = 'An error occurred while editing the tag';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      toast.error(errorMessage);
    }
  };

  const handleDeleteTag = async (tagToDelete) => {
    try {
      await deleteTag(tagToDelete);

      setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete));

      setVoiceNotes(prevNotes => prevNotes.map(note => ({
        ...note,
        tags: note.tags.filter(tag => tag !== tagToDelete)
      })));

      setSelectedTags(prevSelected => prevSelected.filter(tag => tag !== tagToDelete));
      fetchTagsData();
      toast.success('Tag deleted successfully');
    } catch (error) {
      console.error('Error deleting tag:', error);
      toast.error('Failed to delete tag');
    }
  };

  const renderEmptyState = () => {
    // Don't show empty state if we're processing a new note
    if (voiceNotes.length === 0 && !processingStage) {
      return (
        <div className="flex-1 flex items-center justify-center min-h-[calc(100vh-12rem)] -mt-16">
          <NoResultsMessage isDarkMode={isDarkMode} />
        </div>
      );
    } else if (filteredNotes.length === 0 && !processingStage) {
      return (
        <div className="flex-1 flex items-center justify-center min-h-[calc(100vh-12rem)] -mt-16">
          <NoResultsMessage searchTerm={searchTerm} isDarkMode={isDarkMode} />
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    if (location.state && location.state.startRecording) {
      setStartRecordingOnMount(true);
    }
  }, [location]);

  const handleVoiceNoteSelect = (voiceNoteId) => {
    navigate(`/voice-notes/${voiceNoteId}`, { state: { from: 'transcriptionScreen' } });
  };

  const renderMainContent = () => {
    if (children) {
      return (
        <div className={`w-full h-full ${isDarkMode ? 'bg-[#121212]' : 'bg-white'} ${
          location.pathname.includes('/voice-notes/') ? 'pt-0' : 'pt-4'
        }`}>
          {children}
        </div>
      );
    }

    return (
      <div className="flex flex-col w-full">
        {/* Tags section - now sticky within the scrollable area */}
        <div className={`sticky top-0 z-20 w-full backdrop-blur-sm ${isDarkMode ? 'bg-[#121212]/95' : 'bg-white/95'}`}>
          <div className="pt-4 w-full">
            <div className="max-w-3xl mx-auto px-4">
              <TagList
                tags={tags}
                selectedTags={selectedTags}
                onTagSelect={handleTagSelect}
                isDarkMode={isDarkMode}
                onManageTags={() => setIsTagManagerOpen(true)}
                tagCounts={tagCounts}
              />
            </div>
          </div>
        </div>

        {/* Scrollable content */}
        <div className="flex-1 w-full">
          <div className="max-w-3xl mx-auto px-4 pb-32">
            {isLoading && page === 1 ? (
              <VoiceNotesSkeleton isDarkMode={isDarkMode} count={5} />
            ) : error ? (
              <p className="mt-4 text-red-500">{error}</p>
            ) : voiceNotes.length === 0 ? (
              <div className="w-full flex-1 flex items-center justify-center min-h-[calc(100vh-12rem)] -mt-16">
                <NoResultsMessage searchTerm="" isDarkMode={isDarkMode} />
              </div>
            ) : filteredNotes.length === 0 && searchTerm ? (
              <div className="w-full flex-1 flex items-center justify-center min-h-[calc(100vh-12rem)] -mt-16">
                <NoResultsMessage searchTerm={searchTerm} isDarkMode={isDarkMode} />
              </div>
            ) : (
              <div className="space-y-4">
                {filteredNotes.map((note) => (
                  <TranscriptionGridItem
                    key={note._id}
                    id={note._id}
                    title={note.title}
                    transcription={note.transcription}
                    audioUrl={note.audioUrl}
                    createdAt={note.createdAt}
                    isDarkMode={isDarkMode}
                    tags={note.tags || []}
                    allTags={tags}
                    onAddTag={(tag) => handleAddTag(note._id, tag)}
                    onRemoveTag={(tag) => handleRemoveTag(note._id, tag)}
                    onDelete={(id) => handleDelete(id)}
                    onTitleUpdate={(id, newTitle) => handleTitleUpdate(id, newTitle)}
                    onTranscriptionUpdate={(id, newTranscription) => handleTranscriptionUpdate(id, newTranscription)}
                    generatedContents={note.generatedContents || []}
                    onSelect={() => handleVoiceNoteSelect(note._id)}
                  />
                ))}
                {isLoadingMore && <VoiceNotesSkeleton isDarkMode={isDarkMode} count={2} />}
                <div ref={ref} style={{ height: '20px' }}></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Add this useEffect to handle incoming audio data
  useEffect(() => {
    const handleNewVoiceNote = async () => {
      if (location.state?.newVoiceNoteId && location.state?.justUploaded) {
        try {
          // Fetch the voice notes again to get the new one
          let response;
          if (selectedTags.includes('All')) {
            response = await fetchVoiceNotes([], 1);
          } else {
            response = await fetchVoiceNotes(selectedTags, 1);
          }

          setVoiceNotes(response.voiceNotes);
          
          // Clear the location state to prevent re-processing
          navigate(location.pathname, { replace: true });

          // Scroll to top to show the new note
          transcriptionsRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
          console.error('Error fetching voice notes:', error);
          setError('Failed to load voice notes. Please refresh the page.');
        }
      }
    };

    handleNewVoiceNote();
  }, [location.state, selectedTags, navigate]);

  useEffect(() => {
    // Check if we're returning from a voice note page
    if (location.pathname === '/voice-notes' && location.state?.from === 'voiceNotePage') {
      // Clear the state to prevent multiple refreshes
      navigate(location.pathname, { replace: true, state: {} });
      
      // Force refresh data
      forceRefreshData();
      toast.success('Voice notes refreshed');
    }
  }, [location, navigate, forceRefreshData]);

  return (
    <>
      {!children && (
        <Helmet>
          <title>Voice Notes | Ammmplify</title>
          <meta name="description" content="Record, transcribe, and manage your voice notes with AI-powered transcription. Convert your spoken ideas into written content effortlessly." />
          <meta name="keywords" content="voice notes, transcription, AI transcription, voice to text, content creation" />
          <meta property="og:title" content="Voice Notes | Ammmplify" />
          <meta property="og:description" content="Record, transcribe, and manage your voice notes with AI-powered transcription. Convert your spoken ideas into written content effortlessly." />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Voice Notes | Ammmplify" />
          <meta name="twitter:description" content="Record, transcribe, and manage your voice notes with AI-powered transcription. Convert your spoken ideas into written content effortlessly." />
        </Helmet>
      )}
      
      <div 
        data-transcription-screen
        className="fixed inset-0 flex flex-col overflow-hidden"
      >
        <div className={`relative flex-1 flex flex-col w-full overflow-hidden ${
          isDarkMode ? 'bg-[#121212] text-slate-200' : 'bg-white text-gray-800'
        }`}>
          <TopBar 
            searchTerm={searchTerm} 
            setSearchTerm={setSearchTerm} 
            isDarkMode={isDarkMode}
            isSearchExpanded={isSearchExpanded}
            setIsSearchExpanded={setIsSearchExpanded}
            children={children}
          />
          
          {/* Main scrollable content area */}
          <div className="relative flex-1 flex flex-col overflow-hidden">
            <div 
              ref={transcriptionsRef}
              className="absolute inset-0 overflow-y-auto"
            >
              <div className="min-h-full w-full">
                {renderMainContent()}
              </div>
            </div>
          </div>

          {/* Fixed AudioRecorder at bottom - only show if no children */}
          {!children && (
            <div className="fixed bottom-0 left-0 right-0 z-30">
              <div className="max-w-3xl mx-auto px-4">
                <AudioRecorder
                  onRecordingComplete={handleRecordingComplete}
                  isLoading={isAudioRecorderLoading}
                  setProcessingStatus={setProcessingStage}
                  startRecordingOnMount={startRecordingOnMount}
                />
              </div>
            </div>
          )}

          {/* Tag Manager Modal */}
          {isTagManagerOpen && (
            <TagManager
              tags={tags}
              onClose={() => setIsTagManagerOpen(false)}
              onEditTag={handleEditTag}
              onDeleteTag={handleDeleteTag}
              isDarkMode={isDarkMode}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TranscriptionScreen;
