import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ChartBarIcon, DocumentTextIcon, ClockIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';

// Move getTimeAgo helper function to a separate utils file if used elsewhere
const getTimeAgo = (date) => {
  const minutes = Math.floor((new Date() - new Date(date)) / 60000);
  if (minutes < 1) return 'Just now';
  if (minutes === 1) return '1 min ago';
  if (minutes < 60) return `${minutes} mins ago`;
  const hours = Math.floor(minutes / 60);
  if (hours === 1) return '1 hour ago';
  if (hours < 24) return `${hours} hours ago`;
  const days = Math.floor(hours / 24);
  if (days === 1) return '1 day ago';
  if (days < 7) return `${days} days ago`;
  const weeks = Math.floor(days / 7);
  if (weeks === 1) return '1 week ago';
  if (weeks < 4) return `${weeks} weeks ago`;
  const months = Math.floor(days / 30);
  if (months === 1) return '1 month ago';
  if (months < 12) return `${months} months ago`;
  const years = Math.floor(days / 365);
  if (years === 1) return '1 year ago';
  return `${years} years ago`;
};

const DesktopStatsBar = ({ stats, isDarkMode }) => (
  <div className="hidden sm:flex items-center justify-between w-full">
    <div className="flex items-center gap-6">
      <div className="flex items-center gap-2">
        <ChartBarIcon className={`w-3.5 h-3.5 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`} />
        <span className={`text-[11px] font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
          {stats.totalCanvases} {stats.totalCanvases === 1 ? 'Collection' : 'Collections'}
        </span>
      </div>
      <div className={`w-px h-3 ${isDarkMode ? 'bg-slate-800' : 'bg-slate-200'}`} />
      <div className="flex items-center gap-2">
        <span className={`text-[11px] font-medium ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}`}>
          {stats.totalPosts} {stats.totalPosts === 1 ? 'Post' : 'Posts'}
        </span>
        <span className={`text-[11px] ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
          ({stats.publishedPosts} published • {stats.draftPosts} drafts)
        </span>
      </div>
      <div className={`w-px h-3 ${isDarkMode ? 'bg-slate-800' : 'bg-slate-200'}`} />
      <div className="flex items-center">
        <span className={`text-[11px] ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
          Last activity: {getTimeAgo(stats.lastActivity)}
        </span>
      </div>
    </div>
  </div>
);

const MobileStatsBar = ({ stats, isDarkMode }) => (
  <div className="sm:hidden w-full">
    {/* Main Stats Row */}
    <div className="grid grid-cols-2 w-full gap-2 mb-2">
      {/* Collections Stats */}
      <div className={`
        flex items-center justify-between 
        px-2.5 py-1.5 rounded-md
        ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-100'}
      `}>
        <div className="flex items-center gap-1.5">
          <div className={`
            w-6 h-6 rounded-md flex items-center justify-center
            ${isDarkMode ? 'bg-[#252525]' : 'bg-white'}
          `}>
            <ChartBarIcon className={`w-3.5 h-3.5 ${isDarkMode ? 'text-blue-400' : 'text-blue-500'}`} />
          </div>
          <div className="flex flex-col">
            <span className={`text-xs font-medium ${isDarkMode ? 'text-slate-200' : 'text-slate-700'}`}>
              {stats.totalCanvases}
            </span>
            <span className={`text-[10px] ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
              {stats.totalCanvases === 1 ? 'Collection' : 'Collections'}
            </span>
          </div>
        </div>
      </div>

      {/* Posts Stats */}
      <div className={`
        flex items-center justify-between 
        px-2.5 py-1.5 rounded-md
        ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-100'}
      `}>
        <div className="flex items-center gap-1.5">
          <div className={`
            w-6 h-6 rounded-md flex items-center justify-center
            ${isDarkMode ? 'bg-[#252525]' : 'bg-white'}
          `}>
            <DocumentDuplicateIcon className={`w-3.5 h-3.5 ${isDarkMode ? 'text-emerald-400' : 'text-emerald-500'}`} />
          </div>
          <div className="flex flex-col">
            <span className={`text-xs font-medium ${isDarkMode ? 'text-slate-200' : 'text-slate-700'}`}>
              {stats.totalPosts}
            </span>
            <span className={`text-[10px] ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
              {stats.totalPosts === 1 ? 'Post' : 'Posts'}
            </span>
          </div>
        </div>
      </div>
    </div>

    {/* Secondary Stats Row */}
    <div className="grid grid-cols-2 w-full gap-2">
      {/* Published Stats */}
      <div className={`
        flex items-center justify-between 
        px-2.5 py-1.5 rounded-md
        ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-100'}
      `}>
        <div className="flex items-center gap-1.5">
          <div className={`
            w-6 h-6 rounded-md flex items-center justify-center
            ${isDarkMode ? 'bg-[#252525]' : 'bg-white'}
          `}>
            <DocumentTextIcon className={`w-3.5 h-3.5 ${isDarkMode ? 'text-violet-400' : 'text-violet-500'}`} />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-1">
              <span className={`text-xs font-medium ${isDarkMode ? 'text-slate-200' : 'text-slate-700'}`}>
                {stats.publishedPosts}
              </span>
              <span className={`text-[10px] ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
                /{stats.totalPosts}
              </span>
            </div>
            <span className={`text-[10px] ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
              Published
            </span>
          </div>
        </div>
      </div>

      {/* Last Activity */}
      <div className={`
        flex items-center justify-between 
        px-2.5 py-1.5 rounded-md
        ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-100'}
      `}>
        <div className="flex items-center gap-1.5">
          <div className={`
            w-6 h-6 rounded-md flex items-center justify-center
            ${isDarkMode ? 'bg-[#252525]' : 'bg-white'}
          `}>
            <ClockIcon className={`w-3.5 h-3.5 ${isDarkMode ? 'text-amber-400' : 'text-amber-500'}`} />
          </div>
          <div className="flex flex-col">
            <span className={`text-xs font-medium truncate max-w-[100px] ${isDarkMode ? 'text-slate-200' : 'text-slate-700'}`}>
              {getTimeAgo(stats.lastActivity)}
            </span>
            <span className={`text-[10px] ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`}>
              Last Active
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const StatsBottomBarforCanvasWelcome = ({ canvases, isDarkMode, isMobile }) => {
  const stats = useMemo(() => {
    let publishedPosts = 0;
    let draftPosts = 0;
    let totalPosts = 0;

    canvases.forEach(canvas => {
      if (canvas.posts) {
        canvas.posts.forEach(post => {
          totalPosts++;
          if (post.published) {
            publishedPosts++;
          } else {
            draftPosts++;
          }
        });
      }
    });

    return {
      totalCanvases: canvases.length,
      totalPosts,
      publishedPosts,
      draftPosts,
      lastActivity: canvases.reduce((latest, canvas) => {
        const canvasDate = new Date(canvas.lastEdited);
        return latest > canvasDate ? latest : canvasDate;
      }, new Date(0))
    };
  }, [canvases]);

  return (
    <div className={`
      fixed bottom-0 left-0 right-0
      flex items-center justify-between
      ${isMobile ? 'px-3 py-2' : 'px-4 sm:px-6 py-2.5'}
      border-t
      backdrop-blur-xl
      transition-colors duration-200
      z-50
      ${isDarkMode 
        ? 'bg-[#121212]/95 border-[#333333]' 
        : 'bg-white/95 border-slate-200/50'
      }
    `}>
      <DesktopStatsBar stats={stats} isDarkMode={isDarkMode} />
      <MobileStatsBar stats={stats} isDarkMode={isDarkMode} />
    </div>
  );
};

StatsBottomBarforCanvasWelcome.propTypes = {
  canvases: PropTypes.arrayOf(PropTypes.shape({
    posts: PropTypes.array,
    lastEdited: PropTypes.string
  })).isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired
};

DesktopStatsBar.propTypes = {
  stats: PropTypes.shape({
    totalCanvases: PropTypes.number.isRequired,
    totalPosts: PropTypes.number.isRequired,
    publishedPosts: PropTypes.number.isRequired,
    draftPosts: PropTypes.number.isRequired,
    lastActivity: PropTypes.instanceOf(Date).isRequired
  }).isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

MobileStatsBar.propTypes = {
  stats: PropTypes.shape({
    totalCanvases: PropTypes.number.isRequired,
    totalPosts: PropTypes.number.isRequired,
    publishedPosts: PropTypes.number.isRequired,
    draftPosts: PropTypes.number.isRequired,
    lastActivity: PropTypes.instanceOf(Date).isRequired
  }).isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

export default StatsBottomBarforCanvasWelcome; 