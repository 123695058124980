import React, { useState, useEffect, useCallback } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday, isPast, isFuture, addDays, startOfWeek, endOfWeek } from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon, PlusIcon, TrashIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import CompactLinkedInPostPreview from './CompactLinkedInPostPreview';
import SchedulePostModal from './SchedulePostModal';
import CampaignPostsModal from './CampaignPostsModal';
import { motion, AnimatePresence } from 'framer-motion';
import linkedinScheduleService from '../../services/linkedinScheduleService';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';
import MobileCalendarView from './MobileCalendarView';
import ViewToggle from './ViewToggle';
import WeeklyView from './WeeklyView';
import DailyView from './DailyView';
import { cn } from '../../utils/cn';
import { getLinkedInAuthUrl } from '../../services/linkedinAuthService';
import AnimatedLoader from '../common/AnimatedLoader';
import { Helmet } from 'react-helmet-async';

const viewTransition = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 }
};

const LinkedInScheduler = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [scheduledPosts, setScheduledPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [postToSchedule, setPostToSchedule] = useState(null);
  const isMobile = useIsMobile();
  const [currentView, setCurrentView] = useState(() => {
    return localStorage.getItem('linkedInSchedulerView') || 'month';
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, postId: null });

  useEffect(() => {
    const postData = location.state?.postToSchedule;
    if (postData) {
      console.log('Received post data:', postData);
      setPostToSchedule(postData);
      const today = new Date();
      setSelectedDate(today);
      setShowScheduleModal(true);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate]);

  // Add effect to save view preference
  useEffect(() => {
    localStorage.setItem('linkedInSchedulerView', currentView);
  }, [currentView]);

  useEffect(() => {
    fetchScheduledPosts();
  }, []);

  useEffect(() => {
    const now = new Date();
    let targetDate;

    switch (currentView) {
      case 'week':
        targetDate = startOfWeek(now);
        break;
      case 'day':
        targetDate = now;
        break;
      default:
        targetDate = startOfMonth(now);
    }

    setCurrentMonth(targetDate);
  }, [currentView]);

  const fetchScheduledPosts = async () => {
    try {
      setIsLoading(true);
      const posts = await linkedinScheduleService.getScheduledPosts();
      console.log('Fetched scheduled posts:', posts);
      setScheduledPosts(posts);
    } catch (error) {
      console.error('Error fetching scheduled posts:', error);
      toast.error('Failed to fetch scheduled posts. Please try again.');
      
      // Check if it's an authentication error
      if (error.response?.status === 401) {
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>LinkedIn authentication has expired</p>
            <button
              onClick={handleReconnectLinkedIn}
              className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              Reconnect LinkedIn
            </button>
          </div>,
          { duration: 8000 }
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSchedulePost = async (scheduledPost) => {
    console.log('Handling scheduled post:', scheduledPost);
    // Instead of appending, refresh the entire list
    await fetchScheduledPosts();
  };

  const handleEditPost = (post) => {
    setPostToSchedule(post);
    setSelectedDate(new Date(post.scheduledFor));
    setShowScheduleModal(true);
  };

  const handleDeletePost = async (postId) => {
    try {
      await linkedinScheduleService.deleteScheduledPost(postId);
      setScheduledPosts(prev => prev.filter(post => post._id !== postId));
      setDeleteConfirmation({ show: false, postId: null });
      toast.success('Post deleted successfully');
    } catch (error) {
      toast.error('Failed to delete post');
      console.error('Error deleting post:', error);
    }
  };

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const days = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const handlePreviousMonth = () => {
    switch (currentView) {
      case 'week':
        setCurrentMonth(prev => addDays(prev, -7));
        break;
      case 'day':
        setCurrentMonth(prev => addDays(prev, -1));
        break;
      default:
        setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1));
    }
  };

  const handleNextMonth = () => {
    switch (currentView) {
      case 'week':
        setCurrentMonth(prev => addDays(prev, 7));
        break;
      case 'day':
        setCurrentMonth(prev => addDays(prev, 1));
        break;
      default:
        setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1));
    }
  };

  const getPeriodLabel = () => {
    switch (currentView) {
      case 'week':
        return `${format(startOfWeek(currentMonth), 'MMM d')} - ${format(endOfWeek(currentMonth), 'MMM d, yyyy')}`;
      case 'day':
        return format(currentMonth, 'EEEE, MMM d, yyyy');
      default:
        return format(currentMonth, 'MMMM yyyy');
    }
  };

  const handleDateClick = (date) => {
    if (isPast(date) && !isToday(date)) return;
    setSelectedDate(date);
    setShowCampaignModal(true);
  };

  const handlePostSelect = async (post) => {
    console.log('Selected post for scheduling:', post);

    // Process media files to use direct Cloudinary URLs
    const mediaFiles = (post.mediaFiles || []).map(file => ({
      originalName: file.originalName || file.name,
      s3Key: file.s3Key,
      bucket: file.bucket || process.env.REACT_APP_AWS_S3_BUCKET,
      mimeType: file.mimeType || file.type,
      size: file.size,
      metadata: file.metadata || {},
      url: file.cloudinaryUrl || file.url // Use direct Cloudinary URL
    }));

    console.log('Processed media files:', mediaFiles);

    setPostToSchedule({
      ...post,
      mediaFiles,
      title: post.title || 'Untitled Post'
    });
    setShowCampaignModal(false);
    setShowScheduleModal(true);
  };

  const handleDateChange = (increment) => {
    setSelectedDate(prev => addDays(prev || new Date(), increment));
  };

  const handleAddPost = (time) => {
    setSelectedDate(time);
    setShowCampaignModal(true);
  };

  const handleJumpToCurrent = () => {
    setCurrentMonth(new Date());
  };

  const handleViewChange = (newView) => {
    setCurrentView(newView);
  };

  const MobileView = () => (
    <MobileCalendarView
      selectedDate={selectedDate || new Date()}
      scheduledPosts={scheduledPosts}
      isDarkMode={isDarkMode}
      onDateChange={handleDateChange}
      onAddPost={handleAddPost}
      onEditPost={handleEditPost}
    />
  );

  const DesktopView = () => {
    const today = new Date();
    const currentWeekStart = startOfWeek(today);
    const currentWeekEnd = endOfWeek(today);

    return (
      <div className={cn(
        "h-full flex flex-col overflow-hidden",
        isDarkMode ? 'bg-[#0A0A0A]' : 'bg-white'
      )}>
        {/* Calendar Grid */}
        <div className={cn(
          "flex-1 h-full overflow-y-auto p-4 sm:p-6",
          isDarkMode ? 'bg-[#0A0A0A]' : 'bg-background'
        )}>
          <div className="h-full flex flex-col">
            <div className="grid grid-cols-7 gap-1.5">
              {/* Day labels */}
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                <div
                  key={day}
                  className={cn(
                    "text-sm font-medium text-center pb-2",
                    isDarkMode 
                      ? [(index === 0 || index === 6) ? "text-gray-600" : "text-gray-500"]
                      : ["text-muted-foreground", (index === 0 || index === 6) && "opacity-80"]
                  )}
                >
                  {window.innerWidth < 640 ? day.charAt(0) : day}
                </div>
              ))}
            </div>

            <div className="grid grid-cols-7 gap-1.5 flex-1">
              {/* Calendar days */}
              {days.map((day, index) => {
                const postsForDay = scheduledPosts.filter(post => 
                  format(new Date(post.scheduledFor), 'yyyy-MM-dd') === 
                  format(day, 'yyyy-MM-dd')
                );

                const isOutsideMonth = !isSameMonth(day, currentMonth);
                const isPastDay = isPast(day) && !isToday(day);
                const isWeekend = index % 7 === 0 || index % 7 === 6;

                return (
                  <motion.div
                    key={day.toString()}
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2, delay: index * 0.01 }}
                    className={cn(
                      "group relative h-full min-h-[120px]",
                      "rounded-lg transition-all duration-200",
                      isPastDay && [
                        isDarkMode 
                          ? [
                              "border-[#1F1F1F]/30 bg-[#080808]",
                              "opacity-100"
                            ]
                          : "border border-muted/40 bg-muted/10",
                        isOutsideMonth && (isDarkMode ? "opacity-50" : "opacity-30")
                      ],
                      isOutsideMonth && !isPastDay && [
                        isDarkMode 
                          ? [
                              "border-[#1F1F1F]/50 bg-[#0D0D0D]",
                              "opacity-75"
                            ]
                          : "border border-border/30 bg-background/50"
                      ],
                      !isPastDay && !isOutsideMonth && [
                        isDarkMode 
                          ? [
                              "border-[#1F1F1F] bg-[#111111]",
                              "hover:border-blue-500/20 hover:bg-[#151515]",
                              "hover:shadow-lg hover:shadow-black/10"
                            ]
                          : [
                              "border border-border hover:border-primary/30",
                              "hover:bg-accent/[0.05] hover:shadow-sm"
                            ]
                      ],
                      isToday(day) && [
                        isDarkMode 
                          ? [
                              "border-2 border-blue-500/40 bg-[#151515]",
                              "hover:border-blue-500/50 hover:bg-[#181818]",
                              "shadow-sm shadow-blue-500/10"
                            ]
                          : [
                              "border-2 border-primary bg-primary/[0.02]",
                              "hover:bg-primary/[0.05]"
                            ]
                      ],
                      isWeekend && !isPastDay && !isToday(day) && 
                        (isDarkMode ? "bg-[#0F0F0F]" : "bg-accent/[0.02]")
                    )}
                  >
                    {/* Day Header */}
                    <div className={cn(
                      "flex items-center justify-between sticky top-0 z-10",
                      "px-2 py-1.5",
                      isPastDay 
                        ? isDarkMode 
                            ? "text-gray-600" 
                            : "text-muted-foreground"
                        : isToday(day)
                          ? isDarkMode 
                              ? "text-blue-300" 
                              : "text-primary"
                          : isDarkMode 
                              ? "text-gray-300" 
                              : "text-foreground"
                    )}>
                      <div className="flex items-center space-x-2">
                        <span className={cn(
                          "flex h-6 w-6 items-center justify-center rounded-full text-sm",
                          isToday(day) && (isDarkMode 
                            ? "bg-blue-500/20 text-blue-300 font-medium" 
                            : "bg-primary font-medium text-primary-foreground"
                          ),
                          isPastDay && [
                            isOutsideMonth 
                              ? isDarkMode ? "text-gray-700" : "text-slate-400 opacity-30"
                              : isDarkMode ? "text-gray-600" : "text-slate-400 opacity-40"
                          ],
                          !isToday(day) && !isPastDay && [
                            isOutsideMonth 
                              ? isDarkMode ? "text-gray-600" : "text-slate-500 opacity-70"
                              : isDarkMode ? "text-gray-300" : "text-slate-700",
                            isWeekend && (isDarkMode ? "text-gray-500" : "text-slate-500")
                          ]
                        )}>
                          {format(day, 'd')}
                        </span>
                        
                        {/* Post count badge */}
                        {postsForDay.length > 0 && !isOutsideMonth && (
                          <span className={cn(
                            "inline-flex h-5 items-center rounded-full px-2 text-xs font-medium transition-colors duration-200",
                            isPastDay 
                              ? (isDarkMode 
                                  ? "bg-gray-800/30 text-gray-500"
                                  : "bg-slate-100 text-slate-400 opacity-40"
                                )
                              : (isDarkMode
                                  ? "bg-blue-500/10 text-blue-300"
                                  : "bg-primary/15 text-primary"
                                )
                          )}>
                            {postsForDay.length} {postsForDay.length === 1 ? 'post' : 'posts'}
                          </span>
                        )}
                      </div>
                      
                      {/* Plus button - Always show for present/future days */}
                      {(!isPastDay || isToday(day)) && !isOutsideMonth && (
                        <button
                          onClick={() => handleDateClick(day)}
                          className={cn(
                            "inline-flex h-6 w-6 items-center justify-center rounded-md",
                            "transition-all duration-200",
                            isDarkMode
                              ? "hover:bg-white/5 text-gray-400 hover:text-gray-300"
                              : "hover:bg-accent text-muted-foreground hover:text-foreground",
                            "focus-visible:outline-none focus-visible:ring-2",
                            isDarkMode 
                              ? "focus-visible:ring-blue-500/30"
                              : "focus-visible:ring-ring"
                          )}
                        >
                          <PlusIcon className="h-4 w-4" />
                          <span className="sr-only">Add post</span>
                        </button>
                      )}
                    </div>

                    {/* Posts Container */}
                    <div className={cn(
                      "space-y-0.5 px-1 pt-1",
                      "h-[calc(100%-32px)] max-h-[104px]",
                      "overflow-y-auto",
                      "scrollbar-thin relative",
                      isDarkMode 
                        ? "scrollbar-thumb-[#2F2F2F] hover:scrollbar-thumb-[#3F3F3F] scrollbar-track-transparent"
                        : "scrollbar-thumb-gray-200 hover:scrollbar-thumb-gray-300 scrollbar-track-transparent",
                      isPastDay && (isDarkMode ? "opacity-60" : "opacity-40")
                    )}>
                      {postsForDay.map((post, postIndex) => {
                        const uniqueColorIndex = postIndex % 5;
                        
                        return (
                          <motion.div
                            key={post._id}
                            initial={{ opacity: 0, y: 2 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ 
                              duration: 0.1,
                              delay: postIndex * 0.02,
                              ease: "easeOut"
                            }}
                            className="group relative"
                          >
                            <CompactLinkedInPostPreview
                              post={post}
                              isDarkMode={isDarkMode}
                              isSelected={false}
                              colorIndex={uniqueColorIndex}
                              className="transform transition-all duration-200 hover:-translate-y-0.5"
                              onClick={() => handlePostClick(post)}
                            />
                            
                            {/* Delete Button - Only show for scheduled/published posts */}
                            {(post.status === 'scheduled' || post.status === 'published' || post.status === 'failed') && (
                              <div className={cn(
                                "absolute right-0 top-0 -mt-1 -mr-1",
                                "opacity-0 group-hover:opacity-100",
                                "transition-opacity duration-200"
                              )}>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteConfirmation({ show: true, postId: post._id });
                                  }}
                                  className={cn(
                                    "p-1 rounded-full",
                                    "shadow-sm",
                                    isDarkMode
                                      ? [
                                          "bg-red-500/90 hover:bg-red-500",
                                          "text-white/90 hover:text-white",
                                          "border border-red-400/20"
                                        ]
                                      : [
                                          "bg-red-500/90 hover:bg-red-500",
                                          "text-white",
                                          "border border-red-400/20"
                                        ],
                                    "transition-colors duration-200",
                                    "focus:outline-none focus:ring-2",
                                    isDarkMode
                                      ? "focus:ring-red-500/30"
                                      : "focus:ring-red-500/30"
                                  )}
                                  title="Delete post"
                                >
                                  <TrashIcon className="w-3 h-3" />
                                </button>
                              </div>
                            )}
                          </motion.div>
                        );
                      })}

                      {/* Post Count Indicator for scrollable content */}
                      {postsForDay.length > 2 && (
                        <div className={cn(
                          "sticky bottom-0 left-0 right-0",
                          "flex items-center justify-center",
                          "h-5 text-[10px] font-medium",
                          "bg-gradient-to-t",
                          isDarkMode
                            ? [
                                "from-[#111111] via-[#111111]/95 to-transparent",
                                "text-blue-400/90"
                              ]
                            : [
                                "from-white via-white/95 to-transparent",
                                "text-primary/70"
                              ],
                          "transition-colors duration-200"
                        )}>
                          Scroll to see {postsForDay.length - 2} more
                        </div>
                      )}
                    </div>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCurrentView = () => {
    const view = (() => {
      switch (currentView) {
        case 'week':
          return (
            <motion.div
              {...viewTransition}
              className="h-full"
            >
              <WeeklyView
                currentDate={currentMonth}
                scheduledPosts={scheduledPosts}
                isDarkMode={isDarkMode}
                onDateClick={handleDateClick}
                onEditPost={handleEditPost}
                onDeletePost={handleDeletePost}
              />
            </motion.div>
          );
        case 'day':
          return (
            <motion.div
              {...viewTransition}
              className="h-full"
            >
              <DailyView
                currentDate={currentMonth}
                scheduledPosts={scheduledPosts}
                isDarkMode={isDarkMode}
                onDateClick={handleDateClick}
                onEditPost={handleEditPost}
                onDeletePost={handleDeletePost}
              />
            </motion.div>
          );
        default:
          return (
            <motion.div
              {...viewTransition}
              className="h-full"
            >
              {isMobile ? <MobileView /> : <DesktopView />}
            </motion.div>
          );
      }
    })();

    return (
      <AnimatePresence mode="wait">
        {view}
      </AnimatePresence>
    );
  };

  const handleReconnectLinkedIn = async () => {
    try {
      const state = `reconnect-${Date.now()}`;
      const authUrl = await getLinkedInAuthUrl(state);
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error initiating LinkedIn reconnection:', error);
      toast.error('Failed to initiate LinkedIn reconnection');
    }
  };

  const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const { isDarkMode } = useTheme();

    const handleConfirm = async () => {
      setIsDeleting(true);
      try {
        await onConfirm();
      } finally {
        setIsDeleting(false);
      }
    };

    if (!isOpen) return null;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`fixed inset-0 flex items-center justify-center p-4 z-50 ${
          isDarkMode ? 'bg-black/30 backdrop-blur-sm' : 'bg-white/30 backdrop-blur-sm'
        }`}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className={`relative w-full max-w-sm rounded-lg border transition-all
            ${isDarkMode 
              ? 'bg-[#121212] border-[#333333]' 
              : 'bg-white border-zinc-200'}`}
        >
          <div className="p-6">
            <div className="flex items-start gap-3">
              <div className={`p-2 rounded-full ${
                isDarkMode ? 'bg-red-500/10' : 'bg-red-50'
              }`}>
                <TrashIcon className="w-5 h-5 text-red-500" />
              </div>
              <div>
                <h3 className={`text-lg font-semibold leading-none tracking-tight mb-2
                  ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}`}
                >
                  Delete Post
                </h3>
                <p className={`text-sm ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}`}>
                  Are you sure? This action cannot be undone.
                </p>
              </div>
            </div>
            
            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={onClose}
                disabled={isDeleting}
                className={`
                  px-4 py-2 rounded-md text-sm font-medium
                  transition-colors duration-200
                  ${isDarkMode 
                    ? `bg-[#1a1a1a] text-slate-300 border border-[#333333] 
                       ${!isDeleting && 'hover:bg-[#222]'} 
                       ${isDeleting && 'opacity-50 cursor-not-allowed'}`
                    : `bg-white text-slate-900 border border-zinc-200 
                       ${!isDeleting && 'hover:bg-zinc-50'} 
                       ${isDeleting && 'opacity-50 cursor-not-allowed'}`
                  }
                `}
              >
                Cancel
              </button>
              <button
                onClick={handleConfirm}
                disabled={isDeleting}
                className={`
                  relative px-4 py-2 rounded-md text-sm font-medium text-white
                  transition-colors duration-200
                  ${isDeleting 
                    ? 'bg-red-600 cursor-not-allowed'
                    : 'bg-red-600 hover:bg-red-700'
                  }
                  min-w-[80px]
                `}
              >
                <span className={isDeleting ? 'opacity-0' : 'opacity-100'}>
                  Delete
                </span>
                {isDeleting && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <AnimatedLoader size={16} color="white" />
                  </div>
                )}
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  // Add handler for CompactLinkedInPostPreview click
  const handlePostClick = useCallback((post) => {
    console.log('🔍 Post clicked:', post);
    
    // Set the selected date from the post's scheduled date
    const postDate = new Date(post.scheduledFor);
    setSelectedDate(postDate);
    
    // Set the post data for the modal
    setPostToSchedule(post);
    
    // Show the schedule modal
    setShowScheduleModal(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>Content Planner | Ammmplify</title>
        <meta name="description" content="Plan and schedule your LinkedIn content with our intelligent content planner. Organize your posts, manage campaigns, and optimize your social media presence." />
        <meta name="keywords" content="content planner, LinkedIn scheduler, social media planning, content calendar, post scheduling" />
        <meta property="og:title" content="Scheduler | Ammmplify" />
        <meta property="og:description" content="Plan and schedule your LinkedIn content with our intelligent content planner. Organize your posts, manage campaigns, and optimize your social media presence." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Content Planner | Ammmplify" />
        <meta name="twitter:description" content="Plan and schedule your LinkedIn content with our intelligent content planner. Organize your posts, manage campaigns, and optimize your social media presence." />
      </Helmet>

      <div className={cn(
        "h-full flex flex-col overflow-hidden",
        isDarkMode ? 'bg-[#121212]' : 'bg-white'
      )}>
        {/* Header */}
        <div className={cn(
          "flex items-center justify-between h-10 px-3",
          "sticky top-0 z-20 border-b",
          isDarkMode ? 'bg-[#121212] border-[#2a2a2a]' : 'bg-white border-gray-200/80'
        )}>
          {/* Left: Title */}
          <div className="flex-none flex items-center gap-2">
            <CalendarIcon className={cn(
              "w-4 h-4",
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            )} />
            <h2 className={cn(
              "text-xs font-medium",
              isDarkMode ? 'text-gray-200' : 'text-gray-700'
            )}>
              Content Planner
            </h2>
          </div>

          {/* Right: Date Navigation and View Toggle */}
          <div className="flex items-center gap-2">
            <div className={cn(
              "flex items-center gap-1 px-1",
              "rounded-md",
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-50'
            )}>
              <button
                onClick={handlePreviousMonth}
                className={cn(
                  "p-1 rounded-md transition-colors",
                  isDarkMode 
                    ? 'hover:bg-white/[0.06] text-gray-400 hover:text-gray-300' 
                    : 'hover:bg-gray-100/70 text-gray-500 hover:text-gray-600'
                )}
                aria-label={`Previous ${currentView}`}
              >
                <ChevronLeftIcon className="w-3 h-3" />
              </button>
              <button
                onClick={handleJumpToCurrent}
                className={cn(
                  "text-[11px] font-medium px-2 py-1 min-w-[100px]",
                  "rounded-md transition-all duration-200",
                  "hover:bg-blue-500/10 active:bg-blue-500/20",
                  "cursor-pointer select-none",
                  isDarkMode 
                    ? [
                        "text-gray-300 hover:text-blue-400",
                        "hover:bg-blue-500/10",
                        "active:bg-blue-500/20"
                      ]
                    : [
                        "text-gray-600 hover:text-blue-600",
                        "hover:bg-blue-500/5",
                        "active:bg-blue-500/10"
                      ]
                )}
                title={`Jump to current ${currentView}`}
              >
                {getPeriodLabel()}
              </button>
              <button
                onClick={handleNextMonth}
                className={cn(
                  "p-1 rounded-md transition-colors",
                  isDarkMode 
                    ? 'hover:bg-white/[0.06] text-gray-400 hover:text-gray-300' 
                    : 'hover:bg-gray-100/70 text-gray-500 hover:text-gray-600'
                )}
                aria-label={`Next ${currentView}`}
              >
                <ChevronRightIcon className="w-3 h-3" />
              </button>
            </div>
            <ViewToggle
              currentView={currentView}
              onViewChange={handleViewChange}
              isDarkMode={isDarkMode}
            />
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex-1 overflow-hidden">
          {renderCurrentView()}
        </div>

        {/* Modals */}
        <AnimatePresence>
          {showCampaignModal && (
            <CampaignPostsModal
              isOpen={showCampaignModal}
              onClose={() => {
                setShowCampaignModal(false);
                setSelectedDate(null);
              }}
              onSelectPost={handlePostSelect}
              isDarkMode={isDarkMode}
              isMobile={isMobile}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showScheduleModal && (
            <SchedulePostModal
              selectedDate={selectedDate}
              onClose={() => {
                setShowScheduleModal(false);
                setPostToSchedule(null);
              }}
              onSchedule={handleSchedulePost}
              onDelete={handleDeletePost}
              isDarkMode={isDarkMode}
              postData={postToSchedule}
              isOpen={showScheduleModal}
              isMobile={isMobile}
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {deleteConfirmation.show && (
            <DeleteConfirmationModal
              isOpen={deleteConfirmation.show}
              onClose={() => setDeleteConfirmation({ show: false, postId: null })}
              onConfirm={() => handleDeletePost(deleteConfirmation.postId)}
            />
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default LinkedInScheduler; 