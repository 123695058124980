import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './CustomQuill.css';
import { ArrowLeftIcon, ClipboardIcon, CheckIcon } from '@heroicons/react/24/outline';
import '../scrollbar.css';
import IdeasTagMenu from './IdeasTagMenu';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchIdea, updateIdea } from '../../api';
import { motion } from 'framer-motion';
import { socket } from '../../socket';
import { useRecentActivities } from '../../context/RecentActivitiesContext';
import AnimatedLoader from '../common/AnimatedLoader';

const FullScreenNote = ({ isDarkMode, onAddTag, onRemoveTag, allTags }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [note, setNote] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [lastEdited, setLastEdited] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const quillRef = useRef(null);
  const toolbarRef = useRef(null);
  const titleRef = useRef(null);
  const [showToolbar, setShowToolbar] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const [isCopied, setIsCopied] = useState(false);
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const tagMenuRef = useRef(null);
  const [tags, setTags] = useState([]);
  const [tagMenuPosition, setTagMenuPosition] = useState({ top: 0, left: 0 });
  const tagButtonRef = useRef(null);
  const [saveStatus, setSaveStatus] = useState('saved'); // 'saved' | 'saving' | 'error'
  const [statusMessage, setStatusMessage] = useState('');
  const [showStatus, setShowStatus] = useState(false);
  const { refreshActivities } = useRecentActivities();

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const saveChangesDebounced = useCallback(
    debounce(async (newTitle, newContent) => {
      setSaveStatus('saving');
      
      try {
        const updatedNote = await updateIdea(note?._id, { 
          title: newTitle, 
          content: newContent, 
          lastEdited: new Date()
        });
        
        setNote(updatedNote);
        setLastEdited(new Date());
        setSaveStatus('saved');
        
        // Refresh activities after successful update
        await refreshActivities();
      } catch (error) {
        console.error('Error saving note:', error);
        setSaveStatus('error');
        setTimeout(() => {
          setSaveStatus('saved');
        }, 5000);
      }
    }, 500),
    [note?._id, refreshActivities]
  );

  useEffect(() => {
    let mounted = true;
    let retryInterval;

    const loadNote = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const fetchNote = async () => {
          try {
            const fetchedNote = await fetchIdea(id);
            if (mounted && fetchedNote) {
              setNote(fetchedNote);
              setTitle(fetchedNote.title || '');
              setContent(fetchedNote.content === 'Start typing...' ? '' : fetchedNote.content);
              setTags(fetchedNote.tags || []);
              setLastEdited(fetchedNote.lastEdited || new Date());
              setIsLoading(false);
              return true;
            }
            return false;
          } catch (err) {
            return false;
          }
        };

        // Try immediately
        if (await fetchNote()) return;

        // If failed, retry every 500ms for up to 5 seconds
        let attempts = 0;
        retryInterval = setInterval(async () => {
          attempts++;
          if (await fetchNote() || attempts >= 10) {
            clearInterval(retryInterval);
            if (attempts >= 10 && mounted) {
              setError('Failed to load note after multiple attempts');
              setIsLoading(false);
            }
          }
        }, 500);

      } catch (error) {
        if (mounted) {
          console.error('Error loading note:', error);
          setError(error.message || 'Failed to load note');
          setIsLoading(false);
        }
      }
    };
    
    if (id) {
      loadNote();
    }

    return () => {
      mounted = false;
      if (retryInterval) clearInterval(retryInterval);
    };
  }, [id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tagMenuRef.current && !tagMenuRef.current.contains(event.target)) {
        setIsTagMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (isLoading) {
    return (
      <motion.div 
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        className={`flex items-center justify-center h-full ${
          isDarkMode ? 'text-slate-200' : 'text-gray-800'
        }`}
      >
        <div className="flex flex-col items-center gap-4">
          <AnimatedLoader size={32} />
          <span className="text-sm font-medium">Loading note...</span>
        </div>
      </motion.div>
    );
  }

  if (error) {
    return (
      <div className={`flex flex-col items-center justify-center h-full ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
        <div className="text-red-500 mb-4">Error: {error}</div>
        <button
          onClick={() => navigate('/ideas')}
          className={`px-4 py-2 rounded-md ${
            isDarkMode 
              ? 'bg-slate-700 hover:bg-slate-600 text-slate-200' 
              : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
          }`}
        >
          Back to Ideas
        </button>
      </div>
    );
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    saveChanges(e.target.value, content);
  };

  const handleContentChange = (value) => {
    setContent(value);
    saveChanges(title, value);
  };

  const saveChanges = (newTitle, newContent) => {
    saveChangesDebounced(newTitle, newContent);
  };

  const getWordCount = (text) => {
    return text.replace(/<[^>]*>/g, '').trim().split(/\s+/).length;
  };

  const getCharacterCount = (text) => {
    return text.replace(/<[^>]*>/g, '').length;
  };

  const copyToClipboard = () => {
    if (quillRef.current) {
      const text = quillRef.current.getEditor().getText();
      navigator.clipboard.writeText(text).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      });
    }
  };

  const handleClose = () => {
    const updatedNote = {
      _id: note._id,
      title: title,
      content: content,
      lastEdited: lastEdited
    };
    navigate('/ideas');
  };

  const modules = {
    toolbar: false
  };

  const formats = [
    'bold', 'italic', 'underline', 'strike',
    'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'script',
    'link'
  ];

  const textColor = isDarkMode ? 'text-white' : 'text-gray-900';
  const placeholderColor = isDarkMode ? 'text-slate-500' : 'text-gray-400';
  const borderColor = isDarkMode ? 'border-slate-700' : 'border-gray-200';

  const handleAddTag = (newTag) => {
    if (newTag && !tags.includes(newTag)) {
      const updatedTags = [...tags, newTag];
      setTags(updatedTags);
      onAddTag(newTag);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = tags.filter(tag => tag !== tagToRemove);
    setTags(updatedTags);
    onRemoveTag(tagToRemove);
  };

  const handleTagButtonClick = (event) => {
    event.preventDefault();
    const buttonRect = tagButtonRef.current.getBoundingClientRect();
    setTagMenuPosition({
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.left + window.scrollX,
    });
    setIsTagMenuOpen(!isTagMenuOpen);
  };

  return (
    <div 
      className={`${
        isDarkMode ? 'bg-[#121212] dark-mode' : 'bg-white'
      } flex flex-col h-full w-full overflow-hidden relative`}
    >
      {/* Minimal back button */}
      <motion.button
        onClick={handleClose}
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        whileHover={{ 
          scale: 1.05,
          backgroundColor: isDarkMode ? 'rgba(15, 23, 42, 0.8)' : 'rgba(243, 244, 246, 0.9)'
        }}
        whileTap={{ scale: 0.95 }}
        className={`absolute top-4 left-4 z-50 p-1.5 rounded-full
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-slate-800/50 text-slate-400 backdrop-blur-[2px] hover:backdrop-blur-[4px] hover:text-slate-200 hover:shadow-lg hover:shadow-black/10 hover:ring-1 hover:ring-slate-700/50' 
            : 'bg-gray-100/80 text-gray-400 backdrop-blur-[2px] hover:backdrop-blur-[4px] hover:text-gray-600 hover:shadow-sm hover:shadow-gray-200/50 hover:ring-1 hover:ring-gray-200/50'
          }`}
      >
        <ArrowLeftIcon className="w-4 h-4" />
        <span className="sr-only">Back to Ideas</span>
      </motion.button>

      {/* Main content area - restore original padding */}
      <div className="flex-grow overflow-y-auto">
        <div className="max-w-3xl mx-auto px-4">
          {/* Title input - restore original padding */}
          <div className="pt-6 sm:pt-6">
            <input
              ref={titleRef}
              className={`text-2xl font-normal w-full focus:outline-none font-['Instrument_Serif'] ${
                isDarkMode 
                  ? 'text-slate-200 bg-[#121212]' 
                  : 'text-gray-800 bg-transparent'
              } placeholder:${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`}
              value={title}
              onChange={handleTitleChange}
              placeholder="Untitled"
            />
          </div>

          <style jsx global>{`
            @import url('https://fonts.googleapis.com/css2?family=Instrument+Serif&family=Inter:wght@400;500&display=swap');

            /* Placeholder animations */
            @keyframes placeholderFade {
              0% { 
                opacity: 0; 
                transform: translateY(5px);
                background: ${isDarkMode ? 
                  'linear-gradient(45deg, rgba(148, 163, 184, 0.7) 0%, rgba(71, 85, 105, 0.3) 100%)' : 
                  'linear-gradient(45deg, rgba(107, 114, 128, 0.7) 0%, rgba(156, 163, 175, 0.3) 100%)'
                };
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              100% { 
                opacity: 1; 
                transform: translateY(0);
                background: ${isDarkMode ? 
                  'linear-gradient(45deg, rgba(148, 163, 184, 0.5) 0%, rgba(71, 85, 105, 0.2) 100%)' : 
                  'linear-gradient(45deg, rgba(107, 114, 128, 0.5) 0%, rgba(156, 163, 175, 0.2) 100%)'
                };
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }

            input::placeholder {
              animation: placeholderFade 0.8s ease-out forwards;
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .custom-quill .ql-editor.ql-blank::before {
              content: 'Start typing your idea here...';
              animation: placeholderFade 0.8s ease-out 0.2s both;
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              font-style: normal;
              position: absolute;
              pointer-events: none;
            }

            /* Existing styles */
            .custom-quill {
              font-family: 'Inter', sans-serif;
              font-size: 16px;
              line-height: 1.7;
              letter-spacing: -0.011em;
            }

            .custom-quill p {
              margin-bottom: 1.2em;
            }

            .custom-quill .ql-editor {
              font-family: 'Inter', sans-serif;
              padding: 0;
              font-weight: 400;
            }

            .custom-quill .ql-editor strong {
              font-weight: 500;
            }
          `}</style>
          
          {/* Content editor */}
          <div className="mt-2">
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={content}
              onChange={handleContentChange}
              modules={modules}
              formats={formats}
              className={`custom-quill ${textColor} notion-like-editor min-h-[calc(100vh-300px)]`}
            />
          </div>
        </div>
      </div>

      {/* Bottom handle and expandable pill */}
      <div className="fixed bottom-0 left-0 right-0 flex justify-center group">
        {/* Handle line */}
        <div className={`absolute bottom-1 left-1/2 transform -translate-x-1/2 w-16 h-1 rounded-full 
          transition-all duration-300 ease-in-out group-hover:opacity-0
          ${saveStatus === 'saving' 
            ? 'bg-yellow-500/50 animate-pulse' 
            : saveStatus === 'error'
            ? 'bg-red-500/50'
            : saveStatus === 'saved'
            ? 'bg-green-500/50 animate-[fadeToGrey_2s_ease-in-out_forwards]'
            : isDarkMode 
            ? 'bg-slate-800' 
            : 'bg-gray-200'
          }`} 
        />

        <style jsx>{`
          @keyframes fadeToGrey {
            0% { background-color: rgb(34 197 94 / 0.5); }
            100% { background-color: ${isDarkMode ? 'rgb(30 41 59 / 1)' : 'rgb(229 231 235 / 1)'}; }
          }
        `}</style>

        {/* Expandable pill */}
        <div className={`${
          isDarkMode ? 'bg-[#1a1a1a] shadow-[0_-2px_8px_rgba(0,0,0,0.12)]' : 'bg-white shadow-[0_-2px_8px_rgba(0,0,0,0.06)]'
        } rounded-t-xl px-6 py-2 flex items-center space-x-4 min-w-[320px] max-w-[480px]
          opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out
          translate-y-full group-hover:translate-y-0`}>
          <div className="hidden sm:flex items-center space-x-4 w-full justify-between">
            {/* Save status */}
            <div className="flex items-center space-x-2">
              <div className={`w-1.5 h-1.5 rounded-full transition-colors duration-300 ${
                saveStatus === 'saving' ? 'bg-yellow-500 animate-pulse' :
                saveStatus === 'error' ? 'bg-red-500' :
                'bg-green-500'
              }`} />
              <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                {saveStatus === 'saving' ? 'Saving...' :
                 saveStatus === 'error' ? 'Failed to save' :
                 'Saved'}
              </span>
            </div>

            {/* Stats */}
            <div className={`flex items-center space-x-4 text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              <span>{getWordCount(content)} words</span>
              <span className={`text-[10px] ${isDarkMode ? 'text-slate-700' : 'text-gray-300'}`}>•</span>
              <span>Last edited {new Date(lastEdited).toLocaleDateString()}</span>
            </div>
          </div>

          {/* Mobile view */}
          <div className="flex sm:hidden items-center space-x-4 w-full justify-between">
            <div className="flex items-center space-x-2">
              <div className={`w-1.5 h-1.5 rounded-full transition-colors duration-300 ${
                saveStatus === 'saving' ? 'bg-yellow-500 animate-pulse' :
                saveStatus === 'error' ? 'bg-red-500' :
                'bg-green-500'
              }`} />
              <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
                {saveStatus === 'saving' ? 'Saving...' :
                 saveStatus === 'error' ? 'Failed to save' :
                 'Saved'}
              </span>
            </div>
            <span className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              {getWordCount(content)} words
            </span>
          </div>
        </div>
      </div>

      {/* Tag menu remains the same */}
      {isTagMenuOpen && (
        <IdeasTagMenu
          ref={tagMenuRef}
          isDarkMode={isDarkMode}
          tags={tags}
          allTags={allTags}
          onAddTag={handleAddTag}
          onRemoveTag={handleRemoveTag}
          position={tagMenuPosition}
        />
      )}
    </div>
  );
};

export default FullScreenNote;
