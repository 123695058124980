import React from 'react';
import { testLinkedInTokenRefresh } from '../utils/linkedinTest';

const LinkedInTest = () => {
  const handleTest = async () => {
    try {
      await testLinkedInTokenRefresh();
    } catch (error) {
      console.error('Test failed:', error);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">LinkedIn Token Refresh Test</h1>
      <button
        onClick={handleTest}
        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
      >
        Test Token Refresh
      </button>
      <p className="mt-4 text-gray-600">
        Check the browser console (F12) for test results
      </p>
    </div>
  );
};

export default LinkedInTest; 