import React, { useEffect, useRef, useState, useCallback } from 'react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useSearch } from '../../context/SearchContext';
import { useTheme } from '../../context/ThemeContext';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';

const SearchBar = () => {
  const { 
    query, 
    setQuery, 
    performSearch,
    isOpen,
    recentSearches,
    clearRecentSearches,
    isLoading,
    error,
    clearError
  } = useSearch();
  
  const { isDarkMode } = useTheme();
  const inputRef = useRef(null);
  const [localQuery, setLocalQuery] = useState('');

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      if (searchQuery.trim()) {
        performSearch(searchQuery);
      }
    }, 300),
    [performSearch]
  );

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setLocalQuery(query || '');
  }, [query]);

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setLocalQuery(newQuery);
    setQuery(newQuery);
    if (error) clearError();
    debouncedSearch(newQuery);
  };

  const handleClearSearch = () => {
    setLocalQuery('');
    setQuery('');
    if (error) clearError();
    inputRef.current?.focus();
  };

  const handleKeyDown = (e) => {
    // Handle keyboard shortcuts
    if (e.key === 'Escape') {
      handleClearSearch();
    }
  };

  return (
    <div className="relative">
      <div className={`flex items-center px-4 py-3 ${
        isDarkMode ? 'bg-[#0A0A0A]' : 'bg-white'
      } ${error ? 'ring-2 ring-red-500' : ''}`}>
        <div className="flex items-center flex-1 relative">
          <MagnifyingGlassIcon 
            className={`h-5 w-5 flex-shrink-0 ${
              isDarkMode ? 'text-gray-500' : 'text-gray-400'
            } ${isLoading ? 'animate-pulse' : ''}`} 
          />
          
          <div className="relative flex-1 flex items-center">
            <input
              ref={inputRef}
              type="text"
              value={localQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Search anything..."
              className={`w-full px-3 py-1 text-base ${
                isDarkMode 
                  ? 'text-gray-200 placeholder-gray-600' 
                  : 'text-gray-900 placeholder-gray-500'
              } focus:outline-none bg-transparent pr-20`}
              autoComplete="off"
              spellCheck="false"
              aria-label="Search input"
              aria-invalid={!!error}
              aria-describedby={error ? "search-error" : undefined}
            />
            
            {localQuery && (
              <motion.button
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                onClick={handleClearSearch}
                className={`absolute right-2 flex items-center gap-1.5 px-2 py-1 rounded-md text-sm transition-colors ${
                  isDarkMode 
                    ? 'text-gray-500 hover:text-gray-300 hover:bg-white/[0.06]' 
                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
                }`}
                aria-label="Clear search"
              >
                <span>Clear</span>
                <XMarkIcon className="h-4 w-4" />
              </motion.button>
            )}
          </div>
        </div>
      </div>

      {/* Error Message */}
      {error && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={`absolute w-full px-4 py-2 text-sm ${
            isDarkMode 
              ? 'text-red-400 bg-red-900/10' 
              : 'text-red-500 bg-red-50'
          }`}
          id="search-error"
          role="alert"
        >
          {error}
        </motion.div>
      )}

      {/* Recent Searches */}
      {!localQuery && recentSearches?.length > 0 && (
        <div className={`px-4 py-2 border-t ${
          isDarkMode 
            ? 'border-white/[0.06] bg-[#0A0A0A]' 
            : 'border-gray-100 bg-white'
        }`}>
          <div className="flex items-center justify-between mb-2">
            <h3 className={`text-xs font-medium uppercase tracking-wider ${
              isDarkMode ? 'text-gray-600' : 'text-gray-500'
            }`}>
              Recent Searches
            </h3>
            <button
              onClick={clearRecentSearches}
              className={`text-xs ${
                isDarkMode
                  ? 'text-gray-600 hover:text-gray-400'
                  : 'text-gray-500 hover:text-gray-700'
              } transition-colors duration-150`}
            >
              Clear All
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {recentSearches.map((search, index) => (
              <button
                key={index}
                onClick={() => {
                  setLocalQuery(search);
                  setQuery(search);
                  performSearch(search);
                }}
                className={`group flex items-center gap-1.5 px-3 py-1.5 text-sm rounded-full transition-colors duration-150 ${
                  isDarkMode
                    ? 'bg-white/[0.06] hover:bg-white/[0.08] text-gray-400'
                    : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                }`}
              >
                {search}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Loading Indicator */}
      {isLoading && (
        <div className={`absolute bottom-0 left-0 w-full h-0.5 ${
          isDarkMode ? 'bg-[#0F0F0F]' : 'bg-gray-100'
        }`}>
          <motion.div
            className={`h-full ${isDarkMode ? 'bg-blue-600' : 'bg-blue-500'}`}
            initial={{ width: "0%" }}
            animate={{ width: "100%" }}
            transition={{ duration: 1.5, repeat: Infinity }}
          />
        </div>
      )}
    </div>
  );
};

export default SearchBar; 