import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SparklesIcon, XMarkIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';

const UpgradePopup = ({ 
  onClose, 
  title = "Upgrade to Pro", 
  description = "Unlock premium features and take your experience to the next level.",
  features = [],
  currentUsage = null,
  limit = null
}) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  return (
    <AnimatePresence>
      <div className="fixed inset-0 flex items-center justify-center z-50 px-4">
        {/* Backdrop */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="absolute inset-0 bg-black/50 backdrop-blur-sm"
          onClick={onClose}
        />

        {/* Popup Content */}
        <motion.div 
          initial={{ opacity: 0, scale: 0.95, y: 20 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.95, y: 20 }}
          transition={{ type: "spring", duration: 0.5 }}
          className={`
            relative w-full max-w-lg rounded-2xl shadow-xl p-6
            ${isDarkMode 
              ? 'bg-[#1a1a1a] border border-gray-800' 
              : 'bg-white border border-gray-200'
            }
          `}
        >
          {/* Close Button */}
          <button 
            onClick={onClose}
            className={`
              absolute right-4 top-4 p-1 rounded-lg transition-colors
              ${isDarkMode ? 'hover:bg-white/10' : 'hover:bg-gray-100'}
            `}
          >
            <XMarkIcon className={`h-5 w-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
          </button>

          {/* Header */}
          <div className="flex items-center gap-3 mb-4">
            <div className={`
              p-2 rounded-xl
              ${isDarkMode ? 'bg-indigo-500/10' : 'bg-indigo-50'}
            `}>
              <SparklesIcon className={`h-5 w-5 ${isDarkMode ? 'text-indigo-400' : 'text-indigo-600'}`} />
            </div>
            <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              {title}
            </h3>
          </div>

          {/* Description */}
          <p className={`text-sm mb-6 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            {description}
          </p>

          {/* Usage Info */}
          {currentUsage !== null && limit !== null && (
            <div className={`
              mb-6 p-4 rounded-xl
              ${isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50'}
            `}>
              <div className="flex justify-between items-center mb-2">
                <span className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Current Usage
                </span>
                <span className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {currentUsage} / {limit}
                </span>
              </div>
              <div className="w-full h-2 rounded-full bg-gray-200 dark:bg-gray-700">
                <div 
                  className="h-2 rounded-full bg-indigo-500"
                  style={{ width: `${(currentUsage / limit) * 100}%` }}
                />
              </div>
            </div>
          )}

          {/* Features */}
          {features.length > 0 && (
            <div className="space-y-3 mb-6">
              {features.map((feature, index) => (
                <div 
                  key={index}
                  className={`
                    flex items-center gap-3 text-sm
                    ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
                  `}
                >
                  <div className={`
                    p-1 rounded-lg
                    ${isDarkMode ? 'bg-emerald-500/10' : 'bg-emerald-50'}
                  `}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${isDarkMode ? 'text-emerald-400' : 'text-emerald-500'}`} fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                  </div>
                  {feature}
                </div>
              ))}
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex gap-3">
            <button
              onClick={onClose}
              className={`
                flex-1 px-4 py-2 rounded-xl text-sm font-medium transition-all duration-200
                ${isDarkMode 
                  ? 'bg-gray-800 hover:bg-gray-700 text-gray-300' 
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}
              `}
            >
              Maybe Later
            </button>
            <button
              onClick={() => {
                onClose();
                navigate('/subscription');
              }}
              className={`
                flex-1 px-4 py-2 rounded-xl text-sm font-medium
                bg-gradient-to-r from-indigo-500 to-purple-500
                hover:from-indigo-600 hover:to-purple-600
                text-white transition-all duration-200
                transform hover:scale-[1.02] active:scale-[0.98]
                flex items-center justify-center gap-2
              `}
            >
              <span>Upgrade Now</span>
              <ArrowRightIcon className="h-4 w-4" />
            </button>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default UpgradePopup; 