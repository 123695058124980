import React from 'react';
import '../styles/v4-test.css';

const TailwindV4Demo = () => {
  return (
    <div className="p-8 space-y-8">
      <h1 className="text-4xl font-bold mb-8">Tailwind CSS v4 Features Demo</h1>
      
      {/* P3 Color Space Demo */}
      <section className="space-y-4">
        <h2 className="text-2xl font-semibold">P3 Color Space</h2>
        <div className="flex gap-4">
          <button className="modern-button">
            Primary Button
          </button>
          <button className="modern-button" style={{'--color-primary-500': 'oklch(0.63 0.3 200)'}}>
            Custom P3 Button
          </button>
        </div>
      </section>

      {/* Container Queries Demo */}
      <section className="space-y-4">
        <h2 className="text-2xl font-semibold">Container Queries</h2>
        <div className="@container grid gap-4 p-4 border rounded-xl">
          <div className="modern-card">
            <h3 className="text-xl font-semibold">Card 1</h3>
            <p>Resize the container to see the layout change</p>
          </div>
          <div className="modern-card">
            <h3 className="text-xl font-semibold">Card 2</h3>
            <p>The grid adjusts based on container width</p>
          </div>
          <div className="modern-card">
            <h3 className="text-xl font-semibold">Card 3</h3>
            <p>Not viewport width!</p>
          </div>
        </div>
      </section>

      {/* Modern CSS Features */}
      <section className="space-y-4">
        <h2 className="text-2xl font-semibold">Modern Features</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="modern-card">
            <h3 className="text-xl font-semibold">Smooth Transitions</h3>
            <p>Hover to see smooth transitions</p>
          </div>
          <div className="modern-card backdrop-blur-sm bg-white/50">
            <h3 className="text-xl font-semibold">Backdrop Filters</h3>
            <p>Modern backdrop filters in action</p>
          </div>
        </div>
      </section>

      {/* Theme Variables Demo */}
      <section className="space-y-4">
        <h2 className="text-2xl font-semibold">Theme Variables</h2>
        <div className="modern-card">
          <p style={{ fontFamily: 'var(--font-mono)' }}>
            Using theme variables for consistent styling
          </p>
          <div className="mt-4" style={{ maxWidth: 'var(--size-content)' }}>
            <p>Content width controlled by theme variable</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TailwindV4Demo; 