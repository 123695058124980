import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import { useAuth } from '../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Bars3Icon, 
  XMarkIcon, 
  SunIcon, 
  MoonIcon,
  UserCircleIcon,
  ArrowRightOnRectangleIcon,
  Squares2X2Icon,
  ArrowRightIcon,
  SparklesIcon,
  PlayIcon
} from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import EarlyAccessButton from './EarlyAccessButton';
import '../styles/fonts.css';
import api from '../api';
import { isLinkedInConnected } from '../services/linkedinPublishService';

// Update UserDropdown component to use initials instead of avatar
const UserDropdown = ({ user, isDarkMode, onLogout, isMobile }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [linkedInProfile, setLinkedInProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Add effect to fetch LinkedIn profile
  useEffect(() => {
    const fetchLinkedInProfile = async () => {
      try {
        const status = await isLinkedInConnected();
        if (status.isConnected && status.profile?.picture) {
          setLinkedInProfile(status.profile);
        }
      } catch (error) {
        console.error('Error fetching LinkedIn profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLinkedInProfile();
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`
          flex items-center gap-2 
          ${isMobile ? 'p-1.5' : 'px-2 py-1.5'} 
          rounded-lg transition-colors
          ${isDarkMode 
            ? 'hover:bg-white/5' 
            : 'hover:bg-black/5'
          }
        `}
      >
        {isLoading ? (
          // Loading state
          <div className={`w-5 h-5 rounded-full flex items-center justify-center ${isDarkMode ? 'bg-slate-700' : 'bg-slate-500'}`}>
            <div className="animate-pulse w-2.5 h-2.5 rounded-full bg-white/30" />
          </div>
        ) : linkedInProfile?.picture ? (
          // LinkedIn profile picture
          <img 
            src={linkedInProfile.picture}
            alt={`${user?.firstName} ${user?.lastName}`}
            className="w-5 h-5 rounded-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.style.display = 'none';
              e.target.nextElementSibling.style.display = 'flex';
            }}
          />
        ) : (
          // Fallback to initials
          <div className={`w-5 h-5 rounded-full flex items-center justify-center ${isDarkMode ? 'bg-slate-700' : 'bg-slate-500'}`}>
            <span className="text-[10px] font-medium text-white">
              {`${user?.firstName?.[0] || ''}${user?.lastName?.[0] || ''}`}
            </span>
          </div>
        )}
        {!isMobile && (
          <span className={`
            text-sm truncate max-w-[100px]
            ${isDarkMode ? 'text-gray-200' : 'text-gray-700'}
          `}>
            {user?.firstName}
          </span>
        )}
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className={`
              absolute ${isMobile ? 'right-0' : 'right-0'} mt-2 
              w-56 rounded-lg shadow-lg py-1
              ${isDarkMode 
                ? 'bg-[#1a1a1a] border border-[#333]' 
                : 'bg-white border border-gray-200'
              }
            `}
          >
            {/* User Info */}
            <div className={`px-4 py-2 border-b ${
              isDarkMode ? 'border-[#333]' : 'border-gray-200'
            }`}>
              <div className="text-sm font-medium truncate">
                {user?.firstName} {user?.lastName}
              </div>
              <div className={`text-xs truncate ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                {user?.email}
              </div>
            </div>

            {/* Menu Items */}
            <div className="py-1">
              <button
                onClick={() => {
                  navigate('/chat');
                  setIsOpen(false);
                }}
                className={`
                  w-full text-left px-4 py-2 text-sm flex items-center gap-2
                  ${isDarkMode 
                    ? 'text-gray-200 hover:bg-[#252525]' 
                    : 'text-gray-700 hover:bg-gray-50'
                  }
                `}
              >
                <Squares2X2Icon className="w-4 h-4" />
                Go to App
              </button>
              <button
                onClick={() => {
                  onLogout();
                  setIsOpen(false);
                }}
                className={`
                  w-full text-left px-4 py-2 text-sm flex items-center gap-2
                  ${isDarkMode 
                    ? 'text-gray-200 hover:bg-[#252525]' 
                    : 'text-gray-700 hover:bg-gray-50'
                  }
                `}
              >
                <ArrowRightOnRectangleIcon className="w-4 h-4" />
                Logout
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const LPHeader = () => {
  const { isDarkMode, toggleDarkMode } = useTheme();
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const location = useLocation();

  // Optimized scroll handler with debounce
  useEffect(() => {
    let timeoutId;
    const handleScroll = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsScrolled(window.scrollY > 10);
      }, 10);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  const navItems = [
    { label: 'Features', path: '/home#personalization-section' },
    { label: 'Pricing', path: '/pricing' },
  ];

  // Enhanced glass effect styles
  const glassStyles = `
    backdrop-blur-xl backdrop-saturate-150
    ${isDarkMode 
      ? 'bg-[#121212]/40 border-white/10' 
      : 'bg-white/40 border-black/5'}
    border
    transition-all duration-500 ease-in-out
  `;

  // Modern floating pill styles for desktop nav
  const navPillStyles = `
    px-3 py-2 rounded-full
    ${glassStyles}
    ${isScrolled ? 'shadow-lg' : ''}
    transform transition-all duration-500
  `;

  // Enhanced mobile menu styles
  const mobileMenuStyles = `
    ${isDarkMode 
      ? 'bg-[#121212] border-[#333]'
      : 'bg-white border-gray-200'}
    border rounded-2xl
    shadow-xl
    ${isScrolled ? 'shadow-black/10' : ''}
    transform-gpu
  `;

  const handleNavClick = (e, path) => {
    e.preventDefault();
    setIsMenuOpen(false);
    
    // For pricing page, navigate directly regardless of auth state
    if (path === '/pricing') {
      window.location.href = path;
      return;
    }
    
    // Extract the page and section from the path
    const [page, section] = path.split('#');
    const currentPath = window.location.pathname;
    
    if (currentPath !== '/home' && currentPath !== '/') {
      // If we're not on the home page, navigate first
      navigate('/home', { 
        state: { scrollToSection: section } 
      });
    } else {
      // If we're already on the home page, just scroll
      const elementId = section;
      const element = document.getElementById(elementId);
      const mainContainer = document.querySelector('main');
      
      if (element && mainContainer) {
        const currentScrollTop = mainContainer.scrollTop;
        const containerRect = mainContainer.getBoundingClientRect();
        const elementRect = element.getBoundingClientRect();
        const headerOffset = 100;
        const absoluteElementTop = elementRect.top - containerRect.top + currentScrollTop;
        
        mainContainer.scrollTo({
          top: absoluteElementTop - headerOffset,
          behavior: 'smooth'
        });
      }
    }
  };

  const handleScrollToTop = (e) => {
    e.preventDefault();
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    
    if (user) {
      navigate('/chat');
    } else {
      // If we're on the landing page, scroll to top
      if (location.pathname === '/home' || location.pathname === '/') {
        const mainContainer = document.querySelector('main');
        if (mainContainer) {
          mainContainer.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      } else {
        // If we're on any other page (including pricing), navigate to landing page
        navigate('/home');
      }
    }
  };

  const handleWatchDemo = () => {
    const currentPath = window.location.pathname;
    
    if (currentPath !== '/home' && currentPath !== '/') {
      // If we're not on the home page, navigate first
      navigate('/home', { 
        state: { scrollToDemo: true } 
      });
    } else {
      // If we're already on the home page, just scroll
      const mainContainer = document.querySelector('main');
      if (mainContainer) {
        mainContainer.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }
  };

  // Add logout handler
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Add new mobile animation variants
  const mobileMenuVariants = {
    hidden: { 
      opacity: 0,
      y: -20,
      scale: 0.95
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      scale: 0.95,
      transition: {
        duration: 0.2
      }
    }
  };

  const mobileMenuItemVariants = {
    hidden: { x: -20, opacity: 0 },
    visible: { 
      x: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20
      }
    }
  };

  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className={`
        fixed top-0 left-0 right-0 z-50
        px-4 sm:px-6 lg:px-8 py-4
        w-full
        transition-colors duration-300
      `}
    >
      <div className="max-w-3xl mx-auto">
        <nav className={`
          relative flex items-center justify-between
          ${navPillStyles}
          px-4
        `}>
          {/* Logo */}
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="flex items-center"
          >
            <a 
              href={user ? '/chat' : '/'}
              onClick={handleLogoClick}
              className={`
                flex items-center gap-2
                ${isDarkMode ? 'text-[#F2F9F8]' : 'text-black'}
              `}
            >
              <img 
                src="/amplify-logo-full.svg" 
                alt="Amplify Logo" 
                className={`h-5 w-auto ${isDarkMode ? 'invert' : ''}`}
              />
              <div className="flex items-center py-0.5 ml-1">
                <span className={`
                  text-[7px] px-2 py-[1px] rounded-full font-semibold tracking-wide
                  transition-all duration-300 ease-in-out
                  ${isDarkMode 
                    ? 'bg-gradient-to-r from-indigo-500/10 to-purple-500/10 text-indigo-400 ring-1 ring-indigo-500/20' 
                    : 'bg-gradient-to-r from-indigo-50 to-purple-50 text-indigo-600 ring-1 ring-indigo-200/50'
                  }
                  hover:scale-105 transform
                  relative
                  translate-y-[1px]
                `}>
                  BETA
                </span>
              </div>
            </a>
          </motion.div>

          {/* Desktop Navigation */}
          {!isMobile && (
            <div className="flex items-center space-x-6">
              {navItems.map((item) => (
                <motion.div
                  key={item.label}
                  whileHover={{ scale: 1.05 }}
                  className="relative"
                >
                  <button
                    onClick={(e) => handleNavClick(e, item.path)}
                    className={`
                      text-xs font-medium px-2
                      ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
                      hover:text-primary-500 transition-colors
                    `}
                  >
                    {item.label}
                  </button>
                </motion.div>
              ))}
            </div>
          )}

          {/* Auth/Menu Controls */}
          <div className="flex items-center space-x-3">
            {isMobile ? (
              <>
                {user ? (
                  <UserDropdown 
                    user={user} 
                    isDarkMode={isDarkMode} 
                    onLogout={handleLogout}
                    isMobile={true}
                  />
                ) : (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="p-2 rounded-full"
                    aria-label="Toggle menu"
                  >
                    {isMenuOpen ? (
                      <XMarkIcon className="h-3.5 w-3.5" />
                    ) : (
                      <Bars3Icon className="h-3.5 w-3.5" />
                    )}
                  </motion.button>
                )}
              </>
            ) : (
              <>
                {user ? (
                  <UserDropdown 
                    user={user} 
                    isDarkMode={isDarkMode} 
                    onLogout={handleLogout}
                  />
                ) : (
                  <>
                    <Link
                      to="/login"
                      className={`
                        px-3 py-1.5 rounded-lg text-xs font-medium
                        ${isDarkMode 
                          ? 'text-gray-200 hover:bg-white/5' 
                          : 'text-gray-700 hover:bg-black/5'}
                        transition-colors duration-200
                      `}
                    >
                      Login
                    </Link>
                    <button
                      onClick={() => navigate('/register')}
                      className={`
                        hidden sm:flex items-center gap-2 px-4 py-1.5 
                        rounded-lg text-xs font-medium
                        ${isDarkMode 
                          ? 'bg-white text-black hover:bg-white/90' 
                          : 'bg-black text-white hover:bg-black/90'}
                        transition-all duration-200
                        group
                      `}
                    >
                      <SparklesIcon className="w-3.5 h-3.5" />
                      Start 7-Day Free Trial
                      <ArrowRightIcon className="w-3.5 h-3.5 transform transition-transform group-hover:translate-x-1" />
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </nav>

        {/* Mobile Menu */}
        <AnimatePresence mode="wait">
          {isMobile && isMenuOpen && (
            <>
              {/* Backdrop */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={`
                  fixed inset-0 z-40
                  ${isDarkMode ? 'bg-black/50' : 'bg-black/20'}
                  backdrop-blur-sm
                  top-[72px]
                `}
                onClick={() => setIsMenuOpen(false)}
              />

              {/* Menu Content */}
              <motion.div
                variants={mobileMenuVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                className="absolute top-[72px] left-4 right-4 z-45"
              >
                <div className={`
                  ${isDarkMode 
                    ? 'bg-[#121212] border-[#333]'
                    : 'bg-white border-gray-200'}
                  border rounded-2xl
                  shadow-xl
                  ${isScrolled ? 'shadow-black/10' : ''}
                  transform-gpu
                  mt-2
                `}>
                  <div className="p-4 space-y-3">
                    {/* Navigation Items */}
                    {navItems.map((item) => (
                      <motion.div
                        key={item.label}
                        variants={mobileMenuItemVariants}
                        whileTap={{ scale: 0.98 }}
                        className="relative"
                      >
                        <button
                          onClick={(e) => handleNavClick(e, item.path)}
                          className={`
                            block w-full text-left py-3 px-4 text-sm font-medium 
                            rounded-xl active:scale-95 transform-gpu
                            ${isDarkMode 
                              ? 'text-gray-200 hover:bg-white/5 active:bg-white/10' 
                              : 'text-gray-700 hover:bg-black/5 active:bg-black/10'}
                            transition-all duration-200
                            relative overflow-hidden
                          `}
                        >
                          <motion.span
                            initial={{ x: 0 }}
                            whileHover={{ x: 10 }}
                            className="block"
                          >
                            {item.label}
                          </motion.span>
                        </button>
                      </motion.div>
                    ))}

                    {/* Login Button in Mobile Menu */}
                    <motion.div
                      variants={mobileMenuItemVariants}
                      whileTap={{ scale: 0.98 }}
                      className="relative pt-4"
                    >
                      <Link
                        to="/login"
                        onClick={() => setIsMenuOpen(false)}
                        className={`
                          block w-full py-3 px-4 text-sm font-medium
                          rounded-lg transform-gpu
                          ${isDarkMode 
                            ? 'bg-transparent text-white hover:bg-white/10' 
                            : 'bg-transparent text-black hover:bg-black/[0.03]'}
                          transition-all duration-200
                          border
                          ${isDarkMode ? 'border-white/20' : 'border-black/20'}
                          relative
                          group
                        `}
                      >
                        <span className="flex items-center justify-between relative">
                          Sign in
                          <ArrowRightIcon className="w-4 h-4 transform transition-transform group-hover:translate-x-1" />
                        </span>
                      </Link>
                    </motion.div>

                    {/* Early Access Button in Mobile Menu */}
                    <motion.div
                      variants={mobileMenuItemVariants}
                      whileTap={{ scale: 0.98 }}
                      className="pt-0"
                    >
                      <button
                        onClick={() => {
                          setIsMenuOpen(false);
                          navigate('/register');
                        }}
                        className={`
                          block w-full py-3 px-4 text-sm font-medium
                          rounded-lg transform-gpu
                          ${isDarkMode 
                            ? 'bg-white text-black hover:bg-white/90' 
                            : 'bg-black text-white hover:bg-black/90'}
                          transition-all duration-200
                          relative
                          group
                        `}
                      >
                        <span className="flex items-center justify-between relative">
                          <div className="flex items-center gap-2">
                            <SparklesIcon className="w-4 h-4" />
                            Start 7-Day Free Trial
                          </div>
                          <ArrowRightIcon className="w-4 h-4 transform transition-transform group-hover:translate-x-1" />
                        </span>
                      </button>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </motion.header>
  );
};

export default LPHeader;
