import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const RedditIcon = ({ isDarkMode, className = "w-8 h-8", showBackground = true }) => (
  <svg 
    className={className}
    viewBox="0 0 256 256"
  >
    {showBackground && <circle cx="128" cy="128" r="128" fill={isDarkMode ? '#FF4500' : '#FF4500'} />}
    <path 
      fill={showBackground ? (isDarkMode ? '#FFFFFF' : '#FFFFFF') : 'currentColor'} 
      d="M213.15 129.22c0-10.376-8.391-18.617-18.617-18.617a18.74 18.74 0 0 0-12.97 5.189c-12.818-9.157-30.368-15.107-49.9-15.87l8.544-39.981l27.773 5.95c.307 7.02 6.104 12.667 13.278 12.667c7.324 0 13.275-5.95 13.275-13.278c0-7.324-5.95-13.275-13.275-13.275c-5.188 0-9.768 3.052-11.904 7.478l-30.976-6.562c-.916-.154-1.832 0-2.443.458c-.763.458-1.22 1.22-1.371 2.136l-9.464 44.558c-19.837.612-37.692 6.562-50.662 15.872a18.74 18.74 0 0 0-12.971-5.188c-10.377 0-18.617 8.391-18.617 18.617c0 7.629 4.577 14.037 10.988 16.939a33.6 33.6 0 0 0-.458 5.646c0 28.686 33.42 52.036 74.621 52.036c41.202 0 74.622-23.196 74.622-52.036a35 35 0 0 0-.458-5.646c6.408-2.902 10.985-9.464 10.985-17.093M85.272 142.495c0-7.324 5.95-13.275 13.278-13.275c7.324 0 13.275 5.95 13.275 13.275s-5.95 13.278-13.275 13.278c-7.327.15-13.278-5.953-13.278-13.278m74.317 35.251c-9.156 9.157-26.553 9.768-31.588 9.768c-5.188 0-22.584-.765-31.59-9.768c-1.371-1.373-1.371-3.51 0-4.883c1.374-1.371 3.51-1.371 4.884 0c5.8 5.8 18.008 7.782 26.706 7.782s21.058-1.983 26.704-7.782c1.374-1.371 3.51-1.371 4.884 0c1.22 1.373 1.22 3.51 0 4.883m-2.443-21.822c-7.325 0-13.275-5.95-13.275-13.275s5.95-13.275 13.275-13.275c7.327 0 13.277 5.95 13.277 13.275c0 7.17-5.95 13.275-13.277 13.275"
    />
  </svg>
);

const RedditAnalysisOverlay = ({ 
  isVisible, 
  currentStage,
  progress, 
  isDarkMode, 
  onCancel 
}) => {
  if (!isVisible) return null;

  const renderSkeleton = () => {
    return (
      <div className={`space-y-4 pt-4 ${
        isDarkMode ? 'opacity-30' : 'opacity-20'
      }`}>
        {/* Single Post Preview - Hidden on mobile */}
        <div className={`hidden sm:block p-4 rounded-lg border ${
          isDarkMode 
            ? 'bg-[#1a1a1a]/50 border-[#333333]' 
            : 'bg-gray-50/50 border-gray-200/30'
        }`}>
          {/* Post Header */}
          <div className="space-y-3">
            <div className={`h-5 w-2/3 rounded animate-pulse ${
              isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
            }`} />
            <div className="flex gap-2">
              <div className={`h-3 w-24 rounded animate-pulse ${
                isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
              }`} />
              <div className={`h-3 w-32 rounded animate-pulse ${
                isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
              }`} />
            </div>
          </div>

          {/* Comments Preview */}
          <div className="mt-3 space-y-2">
            <div className={`h-3 w-full rounded animate-pulse ${
              isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
            }`} />
            <div className={`h-3 w-5/6 rounded animate-pulse ${
              isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
            }`} />
          </div>

          {/* Post Info */}
          <div className="mt-4 pt-4 space-y-2 border-t border-gray-700/30">
            <div className="flex items-center gap-2">
              <div className={`h-8 w-8 rounded-full animate-pulse ${
                isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
              }`} />
              <div className="flex-1">
                <div className={`h-3 w-1/4 rounded animate-pulse ${
                  isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
                }`} />
                <div className={`mt-1 h-2 w-1/3 rounded animate-pulse ${
                  isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
                }`} />
              </div>
            </div>
          </div>
        </div>

        {/* Compact Mobile Skeleton */}
        <div className={`block sm:hidden p-3 rounded-lg border ${
          isDarkMode 
            ? 'bg-[#1a1a1a]/50 border-[#333333]' 
            : 'bg-gray-50/50 border-gray-200/30'
        }`}>
          <div className="space-y-2">
            <div className={`h-4 w-3/4 rounded animate-pulse ${
              isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
            }`} />
            <div className={`h-3 w-1/2 rounded animate-pulse ${
              isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
            }`} />
          </div>
        </div>

        {/* Analysis Preview - Compact on mobile */}
        <div className={`p-3 sm:p-4 rounded-lg border ${
          isDarkMode 
            ? 'bg-[#1a1a1a]/50 border-[#333333]' 
            : 'bg-gray-50/50 border-gray-200/30'
        }`}>
          <div className="space-y-2 sm:space-y-3">
            <div className={`h-3 sm:h-4 w-1/3 rounded animate-pulse ${
              isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
            }`} />
            <div className="space-y-1.5 sm:space-y-2">
              <div className={`h-2 sm:h-3 w-full rounded animate-pulse ${
                isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
              }`} />
              <div className={`h-2 sm:h-3 w-4/5 rounded animate-pulse ${
                isDarkMode ? 'bg-[#202020]' : 'bg-gray-300'
              }`} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="fixed inset-0 z-[100] backdrop-blur-sm">
      <div className={`absolute inset-0 ${
        isDarkMode ? 'bg-[#121212]/70' : 'bg-white/70'
      }`} />
      
      <div className="relative h-full flex items-center justify-center p-3 sm:p-6">
        <div className={`w-full max-w-2xl rounded-xl p-4 sm:p-6 ${
          isDarkMode 
            ? 'bg-[#151515]/90 border border-[#333333]' 
            : 'bg-white/90 border border-gray-200/50'
        }`}>
          {/* Header with Reddit Icon */}
          <div className="flex items-center justify-center mb-4 sm:mb-6">
            <div className={`p-2 sm:p-3 rounded-lg ${
              isDarkMode ? 'bg-orange-500/20' : 'bg-orange-100/80'
            }`}>
              <RedditIcon 
                isDarkMode={isDarkMode}
                className="w-6 h-6 sm:w-8 sm:h-8 animate-bounce"
              />
            </div>
          </div>

          {/* Progress Section */}
          <div className="space-y-4 sm:space-y-6">
            {/* Stage Title */}
            <div className="text-center">
              <h3 className={`text-base sm:text-lg font-semibold mb-1 sm:mb-2 ${
                isDarkMode ? 'text-white' : 'text-gray-900'
              }`}>
                {currentStage?.title || 'Analyzing Reddit Data'}
              </h3>
              <p className={`text-xs sm:text-sm ${
                isDarkMode ? 'text-gray-400' : 'text-gray-600'
              }`}>
                {currentStage?.message || 'Please wait...'}
              </p>
            </div>

            {/* Progress Bar */}
            <div className="relative">
              <div className={`h-1.5 sm:h-2 rounded-md overflow-hidden ${
                isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-200'
              }`}>
                <div
                  className={`h-full transition-all duration-500 ease-out ${
                    isDarkMode 
                      ? 'bg-gradient-to-r from-orange-500 to-orange-600' 
                      : 'bg-gradient-to-r from-orange-400 to-orange-500'
                  }`}
                  style={{ width: `${progress}%` }}
                />
              </div>
              <span className={`absolute right-0 top-3 text-[10px] sm:text-xs font-medium ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`}>
                {progress}%
              </span>
            </div>

            {/* Skeleton Loaders */}
            {renderSkeleton()}

            {/* Tips Section - Hidden on mobile */}
            <div className={`hidden sm:block mt-8 p-4 rounded-lg ${
              isDarkMode 
                ? 'bg-[#121212]/50 border border-[#333333]' 
                : 'bg-gray-50 border border-gray-200/30'
            }`}>
              <div className="flex items-start gap-3">
                <div className={`p-2 rounded-lg ${
                  isDarkMode ? 'bg-gray-800' : 'bg-white'
                }`}>
                  <span className="text-lg animate-pulse">💡</span>
                </div>
                <div>
                  <h4 className={`text-sm font-medium mb-1 ${
                    isDarkMode ? 'text-gray-300' : 'text-gray-700'
                  }`}>
                    Did you know?
                  </h4>
                  <p className={`text-xs ${
                    isDarkMode ? 'text-gray-400' : 'text-gray-600'
                  }`}>
                    We analyze up to 100 posts and their top comments from each subreddit to provide comprehensive insights.
                  </p>
                </div>
              </div>
            </div>

            {/* Cancel Button */}
            <div className="flex justify-center mt-4 sm:mt-6">
              <button
                onClick={onCancel}
                className={`px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg text-xs sm:text-sm font-medium transition-colors ${
                  isDarkMode
                    ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200'
                    : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
                }`}
              >
                Cancel Analysis
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedditAnalysisOverlay; 