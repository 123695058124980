import React, { useRef, useEffect, useCallback, useState } from 'react';
import { 
  XMarkIcon, 
  ArrowLeftIcon,
  ShareIcon as PublishIcon,
  CheckIcon,
  UserIcon,
  ExclamationTriangleIcon,
  ArrowPathIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  ClipboardIcon
} from '@heroicons/react/24/outline';
import CanvasPostContentEditor from './CanvasPostContentEditor';
import { useTheme } from '../context/ThemeContext';
import { motion } from 'framer-motion';
import { CollapseChatIcon } from './icons/ExpandCollapseIcons';
import { publishToLinkedIn, isLinkedInConnected } from '../services/linkedinPublishService';
import { getLinkedInAuthUrl } from '../services/linkedinAuthService';
import { toast } from 'react-hot-toast';
import { useRecentActivities } from '../context/RecentActivitiesContext';
import { Button } from './ui/button';
import { CollapseIcon } from './icons/ExpandCollapseIcon';
import { SaveButtonIcon } from './icons/SaveButtonIcon';
import PropTypes from 'prop-types';
import Portal from './ui/Portal';
import { useNavigate } from 'react-router-dom';
import AnimatedLoader from './common/AnimatedLoader';

const LinkedInLogo = ({ className = "w-3 h-3" }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
  </svg>
);

const PublishButton = ({ 
  onClick, 
  isPublishing, 
  disabled, 
  isDarkMode, 
  isPublished,
  onSchedule,
  content,
  title,
  postId,
  canvasId,
  mediaFiles,
  onClose
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const publishButtonRef = useRef(null);
  const navigate = useNavigate();

  const calculateDropdownPosition = useCallback(() => {
    if (!publishButtonRef.current) return { top: 0, left: 0 };

    const buttonRect = publishButtonRef.current.getBoundingClientRect();
    const dropdownWidth = 180; // Width of our dropdown
    
    // Calculate initial position
    let left = buttonRect.left;
    let top = buttonRect.bottom + 5; // 5px gap between button and dropdown

    // Adjust horizontal position if it would overflow the viewport
    if (left + dropdownWidth > window.innerWidth) {
      left = window.innerWidth - dropdownWidth - 10; // 10px margin from right edge
    }

    // Ensure dropdown doesn't go off-screen to the left
    if (left < 10) {
      left = 10; // 10px margin from left edge
    }

    // Adjust vertical position if it would overflow the viewport
    const dropdownHeight = 82; // Approximate height of dropdown
    if (top + dropdownHeight > window.innerHeight) {
      top = buttonRect.top - dropdownHeight - 5; // Show above button
    }

    return { top, left };
  }, []);

  useEffect(() => {
    if (showDropdown) {
      const { top, left } = calculateDropdownPosition();
      setDropdownPosition({ top, left });

      const handleClickOutside = (event) => {
        if (!event.target.closest('.dropdown-menu') && !publishButtonRef.current?.contains(event.target)) {
          setShowDropdown(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showDropdown, calculateDropdownPosition]);

  const handleScheduleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!content) {
      toast.error('Cannot schedule empty content');
      return;
    }
    
    // Navigate to scheduler with post data
    navigate('/scheduler', {
      state: {
        postToSchedule: {
          id: postId,
          canvasId,
          content,
          title,
          mediaFiles: mediaFiles?.map(file => ({
            originalName: file.originalName || file.name,
            s3Key: file.s3Key,
            bucket: file.bucket || process.env.REACT_APP_AWS_S3_BUCKET,
            mimeType: file.mimeType || file.type,
            size: file.size,
            metadata: file.metadata || {},
            signedUrl: file.signedUrl
          })) || []
        }
      }
    });
    
    setShowDropdown(false);
    onClose();
  };

  if (isPublishing) {
    return (
      <Button
        variant="ghost"
        size="tiny-with-text"
        isDarkMode={isDarkMode}
        disabled={true}
        aria-label="Publishing to LinkedIn"
        className="flex items-center gap-1 h-8 cursor-not-allowed"
      >
        <LinkedInLogo className="w-3 h-3 text-blue-400" />
        <span className="text-blue-400 flex items-center">
          Publishing<PublishingDots />
        </span>
      </Button>
    );
  }

  return (
    <div className="relative inline-block">
      <Button
        ref={publishButtonRef}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDropdown(!showDropdown);
        }}
        variant="ghost"
        size="tiny-with-text"
        isDarkMode={isDarkMode}
        disabled={disabled}
        aria-label="Publish options"
        className={`
          flex items-center gap-1 h-8 pr-2
          ${isPublished ? 'text-blue-500 hover:bg-blue-500/10' : 'text-blue-500 hover:bg-blue-500/10'}
        `}
      >
        <LinkedInLogo className="w-3 h-3" />
        <span>{isPublished ? 'Published' : 'Publish'}</span>
        <ChevronDownIcon className="w-3 h-3 ml-0.5" />
      </Button>

      {showDropdown && (
        <Portal>
          <div 
            className="fixed inset-0 z-[999]" 
            onClick={() => setShowDropdown(false)}
          >
            <div
              className={`
                fixed min-w-[180px]
                ${isDarkMode 
                  ? 'bg-[#1e1e1e] border-[#333333]' 
                  : 'bg-white border-gray-200'
                }
                rounded-lg shadow-lg 
                border
                z-[1000]
                dropdown-menu
                animate-dropdown-fade-in
                ${isDarkMode 
                  ? 'ring-1 ring-slate-600/50 shadow-xl shadow-black/20' 
                  : 'ring-1 ring-gray-200/50 shadow-xl shadow-black/10'
                }
              `}
              style={{
                top: `${dropdownPosition.top}px`,
                left: `${dropdownPosition.left}px`,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(e);
                  setShowDropdown(false);
                }}
                className={`
                  flex items-center pl-2.5 pr-3 py-2 text-sm w-full
                  ${isDarkMode 
                    ? 'hover:bg-slate-700/50 text-slate-200' 
                    : 'hover:bg-gray-100/80 text-gray-700'
                  }
                  transition-colors duration-150
                  first:rounded-t-lg
                `}
              >
                <PublishIcon className="w-5 h-5 mr-2" />
                <span className="whitespace-nowrap text-xs font-medium">
                  Publish now
                </span>
              </button>

              <div className={`h-[1px] mx-2 ${isDarkMode ? 'bg-[#333333]' : 'bg-gray-200'}`} />

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleScheduleClick(e);
                  setShowDropdown(false);
                }}
                className={`
                  flex items-center px-3 py-2 text-sm w-full
                  ${isDarkMode 
                    ? 'hover:bg-slate-700/50 text-slate-200' 
                    : 'hover:bg-gray-100/80 text-gray-700'
                  }
                  transition-colors duration-150
                  last:rounded-b-lg
                `}
              >
                <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 2V5M16 2V5M3.5 9.09H20.5M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M15.6947 13.7H15.7037M15.6947 16.7H15.7037M11.9955 13.7H12.0045M11.9955 16.7H12.0045M8.29431 13.7H8.30329M8.29431 16.7H8.30329" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <span className="whitespace-nowrap text-xs font-medium">
                  Schedule for later
                </span>
              </button>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

const PublishingDots = () => (
  <span className="flex space-x-1">
    <span className="animate-bounce delay-0">.</span>
    <span className="animate-bounce delay-100">.</span>
    <span className="animate-bounce delay-200">.</span>
  </span>
);

const SaveButton = ({ onClick, hasChanges, isSaving, saveStatus, isDarkMode }) => {
  const getStyles = () => {
    if (!hasChanges || saveStatus === 'saved') {
      return isDarkMode ? 'text-slate-600' : 'text-gray-400';
    }
    return isDarkMode ? 'text-slate-200' : 'text-gray-700';
  };

  return (
    <motion.button
      onClick={onClick}
      disabled={!hasChanges || isSaving || saveStatus === 'saved'}
      className={`
        flex items-center gap-1
        px-2.5 py-1 
        rounded-md text-xs
        transition-all duration-200
        disabled:cursor-not-allowed
        ${getStyles()}
        ${hasChanges && !isSaving && !saveStatus === 'saved' 
          ? isDarkMode 
            ? 'hover:bg-slate-800/50' 
            : 'hover:bg-gray-100'
          : ''
        }
      `}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      whileHover={hasChanges && !isSaving ? { scale: 1.02 } : {}}
      whileTap={hasChanges && !isSaving ? { scale: 0.98 } : {}}
    >
      {isSaving ? (
        <AnimatedLoader size={14} className="mr-1" />
      ) : (
        <SaveButtonIcon className={`w-3.5 h-3.5 ${saveStatus === 'saved' ? 'text-green-500' : ''}`} />
      )}
      <span>
        {isSaving ? 'Saving...' : saveStatus === 'saved' ? 'Saved' : 'Save'}
      </span>
      {saveStatus === 'saved' && (
        <motion.span
          className={`w-1 h-1 rounded-full ${isDarkMode ? 'bg-green-500' : 'bg-green-600'}`}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
        />
      )}
    </motion.button>
  );
};

const CopyButton = ({ content, isDarkMode }) => {
  const [copyState, setCopyState] = useState(false);

  const handleCopy = async () => {
    if (!content) return;

    try {
      await navigator.clipboard.writeText(content);
      setCopyState(true);
      
      toast.success('Content copied!', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });

      setTimeout(() => {
        setCopyState(false);
      }, 2000);
    } catch (error) {
      console.error('Failed to copy content:', error);
      toast.error('Failed to copy content');
    }
  };

  return (
    <button
      onClick={handleCopy}
      disabled={!content}
      className={`
        p-1.5 rounded-md transition-all duration-200
        ${copyState 
          ? isDarkMode 
            ? 'bg-green-500/20 text-green-400' 
            : 'bg-green-100 text-green-600'
          : isDarkMode 
            ? 'bg-[#252525] hover:bg-[#333333] text-gray-400 hover:text-gray-300' 
            : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700'
        }
        disabled:opacity-50 disabled:cursor-not-allowed
      `}
      title="Copy content"
    >
      <motion.div
        initial={false}
        animate={{
          scale: copyState ? [1, 1.2, 1] : 1,
        }}
        transition={{ duration: 0.3 }}
      >
        {copyState ? (
          <CheckIcon className="w-3.5 h-3.5" />
        ) : (
          <ClipboardIcon className="w-3.5 h-3.5" />
        )}
      </motion.div>
    </button>
  );
};

const BackButton = ({ onClick, hasChanges, isDarkMode }) => {
  return (
    <motion.button
      onClick={onClick}
      className={`
        flex items-center gap-1
        px-2.5 py-1
        rounded-md text-xs
        transition-all duration-150
        cursor-pointer
        group
        ml-auto
        ${isDarkMode 
          ? 'text-slate-400 hover:text-slate-200 hover:bg-slate-700/50' 
          : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
        }
      `}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <ArrowLeftIcon className="w-3.5 h-3.5 group-hover:translate-x-[-2px] transition-transform" />
      <span>{hasChanges ? 'Save & Back' : 'Back'}</span>
    </motion.button>
  );
};

const FullScreenCanvasPostContentEditor = ({ 
  content: initialContent,
  onSave, 
  onClose,
  isOpen,
  title = 'Untitled Post',
  isPublished = false,
  onSchedule,
  mediaFiles = [],
  postId,
  canvasId
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const editorRef = useRef(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [currentContent, setCurrentContent] = useState(initialContent);
  const { refreshActivities } = useRecentActivities();
  const [isPublishing, setIsPublishing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLinkedInEnabled, setIsLinkedInEnabled] = useState(false);
  const [saveStatus, setSaveStatus] = useState('idle');
  const [linkedInProfile, setLinkedInProfile] = useState(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [showRepublishConfirm, setShowRepublishConfirm] = useState(false);
  const [mediaPreview, setMediaPreview] = useState(null);

  // Reset hasChanges when editor is opened with new content
  useEffect(() => {
    if (isOpen) {
      setCurrentContent(initialContent);
      setHasChanges(false);
    }
  }, [isOpen, initialContent]);

  // Handle content changes
  const handleContentChange = useCallback((newContent) => {
    setCurrentContent(newContent);
    setHasChanges(newContent !== initialContent);
  }, [initialContent]);

  // Handle keyboard shortcuts
  const handleKeyDown = useCallback((e) => {
    // Only handle Escape and Cmd/Ctrl + Enter
    if (e.key === 'Escape' || (e.key === 'Enter' && (e.metaKey || e.ctrlKey))) {
      e.preventDefault();
      
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
        const content = editorRef.current?.getContent();
        if (content) onSave(content);
      }
    }
    // Don't prevent other keyboard events from bubbling
  }, [onClose, onSave]);

  useEffect(() => {
    const editorContainer = document.querySelector('.fullscreen-editor-container');
    
    if (isOpen && editorContainer) {
      // Focus the editor
      setTimeout(() => {
        editorRef.current?.focus();
      }, 100);
      
      // Prevent body scroll
      document.body.style.overflow = 'hidden';
      
      // Add keyboard event listener to the editor container only
      editorContainer.addEventListener('keydown', handleKeyDown);
    }

    // Cleanup
    return () => {
      document.body.style.overflow = 'unset';
      if (editorContainer) {
        editorContainer.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [isOpen, handleKeyDown]);

  // Check LinkedIn connection status
  useEffect(() => {
    const checkLinkedInStatus = async () => {
      const isConnected = await isLinkedInConnected();
      setIsLinkedInEnabled(isConnected);
    };
    checkLinkedInStatus();
  }, []);

  // Handle reconnect to LinkedIn
  const handleReconnectLinkedIn = async () => {
    try {
      const state = `reconnect-${Date.now()}`;
      const authUrl = await getLinkedInAuthUrl(state);
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error getting LinkedIn auth URL:', error);
      toast.error('Failed to initiate LinkedIn reconnection');
    }
  };

  // Add RepublishConfirmDialog component
  const RepublishConfirmDialog = ({ isOpen, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-[200] flex items-center justify-center">
        {/* Backdrop with subtle blur effect */}
        <div className="absolute inset-0 bg-black/20 backdrop-blur-[1px]" onClick={onCancel} />
        
        {/* Dialog content - Inspired by shadcn's compact design */}
        <div className={`
          relative
          w-full
          max-w-[320px]
          mx-4
          p-4
          rounded-lg
          shadow-lg
          ${isDarkMode 
            ? 'bg-slate-900 border border-slate-800' 
            : 'bg-white border border-slate-200'
          }
        `}>
          <div className="flex flex-col gap-3">
            <div className="flex items-start gap-3">
              <div className={`
                p-2 
                rounded-full 
                ${isDarkMode ? 'bg-slate-800' : 'bg-slate-100'}
              `}>
                <ExclamationTriangleIcon className="w-5 h-5 text-yellow-500" />
              </div>
              <div>
                <h3 className={`
                  text-sm 
                  font-semibold 
                  ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
                `}>
                  Confirm Republish
                </h3>
                <p className={`
                  text-xs 
                  mt-1 
                  ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                `}>
                  This post has already been published. Are you sure you want to publish it again?
                </p>
              </div>
            </div>

            <div className="flex items-center justify-end gap-2">
              <button
                onClick={onCancel}
                className={`
                  px-3 
                  py-1.5 
                  text-xs 
                  font-medium 
                  rounded-md
                  transition-colors
                  ${isDarkMode 
                    ? 'text-slate-300 hover:bg-slate-800' 
                    : 'text-slate-700 hover:bg-slate-100'
                  }
                `}
              >
                Cancel
              </button>
              <button
                onClick={onConfirm}
                className={`
                  px-3 
                  py-1.5 
                  text-xs 
                  font-medium 
                  rounded-md
                  bg-blue-500 
                  text-white
                  hover:bg-blue-600
                  transition-colors
                `}
              >
                Republish
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Update handlePublish to handle republish confirmation
  const handlePublish = async () => {
    if (!currentContent) {
      toast.error('Cannot publish empty content');
      return;
    }

    // If already published, show confirmation dialog
    if (isPublished) {
      setShowRepublishConfirm(true);
      return;
    }

    await publishContent();
  };

  // Add publishContent function to handle actual publishing
  const publishContent = async () => {
    try {
      setIsPublishing(true);
      
      // Check LinkedIn connection status
      const status = await isLinkedInConnected();
      
      if (!status.isConnected || status.needsReconnect) {
        const message = status.error || 
          (status.needsReconnect 
            ? 'LinkedIn authentication has expired' 
            : 'Please connect your LinkedIn account first');
        
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>{message}</p>
            <button
              onClick={handleReconnectLinkedIn}
              className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {status.needsReconnect ? 'Reconnect LinkedIn' : 'Connect LinkedIn'}
            </button>
          </div>,
          { duration: 8000 }
        );
        return;
      }
      
      const result = await publishToLinkedIn(currentContent, title, mediaFiles);
      toast.success('Successfully published to LinkedIn!');
      
      if (refreshActivities) {
        refreshActivities();
      }
    } catch (error) {
      console.error('Error publishing to LinkedIn:', error);
      
      // Handle duplicate content error specifically
      if (error.message?.includes('duplicate') || error.response?.data?.message?.includes('duplicate')) {
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>This content has already been published to LinkedIn</p>
            <span className="text-xs text-gray-500">
              Please modify your content before publishing again
            </span>
          </div>,
          { duration: 5000 }
        );
        return;
      }
      
      // Handle other specific LinkedIn errors
      const errorMessage = error.message || 'Failed to publish to LinkedIn';
      const needsReconnect = errorMessage.includes('expired') || 
                            errorMessage.includes('connect') ||
                            errorMessage.includes('authentication');
      
      if (needsReconnect) {
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>{errorMessage}</p>
            <button
              onClick={handleReconnectLinkedIn}
              className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {errorMessage.includes('expired') ? 'Reconnect LinkedIn' : 'Connect LinkedIn'}
            </button>
          </div>,
          { duration: 8000 }
        );
      } else {
        // Handle other errors
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>Failed to publish to LinkedIn</p>
            <span className="text-xs text-gray-500">
              {error.response?.data?.message || errorMessage}
            </span>
          </div>
        );
      }
    } finally {
      setIsPublishing(false);
      setShowRepublishConfirm(false);
    }
  };

  // Add handleRepublishConfirm handler
  const handleRepublishConfirm = () => {
    publishContent();
  };

  // Handle save with loading state
  const handleSave = async () => {
    if (!hasChanges) return;
    
    try {
      setIsSaving(true);
      setSaveStatus('saving');
      await onSave(currentContent);
      setHasChanges(false);
      setSaveStatus('saved');
      toast.success('Changes saved successfully');
      
      // Reset saved status after 2 seconds
      setTimeout(() => {
        setSaveStatus('idle');
      }, 2000);
    } catch (error) {
      setSaveStatus('error');
      toast.error('Failed to save changes');
    } finally {
      setIsSaving(false);
    }
  };

  const getSaveButtonText = () => {
    if (isSaving) return 'Saving...';
    if (saveStatus === 'saved') return 'Saved';
    return 'Save';
  };

  // Add effect to fetch LinkedIn profile
  useEffect(() => {
    const fetchLinkedInProfile = async () => {
      try {
        setIsLoadingProfile(true);
        const linkedInStatus = await isLinkedInConnected();
        
        if (linkedInStatus.isConnected && linkedInStatus.profile) {
          const profileData = linkedInStatus.profile.profile_data || linkedInStatus.profile;
          
          let fullName;
          if (profileData.name) {
            fullName = profileData.name;
          } else if (profileData.given_name && profileData.family_name) {
            fullName = `${profileData.given_name} ${profileData.family_name}`;
          } else if (linkedInStatus.profile.name) {
            fullName = linkedInStatus.profile.name;
          } else {
            fullName = 'LinkedIn User';
          }
          
          setLinkedInProfile({
            name: fullName,
            avatarUrl: profileData.picture || null
          });
        }
      } catch (error) {
        console.error('Error fetching LinkedIn profile:', error);
      } finally {
        setIsLoadingProfile(false);
      }
    };

    if (isOpen) {
      fetchLinkedInProfile();
    }
  }, [isOpen]);

  // Add renderAvatar function
  const renderAvatar = () => {
    if (isLoadingProfile) {
      return (
        <div className={`h-8 w-8 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} animate-pulse`} />
      );
    }

    return (
      <div className="flex items-center">
        {linkedInProfile?.avatarUrl ? (
          <img 
            src={linkedInProfile.avatarUrl}
            alt={linkedInProfile.name}
            className="h-5 w-5 rounded-full object-cover"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = ''; // Default to empty to show fallback
            }}
          />
        ) : (
          <div className={`h-8 w-8 rounded-full ${isDarkMode ? 'bg-slate-700' : 'bg-gray-200'} flex items-center justify-center`}>
            <UserIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-500' : 'text-gray-400'}`} />
          </div>
        )}
      </div>
    );
  };

  // Add a new handler for combined save and close
  const handleSaveAndClose = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    try {
      // Only save if there are changes
      if (hasChanges) {
        setIsSaving(true);
        setSaveStatus('saving');
        await onSave(currentContent);
        setHasChanges(false);
        setSaveStatus('saved');
      }
      // Close the editor
      onClose();
    } catch (error) {
      console.error('Error saving changes:', error);
      toast.error('Failed to save changes');
    } finally {
      setIsSaving(false);
    }
  };

  // Add effect to handle media preview
  useEffect(() => {
    const loadMediaPreview = async () => {
      if (mediaFiles && mediaFiles.length > 0) {
        const latestMedia = mediaFiles[mediaFiles.length - 1];
        try {
          // Use direct Cloudinary URL instead of signed URL
          const mediaUrl = latestMedia.cloudinaryUrl || latestMedia.url;
          if (mediaUrl) {
            setMediaPreview(mediaUrl);
          }
        } catch (error) {
          console.error('Error loading media preview:', error);
          toast.error('Failed to load media preview');
        }
      }
    };

    if (isOpen) {
      loadMediaPreview();
    }

    return () => {
      // Cleanup preview URL if it's a blob
      if (mediaPreview?.startsWith('blob:')) {
        URL.revokeObjectURL(mediaPreview);
      }
    };
  }, [isOpen, mediaFiles]);

  // Add media preview component
  const MediaPreview = ({ mediaFile, previewUrl }) => {
    if (!mediaFile || !previewUrl) return null;

    const getMediaTypeLabel = () => {
      if (mediaFile.mimeType?.startsWith('image/') || mediaFile.type?.startsWith('image/')) {
        return 'Image attachment';
      } else if (mediaFile.mimeType === 'video/mp4' || mediaFile.type === 'video/mp4') {
        return 'Video attachment';
      }
      return 'File attachment';
    };

    return (
      <div className={`
        mt-4 mb-6 
        inline-flex items-center
        rounded-lg overflow-hidden
        ${isDarkMode ? 'bg-[#1a1a1a] hover:bg-[#202020]' : 'bg-gray-50 hover:bg-gray-100'}
        border
        ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
        max-w-fit
        hover:shadow-sm
        transition-all
        duration-200
        cursor-pointer
        group
      `}>
        <div className="flex items-center gap-3 p-2">
          {/* Thumbnail container */}
          <div className="relative w-16 h-16 flex-shrink-0 rounded-md overflow-hidden">
            {(mediaFile.mimeType?.startsWith('image/') || mediaFile.type?.startsWith('image/')) ? (
              <img 
                src={previewUrl} 
                alt={mediaFile.originalName || "Post media"} 
                className="w-16 h-16 object-cover rounded-md transform group-hover:scale-105 transition-transform duration-200"
              />
            ) : (mediaFile.mimeType === 'video/mp4' || mediaFile.type === 'video/mp4') ? (
              <div className="relative w-16 h-16">
                <video 
                  src={previewUrl}
                  className="w-16 h-16 object-cover rounded-md transform group-hover:scale-105 transition-transform duration-200"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black/20 rounded-md">
                  <svg className="w-8 h-8 text-white/90" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M8 5v14l11-7z"/>
                  </svg>
                </div>
              </div>
            ) : (
              <div className={`
                w-16 h-16 rounded-md
                flex items-center justify-center
                transform group-hover:scale-105 transition-transform duration-200
                ${isDarkMode ? 'bg-slate-800' : 'bg-gray-100'}
              `}>
                <svg className={`w-8 h-8 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                </svg>
              </div>
            )}
          </div>

          {/* Info container */}
          <div className="pr-3">
            <p className={`text-sm font-medium truncate max-w-[200px] ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
              {mediaFile.originalName || mediaFile.name || 'File'}
            </p>
            <p className={`text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              {getMediaTypeLabel()}
            </p>
          </div>
        </div>
      </div>
    );
  };

  // Update the handleSchedule function
  const handleSchedule = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!currentContent) {
      toast.error('Cannot schedule empty content');
      return;
    }
    
    // Navigate to scheduler with post data
    navigate('/scheduler', {
      state: {
        postToSchedule: {
          id: postId,
          canvasId,
          content: currentContent,
          title,
          mediaFiles: mediaFiles?.map(file => ({
            originalName: file.originalName || file.name,
            s3Key: file.s3Key,
            bucket: file.bucket || process.env.REACT_APP_AWS_S3_BUCKET,
            mimeType: file.mimeType || file.type,
            size: file.size,
            metadata: file.metadata || {},
            signedUrl: file.signedUrl
          })) || []
        }
      }
    });

    // Close the editor
    onClose();
  }, [currentContent, postId, canvasId, title, mediaFiles, navigate, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[200]">
      {/* Backdrop */}
      <div 
        className={`absolute inset-0 transition-colors duration-200 ${
          isDarkMode 
            ? 'bg-[#151515]'
            : 'bg-[#f8fafc]'
        }`}
        onClick={onClose}
      />
      
      {/* Main container */}
      <div className="relative w-full h-full z-[201] flex flex-col animate-in fade-in duration-200">
        {/* Updated top bar */}
        <div className={`
          absolute top-0 left-0 right-0 
          flex items-center justify-between 
          pl-3 pr-1 h-10
          pointer-events-auto
          z-[103]
          border-b
          ${isDarkMode 
            ? 'text-slate-200 border-[#333333] bg-[#1e1e1e]/90' 
            : 'text-gray-800 border-gray-200 bg-white/90'
          }
          backdrop-blur-sm
        `}>
          {/* Left section */}
          <div className="flex items-center space-x-3">
            {renderAvatar()}
            <h1 className={`text-[13px] font-medium truncate max-w-[200px] ${
              isDarkMode ? 'text-slate-200/80' : 'text-gray-800/80'
            }`}>
              {title}
            </h1>
          </div>

          {/* Right section */}
          <div className="flex items-center space-x-3">
            {/* Copy button */}
            <CopyButton
              content={currentContent}
              isDarkMode={isDarkMode}
            />

            {/* Publish button */}
            <PublishButton
              onClick={handlePublish}
              isPublishing={isPublishing}
              disabled={!currentContent}
              isDarkMode={isDarkMode}
              isPublished={isPublished}
              onSchedule={handleSchedule}
              content={currentContent}
              title={title}
              postId={postId}
              canvasId={canvasId}
              mediaFiles={mediaFiles}
              onClose={onClose}
            />

            {/* Save button - only show when there are changes */}
            {hasChanges && (
              <SaveButton
                onClick={handleSave}
                hasChanges={hasChanges}
                isSaving={isSaving}
                saveStatus={saveStatus}
                isDarkMode={isDarkMode}
              />
            )}

            {/* Back button */}
            <BackButton
              onClick={handleSaveAndClose}
              hasChanges={hasChanges}
              isDarkMode={isDarkMode}
            />
          </div>
        </div>

        {/* Editor container */}
        <div className="flex-1 overflow-auto px-4 pt-20 z-[102]">
          <div className={`
            max-w-[60ch] mx-auto
            ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}
          `}>
            {/* Add MediaPreview component */}
            {mediaFiles && mediaFiles.length > 0 && (
              <MediaPreview 
                mediaFile={mediaFiles[mediaFiles.length - 1]} 
                previewUrl={mediaPreview}
              />
            )}

            <CanvasPostContentEditor
              ref={editorRef}
              content={initialContent}
              onSave={onSave}
              onCancel={onClose}
              onChange={handleContentChange}
              isDarkMode={isDarkMode}
              isEditing={true}
              hideSaveCancelButtons={true}
              hideMediaButton={true}
              className="prose-lg"
              style={{
                fontSize: '1.25rem',
                lineHeight: '1.6',
                fontFamily: '-apple-system, BlinkMacSystemFont, system-ui, sans-serif',
                letterSpacing: '-0.01em',
              }}
            />
          </div>
        </div>
      </div>

      {/* Add republish confirmation dialog */}
      <RepublishConfirmDialog
        isOpen={showRepublishConfirm}
        onConfirm={handleRepublishConfirm}
        onCancel={() => setShowRepublishConfirm(false)}
      />
    </div>
  );
};

// Add PropTypes
FullScreenCanvasPostContentEditor.propTypes = {
  content: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  isPublished: PropTypes.bool,
  onSchedule: PropTypes.func,
  postId: PropTypes.string,
  canvasId: PropTypes.string,
  mediaFiles: PropTypes.arrayOf(PropTypes.shape({
    originalName: PropTypes.string,
    s3Key: PropTypes.string,
    bucket: PropTypes.string,
    mimeType: PropTypes.string,
    size: PropTypes.number,
    uploadedAt: PropTypes.string,
    metadata: PropTypes.object
  }))
};

export default FullScreenCanvasPostContentEditor; 