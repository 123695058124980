import React from 'react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import { 
  UserIcon,
  EllipsisHorizontalIcon,
  HandThumbUpIcon,
  ChatBubbleOvalLeftIcon,
  ArrowPathRoundedSquareIcon,
  PaperAirplaneIcon,
  GlobeAltIcon,
  DocumentIcon
} from '@heroicons/react/24/outline';
import { cn } from '../../utils/cn';
import { isLinkedInConnected } from '../../services/linkedinPublishService';
import PDFPreview from '../PDFPreview';

const LinkedInPostPreview = ({ 
  post, 
  isDarkMode, 
  onDelete, 
  onEdit,
  isCompact = false,
  viewType = 'day',
  isSelected = false,
  colorIndex = 0,
  className
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [linkedInProfile, setLinkedInProfile] = React.useState(null);
  const [isLoadingProfile, setIsLoadingProfile] = React.useState(true);
  const [showFullScreenPDF, setShowFullScreenPDF] = React.useState(false);

  // Function to determine media type
  const getMediaType = (url, mimeType) => {
    console.log('🔍 Checking media type:', { url, mimeType });
    
    if (!url) {
      console.log('❌ No URL provided');
      return null;
    }

    // First check MIME type
    if (mimeType) {
      console.log('📋 Checking MIME type:', mimeType);
      if (mimeType.startsWith('video/')) {
        console.log('🎥 Detected video from MIME type');
        return 'video';
      }
      if (mimeType === 'application/pdf') {
        console.log('📄 Detected PDF from MIME type');
        return 'pdf';
      }
      if (mimeType.startsWith('image/')) {
        console.log('🖼️ Detected image from MIME type');
        return 'image';
      }
    }

    // Fallback to extension check
    const extension = url.split('.').pop().toLowerCase();
    console.log('🔍 Checking file extension:', extension);
    
    if (['mp4', 'webm', 'ogg'].includes(extension)) {
      console.log('🎥 Detected video from extension');
      return 'video';
    }
    if (extension === 'pdf') {
      console.log('📄 Detected PDF from extension');
      return 'pdf';
    }
    if (['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension)) {
      console.log('🖼️ Detected image from extension');
      return 'image';
    }

    console.log('❌ Could not determine media type');
    return null;
  };

  // Get media details from post
  const mediaFile = post.mediaFiles?.[0];
  console.log('🔍 Media file from post:', mediaFile);
  
  const mediaUrl = mediaFile?.cloudinaryUrl || mediaFile?.url || post.imageUrl;
  console.log('🔍 Media URL:', mediaUrl);
  
  const mediaType = getMediaType(mediaUrl, mediaFile?.mimeType);
  console.log('🔍 Determined media type:', mediaType);

  // Fetch LinkedIn profile
  React.useEffect(() => {
    const fetchLinkedInProfile = async () => {
      try {
        setIsLoadingProfile(true);
        const linkedInStatus = await isLinkedInConnected();
        
        if (linkedInStatus.isConnected && linkedInStatus.profile) {
          const profileData = linkedInStatus.profile.profile_data || linkedInStatus.profile;
          
          let fullName;
          if (profileData.name) {
            fullName = profileData.name;
          } else if (profileData.given_name && profileData.family_name) {
            fullName = `${profileData.given_name} ${profileData.family_name}`;
          } else if (linkedInStatus.profile.name) {
            fullName = linkedInStatus.profile.name;
          } else {
            fullName = 'LinkedIn User';
          }
          
          setLinkedInProfile({
            name: fullName,
            headline: profileData.headline || '',
            avatarUrl: profileData.picture || null
          });
        }
      } catch (error) {
        console.error('Error fetching LinkedIn profile:', error);
      } finally {
        setIsLoadingProfile(false);
      }
    };

    fetchLinkedInProfile();
  }, []);

  // Render media content based on type
  const renderMedia = () => {
    console.log('🎨 Rendering media:', { mediaUrl, mediaType, isCompact });
    
    if (!mediaUrl || isCompact) {
      console.log('⏭️ Skipping media render:', { reason: !mediaUrl ? 'No URL' : 'Compact mode' });
      return null;
    }

    switch (mediaType) {
      case 'pdf':
        console.log('📄 Rendering PDF preview');
        return (
          <div className={cn(
            "relative mt-2 min-h-[300px] max-h-[500px] overflow-hidden",
            "border-t border-b",
            isDarkMode ? "border-gray-700/50" : "border-gray-100"
          )}>
            <PDFPreview
              key={mediaUrl} // Add key to force re-render when URL changes
              url={mediaUrl}
              isDarkMode={isDarkMode}
              containerStyle="h-[400px]"
              showFullScreenButton={true}
              onFullScreen={() => {
                console.log('🔍 Opening PDF in full screen');
                setShowFullScreenPDF(true);
              }}
            />
            {/* PDF Title */}
            <div className={cn(
              "absolute bottom-0 left-0 right-0",
              "p-3 bg-gradient-to-t",
              isDarkMode 
                ? "from-black/80 to-transparent" 
                : "from-white/80 to-transparent"
            )}>
              <div className="flex items-center gap-2">
                <DocumentIcon className="w-5 h-5" />
                <span className="text-sm font-medium truncate">
                  {mediaFile?.originalName || 'Document.pdf'}
                </span>
              </div>
            </div>
          </div>
        );

      case 'video':
        return (
          <div className={cn(
            "relative mt-2",
            "border-t border-b",
            isDarkMode ? "border-gray-700/50" : "border-gray-100"
          )}>
            <video
              controls
              className="w-full h-auto"
              preload="metadata"
              poster={mediaFile?.metadata?.thumbnail}
            >
              <source src={mediaUrl} type={mediaFile?.mimeType || 'video/mp4'} />
              Your browser does not support the video tag.
            </video>
          </div>
        );

      case 'image':
        return (
          <div className={cn(
            "relative mt-2",
            "border-t border-b",
            isDarkMode ? "border-gray-700/50" : "border-gray-100"
          )}>
            <img
              src={mediaUrl}
              alt="Post content"
              className={cn(
                "w-full h-auto object-contain",
                "transition-opacity duration-200"
              )}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '';
                console.error('Failed to load image:', mediaUrl);
              }}
              onLoad={(e) => {
                if (e.target.height < 100) {
                  e.target.style.minHeight = '200px';
                }
              }}
            />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 2 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -2 }}
        whileHover={{ scale: 1.002 }}
        whileTap={{ scale: 0.998 }}
        className={cn(
          "w-full rounded-xl overflow-hidden",
          "transition-all duration-150",
          isDarkMode 
            ? [
                'bg-[#1D2226] border border-[#38434F]',
                'shadow-lg shadow-black/10'
              ]
            : [
                'bg-white border border-[#E7E7E7]',
                'shadow-sm hover:shadow-md'
              ],
          className
        )}
      >
        {/* Post Header */}
        <div className="p-4 flex items-start justify-between">
          <div className="flex gap-3">
            {/* Profile Picture */}
            {isLoadingProfile ? (
              <div className={cn(
                "w-12 h-12 rounded-full animate-pulse",
                isDarkMode ? "bg-gray-800" : "bg-gray-100"
              )} />
            ) : linkedInProfile?.avatarUrl ? (
              <img 
                src={linkedInProfile.avatarUrl}
                alt={linkedInProfile.name}
                className="w-12 h-12 rounded-full object-cover ring-2 ring-white/10"
              />
            ) : (
              <div className={cn(
                "w-12 h-12 rounded-full flex items-center justify-center",
                isDarkMode ? "bg-gray-800" : "bg-gray-100"
              )}>
                <UserIcon className={cn(
                  "w-6 h-6",
                  isDarkMode ? "text-gray-600" : "text-gray-400"
                )} />
              </div>
            )}

            {/* Profile Info */}
            <div className="flex-1 min-w-0">
              <div className="flex items-center gap-2">
                <h3 className={cn(
                  "font-semibold truncate",
                  isDarkMode ? "text-gray-200" : "text-gray-900"
                )}>
                  {linkedInProfile?.name || 'LinkedIn User'}
                </h3>
                <span className="text-sm">•</span>
                <span className={cn(
                  "text-sm",
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                )}>
                  1st
                </span>
              </div>
              
              <p className={cn(
                "text-sm truncate mt-0.5",
                isDarkMode ? "text-gray-400" : "text-gray-500"
              )}>
                {linkedInProfile?.headline || ''}
              </p>

              <div className="flex items-center gap-1 mt-1">
                <time className={cn(
                  "text-xs",
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                )}>
                  Now
                </time>
                <span className={cn(
                  "text-xs",
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                )}>•</span>
                <GlobeAltIcon className={cn(
                  "w-3 h-3",
                  isDarkMode ? "text-gray-400" : "text-gray-500"
                )} />
              </div>
            </div>
          </div>

          <button className={cn(
            "p-1.5 rounded-full",
            "transition-colors duration-200",
            isDarkMode 
              ? "hover:bg-white/5 text-gray-400"
              : "hover:bg-gray-100 text-gray-600"
          )}>
            <EllipsisHorizontalIcon className="w-5 h-5" />
          </button>
        </div>

        {/* Post Content */}
        <div className={cn(
          "px-4 pb-3",
          isDarkMode ? "text-gray-300" : "text-gray-600"
        )}>
          <p className="text-sm whitespace-pre-wrap">
            {post.content}
          </p>
        </div>

        {/* Media Content */}
        {renderMedia()}

        {/* Engagement Stats */}
        <div className={cn(
          "px-4 py-2 mt-2 flex items-center justify-between",
          "border-b",
          isDarkMode 
            ? "border-gray-700/50 text-gray-400"
            : "border-gray-100 text-gray-500"
        )}>
          <div className="flex items-center gap-1">
            <div className="flex -space-x-1 mr-1">
              <img 
                className={cn(
                  "rounded-full w-4 h-4",
                  isDarkMode ? "bg-[#2a2a2a]" : "bg-white"
                )} 
                src="https://static.kleo.so/icons/like.svg" 
                alt="Likes" 
              />
              <img 
                className={cn(
                  "rounded-full w-4 h-4",
                  isDarkMode ? "bg-[#2a2a2a]" : "bg-white"
                )} 
                src="https://static.kleo.so/icons/celebrate.svg" 
                alt="Celebrations" 
              />
              <img 
                className={cn(
                  "rounded-full w-4 h-4",
                  isDarkMode ? "bg-[#2a2a2a]" : "bg-white"
                )} 
                src="https://static.kleo.so/icons/support.svg" 
                alt="Empathy" 
              />
            </div>
            <span className="text-xs">428</span>
          </div>
          <div className="flex items-center gap-3 text-xs">
            <span>24 comments</span>
            <span>12 reposts</span>
          </div>
        </div>

        {/* Action Buttons */}
        <div className={cn(
          "px-2 py-1 grid grid-cols-4 gap-1",
          isDarkMode ? "text-gray-400" : "text-gray-600"
        )}>
          <button className={cn(
            "flex items-center justify-center gap-2 p-2 rounded-lg",
            "transition-colors duration-200",
            isDarkMode 
              ? "hover:bg-white/5"
              : "hover:bg-gray-100"
          )}>
            <HandThumbUpIcon className="w-5 h-5" />
            <span className="text-xs font-medium">Like</span>
          </button>
          <button className={cn(
            "flex items-center justify-center gap-2 p-2 rounded-lg",
            "transition-colors duration-200",
            isDarkMode 
              ? "hover:bg-white/5"
              : "hover:bg-gray-100"
          )}>
            <ChatBubbleOvalLeftIcon className="w-5 h-5" />
            <span className="text-xs font-medium">Comment</span>
          </button>
          <button className={cn(
            "flex items-center justify-center gap-2 p-2 rounded-lg",
            "transition-colors duration-200",
            isDarkMode 
              ? "hover:bg-white/5"
              : "hover:bg-gray-100"
          )}>
            <ArrowPathRoundedSquareIcon className="w-5 h-5" />
            <span className="text-xs font-medium">Repost</span>
          </button>
          <button className={cn(
            "flex items-center justify-center gap-2 p-2 rounded-lg",
            "transition-colors duration-200",
            isDarkMode 
              ? "hover:bg-white/5"
              : "hover:bg-gray-100"
          )}>
            <PaperAirplaneIcon className="w-5 h-5" />
            <span className="text-xs font-medium">Send</span>
          </button>
        </div>
      </motion.div>

      {/* Full Screen PDF Modal */}
      {showFullScreenPDF && (
        <div 
          className={cn(
            "fixed inset-0 z-50",
            "flex items-center justify-center",
            isDarkMode ? "bg-black/90" : "bg-white/90"
          )}
          onClick={() => setShowFullScreenPDF(false)}
        >
          <div 
            className="w-full h-full max-w-5xl p-4"
            onClick={e => e.stopPropagation()}
          >
            <PDFPreview
              url={mediaUrl}
              isDarkMode={isDarkMode}
              containerStyle="h-full"
            />
          </div>
          <button
            onClick={() => setShowFullScreenPDF(false)}
            className={cn(
              "absolute top-4 right-4",
              "p-2 rounded-full",
              isDarkMode 
                ? "bg-gray-800 hover:bg-gray-700 text-white" 
                : "bg-white hover:bg-gray-100 text-gray-900"
            )}
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

export default LinkedInPostPreview; 