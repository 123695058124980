import React from 'react';
import { motion } from 'framer-motion';
import PublicPageLayout from './layout/PublicPageLayout';
import PageSection from './shared/PageSection';
import { useTheme } from '../../context/ThemeContext';
import { ShieldCheckIcon } from '@heroicons/react/24/outline';

const TermsPage = () => {
  const { isDarkMode } = useTheme();

  const sections = [
    {
      title: '1. Terms of Use',
      content: `By accessing and using Ammmplify's platform and services, you agree to be bound by these Terms of Service. If you disagree with any part of these terms, you may not access our services.
      
      Scope of Agreement:
      • These Terms of Service apply to all users
      • Including content creators, businesses
      • AI content generation users
      • LinkedIn integration users
      • API users and integrations
      • Third-party applications
      
      Acceptance:
      • By using our services, you accept these terms
      • You must be at least 16 years old
      • You must have the authority to bind to these terms
      • You agree to provide accurate information
      • You accept our AI content generation policies
      • You comply with LinkedIn's terms of service`
    },
    {
      title: '2. Platform Usage',
      content: `Our platform is designed to help users create and manage content for LinkedIn using AI-powered tools. You agree to use the platform only for its intended purposes and in compliance with all applicable laws.
      
      Prohibited Activities:
      • Using the platform for illegal purposes
      • Violating intellectual property rights
      • Attempting unauthorized access
      • Generating harmful or misleading content
      • Misusing AI-generated content
      • Creating fake personas or profiles
      • Manipulating audience data
      • Interfering with service operations
      • Reverse engineering the platform
      • Circumventing security measures
      • Scraping or harvesting data
      • Impersonating others
      • Spreading malware or viruses
      
      Usage Limits:
      • AI generation quotas
      • API rate limits
      • Storage quotas
      • Concurrent sessions
      • Content creation limits
      • Integration restrictions
      • Persona profile limits
      • Audience insights limits`
    },
    {
      title: '3. Account Responsibilities',
      content: `When you create an account with us, you must provide accurate and complete information. You are responsible for:
      
      Account Security:
      • Maintaining account credentials
      • Protecting access to your account
      • Monitoring account activity
      • Reporting unauthorized access
      • Keeping information updated
      
      Content Management:
      • Accuracy of created content
      • Proper use of AI tools
      • LinkedIn posting compliance
      • Persona profile accuracy
      • Audience data integrity
      
      Integration Management:
      • LinkedIn connection security
      • API key protection
      • Third-party app security
      • Integration permissions`
    },
    {
      title: '4. Content Guidelines',
      content: `You retain ownership of any content you create using our platform. However, you grant us a license to use, store, and display your content in connection with providing our services.
      
      Content Ownership:
      • You own your original content
      • You own your persona profiles
      • You own your audience data
      • You own your custom templates
      
      AI-Generated Content:
      • Must be reviewed before publishing
      • Cannot be used for deception
      • Must comply with LinkedIn policies
      • Must be appropriately disclosed
      
      Content Requirements:
      • Must be accurate and truthful
      • Cannot violate others' rights
      • Must follow LinkedIn guidelines
      • Must be professionally appropriate
      • Cannot be discriminatory or harmful`
    },
    {
      title: '5. Service Modifications',
      content: `We reserve the right to modify or discontinue our service at any time, with or without notice.
      
      Potential Changes:
      • Feature availability
      • AI capabilities
      • Integration options
      • Pricing structure
      • Platform functionality
      • Data retention policies
      
      Impact Management:
      • Notice when possible
      • Data export options
      • Transition assistance
      • Service continuity plans`
    },
    {
      title: '6. Payment Terms',
      content: `For paid subscriptions:
      
      Billing Terms:
      • Secure payment processing
      • Automatic renewal
      • Usage-based billing
      • Feature-based pricing
      
      Subscription Features:
      • AI generation limits
      • Persona profile limits
      • Audience insight access
      • Integration capabilities
      • Advanced analytics
      
      Refund Policy:
      • Pro-rated refunds
      • Cancellation terms
      • Usage-based adjustments
      • Service credit options`
    },
    {
      title: '7. Intellectual Property',
      content: `Ownership and Rights:
      • We own platform IP
      • You own your content
      • You own your data
      • We license AI models
      
      Platform License:
      • Non-exclusive
      • Non-transferable
      • Revocable
      • Limited to platform use
      
      Your Content License to Us:
      • Limited platform usage
      • AI model improvement
      • Service enhancement
      • Marketing (with consent)
      
      AI-Generated Content:
      • Usage rights
      • Distribution terms
      • Attribution requirements
      • Commercial limitations`
    },
    {
      title: '8. Limitation of Liability',
      content: `To the fullest extent permitted by law:
      
      We are not liable for:
      • AI generation accuracy
      • Content performance
      • LinkedIn integration issues
      • Third-party actions
      • Data loss or corruption
      • Service interruptions
      • Market conditions
      • External platform changes
      
      Liability Caps:
      • Limited to fees paid
      • Past 12 months
      • Or $100, whichever is greater
      
      Exceptions:
      • Gross negligence
      • Willful misconduct
      • Death or personal injury
      • Fraud or fraudulent misrepresentation`
    },
    {
      title: '9. Dispute Resolution',
      content: `Resolution Process:
      • Informal negotiation first
      • Mandatory mediation
      • Binding arbitration
      • Small claims option
      
      Covered Disputes:
      • Platform usage
      • Content ownership
      • AI generation issues
      • Integration problems
      • Payment disputes
      • Data handling
      
      Resolution Timeline:
      • 30 days for negotiation
      • 60 days for mediation
      • 90 days for arbitration
      • Expedited if needed`
    },
    {
      title: '10. Indemnification',
      content: `You agree to indemnify us against:
      • Content violations
      • AI misuse claims
      • Integration issues
      • Data breaches caused by you
      • Third-party claims
      • Legal fees and costs
      
      Process:
      • Prompt notification
      • Right to defend
      • Your cooperation
      • Settlement approval
      
      Limitations:
      • Proportional liability
      • Reasonable costs
      • Written demands only
      • Fair resolution process`
    },
    {
      title: '11. Termination',
      content: `Account Termination:
      • For terms violation
      • For harmful content
      • For AI misuse
      • For integration abuse
      • For non-payment
      
      Data Handling:
      • Content preservation
      • Data export options
      • Deletion timeline
      • Recovery period
      
      Post-Termination:
      • Access restriction
      • Data retention
      • Final export
      • Service discontinuation`
    }
  ];

  return (
    <PublicPageLayout
      title="Terms"
      description="Our service agreement"
      className="-mt-8"
    >
      <PageSection className="py-4">
        <div className="max-w-2xl mx-auto space-y-6">
          {/* Header */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className={`
              p-4 rounded-xl border flex items-center justify-between
              ${isDarkMode 
                ? 'bg-[#151515] border-white/10' 
                : 'bg-white border-slate-200/50'
              }
            `}
          >
            <div className="flex items-center gap-3">
              <ShieldCheckIcon className="w-5 h-5 text-blue-500" />
              <p className="text-sm font-medium">
                Last updated: January 19, 2025
              </p>
            </div>
            <a
              href="mailto:vinay@ammmplify.com"
              className={`
                text-xs
                ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-700'}
              `}
            >
              vinay@ammmplify.com
            </a>
          </motion.div>

          {/* Quick Links */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="flex flex-wrap gap-2"
          >
            {sections.map((section, index) => (
              <button
                key={index}
                onClick={() => document.getElementById(section.title).scrollIntoView({ behavior: 'smooth' })}
                className={`
                  text-xs px-3 py-1.5 rounded-lg transition-colors
                  ${isDarkMode 
                    ? 'bg-[#151515] border border-white/10 hover:bg-[#1a1a1a]' 
                    : 'bg-white border border-slate-200/50 hover:bg-slate-50'
                  }
                `}
              >
                {section.title.replace(/^\d+\.\s/, '')}
              </button>
            ))}
          </motion.div>

          {/* Sections */}
          <div className="space-y-4">
            {sections.map((section, index) => (
              <motion.div
                id={section.title}
                key={section.title}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.05 }}
                className={`
                  p-4 rounded-xl border scroll-mt-4
                  ${isDarkMode 
                    ? 'bg-[#151515] border-white/10' 
                    : 'bg-white border-slate-200/50'
                  }
                `}
              >
                <h3 className={`
                  text-sm font-medium mb-3
                  ${isDarkMode ? 'text-white' : 'text-slate-900'}
                `}>
                  {section.title}
                </h3>
                <div className={`
                  text-xs space-y-2
                  ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
                `}>
                  <p className="whitespace-pre-line">
                    {section.content}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </PageSection>
    </PublicPageLayout>
  );
};

export default TermsPage; 