import supabase from './supabaseAuth';
import api from '../api';

export const requestPasswordReset = async (email) => {
  try {
    console.log('Requesting password reset for:', email);
    
    // Send password reset email through Supabase
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/auth/reset-password`,
    });

    if (error) throw error;

    return {
      success: true,
      message: 'Password reset instructions sent to your email'
    };
  } catch (error) {
    console.error('Password reset request error:', error);
    throw error;
  }
};

export const resetPassword = async (newPassword) => {
  try {
    console.log('Resetting password');
    
    // Update password in Supabase
    const { data: { user }, error: supabaseError } = await supabase.auth.updateUser({
      password: newPassword
    });

    if (supabaseError) throw supabaseError;

    // Update password in MongoDB
    const response = await api.post('/api/auth/update-password', {
      supabaseUid: user.id,
      newPassword
    });

    return {
      success: true,
      message: 'Password updated successfully'
    };
  } catch (error) {
    console.error('Password reset error:', error);
    throw error;
  }
};

export const sendMagicLink = async (email) => {
  try {
    console.log('Sending magic link to:', email);
    
    // Send magic link through Supabase
    const { data, error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: process.env.NODE_ENV === 'production' 
          ? 'https://ammmplify.com/auth/callback?type=magiclink'
          : `${window.location.origin}/auth/callback?type=magiclink`,
        shouldCreateUser: true,
      },
    });

    console.log('Magic link response:', {
      success: !error,
      hasData: !!data,
      error: error?.message
    });

    if (error) throw error;

    return {
      success: true,
      message: 'Magic link sent to your email'
    };
  } catch (error) {
    console.error('Magic link request error:', {
      message: error.message,
      code: error.code,
      status: error.status
    });
    throw error;
  }
};

export const verifyMagicLink = async (token) => {
  try {
    console.log('Verifying magic link token');
    
    // Verify OTP token with Supabase
    const { data: { user }, error } = await supabase.auth.verifyOtp({
      token_hash: token,
      type: 'magiclink'
    });

    if (error) throw error;

    // Sync with MongoDB
    await api.post('/api/auth/sync-magic-link', {
      supabaseUid: user.id,
      email: user.email
    });

    return {
      success: true,
      user
    };
  } catch (error) {
    console.error('Magic link verification error:', error);
    throw error;
  }
}; 