import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../../context/ThemeContext';
import useScreenSize from '../../../hooks/useScreenSize';
import { 
  UserGroupIcon,
  ChatBubbleBottomCenterTextIcon,
  Squares2X2Icon,
  PlusIcon
} from '@heroicons/react/24/outline';
import NewChatIcon from '../../icons/NewChatIcon';
import { CanvasGridIcon } from '../../icons/CanvasGridIcon';

// Add font imports and styling
const fontStyles = `
  @import url('https://fonts.googleapis.com/css2?family=Instrument+Serif:wght@400;500&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

  .title-font {
    font-family: 'Instrument Serif', 'Inter', -apple-system, sans-serif;
    font-feature-settings: "liga" 1, "kern" 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    letter-spacing: 0.01em;
  }
`; 

// Add style tag to head
if (!document.getElementById('context-bank-fonts')) {
  const style = document.createElement('style');
  style.id = 'context-bank-fonts';
  style.innerHTML = fontStyles;
  document.head.appendChild(style);
}

// Add custom hook for image loop
const useImageLoop = (images, interval = 3000) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images.length, interval]);

  return currentIndex;
};

// Image transition component
const TransitioningImage = ({ images, alt, isDarkMode }) => {
  const currentIndex = useImageLoop(images);

  return (
    <div className="relative aspect-[16/9] w-full overflow-hidden rounded-t-xl">
      <AnimatePresence initial={false} mode="wait">
        <motion.img
          key={currentIndex}
          src={images[currentIndex]}
          alt={alt}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="absolute inset-0 w-full h-full object-cover object-top"
          loading="lazy"
        />
      </AnimatePresence>
      <div className={`
        absolute inset-0
        ${isDarkMode 
          ? 'bg-gradient-to-b from-transparent via-[#1a1a1a]/40 to-[#1a1a1a]' 
          : 'bg-gradient-to-b from-transparent via-white/40 to-white'
        }
      `}/>
    </div>
  );
};

const ContextBankSection = () => {
  const { isDarkMode } = useTheme();
  const { isMobile } = useScreenSize();
  
  const sources = [
    {
      icon: NewChatIcon,
      title: "Content Studio",
      description: "One chat interface to create content for your LinkedIn. Transform your ideas into viral-worthy content in minutes.",
      features: ["AI-powered writing", "Personalized content", "Smart suggestions"],
      image: "/contextlanding/content sudio.webp"
    },
    {
      icon: UserGroupIcon,
      title: "Personas & Audiences",
      description: "Hyper-personalize every piece of content for your brand. Create profiles that capture your unique voice and style.",
      features: ["Voice matching", "Style analysis", "Brand guidelines"],
      image: "/contextlanding/personas.webp"
    },
    {
      icon: PlusIcon,
      title: "Context Superpowers",
      description: "From notes, ideas, and transcripts to viral-worthy content in minutes. All your content is instantly organized and searchable.",
      features: ["Voice notes", "Text notes", "File uploads"],
      image: "/contextlanding/voicenotes.webp"
    },
    {
      icon: Squares2X2Icon,
      title: "Custom Templates",
      description: "Transform your training data into custom AI apps. Create and customize templates to maintain consistency in your content.",
      features: ["Custom formats", "Structure guides", "Format library"],
      image: "/contextlanding/post templates.webp"
    },
    {
      icon: ChatBubbleBottomCenterTextIcon,
      title: "Comments Cowriter",
      description: "Generate you-like comments in seconds. Maintain engagement and build meaningful connections with your audience.",
      features: ["Smart replies", "Voice matching", "Quick responses"],
      image: "/contextlanding/collections-1.webp"
    },
    {
      icon: CanvasGridIcon,
      title: "Collections",
      description: "Manage, edit and schedule your LinkedIn posts. Organize your content library for maximum impact.",
      features: ["Content management", "Smart scheduling", "Performance tracking"],
      image: "/contextlanding/collections.webp"
    }
  ];

  const MobileContextBank = () => (
    <div 
      id="context-bank-section"
      className={`py-12 relative overflow-hidden ${
        isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
      }`}
    >
      <div className="px-5">
        {/* Section Tag and Header */}
        <div className="mb-8">
          <span className={`
            inline-block px-3 py-1.5 text-xs font-medium 
            uppercase tracking-wider rounded-full
            ${isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
            }
          `}>
            Content Creation
          </span>
        </div>

        <h2 className={`
          text-2xl font-normal leading-tight mb-3 title-font
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}>
          Your AI-Powered{' '}
          <span className={`block mt-1 title-font ${
            isDarkMode ? 'text-white/60' : 'text-slate-900/60'
          }`}>
            Content Studio
          </span>
        </h2>

        <p className={`
          text-sm mb-10 
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}>
          Everything you need to create engaging content that resonates with your audience
        </p>

        {/* Cards Grid */}
        <div className="space-y-5">
          {sources.map((source, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
              className={`
                relative p-6 rounded-xl overflow-hidden
                backdrop-blur-xl backdrop-saturate-150
                ${isDarkMode 
                  ? 'bg-[#1a1a1a]/90 border border-white/10' 
                  : 'bg-white/90 border border-black/5'
                }
              `}
            >
              {/* Image Section */}
              <div className={`${isMobile ? "mb-6 -mx-6 -mt-6" : "mb-8 -mx-8 -mt-8"}`}>
                {source.title === "Personas & Audiences" ? (
                  <TransitioningImage
                    images={["/contextlanding/personas.webp", "/contextlanding/audience.webp"]}
                    alt={source.title}
                    isDarkMode={isDarkMode}
                  />
                ) : (
                  <div className="relative aspect-[16/9] w-full overflow-hidden rounded-t-xl">
                    <img 
                      src={source.image}
                      alt={source.title}
                      className="w-full h-full object-cover object-top"
                      loading="lazy"
                    />
                    <div className={`
                      absolute inset-0
                      ${isDarkMode 
                        ? 'bg-gradient-to-b from-transparent via-[#1a1a1a]/40 to-[#1a1a1a]' 
                        : 'bg-gradient-to-b from-transparent via-white/40 to-white'
                      }
                    `}/>
                  </div>
                )}
              </div>

              <div className="flex flex-col">
                {/* Rest of the card content */}
                <div className="flex items-center gap-3 mb-3">
                  <div className={`
                    w-10 h-10 rounded-xl
                    flex items-center justify-center flex-shrink-0
                    ${isDarkMode 
                      ? 'bg-blue-900/20 text-blue-400 border border-blue-500/20' 
                      : 'bg-blue-50 text-blue-600 border border-blue-100'
                    }
                  `}>
                    <source.icon className="h-5 w-5" />
                  </div>
                  <h3 className={`
                    text-lg font-semibold
                    ${isDarkMode ? 'text-white' : 'text-slate-900'}
                  `}>
                    {source.title}
                  </h3>
                </div>

                <div className="flex-1 min-w-0">
                  <p className={`
                    text-sm mb-4 leading-relaxed
                    ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                  `}>
                    {source.description}
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {source.features.map((feature, featureIndex) => (
                      <span
                        key={featureIndex}
                        className={`
                          inline-flex items-center px-3 py-1.5 
                          rounded-full text-xs font-medium
                          ${isDarkMode
                            ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50'
                            : 'bg-slate-50 text-slate-600 border border-slate-200/50'
                          }
                        `}
                      >
                        <span className={`
                          mr-1.5 w-1 h-1 rounded-full
                          ${isDarkMode ? 'bg-slate-400' : 'bg-slate-500'}
                        `} />
                        {feature}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );

  const DesktopContextBank = () => (
    <div 
      id="context-bank-section"
      className={`py-24 sm:py-32 relative overflow-hidden ${
        isDarkMode ? 'bg-[#121212]/0' : 'bg-white/0'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Header - Updated with flex layout */}
        <div className="flex items-center justify-between mb-12">
          <div className="max-w-xl">
            <h2 className={`
              text-3xl sm:text-4xl font-normal tracking-normal leading-tight sm:leading-normal title-font
              ${isDarkMode ? 'text-white' : 'text-slate-900'}
            `}>
              Your AI-Powered{' '}
              <span className={`
                title-font
                ${isDarkMode 
                  ? 'text-white/60' 
                  : 'text-slate-900/60'
                }
              `}>
                Content Studio
              </span>
            </h2>
          </div>
          
          <span className={`inline-block px-3 py-1.5 text-xs font-medium uppercase tracking-wider rounded-full ${
            isDarkMode 
              ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50' 
              : 'bg-white text-slate-600 border border-slate-200'
          }`}>
            Content Creation
          </span>
        </div>

        {/* Updated grid layout for 6 cards */}
        <div className="grid grid-cols-3 gap-4">
          {sources.map((source, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
              className={`
                relative p-8 rounded-xl overflow-hidden
                backdrop-blur-xl backdrop-saturate-150
                ${isDarkMode 
                  ? 'bg-[#1a1a1a]/90 border border-white/10' 
                  : 'bg-white/90 border border-black/5'
                }
              `}
            >
              {/* Image Section */}
              <div className={`${isMobile ? "mb-6 -mx-6 -mt-6" : "mb-8 -mx-8 -mt-8"}`}>
                {source.title === "Personas & Audiences" ? (
                  <TransitioningImage
                    images={["/contextlanding/personas.webp", "/contextlanding/audience.webp"]}
                    alt={source.title}
                    isDarkMode={isDarkMode}
                  />
                ) : (
                  <div className="relative aspect-[16/9] w-full overflow-hidden rounded-t-xl">
                    <img 
                      src={source.image}
                      alt={source.title}
                      className="w-full h-full object-cover object-top"
                      loading="lazy"
                    />
                    <div className={`
                      absolute inset-0
                      ${isDarkMode 
                        ? 'bg-gradient-to-b from-transparent via-[#1a1a1a]/40 to-[#1a1a1a]' 
                        : 'bg-gradient-to-b from-transparent via-white/40 to-white'
                      }
                    `}/>
                  </div>
                )}
              </div>

              <div className="space-y-6">
                <div className={`
                  inline-flex p-2 rounded-lg
                  ${isDarkMode 
                    ? 'bg-blue-900/20 text-blue-400 border border-blue-500/20' 
                    : 'bg-blue-50 text-blue-600 border border-blue-100'
                  }
                `}>
                  <source.icon className="h-6 w-6" />
                </div>
                
                <div>
                  <h3 className={`text-xl font-semibold mb-3 ${
                    isDarkMode ? 'text-white' : 'text-slate-900'
                  }`}>
                    {source.title}
                  </h3>
                  <p className={`text-sm mb-6 ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                  }`}>
                    {source.description}
                  </p>
                </div>

                <div className="flex flex-wrap gap-2">
                  {source.features.map((feature, featureIndex) => (
                    <span
                      key={featureIndex}
                      className={`
                        inline-block px-3 py-1 rounded-full text-xs font-medium
                        ${isDarkMode
                          ? 'bg-slate-800/80 text-slate-300 border border-slate-700/50'
                          : 'bg-slate-50 text-slate-600 border border-slate-200/50'
                        }
                      `}
                    >
                      {feature}
                    </span>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );

  return isMobile ? <MobileContextBank /> : <DesktopContextBank />;
};

export default ContextBankSection; 