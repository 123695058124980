import React, { useState, useEffect, useCallback, useRef, useMemo, useContext } from 'react';
import { PlusIcon, PencilIcon, TrashIcon, MagnifyingGlassIcon, FolderIcon, DocumentIcon, DocumentTextIcon, EyeIcon, ArrowUpTrayIcon, EllipsisVerticalIcon, CheckIcon, HomeIcon, ChevronRightIcon, ChatBubbleLeftRightIcon, AcademicCapIcon, FolderPlusIcon, Squares2X2Icon, ListBulletIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { fetchKnowledgeBases, createFolder, uploadDocument, deleteItem, updateItem, fetchFolderById, getStorageUsage } from '../services/mongoService';
import { toast } from 'react-hot-toast';
import { DndProvider, useDrag, useDrop, useDragLayer } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import NewFolderModal from './NewFolderModal';
import EditFolderModal from './EditFolderModal';
import TreeDropdown from './TreeDropdown';
import Portal from './Portal';
import Notification from './Notification';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import api from '../api';
import { saveFileMetadata } from '../services/mongoService';
import UploadProgress from './UploadProgress';
import { formatFileSize } from './ItemTooltip';
import FilePreviewIcon from './FilePreviewIcon';
import PopupMenu from './PopupMenu';
import ContextMenu from './ContextMenu';
import FilePreview from './FilePreview';
import { uploadFile, downloadFile } from '../api';
import { AuthContext } from '../context/AuthContext';
import DownloadProgress from './DownloadProgress';
import { useChat } from '../context/ChatContext';
import DownloadNotification from './DownloadNotification';
import FileUploadPopover from './FileUploadPopover';
import FileItem from './FileItem';
import FolderItem from './FolderItem';
import FolderInfoPopup from './FolderInfoPopup';
import ItemRow from './ItemRow';
import FileInfoPopup from './FileInfoPopup';
import { useTheme } from '../context/ThemeContext';
import { formatDistanceToNow } from 'date-fns';
import { useResizeDetector } from 'react-resize-detector';
import ActionButtonGroup from './ActionButtonGroup';
import TableHeader from './TableHeader';
import { motion } from 'framer-motion';

const KnowledgeBaseEditor = ({ knowledgeBaseId, onClose }) => {
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await api.get(`/files/${knowledgeBaseId}/content`);
        setContent(response.data);
      } catch (error) {
        console.error('Error fetching knowledge base content:', error);
        toast.error('Failed to load knowledge base content. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchContent();
  }, [knowledgeBaseId]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await api.post(`/files/${knowledgeBaseId}/content`, { content });
      toast.success('Content saved successfully');
      onClose();
    } catch (error) {
      console.error('Error saving content:', error);
      toast.error('Failed to save content. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
        <div className="text-white">Loading content...</div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-2">Edit Knowledge Base</h3>
        <textarea
          className="w-full h-40 p-2 border rounded"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Enter your knowledge base content here..."
        />
        <div className="mt-4">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
          >
            {isSaving ? 'Saving...' : 'Save'}
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const NoResultsMessage = ({ searchTerm, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.4 }}
    className={`
      flex flex-col items-center justify-center p-12
      backdrop-blur-xl backdrop-saturate-150 mx-auto rounded-2xl
      ${isDarkMode 
        ? 'bg-[#1a1a1a]/90 border border-white/10 shadow-black/50' 
        : 'bg-white/90 border border-black/5 shadow-black/10'}
    `}
  >
    <div className={`
      p-4 rounded-full mb-6 flex items-center justify-center
      ${isDarkMode 
        ? 'bg-[#252525] text-blue-400/80' 
        : 'bg-slate-100 text-blue-600/80'}
    `}>
      <DocumentTextIcon className="w-8 h-8" />
    </div>
    
    <div className="text-center max-w-sm flex flex-col items-center">
      <motion.h3 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className={`text-lg font-semibold mb-3 tracking-tight
          ${isDarkMode ? 'text-white' : 'text-slate-900'}
        `}
      >
        {searchTerm ? `No results for "${searchTerm}"` : 'No files yet'}
      </motion.h3>
      
      <motion.p 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className={`text-sm max-w-[15rem] mx-auto
          ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
        `}
      >
        {searchTerm 
          ? 'Try different keywords or upload a new file' 
          : 'Start by uploading your first file or creating a folder'}
      </motion.p>
    </div>
  </motion.div>
);

const IconButton = React.forwardRef(({ icon: Icon, onClick, title, color }, ref) => {
  return (
    <button
      ref={ref}
      onClick={onClick}
      className={`p-1 rounded-full transition-all duration-200 ease-in-out transform hover:bg-${color}-100 group`}
      title={title}
    >
      <Icon className={`w-4 h-4 text-gray-600 group-hover:text-${color}-600`} />
    </button>
  );
});

const GridItem = ({ item, onEdit, onDelete, onPreview, onMove, onDownload, index, moveItem, onFolderClick, isMoving, folders, setPreviewFile }) => {
  const [isDropTarget, setIsDropTarget] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'GRID_ITEM',
    item: { id: item._id, index, type: item.type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'GRID_ITEM',
    hover: (draggedItem, monitor) => {
      if (draggedItem.id !== item._id && item.type === 'folder') {
        setIsDropTarget(true);
      }
    },
    drop: (draggedItem, monitor) => {
      if (draggedItem.id !== item._id && item.type === 'folder') {
        moveItem(draggedItem.id, item._id);
      }
      setIsDropTarget(false);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }), [item._id, item.type, moveItem]);

  useEffect(() => {
    if (!isOver) {
      setIsDropTarget(false);
    }
  }, [isOver]);

  return (
    <div
      ref={(node) => {
        drag(drop(node));
      }}
      className={`bg-white rounded-lg transition-all duration-200 ${
        isDragging ? 'opacity-50' : ''
      } ${isDropTarget ? 'border-2 border-blue-500' : 'border border-gray-200'} relative w-full flex flex-col p-3`}
    >
      {item.type === 'folder' ? (
        <FolderItem
          item={item}
          onEdit={onEdit}
          onDelete={onDelete}
          onMove={onMove}
          onFolderClick={onFolderClick}
          folders={folders}
          onDownload={onDownload}
        />
      ) : (
        <FileItem
          item={item}
          onEdit={onEdit}
          onDelete={onDelete}
          onPreview={onPreview}
          onDownload={onDownload}
          onMove={onMove}
          folders={folders}
          setPreviewFile={setPreviewFile}
          index={index}
          moveItem={moveItem}
        />
      )}
    </div>
  );
};

const DragPreview = ({ isDarkMode }) => {
  const { isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging || !currentOffset) {
    return null;
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;

  const styles = {
    rowContent: `flex items-center transition-all duration-150 ease-in-out rounded-lg mx-1 my-0.5 ${
      isDarkMode ? 'bg-slate-800' : 'bg-gray-100'
    }`,
    cell: 'py-2 px-3',
    icon: `w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-400'}`,
    text: isDarkMode ? 'text-slate-200' : 'text-gray-900',
    skeleton: `h-4 ${isDarkMode ? 'bg-slate-700' : 'bg-gray-300'} rounded`,
  };

  const getIcon = () => {
    return item.type === 'folder' ? (
      <FolderIcon className={styles.icon} />
    ) : (
      <DocumentIcon className={styles.icon} />
    );
  };

  return (
    <div style={{
      position: 'fixed',
      pointerEvents: 'none',
      zIndex: 100,
      left: 0,
      top: 0,
      transform: transform,
      width: 'calc(100% - 48px)',
      maxWidth: '1200px',
      padding: '0 24px',
    }}>
      <div className={`${styles.rowContent} w-full`}>
        <div className={`${styles.cell} flex items-center flex-grow`}>
          <div className="relative">
            {getIcon()}
          </div>
          <span className={`ml-2 ${styles.text} truncate`}>{item.name}</span>
        </div>
        <div className={`${styles.cell} w-1/4`}>
          <div className={`${styles.skeleton} w-16`}></div>
        </div>
        <div className={`${styles.cell} w-1/6`}>
          <div className={`${styles.skeleton} w-12`}></div>
        </div>
        <div className={`${styles.cell} w-12`}>
          <div className={`${styles.skeleton} w-6`}></div>
        </div>
      </div>
    </div>
  );
};

const KnowledgeBaseManager = () => {
  const { user } = useContext(AuthContext);
  const { isDarkMode } = useTheme();

  const [itemsMap, setItemsMap] = useState(new Map());
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingKnowledgeBase, setEditingKnowledgeBase] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [previewContent, setPreviewContent] = useState(null);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [isEditFolderModalOpen, setIsEditFolderModalOpen] = useState(false);
  const [editingFolder, setEditingFolder] = useState(null);
  const [isMoving, setIsMoving] = useState(false);
  const [itemToMove, setItemToMove] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [downloads, setDownloads] = useState([]);
  const [isFileUploadPopoverOpen, setIsFileUploadPopoverOpen] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [infoItem, setInfoItem] = useState(null);
  const [visiblePopupId, setVisiblePopupId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const [showSearchBar, setShowSearchBar] = useState(false);

  const navigate = useNavigate();
  const { '*': urlPath } = useParams();
  const { addFileToChat } = useChat();

  const { ref: mainContentRef, height: mainContentHeight } = useResizeDetector();

  const showToast = useCallback((message, type = 'info') => {
    const newNotification = {
      id: Date.now(),
      title: type.charAt(0).toUpperCase() + type.slice(1),
      message,
      type
    };
    setNotifications(prev => [...prev, newNotification]);
  }, []);

  const isSubfolder = useCallback((draggedItem, targetFolder, items) => {
    let currentFolder = targetFolder;
    while (currentFolder) {
      if (currentFolder._id === draggedItem._id) {
        return true;
      }
      currentFolder = items.get(currentFolder.parentId);
    }
    return false;
  }, []);

  const updateItems = useCallback((newItems) => {
    setItemsMap(new Map(newItems.map(item => [item._id, item])));
  }, []);

  const buildPathFromUrl = useCallback(async (urlPath) => {
    const pathSegments = urlPath ? urlPath.split('/') : [];
    const newPath = [];
    for (let i = 0; i < pathSegments.length; i++) {
      const folderId = pathSegments[i];
      try {
        const folderData = await fetchFolderById(folderId);
        newPath.push({ _id: folderData._id, name: folderData.name });
      } catch (error) {
        console.error('Error fetching folder data:', error);
        break;
      }
    }
    return newPath;
  }, []);

  const loadKnowledgeBases = useCallback(async (folderId = null) => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Fetching knowledge bases for folder:', folderId);
      const data = await fetchKnowledgeBases(folderId);
      console.log('Fetched knowledge bases:', data);
      updateItems(data);
      setCurrentFolderId(folderId);
      
      if (folderId) {
        const folder = data.find(item => item._id === folderId && item.type === 'folder');
        if (folder) {
          setCurrentPath(prevPath => {
            const existingIndex = prevPath.findIndex(item => item._id === folder._id);
            if (existingIndex !== -1) {
              return prevPath.slice(0, existingIndex + 1);
            } else {
              return [...prevPath, { _id: folder._id, name: folder.name }];
            }
          });
        }
      } else {
        setCurrentPath([]);
      }
    } catch (error) {
      console.error('Error fetching knowledge bases:', error);
      setError('Failed to load knowledge bases. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [updateItems]);

  const handleMoveInitiate = (item) => {
    console.log('Initiating move for item:', item);
    setIsMoving(true);
    setItemToMove(item);
  };

  const handleMove = useCallback(async (itemId, destinationFolderId) => {
    try {
      const newParentId = destinationFolderId === 'home' ? null : destinationFolderId;
      const updatedItem = await updateItem(itemId, { parentId: newParentId });
      
      setItemsMap(prevMap => {
        const newMap = new Map(prevMap);
        if (currentFolderId !== newParentId) {
          newMap.delete(itemId);
        }
        newMap.set(updatedItem._id, updatedItem);
        return newMap;
      });

      if (currentPath.some(pathItem => pathItem._id === itemId)) {
        setCurrentPath(prevPath => {
          const itemIndex = prevPath.findIndex(pathItem => pathItem._id === itemId);
          return prevPath.slice(0, itemIndex);
        });
      }

      await loadKnowledgeBases(currentFolderId);
      toast.success('Item moved successfully');
    } catch (error) {
      console.error('Error moving item:', error);
      toast.error('Failed to move item. Please try again.');
    } finally {
      setIsMoving(false);
      setItemToMove(null);
    }
  }, [currentFolderId, currentPath, loadKnowledgeBases]);

  const moveItem = useCallback(async (draggedItemId, targetFolderId) => {
    try {
      const draggedItem = itemsMap.get(draggedItemId);
      const targetFolder = itemsMap.get(targetFolderId);

      if (!draggedItem || !targetFolder || targetFolder.type !== 'folder') {
        throw new Error('Invalid move operation');
      }

      if (isSubfolder(draggedItem, targetFolder, itemsMap)) {
        throw new Error("Can't move a folder into its own subfolder");
      }

      // Optimistically update the UI
      setItemsMap(prevMap => {
        const newMap = new Map(prevMap);
        const updatedItem = { ...draggedItem, parentId: targetFolderId };
        newMap.set(draggedItemId, updatedItem);
        
        // Remove the item from its previous parent's children array
        if (draggedItem.parentId) {
          const previousParent = newMap.get(draggedItem.parentId);
          if (previousParent) {
            previousParent.children = previousParent.children.filter(id => id !== draggedItemId);
            newMap.set(draggedItem.parentId, previousParent);
          }
        }
        
        // Update the target folder's children array
        const updatedTargetFolder = { 
          ...targetFolder, 
          children: [...(targetFolder.children || []), draggedItemId] 
        };
        newMap.set(targetFolderId, updatedTargetFolder);
        
        return newMap;
      });

      // Perform the actual move operation on the server
      await updateItem(draggedItemId, { parentId: targetFolderId });

      // Refresh the current folder to ensure consistency
      await loadKnowledgeBases(currentFolderId);

      showToast('Item moved successfully', 'success');
    } catch (error) {
      console.error('Error moving item:', error);
      showToast(error.message || 'Failed to move item. Please try again.', 'error');
      
      // Revert the optimistic update
      await loadKnowledgeBases(currentFolderId);
    }
  }, [itemsMap, updateItem, isSubfolder, showToast, loadKnowledgeBases, currentFolderId]);

  useEffect(() => {
    const loadInitialData = async () => {
      const pathSegments = urlPath ? urlPath.split('/') : [];
      const folderId = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : null;
      const newPath = await buildPathFromUrl(urlPath);
      setCurrentPath(newPath);
      setCurrentFolderId(folderId);
      loadKnowledgeBases(folderId);
    };
    loadInitialData();
  }, [urlPath, buildPathFromUrl, loadKnowledgeBases]);

  const handleFolderClick = useCallback((folder) => {
    setCurrentFolderId(folder._id);
    setCurrentPath(prevPath => [...prevPath, { _id: folder._id, name: folder.name }]);
    navigate(`/files/${[...currentPath, folder].map(f => f._id).join('/')}`);
    loadKnowledgeBases(folder._id);
  }, [currentPath, navigate, loadKnowledgeBases]);

  const filteredItems = useMemo(() => {
    return Array.from(itemsMap.values()).filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [itemsMap, searchTerm]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateFolder = async (folderName) => {
    try {
      const newFolder = await createFolder(currentFolderId, folderName);
      setItemsMap(prevMap => new Map(prevMap).set(newFolder._id, newFolder));
      toast.success('Folder created successfully');
    } catch (error) {
      console.error('Error creating folder:', error);
      toast.error('Failed to create folder. Please try again.');
    }
  };

  const handleRename = useCallback(async (item, newName) => {
    try {
      const updatedItem = await updateItem(item._id, { name: newName });
      setItemsMap(prevMap => new Map(prevMap).set(updatedItem._id, updatedItem));
      if (item.type === 'folder') {
        setCurrentPath(prevPath => prevPath.map(pathItem => 
          pathItem._id === updatedItem._id ? { ...pathItem, name: newName } : pathItem
        ));
      }
      toast.success('Item renamed successfully');
    } catch (error) {
      console.error('Error renaming item:', error);
      toast.error('Failed to rename item. Please try again.');
    }
  }, []);

  const confirmDeleteItem = async () => {
    if (confirmDelete) {
      try {
        await deleteItem(confirmDelete.id);
        await loadKnowledgeBases(currentFolderId);
        toast.success(`${confirmDelete.type === 'folder' ? 'Folder' : 'File'} deleted successfully`);
      } catch (error) {
        console.error('Error deleting item:', error);
        toast.error(`Error deleting ${confirmDelete.type}`);
      }
      setConfirmDelete(null);
    }
  };

  const handlePreview = async (item) => {
    try {
      const response = await api.get(`/files/${item._id}/content`);
      setPreviewFile({ ...item, content: response.data });
    } catch (error) {
      console.error('Error fetching file content:', error);
      toast.error('Error fetching file content');
    }
  };

  const handleDelete = useCallback(async (item) => {
    const isFolder = item.type === 'folder';
    
    const confirmMessage = isFolder 
      ? 'Are you sure you want to delete this folder and ALL its contents? This action cannot be undone.'
      : 'Are you sure you want to delete this file? This action cannot be undone.';
    
    if (!window.confirm(confirmMessage)) {
      return;
    }

    try {
      await deleteItem(item._id);
      
      setItemsMap(prevMap => {
        const newMap = new Map(prevMap);
        
        if (isFolder) {
          for (const [id, mapItem] of newMap.entries()) {
            if (mapItem.parentId === item._id) {
              newMap.delete(id);
            }
          }
        }
        
        newMap.delete(item._id);
        return newMap;
      });

      toast.success(
        isFolder 
          ? 'Folder and its contents deleted successfully'
          : 'File deleted successfully'
      );

      if (currentFolderId === item._id) {
        const parentFolder = currentPath[currentPath.length - 2];
        const newPath = currentPath.slice(0, -1);
        setCurrentPath(newPath);
        setCurrentFolderId(parentFolder ? parentFolder._id : null);
        navigate(`/files/${newPath.map(f => f._id).join('/')}`);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error(`Error deleting ${isFolder ? 'folder' : 'file'}: ${error.message}`);
    }
  }, [currentFolderId, currentPath, navigate]);

  const openEditFolderModal = (folder) => {
    console.log('Opening edit folder modal with folder:', folder);
    setEditingFolder(folder);
    setIsEditFolderModalOpen(true);
  };

  const handleEditFolder = async (newName) => {
    if (editingFolder && (editingFolder.id || editingFolder._id) && newName !== editingFolder.name) {
      try {
        const folderId = editingFolder.id || editingFolder._id;
        await updateItem(folderId, { name: newName });
        await loadKnowledgeBases(currentFolderId);
        toast.success('Item renamed successfully');
      } catch (error) {
        console.error('Error renaming item:', error);
        toast.error('Failed to rename item. Please try again.');
      }
    } else {
      console.error('Invalid editing item:', editingFolder);
      toast.error('Unable to rename item. Please try again.');
    }
    setEditingFolder(null);
  };

  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // List of allowed MIME types
    const allowedTypes = [
      // Plain text files
      'text/plain',                     // .txt
      'text/markdown',                  // .md, .markdown
      'text/x-markdown',                // Alternative MIME for markdown
      
      // Document files
      'application/pdf',                // .pdf
      'application/msword',             // .doc
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.oasis.opendocument.text',  // .odt
      'application/rtf',                // .rtf
      
      // Spreadsheet files
      'application/vnd.ms-excel',       // .xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.oasis.opendocument.spreadsheet', // .ods
      'text/csv',                       // .csv
      'text/tab-separated-values',      // .tsv
      
      // Code files
      'text/javascript',                // .js
      'text/css',                       // .css
      'text/html',                      // .html
      'text/xml',                       // .xml
      'application/json',               // .json
      'text/yaml',                      // .yaml, .yml
      'text/x-python',                  // .py
      'text/x-java',                    // .java
    ];

    if (!allowedTypes.includes(file.type)) {
      toast.error('Only text-based files and spreadsheets are allowed (txt, md, pdf, doc, docx, xls, xlsx, csv, etc.)');
      event.target.value = '';
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      const fileSize = formatFileSize(file.size);
      const maxSize = formatFileSize(MAX_FILE_SIZE);
      toast.error(`File size (${fileSize}) exceeds maximum limit of ${maxSize}`);
      event.target.value = '';
      return;
    }

    const toastId = toast.loading(`Uploading ${file.name}...`);

    try {
      const formData = new FormData();
      formData.append('file', file);
      if (currentFolderId) {
        formData.append('parentId', currentFolderId);
      }

      const uploadedFile = await uploadFile(formData);
      setItemsMap(prevMap => new Map(prevMap).set(uploadedFile._id, uploadedFile));
      await loadKnowledgeBases(currentFolderId);

      toast.success(`${file.name} uploaded successfully`, { id: toastId });
    } catch (error) {
      toast.error(error.response?.data?.error || 'Error uploading file', { id: toastId });
    } finally {
      event.target.value = '';
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const removeNotification = useCallback((id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  }, []);

  const Breadcrumbs = ({ path, onNavigate, isLoading, isDarkMode }) => {
    return (
      <nav aria-label="Breadcrumb" className="flex-1">
        <ol className={`flex items-center space-x-1 text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-600'}`}>
          <li className="flex items-center">
            <button
              onClick={() => onNavigate([])}
              className={`flex items-center ${isDarkMode ? 'hover:text-slate-200' : 'hover:text-blue-600'} transition-colors duration-200`}
              aria-label="Navigate to home"
            >
              <HomeIcon className="w-3 h-3 mr-1" />
              <span className="sr-only sm:not-sr-only">Home</span>
            </button>
            {path.length > 0 && (
              <ChevronRightIcon className={`w-3 h-3 mx-1 ${isDarkMode ? 'text-slate-600' : 'text-gray-400'}`} aria-hidden="true" />
            )}
          </li>
          {path.map((folder, index) => (
            <li key={folder._id} className="flex items-center max-w-[100px]">
              <button
                onClick={() => onNavigate(path.slice(0, index + 1))}
                className={`${isDarkMode ? 'hover:text-slate-200' : 'hover:text-blue-600'} transition-colors duration-200 truncate ${
                  index === path.length - 1 ? `font-semibold ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}` : ''
                }`}
                aria-current={index === path.length - 1 ? 'page' : undefined}
                title={folder.name || 'Unnamed Folder'}
              >
                {folder.name || 'Unnamed Folder'}
              </button>
              {index < path.length - 1 && (
                <ChevronRightIcon className={`w-3 h-3 mx-1 ${isDarkMode ? 'text-slate-600' : 'text-gray-400'} flex-shrink-0`} aria-hidden="true" />
              )}
            </li>
          ))}
        </ol>
      </nav>
    );
  };

  const allFolders = useMemo(() => {
    return Array.from(itemsMap.values()).filter(item => item.type === 'folder');
  }, [itemsMap]);

  const handleDownload = useCallback(async (item) => {
    // Create a loading toast that we can update later
    const toastId = toast.loading(`Downloading ${item.name}...`);

    try {
      const blob = await downloadFile(item._id);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      
      // Update the loading toast with success
      toast.success('File downloaded successfully', { id: toastId });
    } catch (error) {
      console.error('Error downloading file:', error);
      
      // Update the loading toast with error
      if (error.response && error.response.status === 404) {
        toast.error('File not found. It may have been deleted.', { id: toastId });
      } else {
        toast.error(`Error downloading file: ${error.message}. Please try again.`, { id: toastId });
      }
    }
  }, []);

  const handleAddToChat = useCallback(async (item) => {
    try {
      const blob = await downloadFile(item._id);
      addFileToChat({
        id: item._id,
        name: item.name,
        type: item.type,
        size: item.size,
        blob: blob
      });
      toast.success(`${item.name} added to chat`);
    } catch (error) {
      console.error('Error adding file to chat:', error);
      toast.error(`Error adding file to chat: ${error.message}. Please try again.`);
    }
  }, [addFileToChat]);

  const handleCancelDownload = (downloadId) => {
    setDownloads(prevDownloads => prevDownloads.filter(download => download.id !== downloadId));
  };

  const handleGetInfo = useCallback((item) => {
    setInfoItem(item);
    setShowInfoPopup(true);
  }, []);

  const handlePopupToggle = useCallback((itemId) => {
    setVisiblePopupId(prevId => prevId === itemId ? null : itemId);
  }, []);

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setVisiblePopupId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedItems = useMemo(() => {
    return [...filteredItems].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredItems, sortColumn, sortDirection]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={`flex flex-col h-full ${isDarkMode ? 'bg-[#121212]' : 'bg-[#fafafa]'}`}
      >
        {/* Top Bar */}
        <div className={`flex items-center justify-between px-4 h-10 ${
          isDarkMode ? 'bg-[#121212] border-[#333333]' : 'bg-white border-gray-200'
        } border-b`}>
          <div className="flex items-center">
            {/* Logo and Title */}
            <AcademicCapIcon className={`w-4 h-4 ${isDarkMode ? 'text-slate-200' : 'text-black'} mr-2`} />
            <h1 className={`text-xs font-semibold ${isDarkMode ? 'text-slate-200' : 'text-gray-800'}`}>
              <span className="hidden sm:inline">Context Base</span>
              <span className="sm:hidden">Context Base</span>
            </h1>
          </div>
          
          <div className="flex items-center space-x-2">
            {/* Create Folder Button */}
            <button
              onClick={() => setIsNewFolderModalOpen(true)}
              className={`flex items-center px-2 py-1 rounded text-xs ${
                isDarkMode 
                  ? 'bg-[#1e1e1e] hover:bg-[#252525] text-slate-200' 
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
              } transition-colors duration-200`}
              title="Create New Folder"
            >
              <FolderPlusIcon className="w-3 h-3 mr-1" />
              <span>New Folder</span>
            </button>

            {/* Upload File Button */}
            <label
              htmlFor="file-upload"
              className={`flex items-center px-2 py-1 rounded cursor-pointer text-xs ${
                isDarkMode 
                  ? 'bg-[#1e1e1e] hover:bg-[#252525] text-slate-200' 
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
              } transition-colors duration-200`}
              title="Upload File"
            >
              <ArrowUpTrayIcon className="w-3 h-3 mr-1" />
              <span>Upload</span>
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleFileUpload}
              />
            </label>
          </div>
        </div>

        {/* Main Content */}
        <main
          ref={mainContentRef}
          className={`flex-1 overflow-auto p-4 ${isDarkMode ? 'bg-[#121212]' : 'bg-[#fafafa]'}`}
          style={{ height: 'calc(100% - 40px)' }}
        >
          {isLoading ? (
            <>
              {/* Breadcrumbs and Search Bar */}
              <div className={`mb-4 px-4 py-2 ${
                isDarkMode ? 'bg-[#1e1e1e] border-[#333333]' : 'bg-white border-gray-200'
              } rounded-lg border flex items-center justify-between`}>
                <Breadcrumbs
                  path={currentPath}
                  onNavigate={(newPath) => {
                    const lastFolder = newPath[newPath.length - 1];
                    setCurrentFolderId(lastFolder ? lastFolder._id : null);
                    setCurrentPath(newPath);
                    navigate(`/files/${newPath.map(f => f._id).join('/')}`);
                  }}
                  isLoading={isLoading}
                  isDarkMode={isDarkMode}
                />
                <div className="flex items-center space-x-2">
                  <div className="relative">
                    {/* Search Bar Component */}
                    {isSearchExpanded ? (
                      <div className="relative">
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={handleSearch}
                          className={`w-48 px-2 h-7 text-xs ${
                            isDarkMode 
                              ? 'bg-[#252525] border-[#333333] text-slate-200 placeholder-slate-400' 
                              : 'bg-gray-100 border-gray-300 text-gray-900'
                          } border rounded-md pl-7 pr-7 focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-transparent`}
                          onBlur={() => {
                            if (!searchTerm) {
                              setIsSearchExpanded(false);
                            }
                          }}
                          autoFocus
                        />
                        <MagnifyingGlassIcon 
                          className={`w-3.5 h-3.5 absolute left-2 top-1/2 transform -translate-y-1/2 ${
                            isDarkMode ? 'text-slate-400' : 'text-gray-400'
                          }`} 
                        />
                        {searchTerm && (
                          <button
                            onClick={() => {
                              setSearchTerm('');
                              setIsSearchExpanded(false);
                            }}
                            className={`absolute right-2 top-1/2 transform -translate-y-1/2 p-0.5 rounded-full hover:bg-opacity-80 ${
                              isDarkMode 
                                ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-200' 
                                : 'hover:bg-gray-200 text-gray-400 hover:text-gray-600'
                            }`}
                          >
                            <XMarkIcon className="w-3 h-3" />
                          </button>
                        )}
                      </div>
                    ) : (
                      <button
                        onClick={() => setIsSearchExpanded(true)}
                        className={`p-1.5 rounded-md ${
                          isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-200'
                        } transition-colors duration-200`}
                      >
                        <MagnifyingGlassIcon className={`w-3.5 h-3.5 ${isDarkMode ? 'text-slate-200' : 'text-gray-600'}`} />
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {/* Loading Skeleton */}
              <div className={`flex-1 overflow-hidden rounded-lg ${
                isDarkMode ? 'bg-[#1e1e1e] border-[#333333]' : 'bg-white border-gray-200'
              } border`}>
                <table className={`min-w-full divide-y ${isDarkMode ? 'divide-[#333333]' : 'divide-gray-200'}`}>
                  <tbody>
                    {Array(5).fill().map((_, index) => (
                      <tr key={index}>
                        <td className="w-1/2 py-4 px-3">
                          <div className="flex items-center">
                            <div className={`w-4 h-4 mr-2 rounded ${isDarkMode ? 'bg-[#252525]' : 'bg-gray-200'}`}></div>
                            <div className={`h-4 w-3/4 ${isDarkMode ? 'bg-[#252525]' : 'bg-gray-200'} rounded`}></div>
                          </div>
                        </td>
                        <td className="w-1/4 py-4 px-3 hidden sm:table-cell">
                          <div className={`h-4 w-2/3 ${isDarkMode ? 'bg-[#252525]' : 'bg-gray-200'} rounded`}></div>
                        </td>
                        <td className="w-1/6 py-4 px-3 hidden md:table-cell">
                          <div className={`h-4 w-1/2 ${isDarkMode ? 'bg-[#252525]' : 'bg-gray-200'} rounded`}></div>
                        </td>
                        <td className="w-1/12 py-4 px-3">
                          <div className={`h-4 w-full ${isDarkMode ? 'bg-[#252525]' : 'bg-gray-200'} rounded`}></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              {/* Always show breadcrumbs and search bar if not loading and there are items in itemsMap */}
              {Array.from(itemsMap.values()).length > 0 && (
                <div className={`mb-4 px-4 py-2 ${
                  isDarkMode ? 'bg-[#1e1e1e] border-[#333333]' : 'bg-white border-gray-200'
                } rounded-lg border flex items-center justify-between`}>
                  <Breadcrumbs
                    path={currentPath}
                    onNavigate={(newPath) => {
                      const lastFolder = newPath[newPath.length - 1];
                      setCurrentFolderId(lastFolder ? lastFolder._id : null);
                      setCurrentPath(newPath);
                      navigate(`/files/${newPath.map(f => f._id).join('/')}`);
                    }}
                    isLoading={isLoading}
                    isDarkMode={isDarkMode}
                  />
                  <div className="flex items-center space-x-2">
                    <div className="relative">
                      {/* Search Bar Component */}
                      {isSearchExpanded ? (
                        <div className="relative">
                          <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className={`w-48 px-2 h-7 text-xs ${
                              isDarkMode 
                                ? 'bg-[#252525] border-[#333333] text-slate-200 placeholder-slate-400' 
                                : 'bg-gray-100 border-gray-300 text-gray-900'
                            } border rounded-md pl-7 pr-7 focus:outline-none focus:ring-1 focus:ring-blue-400 focus:border-transparent`}
                            onBlur={() => {
                              if (!searchTerm) {
                                setIsSearchExpanded(false);
                              }
                            }}
                            autoFocus
                          />
                          <MagnifyingGlassIcon 
                            className={`w-3.5 h-3.5 absolute left-2 top-1/2 transform -translate-y-1/2 ${
                              isDarkMode ? 'text-slate-400' : 'text-gray-400'
                            }`} 
                          />
                          {searchTerm && (
                            <button
                              onClick={() => {
                                setSearchTerm('');
                                setIsSearchExpanded(false);
                              }}
                              className={`absolute right-2 top-1/2 transform -translate-y-1/2 p-0.5 rounded-full hover:bg-opacity-80 ${
                                isDarkMode 
                                  ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-200' 
                                  : 'hover:bg-gray-200 text-gray-400 hover:text-gray-600'
                              }`}
                            >
                              <XMarkIcon className="w-3 h-3" />
                            </button>
                          )}
                        </div>
                      ) : (
                        <button
                          onClick={() => setIsSearchExpanded(true)}
                          className={`p-1.5 rounded-md ${
                            isDarkMode ? 'hover:bg-[#252525]' : 'hover:bg-gray-200'
                          } transition-colors duration-200`}
                        >
                          <MagnifyingGlassIcon className={`w-3.5 h-3.5 ${isDarkMode ? 'text-slate-200' : 'text-gray-600'}`} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* Content Area */}
              {sortedItems.length === 0 ? (
                <div className="flex-1 flex items-center justify-center h-full">
                  <NoResultsMessage searchTerm={searchTerm} isDarkMode={isDarkMode} />
                </div>
              ) : (
                <div className={`flex-1 overflow-hidden rounded-lg ${
                  isDarkMode ? 'bg-[#1e1e1e] border-[#333333]' : 'bg-white border-gray-200'
                } border`}>
                  <div className="relative">
                    <table className={`min-w-full divide-y ${isDarkMode ? 'divide-[#333333]' : 'divide-gray-200'}`}>
                      <TableHeader
                        onSort={handleSort}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        isDarkMode={isDarkMode}
                      />
                    </table>
                    <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 200px)' }}>
                      <table className={`min-w-full ${isDarkMode ? 'divide-[#333333]' : 'divide-gray-200'}`}>
                        <tbody className={`divide-y ${isDarkMode ? 'divide-[#333333]' : 'divide-gray-200'}`}>
                          {sortedItems.map((item) => (
                            <ItemRow
                              key={item._id}
                              item={item}
                              onRename={handleRename}
                              onMove={handleMove}
                              onDelete={handleDelete}
                              onFolderClick={handleFolderClick}
                              onDownload={handleDownload}
                              onGetInfo={handleGetInfo}
                              folders={allFolders}
                              isPopupVisible={visiblePopupId === item._id}
                              onPopupToggle={handlePopupToggle}
                              isDarkMode={isDarkMode}
                              popupRef={popupRef}
                              onAddToChat={handleAddToChat}
                              onMoveInitiate={(item) => {
                                console.log('Move initiated for item:', item);
                                handleMoveInitiate(item);
                              }}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </main>

        {/* Modals and Popups */}
        {showInfoPopup && infoItem && (
          infoItem.type === 'folder' ? (
            <FolderInfoPopup
              folder={infoItem}
              onClose={() => setShowInfoPopup(false)}
            />
          ) : (
            <FileInfoPopup
              file={infoItem}
              onClose={() => setShowInfoPopup(false)}
            />
          )
        )}

        {isNewFolderModalOpen && (
          <NewFolderModal
            onClose={() => setIsNewFolderModalOpen(false)}
            onCreate={handleCreateFolder}
            isDarkMode={isDarkMode}
          />
        )}

        {previewFile && (
          <FilePreview
            file={previewFile}
            onClose={() => setPreviewFile(null)}
            isDarkMode={isDarkMode}
          />
        )}

        {isUploading && (
          <UploadProgress 
            progress={uploadProgress} 
            isUploading={isUploading}
            isDarkMode={isDarkMode}
          />
        )}

        {isMoving && itemToMove && (
          <TreeDropdown
            folders={Array.from(itemsMap.values()).filter(item => item.type === 'folder')}
            onSelect={(destinationFolderId) => handleMove(itemToMove._id, destinationFolderId)}
            currentItemId={itemToMove._id}
            isDarkMode={isDarkMode}
          />
        )}

        <DragPreview isDarkMode={isDarkMode} />

        {/* Notifications */}
        {notifications.map((notification, index) => (
          <Notification
            key={notification.id}
            id={notification.id}
            title={notification.title}
            message={notification.message}
            type={notification.type}
            onClose={removeNotification}
            currentIndex={index + 1}
            totalCount={notifications.length}
            isDarkMode={isDarkMode}
          />
        ))}
      </div>
    </DndProvider>
  );
};

export default KnowledgeBaseManager;