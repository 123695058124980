import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ArrowLeftIcon,
  DocumentDuplicateIcon,
  ChevronDownIcon,
  CheckIcon,
  UserIcon,
  UserGroupIcon,
  CalendarIcon,
  ClockIcon,
  ExclamationCircleIcon,
  DocumentMagnifyingGlassIcon,
  BriefcaseIcon,
  BuildingOfficeIcon,
  InformationCircleIcon,
  XMarkIcon,
  AdjustmentsHorizontalIcon,
  ArrowDownTrayIcon,
  FolderIcon,
  DocumentTextIcon
} from '@heroicons/react/24/outline';
import { contentIdeaApi, saveIdea, getClientFolders, moveIdeaToFolder } from '../../api';
import { createPortal } from 'react-dom';
import IdeaCard from './IdeaCard';
import toast from 'react-hot-toast';
import ContentStrategyPDFDownloader from './ContentStrategyPDFDownloader';
import ReactMarkdown from 'react-markdown';
import AnimatedLoader from '../common/AnimatedLoader';

// Update the TopBar component to include Info text
const TopBar = ({ isDarkMode, onBack, onInfo }) => (
  <div className={`${isDarkMode ? 'bg-[#121212]/95' : 'bg-white/50'} 
    backdrop-blur-sm sticky top-0 z-10 h-10 flex items-center justify-between px-3 w-full border-b ${
    isDarkMode ? 'border-[#333333]' : 'border-gray-200/60'}`}>
    <div className="flex items-center flex-1 gap-2">
      <button
        onClick={onBack}
        className={`p-1 rounded-md transition-colors ${
          isDarkMode 
            ? 'hover:bg-slate-700 text-slate-400' 
            : 'hover:bg-slate-200 text-slate-600'
        }`}
      >
        <ArrowLeftIcon className="w-4 h-4" />
      </button>
      <DocumentMagnifyingGlassIcon className={`h-4 w-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
      <h2 className={`text-xs font-medium ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
        Topic Details
      </h2>
    </div>
    <button
      onClick={onInfo}
      className={`flex items-center gap-1.5 px-2 py-1 rounded-md text-xs transition-colors ${
        isDarkMode 
          ? 'hover:bg-slate-700 text-slate-400' 
          : 'hover:bg-slate-200 text-slate-600'
      }`}
    >
      <InformationCircleIcon className="w-3.5 h-3.5" />
      <span>Info</span>
    </button>
  </div>
);

// Update ActionBar component to use new data structure
const ActionBar = ({ strategy, isDarkMode, selectedPersona }) => {
  const [copyState, setCopyState] = useState('idle');
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  // Calculate statistics using new data structure
  const stats = {
    totalIdeas: strategy.generatedIdeas?.reduce((total, topicIdeas) => 
      total + (topicIdeas.ideas?.length || 0), 0) || 0,
    totalTopics: strategy.topics?.length || 0
  };

  const copyAllIdeas = async () => {
    if (copyState !== 'idle') return;
    
    setCopyState('copying');
    try {
      const formattedIdeas = strategy.generatedIdeas
        .map(topicIdeas => {
          return `${topicIdeas.topic}\n\n${topicIdeas.ideas
            .map(idea => {
              return `• ${idea.content}${
                idea.angle ? `\nAngle: ${idea.angle}` : ''
              }${idea.hook ? `\nHook: ${idea.hook}` : ''}${
                idea.value ? `\nValue: ${idea.value}` : ''
              }${idea.expertiseAlignment ? `\nExpertise Alignment: ${idea.expertiseAlignment}` : ''}`
            })
            .join('\n\n')}`
        })
        .join('\n\n---\n\n');

      await navigator.clipboard.writeText(formattedIdeas);
      setCopyState('copied');
      toast.success('All ideas copied to clipboard');
      
      setTimeout(() => {
        setCopyState('idle');
      }, 3500);
    } catch (error) {
      console.error('Copy error:', error);
      toast.error('Failed to copy ideas');
      setCopyState('idle');
    }
  };

  const downloadPDF = () => {
    setDownloadingPDF(true);
    try {
      // Format data for PDF generation
      const pdfData = {
        data: {
          topics: strategy.topics,
          generatedIdeas: strategy.generatedIdeas,
          persona: strategy.persona,
          audience: strategy.audience
        }
      };
      
      const pdfDownloader = new ContentStrategyPDFDownloader(pdfData, strategy.persona);
      pdfDownloader.generatePDF();
      toast.success('PDF downloaded successfully');
    } catch (error) {
      console.error('PDF generation error:', error);
      toast.error('Failed to generate PDF');
    } finally {
      setDownloadingPDF(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`sticky top-0 z-10 mb-4 rounded-lg ring-1 backdrop-blur-lg ${
        isDarkMode 
          ? 'bg-[#121212] ring-[#333333]' 
          : 'bg-white/90 ring-blue-100'
      }`}
    >
      <div className="px-4 py-3">
        <div className="flex items-center justify-between">
          <span className={`text-sm ${
            isDarkMode ? 'text-blue-400' : 'text-blue-500'
          }`}>
            <span className={`font-medium ${
              isDarkMode ? 'text-blue-100' : 'text-blue-900'
            }`}>{stats.totalIdeas} ideas</span>
            {strategy.persona?.name && (
              <>
                <span> generated for </span>
                <span className={`font-medium ${
                  isDarkMode ? 'text-blue-100' : 'text-blue-900'
                }`}>{strategy.persona.name}</span>
              </>
            )}
          </span>

          <div className="flex items-center gap-2">
            <button
              onClick={copyAllIdeas}
              disabled={copyState !== 'idle'}
              className={`flex items-center gap-1.5 px-2.5 py-1 rounded-md text-xs transition-all duration-200 ${
                copyState === 'copied'
                  ? isDarkMode
                    ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30'
                    : 'bg-green-50 text-green-600 hover:bg-green-100'
                  : isDarkMode
                  ? 'hover:bg-blue-900/50 text-blue-200'
                  : 'hover:bg-blue-100/80 text-blue-700'
              } ${
                copyState === 'copying' ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {copyState === 'copied' ? (
                <>
                  <CheckIcon className="w-3.5 h-3.5 animate-scale-check" />
                  <span>Copied!</span>
                </>
              ) : copyState === 'copying' ? (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5 animate-pulse" />
                  <span>Copying...</span>
                </>
              ) : (
                <>
                  <DocumentDuplicateIcon className="w-3.5 h-3.5" />
                  <span>Copy All</span>
                </>
              )}
            </button>
            
            <button
              onClick={downloadPDF}
              disabled={downloadingPDF}
              className={`flex items-center gap-1.5 px-2.5 py-1 rounded-md text-xs transition-colors ${
                downloadingPDF
                  ? 'opacity-50 cursor-not-allowed'
                  : isDarkMode
                  ? 'hover:bg-blue-900/50 text-blue-200'
                  : 'hover:bg-blue-100/80 text-blue-700'
              }`}
            >
              <ArrowDownTrayIcon className={`w-3.5 h-3.5 ${downloadingPDF ? 'animate-pulse' : ''}`} />
              <span>{downloadingPDF ? 'Downloading...' : 'PDF'}</span>
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

// Update the InfoPopup component's background overlay
const InfoPopup = ({ strategy, isDarkMode, onClose }) => {
  if (!strategy) return null;

  return (
    <>
      {/* Background Overlay - updated to white blur */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
        className={`fixed inset-0 z-40 ${
          isDarkMode 
            ? 'bg-black/50' 
            : 'bg-white/30'
        } backdrop-blur-sm`}
      />

      {/* Popup Content */}
      <div className="fixed inset-x-6 top-16 z-50">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className={`relative w-full max-w-2xl mx-auto rounded-xl border ${
            isDarkMode 
              ? 'bg-[#121212] border-[#333333]' 
              : 'bg-white/95 border-slate-200/50'
          } backdrop-blur-sm`}
        >
          {/* Header - updated border style */}
          <div className={`sticky top-0 z-10 px-4 py-3 border-b rounded-t-xl ${
            isDarkMode 
              ? 'bg-[#121212] border-[#333333]' 
              : 'bg-white/95 border-slate-200/30'
          } backdrop-blur-sm`}>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <InformationCircleIcon className={`w-4 h-4 ${
                  isDarkMode ? 'text-slate-400' : 'text-slate-600'
                }`} />
                <h3 className={`text-sm font-medium ${
                  isDarkMode ? 'text-slate-200' : 'text-slate-900'
                }`}>
                  Topic Details
                </h3>
              </div>
              <button
                onClick={onClose}
                className={`p-1 rounded-md transition-colors ${
                  isDarkMode 
                    ? 'hover:bg-slate-800 text-slate-400' 
                    : 'hover:bg-slate-100 text-slate-600'
                }`}
              >
                <XMarkIcon className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Scrollable Content - updated inner container styles */}
          <div className="max-h-[calc(100vh-12rem)] overflow-y-auto">
            <div className="p-4 space-y-6">
              {/* Persona Info */}
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <UserIcon className={`w-4 h-4 ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                  }`} />
                  <h4 className={`text-xs font-medium ${
                    isDarkMode ? 'text-slate-300' : 'text-slate-700'
                  }`}>
                    Content Creator
                  </h4>
                </div>
                <div className={`p-3 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-[#1e1e1e] border-[#333333]' 
                    : 'bg-slate-50/30 border-slate-200/30'
                }`}>
                  <div className="space-y-2 text-xs">
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Name</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.persona?.name}
                      </span>
                    </div>
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Role</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.persona?.occupation}
                      </span>
                    </div>
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Goals</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.persona?.contentGoals}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Audience Info */}
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <UserGroupIcon className={`w-4 h-4 ${
                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                  }`} />
                  <h4 className={`text-xs font-medium ${
                    isDarkMode ? 'text-slate-300' : 'text-slate-700'
                  }`}>
                    Target Audience
                  </h4>
                </div>
                <div className={`p-3 rounded-lg border ${
                  isDarkMode 
                    ? 'bg-[#1e1e1e] border-[#333333]' 
                    : 'bg-slate-50/30 border-slate-200/30'
                }`}>
                  <div className="space-y-2 text-xs">
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Name</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.audience?.targetAudienceName}
                      </span>
                    </div>
                    <div className="grid grid-cols-[80px_1fr] gap-2">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Industry</span>
                      <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                        {strategy.audience?.industry}
                      </span>
                    </div>
                    <div className="grid grid-cols-[80px_1fr] gap-2 items-start">
                      <span className={isDarkMode ? 'text-slate-400' : 'text-slate-500'}>Pain Points</span>
                      <div className="space-y-1">
                        {strategy.audience?.painPoints?.map((point, index) => (
                          <div key={index} className="flex items-start gap-2">
                            <span className="text-blue-500 mt-1">•</span>
                            <span className={isDarkMode ? 'text-slate-200' : 'text-slate-900'}>
                              {point}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

// Find the FloatingFolderBar component and update its styles:

const FloatingFolderBar = ({ isDarkMode, onClose, onSelectFolder, folders }) => {
  const [isSelectingFolder, setIsSelectingFolder] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="fixed inset-x-0 bottom-8 z-50 flex justify-center px-4"
    >
      <div className={`max-w-xl w-full flex items-center gap-3 px-6 py-4 rounded-xl shadow-lg backdrop-blur-lg ${
        isDarkMode 
          ? 'bg-[#121212]/90 ring-1 ring-[#333333]' 
          : 'bg-white ring-1 ring-slate-200'
      }`}>
        {!isSelectingFolder ? (
          <>
            <div className={`flex items-center gap-2 ${
              isDarkMode ? 'text-gray-300' : 'text-gray-700'
            }`}>
              <CheckIcon className="w-4 h-4" />
              <span className="text-sm">Saved successfully</span>
            </div>
            <div className="flex-1" />
            <button
              onClick={() => setIsSelectingFolder(true)}
              className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
                isDarkMode
                  ? 'bg-[#1e1e1e] text-slate-300 hover:bg-[#1a1a1a]'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              Add to folder
            </button>
            <button
              onClick={onClose}
              className={`p-1.5 rounded-md transition-colors ${
                isDarkMode
                  ? 'text-gray-400 hover:bg-gray-700'
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </>
        ) : (
          <>
            <div className="flex items-center gap-3 flex-1">
              <FolderIcon className={`w-4 h-4 ${
                isDarkMode ? 'text-gray-400' : 'text-gray-500'
              }`} />
              <div className="flex flex-wrap gap-2">
                {folders.map((folder) => (
                  <button
                    key={folder._id}
                    onClick={() => {
                      onSelectFolder(folder);
                      setIsSelectingFolder(false);
                    }}
                    className={`px-3 py-1.5 text-sm rounded-md transition-colors ${
                      isDarkMode
                        ? 'bg-[#1e1e1e] text-slate-300 hover:bg-[#1a1a1a] hover:ring-1 hover:ring-[#333333]'
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:ring-1 hover:ring-gray-300'
                    }`}
                  >
                    {folder.name}
                  </button>
                ))}
              </div>
            </div>
            <button
              onClick={() => setIsSelectingFolder(false)}
              className={`p-1.5 rounded-md transition-colors ${
                isDarkMode
                  ? 'text-gray-400 hover:bg-gray-700'
                  : 'text-gray-500 hover:bg-gray-100'
              }`}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </>
        )}
      </div>
    </motion.div>
  );
};

// Add LoadingView component
const LoadingView = ({ isDarkMode }) => (
  <div className="w-full h-full flex flex-col items-center justify-center gap-4">
    <AnimatedLoader 
      size={32} 
      color={isDarkMode ? '#60A5FA' : '#3B82F6'} 
      className="mb-2"
    />
    <div className="flex flex-col items-center">
      <h3 className={`text-lg font-medium ${
        isDarkMode ? 'text-slate-200' : 'text-slate-900'
      }`}>
        Loading Content Ideas
      </h3>
      <p className={`text-sm mt-1 ${
        isDarkMode ? 'text-slate-400' : 'text-slate-500'
      }`}>
        Please wait while we fetch your content...
      </p>
    </div>
  </div>
);

const ViewStrategy = () => {
  const { id } = useParams();
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [strategy, setStrategy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [copiedIdeaId, setCopiedIdeaId] = useState(null);
  const [expandedTopics, setExpandedTopics] = useState({});

  useEffect(() => {
    loadStrategy();
  }, [id]);

  useEffect(() => {
    // Initialize all topics as expanded when strategy loads
    if (strategy?.generatedIdeas) {
      const initialExpanded = strategy.generatedIdeas.reduce((acc, topic, index) => {
        acc[index] = true; // Start with all expanded
        return acc;
      }, {});
      setExpandedTopics(initialExpanded);
    }
  }, [strategy]);

  const loadStrategy = async () => {
    try {
      console.log('🔄 Loading content ideas:', id);
      setLoading(true);
      setError(null);
      const data = await contentIdeaApi.getStrategy(id);
      console.log('📥 Received content ideas:', data);
      setStrategy(data.data || data);
    } catch (error) {
      console.error('❌ Error loading content ideas:', error);
      setError(error.message || 'Failed to load content ideas');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyIdea = (idea) => {
    const textToCopy = [
      idea.angle && `Angle: ${idea.angle}`,
      idea.hook && `Hook: ${idea.hook}`,
      idea.content && `Content: ${idea.content}`,
      idea.value && `Value: ${idea.value}`,
      idea.expertiseAlignment && `Expertise Alignment: ${idea.expertiseAlignment}`
    ]
      .filter(Boolean)
      .join('\n\n');
      
    navigator.clipboard.writeText(textToCopy);
    setCopiedIdeaId(idea.id || idea._id);
    toast.success('Idea copied to clipboard');
    setTimeout(() => setCopiedIdeaId(null), 2000);
  };

  const toggleTopic = (index) => {
    setExpandedTopics(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  if (loading) {
    return <LoadingView isDarkMode={isDarkMode} />;
  }

  if (error) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <ExclamationCircleIcon className="w-12 h-12 text-red-500 mb-4" />
        <h3 className={`text-lg font-medium mb-2 ${
          isDarkMode ? 'text-slate-200' : 'text-slate-900'
        }`}>
          Failed to load content ideas
        </h3>
        <p className={`text-sm ${
          isDarkMode ? 'text-slate-400' : 'text-slate-500'
        }`}>
          {error}
        </p>
        <button
          onClick={() => navigate('/content-strategy/manager')}
          className={`mt-6 px-4 py-2 rounded-md text-sm font-medium ${
            isDarkMode
              ? 'bg-[#1e1e1e] text-slate-200 hover:bg-[#1a1a1a]'
              : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
          }`}
        >
          Back to Content Ideas
        </button>
      </div>
    );
  }

  if (!strategy) return null;

  return (
    <div className={`h-full w-full flex flex-col ${isDarkMode ? 'bg-[#121212]' : 'bg-[#fafafa]'}`}>
      <TopBar 
        isDarkMode={isDarkMode} 
        onBack={() => navigate('/content-strategy/manager')}
        onInfo={() => setShowInfo(true)}
      />
      
      <div className="flex-1 overflow-auto">
        <div className="w-full max-w-4xl mx-auto p-6 space-y-6">
          <ActionBar 
            strategy={strategy} 
            isDarkMode={isDarkMode}
            selectedPersona={strategy.persona}
          />

          <div className="space-y-6">
            {strategy.generatedIdeas?.length > 0 ? (
              strategy.generatedIdeas.map((topicIdeas, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: index * 0.1 }}
                  className={`rounded-xl ${
                    isDarkMode 
                      ? 'bg-[#151515]' 
                      : 'bg-white'
                  }`}
                >
                  {/* Topic Header */}
                  <div className={`px-6 py-4 border-b ${
                    isDarkMode ? 'border-[#333333]' : 'border-slate-200'
                  }`}>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <div className={`flex items-center justify-center w-10 h-10 rounded-lg font-medium ${
                          isDarkMode 
                            ? 'bg-slate-800/50 text-slate-300' 
                            : 'bg-slate-100 text-slate-600'
                        }`}>
                          {index + 1}
                        </div>
                        <div>
                          <h3 className={`text-base font-medium ${
                            isDarkMode ? 'text-slate-200' : 'text-slate-700'
                          }`}>
                            {topicIdeas.topic}
                          </h3>
                          <p className={`text-sm mt-0.5 ${
                            isDarkMode ? 'text-slate-400' : 'text-slate-500'
                          }`}>
                            {topicIdeas.ideas?.length || 0} ideas
                          </p>
                        </div>
                      </div>

                      <button
                        onClick={() => toggleTopic(index)}
                        className={`p-2 rounded-lg transition-colors ${
                          isDarkMode 
                            ? 'hover:bg-slate-800/40 text-slate-400' 
                            : 'hover:bg-slate-100/60 text-slate-500'
                        }`}
                      >
                        <ChevronDownIcon 
                          className={`w-5 h-5 transition-transform duration-200 ${
                            expandedTopics[index] ? 'transform rotate-180' : ''
                          }`}
                        />
                      </button>
                    </div>
                  </div>

                  {/* Ideas List */}
                  <AnimatePresence>
                    {expandedTopics[index] && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className={`divide-y ${
                          isDarkMode ? 'divide-[#333333]' : 'divide-slate-200'
                        }`}
                      >
                        {topicIdeas.ideas.map((idea, ideaIndex) => {
                          const isIdeaCopied = copiedIdeaId === (idea.id || idea._id);

                          return (
                            <div
                              key={ideaIndex}
                              className={`px-4 py-5 transition-colors ${
                                isDarkMode 
                                  ? 'hover:bg-[#1a1a1a]' 
                                  : 'hover:bg-slate-50'
                              }`}
                            >
                              <div className="flex items-start justify-between gap-4">
                                <div className="flex-1 space-y-3">
                                  {/* Hook */}
                                  <h3 className={`text-[15px] font-medium leading-relaxed tracking-tight ${
                                    isDarkMode ? 'text-slate-200' : 'text-slate-700'
                                  }`}>
                                    {idea.hook?.replace(/['"]/g, '')}
                                  </h3>

                                  {/* Content */}
                                  <p className={`text-[13px] leading-relaxed ${
                                    isDarkMode ? 'text-slate-300' : 'text-slate-600'
                                  }`}>
                                    {idea.content}
                                  </p>

                                  {/* Tags Row */}
                                  <div className="flex flex-wrap items-center gap-x-4 gap-y-2 pt-1">
                                    <div className={`inline-flex items-center px-2 py-0.5 text-[12px] rounded-full ${
                                      isDarkMode 
                                        ? 'bg-slate-800/50 text-slate-400' 
                                        : 'bg-slate-100/50 text-slate-500'
                                    }`}>
                                      <span className="font-medium mr-1">Angle:</span> {idea.angle}
                                    </div>

                                    <div className={`inline-flex items-center px-2 py-0.5 text-[12px] rounded-full ${
                                      isDarkMode 
                                        ? 'bg-slate-800/50 text-slate-400' 
                                        : 'bg-slate-100/50 text-slate-500'
                                    }`}>
                                      <span className="font-medium mr-1">Value:</span> {idea.value}
                                    </div>

                                    <div className={`inline-flex items-center px-2 py-0.5 text-[12px] rounded-full ${
                                      isDarkMode 
                                        ? 'bg-slate-800/50 text-slate-400' 
                                        : 'bg-slate-100/50 text-slate-500'
                                    }`}>
                                      <span className="font-medium mr-1">Alignment:</span> {idea.expertiseAlignment}
                                    </div>
                                  </div>
                                </div>

                                {/* Actions */}
                                <div className={`flex flex-col gap-1.5 transition-opacity duration-200 ${
                                  isIdeaCopied ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
                                }`}>
                                  <button
                                    onClick={() => handleCopyIdea(idea)}
                                    className={`p-1.5 rounded-sm transition-colors ${
                                      isDarkMode ? 'hover:bg-slate-800' : 'hover:bg-slate-100'
                                    }`}
                                    title="Copy to clipboard"
                                  >
                                    {isIdeaCopied ? (
                                      <CheckIcon className="w-3.5 h-3.5 text-green-500" />
                                    ) : (
                                      <DocumentDuplicateIcon className="w-3.5 h-3.5 text-slate-400" />
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              ))
            ) : (
              <div className="text-center py-12">
                <p className={isDarkMode ? 'text-slate-400' : 'text-slate-600'}>
                  No content available.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showInfo && (
          <InfoPopup
            strategy={strategy}
            isDarkMode={isDarkMode}
            onClose={() => setShowInfo(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

// Add the formatting functions at the top of the file
const getFormattedFrameworkName = (name) => {
  const cleanName = name.replace('FRAMEWORK', '').trim();
  switch (cleanName.toLowerCase()) {
    case 'actionable':
      return 'Practical Implementation Guide';
    case 'aspirational':
      return 'Strategic Vision & Growth';
    case 'anthropological':
      return 'Human-Centered Insights';
    case 'analytical':
      return 'Data-Driven Analysis';
    default:
      return cleanName;
  }
};

const getFormattedSeriesName = (name) => {
  const seriesMap = {
    'tips': 'Expert Tips',
    'hacks': 'Practical Hacks',
    'ultimate guides': 'Comprehensive Guides',
    'lessons': 'Key Lessons',
    'mistakes': 'Common Mistakes',
    'reflections': 'Strategic Insights',
    'getting started': 'Quick Start Guide',
    'fears': 'Emotional Challenges',
    'challenges': 'Implementation Challenges',
    'misconceptions': 'Common Misconceptions',
    'trends': 'Industry Trends',
    'impact': 'Impact Analysis',
    'methodology': 'Methodology Breakdown'
  };
  
  const cleanName = name.toLowerCase().replace(' series', '').trim();
  return seriesMap[cleanName] || name;
};

export default ViewStrategy; 