import api from '../api';
import { loadStripe } from '@stripe/stripe-js';
import GeoService from './geoService';
import axios from 'axios';

// Initialize Stripe with public key
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_live_51QbxXiBsbFwyf6Z3Gy7XZFPDSXfquTGHyRoYC7';
console.log('Initializing Stripe with key:', STRIPE_PUBLIC_KEY);
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

class SubscriptionService {
  static async createCheckoutSession(planId, country = 'US', interval = 'month', startTrial = false) {
    try {
      console.log(`Creating checkout session for plan: ${planId}, country: ${country}, interval: ${interval}, startTrial: ${startTrial}`);
      const response = await api.post('/api/subscription/create-checkout-session', {
        planId,
        country,
        interval,
        startTrial,
        successUrl: `${window.location.origin}/dashboard?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/subscription`,
        isYearly: interval === 'year'
      });
      
      // Log the raw response for debugging
      console.log('Raw response:', {
        status: response.status,
        statusText: response.statusText,
        data: response.data
      });

      // Check if response exists and has data
      if (!response?.data) {
        console.error('Invalid response received:', response);
        throw new Error('No response received from server');
      }

      // Check if response indicates success
      if (!response.data.success) {
        const errorMessage = response.data.error || 'Failed to create checkout session';
        console.error('Server returned error:', errorMessage, response.data);
        throw new Error(errorMessage);
      }

      // Check if response has the required data
      const { data } = response.data;
      if (!data?.url || !data?.sessionId) {
        console.error('Invalid response structure:', response.data);
        throw new Error('Invalid response from server');
      }

      // Store session data
      const sessionData = {
        sessionId: data.sessionId,
        planId: data.planId,
        interval: interval,
        timestamp: new Date().toISOString(),
        customerId: data.customer,
        isTrial: startTrial
      };
      console.log('Storing session data:', sessionData);
      localStorage.setItem('checkoutSessionData', JSON.stringify(sessionData));

      // Direct URL redirect
      if (data.url) {
        console.log('Redirecting to Stripe checkout:', data.url);
        window.location.href = data.url;
        return data;
      }

      throw new Error('No valid checkout URL received');
    } catch (error) {
      console.error('Detailed error in createCheckoutSession:', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        response: error.response?.data,
        originalError: error
      });
      
      // Handle specific error cases
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      } else if (error.message.includes('checkout session')) {
        throw new Error('Unable to create checkout session. Please try again later.');
      } else if (error.message.includes('Invalid response')) {
        throw new Error('Server returned an invalid response. Please try again.');
      } else if (error.message.includes('No response')) {
        throw new Error('No response from server. Please check your connection.');
      } else if (error.message.includes('payment system')) {
        throw new Error('Payment system initialization failed. Please try again.');
      } else if (error.message.includes('stripe_tax')) {
        throw new Error('Payment system configuration issue. Please contact support.');
      }
      
      throw error;
    }
  }

  static async getSubscriptionStatus() {
    try {
      const response = await api.get('/api/subscription/status');
      
      // Map Stripe price ID to our internal plan ID using environment variables
      const stripePlanMap = {
        [process.env.REACT_APP_STRIPE_PRO_PRICE_USD_ID]: 'pro',
        [process.env.REACT_APP_STRIPE_PRO_PRICE_INR_ID]: 'pro',
        [process.env.REACT_APP_STRIPE_PRO_YEARLY_PRICE_USD_ID]: 'pro',
        [process.env.REACT_APP_STRIPE_PRO_YEARLY_PRICE_INR_ID]: 'pro',
        [process.env.REACT_APP_STRIPE_STANDARD_PRICE_USD_ID]: 'standard',
        [process.env.REACT_APP_STRIPE_STANDARD_PRICE_INR_ID]: 'standard',
        [process.env.REACT_APP_STRIPE_STANDARD_YEARLY_PRICE_USD_ID]: 'standard',
        [process.env.REACT_APP_STRIPE_STANDARD_YEARLY_PRICE_INR_ID]: 'standard'
      };

      // Add default values if some fields are missing
      return {
        isSubscribed: response.data?.isSubscribed || false,
        subscription: response.data?.subscription ? {
          ...response.data.subscription,
          status: response.data.subscription.status || 'inactive',
          currentPeriodEnd: response.data.subscription.currentPeriodEnd || null,
          cancelAtPeriodEnd: response.data.subscription.cancelAtPeriodEnd || false,
          creditsUsed: response.data.subscription.creditsUsed || 0,
          isCancelled: response.data.subscription.cancelAtPeriodEnd || false,
          canceledAt: response.data.subscription.canceledAt || null,
          interval: response.data.subscription.interval || 'month',
          plan: stripePlanMap[response.data.subscription.planId] || null
        } : null
      };
    } catch (error) {
      console.error('Error getting subscription status:', error);
      throw error;
    }
  }

  static async cancelSubscription() {
    try {
      const response = await api.post('/api/subscription/cancel');
      return response.data;
    } catch (error) {
      console.error('Error canceling subscription:', error);
      throw error;
    }
  }

  static async reactivateSubscription() {
    try {
      const response = await api.post('/api/subscription/reactivate');
      return response.data;
    } catch (error) {
      console.error('Error reactivating subscription:', error);
      throw error;
    }
  }

  static async updateCreditUsage(credits) {
    try {
      const response = await api.post('/api/subscription/update-usage', { credits });
      return response.data;
    } catch (error) {
      console.error('Error updating credit usage:', error);
      throw error;
    }
  }

  static async startTrial(planId, interval = 'month') {
    try {
      console.log('Starting trial for plan:', planId, 'interval:', interval);
      
      // Add loading state tracking
      const startTime = Date.now();
      
      const response = await api.post('/api/subscription/start-trial', {
        planId,
        interval
      });

      console.log('Trial response:', response.data, 'Time taken:', Date.now() - startTime, 'ms');

      if (!response?.data?.success) {
        console.error('Trial start failed:', response.data);
        throw new Error(response.data?.error || 'Failed to start trial');
      }

      // Log successful trial start
      console.log('Trial started successfully:', {
        planId,
        interval,
        trialData: response.data.trial
      });

      return response.data;
    } catch (error) {
      console.error('Detailed error in startTrial:', {
        name: error.name,
        message: error.message,
        stack: error.stack,
        response: error.response?.data,
        originalError: error,
        planId,
        interval
      });
      
      // Handle specific error cases
      if (error.response?.status === 404) {
        throw new Error('Trial service is currently unavailable. Please try again later.');
      } else if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      } else if (error.message.includes('trial')) {
        throw new Error('Unable to start trial. Please try again later.');
      } else if (error.message.includes('network')) {
        throw new Error('Network error. Please check your connection and try again.');
      }
      
      throw error;
    }
  }

  static async checkTrialStatus() {
    try {
      const response = await api.get('/api/subscription/trial-status');
      return response.data;
    } catch (error) {
      console.error('Error checking trial status:', error);
      throw error;
    }
  }

  static async endTrial() {
    try {
      const response = await api.post('/api/subscription/end-trial');
      return response.data;
    } catch (error) {
      console.error('Error ending trial:', error);
      throw error;
    }
  }

  static getSubscriptionPlans() {
    const userCountry = GeoService.getUserCountry();
    const isIndianUser = userCountry === 'IN';

    return {
      STANDARD: {
        id: 'standard',
        name: 'Standard',
        price: isIndianUser ? 999 : 11.99,
        yearlyPrice: isIndianUser ? Math.round(999 * 12 * 0.75) : Math.round(11.99 * 12 * 0.75),
        currency: isIndianUser ? '₹' : '$',
        creditLimit: 500,
        features: [
          '500 AI Credits*',
          '3 Brand Personas',
          '3 Target Audience Personas',
          '3 Custom AI Templates',
          'Unlimited Text Notes',
          '5 Voice Note Transcriptions',
          '10 AI Content Strategies',
          '10 Reddit Subreddit Analysis',
          'Unlimited Custom Post Templates',
          '7 Monthly LinkedIn Schedules',
          'Basic User Analytics',
          'LinkedIn Analytics (Coming Soon)'
        ],
        isPopular: true,
        badge: 'Most Popular',
        trialEnabled: true,
        trialDays: 7,
        trialFeatures: [
          'Full access to Standard features',
          '7 days to explore',
          'No credit card required',
          'Cancel anytime'
        ]
      },
      PRO: {
        id: 'pro',
        name: 'Pro',
        price: isIndianUser ? 2499 : 29,
        yearlyPrice: isIndianUser ? Math.round(2499 * 12 * 0.75) : Math.round(29 * 12 * 0.75),
        currency: isIndianUser ? '₹' : '$',
        creditLimit: 1000,
        features: [
          '1000 AI Credits*',
          '30 Brand Personas',
          '30 Target Audience Personas',
          '30 Custom AI Templates',
          'Unlimited Text Notes',
          '20 Voice Note Transcriptions',
          '50 AI Content Strategies',
          '50 Reddit Subreddit Analysis',
          'Unlimited Custom Post Templates',
          '30 Monthly LinkedIn Schedules',
          'Bulk Scheduling (Coming Soon)',
          'Basic User Analytics',
          'LinkedIn Analytics (Coming Soon)',
          'Priority Support'
        ],
        isPopular: false,
        color: 'blue',
        badge: 'Power User',
        trialEnabled: true,
        trialDays: 7,
        trialFeatures: [
          'Full access to Pro features',
          '7 days to explore',
          'No credit card required',
          'Cancel anytime'
        ]
      }
    };
  }

  async createCustomerPortalSession() {
    try {
      const userCountry = GeoService.getUserCountry();
      const isIndianUser = userCountry === 'IN';
      
      const isProduction = process.env.NODE_ENV === 'production';
      const returnUrl = isProduction 
        ? 'https://ammmplify.com/subscription'
        : window.location.origin + '/subscription';
      const response = await api.post('/api/subscription/create-portal-session', {
        returnUrl,
        isInr: isIndianUser
      });

      if (!response.data || !response.data.url) {
        throw new Error('Invalid response from server. Please try again.');
      }

      return response.data;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
      if (error.response?.status === 404) {
        throw new Error('Subscription service is currently unavailable. Please try again later.');
      } else if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw error;
    }
  }
}

export default SubscriptionService; 