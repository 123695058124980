import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SubscriptionCard from '../components/Subscription/SubscriptionCard';
import SubscriptionService from '../services/subscriptionService';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '../context/ThemeContext';
import { toast } from 'react-hot-toast';
import { SparklesIcon } from '@heroicons/react/24/outline';
import GeoService from '../services/geoService';

const SubscriptionPage = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [userCountry, setUserCountry] = useState(null);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchSubscriptionStatus = async () => {
      try {
        const status = await SubscriptionService.getSubscriptionStatus();
        setSubscription(status.subscription);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
        toast.error('Failed to load subscription information');
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionStatus();
  }, [user, navigate]);

  // Detect user's country on component mount
  useEffect(() => {
    const detectCountry = async () => {
      try {
        await GeoService.detectUserCountry();
        const country = GeoService.getUserCountry();
        setUserCountry(country);
      } catch (error) {
        console.error('Error detecting country:', error);
        setUserCountry('US'); // Default to US if detection fails
      }
    };
    
    detectCountry();
  }, []);

  if (loading) {
    return (
      <div className={`flex-1 flex items-center justify-center ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
        <div className="w-full max-w-3xl mx-auto px-4">
          <div className="animate-pulse">
            <div className={`h-8 rounded w-1/4 mb-4 ${isDarkMode ? 'bg-[#2A2A2A]' : 'bg-gray-200'}`}></div>
            <div className={`h-4 rounded w-2/3 mb-6 ${isDarkMode ? 'bg-[#2A2A2A]' : 'bg-gray-200'}`}></div>
            <div className={`rounded-2xl p-6 ${isDarkMode ? 'bg-[#1E1E1E]' : 'bg-white'}`}>
              <div className={`h-6 rounded w-1/2 mb-4 ${isDarkMode ? 'bg-[#2A2A2A]' : 'bg-gray-200'}`}></div>
              <div className="space-y-3">
                <div className={`h-4 rounded w-3/4 ${isDarkMode ? 'bg-[#2A2A2A]' : 'bg-gray-200'}`}></div>
                <div className={`h-4 rounded w-5/6 ${isDarkMode ? 'bg-[#2A2A2A]' : 'bg-gray-200'}`}></div>
                <div className={`h-4 rounded w-2/3 ${isDarkMode ? 'bg-[#2A2A2A]' : 'bg-gray-200'}`}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <main className={`flex-1 flex flex-col ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
      {/* Header */}
      <header className="flex-none pt-6 pb-4">
        <div className="flex items-center justify-center gap-2">
          <SparklesIcon className={`h-7 w-7 ${isDarkMode ? 'text-indigo-400' : 'text-indigo-600'}`} />
          <h1 className={`text-2xl font-bold ${isDarkMode ? 'text-gray-100' : 'text-gray-900'}`}>
            Choose Your Plan
          </h1>
        </div>
        <p className={`mt-2 text-center text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
          Select the perfect plan for your content creation journey
        </p>
      </header>

      {/* Content */}
      <div className="flex-1 flex items-center justify-center py-8">
        <div className="w-full max-w-5xl px-4 relative">
          {/* Background gradient effect */}
          <div className="absolute inset-0 flex justify-center pointer-events-none">
            <div className={`w-full h-full max-w-lg blur-[120px] opacity-20 ${
              isDarkMode 
                ? 'bg-gradient-to-r from-indigo-500/30 via-purple-500/30 to-pink-500/30' 
                : 'bg-[#ffffff]'
            }`}></div>
          </div>
          
          {/* Content */}
          <div className="relative w-full">
            {userCountry && (
              <SubscriptionCard 
                subscription={null} 
                isPublic={false}
                showTrialInPublic={!user?.isInTrial && !user?.hasTrialExpired}
                userCountry={userCountry}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default SubscriptionPage; 