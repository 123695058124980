import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTheme } from '../context/ThemeContext';
import { useRecentActivities } from '../context/RecentActivitiesContext';
import { useNavigate } from 'react-router-dom';
import { socket } from '../socket';
import { 
  PencilIcon, 
  TrashIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  CheckIcon,
  DocumentDuplicateIcon,
  ClipboardDocumentCheckIcon,
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
  ArrowLeftIcon,
  BugAntIcon
} from '@heroicons/react/24/outline';
import { DocumentDuplicateIcon as DocumentDuplicateSolidIcon } from '@heroicons/react/24/solid';
import { Button } from './ui/button';
import ResetIcon from './personas/ResetIcon';
import { publishToLinkedIn, isLinkedInConnected } from '../services/linkedinPublishService';
import { toast } from 'react-hot-toast';
import { getLinkedInAuthUrl } from '../services/linkedinAuthService';
import Portal from './ui/Portal';
import { ExpandIcon } from './icons/ExpandCollapseIcons';
import { updatePostPublishStatus } from '../api';
import { motion } from 'framer-motion';
import SchedulePostModal from './LinkedInScheduler/SchedulePostModal';
import { PublishButton as PublishIcon } from './icons/PublishButton';
import PropTypes from 'prop-types';

const LinkedInLogo = ({ className = "w-3 h-3" }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
  </svg>
);

const TitleForLinkedInPostMockup = ({
  initialTitle = '',
  onTitleChange = () => {},
  onEdit = () => {},
  onCopy = () => {},
  onRemove = () => {},
  currentIndex = 0,
  totalPosts = 0,
  onNavigateNext = () => {},
  onNavigatePrevious = () => {},
  isFocusMode = false,
  isEditing = false,
  onSave = () => {},
  onCancel = () => {},
  isInCanvasExperience = false,
  isInteracting = false,
  onDelete = () => {},
  isHovered = false,
  canvasId = '',
  postId = '',
  selectedPersona,
  onFullScreen = () => {},
  content = '',
  isPublished = false,
  onPublish = () => {},
  onSchedule = () => {},
  hasContentChanges = false,
  isUploading = false,
  mediaFiles = []
}) => {
  const { isDarkMode } = useTheme();
  const { refreshActivities } = useRecentActivities();
  const [postTitle, setPostTitle] = useState(initialTitle || '');
  const [editingTitle, setEditingTitle] = useState(initialTitle || '');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const publishButtonRef = useRef(null);
  const buttonRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const copyTimeoutRef = useRef(null);
  const [showHyphenWarning, setShowHyphenWarning] = useState(false);
  const warningTimeoutRef = useRef(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isLinkedInEnabled, setIsLinkedInEnabled] = useState(false);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [publishState, setPublishState] = useState('idle');
  const [showRepublishConfirm, setShowRepublishConfirm] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [postToSchedule, setPostToSchedule] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showPublishDropdown, setShowPublishDropdown] = useState(false);
  const [isAutoSaved, setIsAutoSaved] = useState(false);

  // Add function to calculate dropdown position first
  const calculateDropdownPosition = useCallback(() => {
    if (!buttonRef.current) return { top: 0, left: 0 };

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const dropdownWidth = 140; // Width of our dropdown
    
    // Calculate position to appear right next to the button
    let left = buttonRect.right - dropdownWidth; // Align right edge with button
    let top = buttonRect.bottom + 4; // 4px gap between button and dropdown

    // Ensure dropdown doesn't go off-screen to the left
    if (left < 10) {
      left = 10; // 10px margin from left edge
    }

    // Ensure dropdown doesn't go off-screen to the right
    if (left + dropdownWidth > window.innerWidth - 10) {
      left = window.innerWidth - dropdownWidth - 10;
    }

    // If dropdown would go below viewport, show it above the button
    const dropdownHeight = 130; // Approximate height of dropdown
    if (top + dropdownHeight > window.innerHeight) {
      top = buttonRect.top - dropdownHeight - 4;
    }

    return {
      top: Math.max(10, top), // Ensure at least 10px from top
      left
    };
  }, []);

  // Update this effect to use a transition
  useEffect(() => {
    if (initialTitle !== postTitle) {
      setPostTitle(initialTitle || '');
      setEditingTitle(initialTitle || '');
    }
  }, [initialTitle, postTitle]);

  // Check LinkedIn connection status
  useEffect(() => {
    const checkLinkedInStatus = async () => {
      const isConnected = await isLinkedInConnected();
      setIsLinkedInEnabled(isConnected);
    };
    checkLinkedInStatus();
  }, []);

  const handleTitleChange = (e) => {
    const hasHyphen = e.target.value.includes('-');
    if (hasHyphen) {
      setShowHyphenWarning(true);
      // Clear any existing timeout
      if (warningTimeoutRef.current) {
        clearTimeout(warningTimeoutRef.current);
      }
      // Hide warning after 2 seconds
      warningTimeoutRef.current = setTimeout(() => {
        setShowHyphenWarning(false);
      }, 2000);
    }
    const sanitizedValue = e.target.value.replace(/-/g, '');
    setEditingTitle(sanitizedValue);
  };

  const handleTitleSave = async () => {
    const newTitle = editingTitle.trim();
    if (newTitle && newTitle !== postTitle) {
      const sanitizedTitle = newTitle.replace(/-/g, '');
      setPostTitle(sanitizedTitle);
      onTitleChange(sanitizedTitle);
    }
    setIsEditingTitle(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    } else if (e.key === 'Escape') {
      setEditingTitle(initialTitle);
      setIsEditingTitle(false);
    }
  };

  useEffect(() => {
    if (isEditingTitle && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditingTitle]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        handleTitleSave();
      }
    };

    if (isEditingTitle) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditingTitle]);

  const titleStyles = `
    py-1 text-xs font-medium w-full truncate
    ${isDarkMode 
      ? 'text-[#e8e8e8] bg-transparent' 
      : 'text-gray-800 bg-transparent'}
    ${isEditing ? 'cursor-text' : 'cursor-pointer'}
  `;

  const placeholderStyles = `
    ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
  `;

  // Update this function to only show the title without the "Post X -" prefix
  const formatTitle = (title) => {
    return title.trim() ? title : 'Add post title';
  };

  const staticPartStyles = `
    ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
    mr-1
  `;

  useEffect(() => {
    if (copySuccess) {
      if (copyTimeoutRef.current) {
        clearTimeout(copyTimeoutRef.current);
      }
      copyTimeoutRef.current = setTimeout(() => {
        setCopySuccess(false);
      }, 2000); // Increased from 1000 to 2000ms for better visibility
    }
    return () => {
      if (copyTimeoutRef.current) {
        clearTimeout(copyTimeoutRef.current);
      }
    };
  }, [copySuccess]);

  // Update the save handler
  const handleSave = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    // Call onSave since the content is managed by the parent component
    if (onSave) {
      // Preserve the existing content structure
      const contentToSave = { ...content };

      // If we have mediaFiles array, preserve all existing fields
      if (content?.mediaFiles?.length > 0) {
        contentToSave.mediaFiles = content.mediaFiles.map(file => ({
          ...file,
          originalName: file.originalName || file.name,
          s3Key: file.s3Key,
          bucket: file.bucket,
          mimeType: file.mimeType || file.type,
          size: file.size,
          metadata: file.metadata
        }));
        contentToSave.mediaFile = contentToSave.mediaFiles[0];
      }
      // If we have a single mediaFile, ensure it's properly structured
      else if (content?.mediaFile) {
        const mediaInfo = {
          ...content.mediaFile,
          originalName: content.mediaFile.originalName || content.mediaFile.name,
          s3Key: content.mediaFile.s3Key,
          bucket: content.mediaFile.bucket,
          mimeType: content.mediaFile.mimeType || content.mediaFile.type,
          size: content.mediaFile.size,
          metadata: content.mediaFile.metadata
        };
        contentToSave.mediaFile = mediaInfo;
        contentToSave.mediaFiles = [mediaInfo];
      }

      onSave(contentToSave);
    }
  };

  const handleFullScreen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onFullScreen();
  };

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (warningTimeoutRef.current) {
        clearTimeout(warningTimeoutRef.current);
      }
    };
  }, []);

  // Add this near the top with other style constants
  const tooltipStyles = `
    absolute -top-8 left-0
    px-2 py-1
    text-xs text-white
    bg-gray-800 dark:bg-gray-700
    rounded-md shadow-sm
    transition-opacity duration-200
    z-10
    whitespace-nowrap
    after:content-['']
    after:absolute
    after:top-full
    after:left-4
    after:border-4
    after:border-transparent
    after:border-t-gray-800
    dark:after:border-t-gray-700
  `;

  // Add logging for content prop
  useEffect(() => {
    console.log('TitleForLinkedInPostMockup received content:', {
      hasContent: !!content,
      contentLength: content?.length,
      contentPreview: content?.substring(0, 50)
    });
  }, [content]);

  // Add reconnect handler
  const handleReconnectLinkedIn = async () => {
    try {
      const state = `reconnect-${Date.now()}`;
      const authUrl = await getLinkedInAuthUrl(state);
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error getting LinkedIn auth URL:', error);
      toast.error('Failed to initiate LinkedIn reconnection');
    }
  };

  // Update publish handler
  const handlePublish = useCallback(async (e, isTestMode = false) => {
    e.preventDefault();
    e.stopPropagation();

    if (isPublished && !isTestMode) {
      setShowRepublishConfirm(true);
      return;
    }

    if (!content) {
      toast.error('Cannot publish empty content');
      return;
    }

    try {
      setPublishState('publishing');
      
      const status = await isLinkedInConnected();
      console.log('LinkedIn connection status:', status);
      
      if (!status.isConnected || status.needsReconnect) {
        const message = status.error || 
          (status.needsReconnect 
            ? 'LinkedIn authentication has expired' 
            : 'Please connect your LinkedIn account first');
        
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>{message}</p>
            <button
              onClick={handleReconnectLinkedIn}
              className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {status.needsReconnect ? 'Reconnect LinkedIn' : 'Connect LinkedIn'}
            </button>
          </div>,
          { duration: 8000 }
        );
        setPublishState('idle');
        return;
      }
      
      // Process media files keeping Cloudinary URLs
      let processedMediaFiles = [];
      if (mediaFiles?.length > 0) {
        try {
          processedMediaFiles = mediaFiles.map(file => ({
            cloudinaryUrl: file.cloudinaryUrl,
            url: file.cloudinaryUrl || file.url,
            originalName: file.originalName || file.name,
            mimeType: file.mimeType || file.type
          }));
          
          console.log('Processed media files for publishing:', processedMediaFiles);
        } catch (mediaError) {
          console.error('Error processing media files:', mediaError);
          toast.error('Failed to process media files');
          setPublishState('idle');
          return;
        }
      }
      
      const result = await publishToLinkedIn(content, postTitle, processedMediaFiles, isTestMode);
      console.log('Publish result:', result);

      if (isTestMode) {
        toast.success(
          <div className="flex flex-col space-y-2">
            <p>Media upload test successful!</p>
            <div className="text-xs text-gray-500">
              {result?.steps?.mediaDownloaded && '✓ Media downloaded'}<br/>
              {result?.steps?.mediaUploaded && '✓ Media uploaded to LinkedIn'}<br/>
              {result?.steps?.assetRegistered && '✓ Asset registered with LinkedIn'}
              {!result?.steps?.mediaDownloaded && !result?.steps?.mediaUploaded && !result?.steps?.assetRegistered && (
                <>No media files to test</>
              )}
            </div>
          </div>,
          { duration: 5000 }
        );
        setPublishState('idle');
        return;
      }
      
      await updatePostPublishStatus(canvasId, postId, true);
      setPublishState('published');
      
      if (onPublish) {
        onPublish('published');
      }
      
      toast.success('Successfully published to LinkedIn!');
      
      if (refreshActivities) {
        refreshActivities();
      }

    } catch (error) {
      console.error('Error publishing to LinkedIn:', error);
      setPublishState('idle');
      
      if (onPublish) {
        onPublish('idle');
      }
      
      const errorMessage = error.message || 'Failed to publish to LinkedIn';
      
      if (errorMessage.includes('duplicate') || errorMessage.includes('BadRequestResponseException')) {
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>This content has already been published to LinkedIn</p>
            <span className="text-xs text-gray-500">
              LinkedIn prevents posting duplicate content
            </span>
          </div>,
          { duration: 5000 }
        );
        
        try {
          await updatePostPublishStatus(canvasId, postId, true);
          setPublishState('published');
          if (onPublish) {
            onPublish('published');
          }
        } catch (dbError) {
          console.error('Error updating publish status:', dbError);
        }
        return;
      }
      
      const needsReconnect = errorMessage.includes('expired') || 
                            errorMessage.includes('connect') ||
                            errorMessage.includes('authentication');
      
      if (needsReconnect) {
        toast.error(
          <div className="flex flex-col space-y-2">
            <p>{errorMessage}</p>
            <button
              onClick={handleReconnectLinkedIn}
              className="text-sm px-4 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {errorMessage.includes('expired') ? 'Reconnect LinkedIn' : 'Connect LinkedIn'}
            </button>
          </div>,
          { duration: 8000 }
        );
      } else {
        toast.error(errorMessage);
      }
    }
  }, [content, postTitle, canvasId, postId, isPublished, onPublish, refreshActivities, mediaFiles]);

  // Add this new handler for test mode
  const handleTestMediaUpload = (e) => {
    handlePublish(e, true);
  };

  // Add this handler for republish confirmation
  const handleRepublishConfirm = useCallback(() => {
    setShowRepublishConfirm(false);
    // Reset publish state before republishing
    setPublishState('idle');
    if (onPublish) {
      onPublish('idle');
    }
    handlePublish({ preventDefault: () => {}, stopPropagation: () => {} });
  }, [handlePublish, onPublish]);

  // Add this tooltip component
  const Tooltip = ({ children, content }) => (
    <div className="group relative">
      {children}
      <div className={`absolute left-1/2 -translate-x-1/2 -top-8 px-2 py-1 bg-gray-900 text-white text-xs rounded 
        opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap pointer-events-none
        ${isDarkMode ? 'bg-gray-800' : 'bg-gray-900'}`}>
        {content}
        <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 border-4 border-transparent border-t-gray-900"></div>
      </div>
    </div>
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current?.contains(event.target)) {
        return;
      }
      
      if (!event.target.closest('.dropdown-menu')) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showDropdown]);

  const handleDropdownToggle = (e) => {
    e.stopPropagation();
    if (!showDropdown) {
      const { top, left } = calculateDropdownPosition();
      setDropdownPosition({ top, left });
    }
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (showDropdown) {
      const handlePositionUpdate = () => {
        const { top, left } = calculateDropdownPosition();
        setDropdownPosition({ top, left });
      };

      handlePositionUpdate();
      window.addEventListener('scroll', handlePositionUpdate, true);
      window.addEventListener('resize', handlePositionUpdate);

      return () => {
        window.removeEventListener('scroll', handlePositionUpdate, true);
        window.removeEventListener('resize', handlePositionUpdate);
      };
    }
  }, [showDropdown, calculateDropdownPosition]);

  // Add this component for the animated dots
  const PublishingDots = () => (
    <span className="flex space-x-1">
      <span className="animate-bounce delay-0">.</span>
      <span className="animate-bounce delay-100">.</span>
      <span className="animate-bounce delay-200">.</span>
    </span>
  );

  // Add this effect to close dropdowns when entering edit mode
  useEffect(() => {
    if (isEditing) {
      setShowPublishDropdown(false);
      setShowDropdown(false);
    }
  }, [isEditing]);

  // Update the renderPublishButton function to not render in edit mode
  const renderPublishButton = () => {
    if (isEditing) return null;
    
    // If publishing is in progress
    if (publishState === 'publishing') {
      return (
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="tiny-with-text"
            isDarkMode={isDarkMode}
            aria-label="Publishing to LinkedIn"
            className="flex items-center gap-1 cursor-not-allowed h-8"
            disabled
          >
            <LinkedInLogo className="w-3 h-3 text-blue-400" />
            <span className="text-blue-400 flex items-center">
              Publishing<PublishingDots />
            </span>
          </Button>
        </div>
      );
    }

    // Default state - single button with dropdown
    return (
      <div className="flex items-center gap-2">
        <div className="relative inline-block">
          <Button
            ref={publishButtonRef}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!content) {
                toast.error('Cannot publish empty content');
                return;
              }
              setShowPublishDropdown(!showPublishDropdown);
              if (!showPublishDropdown) {
                // Update position when opening
                const { top, left } = calculateDropdownPosition();
                setDropdownPosition({ top, left });
              }
            }}
            variant="ghost"
            size="tiny-with-text"
            isDarkMode={isDarkMode}
            aria-label="Publish options"
            className={`
              flex items-center gap-1 h-8 pr-2
              ${isPublished ? 'text-blue-500 hover:bg-blue-500/10' : 'text-blue-500 hover:bg-blue-500/10'}
            `}
          >
            <LinkedInLogo className="w-3 h-3" />
            <span>{isPublished ? 'Published' : 'Publish'}</span>
            <ChevronDownIcon className="w-3 h-3 ml-0.5" />
          </Button>

          {showPublishDropdown && !isEditing && (
            <Portal>
              <div 
                className="fixed inset-0 z-[999]" 
                onClick={() => setShowPublishDropdown(false)}
              >
                <div
                  className={`
                    fixed min-w-[180px]
                    ${isDarkMode 
                      ? 'bg-[#1e1e1e] border-[#333333]' 
                      : 'bg-white border-gray-200'
                    }
                    rounded-lg shadow-lg 
                    border
                    z-[1000]
                    dropdown-menu
                    animate-dropdown-fade-in
                    ${isDarkMode 
                      ? 'ring-1 ring-slate-600/50 shadow-xl shadow-black/20' 
                      : 'ring-1 ring-gray-200/50 shadow-xl shadow-black/10'
                    }
                  `}
                  style={{
                    top: `${dropdownPosition.top}px`,
                    left: `${dropdownPosition.left}px`,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePublish(e);
                      setShowPublishDropdown(false);
                    }}
                    className={`
                      flex items-center pl-2.5 pr-3 py-2 text-sm w-full
                      ${isDarkMode 
                        ? 'hover:bg-slate-700/50 text-slate-200' 
                        : 'hover:bg-gray-100/80 text-gray-700'
                      }
                      transition-colors duration-150
                      first:rounded-t-lg
                    `}
                  >
                    <PublishIcon className="w-5 h-5 mr-2" />
                    <span className="whitespace-nowrap text-xs font-medium">
                      Publish now
                    </span>
                  </button>

                  <div className={`h-[1px] mx-2 ${isDarkMode ? 'bg-[#333333]' : 'bg-gray-200'}`} />

                  {/* Temporarily hidden Test Media Upload button
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTestMediaUpload(e);
                      setShowPublishDropdown(false);
                    }}
                    className={`
                      flex items-center px-3 py-2 text-sm w-full
                      ${isDarkMode 
                        ? 'hover:bg-slate-700/50 text-slate-200' 
                        : 'hover:bg-gray-100/80 text-gray-700'
                      }
                      transition-colors duration-150
                    `}
                  >
                    <BugAntIcon className="w-4 h-4 mr-2" />
                    <span className="whitespace-nowrap text-xs font-medium">
                      Test Media Upload
                    </span>
                  </button>

                  <div className={`h-[1px] mx-2 ${isDarkMode ? 'bg-[#333333]' : 'bg-gray-200'}`} />
                  */}

                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSchedule(e);
                      setShowPublishDropdown(false);
                    }}
                    className={`
                      flex items-center px-3 py-2 text-sm w-full
                      ${isDarkMode 
                        ? 'hover:bg-slate-700/50 text-slate-200' 
                        : 'hover:bg-gray-100/80 text-gray-700'
                      }
                      transition-colors duration-150
                      last:rounded-b-lg
                    `}
                  >
                    <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 2V5M16 2V5M3.5 9.09H20.5M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M15.6947 13.7H15.7037M15.6947 16.7H15.7037M11.9955 13.7H12.0045M11.9955 16.7H12.0045M8.29431 13.7H8.30329M8.29431 16.7H8.30329" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span className="whitespace-nowrap text-xs font-medium">
                      Schedule for later
                    </span>
                  </button>
                </div>
              </div>
            </Portal>
          )}
        </div>
      </div>
    );
  };

  // Add this component for the republish confirmation dialog
  const RepublishConfirmDialog = ({ isOpen, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
      <Portal>
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Backdrop with subtle blur effect */}
          <div className="absolute inset-0 bg-black/20 backdrop-blur-[1px]" onClick={onCancel} />
          
          {/* Dialog content - Inspired by shadcn's compact design */}
          <div className={`
            relative
            w-full
            max-w-[320px]
            mx-4
            p-4
            rounded-lg
            shadow-lg
            ${isDarkMode 
              ? 'bg-slate-900 border border-slate-800' 
              : 'bg-white border border-slate-200'
            }
          `}>
            <div className="flex flex-col gap-3">
              <div className="flex items-start gap-3">
                <div className={`
                  p-2 
                  rounded-full 
                  ${isDarkMode ? 'bg-slate-800' : 'bg-slate-100'}
                `}>
                  <ExclamationTriangleIcon className="w-5 h-5 text-yellow-500" />
                </div>
                <div>
                  <h3 className={`
                    text-sm 
                    font-semibold 
                    ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
                  `}>
                    Confirm Republish
                  </h3>
                  <p className={`
                    text-xs 
                    mt-1 
                    ${isDarkMode ? 'text-slate-400' : 'text-slate-600'}
                  `}>
                    This post has already been published. Are you sure you want to publish it again?
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-end gap-2">
                <button
                  onClick={onCancel}
                  className={`
                    px-3 
                    py-1.5 
                    text-xs 
                    font-medium 
                    rounded-md
                    transition-colors
                    ${isDarkMode 
                      ? 'text-slate-300 hover:bg-slate-800' 
                      : 'text-slate-700 hover:bg-slate-100'
                    }
                  `}
                >
                  Cancel
                </button>
                <button
                  onClick={onConfirm}
                  className={`
                    px-3 
                    py-1.5 
                    text-xs 
                    font-medium 
                    rounded-md
                    bg-blue-500 
                    text-white
                    hover:bg-blue-600
                    transition-colors
                  `}
                >
                  Republish
                </button>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );
  };

  // Add this new component
  const BackButton = ({ onClick, isDarkMode }) => {
    // Move buttonVariants inside the component
    const buttonVariants = {
      initial: { 
        opacity: 0,
        y: -4
      },
      animate: { 
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.2,
          ease: 'easeOut'
        }
      },
      hover: {
        backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
        transition: {
          duration: 0.2
        }
      },
      tap: {
        scale: 0.98
      }
    };

    return (
      <motion.button
        onClick={onClick}
        className={`
          flex items-center gap-1.5 px-3 py-1.5 rounded-md text-sm
          transition-all duration-200
          ${isDarkMode 
            ? 'text-slate-400 hover:text-slate-300 hover:bg-slate-800/50' 
            : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
          }
        `}
        variants={buttonVariants}
        initial="initial"
        animate="animate"
        whileHover="hover"
        whileTap="tap"
      >
        <ArrowLeftIcon className="w-4 h-4" />
        <span>Back</span>
      </motion.button>
    );
  };

  // Update the handleSchedule function
  const handleSchedule = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    console.log(' Schedule button clicked - Initial data:', {
      content,
      postTitle,
      mediaFiles,
      canvasId,
      postId
    });
    
    if (!content) {
      toast.error('Cannot schedule empty content');
      return;
    }
    
    // Process media files using Cloudinary URLs
    const processedMediaFiles = mediaFiles?.map(file => {
      console.log('🔍 Processing media file:', {
        original: file,
        cloudinaryUrl: file.cloudinaryUrl,
        url: file.url
      });

      // Prioritize cloudinaryUrl, then fall back to url
      const mediaUrl = file.cloudinaryUrl || file.url;
      if (!mediaUrl) {
        console.warn('⚠️ No URL found for media file:', file);
      }

      return {
        ...file, // Keep all original properties
        cloudinaryUrl: mediaUrl, // Use cloudinaryUrl as primary URL
        url: mediaUrl, // Keep url for backward compatibility
        originalName: file.originalName || file.name,
        mimeType: file.mimeType || file.type,
        size: file.size,
        metadata: file.metadata || {},
        _key: `${mediaUrl}-${Date.now()}` // Add unique key for React rendering
      };
    }) || [];

    console.log('🔍 Processed mediaFiles for scheduling:', processedMediaFiles);
    
    // Set up the post data and show the modal
    const scheduleData = {
      id: postId,
      canvasId,
      content: content.trim(),
      title: postTitle || 'Untitled Post',
      mediaFiles: processedMediaFiles,
      postId,
      imageUrl: processedMediaFiles?.[0]?.cloudinaryUrl || processedMediaFiles?.[0]?.url, // Use cloudinaryUrl first
      status: 'draft',
      scheduledFor: selectedDate.toISOString(),
      publishHistory: {
        originalContent: null,
        publishedContent: null,
        lastPublishedAt: null
      },
      editState: {
        isEditedLocally: false,
        lastEditedAt: null,
        originalPublishedContent: null
      }
    };
    
    // Navigate to the scheduler with the post data
    navigate('/scheduler', { 
      state: { postToSchedule: scheduleData },
      replace: true 
    });
  };

  // Add effect to monitor modal state and data
  useEffect(() => {
    if (showScheduleModal) {
      console.log('🔍 Schedule modal opened with data:', {
        postToSchedule,
        selectedDate,
        hasMediaFiles: postToSchedule?.mediaFiles?.length > 0
      });
    }
  }, [showScheduleModal, postToSchedule, selectedDate]);

  // Update the handleCloseScheduleModal function
  const handleCloseScheduleModal = () => {
    setShowScheduleModal(false);
    setPostToSchedule(null);
    setSelectedDate(new Date());
  };

  // Update effect to handle auto-save state when media is uploaded
  useEffect(() => {
    if (isUploading) {
      setIsAutoSaved(false);
    } else if (mediaFiles?.length > 0 && !hasContentChanges) {
      setIsAutoSaved(true);
    }
  }, [isUploading, mediaFiles, hasContentChanges]);

  // Add effect to handle content changes
  useEffect(() => {
    if (hasContentChanges) {
      setIsAutoSaved(false);
    }
  }, [hasContentChanges]);

  return (
    <>
      <div 
        className="m-2 ml-0 px-0 pl-2"
      >
        <div ref={containerRef} className="flex items-center justify-between w-full">
          <div className="flex-1 flex items-center min-w-0">
            {isEditingTitle ? (
              <div className="flex flex-col w-full relative">
                {showHyphenWarning && (
                  <div className={tooltipStyles}>
                    Hyphens are not allowed in the title
                  </div>
                )}
                <div className={`${titleStyles} flex items-center`}>
                  <input
                    ref={inputRef}
                    type="text"
                    value={editingTitle}
                    onChange={handleTitleChange}
                    onBlur={handleTitleSave}
                    onKeyDown={handleKeyPress}
                    className="bg-transparent focus:outline-none w-full"
                    placeholder="Add title"
                  />
                </div>
              </div>
            ) : (
              <div
                onClick={() => setIsEditingTitle(true)}
                className={`${titleStyles} cursor-pointer truncate`}
              >
                <span 
                  className={`${postTitle.trim() ? '' : placeholderStyles} truncate`}
                >
                  {formatTitle(postTitle)}
                </span>
              </div>
            )}
          </div>

          {/* Update the action buttons container to ensure proper alignment */}
          <div className={`
            flex items-center space-x-2 h-8
            ${isEditing ? 'opacity-100' : 'opacity-100'}
          `}>
            {isEditing ? (
              <>
                <Button
                  onClick={onCancel}
                  variant="ghost"
                  size="tiny-with-text"
                  isDarkMode={isDarkMode}
                  aria-label="Go back"
                  disabled={isUploading}
                  className={`
                    flex items-center gap-1
                    ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}
                  `}
                >
                  <ArrowLeftIcon className="w-4 h-4" />
                  <span>Back</span>
                </Button>
                {/* Only show Save button if there are content changes that aren't just media uploads */}
                {hasContentChanges && !isUploading && !isAutoSaved && (
                  <Button
                    onClick={onSave}
                    variant="ghost"
                    size="tiny-with-text"
                    isDarkMode={isDarkMode}
                    aria-label="Save changes"
                    disabled={isUploading}
                    className={`
                      flex items-center gap-1
                      ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}
                    `}
                  >
                    <CheckIcon className="w-4 h-4" />
                    <span>{isUploading ? 'Uploading...' : 'Save'}</span>
                  </Button>
                )}
                {isAutoSaved && (
                  <div className={`
                    flex items-center gap-1 px-2 py-1 rounded-md text-xs
                    ${isDarkMode 
                      ? 'text-green-400 bg-green-400/10' 
                      : 'text-green-600 bg-green-50'
                    }
                  `}>
                    <CheckIcon className="w-4 h-4" />
                    <span>Saved</span>
                  </div>
                )}
                <Button
                  onClick={handleFullScreen}
                  variant="ghost"
                  size="tiny"
                  isDarkMode={isDarkMode}
                  aria-label="Edit in full screen"
                  className="h-8"
                >
                  <ExpandIcon className="w-4 h-4" />
                </Button>
              </>
            ) : (
              <>
                {renderPublishButton()}

                <Button
                  onClick={handleFullScreen}
                  variant="ghost"
                  size="tiny"
                  isDarkMode={isDarkMode}
                  aria-label="Edit in full screen"
                  className="h-8"
                >
                  <ExpandIcon className="w-4 h-4" />
                </Button>

                <div className="relative dropdown-container inline-block h-8">
                  <Button
                    ref={buttonRef}
                    onClick={handleDropdownToggle}
                    variant="ghost"
                    size="tiny"
                    isDarkMode={isDarkMode}
                    aria-label="More options"
                    className="h-8"
                  >
                    <EllipsisVerticalIcon className="w-5 h-5" />
                  </Button>

                  {showDropdown && (
                    <Portal>
                      <div 
                        className="fixed inset-0 z-[999]" 
                        onClick={(e) => {
                          if (e.target === e.currentTarget) {
                            setShowDropdown(false);
                          }
                        }}
                      >
                        <div
                          className={`
                            fixed
                            min-w-[140px]
                            ${isDarkMode 
                              ? 'bg-[#1e1e1e] border-[#333333]' 
                              : 'bg-white border-gray-200'
                            }
                            rounded-lg shadow-lg 
                            border
                            z-[1000]
                            dropdown-menu
                            animate-dropdown-fade-in
                            ${isDarkMode 
                              ? 'ring-1 ring-slate-600/50 shadow-xl shadow-black/20' 
                              : 'ring-1 ring-gray-200/50 shadow-xl shadow-black/10'
                            }
                          `}
                          style={{
                            top: `${dropdownPosition.top}px`,
                            left: `${dropdownPosition.left}px`,
                          }}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              onEdit(e);
                              setShowDropdown(false);
                            }}
                            className={`
                              flex items-center px-3 py-2 text-sm w-full
                              ${isDarkMode 
                                ? 'hover:bg-slate-700/50 text-slate-200' 
                                : 'hover:bg-gray-100/80 text-gray-700'
                              }
                              transition-colors duration-150
                              first:rounded-t-lg
                            `}
                          >
                            <PencilIcon className="w-4 h-4 mr-2" />
                            <span className="whitespace-nowrap text-xs font-medium">
                              Edit post
                            </span>
                          </button>

                          <div className={`h-[1px] mx-2 ${isDarkMode ? 'bg-[#333333]' : 'bg-gray-200'}`} />

                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              if (onCopy) {
                                onCopy();
                                // Show success state
                                setCopySuccess(true);
                                // Reset after 2 seconds
                                setTimeout(() => {
                                  setCopySuccess(false);
                                }, 2000);
                              }
                              setShowDropdown(false);
                            }}
                            className={`
                              flex items-center px-3 py-2 text-sm w-full
                              ${isDarkMode 
                                ? 'hover:bg-slate-700/50 text-slate-200' 
                                : 'hover:bg-gray-100/80 text-gray-700'
                              }
                              transition-colors duration-150
                            `}
                          >
                            {copySuccess ? (
                              <DocumentDuplicateSolidIcon className="w-4 h-4 mr-2" />
                            ) : (
                              <DocumentDuplicateIcon className="w-4 h-4 mr-2" />
                            )}
                            <span className="whitespace-nowrap text-xs font-medium">
                              {copySuccess ? 'Copied!' : 'Copy post'}
                            </span>
                          </button>

                          <div className={`h-[1px] mx-2 ${isDarkMode ? 'bg-[#333333]' : 'bg-gray-200'}`} />

                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              onDelete(e);
                              setShowDropdown(false);
                            }}
                            className={`
                              flex items-center px-3 py-2 text-sm w-full
                              ${isDarkMode 
                                ? 'hover:bg-slate-700/50 text-red-400' 
                                : 'hover:bg-gray-100/80 text-red-500'
                              }
                              transition-colors duration-150
                              last:rounded-b-lg
                            `}
                          >
                            <TrashIcon className="w-4 h-4 mr-2" />
                            <span className="whitespace-nowrap text-xs font-medium">
                              Delete post
                            </span>
                          </button>
                        </div>
                      </div>
                    </Portal>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Add republish confirmation dialog */}
      <RepublishConfirmDialog
        isOpen={showRepublishConfirm}
        onConfirm={handleRepublishConfirm}
        onCancel={() => setShowRepublishConfirm(false)}
      />

      {/* Add the SchedulePostModal */}
      <SchedulePostModal
        selectedDate={selectedDate}
        isOpen={showScheduleModal}
        onClose={handleCloseScheduleModal}
        onSchedule={onSchedule}
        isDarkMode={isDarkMode}
        postData={postToSchedule}
      />
    </>
  );
};

TitleForLinkedInPostMockup.propTypes = {
  initialTitle: PropTypes.string,
  onTitleChange: PropTypes.func,
  onEdit: PropTypes.func,
  onCopy: PropTypes.func,
  onRemove: PropTypes.func,
  currentIndex: PropTypes.number,
  totalPosts: PropTypes.number,
  onNavigateNext: PropTypes.func,
  onNavigatePrevious: PropTypes.func,
  isFocusMode: PropTypes.bool,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isInCanvasExperience: PropTypes.bool,
  isInteracting: PropTypes.bool,
  onDelete: PropTypes.func,
  isHovered: PropTypes.bool,
  canvasId: PropTypes.string,
  postId: PropTypes.string,
  onFullScreen: PropTypes.func,
  content: PropTypes.string,
  isPublished: PropTypes.bool,
  onPublish: PropTypes.func,
  onSchedule: PropTypes.func,
  hasContentChanges: PropTypes.bool,
  isUploading: PropTypes.bool,
  mediaFiles: PropTypes.arrayOf(PropTypes.shape({
    cloudinaryUrl: PropTypes.string,
    url: PropTypes.string,
    originalName: PropTypes.string,
    name: PropTypes.string,
    mimeType: PropTypes.string,
    type: PropTypes.string
  }))
};

export default React.memo(TitleForLinkedInPostMockup);