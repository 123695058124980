import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { CanvasGridIcon } from './icons/CanvasGridIcon';
import CanvasItemMenu from './CanvasItemMenu';
import { useTheme } from '../context/ThemeContext';
import CanvasIconPicker from './CanvasIconPicker';
import { updateCanvasIcon } from '../api';
import AnimatedLoader from './common/AnimatedLoader';
import { 
  BookOpenIcon,
  ChartBarIcon,
  FolderIcon,
  StarIcon,
  DocumentTextIcon,
  BeakerIcon,
  BriefcaseIcon,
  CalendarIcon,
  ChatBubbleLeftIcon,
  CodeBracketIcon,
  CogIcon,
  CommandLineIcon,
  CpuChipIcon,
  GlobeAltIcon,
  HeartIcon,
  LightBulbIcon,
  LinkIcon,
  MusicalNoteIcon,
  PhotoIcon,
  PuzzlePieceIcon,
  RocketLaunchIcon,
  ShoppingBagIcon,
  VideoCameraIcon,
  WrenchIcon,
  EllipsisVerticalIcon,
  CheckIcon,
  ClockIcon,
  XMarkIcon,
  ArrowLeftIcon
} from '@heroicons/react/24/outline';

const icons = {
  default: CanvasGridIcon,
  books: BookOpenIcon,
  chart: ChartBarIcon,
  folder: FolderIcon,
  star: StarIcon,
  note: DocumentTextIcon,
  beaker: BeakerIcon,
  briefcase: BriefcaseIcon,
  calendar: CalendarIcon,
  chat: ChatBubbleLeftIcon,
  code: CodeBracketIcon,
  settings: CogIcon,
  terminal: CommandLineIcon,
  chip: CpuChipIcon,
  globe: GlobeAltIcon,
  heart: HeartIcon,
  bulb: LightBulbIcon,
  link: LinkIcon,
  music: MusicalNoteIcon,
  photo: PhotoIcon,
  puzzle: PuzzlePieceIcon,
  rocket: RocketLaunchIcon,
  shopping: ShoppingBagIcon,
  video: VideoCameraIcon,
  tools: WrenchIcon
};

const getItemStyles = (viewMode, isDarkMode, itemId) => {
  const baseStyles = `
    transition-all duration-200 ease-out
    w-full min-w-0 max-w-full
    border
    ${isDarkMode 
      ? 'bg-[#161616] hover:bg-[#1a1a1a] border-[#2a2a2a]' 
      : 'bg-white hover:bg-slate-50 border-slate-100'}
  `;

  return `${baseStyles} 
    rounded-lg
    overflow-hidden
  `;
};

const CanvasItem = ({
  item,
  viewMode,
  onSelect,
  onRename,
  onDelete,
  isEditing,
  editingTitle,
  onEditingChange,
  onTitleChange,
  onEditComplete,
  isSelected,
  isSelectionMode,
  onLongPress,
  onIconChange,
  addNotification,
  isDeleting
}) => {
  const { isDarkMode } = useTheme();
  const editInputRef = useRef(null);
  const longPressRef = useRef(null);
  const touchStartRef = useRef(null);
  const [isIconPickerOpen, setIsIconPickerOpen] = useState(false);
  const iconRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isReverting, setIsReverting] = useState(false);

  const IconComponent = icons[item.icon] || icons.default;

  const handleMouseDown = () => {
    longPressRef.current = setTimeout(() => {
      onLongPress();
    }, 500); // 500ms for long press
  };

  const handleMouseUp = () => {
    if (longPressRef.current) {
      clearTimeout(longPressRef.current);
    }
  };

  const handleTouchStart = (e) => {
    // Don't start long press if clicking menu or menu content
    if (e.target.closest('[data-menu-trigger]') || e.target.closest('[data-menu-content]')) {
      return;
    }

    touchStartRef.current = {
      time: Date.now(),
      x: e.touches[0].clientX,
      y: e.touches[0].clientY
    };
    
    longPressRef.current = setTimeout(() => {
      onLongPress();
    }, 500);
  };

  const handleTouchEnd = (e) => {
    // Don't handle canvas selection if clicking menu or menu content
    if (e.target.closest('[data-menu-trigger]') || e.target.closest('[data-menu-content]')) {
      return;
    }

    if (longPressRef.current) {
      clearTimeout(longPressRef.current);
    }

    // Only trigger select if it was a short tap (not a long press)
    // and if we're not in selection mode
    if (touchStartRef.current && 
        Date.now() - touchStartRef.current.time < 500 && 
        !isSelectionMode) {
      onSelect(item);
    }
    
    touchStartRef.current = null;
  };

  const handleTouchMove = (e) => {
    if (longPressRef.current) {
      clearTimeout(longPressRef.current);
    }
    touchStartRef.current = null;
  };

  const handleClick = (e) => {
    // Don't handle clicks if clicking menu, menu content, or icon picker
    if (e.target.closest('[data-menu-trigger]') || 
        e.target.closest('[data-menu-content]') || 
        e.target.closest('[data-icon-picker]') ||
        isIconPickerOpen ||
        isEditing) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    
    // In selection mode, toggle selection
    if (isSelectionMode) {
      onSelect(item);
      return;
    }

    // Not in selection mode, open the canvas
    onSelect(item);
  };

  useEffect(() => {
    return () => {
      if (longPressRef.current) {
        clearTimeout(longPressRef.current);
      }
    };
  }, []);

  const renderTitle = () => {
    if (isEditing) {
      const hasChanges = editingTitle.trim() !== item.title;
      return (
        <div 
          className="flex flex-col w-full min-w-0"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="relative flex items-center gap-1.5 min-w-0">
            <div className="flex-1 min-w-0">
              <input
                ref={editInputRef}
                type="text"
                value={editingTitle}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onTitleChange(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter' && hasChanges) {
                    e.preventDefault();
                    onEditComplete(item.id, editingTitle);
                  }
                  if (e.key === 'Escape') {
                    e.preventDefault();
                    onEditingChange(null);
                  }
                }}
                onBlur={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (hasChanges) {
                    onEditComplete(item.id, editingTitle);
                  } else {
                    onEditingChange(null);
                  }
                }}
                className={`
                  w-full bg-transparent border-none outline-none text-xs
                  py-0.5 px-1
                  ${isDarkMode 
                    ? 'text-slate-200 placeholder-slate-500' 
                    : 'text-slate-900 placeholder-slate-400'
                  }
                  focus:ring-0
                `}
                autoFocus
              />
              <div className={`
                absolute inset-0 -z-10
                rounded-md
                pointer-events-none
                ${isDarkMode 
                  ? 'bg-[#1e1e1e]' 
                  : 'bg-slate-100'
                }
              `} />
            </div>

            {/* Action Button */}
            <motion.button
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.1 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (hasChanges) {
                  handleSave(e);
                } else {
                  handleCancel(e);
                }
              }}
              disabled={isReverting || isSaving}
              className={`
                relative
                p-0.5 rounded-md
                flex items-center justify-center
                transition-all duration-200
                ${hasChanges
                  ? isDarkMode 
                    ? 'text-blue-400 hover:text-blue-300' 
                    : 'text-blue-500 hover:text-blue-600'
                  : isDarkMode
                    ? 'text-slate-400 hover:text-slate-300'
                    : 'text-slate-500 hover:text-slate-600'
                }
                ${(isReverting || isSaving) && 'opacity-50 cursor-not-allowed'}
              `}
              title={hasChanges ? "Save" : "Back"}
            >
              {isSaving || isReverting ? (
                <AnimatedLoader 
                  size={8} 
                  color={hasChanges
                    ? (isDarkMode ? '#93c5fd' : '#2563eb')
                    : (isDarkMode ? '#94a3b8' : '#475569')
                  } 
                />
              ) : hasChanges ? (
                <CheckIcon className="w-3 h-3" />
              ) : (
                <ArrowLeftIcon className="w-3 h-3" />
              )}
            </motion.button>
          </div>
        </div>
      );
    }

    return (
      <h3 
        className={`
          text-xs font-medium truncate
          py-0.5 px-1 -mx-1
          rounded-md
          transition-colors duration-200
          ${isDarkMode 
            ? 'text-slate-200' 
            : 'text-slate-900'
          }
        `}
      >
        {item.title}
      </h3>
    );
  };

  const handleIconClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isSelectionMode) {
      setIsIconPickerOpen(true);
    } else {
      onSelect(item);
    }
  };

  const handleIconSelect = (iconName) => {
    console.log('Icon selected:', { itemId: item.id, iconName, currentIcon: item.icon });
    
    // Close picker immediately for better UX
    setIsIconPickerOpen(false);
    
    // Store old icon for potential rollback
    const oldIcon = item.icon || 'default';
    
    // Optimistically update the icon
    onIconChange(item.id, iconName);
    
    // Make API call in background
    updateCanvasIcon(item.id, iconName).catch(error => {
      console.error('Error updating canvas icon:', error);
      
      // Revert to old icon on error
      onIconChange(item.id, oldIcon);
      
      // Show error notification
      addNotification('Failed to update icon', 'error');
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (editingTitle.trim() === item.title) {
      onEditingChange(null);
      return;
    }

    setIsSaving(true);
    try {
      await onEditComplete(item.id, editingTitle);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (editingTitle === item.title) {
      onEditingChange(null);
      return;
    }

    setIsReverting(true);
    try {
      await new Promise(resolve => setTimeout(resolve, 150)); // Small delay for UX
      onEditingChange(null);
    } finally {
      setIsReverting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 5 }}
      transition={{ duration: 0.2 }}
      className={`
        relative min-w-[200px]
        ${isDeleting ? 'pointer-events-none opacity-50' : ''}
        ${isSelectionMode ? 'cursor-pointer select-none' : ''}
        ${!isDeleting && !isEditing && !isSelectionMode ? 'cursor-pointer' : ''}
      `}
      onClick={handleClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className={`
        relative py-2 px-2.5
        rounded-lg
        transition-all duration-200
        border
        ${isDarkMode 
          ? 'bg-[#161616] hover:bg-[#1a1a1a] border-[#2a2a2a]' 
          : 'bg-white hover:bg-slate-50 border-slate-100'
        }
        ${isSelected 
          ? isDarkMode 
            ? 'ring-1 ring-blue-500/30' 
            : 'ring-1 ring-blue-500/20'
          : ''
        }
        group
      `}>
        <div 
          className="flex items-center gap-2 min-w-0"
          onClick={(e) => {
            // Only stop propagation if we're editing
            if (isEditing) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          {/* Icon - Always visible */}
          <div
            ref={iconRef}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (!isEditing) {
                handleIconClick(e);
              }
            }}
            className={`
              flex-shrink-0 p-1 rounded-md
              transition-colors duration-200
              ${isEditing 
                ? isDarkMode 
                  ? 'text-slate-500' 
                  : 'text-slate-400'
                : isDarkMode 
                  ? 'text-slate-400 hover:text-slate-300' 
                  : 'text-slate-500 hover:text-slate-600'
              }
              ${isEditing ? 'cursor-default' : 'cursor-pointer'}
            `}
            data-icon-picker
          >
            <IconComponent className="w-3.5 h-3.5" />
          </div>

          {/* Title and Post Count */}
          <div 
            className="flex-1 min-w-0 mr-1"
          >
            {renderTitle()}
            <AnimatePresence mode="popLayout">
              {!isEditing && (
                <motion.div 
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                  className={`
                    flex items-center gap-1.5 mt-0.5
                    text-[10px]
                    ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
                  `}
                >
                  {item.posts?.length || 0} posts • {getTimeAgo(item.lastEdited)}
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Menu */}
          <AnimatePresence mode="popLayout">
            <motion.div 
              initial={{ opacity: 1 }}
              animate={{ opacity: isEditing ? 0 : 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15 }}
              onClick={(e) => {
                // Always stop propagation for menu clicks
                e.preventDefault();
                e.stopPropagation();
              }}
              className={isEditing ? 'invisible' : 'visible'}
              data-menu-trigger
            >
              <CanvasItemMenu
                item={item}
                onRename={onRename}
                onDelete={onDelete}
                isDarkMode={isDarkMode}
                onToggleSelect={(item) => {
                  if (isSelectionMode) {
                    onSelect(item);
                  }
                }}
                isSelected={isSelected}
                isSelectionMode={isSelectionMode}
                onEnterSelectionMode={() => {
                  onLongPress();
                  return false;
                }}
              >
                <div />
              </CanvasItemMenu>
            </motion.div>
          </AnimatePresence>
        </div>

        {/* Loading Overlay */}
        {isDeleting && (
          <div 
            className="absolute inset-0 flex items-center justify-center z-50 bg-black/5 backdrop-blur-[1px] rounded-lg"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className={`
              flex items-center gap-1.5 px-2 py-1 rounded-md
              ${isDarkMode 
                ? 'bg-[#1a1a1a]/90 text-slate-300' 
                : 'bg-white/90 text-slate-600'
              }
            `}>
              <AnimatedLoader size={12} />
              <span className="text-[10px]">Deleting...</span>
            </div>
          </div>
        )}

        {/* Icon Picker - Only show when not editing */}
        {!isEditing && (
          <CanvasIconPicker
            isOpen={isIconPickerOpen}
            onClose={() => setIsIconPickerOpen(false)}
            onSelect={handleIconSelect}
            currentIcon={item.icon || 'default'}
            isDarkMode={isDarkMode}
            anchorEl={iconRef.current}
          />
        )}
      </div>
    </motion.div>
  );
};

const getTimeAgo = (date) => {
  const minutes = Math.floor((new Date() - new Date(date)) / 60000);
  if (minutes < 1) return 'now';
  if (minutes < 60) return `${minutes}m`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h`;
  const days = Math.floor(hours / 24);
  if (days < 7) return `${days}d`;
  const weeks = Math.floor(days / 7);
  if (weeks < 4) return `${weeks}w`;
  const months = Math.floor(days / 30);
  if (months < 12) return `${months}mo`;
  return `${Math.floor(months / 12)}y`;
};

CanvasItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    lastEdited: PropTypes.string.isRequired,
    posts: PropTypes.array,
    icon: PropTypes.string
  }).isRequired,
  viewMode: PropTypes.oneOf(['grid', 'list']).isRequired,
  onSelect: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editingTitle: PropTypes.string.isRequired,
  onEditingChange: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onEditComplete: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isSelectionMode: PropTypes.bool.isRequired,
  onLongPress: PropTypes.func.isRequired,
  onIconChange: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
};

export default CanvasItem; 