import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, pdf } from '@react-pdf/renderer';

Font.register({
  family: 'Helvetica',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/@canvas-fonts/helvetica@1.0.4/Helvetica.ttf'
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    padding: 40,
    fontFamily: 'Helvetica',
    backgroundColor: '#ffffff'
  },
  header: {
    marginBottom: 30,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    color: '#111827'
  },
  framework: {
    marginBottom: 20
  },
  frameworkTitle: {
    fontSize: 16,
    marginBottom: 15,
    color: '#374151'
  },
  group: {
    marginBottom: 15
  },
  groupTitle: {
    fontSize: 14,
    marginBottom: 8,
    color: '#4B5563'
  },
  idea: {
    fontSize: 12,
    marginBottom: 4,
    paddingLeft: 12,
    color: '#1F2937'
  },
  details: {
    fontSize: 10,
    color: '#6B7280',
    paddingLeft: 12,
    marginBottom: 8
  },
  outcome: {
    fontSize: 10,
    color: '#059669',
    paddingLeft: 12,
    marginBottom: 8
  },
  relevance: {
    fontSize: 10,
    color: '#DC2626',
    paddingLeft: 12,
    marginBottom: 4,
    fontStyle: 'italic'
  }
});

// Add the formatting functions
const getFormattedFrameworkName = (name) => {
  const cleanName = name.replace('FRAMEWORK', '').trim();
  switch (cleanName.toLowerCase()) {
    case 'actionable':
      return 'Action oriented ideas';
    case 'aspirational':
      return 'Strategic perspective ideas';
    case 'anthropological':
      return 'Human emotion-driven ideas';
    case 'analytical':
      return 'Data-driven ideas';
    default:
      return cleanName;
  }
};

const getFormattedSeriesName = (name) => {
  const seriesMap = {
    'tips': 'Quick implementation ideas',
    'hacks': 'Smart solutions & strategies',
    'ultimate guides': 'Comprehensive guides',
    'lessons': 'Key lessons',
    'mistakes': 'Common mistakes',
    'reflections': 'Strategic insights',
    'getting started': 'Quick start guide',
    'fears': 'Emotional challenges',
    'challenges': 'Implementation challenges',
    'misconceptions': 'Common misconceptions',
    'trends': 'Industry trends',
    'impact': 'Impact analysis',
    'methodology': 'Methodology breakdown'
  };
  
  const cleanName = name.toLowerCase().replace(' series', '').trim();
  return seriesMap[cleanName] || name;
};

const ContentStrategyDocument = ({ ideas, selectedPersona }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>
          {selectedPersona 
            ? `Content Ideas for ${selectedPersona.name}`
            : 'Content Strategy'
          }
        </Text>
      </View>

      {ideas.data.generatedIdeas.map((topicIdeas, index) => (
        <View key={index} style={styles.framework}>
          <Text style={styles.frameworkTitle}>
            {topicIdeas.topic}
          </Text>

          <View style={styles.group}>
            {topicIdeas.ideas.map((idea, ideaIndex) => (
              <View key={ideaIndex}>
                {/* Angle */}
                <Text style={styles.idea}>• {idea.angle}</Text>
                
                {/* Hook */}
                <Text style={styles.details}>Hook: {idea.hook}</Text>
                
                {/* Content */}
                <Text style={styles.details}>Content: {idea.content}</Text>
                
                {/* Value and Expertise */}
                <Text style={styles.outcome}>Value: {idea.value}</Text>
                <Text style={styles.relevance}>Expertise: {idea.expertiseAlignment}</Text>
              </View>
            ))}
          </View>
        </View>
      ))}
    </Page>
  </Document>
);

class ContentStrategyPDFDownloader {
  constructor(ideas, selectedPersona) {
    this.ideas = ideas;
    this.selectedPersona = selectedPersona;
  }

  async generatePDF() {
    try {
      const blob = await pdf(
        <ContentStrategyDocument 
          ideas={this.ideas} 
          selectedPersona={this.selectedPersona} 
        />
      ).toBlob();
      
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const fileName = this.selectedPersona 
        ? `content-strategy-${this.selectedPersona.name.toLowerCase()}-${Date.now()}.pdf`
        : `content-strategy-${Date.now()}.pdf`;
      link.download = fileName;
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      URL.revokeObjectURL(url);
      
      return true;
    } catch (error) {
      console.error('PDF generation error:', error);
      throw new Error('Failed to generate PDF. Please try again.');
    }
  }
}

export default ContentStrategyPDFDownloader; 