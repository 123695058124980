import React, { useState, useRef, useEffect, useCallback } from 'react';
import { 
  EllipsisVerticalIcon, 
  PencilIcon, 
  CheckIcon, 
  XMarkIcon, 
  PlayIcon, 
  PauseIcon, 
  TrashIcon, 
  ChevronDownIcon, 
  ChevronUpIcon, 
  ClipboardIcon, 
  TagIcon, 
  SparklesIcon, 
  ChevronRightIcon,
  ArrowPathIcon,
  BriefcaseIcon,
  ClipboardDocumentListIcon,
  FlagIcon,
  QuestionMarkCircleIcon,
  ChartBarIcon,
  ArrowTrendingUpIcon,
  LightBulbIcon,
  ChatBubbleLeftRightIcon,
  EnvelopeIcon
} from '@heroicons/react/24/outline';
import { ClipboardIcon as ClipboardIconSolid } from '@heroicons/react/24/solid';
import { updateTranscriptionTitle, regenerateTranscriptionTitle, getSignedUrl, deleteVoiceNote, generateContent, saveGeneratedContent, deleteGeneratedContent } from '../api';
import { format } from 'date-fns';
import TagMenu from './TagMenu';
import { useMediaQuery } from 'react-responsive';
import TranscriptionMenu from './TranscriptionMenu';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { ExpandIcon } from './icons/ExpandCollapseIcon';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-hot-toast';
import AnimatedLoader from './common/AnimatedLoader';

// Updated base styles with modern shadcn-like approach
const baseStyles = {
  card: {
    base: `
      relative overflow-hidden rounded-xl border 
      transition-all duration-200 ease-in-out
      motion-reduce:transition-none
    `,
    dark: 'bg-[#151515]/95 border-[#333333] hover:border-[#444444] shadow-lg shadow-black/10',
    light: 'bg-white/95 border-gray-200/50 hover:border-gray-300/50 shadow-sm'
  },
  button: {
    base: `
      inline-flex items-center justify-center
      rounded-lg transition-colors duration-200
      focus-visible:outline-none focus-visible:ring-2 
      focus-visible:ring-offset-2 disabled:pointer-events-none
      disabled:opacity-50
    `,
    dark: 'focus-visible:ring-slate-300 hover:bg-slate-800/50',
    light: 'focus-visible:ring-slate-950 hover:bg-slate-100'
  },
  input: {
    base: `
      flex w-full rounded-md border px-3 py-2 text-sm
      transition-colors duration-200
      focus-visible:outline-none focus-visible:ring-1
      disabled:cursor-not-allowed disabled:opacity-50
    `,
    dark: 'bg-[#1a1a1a] border-[#333333] text-slate-200 focus-visible:ring-slate-400',
    light: 'bg-white border-gray-200 text-slate-900 focus-visible:ring-slate-400'
  },
  tag: {
    base: `
      inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium
      transition-colors duration-200
    `,
    dark: 'bg-slate-800 text-slate-300 hover:bg-slate-700',
    light: 'bg-slate-100 text-slate-900 hover:bg-slate-200'
  }
};

// Updated action button styles
const actionButtonStyles = {
  base: `
    inline-flex items-center justify-center rounded-lg p-1.5
    transition-colors duration-200 focus-visible:outline-none
    focus-visible:ring-2 focus-visible:ring-offset-2
  `,
  dark: {
    default: 'text-slate-400 hover:bg-slate-800 focus-visible:ring-slate-300',
    active: 'bg-purple-500/20 text-purple-400 hover:bg-purple-500/30'
  },
  light: {
    default: 'text-slate-600 hover:bg-slate-100 focus-visible:ring-slate-950',
    active: 'bg-purple-100 text-purple-600 hover:bg-purple-200'
  }
};

// Update the ActionButton component with new styles
const ActionButton = React.forwardRef(({ onClick, icon: Icon, tooltip, isDarkMode, isActive, className = '' }, ref) => (
  <button
    ref={ref}
    onClick={onClick}
    className={`
      ${actionButtonStyles.base}
      ${isDarkMode 
        ? isActive ? actionButtonStyles.dark.active : actionButtonStyles.dark.default
        : isActive ? actionButtonStyles.light.active : actionButtonStyles.light.default
      }
      ${className}
    `}
  >
    <Icon className="h-3.5 w-3.5" />
    {tooltip && (
      <span className="
        absolute -top-8 left-1/2 -translate-x-1/2
        rounded bg-slate-950 px-2 py-1 text-xs
        text-slate-50 opacity-0 transition-opacity
        group-hover/tooltip:opacity-100 dark:bg-slate-900
      ">
        {tooltip}
      </span>
    )}
  </button>
));

// Update the Spinner component with modern styling
const Spinner = ({ className }) => (
  <svg 
    className={`animate-spin ${className}`} 
    xmlns="http://www.w3.org/2000/svg" 
    fill="none" 
    viewBox="0 0 24 24"
  >
    <circle 
      className="opacity-25" 
      cx="12" 
      cy="12" 
      r="10" 
      stroke="currentColor" 
      strokeWidth="3"
    />
    <path 
      className="opacity-75" 
      fill="currentColor" 
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

// Add this after the Spinner component and before the ErrorMessage component
const LoaderAnimation = () => (
  <div className="flex items-center gap-2">
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ 
        scale: [0.8, 1.2, 0.8],
        opacity: [0.3, 1, 0.3]
      }}
      transition={{
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      className={`w-1.5 h-1.5 rounded-full bg-current`}
    />
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ 
        scale: [0.8, 1.2, 0.8],
        opacity: [0.3, 1, 0.3]
      }}
      transition={{
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut",
        delay: 0.2
      }}
      className={`w-1.5 h-1.5 rounded-full bg-current`}
    />
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ 
        scale: [0.8, 1.2, 0.8],
        opacity: [0.3, 1, 0.3]
      }}
      transition={{
        duration: 1,
        repeat: Infinity,
        ease: "easeInOut",
        delay: 0.4
      }}
      className={`w-1.5 h-1.5 rounded-full bg-current`}
    />
  </div>
);

// Update the ErrorMessage component with modern styling
const ErrorMessage = ({ message, onRetry, isDarkMode }) => (
  <div 
    role="alert"
    aria-live="polite"
    className={`
      flex items-center justify-between rounded-md p-2
      ${isDarkMode ? 'bg-red-900/20' : 'bg-red-50'}
      transition-colors duration-200
    `}
  >
    <span className={`text-xs ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>
      {message}
    </span>
    {onRetry && (
      <button
        onClick={onRetry}
        className={`
          text-xs font-medium ml-2
          transition-colors duration-200
          ${isDarkMode 
            ? 'text-blue-400 hover:text-blue-300' 
            : 'text-blue-600 hover:text-blue-500'}
        `}
      >
        Retry
      </button>
    )}
  </div>
);

// Update the SuccessMessage component with modern styling
const SuccessMessage = ({ message, isDarkMode }) => (
  <div 
    role="status"
    aria-live="polite"
    className={`
      flex items-center rounded-md p-2
      ${isDarkMode ? 'bg-green-900/20' : 'bg-green-50'}
      transition-colors duration-200
    `}
  >
    <CheckIcon className={`
      h-4 w-4 mr-2
      ${isDarkMode ? 'text-green-400' : 'text-green-500'}
    `} />
    <span className={`
      text-xs
      ${isDarkMode ? 'text-green-400' : 'text-green-600'}
    `}>
      {message}
    </span>
  </div>
);

// Add these suggested prompts near the top of the file with other constants
const SUGGESTED_PROMPTS = [
  {
    label: "Summarize",
    prompt: "Create a concise summary of the main points from this transcription",
    icon: SparklesIcon
  },
  {
    label: "Proofread & Edit",
    prompt: "Proofread, edit, and organize these notes into a clear and structured format",
    icon: PencilIcon
  },
  {
    label: "Write Tweet",
    prompt: "Create an engaging tweet from the key insights in this transcription",
    icon: ArrowPathIcon
  },
  {
    label: "LinkedIn Post",
    prompt: "Transform this transcription into an insightful LinkedIn post",
    icon: BriefcaseIcon
  },
  {
    label: "Extract Action Items",
    prompt: "Extract and list all action items and tasks mentioned in this transcription",
    icon: ClipboardDocumentListIcon
  },
  {
    label: "Key Points",
    prompt: "Extract the key points and main takeaways from this transcription",
    icon: FlagIcon
  },
  {
    label: "Questions",
    prompt: "Generate follow-up questions based on this transcription",
    icon: QuestionMarkCircleIcon
  },
  {
    label: "Analysis",
    prompt: "Provide a detailed analysis of the main themes and insights",
    icon: ChartBarIcon
  },
  {
    label: "Next Steps",
    prompt: "Suggest actionable next steps based on this transcription",
    icon: ArrowTrendingUpIcon
  },
  {
    label: "Ideas",
    prompt: "Generate creative ideas and suggestions based on this content",
    icon: LightBulbIcon
  },
  {
    label: "Social Post",
    prompt: "Create a compelling social media post highlighting the key insights",
    icon: ChatBubbleLeftRightIcon
  },
  {
    label: "Email",
    prompt: "Draft a professional email summarizing the main points",
    icon: EnvelopeIcon
  }
];

// Add this after the SUGGESTED_PROMPTS constant
const getItemColors = (index, isDarkMode) => {
  // Array of subtle color combinations for both dark and light modes
  const colors = [
    // Purple theme
    {
      dark: {
        bg: 'bg-purple-500/5',
        border: 'border-purple-500/20',
        hoverBorder: 'hover:border-purple-500/30',
        hoverBg: 'hover:bg-purple-500/10',
        expandedBg: 'bg-purple-500/10',
      },
      light: {
        bg: 'bg-purple-50/50',
        border: 'border-purple-100',
        hoverBorder: 'hover:border-purple-200',
        hoverBg: 'hover:bg-purple-50',
        expandedBg: 'bg-purple-50',
      }
    },
    // Blue theme
    {
      dark: {
        bg: 'bg-blue-500/5',
        border: 'border-blue-500/20',
        hoverBorder: 'hover:border-blue-500/30',
        hoverBg: 'hover:bg-blue-500/10',
        expandedBg: 'bg-blue-500/10',
      },
      light: {
        bg: 'bg-blue-50/50',
        border: 'border-blue-100',
        hoverBorder: 'hover:border-blue-200',
        hoverBg: 'hover:bg-blue-50',
        expandedBg: 'bg-blue-50',
      }
    },
    // Green theme
    {
      dark: {
        bg: 'bg-emerald-500/5',
        border: 'border-emerald-500/20',
        hoverBorder: 'hover:border-emerald-500/30',
        hoverBg: 'hover:bg-emerald-500/10',
        expandedBg: 'bg-emerald-500/10',
      },
      light: {
        bg: 'bg-emerald-50/50',
        border: 'border-emerald-100',
        hoverBorder: 'hover:border-emerald-200',
        hoverBg: 'hover:bg-emerald-50',
        expandedBg: 'bg-emerald-50',
      }
    },
    // Rose theme
    {
      dark: {
        bg: 'bg-rose-500/5',
        border: 'border-rose-500/20',
        hoverBorder: 'hover:border-rose-500/30',
        hoverBg: 'hover:bg-rose-500/10',
        expandedBg: 'bg-rose-500/10',
      },
      light: {
        bg: 'bg-rose-50/50',
        border: 'border-rose-100',
        hoverBorder: 'hover:border-rose-200',
        hoverBg: 'hover:bg-rose-50',
        expandedBg: 'bg-rose-50',
      }
    },
  ];

  const colorIndex = index % colors.length;
  return isDarkMode ? colors[colorIndex].dark : colors[colorIndex].light;
};

// Add this near the top of the file with other style constants
export const markdownStyles = {
  light: {
    p: 'text-gray-700 mb-2',
    h1: 'text-xl font-bold text-gray-900 mb-2',
    h2: 'text-lg font-bold text-gray-900 mb-2',
    h3: 'text-base font-bold text-gray-900 mb-2',
    ul: 'list-disc pl-4 mb-2',
    ol: 'list-decimal pl-4 mb-2',
    li: 'text-gray-700 mb-1',
    blockquote: 'border-l-4 border-gray-200 pl-4 italic text-gray-600',
    code: 'bg-gray-100 rounded px-1 font-mono text-sm text-gray-800',
    pre: 'bg-gray-100 rounded p-2 mb-2 overflow-x-auto',
  },
  dark: {
    p: 'text-gray-300 mb-2',
    h1: 'text-lg font-bold text-gray-100 mb-2',
    h2: 'text-base font-bold text-gray-100 mb-2',
    h3: 'text-sm font-bold text-gray-100 mb-2',
    ul: 'list-disc pl-4 mb-2',
    ol: 'list-decimal pl-4 mb-2',
    li: 'text-gray-300 mb-1',
    blockquote: 'border-l-4 border-gray-600 pl-4 italic text-gray-400',
    code: 'bg-gray-700 rounded px-1 font-mono text-sm text-gray-200',
    pre: 'bg-gray-700 rounded p-2 mb-2 overflow-x-auto',
  }
};

// Update the formatAIResponse function to better handle original formatting
export const formatAIResponse = (content) => {
  // If content is not a string or is empty, return it as is
  if (typeof content !== 'string' || !content) {
    return content;
  }

  // Handle arrays of content (React children)
  if (Array.isArray(content)) {
    return content.map(item => 
      typeof item === 'string' ? formatAIResponse(item) : item
    );
  }

  // Split content into lines
  const lines = content.split('\n');
  
  // Process each line
  const formattedLines = lines.map(line => {
    const trimmedLine = line.trim();
    
    // Check for existing bullet points or numbered lists
    if (trimmedLine.match(/^[-•]\s/)) {
      // Keep existing bullet points but standardize the spacing
      return `• ${trimmedLine.substring(2).trim()}`;
    } else if (trimmedLine.match(/^\d+\.\s/)) {
      // Keep the original number but standardize format
      const number = trimmedLine.match(/^\d+/)[0];
      return `${number}. ${trimmedLine.substring(trimmedLine.indexOf('.') + 1).trim()}`;
    } else if (trimmedLine.match(/^[#→◆]\s/)) {
      // Keep existing special markers
      return trimmedLine;
    } else if (trimmedLine.match(/^[-*]\s/)) {
      // Convert markdown style bullets to bullet points
      return `• ${trimmedLine.substring(2).trim()}`;
    }
    
    return trimmedLine;
  });

  // Rejoin the lines
  return formattedLines.join('\n');
};

// Update the GeneratedContentSection component
const GeneratedContentSection = ({ item, index, isDarkMode, expandedItems, onToggleExpand, onCopy, onDelete, copiedContentIndex }) => {
  const colors = getItemColors(index, isDarkMode);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete(item._id);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.2 }}
      className={`
        relative rounded-lg border transition-all duration-200
        ${colors.bg}
        ${expandedItems.includes(index)
          ? `${colors.expandedBg} ${colors.border}`
          : `${colors.border} ${colors.hoverBorder} ${colors.hoverBg}`
        }
        ${isDeleting ? 'pointer-events-none' : ''}
      `}
      role="region"
      aria-expanded={expandedItems.includes(index)}
    >
      {/* Delete Loading Overlay */}
      {isDeleting && (
        <div className={`
          absolute inset-0 z-50 
          flex items-center justify-center
          backdrop-blur-[1px]
          ${isDarkMode ? 'bg-black/20' : 'bg-white/20'}
          rounded-lg
        `}>
          <div className={`
            flex items-center gap-2 px-2 py-1.5 rounded-md
            ${isDarkMode ? 'bg-[#1a1a1a]/90 text-slate-200' : 'bg-white/90 text-slate-800'}
            border ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
            shadow-sm
          `}>
            <AnimatedLoader size={12} color={isDarkMode ? '#94a3b8' : '#475569'} />
            <span className="text-[10px] font-medium">Deleting...</span>
          </div>
        </div>
      )}

      {/* Header Section */}
      <div 
        className={`
          flex items-center gap-3 p-3
          ${expandedItems.includes(index) ? 'border-b' : ''}
          ${isDarkMode 
            ? expandedItems.includes(index) ? 'border-[#333333]' : ''
            : expandedItems.includes(index) ? 'border-gray-200' : ''
          }
        `}
      >
        {/* Rest of the header content */}
        <button
          onClick={() => onToggleExpand(index)}
          className={`
            group flex items-center flex-grow min-w-0 text-left
            transition-all duration-200 rounded-md px-2 py-1.5
            ${isDarkMode 
              ? 'hover:bg-[#252525]/50' 
              : 'hover:bg-gray-100/50'
            }
          `}
          aria-expanded={expandedItems.includes(index)}
        >
          <ChevronRightIcon 
            className={`
              transition-transform duration-200
              ${expandedItems.includes(index) ? 'transform rotate-90' : ''}
              ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
              w-3.5 h-3.5 mr-2 flex-shrink-0
              group-hover:scale-105
            `}
          />
          <div className="flex items-center gap-2 truncate">
            <SparklesIcon className={`
              w-3.5 h-3.5 flex-shrink-0 transition-colors duration-200
              ${isDarkMode 
                ? expandedItems.includes(index)
                  ? 'text-purple-400'
                  : 'text-gray-400 group-hover:text-purple-400'
                : expandedItems.includes(index)
                  ? 'text-purple-500'
                  : 'text-gray-400 group-hover:text-purple-500'
              }
            `} />
            <span className={`
              truncate text-xs font-medium transition-colors duration-200
              ${isDarkMode 
                ? 'text-gray-300 group-hover:text-gray-200' 
                : 'text-gray-600 group-hover:text-gray-900'
              }
            `}>
              {item.prompt}
            </span>
          </div>
        </button>

        {/* Action Buttons */}
        <div className="flex items-center gap-1 flex-shrink-0">
          {/* Copy Button */}
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => onCopy(item.content, index)}
            className={`
              p-1.5 rounded-md transition-all duration-200
              ${isDarkMode
                ? copiedContentIndex === index
                  ? 'bg-green-500/10 text-green-400'
                  : 'text-gray-400 hover:bg-[#252525]/50 hover:text-gray-300'
                : copiedContentIndex === index
                  ? 'bg-green-50 text-green-600'
                  : 'text-gray-400 hover:bg-gray-100/50 hover:text-gray-600'
              }
            `}
            aria-label={copiedContentIndex === index ? "Copied!" : "Copy content"}
          >
            {copiedContentIndex === index ? (
              <ClipboardIconSolid className="w-3.5 h-3.5" />
            ) : (
              <ClipboardIcon className="w-3.5 h-3.5" />
            )}
          </motion.button>

          {/* Delete Button */}
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleDelete}
            disabled={isDeleting}
            className={`
              p-1.5 rounded-md transition-all duration-200
              ${isDarkMode
                ? 'text-gray-400 hover:bg-red-500/5 hover:text-red-400'
                : 'text-gray-400 hover:bg-red-50/50 hover:text-red-500'}
              ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}
            `}
            aria-label="Delete content"
          >
            <TrashIcon className="w-3.5 h-3.5" />
          </motion.button>
        </div>
      </div>

      {/* Content Section */}
      <AnimatePresence>
        {expandedItems.includes(index) && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="overflow-hidden"
          >
            <div className="p-4 space-y-2">
              <ReactMarkdown
                components={{
                  p: ({node, children}) => (
                    <motion.p 
                      initial={{ opacity: 0, y: 5 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.2 }}
                      className={`
                        text-xs leading-relaxed whitespace-pre-wrap
                        ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
                      `}
                    >
                      {typeof children === 'string' ? formatAIResponse(children) : children}
                    </motion.p>
                  ),
                  ul: ({node, children}) => (
                    <motion.ul 
                      initial={{ opacity: 0, x: -5 }}
                      animate={{ opacity: 1, x: 0 }}
                      className="list-disc pl-4 space-y-1.5 my-2"
                    >
                      {children}
                    </motion.ul>
                  ),
                  ol: ({node, children}) => (
                    <motion.ol 
                      initial={{ opacity: 0, x: -5 }}
                      animate={{ opacity: 1, x: 0 }}
                      className="list-decimal pl-4 space-y-1.5 my-2"
                    >
                      {children}
                    </motion.ol>
                  ),
                  li: ({node, children}) => (
                    <li className={`
                      text-xs leading-relaxed
                      ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
                    `}>
                      {typeof children === 'string' ? formatAIResponse(children) : children}
                    </li>
                  ),
                  h1: ({node, children}) => (
                    <motion.h1 
                      initial={{ opacity: 0, y: -5 }}
                      animate={{ opacity: 1, y: 0 }}
                      className={`
                        text-sm font-semibold mb-2
                        ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}
                      `}
                    >
                      {children}
                    </motion.h1>
                  ),
                  h2: ({node, children}) => (
                    <motion.h2 
                      initial={{ opacity: 0, y: -5 }}
                      animate={{ opacity: 1, y: 0 }}
                      className={`
                        text-xs font-semibold mb-2
                        ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}
                      `}
                    >
                      {children}
                    </motion.h2>
                  ),
                  h3: ({node, children}) => (
                    <motion.h3 
                      initial={{ opacity: 0, y: -5 }}
                      animate={{ opacity: 1, y: 0 }}
                      className={`
                        text-xs font-semibold mb-2
                        ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}
                      `}
                    >
                      {children}
                    </motion.h3>
                  ),
                  blockquote: ({node, children}) => (
                    <motion.blockquote 
                      initial={{ opacity: 0, x: -5 }}
                      animate={{ opacity: 1, x: 0 }}
                      className={`
                        border-l-2 pl-3 my-2
                        ${isDarkMode 
                          ? 'border-gray-600 text-gray-400' 
                          : 'border-gray-300 text-gray-600'
                        }
                      `}
                    >
                      {children}
                    </motion.blockquote>
                  ),
                  code: ({node, children}) => (
                    <code className={`
                      px-1.5 py-0.5 rounded text-xs font-mono
                      ${isDarkMode 
                        ? 'bg-gray-800 text-gray-300' 
                        : 'bg-gray-100 text-gray-800'
                      }
                    `}>
                      {children}
                    </code>
                  ),
                }}
              >
                {item.content}
              </ReactMarkdown>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

// Update the prompt form section
const PromptForm = ({ isDarkMode, prompt, setPrompt, onGenerate, onClose, isGenerating, isMobile }) => (
  <div className="space-y-3" data-prompt-form="true">
    {/* Input Area */}
    <div className="relative">
      <textarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="What would you like me to do with this transcription?"
        className={`
          w-full px-3 py-2 text-xs
          rounded-md border
          resize-none overflow-hidden
          placeholder:text-muted-foreground
          focus-visible:outline-none
          transition-all duration-200
          ${isDarkMode 
            ? 'bg-[#1a1a1a] border-[#333333] text-slate-200 placeholder-slate-500' 
            : 'bg-gray-50/50 border-gray-200 text-gray-700 placeholder-gray-400'}
          focus-visible:ring-[0.5px]
          focus-visible:ring-opacity-40
          ${isDarkMode
            ? 'focus-visible:ring-slate-500'
            : 'focus-visible:ring-gray-300'
          }
          ${isMobile ? 'placeholder:text-[10px]' : ''}
        `}
        rows="2"
        style={{
          minHeight: '60px',
          maxHeight: '120px'
        }}
      />
      
      {/* Character count */}
      <div 
        className={`
          absolute right-2 bottom-2
          text-[10px] tabular-nums
          ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}
        `}
      >
        {prompt.length}/280
      </div>
    </div>

    {/* Suggested Prompts */}
    <div className="flex flex-wrap gap-1.5">
      {SUGGESTED_PROMPTS.map((item, index) => {
        const Icon = item.icon;
        return (
          <button
            key={index}
            onClick={() => setPrompt(item.prompt)}
            className={`
              inline-flex items-center gap-1.5
              text-[11px] px-2.5 py-1.5
              rounded-md border
              transition-all duration-200
              ${isDarkMode
                ? prompt === item.prompt
                  ? 'bg-gray-700/80 border-gray-600 text-gray-200'
                  : 'bg-gray-800/30 border-gray-700/50 text-gray-400 hover:bg-gray-700/50 hover:text-gray-300'
                : prompt === item.prompt
                  ? 'bg-gray-100 border-gray-200 text-gray-700'
                  : 'bg-gray-50/50 border-gray-200/50 text-gray-500 hover:bg-gray-100/80 hover:text-gray-600'
              }
            `}
          >
            <Icon className="w-3.5 h-3.5" />
            <span>{item.label}</span>
          </button>
        );
      })}
    </div>

    {/* Action Buttons */}
    <div className="flex justify-end gap-2.5">
      <button
        onClick={onClose}
        className={`
          px-4 py-1.5 text-[12px]
          rounded-md border
          transition-all duration-200
          ${isDarkMode
            ? 'border-gray-700 text-gray-400 hover:bg-gray-800/80'
            : 'border-gray-200 text-gray-500 hover:bg-gray-50'
          }
        `}
      >
        Cancel
      </button>
      <motion.button
        onClick={() => onGenerate(prompt)}
        disabled={isGenerating || !prompt.trim() || prompt.length > 280}
        whileTap={{ scale: 0.98 }}
        className={`
          px-4 py-1.5 text-[12px] font-medium
          rounded-md
          transition-all duration-200
          min-w-[90px]
          flex items-center justify-center
          ${isDarkMode
            ? isGenerating || !prompt.trim() || prompt.length > 280
              ? 'bg-gray-800 text-gray-500 cursor-not-allowed'
              : 'bg-gray-800 text-gray-200 hover:bg-gray-700'
            : isGenerating || !prompt.trim() || prompt.length > 280
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-gray-900 text-gray-50 hover:bg-gray-800'
          }
        `}
      >
        {isGenerating ? <LoaderAnimation /> : 'Generate'}
      </motion.button>
    </div>
  </div>
);

const updateSessionStorage = (voiceNotes) => {
  sessionStorage.setItem('voiceNotes', JSON.stringify(voiceNotes));
};

// Add this component before TranscriptionGridItem
const DeleteOverlay = ({ isDarkMode }) => (
  <div className={`
    absolute inset-0 z-50 
    flex items-center justify-center
    backdrop-blur-sm
    ${isDarkMode ? 'bg-black/30' : 'bg-white/30'}
  `}>
    <div className={`
      flex items-center gap-2 px-3 py-2 rounded-lg
      ${isDarkMode ? 'bg-[#1a1a1a] text-slate-200' : 'bg-white text-slate-800'}
      border ${isDarkMode ? 'border-[#333333]' : 'border-gray-200'}
      shadow-lg
    `}>
      <AnimatedLoader size={16} color={isDarkMode ? '#94a3b8' : '#475569'} />
      <span className="text-xs font-medium">Deleting...</span>
    </div>
  </div>
);

const TranscriptionGridItem = ({ 
  transcription: initialTranscription, 
  title, 
  isDarkMode, 
  id, 
  onTitleUpdate, 
  createdAt, 
  onDelete, 
  onTranscriptionUpdate, 
  tags = [], 
  onAddTag, 
  onRemoveTag, 
  allTags = [], 
  generatedContents = [] 
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [regenerateError, setRegenerateError] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const inputRef = useRef(null);
  const menuRef = useRef(null);
  const [isEditingTranscription, setIsEditingTranscription] = useState(false);
  const [currentTranscription, setCurrentTranscription] = useState(initialTranscription);
  const [editedTranscription, setEditedTranscription] = useState(initialTranscription.trim());
  const transcriptionInputRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState('auto');
  const [wordCount, setWordCount] = useState(0);
  const [isTranscriptionEdited, setIsTranscriptionEdited] = useState(false);
  const [showCopyAnimation, setShowCopyAnimation] = useState(false);
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);
  const tagMenuRef = useRef(null);
  const [showRightFade, setShowRightFade] = useState(false);
  const tagsContainerRef = useRef(null);
  const [networkError, setNetworkError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;
  const [isGenerating, setIsGenerating] = useState(false);
  const [showPromptForm, setShowPromptForm] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [generatedContentList, setGeneratedContentList] = useState(generatedContents);
  const [copiedContentIndex, setCopiedContentIndex] = useState(null);
  const [expandedContentIndex, setExpandedContentIndex] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const maxPromptLength = 280;
  const isMobile = useMediaQuery({ maxWidth: 640 });
  const [tagButtonElement, setTagButtonElement] = useState(null);
  const [menuButtonElement, setMenuButtonElement] = useState(null);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  // Add this near the top of the component with other state declarations
  const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    typeof navigator !== 'undefined' ? navigator.userAgent : ''
  );

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleTitleSave = async () => {
    try {
      if (!id) {
        console.error('Transcription ID is undefined', { id, title, transcription: currentTranscription });
        return;
      }
      console.log('Updating transcription with ID:', id);
      const updatedTranscription = await updateTranscriptionTitle(id, editedTitle);
      setIsEditing(false);
      onTitleUpdate(id, updatedTranscription.title);

      // Update session storage
      const cachedNotes = JSON.parse(sessionStorage.getItem('voiceNotes') || '[]');
      const updatedNotes = cachedNotes.map(note => 
        note._id === id 
          ? { ...note, title: editedTitle }
          : note
      );
      updateSessionStorage(updatedNotes);

    } catch (error) {
      console.error('Error saving title:', error);
    }
  };

  const handleRegenerateTitle = async () => {
    setIsRegenerating(true);
    setRegenerateError(null);
    try {
      let newTitle = await regenerateTranscriptionTitle(id);
      // Remove any remaining quotation marks
      newTitle = newTitle.replace(/["']/g, '');
      setEditedTitle(newTitle);
      onTitleUpdate(id, newTitle);
    } catch (error) {
      console.error('Error regenerating title:', error);
      setRegenerateError('Trouble generating! Please try again.');
    } finally {
      setIsRegenerating(false);
    }
  };

  const handleTitleCancel = () => {
    setEditedTitle(title);
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTitleSave();
    } else if (e.key === 'Escape') {
      handleTitleCancel();
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteVoiceNote(id);
      
      // Add a small delay for smooth animation
      await new Promise(resolve => setTimeout(resolve, 200));
      
      // Call parent's onDelete
      onDelete(id);
    } catch (error) {
      console.error('Error deleting voice note:', error);
      toast.error('Failed to delete voice note');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCopyTranscription = async () => {
    try {
      await navigator.clipboard.writeText(currentTranscription);
      setIsCopied(true);
      setShowCopyAnimation(true);
      toast.success('Transcription copied to clipboard');
      setTimeout(() => {
        setShowCopyAnimation(false);
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy transcription');
    }
  };

  // Format the date
  const formattedDate = format(new Date(createdAt), 'MMM dd');
  const formattedTime = format(new Date(createdAt), 'h:mm a');

  const formatTranscription = (text) => {
    const paragraphs = text.split(/\n+/).filter(p => p.trim() !== '');

    return paragraphs.map((paragraph, index) => {
      const speakerMatch = paragraph.match(/^([\w\s]+):\s*(.*)/);
      if (speakerMatch) {
        const [, speaker, content] = speakerMatch;
        return (
          <p key={index} className="mb-1">
            <span className={`font-medium ${isDarkMode ? 'text-blue-300' : 'text-blue-600'}`}>
              {speaker}:
            </span>{' '}
            <span>{content}</span>
          </p>
        );
      }
      return <p key={index} className="mb-1">{paragraph}</p>;
    });
  };

  const truncateTranscription = (text) => {
    // Split into lines and take only first 2 lines
    const lines = text.split('\n').slice(0, 2);
    let truncated = lines.join('\n');
    
    // If there were more lines, add ellipsis
    if (text.split('\n').length > 2) {
      truncated += '...';
    }
    
    return truncated;
  };

  useEffect(() => {
    if (isEditingTranscription && transcriptionInputRef.current) {
      transcriptionInputRef.current.focus();
      // Remove the cursor position setting as it's causing the issue
      adjustTextareaHeight(transcriptionInputRef.current);
    }
  }, [isEditingTranscription]);

  const countWords = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  useEffect(() => {
    setWordCount(countWords(currentTranscription));
  }, [currentTranscription]);

  const handleTranscriptionChange = (e) => {
    const newText = e.target.value;
    // Save the current cursor position
    const cursorPosition = e.target.selectionStart;
    
    setEditedTranscription(newText);
    adjustTextareaHeight(e.target);
    setWordCount(countWords(newText));
    setIsTranscriptionEdited(newText !== currentTranscription);
    
    // Restore the cursor position after the state update
    requestAnimationFrame(() => {
      if (e.target) {
        e.target.setSelectionRange(cursorPosition, cursorPosition);
      }
    });
  };

  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
    setTextareaHeight(`${element.scrollHeight}px`);
  };

  useEffect(() => {
    if (isEditingTranscription && transcriptionInputRef.current) {
      adjustTextareaHeight(transcriptionInputRef.current);
    }
  }, [isEditingTranscription, editedTranscription]);

  const handleSaveTranscription = async () => {
    if (!isTranscriptionEdited) return;
    try {
      console.log('Attempting to save transcription. ID:', id);
      if (!id) {
        console.error('Voice note ID is missing');
        throw new Error('Voice note ID is missing');
      }
      console.log('Saving transcription for id:', id);
      console.log('New transcription:', editedTranscription);
      const result = await onTranscriptionUpdate(id, editedTranscription);
      console.log('Transcription update result:', result);
      setIsEditingTranscription(false);
      console.log('Transcription saved successfully');
      setCurrentTranscription(editedTranscription);
      setIsTranscriptionEdited(false);

      // Update session storage
      const cachedNotes = JSON.parse(sessionStorage.getItem('voiceNotes') || '[]');
      const updatedNotes = cachedNotes.map(note => 
        note._id === id 
          ? { ...note, transcription: editedTranscription }
          : note
      );
      updateSessionStorage(updatedNotes);

      toast.success('Transcription saved successfully');
      return result;
    } catch (error) {
      console.error('Error saving transcription:', error);
      toast.error('Failed to save transcription');
      throw error;
    }
  };

  const handleCancelTranscriptionEdit = () => {
    setEditedTranscription(currentTranscription);
    setIsEditingTranscription(false);
    setIsTranscriptionEdited(false);
  };

  useEffect(() => {
    console.log('TranscriptionGridItem mounted or id changed. Current ID:', id);
  }, [id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is inside the tag menu or the tag button
      const isClickInsideMenu = TagMenu.isClickInsideMenu(event.target, tagMenuRef);
      const isClickOnTagButton = tagButtonElement?.contains(event.target);

      if (!isClickInsideMenu && !isClickOnTagButton) {
        setIsTagMenuOpen(false);
        setTagButtonElement(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tagButtonElement]);

  const handleTagClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // If the tag menu is already open with the same button, close it
    if (isTagMenuOpen && tagButtonElement === e.currentTarget) {
      setIsTagMenuOpen(false);
      setTagButtonElement(null);
    } else {
      // Otherwise, open the tag menu with the new button reference
      setIsTagMenuOpen(true);
      // Use the current target as the anchor element
      setTagButtonElement(e.currentTarget);
    }
  };

  const handleAddTagInternal = (tag) => {
    onAddTag(tag); // This will actually call handleAddTag(note._id, tag) in TranscriptionScreen
  };

  const handleRemoveTag = (tagToRemove) => {
    console.log('handleRemoveTag called with tag:', tagToRemove);
    console.log('Current voice note ID:', id);
    
    // If onRemoveTag expects both parameters
    if (onRemoveTag.length === 2) {
      onRemoveTag(id, tagToRemove);
    } else {
      // If onRemoveTag expects just the tag
      onRemoveTag(tagToRemove);
    }
  };

  useEffect(() => {
    const checkScroll = () => {
      if (tagsContainerRef.current) {
        const { scrollWidth, clientWidth, scrollLeft } = tagsContainerRef.current;
        setShowRightFade(scrollWidth > clientWidth && scrollLeft < scrollWidth - clientWidth);
      }
    };

    checkScroll();
    window.addEventListener('resize', checkScroll);
    return () => window.removeEventListener('resize', checkScroll);
  }, [tags]);

  const handleTagsScroll = () => {
    if (tagsContainerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = tagsContainerRef.current;
      setShowRightFade(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const setTextareaRef = (element) => {
    transcriptionInputRef.current = element;
    if (element && isEditingTranscription) {
      element.focus();
      // Only set cursor position when initially entering edit mode
      if (!transcriptionInputRef.current.value) {
        element.setSelectionRange(editedTranscription.length, editedTranscription.length);
      }
      adjustTextareaHeight(element);
    }
  };

  const handleGenerateContent = async () => {
    if (!prompt.trim() || prompt.length > maxPromptLength) return;
    setIsGenerating(true);
    try {
      console.log('Attempting to generate content for voice note:', id);
      const content = await generateContent(id, prompt);
      console.log('Generated content:', content);
      
      // Save the generated content
      const savedContent = await saveGeneratedContent(id, prompt, content);
      console.log('Saved generated content:', savedContent);
      
      // Update the list with the new content at the beginning
      setGeneratedContentList(prevList => {
        const newList = [savedContent.generatedContent, ...prevList];
        
        // Update the voice note in session storage
        const cachedNotes = JSON.parse(sessionStorage.getItem('voiceNotes') || '[]');
        const updatedNotes = cachedNotes.map(note => 
          note._id === id 
            ? { ...note, generatedContents: newList }
            : note
        );
        updateSessionStorage(updatedNotes);
        
        return newList;
      });
      
      setShowPromptForm(false);
      setPrompt(''); // Clear the prompt input
      toast.success('Content generated successfully');
    } catch (error) {
      console.error('Error generating or saving content:', error);
      let errorMessage = 'Failed to generate or save content. Please try again.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
    } finally {
      setIsGenerating(false);
      setShowPromptForm(false);
      setPrompt(''); // Clear the prompt input
    }
  };

  const handleCopyGeneratedContent = async (content, index) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopiedContentIndex(index);
      toast.success('Content copied to clipboard');
      setTimeout(() => setCopiedContentIndex(null), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      toast.error('Failed to copy content');
    }
  };

  const toggleItemExpansion = (index) => {
    setExpandedItems(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index) 
        : [index] // This ensures only one item is expanded at a time
    );
  };

  const handleMenuToggle = (buttonElement) => {
    if (buttonElement) {
      setMenuButtonElement(buttonElement);
      setIsMenuOpen(true);
    } else {
      setMenuButtonElement(null);
      setIsMenuOpen(false);
    }
  };

  // Add this function inside the component before the return statement
  const handlePromptSelect = (selectedPrompt) => {
    setPrompt(selectedPrompt);
  };

  const handleDeleteContent = async (contentId) => {
    setContentToDelete(contentId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!contentToDelete) return;
    
    try {
      setIsDeleting(true);
      await deleteGeneratedContent(id, contentToDelete);
      
      // Update the list and session storage
      const newList = generatedContentList.filter(item => item._id !== contentToDelete);
      setGeneratedContentList(newList);
      
      // Update session storage
      const cachedNotes = JSON.parse(sessionStorage.getItem('voiceNotes') || '[]');
      const updatedNotes = cachedNotes.map(note => 
        note._id === id 
          ? { ...note, generatedContents: newList }
          : note
      );
      sessionStorage.setItem('voiceNotes', JSON.stringify(updatedNotes));
      toast.success('Content deleted successfully');
    } catch (error) {
      console.error('Error deleting generated content:', error);
      toast.error('Failed to delete content');
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
      setContentToDelete(null);
    }
  };

  // Add transition classes for smooth animations
  const buttonBaseClasses = `
    transition-all duration-200 ease-in-out
    focus:outline-none focus:ring-2 focus:ring-offset-2
    ${isDarkMode ? 'focus:ring-blue-500' : 'focus:ring-blue-600'}
    disabled:opacity-50 disabled:cursor-not-allowed
  `;

  const iconButtonClasses = `
    ${buttonBaseClasses}
    p-1.5 rounded-full
    ${isDarkMode 
      ? 'hover:bg-gray-700 active:bg-gray-600' 
      : 'hover:bg-gray-100 active:bg-gray-200'}
  `;

  // Update the handleItemClick function
  const handleItemClick = (e) => {
    // Prevent navigation if clicking on buttons, selecting text, editing, or interacting with AI generation
    if (
      e.target.closest('button') || 
      window.getSelection().toString() || 
      isEditing || 
      isEditingTranscription ||
      showPromptForm ||
      e.target.closest('[data-generated-content="true"]') ||
      e.target.closest('[data-prompt-form="true"]')
    ) {
      return;
    }

    // Navigate to the voice note detail page
    e.preventDefault();
    e.stopPropagation();
    navigate(`/voice-notes/${id}`);
  };

  // Update the main container styles and restructure the date/time position
  return (
    <motion.div 
      onClick={handleItemClick}
      initial={{ opacity: 1, height: 'auto' }}
      exit={isDeleting ? { opacity: 0, height: 0, marginBottom: 0 } : {}}
      transition={{ duration: 0.2 }}
      className={`group/item relative transition-all duration-200 ease-in-out mb-4 cursor-pointer w-full ${
        isDarkMode ? 'text-gray-200' : 'text-gray-800'
      } ${isDeleting ? 'pointer-events-none' : ''}`}
    >
      {/* Loading Overlay */}
      {isDeleting && <DeleteOverlay isDarkMode={isDarkMode} />}

      {/* Main content container */}
      <div className={`
        relative overflow-hidden w-full
        rounded-xl border transition-all duration-200
        ${isDarkMode 
          ? 'bg-[#151515]/95 border-[#333333] hover:border-[#444444]' 
          : 'bg-white border-gray-200/50 hover:border-gray-300/50'}
        ${isEditingTranscription ? 'p-5' : 'p-4'}
        ${isDeleting ? 'opacity-50' : ''}
      `}>
        {/* Header Row */}
        <div className="flex items-center justify-between mb-2">
          {/* Left side - Date/Time */}
          <div className="flex items-center space-x-1.5">
            <span className={`text-[10px] font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
              {formattedDate}
            </span>
            <span className={`text-[8px] ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>•</span>
            <span className={`text-[10px] ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
              {formattedTime}
            </span>
            <span className={`text-[8px] ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>•</span>
            <span className={`text-[10px] ${isDarkMode ? 'text-gray-500' : 'text-gray-400'}`}>
              {wordCount} words
            </span>
          </div>
          
          {/* Right side - Controls */}
          <div className={`
            flex items-center gap-1
            ${isMobile ? '' : 'opacity-0 group-hover/item:opacity-100 transition-opacity duration-200'}
          `}>
            {/* Generate Content Button */}
            <ActionButton
              onClick={() => setShowPromptForm(prev => !prev)}
              icon={SparklesIcon}
              tooltip={showPromptForm ? "Close prompt" : "Generate content"}
              isDarkMode={isDarkMode}
              isActive={showPromptForm}
            />

            {/* Expand Button */}
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/voice-notes/${id}`);
              }}
              className={`
                p-1 rounded-lg transition-colors duration-200
                ${isDarkMode
                  ? 'text-gray-400 hover:bg-gray-700/50 hover:text-gray-300'
                  : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'}
              `}
              aria-label="Expand voice note"
            >
              <ExpandIcon className="w-3 h-3" />
            </button>

            {/* Menu Button */}
            <div>
              <TranscriptionMenu
                isOpen={isMenuOpen}
                onToggle={handleMenuToggle}
                onDelete={handleDelete}
                onEditTitle={() => setIsEditing(true)}
                onEditTranscription={() => setIsEditingTranscription(true)}
                onRegenerateTitle={handleRegenerateTitle}
                onCopyTranscription={handleCopyTranscription}
                onManageTags={handleTagClick}
                isDarkMode={isDarkMode}
                anchorEl={menuButtonElement}
                isCopied={isCopied}
                tagButtonElement={tagButtonElement}
                setTagButtonElement={setTagButtonElement}
              />
            </div>
          </div>
        </div>

        {/* Title Section */}
        <div className="mb-3">
          {isEditing ? (
            <div className="flex items-center gap-2">
              <input
                ref={inputRef}
                type="text"
                value={editedTitle}
                onChange={handleTitleChange}
                onKeyDown={handleKeyDown}
                className={`
                  flex-grow px-3 py-1.5 text-sm font-medium rounded-lg
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] text-slate-200 focus:bg-[#202020]' 
                    : 'bg-gray-50 text-gray-900 focus:bg-gray-100'}
                  border ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}
                  focus:outline-none focus:ring-1 focus:ring-blue-500
                `}
              />
              <div className="flex items-center gap-1">
                <button onClick={handleTitleSave} className={iconButtonClasses}>
                  <CheckIcon className={`h-4 w-4 ${isDarkMode ? 'text-green-400' : 'text-green-600'}`} />
                </button>
                <button onClick={handleTitleCancel} className={iconButtonClasses}>
                  <XMarkIcon className={`h-4 w-4 ${isDarkMode ? 'text-red-400' : 'text-red-600'}`} />
                </button>
              </div>
            </div>
          ) : (
            <h3 className={`text-sm font-semibold ${isDarkMode ? 'text-gray-100' : 'text-gray-900'}`}>
              {isRegenerating ? (
                <span className="text-green-500">Regenerating title...</span>
              ) : regenerateError ? (
                <span className="text-red-500">{regenerateError}</span>
              ) : (
                editedTitle
              )}
            </h3>
          )}
        </div>

        {/* Transcription Content */}
        <div className={`relative ${isEditingTranscription ? 'mb-4' : 'mb-3'}`}>
          {isEditingTranscription ? (
            <div className="space-y-2">
              <textarea
                ref={setTextareaRef}
                value={editedTranscription}
                onChange={handleTranscriptionChange}
                className={`
                  w-full p-3 rounded-lg text-sm
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'bg-gray-700/50 text-gray-200 border-gray-600' 
                    : 'bg-gray-50 text-gray-800 border-gray-200'}
                  border focus:outline-none focus:ring-1 focus:ring-blue-500
                `}
                style={{ 
                  minHeight: '120px',
                  height: textareaHeight,
                  maxHeight: '400px',
                  lineHeight: '1.5',
                }}
              />
              <div className="flex items-center justify-between px-1">
                <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                  {wordCount} words
                </span>
                <div className="flex items-center gap-2">
                  <button
                    onClick={handleSaveTranscription}
                    disabled={!isTranscriptionEdited}
                    className={`
                      p-1.5 rounded-lg transition-colors duration-200
                      ${isTranscriptionEdited
                        ? isDarkMode
                          ? 'bg-green-500/10 text-green-400 hover:bg-green-500/20'
                          : 'bg-green-50 text-green-600 hover:bg-green-100'
                        : 'opacity-50 cursor-not-allowed'}
                    `}
                  >
                    <CheckIcon className="h-4 w-4" />
                  </button>
                  <button
                    onClick={handleCancelTranscriptionEdit}
                    className={`
                      p-1.5 rounded-lg transition-colors duration-200
                      ${isDarkMode
                        ? 'bg-red-500/10 text-red-400 hover:bg-red-500/20'
                        : 'bg-red-50 text-red-600 hover:bg-red-100'}
                    `}
                  >
                    <XMarkIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="line-clamp-2">
              <div className={`text-xs leading-5 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                {formatTranscription(truncateTranscription(currentTranscription))}
              </div>
            </div>
          )}
        </div>

        {/* Generated Content Section */}
        {showPromptForm && (
          <div className="mt-4 relative" data-prompt-form="true">
            <div className="w-full max-w-full">
              <PromptForm
                isDarkMode={isDarkMode}
                prompt={prompt}
                setPrompt={setPrompt}
                onGenerate={handleGenerateContent}
                onClose={() => {
                  setShowPromptForm(false);
                  setPrompt('');
                }}
                isGenerating={isGenerating}
                isMobile={isMobile}
              />
            </div>
          </div>
        )}

        {generatedContentList.length > 0 && (
          <div className="mt-2" data-generated-content="true">
            <div className="w-full max-w-full space-y-1">
              {generatedContentList.map((item, index) => (
                <GeneratedContentSection
                  key={item._id}
                  item={item}
                  index={index}
                  isDarkMode={isDarkMode}
                  expandedItems={expandedItems}
                  onToggleExpand={toggleItemExpansion}
                  onCopy={handleCopyGeneratedContent}
                  onDelete={handleDeleteContent}
                  copiedContentIndex={copiedContentIndex}
                />
              ))}
            </div>
          </div>
        )}

        {/* Tags Section with Expand Icon */}
        {!isEditingTranscription && (
          <div className={`flex items-center gap-3 ${
            generatedContentList.length > 0 ? 'mt-0' : 'mt-0'
          }`}>
            <div className="flex-1 min-w-0">
              <div className="flex items-center gap-2 overflow-x-auto scrollbar-hide">
                {tags.map((tag) => (
                  <span
                    key={tag}
                    className={`
                      inline-flex items-center px-2 py-1 rounded-lg text-xs
                      ${isDarkMode
                        ? 'bg-gray-700/50 text-gray-300'
                        : 'bg-gray-100 text-gray-600'}
                    `}
                  >
                    {tag}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveTag(tag);
                      }}
                      className={`
                        ml-1.5 p-0.5 rounded-md transition-colors duration-200
                        ${isDarkMode
                          ? 'hover:bg-gray-600 text-gray-400'
                          : 'hover:bg-gray-200 text-gray-500'}
                      `}
                    >
                      <XMarkIcon className="w-3 h-3" />
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>
        )}

        {showDeleteModal && (
          <DeleteConfirmationModal
            isOpen={showDeleteModal}
            onClose={() => {
              setShowDeleteModal(false);
              setContentToDelete(null);
            }}
            onConfirm={handleConfirmDelete}
            isDarkMode={isDarkMode}
            title="Delete Generated Content"
            message="Are you sure you want to delete this generated content? This action cannot be undone."
            isDeleting={isDeleting}
          />
        )}
      </div>

      {/* Tag Menu */}
      {isTagMenuOpen && tagButtonElement && (
        <TagMenu
          ref={tagMenuRef}
          isOpen={isTagMenuOpen}
          onClose={() => {
            setIsTagMenuOpen(false);
            setTagButtonElement(null);
          }}
          anchorEl={tagButtonElement}
          tags={tags}
          allTags={allTags}
          onAddTag={handleAddTagInternal}
          onRemoveTag={handleRemoveTag}
          isDarkMode={isDarkMode}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setContentToDelete(null);
          }}
          onConfirm={handleConfirmDelete}
          isDarkMode={isDarkMode}
          title="Delete Generated Content"
          message="Are you sure you want to delete this generated content? This action cannot be undone."
          isDeleting={isDeleting}
        />
      )}
    </motion.div>
  );
};

export default TranscriptionGridItem;
