import React, { useRef } from 'react';
import { 
  EllipsisVerticalIcon, 
  PencilIcon, 
  TrashIcon,
  ClipboardIcon,
  ArrowPathIcon,
  DocumentTextIcon,
  TagIcon
} from '@heroicons/react/24/outline';
import { ClipboardIcon as ClipboardSolid } from '@heroicons/react/24/solid';
import { createPortal } from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import './TranscriptionMenu.css';

const TranscriptionMenu = ({ 
  isOpen, 
  onToggle, 
  onDelete, 
  onEditTitle, 
  onEditTranscription, 
  onRegenerateTitle, 
  onDownloadAudio,
  onCopyTranscription,
  onManageTags,
  isDarkMode, 
  anchorEl,
  isCopied,
  tagButtonElement,
  setTagButtonElement
}) => {
  const menuRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 640 });

  const menuBgColor = isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white';
  const menuTextColor = isDarkMode ? 'text-slate-300' : 'text-gray-700';
  const menuBorderColor = isDarkMode ? 'border-[#333333]' : 'border-gray-200';
  const menuHoverColor = isDarkMode ? 'hover:bg-[#202020]' : 'hover:bg-gray-100';
  const iconColor = isDarkMode ? 'text-slate-400 hover:text-slate-200' : 'text-gray-500 hover:text-gray-700';

  const getMenuPosition = (anchorRect) => {
    if (!anchorRect) return {};

    const menuWidth = 192; // w-48 = 12rem = 192px
    const spacing = 5;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const menuHeight = 280; // Approximate height of the menu

    // Calculate initial position
    let left = isMobile 
      ? Math.max(10, anchorRect.right - menuWidth)
      : anchorRect.left + window.scrollX;
    
    let top = anchorRect.bottom + window.scrollY + spacing;
    let shouldShowAbove = false;

    // Check right edge
    if (left + menuWidth > viewportWidth - spacing) {
      left = viewportWidth - menuWidth - spacing;
    }

    // Check left edge
    if (left < spacing) {
      left = spacing;
    }

    // Check if there's enough space below
    if (top + menuHeight > viewportHeight - spacing) {
      // Position above the anchor if there's not enough space below
      top = anchorRect.top + window.scrollY - spacing;
      shouldShowAbove = true;
    }

    return {
      top: `${shouldShowAbove ? top - menuHeight : top}px`,
      left: `${left}px`,
      maxHeight: `${menuHeight}px`,
      transformOrigin: shouldShowAbove ? 'bottom' : 'top'
    };
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && 
          !menuRef.current.contains(event.target) && 
          anchorEl && 
          !anchorEl.contains(event.target)) {
        onToggle(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [anchorEl, onToggle]);

  const handleTagMenuClick = (e) => {
    e.stopPropagation();
    const buttonElement = e.currentTarget;
    const buttonRect = buttonElement.getBoundingClientRect();
    const tempAnchor = document.createElement('div');
    tempAnchor.style.position = 'absolute';
    tempAnchor.style.left = `${buttonRect.left}px`;
    tempAnchor.style.top = `${buttonRect.top}px`;
    tempAnchor.style.width = `${buttonRect.width}px`;
    tempAnchor.style.height = `${buttonRect.height}px`;
    document.body.appendChild(tempAnchor);
    
    setTagButtonElement(tempAnchor);
    onManageTags(e);
    onToggle(null);

    setTimeout(() => {
      document.body.removeChild(tempAnchor);
    }, 100);
  };

  return (
    <div className="relative">
      <button 
        onClick={(e) => {
          e.stopPropagation();
          if (isOpen && anchorEl === e.currentTarget) {
            onToggle(null);
          } else {
            onToggle(e.currentTarget);
          }
        }}
        className={`
          p-1.5 rounded-lg transition-colors duration-200
          ${isDarkMode
            ? 'text-slate-400 hover:bg-[#1e1e1e] hover:text-slate-300'
            : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'}
        `}
      >
        <EllipsisVerticalIcon className="h-3.5 w-3.5" />
      </button>
      
      {isOpen && anchorEl && createPortal(
        <div
          ref={menuRef}
          className={`
            fixed z-50 w-48 rounded-md shadow-lg 
            ${menuBgColor} 
            ring-1 ring-black ring-opacity-5 border ${menuBorderColor}
            overflow-y-auto overflow-x-hidden
            transition-all duration-200 ease-out
            animate-menu-enter
          `}
          style={getMenuPosition(anchorEl.getBoundingClientRect())}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="py-1" role="menu">
            <button
              onClick={() => {
                onCopyTranscription();
                onToggle(null);
              }}
              className={`flex items-center px-4 py-2 text-sm ${menuTextColor} ${menuHoverColor} w-full text-left`}
            >
              <ClipboardIcon className="w-4 h-4 mr-2" />
              Copy Transcription
            </button>

            <button
              onClick={() => {
                onEditTranscription();
                onToggle(null);
              }}
              className={`flex items-center px-4 py-2 text-sm ${menuTextColor} ${menuHoverColor} w-full text-left`}
            >
              <PencilIcon className="w-4 h-4 mr-2" />
              Edit Transcription
            </button>

            <button
              onClick={handleTagMenuClick}
              className={`flex items-center px-4 py-2 text-sm ${menuTextColor} ${menuHoverColor} w-full text-left`}
            >
              <TagIcon className="w-4 h-4 mr-2" />
              Manage Tags
            </button>

            <button
              onClick={() => {
                onEditTitle();
                onToggle(null);
              }}
              className={`flex items-center px-4 py-2 text-sm ${menuTextColor} ${menuHoverColor} w-full text-left`}
            >
              <DocumentTextIcon className="w-4 h-4 mr-2" />
              Edit Title
            </button>

            <button
              onClick={() => {
                onRegenerateTitle();
                onToggle(null);
              }}
              className={`flex items-center px-4 py-2 text-sm ${menuTextColor} ${menuHoverColor} w-full text-left`}
            >
              <ArrowPathIcon className="w-4 h-4 mr-2" />
              Regenerate Title
            </button>

            <button
              onClick={() => {
                onDelete();
                onToggle(null);
              }}
              className={`flex items-center px-4 py-2 text-sm ${isDarkMode ? 'text-red-400' : 'text-red-600'} ${menuHoverColor} w-full text-left`}
            >
              <TrashIcon className="w-4 h-4 mr-2" />
              Delete
            </button>
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

TranscriptionMenu.isClickInsideMenu = (element, menuRef) => {
  return menuRef.current && menuRef.current.contains(element);
};

export default TranscriptionMenu;
