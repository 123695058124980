import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import { 
  PencilIcon,
  TrashIcon,
  EllipsisVerticalIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline';

const getOptimalPosition = (triggerRect, menuWidth, menuHeight) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const padding = 8; // Minimum padding from viewport edges

  // Initialize position object
  let position = {
    x: 0,
    y: 0,
    xOrigin: 'left',
    yOrigin: 'top'
  };

  // Calculate horizontal position
  if (triggerRect.right + menuWidth + padding > viewportWidth) {
    // Show menu on the left side of the trigger
    position.x = triggerRect.left - menuWidth;
    position.xOrigin = 'right';
  } else {
    // Show menu on the right side of the trigger
    position.x = triggerRect.right;
    position.xOrigin = 'left';
  }

  // Calculate vertical position
  if (triggerRect.bottom + menuHeight + padding > viewportHeight) {
    // Show menu above the trigger
    position.y = triggerRect.top - menuHeight;
    position.yOrigin = 'bottom';
  } else {
    // Show menu below the trigger
    position.y = triggerRect.top;
    position.yOrigin = 'top';
  }

  // Final boundary checks
  position.x = Math.max(padding, Math.min(position.x, viewportWidth - menuWidth - padding));
  position.y = Math.max(padding, Math.min(position.y, viewportHeight - menuHeight - padding));

  return position;
};

const CanvasItemMenu = ({ 
  item, 
  onRename, 
  onDelete, 
  isDarkMode, 
  children,
  onToggleSelect,
  isSelected,
  isSelectionMode,
  onEnterSelectionMode
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0, xOrigin: 'left', yOrigin: 'top' });
  const menuRef = useRef(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && !event.target.closest('[data-menu-content]') && !event.target.closest('[data-menu-trigger]')) {
        event.stopPropagation();
        setIsMenuOpen(false);
      }
    };

    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape' && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside, true);
    document.addEventListener('scroll', handleScroll, true);
    document.addEventListener('keydown', handleEscape);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('scroll', handleScroll, true);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const handlePositionUpdate = () => {
      if (!isMenuOpen || !triggerRef.current || !menuRef.current) return;

      const triggerRect = triggerRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();
      const newPosition = getOptimalPosition(triggerRect, menuRect.width, menuRect.height);
      setMenuPosition(newPosition);
    };

    const handleScroll = (event) => {
      // Check if the scroll event is from a parent of the trigger
      if (isMenuOpen && triggerRef.current) {
        let element = event.target;
        while (element) {
          if (element.contains(triggerRef.current)) {
            setIsMenuOpen(false);
            break;
          }
          element = element.parentElement;
        }
      }
    };

    window.addEventListener('resize', handlePositionUpdate);
    document.addEventListener('scroll', handleScroll, true);

    // Initial position update
    handlePositionUpdate();

    return () => {
      window.removeEventListener('resize', handlePositionUpdate);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [isMenuOpen]);

  const handleMenuClick = (e) => {
    e.stopPropagation();
    
    if (!triggerRef.current) return;

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const menuWidth = 144; // Width of menu (w-36 = 9rem = 144px)
    const menuHeight = 116; // Approximate height of menu with all items
    
    const position = getOptimalPosition(triggerRect, menuWidth, menuHeight);
    
    setMenuPosition(position);
    setIsMenuOpen(true);
  };

  const handleMenuItemClick = (action) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsMenuOpen(false);
    
    if (action === 'select') {
      if (e.target.closest('[data-menu-content]')) {
        e.nativeEvent.stopImmediatePropagation();
        onEnterSelectionMode();
        onToggleSelect(item);
      }
      return false;
    } else {
      action(item);
    }
  };

  return (
    <div className="relative group" onContextMenu={handleMenuClick}>
      {children}

      {/* Menu or Selection Icon */}
      <div className={`
        absolute right-1 top-1/2 -translate-y-1/2
        inline-flex items-center
        opacity-100
      `}>
        {isSelectionMode ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onToggleSelect(item);
            }}
            className={`
              p-0.5 rounded-md flex items-center justify-center
              transition-all duration-200
              ${isSelected
                ? isDarkMode 
                  ? 'text-blue-400 hover:text-blue-300' 
                  : 'text-blue-500 hover:text-blue-600'
                : isDarkMode
                  ? 'text-slate-400 hover:text-slate-300'
                  : 'text-slate-500 hover:text-slate-600'
              }
            `}
          >
            <CheckCircleIcon className="w-3 h-3" />
          </button>
        ) : (
          <button
            ref={triggerRef}
            data-menu-trigger
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleMenuClick(e);
            }}
            onTouchStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onTouchEnd={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleMenuClick(e);
            }}
            className={`
              p-0.5 rounded-md
              transition-all duration-200
              ${isDarkMode 
                ? 'text-slate-400 hover:text-slate-300' 
                : 'text-slate-500 hover:text-slate-600'
              }
            `}
          >
            <EllipsisVerticalIcon className="w-3 h-3" />
          </button>
        )}
      </div>

      {isMenuOpen && createPortal(
        <div 
          className="fixed inset-0 z-[9999]"
          onClick={() => setIsMenuOpen(false)}
        >
          <AnimatePresence>
            <motion.div
              ref={menuRef}
              data-menu-content
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.1, ease: [0.23, 1, 0.32, 1] }}
              className={`
                fixed w-32 py-0.5
                rounded-lg shadow-sm
                border overflow-hidden
                backdrop-blur-sm
                ${isDarkMode 
                  ? 'bg-[#161616]/95 border-[#2a2a2a]' 
                  : 'bg-white/95 border-slate-100'
                }
              `}
              style={{
                top: menuPosition.y,
                left: menuPosition.x,
                transformOrigin: `${menuPosition.xOrigin} ${menuPosition.yOrigin}`
              }}
              onClick={e => e.stopPropagation()}
            >
              <button
                onClick={handleMenuItemClick(onRename)}
                data-menu-content
                className={`
                  w-full px-2.5 py-1
                  flex items-center space-x-2
                  text-left text-[11px] font-medium
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'text-slate-400 hover:text-slate-300' 
                    : 'text-slate-500 hover:text-slate-600'
                  }
                `}
              >
                <PencilIcon className="w-3 h-3" />
                <span>Rename</span>
              </button>

              <div className={`
                my-0.5 h-px
                ${isDarkMode ? 'bg-[#2a2a2a]' : 'bg-slate-100'}
              `} />

              <button
                onClick={handleMenuItemClick(onDelete)}
                data-menu-content
                className={`
                  w-full px-2.5 py-1
                  flex items-center space-x-2
                  text-left text-[11px] font-medium
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'text-red-400 hover:text-red-300' 
                    : 'text-red-500 hover:text-red-600'
                  }
                `}
              >
                <TrashIcon className="w-3 h-3" />
                <span>Delete</span>
              </button>

              <button
                onClick={handleMenuItemClick('select')}
                data-menu-content
                className={`
                  w-full px-2.5 py-1
                  flex items-center space-x-2
                  text-left text-[11px] font-medium
                  transition-colors duration-200
                  ${isDarkMode 
                    ? 'text-slate-400 hover:text-slate-300' 
                    : 'text-slate-500 hover:text-slate-600'
                  }
                `}
              >
                <CheckCircleIcon className="w-3 h-3" />
                <span>Delete Multiple</span>
              </button>
            </motion.div>
          </AnimatePresence>
        </div>,
        document.body
      )}
    </div>
  );
};

CanvasItemMenu.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onToggleSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isSelectionMode: PropTypes.bool.isRequired,
  onEnterSelectionMode: PropTypes.func.isRequired
};

export default CanvasItemMenu;