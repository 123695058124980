import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { formatFileSize } from './ItemTooltip';
import { useTheme } from '../context/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';

const FileInfoPopup = ({ file, onClose }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${
          isDarkMode ? 'bg-black/60' : 'bg-white/80'
        } backdrop-blur-sm`}
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={`relative w-full max-w-md overflow-hidden rounded-lg shadow-lg ${
            isDarkMode 
              ? 'bg-[#1e1e1e] border border-[#333333]' 
              : 'bg-white border border-gray-200'
          }`}
          onClick={e => e.stopPropagation()}
        >
          {/* Header */}
          <div className={`px-6 py-4 border-b ${
            isDarkMode ? 'border-[#333333]' : 'border-gray-100'
          }`}>
            <div className="flex items-center justify-between">
              <h3 className={`text-lg font-medium leading-6 ${
                isDarkMode ? 'text-slate-200' : 'text-gray-900'
              }`}>
                File Details
              </h3>
              <button
                onClick={onClose}
                className={`rounded-full p-1 transition-colors duration-200 ${
                  isDarkMode 
                    ? 'hover:bg-[#333333] text-slate-400 hover:text-slate-300' 
                    : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
                }`}
              >
                <XMarkIcon className="w-5 h-5" />
              </button>
            </div>
          </div>

          {/* Content */}
          <div className="px-6 py-4 space-y-4">
            <div className="space-y-3">
              <InfoRow 
                label="Name" 
                value={file.name} 
                isDarkMode={isDarkMode}
              />
              <InfoRow 
                label="Size" 
                value={formatFileSize(file.size)} 
                isDarkMode={isDarkMode}
              />
              <InfoRow 
                label="Type" 
                value={file.type || 'Unknown'} 
                isDarkMode={isDarkMode}
              />
              <InfoRow 
                label="Created" 
                value={new Date(file.createdAt).toLocaleString()} 
                isDarkMode={isDarkMode}
              />
              <InfoRow 
                label="Modified" 
                value={new Date(file.updatedAt).toLocaleString()} 
                isDarkMode={isDarkMode}
              />
              <InfoRow 
                label="ID" 
                value={file._id} 
                isDarkMode={isDarkMode}
                monospace
              />
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

const InfoRow = ({ label, value, isDarkMode, monospace = false }) => (
  <div className="flex flex-col space-y-1">
    <dt className={`text-sm font-medium ${
      isDarkMode ? 'text-slate-400' : 'text-gray-500'
    }`}>
      {label}
    </dt>
    <dd className={`text-sm ${
      isDarkMode ? 'text-slate-200' : 'text-gray-900'
    } ${monospace ? 'font-mono' : ''} break-all`}>
      {value}
    </dd>
  </div>
);

export default FileInfoPopup;