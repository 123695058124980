import React from 'react';
import { useSearch } from '../../context/SearchContext';
import { useTheme } from '../../context/ThemeContext';
import * as Icons from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const SearchResults = () => {
  const { results, activeIndex, handleSelect } = useSearch();
  const { isDarkMode } = useTheme();

  // Get icon component
  const getIcon = (iconName) => {
    const Icon = Icons[iconName];
    return Icon ? <Icon className="h-4 w-4" /> : null;
  };

  // Get icon for result type
  const getTypeIcon = (type) => {
    const iconMap = {
      chats: 'ChatBubbleLeftIcon',
      campaigns: 'MegaphoneIcon',
      tasks: 'CheckCircleIcon',
      ideas: 'LightBulbIcon',
      knowledge: 'BookOpenIcon',
      'voice-notes': 'MicrophoneIcon',
      personas: 'UserGroupIcon',
      'target-audiences': 'UsersIcon',
      'ai-agents': 'CpuChipIcon'
    };
    return getIcon(iconMap[type] || 'DocumentIcon');
  };

  // Get display title based on item type
  const getDisplayTitle = (item, type) => {
    switch (type) {
      case 'knowledge':
        return item.title || item.name || 'Untitled Knowledge Base';
      case 'personas':
        return item.name || item.title || 'Untitled Persona';
      case 'target-audiences':
        return item.targetAudienceName || item.name || item.title || 'Untitled Target Audience';
      case 'ai-agents':
        return item.name || item.title || 'Untitled AI Agent';
      default:
        return item.title || 'Untitled';
    }
  };

  // Get display description based on item type
  const getDisplayDescription = (item, type) => {
    switch (type) {
      case 'knowledge':
        return item.description || item.summary || null;
      case 'personas':
        return item.description || item.bio || null;
      case 'target-audiences':
        return item.description || item.summary || null;
      case 'ai-agents':
        return item.description || item.summary || null;
      default:
        return item.description || null;
    }
  };

  let currentIndex = -1;

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="py-2"
    >
      {Object.entries(results).map(([type, items]) => {
        if (!items.length) return null;

        return (
          <motion.div 
            key={type}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-4 last:mb-0"
          >
            <div className={`px-4 py-1.5 text-xs font-medium uppercase tracking-wider flex items-center gap-2 ${
              isDarkMode ? 'text-gray-400' : 'text-gray-500'
            }`}>
              {getTypeIcon(type)}
              <span>{type}</span>
              <span className={isDarkMode ? 'text-gray-500' : 'text-gray-400'}>({items.length})</span>
            </div>
            <div>
              {items.map(item => {
                currentIndex++;
                const isActive = currentIndex === activeIndex;

                return (
                  <motion.button
                    key={item._id}
                    onClick={() => handleSelect({ ...item, type })}
                    data-active={isActive}
                    className={`
                      w-full px-4 py-2.5 flex items-center gap-3 text-left
                      transition-all duration-150 relative
                      ${isActive 
                        ? isDarkMode 
                          ? 'bg-blue-900/30' 
                          : 'bg-blue-50'
                        : isDarkMode
                          ? 'hover:bg-blue-900/20'
                          : 'hover:bg-blue-50/50'
                      }
                      ${isActive 
                        ? 'before:absolute before:left-0 before:top-0 before:bottom-0 before:w-0.5 before:bg-blue-500' 
                        : isDarkMode
                          ? 'hover:before:absolute hover:before:left-0 hover:before:top-0 hover:before:bottom-0 hover:before:w-0.5 hover:before:bg-blue-500/50'
                          : 'hover:before:absolute hover:before:left-0 hover:before:top-0 hover:before:bottom-0 hover:before:w-0.5 hover:before:bg-blue-500/50'
                      }
                    `}
                  >
                    <div className={`
                      flex-shrink-0 p-2 rounded-lg
                      ${isActive 
                        ? isDarkMode
                          ? 'bg-blue-900/50 text-blue-400'
                          : 'bg-blue-100 text-blue-600'
                        : isDarkMode
                          ? 'bg-gray-800 text-gray-400'
                          : 'bg-gray-100 text-gray-500'
                      }
                    `}>
                      {getTypeIcon(type)}
                    </div>
                    <div>
                      <div className={`
                        text-sm font-medium
                        ${isActive 
                          ? isDarkMode
                            ? 'text-blue-400'
                            : 'text-blue-900'
                          : isDarkMode
                            ? 'text-gray-200'
                            : 'text-gray-900'
                        }
                      `}>
                        {getDisplayTitle(item, type)}
                      </div>
                      {getDisplayDescription(item, type) && (
                        <div className={`
                          text-sm truncate mt-0.5
                          ${isActive 
                            ? isDarkMode
                              ? 'text-blue-500'
                              : 'text-blue-600'
                            : isDarkMode
                              ? 'text-gray-400'
                              : 'text-gray-500'
                          }
                        `}>
                          {getDisplayDescription(item, type)}
                        </div>
                      )}
                    </div>
                  </motion.button>
                );
              })}
            </div>
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default SearchResults; 