import React, { useState, useRef, useEffect, forwardRef, useCallback } from 'react';
import { useTheme } from '../context/ThemeContext';
import { 
  PlusIcon, 
  XMarkIcon, 
  ChevronRightIcon, 
  ChevronLeftIcon,
  HomeIcon,
  CheckIcon,
  BookOpenIcon,
  ChartBarIcon,
  FolderIcon,
  StarIcon,
  DocumentTextIcon,
  BeakerIcon,
  BriefcaseIcon,
  CalendarIcon,
  ChatBubbleLeftIcon,
  CodeBracketIcon,
  CogIcon,
  CommandLineIcon,
  CpuChipIcon,
  GlobeAltIcon,
  HeartIcon,
  LightBulbIcon,
  LinkIcon,
  MusicalNoteIcon,
  PhotoIcon,
  PuzzlePieceIcon,
  RocketLaunchIcon,
  ShoppingBagIcon,
  VideoCameraIcon,
  WrenchIcon
} from '@heroicons/react/24/outline';
import '../components/scrollbar.css';
import { createCanvas, updateCanvasTitle, getCanvas, invalidateCanvasCache } from '../api';
import { CANVAS_MARGIN } from '../constants'; // Create this constants file if it doesn't exist
import PropTypes from 'prop-types';
import AnimatedLoader from './common/AnimatedLoader';
import { useRecentActivities } from '../context/RecentActivitiesContext';
import { EnterFullscreenIcon, ExitFullscreenIcon } from './icons/FullscreenIcons';
import { CanvasGridIcon } from './icons/CanvasGridIcon';

// Add these base style constants at the top of the file
const baseButtonStyles = `
  flex items-center justify-center 
  w-7 h-7 mx-1 my-0.5
  rounded-md transition-all duration-200
  hover:shadow-sm
  transform hover:scale-[1.02]
  border border-transparent
`;

const baseTabStyles = `
  group flex items-center h-7 px-2 my-0.5 ml-1
  rounded-md transition-all duration-200
  border border-transparent
  flex-shrink-0
  whitespace-nowrap
  min-w-0
`;

// Add icons mapping
const icons = {
  default: CanvasGridIcon,
  books: BookOpenIcon,
  chart: ChartBarIcon,
  folder: FolderIcon,
  star: StarIcon,
  note: DocumentTextIcon,
  beaker: BeakerIcon,
  briefcase: BriefcaseIcon,
  calendar: CalendarIcon,
  chat: ChatBubbleLeftIcon,
  code: CodeBracketIcon,
  settings: CogIcon,
  terminal: CommandLineIcon,
  chip: CpuChipIcon,
  globe: GlobeAltIcon,
  heart: HeartIcon,
  bulb: LightBulbIcon,
  link: LinkIcon,
  music: MusicalNoteIcon,
  photo: PhotoIcon,
  puzzle: PuzzlePieceIcon,
  rocket: RocketLaunchIcon,
  shopping: ShoppingBagIcon,
  video: VideoCameraIcon,
  tools: WrenchIcon
};

// First, add this new loading dots component specifically for the tab count
const TabCountLoadingDots = ({ isDarkMode }) => (
  <div className="flex items-center gap-0.5 px-1">
    {[...Array(3)].map((_, i) => (
      <div
        key={i}
        className={`
          w-1 h-1 rounded-full
          animate-pulse
          ${isDarkMode 
            ? 'bg-slate-600' 
            : 'bg-slate-400'}
        `}
        style={{
          animationDelay: `${i * 150}ms`
        }}
      />
    ))}
  </div>
);

// Update the Tab component to handle loading state
const Tab = ({ tab, isActive, onSelect, onRemove, onTitleChange, isDarkMode }) => {
  const [isEditing, setIsEditing] = useState(tab.isNew === true);
  const [localTitle, setLocalTitle] = useState(tab.title);
  const [isHovered, setIsHovered] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const inputRef = useRef(null);
  const tabRef = useRef(null);

  // Get the correct icon component
  const IconComponent = icons[tab.icon] || icons.default;

  // Focus the input when the tab is new
  useEffect(() => {
    if (tab.isNew && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [tab.isNew]);

  // Add click outside listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isEditing && 
          tabRef.current && 
          !tabRef.current.contains(event.target)) {
        handleTitleSubmit();
      }
    };

    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]); // Only re-run if isEditing changes

  // Update local title when tab title changes
  useEffect(() => {
    setLocalTitle(tab.title);
  }, [tab.title]);

  const handleTitleSubmit = async () => {
    try {
      const newTitle = localTitle.trim() === '' ? 'Untitled Collection' : localTitle.trim();
      
      setIsSaving(true); // Show saving status
      
      // Call the parent's onTitleChange handler
      await onTitleChange(tab.id, newTitle);
      
      setIsEditing(false);
      setLocalTitle(newTitle); // Update local state
    } catch (error) {
      console.error('Error updating canvas title:', error);
      // Revert to the original title on error
      setLocalTitle(tab.title);
      setIsEditing(false);
    } finally {
      // Hide saving status after a brief delay
      setTimeout(() => {
        setIsSaving(false);
      }, 800);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleTitleSubmit();
    } else if (e.key === 'Escape') {
      setLocalTitle(tab.title);
      setIsEditing(false);
    }
  };

  const renderPostCount = () => {
    if (tab.isLoading) {
      return <TabCountLoadingDots isDarkMode={isDarkMode} />;
    }

    return (
      <span className={`
        text-[10px] px-1.5 py-0.5 rounded-full
        flex-shrink-0
        ${isActive
          ? isDarkMode
            ? 'bg-[#252525] text-slate-400'
            : 'bg-slate-200/70 text-slate-600'
          : isDarkMode
          ? 'bg-[#202020] text-slate-500'
          : 'bg-slate-100 text-slate-500'
        }
      `}>
        {tab.postsCount || 0}
      </span>
    );
  };

  return (
    <div
      ref={tabRef}
      key={tab.id}
      className={`
        ${baseTabStyles}
        ${isActive
          ? isDarkMode
            ? 'bg-[#1e1e1e] text-slate-200'
            : 'bg-slate-100/70 text-slate-900'
          : isDarkMode
          ? 'text-slate-400 hover:bg-[#1a1a1a]'
          : 'text-slate-600 hover:bg-slate-100/50'
        }
        cursor-pointer transform hover:scale-[1.01] transition-transform
        max-w-[200px]
        flex-shrink-0
      `}
      style={{
        minWidth: 0,
      }}
      onClick={(e) => {
        if (!isEditing) {
          onSelect(tab.id);
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isEditing ? (
        <div className="flex items-center w-full">
          <input
            ref={inputRef}
            type="text"
            value={localTitle}
            onChange={(e) => setLocalTitle(e.target.value)}
            onBlur={handleTitleSubmit}
            onKeyDown={handleKeyPress}
            className={`
              flex-1 bg-transparent border-none outline-none text-xs mr-1
              focus:ring-0 focus:outline-none
              ${isDarkMode ? 'placeholder-slate-500' : 'placeholder-slate-400'}
            `}
            onClick={(e) => e.stopPropagation()}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleTitleSubmit();
            }}
            className="w-3.5 h-3.5 flex-shrink-0 text-green-500 hover:text-green-400"
          >
            <CheckIcon className="w-3.5 h-3.5" />
          </button>
        </div>
      ) : (
        <div className="flex items-center w-full gap-1.5">
          <IconComponent 
            className={`
              w-3.5 h-3.5
              ${isActive 
                ? isDarkMode 
                  ? 'text-slate-400' 
                  : 'text-slate-500'
                : isDarkMode
                  ? 'text-slate-500'
                  : 'text-slate-400'
              }
              opacity-50 group-hover:opacity-100
              transition-opacity duration-200
            `} 
          />
          <div className="flex-grow flex items-center gap-1.5 min-w-0">
            <span
              className="truncate text-xs font-medium"
              onDoubleClick={() => setIsEditing(true)}
            >
              {tab.title}
            </span>
            {renderPostCount()}
          </div>
          {isSaving && (
            <AnimatedLoader 
              size={12} 
              className="ml-1" 
              color={isDarkMode ? '#94a3b8' : '#64748b'} 
            />
          )}
          <button
            className={`
              w-3.5 h-3.5 flex-shrink-0 
              transition-all duration-200
              ${isActive || isHovered ? 'opacity-100' : 'opacity-0'}
              text-slate-400 hover:text-red-500
              dark:text-slate-500 dark:hover:text-red-400
              rounded-sm
            `}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove(tab.id);
            }}
          >
            <XMarkIcon className="w-3.5 h-3.5" />
          </button>
        </div>
      )}
    </div>
  );
};

Tab.propTypes = {
  tab: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    postsCount: PropTypes.number,
    isLoading: PropTypes.bool,
    icon: PropTypes.string,
    isNew: PropTypes.bool
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired
};

// Add display name for the forwarded ref component
Tab.displayName = 'Tab';

const CanvasTopBar = forwardRef(({ 
  tabs, 
  activeTabId, 
  onTabChange, 
  onAddTab, 
  onRemoveTab, 
  onTabTitleChange,
  onHomeClick,
  isHomeView,
  setShowWelcome,
  setActiveTabId,
  setTabsState,
  addNotification,
  isChatCollapsed,    // Add these
  onToggleChat,       // two props
  setTabs,
  setPosts,           // Add these
  setCanvasTitle,     // two props
  tabsState,
  calculateLayout,
  isMobile,
}, ref) => {
  const { isDarkMode } = useTheme();
  const tabsContainerRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);
  const { refreshActivities } = useRecentActivities();
  const isMountedRef = useRef(true);

  // Add state to track which tabs are loading
  const [loadingTabs, setLoadingTabs] = useState(new Set());

  // Add cache check function
  const isCanvasCached = useCallback((canvasId) => {
    return tabsState[canvasId]?.lastFetched && 
           Date.now() - tabsState[canvasId].lastFetched < 5 * 60 * 1000; // 5 minutes cache
  }, [tabsState]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const checkForScrollButtons = useCallback(() => {
    if (tabsContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsContainerRef.current;
      setShowLeftScroll(scrollLeft > 0);
      setShowRightScroll(scrollLeft + clientWidth < scrollWidth - 1); // -1 for rounding errors
    }
  }, []);

  useEffect(() => {
    checkForScrollButtons();
    
    // Add resize observer
    const resizeObserver = new ResizeObserver(() => {
      checkForScrollButtons();
    });

    if (tabsContainerRef.current) {
      resizeObserver.observe(tabsContainerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [tabs, checkForScrollButtons]);

  const handleScroll = () => {
    checkForScrollButtons();
  };

  const handleWheel = useCallback((e) => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollLeft += e.deltaY;
      checkForScrollButtons();
    }
  }, [checkForScrollButtons]);

  const scrollTabs = (direction) => {
    if (tabsContainerRef.current) {
      const container = tabsContainerRef.current;
      const scrollAmount = 200; // Adjust this value as needed
      
      const targetScroll = direction === 'left'
        ? container.scrollLeft - scrollAmount
        : container.scrollLeft + scrollAmount;

      container.scrollTo({
        left: targetScroll,
        behavior: 'smooth'
      });
    }
  };

  const handleMouseEvent = (e) => {
    e.stopPropagation();
  };

  const handleAddTab = async () => {
    console.log('Initiating canvas creation from TopBar');
    try {
      const newCanvas = await createCanvas('Untitled Collection');
      console.log('Canvas created successfully:', newCanvas);
      
      const newTab = {
        id: newCanvas._id,
        title: newCanvas.title,
        icon: newCanvas.icon || 'default'  // Add icon to new tab
      };
      
      // Update tabs state and localStorage
      setTabs(prevTabs => {
        const updatedTabs = [...prevTabs, newTab];
        localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
        return updatedTabs;
      });
      
      setActiveTabId(newCanvas._id);
      setShowWelcome(false);
      setPosts([]);
      
      setTabsState(prev => ({
        ...prev,
        [newCanvas._id]: {
          posts: [],
          title: newCanvas.title,
          icon: newCanvas.icon || 'default',  // Add icon to tabsState
          canvasPosition: { x: CANVAS_MARGIN, y: CANVAS_MARGIN },
          zoom: 1
        }
      }));
      
      localStorage.setItem('lastActiveTabId', newCanvas._id);
      localStorage.setItem('canvasLastView', 'canvas');
      
      // Refresh activities after canvas creation
      await refreshActivities();
      
      console.log('New canvas tab added and activated:', newTab);
    } catch (error) {
      console.error('Error in handleAddTab:', error);
      addNotification('Failed to create new canvas', 'error');
    }
  };

  // Update the getTabPostCount function to handle loading state
  const getTabPostCount = useCallback((tabId) => {
    const isLoading = loadingTabs.has(tabId);
    const posts = tabsState[tabId]?.posts;
    return {
      count: posts?.length || 0,
      isLoading
    };
  }, [tabsState, loadingTabs]);

  // Update the handleTabChange function to handle loading state
  const handleTabChange = useCallback((tabId) => {
    if (tabId === activeTabId) return;

    setShowWelcome(false);
    setActiveTabId(tabId);
    
    // Set loading state for this tab
    setLoadingTabs(prev => new Set([...prev, tabId]));
    
    const loadCanvasData = async () => {
      try {
        const canvas = await getCanvas(tabId);
        if (canvas.posts?.length > 0) {
          const postsWithLayout = calculateLayout(canvas.posts);
          setPosts(postsWithLayout);
        } else {
          setPosts([]);
        }
        
        setTabsState(prev => ({
          ...prev,
          [tabId]: {
            posts: canvas.posts || [],
            title: canvas.title,
            icon: canvas.icon || 'default',  // Add icon to tabsState
            lastFetched: Date.now()
          }
        }));

        // Update tabs with icon
        setTabs(prevTabs => 
          prevTabs.map(tab => 
            tab.id === tabId 
              ? { ...tab, icon: canvas.icon || 'default' }
              : tab
          )
        );
      } catch (error) {
        console.error('Error loading canvas data:', error);
        addNotification('Failed to load canvas data', 'error');
      } finally {
        // Remove loading state for this tab
        setLoadingTabs(prev => {
          const next = new Set(prev);
          next.delete(tabId);
          return next;
        });
      }
    };

    loadCanvasData();
  }, [activeTabId, calculateLayout, addNotification, setActiveTabId, setPosts]);

  // Update the home button click handler
  const handleHomeClick = (e) => {
    e.stopPropagation();
    setActiveTabId(null); // Clear active tab
    setShowWelcome(true); // Show welcome screen
    localStorage.setItem('canvasLastView', 'welcome');
    localStorage.removeItem('lastActiveTabId');
    onHomeClick(); // Call the parent handler
  };

  // Update the handleRemoveTab function to handle tab switching
  const handleRemoveTab = (tabId) => {
    // Find the index of the tab being removed
    const tabIndex = tabs.findIndex(tab => tab.id === tabId);
    
    // Create updated tabs array
    const updatedTabs = tabs.filter(tab => tab.id !== tabId);
    
    // Update tabs state and localStorage
    setTabs(updatedTabs);
    localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
    
    setTabsState(prev => {
      const { [tabId]: _, ...rest } = prev;
      return rest;
    });

    // If there are no remaining tabs, show welcome screen
    if (updatedTabs.length === 0) {
      setActiveTabId(null);
      setPosts([]);
      setCanvasTitle('');
      setShowWelcome(true);
      localStorage.setItem('canvasLastView', 'welcome');
      localStorage.removeItem('lastActiveTabId');
      return;
    }

    // If we're removing the active tab, switch to another tab
    if (activeTabId === tabId) {
      let newActiveTabId;
      
      // If there's a tab after the current one, switch to it
      if (tabIndex < updatedTabs.length) {
        newActiveTabId = updatedTabs[tabIndex].id;
      } 
      // Otherwise, switch to the last tab
      else {
        newActiveTabId = updatedTabs[updatedTabs.length - 1].id;
      }
      
      // Update active tab
      setActiveTabId(newActiveTabId);
      handleTabChange(newActiveTabId);
      localStorage.setItem('lastActiveTabId', newActiveTabId);
    }
  };

  // Update the useEffect that listens for canvas deletion events
  useEffect(() => {
    const handleCanvasDeleted = async (event) => {
      const deletedCanvasId = event.detail.canvasId;
      console.log('Canvas deleted event received:', deletedCanvasId);
      
      // Find the index of the deleted tab
      const tabIndex = tabs.findIndex(tab => tab.id === deletedCanvasId);
      
      // Remove the tab
      const updatedTabs = tabs.filter(tab => tab.id !== deletedCanvasId);
      
      // Update tabs state and localStorage
      setTabs(updatedTabs);
      localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));
      
      // Clear tab state
      setTabsState(prev => {
        const { [deletedCanvasId]: _, ...rest } = prev;
        return rest;
      });
      
      // If there are no remaining tabs, show welcome screen
      if (updatedTabs.length === 0) {
        setShowWelcome(true);
        setActiveTabId(null);
        setPosts([]);
        setCanvasTitle('');
        localStorage.setItem('canvasLastView', 'welcome');
        localStorage.removeItem('lastActiveTabId');
      } 
      // If the deleted canvas was active, switch to another tab
      else if (activeTabId === deletedCanvasId) {
        // Try to select the next tab, or the last tab if we're at the end
        const newActiveTabId = tabIndex < updatedTabs.length 
          ? updatedTabs[tabIndex].id 
          : updatedTabs[updatedTabs.length - 1].id;
        
        setActiveTabId(newActiveTabId);
        handleTabChange(newActiveTabId);
        localStorage.setItem('lastActiveTabId', newActiveTabId);
        localStorage.setItem('canvasLastView', 'canvas');
      }
      
      // Refresh activities after canvas deletion
      await refreshActivities();
    };

    window.addEventListener('canvasDeleted', handleCanvasDeleted);
    
    return () => {
      window.removeEventListener('canvasDeleted', handleCanvasDeleted);
    };
  }, [activeTabId, tabs, setShowWelcome, setActiveTabId, handleTabChange, refreshActivities, setTabsState, setPosts, setCanvasTitle]);

  const handleTabTitleChange = async (tabId, newTitle) => {
    // Validate title
    if (!newTitle?.trim()) {
      addNotification('Title cannot be empty', 'error');
      return;
    }

    const trimmedTitle = newTitle.trim();
    const oldTitle = tabs.find(tab => tab.id === tabId)?.title || '';
    
    // Store old states for potential rollback
    const oldTabs = [...tabs];
    const oldTabsState = { ...tabsState };
    const oldLocalStorageTabs = JSON.parse(localStorage.getItem('canvasTabs') || '[]');
    
    try {
      // Update local state optimistically
      const updatedTabs = tabs.map(tab => 
        tab.id === tabId ? { ...tab, title: trimmedTitle } : tab
      );
      
      // Batch state updates
      setTabs(updatedTabs);
      setTabsState(prev => ({
        ...prev,
        [tabId]: {
          ...prev[tabId],
          title: trimmedTitle
        }
      }));
      
      if (tabId === activeTabId) {
        setCanvasTitle(trimmedTitle);
      }
      
      localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));

      // Make API call
      await updateCanvasTitle(tabId, trimmedTitle);
      
      // Show success notification
      addNotification('Title updated', 'success');
      
      // Refresh activities in background
      refreshActivities().catch(console.error);
      
    } catch (error) {
      console.error('Error updating canvas title:', error);
      
      // Full state rollback
      setTabs(oldTabs);
      setTabsState(oldTabsState);
      if (tabId === activeTabId) {
        setCanvasTitle(oldTitle);
      }
      localStorage.setItem('canvasTabs', JSON.stringify(oldLocalStorageTabs));
      
      // Show specific error message
      if (error.message === 'Canvas not found') {
        addNotification('Canvas not found. Please refresh the page.', 'error');
      } else if (error.message === 'Unauthorized to update canvas title') {
        addNotification('You do not have permission to update this canvas.', 'error');
      } else {
        addNotification('Failed to update title: ' + error.message, 'error');
      }
      
      throw error;
    }
  };

  const loadTabsMetadata = async () => {
    try {
      // Create a map of existing tab data to avoid unnecessary updates
      const existingTabsMap = tabs.reduce((acc, tab) => {
        acc[tab.id] = tab;
        return acc;
      }, {});

      // Only load data for tabs that aren't cached
      const tabsToLoad = tabs.filter(tab => !isCanvasCached(tab.id));
      
      if (tabsToLoad.length === 0) {
        console.log('All tabs are cached, skipping metadata load');
        return;
      }

      console.log('Loading metadata for tabs:', tabsToLoad.map(t => t.id));
      const loadedTabs = await Promise.all(
        tabsToLoad.map(async (tab) => {
          try {
            const canvas = await getCanvas(tab.id);
            return {
              id: tab.id,
              title: canvas.title || existingTabsMap[tab.id].title,
              icon: canvas.icon || existingTabsMap[tab.id].icon || 'default'  // Add icon
            };
          } catch (error) {
            console.error(`Error loading canvas ${tab.id}:`, error);
            return existingTabsMap[tab.id]; // Keep existing tab data on error
          }
        })
      );

      if (!isMountedRef.current) return;

      // Merge loaded tabs with existing tabs that were cached
      const updatedTabs = tabs.map(tab => {
        const loadedTab = loadedTabs.find(t => t.id === tab.id);
        return loadedTab || tab;
      });

      // Only update if there are actual changes
      const hasChanges = JSON.stringify(updatedTabs) !== JSON.stringify(tabs);
      if (hasChanges) {
        setTabs(updatedTabs);
        localStorage.setItem('canvasTabs', JSON.stringify(updatedTabs));

        // Update tabsState only for newly loaded tabs
        const newTabsState = {};
        loadedTabs.forEach(tab => {
          if (!tabsState[tab.id]) {
            newTabsState[tab.id] = {
              title: tab.title,
              icon: tab.icon || 'default',  // Add icon to tabsState
              posts: [], // Posts will be loaded when tab is selected
              lastFetched: Date.now()
            };
          }
        });

        if (Object.keys(newTabsState).length > 0) {
          setTabsState(prev => ({...prev, ...newTabsState}));
        }
      }
    } catch (error) {
      console.error('Error loading tabs metadata:', error);
    }
  };

  // Add effect to load tabs metadata
  useEffect(() => {
    if (tabs.length === 0) return;
    loadTabsMetadata();
  }, [tabs]); // Only run when tabs change

  // Add this effect to load initial tabs from localStorage
  useEffect(() => {
    const loadInitialTabs = async () => {
      try {
        const savedTabs = localStorage.getItem('canvasTabs');
        if (savedTabs) {
          const parsedTabs = JSON.parse(savedTabs);
          // Load full canvas data for each tab to ensure we have icons
          const loadedTabs = await Promise.all(
            parsedTabs.map(async (tab) => {
              try {
                const canvas = await getCanvas(tab.id);
                return {
                  ...tab,
                  icon: canvas.icon || tab.icon || 'default'
                };
              } catch (error) {
                console.error(`Error loading canvas ${tab.id}:`, error);
                return tab;
              }
            })
          );
          setTabs(loadedTabs);
          localStorage.setItem('canvasTabs', JSON.stringify(loadedTabs));
        }
      } catch (error) {
        console.error('Error loading initial tabs:', error);
      }
    };

    loadInitialTabs();
  }, []); // Only run on mount

  return (
    <div
      ref={ref}
      className={`
        h-10 flex-shrink-0 flex items-center rounded-t-lg
        ${isDarkMode 
          ? 'bg-[#121212]/95 border-[#333333]' 
          : 'bg-white/90 border-slate-200/50'}
        border-b border-opacity-50 relative z-[100]
        w-full
      `}
      style={{
        minWidth: 0,
        maxWidth: '100vw',
        overflowX: 'hidden'
      }}
    >
      {/* Add Mobile Back Button */}
      {isMobile && (
        <button
          onClick={onToggleChat}
          className={`
            flex items-center px-1.5 py-0.5 ml-0.5
            rounded-md transition-all duration-200
            ${isDarkMode 
              ? 'text-slate-300 hover:bg-[#2A2A2A]' 
              : 'text-slate-600 hover:bg-gray-100'}
            flex-shrink-0
          `}
          aria-label="Back to Chat"
        >
          <ChevronLeftIcon className="w-4 h-4" />
          <span className="text-xs">Chat</span>
        </button>
      )}

      {/* Home Button - Update its container to handle mobile spacing */}
      <div className={`flex-shrink-0 ${isMobile ? 'pl-0.5' : 'pl-1'}`}>
        <button
          onClick={handleHomeClick}
          className={`
            ${baseButtonStyles}
            ${isHomeView
              ? isDarkMode
                ? 'text-slate-200 bg-[#1e1e1e] shadow-sm ring-1 ring-white/5'
                : 'text-slate-900 bg-slate-100 shadow-sm ring-1 ring-black/5'
              : isDarkMode
              ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]'
              : 'text-slate-600 hover:text-slate-900 hover:bg-slate-100'
            }
            ${isMobile ? 'mx-0.5' : 'mx-0.5'}
            transform-gpu transition-all duration-200
            flex-shrink-0
          `}
        >
          <HomeIcon className={`
            w-4 h-4
            ${isHomeView
              ? 'transform-gpu scale-[1.02]'
              : ''
            }
          `} />
        </button>
      </div>

      {/* Tabs Container with Scroll Buttons */}
      <div className={`
        flex-1 flex items-stretch relative min-w-0 
        ${isMobile ? 'mx-0.5' : 'mx-1'}
      `}>
        {/* Left Scroll Button */}
        {showLeftScroll && (
          <div 
            className={`
              absolute left-0 top-1/2 -translate-y-1/2 z-[102]
              pointer-events-none
              ${isDarkMode ? 'bg-gradient-to-r from-[#121212]/95' : 'bg-gradient-to-r from-white/90'}
              pl-0.5 pr-2 py-1
            `}
          >
            <button
              onClick={() => scrollTabs('left')}
              className={`
                flex items-center justify-center 
                w-5 h-5
                rounded-md transition-all duration-200
                pointer-events-auto
                ${isDarkMode 
                  ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]' 
                  : 'text-slate-500 hover:text-slate-700 hover:bg-slate-100/50'}
              `}
            >
              <ChevronLeftIcon className="w-4 h-4" />
            </button>
          </div>
        )}

        {/* Right Scroll Button */}
        {showRightScroll && (
          <div 
            className={`
              absolute right-0 top-1/2 -translate-y-1/2 z-[102]
              pointer-events-none
              ${isDarkMode ? 'bg-gradient-to-l from-[#121212]/95' : 'bg-gradient-to-l from-white/90'}
              pr-0.5 pl-2 py-1
            `}
          >
            <button
              onClick={() => scrollTabs('right')}
              className={`
                flex items-center justify-center 
                w-5 h-5
                rounded-md transition-all duration-200
                pointer-events-auto
                ${isDarkMode 
                  ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]' 
                  : 'text-slate-500 hover:text-slate-700 hover:bg-slate-100/50'}
              `}
            >
              <ChevronRightIcon className="w-4 h-4" />
            </button>
          </div>
        )}

        {/* Tabs Scrollable Container */}
        <div
          ref={tabsContainerRef}
          className={`
            flex-1 overflow-x-auto scrollbar-hide
            min-w-0 relative z-[101]
          `}
          onScroll={handleScroll}
          onWheel={handleWheel}
          style={{
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          <div className="flex h-full items-center min-w-0">
            {tabs.map(tab => {
              const { count, isLoading } = getTabPostCount(tab.id);
              return (
                <Tab
                  key={tab.id}
                  tab={{
                    ...tab,
                    postsCount: count,
                    isLoading
                  }}
                  isActive={activeTabId === tab.id}
                  onSelect={onTabChange}
                  onRemove={handleRemoveTab}
                  onTitleChange={handleTabTitleChange}
                  isDarkMode={isDarkMode}
                />
              );
            })}
            {/* Add New Tab Button */}
            <button
              onClick={handleAddTab}
              className={`
                ${baseButtonStyles}
                ${isDarkMode
                  ? 'text-slate-400 hover:bg-[#1e1e1e] hover:border-[#333333]'
                  : 'text-slate-500 hover:bg-slate-100/50 hover:border-slate-200'}
                hover:shadow-md
                flex-shrink-0
              `}
              title="Add new canvas"
            >
              <PlusIcon className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>

      {/* Chat Toggle Button - with separator and distinct styling */}
      {!isMobile && (
        <div className={`
          flex items-center
          relative z-[102]
          pl-2 pr-2
          flex-shrink-0
          before:content-['']
          before:absolute
          before:left-0
          before:top-2
          before:bottom-2
          before:w-[1px]
          ${isDarkMode 
            ? 'before:bg-[#333333]' 
            : 'before:bg-slate-200'
          }
        `}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onToggleChat();
            }}
            className={`
              flex items-center justify-center 
              w-7 h-7
              rounded-md 
              transition-all duration-200
              border border-transparent
              ${isDarkMode 
                ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]' 
                : 'text-slate-600 hover:text-slate-900 hover:bg-slate-100'
              }
              ${isChatCollapsed 
                ? isDarkMode
                  ? 'bg-[#1e1e1e]' 
                  : 'bg-slate-50'
                : ''
              }
              hover:shadow-sm
              transform hover:scale-[1.02]
              relative
              isolate
            `}
            title={isChatCollapsed ? "Show Chat Interface" : "Hide Chat Interface"}
          >
            <div className="w-4 h-4 flex items-center justify-center">
              {isChatCollapsed ? <ExitFullscreenIcon /> : <EnterFullscreenIcon />}
            </div>
          </button>
        </div>
      )}
    </div>
  );
});

// Add PropTypes validation
CanvasTopBar.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTabId: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
  onAddTab: PropTypes.func.isRequired,
  onRemoveTab: PropTypes.func.isRequired,
  onTabTitleChange: PropTypes.func.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  isHomeView: PropTypes.bool.isRequired,
  setShowWelcome: PropTypes.func.isRequired,
  setActiveTabId: PropTypes.func.isRequired,
  setTabsState: PropTypes.func.isRequired,
  addNotification: PropTypes.func.isRequired,
  isChatCollapsed: PropTypes.bool.isRequired,
  onToggleChat: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired,
  setPosts: PropTypes.func.isRequired,
  setCanvasTitle: PropTypes.func.isRequired,
  tabsState: PropTypes.object,
  calculateLayout: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
};

// Add default props
CanvasTopBar.defaultProps = {
  tabsState: {},
  calculateLayout: (posts) => posts, // Default implementation that just returns the posts unchanged
};

export default CanvasTopBar;
