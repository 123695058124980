import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeftIcon, ArrowRightIcon, CpuChipIcon, CheckIcon, XMarkIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '../../context/ThemeContext';
import api from '../../api';
import Notification from '../Notification';

const PrivacyPopup = ({ onDismiss, onNeverShowAgain }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className={`relative w-full max-w-md transform overflow-hidden rounded-xl shadow-xl transition-all
                      ${isDarkMode ? 'bg-slate-900' : 'bg-white'}`}>
        {/* Header */}
        <div className={`px-6 py-4 border-b ${isDarkMode ? 'border-slate-800' : 'border-gray-200'}`}>
          <div className="flex items-center justify-between">
            <h3 className={`text-lg font-semibold ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
              AI Agent Privacy Notice
            </h3>
            <button
              onClick={onDismiss}
              className={`rounded-full p-1 transition-colors duration-200
                        ${isDarkMode 
                          ? 'hover:bg-slate-800 text-slate-400 hover:text-slate-300' 
                          : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'}`}
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="px-6 py-4">
          <div className={`space-y-4 ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
            <p className="text-sm leading-relaxed">
              Important information about your AI agent:
            </p>
            <ul className="text-sm space-y-2 list-disc list-inside">
              <li>All training data and instructions are used solely for AI agent development</li>
              <li>Your agent's behavior is determined by the provided instructions</li>
              <li>Training examples help shape your agent's responses</li>
              <li>You maintain full control over your agent's configuration</li>
            </ul>
          </div>
        </div>

        {/* Footer */}
        <div className={`px-6 py-4 border-t ${isDarkMode ? 'border-slate-800' : 'border-gray-200'}
                        flex justify-end space-x-3`}>
          <button 
            onClick={onDismiss}
            className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200
                      ${isDarkMode 
                        ? 'bg-slate-800 text-slate-200 hover:bg-slate-700' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
          >
            Got it
          </button>
          <button 
            onClick={onNeverShowAgain}
            className="px-4 py-2 rounded-md text-sm font-medium text-white
                     transition-colors duration-200 bg-indigo-600 hover:bg-indigo-700"
          >
            Don't show again
          </button>
        </div>
      </div>
    </div>
  );
};

const CustomDropdown = ({ options, value, onChange, placeholder, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className={`flex items-center justify-between w-full px-3 py-2 text-sm border rounded-md cursor-pointer
                    ${isDarkMode
          ? 'bg-slate-700 border-slate-600 text-slate-200'
          : 'bg-white border-gray-300 text-gray-900'}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{value || placeholder}</span>
        <svg className={`w-5 h-5 ml-2 -mr-1 transition-transform duration-200 transform ${isOpen ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </div>
      {isOpen && (
        <div className={`absolute z-10 w-full mt-1 overflow-auto text-sm rounded-md shadow-lg max-h-60
                        ${isDarkMode ? 'bg-slate-800' : 'bg-white'}`}>
          {options.map((option) => (
            <div
              key={option}
              className={`px-3 py-2 cursor-pointer
                          ${isDarkMode
                  ? 'hover:bg-slate-700 text-slate-200'
                  : 'hover:bg-gray-100 text-gray-900'}`}
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const ActionButtons = ({ onBack, onSave, isSaving, agentId, isFormValid, isDarkMode, position }) => (
  <div className={`flex items-center justify-between w-full ${position === 'top' ? 'mb-6' : 'mt-6'}`}>
    <button
      onClick={onBack}
      className={`
        flex items-center px-3 py-2 rounded-md 
        transition-all duration-200 text-sm
        ${isDarkMode 
          ? 'bg-[#1a1a1a] hover:bg-[#202020] text-slate-200 border border-[#333333]' 
          : 'bg-gray-100 hover:bg-gray-200 text-gray-700'
        }
      `}
    >
      <ArrowLeftIcon className="w-4 h-4 mr-2" />
      Back to AI Agents
    </button>

    <button
      onClick={onSave}
      disabled={!isFormValid || isSaving}
      className={`
        flex items-center px-4 py-2 rounded-md text-sm font-medium
        transition-all duration-200 shadow-sm
        ${!isFormValid || isSaving
          ? isDarkMode
            ? 'bg-[#1a1a1a] text-slate-500 border border-[#333333] cursor-not-allowed'
            : 'bg-gray-100 text-gray-400 cursor-not-allowed'
          : isDarkMode
            ? 'bg-blue-500 hover:bg-blue-600 text-white'
            : 'bg-blue-600 hover:bg-blue-700 text-white'
        }
      `}
    >
      {isSaving ? (
        <span className="inline-flex items-center gap-2">
          <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
          </svg>
          <span>Saving...</span>
        </span>
      ) : (
        <span className="inline-flex items-center gap-2">
          <CheckIcon className="h-4 w-4" />
          <span>{agentId ? 'Update AI Agent' : 'Save AI Agent'}</span>
        </span>
      )}
    </button>
  </div>
);

const TagInput = ({ tags, onAddTag, onRemoveTag, isDarkMode }) => {
  const [input, setInput] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      const newTag = input.trim();
      if (newTag && !tags.includes(newTag)) {
        onAddTag(newTag);
      }
      setInput('');
    } else if (e.key === 'Backspace' && !input && tags.length > 0) {
      onRemoveTag(tags[tags.length - 1]);
    }
  };

  return (
    <div className={`flex flex-wrap gap-2 p-2 rounded-md border
                    ${isDarkMode 
                      ? 'bg-slate-950 border-slate-800' 
                      : 'bg-white border-gray-200'}`}>
      {tags.map((tag, index) => (
        <span
          key={index}
          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm
                     ${isDarkMode 
                       ? 'bg-slate-800 text-slate-200' 
                       : 'bg-gray-100 text-gray-700'}`}
        >
          {tag}
          <button
            type="button"
            onClick={() => onRemoveTag(tag)}
            className={`ml-1 hover:text-red-500 transition-colors duration-200`}
          >
            ×
          </button>
        </span>
      ))}
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
        className={`outline-none flex-grow min-w-[120px] text-sm
                   ${isDarkMode 
                     ? 'bg-slate-950 text-slate-200' 
                     : 'bg-white text-gray-900'}`}
        placeholder={tags.length === 0 ? "Type and press Enter to add tags" : ""}
      />
    </div>
  );
};

const AIAgentCreationWizard = ({ onSave }) => {
  const navigate = useNavigate();
  const { agentId } = useParams();
  const { isDarkMode } = useTheme();
  const [step, setStep] = useState(0);
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(true);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [agentData, setAgentData] = useState({
    name: '',
    description: '',
    categories: [],
    systemInstructions: '',
    trainingExamples: [{ input: '', output: '' }]
  });

  const [isSaving, setIsSaving] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [errors, setErrors] = useState({});
  const fieldRefs = {
    name: useRef(null),
    systemInstructions: useRef(null),
  };

  const sections = [
    {
      title: 'Basic Information',
      description: 'Define the core identity and purpose of your AI agent.',
      fields: [['name', 'categories'], 'description']
    },
    {
      title: 'Agent Behavior',
      description: 'Configure how your AI agent thinks and responds.',
      fields: ['systemInstructions']
    },
    {
      title: 'Training Examples',
      description: 'Provide examples to guide your AI agent\'s responses.',
      fields: ['trainingExamples']
    }
  ];

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const validateForm = () => {
      const newErrors = {};
      if (!agentData.name.trim()) newErrors.name = "Agent name is required";
      if (!agentData.systemInstructions.trim()) newErrors.systemInstructions = "System instructions are required";
      
      // Only set errors if user has attempted to submit
      if (hasAttemptedSubmit) {
        setErrors(newErrors);
      }
      return Object.keys(newErrors).length === 0;
    };

    setIsFormValid(validateForm());
  }, [agentData, hasAttemptedSubmit]);

  useEffect(() => {
    if (agentId) {
      fetchAgent();
    } else {
      const hasSeenPrivacyNotice = localStorage.getItem('hasSeenPrivacyNotice');
      setShowPrivacyPopup(!hasSeenPrivacyNotice);
    }
  }, [agentId]);

  const fetchAgent = async () => {
    try {
      const response = await api.get(`/api/ai-agents/${agentId}`);
      setAgentData(response.data);
    } catch (error) {
      console.error('Error fetching AI agent:', error);
      addNotification('Error', 'Failed to fetch AI agent data', 'error');
    }
  };

  const handleDismissPrivacyPopup = () => {
    setShowPrivacyPopup(false);
  };

  const handleNeverShowAgain = () => {
    setShowPrivacyPopup(false);
    localStorage.setItem('hasSeenPrivacyNotice', 'true');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAgentData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleTrainingExampleChange = (index, field, value) => {
    const updatedExamples = [...agentData.trainingExamples];
    updatedExamples[index][field] = value;
    setAgentData(prevData => ({
      ...prevData,
      trainingExamples: updatedExamples
    }));
  };

  const addTrainingExample = () => {
    setAgentData(prevData => ({
      ...prevData,
      trainingExamples: [...prevData.trainingExamples, { input: '', output: '' }]
    }));
  };

  const removeTrainingExample = (index) => {
    setAgentData(prevData => ({
      ...prevData,
      trainingExamples: prevData.trainingExamples.filter((_, i) => i !== index)
    }));
  };

  const handleSave = async () => {
    setHasAttemptedSubmit(true);
    
    if (!isFormValid) {
      // Find the first field with an error
      const validateForm = () => {
        const newErrors = {};
        if (!agentData.name.trim()) newErrors.name = "Agent name is required";
        if (!agentData.systemInstructions.trim()) newErrors.systemInstructions = "System instructions are required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
      
      if (!validateForm()) {
        const firstErrorField = Object.keys(errors)[0];
        // Set the step to the one containing the error
        const errorStep = sections.findIndex(step => step.fields.includes(firstErrorField));
        if (errorStep !== -1) {
          setStep(errorStep);
          // Focus on the field with the error
          setTimeout(() => {
            if (fieldRefs[firstErrorField] && fieldRefs[firstErrorField].current) {
              fieldRefs[firstErrorField].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
              fieldRefs[firstErrorField].current.focus();
            }
          }, 100);
        }
        addNotification('Validation Error', 'Please fill all required fields', 'error');
        return;
      }
    }

    try {
      setIsSaving(true);
      addNotification('Saving AI Agent', 'Please wait while we save your AI agent.', 'info');
      let response;
      if (agentId) {
        response = await api.put(`/api/ai-agents/${agentId}`, agentData);
      } else {
        response = await api.post('/api/ai-agents', agentData);
      }

      if (onSave) {
        await onSave(response.data);
      }
      addNotification('AI Agent Saved', 'Your AI agent has been successfully saved.', 'success');
      navigate('/agents', { replace: true });
    } catch (error) {
      console.error('Error saving AI agent:', error.response?.data || error.message);
      addNotification('Save Failed', 'Failed to save AI agent. Please try again.', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleNext = () => {
    if (step < sections.length - 1) {
      setStep(step + 1);
    } else {
      handleSave();
    }
  };

  const handleBack = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate('/agents');
    }
  };

  const addNotification = (title, message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, title, message, type }]);
  };

  const removeNotification = (id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  const formatFieldTitle = (field) => {
    const titleMap = {
      name: 'Agent Name',
      description: 'Description',
      categories: 'Categories',
      systemInstructions: 'System Instructions',
      trainingExamples: 'Training Examples'
    };

    return titleMap[field] || field
      .split(/(?=[A-Z])/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderField = (field) => {
    switch (field) {
      case 'name':
      case 'description':
        return renderTextInputField(field, formatFieldTitle(field));
      case 'categories':
        return renderCategoriesField();
      case 'systemInstructions':
        return renderTextAreaField(field, formatFieldTitle(field));
      case 'trainingExamples':
        return renderTrainingExamplesField();
      default:
        return null;
    }
  };

  const renderTextInputField = (field, fieldTitle) => (
    <div key={field} className="mb-4 space-y-2">
      <label className={`block text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
        {fieldTitle}
        {field === 'name' && <span className="text-red-500 ml-1">*</span>}
      </label>
      <input
        type="text"
        name={field}
        value={agentData[field]}
        onChange={handleInputChange}
        ref={fieldRefs[field]}
        className={`block w-full px-3 py-2 text-sm rounded-md border transition-all duration-200
                  ${isDarkMode 
                    ? 'bg-[#121212] border-[#333333] text-slate-200 hover:border-2' 
                    : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                  ${errors[field] ? 'border-red-500' : ''}
                  focus:outline-none focus:border-2 focus:border-indigo-500
                  placeholder:text-slate-400/50 dark:placeholder:text-slate-500/50`}
        placeholder={`Enter ${fieldTitle.toLowerCase()}`}
      />
      {errors[field] && <p className="mt-1 text-sm text-red-500">{errors[field]}</p>}
    </div>
  );

  const renderCategoriesField = () => (
    <div className="mb-4">
      <label className={`block text-sm font-medium mb-2 ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
        Categories
      </label>
      <TagInput
        tags={agentData.categories}
        onAddTag={(tag) => setAgentData(prev => ({
          ...prev,
          categories: [...prev.categories, tag]
        }))}
        onRemoveTag={(tagToRemove) => setAgentData(prev => ({
          ...prev,
          categories: prev.categories.filter(tag => tag !== tagToRemove)
        }))}
        isDarkMode={isDarkMode}
      />
    </div>
  );

  const renderTextAreaField = (field, fieldTitle) => (
    <div key={field} className="mb-4 space-y-2">
      <label className={`block text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
        {fieldTitle}
        {field === 'systemInstructions' && <span className="text-red-500 ml-1">*</span>}
      </label>
      <textarea
        name={field}
        value={agentData[field]}
        onChange={handleInputChange}
        ref={fieldRefs[field]}
        rows={6}
        className={`block w-full px-3 py-2 text-sm rounded-md border transition-all duration-200
                  resize-y min-h-[100px] max-h-[400px]
                  ${isDarkMode 
                    ? 'bg-[#121212] border-[#333333] text-slate-200 hover:border-2' 
                    : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                  ${errors[field] ? 'border-red-500' : ''}
                  focus:outline-none focus:border-2 focus:border-indigo-500
                  placeholder:text-slate-400/50 dark:placeholder:text-slate-500/50`}
        placeholder={`Enter ${fieldTitle.toLowerCase()}`}
      />
      {errors[field] && <p className="mt-1 text-sm text-red-500">{errors[field]}</p>}
    </div>
  );

  const renderTrainingExamplesField = () => (
    <div className="mb-4 space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <label className={`block text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-700'}`}>
            Training Examples
          </label>
          <p className={`mt-1 text-xs ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
            Help your AI agent learn by providing example conversations
          </p>
        </div>
        <button
          onClick={() => setAgentData(prev => ({
            ...prev,
            trainingExamples: [...prev.trainingExamples, { input: '', output: '' }]
          }))}
          className={`inline-flex items-center px-2.5 py-1.5 text-xs font-medium rounded-md
                    transition-colors duration-200 shadow-sm
                    ${isDarkMode 
                      ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#202020] border border-[#333333]' 
                      : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'}`}
        >
          <PlusIcon className="h-3.5 w-3.5 mr-1" />
          Add Example
        </button>
      </div>
      
      <div className="space-y-6">
        {agentData.trainingExamples.map((example, index) => (
          <div 
            key={index} 
            className={`relative p-5 rounded-lg border transition-all duration-200
                      ${isDarkMode 
                        ? 'bg-[#151515] border-[#333333] hover:border-[#444444]' 
                        : 'bg-white/50 border-gray-200 hover:border-gray-300'}`}
          >
            <div className={`absolute -left-2 -top-2 h-6 w-6 rounded-full flex items-center justify-center text-xs font-medium
                          ${isDarkMode 
                            ? 'bg-[#1a1a1a] text-slate-200 border border-[#333333]' 
                            : 'bg-white text-gray-700 border border-gray-200 shadow-sm'}`}>
              {index + 1}
            </div>
            
            {agentData.trainingExamples.length > 1 && (
              <button
                onClick={() => setAgentData(prev => ({
                  ...prev,
                  trainingExamples: prev.trainingExamples.filter((_, i) => i !== index)
                }))}
                className={`absolute -right-2 -top-2 h-6 w-6 rounded-full flex items-center justify-center
                          transition-colors duration-200
                          ${isDarkMode 
                            ? 'bg-[#1a1a1a] text-slate-400 hover:text-red-400 border border-[#333333]' 
                            : 'bg-white text-gray-400 hover:text-red-600 border border-gray-200 shadow-sm'}`}
              >
                <XMarkIcon className="h-3.5 w-3.5" />
              </button>
            )}
            
            <div className="space-y-4">
              {/* User Input */}
              <div>
                <label className={`block text-sm font-medium mb-2
                                ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                  User Message
                </label>
                <textarea
                  value={example.input}
                  onChange={(e) => handleTrainingExampleChange(index, 'input', e.target.value)}
                  rows={3}
                  className={`w-full px-3 py-2 text-sm rounded-md border transition-all duration-200
                            resize-y min-h-[80px] max-h-[200px]
                            ${isDarkMode 
                              ? 'bg-[#121212] border-[#333333] text-slate-200 hover:border-2' 
                              : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                            focus:outline-none focus:border-2 focus:border-indigo-500`}
                  placeholder="What would a user typically say..."
                />
              </div>

              {/* AI Response */}
              <div>
                <label className={`block text-sm font-medium mb-2
                                ${isDarkMode ? 'text-slate-300' : 'text-gray-600'}`}>
                  AI Response
                </label>
                <textarea
                  value={example.output}
                  onChange={(e) => handleTrainingExampleChange(index, 'output', e.target.value)}
                  rows={3}
                  className={`w-full px-3 py-2 text-sm rounded-md border transition-all duration-200
                            resize-y min-h-[80px] max-h-[200px]
                            ${isDarkMode 
                              ? 'bg-[#121212] border-[#333333] text-slate-200 hover:border-2' 
                              : 'bg-white border-gray-200 text-gray-900 hover:border-2'}
                            focus:outline-none focus:border-2 focus:border-indigo-500`}
                  placeholder="How should your AI agent respond..."
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const renderSection = (section, index) => (
    <div key={index} className={`mb-8 rounded-lg overflow-hidden
                              border shadow-sm
                              ${isDarkMode 
                                ? 'bg-[#151515] border-[#333333]' 
                                : 'bg-white border-gray-200'}`}>
      {/* Section Header */}
      <div className={`px-6 py-5 border-b 
                      ${isDarkMode 
                        ? 'bg-[#1a1a1a] border-[#333333]' 
                        : 'bg-gradient-to-r from-gray-50 to-gray-50/80 border-gray-200'}`}>
        <div className="flex items-center gap-4">
          <div className={`flex items-center justify-center w-10 h-10 rounded-lg text-base font-semibold
                         ${isDarkMode 
                           ? 'bg-[#121212] text-slate-100 ring-1 ring-[#333333]' 
                           : 'bg-white text-gray-900 ring-1 ring-gray-200 shadow-sm'}`}>
            {index + 1}
          </div>
          <div className="space-y-1">
            <h2 className={`text-lg font-semibold leading-none tracking-tight
                          ${isDarkMode ? 'text-slate-100' : 'text-gray-900'}`}>
              {section.title}
            </h2>
            <p className={`text-sm
                          ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
              {section.description}
            </p>
          </div>
        </div>
      </div>

      {/* Section Content */}
      <div className={`p-6 
                      ${isDarkMode 
                        ? 'bg-[#151515] border-[#333333]' 
                        : 'bg-white border-gray-200'}`}>
        <div className="grid grid-cols-1 gap-6">
          {section.fields.map((field, fieldIndex) => {
            if (Array.isArray(field)) {
              // If field is an array, render items in a row
              return (
                <div key={`row-${fieldIndex}`} className="grid grid-cols-2 gap-6">
                  {field.map(subField => (
                    <div key={`field-${subField}`}>
                      {renderField(subField)}
                    </div>
                  ))}
                </div>
              );
            }
            return (
              <div key={`field-${field}`}>
                {renderField(field)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`flex flex-col h-full relative overflow-hidden
                    ${isDarkMode ? 'bg-[#121212]' : 'bg-gray-50'}
                    transition-colors duration-300`}>
      {/* Top Bar */}
      <div className={`${isDarkMode 
        ? 'bg-[#121212]/95 border-[#333333]' 
        : 'bg-white bg-opacity-90 border-gray-200'} 
        !h-10 min-h-10 flex-shrink-0 flex items-center px-4 py-2 w-full border-b`}>
        <div className="flex items-center">
          <CpuChipIcon className={`h-5 w-5 ${isDarkMode ? 'text-slate-400' : 'text-gray-600'} mr-2`} />
          <h2 className={`text-sm font-medium ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
            {agentId ? 'Edit AI Agent' : 'New AI Agent'}
          </h2>
        </div>
      </div>

      {notifications.map((notification, index) => (
        <Notification
          key={notification.id}
          {...notification}
          onClose={removeNotification}
          currentIndex={index + 1}
          totalCount={notifications.length}
        />
      ))}

      {showPrivacyPopup && (
        <PrivacyPopup 
          onDismiss={handleDismissPrivacyPopup} 
          onNeverShowAgain={handleNeverShowAgain}
        />
      )}

      {/* Main Content */}
      <div className={`flex-grow overflow-y-auto custom-scrollbar
                      ${isDarkMode ? 'bg-[#121212]' : 'bg-gray-50'}`}>
        <div className="max-w-3xl mx-auto px-4 py-8">
          {/* Top Action Buttons */}
          <ActionButtons
            onBack={() => navigate('/agents')}
            onSave={handleSave}
            isSaving={isSaving}
            agentId={agentId}
            isFormValid={isFormValid}
            isDarkMode={isDarkMode}
            position="top"
          />

          {/* Sections */}
          {sections.map(renderSection)}

          {/* Bottom Action Buttons */}
          <ActionButtons
            onBack={() => navigate('/agents')}
            onSave={handleSave}
            isSaving={isSaving}
            agentId={agentId}
            isFormValid={isFormValid}
            isDarkMode={isDarkMode}
            position="bottom"
          />
        </div>
      </div>
    </div>
  );
};

export default AIAgentCreationWizard;
