import React, { useState, useEffect, useRef, Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import { 
  TrashIcon, 
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentDuplicateIcon,
  LightBulbIcon,
  ClockIcon,
  GlobeAltIcon,
  CheckIcon,
  BookmarkIcon,
  ChartBarIcon,
  ClipboardIcon,
  ClipboardCheckIcon,
  EllipsisVerticalIcon,
  CalendarIcon
} from '@heroicons/react/24/outline';
import { Menu, Transition, Popover, Portal } from '@headlessui/react';
import RedditDataModal from './RedditDataModal';
import { ExpandIcon, CollapseIcon } from '../icons/ExpandCollapseIcon';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { saveIdea } from '../../api';
import toast from 'react-hot-toast';
import ViralOpportunitiesFormat from './ViralOpportunitiesFormat';
import PropTypes from 'prop-types';

// Update the RedditIcon component at the top of the file
const RedditIcon = ({ isDarkMode, className = "w-4 h-4", showBackground = true }) => (
  <svg 
    className={`${className} ${!showBackground && isDarkMode ? 'text-orange-300' : ''}`}
    viewBox="0 0 256 256"
  >
    {showBackground && (
      <circle 
        cx="128" 
        cy="128" 
        r="128" 
        className={isDarkMode ? 'fill-orange-500/20' : 'fill-orange-500'} 
      />
    )}
    <path 
      className={showBackground 
        ? isDarkMode 
          ? 'fill-orange-300' // Lighter color for dark mode with background
          : 'fill-white'      // White for light mode with background
        : 'fill-current'      // Use current color when no background
      }
      d="M213.15 129.22c0-10.376-8.391-18.617-18.617-18.617a18.74 18.74 0 0 0-12.97 5.189c-12.818-9.157-30.368-15.107-49.9-15.87l8.544-39.981l27.773 5.95c.307 7.02 6.104 12.667 13.278 12.667c7.324 0 13.275-5.95 13.275-13.278c0-7.324-5.95-13.275-13.275-13.275c-5.188 0-9.768 3.052-11.904 7.478l-30.976-6.562c-.916-.154-1.832 0-2.443.458c-.763.458-1.22 1.22-1.371 2.136l-9.464 44.558c-19.837.612-37.692 6.562-50.662 15.872a18.74 18.74 0 0 0-12.971-5.188c-10.377 0-18.617 8.391-18.617 18.617c0 7.629 4.577 14.037 10.988 16.939a33.6 33.6 0 0 0-.458 5.646c0 28.686 33.42 52.036 74.621 52.036c41.202 0 74.622-23.196 74.622-52.036a35 35 0 0 0-.458-5.646c6.408-2.902 10.985-9.464 10.985-17.093M85.272 142.495c0-7.324 5.95-13.275 13.278-13.275c7.324 0 13.275 5.95 13.275 13.275s-5.95 13.278-13.275 13.278c-7.327.15-13.278-5.953-13.278-13.278m74.317 35.251c-9.156 9.157-26.553 9.768-31.588 9.768c-5.188 0-22.584-.765-31.59-9.768c-1.371-1.373-1.371-3.51 0-4.883c1.374-1.371 3.51-1.371 4.884 0c5.8 5.8 18.008 7.782 26.706 7.782s21.058-1.983 26.704-7.782c1.374-1.371 3.51-1.371 4.884 0c1.22 1.373 1.22 3.51 0 4.883m-2.443-21.822c-7.325 0-13.275-5.95-13.275-13.275s5.95-13.275 13.275-13.275c7.327 0 13.277 5.95 13.277 13.275c0 7.17-5.95 13.275-13.277 13.275"
    />
  </svg>
);

// Update the highlightSearchTerm function with yellow highlight and black text
const highlightSearchTerm = (text, searchTerm) => {
  if (!searchTerm || !text) return text;
  
  const regex = new RegExp(`(${searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
  return text.replace(regex, '<mark class="bg-yellow-200 dark:bg-yellow-200 text-black dark:text-black px-0.5 rounded-sm">$1</mark>');
};

// Move getPopupTitle outside of ExpandedPopup component
const getPopupTitle = (idea, getMainIdea) => {
  // First check if source is explicitly set
  if (idea.source === 'reddit') {
    // Remove duplicates and format subreddit names
    const uniqueSubreddits = [...new Set(idea.subreddits?.map(sub => sub.name) || [])];
    return `Reddit Analysis: r/${uniqueSubreddits.join(', r/')}`;
  }

  // Check content for Reddit-related keywords
  const content = idea.generatedIdeas || '';
  const hasRedditKeywords = /\b(reddit|subreddit|r\/)\b/i.test(content);
  
  if (hasRedditKeywords) {
    // Extract subreddit names, remove duplicates, and format
    const subredditMatches = content.match(/r\/[a-zA-Z0-9_]+/g) || [];
    const uniqueSubreddits = [...new Set(subredditMatches.map(sub => sub.replace('r/', '')))];
    return uniqueSubreddits.length > 0 
      ? `Reddit Analysis: r/${uniqueSubreddits.join(', r/')}`
      : 'Reddit Analysis';
  }

  // For non-Reddit content
  const mainIdea = idea.topic || getMainIdea(idea.generatedIdeas);
  return `Internet Analysis: ${mainIdea.replace(/^(Internet Analysis:|Reddit Analysis:)\s*/i, '')}`;
};

// Update the ExpandedPopup component
const ExpandedPopup = ({ idea, isDarkMode, onClose, children, getMainIdea }) => {
  const contentRef = useRef(null);

  const handleBackdropClick = (e) => {
    if (contentRef.current && !contentRef.current.contains(e.target)) {
      onClose();
    }
  };

  return createPortal(
    <AnimatePresence>
      <div 
        className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-0"
        onClick={handleBackdropClick}
      >
        {/* Backdrop with whiter background */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
          className={`fixed inset-0 backdrop-blur-sm
            ${isDarkMode 
              ? 'bg-white/30' // Lighter backdrop for dark mode
              : 'bg-white/60' // Whiter backdrop for light mode
            }`}
        />
        
        {/* Content container */}
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ 
            duration: 0.2,
            ease: "easeOut"
          }}
          className="relative w-full h-[90vh] sm:h-auto sm:max-w-4xl mx-auto z-10"
        >
          <div 
            ref={contentRef}
            className={`w-full h-full sm:h-auto sm:max-h-[85vh] rounded-none sm:rounded-xl
              transform-gpu shadow-[0_0_30px_rgba(0,0,0,0.2)]
              flex flex-col
              ${isDarkMode 
                ? 'bg-[#151515]/95 ring-1 ring-[#333333]' 
                : 'bg-white/95 ring-1 ring-gray-200'
              }`}
          >
            {/* Header */}
            <div className={`
              px-4 sm:px-6 py-3 flex items-center justify-between border-b
              flex-shrink-0
              ${isDarkMode ? 'border-[#333333]' : 'border-gray-200/50'}
            `}>
              <div className="flex items-center gap-2 min-w-0 flex-1 pr-4">
                {isRedditContent(idea) ? (
                  <RedditIcon 
                    isDarkMode={isDarkMode} 
                    className="w-4 h-4 flex-shrink-0 text-orange-500" 
                  />
                ) : (
                  <GlobeAltIcon 
                    className="w-4 h-4 flex-shrink-0 text-indigo-500" 
                  />
                )}
                <div className="overflow-x-auto no-scrollbar">
                  <h2 className={`text-sm font-medium whitespace-nowrap ${
                    isDarkMode ? 'text-gray-100' : 'text-gray-900'
                  }`}>
                    {getPopupTitle(idea, getMainIdea)}
                  </h2>
                </div>
              </div>
              <button
                onClick={onClose}
                className={`p-1.5 rounded-md transition-colors flex-shrink-0
                  ${isDarkMode 
                    ? 'text-gray-400 hover:text-gray-200 hover:bg-gray-700/50' 
                    : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100/50'
                  }`}
              >
                <CollapseIcon className="w-4 h-4" />
              </button>
            </div>

            {/* Scrollable content area */}
            <div className="flex-1 overflow-y-auto custom-scrollbar">
              <div className="p-4 sm:p-6">
                {children}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>,
    document.body
  );
};

// Add this helper function near the top of the file, after component imports
const isRedditContent = (idea) => {
  // Check explicit source
  if (idea.source === 'reddit') return true;
  
  // Check content for Reddit-related keywords
  const content = idea.generatedIdeas || '';
  const hasRedditKeywords = /\b(reddit|subreddit|r\/)\b/i.test(content);
  
  // Check topic for Reddit mentions
  const topic = idea.topic || '';
  const hasRedditTopic = /\b(reddit|subreddit|r\/)\b/i.test(topic);
  
  return hasRedditKeywords || hasRedditTopic;
};

// Update DateDisplay component
const DateDisplay = ({ timestamp, isDarkMode }) => {
  const formattedDate = new Date(timestamp).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  return (
    <Popover className="relative">
      <Popover.Button className={`p-1.5 rounded-md transition-colors ${
        isDarkMode 
          ? 'hover:bg-[#202020] text-slate-400 hover:text-slate-200' 
          : 'hover:bg-gray-100/50 text-gray-500 hover:text-gray-600'
      }`}>
        <CalendarIcon className="w-4 h-4" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel className={`
          absolute z-50 p-2 text-xs rounded-md shadow-lg
          left-1/2 -translate-x-1/2 mt-1
          ${isDarkMode 
            ? 'bg-[#1a1a1a] text-slate-200 border border-[#333333]' 
            : 'bg-white text-gray-900 border border-gray-200'
          }
        `}>
          {formattedDate}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

// Update PopoverMenu component
const PopoverMenu = ({ 
  isDarkMode, 
  onCopy, 
  onDelete, 
  onSave, 
  onViewRedditData, 
  hasRedditData, 
  showCopySuccess, 
  savingState,
  onExpand
}) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button
        className={`p-1.5 rounded-md transition-colors ${
          isDarkMode 
            ? 'hover:bg-[#202020] text-slate-400 hover:text-slate-200' 
            : 'hover:bg-gray-100/50 text-gray-500 hover:text-gray-600'
        }`}
      >
        <EllipsisVerticalIcon className="w-5 h-5" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items
          className={`
            absolute right-0 mt-1 w-48 rounded-lg shadow-lg py-1
            z-[9999] origin-top-right
            ${isDarkMode 
              ? 'bg-[#1a1a1a] border border-[#333333] shadow-[0_4px_12px_rgba(0,0,0,0.2)]' 
              : 'bg-white border border-gray-200 shadow-[0_4px_12px_rgba(0,0,0,0.1)]'
            }
          `}
        >
          {hasRedditData && (
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={onViewRedditData}
                  className={`w-full text-left px-3 py-2 text-xs flex items-center gap-2
                    ${active
                      ? isDarkMode
                        ? 'bg-[#252525] text-slate-200'
                        : 'bg-gray-50 text-gray-900'
                      : isDarkMode
                        ? 'text-slate-300'
                        : 'text-gray-700'
                    }
                  `}
                >
                  <RedditIcon isDarkMode={isDarkMode} className="w-4 h-4" />
                  View Reddit Data
                </button>
              )}
            </Menu.Item>
          )}

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onSave}
                className={`w-full text-left px-3 py-2 text-xs flex items-center gap-2
                  ${active
                    ? isDarkMode
                      ? 'bg-[#252525] text-slate-200'
                      : 'bg-gray-50 text-gray-900'
                    : isDarkMode
                      ? 'text-slate-300'
                      : 'text-gray-700'
                  }
                `}
              >
                <BookmarkIcon 
                  className={`w-4 h-4 ${
                    savingState === 'saving' 
                      ? 'animate-pulse opacity-50' 
                      : savingState === 'saved'
                        ? 'fill-current' 
                        : ''
                  }`} 
                />
                Save to Ideas
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onCopy}
                className={`w-full text-left px-3 py-2 text-xs flex items-center gap-2
                  ${active
                    ? isDarkMode
                      ? 'bg-[#252525] text-slate-200'
                      : 'bg-gray-50 text-gray-900'
                    : isDarkMode
                      ? 'text-slate-300'
                      : 'text-gray-700'
                  }
                `}
              >
                {showCopySuccess ? (
                  <CheckIcon className="w-4 h-4 text-green-500" />
                ) : (
                  <DocumentDuplicateIcon className="w-4 h-4" />
                )}
                {showCopySuccess ? 'Copied!' : 'Copy'}
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onExpand}
                className={`w-full text-left px-3 py-2 text-xs flex items-center gap-2
                  ${active
                    ? isDarkMode
                      ? 'bg-[#252525] text-slate-200'
                      : 'bg-gray-50 text-gray-900'
                    : isDarkMode
                      ? 'text-slate-300'
                      : 'text-gray-700'
                  }
                `}
              >
                <ExpandIcon className="w-4 h-4" />
                Open in Popup
              </button>
            )}
          </Menu.Item>

          <Menu.Item>
            {({ active }) => (
              <button
                onClick={onDelete}
                className={`w-full text-left px-3 py-2 text-xs flex items-center gap-2
                  ${active
                    ? isDarkMode
                      ? 'bg-[#252525] text-red-300'
                      : 'bg-gray-50 text-red-600'
                    : isDarkMode
                      ? 'text-red-400'
                      : 'text-red-500'
                  }
                `}
              >
                <TrashIcon className="w-4 h-4" />
                Delete
              </button>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

// Add helper function for popover positioning
const getPopoverPosition = () => {
  const button = document.activeElement;
  if (!button) return { top: 0, left: 0 };

  const rect = button.getBoundingClientRect();
  const spaceBelow = window.innerHeight - rect.bottom;
  const spaceRight = window.innerWidth - rect.left;

  // Position the menu below the button by default
  let top = rect.bottom + 4;
  let left = rect.left;

  // If there's not enough space below, position it above
  if (spaceBelow < 200 && rect.top > spaceBelow) {
    top = rect.top - 4;
  }

  // If there's not enough space to the right, align it to the right edge
  if (spaceRight < 200) {
    left = Math.max(4, window.innerWidth - 200);
  }

  return { top, left };
};

// Update the YouIdeaItem component's main structure
const YouIdeaItem = ({ 
  idea, 
  isDarkMode, 
  onToggleFavorite, 
  onDelete, 
  markdownStyles,
  isHighlighted,
  searchTerm = '',
  isSidebarCollapsed = false,
  onViewRedditData
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [savingState, setSavingState] = useState('idle'); // 'idle' | 'saving' | 'saved'
  const [expandedSections, setExpandedSections] = useState({
    metrics: true,
    themes: false,
    opportunities: false,
    discussions: false,
    insights: false,
    quickWins: false
  });

  // Move the useEffect here at the component level
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        if (isPopupOpen) {
          handlePopupClose();
        }
      }
    };
    
    document.addEventListener('keydown', handleEscape);
    
    // Cleanup function
    return () => {
      document.removeEventListener('keydown', handleEscape);
      if (isPopupOpen) {
        document.body.style.overflow = 'auto';
      }
    };
  }, [isPopupOpen]); // Add isPopupOpen to dependencies

  // Handlers for popup
  const handlePopupOpen = () => {
    setIsPopupOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
    document.body.style.overflow = 'auto';
  };

  // Helper function to check if idea has valid Reddit data
  const hasRedditData = () => {
    if (!idea) return false;
    
    // Check if it's explicitly a Reddit source with data
    if (idea.source === 'reddit' && Array.isArray(idea.redditData) && idea.redditData.length > 0) {
      return true;
    }
    
    // Check if it has subreddits data
    if (Array.isArray(idea.subreddits) && idea.subreddits.length > 0) {
      return true;
    }
    
    // Check content for Reddit post references
    const content = idea.generatedIdeas || idea.analysis || '';
    const hasRedditUrls = /reddit\.com\/r\/[a-zA-Z0-9_]+\/comments/i.test(content);
    
    return hasRedditUrls;
  };

  // Handler for View Data button click
  const handleViewRedditData = () => {
    if (onViewRedditData && idea.redditData && Array.isArray(idea.redditData)) {
      onViewRedditData(idea.redditData);
    }
  };

  // Extract and clean main idea from content
  const getMainIdea = (content) => {
    if (idea.source === 'reddit') {
      return `Reddit Analysis: ${idea.topic}`;
    }
    
    const mainIdeaMatch = content.match(/Main Idea:\s*([^\n]+)/);
    if (!mainIdeaMatch) return '';
    
    return mainIdeaMatch[1]
      .replace(/[*_~`]+/g, '')
      .trim();
  };

  // Add this function to check if the idea matches the search term
  const matchesSearch = () => {
    if (!searchTerm) return true;
    
    const searchFields = [
      idea.generatedIdeas,
      getMainIdea(idea.generatedIdeas),
      idea.topic,
      // Search through categories if they exist
      ...(idea.categories?.flatMap(cat => [
        cat.name,
        ...cat.ideas.flatMap(idea => [idea.title, idea.explanation])
      ]) || [])
    ];

    return searchFields.some(field => 
      field?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Only render if matches search or no search term
  if (!matchesSearch()) return null;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(idea.generatedIdeas);
      setShowCopySuccess(true);
      setTimeout(() => setShowCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleSaveIdea = async () => {
    if (savingState === 'saving') return;
    
    try {
      setSavingState('saving');
      
      // Format the idea data for saving
      const ideaData = {
        content: idea.generatedIdeas || idea.analysis,
        details: idea.topic || '',
        source: 'you-idea',
        sourceId: idea._id,
        category: idea.source === 'reddit' ? 'Reddit Analysis' : 'Internet Analysis',
        tags: [
          ...(idea.subreddits?.map(sub => `r/${sub.name}`) || []),
          idea.source === 'reddit' ? 'Reddit' : 'Internet Research'
        ],
        status: 'pending'
      };

      console.log('Saving idea with data:', ideaData);
      await saveIdea(ideaData);
      setSavingState('saved');
      toast.success('Idea saved successfully');
      
      // Reset state after 2 seconds
      setTimeout(() => {
        setSavingState('idle');
      }, 2000);
    } catch (error) {
      console.error('Error saving idea:', error);
      if (error.response?.data?.message) {
        console.error('Server error message:', error.response.data.message);
      }
      toast.error('Failed to save idea');
      setSavingState('idle');
    }
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const renderRedditAnalysis = (content) => {
    // Remove the AI instructions section
    const cleanedContent = content.split('Remember to:')[0].trim();
    
    // Split into sections
    const sections = cleanedContent.split(/(?=# |## )/).filter(Boolean);
    
    // Helper function to render a section
    const renderSection = (sectionKey, title, content, customRender) => {
      const sectionContent = sections.find(s => s.includes(title));
      if (!sectionContent) return null;

      // Function to wrap content with copy button
      const wrapWithCopy = (content, element) => {
        return (
          <div className="group relative pr-8">
            <div className="absolute right-0 top-1/2 -translate-y-1/2">
              <CopyButton text={content} isDarkMode={isDarkMode} />
            </div>
            {element}
          </div>
        );
      };

      return (
        <div className="space-y-4">
          {/* Section Header with Expand/Collapse */}
          <div 
            onClick={() => toggleSection(sectionKey)}
            className={`flex items-center justify-between py-2 cursor-pointer group border-b ${
              isDarkMode 
                ? 'border-[#333333] hover:border-[#444444]' 
                : 'border-gray-200/60 hover:border-gray-300/60'
            } transition-colors`}
          >
            <div className="flex items-center gap-2">
              <ChevronDownIcon 
                className={`w-4 h-4 transition-transform duration-200 ${
                  expandedSections[sectionKey] ? 'rotate-180' : ''
                } ${
                  isDarkMode 
                    ? 'text-slate-400 group-hover:text-slate-300' 
                    : 'text-slate-500 group-hover:text-slate-600'
                }`}
              />
              <h3 className={`text-sm font-medium ${
                isDarkMode 
                  ? 'text-slate-200 group-hover:text-slate-100' 
                  : 'text-slate-700 group-hover:text-slate-800'
              }`}>
                {title.replace(/^[#\s]+/, '')}
              </h3>
            </div>
          </div>

          {/* Section Content with Animation */}
          <AnimatePresence>
            {expandedSections[sectionKey] && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
                className="overflow-hidden"
              >
                {customRender(sectionContent, wrapWithCopy)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      );
    };

    return (
      <div className={`space-y-6 ${!isExpanded && 'max-h-[180px] overflow-hidden relative'}`}>
        {/* Title Section with Metrics Overview */}
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <h2 className={`text-lg font-bold ${
              isDarkMode ? 'text-slate-100' : 'text-slate-900'
            }`}>
              {sections[0].split('\n')[0].replace(/^[#\s]+/, '')}
            </h2>
            <span className={`text-sm ${
              isDarkMode ? 'text-slate-400' : 'text-slate-600'
            }`}>
              {sections[0].split('\n')[1]?.trim()}
            </span>
          </div>

          {/* Community Metrics Grid */}
          {renderSection('metrics', 'COMMUNITY METRICS', sections, (content, wrapWithCopy) => (
            <div className={`grid grid-cols-2 md:grid-cols-4 gap-4 p-4 rounded-lg ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-50'
            }`}>
              {content
                .split('\n')
                .filter(line => line.includes(':'))
                .map((line, i) => {
                  const [label, value] = line.split(':').map(s => s.trim());
                  if (!label || !value) return null;
                  return wrapWithCopy(
                    `${label}: ${value}`,
                    <div key={i} className="flex flex-col gap-1">
                      <span className={`text-xs font-medium ${
                        isDarkMode ? 'text-slate-400' : 'text-slate-600'
                      }`}>
                        {label}
                      </span>
                      <span className={`text-xl font-bold ${
                        isDarkMode ? 'text-slate-100' : 'text-slate-900'
                      }`}>
                        {value}
                      </span>
                    </div>
                  );
              })}
            </div>
          ))}
        </div>

        {/* High-Potential Content Themes */}
        {renderSection('themes', 'HIGH-POTENTIAL CONTENT THEMES', sections, (content, wrapWithCopy) => (
          <div className="space-y-6">
            {/* Content Grid */}
            <div className="grid gap-8">
              {sections
                .find(s => s.includes('HIGH-POTENTIAL CONTENT THEMES'))
                .split(/(?=\d+\. )/)
                .filter(Boolean)
                .slice(1)
                .map((theme, themeIndex) => {
                  const [title, ...allContent] = theme.split('\n').filter(Boolean);
                  const engagementScore = title.match(/\(Engagement Score: (\d+)\/10\)/)?.[1];
                  
                  // Find where Quick Stats section starts
                  const quickStatsIndex = allContent.findIndex(line => 
                    line.includes('Quick Stats:')
                  );
                  
                  // Separate content into theme content and quick stats
                  const themeContent = quickStatsIndex === -1 
                    ? allContent 
                    : allContent.slice(0, quickStatsIndex);
                  
                  const themeQuickStats = quickStatsIndex !== -1
                    ? allContent.slice(quickStatsIndex + 1)
                      .filter(line => line.trim().startsWith('-'))
                    : [];
                  
                  // Filter out any remaining Quick Stats references from theme content
                  const contentAngles = themeContent
                    .filter(line => line.trim().startsWith('-'))
                    .filter(line => !line.includes('Quick Stats:'));
                  
                  return wrapWithCopy(
                    title.split('(')[0].replace(/^\d+\.\s*/, '') + '\n' + contentAngles.map(angle => angle.trim()).join('\n'),
                    <div key={themeIndex} className="space-y-4">
                      {/* Theme Content Card */}
                      <div className={`p-4 rounded-lg ${
                        isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-50'
                      }`}>
                        <div className="flex items-center justify-between mb-4">
                          <h4 className={`text-base font-medium ${
                            isDarkMode ? 'text-slate-100' : 'text-slate-900'
                          }`}>
                            {title.split('(')[0].replace(/^\d+\.\s*/, '')}
                          </h4>
                          {engagementScore && (
                            <span className={`px-2 py-1 text-sm rounded ${
                              isDarkMode 
                                ? 'bg-[#252525] text-orange-300' 
                                : 'bg-orange-100 text-orange-700'
                            }`}>
                              Score: {engagementScore}/10
                            </span>
                          )}
                        </div>
                        
                        {/* Content Angles */}
                        <div className="space-y-3">
                          {contentAngles.map((angle, angleIndex) => {
                            const [angleText, explanation] = angle.split('-').slice(1).join('-').split('Why:').map(s => s.trim());
                            return wrapWithCopy(
                              angleText + (explanation ? ` - ${explanation}` : ''),
                              <div key={angleIndex} className="flex gap-3">
                                <span className={`text-sm ${
                                  isDarkMode ? 'text-slate-300' : 'text-slate-700'
                                }`}>
                                  •
                                </span>
                                <div className="flex-1">
                                  <span className={`text-sm ${
                                    isDarkMode ? 'text-slate-200' : 'text-slate-800'
                                  }`}>
                                    {angleText}
                                  </span>
                                  {explanation && (
                                    <p className={`text-sm mt-1 ${
                                      isDarkMode ? 'text-slate-400' : 'text-slate-600'
                                    }`}>
                                      {explanation}
                                    </p>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      {/* Quick Stats Card - Separate but related to theme */}
                      {themeQuickStats.length > 0 && (
                        <div className={`ml-4 p-4 rounded-lg border-l-2 ${
                          isDarkMode 
                            ? 'bg-[#1a1a1a]/50 border-orange-500/30' 
                            : 'bg-gray-50/70 border-orange-200'
                        }`}>
                          <h5 className={`text-sm font-medium mb-3 flex items-center gap-2 ${
                            isDarkMode ? 'text-slate-300' : 'text-slate-700'
                          }`}>
                            <ChartBarIcon className="w-4 h-4" />
                            Quick Stats
                          </h5>
                          <ul className="space-y-2">
                            {themeQuickStats
                              .filter(stat => !stat.includes(title.split('(')[0].trim()))
                              .map((stat, index) => wrapWithCopy(
                                stat.replace(/^[-•\s]+/, ''),
                                <li 
                                  key={index}
                                  className={`flex items-start gap-3 text-sm ${
                                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                                  }`}
                                >
                                  <span>•</span>
                                  <span>{stat.replace(/^[-•\s]+/, '')}</span>
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        ))}

        {/* Viral Content Opportunities Section */}
        {renderSection('opportunities', 'VIRAL CONTENT OPPORTUNITIES', sections, (content, wrapWithCopy) => (
          <ViralOpportunitiesFormat 
            content={content} 
            isDarkMode={isDarkMode}
            wrapWithCopy={wrapWithCopy}
          />
        ))}

        {/* Trending Discussions */}
        {renderSection('discussions', 'TRENDING DISCUSSIONS', sections, (content, wrapWithCopy) => (
          <div className="grid gap-4">
            {content
              .split(/(?=\d+\. )/)
              .filter(Boolean)
              .slice(1)
              .map((discussion, index) => {
                const [title, ...angles] = discussion.split('\n').filter(Boolean);
                const engagement = title.match(/\(Engagement: ([^)]+)\)/)?.[1];
                
                return wrapWithCopy(
                  title.split('(')[0].replace(/^\d+\.\s*/, ''),
                  <div 
                    key={index}
                    className={`p-4 rounded-lg ${
                      isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-50'
                    }`}
                  >
                    <div className="flex items-center justify-between mb-3">
                      <h4 className={`text-base font-medium ${
                        isDarkMode ? 'text-slate-100' : 'text-slate-900'
                      }`}>
                        {title.split('(')[0].replace(/^\d+\.\s*/, '')}
                      </h4>
                      {engagement && (
                        <span className={`px-2 py-1 text-sm rounded ${
                          isDarkMode 
                            ? 'bg-[#252525] text-blue-300' 
                            : 'bg-blue-100 text-blue-700'
                        }`}>
                          {engagement}
                        </span>
                      )}
                    </div>
                    <div className="space-y-2">
                      {angles
                        .filter(angle => angle.trim().startsWith('-'))
                        .map((angle, angleIndex) => {
                          const [angleText, insight] = angle.split('-').slice(1).join('-').split('-').map(s => s.trim());
                          return wrapWithCopy(
                            angleText + (insight ? ` - ${insight}` : ''),
                            <div key={angleIndex} className="flex gap-3">
                              <span className={`text-sm ${
                                isDarkMode ? 'text-slate-300' : 'text-slate-700'
                              }`}>
                                •
                              </span>
                              <div className="flex-1">
                                <span className={`text-sm ${
                                  isDarkMode ? 'text-slate-200' : 'text-slate-800'
                                }`}>
                                  {angleText}
                                </span>
                                {insight && (
                                  <span className={`text-sm ml-2 ${
                                    isDarkMode ? 'text-slate-400' : 'text-slate-600'
                                  }`}>
                                    - {insight}
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                      })}
                    </div>
                  </div>
                );
            })}
          </div>
        ))}

        {/* Engagement Insights */}
        {renderSection('insights', 'ENGAGEMENT INSIGHTS', sections, (content, wrapWithCopy) => (
          <div className="grid gap-4">
            {content
              .split(/(?=\d+\. )/)
              .filter(Boolean)
              .slice(1)
              .map((insight, index) => {
                const [title, ...items] = insight.split('\n').filter(Boolean);
                
                return wrapWithCopy(
                  title.replace(/^\d+\.\s*/, '') + '\n' + items.join('\n'),
                  <div 
                    key={index}
                    className={`p-4 rounded-lg ${
                      isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-50'
                    }`}
                  >
                    <h4 className={`text-base font-medium mb-3 ${
                      isDarkMode ? 'text-slate-100' : 'text-slate-900'
                    }`}>
                      {title.replace(/^\d+\.\s*/, '')}
                    </h4>
                    <div className="space-y-2">
                      {items.map((item, itemIndex) => (
                        wrapWithCopy(
                          item.replace(/^[-•\s]+/, ''),
                          <div key={itemIndex} className="flex gap-3">
                            <span className={`text-sm ${
                              isDarkMode ? 'text-slate-300' : 'text-slate-700'
                            }`}>
                              •
                            </span>
                            <span className={`text-sm ${
                              isDarkMode ? 'text-slate-200' : 'text-slate-800'
                            }`}>
                              {item.replace(/^[-•\s]+/, '')}
                            </span>
                          </div>
                        )
                      ))}
                    </div>
                  </div>
                );
            })}
          </div>
        ))}

        {/* Quick-Win Ideas */}
        {renderSection('quickWins', 'QUICK-WIN IDEAS', sections, (content, wrapWithCopy) => (
          <div className="grid gap-4">
            {content
              .split(/(?=\d+\. )/)
              .filter(Boolean)
              .slice(1)
              .map((idea, index) => {
                const [title, ...details] = idea.split('\n').filter(Boolean);
                
                return wrapWithCopy(
                  title.replace(/^\d+\.\s*/, '') + '\n' + details.join('\n'),
                  <div 
                    key={index}
                    className={`p-4 rounded-lg ${
                      isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-50'
                    }`}
                  >
                    <h4 className={`text-base font-medium mb-3 ${
                      isDarkMode ? 'text-slate-100' : 'text-slate-900'
                    }`}>
                      {title.replace(/^\d+\.\s*/, '')}
                    </h4>
                    <div className="space-y-2">
                      {details.map((detail, detailIndex) => {
                        const [type, content] = detail.split(':').map(s => s.trim());
                        return wrapWithCopy(
                          `${type}: ${content}`,
                          <div key={detailIndex} className="flex gap-2">
                            <span className={`text-sm font-medium ${
                              isDarkMode ? 'text-slate-400' : 'text-slate-600'
                            }`}>
                              {type}:
                            </span>
                            <span className={`text-sm ${
                              isDarkMode ? 'text-slate-300' : 'text-slate-700'
                            }`}>
                              {content}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
            })}
          </div>
        ))}

        {/* Gradient Overlay for collapsed state */}
        {!isExpanded && (
          <div 
            className={`absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t pointer-events-none ${
              isDarkMode ? 'from-[#151515]' : 'from-white'
            } to-transparent`}
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    const content = idea.generatedIdeas;
    
    if (!content) {
      return (
        <div className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
          No content available
        </div>
      );
    }

    // If it's a Reddit analysis
    if (idea.source === 'reddit') {
      return (
        <div className="space-y-4">
          {renderRedditAnalysis(content)}
          
          {/* Expand/Collapse Button */}
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={`w-full flex items-center justify-center gap-2 py-3 text-sm font-medium rounded-lg transition-colors
              ${isDarkMode 
                ? 'text-slate-300 hover:text-slate-100' 
                : 'text-slate-600 hover:text-slate-900'
              }`}
          >
            {isExpanded ? (
              <>
                <ChevronUpIcon className="w-5 h-5" />
                Show Less
              </>
            ) : (
              <>
                <ChevronDownIcon className="w-5 h-5" />
                Show Full Analysis
              </>
            )}
          </button>
        </div>
      );
    }

    // For non-Reddit content...
    // ... rest of the existing code ...
  };

  // Format the timestamp
  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  // Update NumberedReferences component text colors
  const NumberedReferences = ({ references }) => {
    if (!references?.length) return null;

    return (
      <div className={`mt-4 pt-4 border-t ${
        isDarkMode ? 'border-[#333333]' : 'border-gray-200/20'
      }`}>
        <h4 className={`text-sm font-medium mb-2 ${
          isDarkMode ? 'text-slate-200' : 'text-slate-700'
        }`}>
          References
        </h4>
        <div className="space-y-1">
          {references.map((ref, index) => (
            <div 
              key={index}
              className={`text-sm ${
                isDarkMode ? 'text-gray-300' : 'text-slate-600'
              }`}
            >
              <span className="mr-2">[{index + 1}]</span>
              <a 
                href={ref.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-400 hover:underline"
              >
                {ref.url}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Add CopyButton component
  const CopyButton = ({ text, isDarkMode }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = async (e) => {
      e.stopPropagation();
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy text:', err);
      }
    };

    return (
      <button
        onClick={handleCopy}
        className={`p-1 rounded-md opacity-0 group-hover:opacity-100 transition-all duration-200 ${
          isDarkMode 
            ? 'hover:bg-[#252525] text-slate-400 hover:text-slate-300' 
            : 'hover:bg-gray-100 text-gray-400 hover:text-gray-600'
        }`}
        title={copied ? "Copied!" : "Copy to clipboard"}
      >
        {copied ? (
          <CheckIcon className="w-4 h-4 text-green-500" />
        ) : (
          <ClipboardIcon className="w-4 h-4" />
        )}
      </button>
    );
  };

  // Add to PropTypes
  CopyButton.propTypes = {
    text: PropTypes.string.isRequired,
    isDarkMode: PropTypes.bool.isRequired
  };

  return (
    <motion.div
      initial={isHighlighted ? { scale: 0.98, opacity: 0 } : false}
      animate={isHighlighted ? { scale: 1, opacity: 1 } : false}
      transition={{ duration: 0.5 }}
      className={`
        relative rounded-lg
        ${isHighlighted
          ? isDarkMode
            ? 'bg-orange-500/10 border border-orange-500/20'
            : 'bg-orange-50 border border-orange-200'
          : isDarkMode
            ? 'bg-[#151515] border border-[#333333]'
            : 'bg-white border border-gray-200/60'
        }
        ${isDarkMode 
          ? 'hover:bg-[#1a1a1a]' 
          : 'hover:bg-gray-50/50'
        }
        transition-colors duration-200
      `}
    >
      <div className={`
        px-3 sm:px-4 py-3 flex items-center justify-between border-b
        ${isDarkMode ? 'border-[#333333]' : 'border-gray-200/50'}
      `}>
        {/* Title section */}
        <div 
          onClick={() => setIsExpanded(!isExpanded)}
          className={`flex items-center gap-2 min-w-0 flex-1 cursor-pointer 
            group transition-colors rounded-md -ml-1 p-1`}
        >
          {isRedditContent(idea) ? (
            <RedditIcon 
              isDarkMode={isDarkMode} 
              className="w-4 h-4 flex-shrink-0 text-orange-500" 
            />
          ) : (
            <GlobeAltIcon 
              className="w-4 h-4 flex-shrink-0 text-indigo-500" 
            />
          )}
          <div className="min-w-0 flex-1">
            <h3 className={`text-sm font-medium truncate ${
              isDarkMode ? 'text-slate-200' : 'text-gray-900'
            }`}>
              {getPopupTitle(idea, getMainIdea)}
            </h3>
          </div>
        </div>

        {/* Right section with date and actions */}
        <div className="flex items-center gap-2">
          <div className="hidden sm:block">
            <DateDisplay 
              timestamp={idea.createdAt}
              isDarkMode={isDarkMode}
            />
          </div>
          <PopoverMenu
            isDarkMode={isDarkMode}
            onCopy={handleCopy}
            onDelete={() => onDelete(idea._id)}
            onSave={handleSaveIdea}
            onViewRedditData={handleViewRedditData}
            hasRedditData={hasRedditData()}
            showCopySuccess={showCopySuccess}
            savingState={savingState}
            onExpand={handlePopupOpen}
          />
        </div>
      </div>

      {/* Content section */}
      <div className="px-3 sm:px-4 py-3">
        {renderContent()}
      </div>

      {/* Popup */}
      {isPopupOpen && (
        <ExpandedPopup
          idea={idea}
          isDarkMode={isDarkMode}
          onClose={handlePopupClose}
          isSidebarCollapsed={isSidebarCollapsed}
          getMainIdea={getMainIdea}
        >
          <div className="space-y-6">
            <div className={`${isDarkMode ? markdownStyles.dark : markdownStyles.light}`}>
              <ReactMarkdown>
                {idea.generatedIdeas}
              </ReactMarkdown>
            </div>

            {idea.source === 'reddit' && idea.subreddits && (
              <div className={`mt-4 pt-4 border-t ${
                isDarkMode ? 'border-[#333333]' : 'border-gray-200'
              }`}>
                <h4 className={`text-sm font-medium mb-2 ${
                  isDarkMode ? 'text-slate-200' : 'text-slate-700'
                }`}>
                  Analyzed Subreddits
                </h4>
                <div className="grid gap-2">
                  {idea.subreddits.map((sub, index) => (
                    <div 
                      key={index}
                      className={`flex flex-col sm:flex-row sm:items-center justify-between p-2 rounded-lg gap-2 ${
                        isDarkMode ? 'bg-[#1a1a1a] border border-[#333333]' : 'bg-gray-50'
                      }`}
                    >
                      <div className="flex items-center gap-2">
                        <RedditIcon isDarkMode={isDarkMode} />
                        <a 
                          href={`https://reddit.com/r/${sub.name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`text-sm ${
                            isDarkMode ? 'text-indigo-400' : 'text-indigo-500'
                          } hover:underline`}
                        >
                          r/{sub.name}
                        </a>
                      </div>
                      <span className={`text-xs ${
                        isDarkMode ? 'text-gray-400' : 'text-gray-500'
                      }`}>
                        {sub.subscribers?.toLocaleString()} members
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <NumberedReferences references={idea.references} />
          </div>
        </ExpandedPopup>
      )}
    </motion.div>
  );
};

// Update the ActionButton component to support small size
const ActionButton = ({ 
  icon: Icon, 
  onClick, 
  isDarkMode, 
  isActive, 
  activeColor,
  hoverColor,
  tooltip,
  isStatic,
  small = false,
  className = '',
  successIcon
}) => (
  <button
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
    className={`${small ? 'p-1' : 'p-1.5'} rounded-md transition-colors ${
      isDarkMode 
        ? 'text-slate-400 hover:bg-[#202020] hover:text-slate-200' 
        : 'text-gray-500 hover:bg-gray-100/50'
    } ${isActive ? activeColor : ''} ${hoverColor || ''} ${
      isStatic ? 'pointer-events-none' : ''
    } ${className}`}
    title={tooltip}
  >
    {successIcon ? (
      <div className="relative">
        <Icon className={`${small ? 'w-3.5 h-3.5' : 'w-4 h-4'} ${
          isActive ? 'fill-current' : ''
        } transition-opacity duration-200 ${
          isActive ? 'opacity-0' : 'opacity-100'
        }`} />
        {successIcon && (
          <CheckIcon className={`${small ? 'w-3.5 h-3.5' : 'w-4 h-4'} 
            absolute top-0 left-0 
            transition-opacity duration-200 ${
              isActive 
                ? 'opacity-100 text-green-500' 
                : 'opacity-0'
            }`} 
          />
        )}
      </div>
    ) : (
      <Icon className={`${small ? 'w-3.5 h-3.5' : 'w-4 h-4'} ${
        isActive ? 'fill-current' : ''
      }`} />
    )}
  </button>
);

export default YouIdeaItem;
