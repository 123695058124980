import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SparklesIcon, XMarkIcon, CpuChipIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { useTheme } from '../../context/ThemeContext';

const SubscriptionPopup = ({ onClose }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  
  const features = [
    {
      title: 'Advanced AI Models',
      description: 'Access to premium AI models like Claude 3.5 Sonnet, GPT-4o, and Gemini 2.0 Flash',
      icon: CpuChipIcon,
      color: 'indigo'
    },
    {
      title: 'Unlimited Generations',
      description: 'Create content without monthly limits. Unused credits roll over to next month',
      icon: SparklesIcon,
      color: 'blue'  
    },
    {
      title: 'Priority Support',
      description: '24/7 dedicated support, feature requests, and early access to new models',
      icon: UserGroupIcon,
      color: 'emerald'
    }
  ];

  const getColorClasses = (color) => {
    const colors = {
      indigo: {
        bg: isDarkMode ? 'bg-indigo-500/10' : 'bg-indigo-50',
        text: isDarkMode ? 'text-indigo-400' : 'text-indigo-600',
        border: isDarkMode ? 'border-indigo-500/20' : 'border-indigo-200/50',
        shadow: 'shadow-[0_8px_30px_rgba(99,102,241,0.2)]',
        hover: isDarkMode ? 'hover:bg-indigo-500/20' : 'hover:bg-indigo-100'
      },
      blue: {
        bg: isDarkMode ? 'bg-blue-500/10' : 'bg-blue-50',
        text: isDarkMode ? 'text-blue-400' : 'text-blue-600', 
        border: isDarkMode ? 'border-blue-500/20' : 'border-blue-200/50',
        shadow: 'shadow-[0_8px_30px_rgba(59,130,246,0.2)]',
        hover: isDarkMode ? 'hover:bg-blue-500/20' : 'hover:bg-blue-100'
      },
      emerald: {
        bg: isDarkMode ? 'bg-emerald-500/10' : 'bg-emerald-50',
        text: isDarkMode ? 'text-emerald-400' : 'text-emerald-600',
        border: isDarkMode ? 'border-emerald-500/20' : 'border-emerald-200/50', 
        shadow: 'shadow-[0_8px_30px_rgba(16,185,129,0.2)]',
        hover: isDarkMode ? 'hover:bg-emerald-500/20' : 'hover:bg-emerald-100'
      }
    };
    return colors[color];
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999] px-4">
      <div 
        className={`fixed inset-0 backdrop-blur-sm ${
          isDarkMode 
            ? 'bg-gray-900/80' 
            : 'bg-white/80'
        }`} 
        onClick={onClose}
      ></div>
      <motion.div 
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className={`
          relative w-full max-w-lg rounded-2xl p-6
          ${isDarkMode ? 'bg-gray-900/90' : 'bg-white/90'} 
          border ${isDarkMode ? 'border-gray-800' : 'border-gray-200'}
          backdrop-blur-sm shadow-2xl
        `}
      >
        <button 
          onClick={onClose}
          className={`
            absolute right-4 top-4 p-1.5 rounded-lg transition-colors z-[10000]
            ${isDarkMode ? 'hover:bg-white/10' : 'hover:bg-gray-100'}
          `}
        >
          <XMarkIcon className="h-5 w-5" />
        </button>

        <div className="mb-8">
          <div className="flex items-center gap-3 mb-3">
            <div className={`p-2.5 rounded-xl ${isDarkMode ? 'bg-indigo-500/10' : 'bg-indigo-50'}`}>
              <SparklesIcon className={`h-6 w-6 ${isDarkMode ? 'text-indigo-400' : 'text-indigo-600'}`} />
            </div>
            <h3 className="text-xl font-semibold">
              Upgrade to{' '}
              <span className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                Pro
              </span>
            </h3>
          </div>
          <p className={`text-sm ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            Unlock premium features and supercharge your AI experience
          </p>
        </div>

        <div className="space-y-4 mb-8">
          {features.map((feature, index) => {
            const colorClasses = getColorClasses(feature.color);
            return (
              <div 
                key={index}
                className={`
                  p-4 rounded-xl border transition-all duration-300 transform hover:translate-y-[-2px]
                  ${colorClasses.bg} ${colorClasses.border} ${colorClasses.shadow}
                `}
              >
                <div className="flex items-start gap-3">
                  <feature.icon className={`w-5 h-5 mt-0.5 flex-shrink-0 ${colorClasses.text}`} />
                  <div>
                    <div className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                      {feature.title}
                    </div>
                    <div className={`text-xs mt-1 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                      {feature.description}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Money-Back Guarantee Card */}
        <div className={`p-4 rounded-xl border mb-6 backdrop-blur-sm transition-all duration-300 transform hover:translate-y-[-2px] ${
          isDarkMode 
            ? 'bg-blue-950/30 border-blue-800/30 shadow-xl shadow-blue-900/10' 
            : 'bg-blue-50/50 border-blue-100/50 shadow-lg shadow-blue-100/50'
        }`}>
          <div className="flex items-start gap-3">
            <div className={`p-2 rounded-lg ${isDarkMode ? 'bg-blue-500/10' : 'bg-blue-100'}`}>
              <SparklesIcon className={`w-4 h-4 ${isDarkMode ? 'text-blue-400' : 'text-blue-600'}`} />
            </div>
            <div>
              <h3 className={`text-xs font-semibold mb-1 ${isDarkMode ? 'text-blue-200' : 'text-blue-900'}`}>
                7-Day Money-Back Guarantee
              </h3>
              <p className={`text-[10px] leading-relaxed ${isDarkMode ? 'text-blue-300' : 'text-blue-700'}`}>
                Try risk-free with our no-questions-asked refund policy! 🎉
              </p>
            </div>
          </div>
        </div>

        <div className="flex gap-3">
          <button
            onClick={onClose}
            className={`
              flex-1 px-4 py-2 rounded-lg text-sm font-medium transition-colors
              ${isDarkMode 
                ? 'bg-[#2a2a2a] hover:bg-[#333333] text-gray-300' 
                : 'bg-gray-100 hover:bg-gray-200 text-gray-700'}
            `}
          >
            Maybe Later
          </button>
          <button
            onClick={() => {
              onClose();
              navigate('/subscription');
            }}
            className="
              flex-1 px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200
              bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 
              hover:from-blue-600 hover:via-purple-600 hover:to-pink-600
              text-white transform hover:scale-[1.02] active:scale-[0.98]
              shadow-lg shadow-blue-500/20 hover:shadow-xl hover:shadow-blue-500/30
            "
          >
            Upgrade Now
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default SubscriptionPopup; 