import React, { useMemo, useCallback, memo } from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, isToday, isPast } from 'date-fns';
import CompactLinkedInPostPreview from './CompactLinkedInPostPreview';
import { PlusIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '../../lib/utils';
import PropTypes from 'prop-types';

/**
 * @typedef {Object} Post
 * @property {string} _id - Unique identifier for the post
 * @property {string} scheduledFor - ISO date string for scheduled time
 */

/**
 * Memoized TimeSlotCell component for better performance
 */
const TimeSlotCell = memo(({ 
  day, 
  hour, 
  posts = [], 
  isPastSlot, 
  isDarkMode, 
  onDateClick, 
  onEditPost, 
  onDeletePost,
  isCurrentTimeSlot
}) => {
  const cellDate = new Date(day).setHours(hour);
  const hasMultiplePosts = posts.length > 1;
  const now = new Date();
  const isCurrentHour = isToday(day) && hour === now.getHours();
  const currentMinutes = now.getMinutes();

  // Only disable past days, not future hours of today
  const isPastDay = isPast(new Date(day).setHours(23, 59, 59));

  const handleSlotClick = (e) => {
    if (isPastDay) return; // Past days are fully disabled

    // For current hour, calculate click position to determine minutes
    const rect = e.currentTarget.getBoundingClientRect();
    const clickY = e.clientY - rect.top;
    const minuteInHour = Math.floor((clickY / rect.height) * 60);
    
    if (isCurrentHour && minuteInHour <= currentMinutes) {
      // Don't allow scheduling in past minutes of current hour
      return;
    }

    // Set the exact time for scheduling
    const scheduleTime = new Date(day);
    scheduleTime.setHours(hour, isCurrentHour ? minuteInHour : 0);
    onDateClick(scheduleTime);
  };

  return (
    <motion.div 
      initial={false}
      whileHover={{ scale: 0.99 }}
      className={cn(
        "h-20 px-1 py-1 border-b relative group transition-all duration-200",
        "hover:bg-opacity-50 cursor-pointer focus-visible:ring-2 focus-visible:ring-blue-500",
        isPastDay && "opacity-50",
        isDarkMode 
          ? "border-zinc-800 hover:bg-zinc-800/50" 
          : "border-zinc-200 hover:bg-zinc-50",
        hasMultiplePosts && "overflow-y-auto custom-scrollbar",
        isCurrentTimeSlot && (isDarkMode 
          ? "bg-blue-500/10 border-blue-500/20" 
          : "bg-blue-50/50 border-blue-200/20"
        )
      )}
      onClick={handleSlotClick}
      role="button"
      aria-label={`Schedule post for ${format(cellDate, 'EEEE')} at ${format(cellDate, 'h:mm a')}${isPastDay ? ' (Past)' : ''}`}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          !isPastDay && handleSlotClick(e);
        }
      }}
      data-tooltip-id="schedule-tooltip"
      data-tooltip-content={isPastDay ? "Cannot schedule in the past" : "Click to schedule a post"}
    >
      {isCurrentHour && (
        <div 
          className={cn(
            "absolute inset-x-0 top-0 pointer-events-none",
            "bg-gradient-to-b",
            isDarkMode 
              ? "from-red-500/10 to-transparent" 
              : "from-red-100/20 to-transparent",
            "opacity-50"
          )}
          style={{
            height: `${(currentMinutes / 60) * 100}%`
          }}
        />
      )}

      <AnimatePresence>
        {posts.length === 0 && !isPastDay && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={cn(
              "hidden group-hover:flex items-center justify-center",
              "absolute inset-0 bg-opacity-90 gap-1.5",
              isDarkMode ? "bg-zinc-800/90" : "bg-white/90"
            )}
          >
            <PlusIcon className="w-4 h-4 text-blue-500" />
            <span className="text-xs font-medium text-blue-500">
              Schedule Post
            </span>
          </motion.div>
        )}
      </AnimatePresence>

      {posts.map((post, index) => (
        <motion.div
          key={post._id}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ delay: index * 0.1 }}
          className="group relative"
        >
          <CompactLinkedInPostPreview
            post={post}
            isDarkMode={isDarkMode}
            colorIndex={index}
            className="transform transition-all duration-200 hover:-translate-y-0.5"
          />
          
          {/* Delete Button */}
          {(post.status === 'scheduled' || post.status === 'published') && (
            <div className={cn(
              "absolute right-0 top-0 -mt-1 -mr-1",
              "opacity-0 group-hover:opacity-100",
              "transition-opacity duration-200"
            )}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDeletePost(post._id);
                }}
                className={cn(
                  "p-1 rounded-full",
                  "shadow-sm",
                  isDarkMode
                    ? [
                        "bg-red-500/90 hover:bg-red-500",
                        "text-white/90 hover:text-white",
                        "border border-red-400/20"
                      ]
                    : [
                        "bg-red-500/90 hover:bg-red-500",
                        "text-white",
                        "border border-red-400/20"
                      ],
                  "transition-colors duration-200",
                  "focus:outline-none focus:ring-2",
                  isDarkMode
                    ? "focus:ring-red-500/30"
                    : "focus:ring-red-500/30"
                )}
                title="Delete post"
              >
                <TrashIcon className="w-3 h-3" />
              </button>
            </div>
          )}
        </motion.div>
      ))}
    </motion.div>
  );
});

TimeSlotCell.displayName = 'TimeSlotCell';

/**
 * Memoized DayHeader component with sophisticated visual styling
 */
const DayHeader = memo(({ day, isDarkMode }) => {
  const isCurrentDay = isToday(day);
  
  return (
    <div 
      className={cn(
        "h-16 sticky top-0 z-20",
        "transition-all duration-200 ease-in-out",
        isDarkMode 
          ? "shadow-lg shadow-black/30"
          : "shadow-sm shadow-black/5"
      )}
    >
      <div className={cn(
        "absolute inset-0",
        isDarkMode
          ? isCurrentDay
            ? "bg-[#1a1a1a]"
            : "bg-[#141414]"
          : isCurrentDay
            ? "bg-[#111111]"
            : "bg-white",
        isCurrentDay && "border-x border-white/[0.02]"
      )} />
      
      <div className={cn(
        "absolute inset-x-0 bottom-0 h-[1px]",
        isDarkMode
          ? isCurrentDay
            ? "bg-white/10"
            : "bg-white/5"
          : isCurrentDay
            ? "bg-black/10"
            : "bg-black/5"
      )} />

      {isCurrentDay && (
        <>
          <div className={cn(
            "absolute top-0 inset-x-0 h-[2px]",
            isDarkMode
              ? "bg-white/10"
              : "bg-black/10"
          )} />
          <div className={cn(
            "absolute bottom-0 inset-x-0 h-8",
            "bg-gradient-to-t",
            isDarkMode
              ? "from-white/[0.02] to-transparent"
              : "from-black/[0.02] to-transparent"
          )} />
        </>
      )}

      <div 
        className="relative h-full flex flex-col items-center justify-center px-3"
        role="columnheader"
        aria-label={format(day, 'EEEE, MMMM d')}
      >
        <div className={cn(
          "text-sm font-medium tracking-wide",
          isCurrentDay
            ? isDarkMode
              ? "text-white"
              : "text-white"
            : isDarkMode
              ? "text-zinc-300"
              : "text-zinc-600"
        )}>
          {format(day, 'EEE').toUpperCase()}
        </div>
        
        <div className={cn(
          "text-xs mt-1 font-medium",
          isCurrentDay
            ? isDarkMode
              ? "text-zinc-400"
              : "text-zinc-400"
            : isDarkMode
              ? "text-zinc-500"
              : "text-zinc-400"
        )}>
          {format(day, 'MMM d')}
        </div>

        {isCurrentDay && (
          <div className={cn(
            "absolute -bottom-[1px] left-1/2 -translate-x-1/2",
            "w-8 h-[2px] rounded-full",
            isDarkMode
              ? "bg-white/20"
              : "bg-white/30"
          )} />
        )}
      </div>
    </div>
  );
});

DayHeader.displayName = 'DayHeader';

/**
 * Memoized TimeColumn component with improved header styling to match DayHeader
 */
const TimeColumn = memo(({ isDarkMode, timeSlots }) => {
  const now = new Date();
  const currentHour = now.getHours();

  return (
    <div 
      className={cn(
        "border-r sticky left-0 z-30",
        isDarkMode 
          ? "border-[#1f1f1f] bg-[#141414]" 
          : "border-zinc-200/80 bg-white",
        "transition-colors duration-300"
      )}
      role="rowheader"
    >
      {/* Header */}
      <div className={cn(
        "h-16 sticky top-0 z-40",
        "transition-all duration-200 ease-in-out",
        isDarkMode 
          ? "shadow-lg shadow-black/30"
          : "shadow-sm shadow-black/5"
      )}>
        <div className={cn(
          "absolute inset-0",
          isDarkMode
            ? "bg-[#1a1a1a]"
            : "bg-white"
        )} />
        
        <div className={cn(
          "absolute inset-x-0 bottom-0 h-[1px]",
          isDarkMode
            ? "bg-white/5"
            : "bg-black/5"
        )} />

        <div className={cn(
          "relative h-full flex items-center justify-between px-4",
          "group"
        )}>
          <div className="flex items-center">
            <span className="sr-only">Time slots</span>
            <ChevronRightIcon className={cn(
              "w-3.5 h-3.5 mr-1.5 transition-transform duration-200",
              "group-hover:translate-x-0.5",
              isDarkMode ? "text-zinc-500" : "text-zinc-400"
            )} />
            <span className={cn(
              "text-[11px] font-medium tracking-wide uppercase",
              isDarkMode ? "text-zinc-500" : "text-zinc-400"
            )}>
              Time
            </span>
          </div>
          <div className={cn(
            "text-[10px] font-medium",
            "opacity-0 group-hover:opacity-100 transition-opacity duration-200",
            isDarkMode ? "text-zinc-600" : "text-zinc-400"
          )}>
            GMT+5:30
          </div>
        </div>
      </div>

      {/* Time slots */}
      <div className="relative">
        {timeSlots.map(hour => {
          const isCurrentHour = hour === currentHour;
          const isPastHour = hour < currentHour;
          const isNextHour = hour === currentHour + 1;
          
          return (
            <div 
              key={hour}
              className={cn(
                "h-20 px-4",
                "flex items-center justify-end",
                "border-b transition-colors duration-200",
                "group relative",
                isDarkMode 
                  ? "border-[#1f1f1f]" 
                  : "border-zinc-100",
                isCurrentHour && (isDarkMode 
                  ? "bg-[#1a1a1a]" 
                  : "bg-zinc-50"
                ),
                isPastHour && "opacity-50"
              )}
            >
              <div className={cn(
                "flex items-center tabular-nums",
                "text-xs font-medium",
                isCurrentHour
                  ? isDarkMode 
                    ? "text-zinc-200" 
                    : "text-zinc-700"
                  : isDarkMode 
                    ? "text-zinc-500" 
                    : "text-zinc-400"
              )}>
                <span className="w-[1.5ch] text-right">
                  {format(new Date().setHours(hour), 'h')}
                </span>
                <span className="mx-0.5">:</span>
                <span>00</span>
                <span className={cn(
                  "ml-1 text-[10px] uppercase",
                  isCurrentHour
                    ? isDarkMode 
                      ? "text-zinc-400" 
                      : "text-zinc-500"
                    : isDarkMode 
                      ? "text-zinc-600" 
                      : "text-zinc-400"
                )}>
                  {format(new Date().setHours(hour), 'a')}
                </span>
              </div>

              {/* Hover indicator */}
              <div className={cn(
                "absolute right-0 top-1/2 -translate-y-1/2 w-[3px] h-8",
                "opacity-0 group-hover:opacity-100",
                "transition-all duration-200",
                isDarkMode 
                  ? isCurrentHour
                    ? "bg-white/20"
                    : "bg-white/10"
                  : isCurrentHour
                    ? "bg-black/10"
                    : "bg-black/5"
              )} />

              {/* Current time marker */}
              {isCurrentHour && (
                <div className={cn(
                  "absolute left-0 w-full h-[2px]",
                  isDarkMode ? "bg-white/10" : "bg-black/5"
                )}
                style={{
                  top: `${(now.getMinutes() / 60) * 100}%`
                }}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
});

TimeColumn.displayName = 'TimeColumn';

/**
 * WeeklyView Component - Displays a weekly calendar view for LinkedIn post scheduling
 */
const WeeklyView = ({ 
  currentDate, 
  scheduledPosts = [], 
  isDarkMode, 
  onDateClick,
  onEditPost,
  onDeletePost,
  isLoading = false
}) => {
  const scrollContainerRef = React.useRef(null);

  // Effect to scroll to current time on mount and when currentDate changes
  React.useEffect(() => {
    if (scrollContainerRef.current) {
      const now = new Date();
      const currentHour = now.getHours();
      
      // Each time slot is 5rem (80px) high
      const scrollPosition = currentHour * 80 - (window.innerHeight / 2) + 40;
      
      scrollContainerRef.current.scrollTo({
        top: Math.max(0, scrollPosition),
        behavior: 'smooth'
      });
    }
  }, [currentDate]);

  // Memoize date calculations
  const { weekStart, weekEnd, days, timeSlots } = useMemo(() => ({
    weekStart: startOfWeek(currentDate, { weekStartsOn: 0 }),
    weekEnd: endOfWeek(currentDate, { weekStartsOn: 0 }),
    days: eachDayOfInterval({ 
      start: startOfWeek(currentDate, { weekStartsOn: 0 }), 
      end: endOfWeek(currentDate, { weekStartsOn: 0 }) 
    }),
    timeSlots: Array.from({ length: 24 }, (_, i) => i)
  }), [currentDate]);

  // Memoize posts by day and hour
  const postsByDayAndHour = useMemo(() => {
    const map = new Map();
    
    scheduledPosts.forEach(post => {
      const postDate = new Date(post.scheduledFor);
      const key = `${format(postDate, 'yyyy-MM-dd')}-${format(postDate, 'HH')}`;
      if (!map.has(key)) {
        map.set(key, []);
      }
      map.get(key).push(post);
    });
    
    return map;
  }, [scheduledPosts]);

  if (isLoading) {
    return (
      <div className="flex-1 overflow-hidden">
        <div className="grid grid-cols-8 h-full animate-pulse">
          <TimeColumn isDarkMode={isDarkMode} timeSlots={timeSlots} />
          {Array.from({ length: 7 }).map((_, index) => (
            <div 
              key={index}
              className={cn(
                "border-r",
                isDarkMode ? "border-zinc-800" : "border-zinc-200"
              )}
            >
              <div className={cn(
                "h-12 border-b",
                isDarkMode ? "bg-zinc-800/50" : "bg-zinc-100/50",
                isDarkMode ? "border-zinc-800" : "border-zinc-200"
              )} />
              {Array.from({ length: 24 }).map((_, hourIndex) => (
                <div 
                  key={hourIndex}
                  className={cn(
                    "h-20 border-b",
                    isDarkMode ? "border-zinc-800" : "border-zinc-200"
                  )}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col h-full">
      <div 
        ref={scrollContainerRef}
        className={cn(
          "flex-1 overflow-auto relative custom-scrollbar",
          isDarkMode ? "bg-zinc-900" : "bg-white"
        )}
        role="grid"
        aria-label="Weekly schedule view"
      >
        <div className="grid grid-cols-8 min-w-[800px] h-[calc(24*5rem+3rem)]">
          <TimeColumn isDarkMode={isDarkMode} timeSlots={timeSlots} />
          
          {days.map(day => (
            <div 
              key={day.toString()}
              className={cn(
                "border-r relative",
                isDarkMode ? "border-zinc-800" : "border-zinc-200"
              )}
              role="columnheader"
            >
              <DayHeader day={day} isDarkMode={isDarkMode} />
              
              {timeSlots.map(hour => {
                const key = `${format(day, 'yyyy-MM-dd')}-${String(hour).padStart(2, '0')}`;
                const postsAtSlot = postsByDayAndHour.get(key) || [];
                const cellDate = new Date(day).setHours(hour);
                const now = new Date();
                const isPastDay = isPast(new Date(day).setHours(23, 59, 59));
                const isTodays = isToday(day);
                const isPastSlot = isPastDay || (isTodays && hour < now.getHours());
                const isCurrentTimeSlot = isToday(day) && hour === now.getHours();
                
                return (
                  <TimeSlotCell
                    key={`${day}-${hour}`}
                    day={day}
                    hour={hour}
                    posts={postsAtSlot}
                    isPastSlot={isPastSlot}
                    isDarkMode={isDarkMode}
                    onDateClick={onDateClick}
                    onEditPost={onEditPost}
                    onDeletePost={onDeletePost}
                    isCurrentTimeSlot={isCurrentTimeSlot}
                  />
                );
              })}

              {isToday(day) && (
                <div 
                  className="absolute left-0 right-0 z-30 pointer-events-none"
                  style={{ 
                    top: `${new Date().getHours() * 80 + (new Date().getMinutes() / 60) * 80}px`
                  }}
                >
                  <div className="relative flex items-center">
                    <div className="w-2 h-2 rounded-full bg-blue-500 ml-2" />
                    <div className="flex-1 h-[2px] bg-blue-500/50" />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

WeeklyView.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  scheduledPosts: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    scheduledFor: PropTypes.string.isRequired,
  })),
  isDarkMode: PropTypes.bool,
  onDateClick: PropTypes.func.isRequired,
  onEditPost: PropTypes.func.isRequired,
  onDeletePost: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default memo(WeeklyView); 