import React from 'react';
import { motion } from 'framer-motion';
import PublicPageLayout from './layout/PublicPageLayout';
import PageSection from './shared/PageSection';
import { useTheme } from '../../context/ThemeContext';
import { 
  SparklesIcon,
  BoltIcon,
  ChartBarIcon,
  ClockIcon,
  UserGroupIcon,
  PencilSquareIcon,
  RocketLaunchIcon,
  ChatBubbleLeftRightIcon,
  ArrowRightIcon
} from '@heroicons/react/24/outline';

const FeaturesPage = () => {
  const { isDarkMode } = useTheme();

  const mainFeatures = [
    {
      icon: SparklesIcon,
      title: 'AI-Powered Content Creation',
      description: 'Create engaging LinkedIn posts with the help of advanced AI that understands your voice and style.',
      benefits: [
        'Personalized content suggestions',
        'Voice and tone matching',
        'Topic ideation',
        'Content optimization'
      ]
    },
    {
      icon: BoltIcon,
      title: 'Smart Automation',
      description: 'Streamline your content workflow with intelligent automation tools.',
      benefits: [
        'Post scheduling',
        'Content calendar',
        'Auto-hashtag suggestions',
        'Batch content creation'
      ]
    },
    {
      icon: ChartBarIcon,
      title: 'Advanced Analytics',
      description: 'Track and optimize your content performance with detailed analytics.',
      benefits: [
        'Engagement metrics',
        'Audience insights',
        'Performance trends',
        'Content recommendations'
      ]
    },
    {
      icon: ClockIcon,
      title: 'Time-Saving Tools',
      description: 'Maximize efficiency with our suite of productivity tools.',
      benefits: [
        'Content templates',
        'Quick editing',
        'Bulk scheduling',
        'Content repurposing'
      ]
    }
  ];

  const additionalFeatures = [
    {
      icon: UserGroupIcon,
      title: 'Audience Management',
      description: 'Understand and engage with your audience effectively.'
    },
    {
      icon: PencilSquareIcon,
      title: 'Content Library',
      description: 'Organize and manage your content in one place.'
    },
    {
      icon: RocketLaunchIcon,
      title: 'Growth Tools',
      description: 'Tools and insights to grow your LinkedIn presence.'
    },
    {
      icon: ChatBubbleLeftRightIcon,
      title: 'Engagement Features',
      description: 'Foster meaningful interactions with your network.'
    }
  ];

  return (
    <PublicPageLayout
      title="Features"
      description="Everything you need to create impactful content on LinkedIn"
    >
      {/* Hero Section */}
      <PageSection>
        <div className={`
          max-w-4xl mx-auto p-8 rounded-2xl text-center
          ${isDarkMode 
            ? 'bg-gradient-to-b from-blue-500/10 to-purple-500/10 border border-white/10' 
            : 'bg-gradient-to-b from-blue-50 to-purple-50 border border-black/5'
          }
        `}>
          <h2 className={`text-3xl font-bold mb-4 ${
            isDarkMode ? 'text-white' : 'text-slate-900'
          }`}>
            Amplify Your LinkedIn Presence
          </h2>
          <p className={`text-lg mb-8 ${
            isDarkMode ? 'text-slate-300' : 'text-slate-600'
          }`}>
            Our comprehensive suite of features helps you create, manage, and optimize 
            your LinkedIn content with ease.
          </p>
          <div className="flex justify-center gap-4">
            <a
              href="/register"
              className={`
                inline-flex items-center gap-2
                px-6 py-3 rounded-lg
                font-medium text-white
                transition-all duration-200
                bg-blue-500 hover:bg-blue-600
              `}
            >
              Get Started Free
              <ArrowRightIcon className="w-4 h-4" />
            </a>
          </div>
        </div>
      </PageSection>

      {/* Main Features */}
      <PageSection
        title="Core Features"
        className="mt-12"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {mainFeatures.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className={`
                p-6 rounded-2xl
                ${isDarkMode 
                  ? 'bg-[#151515] border border-white/10' 
                  : 'bg-white border border-black/5'
                }
              `}
            >
              <div className={`
                p-3 rounded-lg w-fit mb-4
                ${isDarkMode 
                  ? 'bg-blue-500/10 text-blue-400' 
                  : 'bg-blue-50 text-blue-600'
                }
              `}>
                <feature.icon className="w-6 h-6" />
              </div>
              <h3 className={`text-xl font-semibold mb-2 ${
                isDarkMode ? 'text-white' : 'text-slate-900'
              }`}>
                {feature.title}
              </h3>
              <p className={`text-sm mb-4 ${
                isDarkMode ? 'text-slate-400' : 'text-slate-600'
              }`}>
                {feature.description}
              </p>
              <ul className="space-y-2">
                {feature.benefits.map((benefit) => (
                  <li 
                    key={benefit}
                    className={`
                      text-sm flex items-center gap-2
                      ${isDarkMode ? 'text-slate-300' : 'text-slate-700'}
                    `}
                  >
                    <span className="w-1 h-1 rounded-full bg-blue-500" />
                    {benefit}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </PageSection>

      {/* Additional Features */}
      <PageSection
        title="Additional Features"
        className="mt-12"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {additionalFeatures.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className={`
                p-6 rounded-2xl text-center
                ${isDarkMode 
                  ? 'bg-[#151515] border border-white/10' 
                  : 'bg-white border border-black/5'
                }
              `}
            >
              <div className={`
                mx-auto w-12 h-12 rounded-xl mb-4
                flex items-center justify-center
                ${isDarkMode 
                  ? 'bg-blue-500/10 text-blue-400' 
                  : 'bg-blue-50 text-blue-600'
                }
              `}>
                <feature.icon className="w-6 h-6" />
              </div>
              <h3 className={`text-lg font-semibold mb-2 ${
                isDarkMode ? 'text-white' : 'text-slate-900'
              }`}>
                {feature.title}
              </h3>
              <p className={`text-sm ${
                isDarkMode ? 'text-slate-400' : 'text-slate-600'
              }`}>
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>
      </PageSection>

      {/* CTA Section */}
      <PageSection className="mt-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className={`
            max-w-3xl mx-auto p-8 rounded-2xl text-center
            ${isDarkMode 
              ? 'bg-[#151515] border border-white/10' 
              : 'bg-white border border-black/5'
            }
          `}
        >
          <h3 className={`text-2xl font-semibold mb-4 ${
            isDarkMode ? 'text-white' : 'text-slate-900'
          }`}>
            Ready to amplify your LinkedIn presence?
          </h3>
          <p className={`text-sm mb-6 ${
            isDarkMode ? 'text-slate-400' : 'text-slate-600'
          }`}>
            Join thousands of professionals using Ammmplify to create impactful content
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a
              href="/register"
              className={`
                px-6 py-3 rounded-lg
                font-medium text-white
                transition-all duration-200
                bg-blue-500 hover:bg-blue-600
              `}
            >
              Get Started Free
            </a>
            <a
              href="/contact"
              className={`
                px-6 py-3 rounded-lg
                font-medium
                transition-all duration-200
                ${isDarkMode 
                  ? 'bg-white/5 hover:bg-white/10 text-white' 
                  : 'bg-slate-100 hover:bg-slate-200 text-slate-900'
                }
              `}
            >
              Contact Sales
            </a>
          </div>
        </motion.div>
      </PageSection>
    </PublicPageLayout>
  );
};

export default FeaturesPage;
