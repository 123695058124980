import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '../context/ThemeContext';
import LPHeader from './LPHeader';
import Footer from './Footer';
import '../components/scrollbar.css';
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/24/outline';

import {
  HeroSection,
  ContextBankSection,
  PricingSection
} from './landing';

// YouTube Modal Component at root level
const YouTubeModal = ({ isOpen, onClose, videoId }) => {
  const { isDarkMode } = useTheme();
  
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[9999]" style={{ isolation: 'isolate' }}>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 w-screen h-screen flex items-center justify-center p-4 sm:p-6 md:p-8"
        >
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 w-full h-full bg-black/95 backdrop-blur-md"
            onClick={onClose}
          />

          {/* Content Container */}
          <div className="relative z-[9999] w-full max-w-[1200px] aspect-video">
            {/* Close button */}
            <motion.button
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              onClick={onClose}
              className="absolute -top-12 right-0 p-2 rounded-full bg-white/10 hover:bg-white/20 text-white transition-all duration-200 hover:scale-110 transform backdrop-blur-sm border border-white/20"
              aria-label="Close video"
            >
              <XMarkIcon className="w-6 h-6" />
            </motion.button>

            {/* Video Container */}
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              transition={{
                type: "spring",
                stiffness: 300,
                damping: 30
              }}
              className="w-full h-full bg-black rounded-xl overflow-hidden shadow-2xl"
            >
              <iframe
                title="YouTube video player"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&modestbranding=1&enablejsapi=1&widgetid=1`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                allowFullScreen={true}
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
                frameBorder="0"
                className="w-full h-full"
              />
            </motion.div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const LandingPage = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videoId, setVideoId] = useState('');

  // Function to handle video modal
  const handleVideoModal = (id) => {
    setVideoId(id);
    setIsVideoModalOpen(true);
  };

  useEffect(() => {
    // Reset scroll position on mount
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    }
  }, []);

  useEffect(() => {
    // Only scroll if explicitly navigating to a section
    if (location.state?.scrollToSection && location.state?.shouldScroll) {
      const elementId = location.state.scrollToSection;
      const element = document.getElementById(elementId);
      const mainContainer = document.querySelector('main');
      
      if (element && mainContainer) {
        setTimeout(() => {
          const currentScrollTop = mainContainer.scrollTop;
          const containerRect = mainContainer.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const headerOffset = 100;
          const absoluteElementTop = elementRect.top - containerRect.top + currentScrollTop;
          
          mainContainer.scrollTo({
            top: absoluteElementTop - headerOffset,
            behavior: 'smooth'
          });
        }, 100);
      }
    }
  }, [location]);

  // Structured data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Ammmplify | Hyper-personalized AI Copilot for LinkedIn Creators",
    "description": "Transform your expertise into viral content. Our AI learns your voice, understands your audience, and amplifies your LinkedIn impact — all while staying authentically you.",
    "applicationCategory": "BusinessApplication",
    "operatingSystem": "Web",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "featureList": [
      "Hyper-personalized AI Copilot",
      "Voice Pattern Analysis",
      "Personal Context Integration",
      "LinkedIn Content Creation",
      "Professional Network Growth",
      "Engagement Optimization"
    ]
  };

  const mainTitle = "Ammmplify | Hyper-personalized AI Copilot for LinkedIn Creators";
  const mainDescription = "Transform your expertise into viral content. Our AI learns your voice, understands your audience, and amplifies your LinkedIn impact — all while staying authentically you.";

  return (
    <>
      <Helmet>
        <title>{mainTitle}</title>
        <meta name="description" content={mainDescription} />
        <meta name="keywords" content="LinkedIn AI, AI Copilot, LinkedIn content creation, personal branding, thought leadership, content automation, AI writing assistant, LinkedIn growth, professional networking" />
        
        {/* Open Graph tags */}
        <meta property="og:title" content={mainTitle} />
        <meta property="og:description" content={mainDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ammmplify.com/" />
        <meta property="og:image" content="%PUBLIC_URL%/og-image.webp" />
        
        {/* Twitter Card tags */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ammmplify.com/" />
        <meta property="twitter:title" content={mainTitle} />
        <meta property="twitter:description" content={mainDescription} />
        <meta property="twitter:image" content="%PUBLIC_URL%/og-image.webp" />
        
        {/* Structured data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div 
        className={`min-h-screen flex flex-col overflow-x-hidden w-full ${
          isDarkMode 
            ? 'bg-[#121212] text-slate-200' 
            : 'bg-white text-slate-800'
        } dotted-background relative`}
        role="application"
        aria-label="Ammmplify Landing Page"
      >
        <LPHeader />
        <main 
          className={`flex-grow overflow-y-auto overflow-x-hidden w-full chat-scrollbar-overlay relative ${
            isDarkMode ? 'bg-[#121212]/50' : 'bg-white/50'
          }`}
          role="main"
          aria-label="Main content"
        >
          <article>
            <HeroSection onVideoClick={handleVideoModal} />
            <ContextBankSection />
          </article>
          <Footer />
        </main>
      </div>

      {/* YouTube Modal at root level */}
      <YouTubeModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        videoId={videoId}
      />
    </>
  );
};

export default LandingPage;
