import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { cn } from '../utils/cn';
import FontStylePortal from './FontStylePortal';

const listStyles = [
  {
    id: 'numbered',
    symbol: '1.',
    label: 'Numbered',
    preview: '1. First item\n2. Second item\n3. Third item',
    regex: /^\d+\.\s+/
  },
  {
    id: 'bullet',
    symbol: '•',
    label: 'Bullet',
    preview: '• First item\n• Second item\n• Third item',
    regex: /^[•]\s+/
  },
  {
    id: 'dash',
    symbol: '—',
    label: 'Dash',
    preview: '— First item\n— Second item\n— Third item',
    regex: /^[—]\s+/
  },
  {
    id: 'check',
    symbol: '✓',
    label: 'Check',
    preview: '✓ First item\n✓ Second item\n✓ Third item',
    regex: /^[✓]\s+/
  },
  {
    id: 'star',
    symbol: '★',
    label: 'Star',
    preview: '★ First item\n★ Second item\n★ Third item',
    regex: /^[★]\s+/
  },
  {
    id: 'arrow',
    symbol: '→',
    label: 'Arrow',
    preview: '→ First item\n→ Second item\n→ Third item',
    regex: /^[→]\s+/
  }
];

// Helper function to detect if text is already a list and its type
const detectListType = (text) => {
  for (const style of listStyles) {
    if (style.regex.test(text)) {
      return style.id;
    }
  }
  return null;
};

// Helper function to get list items, preserving empty lines
const getListItems = (text) => {
  // Split by newline but preserve empty lines
  const lines = text.split(/\n/);
  const items = [];
  let currentItem = [];
  
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    const trimmedLine = line.trim();
    
    // If it's an empty line and we have a current item
    if (trimmedLine === '' && currentItem.length > 0) {
      items.push(currentItem.join('\n'));
      currentItem = [];
      // Add empty line as a separator
      items.push('');
      continue;
    }
    
    // If it's a continuation of current item (indented or not starting with list marker)
    const isListItem = listStyles.some(style => style.regex.test(trimmedLine));
    if (!isListItem && currentItem.length > 0) {
      currentItem.push(line);
      continue;
    }
    
    // If it's a new list item
    if (isListItem) {
      if (currentItem.length > 0) {
        items.push(currentItem.join('\n'));
        currentItem = [];
      }
      currentItem.push(line);
    } else {
      currentItem.push(line);
    }
  }
  
  // Add the last item if exists
  if (currentItem.length > 0) {
    items.push(currentItem.join('\n'));
  }
  
  return items;
};

// New function to handle advanced list transformations
const handleAdvancedListStyleSelect = (text, styleId) => {
  try {
    // Get the selected style
    const newStyle = listStyles.find(style => style.id === styleId);
    if (!newStyle) return text;

    // Split text into paragraphs/blocks while preserving empty lines
    const items = getListItems(text);
    
    return items.map((item, index) => {
      // Preserve empty lines
      if (item.trim() === '') return '';
      
      // Remove any existing list markers
      const existingType = detectListType(item);
      if (existingType) {
        const existingStyle = listStyles.find(style => style.id === existingType);
        item = item.replace(existingStyle.regex, '');
      }
      
      // Clean up the item
      item = item.trim();
      
      // For numbered lists, use the actual index
      if (styleId === 'numbered') {
        // Only count non-empty items for numbering
        const actualIndex = items.slice(0, index)
          .filter(i => i.trim() !== '').length + 1;
        return `${actualIndex}. ${item}`;
      }
      
      // For other list types
      return `${newStyle.symbol} ${item}`;
    }).join('\n');
  } catch (error) {
    console.error('Error applying list style:', error);
    return text; // Return original text if transformation fails
  }
};

// Add a function to render the preview icons
const ListPreviewIcon = ({ style, isDarkMode }) => {
  const baseIconClasses = cn(
    "flex flex-col items-start scale-[0.85] transition-transform duration-200",
    "group-hover:scale-90",
    isDarkMode ? "text-slate-200" : "text-gray-700"
  );

  const itemClasses = cn(
    "flex items-center gap-[2px]",
    "h-[6px] transition-all duration-200",
    "group-hover:gap-[3px]"
  );

  const symbolClasses = cn(
    "text-[7px] leading-none font-semibold transition-all duration-200",
    style.id === 'numbered' ? "tabular-nums" : "",
    isDarkMode 
      ? "text-white group-hover:text-blue-400" 
      : "text-gray-900 group-hover:text-blue-600"
  );

  const lineClasses = cn(
    "h-[1px] w-[12px] transition-all duration-200",
    isDarkMode 
      ? "bg-slate-300 group-hover:bg-blue-400/70" 
      : "bg-gray-600 group-hover:bg-blue-600/70"
  );

  const renderSymbol = (index) => {
    if (style.id === 'numbered') {
      return `${index}.`;
    }
    // Make symbols slightly larger for better visibility
    const symbolMap = {
      bullet: '•',
      dash: '—',
      check: '✓',
      star: '★',
      arrow: '→'
    };
    return symbolMap[style.id] || style.symbol;
  };

  return (
    <div className={baseIconClasses}>
      {[1, 2, 3].map((num) => (
        <div key={num} className={itemClasses}>
          <div className={symbolClasses}>
            {renderSymbol(num)}
          </div>
          <div className={lineClasses} />
        </div>
      ))}
    </div>
  );
};

const ListStylePillBar = ({ onSelectStyle, selectedStyle, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredStyle, setHoveredStyle] = useState(null);
  const buttonRef = useRef(null);
  const [dropdownStyle, setDropdownStyle] = useState({});

  const selectedListStyle = listStyles.find(style => style.id === selectedStyle) || listStyles[0];

  // Update dropdown position when button position changes
  useEffect(() => {
    if (isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setDropdownStyle({
        position: 'fixed',
        top: `${rect.bottom + 4}px`,
        left: `${rect.left}px`,
        minWidth: `${Math.max(rect.width, 180)}px`
      });
    }
  }, [isOpen]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!isOpen) return;

      switch (e.key) {
        case 'Escape':
          setIsOpen(false);
          break;
        case 'ArrowDown':
          e.preventDefault();
          const currentIndex = listStyles.findIndex(style => style.id === hoveredStyle);
          const nextIndex = currentIndex === listStyles.length - 1 ? 0 : currentIndex + 1;
          setHoveredStyle(listStyles[nextIndex].id);
          break;
        case 'ArrowUp':
          e.preventDefault();
          const currIndex = listStyles.findIndex(style => style.id === hoveredStyle);
          const prevIndex = currIndex <= 0 ? listStyles.length - 1 : currIndex - 1;
          setHoveredStyle(listStyles[prevIndex].id);
          break;
        case 'Enter':
          if (hoveredStyle) {
            onSelectStyle(hoveredStyle);
            setIsOpen(false);
          }
          break;
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, [isOpen, hoveredStyle, onSelectStyle]);

  return (
    <div className="relative inline-block">
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setIsOpen(!isOpen);
          }
        }}
        aria-label="Select list style"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        className={cn(
          "flex items-center gap-2 px-3 py-1.5 rounded-md text-xs font-medium min-w-[32px] justify-between h-[30px]",
          "transition-all duration-200 select-none group",
          isDarkMode
            ? [
                "bg-[#151515] border border-[#333] text-slate-200 hover:bg-[#1a1a1a]",
              ]
            : [
                "bg-gray-50 border border-gray-200 text-gray-700 hover:bg-white",
              ],
          isOpen && (isDarkMode 
            ? "bg-[#1a1a1a] border-[#444]" 
            : "bg-white border-gray-300")
        )}
      >
        <div className="flex items-center">
          <ListPreviewIcon 
            style={selectedListStyle} 
            isDarkMode={isDarkMode} 
          />
        </div>
        <ChevronDownIcon 
          className={cn(
            "w-3 h-3 transition-all duration-300 ease-out",
            isDarkMode 
              ? "text-slate-400 group-hover:text-blue-400" 
              : "text-gray-400 group-hover:text-blue-600",
            isOpen ? "transform rotate-180" : ""
          )}
        />
      </button>

      {isOpen && (
        <FontStylePortal>
          {/* Backdrop */}
          <div 
            className="fixed inset-0 z-[9999] bg-transparent" 
            onClick={() => setIsOpen(false)}
          />

          {/* Dropdown */}
          <div
            style={dropdownStyle}
            className={cn(
              "z-[9999] rounded-lg shadow-lg p-1.5",
              "transform transition-all duration-200 ease-out",
              "animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95",
              isDarkMode
                ? "bg-[#1a1a1a] border border-[#333] shadow-2xl shadow-black/20"
                : "bg-white border border-gray-200 shadow-xl shadow-gray-100/70"
            )}
            role="listbox"
            aria-label="List styles"
          >
            <div className="py-1">
              {listStyles.map((style) => (
                <button
                  key={style.id}
                  role="option"
                  aria-selected={selectedStyle === style.id}
                  onClick={() => {
                    onSelectStyle(style.id);
                    setIsOpen(false);
                  }}
                  onMouseEnter={() => setHoveredStyle(style.id)}
                  onMouseLeave={() => setHoveredStyle(null)}
                  className={cn(
                    "w-full text-left px-3 py-2 text-xs rounded-md",
                    "flex items-center gap-3",
                    "transition-all duration-200",
                    "relative group",
                    selectedStyle === style.id
                      ? isDarkMode
                        ? [
                            "bg-[#252525] text-white",
                          ]
                        : [
                            "bg-gray-100 text-gray-900",
                          ]
                      : isDarkMode
                        ? [
                            "text-slate-200 hover:bg-[#252525] hover:text-white",
                          ]
                        : [
                            "text-gray-800 hover:bg-gray-50 hover:text-gray-900",
                          ],
                    hoveredStyle === style.id && "bg-opacity-50"
                  )}
                >
                  <div className="flex items-center gap-3">
                    <ListPreviewIcon 
                      style={style} 
                      isDarkMode={isDarkMode} 
                    />
                    <span className="flex-1 font-medium">{style.label}</span>
                  </div>

                  {/* Preview tooltip */}
                  <div className={cn(
                    "absolute left-full ml-3 w-48 p-3 rounded-lg",
                    "transform scale-95 opacity-0 -translate-y-1",
                    "transition-all duration-200 pointer-events-none",
                    "whitespace-pre-line text-xs leading-relaxed",
                    (hoveredStyle === style.id) && "scale-100 opacity-100 translate-y-0",
                    isDarkMode
                      ? [
                          "bg-[#252525] border border-[#444] text-slate-300",
                          "shadow-2xl shadow-black/20"
                        ]
                      : [
                          "bg-white border border-gray-200 text-gray-600",
                          "shadow-xl shadow-gray-100/70"
                        ]
                  )}>
                    {style.preview}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </FontStylePortal>
      )}
    </div>
  );
};

export default ListStylePillBar; 