import React from 'react';
import { XMarkIcon, UserGroupIcon, PencilSquareIcon, BuildingOfficeIcon, AcademicCapIcon, HeartIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../../context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const TargetAudienceOverviewPopup = ({ audience, onClose }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/target-audiences/edit/${audience._id}`);
    onClose();
  };

  const fieldGroups = [
    {
      title: 'Basic Information',
      description: 'Core demographics and characteristics.',
      icon: (className) => <UserGroupIcon className={className} />,
      fields: [
        { label: 'Description', value: audience.describeYourAudience },
        { label: 'Age Range', value: audience.ageRange },
        { label: 'Location', value: audience.primaryLocation },
        { label: 'Industry', value: audience.industry }
      ]
    },
    {
      title: 'Professional Context',
      description: 'Career and expertise insights.',
      icon: (className) => <BuildingOfficeIcon className={className} />,
      fields: [
        { label: 'Career Stage', value: audience.careerStage },
        { label: 'Knowledge Level', value: audience.knowledgeLevel },
        { label: 'Primary Goal', value: audience.primaryGoal }
      ]
    },
    {
      title: 'Content Preferences',
      description: 'Learning and engagement preferences.',
      icon: (className) => <AcademicCapIcon className={className} />,
      fields: [
        { label: 'Learning Style', value: audience.preferredLearningStyle },
        { label: 'Platforms', value: audience.topPlatforms },
        { label: 'Formats', value: audience.preferredFormats },
        { label: 'Tone', value: audience.contentTone }
      ]
    },
    {
      title: 'Behavioral Insights',
      description: 'Motivations and core values.',
      icon: (className) => <HeartIcon className={className} />,
      fields: [
        { label: 'Motivations', value: audience.keyMotivations },
        { label: 'Pain Points', value: audience.painPoints },
        { label: 'Core Values', value: audience.coreValues }
      ]
    }
  ];

  const renderValue = (value) => {
    if (!value) return null;
    if (Array.isArray(value)) {
      return (
        <div className="flex flex-wrap gap-2">
          {value.map((item, i) => (
            <span 
              key={i} 
              className={`
                px-2.5 py-1 rounded-md text-xs font-medium transition-colors duration-200
                ${isDarkMode 
                  ? 'bg-[#1a1a1a]/50 text-slate-300 hover:bg-[#1a1a1a] border border-[#333333]' 
                  : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
                }
              `}
            >
              {item}
            </span>
          ))}
        </div>
      );
    }
    return (
      <p className={`
        text-sm whitespace-pre-wrap break-words leading-relaxed
        ${isDarkMode ? 'text-slate-300' : 'text-slate-600'}
      `}>
        {value}
      </p>
    );
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`fixed inset-0 backdrop-blur-sm flex items-center justify-center z-50 p-4
          ${isDarkMode ? 'bg-black/60' : 'bg-white/60'}`}
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          transition={{ type: "spring", duration: 0.3 }}
          className={`
            relative w-full max-w-3xl max-h-[85vh] rounded-xl shadow-2xl
            border popup-scrollbar overflow-y-auto
            ${isDarkMode 
              ? 'bg-[#121212] border-[#333333] shadow-black/20' 
              : 'bg-white border-slate-200 shadow-slate-200/20'
            }
          `}
          onClick={e => e.stopPropagation()}
        >
          {/* Close Button - Absolute Position */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClose}
            className={`
              absolute top-3 right-3 z-20
              rounded-lg p-1.5 sm:p-2 flex-shrink-0
              transition-colors duration-200
              ${isDarkMode 
                ? 'text-slate-400 hover:bg-[#1a1a1a]' 
                : 'text-slate-500 hover:bg-slate-100'
              }
            `}
          >
            <XMarkIcon className="w-4 h-4 sm:w-5 sm:h-5" />
          </motion.button>

          {/* Header */}
          <div className={`
            sticky top-0 z-10 flex flex-col sm:flex-row sm:items-center
            p-4 sm:p-6 pr-12 border-b gap-3 sm:gap-4
            backdrop-blur-xl
            ${isDarkMode 
              ? 'bg-[#121212]/95 border-[#333333]' 
              : 'bg-white/95 border-slate-200'
            }
          `}>
            <div className="flex items-center gap-3 min-w-0">
              {/* Icon */}
              <motion.div 
                whileHover={{ scale: 1.05 }}
                className={`
                  relative h-12 w-12 sm:h-14 sm:w-14 rounded-lg overflow-hidden border
                  flex-shrink-0 flex items-center justify-center
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] border-[#333333]' 
                    : 'bg-slate-100 border-slate-200'
                  }
                `}
              >
                <UserGroupIcon className={`h-7 w-7 sm:h-8 sm:w-8 ${isDarkMode ? 'text-slate-400' : 'text-slate-400'}`} />
              </motion.div>
              <div className="min-w-0 flex-1">
                <h2 className={`
                  text-lg sm:text-xl font-semibold tracking-tight truncate
                  ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
                `}>
                  {audience.targetAudienceName}
                </h2>
                <p className={`
                  text-sm font-medium truncate
                  ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
                `}>
                  Target Audience Profile
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleEdit}
                className={`
                  px-3 sm:px-4 py-1.5 sm:py-2 rounded-lg text-sm font-medium
                  transition-colors duration-200 flex items-center gap-1.5 sm:gap-2 flex-shrink-0
                  ${isDarkMode 
                    ? 'bg-[#1a1a1a] text-slate-200 hover:bg-[#252525] border border-[#333333]' 
                    : 'bg-slate-100 text-slate-700 hover:bg-slate-200'
                  }
                `}
              >
                <PencilSquareIcon className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                <span className="hidden sm:inline">Edit Profile</span>
                <span className="sm:hidden">Edit</span>
              </motion.button>
            </div>
          </div>

          {/* Content */}
          <div className="p-6 space-y-8">
            {fieldGroups.map((group, index) => (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                key={index}
                className="space-y-4"
              >
                <div className="flex items-center gap-3">
                  <div className={`
                    p-2 rounded-lg
                    ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-slate-100'}
                  `}>
                    {group.icon(`w-5 h-5 ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}`)}
                  </div>
                  <div>
                    <h3 className={`
                      text-sm font-semibold tracking-tight
                      ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}
                    `}>
                      {group.title}
                    </h3>
                    <p className={`
                      text-xs
                      ${isDarkMode ? 'text-slate-500' : 'text-slate-500'}
                    `}>
                      {group.description}
                    </p>
                  </div>
                </div>
                <div className={`
                  grid grid-cols-1 md:grid-cols-2 gap-6 p-4 rounded-lg border
                  ${isDarkMode 
                    ? 'bg-[#151515]/50 border-[#333333]' 
                    : 'bg-slate-50 border-slate-200'
                  }
                `}>
                  {group.fields.map((field, fieldIndex) => (
                    field.value && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: fieldIndex * 0.05 }}
                        key={fieldIndex}
                        className="space-y-2"
                      >
                        <label className={`
                          text-xs font-medium
                          ${isDarkMode ? 'text-slate-400' : 'text-slate-500'}
                        `}>
                          {field.label}
                        </label>
                        <div className="min-h-[1.5rem]">
                          {renderValue(field.value)}
                        </div>
                      </motion.div>
                    )
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default TargetAudienceOverviewPopup;
