import React, { useState, useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { PaperAirplaneIcon, ClipboardDocumentIcon, ArrowLeftIcon, ClockIcon, XMarkIcon, ChatBubbleLeftRightIcon, TrashIcon, CheckIcon } from '@heroicons/react/24/outline';
import { ClipboardDocumentIcon as ClipboardDocumentIconSolid } from '@heroicons/react/24/solid';
import { motion, AnimatePresence } from 'framer-motion';
import PersonaDropdown from '../personas/PersonaDropdown';
import TargetAudienceDropdown from '../targetAudiences/TargetAudienceDropdown';
import { ToneDropdown } from './PostComments';
import { useNavigate } from 'react-router-dom';
import { usePostComments } from '../../context/PostCommentsContext';
import StyledActionButton from '../StyledActionButton';
import { useAuth } from '../../context/AuthContext';
import '../customScrollbar.css';
import ClearIcon from '../icons/ClearIcon';
import ChatInterfaceCommentHistory from './ChatInterfaceCommentHistory';
import toast from 'react-hot-toast';

const TopBar = ({ isDarkMode, onClose, onHistoryClick }) => (
  <div className={`${isDarkMode ? 'bg-[#151515]/80 border-[#333333]' : 'bg-white/80 border-gray-100'} 
    backdrop-blur-xl h-10 flex-shrink-0 flex items-center justify-between px-3 w-full border-b sticky top-0 z-50`}>
    <div className="flex items-center space-x-1.5">
      <ChatBubbleLeftRightIcon className={`h-3.5 w-3.5 ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`} />
      <h2 className={`text-xs font-medium tracking-tight ${isDarkMode ? 'text-slate-100' : 'text-gray-900'}`}>
        Comments Generator
      </h2>
    </div>
    <div className="flex items-center space-x-1.5">
      <button
        onClick={onHistoryClick}
        className={`p-1.5 rounded-lg transition-all duration-200 flex items-center gap-1.5 ${
          isDarkMode 
            ? 'hover:bg-white/5 active:bg-white/10 text-slate-300 hover:text-slate-100' 
            : 'hover:bg-gray-100 active:bg-gray-200 text-gray-600 hover:text-gray-900'
        }`}
      >
        <ClockIcon className="h-3.5 w-3.5" />
        <span className="text-xs">
          <span className="hidden sm:inline">Comment </span>
          History
        </span>
      </button>
      <button
        onClick={onClose}
        className={`p-1.5 rounded-lg transition-all duration-200 ${
          isDarkMode 
            ? 'hover:bg-white/5 active:bg-white/10' 
            : 'hover:bg-gray-100 active:bg-gray-200'
        }`}
      >
        <ArrowLeftIcon className={`h-3.5 w-3.5 ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`} />
      </button>
    </div>
  </div>
);

const styles = `
  @media (min-width: 640px) {
    .icon-text {
      display: inline;
    }
  }
  @media (max-width: 639px) {
    .icon-text {
      display: none;
    }
  }
  .action-buttons {
    transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
    backdrop-filter: blur(8px);
  }
  .action-buttons.hidden {
    opacity: 0;
    transform: translateY(10px) scale(0.98);
    pointer-events: none;
  }
  .apple-input {
    transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  }
  .apple-input:focus {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  }
  .generated-comment {
    transition: all 0.2s cubic-bezier(0.16, 1, 0.3, 1);
  }
  .generated-comment:hover {
    transform: translateY(-1px);
  }
`;

const ChatInterfacePostComments = ({ onClose }) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [hoveredCommentId, setHoveredCommentId] = useState(null);
  const [commentGroups, setCommentGroups] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [isHistoryLoaded, setIsHistoryLoaded] = useState(false);
  const [showReplyInput, setShowReplyInput] = useState(false);
  
  const {
    postContent,
    setPostContent,
    selectedTone,
    setSelectedTone,
    generatedComments,
    setGeneratedComments,
    isLoading,
    error,
    setError,
    handleGenerate,
    copiedComments,
    copyToClipboard,
    selectedPersona,
    setSelectedPersona,
    selectedTargetAudience,
    setSelectedTargetAudience,
    isLoadingAvatar,
    saveToHistory,
    commentToReply,
    setCommentToReply,
    fetchCommentHistory,
    setCommentHistory
  } = usePostComments();
  const { user, isAuthenticated, loading: authLoading } = useAuth();

  useEffect(() => {
    if (!authLoading) {
      if (!isAuthenticated) {
        console.log('User not authenticated');
      } else {
        console.log('User authenticated:', user);
      }
    }
  }, [authLoading, isAuthenticated, user]);

  const handleGenerateAndSave = async () => {
    try {
      console.log('=== SENDING TO BACKEND ===');
      console.log('Post Content:', postContent);
      console.log('Comment to Reply:', commentToReply);
      console.log('Selected Tone:', selectedTone);
      console.log('Selected Persona:', selectedPersona);
      console.log('Selected Target Audience:', selectedTargetAudience);
      console.log('========================');

      const comments = await handleGenerate(commentToReply);
      
      console.log('=== RECEIVED FROM BACKEND ===');
      console.log('Generated Comments:', comments);
      console.log('===========================');

      if (comments && comments.length > 0) {
        setCommentGroups(prevGroups => [{
          id: Date.now(),
          comments,
          timestamp: new Date(),
          tone: selectedTone,
          persona: selectedPersona,
          targetAudience: selectedTargetAudience
        }, ...prevGroups]);

        for (const comment of comments) {
          await saveToHistory({
            postContent,
            commentToReply,
            comment,
            tone: selectedTone,
            persona: selectedPersona,
            targetAudience: selectedTargetAudience
          });
        }
      }
    } catch (error) {
      console.error('Error generating and saving comments:', error);
      setError('Failed to generate and save comments');
    }
  };

  const handleHistoryClick = async () => {
    console.log('Opening history popup...');
    const token = localStorage.getItem('accessToken');
    
    if (!token) {
      setError('Please log in to view comment history');
      return;
    }
    
    setShowHistory(true);
    setIsHistoryLoaded(true);
    setError(null); // Clear any existing errors
    
    try {
      console.log('Fetching comment history...');
      const result = await fetchCommentHistory(1, 10);
      console.log('History fetch result:', {
        success: !!result,
        commentsCount: result?.comments?.length || 0,
        pagination: result?.pagination
      });
    } catch (error) {
      console.error('Error fetching history:', error);
      setError('Failed to load comment history');
    }
  };

  const handleHistoryClose = () => {
    console.log('Closing history popup...');
    setShowHistory(false);
    setIsHistoryLoaded(false);
    setCommentHistory([]); // Clear the history when closing
    setError(null); // Clear any errors
  };

  return (
    <div className={`flex flex-col h-full w-full ${isDarkMode ? 'bg-[#121212]' : 'bg-gray-50'}`}>
      <style>{styles}</style>
      <TopBar 
        isDarkMode={isDarkMode} 
        onClose={onClose} 
        onHistoryClick={handleHistoryClick}
      />
      
      <div className="flex-1 overflow-y-auto px-2 sm:px-4 py-2 sm:py-4 popup-scrollbar">
        <div className="w-full max-w-[calc(100vw-16px)] sm:max-w-2xl mx-auto space-y-4 sm:space-y-6">
          {/* Input Section */}
          <div className={`${isDarkMode ? 'bg-[#1E1E1E] border-[#333333]' : 'bg-white border-gray-200'} 
            rounded-xl p-3 sm:p-5 relative z-30 shadow-sm border transition-shadow duration-200 hover:shadow-md`}>
            {/* Persona and Audience Selection */}
            <div className="flex items-center justify-start gap-1 sm:gap-2 mb-4 sm:mb-5 relative z-[100]">
              <PersonaDropdown
                onSelectPersona={setSelectedPersona}
                selectedPersona={selectedPersona}
                isLoadingAvatar={isLoadingAvatar}
                compact={true}
              />
              <TargetAudienceDropdown
                onSelectAudience={setSelectedTargetAudience}
                selectedAudience={selectedTargetAudience}
                compact={true}
              />
            </div>

            {/* Content Area */}
            <div className="space-y-3 sm:space-y-4">
              {/* Post textarea */}
              <div className="relative">
                <label className={`block text-xs font-medium mb-1.5 sm:mb-2 
                  ${isDarkMode ? 'text-slate-300' : 'text-gray-700'}`}>
                  Post you want to comment on
                </label>
                <div className="relative">
                  <textarea
                    value={postContent}
                    onChange={(e) => setPostContent(e.target.value)}
                    className={`w-full p-2.5 sm:p-3 text-sm rounded-lg border popup-scrollbar
                      transition-all duration-200 apple-input
                      ${isDarkMode 
                        ? 'bg-[#252525] border-[#404040] text-slate-100 placeholder-slate-500 focus:border-blue-500/50 focus:bg-[#2A2A2A]' 
                        : 'bg-gray-50 border-gray-200 text-gray-900 placeholder-gray-400 focus:border-blue-500/30 focus:bg-white'
                      }
                      focus:outline-none focus:ring-2 focus:ring-offset-2
                      ${isDarkMode 
                        ? 'focus:ring-blue-500/20 focus:ring-offset-[#1E1E1E]' 
                        : 'focus:ring-blue-500/20 focus:ring-offset-white'
                      }
                    `}
                    placeholder="Enter post content..."
                    rows={3}
                  />
                </div>
              </div>

              {/* Reply Section */}
              <div className="relative">
                <button
                  onClick={() => setShowReplyInput(!showReplyInput)}
                  className={`w-full text-left px-1 py-2 text-sm
                    transition-colors duration-200 outline-none
                    ${isDarkMode 
                      ? 'text-slate-400 hover:text-slate-300' 
                      : 'text-gray-500 hover:text-gray-700'
                    }
                  `}
                >
                  <div className="flex items-center gap-2">
                    <svg
                      className={`w-3.5 h-3.5 transition-transform duration-200 ${showReplyInput ? 'transform rotate-180' : ''}`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                    <span className="text-xs font-medium">
                      Reply to a comment (Optional)
                    </span>
                  </div>
                </button>

                <div className={`transition-all duration-200 overflow-hidden ${showReplyInput ? 'max-h-[500px] opacity-100 mt-2' : 'max-h-0 opacity-0'}`}>
                  <div className={`relative border rounded-lg ${
                    isDarkMode ? 'border-[#404040] bg-[#252525]' : 'border-gray-200 bg-gray-50'
                  }`}>
                    <textarea
                      value={commentToReply}
                      onChange={(e) => setCommentToReply(e.target.value)}
                      className={`w-full p-2.5 sm:p-3 text-sm rounded-lg popup-scrollbar
                        transition-all duration-200 apple-input
                        ${isDarkMode 
                          ? 'bg-[#252525] text-slate-100 placeholder-slate-500 focus:bg-[#2A2A2A]' 
                          : 'bg-gray-50 text-gray-900 placeholder-gray-400 focus:bg-white'
                        }
                        focus:outline-none
                      `}
                      placeholder="Enter comment to reply to..."
                      rows={2}
                    />
                  </div>
                </div>
              </div>

              {error && (
                <div className={`rounded-lg p-2.5 sm:p-3 text-sm ${
                  isDarkMode ? 'bg-red-500/10 text-red-400' : 'bg-red-50 text-red-600'
                }`}>
                  {error}
                </div>
              )}

              {/* Tone Selection and Actions Row */}
              <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-3 sm:gap-4 pt-2 relative z-[90]">
                {/* Tone Selection */}
                <div className="flex-1">
                  <ToneDropdown
                    selectedTone={selectedTone}
                    onToneSelect={setSelectedTone}
                    isDarkMode={isDarkMode}
                    className={`
                      w-full px-2.5 sm:px-3 py-2 text-sm rounded-lg
                      transition-all duration-200
                      ${isDarkMode 
                        ? 'bg-[#252525] border-[#404040] text-slate-100' 
                        : 'bg-gray-50 border-gray-200 text-gray-900'
                      } border
                      focus:outline-none focus:ring-2 focus:ring-offset-2
                      ${isDarkMode 
                        ? 'focus:ring-blue-500/20 focus:ring-offset-[#1E1E1E]' 
                        : 'focus:ring-blue-500/20 focus:ring-offset-white'
                      }
                    `}
                  />
                </div>

                {/* Actions */}
                <div className="flex items-center gap-2 justify-end">
                  <StyledActionButton
                    onClick={handleGenerateAndSave}
                    icon={PaperAirplaneIcon}
                    text="Generate"
                    loadingText="Generating..."
                    isDarkMode={isDarkMode}
                    disabled={isLoading || !postContent.trim()}
                    isLoading={isLoading}
                  />
                  <button
                    onClick={() => {
                      setPostContent('');
                      setCommentToReply('');
                    }}
                    className={`
                      p-2 rounded-lg
                      transition-all duration-200
                      ${isDarkMode 
                        ? 'text-slate-400 hover:text-slate-200 hover:bg-white/5 active:bg-white/10' 
                        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200'
                      }
                    `}
                    title="Clear inputs"
                  >
                    <ClearIcon className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Generated Comments Section */}
          <div className="rounded-lg relative z-10">
            {commentGroups.length > 0 ? (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="space-y-8 mt-3 popup-scrollbar"
              >
                {commentGroups.map((group, groupIndex) => (
                  <div key={group.id} className={`relative ${groupIndex !== 0 ? 'mt-12' : ''}`}>
                    <div className={`flex items-center justify-between mb-3 ${
                      isDarkMode ? 'text-slate-400' : 'text-gray-500'
                    }`}>
                      <div className="flex items-center space-x-2">
                        <ClockIcon className="w-4 h-4" />
                        <span className="text-xs">
                          {new Date(group.timestamp).toLocaleTimeString()}
                        </span>
                        <span className="text-xs px-2 py-1 rounded-full bg-opacity-10 border border-opacity-20 capitalize ml-2
                          ${isDarkMode ? 'bg-blue-500 border-blue-400' : 'bg-blue-100 border-blue-200'}">
                          {group.tone}
                        </span>
                      </div>
                      <button
                        onClick={() => setCommentGroups(prevGroups => 
                          prevGroups.filter(g => g.id !== group.id)
                        )}
                        className={`p-1.5 rounded-lg transition-colors duration-200 ${
                          isDarkMode 
                            ? 'hover:bg-white/5 text-slate-400 hover:text-slate-300' 
                            : 'hover:bg-gray-100 text-gray-400 hover:text-gray-500'
                        }`}
                        title="Remove this group"
                      >
                        <TrashIcon className="w-4 h-4" />
                      </button>
                    </div>
                    <div className="space-y-4">
                      {group.comments.map((comment, index) => (
                        <motion.div
                          key={`${group.id}-${index}`}
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: index * 0.1 }}
                          className={`p-4 rounded-xl border ${
                            isDarkMode 
                              ? 'bg-[#1E1E1E] border-[#333333] hover:bg-[#252525]' 
                              : 'bg-white border-gray-200 hover:bg-gray-50'
                          } relative group mb-8 transition-all duration-200 hover:shadow-md`}
                          onMouseEnter={() => setHoveredCommentId(`${group.id}-${index}`)}
                          onMouseLeave={() => setHoveredCommentId(null)}
                        >
                          <div className={`text-sm whitespace-pre-line ${isDarkMode ? 'text-slate-100' : 'text-gray-700'}`}>
                            {comment.split('\n').map((paragraph, i) => (
                              <p key={i} className={i !== 0 ? 'mt-3' : ''}>
                                {paragraph}
                              </p>
                            ))}
                          </div>
                          <div className="absolute left-0 bottom-[-24px] right-0 flex justify-start">
                            <div className="max-w-full overflow-x-auto ml-2">
                                <button
                                  onClick={() => {
                                    copyToClipboard(comment, `${group.id}-${index}`);
                                    toast.success('Comment copied!', {
                                      position: 'top-center',
                                      style: isDarkMode 
                                        ? { 
                                            background: '#1e1e1e', 
                                            color: '#fff',
                                            border: '1px solid #333333'
                                          }
                                        : {
                                            background: '#fff',
                                            color: '#000',
                                            border: '1px solid #e5e7eb'
                                          }
                                    });
                                  }}
                                  className={`action-buttons ${hoveredCommentId === `${group.id}-${index}` ? '' : 'hidden'} 
                                    inline-flex items-center rounded-lg border shadow-lg
                                    transition-all duration-200 gap-1.5 py-1.5 px-3
                                    ${isDarkMode 
                                      ? copiedComments[`${group.id}-${index}`]
                                        ? 'bg-green-500/20 text-green-400 border-green-500/30'
                                        : 'bg-[#252525] border-[#404040] text-slate-300 hover:text-white hover:bg-[#2A2A2A] shadow-black/20' 
                                      : copiedComments[`${group.id}-${index}`]
                                        ? 'bg-green-100 text-green-600 border-green-200'
                                        : 'bg-white border-gray-200 text-gray-600 hover:text-gray-900 hover:bg-gray-50 shadow-gray-100'
                                    }`}
                                  title={copiedComments[`${group.id}-${index}`] ? "Copied!" : "Copy to clipboard"}
                                >
                                  <motion.div
                                    initial={false}
                                    animate={{
                                      scale: copiedComments[`${group.id}-${index}`] ? [1, 1.2, 1] : 1,
                                    }}
                                    transition={{ duration: 0.3 }}
                                    className="flex items-center gap-1.5"
                                  >
                                    {copiedComments[`${group.id}-${index}`] ? (
                                      <>
                                        <CheckIcon className="h-3.5 w-3.5" />
                                        <span className="text-xs font-medium">Copied</span>
                                      </>
                                    ) : (
                                      <>
                                        <ClipboardDocumentIcon className="h-3.5 w-3.5" />
                                        <span className="text-xs font-medium">Copy</span>
                                      </>
                                    )}
                                  </motion.div>
                                </button>
                            </div>
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  </div>
                ))}
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={`flex flex-col items-center justify-center h-32 rounded-xl border border-dashed mt-3
                  ${isDarkMode 
                    ? 'border-[#333333] bg-[#1E1E1E] text-slate-400' 
                    : 'border-gray-200 bg-gray-50 text-gray-500'
                  }`}
              >
                <p className="text-sm">
                  Add content and press Generate
                </p>
              </motion.div>
            )}
          </div>
        </div>
      </div>

      {/* Comment History Popup */}
      <AnimatePresence>
        {showHistory && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-[100] bg-black/50 backdrop-blur-sm"
          >
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: "100%" }}
              transition={{ type: "spring", damping: 25, stiffness: 200 }}
              className={`absolute inset-0 w-full h-full flex flex-col ${isDarkMode ? 'bg-[#121212]' : 'bg-gray-50'} overflow-hidden shadow-xl`}
            >
              <ChatInterfaceCommentHistory onClose={handleHistoryClose} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ChatInterfacePostComments; 