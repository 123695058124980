import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import supabaseAuth, { 
  analyzeTokenExpiration, 
  setupProactiveRefresh 
} from '../services/supabaseAuth';
import { toast } from 'react-hot-toast';
import api from '../api';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import AnimatedLoader from './common/AnimatedLoader';
import { useTheme } from '../context/ThemeContext';

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useAuth();
  const { isDarkMode } = useTheme();
  const [showTimeout, setShowTimeout] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check for magic link errors in URL hash
    const params = new URLSearchParams(location.search);
    const isMagicLink = params.get('type') === 'magiclink';
    
    if (isMagicLink && location.hash) {
      const hashParams = new URLSearchParams(location.hash.substring(1));
      const errorCode = hashParams.get('error_code');
      const errorDescription = hashParams.get('error_description');
      
      if (errorCode === 'otp_expired') {
        setError({
          title: 'Magic Link Expired',
          message: 'Your login link has expired. Please request a new one.',
          code: errorCode
        });
        return;
      }
      
      if (errorCode) {
        setError({
          title: 'Authentication Error',
          message: errorDescription?.replace(/\+/g, ' ') || 'Failed to authenticate. Please try again.',
          code: errorCode
        });
        return;
      }
    }

    const timeoutId = setTimeout(() => {
      setShowTimeout(true);
    }, 15000);

    return () => clearTimeout(timeoutId);
  }, [location]);

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        console.log('Handling auth callback...', {
          search: location.search,
          hash: location.hash,
          pathname: location.pathname
        });
        
        // Get the current session
        const { data: { session }, error: sessionError } = await supabaseAuth.auth.getSession();
        console.log('Current session:', session);
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw sessionError;
        }

        if (!session) {
          console.error('No session found');
          throw new Error('No session found');
        }

        // Get the current user
        const { data: { user }, error: userError } = await supabaseAuth.auth.getUser();
        console.log('Current user:', user);
        
        if (userError) {
          console.error('User error:', userError);
          throw userError;
        }

        // Check if this is a magic link authentication
        const params = new URLSearchParams(location.search);
        const isMagicLink = params.get('type') === 'magiclink';

        console.log('Auth type:', {
          isMagicLink,
          type: params.get('type'),
          fullUrl: window.location.href
        });

        if (isMagicLink) {
          console.log('Magic link authentication detected');
          try {
            // Log raw session data with full structure
            console.log('Complete Supabase session:', JSON.stringify(session, null, 2));
            
            // The session object from Supabase has a nested structure
            const sessionToStore = {
              access_token: session?.access_token,
              refresh_token: session?.refresh_token,
              expires_at: session?.expires_at,
              expires_in: session?.expires_in,
              token_type: session?.token_type || 'bearer',
              user: session?.user,
              // If the data is nested inside a session property
              ...(session?.session && {
                access_token: session.session.access_token,
                refresh_token: session.session.refresh_token,
                expires_at: session.session.expires_at,
                expires_in: session.session.expires_in,
                token_type: session.session.token_type || 'bearer',
                user: session.session.user
              })
            };

            // Calculate expires_at if not present but expires_in is available
            if (!sessionToStore.expires_at && sessionToStore.expires_in) {
              sessionToStore.expires_at = Math.floor(Date.now() / 1000) + sessionToStore.expires_in;
            }

            // Store the processed session
            localStorage.setItem('supabase_session', JSON.stringify(sessionToStore));

            // Verify the stored data
            const storedSession = localStorage.getItem('supabase_session');
            const parsedStoredSession = JSON.parse(storedSession);
            console.log('Stored session verification:', {
              raw: storedSession,
              parsed: parsedStoredSession,
              expiresAt: parsedStoredSession.expires_at 
                ? new Date(parsedStoredSession.expires_at * 1000).toLocaleString()
                : 'Not found',
              expiresIn: parsedStoredSession.expires_in,
              hasAccessToken: !!parsedStoredSession.access_token,
              hasRefreshToken: !!parsedStoredSession.refresh_token
            });

            // Set up proactive session refresh
            setupProactiveRefresh();

            // Set up Supabase auth state change listener
            supabaseAuth.auth.onAuthStateChange(async (event, session) => {
              console.log('Auth state changed:', event);
              if (event === 'SIGNED_OUT') {
                // Clear both JWT and Supabase tokens
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('supabase_session');
                setUser(null);
                navigate('/login');
              }
            });

            // Sync with MongoDB
            console.log('Syncing with MongoDB...');
            const response = await api.post('/api/auth/sync-magic-link', {
              supabaseUid: user.id,
              email: user.email
            });

            console.log('MongoDB sync response:', response.data);

            // Store the JWT tokens
            const { accessToken, refreshToken } = response.data;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);

            // Set the Authorization header for all future requests
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            // Set the user in context
            setUser(response.data.user);

            toast.success('Successfully signed in!');
            navigate('/');
            return;
          } catch (error) {
            console.error('Magic link sync error:', error);
            toast.error('Failed to complete sign in. Please try again.');
            navigate('/login');
            return;
          }
        }

        // Handle email verification
        if (user?.email_confirmed_at || user?.confirmed_at) {
          console.log('Email confirmed for user:', user.email);

          try {
            // Update MongoDB with verification status
            const response = await api.post('/api/auth/verify-email', {
              email: user.email,
              supabaseUid: user.id
            });

            console.log('MongoDB update response:', response.data);

            if (response.data.user?.emailVerified) {
              // Show success toast
              toast.success('Email verified successfully! Logging you in...');
              
              try {
                // Get tokens from the response
                const { accessToken, refreshToken } = response.data;
                
                // Store tokens
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);
                
                // Update axios headers
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

                // Store Supabase session
                localStorage.setItem('supabase_session', JSON.stringify(session));

                // Set up proactive refresh
                setupProactiveRefresh();

                // Update user context
                setUser(response.data.user);

                // Navigate to home page
                window.location.replace('/');
                return;
              } catch (loginError) {
                console.error('Auto-login error:', loginError);
                throw new Error('Failed to complete automatic login');
              }
            } else {
              throw new Error('Verification status not updated in MongoDB');
            }
          } catch (error) {
            console.error('Verification error:', error);
            toast.error(error.message || 'Verification failed');
            
            // Navigate to login page with error
            window.location.replace('/login?error=true&message=' + encodeURIComponent(error.message || 'Verification failed'));
            return;
          }
        } else {
          console.log('Email not confirmed yet');
          // Show error toast and wait for it to be added to the DOM
          await new Promise(resolve => {
            toast.error('Email not verified yet. Please check your email and click the verification link.', {
              duration: 3000,
              onClose: resolve
            });
          });
          
          // Add a small delay to ensure toast is visible
          await new Promise(resolve => setTimeout(resolve, 1000));
          
          window.location.replace('/verification?email=' + encodeURIComponent(user?.email || ''));
        }
      } catch (error) {
        console.error('Verification error:', error);
        // Show error toast and wait for it to be added to the DOM
        await new Promise(resolve => {
          toast.error(error.message || 'Verification failed', {
            duration: 3000,
            onClose: resolve
          });
        });
        
        // Add a small delay to ensure toast is visible
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        window.location.replace('/login?error=true&message=' + encodeURIComponent(error.message || 'Verification failed'));
      }
    };

    handleAuthCallback();
  }, [navigate, setUser, location.search]);

  // If there's an error, show error UI instead of verification status
  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background">
        <div className="text-center p-8 rounded-lg w-[380px] bg-card border border-border">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8 mx-auto text-red-500 dark:text-red-400">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
          </svg>
          <h3 className="mt-5 text-lg font-semibold text-red-500 dark:text-red-400">
            {error.title}
          </h3>
          <p className="mt-2.5 text-sm text-muted-foreground">
            {error.message}
          </p>
          <div className="mt-6">
            <Link 
              to="/login" 
              className="inline-flex items-center justify-center rounded-md text-sm font-medium h-10 px-8 py-2 bg-red-500 hover:bg-red-600 text-white transition-colors"
            >
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-background">
      <div className="text-center p-8 rounded-lg w-[380px] bg-card border border-border">
        <AnimatedLoader size={40} color="currentColor" className="text-primary" />
        <p className="mt-6 font-medium text-card-foreground">
          Verifying your credentials...
        </p>
        <p className="mt-2 text-sm text-muted-foreground">
          Please wait while we complete the authentication process.
        </p>
        
        {showTimeout && !error && (
          <div className="mt-8 animate-in fade-in slide-in-from-bottom-4 duration-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-8 h-8 mx-auto text-amber-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            <h3 className="mt-5 text-lg font-semibold text-amber-500">
              Taking longer than expected
            </h3>
            <div className="mt-4 space-y-2 text-sm text-muted-foreground">
              <p>Clear browser cache</p>
              <p>Restart browser</p>
              <p>Try signing in again</p>
            </div>
            <div className="mt-6">
              <Link
                to="/login"
                className="inline-flex h-9 items-center justify-center rounded-md bg-amber-500 px-8 text-sm font-medium text-white hover:bg-amber-600 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
              >
                Return to Login
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthCallback; 