import React, { useState, useRef, useEffect, useCallback } from 'react';
import { format, isToday, isTomorrow } from 'date-fns';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { 
  CalendarIcon, 
  PencilIcon, 
  CheckIcon, 
  XMarkIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import DateTimePickerDesign from './DateTimePickerDesign';

const MAX_TEXT_LENGTH = 500;
const MAX_DESCRIPTION_LENGTH = 1000;

const TaskItem = ({ 
  task, 
  onToggle, 
  onDelete, 
  onDateChange,
  onUpdate,
  isDragging,
  onDeleteClick
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(task.text);
  const [editedDescription, setEditedDescription] = useState(task.description || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const datePickerRef = useRef(null);
  const calendarButtonRef = useRef(null);
  const containerRef = useRef(null);
  const editInputRef = useRef(null);
  const previousText = useRef(task.text);
  const previousDescription = useRef(task.description || '');

  useEffect(() => {
    // Update local state when task prop changes
    if (task.text !== previousText.current || task.description !== previousDescription.current) {
      setEditedText(task.text);
      setEditedDescription(task.description || '');
      previousText.current = task.text;
      previousDescription.current = task.description;
    }
  }, [task]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && 
          !datePickerRef.current.contains(event.target) &&
          !calendarButtonRef.current?.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isEditing && editInputRef.current) {
      editInputRef.current.focus();
      // Place cursor at the end
      const length = editInputRef.current.value.length;
      editInputRef.current.setSelectionRange(length, length);
    }
  }, [isEditing]);

  const formatDate = useCallback((date) => {
    if (!date) return '';
    try {
      const dateObj = new Date(date);
      if (isNaN(dateObj.getTime())) return '';
      
      const time = format(dateObj, 'h:mm a');
      
      if (isToday(dateObj)) {
        return `today ${time}`;
      }
      if (isTomorrow(dateObj)) {
        return `tmr ${time}`;
      }
      return format(dateObj, 'MMM d') + ` ${time}`;
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  }, []);

  const handleDateSelect = useCallback(async (type, dateTime) => {
    if (!dateTime || isNaN(dateTime.getTime())) {
      return;
    }

    try {
      await onDateChange(task._id, {
        [type === 'start' ? 'startDate' : 'endDate']: dateTime.toISOString()
      });
      setShowDatePicker(false);
    } catch (error) {
      console.error('Error updating date:', error);
    }
  }, [task._id, onDateChange]);

  const validateInput = useCallback(() => {
    if (!editedText.trim()) {
      return false;
    }
    if (editedText.length > MAX_TEXT_LENGTH) {
      return false;
    }
    if (editedDescription.length > MAX_DESCRIPTION_LENGTH) {
      return false;
    }
    return true;
  }, [editedText, editedDescription]);

  const handleSave = async () => {
    if (isSubmitting || !validateInput()) return;
    
    try {
      setIsSubmitting(true);
      await onUpdate(task._id, {
        text: editedText.trim(),
        description: editedDescription.trim()
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating task:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setEditedText(task.text);
    setEditedDescription(task.description || '');
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSave();
    } else if (e.key === 'Escape') {
      handleCancel();
    }
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText.length <= MAX_TEXT_LENGTH) {
      setEditedText(newText);
    }
  };

  const handleDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription.length <= MAX_DESCRIPTION_LENGTH) {
      setEditedDescription(newDescription);
    }
  };

  const isDark = document.documentElement.classList.contains('dark');

  return (
    <div 
      ref={containerRef} 
      className={`
        group relative flex flex-col gap-1.5 py-2.5 px-3 
        hover:bg-gray-50/30 dark:hover:bg-zinc-800/30
        rounded-lg transition-all duration-200
        ${isEditing ? 'bg-gray-50/10 dark:bg-zinc-800/10' : ''}
        ${isDragging ? 'shadow-lg bg-gray-50/50 dark:bg-zinc-800/50' : ''}
      `}
      draggable
    >
      <div className="flex items-start gap-3">
        <button
          onClick={() => onToggle(task._id)}
          className={`
            flex-none mt-0.5 w-4 h-4 rounded-md border transition-colors duration-200
            ${task.completed 
              ? isDark 
                ? 'bg-blue-500/90 border-blue-500/90 hover:bg-blue-500' 
                : 'bg-blue-500/90 border-blue-500/90 hover:bg-blue-500'
              : isDark
                ? 'border-zinc-700 hover:border-zinc-600'
                : 'border-gray-200 hover:border-gray-300'
            }
          `}
        >
          {task.completed && (
            <CheckIcon className="w-3 h-3 text-white" />
          )}
        </button>
        
        <div className="flex-1 min-w-0">
          {isEditing ? (
            <div className="space-y-2">
              <input
                ref={editInputRef}
                type="text"
                value={editedText}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                className={`
                  w-full px-2.5 py-1 bg-transparent rounded-md
                  border border-gray-100 dark:border-zinc-800/50
                  focus:border-gray-200 dark:focus:border-zinc-800
                  focus:ring-0 outline-none
                  placeholder:text-gray-300 dark:placeholder:text-gray-600
                  transition-all duration-200 text-sm
                  font-normal
                  ${isDark 
                    ? 'text-gray-200' 
                    : 'text-gray-700'
                  }
                `}
                placeholder="Task title"
              />
              <textarea
                value={editedDescription}
                onChange={handleDescriptionChange}
                onKeyDown={handleKeyDown}
                rows={2}
                className={`
                  w-full px-2.5 py-1 bg-transparent rounded-md
                  border border-gray-100 dark:border-zinc-800/50
                  focus:border-gray-200 dark:focus:border-zinc-800
                  focus:ring-0 outline-none
                  placeholder:text-gray-300 dark:placeholder:text-gray-600
                  transition-all duration-200 resize-none text-xs
                  font-normal leading-relaxed
                  ${isDark 
                    ? 'text-gray-400' 
                    : 'text-gray-600'
                  }
                `}
                placeholder="Add description (optional)"
              />
            </div>
          ) : (
            <div className="space-y-1">
              <div className="flex items-center gap-2">
                <span className={`
                  text-sm font-normal
                  ${task.completed 
                    ? isDark ? 'text-zinc-500 line-through' : 'text-gray-400 line-through'
                    : isDark ? 'text-gray-200' : 'text-gray-700'
                  }
                `}>
                  {task.text}
                </span>
              </div>
              
              {task.description && (
                <p className={`
                  text-xs whitespace-pre-wrap font-normal leading-relaxed
                  ${isDark ? 'text-gray-500' : 'text-gray-500'}
                `}>
                  {task.description}
                </p>
              )}
            </div>
          )}
        </div>

        <div className="flex items-center gap-0.5 ml-2">
          {isEditing ? (
            <>
              <button
                onClick={handleSave}
                className={`p-1.5 rounded-md hover:bg-gray-100/50 dark:hover:bg-zinc-700/30 transition-colors ${
                  isDark ? 'text-green-400 hover:text-green-300' : 'text-green-600 hover:text-green-700'
                }`}
              >
                <CheckIcon className="w-3.5 h-3.5" />
              </button>
              <button
                onClick={handleCancel}
                className={`p-1.5 rounded-md hover:bg-gray-100/50 dark:hover:bg-zinc-700/30 transition-colors ${
                  isDark ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-600'
                }`}
              >
                <XMarkIcon className="w-3.5 h-3.5" />
              </button>
            </>
          ) : (
            <div className="flex items-center gap-0.5">
              <button
                onClick={() => setIsEditing(true)}
                className={`p-1.5 rounded-md hover:bg-gray-100/50 dark:hover:bg-zinc-700/30 transition-colors ${
                  isDark ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-600'
                } opacity-0 group-hover:opacity-100 transition-opacity duration-200`}
              >
                <PencilIcon className="w-3.5 h-3.5" />
              </button>
              <button
                ref={calendarButtonRef}
                onClick={() => setShowDatePicker(!showDatePicker)}
                className={`p-1.5 rounded-md hover:bg-gray-100/50 dark:hover:bg-zinc-700/30 transition-colors ${
                  isDark ? 'text-gray-400 hover:text-gray-300' : 'text-gray-500 hover:text-gray-600'
                } opacity-0 group-hover:opacity-100 transition-opacity duration-200`}
              >
                <CalendarIcon className="w-3.5 h-3.5" />
              </button>
              <button
                onClick={() => onDeleteClick(task)}
                className={`p-1.5 rounded-md hover:bg-gray-100/50 dark:hover:bg-zinc-700/30 transition-colors ${
                  isDark ? 'text-gray-400 hover:text-red-400' : 'text-gray-500 hover:text-red-500'
                } opacity-0 group-hover:opacity-100 transition-opacity duration-200`}
              >
                <TrashIcon className="w-3.5 h-3.5" />
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Date Picker Portal */}
      {showDatePicker && (
        <div 
          ref={datePickerRef}
          className="absolute z-50"
          style={{
            top: '100%',
            right: '0',
            marginTop: '4px'
          }}
        >
          <DateTimePickerDesign
            selectedStartDateTime={task.startDate ? new Date(task.startDate) : new Date()}
            selectedEndDateTime={task.endDate ? new Date(task.endDate) : new Date()}
            onDateTimeSelect={handleDateSelect}
            onClose={() => setShowDatePicker(false)}
          />
        </div>
      )}
    </div>
  );
};

TaskItem.propTypes = {
  task: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    description: PropTypes.string,
    completed: PropTypes.bool.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  onToggle: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isDragging: PropTypes.bool,
  onDeleteClick: PropTypes.func.isRequired,
};

export default TaskItem; 