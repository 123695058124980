import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  ArrowLeftIcon, 
  SparklesIcon,
  ChevronRightIcon,
  ClipboardIcon,
  TrashIcon,
  XMarkIcon,
  CheckIcon
} from '@heroicons/react/24/outline';
import { ClipboardIcon as ClipboardIconSolid } from '@heroicons/react/24/solid';
import { useTheme } from '../context/ThemeContext';
import { 
  fetchVoiceNote,
  generateContent,
  saveGeneratedContent,
  deleteGeneratedContent,
  updateTranscriptionTitle,
  regenerateTranscriptionTitle,
  getSignedUrl,
  deleteVoiceNote,
  updateVoiceNote
} from '../api';
import { format } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import { markdownStyles, formatAIResponse } from './TranscriptionGridItem';
import { createPortal } from 'react-dom';
import GenerateContentPopup from './GenerateContentPopup';
import TranscriptionMenu from './TranscriptionMenu';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { toast } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';

const splitIntoParagraphs = (text) => {
  if (!text) return [];
  
  // Split on multiple line breaks or when there's a significant pause (...)
  const paragraphs = text
    .split(/\n{2,}|\.\.\./g)
    .map(p => p.trim())
    .filter(p => p.length > 0);
    
  // If no natural breaks found, try to split on sentences
  if (paragraphs.length === 1) {
    return text
      .split(/(?<=[.!?])\s+/g)
      .reduce((acc, sentence, i) => {
        if (i % 3 === 0) acc.push(sentence);
        else acc[acc.length - 1] += ' ' + sentence;
        return acc;
      }, []);
  }
  
  return paragraphs;
};

const getItemColors = (index, isDarkMode) => {
  // Array of subtle color combinations for both dark and light modes
  const colors = [
    // Purple theme
    {
      dark: {
        bg: 'bg-purple-500/5',
        border: 'border-purple-500/20',
        hoverBorder: 'hover:border-purple-500/30',
        hoverBg: 'hover:bg-purple-500/10',
        expandedBg: 'bg-purple-500/10',
      },
      light: {
        bg: 'bg-purple-50/50',
        border: 'border-purple-100',
        hoverBorder: 'hover:border-purple-200',
        hoverBg: 'hover:bg-purple-50',
        expandedBg: 'bg-purple-50',
      }
    },
    // Blue theme
    {
      dark: {
        bg: 'bg-blue-500/5',
        border: 'border-blue-500/20',
        hoverBorder: 'hover:border-blue-500/30',
        hoverBg: 'hover:bg-blue-500/10',
        expandedBg: 'bg-blue-500/10',
      },
      light: {
        bg: 'bg-blue-50/50',
        border: 'border-blue-100',
        hoverBorder: 'hover:border-blue-200',
        hoverBg: 'hover:bg-blue-50',
        expandedBg: 'bg-blue-50',
      }
    },
    // Green theme
    {
      dark: {
        bg: 'bg-emerald-500/5',
        border: 'border-emerald-500/20',
        hoverBorder: 'hover:border-emerald-500/30',
        hoverBg: 'hover:bg-emerald-500/10',
        expandedBg: 'bg-emerald-500/10',
      },
      light: {
        bg: 'bg-emerald-50/50',
        border: 'border-emerald-100',
        hoverBorder: 'hover:border-emerald-200',
        hoverBg: 'hover:bg-emerald-50',
        expandedBg: 'bg-emerald-50',
      }
    },
    // Rose theme
    {
      dark: {
        bg: 'bg-rose-500/5',
        border: 'border-rose-500/20',
        hoverBorder: 'hover:border-rose-500/30',
        hoverBg: 'hover:bg-rose-500/10',
        expandedBg: 'bg-rose-500/10',
      },
      light: {
        bg: 'bg-rose-50/50',
        border: 'border-rose-100',
        hoverBorder: 'hover:border-rose-200',
        hoverBg: 'hover:bg-rose-50',
        expandedBg: 'bg-rose-50',
      }
    },
  ];

  const colorIndex = index % colors.length;
  return isDarkMode ? colors[colorIndex].dark : colors[colorIndex].light;
};

const VoiceNotePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [voiceNote, setVoiceNote] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showPromptForm, setShowPromptForm] = useState(false);
  const [generatedContents, setGeneratedContents] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [copiedContentIndex, setCopiedContentIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [promptButtonRef, setPromptButtonRef] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTranscription, setIsEditingTranscription] = useState(false);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedTranscription, setEditedTranscription] = useState('');
  const [buttonRect, setButtonRect] = useState(null);
  const createButtonRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const loadVoiceNote = async () => {
      if (!id) return;
      
      try {
        setIsLoading(true);
        setError(null);
        console.log('Loading voice note:', id); // Debug log
        const data = await fetchVoiceNote(id);
        console.log('Loaded voice note:', data); // Debug log
        
        if (!data) {
          throw new Error('Voice note not found');
        }
        
        setVoiceNote(data);
        setEditedTitle(data.title);
        setEditedTranscription(data.transcription);
        setGeneratedContents(data.generatedContents || []);
      } catch (err) {
        console.error('Error loading voice note:', err);
        setError(err.message || 'Failed to load voice note');
      } finally {
        setIsLoading(false);
      }
    };

    loadVoiceNote();
  }, [id]);

  useEffect(() => {
    if (voiceNote) {
      setEditedTitle(voiceNote.title);
      setEditedTranscription(voiceNote.transcription);
    }
  }, [voiceNote]);

  const handleGenerateContent = async (promptText) => {
    setIsGenerating(true);
    try {
      const content = await generateContent(id, promptText);
      const savedContent = await saveGeneratedContent(id, promptText, content);
      setGeneratedContents(prev => [savedContent.generatedContent, ...prev]);
      
      // Update session storage
      const cachedNotes = JSON.parse(sessionStorage.getItem('voiceNotes') || '[]');
      const updatedNotes = cachedNotes.map(note => 
        note._id === id 
          ? { 
              ...note, 
              generatedContents: [savedContent.generatedContent, ...(note.generatedContents || [])]
            }
          : note
      );
      sessionStorage.setItem('voiceNotes', JSON.stringify(updatedNotes));
      
      setShowPromptForm(false);
      toast.success('Content generated successfully');

      // Scroll to new content after it's rendered
      setTimeout(() => {
        const newContent = document.querySelector('[data-generated-content="latest"]');
        if (newContent) {
          newContent.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }, 100);
    } catch (err) {
      console.error('Failed to generate content:', err);
      toast.error('Failed to generate content');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDeleteContent = async (contentId) => {
    setContentToDelete(contentId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteGeneratedContent(id, contentToDelete);
      setGeneratedContents(prev => prev.filter(item => item._id !== contentToDelete));
      
      // Update session storage
      const cachedNotes = JSON.parse(sessionStorage.getItem('voiceNotes') || '[]');
      const updatedNotes = cachedNotes.map(note => 
        note._id === id 
          ? { 
              ...note, 
              generatedContents: note.generatedContents.filter(item => item._id !== contentToDelete)
            }
          : note
      );
      sessionStorage.setItem('voiceNotes', JSON.stringify(updatedNotes));
      
      toast.success('Content deleted successfully');
    } catch (err) {
      console.error('Failed to delete generated content:', err);
      toast.error('Failed to delete content');
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
      setContentToDelete(null);
    }
  };

  const handleCopyGeneratedContent = async (content, index) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopiedContentIndex(index);
      toast.success('Content copied to clipboard');
      setTimeout(() => setCopiedContentIndex(null), 2000);
    } catch (err) {
      console.error('Failed to copy content:', err);
      toast.error('Failed to copy content');
    }
  };

  const toggleItemExpansion = (index) => {
    setExpandedItems(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index) 
        : [index]
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showPromptForm && !event.target.closest('.prompt-popup') && !event.target.closest('.create-button')) {
        setShowPromptForm(false);
        setPrompt('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPromptForm]);

  useEffect(() => {
    const updateButtonRect = () => {
      if (createButtonRef.current) {
        setButtonRect(createButtonRef.current.getBoundingClientRect());
      }
    };

    // Update initially and on scroll/resize
    updateButtonRect();
    window.addEventListener('scroll', updateButtonRect, { passive: true });
    window.addEventListener('resize', updateButtonRect, { passive: true });

    return () => {
      window.removeEventListener('scroll', updateButtonRect);
      window.removeEventListener('resize', updateButtonRect);
    };
  }, [showPromptForm]); // Re-run when popup visibility changes

  const renderPopup = () => {
    if (!showPromptForm || !buttonRect) return null;

    return createPortal(
      <GenerateContentPopup
        buttonRect={buttonRect}
        onClose={() => setShowPromptForm(false)}
        onGenerate={handleGenerateContent}
        isGenerating={isGenerating}
      />,
      document.body
    );
  };

  const handleDeleteVoiceNote = async () => {
    try {
      await deleteVoiceNote(id);
      toast.success('Voice note deleted successfully');
      navigate('/voice-notes');
    } catch (error) {
      console.error('Failed to delete voice note:', error);
      toast.error('Failed to delete voice note');
    }
  };

  const handleEditTitle = () => {
    setIsEditing(true);
  };

  const handleEditTranscription = () => {
    setIsEditingTranscription(true);
  };

  const handleRegenerateTitle = async () => {
    try {
      let newTitle = await regenerateTranscriptionTitle(id);
      // Remove any remaining quotation marks
      newTitle = newTitle.replace(/["']/g, '');
      setVoiceNote(prev => ({ ...prev, title: newTitle }));
      toast.success('Title regenerated successfully');
    } catch (error) {
      console.error('Failed to regenerate title:', error);
      toast.error('Failed to regenerate title');
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownloadAudio = async () => {
    try {
      const downloadUrl = await getSignedUrl(id, true);
      window.open(downloadUrl, '_blank');
      toast.success('Audio download started');
    } catch (error) {
      console.error('Failed to get download URL:', error);
      toast.error('Failed to download audio');
    }
  };

  const handleTitleSave = async () => {
    try {
      const updatedTranscription = await updateTranscriptionTitle(id, editedTitle);
      setVoiceNote(prev => ({ ...prev, title: updatedTranscription.title }));
      setIsEditing(false);
      toast.success('Title updated successfully');
    } catch (error) {
      console.error('Failed to update title:', error);
      toast.error('Failed to update title');
    }
  };

  const onTranscriptionUpdate = async (id, newTranscription) => {
    try {
      const updatedVoiceNote = await updateVoiceNote(id, {
        transcription: newTranscription,
        title: voiceNote.title
      });
      
      setVoiceNote(updatedVoiceNote);
      return updatedVoiceNote;
    } catch (error) {
      console.error('Error updating transcription:', error);
      throw new Error('Failed to update transcription');
    }
  };

  const handleSaveTranscription = async () => {
    try {
      const updatedVoiceNote = await onTranscriptionUpdate(id, editedTranscription);
      setVoiceNote(updatedVoiceNote);
      setIsEditingTranscription(false);
      toast.success('Transcription updated successfully');
    } catch (error) {
      console.error('Failed to update transcription:', error);
      toast.error('Failed to update transcription');
    }
  };

  if (isLoading) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-[#121212]' : 'bg-slate-50'}`}>
        <div className="max-w-2xl mx-auto p-4">
          {/* Back button skeleton - removed animation */}
          <div className={`w-24 h-6 rounded-md mb-6 ${
            isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
          }`} />

          {/* Main content skeleton */}
          <div className="space-y-4">
            {/* Date - removed animation */}
            <div className={`w-32 h-3 rounded ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
            }`} />

            {/* Title - kept animation but made it smoother */}
            <div className={`w-3/4 h-6 rounded ${
              isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
            } animate-pulse transition-opacity duration-1000`} />

            {/* Content lines - removed animation from individual lines */}
            <div className="space-y-2 mt-6">
              {Array(6).fill(0).map((_, index) => (
                <div 
                  key={index}
                  className={`h-3 rounded ${
                    isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
                  } ${index === 5 ? 'w-2/3' : 'w-full'}`}
                />
              ))}
            </div>

            {/* Actions bar - kept animation but made it smoother */}
            <div className="mt-8 pt-4 flex justify-between items-center border-t border-opacity-10 border-[#333333]">
              <div className={`w-20 h-7 rounded-md ${
                isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
              } animate-pulse transition-opacity duration-1000`} />
              <div className={`w-8 h-8 rounded-full ${
                isDarkMode ? 'bg-[#1a1a1a]' : 'bg-gray-100'
              }`} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'} p-4`}>
        <div className="max-w-2xl mx-auto">
          <div className={`p-4 rounded-md ${isDarkMode ? 'bg-red-900/20 text-red-400' : 'bg-red-50 text-red-600'}`}>
            {error}
          </div>
        </div>
      </div>
    );
  }

  if (!voiceNote) return null;

  return (
    <div className={`h-full w-full overflow-y-auto ${
      isDarkMode 
        ? 'bg-gradient-to-b from-[#121212] to-[#1a1a1a]' 
        : 'bg-gradient-to-b from-slate-50 to-white'
    }`}>
      <div className="h-full max-w-2xl mx-auto px-2 py-1 md:p-4">
        {/* Transcription box with title and content */}
        <div className={`
          rounded-xl border backdrop-blur-xl
          mt-1 md:mt-10 px-4 py-4 md:p-6
          ${isDarkMode 
            ? 'bg-[#151515]/95 border-[#333333]' 
            : 'bg-white/95 border-gray-200/50'}
          relative
        `}>
          {/* Date and Title moved inside */}
          <div className="mb-3 md:mb-4">
            <div className="flex justify-between items-start">
              <p className={`text-[11px] md:text-xs ${isDarkMode ? 'text-slate-500' : 'text-slate-500'}`}>
                {format(new Date(voiceNote?.createdAt), 'MMMM d, yyyy • h:mm a')}
              </p>
              <button
                ref={(el) => {
                  setPromptButtonRef(el);
                  createButtonRef.current = el;
                }}
                onClick={() => setShowPromptForm(!showPromptForm)}
                className={`create-button inline-flex items-center px-3 py-1.5 rounded-md text-xs transition-colors duration-200 ${
                  isDarkMode 
                    ? 'bg-[#1a1a1a] hover:bg-[#1e1e1e] text-slate-200 border border-[#333333]' 
                    : 'bg-slate-800 hover:bg-slate-700 text-slate-100'
                }`}
              >
                Create with your voice
              </button>
            </div>
            {isEditing ? (
              <div className="flex items-center mt-2 md:mt-2">
                <input
                  type="text"
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                  className={`text-lg font-bold w-full mr-2 px-2 py-1 rounded transition-colors duration-200 ${
                    isDarkMode 
                      ? 'bg-[#1a1a1a] text-slate-200 border-[#333333] placeholder-slate-500' 
                      : 'bg-slate-100 text-slate-900 border-slate-200'
                  } border`}
                />
                <button
                  onClick={handleTitleSave}
                  className={`p-1 rounded-full transition-colors duration-200 ${
                    isDarkMode ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-200' : 'hover:bg-slate-200'
                  }`}
                >
                  <CheckIcon className="w-4 h-4 text-green-500" />
                </button>
                <button
                  onClick={() => {
                    setIsEditing(false);
                    setEditedTitle(voiceNote.title);
                  }}
                  className={`p-1 rounded-full transition-colors duration-200 ${
                    isDarkMode ? 'hover:bg-[#1e1e1e] text-slate-400 hover:text-slate-200' : 'hover:bg-slate-200'
                  }`}
                >
                  <XMarkIcon className="w-4 h-4 text-red-500" />
                </button>
              </div>
            ) : (
              <h1 className={`text-lg font-bold mt-2 ${isDarkMode ? 'text-slate-200' : 'text-slate-900'}`}>
                {voiceNote?.title}
              </h1>
            )}
          </div>

          {/* Transcription content */}
          {isEditingTranscription ? (
            <div className="relative">
              <textarea
                value={editedTranscription}
                onChange={(e) => setEditedTranscription(e.target.value)}
                className={`w-full p-2 text-xs border rounded-md transition-colors duration-200 ${
                  isDarkMode 
                    ? 'bg-[#1a1a1a] text-slate-200 border-[#333333] placeholder-slate-500' 
                    : 'bg-slate-50 text-slate-800 border-slate-200'
                } focus:outline-none focus:ring-1 focus:ring-blue-500/30`}
                rows="10"
              />
              <div className="flex justify-end mt-2 space-x-2">
                <button
                  onClick={() => {
                    setIsEditingTranscription(false);
                    setEditedTranscription(voiceNote.transcription);
                  }}
                  className={`px-3 py-1 rounded-md text-xs transition-colors duration-200 ${
                    isDarkMode 
                      ? 'text-slate-400 hover:text-slate-200 hover:bg-[#1e1e1e]' 
                      : 'text-slate-600 hover:bg-slate-200'
                  }`}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveTranscription}
                  className={`px-3 py-1 rounded-md text-xs transition-colors duration-200 ${
                    isDarkMode 
                      ? 'bg-[#1a1a1a] hover:bg-[#1e1e1e] text-slate-200' 
                      : 'bg-slate-800 hover:bg-slate-700 text-slate-100'
                  }`}
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            <div className={`prose ${isDarkMode ? 'prose-invert' : ''} max-w-none text-xs`}>
              {splitIntoParagraphs(voiceNote?.transcription).map((paragraph, index) => (
                <p 
                  key={index} 
                  className={`text-xs mb-4 ${
                    isDarkMode ? 'text-slate-300' : 'text-slate-700'
                  }`}
                >
                  {paragraph}
                </p>
              ))}
            </div>
          )}

          {/* Add error message display */}
          {error && (
            <div className={`mt-4 p-2 rounded-md ${
              isDarkMode 
                ? 'bg-red-900/20 text-red-400 border border-red-900/30' 
                : 'bg-red-50 text-red-600'
            }`}>
              {error}
              <button
                onClick={() => setError(null)}
                className={`ml-2 text-xs hover:underline ${
                  isDarkMode ? 'text-red-400 hover:text-red-300' : 'text-red-600 hover:text-red-700'
                }`}
              >
                Dismiss
              </button>
            </div>
          )}

          {/* Render popup through portal */}
          {renderPopup()}

          {/* Generated Content List */}
          {generatedContents.length > 0 && (
            <div className="mt-2 space-y-1">
              {generatedContents.map((item, index) => {
                const colors = getItemColors(index, isDarkMode);
                return (
                <motion.div 
                  key={item._id}
                  data-generated-content={index === 0 ? "latest" : ""}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                  className={`
                    rounded-lg border transition-all duration-200
                    ${colors.bg}
                    ${expandedItems.includes(index)
                      ? `${colors.expandedBg} ${colors.border}`
                      : `${colors.border} ${colors.hoverBorder} ${colors.hoverBg}`
                    }
                  `}
                  role="region"
                  aria-expanded={expandedItems.includes(index)}
                >
                  {/* Header Section */}
                  <div 
                    className={`
                      flex items-center gap-3 p-3
                      ${expandedItems.includes(index) ? 'border-b' : ''}
                      ${isDarkMode 
                        ? expandedItems.includes(index) ? 'border-[#333333]' : ''
                        : expandedItems.includes(index) ? 'border-gray-200' : ''
                      }
                    `}
                  >
                    {/* Expand/Collapse Button */}
                    <button
                      onClick={() => toggleItemExpansion(index)}
                      className={`
                        group flex items-center flex-grow min-w-0 text-left
                        transition-all duration-200 rounded-md px-2 py-1.5
                        ${isDarkMode 
                          ? 'hover:bg-[#252525]/50' 
                          : 'hover:bg-gray-100/50'
                        }
                      `}
                      aria-expanded={expandedItems.includes(index)}
                    >
                      <ChevronRightIcon 
                        className={`
                          transition-transform duration-200
                          ${expandedItems.includes(index) ? 'transform rotate-90' : ''}
                          ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}
                          w-3.5 h-3.5 mr-2 flex-shrink-0
                          group-hover:scale-105
                        `}
                      />
                      <div className="flex items-center gap-2 truncate">
                        <SparklesIcon className={`
                          w-3.5 h-3.5 flex-shrink-0 transition-colors duration-200
                          ${isDarkMode 
                            ? expandedItems.includes(index)
                              ? 'text-purple-400'
                              : 'text-gray-400 group-hover:text-purple-400'
                            : expandedItems.includes(index)
                              ? 'text-purple-500'
                              : 'text-gray-400 group-hover:text-purple-500'
                          }
                        `} />
                        <span className={`
                          truncate text-xs font-medium transition-colors duration-200
                          ${isDarkMode 
                            ? 'text-gray-300 group-hover:text-gray-200' 
                            : 'text-gray-600 group-hover:text-gray-900'
                          }
                        `}>
                          {item.prompt}
                        </span>
                      </div>
                    </button>

                    {/* Action Buttons */}
                    <div className="flex items-center gap-1 flex-shrink-0">
                      {/* Copy Button */}
                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={() => handleCopyGeneratedContent(item.content, index)}
                        className={`
                          p-1.5 rounded-md transition-all duration-200
                          ${isDarkMode
                            ? copiedContentIndex === index
                              ? 'bg-green-500/10 text-green-400'
                              : 'text-gray-400 hover:bg-[#252525]/50 hover:text-gray-300'
                            : copiedContentIndex === index
                              ? 'bg-green-50 text-green-600'
                              : 'text-gray-400 hover:bg-gray-100/50 hover:text-gray-600'
                          }
                        `}
                        aria-label={copiedContentIndex === index ? "Copied!" : "Copy content"}
                      >
                        {copiedContentIndex === index ? (
                          <ClipboardIconSolid className="w-3.5 h-3.5" />
                        ) : (
                          <ClipboardIcon className="w-3.5 h-3.5" />
                        )}
                      </motion.button>

                      {/* Delete Button */}
                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={() => handleDeleteContent(item._id)}
                        className={`
                          p-1.5 rounded-md transition-all duration-200
                          ${isDarkMode
                            ? 'text-gray-400 hover:bg-red-500/5 hover:text-red-400'
                            : 'text-gray-400 hover:bg-red-50/50 hover:text-red-500'
                          }
                        `}
                        aria-label="Delete content"
                      >
                        <TrashIcon className="w-3.5 h-3.5" />
                      </motion.button>
                    </div>
                  </div>

                  {/* Content Section */}
                  <AnimatePresence>
                    {expandedItems.includes(index) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="overflow-hidden"
                      >
                        <div className="p-4 space-y-2">
                          <ReactMarkdown
                            components={{
                              p: ({node, children}) => (
                                <motion.p 
                                  initial={{ opacity: 0, y: 5 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{ duration: 0.2 }}
                                  className={`
                                    text-xs leading-relaxed whitespace-pre-wrap
                                    ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
                                  `}
                                >
                                  {typeof children === 'string' ? formatAIResponse(children) : children}
                                </motion.p>
                              ),
                              ul: ({node, children}) => (
                                <motion.ul 
                                  initial={{ opacity: 0, x: -5 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  className="list-disc pl-4 space-y-1.5 my-2"
                                >
                                  {children}
                                </motion.ul>
                              ),
                              ol: ({node, children}) => (
                                <motion.ol 
                                  initial={{ opacity: 0, x: -5 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  className="list-decimal pl-4 space-y-1.5 my-2"
                                >
                                  {children}
                                </motion.ol>
                              ),
                              li: ({node, children}) => (
                                <li className={`
                                  text-xs leading-relaxed
                                  ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
                                `}>
                                  {typeof children === 'string' ? formatAIResponse(children) : children}
                                </li>
                              ),
                              h1: ({node, children}) => (
                                <motion.h1 
                                  initial={{ opacity: 0, y: -5 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  className={`
                                    text-sm font-semibold mb-2
                                    ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}
                                  `}
                                >
                                  {children}
                                </motion.h1>
                              ),
                              h2: ({node, children}) => (
                                <motion.h2 
                                  initial={{ opacity: 0, y: -5 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  className={`
                                    text-xs font-semibold mb-2
                                    ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}
                                  `}
                                >
                                  {children}
                                </motion.h2>
                              ),
                              h3: ({node, children}) => (
                                <motion.h3 
                                  initial={{ opacity: 0, y: -5 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  className={`
                                    text-xs font-semibold mb-2
                                    ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}
                                  `}
                                >
                                  {children}
                                </motion.h3>
                              ),
                              blockquote: ({node, children}) => (
                                <motion.blockquote 
                                  initial={{ opacity: 0, x: -5 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  className={`
                                    border-l-2 pl-3 my-2
                                    ${isDarkMode 
                                      ? 'border-gray-600 text-gray-400' 
                                      : 'border-gray-300 text-gray-600'
                                    }
                                  `}
                                >
                                  {children}
                                </motion.blockquote>
                              ),
                              code: ({node, children}) => (
                                <code className={`
                                  px-1.5 py-0.5 rounded text-xs font-mono
                                  ${isDarkMode 
                                    ? 'bg-gray-800 text-gray-300' 
                                    : 'bg-gray-100 text-gray-800'
                                  }
                                `}>
                                  {children}
                                </code>
                              ),
                            }}
                          >
                            {item.content}
                          </ReactMarkdown>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              );
            })}
            </div>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <DeleteConfirmationModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setContentToDelete(null);
          }}
          onConfirm={handleConfirmDelete}
          isDarkMode={isDarkMode}
          title="Delete Generated Content"
          message="Are you sure you want to delete this generated content? This action cannot be undone."
          isDeleting={isDeleting}
        />
      )}
    </div>
  );
};

export default VoiceNotePage;