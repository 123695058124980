import React, { useState } from 'react';
import PostTemplatesPopup from '../components/PostTemplates/PostTemplatesPopup';
import { useNavigate } from 'react-router-dom';
import AnimatedLoader from '../components/common/AnimatedLoader';
import { useTheme } from '../context/ThemeContext';
import { Helmet } from 'react-helmet-async';

const LoadingState = ({ isDarkMode }) => (
  <div className={`
    fixed inset-0 z-[100] flex flex-col items-center justify-center
    ${isDarkMode ? 'bg-[#121212]/80' : 'bg-white/80'} backdrop-blur-sm
  `}>
    <AnimatedLoader 
      size={48} 
      color={isDarkMode ? '#3B82F6' : '#2563EB'} 
      className="mb-4"
    />
    <h3 className={`text-lg font-medium mb-2 ${isDarkMode ? 'text-slate-200' : 'text-gray-900'}`}>
      Loading templates...
    </h3>
    <p className={`text-sm max-w-md text-center ${isDarkMode ? 'text-slate-400' : 'text-gray-500'}`}>
      Please wait while we fetch your templates
    </p>
  </div>
);

const PostTemplatesPage = () => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(true);

  const handleSelectTemplate = (template) => {
    // Handle template selection
    console.log('Selected template:', template);
    // You can navigate to the editor or handle the template as needed
  };

  return (
    <>
      <Helmet>
        <title>Post Templates | Ammmplify</title>
        <meta name="description" content="Choose from a variety of professionally designed post templates to create engaging social media content. Customize and personalize your posts with ease." />
        <meta name="keywords" content="post templates, social media templates, content creation, social media design, post customization" />
        <meta property="og:title" content="Post Templates | Ammmplify" />
        <meta property="og:description" content="Choose from a variety of professionally designed post templates to create engaging social media content. Customize and personalize your posts with ease." />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Post Templates | Ammmplify" />
        <meta name="twitter:description" content="Choose from a variety of professionally designed post templates to create engaging social media content. Customize and personalize your posts with ease." />
      </Helmet>

      <div className="h-full w-full">
        <PostTemplatesPopup
          onSelectTemplate={handleSelectTemplate}
          setPageLoading={setIsLoading}
          isLoading={isLoading}
        />
        {isLoading && <LoadingState isDarkMode={isDarkMode} />}
      </div>
    </>
  );
};

export default PostTemplatesPage; 