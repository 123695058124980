import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { XCircleIcon, ClockIcon, CreditCardIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button } from '../components/ui/button';
import { useTheme } from '../context/ThemeContext';

const CANCEL_SCENARIOS = {
  INCOMPLETE: 'incomplete',
  CANCELLED: 'cancelled',
  PAYMENT_FAILED: 'payment_failed',
  DEFAULT: 'default'
};

const getCancelContent = (scenario) => {
  switch (scenario) {
    case CANCEL_SCENARIOS.INCOMPLETE:
      return {
        icon: ClockIcon,
        title: 'Subscription Process Incomplete',
        description: 'You haven\'t completed the subscription process. No charges have been made to your account.',
        iconColor: 'text-yellow-500',
      };
    case CANCEL_SCENARIOS.CANCELLED:
      return {
        icon: XCircleIcon,
        title: 'Subscription Cancelled',
        description: 'Your subscription has been successfully cancelled. You\'ll continue to have access until the end of your current billing period.',
        iconColor: 'text-red-500',
      };
    case CANCEL_SCENARIOS.PAYMENT_FAILED:
      return {
        icon: CreditCardIcon,
        title: 'Payment Failed',
        description: 'We couldn\'t process your payment. Please try again with a different payment method.',
        iconColor: 'text-orange-500',
      };
    default:
      return {
        icon: ExclamationTriangleIcon,
        title: 'Subscription Not Completed',
        description: 'The subscription process was not completed. No charges have been made to your account.',
        iconColor: 'text-gray-500',
      };
  }
};

const SubscriptionCancel = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isDarkMode } = useTheme();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const scenario = searchParams.get('reason') || CANCEL_SCENARIOS.DEFAULT;
  const content = getCancelContent(scenario);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRedirecting(true);
      navigate('/subscription');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  const Icon = content.icon;

  return (
    <div className={`min-h-screen py-12 ${isDarkMode ? 'bg-slate-900' : 'bg-gray-50'}`}>
      <div className="max-w-md mx-auto px-4">
        <div className={`rounded-lg border p-6 ${
          isDarkMode 
            ? 'bg-slate-800 border-slate-700' 
            : 'bg-white border-gray-100'
        }`}>
          <div className="flex flex-col items-center text-center">
            <div className={`p-3 rounded-full ${isDarkMode ? 'bg-slate-700/50' : 'bg-gray-100'}`}>
              <Icon className={`h-8 w-8 ${content.iconColor}`} />
            </div>
            
            <h2 className={`mt-4 text-xl font-semibold ${
              isDarkMode ? 'text-slate-200' : 'text-gray-900'
            }`}>
              {content.title}
            </h2>
            
            <p className={`mt-2 text-sm ${
              isDarkMode ? 'text-slate-400' : 'text-gray-600'
            }`}>
              {content.description}
            </p>

            <div className="mt-6 space-y-3 w-full">
              <Button
                variant={isDarkMode ? "default" : "secondary"}
                className="w-full"
                onClick={() => navigate('/subscription')}
                isDarkMode={isDarkMode}
              >
                Return to Subscription
              </Button>
              
              <p className={`text-xs ${
                isDarkMode ? 'text-slate-500' : 'text-gray-500'
              }`}>
                {isRedirecting ? 'Redirecting...' : 'You will be redirected in 5 seconds...'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCancel; 