import axios from 'axios';
import { API_URL } from '../api';

export const testLinkedInTokenRefresh = async () => {
  try {
    console.log('🔵 Starting LinkedIn token refresh test...');
    
    // 1. Get current tokens from localStorage
    const tokens = {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
      linkedinAccessToken: localStorage.getItem('linkedin_access_token'),
      linkedinRefreshToken: localStorage.getItem('linkedin_refresh_token'),
      linkedinExpiresAt: localStorage.getItem('linkedin_expires_at')
    };

    console.log('🔵 Current tokens:', {
      hasAccessToken: !!tokens.accessToken,
      hasRefreshToken: !!tokens.refreshToken,
      hasLinkedInAccess: !!tokens.linkedinAccessToken,
      hasLinkedInRefresh: !!tokens.linkedinRefreshToken,
      linkedinExpiresAt: tokens.linkedinExpiresAt ? new Date(tokens.linkedinExpiresAt).toISOString() : null
    });

    if (!tokens.refreshToken || !tokens.linkedinRefreshToken) {
      throw new Error('No refresh tokens found. Please login with LinkedIn first.');
    }

    // 2. Test token refresh
    console.log('🔵 Attempting token refresh...');
    const response = await axios.post(`${API_URL}/api/auth/refresh-token`, {
      refreshToken: tokens.refreshToken,
      linkedinRefreshToken: tokens.linkedinRefreshToken
    });

    // 3. Store new tokens
    if (response.data.accessToken) {
      localStorage.setItem('accessToken', response.data.accessToken);
    }
    if (response.data.refreshToken) {
      localStorage.setItem('refreshToken', response.data.refreshToken);
    }
    if (response.data.linkedinTokens) {
      localStorage.setItem('linkedin_access_token', response.data.linkedinTokens.access_token);
      localStorage.setItem('linkedin_refresh_token', response.data.linkedinTokens.refresh_token);
      localStorage.setItem('linkedin_expires_at', response.data.linkedinTokens.expires_at);
    }

    console.log('✅ Token refresh successful:', {
      hasNewAccessToken: !!response.data.accessToken,
      hasNewRefreshToken: !!response.data.refreshToken,
      hasLinkedInTokens: !!response.data.linkedinTokens,
      user: {
        id: response.data.user.id,
        email: response.data.user.email,
        hasLinkedIn: !!response.data.user.linkedinId
      }
    });

    // 4. Verify the new tokens
    if (response.data.linkedinTokens) {
      console.log('✅ LinkedIn tokens refreshed:', {
        expiresAt: new Date(response.data.linkedinTokens.expires_at).toISOString()
      });
    }

    return response.data;
  } catch (error) {
    console.error('❌ Test failed:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });
    throw error;
  }
}; 