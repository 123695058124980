import React, { useState, useRef, useEffect } from 'react';
import { 
  PauseIcon,
  PlayIcon,
  StopIcon, 
  ArrowTopRightOnSquareIcon,
  CheckIcon,
  ArrowLeftIcon,
  XMarkIcon,
  DocumentDuplicateIcon,
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
  MicrophoneIcon,
  ClipboardIcon
} from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../api';
import { toast } from 'react-hot-toast';
import Draggable from 'react-draggable';
import AnimatedLoader from './common/AnimatedLoader';

// Shadcn-style button variants
const buttonVariants = {
  base: `
    inline-flex items-center justify-center
    rounded-2xl transition-all duration-300
    focus-visible:outline-none
    disabled:pointer-events-none
    disabled:opacity-50 select-none
    shadow-lg backdrop-blur-sm
    border
    gap-1.5
  `,
  recording: {
    dark: `
      bg-zinc-900/70 text-red-400
      hover:bg-zinc-800/80
      shadow-zinc-950/10
      border-red-500/10
      hover:border-red-500/20
    `,
    light: `
      bg-white/90 text-red-500
      hover:bg-white/95
      shadow-zinc-200/20
      border-red-200/30
      hover:border-red-300/40
    `
  },
  idle: {
    dark: `
      bg-zinc-900/70 text-zinc-300
      hover:bg-zinc-800/80
      shadow-zinc-950/10
      border-zinc-700/20
      hover:border-zinc-600/30
    `,
    light: `
      bg-white/90 text-zinc-600
      hover:bg-white/95
      shadow-zinc-200/20
      border-zinc-200/40
      hover:border-zinc-300/50
    `
  },
  success: {
    dark: `
      bg-zinc-900/70 text-emerald-400
      hover:bg-zinc-800/80
      shadow-zinc-950/10
      border-emerald-500/10
      hover:border-emerald-500/20
    `,
    light: `
      bg-white/90 text-emerald-500
      hover:bg-white/95
      shadow-zinc-200/20
      border-emerald-200/30
      hover:border-emerald-300/40
    `
  },
  processing: {
    dark: `
      bg-zinc-900/70 text-zinc-400
      hover:bg-zinc-800/80
      shadow-zinc-950/10
      border-zinc-600/20
      hover:border-zinc-500/30
    `,
    light: `
      bg-white/90 text-zinc-500
      hover:bg-white/95
      shadow-zinc-200/20
      border-zinc-300/40
      hover:border-zinc-400/50
    `
  }
};

// Shadcn-style control button variants
const controlButtonVariants = {
  base: `
    inline-flex items-center justify-center
    rounded-full transition-all duration-300
    focus-visible:outline-none
    disabled:pointer-events-none
    disabled:opacity-50 select-none
    shadow-lg backdrop-blur-sm
    border
  `,
  dark: `
    bg-zinc-900/70 text-zinc-400
    hover:bg-zinc-800/80 hover:text-zinc-300
    shadow-zinc-950/10
    border-zinc-700/20
    hover:border-zinc-600/30
  `,
  light: `
    bg-white/90 text-zinc-600
    hover:bg-white/95 hover:text-zinc-900
    shadow-zinc-200/20
    border-zinc-200/40
    hover:border-zinc-300/50
  `
};

const CopyButton = ({ text, isDarkMode }) => {
  const [copyState, setCopyState] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyState(true);
      
      toast.success('Message copied!', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });

      setTimeout(() => {
        setCopyState(false);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
      toast.error('Failed to copy message', {
        position: 'top-center',
        style: isDarkMode 
          ? { 
              background: '#1e1e1e', 
              color: '#fff',
              border: '1px solid #333333'
            }
          : {
              background: '#fff',
              color: '#000',
              border: '1px solid #e5e7eb'
            }
      });
    }
  };

  return (
    <button
      onClick={handleCopy}
      className={`
        p-1.5 rounded-md transition-all duration-200
        ${copyState 
          ? isDarkMode 
            ? 'bg-green-500/20 text-green-400' 
            : 'bg-green-100 text-green-600'
          : isDarkMode 
            ? 'bg-[#252525] hover:bg-[#333333] text-gray-400 hover:text-gray-300' 
            : 'bg-gray-100 hover:bg-gray-200 text-gray-500 hover:text-gray-700'
        }
      `}
      title="Copy transcription"
    >
      <motion.div
        initial={false}
        animate={{
          scale: copyState ? [1, 1.2, 1] : 1,
        }}
        transition={{ duration: 0.3 }}
      >
        {copyState ? (
          <CheckIcon className="w-3.5 h-3.5" />
        ) : (
          <ClipboardIcon className="w-3.5 h-3.5" />
        )}
      </motion.div>
    </button>
  );
};

const FloatingTranscriptionBox = ({ 
  isDarkMode, 
  transcriptionText, 
  onClose,
  onViewFull 
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20, scale: 0.95 }}
    animate={{ opacity: 1, y: 0, scale: 1 }}
    exit={{ opacity: 0, y: 20, scale: 0.95 }}
    transition={{ type: "spring", stiffness: 300, damping: 25 }}
    className={`
      fixed z-50 
      right-4 
      sm:right-6
      md:right-4
      bottom-20
      sm:bottom-24
      md:bottom-[4.5rem]
      w-full max-w-md
      rounded-xl border shadow-lg
      backdrop-blur-lg
      ${isDarkMode 
        ? 'bg-zinc-900/95 border-zinc-800/50 shadow-black/20' 
        : 'bg-white/95 border-zinc-200/50 shadow-zinc-200/20'
      }
    `}
  >
    {/* Decorative tail */}
    <div className={`
      absolute bottom-[-6px] right-6
      w-3 h-3 rotate-45 transform
      ${isDarkMode 
        ? 'bg-zinc-900/95 border-zinc-800/50' 
        : 'bg-white/95 border-zinc-200/50'
      }
      border-r border-b
    `} />

    {/* Header */}
    <div className="flex items-center justify-between p-3">
      <motion.div 
        initial={{ opacity: 0, x: -10 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.1 }}
        className="flex items-center gap-2"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ 
            type: "spring",
            stiffness: 400,
            damping: 20,
            delay: 0.2 
          }}
        >
          <CheckIcon className={`
            w-5 h-5
            ${isDarkMode ? 'text-emerald-400' : 'text-emerald-600'}
          `} />
        </motion.div>
        <span className={`
          text-sm font-medium
          ${isDarkMode ? 'text-zinc-200' : 'text-zinc-900'}
        `}>
          Voice Note Captured
        </span>
      </motion.div>
      <div className="flex items-center gap-2">
        <CopyButton text={transcriptionText} isDarkMode={isDarkMode} />
        <button
          onClick={onClose}
          className={`
            p-1.5 rounded-lg
            transition-all duration-200
            ${isDarkMode 
              ? 'hover:bg-zinc-800 text-zinc-400 hover:text-zinc-300' 
              : 'hover:bg-zinc-100 text-zinc-500 hover:text-zinc-600'
            }
          `}
          aria-label="Close transcription"
        >
          <XMarkIcon className="w-4 h-4" />
        </button>
      </div>
    </div>

    {/* Content */}
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.3 }}
      className={`
        px-3 pb-3
      `}
    >
      <div className={`
        p-3 rounded-lg
        ${isDarkMode 
          ? 'bg-zinc-800/50' 
          : 'bg-zinc-50/80'
        }
      `}>
        <div className={`
          text-xs font-medium mb-2
          ${isDarkMode ? 'text-zinc-400' : 'text-zinc-500'}
        `}>
          Transcription
        </div>
        <div className={`
          text-sm leading-relaxed
          min-h-[3rem] max-h-[12rem]
          overflow-y-auto
          ${isDarkMode ? 'text-zinc-300' : 'text-zinc-600'}
          scrollbar-thin
          ${isDarkMode 
            ? 'scrollbar-thumb-zinc-700 scrollbar-track-zinc-800/50' 
            : 'scrollbar-thumb-zinc-300 scrollbar-track-zinc-100/50'
          }
        `}>
          {transcriptionText}
        </div>
      </div>

      {/* Action Button */}
      <motion.div 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4 }}
        className="flex justify-end mt-3"
      >
        <button
          onClick={onViewFull}
          className={`
            inline-flex items-center justify-center
            gap-2 px-4 py-2 rounded-lg text-sm font-medium
            transition-all duration-200
            focus-visible:outline-none focus-visible:ring-2
            focus-visible:ring-offset-2
            ${isDarkMode 
              ? 'bg-emerald-500/10 text-emerald-400 hover:bg-emerald-500/20 focus-visible:ring-emerald-500/50' 
              : 'bg-emerald-50 text-emerald-600 hover:bg-emerald-100 focus-visible:ring-emerald-500/30'
            }
          `}
        >
          <span>View Full Note</span>
          <ArrowTopRightOnSquareIcon className="w-4 h-4" />
        </button>
      </motion.div>
    </motion.div>
  </motion.div>
);

const RecordIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 32 32"
    className={className}
    fill="currentColor"
  >
    <path d="M16 2C8.3 2 2 8.3 2 16s6.3 14 14 14s14-6.3 14-14S23.7 2 16 2m0 20c-3.3 0-6-2.7-6-6s2.7-6 6-6s6 2.7 6 6s-2.7 6-6 6"/>
  </svg>
);

const DragHandleIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 24 24"
    className={className}
    fill="currentColor"
  >
    <path d="M9 4.5a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3m1.5 7.5a1.5 1.5 0 1 0-3 0a1.5 1.5 0 0 0 3 0m0 6a1.5 1.5 0 1 0-3 0a1.5 1.5 0 0 0 3 0m6-6a1.5 1.5 0 1 0-3 0a1.5 1.5 0 0 0 3 0M15 16.5a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3M16.5 6a1.5 1.5 0 1 0-3 0a1.5 1.5 0 0 0 3 0" />
  </svg>
);

const MobileFullScreenUI = ({ 
  isDarkMode, 
  isProcessing,
  showSuccess,
  recordingTime,
  isPaused,
  formatTime,
  togglePause,
  stopRecording,
  cancelRecording,
  lastVoiceNoteId,
  viewTranscription,
  isInitializing,
  transcriptionText,
  onCloseSuccess,
  onExitFullScreen,
  isMobile,
  isRecording
}) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
    className="fixed inset-0 z-50 bg-black safe-top safe-bottom"
    onClick={(e) => e.stopPropagation()}
  >
    <motion.div 
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.2, delay: 0.1 }}
      className="flex flex-col h-full"
      onClick={(e) => e.stopPropagation()}
    >
      {/* Header */}
      <div className="flex items-center justify-between px-4 py-4 border-b border-white/10">
        <div className="flex items-center gap-3">
          {/* Only show back button when not recording and not in success/processing state */}
          {!isRecording && !showSuccess && !isProcessing && (
            <button
              onClick={onExitFullScreen}
              className="p-2 -ml-2 rounded-md transition-colors hover:bg-white/10 text-zinc-400 hover:text-white"
            >
              <ArrowLeftIcon className="w-5 h-5" />
            </button>
          )}
          <motion.span 
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-sm font-medium text-white"
          >
            {isProcessing ? 'Processing Voice Note' : showSuccess ? 'Voice Note Saved' : 'Recording Voice Note'}
          </motion.span>
        </div>
        {/* Show minimize button during recording for desktop */}
        {isRecording && !isMobile && (
          <button
            onClick={onExitFullScreen}
            className="p-2 rounded-md transition-colors hover:bg-white/10 text-zinc-400 hover:text-white"
            title="Switch to floating recorder"
          >
            <ArrowsPointingInIcon className="w-5 h-5" />
          </button>
        )}
        {/* Show close button in success/processing state */}
        {(showSuccess || isProcessing) && (
          <button
            onClick={onCloseSuccess}
            className="p-2 -mr-2 rounded-md transition-colors hover:bg-white/10 text-zinc-400 hover:text-white"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        )}
      </div>

      {/* Main Content */}
      <div className="relative flex flex-col items-center justify-center flex-1 px-4">
        {/* Timer */}
        {!isProcessing && !showSuccess && !isInitializing && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ 
              opacity: isPaused ? 0.6 : 1,
              scale: isPaused ? 0.95 : 1
            }}
            transition={{ duration: 0.2 }}
            className="mb-16"
          >
            <span className="font-mono text-8xl font-bold tabular-nums tracking-tight leading-none text-white">
              {formatTime(recordingTime)}
            </span>
          </motion.div>
        )}

        {/* Controls */}
        {!isProcessing && !showSuccess && !isInitializing && (
          <div className="flex items-center justify-center gap-8">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={togglePause}
              className="inline-flex items-center justify-center w-20 h-20 rounded-full border-2 border-white/30 hover:border-white/40 bg-white/5 hover:bg-white/10 text-white transition-all duration-200"
            >
              {isPaused ? (
                <PlayIcon className="w-10 h-10" />
              ) : (
                <PauseIcon className="w-10 h-10" />
              )}
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={stopRecording}
              className="inline-flex items-center justify-center w-20 h-20 rounded-full bg-red-500 hover:bg-red-600 text-white transition-all duration-200"
            >
              <StopIcon className="w-10 h-10" />
            </motion.button>
          </div>
        )}

        {/* Recording Status */}
        {!isProcessing && !showSuccess && !isInitializing && (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="absolute bottom-8 left-0 right-0 px-4 flex flex-col items-center gap-2"
          >
            <div className="flex items-center justify-center gap-2 py-3 px-4 rounded-lg bg-white/5 backdrop-blur-sm">
              <motion.div
                animate={{
                  scale: isPaused ? 1 : [1, 1.2, 1],
                  opacity: isPaused ? 0.5 : [0.5, 1, 0.5]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                className={`w-2 h-2 rounded-full ${isPaused ? 'bg-zinc-500' : 'bg-red-500'}`}
              />
              <span className="text-xs font-medium text-zinc-400">
                {isPaused ? "Recording paused" : "Recording in progress"}
              </span>
            </div>
            {isMobile && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.6 }}
                transition={{ delay: 1 }}
                className="text-xs text-zinc-500"
              >
                Keep phone unlocked
              </motion.div>
            )}
          </motion.div>
        )}

        {/* Processing State */}
        {isProcessing && (
          <motion.div 
            className="flex flex-col items-center gap-6"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <AnimatedLoader size={64} color="#60A5FA" />
            <div className="text-center">
              <span className="text-lg font-medium text-white">Processing your voice note</span>
              <span className="block mt-1 text-sm text-zinc-400">This will just take a moment</span>
            </div>
          </motion.div>
        )}

        {/* Success State */}
        {showSuccess && (
          <motion.div 
            className="flex flex-col items-center gap-8 w-full max-w-md"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <div className="relative">
              <motion.div
                className="absolute inset-0 rounded-full bg-emerald-500/20"
                initial={{ scale: 0 }}
                animate={{ scale: [1, 1.5, 1.2] }}
                transition={{ duration: 0.6 }}
              />
              <motion.div
                initial={{ scale: 0, rotate: -90 }}
                animate={{ scale: 1, rotate: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 20
                }}
              >
                <CheckIcon className="w-16 h-16 text-emerald-500" />
              </motion.div>
            </div>

            {transcriptionText && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="w-full p-4 rounded-lg bg-white/5 backdrop-blur-sm"
              >
                <div className="flex items-center justify-between mb-2">
                  <span className="text-xs font-medium text-zinc-400">Transcription</span>
                  <CopyButton text={transcriptionText} isDarkMode={true} />
                </div>
                <div className="text-sm leading-relaxed text-white/90 max-h-[8rem] overflow-y-auto">
                  {transcriptionText}
                </div>
              </motion.div>
            )}

            <div className="flex gap-3">
              <motion.button
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                onClick={onCloseSuccess}
                className="px-6 py-2.5 rounded-lg text-sm font-medium bg-white/10 hover:bg-white/20 text-white transition-colors"
              >
                Close
              </motion.button>
              <motion.button
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                onClick={viewTranscription}
                className="flex items-center gap-2 px-6 py-2.5 rounded-lg text-sm font-medium bg-emerald-500 hover:bg-emerald-600 text-white transition-colors"
              >
                View Full Note
                <ArrowTopRightOnSquareIcon className="w-4 h-4" />
              </motion.button>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  </motion.div>
);

const FloatingVoiceRecorder = ({ onRecordingComplete }) => {
  const { isDarkMode } = useTheme();
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [lastVoiceNoteId, setLastVoiceNoteId] = useState(null);
  const [transcriptionText, setTranscriptionText] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef(null);
  const timerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [hasPermission, setHasPermission] = useState(false);
  const audioContextRef = useRef(null);
  const streamRef = useRef(null);
  const [isStandalone, setIsStandalone] = useState(false);
  const wakeLockRef = useRef(null);
  const [isWakeLockSupported, setIsWakeLockSupported] = useState(false);
  const [showTranscriptionBox, setShowTranscriptionBox] = useState(false);
  const visibilityChangeTimeRef = useRef(null);
  const MAX_VISIBILITY_CHANGE_DURATION = 5 * 60 * 1000; // 5 minutes
  const dragStartRef = useRef({ x: 0, y: 0, time: 0 });
  const isDraggingRef = useRef(false);
  const nodeRef = useRef(null); // Add ref for Draggable

  // Handle mobile detection
  useEffect(() => {
    const checkMobile = () => {
      // Check for iOS specifically
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isMobileView = window.innerWidth < 768 || isIOS;
      console.log('Device info:', {
        userAgent: navigator.userAgent,
        isIOS,
        width: window.innerWidth,
        isMobileView,
        isStandalone
      });
      setIsMobile(isMobileView);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [isStandalone]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const startTimer = () => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  };

  // Define cleanup function after timer functions
  const cleanup = () => {
    stopTimer();
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    // Add AudioContext cleanup
    if (audioContextRef.current?.state !== 'closed') {
      audioContextRef.current?.close().catch(console.error);
      audioContextRef.current = null;
    }
    setRecordingTime(0);
    setIsPaused(false);
    setIsRecording(false);
    // Clear any active toasts when cleaning up
    toast.dismiss();
  };

  // Check Wake Lock support
  useEffect(() => {
    setIsWakeLockSupported('wakeLock' in navigator);
  }, []);

  // Handle visibility change
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'hidden') {
        // Store the time when the app became hidden
        visibilityChangeTimeRef.current = Date.now();
        
        if (isRecording && !isPaused) {
          console.log('Screen locked/app hidden while recording, pausing...');
          setIsPaused(true);
          try {
            if (mediaRecorderRef.current?.state === 'recording') {
              mediaRecorderRef.current.pause();
            }
          } catch (error) {
            console.error('Error pausing recorder:', error);
          }
        }
      } else if (document.visibilityState === 'visible') {
        // Check how long the app was hidden
        const hiddenDuration = Date.now() - (visibilityChangeTimeRef.current || 0);
        
        if (isRecording && isPaused) {
          if (hiddenDuration > MAX_VISIBILITY_CHANGE_DURATION) {
            console.log('App was hidden for too long, stopping recording...');
            stopRecording();
            addNotification('Recording stopped due to long inactivity', 'warning');
          } else {
            console.log('Screen unlocked/app visible, resuming...');
            try {
              // Re-initialize audio context if needed
              if (audioContextRef.current?.state === 'suspended') {
                await audioContextRef.current.resume();
              }
              
              // Check if stream is still active
              if (!streamRef.current || streamRef.current.getTracks()[0]?.readyState === 'ended') {
                console.log('Stream ended, restarting recording...');
                await restartRecording();
              } else if (mediaRecorderRef.current?.state === 'paused') {
                mediaRecorderRef.current.resume();
                setIsPaused(false);
              }
            } catch (error) {
              console.error('Error resuming recording:', error);
              stopRecording();
              addNotification('Could not resume recording. Please try again.', 'error');
            }
          }
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [isRecording, isPaused]);

  // Handle screen lock
  useEffect(() => {
    const requestWakeLock = async () => {
      if (isWakeLockSupported && isRecording && !isPaused) {
        try {
          wakeLockRef.current = await navigator.wakeLock.request('screen');
          console.log('Wake Lock is active');
          
          wakeLockRef.current.addEventListener('release', () => {
            console.log('Wake Lock was released');
          });
        } catch (err) {
          console.error('Error requesting Wake Lock:', err);
        }
      }
    };

    const releaseWakeLock = async () => {
      if (wakeLockRef.current) {
        try {
          await wakeLockRef.current.release();
          wakeLockRef.current = null;
          console.log('Wake Lock released');
        } catch (err) {
          console.error('Error releasing Wake Lock:', err);
        }
      }
    };

    if (isRecording && !isPaused) {
      requestWakeLock();
    } else {
      releaseWakeLock();
    }

    return () => {
      releaseWakeLock();
    };
  }, [isRecording, isPaused, isWakeLockSupported]);

  const restartRecording = async () => {
    try {
      // Stop existing stream and recorder
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }

      // Get new stream
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          googEchoCancellation: true,
          googAutoGainControl: true,
          googNoiseSuppression: true,
          googHighpassFilter: true
        }
      });

      streamRef.current = stream;
      
      // Reinitialize audio context if needed
      if (!audioContextRef.current || audioContextRef.current.state === 'closed') {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 16000,
          latencyHint: 'interactive',
          iosLatencyHint: 0.01
        });
      }

      const source = audioContextRef.current.createMediaStreamSource(stream);
      const destination = audioContextRef.current.createMediaStreamDestination();
      source.connect(destination);

      // Create new media recorder
      const mimeType = MediaRecorder.isTypeSupported('audio/webm;codecs=opus')
        ? 'audio/webm;codecs=opus'
        : 'audio/mp4';

      const mediaRecorder = new MediaRecorder(destination.stream, {
        mimeType,
        audioBitsPerSecond: 24000
      });

      // Keep existing chunks
      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(chunksRef.current, { type: mimeType });
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }
        await handleRecordingComplete(audioBlob);
      };

      mediaRecorder.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        if (isRecording) {
          stopRecording();
        }
      };

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start();
      setIsPaused(false);
      
      return true;
    } catch (error) {
      console.error('Error restarting recording:', error);
      return false;
    }
  };

  // Handle beforeunload
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isRecording) {
        e.preventDefault();
        e.returnValue = 'Recording in progress. Are you sure you want to leave?';
        return e.returnValue;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isRecording]);

  // Handle audio interruptions
  useEffect(() => {
    const handleAudioInterruption = async () => {
      if (isRecording && !isPaused) {
        console.log('Audio interrupted, pausing recording...');
        togglePause();
      }
    };

    // iOS specific events
    document.addEventListener('pause', handleAudioInterruption);
    document.addEventListener('resign', handleAudioInterruption);
    
    // Android specific events
    if ('ondevicelight' in window) {
      window.addEventListener('devicelight', handleAudioInterruption);
    }

    return () => {
      document.removeEventListener('pause', handleAudioInterruption);
      document.removeEventListener('resign', handleAudioInterruption);
      if ('ondevicelight' in window) {
        window.removeEventListener('devicelight', handleAudioInterruption);
      }
    };
  }, [isRecording, isPaused]);

  // Retry logic for failed recordings
  const retryRecording = async (retryCount = 0, maxRetries = 3) => {
    try {
      if (retryCount >= maxRetries) {
        throw new Error('Max retries reached');
      }

      await startRecording();
    } catch (error) {
      console.error(`Recording attempt ${retryCount + 1} failed:`, error);
      
      if (retryCount < maxRetries) {
        console.log(`Retrying recording... (${retryCount + 1}/${maxRetries})`);
        setTimeout(() => retryRecording(retryCount + 1, maxRetries), 1000);
      } else {
        console.error('All recording attempts failed');
        // Handle final failure
        setIsFullScreen(false);
        cleanup();
      }
    }
  };

  // Detect standalone mode (PWA)
  useEffect(() => {
    const isIOSStandalone = window.navigator.standalone;
    const isOtherStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(isIOSStandalone || isOtherStandalone);
    
    console.log('PWA Status:', {
      isIOSStandalone,
      isOtherStandalone,
      navigator: window.navigator,
      standalone: window.navigator.standalone
    });
  }, []);

  // Handle mobile detection
  useEffect(() => {
    const checkMobile = () => {
      // Check for iOS specifically
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isMobileView = window.innerWidth < 768 || isIOS;
      console.log('Device info:', {
        userAgent: navigator.userAgent,
        isIOS,
        width: window.innerWidth,
        isMobileView,
        isStandalone
      });
      setIsMobile(isMobileView);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [isStandalone]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }
      cleanup();
    };
  }, []);

  // Log component state changes
  useEffect(() => {
    console.log('Component state:', {
      isMobile,
      isRecording,
      isProcessing,
      showSuccess,
      isFullScreen,
      hasPermission
    });
  }, [isMobile, isRecording, isProcessing, showSuccess, isFullScreen, hasPermission]);

  // Don't show on voice notes page, subscription page, or full screen note page
  if (location.pathname.startsWith('/voice-notes') || 
      location.pathname.startsWith('/subscription') ||
      location.pathname.startsWith('/ideas/') || // This will hide it on FullScreenNote
      location.pathname.startsWith('/content-strategy') ||
      location.pathname.startsWith('/code-to-visual')
  ) {
    return null;
  }

  const requestPermission = async () => {
    try {
      // For iOS PWA, we need to be more explicit with constraints
      const constraints = {
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          // iOS specific
          googEchoCancellation: true,
          googAutoGainControl: true,
          googNoiseSuppression: true,
          googHighpassFilter: true
        }
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      
      // Store permission state
      setHasPermission(true);
      
      // Cleanup test stream
      stream.getTracks().forEach(track => track.stop());
      
      return true;
    } catch (error) {
      console.error('Permission error:', error);
      setHasPermission(false);
      return false;
    }
  };

  const startRecording = async () => {
    try {
      console.log('Starting recording flow...', { isStandalone });
      
      // Clear any previous states
      setShowSuccess(false);
      setTranscriptionText('');
      setLastVoiceNoteId(null);
      setShowTranscriptionBox(false);
      
      if (isMobile || isStandalone) {
        setIsInitializing(true);
        console.log('Setting fullscreen for mobile/PWA');
        setIsFullScreen(true);
      }

      // Check/request permission first
      const permitted = hasPermission || await requestPermission();
      if (!permitted) {
        console.error('No microphone permission');
        setIsFullScreen(false);
        setIsInitializing(false);
        return;
      }

      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 16000,
          latencyHint: 'interactive',
          iosLatencyHint: 0.01
        });
      }
      
      if (audioContextRef.current?.state === 'suspended') {
        await audioContextRef.current.resume();
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: 16000,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          googEchoCancellation: true,
          googAutoGainControl: true,
          googNoiseSuppression: true,
          googHighpassFilter: true
        }
      });
      
      streamRef.current = stream;

      const source = audioContextRef.current.createMediaStreamSource(stream);
      const destination = audioContextRef.current.createMediaStreamDestination();
      source.connect(destination);

      const mimeType = MediaRecorder.isTypeSupported('audio/webm;codecs=opus')
        ? 'audio/webm;codecs=opus'
        : 'audio/mp4';

      const mediaRecorder = new MediaRecorder(destination.stream, {
        mimeType,
        audioBitsPerSecond: 24000
      });

      mediaRecorderRef.current = mediaRecorder;
      chunksRef.current = [];

      mediaRecorder.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        if (isRecording) {
          stopRecording();
        }
      };

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(chunksRef.current, { type: mimeType });
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }
        await handleRecordingComplete(audioBlob);
      };

      mediaRecorder.start();
      setIsRecording(true);
      setIsPaused(false);
      setIsInitializing(false);
      setRecordingTime(0);
      startTimer();
    } catch (error) {
      console.error('Error starting recording:', error);
      setIsFullScreen(false);
      setIsInitializing(false);
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      try {
        if (mediaRecorderRef.current.state !== 'inactive') {
          mediaRecorderRef.current.stop();
        }
        setIsRecording(false);
        setIsPaused(false);
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }
        cleanup();
      } catch (error) {
        console.error('Error stopping recording:', error);
        cleanup();
      }
    }
  };

  const cancelRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }
      setIsRecording(false);
      setIsPaused(false);
      setIsFullScreen(false);
      stopTimer();
      setRecordingTime(0);
      cleanup();
    }
  };

  const togglePause = () => {
    if (mediaRecorderRef.current) {
      if (isPaused) {
        mediaRecorderRef.current.resume();
        startTimer();
      } else {
        mediaRecorderRef.current.pause();
        stopTimer();
      }
      setIsPaused(!isPaused);
    }
  };

  const handleRecordingComplete = async (audioBlob) => {
    try {
      setIsProcessing(true);
      
      const formData = new FormData();
      formData.append('audio', audioBlob);

      const response = await api.post('/api/voice-notes/transcribe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (onRecordingComplete) {
        await onRecordingComplete(response.data._id);
      }

      setLastVoiceNoteId(response.data._id);
      setTranscriptionText(response.data.transcription || '');
      
      // Show success state without auto-dismiss
      setShowSuccess(true);
      if (!isMobile) {
        setShowTranscriptionBox(true);
      }
    } catch (error) {
      console.error('Error handling recording completion:', error);
      addNotification('Error processing recording. Please try again.', 'error');
      setIsFullScreen(false);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCloseSuccess = () => {
    setShowSuccess(false);
    setLastVoiceNoteId(null);
    setTranscriptionText('');
    setIsFullScreen(false);
  };

  const viewTranscription = () => {
    if (lastVoiceNoteId) {
      navigate('/voice-notes', { 
        state: { 
          newVoiceNoteId: lastVoiceNoteId,
          justUploaded: true
        } 
      });
    }
  };

  // Add notification helper
  const addNotification = (message, type = 'error') => {
    const options = {
      duration: 4000,
      position: 'bottom-center',
      style: {
        background: isDarkMode 
          ? type === 'error' 
            ? 'rgba(239, 68, 68, 0.1)' 
            : type === 'warning'
              ? 'rgba(245, 158, 11, 0.1)'
              : 'rgba(0, 0, 0, 0.8)'
          : type === 'error'
            ? '#FEE2E2'
            : type === 'warning'
              ? '#FEF3C7'
              : '#FFFFFF',
        color: isDarkMode
          ? type === 'error'
            ? '#FCA5A5'
            : type === 'warning'
              ? '#FCD34D'
              : '#E5E7EB'
          : type === 'error'
            ? '#991B1B'
            : type === 'warning'
              ? '#92400E'
              : '#1F2937',
        border: `1px solid ${
          isDarkMode
            ? type === 'error'
              ? 'rgba(239, 68, 68, 0.2)'
              : type === 'warning'
                ? 'rgba(245, 158, 11, 0.2)'
                : 'rgba(75, 85, 99, 0.2)'
            : type === 'error'
              ? '#FCA5A5'
              : type === 'warning'
                ? '#FCD34D'
                : '#E5E7EB'
        }`,
        padding: '12px 16px',
        borderRadius: '8px',
      },
    };

    switch (type) {
      case 'error':
        toast.error(message, options);
        break;
      case 'warning':
        toast(message, {
          ...options,
          icon: '⚠️',
        });
        break;
      default:
        toast(message, options);
    }
  };

  const handleDragStart = (e, data) => {
    isDraggingRef.current = false;
    dragStartRef.current = {
      x: data.x,
      y: data.y,
      time: Date.now()
    };
  };

  const handleDrag = (e, data) => {
    const dragDistance = Math.sqrt(
      Math.pow(data.x - dragStartRef.current.x, 2) + 
      Math.pow(data.y - dragStartRef.current.y, 2)
    );
    
    // If dragged more than threshold, consider it a drag
    if (dragDistance > (isMobile ? 3 : 5)) {
      isDraggingRef.current = true;
    }
  };

  const handleDragStop = (e, data) => {
    const dragTime = Date.now() - dragStartRef.current.time;
    const dragDistance = Math.sqrt(
      Math.pow(data.x - dragStartRef.current.x, 2) + 
      Math.pow(data.y - dragStartRef.current.y, 2)
    );

    // On mobile, if it was a short tap (less than 200ms) and minimal movement,
    // consider it a click
    if (isMobile && dragTime < 200 && dragDistance < 3) {
      isDraggingRef.current = false;
      if (!isRecording && !isProcessing) {
        startRecording();
      }
      return;
    }

    // For desktop or mobile drags, reset the drag state
    setTimeout(() => {
      isDraggingRef.current = false;
    }, 0);
  };

  const handleClick = (e) => {
    // Prevent click from firing if we just finished dragging
    if (isDraggingRef.current) {
      e.stopPropagation();
      return;
    }

    if (!isRecording && !isProcessing) {
      startRecording();
    }
  };

  const renderContent = () => {
    // Don't render floating recorder when in fullscreen mode on desktop
    if (isFullScreen && !isMobile) {
      return null;
    }

    if ((isRecording || isProcessing || showSuccess) && isMobile) {
      return null;
    }

    const content = (
      <div 
        ref={nodeRef}
        className={`
          fixed z-50
          bottom-36 right-4 
          sm:bottom-16 sm:right-6
          md:bottom-6 md:right-6
          touch-none
        `}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={isRecording ? 'recording' : isProcessing ? 'processing' : 'idle'}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            whileTap={{ scale: 0.95 }}
            whileHover={{ scale: 1.05 }}
            className={`
              drag-handle
              ${buttonVariants.base}
              relative
              ${isProcessing 
                ? buttonVariants.processing[isDarkMode ? 'dark' : 'light']
                : isRecording
                  ? buttonVariants.recording[isDarkMode ? 'dark' : 'light']
                  : isDarkMode
                    ? 'bg-zinc-900/70 text-indigo-400 hover:bg-zinc-800/80'
                    : 'bg-white/90 text-indigo-600 hover:bg-white/95'
              }
              ${!isProcessing ? 'cursor-grab active:cursor-grabbing' : 'cursor-default'}
              group touch-none
              transition-all duration-300
            `}
            onClick={handleClick}
          >
            {!isMobile ? (
              <div className={`
                flex flex-col w-full
                ${isRecording 
                  ? isMobile ? 'w-fit' : 'min-w-[300px]'
                  : isProcessing
                    ? isMobile ? 'w-fit' : 'min-w-[200px]'
                    : isMobile ? 'w-fit' : 'min-w-[120px]'
                }
              `}>
                {isRecording ? (
                  <div className="flex flex-col">
                    {/* Top Row: Timer and Recording Indicator */}
                    <div className="flex items-center justify-between py-3 px-4">
                      <div className="flex items-center gap-3">
                        <div className="relative flex items-center justify-center w-8 h-8">
                          <RecordIcon className="w-4 h-4 text-red-400 relative z-10" />
                          <motion.div 
                            className="absolute inset-0 rounded-full bg-red-400/20"
                            animate={{
                              scale: [1, 1.5, 1],
                              opacity: [0.2, 0.4, 0.2]
                            }}
                            transition={{
                              duration: 2,
                              repeat: Infinity,
                              ease: "easeInOut"
                            }}
                          />
                        </div>
                        <span className="font-mono text-base tabular-nums text-white font-medium tracking-tight">
                          {formatTime(recordingTime)}
                        </span>
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsFullScreen(true);
                        }}
                        className="p-2 rounded-md transition-colors hover:bg-zinc-800/50 text-zinc-400 hover:text-zinc-300"
                      >
                        <ArrowsPointingOutIcon className="w-4 h-4" />
                      </button>
                    </div>

                    {/* Bottom Row: Controls */}
                    <div className="flex items-center justify-between py-3 px-4">
                      <div className="flex items-center gap-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            togglePause();
                          }}
                          className="p-2 rounded-md transition-colors hover:bg-zinc-800/50 text-zinc-400 hover:text-zinc-300"
                        >
                          {isPaused ? (
                            <PlayIcon className="w-4 h-4" />
                          ) : (
                            <PauseIcon className="w-4 h-4" />
                          )}
                        </button>
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          stopRecording();
                        }}
                        className="flex items-center gap-2 px-3 py-1.5 rounded-md bg-red-500/10 hover:bg-red-500/20 text-red-400 transition-colors"
                      >
                        <StopIcon className="w-4 h-4" />
                        <span className="text-xs font-medium">Done</span>
                      </button>
                    </div>
                  </div>
                ) : isProcessing ? (
                  <div className="flex items-center justify-between py-3 px-4">
                    <div className="flex items-center gap-3">
                      <AnimatedLoader size={24} color="#60A5FA" />
                      <span className="text-sm font-medium text-blue-400">Processing...</span>
                    </div>
                  </div>
                ) : (
                  <div className={`
                    flex items-center justify-between 
                    ${isMobile ? 'py-2 px-3 h-10' : 'py-1.5 px-2 h-8'}
                  `}>
                    <div className={`
                      flex items-center
                      ${isMobile ? 'gap-2' : 'gap-2'}
                      cursor-pointer
                    `}>
                      <div className={`
                        relative flex items-center justify-center
                        ${isMobile ? 'w-5 h-5' : 'w-4 h-4'}
                      `}>
                        <MicrophoneIcon className={`
                          ${isMobile ? 'w-4 h-4' : 'w-3.5 h-3.5'}
                          ${isDarkMode ? 'text-zinc-400' : 'text-zinc-600'}
                          relative z-10
                        `} />
                        <motion.div 
                          className={`
                            absolute inset-0 rounded-full
                            ${isDarkMode ? 'bg-zinc-400/5' : 'bg-zinc-100/80'}
                          `}
                          animate={{
                            scale: [1, 1.1, 1]
                          }}
                          transition={{
                            duration: 3,
                            repeat: Infinity,
                            ease: "easeInOut"
                          }}
                        />
                      </div>
                      {!isMobile && (
                        <span className={`
                          text-xs font-medium
                          ${isDarkMode ? 'text-zinc-400' : 'text-zinc-600'}
                        `}>
                          Record
                        </span>
                      )}
                    </div>
                    <div className={`
                      drag-handle cursor-grab active:cursor-grabbing
                      ${isDarkMode ? 'border-l border-zinc-700/30' : 'border-l border-zinc-300/30'}
                      ${isMobile ? 'pl-2 ml-2' : 'pl-2 ml-2'}
                    `}>
                      <DragHandleIcon className={`
                        ${isMobile ? 'w-4 h-4' : 'w-3.5 h-3.5'}
                        ${isDarkMode ? 'text-zinc-500' : 'text-zinc-400'}
                        group-hover:text-zinc-300 transition-colors duration-200
                      `} />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={`
                flex flex-col w-full
                ${isRecording 
                  ? isMobile ? 'w-fit' : 'min-w-[300px]'
                  : isProcessing
                    ? isMobile ? 'w-fit' : 'min-w-[200px]'
                    : isMobile ? 'w-fit' : 'min-w-[120px]'
                }
              `}>
                {isRecording ? (
                  <div className="flex flex-col">
                    {/* Top Row: Timer and Recording Indicator */}
                    <div className="flex items-center justify-between py-3 px-4">
                      <div className="flex items-center gap-3">
                        <div className="relative flex items-center justify-center w-8 h-8">
                          <RecordIcon className="w-4 h-4 text-red-400 relative z-10" />
                          <motion.div 
                            className="absolute inset-0 rounded-full bg-red-400/20"
                            animate={{
                              scale: [1, 1.5, 1],
                              opacity: [0.2, 0.4, 0.2]
                            }}
                            transition={{
                              duration: 2,
                              repeat: Infinity,
                              ease: "easeInOut"
                            }}
                          />
                        </div>
                        <span className="font-mono text-base tabular-nums text-white font-medium tracking-tight">
                          {formatTime(recordingTime)}
                        </span>
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsFullScreen(true);
                        }}
                        className="p-2 rounded-md transition-colors hover:bg-zinc-800/50 text-zinc-400 hover:text-zinc-300"
                      >
                        <ArrowsPointingOutIcon className="w-4 h-4" />
                      </button>
                    </div>

                    {/* Bottom Row: Controls */}
                    <div className="flex items-center justify-between py-3 px-4">
                      <div className="flex items-center gap-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            togglePause();
                          }}
                          className="p-2 rounded-md transition-colors hover:bg-zinc-800/50 text-zinc-400 hover:text-zinc-300"
                        >
                          {isPaused ? (
                            <PlayIcon className="w-4 h-4" />
                          ) : (
                            <PauseIcon className="w-4 h-4" />
                          )}
                        </button>
                      </div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          stopRecording();
                        }}
                        className="flex items-center gap-2 px-3 py-1.5 rounded-md bg-red-500/10 hover:bg-red-500/20 text-red-400 transition-colors"
                      >
                        <StopIcon className="w-4 h-4" />
                        <span className="text-xs font-medium">Done</span>
                      </button>
                    </div>
                  </div>
                ) : isProcessing ? (
                  <div className="flex items-center justify-between py-3 px-4">
                    <div className="flex items-center gap-3">
                      <AnimatedLoader size={24} color="#60A5FA" />
                      <span className="text-sm font-medium text-blue-400">Processing...</span>
                    </div>
                  </div>
                ) : (
                  <div className={`
                    flex items-center justify-between 
                    ${isMobile ? 'py-2 px-3 h-10' : 'py-1.5 px-2 h-8'}
                  `}>
                    <div className={`
                      flex items-center
                      ${isMobile ? 'gap-2' : 'gap-2'}
                      cursor-pointer
                    `}>
                      <div className={`
                        relative flex items-center justify-center
                        ${isMobile ? 'w-5 h-5' : 'w-4 h-4'}
                      `}>
                        <MicrophoneIcon className={`
                          ${isMobile ? 'w-4 h-4' : 'w-3.5 h-3.5'}
                          ${isDarkMode ? 'text-zinc-400' : 'text-zinc-600'}
                          relative z-10
                        `} />
                        <motion.div 
                          className={`
                            absolute inset-0 rounded-full
                            ${isDarkMode ? 'bg-zinc-400/5' : 'bg-zinc-100/80'}
                          `}
                          animate={{
                            scale: [1, 1.1, 1]
                          }}
                          transition={{
                            duration: 3,
                            repeat: Infinity,
                            ease: "easeInOut"
                          }}
                        />
                      </div>
                      {!isMobile && (
                        <span className={`
                          text-xs font-medium
                          ${isDarkMode ? 'text-zinc-400' : 'text-zinc-600'}
                        `}>
                          Record
                        </span>
                      )}
                    </div>
                    <div className={`
                      drag-handle cursor-grab active:cursor-grabbing
                      ${isDarkMode ? 'border-l border-zinc-700/30' : 'border-l border-zinc-300/30'}
                      ${isMobile ? 'pl-2 ml-2' : 'pl-2 ml-2'}
                    `}>
                      <DragHandleIcon className={`
                        ${isMobile ? 'w-4 h-4' : 'w-3.5 h-3.5'}
                        ${isDarkMode ? 'text-zinc-500' : 'text-zinc-400'}
                        group-hover:text-zinc-300 transition-colors duration-200
                      `} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    );

    return (
      <Draggable 
        bounds={isMobile ? "body" : "parent"}
        handle=".drag-handle"
        onStart={handleDragStart}
        onDrag={handleDrag}
        onStop={handleDragStop}
        defaultPosition={{ x: 0, y: 0 }}
        position={undefined}
        nodeRef={nodeRef}
      >
        {content}
      </Draggable>
    );
  };

  return (
    <>
      <AnimatePresence>
        {((isRecording || isProcessing || showSuccess || isInitializing) && (isMobile || isFullScreen)) ? (
          <MobileFullScreenUI 
            isDarkMode={isDarkMode}
            isProcessing={isProcessing}
            isInitializing={isInitializing}
            showSuccess={showSuccess}
            recordingTime={recordingTime}
            isPaused={isPaused}
            formatTime={formatTime}
            togglePause={togglePause}
            stopRecording={stopRecording}
            cancelRecording={cancelRecording}
            lastVoiceNoteId={lastVoiceNoteId}
            viewTranscription={viewTranscription}
            transcriptionText={transcriptionText}
            onCloseSuccess={handleCloseSuccess}
            onExitFullScreen={() => {
              if (!isMobile) {
                setIsFullScreen(false);
                if (isRecording) {
                  // Don't stop the recording, just switch the UI
                }
              } else {
                cancelRecording();
              }
            }}
            isMobile={isMobile}
            isRecording={isRecording}
          />
        ) : null}
      </AnimatePresence>

      {/* Desktop Transcription Box - Only show when not in fullscreen */}
      <AnimatePresence>
        {showTranscriptionBox && !isMobile && !isFullScreen && (
          <FloatingTranscriptionBox
            isDarkMode={isDarkMode}
            transcriptionText={transcriptionText}
            onClose={() => {
              setShowTranscriptionBox(false);
              setTranscriptionText('');
              setLastVoiceNoteId(null);
            }}
            onViewFull={viewTranscription}
          />
        )}
      </AnimatePresence>

      {renderContent()}
    </>
  );
};

export default FloatingVoiceRecorder; 
