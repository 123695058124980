import React, { useState, useContext, useEffect } from 'react';
import { EnvelopeIcon, ArrowRightOnRectangleIcon, PencilSquareIcon, CheckIcon, XMarkIcon, ChevronRightIcon, EyeIcon, EyeSlashIcon, UserIcon } from '@heroicons/react/24/outline';
import { AuthContext } from '../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { updateUserProfile } from '../services/userService';
import { disconnectLinkedIn, isLinkedInConnected, clearLinkedInCache } from '../services/linkedinPublishService';
import api from '../api';
import AnimatedLoader from './common/AnimatedLoader';
import { useTheme } from '../context/ThemeContext';
import { Dialog } from '@headlessui/react'
import './settings-scrollbar.css';
import toast from 'react-hot-toast';
import { getLinkedInAuthUrl, saveState, generateCodeVerifier } from '../services/linkedinAuthService';
import supabase from '../services/supabaseAuth';
import { motion } from 'framer-motion';
import InitialsAvatar from './common/InitialsAvatar';

const AVATAR_CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
const DEFAULT_AVATAR = 'https://ghostscompany.s3.amazonaws.com/userprofile-placeholder.png';

// Add new styles for animations
const fadeInAnimation = `
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

// Update the LogoutConfirmationModal with minimal design
const LogoutConfirmationModal = ({ isOpen, onClose, onConfirm, isDarkMode }) => {
  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 backdrop-blur-sm z-50
      ${isDarkMode 
        ? 'bg-black/70' 
        : 'bg-white/70'}`}>
      <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[320px] 
        ${isDarkMode 
          ? 'bg-black/90 border border-white/[0.08]' 
          : 'bg-white/90 border border-black/[0.08]'} 
        backdrop-blur-xl rounded-2xl p-6 transform transition-all duration-300
        shadow-xl`}>
        <div className="space-y-4">
          <h3 className={`text-base font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
            Sign out of your account?
          </h3>
          <div className="space-y-2">
            <button
              onClick={onConfirm}
              className="w-full py-2 text-sm font-medium text-white bg-red-500/90 
                rounded-full transition-all duration-200 hover:bg-red-500"
            >
              Sign Out
            </button>
            <button
              onClick={onClose}
              className={`w-full py-2 text-sm font-medium rounded-full transition-all duration-200
                ${isDarkMode 
                  ? 'bg-white/10 text-white hover:bg-white/20' 
                  : 'bg-black/5 text-black hover:bg-black/10'}`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuccessModal = ({ isOpen, onClose, message, isDarkMode }) => {
  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 backdrop-blur-sm z-50
      ${isDarkMode 
        ? 'bg-black/70' 
        : 'bg-white/70'}`}>
      <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[320px] 
        ${isDarkMode 
          ? 'bg-black/90 border border-white/[0.08]' 
          : 'bg-white/90 border border-black/[0.08]'} 
        backdrop-blur-xl rounded-2xl p-6 transform transition-all duration-300
        shadow-xl`}>
        <div className="flex flex-col items-center space-y-4">
          {/* Success Icon with animated ring */}
          <div className="relative">
            <div className={`absolute inset-0 rounded-full 
              ${isDarkMode ? 'bg-green-500/20' : 'bg-green-100'} 
              animate-[ping_1s_cubic-bezier(0,0,0.2,1)_1]`} 
            />
            <div className={`w-14 h-14 rounded-full flex items-center justify-center
              ${isDarkMode ? 'bg-green-500/20' : 'bg-green-100'}`}>
              <CheckIcon className={`w-7 h-7 
                ${isDarkMode ? 'text-green-400' : 'text-green-600'}`} />
            </div>
          </div>
          
          {/* Message */}
          <div className="space-y-1 text-center">
            <h3 className={`text-base font-semibold
              ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Success!
            </h3>
            <p className={`text-sm
              ${isDarkMode ? 'text-white/60' : 'text-gray-600'}`}>
              {message}
            </p>
          </div>
          
          {/* Done Button */}
          <button
            onClick={onClose}
            className={`w-full py-2 text-sm font-medium rounded-full transition-all duration-200
              ${isDarkMode 
                ? 'bg-green-500/20 text-green-400 hover:bg-green-500/30' 
                : 'bg-green-600 text-white hover:bg-green-700'}`}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

const PasswordStrengthIndicator = ({ password, isDarkMode }) => {
  const requirements = [
    { regex: /.{8,}/, text: "At least 8 characters" },
    { regex: /[A-Z]/, text: "Uppercase letter" },
    { regex: /[a-z]/, text: "Lowercase letter" },
    { regex: /[0-9]/, text: "Number" },
    { regex: /[!@#$%^&*]/, text: "Special character" },
  ];

  if (!password) return null;

  return (
    <div className="mt-2 space-y-2">
      <div className={`text-xs font-medium ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
        Password strength:
      </div>
      {requirements.map((req, index) => (
        <div key={index} className="flex items-center text-xs">
          {req.regex.test(password) ? (
            <CheckIcon className="h-3 w-3 text-green-500 mr-2" />
          ) : (
            <XMarkIcon className="h-3 w-3 text-red-500 mr-2" />
          )}
          <span className={isDarkMode ? 'text-white/50' : 'text-black/50'}>
            {req.text}
          </span>
        </div>
      ))}
    </div>
  );
};

const PasswordSection = ({ isDarkMode, onSuccess }) => {
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const [error, setError] = useState('');

  const validatePassword = (password) => {
    if (password.length < 8) return "Password must be at least 8 characters long";
    if (!/[A-Z]/.test(password)) return "Password must contain at least one uppercase letter";
    if (!/[a-z]/.test(password)) return "Password must contain at least one lowercase letter";
    if (!/[0-9]/.test(password)) return "Password must contain at least one number";
    if (!/[!@#$%^&*]/.test(password)) return "Password must contain at least one special character";
    return null;
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validate new password
    const passwordError = validatePassword(passwordData.newPassword);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      setError("New passwords don't match");
      return;
    }

    try {
      const response = await api.put('/api/user/change-password', {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
      });

      // Clear form
      setPasswordData({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      });
      setShowPasswords({
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      });

      // Call onSuccess with message
      onSuccess("Password updated successfully");
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error changing password';
      setError(errorMessage);
    }
  };

  return (
    <form onSubmit={handlePasswordSubmit} className="space-y-4">
      {error && (
        <div className={`rounded-lg p-2 text-sm ${
          isDarkMode ? 'bg-red-500/20 text-red-400' : 'bg-red-50 text-red-500'
        }`}>
          {error}
        </div>
      )}
      
      {/* Password Fields */}
      {[
        { name: 'currentPassword', label: 'Current Password' },
        { name: 'newPassword', label: 'New Password' },
        { name: 'confirmNewPassword', label: 'Confirm New Password' }
      ].map(({ name, label }) => (
        <div key={name} className="space-y-1">
          <label 
            htmlFor={name}
            className={`block text-xs font-medium ${
              isDarkMode ? 'text-white/60' : 'text-black/60'
            }`}
          >
            {label}
          </label>
          <div className="relative">
            <input
              type={showPasswords[name] ? "text" : "password"}
              id={name}
              name={name}
              value={passwordData[name]}
              onChange={handlePasswordChange}
              className={`w-full px-3 py-2 text-sm rounded-lg transition-colors
                ${isDarkMode 
                  ? 'bg-white/10 text-white focus:bg-white/20' 
                  : 'bg-black/5 text-black focus:bg-black/10'}
                border-0 focus:ring-1 focus:ring-blue-500/30`}
              required
            />
            <button
              type="button"
              onClick={() => togglePasswordVisibility(name)}
              className={`absolute right-2 top-1/2 -translate-y-1/2 p-1 rounded-md
                ${isDarkMode ? 'hover:bg-white/10' : 'hover:bg-black/5'}`}
            >
              {showPasswords[name] ? (
                <EyeIcon className="w-4 h-4 opacity-60" />
              ) : (
                <EyeSlashIcon className="w-4 h-4 opacity-60" />
              )}
            </button>
          </div>
        </div>
      ))}

      {/* Password Strength Indicator */}
      {passwordData.newPassword && (
        <PasswordStrengthIndicator 
          password={passwordData.newPassword} 
          isDarkMode={isDarkMode}
        />
      )}

      {/* Submit Button */}
      <button
        type="submit"
        className={`w-full mt-4 py-2 text-sm font-medium rounded-lg transition-all duration-200
          ${isDarkMode 
            ? 'bg-white text-black hover:bg-white/90' 
            : 'bg-black text-white hover:bg-black/90'}`}
      >
        Update Password
      </button>
    </form>
  );
};

const LinkedInLogo = ({ className = "w-5 h-5" }) => (
  <svg className={className} viewBox="0 0 24 24" fill="currentColor">
    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
  </svg>
);

const ConnectionStatus = ({ isConnected, isDarkMode }) => (
  <motion.div 
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    className="flex items-center space-x-2"
  >
    <span className={`text-xs ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
      {isConnected ? 'Connected' : 'Not connected'}
    </span>
    <motion.div 
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      className={`w-2 h-2 rounded-full ${
        isConnected 
          ? 'bg-green-500' 
          : isDarkMode 
            ? 'bg-white/20' 
            : 'bg-black/20'
      }`}
    />
  </motion.div>
);

// Add this animation variant for smoother list animations
const listItemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: { opacity: 1, x: 0 }
};

// Add this new component near the top with other modal components
const LinkedInDisconnectModal = ({ isOpen, onClose, onConfirm, isDarkMode, isLoading }) => {
  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 backdrop-blur-sm z-50
      ${isDarkMode 
        ? 'bg-black/70' 
        : 'bg-white/70'}`}>
      <div className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[320px] 
        ${isDarkMode 
          ? 'bg-black/90 border border-white/[0.08]' 
          : 'bg-white/90 border border-black/[0.08]'} 
        backdrop-blur-xl rounded-2xl p-6 transform transition-all duration-300
        shadow-xl`}>
        <div className="space-y-4">
          {/* Icon */}
          <div className="flex justify-center">
            <div className={`p-3 rounded-full 
              ${isDarkMode ? 'bg-white/10' : 'bg-black/5'}`}>
              <LinkedInLogo className={`w-6 h-6 
                ${isDarkMode ? 'text-white/60' : 'text-black/60'}`} />
            </div>
          </div>

          {/* Title and Description */}
          <div className="text-center space-y-2">
            <h3 className={`text-base font-medium 
              ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              Disconnect LinkedIn Account?
            </h3>
            <p className={`text-sm 
              ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
              You won't be able to publish posts directly to LinkedIn after disconnecting.
            </p>
          </div>

          {/* Buttons */}
          <div className="space-y-2">
            <button
              onClick={onConfirm}
              disabled={isLoading}
              className={`relative w-full py-2 text-sm font-medium 
                ${isDarkMode 
                  ? 'bg-red-500/20 text-red-400 hover:bg-red-500/30' 
                  : 'bg-red-50 text-red-600 hover:bg-red-100'}
                rounded-full transition-all duration-200`}
            >
              <span className={isLoading ? 'opacity-0' : 'opacity-100'}>
                Disconnect Account
              </span>
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <AnimatedLoader size={24} color={isDarkMode ? "white" : "black"} />
                </div>
              )}
            </button>
            <button
              onClick={onClose}
              disabled={isLoading}
              className={`w-full py-2 text-sm font-medium rounded-full transition-all duration-200
                ${isDarkMode 
                  ? 'bg-white/10 text-white hover:bg-white/20' 
                  : 'bg-black/5 text-black hover:bg-black/10'}`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add this component near other component definitions
const LinkedInSettingsSkeletonLoader = ({ isDarkMode }) => {
  return (
    <div className="space-y-4 animate-pulse">
      {/* Header Skeleton */}
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <div className={`w-4 h-4 rounded ${
            isDarkMode ? 'bg-white/10' : 'bg-black/10'
          }`} />
          <div className={`h-3 rounded ${
            isDarkMode ? 'bg-white/10' : 'bg-black/10'
          } w-24`} />
        </div>
        <div className="flex items-center space-x-2">
          <div className={`h-3 rounded ${
            isDarkMode ? 'bg-white/10' : 'bg-black/10'
          } w-16`} />
          <div className={`w-2 h-2 rounded-full ${
            isDarkMode ? 'bg-white/10' : 'bg-black/10'
          }`} />
        </div>
      </div>

      {/* Profile Info Skeleton */}
      <div className={`p-4 rounded-lg ${isDarkMode ? 'bg-white/5' : 'bg-black/5'}`}>
        <div className="flex items-center space-x-3">
          {/* Avatar Skeleton */}
          <div className={`w-12 h-12 rounded-full ${
            isDarkMode ? 'bg-white/10' : 'bg-black/10'
          }`} />
          
          {/* Profile Text Skeleton */}
          <div className="space-y-2 flex-1">
            <div className={`h-4 rounded ${
              isDarkMode ? 'bg-white/10' : 'bg-black/10'
            } w-1/3`} />
            <div className={`h-3 rounded ${
              isDarkMode ? 'bg-white/10' : 'bg-black/10'
            } w-1/2`} />
          </div>
        </div>
      </div>

      {/* Features List Skeleton */}
      <div className="space-y-3">
        <div className={`h-4 rounded ${
          isDarkMode ? 'bg-white/10' : 'bg-black/10'
        } w-2/3`} />
        <div className="space-y-2">
          {[1, 2, 3].map((item) => (
            <div key={item} className="flex items-center space-x-2">
              <div className={`w-4 h-4 rounded ${
                isDarkMode ? 'bg-white/10' : 'bg-black/10'
              }`} />
              <div className={`h-3 rounded ${
                isDarkMode ? 'bg-white/10' : 'bg-black/10'
              } w-3/4`} />
            </div>
          ))}
        </div>
      </div>

      {/* Button Skeleton */}
      <div className="pt-2">
        <div className={`h-10 rounded-lg ${
          isDarkMode ? 'bg-white/10' : 'bg-black/10'
        } w-full`} />
      </div>
    </div>
  );
};

// Add these skeleton components near the top
const ProfileSkeletonLoader = ({ isDarkMode }) => {
  return (
    <div className={`p-4 rounded-2xl border relative animate-pulse
      ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}
    >
      <div className="flex items-center space-x-4">
        {/* Avatar Skeleton */}
        <div className={`w-16 h-16 rounded-full 
          ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
        />

        {/* Profile Info Skeleton */}
        <div className="flex-grow space-y-2">
          <div className={`h-4 rounded w-1/3 
            ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
          />
          <div className="flex items-center space-x-1">
            <div className={`w-3 h-3 rounded 
              ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
            />
            <div className={`h-3 rounded w-1/2 
              ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutSkeletonLoader = ({ isDarkMode }) => {
  return (
    <div className={`p-4 rounded-2xl border animate-pulse
      ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}
    >
      {/* Header */}
      <div className="flex justify-between items-center mb-3">
        <div className={`h-3 rounded w-16 
          ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
        />
      </div>

      {/* Content */}
      <div className="space-y-2">
        <div className={`h-4 rounded w-full 
          ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
        />
        <div className={`h-4 rounded w-3/4 
          ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
        />
        <div className={`h-4 rounded w-1/2 
          ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
        />
      </div>
    </div>
  );
};

const PasswordSkeletonLoader = ({ isDarkMode }) => {
  return (
    <div className={`p-4 rounded-2xl border animate-pulse
      ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}
    >
      <div className="flex justify-between items-center">
        <div className={`h-3 rounded w-20 
          ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
        />
        <div className={`w-3 h-3 rounded 
          ${isDarkMode ? 'bg-white/10' : 'bg-black/10'}`} 
        />
      </div>
    </div>
  );
};

const Settings = () => {
  const { user, logout, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(() => {
    if (location.state?.openLinkedIn) {
      return 'linkedin';
    }
    return 'profile';
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    about: user?.about || '',
  });
  const [linkedInProfile, setLinkedInProfile] = useState(null);
  const [isLinkedInLoading, setIsLinkedInLoading] = useState(true);
  const [isAvatarLoading, setIsAvatarLoading] = useState(true);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [isAboutExpanded, setIsAboutExpanded] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [successModal, setSuccessModal] = useState({ show: false, message: '' });
  const [isDisconnectingLinkedIn, setIsDisconnectingLinkedIn] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [linkedInStatus, setLinkedInStatus] = useState({
    isConnected: false,
    needsReconnect: false
  });
  const [isEditingAbout, setIsEditingAbout] = useState(false);

  const { isDarkMode } = useTheme();

  // Add LinkedIn profile fetch
  useEffect(() => {
    const fetchLinkedInProfile = async () => {
      try {
        setIsLinkedInLoading(true);
        const status = await isLinkedInConnected();
        
        // Update LinkedIn connection status
        setLinkedInStatus({
          isConnected: status.isConnected,
          needsReconnect: status.needsReconnect
        });
        
        if (status.isConnected && status.profile) {
          // Extract profile data from the response
          const profileData = status.profile.profile_data || status.profile;
          
          // Get name from the correct path in the data structure
          let fullName;
          if (profileData.name) {
            fullName = profileData.name;
          } else if (profileData.given_name && profileData.family_name) {
            fullName = `${profileData.given_name} ${profileData.family_name}`;
          } else if (status.profile.name) {
            fullName = status.profile.name;
          } else {
            fullName = 'LinkedIn User';
          }
          
          setLinkedInProfile({
            name: fullName,
            avatarUrl: profileData.picture || null
          });
        }
      } catch (error) {
        console.error('Error fetching LinkedIn profile:', error);
        // Set connection status to false on error
        setLinkedInStatus({
          isConnected: false,
          needsReconnect: true
        });
      } finally {
        setIsLinkedInLoading(false);
        setIsAvatarLoading(false);
      }
    };

    fetchLinkedInProfile();
  }, []);

  useEffect(() => {
    const checkEmailVerification = async () => {
      try {
        console.log('Checking email verification status...');
        const isVerified = user?.emailVerified || false;
        console.log('Is email verified:', isVerified);
        setIsEmailVerified(isVerified);
      } catch (error) {
        console.error('Error checking email verification:', error);
        toast.error('Failed to check email verification status');
      }
    };

    if (user?.email) {
      checkEmailVerification();
    }
  }, [user]);

  // Add About section handlers
  const handleEditAbout = () => setIsEditingAbout(true);
  
  const handleCancelAbout = () => {
    setIsEditingAbout(false);
    setEditedUser(prev => ({
      ...prev,
      about: user.about
    }));
  };

  const handleSaveAbout = async () => {
    try {
      const updatedUser = await updateUserProfile({ about: editedUser.about });
      setUser({ ...user, ...updatedUser });
      setIsEditingAbout(false);
    } catch (error) {
      console.error('Error updating about:', error.message);
    }
  };

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setIsEditing(false);
    setEditedUser({
      firstName: user.firstName,
      lastName: user.lastName,
      about: user.about,
    });
  };

  const handleSave = async () => {
    try {
      const updatedUser = await updateUserProfile(editedUser);
      setUser({ ...user, ...updatedUser });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error.message);
      // You might want to show an error message to the user here
      // For example: setErrorMessage(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser(prev => ({ ...prev, [name]: value }));
  };

  const handleDisconnectLinkedIn = async () => {
    try {
      setIsDisconnectingLinkedIn(true);
      await disconnectLinkedIn();
      clearLinkedInCache();
      setUser({ ...user, linkedinId: undefined });
      toast.success('LinkedIn account disconnected successfully');
      setShowDisconnectModal(false);
    } catch (error) {
      console.error('Error disconnecting LinkedIn:', error);
      toast.error('Failed to disconnect LinkedIn account');
    } finally {
      setIsDisconnectingLinkedIn(false);
    }
  };

  const handleLinkedInReconnect = async () => {
    try {
      // Generate new state with reconnect prefix
      const state = `reconnect-${Date.now()}-${Math.random().toString(36).substring(7)}`;
      const stateData = {
        state,
        timestamp: Date.now(),
        origin: window.location.origin,
        codeVerifier: generateCodeVerifier()
      };
      
      // Save state data
      const saved = saveState(stateData);
      if (!saved) {
        throw new Error('Failed to save authentication state');
      }
      
      // Get auth URL with state
      const authUrl = await getLinkedInAuthUrl(state);
      
      // Redirect to LinkedIn auth
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error initiating LinkedIn reconnection:', error);
      toast.error('Failed to initiate LinkedIn reconnection. Please try again.');
    }
  };

  // Update the LinkedIn section render logic
  const renderLinkedInSection = () => {
    if (isLinkedInLoading) {
      return <LinkedInSettingsSkeletonLoader isDarkMode={isDarkMode} />;
    }

    return (
      <div className="space-y-3">
        {/* Status Header */}
        <div className={`
          p-4 rounded-2xl border overflow-hidden w-full
          ${isDarkMode ? 'border-white/10' : 'border-black/5'}
        `}>
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <LinkedInLogo className={`w-4 h-4 transition-colors duration-200
                ${isDarkMode 
                  ? 'text-white/60 group-hover:text-white/80' 
                  : 'text-black/60 group-hover:text-black/80'}`} 
              />
              <span className={`text-xs font-medium tracking-wide uppercase
                ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                LinkedIn Integration
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <span className={`text-xs ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
                {linkedInStatus.isConnected ? 'Connected' : 'Not connected'}
              </span>
              <div className={`w-2 h-2 rounded-full ${
                linkedInStatus.isConnected 
                  ? 'bg-green-500' 
                  : isDarkMode 
                    ? 'bg-white/20' 
                    : 'bg-black/20'
              }`} />
            </div>
          </div>
        </div>

        {/* Features List */}
        <div className="space-y-3">
          <p className={`text-sm ${isDarkMode ? 'text-white/40' : 'text-black/40'}`}>
            {linkedInStatus.isConnected 
              ? 'Your LinkedIn account is connected. You can now:'
              : 'Connect your LinkedIn account to:'}
          </p>
          <ul className={`text-sm space-y-2 ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
            {[
              'Publish posts directly to LinkedIn',
              'Sync your professional network'
            ].map((feature, index) => (
              <motion.li 
                key={feature}
                variants={listItemVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: index * 0.1 }}
                className="flex items-center space-x-2"
              >
                {linkedInStatus.isConnected ? (
                  <CheckIcon className="w-4 h-4 text-green-500" />
                ) : (
                  <XMarkIcon className="w-4 h-4 text-red-500" />
                )}
                <span>{feature}</span>
              </motion.li>
            ))}
          </ul>
          
          <div className="pt-2">
            {linkedInStatus.isConnected && (
              <div className={`mb-3 p-3 rounded-lg text-xs
                ${isDarkMode 
                  ? 'bg-yellow-500/10 text-yellow-400/90' 
                  : 'bg-yellow-50 text-yellow-600'}`}
              >
                <p>Note: If you disconnect your LinkedIn account, you'll need to:</p>
                <ul className="mt-1 ml-4 list-disc space-y-0.5">
                  <li>Wait for 30 minutes before reconnecting</li>
                  <li>Log out and clear all browser cookies</li>
                  <li>Log back in before attempting to reconnect</li>
                </ul>
              </div>
            )}

            {linkedInStatus.isConnected ? (
              <button
                onClick={() => setShowDisconnectModal(true)}
                disabled={isDisconnectingLinkedIn}
                className={`group relative w-full px-3 py-2 text-sm font-medium rounded-lg 
                  transition-all duration-200 
                  ${isDarkMode 
                    ? 'bg-red-500/20 text-red-400 hover:bg-red-500/30' 
                    : 'bg-red-50 text-red-600 hover:bg-red-100'
                  }`}
              >
                <span className="flex items-center justify-center space-x-2">
                  <ArrowRightOnRectangleIcon className="w-4 h-4" />
                  <span>Disconnect Account</span>
                </span>
              </button>
            ) : (
              <button
                onClick={handleLinkedInReconnect}
                className={`w-full flex items-center justify-center space-x-2 
                  px-3 py-2 text-sm font-medium rounded-lg
                  bg-[#0A66C2] text-white hover:bg-[#0A66C2]/90
                  transition-all duration-200`}
              >
                <LinkedInLogo className="w-4 h-4" />
                <span>Connect with LinkedIn</span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Add loading states
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isAboutLoading, setIsAboutLoading] = useState(true);
  
  // Update useEffect to handle loading states
  useEffect(() => {
    const loadUserData = async () => {
      try {
        setIsProfileLoading(true);
        setIsAboutLoading(true);
        
        // Fetch user data
        const userResponse = await api.get('/api/user/profile');
        setUser(userResponse.data);
        
        // Simulate staggered loading for better UX
        setTimeout(() => setIsProfileLoading(false), 500);
        setTimeout(() => setIsAboutLoading(false), 800);
        
      } catch (error) {
        console.error('Error loading user data:', error);
        toast.error('Failed to load user data');
      }
    };

    loadUserData();
  }, []);

  return (
    <div className={`h-full flex flex-col ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
      {/* Minimal Header - Height 10 (40px) */}
      <div className={`h-10 flex items-center px-4 backdrop-blur-xl border-b 
        ${isDarkMode 
          ? 'bg-black/90 border-white/10' 
          : 'bg-white/90 border-black/5'}`}>
        <h2 className={`text-sm font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
          Settings
        </h2>
        <button 
          onClick={() => setShowLogoutConfirmation(true)}
          className={`ml-auto p-1.5 rounded-full transition-all duration-200 group
            ${isDarkMode 
              ? 'hover:bg-white/10' 
              : 'hover:bg-black/5'}`}
        >
          <ArrowRightOnRectangleIcon className={`w-4 h-4 transition-colors duration-200
            ${isDarkMode 
              ? 'text-white/60 group-hover:text-white/80' 
              : 'text-black/60 group-hover:text-black/80'}`} 
          />
        </button>
      </div>

      {/* Content Area */}
      <div className="flex-grow overflow-y-auto settings-scroll-area">
        <div className="max-w-2xl mx-auto p-4 space-y-4">
          {/* Profile Section */}
          {isProfileLoading ? (
            <ProfileSkeletonLoader isDarkMode={isDarkMode} />
          ) : (
            <div className={`p-4 rounded-2xl border relative group
              ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}>
              <div className="flex items-center space-x-4">
                {/* Avatar - Now shows LinkedIn profile picture or InitialsAvatar */}
                <div className="relative">
                  {isAvatarLoading ? (
                    <div className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center">
                      <AnimatedLoader size={24} color={isDarkMode ? "white" : "black"} />
                    </div>
                  ) : linkedInProfile?.avatarUrl || user?.avatar ? (
                    <div className="relative w-16 h-16">
                      <img 
                        src={linkedInProfile?.avatarUrl || user?.avatar}
                        alt={`${user?.firstName} ${user?.lastName}`}
                        className="w-full h-full rounded-full object-cover"
                        onError={(e) => {
                          e.target.style.display = 'none';
                          // Create and show initials avatar
                          const initialsDiv = document.createElement('div');
                          initialsDiv.className = `w-16 h-16 rounded-full flex items-center justify-center ${
                            isDarkMode ? 'bg-slate-700' : 'bg-slate-200'
                          }`;
                          initialsDiv.innerHTML = `<span class="text-2xl font-medium text-white">
                            ${user?.firstName?.[0] || ''}${user?.lastName?.[0] || ''}
                          </span>`;
                          e.target.parentNode.appendChild(initialsDiv);
                        }}
                      />
                    </div>
                  ) : (
                    <div className={`w-16 h-16 rounded-full flex items-center justify-center ${
                      isDarkMode ? 'bg-slate-700' : 'bg-slate-200'
                    }`}>
                      <span className="text-2xl font-medium text-white">
                        {`${user?.firstName?.[0] || ''}${user?.lastName?.[0] || ''}`}
                      </span>
                    </div>
                  )}
                </div>

                {/* Profile Info */}
                <div className="flex-grow min-w-0">
                  {isEditing ? (
                    <div className="space-y-2">
                      <input
                        type="text"
                        name="firstName"
                        value={editedUser.firstName}
                        onChange={handleChange}
                        className={`w-full text-sm px-2 py-1 rounded-lg transition-all duration-200
                          ${isDarkMode 
                            ? 'bg-white/[0.06] text-white focus:bg-white/[0.07] placeholder-white/30' 
                            : 'bg-white text-black focus:bg-white placeholder-black/30'}
                          border ${isDarkMode 
                            ? 'border-white/10 focus:border-white/[0.15]' 
                            : 'border-black/[0.06] focus:border-black/[0.09]'}
                          focus:ring-0 focus:outline-none`}
                        placeholder="First Name"
                      />
                      <input
                        type="text"
                        name="lastName"
                        value={editedUser.lastName}
                        onChange={handleChange}
                        className={`w-full text-sm px-2 py-1 rounded-lg transition-all duration-200
                          ${isDarkMode 
                            ? 'bg-white/[0.06] text-white focus:bg-white/[0.07] placeholder-white/30' 
                            : 'bg-white text-black focus:bg-white placeholder-black/30'}
                          border ${isDarkMode 
                            ? 'border-white/10 focus:border-white/[0.15]' 
                            : 'border-black/[0.06] focus:border-black/[0.09]'}
                          focus:ring-0 focus:outline-none`}
                        placeholder="Last Name"
                      />
                    </div>
                  ) : (
                    <>
                      <h3 className={`text-sm font-medium truncate 
                        ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                        {`${editedUser.firstName} ${editedUser.lastName}`}
                      </h3>
                      <div className={`flex items-center space-x-1 mt-0.5
                        ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                        <EnvelopeIcon className="w-3 h-3" />
                        <span className="text-xs truncate">{user.email}</span>
                        {isEmailVerified && (
                          <div className="flex items-center">
                            <CheckIcon className="w-3 h-3 text-green-500 ml-1" />
                            <span className="text-xs text-green-500 ml-0.5">Verified</span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>

                {/* Edit Button */}
                <div className="flex items-center space-x-2">
                  {isEditing ? (
                    <>
                      <button
                        onClick={handleSave}
                        className={`p-1.5 rounded-full transition-colors
                          ${isDarkMode 
                            ? 'bg-white/10 hover:bg-white/15' 
                            : 'bg-black/5 hover:bg-black/10'}`}
                      >
                        <CheckIcon className={`w-4 h-4 ${isDarkMode ? 'text-white' : 'text-black'}`} />
                      </button>
                      <button
                        onClick={handleCancel}
                        className={`p-1.5 rounded-full transition-colors
                          ${isDarkMode 
                            ? 'bg-white/10 hover:bg-white/15' 
                            : 'bg-black/5 hover:bg-black/10'}`}
                      >
                        <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-white' : 'text-black'}`} />
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={handleEdit}
                      className={`p-1.5 rounded-full transition-opacity duration-200 opacity-0 group-hover:opacity-100
                        ${isDarkMode 
                          ? 'hover:bg-white/10' 
                          : 'hover:bg-black/5'}`}
                    >
                      <PencilSquareIcon className={`w-3.5 h-3.5 
                        ${isDarkMode ? 'text-white/60' : 'text-black/60'}`} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* About Section with Minimal Design */}
          {isAboutLoading ? (
            <AboutSkeletonLoader isDarkMode={isDarkMode} />
          ) : (
            <div className={`p-4 rounded-2xl border relative group
              ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}>
              <div className="flex justify-between items-center mb-3">
                <h3 className={`text-xs font-medium tracking-wide uppercase
                  ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                  About
                </h3>
                <div className="flex items-center space-x-2">
                  {isEditingAbout ? (
                    <>
                      <button
                        onClick={handleSaveAbout}
                        className={`p-1.5 rounded-full transition-colors
                          ${isDarkMode 
                            ? 'bg-white/10 hover:bg-white/15' 
                            : 'bg-black/5 hover:bg-black/10'}`}
                      >
                        <CheckIcon className={`w-4 h-4 ${isDarkMode ? 'text-white' : 'text-black'}`} />
                      </button>
                      <button
                        onClick={handleCancelAbout}
                        className={`p-1.5 rounded-full transition-colors
                          ${isDarkMode 
                            ? 'bg-white/10 hover:bg-white/15' 
                            : 'bg-black/5 hover:bg-black/10'}`}
                      >
                        <XMarkIcon className={`w-4 h-4 ${isDarkMode ? 'text-white' : 'text-black'}`} />
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={handleEditAbout}
                      className={`p-1.5 rounded-full transition-opacity duration-200 opacity-0 group-hover:opacity-100
                        ${isDarkMode 
                          ? 'hover:bg-white/10' 
                          : 'hover:bg-black/5'}`}
                    >
                      <PencilSquareIcon className={`w-3.5 h-3.5 
                        ${isDarkMode ? 'text-white/60' : 'text-black/60'}`} />
                    </button>
                  )}
                </div>
              </div>

              {isEditingAbout ? (
                <textarea
                  name="about"
                  value={editedUser.about}
                  onChange={handleChange}
                  rows="3"
                  className={`w-full text-sm px-3 py-2 rounded-lg transition-all duration-200
                    ${isDarkMode 
                      ? 'bg-white/[0.06] text-white focus:bg-white/[0.07] placeholder-white/30' 
                      : 'bg-white text-black focus:bg-white placeholder-black/30'}
                    border ${isDarkMode 
                      ? 'border-white/10 focus:border-white/[0.15]' 
                      : 'border-black/[0.06] focus:border-black/[0.09]'}
                    focus:ring-0 focus:outline-none resize-y min-h-[72px] max-h-[300px]`}
                  placeholder="Tell us about yourself..."
                />
              ) : (
                <div className="space-y-2">
                  <div className={`relative transition-all duration-200
                    ${isAboutExpanded ? 'h-[300px]' : 'max-h-[100px] overflow-hidden'}`}>
                    <div className={`text-sm whitespace-pre-line h-full
                      ${isAboutExpanded ? 'overflow-y-auto settings-scroll-area' : ''}
                      ${isDarkMode ? 'text-white/80' : 'text-black/80'}`}>
                      {editedUser.about || "No bio yet"}
                    </div>
                    {!isAboutExpanded && editedUser.about && editedUser.about.length > 100 && (
                      <div className={`absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t
                        ${isDarkMode ? 'from-black/90' : 'from-white/90'}`} />
                    )}
                  </div>
                  
                  {/* Add Show more/less button */}
                  {editedUser.about && editedUser.about.length > 100 && (
                    <button
                      onClick={() => setIsAboutExpanded(!isAboutExpanded)}
                      className={`text-xs font-medium transition-colors
                        ${isDarkMode 
                          ? 'text-white/40 hover:text-white/60' 
                          : 'text-black/40 hover:text-black/60'}`}
                    >
                      {isAboutExpanded ? 'Show less' : 'Show more'}
                    </button>
                  )}
                </div>
              )}
            </div>
          )}

          {/* Password Section */}
          <div className={`p-4 rounded-2xl border overflow-hidden
            ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}>
            <button
              onClick={() => setShowPasswordChange(!showPasswordChange)}
              className={`w-full flex justify-between items-center`}
            >
              <span className={`text-xs font-medium tracking-wide uppercase
                ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                Password
              </span>
              <ChevronRightIcon 
                className={`w-3 h-3 transition-transform duration-200
                  ${showPasswordChange ? 'rotate-90' : ''}
                  ${isDarkMode ? 'text-white/40' : 'text-black/40'}`} 
              />
            </button>

            {showPasswordChange && (
              <div className="mt-3">
                <PasswordSection 
                  isDarkMode={isDarkMode} 
                  onSuccess={(message) => setSuccessModal({ show: true, message })}
                />
              </div>
            )}
          </div>

          {/* LinkedIn Connection Section */}
          <motion.div 
            layout
            id="linkedin-section"
            className={`p-4 rounded-2xl border overflow-hidden
              ${isDarkMode ? 'border-white/10' : 'border-black/5'}`}
          >
            <motion.div layout className="mt-4">
              {renderLinkedInSection()}
            </motion.div>
          </motion.div>

        </div>
      </div>

      <LogoutConfirmationModal
        isOpen={showLogoutConfirmation}
        onClose={() => setShowLogoutConfirmation(false)}
        onConfirm={() => {
          logout();
          navigate('/login');
        }}
        isDarkMode={isDarkMode}
      />

      <SuccessModal
        isOpen={successModal.show}
        onClose={() => setSuccessModal({ show: false, message: '' })}
        message={successModal.message}
        isDarkMode={isDarkMode}
      />

      <LinkedInDisconnectModal
        isOpen={showDisconnectModal}
        onClose={() => setShowDisconnectModal(false)}
        onConfirm={handleDisconnectLinkedIn}
        isDarkMode={isDarkMode}
        isLoading={isDisconnectingLinkedIn}
      />
    </div>
  );
};

export default Settings;