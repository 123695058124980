import React from 'react';
import { format } from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const ScheduleCalendarDesign = ({
  month,
  year,
  selectedDate,
  onDateSelect,
  onPrevMonth,
  onNextMonth,
  isDarkMode,
  disablePastDates = true,
  highlightedDates = []
}) => {
  // Get the first day of the month and total days
  const firstDay = new Date(year, month, 1).getDay();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  
  // Get current date for comparison
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Update weekday names to English
  const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  // Generate calendar days array
  const generateDays = () => {
    const days = [];
    
    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }
    
    // Add the days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      days.push(day);
    }
    
    return days;
  };

  const isDateDisabled = (day) => {
    if (!day) return true;
    if (!disablePastDates) return false;
    
    const date = new Date(year, month, day);
    return date < currentDate;
  };

  const isDateHighlighted = (day) => {
    if (!day) return false;
    return highlightedDates.some(date => {
      const highlightDate = new Date(date);
      return highlightDate.getDate() === day &&
             highlightDate.getMonth() === month &&
             highlightDate.getFullYear() === year;
    });
  };

  const isDateSelected = (day) => {
    if (!day || !selectedDate) return false;
    return selectedDate.getDate() === day &&
           selectedDate.getMonth() === month &&
           selectedDate.getFullYear() === year;
  };

  return (
    <div className={`
      w-[280px]
      rounded-lg
      p-3
      ${isDarkMode ? 'bg-[#1a1a1a]' : 'bg-white'}
    `}>
      {/* Header */}
      <div className="flex items-center justify-between mb-2">
        <h2 className={`
          text-sm font-medium tracking-tight
          ${isDarkMode ? 'text-zinc-100' : 'text-zinc-900'}
        `}>
          {format(new Date(year, month), 'MMM yyyy')}
        </h2>
        <div className="flex items-center">
          <button
            onClick={onPrevMonth}
            className={`
              p-1 rounded-md
              transition-colors
              ${isDarkMode 
                ? 'hover:bg-zinc-800 text-zinc-400 hover:text-zinc-100' 
                : 'hover:bg-zinc-100 text-zinc-600 hover:text-zinc-900'
              }
            `}
          >
            <ChevronLeftIcon className="w-3.5 h-3.5" />
          </button>
          <button
            onClick={onNextMonth}
            className={`
              p-1 rounded-md
              transition-colors
              ${isDarkMode 
                ? 'hover:bg-zinc-800 text-zinc-400 hover:text-zinc-100' 
                : 'hover:bg-zinc-100 text-zinc-600 hover:text-zinc-900'
              }
            `}
          >
            <ChevronRightIcon className="w-3.5 h-3.5" />
          </button>
        </div>
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-0.5">
        {/* Weekday headers */}
        {weekDays.map((day, index) => (
          <div
            key={day}
            className={`
              text-center text-[11px] font-medium py-1.5
              ${isDarkMode ? 'text-zinc-400' : 'text-zinc-500'}
            `}
          >
            {day}
          </div>
        ))}

        {/* Calendar days */}
        {generateDays().map((day, index) => {
          const isDisabled = isDateDisabled(day);
          const isHighlighted = isDateHighlighted(day);
          const isSelected = isDateSelected(day);

          return (
            <button
              key={index}
              disabled={isDisabled}
              onClick={() => day && onDateSelect(new Date(year, month, day))}
              className={`
                relative
                aspect-square
                flex
                items-center
                justify-center
                text-xs
                font-medium
                rounded
                transition-colors
                ${!day ? 'pointer-events-none' : 'cursor-pointer'}
                ${isDisabled 
                  ? isDarkMode 
                    ? 'text-zinc-700' 
                    : 'text-zinc-300'
                  : isSelected
                  ? 'bg-blue-500 text-white hover:bg-blue-600'
                  : isHighlighted
                  ? isDarkMode
                    ? 'bg-blue-500/20 text-blue-400 hover:bg-blue-500/30'
                    : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                  : isDarkMode
                  ? 'text-zinc-100 hover:bg-zinc-800'
                  : 'text-zinc-900 hover:bg-zinc-100'
                }
              `}
            >
              {day}
              {isHighlighted && !isSelected && (
                <span className={`
                  absolute bottom-0.5 left-1/2 -translate-x-1/2
                  w-0.5 h-0.5 rounded-full
                  ${isDarkMode ? 'bg-blue-400' : 'bg-blue-600'}
                `} />
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ScheduleCalendarDesign; 