import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Create a singleton worker instance
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

let pdfWorker = null;

const initPDFWorker = () => {
  if (!pdfWorker) {
    pdfWorker = new pdfjs.PDFWorker('pdf-worker');
  }
  return pdfWorker;
};

const PDFPreview = React.memo(({ 
  url, 
  isDarkMode, 
  containerStyle = '', 
  showFullScreenButton = false, 
  onFullScreen 
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [containerWidth, setContainerWidth] = useState(null);
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const loadingTaskRef = useRef(null);
  const documentRef = useRef(null);
  const isMountedRef = useRef(true);
  const [workerReady, setWorkerReady] = useState(false);

  const documentOptions = useMemo(() => ({
    worker: pdfWorker,
    cMapUrl: 'https://unpkg.com/pdfjs-dist@3.11.174/cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'https://unpkg.com/pdfjs-dist@3.11.174/standard_fonts/'
  }), []);

  // Initialize worker
  useEffect(() => {
    const initWorker = async () => {
      try {
        const worker = initPDFWorker();
        if (worker) {
          setWorkerReady(true);
        }
      } catch (error) {
        console.error('Error initializing PDF worker:', error);
        setError('Failed to initialize PDF viewer');
      }
    };
    
    initWorker();
    
    return () => {
      setWorkerReady(false);
    };
  }, []);

  // Cleanup function for PDF document
  const cleanupPDFDocument = useCallback(async () => {
    try {
      if (documentRef.current) {
        const doc = documentRef.current;
        documentRef.current = null;
        await doc.destroy();
      }
      if (loadingTaskRef.current) {
        const task = loadingTaskRef.current;
        loadingTaskRef.current = null;
        await task.destroy();
      }
    } catch (error) {
      console.error('Error cleaning up PDF document:', error);
    }
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      cleanupPDFDocument();
    };
  }, [cleanupPDFDocument]);

  // Add resize observer to track container width
  useEffect(() => {
    if (!containerRef.current) return;
    
    const resizeObserver = new ResizeObserver(entries => {
      if (!isMountedRef.current) return;
      const width = entries[0]?.contentRect.width;
      if (width) {
        setContainerWidth(width);
      }
    });

    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const onDocumentLoadSuccess = useCallback((pdf) => {
    if (!isMountedRef.current) return;
    documentRef.current = pdf;
    setNumPages(pdf.numPages);
    setIsLoading(false);
  }, []);

  const onDocumentLoadError = useCallback((error) => {
    if (!isMountedRef.current) return;
    console.error('Error loading PDF:', error);
    setError('Failed to load PDF');
    setIsLoading(false);
  }, []);

  const handlePrevPage = useCallback(() => {
    setPageNumber(prev => Math.max(prev - 1, 1));
  }, []);

  const handleNextPage = useCallback(() => {
    setPageNumber(prev => Math.min(prev + 1, numPages));
  }, [numPages]);

  if (error) {
    return (
      <div className={`
        p-4 text-center
        ${isDarkMode ? 'text-red-400' : 'text-red-600'}
      `}>
        {error}
      </div>
    );
  }

  return (
    <div 
      className="flex flex-col items-center overflow-hidden relative group"
      ref={containerRef}
    >
      <div 
        className={`flex-1 overflow-auto w-full ${containerStyle}`}
      >
        {isLoading && (
          <div className={`
            flex items-center justify-center p-4
            ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}
          `}>
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
              <circle 
                className="opacity-25" 
                cx="12" 
                cy="12" 
                r="10" 
                stroke="currentColor" 
                strokeWidth="4"
                fill="none"
              />
              <path 
                className="opacity-75" 
                fill="currentColor" 
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
            Loading PDF...
          </div>
        )}
        
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
          loading={null}
          className="flex justify-center"
          options={documentOptions}
        >
          {numPages > 0 && workerReady && (
            <Page
              pageNumber={pageNumber}
              width={containerWidth}
              className={`
                shadow-lg 
                rounded-lg 
                ${isDarkMode ? 'bg-gray-800' : 'bg-white'}
              `}
              loading={null}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          )}
        </Document>

        {/* Side Navigation Controls */}
        <div className="absolute inset-y-0 left-0 right-0 pointer-events-none">
          {/* Left Arrow */}
          <div className="absolute left-2 h-full flex items-center">
            {pageNumber > 1 && (
              <button
                onClick={handlePrevPage}
                className={`
                  p-2
                  rounded-full
                  pointer-events-auto
                  ${isDarkMode 
                    ? 'bg-gray-800/90 hover:bg-gray-700' 
                    : 'bg-white/90 hover:bg-gray-100'
                  }
                  transition-all
                  duration-200
                  opacity-0 group-hover:opacity-100
                  shadow-[0_0_0_1px_rgba(0,0,0,0.1)]
                  ${isDarkMode ? 'shadow-[0_0_0_1px_rgba(255,255,255,0.1)]' : ''}
                `}
                title="Previous page"
              >
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
            )}
          </div>

          {/* Right Arrow */}
          <div className="absolute right-2 h-full flex items-center">
            {pageNumber < numPages && (
              <button
                onClick={handleNextPage}
                className={`
                  p-2
                  rounded-full
                  pointer-events-auto
                  ${isDarkMode 
                    ? 'bg-gray-800/90 hover:bg-gray-700' 
                    : 'bg-white/90 hover:bg-gray-100'
                  }
                  transition-all
                  duration-200
                  opacity-0 group-hover:opacity-100
                  shadow-[0_0_0_1px_rgba(0,0,0,0.1)]
                  ${isDarkMode ? 'shadow-[0_0_0_1px_rgba(255,255,255,0.1)]' : ''}
                `}
                title="Next page"
              >
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            )}
          </div>
        </div>

        {/* Bottom Controls for Page Number and Full Screen */}
        <div className={`
          absolute 
          bottom-4 
          right-4
          flex 
          items-center 
          gap-1
          p-1
          rounded-lg
          transition-all
          duration-200
          opacity-0 group-hover:opacity-100
          ${isDarkMode 
            ? 'bg-gray-800/90 shadow-[0_0_0_1px_rgba(255,255,255,0.1)]' 
            : 'bg-white/90 shadow-[0_0_0_1px_rgba(0,0,0,0.1)]'
          }
        `}>
          <span className={`
            text-xs font-medium px-2
            ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}
          `}>
            {pageNumber}/{numPages || '--'}
          </span>

          {showFullScreenButton && (
            <>
              <div className={`
                w-px h-4 mx-1
                ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'}
              `} />
              <button
                onClick={onFullScreen}
                className={`
                  p-1.5 
                  rounded-md 
                  ${isDarkMode 
                    ? 'hover:bg-gray-700' 
                    : 'hover:bg-gray-100'
                  }
                  transition-colors
                `}
                title="Open in full screen"
              >
                <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                </svg>
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

PDFPreview.displayName = 'PDFPreview';

export default PDFPreview; 