// Debounce helper function
function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// Throttle helper function
function throttle(func, limit) {
  let inThrottle;
  return function(...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
}

export function setViewportHeight() {
  try {
    // Get the actual viewport height
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // Set explicit heights for mobile
    if (window.innerWidth <= 768) {
      // Use the maximum of window.innerHeight and -webkit-fill-available
      const height = Math.max(window.innerHeight, document.documentElement.clientHeight);
      document.documentElement.style.setProperty('--mobile-height', `${height}px`);
      
      // Set minimal bottom padding
      const bottomPadding = Math.min(window.innerHeight * 0.03, 16); // maximum 16px or 3% of height
      document.documentElement.style.setProperty('--safe-bottom', `${bottomPadding}px`);
    }
  } catch (error) {
    console.warn('Error setting viewport height:', error);
  }
}

// Create both throttled and debounced versions with more appropriate timing
const throttledSetViewportHeight = throttle(setViewportHeight, 16); // ~60fps
const debouncedSetViewportHeight = debounce(setViewportHeight, 100);

export function initViewportHeightFix() {
  // Initial setup
  setViewportHeight();

  // Update on resize with throttle for smooth updates
  window.addEventListener('resize', throttledSetViewportHeight, { passive: true });

  // Update on orientation change with debounce and delay
  window.addEventListener('orientationchange', () => {
    // Wait for the orientation change to complete
    setTimeout(debouncedSetViewportHeight, 200);
  });

  // Handle keyboard appearance on iOS
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    window.addEventListener('focusin', (e) => {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        // Add a class to handle keyboard appearance
        document.documentElement.classList.add('keyboard-visible');
        throttledSetViewportHeight();
      }
    });

    window.addEventListener('focusout', () => {
      // Remove the class when keyboard disappears
      document.documentElement.classList.remove('keyboard-visible');
      throttledSetViewportHeight();
    });
  }

  // Update on page load and DOM content loaded
  window.addEventListener('load', setViewportHeight, { passive: true });
  document.addEventListener('DOMContentLoaded', setViewportHeight, { passive: true });

  // Return cleanup function
  return () => {
    window.removeEventListener('resize', throttledSetViewportHeight);
    window.removeEventListener('load', setViewportHeight);
    document.removeEventListener('DOMContentLoaded', setViewportHeight);
  };
} 