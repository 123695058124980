import React, { useState } from 'react';
import { XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';
import { useTheme } from '../context/ThemeContext';
import { cn } from '../utils/cn';
import { motion, AnimatePresence } from 'framer-motion';

const TemplateAttachment = ({ template, onRemove, width = 'w-full' }) => {
  const [showContent, setShowContent] = useState(false);
  const { isDarkMode } = useTheme();

  const tags = [template.inspiredBy, template.category, template.tone]
    .filter(Boolean)
    .join(' • ');

  return (
    <div className={cn(width, "relative")}>
      <motion.div 
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        className={cn(
          "rounded-xl p-3",
          isDarkMode 
            ? "bg-[#1E1E1E] border border-[#333333] hover:bg-[#252525]" 
            : "bg-white border border-gray-200 hover:bg-gray-50",
          "transition-all duration-200 hover:shadow-md"
        )}
      >
        <div className="flex items-center gap-3">
          {/* Column with template name and tags */}
          <div className="flex flex-col flex-1 min-w-0 justify-center">
            <div className={cn(
              "text-sm font-medium truncate",
              isDarkMode ? "text-slate-100" : "text-gray-900"
            )}>
              {template.title}
            </div>
            {tags && (
              <div className={cn(
                "text-xs truncate mt-0.5",
                isDarkMode ? "text-slate-400" : "text-gray-500"
              )}>
                {tags}
              </div>
            )}
          </div>

          {/* Action buttons */}
          <div className="flex gap-2 shrink-0">
            <button 
              onClick={() => setShowContent(!showContent)} 
              className={cn(
                "p-2 rounded-lg transition-all duration-200",
                isDarkMode 
                  ? "text-slate-400 hover:text-slate-200 hover:bg-white/5 active:bg-white/10" 
                  : "text-gray-500 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200"
              )}
              title={showContent ? "Hide content" : "Show content"}
            >
              <EyeIcon className="h-4 w-4" />
            </button>
            <button 
              onClick={onRemove} 
              className={cn(
                "p-2 rounded-lg transition-all duration-200",
                isDarkMode 
                  ? "text-slate-400 hover:text-red-400 hover:bg-red-500/10 active:bg-red-500/20" 
                  : "text-gray-500 hover:text-red-600 hover:bg-red-50 active:bg-red-100"
              )}
              title="Remove template"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      </motion.div>

      {/* Preview Popup */}
      <AnimatePresence>
        {showContent && (
          <motion.div
            initial={{ opacity: 0, y: -5, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -5, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className={cn(
              "absolute z-10 left-0 right-0 bottom-full mb-2 p-4 rounded-xl border",
              "shadow-lg max-h-60 overflow-y-auto",
              isDarkMode 
                ? "bg-[#252525] border-[#404040] shadow-black/20" 
                : "bg-white border-gray-200 shadow-gray-100/60",
              "backdrop-blur-sm",
              isDarkMode 
                ? "bg-opacity-90" 
                : "bg-opacity-90"
            )}
          >
            <p className={cn(
              "text-sm whitespace-pre-wrap",
              isDarkMode ? "text-slate-100" : "text-gray-700"
            )}>
              {template.content}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TemplateAttachment;